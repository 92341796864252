import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerAddressesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromModuleModels.Address;
  entities: { [iri: string]: fromModuleModels.Address };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  current: null,
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    CustomerAddressesActions.CreateCustomerAddress,
    CustomerAddressesActions.ReadCustomerAddress,
    CustomerAddressesActions.UpdateCustomerAddress,
    CustomerAddressesActions.DeleteCustomerAddress,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    CustomerAddressesActions.CreateCustomerAddressSuccess,
    (state, { response }) => {
      const entities = {
        [response['@id']]: response,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    CustomerAddressesActions.ReadCustomerAddressSuccess,
    CustomerAddressesActions.UpdateCustomerAddressSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    CustomerAddressesActions.ReadCustomerAddressesSuccess,
    CustomerAddressesActions.ReadCustomerBillingAddressesSuccess,
    CustomerAddressesActions.ReadCustomerDeliveryAddressesSuccess,
    CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddressesSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const hasPagination = response.hasOwnProperty('hydra:view');
      let pagination = {};

      const entities = items.reduce(
        (
          entities: { [iri: string]: fromModuleModels.CustomerAddress },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        errors: {},
        loading: false,
        pagination
      };
    }
  ),
  on(
    CustomerAddressesActions.DeleteCustomerAddressSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removedItem, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    CustomerAddressesActions.CreateCustomerAddressFail,
    CustomerAddressesActions.ReadCustomerAddressFail,
    CustomerAddressesActions.ReadCustomerAddressesFail,
    CustomerAddressesActions.UpdateCustomerAddressFail,
    CustomerAddressesActions.DeleteCustomerAddressFail,
    (state, { response }) => {
      return {
        ...state,
        customerAddressesErrors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
