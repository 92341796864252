import {NgModule} from '@angular/core';

import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';
import * as fromModuleServices from './services';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    fromModuleContainers.AllContainers,
    fromModuleComponents.AllComponents
  ],
  exports: [
    fromModuleContainers.AllContainers,
    fromModuleComponents.AllComponents
  ],
  providers: [
    fromModuleServices.AllServices
  ],
})
export class NotificationsModule {
}
