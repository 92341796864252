import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { take } from 'rxjs/operators';
import {
  extractIri,
  extractTypeByIri
} from '../../shared/utilities/objects.utility';
import { CustomerDataMediumsService } from './customer-data-mediums.service';
import { ReplacementDataMediumsService } from './replacement-data-mediums.service';
import { EnclosureDataMediumsService } from './enclosure-data-mediums.service';
import { UserRoleService } from '../../shared/services/user-role.service';

type LabelResponse = {
  size: number;
  mimeType: string;
  fileName: string;
  contentUrl: string;
};

@Injectable()
export class DataMediumsService {
  constructor(
    private apiService: AbstractApiService,
    private customerDataMediumService: CustomerDataMediumsService,
    private enclosureDataMediumsService: EnclosureDataMediumsService,
    private replacementDataMediumsService: ReplacementDataMediumsService,
    private rolesService: UserRoleService
  ) {}

  updateDataMedium(payload): Observable<AbstractApiResponse> {
    switch (extractTypeByIri(payload)) {
      case 'customer_data_media':
        return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
          'ROLE_MWS_CUSTOMER_DATA_MEDIUM_EDIT',
          () =>
            this.customerDataMediumService.update(extractIri(payload), payload)
        );
      case 'replacement_data_media':
        return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
          'ROLE_MWS_REPLACEMENT_DATA_MEDIUM_EDIT',
          () =>
            this.replacementDataMediumsService.update(
              extractIri(payload),
              payload
            )
        );
      case 'enclosure_data_media':
        return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
          'ROLE_MWS_ENCLOSURE_DATA_MEDIUM_EDIT',
          () =>
            this.enclosureDataMediumsService.update(
              extractIri(payload),
              payload
            )
        );
    }
  }

  readDataMediums(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ABSTRACT_DATA_MEDIUM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/data_media', page, params)
            )
    );
  }

  readLabel(iri: string): Observable<LabelResponse> {
    return this.rolesService.userHasRoleFilter<LabelResponse>(
      [
        'ROLE_MWS_ENCLOSURE_DATA_MEDIUM_LABEL',
        'ROLE_MWS_CUSTOMER_DATA_MEDIUM_LABEL',
        'ROLE_MWS_REPLACEMENT_DATA_MEDIUM_LABEL'
      ],
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(iri + '/label', true)
    );
  }

  openLabelInPopup(iri: string): void {
    this.readLabel(iri)
      .pipe(take(1))
      .subscribe(response => {
        const win = window.open(
          '',
          'Label',
          'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=100,top=100'
        );
        const html = `<html lang="de"><body style="margin:0!important"><embed width="100%" height="100%" src="${response.contentUrl}" type="application/pdf" /></body></html>`;
        setTimeout(() => {
          win.document.open();
          win.document.write(html);
          win.document.close();
        }, 0);
        // const file = new Blob([response.contentUrl], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        // console.log(response);
      });
  }

  readDataMediumsChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ABSTRACT_DATA_MEDIUM_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }
}
