import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerComment } from '../../models';
import { CustomerCommentsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.CustomerComment };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    CustomerCommentsActions.CreateCustomerComment,
    CustomerCommentsActions.ReadCustomerComment,
    CustomerCommentsActions.ReadCustomerComments,
    CustomerCommentsActions.UpdateCustomerComment,
    CustomerCommentsActions.DeleteCustomerComment,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    CustomerCommentsActions.CreateCustomerCommentSuccess,
    (state, { response }) => {
      const entities = {
        // Note: Newly created comments are added to state BEFORE existing comments since their order is DESC
        [response['@id']]: response,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  // todo: this should be obsolete
  on(
    CustomerCommentsActions.LoadCustomerCommentsSelectionSuccess,
    (state, { response, type }) => {
      // const customerUuid = response['hydra:view']['@id'].split('=')[1];

      const isNotSelection = response.hasOwnProperty('hydra:member');

      const comments = isNotSelection ? response['hydra:member'] : response;

      const entities = comments.reduce(
        (entities: { [iri: string]: CustomerComment }, comment) => {
          return {
            ...entities,
            [comment['@id']]: comment
          };
        },
        { ...state.entities }
      );

      // console.clear();
      // console.log(type);
      // console.log(entities);

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    CustomerCommentsActions.ReadCustomerCommentsSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      let pagination = {};
      const entities = items.reduce(
        (
          entities: { [iri: string]: fromModuleModels.CustomerComment },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        // intentionally "reset" entities for proper pagination as opposed to accumulating values
        {}
      );

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        pagination,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    CustomerCommentsActions.ReadCustomerCommentChunkSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      let pagination = {};
      const entities = items.reduce(
        (
          entities: { [iri: string]: fromModuleModels.CustomerComment },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        pagination,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    CustomerCommentsActions.UpdateCustomerCommentSuccess,
    (state, { response }) => {
      const entities = { ...state.entities, [response['@id']]: response };

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(CustomerCommentsActions.DeleteCustomerCommentSuccess, (state, { iri }) => {
    // @ts-ignore
    const { [iri]: removed, ...entities } = state.entities;

    return {
      ...state,
      entities,
      errors: {},
      loading: false
    };
  }),
  on(
    CustomerCommentsActions.CreateCustomerCommentFail,
    CustomerCommentsActions.ReadCustomerCommentFail,
    CustomerCommentsActions.ReadCustomerCommentsFail,
    CustomerCommentsActions.UpdateCustomerCommentFail,
    CustomerCommentsActions.DeleteCustomerCommentFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
