import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {InvoiceItemsActions, PartialInvoiceItemsActions} from '../actions/';
import {PartialInvoiceItemsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class PartialInvoiceItemsEffects {

  constructor(private actions$: Actions, private service: PartialInvoiceItemsService, private notifierService: NotifierService) {
  }


  CreatePartialInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(PartialInvoiceItemsActions.CreatePartialInvoiceItem),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createPartialInvoiceItem(payload).pipe(
        map((response) => PartialInvoiceItemsActions.CreatePartialInvoiceItemSuccess({response, invoiceIri})),
        catchError(response => of(PartialInvoiceItemsActions.CreatePartialInvoiceItemFail({response})))
      );
    })
  ));


  ReadPartialInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(PartialInvoiceItemsActions.ReadPartialInvoiceItem),
    switchMap(({iri}) => {
      return this.service.readPartialInvoiceItem(iri).pipe(
        map((response => PartialInvoiceItemsActions.ReadPartialInvoiceItemSuccess({response}))),
        catchError(response => of(PartialInvoiceItemsActions.ReadPartialInvoiceItemFail({response})))
      );
    })
  ));


  UpdatePartialInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(PartialInvoiceItemsActions.UpdatePartialInvoiceItem),
    switchMap(({iri, payload, invoiceIri}) => {
      return this.service.updatePartialInvoiceItem(iri, payload).pipe(
        map(response => PartialInvoiceItemsActions.UpdatePartialInvoiceItemSuccess({response, invoiceIri})),
        catchError(response => of(PartialInvoiceItemsActions.UpdatePartialInvoiceItemFail({response})))
      );
    })
  ));


  DeletePartialInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(PartialInvoiceItemsActions.DeletePartialInvoiceItem),
    switchMap(({iri, invoiceIri}) => {
      return this.service.deletePartialInvoiceItem(iri).pipe(
        map(() => PartialInvoiceItemsActions.DeletePartialInvoiceItemSuccess({iri, invoiceIri})),
        catchError((response: HttpErrorResponse) => of(PartialInvoiceItemsActions.DeletePartialInvoiceItemFail({response})))
      );
    })
  ));

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      PartialInvoiceItemsActions.CreatePartialInvoiceItemFail,
      PartialInvoiceItemsActions.UpdatePartialInvoiceItemFail,
      PartialInvoiceItemsActions.DeletePartialInvoiceItemFail,
    ),
    map(({type, response}) => {
      console.log({response});
      const errors = response?.error['hydra:description'];
      const message = 'Fehler bei der Anfrage: ' + errors;
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      PartialInvoiceItemsActions.CreatePartialInvoiceItemSuccess,
      PartialInvoiceItemsActions.UpdatePartialInvoiceItemSuccess,
      PartialInvoiceItemsActions.DeletePartialInvoiceItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case PartialInvoiceItemsActions.CREATE_PARTIAL_INVOICE_ITEM_SUCCESS:
          message = 'Ein neue Abschlagsrechnungsposition wurde angelegt.';
          break;
        case PartialInvoiceItemsActions.UPDATE_PARTIAL_INVOICE_ITEM_SUCCESS:
          message = 'Die Abschlagsrechnungsposition wurde aktualisiert.';
          break;
        case PartialInvoiceItemsActions.DELETE_PARTIAL_INVOICE_ITEM_SUCCESS:
          message = 'Die Abschlagsrechnungsposition wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

  PartialInvoiceItemActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      PartialInvoiceItemsActions.CreatePartialInvoiceItemSuccess,
      PartialInvoiceItemsActions.UpdatePartialInvoiceItemSuccess,
    ),
    map(({type}) => {

      const text = type === PartialInvoiceItemsActions.CREATE_PARTIAL_INVOICE_ITEM_SUCCESS
        ? `Die Abschlagsrechnungsposition wurde angelegt.`
        : 'Die Abschlagsrechnungsposition wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt.',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
