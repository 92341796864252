import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as CustomersModuleActions from '../../../customers/store';
import { CustomersActions } from '../../../customers/store';
import * as CustomersModuleSelectors from '../../../customers/store/selectors';
import { CustomersSelectors } from '../../../customers/store/selectors';
import * as InvoicesModuleActions from '../../store';
import {
  CorrectionInvoicesActions,
  DocumentDeliveriesActions
} from '../../store';
import * as InvoicesModuleSelectors from '../../store/selectors';
import {
  CancellationInvoicesSelectors,
  CorrectionInvoicesSelectors
} from '../../store/selectors';
import * as MasterDataModuleSelectors from '../../../master-data/store/selectors';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromInvoicesModuleModels from '../../models';
import { CorrectionInvoice } from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import { ApplicationState } from '../../../application-state/store';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { ModalDialogOptions } from '../../../application-state/models';
import { CorrectionInvoicesService } from '../../services';
import {
  PaymentRecorderComponent,
  SendDocumentToolComponent
} from '../../components';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import * as moment from 'moment';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { ActivatedRoute } from '@angular/router';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-correction-invoice-view',
  styleUrls: ['correction-invoice-view.component.scss'],
  template: `
    <view-heading
      heading="Faktura: Korrekturrechnung bearbeiten"
      colRight="text-right"
    >
      <span>
        <button
          mat-icon-button
          class="text-color-grey"
          (click)="openMailDialog()"
        >
          <mat-icon>mail</mat-icon>
        </button>
      </span>
    </view-heading>

    <div class="invoice__outer" [formGroup]="cif">
      <div class="wrap invoice__wrap">
        <div class="invoice grid">
          <div class="column-9">
            <app-correction-invoice-preview
              [invoice$]="invoice$"
              (updateInvoice)="handleUpdateInvoice($event)"
            ></app-correction-invoice-preview>
          </div>

          <div class="column-5 p-b--32">
            <correction-invoice-facturer
              *ngIf="invoice"
              [invoice]="invoice"
              [formGroup]="cif"
              (requestBookInvoice)="handleBookCorrectionInvoice($event)"
              (requestCreateInvoice)="handleCreateCorrectionInvoice()"
              (requestUpdateInvoice)="handleUpdateInvoice($event)"
            ></correction-invoice-facturer>

            <app-send-document-tool
              *ngIf="invoice"
              [invoice]="invoice$ | async"
              invoiceTypeName="Gutschrift"
            ></app-send-document-tool>

            <payout-tool
              [credit]="invoice"
              (requestBookPayOut)="
                handleBookCorrectionInvoicePayOut(invoice['@id'], $event)
              "
            ></payout-tool>
          </div>
        </div>
      </div>
    </div>

    <!--      <ng-template #dsfm>-->

    <!--        <div class="dialog__header grid grid-no-gutter">-->
    <!--          <div class="column-12">-->
    <!--            <h2>Abteilung wählen</h2>-->
    <!--          </div>-->
    <!--          <div class="column-2 m-ta&#45;&#45;2">-->
    <!--            <button mat-icon-button mat-dialog-close>-->
    <!--              <mat-icon>close</mat-icon>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="issuer-list" mat-dialog-content style="min-width: 450px;" [formGroup]="dsf">-->

    <!--          <ng-select-->
    <!--            [items]="data.departments"-->
    <!--            placeholder="Abteilung wählen"-->
    <!--            bindLabel="name"-->
    <!--            bindValue="@id"-->
    <!--            #departmentSelector-->
    <!--            [clearable]="false"-->
    <!--            [markFirst]="false"-->
    <!--            formControlName="issuer"-->
    <!--            appendTo="body"-->
    <!--          ></ng-select>-->

    <!--          <div class="m-ta&#45;&#45;2">-->

    <!--            <button [disabled]="!departmentSelector.hasValue"-->
    <!--                    mat-flat-button color="green"-->
    <!--                    (click)="handleUpdateIssuer(departmentSelector.selectedValues)">-->

    <!--              <mat-icon>save</mat-icon>-->
    <!--              <span>Speichern</span>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </ng-template>-->

    <!--      <ng-template #iifm>-->

    <!--        <app-dialog-header>-->
    <!--          <h2>{{ getInvoiceItemModalHeading() }}</h2>-->
    <!--        </app-dialog-header>-->

    <!--        <div mat-dialog-content>-->

    <!--          <div *ngIf="!!!invoiceItemType$.getValue()" style="min-width: 450px">-->

    <!--            <ng-select-->
    <!--              class="m-b&#45;&#45;16"-->
    <!--              #itemTypeSelector-->
    <!--              [items]="invoiceTypeOptions"-->
    <!--              bindLabel="label"-->
    <!--              bindValue="value"-->
    <!--              appendTo="body"-->
    <!--              placeholder="Art der Position auswählen"-->
    <!--            ></ng-select>-->

    <!--            <div class="m-ta&#45;&#45;2">-->
    <!--              <button mat-flat-button color="green"-->
    <!--                      (click)="invoiceItemType$.next(itemTypeSelector.selectedValues[0]['value'])">-->
    <!--                <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--                <span>Weiter</span>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <app-invoice-item-form-->
    <!--            *ngIf="!!invoiceItemType$.getValue()"-->
    <!--            [currencies$]="currencies$"-->
    <!--            [invoice]="data.invoice"-->
    <!--            [itemType$]="invoiceItemType$"-->
    <!--            [presets$]="presets$"-->
    <!--            [products$]="products$"-->
    <!--            [services$]="services$"-->
    <!--            [taxRates$]="taxes$"-->
    <!--            [units$]="productUnits$"-->
    <!--            (requestReadServices)="handleReadServices()"-->
    <!--            (requestReadProducts)="handleReadProducts()"-->
    <!--            (requestCreateInvoiceItem)="handleCreateCorrectionInvoiceItem($event, data.invoice['@id'])"-->
    <!--            (requestUpdateInvoiceItem)="handleUpdateCorrectionInvoiceItem($event, data.invoice['@id'])"-->
    <!--          ></app-invoice-item-form>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--    </ng-container>-->

    <!--    <ng-template #casm>-->

    <!--      <div class="dialog__header grid grid-no-gutter">-->
    <!--        <div class="column-12">-->
    <!--          <h2>Kunden festlegen</h2>-->
    <!--        </div>-->
    <!--        <div class="column-2 m-ta&#45;&#45;2">-->
    <!--          <button mat-icon-button mat-dialog-close>-->
    <!--            <mat-icon>close</mat-icon>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="issuer-list" mat-dialog-content style="min-width: 550px;">-->

    <!--        <p class="heading&#45;&#45;h3">Kunden auswählen</p>-->

    <!--        <customer-search-->
    <!--          (requestReadCustomers)="handleReadCustomers($event)"-->
    <!--          (requestResetSearchResults)="handleResetSearchResults()"-->
    <!--          (requestSetCustomer)="handleSelectCustomer($event)"-->
    <!--          [editableSearchTerm]="true"-->
    <!--          [formGroup]="cf"-->
    <!--          [clearable]="true"-->
    <!--          appendTo="body"-->
    <!--          [paginationNextLink$]="paginationNextLink$"-->
    <!--          [searchResults$]="searchResults$"-->
    <!--          notFoundText="Keine Ergebnisse"-->
    <!--          placeholder="Firma oder Kunde"-->
    <!--        ></customer-search>-->

    <!--        <p class="heading&#45;&#45;h3">Rechnungsanschrift</p>-->

    <!--        <ng-container [formGroup]="caf">-->
    <!--          <ng-select-->
    <!--            [items]="customerAddresses$ | async"-->
    <!--            notFoundText="Keine Ergebnisse"-->
    <!--            [searchable]="false"-->
    <!--            [clearable]="false"-->
    <!--            [readonly]="!!!customer$.getValue()"-->
    <!--            (change)="handleUpdateInvoiceRecipient(addressSelector.selectedValues)"-->
    <!--            formControlName="address"-->
    <!--            bindValue="@id"-->
    <!--            placeholder="{{ !!customer$.getValue() ? 'Adressen für diesen Kunden:' : 'Zunächst Kunde oder Auftrag wählen' }}"-->
    <!--            appendTo="body"-->
    <!--            #addressSelector>-->

    <!--            <ng-template ng-label-tmp let-item="item">-->
    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->

    <!--            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">-->

    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->
    <!--          </ng-select>-->
    <!--        </ng-container>-->

    <!--        <div class="m-ta&#45;&#45;2 m-t&#45;&#45;16">-->
    <!--          <button mat-flat-button color="green" (click)="dialog.closeAll()">-->

    <!--            <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--            <span>Speichern und schließen</span>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--<customer-address-search
              (requestReadCustomerAddresses)="handleReadCustomerAddresses($event)"
              (requestResetSearchResults)="handleResetCustomerAddressSearchResults()"
              (requestSetCustomer)="handleSelectCustomer($event)"
              [clearable]="disabled"
              [editableSearchTerm]="true"
              [paginationNextLink$]="customerAddressPaginationNextLink$"
              [searchResults$]="customerAddressesSearchResults$"
              notFoundText="Keine Ergebnisse"
              placeholder="Kunden-Adresse"
            ></customer-address-search>-->
    <!--    </ng-template>-->
  `
})
export class CorrectionInvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentRecorderComponent) prc: PaymentRecorderComponent;
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;
  caf: FormGroup;
  cf: FormGroup;
  cif: FormGroup;
  currencies$: Observable<Array<fromMasterDataModuleModels.Currency>>;
  customer$: BehaviorSubject<
    fromCustomersModuleModels.Customer
  > = new BehaviorSubject(null);
  customerAddress$: BehaviorSubject<
    fromCustomersModuleModels.CustomerAddress | any
  > = new BehaviorSubject(null);
  customerAddressPaginationNextLink$: BehaviorSubject<
    string
  > = new BehaviorSubject(null);
  customerAddresses$: Observable<
    Array<fromCustomersModuleModels.CustomerAddress>
  >;
  customerContacts$: Observable<
    Array<fromCustomersModuleModels.CustomerContact>
  >;
  departmentAdUri: SafeUrl | any = null;
  departmentLogoUri: SafeUrl | any = null;
  departments$: Observable<Array<fromMasterDataModuleModels.Department>>;
  documentDeliveryProviders$: Observable<
    Array<fromInvoicesModuleModels.DocumentDeliveryProvider>
  >;
  dsf: FormGroup;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  invoiceIri: string;
  invoiceUUID: string;
  invoice$: BehaviorSubject<CorrectionInvoice> = new BehaviorSubject<
    CorrectionInvoice
  >({});
  invoice: CorrectionInvoice;
  invoiceItemType$: BehaviorSubject<
    'custom' | 'product' | 'service'
  > = new BehaviorSubject(null);
  invoiceType$: BehaviorSubject<
    'Invoice' | 'PartialInvoice' | string
  > = new BehaviorSubject(null);
  invoiceTypeOptions = [
    { label: 'Freie Position', value: 'custom' },
    {
      label: 'Produkt',
      value: 'product'
    },
    { label: 'Service', value: 'service' }
  ];
  issuer$: BehaviorSubject<
    fromMasterDataModuleModels.Department
  > = new BehaviorSubject(null);
  onDestroy$: Subject<any> = new Subject<any>();
  paginationNextLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  productUnits$: Observable<Array<fromMasterDataModuleModels.ProductUnit>>;
  productUnitsEntities$: Observable<{
    [iri: string]: fromMasterDataModuleModels.ProductUnit;
  }>;
  products$: Observable<Array<fromMasterDataModuleModels.Product>>;
  searchResults$: Observable<Array<fromCustomersModuleModels.Customer>>;
  services$: Observable<Array<fromMasterDataModuleModels.Service>>;
  taxes$: Observable<Array<fromMasterDataModuleModels.Tax>>;
  protected readonly extractIri = extractIri;

  constructor(
    public dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fs: FormsService,
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private cis: CorrectionInvoicesService,
    private activatedRoute: ActivatedRoute,
    private dls: DepartmentLogosService
  ) {}

  getHourDifferential(futureDate: string): number {
    const duration = moment.duration(
      moment(futureDate).diff(moment(new Date()))
    );
    return Math.floor(duration.asHours());
  }

  getInvoiceHeading(
    invoice?: fromInvoicesModuleModels.CorrectionInvoice
  ): string {
    return `Korrekturrechnung / Credit ${
      invoice?.correctionInvoiceNumber ? invoice?.correctionInvoiceNumber : ''
    }`;
  }

  getInvoiceItemModalHeading(): string {
    const headings = {
      custom: 'Freie Position zur Rechnung hinzufügen',
      service: 'Service zur Rechnung hinzufügen',
      product: 'Produkt zur Rechnung hinzufügen'
    };

    if (!!this.invoiceItemType$.getValue()) {
      return headings[this.invoiceItemType$.getValue()];
    }

    return 'Position zur Rechnung hinzufügen';
  }

  ngOnInit(): void {
    this.initForms();

    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.invoiceIri = decodeURIComponent(
          this.activatedRoute.snapshot.params.iri
        );

        this.invoiceUUID = params.uuid;
        this.invoiceIri = '/api/correction_invoices/' + params.uuid;
        this.store$
          .select(CorrectionInvoicesSelectors.selectCorrectionInvoiceByIndex, {
            iri: this.invoiceIri
          })
          .pipe(
            takeUntil(this.onDestroy$),
            filter(i => !!i)
          )
          .subscribe(invoice => {
            this.invoice$.next(invoice);
            this.upsertInvoiceData(invoice);
            if (invoice.customerNumber) {
              this.store$.dispatch(
                CustomersActions.ReadCustomers({
                  page: -1,
                  params: { id: invoice.customerNumber }
                })
              );
              this.store$
                .select(CustomersSelectors.sByCustomerNumber, {
                  customerNumber: invoice.customerNumber
                })
                .pipe(
                  filter(e => !!e),
                  take(1)
                )
                .subscribe(c => {
                  this.customer$.next(c);
                });
            }
          });
        this.store$.dispatch(
          CorrectionInvoicesActions.ReadCorrectionInvoice({
            iri: this.invoiceIri
          })
        );
      });
    this.selectData();
    this.initSubscriptions();
    this.initActionListeners();
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .CreateCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .UpdateCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .DeleteCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BookCorrectionInvoicePayOutSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BookCorrectionInvoiceSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .UpdateCorrectionInvoiceSuccess,
          InvoicesModuleActions.InvoicePaymentsActions
            .CreateInvoicePaymentSuccess
        )
      )
      // @ts-ignore
      .subscribe(({ type, response = null }) => {
        const invoiceIri = this.invoiceIri;
        this.store$.dispatch(
          InvoicesModuleActions.CorrectionInvoicesActions.ReadCorrectionInvoice(
            { iri: invoiceIri }
          )
        );
      });
  }

  initForms(): void {
    this.cif = this.fb.group({
      correctedInvoice: this.fb.control(null),
      issuer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null, [Validators.required]),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null, Validators.required),
        taxNumber: this.fb.control(null)
      }),
      customerNumber: this.fb.control(null, Validators.required),
      customText: this.fb.control('')
    });

    this.cf = this.fb.group({
      customer: this.fb.control(null)
    });

    this.caf = this.fb.group({
      address: this.fb.control(null)
    });

    this.dsf = this.fb.group({
      issuer: this.fb.control(null)
    });
  }

  selectData(): void {
    this.store$
      .select(CancellationInvoicesSelectors.selectCancellationInvoiceByIndex, {
        iri: this.invoiceIri
      })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(invoice => {
        this.invoice$.next(invoice);
      });
    // Observables
    this.currencies$ = this.store$.pipe(
      select(MasterDataModuleSelectors.CurrenciesSelectors.selectCurrencies)
    );
    this.searchResults$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectSearchResults)
    );
    this.departments$ = this.store$.pipe(
      select(DepartmentsSelectors.selectDepartments)
    );
    this.documentDeliveryProviders$ = this.store$.pipe(
      select(
        InvoicesModuleSelectors.DocumentDeliveryProvidersSelectors
          .selectDocumentDeliveryProviders
      )
    );
    this.taxes$ = this.store$.pipe(
      select(MasterDataModuleSelectors.TaxesSelectors.selectTaxes)
    );
    this.products$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ProductsSelectors.sList)
    );
    this.productUnits$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ProductUnitsSelectors.selectProductUnits)
    );
    this.productUnitsEntities$ = this.store$.pipe(
      select(
        MasterDataModuleSelectors.ProductUnitsSelectors
          .selectProductUnitsEntities
      )
    );
    this.services$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ServicesSelectors.selectServices)
    );

    // Behavior Subjects
    // this.store$.pipe(select(OrdersSelectors.selectCurrentOrder)).subscribe(this.order$);
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .CreateCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .UpdateCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .DeleteCorrectionInvoiceItemSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BookCorrectionInvoicePayOutSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BookCorrectionInvoiceSuccess,
          InvoicesModuleActions.CorrectionInvoicesActions
            .UpdateCorrectionInvoiceSuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateEmailDocumentDeliverySuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateLetterXPressDocumentDeliverySuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateRegisteredLetterDocumentDeliverySuccess,
          InvoicesModuleActions.InvoicePaymentsActions
            .CreateInvoicePaymentSuccess
        )
      )
      // @ts-ignore
      .subscribe(({ type, response = null }) => {
        const triggerRefresh = [
          DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .CREATE_CORRECTION_INVOICE_ITEM_SUCCESS,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .UPDATE_CORRECTION_INVOICE_ITEM_SUCCESS,
          InvoicesModuleActions.CorrectionInvoiceItemsActions
            .DELETE_CORRECTION_INVOICE_ITEM_SUCCESS,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BOOK_CORRECTION_INVOICE_PAY_OUT_SUCCESS,
          InvoicesModuleActions.CorrectionInvoicesActions
            .BOOK_CORRECTION_INVOICE_SUCCESS
        ];

        if (
          type ===
          InvoicesModuleActions.CorrectionInvoicesActions
            .UPDATE_CORRECTION_INVOICE_SUCCESS
        ) {
          this.cif.get('customText').markAsPristine();
        }

        if (
          type ===
          InvoicesModuleActions.InvoicePaymentsActions
            .CREATE_INVOICE_PAYMENT_SUCCESS
        ) {
          this.prc.initForm();
        }

        if (
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS ||
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS ||
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS
        ) {
          this.sitc.initForm();
          this.sitc.resetTypeSelect();
          this.sitc.documentDeliveryTarget$.next(null);
        }

        if (triggerRefresh.includes(type)) {
          const invoiceIri = this.invoiceIri;
          this.store$.dispatch(
            InvoicesModuleActions.CorrectionInvoicesActions.ReadCorrectionInvoice(
              { iri: invoiceIri }
            )
          );
        }
      });
  }

  initSubscriptions(): void {
    this.customer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(customer => !!customer)
      )
      .subscribe(customer => {
        this.handleUpdateFormsAfterCustomerChange(customer);

        // Note: if customer is restored we don't know @id
        if (customer.hasOwnProperty('@id')) {
          this.handleReadCustomerAddresses(customer['@id']);
        }

        // this.handleReadCustomerContacts(customer['@id'])
      });

    /*this.customerAddress$.pipe(
      takeUntil(this.onDestroy$),
      filter(address => !!address)
    ).subscribe((address) => {
      this.handleUpdateInvoiceRecipient([address]);
    });*/

    this.issuer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(issuer => !!issuer)
      )
      .subscribe(issuer => {
        const invoice: CorrectionInvoice = {
          ...this.invoice$.getValue(),
          issuer: extractIri(issuer)
        };
        this.invoice$.next(invoice);
        this.cf.get('issuer').setValue(issuer['@id']);
        this.cif.get('issuer').setValue(issuer['@id']);
        this.dsf.get('issuer').setValue(issuer['@id']);
      });

    this.invoice$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(invoice => !!invoice)
      )
      .subscribe(invoice => {
        this.upsertInvoiceData(invoice);
        this.invoiceType$.next(invoice['@type']);
      });

    this.dialog.afterAllClosed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invoiceItemType$.next(null);
      });
  }

  upsertInvoiceData(invoice: fromInvoicesModuleModels.CorrectionInvoice): void {
    this.invoice = invoice;
    const existingIssuer = this.issuer$.getValue();

    // Prevent re-fetching issuer every time invoice is updated
    if (!!existingIssuer && existingIssuer['@id'] !== invoice.issuer['@id']) {
      this.issuer$.next(invoice.issuer);
    }

    this.customer$.next({
      nameLine1: invoice.recipient?.nameLine1,
      nameLine2: invoice.recipient?.nameLine2,
      customerNumber: invoice.customerNumber
    });
    this.customerAddress$.next({
      address: invoice.recipient?.address,
      addressType: invoice.recipient?.addressType
    });

    this.cif.get('customText').setValue(invoice.customText);
    this.cif.get('recipient.address').patchValue(invoice.recipient?.address);
    if (invoice?.recipient?.addressType) {
      this.cif
        .get('recipient.addressType')
        .patchValue(invoice.recipient?.addressType['@id']);
    }
    this.cif.get('correctedInvoice').setValue(invoice.correctedInvoice);
  }

  handleUpdateFormsAfterCustomerChange(
    customer: fromCustomersModuleModels.Customer
  ): void {
    this.cif.get('customerNumber').setValue(customer.customerNumber);
    this.cif.get('recipient.taxNumber').setValue(customer.taxNumber);
    this.cif.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.cif.get('recipient.nameLine2').setValue(customer.nameLine2);

    this.cf.get('customer').setValue(customer['@id']);

    // todo: auto-set default address if given?
    /*if(!!customer?.defaultBillingAddress) {
      // Check for and fetch default customer address if set
      this.caf.get('address').setValue(customer.defaultBillingAddress);
    }*/
  }

  handleUpdateIssuer(
    selectedIssuers: Array<fromMasterDataModuleModels.Department> | any
  ): void {
    this.issuer$.next(selectedIssuers[0]);
  }

  formatAddress(address: fromCustomersModuleModels.Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleReadCustomer(customerIri: string): void {
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ReadCustomer({ iri: customerIri })
    );
  }

  handleReadCustomerAddresses(customerIri: string): void {
    const params = {
      'customer.uuid': StringsUtility.getUuidFromIri(customerIri)
    };
    this.store$.dispatch(
      CustomersModuleActions.CustomerAddressesActions.ReadCustomerBillingAddresses(
        { customerIri }
      )
    );
    this.customerAddresses$ = this.store$.pipe(
      select(
        CustomersModuleSelectors.CustomerAddressesSelectors
          .selectCustomerBillingAddressesByCustomerIri,
        { customerIri }
      )
    );
  }

  handleBookCorrectionInvoice(invoice: string): void {
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.BookCorrectionInvoice({
        iri: invoice
      })
    );
  }

  handleCreateCorrectionInvoice(): void {
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.CreateCorrectionInvoice({
        payload: this.cif.value
      })
    );
  }

  handleUpdateInvoice(PAYLOAD: fromInvoicesModuleModels.InvoiceLike): void {
    console.log('UPDATE INVOICE', PAYLOAD);
    if (!extractIri(PAYLOAD, true) && !extractIri(this.invoice, true)) {
      return;
    }

    const payload = {
      iri: extractIri(PAYLOAD, true) || extractIri(this.invoice, true),
      payload: PAYLOAD ? PAYLOAD : this.cif.value
    };
    if (payload.payload.issuer) {
      payload.payload.issuer = extractIri(payload.payload.issuer);
    }
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.UpdateCorrectionInvoice(
        payload
      )
    );
  }

  handleBookCorrectionInvoicePayOut(
    correctionInvoice: string,
    payload: { payoutAt: string }
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.BookCorrectionInvoicePayOut(
        {
          iri: correctionInvoice,
          payload
        }
      )
    );
  }

  openDialog(
    ref: TemplateRef<any>,
    config: ModalDialogOptions | any = {}
  ): void {
    this.dialog.open(ref, config);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    // this.store$.dispatch(OrdersActions.ResetCurrentOrder());
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ResetCurrentCustomer()
    );
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.ResetCorrectionInvoicesLoaded()
    );
    this.store$.dispatch(
      InvoicesModuleActions.CorrectionInvoicesActions.ResetCurrentCorrectionInvoice()
    );
  }

  openMailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'correction-invoice', entity$: this.invoice$ }
    });
  }
}
