import {Component, Inject, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { QuillEditorComponent } from 'ngx-quill';
import { FeedbackRequestsActions } from '../../../notifications/store';
import { FeedbackRequest } from '../../../notifications/models';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogComponent } from '../dialog/dialog.component';
import {
  getUuidFromIri,
  StringsUtility
} from '../../utilities/strings.utility';
import { RouterActions } from '../../../application-state/store/actions';
import { extractUUID } from '../../utilities/objects.utility';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import {AdministratorFeedbackRequestsActions, AdministratorsActions} from '../../../administrators/store';
import { Administrator } from '../../../administrators/models';

@Component({
  selector: 'app-reply-comment-modal',
  styleUrls: ['./reply-comment-modal.component.scss'],
  template: `
    <div mat-dialog-content>
      <!--      <pre>{{ data.feedbackRequest | json }}</pre>-->
      <!--      <pre>{{ administrators | json }}</pre>-->
      <!--      <pre>{{ administrators[data?.feedbackRequest?.createdBy] | json }}</pre>-->


      <form
        [formGroup]="form"
        class="dialog--feedback-request mat-dialog-content__inner"
      >
        <!--<pre>{{data.feedbackRequest|json }}</pre>-->
        <div class="dialog__header">
          <span>Ursprungskommentar</span>
        </div>
        <div
          class="dialog__original-content"
          [innerHTML]="data.response?.comment?.content || data.response?.content | safeHtml"
        ></div>

        <div class="dialog__editor">
          <app-text-editor
            formControlName="feedback"
            placeholder="Antwort-Kommentar"
          ></app-text-editor>
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="grid">
      <div class="column-7"></div>
      <div class="column-7 m-ta--2">
        <button
          (click)="handleSubmit()"
          [disabled]="form.invalid"
          mat-button
          color="green"
        >
          Antworten
        </button>
        <button mat-button mat-dialog-close>Abbrechen</button>
      </div>
    </div>
  `
})
export class ReplyCommentModalComponent implements OnInit, OnDestroy {
  @ViewChildren(QuillEditorComponent) editors: { [property: string]: any };

  onDestroy$: Subject<any> = new Subject<any>();

  contents: { [id: string]: string } = {
    confirmationText: 'Antworten'
  };
  form: FormGroup;
  administrators: { [k: string]: Administrator } = {};

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private actions$: Actions,
    public dialogRef: MatDialogRef<ReplyCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      response: FeedbackRequest,
      variation?: "answer" | "resolve",
      module?: "customer" | "lead" | "ticket" | "order"
    }
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      feedback: this.fb.control(null, [
        Validators.required,
        Validators.minLength(2)
      ])
    });
    this.form.get('feedback').setValue(this.data.response.additionalContent && this.data.response.additionalContent)
    this.form.updateValueAndValidity()
    this.actions$
      .pipe(
        ofType(FeedbackRequestsActions.UpdateFeedbackRequestSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        this.dialog
          .open(DialogComponent, {
            data: {
              heading: 'Erledigt',
              text: 'Die Antwort wurde gespeichert.',
              confirmationText: 'Zum Kommentar springen',
              closeText: 'Schließen'
            }
          })
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(confirmation => {
            if (confirmation) {
              const iri = this.data.response.relatedInstance;
              const type: string = iri.split('/')[2];
              const uuid = iri.startsWith('/')
                ? StringsUtility.getUuidFromIri(iri)
                : iri;
              const uuidComment = extractUUID(response.commentIri);
              this.store$.dispatch(
                RouterActions.Go({
                  path: [type, uuid],
                  query: { target_comment: uuidComment }
                })
              );
            }
            this.dialog.closeAll();
            this.store$.dispatch(
              FeedbackRequestsActions.LoadFeedbackRequests()
            );
          });
      });
    this.loadAdministrators();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleTextColorChange(
    color: string,
    propertyName: string,
    editorIndex: number
  ): void {
    const editor = this.editors.toArray()[editorIndex].quillEditor;

    const defaultColor = '#000000';
    const { index, length } = editor.getSelection();
    const { color: currentColor } = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(index, length, {
      color: currentColor === color ? defaultColor : color
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getRouterLink(): any {
    const parts = this.data.response.relatedInstance.split('/');
    return [parts[2] + '/' + parts[3]];
  }

  getRouterQuery(): any {
    return {
      target_comment: getUuidFromIri(this.data.response.commentIri)
    };
  }

  loadAdministrators(): void {
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(entities => {
        this.administrators = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }

  handleSubmit(): void {
    const feedback: any = this.form.value.feedback;
    this.store$.dispatch(
      AdministratorFeedbackRequestsActions.PatchFeedbackRequests({
        iri: this.data.response['@id'],
        module: this.data.module,
        variation: this.data.variation,
        payload: {
          additionalContent: feedback
        }
      })
    );
    this.dialogRef.close();
  }
}
