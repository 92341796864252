import {createSelector} from '@ngrx/store';

// Provider ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY
import {ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectAnalysisResultCategoriesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY]
);

export const selectAnalysisResultCategoriesEntities = createSelector(
  selectAnalysisResultCategoriesState,
  (state) => state.entities
);

export const selectAnalysisResultCategories = createSelector(
  selectAnalysisResultCategoriesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectAnalysisResultCategoriesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectAnalysisResultCategoriesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectAnalysisResultCategoriesState,
  (state) => state.loaded
);
