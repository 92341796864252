import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Lead, LeadComment} from '../../models';
import {BehaviorSubject, Subject} from 'rxjs';
import {LeadCommentsActions} from '../../store';
import {Actions, ofType} from '@ngrx/effects';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-lead-comment-form-dialog',
  styleUrls: ['./lead-comment-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Kommentar bearbeiten</h2>
    </app-dialog-header>
    <div mat-dialog-content>
      <app-lead-comment-form
        *ngIf="data"
        [lead$]="data.lead$"
        [leadComment$]="data.leadComment$"
      ></app-lead-comment-form>
    </div>
  `

})
export class LeadCommentFormDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                lead$: BehaviorSubject<Lead>,
                leadComment$: BehaviorSubject<LeadComment>
              },
              public dialogRef: MatDialogRef<LeadCommentFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions
  ) {
  }


  ngOnInit(): void {
    this.actions$.pipe(
      ofType(LeadCommentsActions.CreateLeadCommentSuccess, LeadCommentsActions.UpdateLeadCommentSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
