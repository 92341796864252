import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {LeadOriginTypesSelectors} from '../../store/selectors';
import {Lead, LeadOriginType} from '../../models';
import * as CustomersModuleActions from '../../../customers/store';
import * as CustomersModuleSelector from '../../../customers/store/selectors';
import {PartnerWebsite} from '../../../customers/models';

@Component({
  selector: 'app-lead-meta-form',
  styleUrls: ['lead-meta-form.component.scss'],
  template: `

    <ng-container [formGroup]="lmf">
      <div class="header grid">
        <div class="column-12">
          <ng-select
            *ngIf="isNewLead"
            [clearable]="false"
            [items]="leadOriginTypes$ | async"
            [loading]="leadOriginTypesLoading$ | async"
            [searchable]="false"
            [markFirst]="true"
            (change)="updateSelectedLeadOrigin($event)"
            bindValue="@id"
            placeholder="Herkunft*"
            formControlName="leadOriginType"
            bindLabel="name"
          ></ng-select>

          <p *ngIf="!isNewLead"
             class="heading">Anfrage vom {{ lead.createdAt | date: 'medium' }} via {{ getLeadSourceName() }}</p>
        </div>
        <span class="icon-wrapper column-2">
          <span class="icon">{{ (selectedLeadOriginType$|async)?.short || 'T' }}</span>
        </span>
      </div>

      <hr class="">

      <div class="comment-box">
        <app-text-editor class="editor--lead-text" formControlName="leadText"
                         *ngIf="isNewLead || editComment"></app-text-editor>
        <mat-hint align="start" *ngIf="errors?.leadText">{{ errors.leadText.message }}</mat-hint>

        <div *ngIf="!editComment && !isNewLead">
          <div class="edit-btn-wrapper cursor-pointer" (click)="editComment=!editComment">
            <mat-icon>edit</mat-icon>
          </div>
          <p *ngIf="lead.leadText" [innerHTML]="lead.leadText|safeHtml"></p>
          <p *ngIf="!lead.leadText"> Kein Text vorhanden</p>
        </div>

      </div>
    </ng-container>

    <!-- <pre>{{ lmf.value | json }}</pre>-->
  `
})
export class LeadMetaFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() lead$: BehaviorSubject<Lead>;
  leadOriginTypes$: Observable<Array<LeadOriginType>>;
  leadOriginTypesLoading$: Observable<boolean>;
  partnerWebsite: PartnerWebsite;
  selectedLeadOriginType$: BehaviorSubject<fromModuleModels.LeadOriginType> = new BehaviorSubject<LeadOriginType>(null);

  lmf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  editComment = true;


  get lead(): fromModuleModels.Lead {
    return this.lead$ && this.lead$.getValue();
  }

  get isNewLead(): boolean {
    return !this.lead;
  }

  constructor(private fb: FormBuilder, private fs: FormsService, private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.leadOriginTypesLoading$ = this.store$.select(LeadOriginTypesSelectors.isLoading);

    this.initForm();
    this.lead$.pipe(
      takeUntil(this.onDestroy$),
      filter((presets: any) => !!presets)
    ).subscribe((lead: Lead) => {
      this.editComment = false;
      // loadPartner website
      if (lead.createdBy.includes('partner_websites')) {
        const iri = lead.createdBy;
        this.store$.dispatch(CustomersModuleActions.PartnerWebsitesActions.ReadPartnerWebsite({iri}));
        this.store$.select(CustomersModuleSelector.PartnerWebsitesSelectors.selectPartnerWebsiteByIndex, {iri})
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            this.partnerWebsite = v;
          });
      }
      this.store$.select(LeadOriginTypesSelectors.selectLeadOriginType, {iri: lead.leadOriginType})
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((selectedLeadOriginType) => {
          this.selectedLeadOriginType$.next(selectedLeadOriginType);
        });


      this.fs.patchForm(this.lmf, lead);
      this.lmf.markAsUntouched();
    });
    this.leadOriginTypes$ = this.store$.select(LeadOriginTypesSelectors.selectLeadOriginTypes);

  }

  getLeadSourceName(): string {

    if (this.partnerWebsite && this.partnerWebsite?.website) {
      return this.partnerWebsite.website;
    }
    if (!this.selectedLeadOriginType$ || !this.selectedLeadOriginType$.getValue()) {
      return '';
    }
    return this.selectedLeadOriginType$.getValue().name;
  }

  initForm(): void {
    this.lmf = this.fb.group({
      leadOriginType: this.fb.control(
        {
          // Set Phone as initial default when using app
          value: '/api/lead_origin_types/0',
          disabled: !!this.lead
        },
        [Validators.required]
      ),
      leadText: this.fb.control('')
    });
  }

  updateSelectedLeadOrigin(type: fromModuleModels.LeadOriginType): void {
    this.selectedLeadOriginType$.next(type);
  }
}
