import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { CustomerAccount } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerAccountsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  // POST /customer_accounts
  createCustomerAccount(
    payload: CustomerAccount
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<CustomerAccount>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/customer_accounts`, payload)
    );
  }

  // GET /customer_accounts/{uuid}
  readCustomerAccount(iri: string): Observable<CustomerAccount> {
    return this.rolesService.userHasRoleFilter<CustomerAccount>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  // SECURITY: Everyone can view his/her own profile
  // GET /customer_accounts/{uuid}/profile
  readCustomerAccountProfile(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_PROFILE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`${iri}/profile`, true)
    );
  }

  // GET /customer_accounts
  readCustomerAccounts(page = 1): Observable<AbstractApiResponse> {
    let uri = '/customer_accounts';
    if (page === -1) {
      uri += '?pagination=false';
    } else {
      uri += `?page=${page}`;
    }
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole => (hasRole === false ? EMPTY : this.apiService.getObject(uri))
    );
  }

  // SECURITY: Everyone can update his/her own password
  // PUT /customer_accounts/{uuid}/change_password
  updateCustomerAccountPassword(
    iri: string,
    payload: {
      oldPassword: string;
      plainPassword: string;
      plainPasswordConfirmation: string;
    }
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/change_password`,
              payload,
              true
            )
    );
  }

  // PUT /customer_accounts/{uuid}/profile
  updateCustomerAccountProfile(
    iri: string,
    payload: any
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(`${iri}/profile`, payload, true)
    );
  }

  // PUT /customer_accounts/{uuid}
  updateCustomerAccount(
    iri: string,
    payload: CustomerAccount
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  // DELETE /customer_accounts/{uuid}
  deleteCustomerAccount(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
