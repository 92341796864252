import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { ShippingProvider } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ShippingProvidersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getShippingProviderToDataRecovery(id: string): Observable<ShippingProvider> {
    return this.rolesService.userHasRoleFilter<ShippingProvider>(
      'ROLE_MWS_SHIPPING_PROVIDER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipping_providers_to_dr/${id}`)
    );
  }

  getShippingProvidersToDataRecovery(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<ShippingProvider>(
      'ROLE_MWS_SHIPPING_PROVIDER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipping_providers_to_dr`)
    );
  }

  getShippingProviderToCustomer(id: string): Observable<ShippingProvider> {
    return this.rolesService.userHasRoleFilter<ShippingProvider>(
      'ROLE_MWS_SHIPPING_PROVIDER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipping_providers_to_customer/${id}`)
    );
  }

  getShippingProvidersToCustomer(): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SHIPPING_PROVIDER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipping_providers_to_customer`)
    );
  }
}
