import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {sortBy} from 'lodash-es';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Store} from '@ngrx/store';

import {ApplicationState} from '../../../application-state/store';
import {RouterActions} from '../../../application-state/store/actions';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {TicketsActions} from '../../store';
import {TicketsSelectors} from '../../store/selectors';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {TicketList} from '../../models/ticket-list.interface';
import * as moment from 'moment/moment';
import {PageEvent} from '@angular/material/paginator';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../../orders/store/selectors';
import {CustomerContactsSelectors} from '../../../customers/store/selectors';
import {LocalStorageService} from "../../../shared/services";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-tickets-view',
  styleUrls: ['tickets-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row">
      <div class="col-auto me-auto">
        <h1>Tickets</h1>
      </div>
      <div class="col-auto">
        <app-search-input
          [showToggle]="true"
          [filtersPage]="'tickets'"
          (searchTermChange)="handleSearchInput($event)"
          (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
        ></app-search-input>
      </div>
      <app-ticket-filter-form
        [searchBoxVisible$]="searchBoxVisible$"
        (updateFilter)="handleUpdateFilter($event)"
      ></app-ticket-filter-form>

    </div>

    <div class="row">
      <div class="col">
        <div class="table-paginator-group pos-relative">
          <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

          <app-ticket-list
            [items]="sortTickets(filterTickets(tickets$|async)) | slice: lowValue : highValue"
            (requestViewTicket)="handleViewTicket($event)"
          ></app-ticket-list>

          <app-paginator-unstyled
            [totalItems]="filterTickets(tickets$|async).length"
            [pageSize]="30"
            [pageIndex]="currentPage - 1"
            [pageSizeOptions]="[5,10,15,20, 25, 30]"
            (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
            [showFirstLastButtons]="true"
          ></app-paginator-unstyled>
        </div>
      </div>
    </div>

  `
})
export class TicketsViewComponent implements OnInit, OnDestroy {

  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeFilter: { [p: string]: string } = {};
  filterString = '';
  lowValue = 0;
  highValue = 30;
  tickets$: Observable<Array<TicketList>>;
  isLoading$: Observable<boolean>;
  currentPage: number;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              private title: AbstractTitleService,
              private router: Router,
              private route: ActivatedRoute,
              private localStorageService: LocalStorageService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.title.setTitle('Tickets');
    this.tickets$ = this.store$.select(TicketsSelectors.sList);

    this.store$.dispatch(TicketsActions.ReadTickets({page: -1}));
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(TicketsSelectors.isLoading),
      this.store$.select(CustomerContactsSelectors.isLoading),
    ]);
    const filters = this.localStorageService.getObjectByKey('filters', 'tickets');
    this.searchBoxVisible$.next(!!filters);

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.lowValue = (this.currentPage - 1) * 30;
      this.highValue = this.lowValue + 30;
    });
    // this.tickets$
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe((tickets) => {
    //     let uuid = [];
    //     for (const ticket of tickets) {
    //       if (ticket.order?.technicalCustomerContact
    //         && loadedCustomerContactsUUIDs.indexOf(getUuidFromIri(ticket.order.technicalCustomerContact)) <= -1) {
    //         uuid.push(ticket.order.technicalCustomerContact);
    //       }
    //       if (ticket.order.organizationalCustomerContact
    //         && loadedCustomerContactsUUIDs.indexOf(getUuidFromIri(ticket.order.organizationalCustomerContact)) <= -1) {
    //         uuid.push(ticket.order.organizationalCustomerContact);
    //       }
    //       if (ticket.order.decisionMakerCustomerContact
    //         && loadedCustomerContactsUUIDs.indexOf(getUuidFromIri(ticket.order.decisionMakerCustomerContact)) <= -1) {
    //         uuid.push(ticket.order.decisionMakerCustomerContact);
    //       }
    //     }
    //     uuid = uuid.map(getUuidFromIri);
    //     if (uuid.length > 0) {
    //       loadedCustomerContactsUUIDs.push(...uuid);
    //       this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({page: -1, params: {uuid}}));
    //     }
    //
    //   });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.updateUrl();
  }

  handleSearchInput(term: string): void {
    this.filterString = term;
  }

  sortTickets(toBeSortedTickets: TicketList[]): TicketList[] {
    return sortBy(toBeSortedTickets, (e) => moment(e.createdAt).toDate().getTime() * -1);
  }

  filterTickets(toBeFilteredOrders: TicketList[]): TicketList[] {
    const filter = this.activeFilter;
    const filterString = this.filterString;
    return toBeFilteredOrders.filter(e => {
      let match = true;


      if (!filter && !filterString) {
        return match;
      }
      if (filterString !== '') {
        const parts = filterString.split(' ').map(f => f.toLowerCase());
        match = parts.every(t => {
          // Ticketnummer,
          // Name,
          // Firma,
          // Datenträger
          // Seriennummer
          // Ticket
          // Auftragsverlauf
          return ((e.ticketNumber + '').toLowerCase().indexOf(t) > -1) ||
            (e.order?.customer?.nameLine1?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.customer?.nameLine2?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.decisionMakerCustomerContact?.firstName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.decisionMakerCustomerContact?.lastName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.organizationalCustomerContact?.firstName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.organizationalCustomerContact?.lastName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.technicalCustomerContact?.firstName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.technicalCustomerContact?.lastName?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.analysisPriorityMode?.name?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.stateRange?.toLowerCase().indexOf(t) > -1) ||
            (e.order?.storageSystem?.name.toLowerCase().indexOf(t) > -1);
        });
      }
      if (filter?.stateRange && e.order.stateRange !== filter.stateRange) {
        match = false;
      }
      if (filter?.dataRecoveryPriorityMode &&
        (!e.order.dataRecoveryPriorityMode
          || (e.order.dataRecoveryPriorityMode && e.order.dataRecoveryPriorityMode.name !== filter.dataRecoveryPriorityMode))) {
        match = false;
      }

      if (filter && filter.technician && e.order?.technician !== filter.technician) {
        match = false;
      }
      if (filter && (filter.createdAtMin || filter.createdAtMax)) {
        const createdAt = moment(e.createdAt);
        if (filter.createdAtMin && !moment(filter.createdAtMin, 'YYYY-MM-DD').isSameOrBefore(createdAt)) {
          match = false;
        }
        if (filter.createdAtMax && !moment(filter.createdAtMax, 'YYYY-MM-DD').isSameOrAfter(createdAt)) {
          match = false;
        }
      }
      if (filter && (filter.releasedAtMin || filter.releasedAtMax)) {
        if (!e.order.releasedAt) {
          match = false;
        } else {
          const releasedAt = moment(e.order.releasedAt);
          if (filter.createdAtMin && !moment(filter.releasedAtMin, 'YYYY-MM-DD').isSameOrBefore(releasedAt)) {
            match = false;
          }
          if (filter.createdAtMax && !moment(filter.releasedAtMax, 'YYYY-MM-DD').isSameOrAfter(releasedAt)) {
            match = false;
          }
        }
      }


      return match;
    });
  }


  handleViewTicket(ticket: TicketList): void {
    const uuid = StringsUtility.getUuidFromIri(ticket['@id']);
    // this.store$.dispatch(RouterActions.Go({path: ['tickets', uuid]}));
    window.open(`/tickets/${uuid}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=200,width=1280,height=800");
  }

  handleUpdateFilter(filters: { [p: string]: string }): void {
    this.activeFilter = filters;
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
  }

}
