import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { FollowUp, FollowUpDetail } from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_FOLLOW_UP = '[FollowUps] Create FollowUp';
export const CREATE_FOLLOW_UP_SUCCESS = '[FollowUps] Create FollowUp Success';
export const CREATE_FOLLOW_UP_FAIL = '[FollowUps] Create FollowUp Fail';

export const CreateFollowUp = createAction(
  CREATE_FOLLOW_UP,
  props<{ payload: FollowUp }>()
);

export const CreateFollowUpSuccess = createAction(
  CREATE_FOLLOW_UP_SUCCESS,
  props<{ response: FollowUp }>()
);

export const CreateFollowUpFail = createAction(
  CREATE_FOLLOW_UP_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Load 1

export const LOAD_FOLLOW_UP = '[FollowUps] Load FollowUp';
export const LOAD_FOLLOW_UP_SUCCESS = '[FollowUps] Load FollowUp Success';
export const LOAD_FOLLOW_UP_FAIL = '[FollowUps] Load FollowUp Fail';

export const LoadFollowUp = createAction(
  LOAD_FOLLOW_UP,
  props<{ iri: string }>()
);

export const LoadFollowUpSuccess = createAction(
  LOAD_FOLLOW_UP_SUCCESS,
  props<{ response: FollowUpDetail }>()
);

export const LoadFollowUpFail = createAction(
  LOAD_FOLLOW_UP_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Load list

export const LOAD_FOLLOW_UPS = '[FollowUps] Load FollowUps';
export const LOAD_FOLLOW_UPS_SUCCESS = '[FollowUps] Load FollowUps Success';
export const LOAD_FOLLOW_UPS_FAIL = '[FollowUps] Load FollowUps Fail';

export const LoadFollowUps = createAction(
  LOAD_FOLLOW_UPS,
  props<{ fromDate: Date | null; toDate: Date }>()
);

export const LoadFollowUpsSuccess = createAction(
  LOAD_FOLLOW_UPS_SUCCESS,
  props<{ response: Array<FollowUp> }>()
);

export const LoadFollowUpsFail = createAction(
  LOAD_FOLLOW_UPS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_FOLLOW_UP = '[FollowUps] Update FollowUp';
export const UPDATE_FOLLOW_UP_SUCCESS = '[FollowUps] Update FollowUp Success';
export const UPDATE_FOLLOW_UP_FAIL = '[FollowUps] Update FollowUp Fail';

export const UpdateFollowUp = createAction(
  UPDATE_FOLLOW_UP,
  props<{
    iri: string;
    payload: FollowUp | { date: Date } | { deletedAt: string | null };
  }>()
);

export const UpdateFollowUpSuccess = createAction(
  UPDATE_FOLLOW_UP_SUCCESS,
  props<{ response: FollowUp }>()
);

export const UpdateFollowUpFail = createAction(
  UPDATE_FOLLOW_UP_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_FOLLOW_UP = '[FollowUps] Delete FollowUp';
export const DELETE_FOLLOW_UP_SUCCESS = '[FollowUps] Delete FollowUp Success';
export const DELETE_FOLLOW_UP_FAIL = '[FollowUps] Delete FollowUp Fail';

export const DeleteFollowUp = createAction(
  DELETE_FOLLOW_UP,
  props<{ iri: string }>()
);

export const DeleteFollowUpSuccess = createAction(
  DELETE_FOLLOW_UP_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteFollowUpFail = createAction(
  DELETE_FOLLOW_UP_FAIL,
  props<{ response: HttpErrorResponse }>()
);
