import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Order, OrderComment} from '../../../orders/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {Ticket} from '../../models';
import {OrderCommentsActions} from '../../../orders/store';
import {takeUntil} from 'rxjs/operators';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../../orders/store/selectors';
import {
  CustomerAddressesSelectors,
  CustomerContactsSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';

@Component({
  selector: 'app-ticket-detail-form-dialog',
  styleUrls: ['./ticket-detail-form-dialog.component.scss'],
  template: `
    <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

    <app-dialog-header>
      <h2>Ticket bearbeiten</h2>
    </app-dialog-header>
    <div mat-dialog-content>
      <app-ticket-form
        [order$]="data.order$"
      ></app-ticket-form>
    </div>
  `
})
export class TicketDetailFormDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                order$: BehaviorSubject<Order>,
                ticket$: BehaviorSubject<Ticket>
              },
              public dialogRef: MatDialogRef<TicketDetailFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(CustomersSelectors.isLoading),
      this.store$.select(CustomerAddressesSelectors.isLoading),
      this.store$.select(CustomerContactsSelectors.isLoading),

    ]);
    this.actions$.pipe(
      ofType(OrderCommentsActions.CreateOrderCommentSuccess, OrderCommentsActions.UpdateOrderCommentSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
