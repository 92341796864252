<ng-container [formGroup]="sf">
  <div class="grid">
    <div
      class="mat-form-field column-14"
      [class.column-7]="shippingProvider.indexOf('SHIPPING_PROVIDER_SHIPPING_LABEL_DHL') > -1"
      [class.column-14]="shippingProvider.indexOf('SHIPPING_PROVIDER_SHIPPING_LABEL_DHL') === -1"
      [class.hidden]="shippingProvider.indexOf('GENERIC_SHIPMENT') > -1"
    >
      <ng-select
        [items]="shipmentServices$ | async"
        [loading]="shipmentServicesIsLoading$ | async"
        [clearable]="false"
        [markFirst]="true"
        [searchable]="false"
        bindValue="@id"
        bindLabel="name"
        placeholder="Versand-Service"
        formControlName="shipmentService"
      ></ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'shipmentService'"
          [formGroup]="sf"
        ></app-form-error>
      </mat-error>
    </div>

    <div
      class="mat-form-field column-7"
      *ngIf="shippingProvider.indexOf('SHIPPING_PROVIDER_SHIPPING_LABEL_DHL') > -1"
    >
      <ng-select
        [items]="shipmentAdditionalInsurances$ | async"
        [loading]="shipmentAdditionalInsurancesIsLoading$ | async"
        [clearable]="true"
        [markFirst]="true"
        [searchable]="false"
        bindValue="@id"
        bindLabel="name"
        [placeholder]="'shared.show_shipment_dialog.additional_insurance_placeholder' | translate"
        formControlName="additionalInsurance"
      ></ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'additionalInsurance'"
          [formGroup]="sf"
        ></app-form-error>
      </mat-error>
    </div>
    <div class="column-6" *ngIf="sf?.contains('pickupDate')">
      <mat-form-field>
        <mat-label>Abholung</mat-label>
        <input type="date" matInput formControlName="pickupDate" />
        <mat-error>
          <app-form-error
            [fieldName]="'pickupDate'"
            [formGroup]="sf"
          ></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="column-4" *ngIf="sf?.get('pickupTimeEarliest')">
      <mat-form-field>
        <mat-label>Abholung (Uhrzeit von)</mat-label>
        <input
          type="time"
          matInput
          formControlName="pickupTimeEarliest"
          list="pickupTimeEarliestList"
        />
        <datalist id="pickupTimeEarliestList">
          <option *ngFor="let time of times">{{ time.time }}</option>
        </datalist>

        <mat-error>
          <app-form-error
            [fieldName]="'pickupTimeEarliest'"
            [formGroup]="sf"
          ></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="column-4" *ngIf="sf?.get('pickupTimeLatest')">
      <mat-form-field>
        <mat-label>Abholung (Uhrzeit bis)</mat-label>
        <input
          type="time"
          matInput
          formControlName="pickupTimeLatest"
          list="pickupTimeLatestList"
        />
        <datalist id="pickupTimeLatestList">
          <option *ngFor="let time of times">{{ time.time }}</option>
        </datalist>

        <mat-error>
          <app-form-error
            [fieldName]="'pickupTimeLatest'"
            [formGroup]="sf"
          ></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div
      *ngFor="let destKey of destinationKeys"
      class="column-{{ destinationKeys.length > 2 ? 'auto' : '7' }} grid address-block"
    >
      <app-shipping-address
        [groupName]="destKey"
        [group]="sf"
        [errors]="errors"
      >
        <h2 class="heading column-14">
          {{ getDestinationValue(destKey) }}
        </h2>
      </app-shipping-address>
    </div>

    <mat-form-field [class]="sf.get('trackingNumber').enabled ? 'column-7' : 'column-14'">
      <mat-label>Beschreibung</mat-label>
      <textarea
        matInput
        rows="5"
        formControlName="description"
        cdkTextareaAutosize
      ></textarea>
      <mat-error>
        <app-form-error
          [fieldName]="'description'"
          [formGroup]="sf"
        ></app-form-error>
      </mat-error>
    </mat-form-field>

    <div *ngIf="sf.get('trackingNumber').enabled" class="column-7">
      <mat-form-field>
        <mat-label>Tracking Number</mat-label>
        <input matInput formControlName="trackingNumber" />
        <mat-error>
          <app-form-error
            [fieldName]="'trackingNumber'"
            [formGroup]="sf"
          ></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>

<!--<pre>{{ shippingProvider | json }}</pre>-->
<!--<pre>{{ department | json }}</pre>-->
<!--   <pre>{{ sf.value | json }}</pre>-->
