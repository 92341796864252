import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_SERVICE = '[Master Data] Create Service';
export const CREATE_SERVICE_SUCCESS = '[Master Data] Create Service Success';
export const CREATE_SERVICE_FAIL = '[Master Data] Create Service Fail';
export const CreateService = createAction(
  CREATE_SERVICE,
  props<{ payload: fromModuleModels.Service }>()
);
export const CreateServiceSuccess = createAction(
  CREATE_SERVICE_SUCCESS,
  props<{ response: fromModuleModels.Service }>()
);
export const CreateServiceFail = createAction(
  CREATE_SERVICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SERVICES = '[Master Data] Read Services';
export const READ_SERVICES_SUCCESS = '[Master Data] Read Services Success';
export const READ_SERVICES_FAIL = '[Master Data] Read Services Fail';
export const ReadServices = createAction(
  READ_SERVICES
);
export const ReadServicesSuccess = createAction(
  READ_SERVICES_SUCCESS,
  props<{ response: any }>()
);
export const ReadServicesFail = createAction(
  READ_SERVICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_SERVICE = '[Master Data] Read Service';
export const READ_SERVICE_SUCCESS = '[Master Data] Read Service Success';
export const READ_SERVICE_FAIL = '[Master Data] Read Service Fail';
export const ReadService = createAction(
  READ_SERVICE,
  props<{ iri: string }>()
);
export const ReadServiceSuccess = createAction(
  READ_SERVICE_SUCCESS,
  props<{ response }>()
);
export const ReadServiceFail = createAction(
  READ_SERVICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_SERVICE = '[Master Data] Update Service';
export const UPDATE_SERVICE_SUCCESS = '[Master Data] Update Service Success';
export const UPDATE_SERVICE_FAIL = '[Master Data] Update Service Fail';
export const UpdateService = createAction(
  UPDATE_SERVICE,
  props<{ iri: string, payload: fromModuleModels.Service }>()
);
export const UpdateServiceSuccess = createAction(
  UPDATE_SERVICE_SUCCESS,
  props<{ response: fromModuleModels.Service }>()
);
export const UpdateServiceFail = createAction(
  UPDATE_SERVICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_SERVICE = '[Master Data] Delete Service';
export const DELETE_SERVICE_SUCCESS = '[Master Data] Delete Service Success';
export const DELETE_SERVICE_FAIL = '[Master Data] Delete Service Fail';
export const DeleteService = createAction(
  DELETE_SERVICE,
  props<{ iri: string }>()
);
export const DeleteServiceSuccess = createAction(
  DELETE_SERVICE_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteServiceFail = createAction(
  DELETE_SERVICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
