<app-loading-overlay
  *ngIf="isLoading$ | async"
></app-loading-overlay>
<table class="bmo-table bmo-table-hover table-dense bmo-table-clickable" mat-table [dataSource]="dataSource" matSort>
  <!-- Circle -->
  <ng-container
    matColumnDef="circle"
  >
    <th mat-header-cell *matHeaderCellDef></th>
    <td
      style="width: 50px;"
      class="pos-relative"
      *matCellDef="let element"
      [routerLink]="[
          element.ticket && '/tickets/'
          || element.order && '/orders/'
          || element.customer && '/customers/'
          || element.lead && '/leads/'
        ,
          element.ticket && extractUUID(element.ticket)
          || element.order && extractUUID(element.order)
          || element.customer && extractUUID(element.customer)
          || element.lead && extractUUID(element.lead)]"
    >
      <div class="circle" [class.yellow]="!element.feedbackRequestResolved"  [class.green]="element.feedbackRequestResolved"></div>

    </td>
  </ng-container>
  <!-- Type Column -->
  <ng-container
    matColumnDef="type"
  >
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"dashboard.feedback_requests.table_headings.type" | translate}} </th>
    <td mat-cell *matCellDef="let element"
        class="pos-relative"
        style="width: 50px;"
        [routerLink]="[
          element.ticket && '/tickets/'
          || element.order && '/orders/'
          || element.customer && '/customers/'
          || element.lead && '/leads/'
        ,
          element.ticket && extractUUID(element.ticket)
          || element.order && extractUUID(element.order)
          || element.customer && extractUUID(element.customer)
          || element.lead && extractUUID(element.lead)]">
      <span>
                {{ element['@type'] ? element['@type'].replace('Comment', '') : '-' }}
      </span>
    </td>
  </ng-container>
  <!-- Created At -->
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"dashboard.feedback_requests.table_headings.created_at" | translate}}</th>
    <td mat-cell *matCellDef="let element" style="width: 120px;" class="pos-relative"
        [routerLink]="[
          element.ticket && '/tickets/'
          || element.order && '/orders/'
          || element.customer && '/customers/'
          || element.lead && '/leads/'
        ,
          element.ticket && extractUUID(element.ticket)
          || element.order && extractUUID(element.order)
          || element.customer && extractUUID(element.customer)
          || element.lead && extractUUID(element.lead)]">
      <span>
          {{ element.createdAt ? (element.createdAt | date: 'short') : 'nie' }}
      </span>
    </td>
  </ng-container>
  <!-- Content Column -->
  <ng-container matColumnDef="content">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"dashboard.feedback_requests.table_headings.content" | translate}}</th>
    <td mat-cell *matCellDef="let element"
        [routerLink]="[
          element.ticket && '/tickets/'
          || element.order && '/orders/'
          || element.customer && '/customers/'
          || element.lead && '/leads/'
        ,
          element.ticket && extractUUID(element.ticket)
          || element.order && extractUUID(element.order)
          || element.customer && extractUUID(element.customer)
          || element.lead && extractUUID(element.lead)]"
    >
      <span [innerHTML]="element.content"></span>
    </td>
  </ng-container>
  <!-- Updated At -->
  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"dashboard.feedback_requests.table_headings.updated_at" | translate}}</th>
    <td mat-cell *matCellDef="let element" style="width: 120px;" class="pos-relative"
        [routerLink]="[
          element.ticket && '/tickets/'
          || element.order && '/orders/'
          || element.customer && '/customers/'
          || element.lead && '/leads/'
        ,
          element.ticket && extractUUID(element.ticket)
          || element.order && extractUUID(element.order)
          || element.customer && extractUUID(element.customer)
          || element.lead && extractUUID(element.lead)]"
    >
      <span>
          {{ element.updatedAt ? (element.updatedAt | date: 'short') : 'nie' }}
      </span>
    </td>
  </ng-container>

  <!-- Other columns -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<table class="bmo-table bmo-table-hover table-dense bmo-table-clickable no-entries" *ngIf="(feedbacks$ | async)?.length <= 0" style="padding: 20px 0;">
  <tr>
    <td class="text-center cursor-default">
      <span class="no-entries">Keine Elemente vorhanden</span>
    </td>
  </tr>
</table>
