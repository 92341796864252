<div class="lead-contact-form__wrapper px-2 pt-3">
  <div [formGroup]="lcf" class="lead-contact-form">
    <div class="grid">
      <div class="column-14 grid">
        <div class="mat-form-field column-11">
          <ng-select
            [clearable]="false"
            [items]="leadContactTypes$ | async"
            [loading]="leadContactTypesIsLoading$| async"
            [searchable]="false"
            bindLabel="name"
            bindValue="@id"
            formControlName="leadContactType">

            <ng-template let-item="item" ng-label-tmp>
              <mat-icon *ngIf="item.borderColor" [style.color]="item.borderColor" style="font-size: 22px;">
                circle
              </mat-icon>
              <div *ngIf="item.borderColor" class="color-text">{{ (item.name) }}</div>
            </ng-template>

            <ng-template let-item="item" ng-option-tmp>
              <mat-icon [style.color]="item.borderColor" style="font-size: 12px;">circle</mat-icon>
              <div class="color-text">{{ (item.name) }}</div>
            </ng-template>

          </ng-select>

          <mat-error>
            <app-form-error [fieldName]="'leadContactType'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </div>

        <div class="column-3" style="align-self: center;">
          <mat-slide-toggle checked="false" formControlName="vip">VIP</mat-slide-toggle>
        </div>

        <!-- Existing Customer -->

        <div class="column-14">

          <!--<span class="heading--h3 p-r--8">Kunde</span>
          <button class="button--new" (click)="handleRequestShowCustomerForm()" color="green" mat-button>
            <mat-icon>add</mat-icon>
          </button>-->
          <mat-button-toggle-group (change)="updateSelectedCustomerType($event.value)" aria-label="Art des Kunden"
                                   class="m-b--16 block small" formControlName="customerTypeToggle">
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else" value="SelectExistingCustomer">
              Bestandskunde
            </mat-button-toggle>
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else" value="NewCustomer">Neuer Kunde
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="column-14">
          <app-customer-select
            (selectCustomer)="selectCustomer($event)"
            [class.hidden]="lcf.get('customerTypeToggle').value === 'NewCustomer'"
            formControlName="customer"
            label="Bestandskunden wählen">
          </app-customer-select>


          <mat-error>
            <app-form-error [fieldName]="'customer'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </div>
        <div class="column-14">
          <ng-select
            [class.hidden]="lcf.get('customerTypeToggle').value === 'SelectExistingCustomer'"
            [clearable]="true"
            [items]="customerTypes$|async"
            [loading]="customerTypesIsLoading$| async"
            [markFirst]="true"
            bindLabel="customerType"
            bindValue="@id"
            formControlName="customerType"
            placeholder="Kunden-Typ">
          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'customerType'" [formGroup]="lcf"></app-form-error>
          </mat-error>
        </div>
        <div class="column-14">
          <ng-select
            [class.hidden]="lcf.get('customerTypeToggle').value === 'SelectExistingCustomer'"
            [clearable]="true"
            [items]="customerPartnerStatuses$|async"
            [loading]="customerPartnerStatusesIsLoading$ | async"
            bindLabel="name"
            bindValue="@id"
            formControlName="partnerStatus"
            placeholder="Kunden-Partner-Status">
          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'partnerStatus'" [formGroup]="lcf"></app-form-error>
          </mat-error>
        </div>

        <mat-form-field
          [class.hidden]="lcf.get('customerTypeToggle').value !== 'NewCustomer' || lcf.get('customerType').value !== '/api/customer_types/0'"
          class="column-14">
          <mat-label>Firmenname*</mat-label>
          <input formControlName="nameLine1" matInput type="text">
          <mat-error>
            <app-form-error [fieldName]="'nameLine1'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </mat-form-field>
        <div class="column-14">
          <!--          {{lcf.get('nameLine1')?.value|json}}-->
          <!--          {{(lcf.get('nameLine1')?.value || selectedCustomer?.nameLine1)|json}}-->
          <app-similar-customer-display
            (selectCustomer)="setCustomer($event)"
            *ngIf="lcf.get('nameLine1')?.value || selectedCustomer"
            [companyName]="lcf.get('nameLine1')?.value || selectedCustomer?.nameLine1"
            [hideCustomer]="selectedCustomer">
          </app-similar-customer-display>
        </div>

        <mat-form-field
          [class.hidden]="lcf.get('customerTypeToggle').value !== 'NewCustomer'  || lcf.get('customerType').value !== '/api/customer_types/0'"
          class="column-14">
          <mat-label>Namenszusatz</mat-label>
          <input formControlName="nameLine2" matInput type="text">
          <mat-error>
            <app-form-error [fieldName]="'nameLine2'" [formGroup]="lcf"></app-form-error>
          </mat-error>
        </mat-form-field>
        <!--        <div class="column-7">-->

        <!--          <ng-select-->
        <!--            class="hidden"-->
        <!--            [items]="customerPartnerStatuses$|async"-->
        <!--            bindLabel="name"-->
        <!--            [loading]="customerPartnerStatusesIsLoading$| async"-->
        <!--            bindValue="@id"-->
        <!--            [clearable]="true"-->
        <!--            [markFirst]="true"-->
        <!--            placeholder="Partner Status"-->
        <!--            formControlName="partnerStatus">-->
        <!--          </ng-select>-->
        <!--          <mat-error>-->
        <!--            <app-form-error [fieldName]="'partnerStatus'" [formGroup]="lcf"></app-form-error>-->
        <!--          </mat-error>-->

        <!--        </div>-->


        <mat-form-field *ngIf="lcf.get('customerType').value === '/api/customer_types/0'"
                        [class.hidden]="lcf.get('customerTypeToggle').value !== 'NewCustomer'"
                        class="column-14">
          <mat-label>Sonstige Informationen</mat-label>
          <textarea formControlName="customerInformation" matInput type="text"></textarea>
        </mat-form-field>


      </div>

      <div class="column-14 grid">

        <!-- New Customer Contact -->

        <span [class.hidden]="lcf.get('customerType').value === '/api/customer_types/1'"
              class="column-14">

            <span class="heading--h3 p-r--8">Ansprechpartner</span>
          </span>
        <div class="column-14">

          <mat-button-toggle-group (change)="updateSelectedCustomerContactType($event.value)"
                                   *ngIf="lcf.get('customerTypeToggle').value !== 'NewCustomer'"
                                   aria-label="Art des Kunden" class="m-b--16 block small"
                                   formControlName="customerContactTypeToggle">
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else"
                               value="SelectExistingCustomerContact">Bestehender
              Ansprechpartner
            </mat-button-toggle>
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else" value="NewCustomerContact">Neuer
              Ansprechpartner
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="mat-form-field column-14">

          <ng-select
            (change)="handleUpdateCustomerContactDetails($event)"
            [class.hidden]="lcf.get('customerContactTypeToggle').value === 'NewCustomerContact'"
            [items]="customerContacts$ | async"
            [loading]="customerContactsIsLoading$| async"
            [placeholder]="lcf.get('customer').value?'Ansprechpartner':'Bitte erst einen Kunden auswählen'"
            [searchable]="false"
            bindValue="@id"
            formControlName="decisionMakerCustomerContact">

            <ng-template let-item="item" ng-label-tmp>
              <span>{{ formatName(item) }}</span>
            </ng-template>

            <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
              <span>{{ formatName(item) }}</span>
              {{getUsedAsDefaultContact(item)}}
            </ng-template>
            <mat-error>
              <app-form-error [fieldName]="'decisionMakerCustomerContact'" [formGroup]="lcf"></app-form-error>
            </mat-error>

          </ng-select>
        </div>
        <div
          [class.hidden]="lcf.get('customerContactTypeToggle').value !== 'SelectExistingCustomerContact' || !lcf.get('decisionMakerCustomerContact').value"
          class="data-box container">
          <div class="row">
            <div class=" col data-block">
              <span class="key">Anrede</span>
              <span class="value">{{(getSalutation((selectedCustomerContact$|async)?.salutation)|async)?.name}}</span>
            </div>
            <div class="col data-block">
              <span class="key">Akademischer Grad</span>
              <span class="value">{{(getGrade((selectedCustomerContact$|async)?.grade)|async)?.name}}</span>

            </div>
          </div>
          <div class="row">
            <div class="col data-block">
              <span class="key">Vorname</span>
              <span class="value">{{(selectedCustomerContact$|async)?.firstName}}</span>
            </div>
            <div *ngIf="(selectedCustomerContact$|async)?.middleName" class="col data-block">
              <span class="key">Zweitname</span>
              <span class="value">{{(selectedCustomerContact$|async)?.middleName}}</span>
            </div>
            <div class="col data-block">
              <span class="key">Nachname</span>
              <span class="value">{{(selectedCustomerContact$|async)?.lastName}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col data-block">
              <span class="key">Telefon</span>
              <span class="value">{{(selectedCustomerContact$|async)?.phone || '-'}}</span>
            </div>
            <div class="col data-block">
              <span class="key">Mobil</span>
              <span class="value">{{(selectedCustomerContact$|async)?.mobile || '-'}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col data-block">
              <span class="key">E-Mail</span>
              <span class="value">{{(selectedCustomerContact$|async)?.email || '-'}}</span>
            </div>
          </div>


        </div>


        <div [class.hidden]="lcf.get('customerContactTypeToggle').value !== 'NewCustomerContact'"
             class="column-14 grid">
          <div class="mat-form-field column-7">
            <ng-select
              [clearable]="false"
              [items]="salutations$|async"
              [loading]="salutationsIsLoading$|async"
              [searchable]="false"
              bindLabel="name"
              bindValue="@id"
              formControlName="salutation"
              placeholder="Anrede*"
            ></ng-select>
            <mat-error>
              <app-form-error [fieldName]="'salutation'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </div>

          <div class="mat-form-field column-7">
            <ng-select
              [clearable]="true"
              [items]="grades$|async"
              [loading]="gradesIsLoading$|async"
              [searchable]="false"
              bindLabel="name"
              bindValue="@id"
              formControlName="grade"
              placeholder="Akademischer Grad"
            ></ng-select>
            <mat-error>
              <app-form-error [fieldName]="'grade'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </div>

          <mat-form-field class="column-5">
            <mat-label>Vorname*</mat-label>
            <input formControlName="firstName" matInput type="text">
            <mat-error>
              <app-form-error [fieldName]="'firstName'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-4">
            <mat-label>Zweitname</mat-label>
            <input formControlName="middleName" matInput type="text">
            <mat-error>
              <app-form-error [fieldName]="'middleName'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="column-5">
            <mat-label>Nachname*</mat-label>
            <input formControlName="lastName" matInput type="text">
            <mat-error>
              <app-form-error [fieldName]="'lastName'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Festnetz</mat-label>
            <ngx-11-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="false"
              [preferredCountries]="['de', 'us']"
              formControlName="phone"
              name="phone"
            ></ngx-11-mat-intl-tel-input>
            <!--<input type="tel" mask="0*" formControlName="phone" >-->
            <mat-error>
              <app-form-error [fieldName]="'phone'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Mobilnummer</mat-label>
            <ngx-11-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="false"
              [preferredCountries]="['de', 'us']"
              formControlName="mobile"
              name="mobile"
            ></ngx-11-mat-intl-tel-input>
            <mat-error>
              <app-form-error [fieldName]="'mobile'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>E-Mail-Adresse*</mat-label>
            <input formControlName="email" matInput type="email">
            <mat-error>
              <app-form-error [fieldName]="'email'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </mat-form-field>
          <div class="column-14">
            <div class="mat-form-field">
              <mat-label>Marketing-Erlaubnis</mat-label>
              <mat-button-toggle-group [multiple]="true"
                                       aria-label="Marketing-Erlaubnis"
                                       class="block small"
                                       formControlName="marketingPermissions">
                <mat-button-toggle (click)="changeMarketingPermissions('marketingPermissionEmail')"
                                   class="green-if-checked"
                                   value="marketingPermissionEmail">
                  <mat-icon class="">alternate_email</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle (click)="changeMarketingPermissions('marketingPermissionPhone')"
                                   class="green-if-checked"
                                   value="marketingPermissionPhone">
                  <mat-icon class="">phone</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle (click)="changeMarketingPermissions('marketingPermissionPostal')"
                                   class="green-if-checked"
                                   value="marketingPermissionPostal">
                  <mat-icon class="">local_post_office</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle (click)="changeMarketingPermissions('marketingPermissionNone')"
                                   class="red-if-checked"
                                   value="marketingPermissionNone">
                  <mat-icon class="">block</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="lcf" fieldName="marketingPermissions"></app-form-error>
              </mat-error>
            </div>
          </div>
          <mat-form-field *ngIf="lcf.get('customerType').value === '/api/customer_types/1'"
                          [class.hidden]="lcf.get('customerTypeToggle').value !== 'NewCustomer'"
                          class="column-14">
            <mat-label>Sonstige Informationen</mat-label>
            <textarea formControlName="customerInformation" matInput type="text"></textarea>
          </mat-form-field>
          <!--<div class="blocker"></div>-->
        </div>
        <div class="column-14">

          <ng-select
            [clearable]="true"
            [items]="locales$|async"
            [loading]="localesIsLoading$| async"
            [markFirst]="true"
            bindLabel="name"
            bindValue="locale"
            formControlName="locale"
            placeholder="Sprache">
          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'locale'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </div>

        <!-- Billing Address -->

        <div class="column-14">
          <span class="heading--h3 p-r--8">Rechnungsanschrift</span>
        </div>
        <div class="column-14">

          <mat-button-toggle-group (change)="updateSelectedCustomerBillingType($event.value)"
                                   *ngIf="lcf.get('customerTypeToggle').value !== 'NewCustomer'"
                                   aria-label="Art der Rechnungsanschrift" class="m-b--16 block small"
                                   formControlName="customerBillingAddressTypeToggle">
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else"
                               value="SelectExistingBillingAddress">Bestehende Adresse
            </mat-button-toggle>
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else"
                               value="NewCustomerBillingAddress">
              Neue Adresse
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div [class.hidden]="lcf.get('customerBillingAddressTypeToggle').value !== 'SelectExistingBillingAddress'"
             class="mat-form-field column-14">
          <ng-select
            [items]="customerBillingAddresses$ | async"
            [loading]="customerAddressesIsLoading$ | async"
            [placeholder]="lcf.get('customer').value?'Rechnungsadresse':'Bitte erst einen Kunden auswählen'"
            [searchable]="false"
            bindValue="@id"
            formControlName="customerBillingAddress">

            <ng-template let-item="item" ng-label-tmp>
              <span>{{ formatAddress(item) }}</span>
            </ng-template>

            <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
              <span>{{ formatAddress(item) }}</span>
            </ng-template>

          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'customerBillingAddress'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </div>

        <!-- [class.disabled]="!!!ccf.get('customerAddress').value" -->
        <div [class.hidden]="lcf.get('customerBillingAddressTypeToggle').value !== 'NewCustomerBillingAddress'  "
             class="column-14 grid">

          <ng-container formGroupName="billingAddress">
            <div class="mat-form-field column-14">

              <ng-select
                [clearable]="false"
                [items]="addressTypes$|async"
                [loading]="addressTypesIsLoading$|async"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="addressType"
                placeholder="AddressTyp"
              ></ng-select>
              <mat-error>
                <app-form-error [fieldName]="'billingAddress.addressType'" [formGroup]="lcf"></app-form-error>
              </mat-error>
            </div>

            <ng-container formGroupName="address">

              <mat-form-field class="column-10">
                <mat-label>Straße*</mat-label>
                <input formControlName="line1" matInput type="text">
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.line1'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-4">
                <mat-label>Hausnummer</mat-label>
                <input formControlName="line2" matInput required type="text">
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.line2'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-14">
                <mat-label>c/o, Adresszusatz</mat-label>
                <input formControlName="line3" matInput type="text">
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.line3'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>PLZ</mat-label>
                <input formControlName="zipPostcode" matInput required type="text">
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.zipPostcode'"
                                  [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-9">
                <mat-label>Stadt</mat-label>
                <input formControlName="city" matInput required type="text">
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.city'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <div class="mat-form-field column-14">

                <ng-select
                  [clearable]="false"
                  [items]="countries$|async"
                  [loading]="countriesIsLoading$|async"
                  [searchable]="true"
                  bindLabel="name"
                  bindValue="code"
                  formControlName="country"
                  placeholder="Land*"
                ></ng-select>
                <mat-error>
                  <app-form-error [fieldName]="'billingAddress.address.country'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </div>
            </ng-container>
          </ng-container>

          <!--<div class="blocker"></div>-->
        </div>
        <div class="column-14">
            <span [class.text-color-red]="lcf.get('customerDeliveryAddressTypeToggle').invalid"
                  class="heading--h3 p-r--8">Lieferanschrift</span>
        </div>
        <div class="column-14 mat-form-field">

          <mat-button-toggle-group (change)="updateSelectedCustomerDeliveryType($event.value)"
                                   aria-label="Art der Lieferanschrift" class="m-b--16 block small"
                                   formControlName="customerDeliveryAddressTypeToggle">
            <mat-button-toggle
              *ngIf="lcf.get('customerBillingAddressTypeToggle').value !== 'SelectExistingBillingAddress'"
              class="bold-if-checked white-if-checked lightgrey-else"
              value="sameAsBillingAddress">Gleich
              Rechnungsadresse
            </mat-button-toggle>
            <mat-button-toggle *ngIf="lcf.get('customerTypeToggle').value !== 'NewCustomer'"
                               class="bold-if-checked white-if-checked lightgrey-else"
                               value="SelectExistingDeliveryAddress">Bestehende
              Adresse
            </mat-button-toggle>
            <mat-button-toggle class="bold-if-checked white-if-checked lightgrey-else"
                               value="NewCustomerDeliveryAddress">Neue Adresse
            </mat-button-toggle>
            <app-form-error *ngIf="lcf.get('customerDeliveryAddressTypeToggle').invalid"
                            [fieldName]="'customerBillingAddressTypeToggle'" [formGroup]="lcf"></app-form-error>

          </mat-button-toggle-group>
        </div>

        <div [class.hidden]="lcf.get('customerDeliveryAddressTypeToggle').value !== 'SelectExistingDeliveryAddress'"
             class="mat-form-field column-14">
          <ng-select
            [items]="customerDeliveryAddresses$ | async"
            [loading]="customerAddressesIsLoading$ | async"
            [placeholder]="lcf.get('customer').value?'Lieferadresse':'Bitte erst einen Kunden auswählen'"
            [searchable]="false"
            bindValue="@id"
            formControlName="customerDeliveryAddress">

            <ng-template let-item="item" ng-label-tmp>
              <span>{{ formatAddress(item) }}</span>
            </ng-template>

            <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
              <span>{{ formatAddress(item) }}</span>
            </ng-template>

          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'customerDeliveryAddress'" [formGroup]="lcf"></app-form-error>
          </mat-error>

        </div>
        <div [class.hidden]="lcf.get('customerDeliveryAddressTypeToggle').value !== 'NewCustomerDeliveryAddress'  "
             class="column-14 grid">

          <ng-container formGroupName="deliveryAddress">
            <div class="mat-form-field column-14">

              <ng-select
                [clearable]="false"
                [items]="addressTypes$|async"
                [loading]="countriesIsLoading$|async"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="addressType"
                placeholder="AddressTyp"
              ></ng-select>
              <mat-error>
                <app-form-error [fieldName]="'deliveryAddress.addressType'" [formGroup]="lcf"></app-form-error>
              </mat-error>
            </div>

            <ng-container formGroupName="address">

              <mat-form-field class="column-10">
                <mat-label>Straße*</mat-label>
                <input formControlName="line1" matInput type="text">
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.line1'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-4">
                <mat-label>Hausnummer</mat-label>
                <input formControlName="line2" matInput type="text">
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.line2'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-14">
                <mat-label>c/o, Adresszusatz</mat-label>
                <input formControlName="line3" matInput type="text">
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.line3'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>PLZ</mat-label>
                <input formControlName="zipPostcode" matInput required type="text">
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.zipPostcode'"
                                  [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-9">
                <mat-label>Stadt</mat-label>
                <input formControlName="city" matInput required type="text">
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.city'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </mat-form-field>

              <div class="mat-form-field column-14">

                <ng-select
                  [clearable]="false"
                  [items]="countries$|async"
                  [loading]="countriesIsLoading$|async"
                  [searchable]="true"
                  bindLabel="name"
                  bindValue="code"
                  formControlName="country"
                  placeholder="Land*"
                ></ng-select>
                <mat-error>
                  <app-form-error [fieldName]="'deliveryAddress.address.country'" [formGroup]="lcf"></app-form-error>
                </mat-error>
              </div>
            </ng-container>
          </ng-container>

          <!--<div class="blocker"></div>-->
        </div>

        <div class="column-14">
          <span class="heading--h3 p-r--8">Erreichbarkeit & Vermittler</span>
        </div>

        <ng-container [formGroup]="lcf">

          <div class="column-14">
            <mat-form-field>
              <mat-label>Vermerke zur Erreichbarkeit</mat-label>
              <input formControlName="availability" matInput type="text">
              <mat-error>
                <app-form-error [fieldName]="'availability'" [formGroup]="lcf"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="mat-form-field column-14">
            <app-customer-select
              (selectCustomer)="selectBroker($event)"
              formControlName="broker"
              label="Vermittler wählen">
            </app-customer-select>
            <mat-error>
              <app-form-error [fieldName]="'broker'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </div>
          <div class="mat-form-field column-14">
            <ng-select
              [clearable]="true"
              [items]="brokerContacts$|async"
              [loading]="brokerContactsIsLoading$| async"
              [searchFn]="findBrokerContact"
              [searchable]="true"
              bindLabel="name"
              bindValue="@id"
              formControlName="brokerContact"
              placeholder="Vermittler Kontakt wählen">

              <ng-template let-item="item" ng-label-tmp>
                  <span *ngIf="item['@type'] == 'PartnerWebsite'">
                    <strong>W:</strong>
                    {{item.website}}
                  </span>
                <span *ngIf="item['@type'] == 'CustomerAccount'">
                    <strong>P:</strong>
                  {{item.firstName}} {{item.lastName}}
                  <span *ngIf="item.email"> ({{item.email}})</span>
                  </span>
              </ng-template>

              <ng-template let-item="item" ng-option-tmp>
                  <span *ngIf="item['@type'] === 'PartnerWebsite'">
                    <strong>W:</strong>
                    {{item.website}}
                  </span>
                <span *ngIf="item['@type'] === 'CustomerAccount'">
                    <strong>P:</strong>
                  {{item.firstName}} {{item.lastName}}
                  <span *ngIf="item.email"> ({{item.email}})</span>
                  </span>
              </ng-template>

            </ng-select>
            <mat-error>
              <app-form-error [fieldName]="'brokerContact'" [formGroup]="lcf"></app-form-error>
            </mat-error>
          </div>
          <div class="mat-form-field column-14" *ngIf="!!lcf.get('broker').value">
            <mat-checkbox formControlName="brokeredToPartner">Vermittelt an Partner</mat-checkbox>
            <small class="mat-error">
              <app-form-error [fieldName]="'brokeredToPartner'" [formGroup]="lcf"></app-form-error>
            </small>
          </div>
          <div class="mat-form-field column-14"
             *ngIf="
               !!lcf.get('broker').value && this.isBrokeredToPartnerChecked && (brokerContactAddresses$ | async).length > 0">
            <div class="mat-form-field column-14">
              <ng-select
                [items]="brokerContactAddresses$ | async"
                [loading]="brokerContactAddressesIsLoading$ | async"
                [placeholder]="'Partner Abgabestellen'"
                [searchable]="false"
                bindValue="@id"
                formControlName="partnerBranchOfficeAddress">

                <ng-template let-item="item" ng-label-tmp>
                  <span>{{ formatAddress(item) }}</span>
                </ng-template>

                <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
                  <span>{{ formatAddress(item) }}</span>
                </ng-template>

              </ng-select>
              <mat-error>
                <app-form-error [fieldName]="'partnerBranchOfficeAddress'" [formGroup]="lcf"></app-form-error>
              </mat-error>

            </div>
          </div>
          <div *ngIf="this.lcf.get('brokeredToPartner').value && selectedBroker && (brokerContactAddresses$ | async)?.length === 0">
            <p>{{"leads.leads_contact_form.no_broker_address_placeholder.no_partner" | translate}} <a [routerLink]="['/customers', extractUUID(selectedBroker)]">{{"leads.leads_contact_form.no_broker_address_placeholder.define_address" | translate}}</a></p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!--<pre>{{ lcf.value | json }}</pre>-->
<!--<pre>{{ searchResults$ | async | json | json }}</pre>-->
<!--<pre>ccf.valid: {{ ccf.valid | json }}</pre>-->
<!--<pre>addresses$: {{ customerAddresses$ | async | json }}</pre>-->
<!--<pre>isAddressBlockValid: {{ isAddressBlockValid() | json }}</pre>-->
<!--<pre>isCustomerContactBlockValid: {{ isCustomerContactBlockValid() | json }}</pre>-->
