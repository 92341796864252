import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, NgZone} from '@angular/core';
@Injectable()
export class AbstractMercureService {
  BASE_URL = environment.baseUrl + '/.well-known/mercure';
  API_URL = environment.apiUrl;

  eventSource: EventSource;
  private subscriptions: { [key: string]: BehaviorSubject<any> } = {};
  topics = [
    '/api/leads/{uuid}'
  ];

  constructor(private zone: NgZone) {
    this.connect();
  }

  private connect(): void {
    const u = new URL(this.BASE_URL);

    u.searchParams.append(
      'topic',
      '*'
    );
    this.eventSource = new EventSource(u.toString(), {withCredentials: true});
    this.eventSource.onerror = (error) => {
      console.error(error);
    };
    this.eventSource.onopen = (things) => {
      console.log(things);
    }
    this.eventSource.onmessage = ({data}) => {
      console.log('Message:', data);
    };

  }

  close(): void {
    this.eventSource.close();
  }

  createEventSource(): Observable<any> {
    return new Observable(observer => {
      this.eventSource.onmessage = ({data}) => {
        observer.next(JSON.parse(data));
      };
    });
  }

  addSubscription(topic: string): BehaviorSubject<any> {
    if (!this.subscriptions[topic]) {
      this.subscriptions[topic] = new BehaviorSubject<any>(null);
    }
    return this.subscriptions[topic];
  }
}
