import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerContactTypesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getCustomerContactType(uuid: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CONTACT_TYPE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/api/customer_contact_types/${uuid}`,
              true
            )
    );
  }

  getCustomerContactTypes(): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CONTACT_TYPE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/customer_contact_types`)
    );
  }
}
