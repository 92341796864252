import { CustomerViewComponent } from './customer-view/customer-view.component';
import { CustomersViewComponent } from './customers-view/customers-view.component';

export const AllContainers = [
  CustomersViewComponent,
  CustomerViewComponent
];

export * from './customer-view/customer-view.component';
export * from './customers-view/customers-view.component';
