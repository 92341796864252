import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ROLES = '[Master Data] Read Roles';
export const READ_ROLES_SUCCESS = '[Master Data] Read Roles Success';
export const READ_ROLES_FAIL = '[Master Data] Read Roles Fail';
export const ReadRoles = createAction(
  READ_ROLES
);
export const ReadRolesSuccess = createAction(
  READ_ROLES_SUCCESS,
  props<{ response }>()
);
export const ReadRolesFail = createAction(
  READ_ROLES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
