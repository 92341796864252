import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_ADDRESS_TYPE = '[Master Data] Read Address Type';
export const READ_ADDRESS_TYPE_SUCCESS = '[Master Data] Read Address Type Success';
export const READ_ADDRESS_TYPE_FAIL = '[Master Data] Read Address Type Fail';

export const ReadAddressType = createAction(
  READ_ADDRESS_TYPE,
  props<{ iri: string }>()
);

export const ReadAddressTypeSuccess = createAction(
  READ_ADDRESS_TYPE_SUCCESS,
  props<{ response: fromModuleModels.AddressType }>()
);

export const ReadAddressTypeFail = createAction(
  READ_ADDRESS_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_ADDRESS_TYPES = '[Master Data] Read Address Types';
export const READ_ADDRESS_TYPES_SUCCESS = '[Master Data] Read Address Types Success';
export const READ_ADDRESS_TYPES_FAIL = '[Master Data] Read Address Types Fail';

export const ReadAddressTypes = createAction(
  READ_ADDRESS_TYPES
);

export const ReadAddressTypesSuccess = createAction(
  READ_ADDRESS_TYPES_SUCCESS,
  props<{ response: any }>()
);

export const ReadAddressTypesFail = createAction(
  READ_ADDRESS_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
