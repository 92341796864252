import {Component, Input} from '@angular/core';

import * as fromInvoiceModuleModels from '../../models';

@Component({
  selector: 'app-invoice-total',
  styleUrls: ['invoice-total.component.scss'],
  template: `

    <!-- Net Total -->

    <div class="grid grid-no-gutter" *ngIf="invoice && !!invoice.items?.length">
      <div class="block column-9">Summe netto:</div>
      <div class="block column-5">
        <ng-container *ngIf="invoice">
          {{ invoice.netTotal.value | number:'1.2-2' }} {{ invoice.netTotal.currency|currencyShortener }}
        </ng-container>
      </div>
      <!--      <ng-container *ngIf="hasDiscounts">-->
      <!--        <div class="block column-9 text-color-grey">Gesamtrabatt:</div>-->
      <!--        <div class="block column-5 text-color-grey">-->
      <!--          <ng-container *ngIf="invoice">-->
      <!--            {{ amountDiscount | number:'1.2-2' }} {{ invoice.netTotal.currency|currencyShortener }}-->
      <!--          </ng-container>-->
      <!--        </div>-->

      <!--      </ng-container>-->

      <!-- VAT ( CancellationInvoice is missing taxesTotal property ) -->

      <ng-container *ngIf="!!invoice?.taxesTotal?.length && !isCancellationInvoice(invoice)">
        <ng-container *ngFor="let taxes of invoice.taxesTotal">

          <div class="block column-9">{{ taxes.tax.name }}:
          </div>

          <div class="block column-5">
            {{ taxes.amount.value | number:'1.2-2' }} {{ taxes.amount.currency|currencyShortener }}</div>
        </ng-container>
      </ng-container>

      <!-- Gross Total -->

      <div class="block column-9 second-last">{{ getInvoiceTotalLabel(invoice) }}:</div>
      <div class="block column-5 second-last">
        {{ invoice?.grossTotal?.value | number:'1.2-2' }} {{ invoice?.grossTotal?.currency|currencyShortener }}
      </div>


      <div class="block last p-t--12 column-9">
        {{ getInvoiceSummaryLineLabel(invoice)  }}:
      </div>
      <div class="block last column-5 p-t--12">
        {{ invoice?.grossTotal?.value | number:'1.2-2' }} {{ invoice?.grossTotal?.currency|currencyShortener }}
      </div>
      <div class="row">
        <!--        <pre>{{invoice.relatedPayments|json}}</pre>-->

        <p class="text-color-grey text-right"
           *ngFor="let relatedPayment of invoice.relatedPayments|values">
          {{relatedPayment?.amount?.value|number:'1.2-2'}} {{ relatedPayment?.amount?.currency|currencyShortener }}
          {{relatedPayment?.amount?.value.indexOf('-') > -1 ? 'zurückgezahlt' : 'gezahlt'}}
          am {{relatedPayment?.receivedAt|momentDate}}
          <!-- TODO Fill InvoiceNumber
          - Abschlagsrechnung {{relatedPayment}}-->
        </p>
      </div>
    </div>

    <!--<pre>{{ invoice | json }}</pre>-->
  `
})
export class InvoiceTotalComponent {

  @Input() invoice: fromInvoiceModuleModels.InvoiceLike;

  get hasDiscounts(): boolean {
    return this.invoice.items.some(e => e.netDiscountAmount?.value && parseFloat(e.netDiscountAmount.value) !== 0.0);
  }

  get amountDiscount(): number {
    let count = 0;
    this.invoice.items.forEach((e) => {
      if (e.netDiscountAmount) {
        count += parseFloat(e.netDiscountAmount.value);
      }
    });
    return count;
  }

  isCancellationInvoice(invoice?: fromInvoiceModuleModels.InvoiceLike): boolean {
    return invoice && (invoice['@type'] === 'CancellationInvoice');
  }

  getInvoiceTotalLabel(invoice: fromInvoiceModuleModels.InvoiceLike): string {

    return {
      Invoice: 'Rechnungssumme',
      PartialInvoice: 'Rechnungssumme',
      CancellationInvoice: 'Summe des Storno-Rechnungsbetrags',
      Offer: 'Angebotsbetrag',
      CommissionCredit: 'Insgesamt',
      CorrectionInvoice: 'Zwischensumme',
    }[invoice['@type']];
  }

  getInvoiceSummaryLineLabel(invoice: fromInvoiceModuleModels.InvoiceLike): string {
    return {
      Invoice: 'Zu zahlender Betrag',
      PartialInvoice: 'Zu zahlender Betrag',
      CancellationInvoice: 'Storno-Rechnungsbetrag',
      Offer: 'Insgesamt',
      CommissionCredit: 'Insgesamt',
      CorrectionInvoice: 'Insgesamt',
    }[invoice['@type']];
  }
}
