import { createReducer, on } from '@ngrx/store';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import { StorageLocationsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromMasterDataModuleModels.StorageLocation };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    StorageLocationsActions.CreateStorageLocation,
    StorageLocationsActions.ReadStorageLocation,
    StorageLocationsActions.ReadStorageLocations,
    StorageLocationsActions.UpdateStorageLocation,
    StorageLocationsActions.DeleteStorageLocation,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    StorageLocationsActions.ReadStorageLocationSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    StorageLocationsActions.ReadStorageLocationsSuccess,
    StorageLocationsActions.ReadStorageLocationsChunkSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      const totalItems = response['hydra:totalItems'];
      let pagination = {};
      const entities = items.reduce(
        (
          entities: {
            [iri: string]: fromMasterDataModuleModels.StorageLocation;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        // Note: Intentionally "clear" state when returning from single view or navigating tru list view
        {}
        // {...state.entities}
      );
      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        pagination,
        totalItems,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    StorageLocationsActions.CreateStorageLocationSuccess,
    StorageLocationsActions.UpdateStorageLocationSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(StorageLocationsActions.DeleteStorageLocationSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    StorageLocationsActions.CreateStorageLocationFail,
    StorageLocationsActions.DeleteStorageLocationFail,
    StorageLocationsActions.ReadStorageLocationFail,
    StorageLocationsActions.ReadStorageLocationsChunkFail,
    StorageLocationsActions.ReadStorageLocationsFail,
    StorageLocationsActions.UpdateStorageLocationFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
