import {createSelector} from '@ngrx/store';

import {CUSTOMERS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';
import {Customer} from '../../models';
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";

export const selectCustomersState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMERS_FEATURE_KEY]
);

export const selectCustomersEntities = createSelector(
  selectCustomersState,
  (state) => state.entities
);

export const selectCustomers = createSelector(
  selectCustomersEntities,
  (customers) => Object.values(customers)
);
export const sByUUID = createSelector(
  selectCustomers,
  (customers, {uuid}) => customers.find(e => getUuidFromIri(e['@id']) === uuid)
);
export const selectBrokers = createSelector(
  selectCustomers,
  (customers) => customers.filter(e => e.partnerStatus !== '/api/customer_partner_statuses/0')
);

export const selectCustomerByIndex = createSelector(
  selectCustomersEntities,
  (customers, {iri}) => customers[iri]
);

export const sByCustomerNumber = createSelector(
  selectCustomers,
  (customers, {customerNumber}) => customers.find((e: Customer) => e.customerNumber === customerNumber)
);

export const selectCurrentCustomer = createSelector(
  selectCustomersState,
  (state) => state.current
);

export const selectCurrentPartner = createSelector(
  selectCustomersState,
  (state) => state.currentPartner
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectCustomersState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectCustomersEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectCustomersState,
  state => state.totalItems
);
export const selectCustomerPagination = createSelector(
  selectCustomersState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectCustomerPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectCustomerPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectCustomerPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectCustomerPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectCustomerPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCustomersState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCustomersState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomersState,
  (state) => state.errors
);
