import {createSelector} from '@ngrx/store';

import {selectShippingModuleState, SHIPMENTS_FEATURE_KEY} from '../reducers';

export const selectShipmentsState = createSelector(
  selectShippingModuleState,
  (moduleState) => moduleState[SHIPMENTS_FEATURE_KEY]
);

export const selectShipmentsEntities = createSelector(
  selectShipmentsState,
  (state) => state.entities
);

export const selectShipments = createSelector(
  selectShipmentsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectShipmentsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectShipmentsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectShipmentsState,
  (state) => state.loaded
);
