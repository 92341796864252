import {Component, Input, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {Order} from '../../../orders/models';
import {PotentialAction} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {filter, map, switchAll, takeUntil} from 'rxjs/operators';
import {TransitionsActions} from '../../../application-state/store/actions';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {ActionBoxInputTypes} from '../../models/action-box-input-types.type';
import {AuthService} from '../../../auth/services/auth.service';
import {OrdersService, ProductOrderItemsService} from "../../../orders/services";
import {CustomerDataMedium, ReplacementDataMedia, ReplacementDataMedium} from "../../../warehouse/models";
import {CustomerDataMediumsService, ReplacementDataMediumsService} from "../../../warehouse/services";

@Component({
  selector: 'app-action-box-copy-data-to-data-copied',
  styleUrls: ['./action-box-copy-data-to-data-copied.component.scss'],
  template: `
    <div class="action-box">
      <div class="position-absolute" style="right: 20px; top: 10px;" *ngIf="isLoading">
        <app-loading-overlay overlay="false" size="20"></app-loading-overlay>
      </div>
      <div class="action-required-label" *ngIf="(canMakeTransition | async)">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!(canMakeTransition | async)">
        Warte auf Zuarbeit
      </div>
      <div class="content">
        <div class="row" *ngIf="(canMakeTransition | async)">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Daten auf Ersatzdatenträger kopiert?
              </div>
              <div class="sub-header col-auto">
                Sind die Daten kopiert worden?
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition()"
              [disabled]="!okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!(canMakeTransition | async)">
          <div class=" col-auto header">Daten-Kopie:</div>
          <div class="sub-header col-auto">
            Warte auf Kopie der Daten auf den Ersatzdatenträger
          </div>
        </div>
      </div>
      <div class="content replacement-data-mediums-inner"
           *ngIf="order?.replacementDataMedia.length > 0 && (canMakeTransition | async)">
        <div class="header col-auto">
          Hinzugefügte Ersatzdatenträger
        </div>
        <div class="row replacement-data-mediums">
          <table
            mat-table
            [dataSource]="order?.replacementDataMedia">

            <!-- Data Replacement Media Number Column -->
            <ng-container matColumnDef="storageSystemSerialNumber">
              <th mat-header-cell *matHeaderCellDef>Nr.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.storageSystemSerialNumber }}
              </td>
            </ng-container>

            <!-- Data Replacement Storage Number Column -->
            <ng-container matColumnDef="storageNumber">
              <th mat-header-cell *matHeaderCellDef>Einl-Nr.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.storageNumber }}
              </td>
            </ng-container>

            <!-- Data Replacement Storage Type -->
            <ng-container matColumnDef="storageType">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{ isCustomerDataMedium(element) ? element.stockItem?.storageSystemType?.name : element.stockItem?.product?.storageSystemType?.name }}
              </td>
            </ng-container>

            <!-- Data Replacement Storage Manufacturer -->
            <ng-container matColumnDef="manufacturer">
              <th mat-header-cell *matHeaderCellDef>Hersteller</th>
              <td mat-cell *matCellDef="let element">
                {{ isCustomerDataMedium(element) ? element?.storageSystemManufacturer?.name : element.stockItem?.product?.storageSystemManufacturer?.name }} GB
              </td>
            </ng-container>

            <!-- Data Replacement Storage Memory Size -->
            <ng-container matColumnDef="memorySize">
              <th mat-header-cell *matHeaderCellDef>Speichergröße</th>
              <td mat-cell *matCellDef="let element">
                {{ isCustomerDataMedium(element) ? element?.storageSystemSize : element.stockItem?.product?.storageSystemSize }} GB
              </td>
            </ng-container>

            <!-- Data Replacement Storage Serial Number -->
            <ng-container matColumnDef="serialNumber">
              <th mat-header-cell *matHeaderCellDef>Seriennummer</th>
              <td mat-cell *matCellDef="let element">
                {{ element.storageSystemSerialNumber }}
              </td>
            </ng-container>

            <!-- Data Replacement Storage Provider-->
            <ng-container matColumnDef="storageProvider">
              <th mat-header-cell *matHeaderCellDef>BEREITSTELLUNG VON</th>
              <td mat-cell *matCellDef="let element">
                {{ isCustomerDataMedium(element) ? 'Kunde' : 'DATAREVERSE '}}
              </td>
            </ng-container>

            <!-- Data Replacement Storage Password-->
            <ng-container matColumnDef="storagePassword">
              <th mat-header-cell *matHeaderCellDef>Passwort</th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field>
                  <mat-label>Passwort</mat-label>
                  <input type="text" matInput #passwordInput name="password"
                         [disabled]="!(canEditRKeyAndPassword | async)"
                         [value]="element.password ? element.password : ''"
                         (blur)="updateReplacementStorage(element, passwordInput)"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Data Replacement Storage Recovery Key-->
            <ng-container matColumnDef="recoveryKey">
              <th mat-header-cell *matHeaderCellDef>Recovery Key</th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field>
                  <mat-label>Wiederherstellungsschlüssel</mat-label>
                  <input type="text" matInput
                         #recoveryKey
                         name="recoveryKey"
                         [disabled]="!(canEditRKeyAndPassword | async)"
                         [value]="element.recoveryKey ? element.recoveryKey : ''"
                         (blur)="updateReplacementStorage(element, recoveryKey)"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Data Replacement Storage Recovery Key-->
            <ng-container matColumnDef="deleteReplacementStorage">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button aria-label="Delete" (click)="deleteReplacementStorage(element)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxCopyDataToDataCopiedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  displayedColumns: Array<string> = [
    'storageSystemSerialNumber',
    'storageNumber',
    'storageType',
    'manufacturer',
    'memorySize',
    'serialNumber',
    'storageProvider',
    'storagePassword',
    'recoveryKey',
    'deleteReplacementStorage'
  ];

  order: Order;
  isLoading: boolean = false;
  okAction: PotentialAction = null;

  constructor(
    private authService: AuthService,
    private ordersService: OrdersService,
    private store$: Store<ApplicationState>,
    private customerDataMediumService: CustomerDataMediumsService,
    private replacementDataMediumService: ReplacementDataMediumsService,
    private productOrderItemService: ProductOrderItemsService
  ) {
    super();
  }

  get canMakeTransition(): Observable<boolean> {
    return combineLatest([
      this.authService.isTechnician$,
      this.authService.isSupervisor$,
      this.authService.isAdmin$,
    ]).pipe(
      map(([isTechnician, isSupervisor, isAdmin]) =>
        this.inputType === 'ticket' && (isTechnician || isSupervisor || isAdmin)
      )
    );
  }


  get canEditRKeyAndPassword(): Observable<boolean> {
    return combineLatest([
      this.authService.isTechnician$,
      this.authService.isAdmin$,
    ]).pipe(
      map(([isTechnician, isAdmin]) =>
        this.inputType === 'ticket' && (isTechnician || isAdmin)
      )
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        console.log(this.order?.replacementDataMedia);
        this.okAction = order && order.potentialActions?.find(
          e => (e.transition.indexOf('copy_data_to_data_copied') > -1 || e.transition === 'copy_data_to_data_copied') /*&& !e.error*/
        );
      });
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({uri, payload}));
  }

  deleteReplacementStorage(element: ReplacementDataMedia): void {
    this.isLoading = true;
    if (this.isCustomerDataMedium(element)) {
      this.customerDataMediumService.deleteCustomerDataMedium(element['@id']).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
          this.ordersService.getOrderByIri(this.order['@id']).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe(
            order => {
              this.order = order;
              this.isLoading = false;
            },
            _ => {
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            }
          );
        },
        _ => {
          this.isLoading = false;
        }
      );
    } else {
      this.productOrderItemService.deleteProductOrderItem(element.orderItem).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
          this.ordersService.getOrderByIri(this.order['@id']).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe(
            order => {
              this.order = order;
              this.isLoading = false;
            },
            _ => {
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            }
          );
        },
        _ => {
          this.isLoading = false;
        }
      );
    }
  }

  updateReplacementStorage(element: ReplacementDataMedia, htmlInputElement: HTMLInputElement): void {
    console.log(htmlInputElement.value, htmlInputElement.name);
    const payload: CustomerDataMedium = htmlInputElement.name === 'password'
      && {password: htmlInputElement.value}
      || htmlInputElement.name === 'recoveryKey'
      && {recoveryKey: htmlInputElement.value};

    this.isLoading = true;
    if (this.isCustomerDataMedium(element)) {
      this.customerDataMediumService.update(element['@id'], payload).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
          this.isLoading = false;
        },
        _ => {
          this.isLoading = false;
        }
      );
    } else {
      this.replacementDataMediumService.update(element['@id'], payload).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
          this.isLoading = false;
        },
        _ => {
          this.isLoading = false;
        }
      );
    }
  }

  filterReplacementDataMediums(dataMedium: ReplacementDataMedia[]): ReplacementDataMedia[] {
    return dataMedium.filter(data => this.isCustomerDataMedium(data));
  }

  isCustomerDataMedium(dataMedium: ReplacementDataMedia): boolean {
    return dataMedium['@type'] === 'CustomerDataMedium';
  }

}
