import { CustomerAccountFormComponent } from './customer-account-form/customer-account-form.component';
import { CustomerAccountInvitationListComponent } from './customer-account-invitation-list/customer-account-invitation-list.component';
import { CustomerAccountListComponent } from './customer-account-list/customer-account-list.component';
import { CustomerAddressFormComponent } from './customer-address-form/customer-address-form.component';
import { CustomerAddressListComponent } from './customer-address-list/customer-address-list.component';
import { CustomerCommentFormComponent } from './customer-comment-form/customer-comment-form.component';
import { CustomerCommentsComponent } from './customer-comments/customer-comments.component';
import { CustomerContactFormComponent } from './customer-contact-form/customer-contact-form.component';
import { CustomerContactListComponent } from './customer-contact-list/customer-contact-list.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { CustomerDetailsFormComponent } from './customer-details-form/customer-details-form.component';
import { CustomerFilterFormComponent } from './customer-filter-form/customer-filter-form.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerInvoiceListComponent } from './customer-invoice-list/customer-invoice-list.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerOfferListComponent } from './customer-offer-list/customer-offer-list.component';
import { CustomerOrderListComponent } from './customer-order-list/customer-order-list.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { CustomerTicketListComponent } from './customer-ticket-list/customer-ticket-list.component';
import { PartnerStatusFormComponent } from './partner-status-form/partner-status-form.component';
import { PartnerWebsiteFormComponent } from './partner-website-form/partner-website-form.component';
import { PartnerWebsiteInvitationListComponent } from './partner-website-invitation-list/partner-website-invitation-list.component';
import { PartnerWebsiteListComponent } from './partner-website-list/partner-website-list.component';

export const AllComponents = [
  CustomerAccountFormComponent,
  CustomerAccountInvitationListComponent,
  CustomerAccountListComponent,
  CustomerAddressFormComponent,
  CustomerAddressListComponent,
  CustomerCommentFormComponent,
  CustomerCommentsComponent,
  CustomerContactFormComponent,
  CustomerContactListComponent,
  CustomerDetailsComponent,
  CustomerDetailsFormComponent,
  CustomerFilterFormComponent,
  CustomerFormComponent,
  CustomerInvoiceListComponent,
  CustomerListComponent,
  CustomerOfferListComponent,
  CustomerOrderListComponent,
  CustomerSearchComponent,
  CustomerTicketListComponent,
  PartnerStatusFormComponent,
  PartnerWebsiteFormComponent,
  PartnerWebsiteInvitationListComponent,
  PartnerWebsiteListComponent,
];

export * from './customer-account-form/customer-account-form.component';
export * from './customer-account-invitation-list/customer-account-invitation-list.component';
export * from './customer-account-list/customer-account-list.component';
export * from './customer-address-form/customer-address-form.component';
export * from './customer-address-list/customer-address-list.component';
export * from './customer-comment-form/customer-comment-form.component';
export * from './customer-comments/customer-comments.component';
export * from './customer-contact-form/customer-contact-form.component';
export * from './customer-contact-list/customer-contact-list.component';
export * from './customer-details-form/customer-details-form.component';
export * from './customer-details/customer-details.component';
export * from './customer-filter-form/customer-filter-form.component';
export * from './customer-form/customer-form.component';
export * from './customer-invoice-list/customer-invoice-list.component';
export * from './customer-list/customer-list.component';
export * from './customer-offer-list/customer-offer-list.component';
export * from './customer-order-list/customer-order-list.component';
export * from './customer-search/customer-search.component';
export * from './customer-ticket-list/customer-ticket-list.component';
export * from './partner-status-form/partner-status-form.component';
export * from './partner-website-form/partner-website-form.component';
export * from './partner-website-invitation-list/partner-website-invitation-list.component';
export * from './partner-website-list/partner-website-list.component';
