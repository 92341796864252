import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {clone} from 'lodash-es';

import * as fromCustomersModuleModels from '../../../customers/models';
import {
  Address,
  Customer,
  CustomerAccount,
  CustomerAddress,
  CustomerContact,
  CustomerPartnerStatus,
  CustomerType,
  PartnerWebsite
} from '../../../customers/models';
import {ApplicationState} from '../../../application-state/store';
import {
  CustomerAccountsSelectors,
  CustomerAddressesSelectors,
  CustomerContactsSelectors,
  CustomerPartnerStatusesSelectors,
  CustomersSelectors,
  CustomerTypesSelectors,
  PartnerWebsitesSelectors
} from '../../../customers/store/selectors';
import {
  getUuidFromIri,
  StringsUtility
} from '../../../shared/utilities/strings.utility';
import {Lead, LeadContactType} from '../../models';
import {LeadContactTypesSelectors} from '../../store/selectors';
import {LeadContactTypesActions} from '../../store';
import {
  AddressType,
  Country,
  Grade,
  Locale,
  Salutation
} from '../../../master-data/models';
import {
  AddressTypesSelectors,
  CountriesSelectors,
  GradesSelectors,
  LocalesSelectors,
  SalutationsSelectors
} from '../../../master-data/store/selectors';
import {
  AddressTypesActions,
  CountriesActions,
  GradesActions,
  LocalesActions,
  SalutationsActions
} from '../../../master-data/store';
import {
  CustomerAccountsActions,
  CustomerAddressesActions,
  CustomerContactsActions,
  CustomerPartnerStatusesActions,
  CustomersActions,
  CustomerTypesActions,
  PartnerWebsitesActions
} from '../../../customers/store';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {SenderAddress} from '../../models/sender-address';
import {combineLatestArray} from 'rxjs-etc';
import {extractIri, extractUUID} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-lead-contact-form',
  styleUrls: ['lead-contact-form.component.scss'],
  templateUrl: './lead-contact-form.component.html'
})
export class LeadContactFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() lead$: BehaviorSubject<Lead>;
  @Output() updateSenderAddress: BehaviorSubject<
    SenderAddress
  > = new BehaviorSubject<SenderAddress>(null);
  @Output() updateLeadContactType: BehaviorSubject<
    LeadContactType
  > = new BehaviorSubject<LeadContactType>(null);
  lead: Lead;
  countries$: Observable<Array<Country>>;
  countriesIsLoading$: Observable<boolean>;

  selectedCustomer: Customer;
  customers$: Observable<Array<Customer>>;

  brokerContacts$: Observable<Array<CustomerContact | PartnerWebsite>>;
  brokerContactsIsLoading$: Observable<boolean>;

  brokerContactAddresses$: Observable<Array<CustomerContact | PartnerWebsite>>;
  brokerContactAddressesIsLoading$: Observable<boolean>;

  customerTypes$: Observable<Array<CustomerType>>;
  customerTypesIsLoading$: Observable<boolean>;

  customerPartnerStatuses$: Observable<Array<CustomerPartnerStatus>>;
  customerPartnerStatusesIsLoading$: Observable<boolean>;

  selectedCustomerContact: CustomerContact;
  customerContacts$: Observable<Array<CustomerContact>>;
  customerContactsIsLoading$: Observable<boolean>;

  grades$: Observable<Array<Grade>>;
  gradesIsLoading$: Observable<boolean>;

  salutations$: Observable<Array<Salutation>>;
  salutationsIsLoading$: Observable<boolean>;

  leadContactTypes$: Observable<Array<LeadContactType>>;
  leadContactTypesIsLoading$: Observable<boolean>;

  locales$: Observable<Array<Locale>>;
  localesIsLoading$: Observable<boolean>;

  customerBillingAddresses$: Observable<Array<CustomerAddress>>;
  selectedCustomerDeliveryAddress: CustomerAddress;
  customerDeliveryAddresses$: Observable<Array<CustomerAddress>>;
  customerAddressesIsLoading$: Observable<boolean>;

  addressTypes$: Observable<Array<AddressType>>;
  addressTypesIsLoading$: Observable<boolean>;

  lcf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  isPartner: boolean;
  isBrokeredToPartnerChecked: boolean;
  selectedBroker: Customer;
  brokerId: string;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
  }

  get selectedCustomerContact$(): Observable<CustomerContact> {
    const obs = this.store$.select(
      CustomerContactsSelectors.selectCustomerContactByIndex,
      this.lcf.get('decisionMakerCustomerContact').value
    );
    obs.pipe(takeUntil(this.onDestroy$)).subscribe(customerContact => {
      this.updateSelectedCustomerContact(customerContact);
    });
    return obs;
  }

  ngOnInit(): void {
    this.initForm();

    this.customerAddressesIsLoading$ = this.store$.select(
      CustomerAddressesSelectors.isLoading
    );
    this.customerContactsIsLoading$ = this.store$.select(
      CustomerContactsSelectors.isLoading
    );

    this.loadLeadContactTypes();
    this.loadGrades();
    this.loadLocales();
    this.loadSalutions();
    this.loadCountries();
    this.loadAddressTypes();
    this.loadBrokerContacts();

    this.updateSelectedCustomerType('SelectExistingCustomer');
    this.updateSelectedCustomerContactType('SelectExistingCustomerContact');
    this.updateSelectedCustomerBillingType('SelectExistingBillingAddress');
    this.updateSelectedCustomerDeliveryType('SelectExistingDeliveryAddress');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.lead$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(lead => !!lead)
      )
      .subscribe(lead => {
        if (lead.broker) {
          this.loadBrokerContactAddress(extractIri(lead.broker['@id']));
        }
        const newLead = {...lead, marketingPermissions: []};

        // this.initForm();
        if (lead.customer) {
          const customerIri = extractIri(lead.customer);

          this.store$
            .select(CustomersSelectors.selectCustomerByIndex, {
              iri: customerIri
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(customer => {
              this.selectedCustomer = customer;
              this.doUpdateSenderAddress(this.lcf.getRawValue());
            });
          this.updateSelectedCustomerType('SelectExistingCustomer', false);
          this.loadCustomerData(customerIri);

          newLead.customer = customerIri;
        } else {
          this.updateSelectedCustomerType('NewCustomer', false);
        }
        if (newLead.decisionMakerCustomerContact?.['@id']) {
          newLead.decisionMakerCustomerContact = extractIri(
            newLead.decisionMakerCustomerContact
          );
        }
        newLead.broker = extractIri(newLead.broker);
        newLead.brokerContact = extractIri(newLead.brokerContact);
        this.loadBrokerAndContact(newLead);
        this.lead = newLead;
        if (lead.firstName) {
          this.updateSelectedCustomerContactType('NewCustomerContact', false);
        } else {
          this.updateSelectedCustomerContactType(
            'SelectExistingCustomerContact',
            false
          );
        }
        if (lead.customerBillingAddress) {
          this.updateSelectedCustomerBillingType(
            'SelectExistingBillingAddress',
            false
          );
        } else {
          this.updateSelectedCustomerBillingType(
            'NewCustomerBillingAddress',
            false
          );
        }
        if (lead.customerDeliveryAddress) {
          this.updateSelectedCustomerDeliveryType(
            'SelectExistingDeliveryAddress',
            false
          );
        } else if (
          !lead.customerBillingAddress &&
          (!lead.deliveryAddress ||
            !lead.deliveryAddress.address ||
            !lead.deliveryAddress.address.line1)
        ) {
          this.updateSelectedCustomerDeliveryType(
            'sameAsBillingAddress',
            false
          );
        } else {
          this.updateSelectedCustomerDeliveryType(
            'NewCustomerDeliveryAddress',
            false
          );
        }
        if (newLead.marketingPermissionEmail) {
          newLead.marketingPermissions.push('marketingPermissionEmail');
        }
        if (newLead.marketingPermissionPhone) {
          newLead.marketingPermissions.push('marketingPermissionPhone');
        }
        if (newLead.marketingPermissionPostal) {
          newLead.marketingPermissions.push('marketingPermissionPostal');
        }
        if (newLead.marketingPermissions.length === 0) {
          newLead.marketingPermissions.push('marketingPermissionNone');
        }

        if (lead.billingAddress) {
          newLead.billingAddress = {
            ...lead.billingAddress,
            addressType: lead.billingAddress?.addressType['@id']
              ? lead.billingAddress.addressType['@id']
              : lead.billingAddress
          };
        }
        if (lead.deliveryAddress) {
          newLead.deliveryAddress = {
            ...lead.deliveryAddress,
            addressType: lead.deliveryAddress?.addressType['@id']
              ? lead.deliveryAddress.addressType['@id']
              : lead.deliveryAddress
          };
        }
        for (const controlKey of Object.keys(this.lcf.controls)) {
          if (!newLead[controlKey] && controlKey.indexOf('Toggle') <= -1) {
            newLead[controlKey] = null;
          }
        }
        this.lcf.patchValue(newLead);

        this.doUpdateSenderAddress(this.lcf.getRawValue());
        this.lcf.markAsUntouched();
        /*if (lead.broker) {
          this.lcf.get('broker').disable();
        }
        if (lead.brokerContact) {
          this.lcf.get('brokerContact').disable();
        }*/
      });
  }

  initForm(): void {
    this.lcf = this.fb.group({
      leadContactType: this.fb.control('/api/lead_contact_types/0', [
        Validators.required
      ]),
      vip: this.fb.control(false),
      customerTypeToggle: this.fb.control('SelectExistingCustomer'),
      customer: this.fb.control(null, [Validators.required]),
      nameLine1: this.fb.control({value: null, disabled: true}, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      // marketing permissions as Multiple field
      marketingPermissions: this.fb.control(null, [Validators.required]),
      marketingPermissionEmail: this.fb.control(null),
      marketingPermissionPhone: this.fb.control(null),
      marketingPermissionPostal: this.fb.control(null),
      partnerBranchOfficeAddress: this.fb.control(null),
      nameLine2: this.fb.control({value: '', disabled: true}),
      nameLine3: this.fb.control({value: '', disabled: true}),
      nameLine4: this.fb.control({value: '', disabled: true}),
      customerType: this.fb.control({value: null, disabled: true}),
      partnerStatus: this.fb.control({value: null, disabled: true}, [
        Validators.required
      ]),
      customerInformation: this.fb.control({value: '', disabled: true}),
      partner: this.fb.control(null),

      decisionMakerCustomerContact: this.fb.control(null, [
        Validators.required
      ]),
      salutation: this.fb.control(null),
      grade: this.fb.control(null),
      firstName: this.fb.control('', [Validators.required]),
      middleName: this.fb.control(''),
      lastName: this.fb.control('', [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      phone: this.fb.control(null),
      mobile: this.fb.control(''),
      customerContactTypeToggle: this.fb.control(
        'SelectExistingCustomerContact'
      ),

      customerBillingAddressTypeToggle: this.fb.control(
        'SelectExistingBillingAddress'
      ),
      customerBillingAddress: this.fb.control(null),
      billingAddress: this.fb.group({
        address: this.fb.group({
          line1: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control(null, [Validators.maxLength(30)]),
          line3: this.fb.control(null, [Validators.maxLength(30)]),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null, [
            Validators.maxLength(30)
          ]),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null)
      }),

      customerDeliveryAddressTypeToggle: this.fb.control(
        'SelectExistingDeliveryAddress',
        [Validators.required]
      ),
      customerDeliveryAddress: this.fb.control(null),
      deliveryAddress: this.fb.group({
        address: this.fb.group({
          line1: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control(null, [Validators.maxLength(30)]),
          line3: this.fb.control(null, [Validators.maxLength(30)]),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null, [
            Validators.maxLength(30)
          ]),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null)
      }),

      broker: this.fb.control(null),
      brokerContact: this.fb.control(null),
      availability: this.fb.control(''),
      brokeredToPartner: this.fb.control(false),
      locale: this.fb.control('de_DE', [Validators.required])
    });
    this.handleSenderAddressChanges();
    this.handleBillingAddressChanges();
    this.handleBrokerChange();
    this.handleLeadContactTypeChange();
    this.lcf
      .get('firstName')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(values => {
        this.autoCompleteName();
      });
    this.lcf
      .get('customerType')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(values => {
        this.autoCompleteName();
      });
    this.lcf
      .get('lastName')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(values => {
        this.autoCompleteName();
      });
    // if(!this.lcf.get('brokeredToPartner').value) {
    //   this.lcf.get('partnerBranchOfficeAddress').disable();
    // }

    this.lcf.get('broker').valueChanges
      .pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (!!value) {
        this.store$.dispatch(
          CustomersActions.ReadCustomer({iri: extractIri(value)})
        );
        this.store$.select(
          CustomersSelectors.selectCustomerByIndex, {iri: extractIri(value)}
        ).pipe(takeUntil(this.onDestroy$))
          .subscribe(value => {
            if(value) {
              if(value.defaultPartnerBranchOfficeAddress) {
                this.setPartnerBranchOffice(this.lcf.get('brokeredToPartner').value, value.defaultPartnerBranchOfficeAddress)
              } else {
                this.setPartnerBranchOffice(this.lcf.get('brokeredToPartner').value, this.lead.partnerBranchOfficeAddress && this.lead.partnerBranchOfficeAddress)
              }
            }
          })
      }
    });

    this.lcf.get('brokeredToPartner').valueChanges
      .pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.isBrokeredToPartnerChecked = value;
      this.setPartnerBranchOffice(value)
    });

    // this.lcf
    //   .get('customerType')
    //   .valueChanges.pipe(takeUntil(this.onDestroy$))
    //   .subscribe(values => {
    //     this.autoCompleteName();
    //     if (
    //       values === '/api/customer_types/0' &&
    //       extractIri(this.lead$.getValue()) !== null
    //     ) {
    //       // Geschäftskunde
    //
    //       //reset NameLine1
    //       this.lcf.get('nameLine1').setValue('');
    //     }
    //   });
  }

  autoCompleteName(): void {
    const customerTypeToggle = this.lcf.get('customerTypeToggle').value;
    const firstName = this.lcf.get('firstName').value;
    const lastName = this.lcf.get('lastName').value;
    const customerType = this.lcf.get('customerType').value;
    const nameLine1 = this.lcf.get('nameLine1');
    if (
      customerTypeToggle === 'NewCustomer' &&
      customerType !== '/api/customer_types/0' //nicht Geschäfskunde
    ) {
      const name = firstName + ' ' + lastName;
      this.lcf.get('nameLine1').setValue(name);
    }
  }

  setPartnerBranchOffice(isConditionMet?: boolean, address?: string): void {
    if(address) {
      this.lcf.get('partnerBranchOfficeAddress').setValue(address);
    }
    if (isConditionMet) {
      this.lcf.get('partnerBranchOfficeAddress').enable();
      this.lcf.get('partnerBranchOfficeAddress').setValidators(Validators.required);
    } else {
      this.lcf.get('partnerBranchOfficeAddress').disable();
      this.lcf.get('partnerBranchOfficeAddress').clearValidators();
    }
    // Trigger validation re-evaluation
    this.lcf.get('partnerBranchOfficeAddress').updateValueAndValidity();
  }

  updateSelectedCustomerType($event: string, resetValue = true): void {
    this.lcf.patchValue({customerTypeToggle: $event});
    if ($event === 'NewCustomer') {
      this.lcf.get('customer').disable();
      this.lcf.get('nameLine1').enable();
      this.lcf.get('nameLine2').enable();
      this.lcf.get('nameLine3').enable();
      this.lcf.get('nameLine4').enable();
      this.lcf.get('customerType').enable();
      this.lcf.get('partnerStatus').enable();
      this.lcf.get('customerInformation').enable();
      if (resetValue) {
        this.lcf.get('nameLine1').markAsUntouched();
        this.lcf.get('customer').setValue(null);
        this.lcf.get('availability').setValue('');
        this.updateSelectedCustomerContactType(
          'NewCustomerContact',
          resetValue
        );
        this.updateSelectedCustomerBillingType(
          'NewCustomerBillingAddress',
          resetValue
        );
        this.updateSelectedCustomerDeliveryType(
          'sameAsBillingAddress',
          resetValue
        );
      }
      this.loadCustomerTypes();
      this.loadPartnerStatus();
    } else {
      this.lcf.get('customer').enable();
      this.lcf.get('customer').markAsUntouched();
      this.lcf.get('nameLine1').disable();
      this.lcf.get('nameLine2').disable();
      this.lcf.get('nameLine3').disable();
      this.lcf.get('nameLine4').disable();
      this.lcf.get('customerType').disable();
      this.lcf.get('partnerStatus').disable();
      this.lcf.get('customerInformation').disable();
      if (resetValue) {
        this.lcf.get('customerInformation').setValue(null);
        this.lcf.get('partnerStatus').setValue(null);
        this.lcf.get('customerType').setValue(null);
        this.lcf.get('nameLine1').setValue('');
        this.lcf.get('nameLine2').setValue('');
        this.lcf.get('nameLine3').setValue('');
        this.lcf.get('nameLine4').setValue('');

        this.updateSelectedCustomerContactType(
          'SelectExistingCustomerContact',
          resetValue
        );
        this.updateSelectedCustomerBillingType(
          'SelectExistingBillingAddress',
          resetValue
        );
        this.updateSelectedCustomerDeliveryType(
          'SelectExistingDeliveryAddress',
          resetValue
        );
      }
    }
  }

  updateSelectedCustomerContactType($event: string, resetValue = true): void {
    this.lcf.patchValue({customerContactTypeToggle: $event});
    if ($event === 'SelectExistingCustomerContact') {
      this.lcf.get('decisionMakerCustomerContact').enable();
      this.lcf.get('salutation').disable();
      this.lcf.get('grade').disable();
      this.lcf.get('firstName').disable();
      this.lcf.get('middleName').disable();
      this.lcf.get('lastName').disable();
      this.lcf.get('phone').disable();
      this.lcf.get('mobile').disable();
      this.lcf.get('email').disable();
    } else {
      this.lcf.get('decisionMakerCustomerContact').disable();
      this.lcf.get('salutation').enable();
      this.lcf.get('grade').enable();
      this.lcf.get('firstName').enable();
      this.lcf.get('middleName').enable();
      this.lcf.get('lastName').enable();
      this.lcf.get('phone').enable();
      this.lcf.get('mobile').enable();
      this.lcf.get('email').enable();
    }
  }

  updateSelectedCustomerBillingType($event: string, resetValue = true): void {
    this.lcf.patchValue({customerBillingAddressTypeToggle: $event});

    if ($event === 'SelectExistingBillingAddress') {
      this.lcf.get('customerBillingAddress').enable();
      this.lcf.get('customerBillingAddress').markAsUntouched();
      this.lcf.get('billingAddress').disable();
      if (
        this.lcf.get('customerDeliveryAddressTypeToggle').value ===
        'sameAsBillingAddress'
      ) {
        this.updateSelectedCustomerDeliveryType('NewCustomerDeliveryAddress');
      }
      if (resetValue) {
        this.updateSelectedCustomerDeliveryType(
          'SelectExistingDeliveryAddress'
        );
      }
    } else {
      this.loadAddressTypes();
      this.lcf.get('customerBillingAddress').disable();
      this.lcf.get('billingAddress').enable();
      if (resetValue) {
        this.lcf.get('customerBillingAddress').setValue(null);
        this.lcf
          .get('billingAddress.addressType')
          .setValue('/api/address_types/0');
        this.lcf.get('billingAddress.address.country').setValue('DE');
        this.updateSelectedCustomerDeliveryType('sameAsBillingAddress');
      }
    }
  }

  updateSelectedCustomerDeliveryType($event: string, resetValue = true): void {
    this.lcf.patchValue({customerDeliveryAddressTypeToggle: $event});
    if ($event === 'SelectExistingDeliveryAddress') {
      this.lcf.get('customerDeliveryAddress').enable();
      this.lcf.get('deliveryAddress').disable();
    } else if (
      $event === 'NewCustomerDeliveryAddress' ||
      $event === 'sameAsBillingAddress'
    ) {
      this.lcf.get('customerDeliveryAddress').disable();
      this.lcf.get('deliveryAddress').enable();
      if (resetValue) {
        this.lcf
          .get('deliveryAddress.addressType')
          .setValue('/api/address_types/0');
        this.lcf.get('deliveryAddress.address.country').setValue('DE');
      }
    }
    if ($event === 'sameAsBillingAddress') {
      this.lcf.patchValue({
        deliveryAddress: this.lcf.get('billingAddress').value
      });
    }
  }

  handleBillingAddressChanges(): void {
    this.lcf
      .get('billingAddress')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (
          this.lcf.get('customerDeliveryAddressTypeToggle').value !==
          'sameAsBillingAddress'
        ) {
          return;
        }
        this.lcf.patchValue({deliveryAddress: value});
      });
  }

  handleBrokerChange(): void {
    this.lcf
      .get('broker')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(brokerId => {
        this.lcf.get('brokerContact').setValue(null);
        this.brokerId = extractIri(brokerId);
        this.selectedBroker = brokerId;
        this.brokerContacts$ = combineLatestArray(
          [
            this.store$.select(
              CustomerAccountsSelectors.selectCustomerAccountsByCustomerIri,
              {iri: this.selectedBroker ? this.selectedBroker : null}
            ),
            this.store$.select(PartnerWebsitesSelectors.selectPartnerWebsites)
          ],
          results => {
            const result = [];
            result.push(...results[0]);
            const brokerWebpage = results[1].find(e => e.customer === brokerId);
            if (brokerWebpage) {
              result.push(brokerWebpage);
            }
            return result;
          }
        );
      });
  }

  doUpdateSenderAddress(formData): void {
    const object: SenderAddress = {};
    if (formData.customerTypeToggle === 'NewCustomer' && formData.nameLine1 && formData.customerType !== '/api/customer_types/1') {
      object.companyName = formData.nameLine1;
    } else if (
      this.selectedCustomer &&
      this.selectedCustomer.customerType === '/api/customer_types/0'
    ) {
      object.companyName = this.selectedCustomer.nameLine1;
    }
    if (
      formData.customerContactTypeToggle === 'SelectExistingCustomerContact' &&
      this.selectedCustomerContact
    ) {
      object.firstName = this.selectedCustomerContact.firstName;
      object.lastName = this.selectedCustomerContact.lastName;
      object.phone =
        this.selectedCustomerContact.phone ||
        this.selectedCustomerContact.mobile;
    } else {
      object.firstName = formData.firstName;
      object.lastName = formData.lastName;
      if (formData.phone) {
        object.phone = formData.phone;
      } else if (formData.mobile) {
        object.phone = formData.mobile;
      }
    }
    if (
      formData.customerDeliveryAddressTypeToggle ===
      'SelectExistingDeliveryAddress'
    ) {
      object.address = this.selectedCustomerDeliveryAddress?.address;
    } else if (
      formData.customerDeliveryAddressTypeToggle === 'sameAsBillingAddress'
    ) {
      object.address = formData.billingAddress?.address;
    } else if (
      formData.customerDeliveryAddressTypeToggle ===
      'NewCustomerDeliveryAddress'
    ) {
      object.address = formData.deliveryAddress?.address;
    }
    this.updateSenderAddress.next(object);
  }

  handleSenderAddressChanges(): void {
    this.lcf.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.doUpdateSenderAddress(value);
    });
  }

  handleUpdateCustomerContactDetails(
    contact: fromCustomersModuleModels.CustomerContact
  ): void {
    this.updateSelectedCustomerContact(contact);
    if (contact && contact.availability) {
      this.lcf.get('availability').setValue(contact.availability);
    }
  }

  getUsedAsDefaultContact(contact): string {
    if (!this.lcf || !this.lcf.get('customer') || !this.selectedCustomer) {
      return '';
    }
    if (
      this.selectedCustomer.defaultDecisionMakerCustomerContact ===
      contact['@id']
    ) {
      return '(Entscheider)';
    }
    if (
      this.selectedCustomer.defaultOrganizationalCustomerContact ===
      contact['@id']
    ) {
      return '(Organisatorischer AP)';
    }
    if (
      this.selectedCustomer.defaultTechnicalCustomerContact === contact['@id']
    ) {
      return '(Technischer AP)';
    }
    return '';
  }

  resetCustomer(): void {
    this.selectedCustomer = null;
    this.lcf.get('decisionMakerCustomerContact').setValue(null);
    this.lcf.get('customerBillingAddress').setValue(null);
    this.lcf.get('customerDeliveryAddress').setValue(null);
    this.lcf.get('decisionMakerCustomerContact').markAsUntouched();
    this.lcf.get('customerBillingAddress').markAsUntouched();
    this.lcf.get('customerDeliveryAddress').markAsUntouched();
  }

  updateSelectedCustomer($event: Customer): void {
    this.loadCustomerData($event['@id']);
    this.selectedCustomer = $event;
    if ($event.defaultDecisionMakerCustomerContact) {
      this.lcf
        .get('decisionMakerCustomerContact')
        .setValue($event.defaultDecisionMakerCustomerContact);
      this.customerContacts$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(contacts => {
          this.handleUpdateCustomerContactDetails(
            contacts.find(
              e => e['@id'] === $event.defaultDecisionMakerCustomerContact
            )
          );
          this.doUpdateSenderAddress(this.lcf.getRawValue());
        });
    } else {
      this.lcf.get('decisionMakerCustomerContact').setValue(null);
    }
    this.lcf.get('decisionMakerCustomerContact').markAsUntouched();

    if ($event.defaultBillingAddress) {
      this.lcf
        .get('customerBillingAddress')
        .setValue($event.defaultBillingAddress);
    } else {
      this.lcf.get('customerBillingAddress').setValue(null);
    }
    this.lcf.get('customerBillingAddress').markAsUntouched();

    if ($event.defaultDeliveryAddress) {
      this.lcf
        .get('customerDeliveryAddress')
        .setValue($event.defaultDeliveryAddress);
    } else {
      this.lcf.get('customerDeliveryAddress').setValue(null);
    }
    this.lcf.get('customerDeliveryAddress').markAsUntouched();
  }

  formatAddress(address: CustomerAddress | Address): string {
    // @ts-ignore ¯\_(ツ)_/¯
    return StringsUtility.formatAddress(
      'address' in address ? address.address : address
    );
  }

  formatName(contact: fromCustomersModuleModels.CustomerContact): string {
    return StringsUtility.formatName(contact);
  }

  getSalutation(iri): Observable<Salutation> {
    if (!iri) {
      return null;
    }
    return this.store$.select(SalutationsSelectors.selectSalutation, iri);
  }

  getGrade(iri): Observable<Grade> {
    if (!iri) {
      return null;
    }
    return this.store$.select(GradesSelectors.selectGrade, iri);
  }

  canDeactivate(): boolean {
    if (this.lcf.dirty || this.lcf.touched) {
      return confirm(
        'Achtung, es gibt noch ungespeicherte Änderungen. Hier bleiben?'
      );
    }
    return false;
  }

  selectCustomer(customer: Customer): void {
    if (customer) {
      this.updateSelectedCustomer(customer);
    } else {
      this.resetCustomer();
    }
  }

  isBrokerPartner(partnerStatus: string) {
    return partnerStatus !== '/api/customer_partner_statuses/0';
  }

  findCustomer(term: string, item: Customer): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (
        item.nameLine1.toLowerCase().indexOf(t.toLowerCase()) > -1 ||
        (item.nameLine2 &&
          item.nameLine2.toLowerCase().indexOf(t.toLowerCase()) > -1)
      );
    });
  }

  findBrokerContact(
    term: string,
    item: CustomerAccount | PartnerWebsite
  ): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (
        ('firstName' in item &&
          item.firstName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        ('lastName' in item &&
          item.lastName &&
          item.lastName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        ('website' in item &&
          item.website &&
          item.website.toLowerCase().indexOf(t.toLowerCase()) > -1)
      );
    });
  }

  changeMarketingPermissions(changedValue: string): void {
    const formField = this.lcf.get('marketingPermissions');
    let valueCopy: Array<string> = clone(formField.value);
    let hasChanged = false;
    const addedValue = valueCopy.indexOf(changedValue) > -1;
    const hasNoneValue = valueCopy.indexOf('marketingPermissionNone') > -1;
    if (
      changedValue === 'marketingPermissionEmail' ||
      changedValue === 'marketingPermissionPhone' ||
      changedValue === 'marketingPermissionPostal'
    ) {
      if (addedValue && hasNoneValue) {
        hasChanged = true;
        valueCopy.splice(valueCopy.indexOf('marketingPermissionNone'), 1);
      } else if (valueCopy.length === 0) {
        hasChanged = true;
        valueCopy = ['marketingPermissionNone'];
      }
    } else if (changedValue === 'marketingPermissionNone') {
      if (addedValue) {
        hasChanged = true;
        valueCopy = ['marketingPermissionNone'];
      }
    }
    if (hasChanged) {
      formField.setValue(valueCopy);
    }
    this.lcf.patchValue({
      marketingPermissionEmail:
        valueCopy.indexOf('marketingPermissionEmail') > -1,
      marketingPermissionPhone:
        valueCopy.indexOf('marketingPermissionPhone') > -1,
      marketingPermissionPostal:
        valueCopy.indexOf('marketingPermissionPostal') > -1
    });
  }

  updateCustomerDeliveryAddress($event: any): void {
    this.selectedCustomerDeliveryAddress = $event;
  }

  updateSelectedCustomerContact($event: any): void {
    this.selectedCustomerContact = $event;
  }

  selectBroker($event: Customer): void {
    if (!!$event) {
      this.isPartner = this.isBrokerPartner($event.partnerStatus);
      this.loadBrokerContactAddress(extractIri($event['@id']));
      this.lcf.get('broker').setValue(extractIri($event));
    }

    // Clear the value of customerBillingAddress
    this.lcf.get('customerBillingAddress').setValue(null);
  }

  setCustomer($event: Customer): void {
    this.updateSelectedCustomerType('SelectExistingCustomer', true);
    this.lcf.get('customer').patchValue(extractIri($event));
    this.selectCustomer($event);
  }

  private handleLeadContactTypeChange(): void {
    this.lcf
      .get('leadContactType')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(LeadContactTypeIri => {
        this.store$
          .select(LeadContactTypesSelectors.selectLeadContactTypeByIri, {
            iri: LeadContactTypeIri
          })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(selectedLeadContactType => {
            this.updateLeadContactType.next(selectedLeadContactType);
          });
      });
  }

  private loadLeadContactTypes(): void {
    this.leadContactTypes$ = this.store$.select(
      LeadContactTypesSelectors.selectLeadContactTypes
    );
    this.leadContactTypesIsLoading$ = this.store$.select(
      LeadContactTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      LeadContactTypesSelectors.isLoaded,
      LeadContactTypesActions.LoadLeadContactTypes()
    );
  }

  private loadGrades(): void {
    this.grades$ = this.store$.select(GradesSelectors.selectGrades);
    this.gradesIsLoading$ = this.store$.select(GradesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      GradesSelectors.isLoaded,
      GradesActions.ReadGrades()
    );
  }

  private loadSalutions(): void {
    this.salutations$ = this.store$.select(
      SalutationsSelectors.selectSalutations
    );
    this.salutationsIsLoading$ = this.store$.select(
      SalutationsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      SalutationsSelectors.isLoaded,
      SalutationsActions.ReadSalutations()
    );
  }

  private loadCountries(): void {
    this.countries$ = this.store$.select(CountriesSelectors.selectCountries);
    this.countriesIsLoading$ = this.store$.select(CountriesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      CountriesSelectors.isLoaded,
      CountriesActions.ReadCountries()
    );
  }

  private loadCustomerTypes(): void {
    this.customerTypes$ = this.store$.select(
      CustomerTypesSelectors.selectCustomerTypes
    );
    this.customerTypesIsLoading$ = this.store$.select(
      CustomerTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      CustomerTypesSelectors.isLoaded,
      CustomerTypesActions.LoadCustomerTypes()
    );
  }

  private setDefaultPartnerStatus(status: CustomerPartnerStatus): void {
    if (
      status &&
      this.lcf.get('customerTypeToggle').value === 'NewCustomer' &&
      this.lcf.get('partnerStatus').value === null
    ) {
      this.lcf.get('partnerStatus').setValue(status['@id']);
    }
  }

  private loadPartnerStatus(): void {
    this.customerPartnerStatuses$ = this.store$.select(
      CustomerPartnerStatusesSelectors.selectCustomerPartnerStatuses
    );
    this.customerPartnerStatusesIsLoading$ = this.store$.select(
      CustomerPartnerStatusesSelectors.isLoading
    );
    this.customerPartnerStatuses$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e?.length > 0)
      )
      .subscribe(stati => {
        const noPartner = stati.find(e => e.name === 'kein Partner');
        this.setDefaultPartnerStatus(noPartner);
      });
    loadIfNotLoaded(
      this.store$,
      CustomerPartnerStatusesSelectors.isLoaded,
      CustomerPartnerStatusesActions.LoadCustomerPartnerStatuses()
    );
  }

  private loadAddressTypes(): void {
    this.addressTypes$ = this.store$.select(
      AddressTypesSelectors.selectAddressTypes
    );
    this.addressTypesIsLoading$ = this.store$.select(
      AddressTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AddressTypesSelectors.isLoaded,
      AddressTypesActions.ReadAddressTypes()
    );
  }

  private loadLocales(): void {
    this.locales$ = this.store$.select(LocalesSelectors.selectLocales);
    this.localesIsLoading$ = this.store$.select(LocalesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      LocalesSelectors.isLoaded,
      LocalesActions.ReadLocales()
    );
  }

  private loadCustomerData(customerIri: string): void {
    customerIri = extractIri(customerIri);
    this.store$.dispatch(
      CustomerContactsActions.ReadCustomerContacts({
        page: -1,
        params: {'customer.uuid': getUuidFromIri(customerIri)}
      })
    );
    this.store$.dispatch(CustomersActions.ReadCustomer({iri: customerIri}));
    this.store$.dispatch(
      CustomerAddressesActions.ReadCustomerAddresses({customerIri})
    );
    this.customerContacts$ = this.store$.select(
      CustomerContactsSelectors.selectCustomerContactsByCustomerIri,
      {customerIri}
    );
    this.customerBillingAddresses$ = this.store$.select(
      CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
      {customerIri}
    );
    this.customerDeliveryAddresses$ = this.store$.select(
      CustomerAddressesSelectors.selectCustomerDeliveryAddressesByCustomerIri,
      {customerIri}
    );
    this.customerDeliveryAddresses$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(deliveryAddresses => {
        this.updateCustomerDeliveryAddress(
          deliveryAddresses.find(
            e => e['@id'] === this.lcf.get('customerDeliveryAddress').value
          )
        );
        this.doUpdateSenderAddress(this.lcf.getRawValue());
      });
  }

  private loadBrokerContacts(): void {
    this.brokerContactsIsLoading$ = isLoadingArray([
      this.store$.select(CustomerAccountsSelectors.isLoading),
      this.store$.select(PartnerWebsitesSelectors.isLoading)
    ]);
    loadIfNotLoaded(
      this.store$,
      CustomerAccountsSelectors.isLoaded,
      CustomerAccountsActions.ReadCustomerAccounts({page: -1})
    );
    loadIfNotLoaded(
      this.store$,
      PartnerWebsitesSelectors.isLoaded,
      PartnerWebsitesActions.ReadPartnerWebsites({page: -1})
    );
  }

  private loadBrokerContactAddress(customerIri: string): void {
    this.brokerContactAddresses$ = this.store$.select(
      CustomerAddressesSelectors.selectCustomersPartnerBranchOfficeByCustomerIri,
      {customerIri}
    );
    this.brokerContactAddressesIsLoading$ = isLoadingArray([
      this.store$.select(CustomerAddressesSelectors.isLoading)
    ]);
    loadIfNotLoaded(
      this.store$,
      CustomerAddressesSelectors.isLoaded,
      CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddresses(
        {customerIri}
      )
    );
  }

  private loadBrokerAndContact(newLead: Lead): void {
    if (newLead.broker) {
      this.store$.dispatch(
        CustomersActions.ReadCustomer({iri: extractIri(newLead.broker)})
      );
    }
    if (newLead.brokerContact) {
      if (newLead.brokerContact.indexOf('partner_website') > -1) {
        this.store$.dispatch(
          PartnerWebsitesActions.ReadPartnerWebsite({
            iri: extractIri(newLead.brokerContact)
          })
        );
      } else {
        this.store$.dispatch(
          CustomerAccountsActions.ReadCustomerAccount({
            iri: extractIri(newLead.brokerContact)
          })
        );
      }
    }
  }

  protected readonly extractIri = extractIri;
  protected readonly extractUUID = extractUUID;
}
