import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AbstractTitleService {
  BASE_APP_NAME = 'BMOffice24';
  SEPARATOR = ' - ';

  constructor(public titleService: Title) {}

  setTitle(name: string): void {
    this.titleService.setTitle(name + this.SEPARATOR + this.BASE_APP_NAME);
  }
}
