import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  extractIri,
  extractUUID,
  ObjectsUtility
} from '../../../shared/utilities/objects.utility';
import {
  getUuidFromIri,
  StringsUtility
} from '../../../shared/utilities/strings.utility';
import { Observable, Subject } from 'rxjs';
import { Order } from '../../models';
import { lowerFirst } from 'lodash-es';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { OrdersSelectors } from '../../store/selectors';
import {
  AnalysisPriorityModesSelectors,
  DataRecoveryPriorityModesSelectors,
  DepartmentsSelectors,
  DiscountsSelectors,
  DisposalTypesSelectors,
  LabLocationsSelectors,
  ReplacementDataMediumSourcesSelectors
} from '../../../master-data/store/selectors';
import {
  AnalysisPriorityModesActions,
  DataRecoveryPriorityModesActions,
  DepartmentsActions,
  DiscountsActions,
  DisposalTypesActions,
  LabLocationsActions,
  ReplacementDataMediumSourcesActions
} from '../../../master-data/store';
import {
  AnalysisPriorityMode,
  DataRecoveryPriorityMode,
  Department,
  Discount,
  DisposalType,
  LabLocation,
  ReplacementDataMediumSource
} from '../../../master-data/models';
import * as CustomersModuleSelectors from '../../../customers/store/selectors';
import {
  CustomerAddressesSelectors,
  CustomerContactsSelectors,
  CustomerContactTypesSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import {
  CustomerAddressesActions,
  CustomerContactTypesActions,
  CustomersActions, PartnersActions
} from '../../../customers/store';
import {
  Address,
  Customer,
  CustomerAddress,
  CustomerContact,
  CustomerContactType
} from '../../../customers/models';
import { ShippingProvider } from '../../../shipping/models';
import {
  ShippingProvidersToCustomerSelectors,
  ShippingProvidersToDataRecoverySelectors
} from '../../../shipping/store/selectors';
import { ShippingProvidersActions } from '../../../shipping/store';
import { combineLatestArray } from 'rxjs-etc';
import { OrderDetailsEditDialogComponent } from '../order-details-edit-dialog/order-details-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { AdministratorsActions } from '../../../administrators/store';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { Administrator } from '../../../administrators/models';
import { AuthService } from '../../../auth/services/auth.service';
import * as CustomersModuleActions from '../../../customers/store/actions';
import {AbstractApiResponse} from "../../../shared/models";
import {AbstractApiService} from "../../../shared/services";

@Component({
  selector: 'app-order-details',
  styleUrls: ['order-details.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Allgemeine Daten</span>

        <span class="btn--edit">
          <button
            mat-icon-button
            [disabled]="isLoading$ | async"
            (click)="handleRequestOrderForm()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </span>
      </div>

      <div class="card__content pos-relative">
        <app-loading-overlay *ngIf="(isLoading$ | async) || (order && order.partnerBranchOfficeAddress && !partnerBranchOffice)"></app-loading-overlay>

        <div class="overview grid grid-no-gutter" *ngIf="order">
          <dl class="list--dl column-14 grid border-bottom  p-a--16">
            <dt class="column-5">Auftragsdatum:</dt>
            <dd class="column-9">{{ order?.createdAt | date }}</dd>

            <dt class="column-5">Auftragsnummer:</dt>

            <dd class="column-9">
              <span>{{ order?.orderNumber }}</span>
              <span class="mx-2">|</span>
              <span
                class="badge rounded-pill bg-secondary"
                *ngIf="getOrderPriorityMode() !== 'EXPRESS'"
                >{{ getOrderPriorityMode() }}</span
              >
              <span
                *ngIf="getOrderPriorityMode() === 'EXPRESS'"
                class="badge rounded-pill text-white bg-warning"
                >{{ getOrderPriorityMode() }}</span
              >
            </dd>

            <dt class="column-5">Ticket:</dt>
            <dd class="column-9">
              <a
                [routerLink]="['/tickets', ticketUUID]"
                class="text-decoration-none"
              >
                {{ order?.ticket?.ticketNumber }}</a
              >
            </dd>

            <dt class="column-5">Status:</dt>
            <dd class="column-9">
              <strong>{{ order.stateRange }}</strong
              ><br />
              <span
                *ngFor="let key of order.state | keys; let isLast = last"
                class="text-color-lightgrey"
              >
                {{ key }}<span *ngIf="!isLast">; </span>
              </span>
            </dd>
            <dt class="column-5">Vertriebler:</dt>
            <dd class="column-9">
              {{
                getOrderProperty('administratorsEntities', 'salesperson')
                  ?.firstName || '-'
              }}
              {{
                getOrderProperty('administratorsEntities', 'salesperson')
                  ?.lastName
              }}
            </dd>

            <dt class="column-5">Techniker:</dt>
            <dd class="column-9">
              {{
                getOrderProperty('administratorsEntities', 'technician')
                  ?.firstName || '-'
              }}
              {{
                getOrderProperty('administratorsEntities', 'technician')
                  ?.lastName
              }}
            </dd>
            <dt class="column-5">Bereich:</dt>
            <dd class="column-9">
              {{
                getOrderProperty('departmentsEntities', 'department')
                  ?.companyName
              }}
            </dd>

            <dt class="column-5">
              {{
                customer?.customerType === '/api/customer_types/0'
                  ? 'Firma'
                  : 'Privat'
              }}:
            </dt>
            <dd class="column-9">
              <a
                [routerLink]="['/customers', extractUUID(customer)]"
                *ngIf="canViewCustomer"
                class="text-decoration-none"
              >
                {{ customer?.nameLine1 }}
                <ng-container *ngIf="customer?.nameLine2"
                  >[{{ customer?.nameLine2 }}]
                </ng-container>
              </a>
              <span *ngIf="!canViewCustomer">
                {{ customer?.nameLine1 }}
                <ng-container *ngIf="customer?.nameLine2"
                  >[{{ customer?.nameLine2 }}]</ng-container
                >
              </span>
            </dd>

            <ng-container *ngFor="let t of customerAddressTypes">
              <strong class="column-5">{{ t.name }}</strong>
              <span class="column-9">
                {{ formatAddress(getAddress(t.customerAddressType)) }}
              </span>
            </ng-container>

            <dt class="column-5">Vermittler:</dt>
            <dd class="column-9">{{ getBrokerName() }}</dd>

            <ng-container *ngIf="order.partnerBranchOfficeAddress">
              <dt class="column-5">Partner Abgabestellen:</dt>
              <dd class="column-9">{{ getPartnerBranchOffice() }}</dd>
            </ng-container>

            <dt class="column-5">Analyse in:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName('labLocationsEntities', 'analysisLocation')
              }}
            </dd>

            <dt class="column-5">Datenrettung in:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'labLocationsEntities',
                  'dataRecoveryLocation'
                )
              }}
            </dd>
          </dl>
          <dl class="column-14 grid border-bottom p-a--16">
            <dt class="column-5">Ersatzdatenträger:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'replacementDataMediumSourcesEntities',
                  'replacementDataMediumSource'
                )
              }}
            </dd>

            <dt class="column-5">Alte Datenträger:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName('disposalTypesEntities', 'disposalType')
              }}
            </dd>
          </dl>
          <dl class="column-14 grid border-bottom p-a--16">

            <dt class="column-5">PW:</dt>
            <dd class="column-9">{{ order?.encryptionKey ? order.encryptionKey : '-'}}</dd>

            <dt class="column-5">ED Betriebssystem:</dt>
            <dd class="column-9">{{ order?.replacementDataMediumOperatingSystem ? getOrderPropertyName('operatingSystemsEntities', 'replacementDataMediumOperatingSystem') : '-'}}</dd>

          </dl>
          <dl class="column-14 grid border-bottom p-a--16">
            <dt class="column-5">Rabatt:</dt>
            <dd *ngIf="order.specialDiscount" class="column-9">
              {{ order.specialDiscount | number: '1.2-2' }} %
            </dd>
            <dd
              *ngIf="!order.specialDiscount && order.discount"
              class="column-9"
            >
              {{ getOrderProperty('discountsEntities', 'discount').name }} ({{
                getOrderProperty('discountsEntities', 'discount').value
                  | number: '1.2-2'
              }}%)
            </dd>
            <dd
              *ngIf="!order.specialDiscount && !order.discount"
              class="column-9"
            >
              -
            </dd>

            <dt class="column-5">Datenrettung Typ:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'dataRecoveryPriorityModesEntities',
                  'dataRecoveryPriorityMode'
                )
              }}
            </dd>
            <dt class="column-5">Übergabe an DR:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'shippingProvidersToDataRecoveryEntities',
                  'shippingProviderToDR'
                )
              }}
            </dd>

            <dt class="column-5">Übergabe an Kunden:</dt>
            <dd class="column-9">
              {{
                order.shippingProviderToCustomer
                  ? getOrderPropertyName(
                      'shippingProvidersToCustomerEntities',
                      'shippingProviderToCustomer'
                    )
                  : '-'
              }}
            </dd>

            <dt class="column-5">% Anzahlung:</dt>
            <dd class="column-9">
              {{
                order.downPayment
                  ? (order.downPayment | number: '1.2-2') + ' %'
                  : '-'
              }}
            </dd>

            <dt class="column-5">zusätzliche Vereinbarungen:</dt>
            <dd class="column-9">
              {{
                order.additionalAgreements ? order.additionalAgreements : '-'
              }}
            </dd>

            <dt class="column-5">€ Bereitschaft Kd.</dt>
            <dd class="column-9">
              {{
                order.willingnessToPay
                  ? (order.willingnessToPay.value | number: '1.2-2')
                  : '-'
              }}
              {{
                order.willingnessToPay
                  ? (order.willingnessToPay.currency | currencyShortener)
                  : '-'
              }}
            </dd>
          </dl>

          <div class="contacts py-2 p-a--16">
            <ng-container
              *ngFor="
                let cT of customerContactTypes;
                let i = index;
                let last = last
              "
            >
              <contact-person-details
                [contact]="getDefaultContact(cT.mappingName)"
                [last]="last"
                [index]="i"
                [label]="cT.abbreviation"
              ></contact-person-details>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;
  partnerBranchOffice: any;
  isLoading$: Observable<boolean>;

  addresses: Array<CustomerAddress>;
  contacts: Array<CustomerContact>;
  customer: Customer;

  analysisPriorityModesEntities: { [iri: string]: AnalysisPriorityMode };
  administratorsEntities: { [iri: string]: Administrator };
  customerContactTypes: Array<CustomerContactType> = [];
  dataRecoveryPriorityModesEntities: {
    [iri: string]: DataRecoveryPriorityMode;
  };
  departmentsEntities: { [iri: string]: Department };
  discountsEntities: { [iri: string]: Discount };
  disposalTypesEntities: { [iri: string]: DisposalType };
  labLocationsEntities: { [iri: string]: LabLocation };
  brokerEntities: { [iri: string]: Customer };
  replacementDataMediumSourcesEntities: {
    [iri: string]: ReplacementDataMediumSource;
  };
  shippingProvidersToCustomerEntities: { [iri: string]: ShippingProvider };
  shippingProvidersToDataRecoveryEntities: { [iri: string]: ShippingProvider };

  customerAddressTypes: Array<{ customerAddressType: string; name: string }> = [
    { customerAddressType: 'BillingAddress', name: 'Rechnungsadresse' },
    { customerAddressType: 'DeliveryAddress', name: 'Lieferadresse' }
  ];

  onDestroy$: Subject<any> = new Subject<any>();
  protected readonly extractUUID = extractUUID;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private authService: AuthService,
    private apiService: AbstractApiService
  ) {}

  get canViewCustomer(): boolean {
    return (
      this.authService.isAdmin() ||
      this.authService.isSupervisor() ||
      this.authService.isPartnerManagement() ||
      this.authService.isSales() ||
      this.authService.isSalesExternal() ||
      this.authService.isTechnician() ||
      this.authService.isAccounting() ||
      this.authService.isLogistic()
    );
  }

  get ticketUUID(): string {
    if (!this.order || !this.order.ticket) {
      return null;
    }
    return getUuidFromIri(extractIri(this.order.ticket));
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(AnalysisPriorityModesSelectors.isLoading),
      this.store$.select(CustomerContactTypesSelectors.isLoading),
      this.store$.select(DataRecoveryPriorityModesSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(DiscountsSelectors.isLoading),
      this.store$.select(DisposalTypesSelectors.isLoading),
      this.store$.select(LabLocationsSelectors.isLoading),
      this.store$.select(ReplacementDataMediumSourcesSelectors.isLoading),
      this.store$.select(ShippingProvidersToCustomerSelectors.isLoading),
      this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading)
    ]);

    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.store$.dispatch(
          CustomerAddressesActions.ReadCustomerAddresses({
            customerIri: extractIri(order.customer)
          })
        );
        this.store$.dispatch(
          CustomersModuleActions.CustomerContactsActions.ReadCustomerContacts({
            page: -1,
            params: {
              'customer.uuid': getUuidFromIri(extractIri(order?.customer))
            }
          })
        );
        if(this.order.partnerBranchOfficeAddress) {
          this.apiService.getObject(this.order.partnerBranchOfficeAddress, true).subscribe(
            data => {
              this.partnerBranchOffice = data;
            }
          )
        }

        this.store$
          .select(
            CustomerContactsSelectors.selectCustomerContactsByCustomerIri,
            { customerIri: extractIri(order?.customer) }
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(contacts => {
            this.contacts = contacts;
          });

        const customerUUIDs = [getUuidFromIri(extractIri(order?.customer))];
        if (order?.broker) {
          customerUUIDs.push(getUuidFromIri(extractIri(order.broker)));
        }
        this.store$.dispatch(
          CustomersActions.ReadCustomers({
            page: -1,
            params: { uuid: customerUUIDs }
          })
        );
        this.store$
          .select(CustomersSelectors.selectCustomerByIndex, {
            iri: order?.customer['@id']
          })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(customer => {
            this.customer = customer;
          });
        combineLatestArray([
          this.store$.select(
            CustomersModuleSelectors.CustomerAddressesSelectors
              .selectCustomerBillingAddressesByCustomerIri,
            { customerIri: order?.customer['@id'] }
          ),
          this.store$.select(
            CustomersModuleSelectors.CustomerAddressesSelectors
              .selectCustomerDeliveryAddressesByCustomerIri,
            { customerIri: order?.customer['@id'] }
          )
        ])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            this.addresses = [].concat(...data);
          });
      });

    this.loadAnalysisPriorityModes();
    this.loadCustomerContactTypes();
    this.loadDataRecoveryPriorityModes();
    this.loadDepartments();
    this.loadDiscounts();
    this.loadDisposalTypes();
    this.loadLabLocations();
    this.loadReplacementDataMediumSources();
    this.loadShippingProviders();
    this.loadAdministrators();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  keysAsString(obj): string {
    if (obj && obj instanceof Object) {
      return ObjectsUtility.getObjectKeys(obj).join('\n');
    }
    return '';
  }

  getAddress(addressType: string): CustomerAddress {
    if (
      !this.customer ||
      !this.order ||
      !this.addresses ||
      this.addresses.length <= 0
    ) {
      return null;
    }
    const addressTypeWithFirstLetterLowerCase = lowerFirst(addressType);
    const AddressID = this.order[addressTypeWithFirstLetterLowerCase]
      ? this.order[addressTypeWithFirstLetterLowerCase]
      : this.customer[`default${addressType}`];
    return this.addresses.find(
      address => address && address['@id'] === AddressID
    )?.address;
  }

  getDefaultContact(contactType: string): CustomerContact {
    return this.customer && this.contacts
      ? this.contacts.find(
          contact =>
            contact && contact['@id'] === this.customer[`default${contactType}`]
        )
      : null;
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Adresse gesetzt';
  }

  getOrderPropertyName(entitiesSet: string, orderProperty: string): string {
    return this[entitiesSet] && this[entitiesSet][this.order[orderProperty]]?.name
      ? this[entitiesSet][this.order[orderProperty]].name
      : '-';
  }


  getOrderProperty(entitiesSet: string, orderProperty: string): string {
    return this[entitiesSet] && this[entitiesSet][this.order[orderProperty]]
      ? this[entitiesSet][this.order[orderProperty]]
      : null;
  }

  handleRequestOrderForm(): void {
    this.dialog.open(OrderDetailsEditDialogComponent, {
      disableClose: false,
      data: {
        order$: this.order$,
        administrators: this.administratorsEntities
      }
    });
  }

  getOrderPriorityMode(): string {
    if (!this.order || !this.analysisPriorityModesEntities) {
      return '';
    }
    if (this.order.dataRecoveryPriorityMode) {
      return (
        this.dataRecoveryPriorityModesEntities[
          this.order.dataRecoveryPriorityMode['@id']
        ]?.name || 'UNSET'
      );
    } else if (this.order.analysisPriorityMode) {
      return (
        this.analysisPriorityModesEntities[
          this.order.analysisPriorityMode['@id']
        ]?.name || 'UNSET'
      );
    }
  }

  getBrokerName(): string {
    if (!this.order || !this.brokerEntities) {
      return '';
    }
    if (!this.order.broker) {
      return '-';
    }
    return this.brokerEntities[this.order.broker['@id']]?.nameLine1 || '-';
  }

  getPartnerBranchOffice(): string {
    if(!this.partnerBranchOffice) {
      return '';
    } else {
      return `${this.partnerBranchOffice.address.line1} ${this.partnerBranchOffice.address.line2}, ${this.partnerBranchOffice.address.zipPostcode} ${this.partnerBranchOffice.address.city}`
    }
  }

  private loadAnalysisPriorityModes(): void {
    this.store$
      .select(
        AnalysisPriorityModesSelectors.selectAnalysisPriorityModesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.analysisPriorityModesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
  }

  private loadCustomerContactTypes(): void {
    this.store$
      .select(CustomerContactTypesSelectors.selectCustomerContactTypes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.customerContactTypes = entities;
      });
    loadIfNotLoaded(
      this.store$,
      CustomerContactTypesSelectors.isLoaded,
      CustomerContactTypesActions.ReadCustomerContactTypes()
    );
  }

  private loadDataRecoveryPriorityModes(): void {
    this.store$
      .select(DataRecoveryPriorityModesSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.dataRecoveryPriorityModesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes()
    );
  }

  private loadDepartments(): void {
    this.store$
      .select(DepartmentsSelectors.selectDepartmentsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.departmentsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }

  private loadDiscounts(): void {
    this.store$
      .select(DiscountsSelectors.selectDiscountsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.discountsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }

  private loadDisposalTypes(): void {
    this.store$
      .select(DisposalTypesSelectors.selectDisposalTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.disposalTypesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }

  private loadLabLocations(): void {
    this.store$.select(LabLocationsSelectors.sEntities).subscribe(entities => {
      this.labLocationsEntities = entities;
    });
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  private loadReplacementDataMediumSources(): void {
    this.store$
      .select(
        ReplacementDataMediumSourcesSelectors.selectReplacementDataMediumSourcesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.replacementDataMediumSourcesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ReplacementDataMediumSourcesSelectors.isLoaded,
      ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources()
    );
  }

  private loadShippingProviders(): void {
    this.store$
      .select(
        ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomerEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.shippingProvidersToCustomerEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToCustomerSelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToCustomer()
    );
    this.store$
      .select(
        ShippingProvidersToDataRecoverySelectors.selectShippingProvidersToDataRecoveryEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.shippingProvidersToDataRecoveryEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToDataRecoverySelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToDataRecovery()
    );
  }

  private loadAdministrators(): void {
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.administratorsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }
}
