import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NgSelectComponent} from '@ng-select/ng-select';
import {debounceTime, distinctUntilChanged, filter, take, takeUntil, tap, throttleTime} from 'rxjs/operators';

import * as fromCustomersModuleModels from '../../../customers/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'customer-search',
  styleUrls: ['customer-search.component.scss'],
  template: `

    <div class="mat-form-field" [formGroup]="formGroup">

      <ng-select
        (change)="handleRequestHandleCustomerSelected($event)"
        (clear)="handleRequestHandleCustomerSelected()"
        (close)="handleRequestResetSearchResults()"
        (scrollToEnd)="listScrollToEnd$.next($event)"
        [clearable]="true"
        [closeOnSelect]="true"
        [items]="items"
        [appendTo]="appendTo"
        [loading]="loading"
        [readonly]="isReadonly"
        [notFoundText]="notFoundText"
        [placeholder]="placeholder"
        [searchWhileComposing]="false"
        [typeahead]="typeahead$"
        [virtualScroll]="true"
        [matTooltip]="tooltipContent"
        [matTooltipDisabled]="tooltipDisabled"
        bindLabel="nameLine1"
        bindValue="@id"
        [formControlName]="formControlName"
        [typeToSearchText]="typeToSearchText">

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="ng-option custom-layout">
          <span style="padding-right: 7px">
            <mat-icon [inline]="true" color="accent">person</mat-icon>
          </span>
            <span style="padding-right: 7px">{{ item.nameLine1 }}</span>
            <strong *ngIf="item.nameLine2" style="font-size: 12px">({{ item.nameLine2 }})</strong>
          </div>
        </ng-template>
      </ng-select>
      <mat-hint align="start" *ngIf="errors?.order">{{ errors.order.message }}</mat-hint>
    </div>
    <!--<pre>{{ searchResults$ | async | json }}</pre>-->
  `,
})
export class CustomerSearchComponent {

  @ViewChild(NgSelectComponent)
  selectBar: NgSelectComponent;

  @Input() appendTo: string = null;
  @Input() clearable: boolean;
  @Input() editableSearchTerm: boolean;
  @Input() errors: ErrorsObject;
  @Input() formControlName = 'customer';
  @Input() formGroup: FormGroup;
  @Input() isReadonly = false;
  @Input() notFoundText: string;
  @Input() paginationNextLink$: BehaviorSubject<string | null | undefined>;
  @Input() placeholder: string;
  @Input() searchResults$: Observable<Array<fromCustomersModuleModels.Customer>>;
  @Input() staticSelection$: Observable<fromCustomersModuleModels.Customer>;
  @Input() tooltipContent: string = null;
  @Input() tooltipDisabled = true;
  @Input() typeToSearchText = 'Nach einem Kunden suchen';

  @Output() requestReadCustomers: EventEmitter<{
    page: number,
    params: { [p: string]: string | boolean | number }
  }> = new EventEmitter();
  @Output() requestResetSearchResults: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestSetCustomer: EventEmitter<fromCustomersModuleModels.Customer | null> = new EventEmitter();

  items$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  listScrollToEnd$: Subject<any> = new Subject<any>();
  loading: boolean;
  onDestroy$: Subject<any> = new Subject<any>();
  typeahead$: Subject<string> = new Subject<string>();

  get items(): Array<fromCustomersModuleModels.Customer> {
    return this.items$.getValue();
  }

  ngOnInit(): void {

    this.searchResults$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
    ).subscribe((results) => {
      this.loading = false;

      // Rothe-esque solution for now ...
      results.forEach(result => {
        if (!!!this.items.filter(item => item['@id'] === result['@id']).length) {
          this.items$.next([...this.items, result]);
        }
      });

      // todo: maybe use mapped object / entities to circumvent forEach?
      // this.items$.next([...this.items, ...results]);
    });

    this.typeahead$.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
      debounceTime(500),
      tap(() => this.loading = true),
      tap(() => this.items$.next([])),
    ).subscribe(queryString => {
      if (queryString?.length >= 2) {
        // note: term can be null at this point (or undefined)
        this.handleRequestReadCustomers({page: 1, params: {fulltext_search: queryString, itemsPerPage: 15}});
      } else {
        this.loading = false;
      }
    });

    this.listScrollToEnd$.pipe(
      throttleTime(100),
    ).subscribe(() => {
      this.handleReadNextItemChunk();
    });
  }

  ngAfterViewInit(): void {
    if (this.staticSelection$) {
      this.staticSelection$.pipe(
        takeUntil(this.onDestroy$),
        filter(item => !!item)
      ).subscribe((item) => {
        this.items$.next([item]);
      });
    }
  }

  handleRequestHandleCustomerSelected(customer?: fromCustomersModuleModels.Customer): void {
    this.requestSetCustomer.emit(customer);
  }

  handleRequestReadCustomers(payload: { page: number, params: { [p: string]: any } }): void {
    this.requestReadCustomers.emit(payload);
  }

  handleReadNextItemChunk(): void {

    const nextLink = this.paginationNextLink$.getValue();

    if (nextLink) {
      this.loading = true;
      const params = nextLink.split('?')[1];
      const searchParams = new URLSearchParams(params);

      const payload = {
        page: parseInt(searchParams.get('page')),
        params: {
          fulltext_search: !!searchParams.get('term') ? searchParams.get('term') : this.selectBar.searchTerm,
          itemsPerPage: 15
        }
      };

      this.handleRequestReadCustomers(payload);
    }
  }

  handleRequestResetSearchResults(): void {
    this.requestResetSearchResults.emit();
  }
}
