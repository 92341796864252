import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DESTRUCTION_TYPE = '[Master Data] Read Destruction Type';
export const READ_DESTRUCTION_TYPE_SUCCESS = '[Master Data] Read Destruction Type Success';
export const READ_DESTRUCTION_TYPE_FAIL = '[Master Data] Read Destruction Type Fail';
export const ReadDestructionType = createAction(
  READ_DESTRUCTION_TYPE,
  props<{ iri: string }>()
);
export const ReadDestructionTypeSuccess = createAction(
  READ_DESTRUCTION_TYPE_SUCCESS,
  props<{ response: fromModuleModels.DestructionType }>()
);
export const ReadDestructionTypeFail = createAction(
  READ_DESTRUCTION_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DESTRUCTION_TYPES = '[Master Data] Read Destruction Types';
export const READ_DESTRUCTION_TYPES_SUCCESS = '[Master Data] Read Destruction Types Success';
export const READ_DESTRUCTION_TYPES_FAIL = '[Master Data] Read Destruction Types Fail';
export const ReadDestructionTypes = createAction(
  READ_DESTRUCTION_TYPES
);
export const ReadDestructionTypesSuccess = createAction(
  READ_DESTRUCTION_TYPES_SUCCESS,
  props<{ response: any }>()
);
export const ReadDestructionTypesFail = createAction(
  READ_DESTRUCTION_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
