import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ProductUnitsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { ProductUnitsService } from '../../services/product-units.service';

@Injectable()
export class ProductUnitsEffects {
  readProductUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductUnitsActions.ReadProductUnit),
      map(action => action),
      switchMap(({ iri }) => {
        return this.aps.readProductUnit(iri).pipe(
          map(response =>
            ProductUnitsActions.ReadProductUnitSuccess({ response })
          ),
          catchError(response =>
            of(ProductUnitsActions.ReadProductUnitFail({ response }))
          )
        );
      })
    )
  );
  readProductUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductUnitsActions.ReadProductUnits),
      map(action => action),
      switchMap(() => {
        return this.aps.readProductUnits().pipe(
          map(response =>
            ProductUnitsActions.ReadProductUnitsSuccess({ response })
          ),
          catchError(response =>
            of(ProductUnitsActions.ReadProductUnitsFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductUnitsActions.ReadProductUnitsFail,
          ProductUnitsActions.ReadProductUnitFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aps: ProductUnitsService,
    private notifierService: NotifierService
  ) {}
}
