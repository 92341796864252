import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FormsService {
  nestedProperties = [
    'broker',
    'salutation',
    'grade',
    'address',
    // 'defaultTax',
    // 'productUnit',
    'price',
    'priceAnalysisGross',
    'priceAnalysisNet',
    'priceDataRecoveryGross',
    'priceDataRecoveryNet',
    'priceDismantlingGross',
    'priceDismantlingNet',
    'priceDisposalGross',
    'priceDisposalNet',
    'priceInitialCostsGross',
    'priceInitialCostsNet',
    'priceReturnShipmentGross',
    'priceReturnShipmentNet',
    'unitAnalysis',
    'unitDataRecovery',
    'unitDismantling',
    'unitDisposal',
    'unitInitialCosts',
    'unitReturnShipment',
    'willingnessToPay',
    'netPricePerUnit',
    'taxRate',
    'taxesPerUnit',
    'netTotal',
    'grossTotal',
    'originalNetTotal',
    'originalGrossTotal'
  ];
  // todo: naming
  propertiesRequiringIri = [
    'broker',
    'addressType',
    'analysisPriorityMode',
    'dataRecoveryPriorityMode',
    'defaultTax',
    'productUnit',
    'taxAnalysis',
    'taxDataRecovery',
    'taxDismantling',
    'taxDisposal',
    'taxInitialCosts',
    'taxRate',
    'taxReturnShipment'
  ];

  constructor() {}

  // todo: check if arrays are handled correctly
  resetFormErrors(form: FormGroup): void {
    if (!form) {
      return;
    }

    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      control.setErrors(null);
      control.markAsPristine();
    });
    const controls: Array<AbstractControl> = Object.values(form.controls);
    controls.forEach((control: AbstractControl) => control.clearValidators());
  }

  mergeErrorsIntoForm(
    backendErrors: { [p: string]: any },
    formGroup: FormGroup
  ): void {
    for (const field of Object.keys(backendErrors)) {
      if (!formGroup.controls[field]) {
        continue;
      }
      formGroup.controls[field].setErrors({
        backendError: backendErrors[field].message
      });
    }
  }

  mergeViolationsIntoForm(
    violations: [{ propertyPath: string; message: string }],
    formGroup: FormGroup
  ): void {
    for (const { propertyPath, message } of violations) {
      if (!formGroup.get(propertyPath)) {
        continue;
      }
      formGroup.get(propertyPath).setErrors({ backendError: message });
    }
    formGroup.updateValueAndValidity();
  }

  mergeErrorResponseIntoForm(
    failResponse: { response: HttpErrorResponse },
    form
  ): void {
    if (failResponse?.response?.error?.violations) {
      this.mergeViolationsIntoForm(
        failResponse.response.error.violations,
        form
      );
    }
  }

  clearObject(object: {}): any {
    const newObject = { ...object };
    if (newObject['@id']) {
      delete newObject['@id'];
    }
    if (newObject['@context']) {
      delete newObject['@context'];
    }
    if (newObject['@type']) {
      delete newObject['@type'];
    }
    for (const key of Object.keys(newObject)) {
      if (typeof newObject[key] === 'object') {
        newObject[key] = this.clearObject(newObject[key]);
      }
    }
    return newObject;
  }

  patchForm(form: FormGroup, presets: any): void {
    if (!presets || !form) {
      return;
    }

    // todo: check plainPasswordConfirmation.updateValueAndValidity(); as an option for resetting error state
    // Remove API-specific properties
    const properties = presets; // this.clearObject(presets);

    if (!properties) {
      return;
    }
    // console.log('patchForm', properties);
    form.patchValue(properties);
    /*Object
      .keys(properties)
      .forEach(key => {
        console.log('forKey', key);

        let formInput = null;

        if (form.contains(key)) {
          formInput = form.get(key);
        }
        console.log('forKey', key, formInput);

        if (formInput && !!properties[key]) {

          // Note: In some cases, only an IRI reference is sent via frontend but a resolved object is returned via API ...
          if (this.propertiesRequiringIri.includes(key) && typeof properties[key] !== typeof 'String') {
            formInput.setValue(properties[key]['@id']);
            return;
          }

          // ... in other cases the form (a nested Form group, that is) needs to patched with the returned object.
          // handle nested value objects. todo: maybe use recursion or ngx-neat
          if (this.nestedProperties.includes(key) && typeof properties[key] === 'object') {

            // Remove API-specific properties
            const {['@type']: type, ['@context']: context, ['@id']: id, ...nestedFormGroup} = properties[key];

            Object
              .keys(nestedFormGroup)
              .forEach(nestedKey => {

                let nestedInput = null;
                const nestedGroup = form.controls[key];
                // @ts-ignore
                // const { ['@type']: type, ['@context']: context, ['@id']: id, ...nestedGroup } = form.controls[key];

                // @ts-ignore
                if (nestedGroup?.contains(nestedKey)) {
                  nestedInput = form.get(`${key}.${nestedKey}`);
                  nestedInput.setValue(nestedFormGroup[nestedKey]);
                }
              });
            return;
          }
          formInput.setValue(properties[key]);

          formInput.markAsTouched();
        }
      });
      /
     */
    form.markAsTouched();

    // console.log(form.getRawValue());
    // const controls: Array<AbstractControl> = Object.keys(form.controls).map(key => form.controls[key]);
    // controls.forEach((control: AbstractControl) => control.clearValidators());
  }
}
