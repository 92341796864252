import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';

import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Subject } from 'rxjs';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder
} from '../../helpers';
import { extractUUID } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-orders-list',
  styleUrls: ['./orders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
      mat-table
      [dataSource]="orders"
    >
      <!-- Dot Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a>
          <span class="badge--express" *ngIf="isExpress(element)">
            <span>Express</span>
          </span>
          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>

      <!-- Order Number Column -->
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Nr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ element.orderNumber }}
        </td>
      </ng-container>

      <!-- Salesperson Column -->
      <ng-container matColumnDef="salesperson">
        <th mat-header-cell *matHeaderCellDef>Vertriebler</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ element.salesperson?.firstName }} {{ element.salesperson?.lastName }}
        </td>
      </ng-container>

      <!-- Mode Column -->
      <ng-container matColumnDef="mode">
        <th mat-header-cell *matHeaderCellDef>Modus</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ element.analysisPriorityMode?.name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a>
          <span *ngIf="element?.customer?.vip" class="badge--vip m-0 small">
            <span>VIP</span>
          </span>
          {{ getCustomerName(element) }}
        </td>
      </ng-container>

      <!-- Company Column -->
      <ng-container matColumnDef="customerCompany">
        <th mat-header-cell *matHeaderCellDef>Firma</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ getCompanyName(element) }}
        </td>
      </ng-container>

      <!-- state Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            *ngIf="!orderSelection"
            class="stretched-link"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a>
          <strong>{{ element.stateRange }}</strong>
          <ng-container *ngIf="showMoreStates">
            <br />
            <span
              *ngFor="let key of element.state | keys; let isLast = last"
              class="text-color-lightgrey"
            >
              {{ key }}<span *ngIf="!isLast">; </span>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <!-- createdAt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ element.createdAt | momentDateWithTime }}
        </td>
      </ng-container>

      <!-- storageSystem Column -->
      <ng-container matColumnDef="storageSystem">
        <th mat-header-cell *matHeaderCellDef>Speichersystem</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            *ngIf="!orderSelection"
            [routerLink]="['/orders', extractUUID(element)]"
          ></a
          >{{ element.storageSystem?.name }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        (click)="onClickLine(row)"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.has-badge]="isExpress"
      ></tr>
    </table>
  `
})
export class OrdersListComponent implements OnInit, OnDestroy {
  @Input() orders: Array<Order>;
  @Input() @Optional() orderSelection = false;
  @Output() requestViewOrder: EventEmitter<Order> = new EventEmitter();
  onDestroy$: Subject<any> = new Subject<any>();
  displayedColumns: Array<string> = [
    'dot',
    'orderNumber',
    'salesperson',
    'mode',
    'customerName',
    'customerCompany',
    'state',
    'createdAt',
    'storageSystem'
  ];
  showMoreStates = false;
  getCustomerName = getCustomerNameFromOrder;
  // }
  getCompanyName = getCompanyNameFromOrder;
  protected readonly extractUUID = extractUUID;

  // getCustomerName(element: Order): string {
  //   if (element?.decisionMakerCustomerContact) {
  //     const contact = element.decisionMakerCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.technicalCustomerContact) {
  //     const contact = element.technicalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.organizationalCustomerContact) {
  //     const contact = element.organizationalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   }
  //   if (element.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return element.customer.nameLine1;
  //   } else {
  //     // Geschäftskunde
  //     return '-';
  //   }

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColorClass(element: Order): string[] {
    if (element.stateRange === 'Warte auf Schlusszahlung') {
      return ['red'];
    } else if (element.stateRange === 'Auftrag abgeschlossen') {
      return ['grey'];
    } else {
      return ['green'];
    }
  }

  // getCompanyName(element: Order): string {
  //   if (element.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return '-';
  //   } else {
  //     // Geschäftskunde
  //     return element.customer.nameLine1;
  //   }
  // }

  isExpress(order: Order): boolean {
    if(order.analysisPriorityMode) {
      return order.analysisPriorityMode && order.analysisPriorityMode.name.toLowerCase() === 'express';
    }
  }

  onClickLine(order: Order) {
    if (this.orderSelection) {
      this.requestViewOrder.emit(order);
    }
  }
}
