import {Component, EventEmitter, Input, Output} from '@angular/core';

import * as fromModuleModels from '../../models';
import {AddressType} from '../../../master-data/models';
import {StringsUtility} from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'customer-details',
  styleUrls: ['customer-details.component.scss'],
  template: `

    <div class="card m-b--32 mat-elevation-z1">

      <div class="card__heading">
        <span>Allgemeine Daten</span>

        <span class="btn--edit">
        <button mat-icon-button (click)="handleRequestCustomerForm()">
          <mat-icon>edit</mat-icon>
        </button>
      </span>
      </div>

      <div class="card__content m-b--18">

        <div class="overview grid">

          <strong class="column-4">Kunden-Nr.</strong>
          <p class="column-10">{{ customer.customerNumber }}</p>

          <strong class="column-4">Firma</strong>
          <p class="column-10">{{ customer.nameLine1 }} <span *ngIf="customer?.nameLine2">({{ customer.nameLine2 }}
            )</span></p>
          <strong class="column-4">Steuernummer</strong>
          <p class="column-10">{{ customer.taxNumber }}</p>

          <ng-container *ngFor="let t of customerAddressTypes">

            <strong class="column-4">{{ t.name }}</strong>
            <p class="column-10">{{ formatAddress(getAddress(t.customerAddressType)) }}</p>
          </ng-container>
        </div>

        <div class="contacts">

          <ng-container *ngFor="let cT of customerContactTypes; let i = index; let last = last">

            <contact-person-details
              [contact]="getDefaultContact(cT.mappingName)"
              [last]="last"
              [index]="i"
              [label]="cT.abbreviation"
            ></contact-person-details>
          </ng-container>
        </div>

        <ng-template #loading>
          <mat-spinnner
          ></mat-spinnner>
        </ng-template>
      </div>
    </div>
  `
})
export class CustomerDetailsComponent {

  @Input() addressTypes: Array<AddressType>;
  @Input() addresses: Array<fromModuleModels.CustomerAddress>;
  @Input() contacts: Array<fromModuleModels.CustomerContact>;
  @Input() customer: fromModuleModels.Customer;
  @Input() customerAddressEntities: { [iri: string]: fromModuleModels.CustomerAddress };
  @Input() customerContactTypes: Array<fromModuleModels.CustomerContactType>;

  @Output() requestShowCustomerForm: EventEmitter<fromModuleModels.Customer> = new EventEmitter();

  customerAddressTypes: Array<{ customerAddressType: string, name: string }> = [
    {customerAddressType: 'BillingAddress', name: 'Rechnungsadresse'},
    {customerAddressType: 'DeliveryAddress', name: 'Lieferadresse'},
  ];

  getAddress(addressType: string): fromModuleModels.CustomerAddress {
    return this.addresses.find(address => address && address['@id'] === this.customer[`default${addressType}`])?.address;
  }

  getDefaultContact(contactType: string): fromModuleModels.CustomerContact {
    return this.contacts.find(contact => contact && contact['@id'] === this.customer[`default${contactType}`]);
  }

  formatAddress(address: fromModuleModels.Address): string {
    return address ? StringsUtility.formatAddress(address) : 'Keine Standardadresse gesetzt';
  }

  handleRequestCustomerForm(): void {
    this.requestShowCustomerForm.emit(this.customer);
  }
}
