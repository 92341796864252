import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { AuthService } from '../../../auth/services/auth.service';
import { ParameterBag } from '../../../shared/models/ParameterBag.interface';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { extractIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-income-data-medium-delayed-widget',
  styleUrls: ['./income-data-medium-delayed-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Speichersystem für Analyse nicht eingegangen</span>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
          [additionalParams]="additionalParams"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class IncomeDataMediumDelayedWidgetComponent
  extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  states: string[];
  additionalParams: ParameterBag;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.states = ['incoming_dm_delayed'];
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(profile => {
        if (this.authService.isSales() || this.authService.isSalesExternal()) {
          this.additionalParams = {
            'salesperson.uuid': getUuidFromIri(extractIri(profile))
          };
        } else {
          this.additionalParams = {};
        }
      });
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }
}
