import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {QuillEditorComponent} from 'ngx-quill';
import {map, switchMap, takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {AdministratorsActions} from '../../../administrators/store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {LeadCommentTagsSelectors} from '../../store/selectors';
import {LeadCommentsActions, LeadCommentTagsActions} from '../../store';
import {Administrator} from '../../../administrators/models';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {Lead, LeadComment} from '../../models';
import {Actions, ofType} from '@ngrx/effects';

@Component({
  selector: 'app-lead-comment-form',
  styleUrls: ['lead-comment-form.component.scss'],
  template: `

    <div class="lead__comment-form" [formGroup]="lcf">

      <div class="filter grid grid-no-gutter">

        <div class="column-7">
        <span *ngFor="let tag of leadCommentTags$|async" class="filter__item">

          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="getCommentTagValue == tag['@id']"
                  (click)="toggleCommentTag(tag['@id'])"
          >{{ tag.commentTag }}</button>
        </span>
        </div>
        <div class="column-7 m-ta--2">
        <span class="filter__item">
          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'recipient'"
                  (click)="toggleAdditionalInputVisible('recipient')"
          >Rückfrage</button>
        </span>

          <span class="filter__item">
          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'additionalContent'"
                  (click)="toggleAdditionalInputVisible('additionalContent')"
          >Kundenkommentar</button>
        </span>
        </div>
      </div>

      <app-text-editor formControlName="content" [label]="getRecipientValue|async"></app-text-editor>

      <div class="grid" *ngIf="additionalInputVisible === 'recipient'">

        <div class="column-6 off-8 mt-2">

          <ng-select placeholder="Rückfrage richten an ..."
                     [items]="administrators$|async"
                     [loading]="administratorsIsLoading$|async"
                     bindValue="@id"
                     bindLabel="username"
                     [searchable]="false"
                     formControlName="recipient">

            <ng-template ng-label-tmp let-item="item">
              <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="ng-option">
                <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div *ngIf="additionalInputVisible === 'additionalContent'" style="margin-top: 25px">
        <app-text-editor formControlName="additionalContent" label="Kundenkommentar"></app-text-editor>
      </div>

      <div class="m-ta--2 m-t--24">
        <button mat-flat-button color="green" type="button"
                [disabled]="lcf && lcf.invalid"
                (click)="handleSubmit()">{{ isNew ? 'Kommentar anlegen' : 'Aktualisieren'  }} </button>
      </div>
    </div>
  `
})
export class LeadCommentFormComponent implements OnInit, OnDestroy {

  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  administrators$: Observable<Array<Administrator>>;
  administratorsIsLoading$: Observable<boolean>;

  leadCommentTags$: Observable<Array<fromModuleModels.LeadCommentTag>>;
  leadCommentTagsIsLoading$: Observable<boolean>;

  @Input() lead$: BehaviorSubject<Lead>;
  lead: Lead;
  @Input() leadComment$: BehaviorSubject<LeadComment>;
  leadComment: LeadComment;

  additionalInputVisible: string;
  isNew = true;
  additionalInputsChoices: Array<string> = ['additionalContent', 'recipient'];
  selectedAdministrator: Administrator = null;
  lcf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, public dialog: MatDialog, private cd: ChangeDetectorRef,
              private store$: Store<ApplicationState>,
              private actions$: Actions) {
  }


  ngOnInit(): void {
    this.initForm();
    this.loadAdministrators();
    this.loadLeadCommentTags();

    this.actions$.pipe(
      takeUntil(this.onDestroy$),
      ofType(
        LeadCommentsActions.UpdateLeadCommentSuccess,
        LeadCommentsActions.CreateLeadCommentSuccess
      )
    ).subscribe(() => {
      this.resetForm();
    });
    this.lead$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((lead) => {
        if (lead) {
          this.updateLead(lead);
          this.lead = lead;
        }
      });
    if (!this.leadComment$) {
      return;
    }
    this.leadComment$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((leadComment) => {
        this.isNew = !leadComment;
        if (!leadComment) {
          return;
        }
        this.leadComment = leadComment;
        this.restoreFormState(leadComment);
        this.lcf.markAsUntouched();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private updateLead(lead: Lead): void {
    this.lcf.patchValue({...lead, lead: lead['@id']});
  }

  private resetForm(): void {
    this.lcf.get('commentTag').reset();
    this.lcf.get('content').reset();
    this.lcf.get('additionalContent').reset();
    this.lcf.get('recipient').reset();
    this.cd.markForCheck();
  }


  initForm(): void {
    this.lcf = this.fb.group({
      lead: this.fb.control(null, [Validators.required]),
      commentTag: this.fb.control(null),
      content: this.fb.control('', [Validators.required]),
      additionalContent: this.fb.control(null),
      recipient: this.fb.control(null)
    });
    this.lcf.get('recipient').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        // console.log(value);
        this.selectedAdministrator = value;
      });
  }

  get getCommentTagValue(): string | null {
    return this.lcf.get('commentTag')?.value;
  }

  get getRecipientValue(): Observable<string | null> {
    if (!this.lcf) {
      return of('Eigener Kommentar');
    }
    if (this.lcf.get('recipient').value) {
      return this.store$.select(AdministratorsSelectors.sByIri, {iri: this.lcf.get('recipient').value})
        .pipe(map((adminFromState: Administrator) => {
          return `@${adminFromState?.firstName} ${adminFromState?.lastName}`;
        }));
    } else {
      return of('Eigener Kommentar');
    }
  }

  restoreFormState(comment?: fromModuleModels.LeadComment): void {
    if (!comment) {
      return;
    }

    Object.keys(comment).forEach(key => {
      const formInput = this.lcf.get(key);

      // check if both default value and formInput for given key exist
      if (formInput && !!comment[key]) {
        formInput.setValue(comment[key]);
        formInput.markAsTouched();

        if (key === 'recipient') {
          this.toggleAdditionalInputVisible('recipient');
          formInput.setValidators([Validators.required]);
        }

        if (key === 'additionalContent') {
          this.toggleAdditionalInputVisible('additionalContent');
          formInput.setValidators([Validators.required]);
        }
      }
    });
  }

  handleTextColorChange(color: string, propertyName: string, editorIndex: number): void {

    const editor = this.editors.toArray()[editorIndex].quillEditor;

    const defaultColor = '#000000';
    const {index, length} = editor.getSelection();
    const {color: currentColor} = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(
      index,
      length,
      {color: (currentColor === color) ? defaultColor : color}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.lcf.get(propertyName).setValue(editorHTML);
    this.triggerChangeDetection();
  }

  toggleCommentTag(iri: string): void {
    this.lcf.patchValue({commentTag: (this.getCommentTagValue === iri) ? null : iri});
    this.triggerChangeDetection();
  }

  // todo: side effects
  toggleAdditionalInputVisible(to: string): void {

    const currentValue = String(this.additionalInputVisible);
    const formField = this.lcf.get(currentValue);
    const informationLoss = this.additionalInputsChoices.includes(currentValue) && formField.dirty && (formField.value != '');

    if (informationLoss) {

      this.dialog.open(DialogComponent, {
        data: {
          heading: 'Achtung',
          text: 'Hierdurch geht eingegebener Text bzw. die Auswahl verloren. Fortfahren?',
          confirmationText: 'Weiter',
          cancelText: 'Abbrechen',
          disableClose: true,
        }
      }).afterClosed().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        (hasConfirmed: boolean) => {

          if (!!hasConfirmed) {

            this.additionalInputVisible = (currentValue === to) ? undefined : to;
            formField.patchValue(null);
            formField.markAsPristine();
          }
        }
      );
    } else {
      this.additionalInputVisible = (currentValue === to) ? undefined : to;
    }
  }

  handleSubmit(): void {

    const payload: fromModuleModels.LeadComment = this.lcf.value;

    this.isNew
      ? this.store$.dispatch(LeadCommentsActions.CreateLeadComment({payload}))
      : this.store$.dispatch(LeadCommentsActions.UpdateLeadComment({iri: this.leadComment['@id'], payload}));

  }

  triggerChangeDetection(): void {
    this.cd.markForCheck();
  }

  loadAdministrators(): void {
    this.administrators$ = this.store$.select(AdministratorsSelectors.selectAdministratorsWithoutLoggedInUser);
    this.administratorsIsLoading$ = this.store$.select(AdministratorsSelectors.isLoading);
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());
  }

  loadLeadCommentTags(): void {
    this.leadCommentTags$ = this.store$.select(LeadCommentTagsSelectors.selectLeadCommentTags);
    this.leadCommentTagsIsLoading$ = this.store$.select(LeadCommentTagsSelectors.isLoading);
    loadIfNotLoaded(this.store$, LeadCommentTagsSelectors.isLoaded, LeadCommentTagsActions.LoadLeadCommentTags());
  }

}
