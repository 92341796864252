import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';


import * as fromInvoicesModuleModels from '../../../invoices/models';
import {extractIri, extractTypeByIri, extractUUID} from "../../../shared/utilities/objects.utility";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {CorrectionInvoicesActions} from "../../store";

@Component({
  selector: 'correction-invoice-facturer',
  styleUrls: ['correction-invoice-facturer.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading" [class.green]="!!invoice?.bookedAt">
        <span>Details zur Korrekturrechnung</span>
      </div>

      <div class="card__content grid">
        <div class="column-14 my-2" *ngIf="invoice.correctedInvoice">
          Korrekturrechung zu: <a
          [routerLink]="['/invoices','payable',extractTypeByIri(invoice.correctedInvoice), extractUUID(invoice.correctedInvoice)]">{{correctedInvoiceLinkName}}</a>
        </div>
        <div class="column-14 m-ta--2">

          <button color="outline"
                  mat-flat-button
                  class="m-r--16"
                  [disabled]="!isInvoiceBookable(invoice)"
                  (click)="handleRequestBookCorrectionInvoice(invoice)">
            <mat-icon class="m-r--8">recommend</mat-icon>
            <span>Gutschrift buchen</span>
          </button>

          <button mat-flat-button
                  color="green"
                  [disabled]="formGroup.invalid || formGroup.pristine"
                  (click)="handleRequestRequestCreateOrUpdateInvoice(invoice)">
            <mat-icon class="m-r--8">{{ invoice ? 'update' : 'description' }}</mat-icon>
            <span>{{ invoice ? 'Aktualisieren' : 'Speichern' }}</span>
          </button>
        </div>
      </div>

      <div class="card__footer">
        <div class="grid">

      <span class="column-6">

        <button
          *ngIf="(invoice.state === 'booked') || invoice.state === 'paid_out'"
          mat-flat-button
          (click)="handleRequestDownloadInvoice(invoice)"
          style="color: #bbb">
          <mat-icon class="m-r--8">description</mat-icon>
          <span>Download</span>
        </button>
      </span>

          <span class="column-8 m-ta--2">
        <span *ngIf="invoice?.bookedAt" class="booked-wrapper">
          <mat-icon class="booked-icon m-r--8">thumb_up</mat-icon>
          <span>Korrekturrechnung am {{ invoice.bookedAt | date: 'dd.MM.Y' }} gebucht</span>
        </span>
      </span>
        </div>
      </div>
    </div>

    <!--<pre>{{ administratorEntities | json }}</pre>-->
    <!--<pre>{{ formGroup.value | json }}</pre>-->
  `
})
export class CorrectionInvoiceFacturerComponent {

  @Input() formGroup: FormGroup;
  @Input() invoice: fromInvoicesModuleModels.CorrectionInvoice;

  @Output()
  requestDownloadInvoice: EventEmitter<string> = new EventEmitter();

  @Output()
  requestBookInvoice: EventEmitter<string> = new EventEmitter();

  @Output()
  requestCreateInvoice: EventEmitter<string> = new EventEmitter();

  @Output()
  requestUpdateInvoice: EventEmitter<string> = new EventEmitter();

  constructor(
    private store$: Store<ApplicationState>,
  ) {
  }

  get correctedInvoiceLinkName(): string {
    switch (extractTypeByIri(this.invoice.correctedInvoice)) {

      case 'invoices':
        return 'Rechnung ' + extractUUID(this.invoice.correctedInvoice);
      case 'partial_invoices':
        return 'Teilrechnung ' + extractUUID(this.invoice.correctedInvoice);
      default:
        return '';
    }
  }

  handleRequestDownloadInvoice(invoice: fromInvoicesModuleModels.CorrectionInvoice): void {
    this.store$.dispatch(CorrectionInvoicesActions.ReadPDF({iri: extractIri(invoice)}));
  }

  handleRequestBookCorrectionInvoice(invoice: fromInvoicesModuleModels.CorrectionInvoice): void {
    if (!invoice) {
      alert('Invoice must be set');
      return;
    }
    this.requestBookInvoice.emit(invoice['@id']);
  }

  isInvoiceBookable(invoice: fromInvoicesModuleModels.CorrectionInvoice): boolean {
    return !!invoice && !!invoice.potentialActions?.filter(action => action.transition === 'book').length && invoice.state !== 'booked';
  }

  handleRequestRequestCreateOrUpdateInvoice(credit?: fromInvoicesModuleModels.CorrectionInvoice): void {

    if (!credit) {
      this.requestCreateInvoice.emit();
    } else {
      this.requestUpdateInvoice.emit(credit['@id']);
    }
  }

  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractUUID = extractUUID;
}
