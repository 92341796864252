import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { DataRecoveryCosts, Order } from '../../../orders/models';
import { LabLocation } from '../../../master-data/models';

@Component({
  selector: 'app-ticket-costs-form',
  styleUrls: ['ticket-costs-form.component.scss'],
  template: `
    <div class="grid grid-top">
      <mat-form-field class="column-7">
        <mat-placeholder>Datenretter</mat-placeholder>
        <input
          type="text"
          matInput
          disabled
          [value]="_dataRecoveryCosts.analysisLocation"
        />
        <!--<mat-hint align="start" *ngIf="errors?.reference">{{ errors.reference.message }}</mat-hint>-->
      </mat-form-field>

      <mat-form-field class="column-7">
        <mat-placeholder>Datum</mat-placeholder>
        <input
          type="text"
          matInput
          disabled
          [value]="_dataRecoveryCosts.createdAt | date: 'dd.MM.Y'"
        />
        <!--<mat-hint align="start" *ngIf="errors?.reference">{{ errors.reference.message }}</mat-hint>-->
      </mat-form-field>

      <ng-container *ngFor="let form of getForms(); let first = first">
        <div class="column-7 grid" [formGroup]="form.formGroup">
          <mat-form-field
            class="column-14"
            [formGroupName]="form.priceType + 'PriceInitial'"
          >
            <mat-label>Preis Initialkosten {{ form.labelSuffix }}</mat-label>
            <input
              formControlName="value"
              type="text"
              matInput
              required
              currencyMask
              [options]="{ prefix: '' }"
            />
            <mat-error>
              <app-form-error
                [fieldName]="form.priceType + 'PriceInitial' + '.value'"
                [formGroup]="form.formGroup"
              ></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="column-14"
            [formGroupName]="form.priceType + 'PriceDataRecovery'"
          >
            <mat-label>Preis Datenrettung {{ form.labelSuffix }}</mat-label>
            <input
              formControlName="value"
              type="text"
              matInput
              required
              currencyMask
              [options]="{ prefix: '' }"
            />
            <mat-error>
              <app-form-error
                [fieldName]="form.priceType + 'PriceDataRecovery' + '.value'"
                [formGroup]="form.formGroup"
              ></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            class="column-14"
            [formGroupName]="form.priceType + 'PriceOther'"
          >
            <mat-label>Preis Sonstiges {{ form.labelSuffix }}</mat-label>
            <input
              formControlName="value"
              type="text"
              matInput
              required
              currencyMask
              [options]="{ prefix: '' }"
            />
            <mat-error>
              <app-form-error
                [fieldName]="form.priceType + 'PriceOther' + '.value'"
                [formGroup]="form.formGroup"
              ></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Kommentar {{ form.labelSuffix }}</mat-label>
            <textarea
              matInput
              [formControlName]="form.priceType + 'PriceComment'"
              rows="5"
              cdkTextareaAutosize
            ></textarea>
            <mat-error>
              <app-form-error
                [fieldName]="form.priceType + 'PriceComment'"
                [formGroup]="form.formGroup"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            color="green"
            [disabled]="form.formGroup.invalid || form.formGroup.pristine"
            (click)="handleSubmit(form)"
          >
            <mat-icon class="m-r--8">edit</mat-icon>
            <span>{{ form.labelSuffix }} speichern</span>
          </button>
        </div>
      </ng-container>
    </div>
  `
})
export class TicketCostsFormComponent implements OnInit, OnDestroy {
  @Input() labLocationsEntities: { [p: string]: LabLocation };
  @Input() dataRecoveryCosts: DataRecoveryCosts;
  @Input() order: Order;

  @Output() requestCloseDialog: EventEmitter<void> = new EventEmitter();
  @Output() requestUpdateSellingPrices: EventEmitter<{
    iri: string;
    payload: DataRecoveryCosts;
  }> = new EventEmitter();
  @Output() requestUpdatePurchasePrices: EventEmitter<{
    iri: string;
    payload: DataRecoveryCosts;
  }> = new EventEmitter();

  forms: {
    [k: string]: {
      formGroup: FormGroup;
      priceType: string;
      labelSuffix: string;
    };
  } = {
    ppf: { formGroup: null, priceType: 'purchase', labelSuffix: 'EK' },
    spf: { formGroup: null, priceType: 'selling', labelSuffix: 'VK' }
  };

  onDestroy$: Subject<any> = new Subject<any>();
  _dataRecoveryCosts: DataRecoveryCosts;

  constructor(private fb: FormBuilder) {}

  getForms(): Array<{
    formGroup: FormGroup;
    priceType: string;
    labelSuffix: string;
  }> {
    return Object.values(this.forms);
  }

  ngOnInit(): void {
    this._dataRecoveryCosts = { ...this.dataRecoveryCosts };
    this.initForms();
  }

  initForms(): void {
    const initData = this?._dataRecoveryCosts;
    this.forms.ppf.formGroup = this.fb.group({
      [`purchasePriceInitial`]: this.fb.group({
        value: this.fb.control(
          initData.purchasePriceInitial
            ? initData.purchasePriceInitial.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`purchasePriceDataRecovery`]: this.fb.group({
        value: this.fb.control(
          initData?.purchasePriceDataRecovery
            ? initData.purchasePriceDataRecovery.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`purchasePriceOther`]: this.fb.group({
        value: this.fb.control(
          initData?.purchasePriceOther
            ? initData.purchasePriceOther.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      ['purchasePriceComment']: this.fb.control(
        initData?.purchasePriceComment ? initData?.purchasePriceComment : ''
      )
    });

    this.forms.spf.formGroup = this.fb.group({
      [`sellingPriceInitial`]: this.fb.group({
        value: this.fb.control(
          initData?.sellingPriceInitial
            ? initData.sellingPriceInitial.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`sellingPriceDataRecovery`]: this.fb.group({
        value: this.fb.control(
          initData?.sellingPriceDataRecovery
            ? initData.sellingPriceDataRecovery.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`sellingPriceOther`]: this.fb.group({
        value: this.fb.control(
          initData?.sellingPriceOther
            ? this._dataRecoveryCosts.sellingPriceOther.value
            : null,
          [Validators.required]
        ),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      sellingPriceComment: this.fb.control(
        initData?.sellingPriceComment ? initData?.sellingPriceComment : ''
      )
    });
  }

  handleSubmit(
    form: {
      formGroup: FormGroup;
      priceType: string;
      labelSuffix: string;
    } = null
  ): void {
    const values = form.formGroup.getRawValue();
    const payload = { ['@id']: this.dataRecoveryCosts['@id'] };
    for (const key of Object.keys(values)) {
      if (values[key].hasOwnProperty('value')) {
        payload[key] = {
          value: values[key].value === 0 ? '0.0' : values[key].value.toString()
        };
      } else {
        payload[key] = values[key];
      }
    }
    console.log(payload);
    if (form.priceType === 'purchase') {
      this.requestUpdatePurchasePrices.emit({
        iri: this._dataRecoveryCosts['@id'],
        payload
      });
    } else {
      this.requestUpdateSellingPrices.emit({
        iri: this._dataRecoveryCosts['@id'],
        payload
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
