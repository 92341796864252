import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { ProductUnit } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ProductUnitsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readProductUnit(iri: string): Observable<ProductUnit> {
    return this.rolesService.userHasRoleFilter<ProductUnit>(
      'ROLE_MWS_PRODUCT_UNIT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readProductUnits(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PRODUCT_UNIT_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/product_units`)
    );
  }
}
