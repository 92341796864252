import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {InvoiceItemsActions} from '../actions/';
import {InvoiceItemsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {NotifierService} from 'angular-notifier';
import {InvoiceItem} from '../../models';
import {DepartmentLogosActions} from "../../../master-data/store";

@Injectable()
export class InvoiceItemsEffects {

  constructor(private actions$: Actions, private service: InvoiceItemsService, private notifierService: NotifierService) {
  }

  CreateInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceItemsActions.CreateInvoiceItem),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createInvoiceItem(payload).pipe(
        map((response) => InvoiceItemsActions.CreateInvoiceItemSuccess({response, invoiceIri})),
        catchError(response => of(InvoiceItemsActions.CreateInvoiceItemFail({response})))
      );
    })
  ));

  ReadInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceItemsActions.ReadInvoiceItem),
    switchMap(({iri}) => {
      return this.service.readInvoiceItem(iri).pipe(
        map((response => InvoiceItemsActions.ReadInvoiceItemSuccess({response}))),
        catchError(response => of(InvoiceItemsActions.ReadInvoiceItemFail({response})))
      );
    })
  ));

  UpdateInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceItemsActions.UpdateInvoiceItem),
    switchMap(({iri, payload, invoiceIri}) => {
      return this.service.updateInvoiceItem(iri, payload).pipe(
        map((response: InvoiceItem) => InvoiceItemsActions.UpdateInvoiceItemSuccess({
          response,
          invoiceIri
        })),
        catchError((response: HttpErrorResponse) => of(InvoiceItemsActions.UpdateInvoiceItemFail({response})))
      );
    })
  ));

  DeleteInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceItemsActions.DeleteInvoiceItem),
    switchMap(({iri, invoiceIri}) => {
      return this.service.deleteInvoiceItem(iri).pipe(
        map(() => InvoiceItemsActions.DeleteInvoiceItemSuccess({iri, invoiceIri})),
        catchError((response: HttpErrorResponse) => of(InvoiceItemsActions.DeleteInvoiceItemFail({response})))
      );
    })
  ));
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      InvoiceItemsActions.CreateInvoiceItemFail,
      InvoiceItemsActions.UpdateInvoiceItemFail,
      InvoiceItemsActions.DeleteInvoiceItemFail,
    ),
    map(({type, response}) => {
      console.log({response});
      const errors = response?.error['hydra:description'];
      const message = 'Fehler bei der Anfrage: ' + errors;
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      InvoiceItemsActions.CreateInvoiceItemSuccess,
      InvoiceItemsActions.UpdateInvoiceItemSuccess,
      InvoiceItemsActions.DeleteInvoiceItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case InvoiceItemsActions.CREATE_INVOICE_ITEM_SUCCESS:
          message = 'Ein neue Rechnungsposition wurde angelegt.';
          break;
        case InvoiceItemsActions.UPDATE_INVOICE_ITEM_SUCCESS:
          message = 'Die Rechnungsposition wurde aktualisiert.';
          break;
        case InvoiceItemsActions.DELETE_INVOICE_ITEM_SUCCESS:
          message = 'Die Rechnungsposition wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

}
