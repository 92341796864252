import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read

export const READ_DATA_RECOVERY_RESULT = '[Orders Module] Read Data Recovery Result';
export const READ_DATA_RECOVERY_RESULT_SUCCESS = '[Orders Module] Read Data Recovery Result Success';
export const READ_DATA_RECOVERY_RESULT_FAIL = '[Orders Module] Read Data Recovery Result Fail';

export const ReadDataRecoveryResult = createAction(
  READ_DATA_RECOVERY_RESULT,
  props<{ iri: string }>()
);

export const ReadDataRecoveryResultSuccess = createAction(
  READ_DATA_RECOVERY_RESULT_SUCCESS,
  props<{ response: fromOrdersModuleModels.DataRecoveryResult }>()
);

export const ReadDataRecoveryResultFail = createAction(
  READ_DATA_RECOVERY_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_DATA_RECOVERY_RESULT = '[Orders Module] Update Data Recovery Result';
export const UPDATE_DATA_RECOVERY_RESULT_SUCCESS = '[Orders Module] Update Data Recovery Result Success';
export const UPDATE_DATA_RECOVERY_RESULT_FAIL = '[Orders Module] Update Data Recovery Result Fail';

export const UpdateDataRecoveryResult = createAction(
  UPDATE_DATA_RECOVERY_RESULT,
  props<{ iri: string, payload: { recoveredDataPercentage: number, comment: string } }>()
);

export const UpdateDataRecoveryResultSuccess = createAction(
  UPDATE_DATA_RECOVERY_RESULT_SUCCESS,
  props<{ response: fromOrdersModuleModels.DataRecoveryResult }>()
);

export const UpdateDataRecoveryResultFail = createAction(
  UPDATE_DATA_RECOVERY_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset State

export const RESET_DATA_RECOVERY_RESULTS_STATE = '[Orders Module] Reset Data Recovery Results State';

export const ResetDataRecoveryResultsState = createAction(
  RESET_DATA_RECOVERY_RESULTS_STATE,
);
