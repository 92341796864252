import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Offer, CorrectionInvoice, CancellationInvoice} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {RouterActions} from '../../../application-state/store/actions';
import {extractUUID, extractTypeByIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-cancellation-invoices-list',
  styleUrls: ['cancellation-invoices-list.component.scss'],
  template: `
    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >
      <!-- Dot Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef style="width: 20px;"></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          <!--<span class="badge--express" *ngIf="isVIP(element)">
                    <span>Express</span>
                  </span>-->
          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>
      <!-- cancellationInvoiceNumber Column -->
      <ng-container matColumnDef="cancellationInvoiceNumber">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">Nr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{ element.cancellationInvoiceNumber }}
        </td>
      </ng-container>

      <!-- Order Number -->
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">A</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{
          element?.canceledInvoice?.paymentProcess?.order
            ? element.canceledInvoice.paymentProcess.order.orderNumber
            : ''
          }}
        </td>
      </ng-container>

      <!--<ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>-->

      <!-- Recipient Column -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Empfänger [Zusatz]</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{ element.recipient.nameLine1 }}

          <ng-container *ngIf="element.recipient.nameLine2">
            [{{ element.recipient.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>

      <!-- Issuer Column -->
      <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef>Bereich</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>
          {{ element.issuer.name }}</td>
      </ng-container>

      <!-- Net Total Column -->
      <ng-container matColumnDef="netTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (netto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{ element.netTotal.value | number: '1.2-2' }}
          {{ element.netTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Gross Total Column -->
      <ng-container matColumnDef="grossTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (brutto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{ element.grossTotal.value | number: '1.2-2' }}
          {{ element.grossTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- CreatedAt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'cancellation', extractId(element)]"></a>

          {{ element?.createdAt | date: 'dd.MM.Y' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  `
})
export class CancellationInvoicesListComponent {
  @Input() items: Array<CancellationInvoice>;
  @Input() totalItems: number;

  @Output() requestGoToCancellationInvoiceView: EventEmitter<
    string
  > = new EventEmitter();

  displayedColumns: Array<string> = [
    'dot',
    'cancellationInvoiceNumber',
    'orderNumber',
    'recipient',
    'issuer',
    'netTotal',
    'grossTotal',
    'createdAt'
  ];

  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractId = extractUUID;

  constructor(private store$: Store<ApplicationState>) {
  }

  getCircleColorClass(element: CancellationInvoice): string[] {
    // if (element.) {
    //   return ['red'];
    // } else
    if (!element.cancellationInvoiceNumber) {
      return ['grey'];
    } else {
      return ['green'];
    }
  }

  handleClick(invoice: Offer): void {
    // TODO use UUID not iri
    // this.store$.dispatch(RouterActions.Go({path: ['invoices', 'payable', getUuidFromIri(invoice['@id'])]}));

    this.store$.dispatch(
      RouterActions.Go({path: ['invoices', 'cancellation', invoice['@id']]})
    );
  }

}
