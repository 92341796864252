import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {ProductOrderItemsActions, ServiceOrderItemsActions} from '../actions';
import {ProductOrderItemsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class ProductOrderItemsEffects {

  constructor(private actions$: Actions, private service: ProductOrderItemsService, private notifierService: NotifierService) {
  }


  createProductOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ProductOrderItemsActions.CreateProductOrderItem),
    switchMap(({payload}) => {

      return this.service
        .createProductOrderItem(payload)
        .pipe(
          map((response: any) => ProductOrderItemsActions.CreateProductOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ProductOrderItemsActions.CreateProductOrderItemFail({response})))
        );
    })
  ));


  readProductOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ProductOrderItemsActions.ReadProductOrderItem),
    switchMap(({iri}) => {

      return this.service
        .readProductOrderItem(iri)
        .pipe(
          map((response: any) => ProductOrderItemsActions.ReadProductOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ProductOrderItemsActions.ReadProductOrderItemFail({response})))
        );
    })
  ));


  readProductOrderItems$ = createEffect(() => this.actions$.pipe(
    ofType(ProductOrderItemsActions.ReadProductOrderItems),
    switchMap(({page, orderUuid}) => {

      return this.service
        .readProductOrderItems(orderUuid, page)
        .pipe(
          map((response: any) => ProductOrderItemsActions.ReadProductOrderItemsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ProductOrderItemsActions.ReadProductOrderItemsFail({response})))
        );
    })
  ));


  updateProductOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ProductOrderItemsActions.UpdateProductOrderItem),
    switchMap(({iri, payload}) => {

      return this.service
        .updateProductOrderItem(iri, payload)
        .pipe(
          map((response: any) => ProductOrderItemsActions.UpdateProductOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ProductOrderItemsActions.UpdateProductOrderItemFail({response})))
        );
    })
  ));


  deleteProductOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ProductOrderItemsActions.DeleteProductOrderItem),
    switchMap(({iri}) => {

      return this.service
        .deleteProductOrderItem(iri)
        .pipe(
          map(() => ProductOrderItemsActions.DeleteProductOrderItemSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(ProductOrderItemsActions.DeleteProductOrderItemFail({response})))
        );
    })
  ));
  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      ProductOrderItemsActions.UpdateProductOrderItemSuccess,
      ProductOrderItemsActions.CreateProductOrderItemSuccess,
      ProductOrderItemsActions.DeleteProductOrderItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case ProductOrderItemsActions.CREATE_PRODUCT_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde angelegt.';
          break;
        case ProductOrderItemsActions.UPDATE_PRODUCT_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde aktualisiert.';
          break;
        case ProductOrderItemsActions.DELETE_PRODUCT_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});
}
