import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {HttpErrorResponse} from '@angular/common/http';

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_USER_PROFILE = '[Auth Module] Read Profile';
export const READ_USER_PROFILE_SUCCESS = '[Auth Module] Read Profile Success';
export const READ_USER_PROFILE_FAIL = '[Auth Module] Read Profile Fail';

export const ReadUserProfile = createAction(
  READ_USER_PROFILE,
  props<{ iri: string }>()
);

export const ReadUserProfileSuccess = createAction(
  READ_USER_PROFILE_SUCCESS,
  props<{ response: any }>()
);

export const ReadUserProfileFail = createAction(
  READ_USER_PROFILE_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_USER_PROFILE = '[Auth Module] Update User Profile';
export const UPDATE_USER_PROFILE_SUCCESS = '[Auth Module] Update User Profile Success';
export const UPDATE_USER_PROFILE_FAIL = '[Auth Module] Update User Profile Fail';

export const UpdateUserProfile = createAction(
  UPDATE_USER_PROFILE,
  props<{ iri: string, payload: any }>()
);

export const UpdateUserProfileSuccess = createAction(
  UPDATE_USER_PROFILE_SUCCESS,
  props<{ response: any }>()
);

export const UpdateUserProfileFail = createAction(
  UPDATE_USER_PROFILE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
