import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { DepartmentAd } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DepartmentAdsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createDepartmentAd(payload: {
    file: File;
    uuid?: string;
  }): Observable<AbstractApiResponse | any> {
    // Manually create FormDate for proper image processing
    const formData: FormData = new FormData();
    formData.append('file', payload.file, payload.file.name);

    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }

    return this.rolesService.userHasRoleFilter<DepartmentAd>(
      'ROLE_MWS_DEPARTMENT_AD_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/department_ads`, formData)
    );
  }

  getDepartmentAdInformation(iri: string): Observable<DepartmentAd> {
    return this.rolesService.userHasRoleFilter<DepartmentAd>(
      'ROLE_MWS_DEPARTMENT_AD_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getDepartmentAdAsImage(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_DEPARTMENT_AD_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getImage(iri + '?disposition=inline', true)
    );
  }

  getDepartmentAds(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_AD_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/department_ads`)
    );
  }

  // No real usage existing, Backend do not have this route
  // updateDepartmentAd({iri, payload}): Observable<DepartmentAd> {
  //   return  this.apiService.updateObject(iri, payload, true));
  // }

  deleteDepartmentAd(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_AD_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
