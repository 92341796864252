import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { DepartmentsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Department };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    DepartmentsActions.CreateDepartment,
    DepartmentsActions.ReadDepartment,
    DepartmentsActions.ReadDepartments,
    DepartmentsActions.UpdateDepartment,
    DepartmentsActions.DeleteDepartment,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(DepartmentsActions.ReadDepartmentSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(DepartmentsActions.ReadDepartmentsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Department }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    DepartmentsActions.CreateDepartmentSuccess,
    DepartmentsActions.UpdateDepartmentSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        errors: {},
        loading: false,
        loaded: true
      };
    }
  ),
  on(DepartmentsActions.DeleteDepartmentSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    DepartmentsActions.CreateDepartmentFail,
    DepartmentsActions.ReadDepartmentFail,
    DepartmentsActions.ReadDepartmentsFail,
    DepartmentsActions.UpdateDepartmentFail,
    DepartmentsActions.DeleteDepartmentFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
