import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IpVersion, RxwebValidators} from '@rxweb/reactive-form-validators';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {FormsService} from '../../../shared/services';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {Ticket} from '../../models';
import {RemoteAccessReasonsSelectors} from '../../store/selectors';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {TicketsActions} from '../../store';
import {extractIri} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-remote-access-form',
  styleUrls: ['remote-access-form.component.scss'],
  template: `

    <div class="grid" [formGroup]="raf">

      <div class="mat-form-field column-14">

        <ng-select
          placeholder="Typ*"
          [items]="remoteAccessReasons$ | async"
          bindLabel="name"
          bindValue="@id"
          [searchable]="false"
          formControlName="remoteAccessReason"
        ></ng-select>
        <mat-hint align="start" *ngIf="errors?.remoteAccessReason">{{ errors.remoteAccessReason.message }}</mat-hint>
      </div>

      <mat-form-field class="column-14">
        <mat-placeholder>URL*</mat-placeholder>
        <input formControlName="ip" type="text" minlength="7" maxlength="15" size="15" matInput>
        <mat-hint align="start" *ngIf="errors?.ip">{{ errors.ip.message }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="column-14">
        <mat-placeholder>User*</mat-placeholder>
        <input matInput formControlName="user" type="text">
        <mat-hint align="start" *ngIf="errors?.user">{{ errors.user.message }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="column-14">
        <mat-placeholder>Passwort*</mat-placeholder>
        <input matInput formControlName="password" type="text">
        <mat-hint align="start" *ngIf="errors?.password">{{ errors.password.message }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="column-14">
        <mat-placeholder>Bemerkung*</mat-placeholder>
        <textarea matInput formControlName="comment" rows="5"></textarea>
        <mat-hint align="start" *ngIf="errors?.comment">{{ errors.comment.message }}</mat-hint>
      </mat-form-field>

      <div class="column-14">

        <div class="m-ta--2">
          <button mat-flat-button color="green" type="button"
                  [disabled]="raf.invalid"
                  (click)="handleSubmit()">{{ isEdit ? 'Aktualisieren' : 'Zugriff anlegen' }}</button>
        </div>
      </div>
    </div>

    <!--<pre>{{ raf.value | json }}</pre>-->
  `
})
export class RemoteAccessFormComponent implements OnInit, OnDestroy {

  @Input() errors: ErrorsObject;
  remoteAccessReasons$: Observable<Array<fromModuleModels.RemoteAccessReason>>;
  @Input() ticket$: BehaviorSubject<Ticket>;
  ticket: Ticket;
  isEdit = false;
  @Output() editCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestUpdateRemoteAccess: EventEmitter<{
    iri: string,
    payload: { remoteAccess: fromModuleModels.RemoteAccess }
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  raf: FormGroup;

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
  ) {
  }

  ngOnInit(): void {
    this.initForm();

    this.ticket$.pipe(
      takeUntil(this.onDestroy$),
      filter(ticket => !!ticket)
    ).subscribe(ticket => {
      this.isEdit = true;
      this.ticket = ticket;
      if (ticket.remoteAccess.remoteAccessReason) {
        ticket.remoteAccess.remoteAccessReason = extractIri(ticket.remoteAccess.remoteAccessReason);
      }
      this.fs.patchForm(this.raf, ticket.remoteAccess);
      this.raf.markAsUntouched();
    });
    this.remoteAccessReasons$ = this.store$.select(RemoteAccessReasonsSelectors.sList);

  }

  initForm(): void {
    this.raf = this.fb.group({
      remoteAccessReason: this.fb.control({disabled: true}),
      ip: this.fb.control(''),
      user: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null),
      comment: this.fb.control(null),
    });
  }

  handleSubmit(): void {
    this.store$.dispatch(TicketsActions.UpdateTicket({
      iri: this.ticket['@id'],
      payload: {remoteAccess: this.raf.value}
    }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
