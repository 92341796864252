import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as fromInvoicesModuleModels from '../../../invoices/models';

@Component({
  selector: 'customer-invoice-list',
  styleUrls: ['customer-invoice-list.component.scss'],
  template: `

    <div class="card">
      <div class="card__heading">
        <div class="col-6">

          <span>Rechnungen für diesen Kunden</span>
        </div>
        <div class="col-6 text-right">
          <button mat-flat-button (click)="requestNewCorrectionInvoice.emit()">
            <mat-icon class="inline-icon text-small me-2">add</mat-icon>
            Neue Gutschrift erstellen
          </button>
          <button mat-flat-button (click)="requestNewInvoice.emit()">
            <mat-icon class="inline-icon text-small me-2">add</mat-icon>
            Neue Rechnung erstellen
          </button>
        </div>
      </div>

      <div class="card__content">
        <div class="order-list__outer">
          <div class="order-list__wrapper wrap">
            <div class="order-list grid">

              <div class="column-14">

                <div *ngIf="!items?.length; else table" style="padding: 24px;">
                  Keine Datensätze vorhanden
                </div>

                <ng-template #table>

                  <table *ngIf="items?.length" class="table" mat-table [dataSource]="items">

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef>Art</th>
                      <td mat-cell *matCellDef="let element">{{ getTypeLabel(element['@type']) }}</td>
                    </ng-container>

                    <!-- Number Column -->
                    <ng-container matColumnDef="number">
                      <th mat-header-cell *matHeaderCellDef>Number</th>
                      <td mat-cell *matCellDef="let element">{{ element.number }}</td>
                    </ng-container>

                    <!-- createdAt Column -->
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef>Erstellt</th>
                      <td mat-cell *matCellDef="let element">{{ element.createdAt | date:'dd.MM.Y - HH:mm' }} Uhr</td>
                    </ng-container>

                    <!-- Gross Total Column -->
                    <ng-container matColumnDef="grossTotal">
                      <th mat-header-cell *matHeaderCellDef>Gesamtsumme</th>
                      <td mat-cell
                          *matCellDef="let element">{{ element.grossTotal.value | number:'1.2-2' }} {{ element.grossTotal.currency|currencyShortener }}</td>
                    </ng-container>

                    <!-- amountPaid Column -->
                    <ng-container matColumnDef="amountPaid">
                      <th mat-header-cell *matHeaderCellDef>Betrag Gezahlt</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.amountPaid">
                          <strong
                            class="green">{{ element.amountPaid.value | number:'1.2-2' }} {{ element.amountPaid.currency|currencyShortener }}</strong>
                        </ng-container>
                      </td>
                    </ng-container>

                    <!-- amountOutstanding Column -->
                    <ng-container matColumnDef="amountOutstanding">
                      <th mat-header-cell *matHeaderCellDef>Offener Betrag</th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.amountOutstanding">
                          <strong [class.red]="isInTheRed(element.amountOutstanding.value)">
                            {{ element.amountOutstanding.value | number:'1.2-2' }} {{ element.amountOutstanding.currency|currencyShortener }}
                          </strong>
                        </ng-container>
                      </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">

                        <button mat-icon-button (click)="handleRequestViewCustomerInvoice(element['@id'])">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CustomerInvoiceListComponent implements OnInit {

  @Input() items: Array<fromInvoicesModuleModels.CorrectionInvoice>;

  @Output() requestFetchCustomerInvoices: EventEmitter<void> = new EventEmitter();
  @Output() requestViewCustomerInvoice: EventEmitter<string> = new EventEmitter();
  @Output() requestNewInvoice: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestNewCorrectionInvoice: EventEmitter<void> = new EventEmitter<void>();
  displayedColumns = ['type', 'number', 'createdAt', 'grossTotal', 'amountPaid', 'amountOutstanding', 'actions'];

  constructor() {
  }

  getTypeLabel(type: string): string {
    return {
      CancellationInvoice: 'Storno-Rechnung',
      CommissionCredit: 'Gutschrift',
      CorrectionInvoice: 'Gutschrift',
      Invoice: 'Rechnung',
      PartialInvoice: 'Rechnung',
    }[type];
  }

  ngOnInit(): void {
    this.requestFetchCustomerInvoices.emit();
  }

  handleRequestViewCustomerInvoice(customerInvoice: string): void {
    this.requestViewCustomerInvoice.emit(customerInvoice);
  }

  isInTheRed(value: string): boolean {
    return parseFloat(value) > 0;
  }
}
