<div class="action-box">
  <div class="action-required-label">
    {{"shared.action_box.action_required" | translate}}
  </div>
  <div class="content">
    <div class="row">
      <div class=" col-auto my-2 align-items-center">
        <div class="row">
          <div class=" col-auto header">
            {{"shared.action_box.confirm_costs" | translate}}
          </div>
        </div>
        </div>
      </div>
    <div
      class="additional-content data-recovery-price-checked"
      [formGroup]="form"
      *ngIf="canMakeTransition"
    >
      <div class="row">
        <div class="col-lg-5">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.price_initial_costs" | translate}}</h4>
              <mat-form-field
                formGroupName="calculatedPurchasePriceInitial"
              >
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  readonly
                  matInput
                  currencyMask
                  [options]="{ prefix: '' }"
                />
              </mat-form-field>
            </div>
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.calculated_initial_costs_price" | translate}}</h4>
              <mat-form-field formGroupName="purchasePriceInitial">
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  matInput
                  required
                  currencyMask
                  [options]="{ prefix: '' }"
                />
                <mat-error>
                  <app-form-error
                    [fieldName]="'purchasePriceInitial.value'"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div
              class="text-color-black flex-grow-0"
              style="min-width: 26px; display: inline-block; white-space: nowrap;"
            >
                <span
                  style="white-space: nowrap;"
                  [class.text-color-red]="
                    purchasePriceInitialDifference > 0
                  "
                  [class.text-color-green]="
                    purchasePriceInitialDifference < 0
                  "
                >{{ purchasePriceInitialDifferenceString }}</span
                >
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.calculated_data_recovery_price" | translate}}</h4>
              <mat-form-field
                formGroupName="calculatedPurchasePriceDataRecovery"
              >
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  readonly
                  matInput
                  currencyMask
                  [options]="{ prefix: '' }"
                />
              </mat-form-field>
            </div>
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.data_recovery_price" | translate}}</h4>
              <mat-form-field formGroupName="purchasePriceDataRecovery">
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  matInput
                  required
                  currencyMask
                  [options]="{ prefix: '' }"
                />
                <mat-error>
                  <app-form-error
                    [fieldName]="'purchasePriceDataRecovery.value'"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div
              class="text-color-black flex-grow-0"
              style="min-width: 26px; display: inline-block; white-space: nowrap;"
            >
                <span
                  style="white-space: nowrap;"
                  [class.text-color-red]="
                    purchasePriceDataRecoveryDifference > 0
                  "
                  [class.text-color-green]="
                    purchasePriceDataRecoveryDifference < 0
                  "
                >{{ purchasePriceDataRecoveryDifferenceString }}</span
                >
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.calculated_price_other" | translate}}</h4>
              <mat-form-field formGroupName="calculatedPurchasePriceOther">
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  readonly
                  matInput
                  currencyMask
                  [options]="{ prefix: '' }"
                />
              </mat-form-field>
            </div>
            <div class="flex-grow-1 mx-1 data-recovery-inputs">
              <h4 class="mb-1">{{"shared.action_box.price_other" | translate}}</h4>
              <mat-form-field formGroupName="purchasePriceOther">
                <mat-label>{{"shared.action_box.price_in_eur" | translate}}</mat-label>
                <input
                  formControlName="value"
                  type="text"
                  matInput
                  required
                  currencyMask
                  [options]="{ prefix: '' }"
                />
                <mat-error>
                  <app-form-error
                    [fieldName]="'purchasePriceOther.value'"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div
              class="text-color-black flex-grow-0"
              style="min-width: 26px; display: inline-block; white-space: nowrap;"
            >
                <span
                  style="white-space: nowrap;"
                  [class.text-color-red]="purchasePriceOtherDifference > 0"
                  [class.text-color-green]="purchasePriceOtherDifference < 0"
                >{{ purchasePriceOtherDifferenceString }}</span
                >
            </div>
          </div>
        </div>
        <div class="col-lg-7 comment">
          <mat-form-field>
            <mat-label>{{"shared.action_box.comment" | translate}}</mat-label>
            <textarea
              matInput
              formControlName="purchasePriceComment"
              rows="16"
              cdkTextareaAutosize
            ></textarea>
            <mat-error>
              <app-form-error
                [fieldName]="'purchasePriceComment'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <div class="col text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid || !okAction"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Kosten senden</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
