import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';

import * as fromAuthModulesModels from '../../models';
import {ApplicationState} from '../../../application-state/store';
import {ErrorsObject, ErrorsUtility} from '../../../shared/utilities/error-utility.utility';
import {FormBuilder} from '@angular/forms';
import {CustomerAccountInvitationsActions, PartnerWebsiteInvitationsActions} from '../../../customers/store';
import {AdministratorsActions} from '../../../administrators/store';
import {combineLatestObject} from 'rxjs-etc';
import * as CustomersModuleSelectors from '../../../customers/store/selectors';
import {
  CustomerAccountInvitationsSelectors,
  PartnerWebsiteInvitationsSelectors
} from '../../../customers/store/selectors';

@Component({
  selector: 'invitation-confirmation-screen',
  styleUrls: ['invitation-confirmation-screen.component.scss'],
  template: `

    <div class="form__wrapper wrap">

      <div class="grid">
        <div class="column-13">
          <img src="assets/images/logos/logo-bmoffice24.png" class="img--resp" alt="">
        </div>
        <div class="column-1">
          <mat-icon>account_circle</mat-icon>
        </div>
      </div>

      <invitation-confirmation-form
        *ngIf="token"
        [invitationToken]="token"
        [errors]="selectErrors(['customerAccountInvitationsErrors','partnerWebsiteInvitationsErrors'])"
        (requestCreateInvitationConfirmation)="handleCreateInvitationConfirmation($event)"
      ></invitation-confirmation-form>
    </div>
  `
})
export class InvitationConfirmationScreenComponent {

  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject({});
  onDestroy$: Subject<any>;
  token: string;
  accountType: string;
  routeParamSubscription$: Subscription;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.mergeErrors();
  }

  initSubscriptions(): void {
    this.routeParamSubscription$ = this.route.paramMap.subscribe((paramMap) => {
      // @ts-ignore
      const {params} = paramMap;
      this.accountType = params.accountType;
      this.token = params.token;
    });
  }

  mergeErrors(): void {
    combineLatestObject({
      customerAccountInvitationsErrors: this.store$.pipe(select(CustomerAccountInvitationsSelectors.selectErrors)),
      partnerWebsiteInvitationsErrors: this.store$.pipe(select(PartnerWebsiteInvitationsSelectors.selectErrors)),
    }).subscribe(this.errors$);
  }

  selectErrors(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  handleCreateInvitationConfirmation(payload: fromAuthModulesModels.InvitationConfirmation): void {

    if (this.accountType === 'customer_accounts') {
      this.store$.dispatch(CustomerAccountInvitationsActions.CreateCustomerAccountInvitationConfirmation({payload}));
    }

    if (this.accountType === 'partner_websites') {
      this.store$.dispatch(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationConfirmation({payload}));
    }
  }

  ngOnDestroy(): void {
    this.routeParamSubscription$.unsubscribe();
  }
}
