import {NgModule} from '@angular/core';

import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';
import * as fromModuleGuards from './guards';
import * as fromModuleServices from './services';
import {OrderSelectDialogComponent} from './components/order-select-dialog/order-select-dialog.component';
import {SharedModule} from '../shared/shared.module';
import {
  AnalysisDoneListWidgetComponent
} from './components/analysis-done-list-widget/analysis-done-list-widget.component';
import {
  BindingOrdersOpenWidgetComponent
} from './components/binding-orders-open-widget/binding-orders-open-widget.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {AdministratorsModule} from '../administrators/administrators.module';
import {OrderStatusSelectComponent} from './components/order-status-select/order-status-select.component';
import {
  OrderPartnerStatusSelectComponent
} from './components/order-partner-status-select/order-partner-status-select.component';
import {
  OrderCooperationSelectComponent
} from './components/order-cooperation-select/order-cooperation-select.component';
import {
  OrderLabLocationSelectComponent
} from './components/order-lab-location-select/order-lab-location-select.component';
import {OrderTechnicianSelectComponent} from './components/order-technician-select/order-technician-select.component';
import {BrokerSelectComponent} from './components/broker-select/broker-select.component';
import {
  OrderDetailsEditDialogComponent
} from './components/order-details-edit-dialog/order-details-edit-dialog.component';
import {
  DeviceDetailsEditDialogComponent
} from './components/device-details-edit-dialog/device-details-edit-dialog.component';
import {
  OrderCommentFormDialogComponent
} from './components/order-comment-form-dialog/order-comment-form-dialog.component';
import {
  OrderAnalysisResultDialogComponent
} from './components/order-analysis-result-dialog/order-analysis-result-dialog.component';
import {
  OrderItemDialogComponent
} from './components/order-item-dialog/order-item-dialog.component';
import {
  ProofOfDestructionDialogComponent
} from './components/proof-of-destruction-dialog/proof-of-destruction-dialog.component';
import {ProofOfDestructionBoxComponent} from './components/proof-of-destruction-box/proof-of-destruction-box.component';
import {BindingOrderDialogComponent} from './components/binding-order-dialog/binding-order-dialog.component';
import {
  GenericOrderItemDialogComponent
} from './components/generic-order-item-dialog/generic-order-item-dialog.component';
import {OrderProductAddListComponent} from './components/order-product-add-list/order-product-add-list.component';
import {OrderDataMediaLogComponent} from './components/order-data-media-log/order-data-media-log.component';
import {
  OpenDatarecoveryListWidgetComponent
} from './components/open-datarecovery-list-widget/open-datarecovery-list-widget.component';
import {
  OpenPurchasePriceListWidgetComponent
} from './components/open-purchase-price-list-widget/open-purchase-price-list-widget.component';
import {
  OpenSellingPricesListWidgetComponent
} from './components/open-selling-prices-list-widget/open-selling-prices-list-widget.component';
import {
  IncomeDataMediumDelayedWidgetComponent
} from './components/income-data-medium-delayed-widget/income-data-medium-delayed-widget.component';
import {OpenAnalysisWidgetComponent} from './components/open-analysis-widget/open-analysis-widget.component';
import {OrderTrackingDetailsComponent} from './components/order-tracking-details/order-tracking-details.component';
import {CustomersModule} from '../customers/customers.module';
import {
  DatarecoveryDoneListWidgetComponent
} from './components/datarecovery-done-list-widget/datarecovery-done-list-widget.component';
import {
  MissingReplacementDmWidgetComponent
} from './components/missing-replacement-dm-widget/missing-replacement-dm-widget.component';
import {OrdersListForWidgetComponent} from './components/orders-list-for-widget/orders-list-for-widget.component';
import {MasterDataModule} from '../master-data/master-data.module';
import {WarehouseModule} from '../warehouse/warehouse.module';
import {
  OrderTransitionLogDialogComponent
} from './components/order-transition-log-dialog/order-transition-log-dialog.component';
import {OrderInvoiceListComponent} from './components/order-invoice-list/order-invoice-list.component';
import {OrderInvoiceListItemComponent} from './components/order-invoice-list-item/order-invoice-list-item.component';
import {TranslateModule} from "@ngx-translate/core";
import { OrdersDataCarriersReturnWidgetComponent } from './components/orders-data-carriers-return-widget/orders-data-carriers-return-widget.component';
import { OrdersListToBeDestroyedWidgetExtComponent } from './components/orders-list-to-be-destroyed-widget-ext/orders-list-to-be-destroyed-widget-ext.component';
import { PendingStorageSystemWidgetComponent } from '../orders/components/pending-storage-system-widget/pending-storage-system-widget.component';

@NgModule({
    imports: [
        SharedModule,
        MatButtonToggleModule,
        FormsModule,
        AdministratorsModule,
        CustomersModule,
        MasterDataModule,
        WarehouseModule,
        TranslateModule,
    ],
  declarations: [
    fromModuleComponents.AllComponents,
    fromModuleContainers.AllContainers,
    GenericOrderItemDialogComponent,
    OrderSelectDialogComponent,
    AnalysisDoneListWidgetComponent,
    BindingOrdersOpenWidgetComponent,
    OrderStatusSelectComponent,
    OrderPartnerStatusSelectComponent,
    OrderCooperationSelectComponent,
    OrderLabLocationSelectComponent,
    OrderTechnicianSelectComponent,
    BrokerSelectComponent,
    OrderDetailsEditDialogComponent,
    DeviceDetailsEditDialogComponent,
    OrderCommentFormDialogComponent,
    OrderAnalysisResultDialogComponent,
    OrderItemDialogComponent,
    ProofOfDestructionDialogComponent,
    ProofOfDestructionBoxComponent,
    BindingOrderDialogComponent,
    OrderProductAddListComponent,
    OrderDataMediaLogComponent,
    OpenDatarecoveryListWidgetComponent,
    OpenPurchasePriceListWidgetComponent,
    OpenSellingPricesListWidgetComponent,
    IncomeDataMediumDelayedWidgetComponent,
    OpenAnalysisWidgetComponent,
    OrderTrackingDetailsComponent,
    DatarecoveryDoneListWidgetComponent,
    MissingReplacementDmWidgetComponent,
    OrdersListForWidgetComponent,
    OrderTransitionLogDialogComponent,
    OrderInvoiceListComponent,
    OrderInvoiceListItemComponent,
    OrdersDataCarriersReturnWidgetComponent,
    OrdersListToBeDestroyedWidgetExtComponent,
    PendingStorageSystemWidgetComponent
  ],
  exports: [
    fromModuleComponents.AllComponents,
    GenericOrderItemDialogComponent,
    fromModuleComponents.OrderSpecialItemsComponent,
    AnalysisDoneListWidgetComponent,
    BindingOrdersOpenWidgetComponent,
    OpenDatarecoveryListWidgetComponent,
    OrderTechnicianSelectComponent,
    OpenPurchasePriceListWidgetComponent,
    OpenSellingPricesListWidgetComponent,
    IncomeDataMediumDelayedWidgetComponent,
    OpenAnalysisWidgetComponent,
    DatarecoveryDoneListWidgetComponent,
    MissingReplacementDmWidgetComponent,
    OrderLabLocationSelectComponent,
    OrdersDataCarriersReturnWidgetComponent,
    OrdersListToBeDestroyedWidgetExtComponent,
    OrdersListForWidgetComponent,
    PendingStorageSystemWidgetComponent
  ],
  providers: [
    fromModuleGuards.AllGuards,
    fromModuleServices.AllServices
  ]
})
export class OrdersModule {
}
