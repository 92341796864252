import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CorrectionInvoice,
  InvoicePayment,
  Offer,
  PayableInvoice
} from '../../models';
import {
  extractIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { PotentialAction } from '../../../shared/models';
import { TransitionsActions } from '../../../application-state/store/actions';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Observable } from 'rxjs';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { TransitionsSelectors } from '../../../application-state/store/selectors';
import { PayableInvoicesActions } from '../../store';
import { invoicePayTypeMap } from '../../helper/invoice-paid-type-map';

type Invoice = PayableInvoice | Offer | CorrectionInvoice | any;

@Component({
  selector: 'app-payment-recorder',
  styleUrls: ['payment-recorder.component.scss'],
  template: `
    <div class="card mt-3 mat-elevation-z1 pos-relative">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div
        class="card__heading"
        [ngClass]="
          isInvoiceOverpaid || isInvoiceOverdue
            ? 'red'
            : invoice?.fullySettled
            ? 'green'
            : null
        "
      >
        <span>{{ getHeading(invoice) }}</span>
        <span class="spacer"></span>
        <span *ngIf="invoice.amountOutstanding.value < 0"
          >{{ invoice.amountOutstanding.value * -1 | number: '1.2-2' }}
          {{ invoice.amountOutstanding.currency | currencyShortener }} zu
          viel</span
        >
        <span *ngIf="invoice.amountOutstanding.value > 0"
          >{{ invoice.amountOutstanding.value | number: '1.2-2' }}
          {{ invoice.amountOutstanding.currency | currencyShortener }} noch
          offen</span
        >
      </div>

      <div class="card__content grid">
        <div *ngIf="!!invoice?.payments?.length" class="column-14">
          <div
            class="m-ta--2"
            *ngFor="let p of payments"
            [class.text-color-red]="p.amount.value < 0"
            [class.text-color-green]="p.amount.value > 0"
          >
            <span *ngIf="p.paidType"
              >[{{ invoicePayTypeMap[p.paidType] || p.paidType }}]
            </span>
            <span *ngIf="p.amount.value > 0">+</span>
            {{ p.amount.value | number: '1.2-2' }}
            {{ p.amount.currency | currencyShortener }}
            <ng-container *ngIf="p.comment">, {{ p.comment }},</ng-container>
            <span style="color: #bbb">
              {{ p.receivedAt | date: 'dd.MM.Y' }}</span
            >
          </div>
        </div>
        <div *ngIf="isInvoiceOverdue" class="column-14 text-right">
          Fälligkeit: {{ invoice.dueDate | momentDuration }},
          <span style="color: #bbb">
            {{ invoice.dueDate | date: 'dd.MM.Y' }}</span
          >
        </div>
        <div
          *ngIf="hasSendFirstReminder"
          class="column-14 text-right text-color-red"
        >
          1. Zahlungserinnerung per E-Mail,
          {{ invoice.firstReminderAt | date: 'dd.MM.Y' }}
        </div>
        <div
          *ngIf="hasSendSecondReminder"
          class="column-14 text-right text-color-red"
        >
          2. Mahnung per Einschreiben,
          {{ invoice.secondReminderAt | date: 'dd.MM.Y' }}
        </div>
        <div
          *ngIf="hasInstructedDeptCollection"
          class="column-14 text-right text-color-red"
        >
          Inkasso beauftragt, {{ invoice.debtCollectionAt | date: 'dd.MM.Y' }}
        </div>

        <div
          *ngIf="canSendFirstReminder || hasSendFirstReminder"
          class="column-14 text-right py-2"
        >
          <button
            mat-flat-button
            [disabled]="hasSendFirstReminder"
            [color]="hasSendFirstReminder ? 'orange' : 'red'"
            class="me-2"
            (click)="sendFirstReminder()"
          >
            <mat-icon class="m-r--8">warning</mat-icon>
            <span
              >Zahlungerinnerung
              {{ hasSendFirstReminder ? 'erneut senden' : '' }} (Mail)
            </span>
          </button>
          <button
            mat-flat-button
            *ngIf="hasSendFirstReminder"
            [matTooltip]="'Zahlungserinnerung herunterladen'"
            [color]="hasSendFirstReminder ? 'orange' : 'red'"
            (click)="downloadFirstReminder()"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <div
          *ngIf="canSendSecondReminder || hasSendSecondReminder"
          class="column-14 text-right py-2"
        >
          <button
            mat-flat-button
            class="me-2"
            [disabled]="hasSendSecondReminder"
            [color]="hasSendSecondReminder ? 'orange' : 'red'"
            (click)="sendSecondReminder()"
          >
            <mat-icon class="m-r--8">warning</mat-icon>
            <span
              >Mahnung
              {{
                hasSendSecondReminder
                  ? 'verschickt'
                  : 'erstellen und verschicken'
              }}</span
            >
          </button>
          <button
            mat-flat-button
            *ngIf="hasSendSecondReminder"
            [matTooltip]="'Mahnung herunterladen'"
            [color]="hasSendSecondReminder ? 'orange' : 'red'"
            (click)="downloadSecondReminder()"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <!-- Dept Collection -->
        <div
          *ngIf="canInstructDeptCollection || hasInstructedDeptCollection"
          class="column-14 text-right py-2"
        >
          <button
            mat-flat-button
            class="me-2"
            [disabled]="hasInstructedDeptCollection"
            [color]="hasInstructedDeptCollection ? 'orange' : 'red'"
            (click)="instructDebtCollection()"
          >
            <mat-icon class="m-r--8">warning</mat-icon>
            <span
              >INKASSO
              {{
                hasInstructedDeptCollection ? 'beauftragt' : 'beauftragen'
              }}</span
            >
          </button>
        </div>
        <!--        <pre>{{ invoice | json }}</pre>-->

        <ng-container [formGroup]="ff">
          <div class="column-7" formGroupName="amount">
            <mat-form-field>
              <mat-label>Summe</mat-label>
              <input
                type="text"
                matInput
                formControlName="value"
                required
                currencyMask
                [options]="{ prefix: '', suffix: '', allowNegative: true }"
              />
            </mat-form-field>
          </div>

          <mat-form-field class="column-7">
            <mat-label>Datum</mat-label>
            <input
              type="text"
              matInput
              [matDatepicker]="picker"
              formControlName="receivedAt"
              readonly
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="column-4">
            <mat-label>Konto</mat-label>
            <mat-select formControlName="paidType">
              <mat-option
                matInput
                [value]="typeKey"
                *ngFor="let typeKey of invoicePayTypeMap | keys"
                >{{ invoicePayTypeMap[typeKey] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="column-10">
            <mat-label>Kommentar</mat-label>
            <textarea
              matInput
              formControlName="comment"
              cdkTextareaAutosize
            ></textarea>
          </mat-form-field>
        </ng-container>

        <div class="column-14 m-b--24">
          <span class="heading--h3">Zahlungsstatus</span>
          <div class="progress-bar__outer">
            <!-- For dynamic "slide-in":  -->
            <div
              class="progress-bar"
              [ngStyle]="{
                transform: 'translateX(' + invoice.percentagePaid + '%)'
              }"
            ></div>
            <span class="progress-bar__text--left"
              >{{ invoice.percentagePaid }} % bezahlt</span
            >
            <span class="progress-bar__text--right"
              >{{ invoice.grossTotal.value | number: '1.2-2' }}
              {{ invoice.grossTotal.currency | currencyShortener }}</span
            >
          </div>
        </div>

        <div class="column-5">
          <button
            [disabled]="!isFullPaymentReceivedTransitionAvailable(invoice)"
            mat-flat-button
            color="green"
            (click)="handleRequestBookFullPayment(invoice)"
          >
            <span>Als vollständig markieren</span>
          </button>
        </div>

        <div class="column-9 m-ta--2">
          <button
            [disabled]="ff.invalid"
            mat-flat-button
            color="green"
            (click)="handleRequestCreateInvoicePayment()"
          >
            <mat-icon class="m-r--8">euro</mat-icon>
            <span>Zahlung erfassen</span>
          </button>
        </div>
      </div>

      <div class="blocker"></div>
      <div class="blocker-canceled" *ngIf="invoice.state === 'canceled'">
        Rechnung storniert.
        <br />
        <a
          class="text-decoration-none"
          [routerLink]="[
            '/invoices',
            'cancellation',
            extractUUID(invoice.cancellationInvoice)
          ]"
          >Zur Stornorechnung</a
        >
      </div>
    </div>

    <!--<pre>{{ ff.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
  `
})
export class PaymentRecorderComponent implements OnInit {
  @Input() invoice: Invoice;
  isLoading$: Observable<boolean>;
  @Output()
  requestCreateInvoicePayment: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  requestBookFullPaymentReceived: EventEmitter<string> = new EventEmitter();

  ff: FormGroup;
  readonly invoicePayTypeMap = invoicePayTypeMap;
  protected readonly extractUUID = extractUUID;

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>
  ) {}

  get payments(): Array<InvoicePayment & { paidType: string }> {
    return this.invoice.payments.map(e => {
      const index = e.comment?.indexOf('#!#');
      if (e.comment && index > 0) {
        return {
          ...e,
          paidType: e.comment.substr(0, index),
          comment:
            e.comment.substr(index + 3) === 'null'
              ? null
              : e.comment.substr(index + 3)
        };
      } else {
        return { ...e, paidType: 'misc', comment: e.comment };
      }
    });
  }

  get isInvoiceOverpaid(): boolean {
    return parseFloat(this.invoice?.amountOutstanding.value) < 0;
  }

  get isInvoiceOverdue(): boolean {
    return this.invoice?.overdue;
  }

  get firstReminderAction(): PotentialAction {
    const found = this.invoice?.potentialActions?.findIndex(
      action => action.transition === 'send_first_reminder'
    );
    return this.invoice?.potentialActions?.[found];
  }

  get canSendFirstReminder(): boolean {
    if (!this.isInvoiceOverdue) {
      return false;
    }
    const action = this.firstReminderAction;
    return !!action && !action.hasOwnProperty('error');
  }

  get hasSendFirstReminder(): boolean {
    return this.invoice?.firstReminderAt;
  }

  get secondReminderAction(): PotentialAction {
    const found = this.invoice?.potentialActions?.findIndex(
      action => action.transition === 'send_second_reminder'
    );
    return this.invoice?.potentialActions?.[found];
  }

  get canSendSecondReminder(): boolean {
    const action = this.secondReminderAction;
    return !!action && !action.hasOwnProperty('error');
  }

  get hasSendSecondReminder(): boolean {
    return this.invoice?.secondReminderAt;
  }

  get instructDebtCollectionAction(): PotentialAction {
    const found = this.invoice?.potentialActions?.findIndex(
      action => action.transition === 'instruct_debt_collection'
    );
    return this.invoice?.potentialActions?.[found];
  }

  get hasInstructedDeptCollection(): boolean {
    return this.invoice?.debtCollectionAt;
  }

  get canInstructDeptCollection(): boolean {
    const action = this.instructDebtCollectionAction;
    return !!action && !action.hasOwnProperty('error');
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(TransitionsSelectors.isLoading)
    ]);
    this.ff = this.fb.group({
      amount: this.fb.group({
        value: this.fb.control(null, Validators.required),
        currency: this.fb.control('EUR', Validators.required)
      }),
      receivedAt: this.fb.control(new Date(), Validators.required),
      comment: this.fb.control(null),
      paidType: this.fb.control(Object.keys(invoicePayTypeMap)[0]),
      invoice: this.fb.control(null)
    });
  }

  getHeading(invoice: PayableInvoice): string {
    if (this.isInvoiceOverpaid) {
      return 'Rechnung überzahlt';
    }
    if (this.isInvoiceOverdue) {
      return 'Zahlungsfrist überschritten';
    }

    if (invoice?.fullySettled) {
      return 'Rechnung komplett beglichen';
    }

    return 'Zahlung erfassen';
  }

  handleRequestBookFullPayment(invoice: PayableInvoice): void {
    this.requestBookFullPaymentReceived.emit(invoice['@id']);
  }

  isFullPaymentReceivedTransitionAvailable(invoice: PayableInvoice): boolean {
    const found = invoice.potentialActions?.findIndex(
      action => action.transition === 'full_payment_received'
    );
    return (
      invoice.potentialActions &&
      found !== -1 &&
      !invoice.potentialActions[found].hasOwnProperty('error')
    );
  }

  downloadFirstReminder(): void {
    this.store$.dispatch(
      PayableInvoicesActions.DownloadFirstReminder({
        iri: extractIri(this.invoice)
      })
    );
  }

  sendFirstReminder(): void {
    this.makeTransition(this.firstReminderAction);
  }

  sendSecondReminder(): void {
    this.makeTransition(this.secondReminderAction);
  }

  instructDebtCollection(): void {
    this.makeTransition(this.instructDebtCollectionAction);
  }

  downloadSecondReminder(): void {
    this.store$.dispatch(
      PayableInvoicesActions.DownloadSecondReminder({
        iri: extractIri(this.invoice)
      })
    );
  }

  makeTransition(action: PotentialAction): void {
    if (!action) {
      return;
    }
    // const uri = action.target.url;
    const uri = `${this.invoice['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;

    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleRequestCreateInvoicePayment(): void {
    if (!this.invoice) {
      alert('Invoice must be set.');
      return;
    }

    this.ff.get('invoice').setValue(this.invoice['@id']);

    const payload = {
      ...this.ff.value,
      amount: {
        value: this.ff.get('amount.value').value.toString()
      },
      comment: this.ff.value.paidType + '#!#' + this.ff.value.comment
    };

    this.requestCreateInvoicePayment.emit(payload);
  }
}
