import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { ShipmentService } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ShipmentServicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getShipmentService(id: string): Observable<ShipmentService> {
    return this.rolesService.userHasRoleFilter<ShipmentService>(
      'ROLE_MWS_SHIPMENT_SERVICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipment_services/${id}`)
    );
  }

  getShipmentServices(
    shippingProvider: string
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SHIPMENT_SERVICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/shipment_services?valid_for_shipping_provider=${shippingProvider}`
            )
    );
  }
}
