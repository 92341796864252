import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class StockItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getStockItems(page = 1, params = {}): Observable<Array<AbstractApiResponse>> {
    return this.rolesService.userHasRoleFilter<Array<AbstractApiResponse>>(
      'ROLE_MWS_ABSTRACT_STOCK_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(`/stock_items`, page, params)
            )
    );
  }
}
