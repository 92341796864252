// Provider Imports
import * as fromAdministratorsModule from '../../../administrators/store/effects';
import * as fromAuthModule from '../../../auth/store/effects';
import * as fromCustomersModule from '../../../customers/store/effects';
import * as fromDashboardModule from '../../../dashboard/store/effects';
import * as fromInvoicesModule from '../../../invoices/store/effects';
import * as fromLeadsModule from '../../../leads/store/effects';
import * as fromMasterDataModule from '../../../master-data/store/effects';
import * as fromNotificationsModule from '../../../notifications/store/effects';
import * as fromOrdersModule from '../../../orders/store/effects';
import * as fromShippingModule from '../../../shipping/store/effects';
import * as fromTicketsModule from '../../../tickets/store/effects';
import * as fromWarehouseModule from '../../../warehouse/store/effects';

import {DialogEffects} from './dialog.effects';
import {RouterEffects} from './router.effects';
import {TransitionsEffects} from './transitions.effects';

export const ROOT_EFFECTS: Array<any> = [
  DialogEffects,
  RouterEffects,
  TransitionsEffects,
  ...fromDashboardModule.effects,
  ...fromAdministratorsModule.effects,
  ...fromAuthModule.effects,
  ...fromCustomersModule.effects,
  ...fromInvoicesModule.effects,
  ...fromLeadsModule.effects,
  ...fromMasterDataModule.effects,
  ...fromNotificationsModule.effects,
  ...fromOrdersModule.effects,
  ...fromShippingModule.effects,
  ...fromTicketsModule.effects,
  ...fromWarehouseModule.effects,
];
