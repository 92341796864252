import {createSelector} from '@ngrx/store';
import {getRouterState, RouterStateUrl} from '../index';

import * as TransitionsSelectors from './transitions.selectors';

export const selectRouterState = createSelector(
  getRouterState,
  (router: RouterStateUrl | any) => router.state
);

export const selectRouteParameters = createSelector(
  selectRouterState,
  (state) => state.params
);

export const selectRouterUrl = createSelector(
  selectRouterState,
  (state: RouterStateUrl | any) => state.url
);


export {
  TransitionsSelectors
};
