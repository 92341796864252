import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {IsAuthenticatedGuard} from './is-authenticated.guard';

@Injectable()
export class IsNotAuthenticatedGuard implements CanActivate {

  constructor(private guard: IsAuthenticatedGuard) {
  }

  canActivate(): Observable<boolean> {

    return this.guard.canActivate().pipe(map(value => !value));
  }
}
