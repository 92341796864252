import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DataRecoveryPriorityMode} from '../../../master-data/models';
import {Observable} from 'rxjs';
import {TicketList} from '../../models/ticket-list.interface';
import {DataRecoveryPriorityModesSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {DataRecoveryPriorityModesActions} from '../../../master-data/store';
import {TicketsSelectors} from '../../store/selectors';
import {OrderStateRange} from '../../../orders/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrderStateRangesSelectors} from '../../../orders/store/selectors';
import {OrderStateRangesActions} from '../../../orders/store';

@Component({
  selector: 'app-ticket-state-range-select',
  styleUrls: ['./ticket-state-range-select.component.scss'],
  template: `
    <div [formGroup]="formGroup">

      <ng-select
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="name"
        [clearable]="true"
        [searchFn]="findItem"
        placeholder="Status"
        [formControlName]="fieldName"
        (change)="setSelectedItem($event)">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item.name)>0">({{getOrderCount(item.name)}}x)</span>

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item.name)>0">({{getOrderCount(item.name)}}x)</span>
        </ng-template>
      </ng-select>
    </div>
  `

})
export class TicketStateRangeSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Output() updateSelectedObject: EventEmitter<OrderStateRange> = new EventEmitter<OrderStateRange>();

  items$: Observable<Array<OrderStateRange>>;
  isLoading$: Observable<boolean>;
  selectedItem: OrderStateRange;

  tickets: TicketList[];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.items$ = this.store$.select(OrderStateRangesSelectors.sList);
    this.isLoading$ = this.store$.select(OrderStateRangesSelectors.isLoading);
    loadIfNotLoaded(this.store$, OrderStateRangesSelectors.isLoaded,
      OrderStateRangesActions.ReadOrderStateRanges());
    this.store$.select(TicketsSelectors.sList).subscribe((tickets) => {
      this.tickets = tickets;
    });
  }

  getOrderCount(statusName: string): number {
    return this.tickets.filter((e: TicketList) => e.order?.stateRange === statusName).length || -1;
  }

  setSelectedItem(element: OrderStateRange): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: OrderStateRange): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

}
