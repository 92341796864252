import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as fromModuleModels from '../../models';
import { extractUUID } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'customer-list',
  styleUrls: ['customer-list.component.scss'],
  template: `
    <table
      class="bmo-table bmo-table-hover rounded-top bmo-table-clickable bmo-table-heading-uppercase"
      mat-table
      *ngIf="customers"
      [dataSource]="customers"
    >
      <!-- Customer Number Column -->
      <ng-container matColumnDef="customerNumber">
        <th mat-header-cell *matHeaderCellDef>Nr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/customers', extractId(element)]"
          ></a>
          <mat-icon
            class="inline-icon"
            *ngIf="element.customerType === '/api/customer_types/1'"
            >person
          </mat-icon>
          <mat-icon
            class="inline-icon"
            *ngIf="element.customerType === '/api/customer_types/0'"
            >factory
          </mat-icon>
          {{ element.customerNumber }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="nameLine1">
        <th mat-header-cell *matHeaderCellDef>Name/Firma</th>
        <td
          mat-cell
          *matCellDef="let element"
          [class.is-vip]="element.vip"
          class="pos-relative"
        >
          <a
            class="stretched-link"
            [routerLink]="['/customers', extractId(element)]"
          ></a>
          <span>{{ element.nameLine1 }}</span>
        </td>
      </ng-container>

      <!-- Firma Column -->
      <ng-container matColumnDef="nameLine2">
        <th mat-header-cell *matHeaderCellDef>Zusatz</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/customers', extractId(element)]"
          ></a
          >{{ element.nameLine2 }}
        </td>
      </ng-container>

      <!-- Angelegt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Angelegt</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="pos-relative"
          [matTooltip]="element.createdAt | date"
        >
          <a
            class="stretched-link"
            [routerLink]="['/customers', extractId(element)]"
          ></a>
          {{ element.createdAt | momentDuration }}
        </td>
      </ng-container>

      <!-- Updated Column -->
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef>Zuletzt aktualisiert</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/customers', extractId(element)]"
          ></a>
          {{ element.updatedAt ? (element.updatedAt | date: 'medium') : 'nie' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `
})
export class CustomerListComponent {
  @Input() customers: Array<fromModuleModels.Customer>;
  @Input() customerTotalItems: number;

  @Output() requestGoToCustomerViewer: EventEmitter<
    fromModuleModels.Customer
  > = new EventEmitter<fromModuleModels.Customer>();
  @Output() requestReadCustomers: EventEmitter<
    'next' | 'previous'
  > = new EventEmitter<'next' | 'previous'>();
  displayedColumns: Array<string> = [
    'customerNumber',
    'nameLine1',
    'nameLine2',
    'createdAt',
    'updatedAt'
  ];
  protected readonly extractId = extractUUID;
  protected readonly extractUUID = extractUUID;

  constructor() {}

  handleClick(customer: fromModuleModels.Customer): void {
    this.requestGoToCustomerViewer.emit(customer);
  }
}
