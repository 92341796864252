import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SalesSummary} from '../../models';


@Component({
  selector: 'app-sales-summary',
  styleUrls: ['sales-summary.component.scss'],
  template: `

    <div class="row">
      <div class="col-8 offset-4">
        <div class="card">
          <div class="card__heading">Umsatzauswertung gesamt</div>
          <div class="card__content">
            <div class="row summary-line g-0">
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Private Analysen</div>
                  <div
                    class="col-6">{{ salesSummary?.privateAnalyses }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Private Datenr.</div>
                  <div
                    class="col-6">{{ salesSummary?.privateDataRecoveries }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz sicher</div>
                  <div
                    class="col-6">{{ salesSummary?.salesSecure.value | number:'1.2' }} {{ salesSummary?.salesSecure.currency|currencyShortener }}</div>
                </div>
              </div>
            </div>
            <div class="row summary-line  g-0">
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Geschäftliche Analysen</div>
                  <div
                    class="col-6">{{ salesSummary?.businessAnalyses }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Geschäftliche Datenr.</div>
                  <div
                    class="col-6">{{ salesSummary?.businessDataRecoveries }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz möglich</div>
                  <div
                    class="col-6">{{ salesSummary?.salesPossible.value | number:'1.2' }} {{ salesSummary?.salesPossible.currency|currencyShortener }}</div>
                </div>
              </div>
            </div>
            <div class="row summary-line  g-0">
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Analysen Gesamt:</div>
                  <div
                    class="col-6">{{ salesSummary?.totalAnalyses }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Datenrettungen Gesamt:</div>
                  <div
                    class="col-6">{{ salesSummary?.totalDataRecoveries }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatzziel</div>
                  <div
                    class="col-6">{{ salesSummary?.salesTarget.value | number:'1.2' }} {{ salesSummary?.salesTarget.currency|currencyShortener }}</div>
                </div>
              </div>
            </div>
            <div class="row summary-line  g-0">
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Anzahl nach Modis:</div>
                  <div
                    class="col-6">
                    <span matTooltip="Eco" class="px-2">{{ salesSummary?.dataRecoveriesPriorityModeEco }} </span>/
                    <span matTooltip="Standard"
                          class="px-2">{{ salesSummary?.dataRecoveriesPriorityModeStandard }} </span>/
                    <span matTooltip="StandardPlus"
                          class="px-2">{{ salesSummary?.dataRecoveriesPriorityModeStandardPlus }} </span>/
                    <span matTooltip="Express"
                          class="px-2">{{ salesSummary?.dataRecoveriesPriorityModeExpress }} </span>
                  </div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold"></div>
                  <div
                    class="col-6"></div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz Analyse</div>
                  <div
                    class="col-6">{{ salesSummary?.salesAnalyses.value | number:'1.2' }} {{ salesSummary?.salesAnalyses.currency|currencyShortener }}</div>
                </div>
              </div>
            </div>
            <div class="row summary-line  g-0">
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz Privat:</div>
                  <div
                    class="col-6">{{ salesSummary?.salesPrivate.value | number:'1.2' }} {{ salesSummary?.salesPrivate.currency|currencyShortener }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz Geschäftlich:</div>
                  <div
                    class="col-6">
                    {{ salesSummary?.salesBusiness.value | number:'1.2' }} {{ salesSummary?.salesBusiness.currency|currencyShortener }}</div>
                </div>
              </div>
              <div class="col-4 data-block">
                <div class="row p-3">
                  <div class="col-6 text-bold">Umsatz Ersatz:</div>
                  <div
                    class="col-6">{{ salesSummary?.salesReplacementDataMedia.value | number:'1.2' }} {{ salesSummary?.salesReplacementDataMedia.currency|currencyShortener }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<pre>{{ salesSummary | json }}</pre>-->
  `
})
export class SalesSummaryComponent implements OnInit {

  @Input() salesSummary: SalesSummary;


  constructor() {
  }

  ngOnInit(): void {
  }
}
