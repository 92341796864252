import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerOffersActions} from '../actions/';
import * as fromCustomersModuleModels from '../../services';

@Injectable()
export class CustomerOffersEffects {

  constructor(private actions$: Actions, private cs: fromCustomersModuleModels.CustomersService) {
  }


  ReadCustomerOffers$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerOffersActions.ReadCustomerOffers),
    map(action => action),
    switchMap(({iri}) => {
      return this.cs.readCustomerOffers(iri).pipe(
        map((response: any) => CustomerOffersActions.ReadCustomerOffersSuccess({response})),
        catchError(response => of(CustomerOffersActions.ReadCustomerOffersFail({response})))
      );
    })
  ));
}
