import {createSelector} from '@ngrx/store';

import {selectTicketsModuleState, TICKET_ATTACHMENTS_FEATURE_KEY} from '../reducers';

export const selectTicketAttachmentsState = createSelector(
  selectTicketsModuleState,
  (moduleState) => moduleState[TICKET_ATTACHMENTS_FEATURE_KEY]
);

export const selectTicketAttachmentsEntities = createSelector(
  selectTicketAttachmentsState,
  (state) => state.entities
);

export const selectTicketAttachments = createSelector(
  selectTicketAttachmentsEntities,
  (entities) => Object.values(entities)
);

export const selectTicketAttachmentByIndex = createSelector(
  selectTicketAttachmentsEntities,
  (tickets, props) => tickets[props.iri]
);

export const sByTicketIri = createSelector(
  selectTicketAttachments,
  (entities, {iri}) => entities.filter(attachment => attachment.ticket === iri)
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTicketAttachmentTotalItems = createSelector(
  selectTicketAttachmentsState,
  state => state.totalItems
);
export const selectTicketAttachmentPagination = createSelector(
  selectTicketAttachmentsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectTicketAttachmentPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectTicketAttachmentPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectTicketAttachmentPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectTicketAttachmentPagination,
  pagination => pagination.next
);
export const selectPaginationLastLink = createSelector(
  selectTicketAttachmentPagination,
  pagination => pagination.last
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectTicketAttachmentsState,
  (state) => state.errors
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectTicketAttachmentsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectTicketAttachmentsState,
  (state) => state.loaded
);
