import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, take, takeUntil} from 'rxjs/operators';
import {Department} from '../../../master-data/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../utilities/observable.utility';
import {DepartmentsActions} from '../../../master-data/store';

@Component({
  selector: 'app-department-select',
  styleUrls: ['./department-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DepartmentSelectComponent),
    multi: true
  }],
  template: `
    <ng-container [formGroup]="form">
      <ng-select
        [items]="departments$ | async"
        [loading]="departmentsIsLoading$|async"
        [placeholder]="label"
        bindLabel="companyName"
        bindValue="@id"
        #departmentSelector
        [clearable]="false"
        (change)="propagateUpdate($event)"
        [markFirst]="false"
        formControlName="department"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <span [matTooltip]="item.companyName +' | '+ item.name">
          <mat-icon class="small" *ngIf="item.isDefault">star</mat-icon>
            {{ item.name }}
          </span>
        </ng-template>
      </ng-select>
    </ng-container>
  `
})
export class DepartmentSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label = 'Abteilung';
  @Output() selectDepartment: EventEmitter<Department> = new EventEmitter<Department>();
  form: FormGroup;
  departments$: Observable<Array<Department>>;
  departmentsIsLoading$: Observable<boolean>;

  onDestroy$: Subject<any> = new Subject<any>();
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      department: this.fb.control(null)
    });
    this.form.get('department').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
    this.departments$ = this.store$.select(DepartmentsSelectors.selectDepartments);
    this.departmentsIsLoading$ = this.store$.select(DepartmentsSelectors.isLoading);
    this.setDefaultDepartment();
    loadIfNotLoaded(this.store$, DepartmentsSelectors.isLoaded, DepartmentsActions.ReadDepartments());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    if (this.form.get('department').value !== null) {
      this.onChange(this.form.get('department').value);
    }

  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('department').disable();
    } else {
      this.form.get('department').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('department').setValue(value);
    if (value === null) {
      this.setDefaultDepartment();
    }

  }

  private setDefaultDepartment(): void {
    this.store$.select(DepartmentsSelectors.selectDefaultDepartment)
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e), take(1))
      .subscribe(department => {
        const field = this.form.get('department');
        if (field.value === null) {
          field.setValue(department['@id']);
          this.onChange(department['@id']);
        }
      });

  }

  propagateUpdate($event: any): void {
    this.selectDepartment.emit($event);
  }
}
