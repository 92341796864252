import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PARTNER = '[Customers Module] Read Partner';
export const READ_PARTNER_SUCCESS = '[Customers Module] Read Partner Success';
export const READ_PARTNER_FAIL = '[Customers Module] Read Partner Fail';

export const ReadPartner = createAction(
  READ_PARTNER,
  props<{ iri: string }>()
);

export const ReadPartnerSuccess = createAction(
  READ_PARTNER_SUCCESS,
  props<{ response: fromModuleModels.Customer }>()
);

export const ReadPartnerFail = createAction(
  READ_PARTNER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
// todo: probably rename to ReadPartnersCollection ?
export const READ_PARTNERS = '[Customers Module] Read Partners';
export const READ_PARTNERS_SUCCESS = '[Customers Module] Read Partners Success';
export const READ_PARTNERS_FAIL = '[Customers Module] Read Partners Fail';

export const ReadPartners = createAction(
  READ_PARTNERS,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadPartnersSuccess = createAction(
  READ_PARTNERS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadPartnersFail = createAction(
  READ_PARTNERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_PARTNER_CHUNK = '[Customers Module] Read Partner Chunk';
export const READ_PARTNER_CHUNK_SUCCESS = '[Customers Module] Read Partner Chunk Success';
export const READ_PARTNER_CHUNK_FAIL = '[Customers Module] Read Partner Chunk Fail';

export const ReadPartnerChunk = createAction(
  READ_PARTNER_CHUNK,
  props<{ uri: string }>()
);

export const ReadPartnerChunkSuccess = createAction(
  READ_PARTNER_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerChunkFail = createAction(
  READ_PARTNER_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_PARTNER = '[Customers Module] Reset Current Partner';

export const ResetCurrentPartner = createAction(
  RESET_CURRENT_PARTNER
);

export const RESET_IS_LOADED = '[Customers Module] Reset Is Loaded';

export const ResetIsLoaded = createAction(
  RESET_IS_LOADED
);
