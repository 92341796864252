import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { EnclosureDataMediumsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.EnclosureDataMedium };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    EnclosureDataMediumsActions.CreateEnclosureDataMedium,
    EnclosureDataMediumsActions.ReadEnclosureDataMedium,
    EnclosureDataMediumsActions.UpdateEnclosureDataMedium,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    EnclosureDataMediumsActions.CreateEnclosureDataMediumSuccess,
    EnclosureDataMediumsActions.ReadEnclosureDataMediumSuccess,
    EnclosureDataMediumsActions.UpdateEnclosureDataMediumSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    EnclosureDataMediumsActions.CreateEnclosureDataMediumFail,
    EnclosureDataMediumsActions.ReadEnclosureDataMediumFail,
    EnclosureDataMediumsActions.UpdateEnclosureDataMediumFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    EnclosureDataMediumsActions.DeleteEnclosureDataMediumSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
