import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';

import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { combineLatestObject } from 'rxjs-etc';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromAdministratorModuleModels from '../../../administrators/models';
import * as fromModuleActions from '../../store/actions';
import {
  AnalysisResultCategoriesActions,
  AnalysisResultsActions,
  CountriesActions,
  CurrenciesActions,
  CustomerCooperationsActions,
  DamagesActions,
  DepartmentsActions,
  DiscountsActions,
  EmailTemplateCategoriesActions,
  EmailTemplateContextsActions,
  EmailTemplatesActions,
  FileSystemsActions,
  GradesActions,
  OperatingSystemsActions,
  ProductsActions,
  ProductUnitsActions,
  SalutationsActions,
  ServicesActions,
  SpecialAgreementsActions,
  StorageLocationsActions,
  StorageSystemManufacturersActions,
  StorageSystemsActions,
  SymptomsActions,
  TaxesActions
} from '../../store/actions';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import {
  ErrorsObject,
  ErrorsUtility
} from '../../../shared/utilities/error-utility.utility';
import { ModalDialogOptions } from '../../../application-state/models';
import { Router } from '@angular/router';
import { ItemListComponent } from '../../components';
import {
  AnalysisResultCategoriesSelectors,
  AnalysisResultsSelectors,
  CountriesSelectors,
  CurrenciesSelectors,
  CustomerCooperationsSelectors,
  DamagesSelectors,
  DepartmentsSelectors,
  DiscountsSelectors,
  EmailTemplateCategoriesSelectors,
  EmailTemplatesSelectors,
  FileSystemsSelectors,
  GradesSelectors,
  LocalesSelectors,
  OperatingSystemsSelectors,
  ProductsSelectors,
  ProductUnitsSelectors,
  SalutationsSelectors,
  ServicesSelectors,
  SpecialAgreementsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors,
  SymptomsSelectors,
  TaxesSelectors
} from '../../store/selectors';
import {
  AnalysisResult,
  AnalysisResultCategory,
  Country,
  Currency,
  CustomerCooperation,
  Damage,
  Department,
  Discount,
  EmailTemplate,
  EmailTemplateCategory,
  FileSystem,
  Grade,
  Locale,
  OperatingSystem,
  Product,
  ProductUnit,
  Salutation,
  Service,
  SpecialAgreement,
  StorageLocation,
  StorageSystem,
  StorageSystemManufacturer,
  Symptom,
  Tax,
  TermsAndConditionsEntity
} from '../../models';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { AdministratorsActions } from '../../../administrators/store';
import { CurrenciesService } from '../../services/currencies.service';

@Component({
  styleUrls: ['master-data-view.component.scss'],
  // providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: "master-data-view.component.html"
})
export class MasterDataViewComponent implements OnInit, OnDestroy {
  @ViewChildren(ItemListComponent) itemLists!: QueryList<ItemListComponent>;

  activeLinkIndex = 0;
  analysisResultCategories$: Observable<Array<AnalysisResultCategory>>;
  analysisResultCategoriesEntities$: Observable<{
    [iri: string]: AnalysisResultCategory;
  }>;
  analysisResults$: Observable<Array<AnalysisResult>>;
  countries$: Observable<Array<Country>>;
  currencies$: Observable<Array<Currency>>;
  customerCooperations$: Observable<Array<CustomerCooperation>>;
  damages$: Observable<Array<Damage>>;
  departments$: Observable<Array<Department>>;
  discounts$: Observable<Array<Discount>>;
  emailTemplateCategories$: Observable<Array<EmailTemplateCategory>>;
  emailTemplates$: Observable<Array<EmailTemplate>>;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  fileSystems$: Observable<Array<FileSystem>>;
  grades$: Observable<Array<Grade>>;
  locales$: Observable<Array<Locale>>;
  onDestroy$: Subject<any> = new Subject<null>();
  operatingSystems$: Observable<Array<OperatingSystem>>;
  presets$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  productUnits$: Observable<Array<ProductUnit>>;
  products$: Observable<Array<Product>>;
  salutations$: Observable<Array<Salutation>>;
  services$: Observable<Array<Service>>;
  specialAgreements$: Observable<Array<SpecialAgreement>>;
  storageLocations$: Observable<Array<StorageLocation>>;
  storageSystemManufacturers$: Observable<Array<StorageSystemManufacturer>>;
  storageSystemManufacturersEntities$: Observable<{
    [iri: string]: StorageSystemManufacturer;
  }>;
  storageSystems$: Observable<Array<StorageSystem>>;
  storageSystemsEntities$: Observable<{ [iri: string]: StorageSystem }>;
  symptoms$: Observable<Array<Symptom>>;
  taxes$: Observable<Array<Tax>>;
  technicians$: Observable<Array<fromAdministratorModuleModels.Administrator>>;
  termsAndConditions$: Observable<Array<TermsAndConditionsEntity>>;

  isLoading$: Observable<boolean>;

  mappingData$: Observable<{
    [propertyName: string]: {
      [iri: string]:
        | AnalysisResultCategory
        | StorageSystemManufacturer
        | StorageSystem;
    };
  }>;

  params$: BehaviorSubject<any> = new BehaviorSubject({
    StorageLocation: {
      itemsPerPage: 30
    },
    AnalysisResult: {
      itemsPerPage: 30
    },
    EmailTemplate: {
      itemsPerPage: 30
    }
  });

  itemsTotal$: Observable<{ [k: string]: number }>;

  paginations$: BehaviorSubject<any> = new BehaviorSubject(null);

  columnDefs: {
    [entityName: string]: Array<{ propertyName: string; columnLabel: string }>;
  } = {
    analysisResult: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'category', columnLabel: 'Kategorie' },
      { propertyName: 'content', columnLabel: 'Inhalt' }
    ],
    analysisResultCategory: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'subject', columnLabel: 'Betreff' },
      { propertyName: 'local', columnLabel: 'Sprache' },
      {
        propertyName: 'emailTemplateCategory',
        columnLabel: 'Vorlagen-Kategorie'
      },
      { propertyName: 'templateContext', columnLabel: 'Nutzung für' }
    ],
    emailTemplateCategories: [{ propertyName: 'name', columnLabel: 'Name' }],
    emailTemplates: [{ propertyName: 'name', columnLabel: 'Name' }],
    customerCooperation: [{ propertyName: 'name', columnLabel: 'Name' }],
    damage: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' }
    ],
    department: [
      { propertyName: 'isDefault', columnLabel: 'Standard-Abt.' },
      { propertyName: 'companyName', columnLabel: 'Firmenname' },
      { propertyName: 'address', columnLabel: 'Adresse' },
      { propertyName: 'email', columnLabel: 'Mail' }
    ],
    discount: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'value', columnLabel: 'Wert' }
    ],
    fileSystem: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' }
    ],
    grade: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'abbreviation', columnLabel: 'Abkürzung' }
    ],
    operatingSystem: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' }
    ],
    product: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'price', columnLabel: 'Preis' },
      // note: this should be storageSystem, not storageSystemType, as per API
      { propertyName: 'storageSystemType', columnLabel: 'System' },
      { propertyName: 'storageSystemManufacturer', columnLabel: 'Hersteller' },
      { propertyName: 'storageSystemSize', columnLabel: 'GB' },
      { propertyName: 'description', columnLabel: 'Beschreibung' }
    ],
    salutation: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'salutation', columnLabel: 'Grußformel' }
    ],
    service: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'price', columnLabel: 'Preis' },
      { propertyName: 'description', columnLabel: 'Beschreibung' }
    ],
    specialAgreement: [
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'content', columnLabel: 'Inhalt' }
    ],
    storageLocation: [
      {
        propertyName: 'storageLocationNumber',
        columnLabel: 'Lagerplatz-Nummer'
      }
    ],
    storageSystemManufacturer: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'order', columnLabel: 'Sortierung' }
    ],
    storageSystem: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'order', columnLabel: 'Sortierung' }
    ],
    symptom: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' }
    ],
    tax: [
      { propertyName: 'isActive', columnLabel: 'Aktiv?' },
      { propertyName: 'name', columnLabel: 'Name' },
      { propertyName: 'value', columnLabel: 'Wert' },
      { propertyName: 'isDefault', columnLabel: 'Standard-Steuersatz?' }
    ],
    termsAndConditions: [{ propertyName: 'file', columnLabel: 'Datei' }]
  };

  constructor(
    private cs: CurrenciesService,
    private actions$: Actions,
    private router: Router,
    private dialog: MatDialog,
    private store$: Store<ApplicationState>
  ) {}

  get refreshAfterActions(): Array<any> {
    return [
      fromModuleActions.AnalysisResultCategoriesActions
        .CREATE_ANALYSIS_RESULT_CATEGORY_SUCCESS,
      fromModuleActions.AnalysisResultCategoriesActions
        .UPDATE_ANALYSIS_RESULT_CATEGORY_SUCCESS,
      fromModuleActions.AnalysisResultsActions.CREATE_ANALYSIS_RESULT_SUCCESS,
      fromModuleActions.AnalysisResultsActions.UPDATE_ANALYSIS_RESULT_SUCCESS,
      fromModuleActions.CustomerCooperationsActions
        .CREATE_CUSTOMER_COOPERATION_SUCCESS,
      fromModuleActions.CustomerCooperationsActions
        .UPDATE_CUSTOMER_COOPERATION_SUCCESS,
      fromModuleActions.DamagesActions.CREATE_DAMAGE_SUCCESS,
      fromModuleActions.DamagesActions.UPDATE_DAMAGE_SUCCESS,
      fromModuleActions.DepartmentLogosActions.CREATE_DEPARTMENT_LOGO_SUCCESS,
      fromModuleActions.DepartmentLogosActions.UPDATE_DEPARTMENT_LOGO_SUCCESS,
      fromModuleActions.DepartmentsActions.CREATE_DEPARTMENT_SUCCESS,
      fromModuleActions.DepartmentsActions.UPDATE_DEPARTMENT_SUCCESS,
      fromModuleActions.DiscountsActions.CREATE_DISCOUNT_SUCCESS,
      fromModuleActions.DiscountsActions.UPDATE_DISCOUNT_SUCCESS,
      fromModuleActions.FileSystemsActions.CREATE_FILE_SYSTEM_SUCCESS,
      fromModuleActions.FileSystemsActions.UPDATE_FILE_SYSTEM_SUCCESS,
      fromModuleActions.GradesActions.CREATE_GRADE_SUCCESS,
      fromModuleActions.GradesActions.UPDATE_GRADE_SUCCESS,
      fromModuleActions.OperatingSystemsActions.CREATE_OPERATING_SYSTEM_SUCCESS,
      fromModuleActions.OperatingSystemsActions.UPDATE_OPERATING_SYSTEM_SUCCESS,
      fromModuleActions.ProductsActions.CREATE_PRODUCT_SUCCESS,
      fromModuleActions.ProductsActions.UPDATE_PRODUCT_SUCCESS,
      fromModuleActions.SalutationsActions.CREATE_SALUTATION_SUCCESS,
      fromModuleActions.SalutationsActions.UPDATE_SALUTATION_SUCCESS,
      fromModuleActions.ServicesActions.CREATE_SERVICE_SUCCESS,
      fromModuleActions.ServicesActions.UPDATE_SERVICE_SUCCESS,
      fromModuleActions.SpecialAgreementsActions
        .CREATE_SPECIAL_AGREEMENT_SUCCESS,
      fromModuleActions.SpecialAgreementsActions
        .UPDATE_SPECIAL_AGREEMENT_SUCCESS,
      fromModuleActions.StorageSystemManufacturersActions
        .CREATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
      fromModuleActions.StorageSystemManufacturersActions
        .UPDATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
      fromModuleActions.StorageSystemsActions.CREATE_STORAGE_SYSTEM_SUCCESS,
      fromModuleActions.StorageSystemsActions.UPDATE_STORAGE_SYSTEM_SUCCESS,
      fromModuleActions.SymptomsActions.CREATE_SYMPTOM_SUCCESS,
      fromModuleActions.SymptomsActions.UPDATE_SYMPTOM_SUCCESS,
      fromModuleActions.TaxesActions.CREATE_TAX_SUCCESS,
      fromModuleActions.TaxesActions.UPDATE_TAX_SUCCESS,
      fromModuleActions.TermsAndConditionsActions
        .CREATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
      fromModuleActions.TermsAndConditionsActions
        .UPDATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
      EmailTemplatesActions.UPDATE_EMAIL_TEMPLATE_SUCCESS,
      EmailTemplateCategoriesActions.UPDATE_SUCCESS
    ];
  }

  ngOnInit(): void {
    this.selectData();
    this.initSubscriptions();
    this.mergeErrors();
    this.mergeTotalItems();
    this.handleTabChange({ index: this.activeLinkIndex, tab: null });
  }

  selectData(): void {
    this.storageSystems$ = this.store$.select(
      StorageSystemsSelectors.sOrderedList
    );
    this.storageSystemsEntities$ = this.store$.select(
      StorageSystemsSelectors.sEntities
    );

    this.storageSystemManufacturers$ = this.store$.select(
      StorageSystemManufacturersSelectors.sOrderedList
    );
    this.productUnits$ = this.store$.select(
      ProductUnitsSelectors.selectProductUnits
    );

    this.analysisResultCategories$ = this.store$.select(
      AnalysisResultCategoriesSelectors.selectAnalysisResultCategories
    );
    this.analysisResultCategoriesEntities$ = this.store$.select(
      AnalysisResultCategoriesSelectors.selectAnalysisResultCategoriesEntities
    );
    this.analysisResults$ = this.store$.select(AnalysisResultsSelectors.sList);
    this.countries$ = this.store$.select(CountriesSelectors.selectCountries);
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.customerCooperations$ = this.store$.select(
      CustomerCooperationsSelectors.selectCustomerCooperations
    );
    this.damages$ = this.store$.select(DamagesSelectors.selectDamages);
    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.fileSystems$ = this.store$.select(
      FileSystemsSelectors.selectFileSystems
    );
    this.grades$ = this.store$.select(GradesSelectors.selectGrades);
    this.locales$ = this.store$.select(LocalesSelectors.selectLocales);
    this.operatingSystems$ = this.store$.select(
      OperatingSystemsSelectors.selectOperatingSystems
    );
    this.products$ = this.store$.select(ProductsSelectors.sList);
    this.salutations$ = this.store$.select(
      SalutationsSelectors.selectSalutations
    );
    this.services$ = this.store$.select(ServicesSelectors.selectServices);
    this.specialAgreements$ = this.store$.select(
      SpecialAgreementsSelectors.selectSpecialAgreements
    );
    this.storageLocations$ = this.store$.select(
      StorageLocationsSelectors.sList
    );
    this.storageSystemManufacturersEntities$ = this.store$.select(
      StorageSystemManufacturersSelectors.sEntities
    );
    this.symptoms$ = this.store$.select(SymptomsSelectors.selectSymptoms);
    this.taxes$ = this.store$.select(TaxesSelectors.selectTaxes);
    this.technicians$ = this.store$.select(
      AdministratorsSelectors.selectTechnicians
    );
    this.emailTemplateCategories$ = this.store$.select(
      EmailTemplateCategoriesSelectors.sList
    );
    this.emailTemplates$ = this.store$.select(EmailTemplatesSelectors.sList);
    // this.termsAndConditions$ = this.store$.select(TermsAndConditionsSelectors.selectTermsAndConditions));

    this.mappingData$ = combineLatestObject({
      category: this.analysisResultCategoriesEntities$,
      // note: this should be storageSystem, not storageSystemType, as per API
      storageSystemType: this.storageSystemsEntities$,
      storageSystemManufacturer: this.storageSystemManufacturersEntities$
    });

    combineLatestObject({
      StorageLocation: this.store$.select(
        StorageLocationsSelectors.selectStorageLocationsPagination
      ),
      AnalysisResult: this.store$.select(
        AnalysisResultsSelectors.selectAnalysisResultsPagination
      ),
      EmailTemplate: this.store$.select(
        EmailTemplatesSelectors.selectEmailTemplatesPagination
      )
    }).subscribe(this.paginations$);
    this.isLoading$ = isLoadingArray([
      this.store$.select(StorageSystemsSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(AnalysisResultCategoriesSelectors.isLoading),
      this.store$.select(AnalysisResultsSelectors.isLoading),
      this.store$.select(CountriesSelectors.isLoading),
      this.store$.select(CurrenciesSelectors.isLoading),
      this.store$.select(CustomerCooperationsSelectors.isLoading),
      this.store$.select(DamagesSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(DiscountsSelectors.isLoading),
      this.store$.select(FileSystemsSelectors.isLoading),
      this.store$.select(GradesSelectors.isLoading),
      this.store$.select(LocalesSelectors.isLoading),
      this.store$.select(OperatingSystemsSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(SalutationsSelectors.isLoading),
      this.store$.select(ServicesSelectors.isLoading),
      this.store$.select(SpecialAgreementsSelectors.isLoading),
      this.store$.select(StorageLocationsSelectors.isLoading),
      this.store$.select(SymptomsSelectors.isLoading),
      this.store$.select(TaxesSelectors.isLoading),
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(EmailTemplatesSelectors.isLoading),
      this.store$.select(EmailTemplateCategoriesSelectors.isLoading)
    ]);
  }

  initSubscriptions(): void {
    this.actions$
      .pipe(ofType(...this.refreshAfterActions), takeUntil(this.onDestroy$))
      .subscribe(({ response }) => {
        this.presets$.next(null);
      });
  }

  mergeTotalItems(): void {
    this.itemsTotal$ = combineLatestObject({
      StorageLocation: this.store$.select(
        StorageLocationsSelectors.selectStorageLocationsTotalItems
      ),
      AnalysisResult: this.store$.select(
        AnalysisResultsSelectors.selectAnalysisResultsTotalItems
      ),
      EmailTemplate: this.store$.select(
        EmailTemplatesSelectors.selectEmailTemplatesTotalItems
      )
    });
    this.itemsTotal$.subscribe(value => console.log(value));
  }

  mergeErrors(): void {
    combineLatestObject({
      analysisResultCategoriesErrors: this.store$.select(
        AnalysisResultCategoriesSelectors.selectErrors
      ),
      analysisResultsErrors: this.store$.select(
        AnalysisResultsSelectors.selectErrors
      ),
      emailTemplatesErrors: this.store$.select(
        EmailTemplatesSelectors.selectErrors
      ),
      customerCooperationsErrors: this.store$.select(
        CustomerCooperationsSelectors.selectErrors
      ),
      damagesErrors: this.store$.select(DamagesSelectors.selectErrors),
      departmentsErrors: this.store$.select(DepartmentsSelectors.selectErrors),
      discountsErrors: this.store$.select(DiscountsSelectors.selectErrors),
      fileSystemsErrors: this.store$.select(FileSystemsSelectors.selectErrors),
      gradesErrors: this.store$.select(GradesSelectors.selectErrors),
      operatingSystemsErrors: this.store$.select(
        OperatingSystemsSelectors.selectErrors
      ),
      productsErrors: this.store$.select(ProductsSelectors.selectErrors),
      salutationsErrors: this.store$.select(SalutationsSelectors.selectErrors),
      servicesErrors: this.store$.select(ServicesSelectors.selectErrors),
      specialAgreementsErrors: this.store$.select(
        SpecialAgreementsSelectors.selectErrors
      ),
      storageLocationsErrors: this.store$.select(
        StorageLocationsSelectors.selectErrors
      ),
      storageSystemManufacturersErrors: this.store$.select(
        StorageSystemManufacturersSelectors.selectErrors
      ),
      storageSystemsErrors: this.store$.select(
        StorageSystemsSelectors.selectErrors
      ),
      symptomsErrors: this.store$.select(SymptomsSelectors.selectErrors),
      taxesErrors: this.store$.select(TaxesSelectors.selectErrors)
    }).subscribe(this.errors$);
  }

  selectErrors(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  handleUpdateItemForm(item: any): void {
    this.presets$.next(item);
  }

  handleCreateItem(payload: { payload: any; entity: string }): void {
    let Action;
    const { entity } = payload;
    if (
      fromModuleActions[`${entity}sActions`] &&
      fromModuleActions[`${entity}sActions`][`Create${entity}`]
    ) {
      Action = fromModuleActions[`${entity}sActions`][`Create${entity}`];
    } else if (entity === 'EmailTemplateCategory') {
      Action = EmailTemplateCategoriesActions.Create;
    } else if (entity === 'EmailTemplate') {
      Action = EmailTemplatesActions.CreateEmailTemplate;
    } else if (entity === 'TermsAndCondition') {
      Action =
        fromModuleActions[`TermsAndConditionsActions`][
          `CreateTermsAndConditionsEntity`
        ];
    } else if (entity === 'AnalysisResultCategory') {
      Action =
        fromModuleActions[`AnalysisResultCategoriesActions`][
          `CreateAnalysisResultCategory`
        ];
    } else if (entity === 'Tax') {
      Action = fromModuleActions[`TaxesActions`][`CreateTax`];
    } else {
      console.log('no ACtion found:' + entity);
    }
    this.store$.dispatch(Action(payload));
  }

  handleUpdateItem(payload: {
    iri?: string;
    payload: any;
    entity: string;
  }): void {
    const { entity, iri } = payload;
    let Action;

    if (
      fromModuleActions[`${entity}sActions`] &&
      fromModuleActions[`${entity}sActions`][`Update${entity}`]
    ) {
      Action = fromModuleActions[`${entity}sActions`][`Update${entity}`];
    } else if (entity === 'EmailTemplateCategory') {
      Action = EmailTemplateCategoriesActions.Update;
    } else if (entity === 'EmailTemplate') {
      Action = EmailTemplatesActions.UpdateEmailTemplate;
    } else if (entity === 'TermsAndCondition') {
      Action =
        fromModuleActions[`TermsAndConditionsActions`][
          `UpdateTermsAndConditionsEntity`
        ];
    } else if (entity === 'AnalysisResultCategory') {
      Action =
        fromModuleActions[`AnalysisResultCategoriesActions`][
          `UpdateAnalysisResultCategory`
        ];
    } else if (entity === 'Tax') {
      Action = fromModuleActions[`TaxesActions`][`UpdateTax`];
    } else {
      console.log('no ACtion found:' + entity);
    }
    this.store$.dispatch(Action(payload));
  }

  handleDeleteItem(payload: { iri?: string; entity: string }): void {
    const { entity, iri } = payload;
    let Action;
    console.log(entity);
    if (fromModuleActions[`${entity}sActions`] && fromModuleActions[`${entity}sActions`][`Delete${entity}`]) {
      Action = fromModuleActions[`${entity}sActions`][`Delete${entity}`];
    } else if (entity === 'EmailTemplateCategory') {
      Action = EmailTemplateCategoriesActions.Delete;
    } else if (entity === 'EmailTemplate') {
      Action = EmailTemplatesActions.DeleteEmailTemplate;
    } else if (entity === 'TermsAndCondition') {
      Action =
        fromModuleActions[`TermsAndConditionsActions`][
          `DeleteTermsAndConditionsEntity`
        ];
    } else if (entity === 'AnalysisResultCategory') {
      Action =
        fromModuleActions[`AnalysisResultCategoriesActions`][
          `DeleteAnalysisResultCategory`
        ];
    } else if (entity === 'Tax') {
      Action = fromModuleActions[`TaxesActions`][`DeleteTax`];
    } else {
      console.log('no ACtion found:' + entity);
    }

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieses Objekt wirklich gelöscht werden?',
          heading: 'Objekt löschen',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => this.store$.dispatch(Action({ iri })));
  }

  handleRefreshItems(entity: string, direction?: string, uri?: string): void {
    let Action;
    try {
      if (uri) {
        Action = fromModuleActions[`${entity}sActions`][`Read${entity}sChunk`];
      } else {
        Action = fromModuleActions[`${entity}sActions`][`Read${entity}s`];
      }
    } catch (e) {
      // handle plural ...
      console.log(`${entity}sActions`);
      console.log(`Read${entity}sChunk`);
      console.log(Action);
      alert(
        'error: Action does not exist most likely due to naming. See console'
      );

      if (entity === 'Tax') {
        Action = fromModuleActions[`TaxesActions`][`ReadTaxes`];
      }
    }

    if (uri) {
      this.store$.dispatch(Action({ uri }));
    } else {
      this.store$.dispatch(
        Action({ page: direction && direction == 'next' ? 2 : 1, params: { ...this.params$.getValue() }[entity] })
      );
    }
  }

  loadMappingData(): void {
    this.store$.dispatch(StorageSystemsActions.ReadStorageSystems({}));
    this.store$.dispatch(
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
    this.store$.dispatch(
      AnalysisResultCategoriesActions.ReadAnalysisResultCategories()
    );
  }

  handleTabChange(e: MatTabChangeEvent): void {
    switch (e.index) {
      case 0: // Produkte
        this.loadMappingData();
        this.store$.dispatch(ProductUnitsActions.ReadProductUnits());
        this.store$.dispatch(ProductsActions.ReadProducts());
        this.store$.dispatch(CurrenciesActions.ReadCurrencies());
        this.store$.dispatch(TaxesActions.ReadTaxes());

        break;
      case 1: // Leistungen
        this.store$.dispatch(CurrenciesActions.ReadCurrencies());
        this.store$.dispatch(ProductUnitsActions.ReadProductUnits());
        this.store$.dispatch(TaxesActions.ReadTaxes());
        this.store$.dispatch(ServicesActions.ReadServices());
        break;
      case 2: // Kunden-Kooperationen
        this.store$.dispatch(
          CustomerCooperationsActions.ReadCustomerCooperations()
        );
        break;
      case 3: // Abteilungen
        this.store$.dispatch(CountriesActions.ReadCountries());
        this.store$.dispatch(AdministratorsActions.ReadAdministrators());
        this.store$.dispatch(DepartmentsActions.ReadDepartments());
        break;
      case 4: // Schadensarten
        this.store$.dispatch(DamagesActions.ReadDamages());
        break;
      case 5: // Symptome
        this.store$.dispatch(SymptomsActions.ReadSymptoms());
        break;
      case 6: // Analysevorlagen
        this.store$.dispatch(AnalysisResultsActions.ReadAnalysisResults({ page: -1 }));
        this.loadMappingData();
        break;
      case 7: // Analysevorlagen-Kategorien
        this.store$.dispatch(
          AnalysisResultCategoriesActions.ReadAnalysisResultCategories()
        );
        break;
      case 8: // Rabattstufen
        this.store$.dispatch(DiscountsActions.ReadDiscounts());
        break;
      case 9: // Lagerplätze
        this.store$.dispatch(
          StorageLocationsActions.ReadStorageLocations({ page: -1 })
        );
        break;
      case 10: // Dateisysteme
        this.store$.dispatch(FileSystemsActions.ReadFileSystems());
        break;
      case 11: // Betriebssysteme
        this.store$.dispatch(OperatingSystemsActions.ReadOperatingSystems());
        break;
      case 12: // Speichersysteme
        this.store$.dispatch(StorageSystemsActions.ReadStorageSystems({}));
        break;
      case 13: // Sondervereinbarungen
        this.store$.dispatch(SpecialAgreementsActions.ReadSpecialAgreements());
        break;
      case 14: // Speichersystem-Hersteller
        this.store$.dispatch(
          StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
        );
        break;
      case 15: // Steuersätze
        this.store$.dispatch(TaxesActions.ReadTaxes());
        break;
      case 16: // Anreden
        this.store$.dispatch(SalutationsActions.ReadSalutations());
        break;
      case 17: // Akad. Grade
        this.store$.dispatch(GradesActions.ReadGrades());
        break;
      case 18: // Email Templates Categories
        this.store$.dispatch(EmailTemplateCategoriesActions.ReadList());
        break;
      case 19: // Email Templates
        this.store$.dispatch(EmailTemplatesActions.ReadEmailTemplates({ page: -1 }));
        this.store$.dispatch(EmailTemplateContextsActions.ReadList());
        this.store$.dispatch(EmailTemplateCategoriesActions.ReadList());
        break;
    }
    this.presets$.next(null);
  }

  handleReadItemChunk({
    entity,
    itemsPerPage
  }: {
    entity: string;
    itemsPerPage: number;
  }): void {
    this.params$.next({
      ...this.params$.getValue(),
      [entity]: {
        itemsPerPage: itemsPerPage.toString()
      }
    });

    this.handleRefreshItems(entity);
  }

  handleReadItemsPage({
    entity,
    direction
  }: {
    entity: string;
    direction: string;
  }): void {
    if (!direction) {
      alert('direction is null or undefined');
    }
    console.log(entity);
    console.log(this.paginations$.getValue());
    console.log(this.paginations$.getValue()[entity][direction]);
    const uri = this.paginations$.getValue()[entity][direction];

    // if (uri) {
      this.handleRefreshItems(entity, direction, uri);
    // }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
