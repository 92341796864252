import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as CustomersModuleSelectors from '../../store/selectors';
import { CustomersSelectors } from '../../store/selectors';
import * as fromCustomersModuleModels from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import { ApplicationState } from '../../../application-state/store';
import { CustomerFormComponent } from '../../components';
import { CustomersActions } from '../../store/actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { Go } from '../../../application-state/store/actions/router.actions';
import { OffersActions } from '../../../invoices/store';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { CustomerCooperationsSelectors } from '../../../master-data/store/selectors';
import { PaginatorComponent } from '../../../shared/components/paginator/paginator.component';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {LocalStorageService} from "../../../shared/services";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'customers-view',
  template: `
    <div class="pos-relative ">
      <view-heading
        heading="Kunden"
        colLeft="column-10"
        colRight="column-4"
        colRightClass="m-ta--2"
      >
        <span class="left">
          <button
            mat-flat-button
            class="m-l--8"
            (click)="handleShowCustomerForm(customerFormDialog)"
          >
            Neuen Kunden anlegen
          </button>
        </span>

        <app-search-input
          [showToggle]="true"
          [filtersPage]="'customers'"
          (searchTermChange)="handleSearchInput($event)"
          (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
        ></app-search-input>
      </view-heading>

      <customer-filter
        [customerCooperations$]="customerCooperations$"
        [customerTypes$]="customerTypes$"
        [customerPartnerStatuses$]="customerPartnerStatuses$"
        [searchBoxVisible$]="searchBoxVisible$"
        (requestHandleAction)="handleFilterFormAction($event)"
      ></customer-filter>
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

      <customer-list
        [customers]="customers$ | async"
        (requestGoToCustomerViewer)="handleRequestGoToCustomerViewer($event)"
      ></customer-list>

      <paginator
        [pageIndex]="currentPage"
        [totalItems]="customerTotalItems$ | async"
        [pageSizeOptions]="[5, 15, 30, 50]"
        (requestChangePaginationPage)="handleReadCustomerPage($event)"
        (requestChangePaginationChunkSize)="handleReadCustomerChunk($event)"
      ></paginator>

      <ng-template #customerFormDialog>
        <app-dialog-header>
          <h2>Kunde anlegen</h2>
        </app-dialog-header>

        <div mat-dialog-content>
          <div class="dialog--customer-form">
            <customer-form
              (cancelEdit)="dialog.closeAll()"
              (requestCreateCustomer)="handleCreateCustomer($event)"
              [errors]="errors$ | async"
              [customerTypes]="customerTypes$ | async"
              [customerPartnerStatuses]="customerPartnerStatuses$ | async"
            ></customer-form>
          </div>
        </div>
      </ng-template>

      <ng-template #inviteCustomerFormDialog>
        <div class="mat-dialog-content">
          <invite-customer-form>
            <h2 class="form__heading">Invite Customer</h2>
          </invite-customer-form>
        </div>
      </ng-template>
    </div>
  `
})
export class CustomersViewComponent implements OnInit, OnDestroy {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  customerCooperations$: Observable<Array<fromMasterDataModuleModels.Product>>;
  customerPartnerStatuses$: Observable<
    Array<fromCustomersModuleModels.CustomerPartnerStatus>
  >;
  isLoading$: Observable<boolean>;
  customerTotalItems$: Observable<number>;
  customerTypes$: Observable<Array<fromCustomersModuleModels.CustomerType>>;
  customers$: Observable<Array<fromCustomersModuleModels.Customer>>;
  errors$: Observable<ErrorsObject>;
  onDestroy$: Subject<any> = new Subject<any>();
  pagination$: BehaviorSubject<{ [p: string]: string }> = new BehaviorSubject(
    null
  );
  params$: BehaviorSubject<{ [p: string]: string }> = new BehaviorSubject({
    'order[createdAt]': 'desc'
  });
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentPage: number;

  constructor(
    private actions$: Actions,
    private store$: Store<ApplicationState>,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    // todo: migrate to facade
    this.selectData();
    this.initActionListeners();
    this.isLoading$ = isLoadingArray([
      this.store$.select(CustomersSelectors.isLoading)
    ]);
    this.store$.dispatch(CustomersActions.ResetCurrentCustomer());
    const filters = this.localStorage.getObjectByKey('filters', 'customers');
    this.searchBoxVisible$.next(!!filters);
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.store$.dispatch(
        CustomersActions.ReadCustomers({
          page: this.currentPage,
          params: { ...this.params$.getValue() }
        })
      );
    });
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        ofType(CustomersActions.CreateCustomerSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ type }) => {
        if (type === CustomersActions.CREATE_CUSTOMER_SUCCESS) {
          this.store$.dispatch(
            CustomersActions.ReadCustomers({
              page: this.currentPage,
              params: { ...this.params$.getValue() }
            })
          );
        }
      });
  }

  selectData(): void {
    this.customerCooperations$ = this.store$.pipe(
      select(CustomerCooperationsSelectors.selectCustomerCooperations)
    );
    this.customerPartnerStatuses$ = this.store$.pipe(
      select(
        CustomersModuleSelectors.CustomerPartnerStatusesSelectors
          .selectCustomerPartnerStatuses
      )
    );
    this.customerTotalItems$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectTotalItems)
    );
    this.customerTypes$ = this.store$.pipe(
      select(
        CustomersModuleSelectors.CustomerTypesSelectors.selectCustomerTypes
      )
    );
    this.customers$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectCustomers)
    );
    this.errors$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectErrors)
    );

    this.store$
      .pipe(
        select(
          CustomersModuleSelectors.CustomersSelectors.selectCustomerPagination
        )
      )
      .subscribe(this.pagination$);
  }

  handleCreateCustomer(payload: fromCustomersModuleModels.Customer): void {
    this.store$.dispatch(CustomersActions.CreateCustomer({ payload }));
  }

  handleRequestGoToCustomerViewer(
    customer: fromCustomersModuleModels.Customer
  ): void {
    const uuid = StringsUtility.getUuidFromIri(customer['@id']);
    this.store$.dispatch(Go({ path: ['customers', uuid] }));
  }

  handleReadCustomerPage(direction: 'next' | 'previous' | 'first'): void {
    if (!direction) {
      alert('direction is null or undefined');
    }
    const uri = this.pagination$.getValue()[direction];
    if(direction === 'next') {
      this.currentPage++;
    } else if(direction === 'previous') {
      this.currentPage--;
    }
    if (uri) {
      this.store$.dispatch(CustomersActions.ReadCustomerChunk({ uri }));
    }
    this.updateUrl();
  }

  handleReadCustomerChunk(itemsPerPage: number): void {
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: itemsPerPage.toString()
    });

    this.store$.dispatch(
      CustomersActions.ReadCustomers({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
  }

  handleSearchInput(term: string): void {
    this.params$.next({
      ...this.params$.getValue(),
      nameLine1: term ? term : ''
    });

    this.store$.dispatch(
      CustomersActions.ReadCustomers({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
  }

  handleFilterFormAction(filters: any): void {
    const params = {
      id: null,
      customerType: null,
      partnerStatus: null,
      partnerType: null,
      'customerCooperations.id': null,
      'createdAt[strictly_after]': null,
      'createdAt[strictly_before]': null
    };

    if (filters?.id) {
      params.id = filters.id;
    }

    if (filters?.customerType) {
      params.customerType = StringsUtility.getUuidFromIri(filters.customerType);
    }

    if (filters?.partnerType) {
      params.partnerStatus = StringsUtility.getUuidFromIri(filters.partnerType);
    }

    if (filters?.customerCooperation) {
      params['customerCooperations.id'] = StringsUtility.getUuidFromIri(
        filters.customerCooperation
      );
    }

    if (filters?.createdAtMin) {
      params['createdAt[strictly_after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[strictly_before]'] = filters.createdAtMax;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });

    this.store$.dispatch(
      CustomersActions.ReadCustomers({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
    this.paginator.paginator.firstPage();
  }

  toggleFilterBoxVisibility(): void {
    if (!this.searchBoxVisible$.getValue() === false) {
      this.params$.next({
        'order[createdAt]': 'desc',
        fulltext_search: this.params$
          .getValue()
          .hasOwnProperty('fulltext_search')
          ? this.params$.getValue().fulltext_search
          : ''
      });

      this.store$.dispatch(
        OffersActions.ReadOffers({ page: 1, params: this.params$.getValue() })
      );
    }

    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
  }

  handleShowCustomerForm(
    ref: TemplateRef<CustomerFormComponent>,
    modalOptions = {}
  ): void {
    this.dialog.open(ref, { disableClose: true, ...modalOptions });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    this.store$.dispatch(CustomersActions.ResetIsLoaded());
  }
}
