import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { StorageLocation } from '../models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class StorageLocationsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createStorageLocation(payload: StorageLocation): Observable<StorageLocation> {
    return this.rolesService.userHasRoleFilter<StorageLocation>(
      'ROLE_MWS_STORAGE_LOCATION_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/storage_locations`, payload)
    );
  }

  getStorageLocation(iri: string): Observable<StorageLocation> {
    return this.rolesService.userHasRoleFilter<StorageLocation>(
      'ROLE_MWS_STORAGE_LOCATION_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getStorageLocations(
    page = -1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_STORAGE_LOCATION_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/storage_locations', page, params)
            )
    );
  }

  readStorageLocationsChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_STORAGE_LOCATION_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updateStorageLocation({ iri, payload }): Observable<StorageLocation> {
    return this.rolesService.userHasRoleFilter<StorageLocation>(
      'ROLE_MWS_STORAGE_LOCATION_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteStorageLocation(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_STORAGE_LOCATION_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
