import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BehaviorSubject, fromEvent, Observable, Subject, throwError} from 'rxjs';
import {catchError, filter, mergeMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';

import {ErrorsObject} from '../../utilities/error-utility.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';

@Component({
  selector: 'app-image-input',
  styleUrls: ['image-input.component.scss'],
  template: `

    <form [formGroup]="dlf" enctype="multipart/form-data">

      <div class="grid">

        <div class="column-14" [class.hidden]="!loading">
          <div class="row" *ngIf="uploadStatus$|async as status">
            <div class="col-12 text-center">
              <span *ngIf="status.type === 1"> Lade hoch ... </span>
              <span *ngIf="status.type === 3"> Verarbeite Datei ... </span>
              <mat-progress-bar [mode]="status.type === 1?'determinate':'indeterminate'"
                                [value]="status.loaded/status.total*100"></mat-progress-bar>
            </div>
          </div>
        </div>
        <div class="column-11" [class.hidden]="loading || uploadedFile">

          <mat-form-field>
            <mat-label>Logo</mat-label>

            <input style="cursor: pointer" matInput readonly type="text" #fileNameInput
                   (click)="fileInput.click()">

            <mat-icon matSuffix style="margin-right: 12px; cursor: pointer" *ngIf="fileNameInput.value"
                      (click)="handleClearFile()">clear
            </mat-icon>

            <mat-icon matSuffix style="margin-right: 12px" (click)="fileInput.click()">attach_file</mat-icon>
            <mat-hint align="start"
                      *ngIf="errors && errors[this.propertyName]">{{ errors[this.propertyName].message }}</mat-hint>
            <mat-hint align="start" *ngIf="_errors">{{ _errors }}</mat-hint>
          </mat-form-field>

          <input type="file" #fileInput [accept]="accept">
        </div>
        <div class="column-13" [class.hidden]="loading || !uploadedFile">
          <mat-icon class="text-color-green">check</mat-icon>
          {{uploadedFile}}

        </div>
        <div class="column-1 text-right" [class.hidden]="loading || !uploadedFile">
          <button mat-flat-button mat-icon-button (click)="handleClearFile()">
            <mat-icon class="">close</mat-icon>
          </button>

        </div>


        <div class="column-3 m-ta--1 button-wrapper" *ngIf="!previewImageSrc">
          <button *ngIf="!loading"
                  mat-button [color]="_errors ? 'warn' : 'green'" [disabled]="!!!this.dlf.get('file').value || _errors"
                  (click)="handleUpload()">
            <mat-icon>upload</mat-icon>
          </button>

          <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
        </div>

        <div class="preview-wrapper {{ previewOrientation === 'below' ? 'column-14' : 'column-3' }}"
             *ngIf="previewImageSrc">
          <img class="img--resp" [src]="previewImageSrc" *ngIf="previewImageSrc">
        </div>
      </div>
    </form>
    <!--<pre>{{ _errors | json }}</pre>-->
  `
})
export class ImageInputComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('attr.class')
  marginBottom = 'm-b--24';

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileNameInput') fileNameInput: ElementRef;

  @Input() accept = 'image/*';
  @Input() actionNameCreate: string;
  @Input() actionNameGetBlob: string;
  @Input() previewOrientation: 'asideRight' | 'below';
  @Input() propertyName: string;
  @Input() entity$: BehaviorSubject<object>;
  @Input() errors: ErrorsObject;
  @Input() service: any;

  @Input() dispatchAction?: any;
  @Input() statusSelector?: any;
  @Input() uploadCompletedAction?: any;
  @Input() uploadFailAction?: any;
  uploadedFile: string = null;
  @Input() additionalPayload?: {};

  @Output()
  requestPatchParentForm: EventEmitter<{ [k: string]: any }> = new EventEmitter<{ [k: string]: any }>();

  dlf: FormGroup;
  file: File;
  loading = false;
  onDestroy$: Subject<any> = new Subject<any>();
  previewImageSrc: string | SafeUrl;
  uploadStatus$: Observable<{ type: number, loaded: number, total?: number }>;
  _errors: any;

  constructor(private sanitizer: DomSanitizer, private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  // todo: implement; return value not interpolated?
  getPreviewWrapperColumns(): string {
    return this.previewOrientation === 'below' ? 'column-14' : 'column-3';
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    fromEvent(
      this.fileInput.nativeElement,
      'change'
    ).pipe(
      takeUntil(this.onDestroy$),
      filter((changeEvent: any) => changeEvent.target.files && changeEvent.target.files.length)
    ).subscribe((changeEvent: any) => {

      this._errors = null;
      const file = changeEvent.target.files[0];
      this.dlf.patchValue({file});
      this.fileNameInput.nativeElement.value = file.name;
      this.showPreview();
      this.handleUpload();
    });

    if (!!!this.dlf.get('file').value) {
      this.handleClearFile();
    }
  }

  initForm(): void {
    this.dlf = this.fb.group({
      file: this.fb.control(null)
    });

    if (this.entity$ && this.entity$.getValue()) {
      this.dlf.addControl('uuid', this.fb.control(this.entity$.getValue()['@id']));
    }
  }

  showPreview(): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const uploadedImage = reader.result;
      if (typeof uploadedImage === 'string') {
        this.previewImageSrc = this.sanitizer.bypassSecurityTrustUrl(uploadedImage);
      }
    });
    reader.readAsDataURL(this.dlf.value.file);

  }

  handleUpload(additionalPayload = {}): void {
    if (this.additionalPayload) {
      Object.assign(additionalPayload, this.additionalPayload);
    }

    this.loading = true;
    if (this.dispatchAction) {
      this.store$.dispatch(this.dispatchAction({payload: {...this.dlf.value, ...additionalPayload}}));
      if (this.uploadCompletedAction) {
        this.actions$.pipe(ofType(this.uploadCompletedAction), takeUntil(this.onDestroy$), take(1)).subscribe(() => {
          this.uploadedFile = this.dlf.value.file.name;

          this.fileNameInput.nativeElement.value = null;
          this.dlf.get('file').setValue(null);
          this.loading = false;

        });
      }
      if (this.uploadFailAction) {
        this.actions$.pipe(ofType(this.uploadFailAction), takeUntil(this.onDestroy$), take(1)).subscribe((response) => {
          console.log('ERROR', response);
          this.loading = false;

        });
      }

      // @ts-ignore status selectors must match the status object
      this.uploadStatus$ = this.store$.select(this.statusSelector);
    } else {
      const blob$ = this.service[this.actionNameCreate](this.dlf.value);
      blob$.pipe(
        tap(response => console.log(response)),
        filter(response => !!response),
        catchError(error => throwError(error)),
        mergeMap(response => this.service[this.actionNameGetBlob](response['@id'])),
        withLatestFrom(blob$),
        take(1)
      ).subscribe(({0: {body: imageBlob}, 1: {'@id': logoIri}}) => {
        // todo: calculate image dimensions in px
        this.previewImageSrc = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(imageBlob));
        this.requestPatchParentForm.emit({[this.propertyName]: logoIri});
        this.dlf.get('file').setValue(null);

        this.loading = false;
      }, response => {
        this.loading = false;
        this._errors = response.error['hydra:description'];
      });
    }
  }

  handleClearFile(): void {
    this.file = null;
    this._errors = null;
    this.uploadedFile = null;
    this.dlf.removeControl('uuid');
    this.dlf.get('file').setValue(null);
    this.fileNameInput.nativeElement.value = null;
    this.previewImageSrc = null;
    this.requestPatchParentForm.emit({[this.propertyName]: null});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
