import {createSelector} from '@ngrx/store';

import {LEAD_COMMENTS_FEATURE_KEY, selectLeadsModuleState} from '../reducers';
import {selectCustomersState} from '../../../customers/store/selectors/customers.selectors';

export const selectLeadCommentsState = createSelector(
  selectLeadsModuleState,
  (leadsModuleState) => leadsModuleState[LEAD_COMMENTS_FEATURE_KEY]
);

export const selectLeadCommentsEntities = createSelector(
  selectLeadCommentsState,
  (state) => state.entities
);

export const selectLeadComments = createSelector(
  selectLeadCommentsEntities,
  (entities, props) => Object.keys(entities).map(iri => entities[iri]).filter(comment => comment.lead == props.iri)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectLeadCommentsState,
  state => state.totalItems
);
export const selectLeadCommentsPagination = createSelector(
  selectLeadCommentsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectLeadCommentsPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectLeadCommentsPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectLeadCommentsPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectLeadCommentsPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectLeadCommentsPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectLeadCommentsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectLeadCommentsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectLeadCommentsErrors = createSelector(
  selectLeadCommentsState,
  (state) => state.errors
);
