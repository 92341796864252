import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Order, ProductOrderItem} from '../../models';
import {Product, StorageSystem, Tax} from '../../../master-data/models';
import {
  ProductsActions,
  SpecialAgreementsActions,
  StorageSystemsActions,
  TaxesActions
} from '../../../master-data/store';
import {
  ProductsSelectors,
  ProductUnitsSelectors,
  SpecialAgreementsSelectors, StorageSystemsSelectors, TaxesSelectors
} from '../../../master-data/store/selectors';
import {cloneDeep} from 'lodash-es';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../shared/services';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {sortProducts} from '../../../shared/utilities/array.utility';
import {ProductOrderItemsActions} from '../../store';
import {Actions, ofType} from '@ngrx/effects';
import {ProductOrderItemsSelectors} from '../../store/selectors';

@Component({
  selector: 'app-order-product-add-list',
  styleUrls: ['./order-product-add-list.component.scss'],
  template: `

    <div class="row" [formGroup]="form">
      <mat-form-field class="col-12">
        <mat-label>Datenträger suchen</mat-label>
        <input type="text" matInput formControlName="searchString">
        <mat-error>
          <app-form-error fieldName="searchString" [formGroup]="form"></app-form-error>
        </mat-error>
        <mat-icon matSuffix>search</mat-icon>

      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-12 order-product-list">

        <table
          class="bmo-table bmo-table-bordered bmo-table-rounded bmo-table-dense bmo-table-hover pos-relative">
          <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

          <tbody>
          <tr *ngFor="let product of filteredSortedProducts$|async|slice:0:PAGE_SIZE"
              [class.text-muted]="product?.actualStock<=0">
            <!--<pre>{{product|json}}</pre>-->
            <td>{{ product?.name }}</td>
            <td>{{ storageSystemTypeEntities[product?.storageSystemType]?.name }}</td>
            <td style="width: 10%;">{{product?.storageSystemSize }} GB</td>
            <td style="width: 10%;" *ngIf="product?.actualStock>0">{{product?.actualStock}}x im Lager</td>
            <td style="width: 10%;" *ngIf="product?.actualStock<=0" class="text-color-red">Ausverkauft</td>
            <td style="width: 40%;">
              {{ product?.price?.value | number:'1.2-2' }} {{ product?.price?.currency|currencyShortener }}
              <small class="text-muted">
                ({{ product?.grossPrice?.value | number:'1.2-2' }} {{ product?.grossPrice?.currency|currencyShortener }}
                brutto, {{ taxRateEntities[product?.defaultTax]?.name }})</small>
            </td>
            <td style="width: 40px;" class="text-color-green text-center">
              <mat-icon class="cursor-pointer" (click)="addProductToOrder(product)" *ngIf="product?.actualStock>0">add
              </mat-icon>
            </td>
          </tr>
          <tr *ngIf="(filteredSortedProducts$ |async)?.length>PAGE_SIZE" class="text-center">
            <td colspan="6"> Die Ersten {{PAGE_SIZE}} Datenträgen werden angezeigt. Bitte Suche zum weiter Einschränken
              verwenden.
              <br>
              Datenträger insgesamt: {{(filteredSortedProducts$|async).length}}</td>
          </tr>
          <tr *ngIf="(filteredSortedProducts$ |async)?.length<=0" class="text-center">
            <td colspan="6"> Keine Datenträger verfügbar</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


  `
})
export class OrderProductAddListComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;
  order: Order;

  onDestroy$: Subject<any> = new Subject<any>();

  products$: Observable<Array<Product>>;
  products: Array<Product>;
  isLoading$: Observable<boolean>;

  filteredSortedProducts$: Subject<Array<Product>> = new Subject<Array<Product>>();
  storageSystemTypeEntities: { [key: string]: StorageSystem };
  taxRateEntities: { [key: string]: Tax };
  form: FormGroup;
  PAGE_SIZE = 20;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ProductOrderItemsSelectors.isLoading)
    ]);
    this.initForm();
    this.loadProducts();
    this.loadStorageSystemTypes();
    this.loadTaxes();

    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe(order => {
      this.order = order;
    });
    this.actions$.pipe(
      ofType(
        ProductOrderItemsActions.DeleteProductOrderItemSuccess,
        ProductOrderItemsActions.CreateProductOrderItemSuccess
      ),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.loadProducts();
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


  private initForm(): void {
    this.form = this.fb.group({
      searchString: this.fb.control(null, []),
    });

    this.form.get('searchString').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(search => {
      this.updateFilteredProducts(search);
    });

  }

  updateFilteredProducts(search: string = null): void {
    const searchString = search ? search.toLowerCase() : this.form.getRawValue()?.searchString?.toLowerCase();
    let products = this.products;

    if (!searchString || searchString.trim() === '') {
      this.filteredSortedProducts$.next(products);
      return;
    }
    const searchStrings = searchString.split(' ');
    products = products
      .filter((p: any) => searchStrings.every(str => {
        return p.filterString && p.filterString.indexOf(str) > -1;
      }));
    this.filteredSortedProducts$.next(products);
  }

  loadProducts(): void {
    this.store$.dispatch(ProductsActions.ReadProducts());
    this.products$ = this.store$.select(ProductsSelectors.sList); // .pipe(map(arr => arr.sort()));
    this.products$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(products => {
      this.products = cloneDeep(products).map(e => ({
        ...e,
        filterString: [
          e.name,
          this.storageSystemTypeEntities ? this.storageSystemTypeEntities[e.storageSystemType]?.name : '',
          e.storageSystemSize + ' GB',
          e.price.value,
          e.grossPrice.value
        ].join(' ').toLowerCase()
      })).sort(sortProducts);
      this.updateFilteredProducts();
    });

  }

  addProductToOrder(product: Product): void {
    const order = this.order['@id'];
    const payload = {
      order,
      product: product['@id'],
      // @ts-ignore
      taxRate: product.defaultTax,
      upsell: false,
      netPricePerUnit: {
        value: product.price.value,
        currency: product.price.currency,
      },
      description: product.description,
      // @ts-ignore
      unit: product.productUnit,
      title: product.name
    };
    this.store$.dispatch(ProductOrderItemsActions.CreateProductOrderItem({payload}));
  }

  loadStorageSystemTypes(): void {
    this.store$.select(StorageSystemsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemTypeEntities = entities;
      });
    loadIfNotLoaded(this.store$, StorageSystemsSelectors.isLoaded, StorageSystemsActions.ReadStorageSystems({}));

  }

  loadTaxes(): void {
    this.store$.select(TaxesSelectors.selectTaxesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.taxRateEntities = entities;
      });
    loadIfNotLoaded(this.store$, TaxesSelectors.isLoaded, TaxesActions.ReadTaxes());

  }
}
