import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'confirmation-box',
  styleUrls: ['confirmation-box.component.scss'],
  template: `

  `
})
export class ConfirmationBoxComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
