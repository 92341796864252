import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Form, FormBuilder, FormGroup} from '@angular/forms';

import {debounceTime, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';

import {getDirtyValuesObject} from '../../utilities/forms.utility';

@Component({
  selector: 'dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template: `

    <form class="dynamic-form grid"
          [formGroup]="form">

      <ng-container
        *ngFor="let field of config;" dynamicField [config]="field"
        [group]="form"
      ></ng-container>
    </form>
  `
})
export class DynamicFormComponent implements OnInit {

  @Input() config: Array<any> = [];
  @Input() storedFilters?: any;
  @Input() debounceTime = 400;

  @Output() requestAction: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.createGroup();
    if(this.storedFilters) {
      this.form.patchValue(this.storedFilters);
    }
    this.form.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      filter(formValues => !!Object.values(formValues).length),
      debounceTime(this.debounceTime),
      switchMap(() => of(getDirtyValuesObject(this.form))),
    ).subscribe(v => {
      this.requestAction.emit(v);
    });
  }

  // todo: implement passing validators
  createGroup(): FormGroup {
    const group = this.fb.group({});
    this.config.forEach(control => group.addControl(control.name, this.fb.control(null)));
    return group;
  }
}
