import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { OrdersSelectors } from '../../store/selectors';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import {
  CancellationInvoicesSelectors,
  CorrectionInvoicesSelectors,
  OffersSelectors,
  PayableInvoicesSelectors
} from '../../../invoices/store/selectors';
import {
  CancellationInvoice,
  CorrectionInvoice,
  Offer,
  PartialInvoice,
  PayableInvoice
} from '../../../invoices/models';
import {
  CancellationInvoicesActions,
  CorrectionInvoicesActions,
  OffersActions,
  PayableInvoicesActions
} from '../../../invoices/store';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { payableInvoiceStateMapDE } from '../../../invoices/helper/invoice-state-maps';

@Component({
  selector: 'app-order-invoice-list',
  styleUrls: ['./order-invoice-list.component.scss'],
  template: `
    <div class="pos-relative" style="min-height: 200px;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div class="card mb-2 overflow-hidden">
        <div class="card__heading">
          <span>Rechnungen</span>
        </div>
        <div class="card__content p-a--24">
          <ng-container
            *ngIf="(isLoading$ | async) === false && invoices?.length <= 0"
          >
            <p class="">Keine Rechnungen vorhanden.</p>
          </ng-container>

          <div *ngIf="invoices" class="row">
            <div class="col ">
              <table
                class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th>Betrag</th>
                    <th>Saldo</th>
                    <th>Status</th>
                    <th>Datum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    app-order-invoice-list-item
                    [invoice]="invoice"
                    *ngFor="let invoice of invoices"
                  ></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2 overflow-hidden">
        <div class="card__heading">
          <span>Angebote</span>
        </div>
        <div class="card__content p-a--24">
          <ng-container
            *ngIf="(isLoading$ | async) === false && offers?.length <= 0"
          >
            <p class="">Keine Angebote vorhanden.</p>
          </ng-container>

          <div *ngIf="offers" class="row">
            <div class="col ">
              <table
                class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Betrag</th>
                    <th>Status</th>
                    <th>Datum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let offer of offers">
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'offers',
                          extractUUID(offer)
                        ]"
                      ></a>
                      {{ offer.offerNumber }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'offers',
                          extractUUID(offer)
                        ]"
                      ></a>
                      {{ offer.grossTotal.value | number: '1.2-2' }}
                      {{ offer.grossTotal.currency | currencyShortener }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'offers',
                          extractUUID(offer)
                        ]"
                      ></a>
                      {{ offer.acceptedOffer ? 'Bestätigt' : 'Erstellt' }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'offers',
                          extractUUID(offer)
                        ]"
                      ></a>
                      {{ offer.createdAt | momentDateWithTime }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2 overflow-hidden">
        <div class="card__heading">
          <span>Stornorechnungen</span>
        </div>
        <div class="card__content p-a--24">
          <ng-container
            *ngIf="
              (isLoading$ | async) === false &&
              cancellationInvoices?.length <= 0
            "
          >
            <p class="">Keine Stornorechnungen vorhanden.</p>
          </ng-container>

          <div *ngIf="cancellationInvoices" class="row">
            <div class="col ">
              <table
                class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Betrag</th>
                    <th>Storno zu Re.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of cancellationInvoices">
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'cancellation',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      {{ invoice.cancellationInvoiceNumber }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'cancellation',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      {{ invoice.netTotal.value | number: '1.2-2' }}
                      {{ invoice.netTotal.currency | currencyShortener }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'cancellation',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      #{{
                        getCanceledInvoice(invoice.canceledInvoice)
                          ?.invoiceNumber || ' E'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-2 overflow-hidden">
        <div class="card__heading">
          <span>Korrekturrechnung</span>
        </div>
        <div class="card__content p-a--24">
          <ng-container
            *ngIf="
              (isLoading$ | async) === false && correctionInvoices?.length <= 0
            "
          >
            <p class="">Keine Korrekturrechnung vorhanden.</p>
          </ng-container>
          <div *ngIf="correctionInvoices" class="row">
            <div class="col ">
              <table
                class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Betrag</th>
                    <th>Status</th>
                    <th>Korrektur zu Re.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of correctionInvoices">
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'corrections',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      {{ invoice.correctionInvoiceNumber || ' E' }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'corrections',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      {{ invoice.netTotal.value | number: '1.2-2' }}
                      {{ invoice.netTotal.currency | currencyShortener }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'corrections',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      {{
                        invoice.state | myI18nSelect: payableInvoiceStateMapDE
                      }}
                    </td>
                    <td class="pos-relative">
                      <a
                        class="stretched-link"
                        [routerLink]="[
                          '/invoices',
                          'corrections',
                          extractUUID(invoice)
                        ]"
                      ></a>
                      #{{
                        getCorrectedInvoice(invoice.correctedInvoice)
                          ?.invoiceNumber || ' E'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrderInvoiceListComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  order: Order;
  isLoading$: Observable<boolean>;
  invoices: Array<PayableInvoice | PartialInvoice>;
  offers: Array<Offer>;
  cancellationInvoices: Array<CancellationInvoice>;
  correctionInvoices: Array<CorrectionInvoice>;
  payableInvoiceStateMapDE = payableInvoiceStateMapDE;
  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractUUID = extractUUID;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(PayableInvoicesSelectors.isLoading),
      this.store$.select(OffersSelectors.isLoading),
      this.store$.select(CancellationInvoicesSelectors.isLoading)
    ]);

    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.loadInvoices();
        this.loadOffers();
        this.loadCancellationInvoices();
        this.loadCorrectionInvoices();
        // console.log(order.paymentProcess);
      });
  }

  getCorrectedInvoice(correctedInvoice: any): PayableInvoice | PartialInvoice {
    const iri = extractIri(correctedInvoice);
    return this.invoices?.find(e => extractIri(e) === iri);
  }

  getCanceledInvoice(canceledInvoice: any): PayableInvoice | PartialInvoice {
    const iri = extractIri(canceledInvoice);
    return this.invoices?.find(e => extractIri(e) === iri);
  }

  loadInvoices(): void {
    this.store$
      .select(PayableInvoicesSelectors.selectPayableInvoices)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0),
        map(e =>
          e.filter(i => {
            return (
              // @ts-ignore
              extractIri(i.paymentProcess?.order) === extractIri(this.order)
            );
          })
        )
      )
      .subscribe(data => {
        this.invoices = data;
      });
    this.store$.dispatch(
      PayableInvoicesActions.ReadPayableInvoices({
        page: -1,
        params: { orderNumber: this.order.orderNumber }
      })
    );
  }

  loadOffers(): void {
    this.store$
      .select(OffersSelectors.selectOffers)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0),
        map(e =>
          e.filter(i => {
            return (
              // @ts-ignore
              i.paymentProcess?.order?.orderNumber === this.order?.orderNumber
            );
          })
        )
      )
      .subscribe(data => {
        this.offers = data;
      });
    this.store$.dispatch(
      OffersActions.ReadOffers({
        page: -1,
        params: { 'paymentProcess.order.id': this.order.orderNumber }
      })
    );
  }

  loadCancellationInvoices(): void {
    this.store$
      .select(CancellationInvoicesSelectors.selectCancellationInvoices)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0),
        map(e =>
          e.filter(i => {
            return (
              // @ts-ignore
              i.canceledInvoice?.paymentProcess?.order?.orderNumber ===
              this.order?.orderNumber
            );
          })
        )
      )
      .subscribe(data => {
        this.cancellationInvoices = data;
      });
    this.store$.dispatch(
      CancellationInvoicesActions.ReadCancellationInvoices({
        page: -1,
        params: { orderNumber: this.order.orderNumber }
      })
    );
  }

  private loadCorrectionInvoices(): void {
    this.store$
      .select(CorrectionInvoicesSelectors.selectCorrectionInvoices)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0),
        map(e =>
          e.filter(i => {
            // console.log(i);
            return (
              // @ts-ignore
              i.correctedInvoice?.paymentProcess?.order?.orderNumber ===
              this.order?.orderNumber
            );
          })
        )
      )
      .subscribe(data => {
        this.correctionInvoices = data;
      });
    this.store$.dispatch(
      CorrectionInvoicesActions.ReadCorrectionInvoices({
        page: -1,
        params: { orderNumber: this.order.orderNumber }
      })
    );
  }
}
