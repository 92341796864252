import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AdministratorFeedbackRequestsActions} from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import {UserRoleActions} from "../../../application-state/store/actions";

export interface State {
  entities: { [iri: string]: fromModuleModels.FeedbackRequest };
  sendEntities: { [iri: string]: fromModuleModels.FeedbackRequest };
  recievedEntities: { [iri: string]: fromModuleModels.FeedbackRequest };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  sendEntities: {},
  recievedEntities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequests,
    AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequests,
    AdministratorFeedbackRequestsActions.PatchFeedbackRequests,
    (state: State) => {

      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess,
    (state: State, { response }) => {
      const entities = response['hydra:member'];

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequestsSuccess,
    (state: State, { response }) => {
      const entities = response['hydra:member'];

      return {
        ...state,
        recievedEntities: entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequestsSuccess,
    (state: State, { response }) => {
      const entities = response['hydra:member'];

      return {
        ...state,
        sendEntities: entities,
        loading: false,
        loaded: true
      };
    }
  ),
on(
    AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequestsFail,
    AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequestsFail,
    AdministratorFeedbackRequestsActions.PatchFeedbackRequestsFail,
    (state: State, { response }) => {
      return {
        ...state,
        errors: response.error,
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
