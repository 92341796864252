import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {AbstractApiResponse} from '../../../shared/models';
import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_STORAGE_LOCATION = '[Master Data] Create Storage Location';
export const CREATE_STORAGE_LOCATION_SUCCESS = '[Master Data] Create Storage Location Success';
export const CREATE_STORAGE_LOCATION_FAIL = '[Master Data] Create Storage Location Fail';
export const CreateStorageLocation = createAction(
  CREATE_STORAGE_LOCATION,
  props<{ payload: fromModuleModels.StorageLocation }>()
);
export const CreateStorageLocationSuccess = createAction(
  CREATE_STORAGE_LOCATION_SUCCESS,
  props<{ response: fromModuleModels.StorageLocation }>()
);
export const CreateStorageLocationFail = createAction(
  CREATE_STORAGE_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_STORAGE_LOCATION = '[Master Data] Read Storage Location';
export const READ_STORAGE_LOCATION_SUCCESS = '[Master Data] Read Storage Location Success';
export const READ_STORAGE_LOCATION_FAIL = '[Master Data] Read Storage Location Fail';
export const ReadStorageLocation = createAction(
  READ_STORAGE_LOCATION,
  props<{ iri: string }>()
);
export const ReadStorageLocationSuccess = createAction(
  READ_STORAGE_LOCATION_SUCCESS,
  props<{ response }>()
);
export const ReadStorageLocationFail = createAction(
  READ_STORAGE_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_STORAGE_LOCATIONS = '[Master Data] Read Storage Locations';
export const READ_STORAGE_LOCATIONS_SUCCESS = '[Master Data] Read Storage Locations Success';
export const READ_STORAGE_LOCATIONS_FAIL = '[Master Data] Read Storage Locations Fail';
export const ReadStorageLocations = createAction(
  READ_STORAGE_LOCATIONS,
  (payload: { page?: number, params?: { [p: string]: any } } = {}) => ({...payload})
);

export const ReadStorageLocationsSuccess = createAction(
  READ_STORAGE_LOCATIONS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadStorageLocationsFail = createAction(
  READ_STORAGE_LOCATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_STORAGE_LOCATIONS_CHUNK = '[Master Data] Read Storage Locations Chunk';
export const READ_STORAGE_LOCATIONS_CHUNK_SUCCESS = '[Master Data] Read Storage Locations Chunk Success';
export const READ_STORAGE_LOCATIONS_CHUNK_FAIL = '[Master Data] Read Storage Locations Chunk Fail';

export const ReadStorageLocationsChunk = createAction(
  READ_STORAGE_LOCATIONS_CHUNK,
  props<{ uri: string }>()
);

export const ReadStorageLocationsChunkSuccess = createAction(
  READ_STORAGE_LOCATIONS_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadStorageLocationsChunkFail = createAction(
  READ_STORAGE_LOCATIONS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_STORAGE_LOCATION = '[Master Data] Update Storage Location';
export const UPDATE_STORAGE_LOCATION_SUCCESS = '[Master Data] Update Storage Location Success';
export const UPDATE_STORAGE_LOCATION_FAIL = '[Master Data] Update Storage Location Fail';
export const UpdateStorageLocation = createAction(
  UPDATE_STORAGE_LOCATION,
  props<{ iri: string, payload: fromModuleModels.StorageLocation }>()
);
export const UpdateStorageLocationSuccess = createAction(
  UPDATE_STORAGE_LOCATION_SUCCESS,
  props<{ response: fromModuleModels.StorageLocation }>()
);
export const UpdateStorageLocationFail = createAction(
  UPDATE_STORAGE_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_STORAGE_LOCATION = '[Master Data] Delete Storage Location';
export const DELETE_STORAGE_LOCATION_SUCCESS = '[Master Data] Delete Storage Location Success';
export const DELETE_STORAGE_LOCATION_FAIL = '[Master Data] Delete Storage Location Fail';
export const DeleteStorageLocation = createAction(
  DELETE_STORAGE_LOCATION,
  props<{ iri: string }>()
);
export const DeleteStorageLocationSuccess = createAction(
  DELETE_STORAGE_LOCATION_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteStorageLocationFail = createAction(
  DELETE_STORAGE_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
