import { Order } from '../models';
import { OrderShipment } from '../models/order-shipment.interface';

export const getCustomerNameFromOrder = (
  element: Order | OrderShipment
): string => {
  if (element?.decisionMakerCustomerContact) {
    const contact = element.decisionMakerCustomerContact;
    return contact.firstName + ' ' + contact.lastName;
  } else if (element?.technicalCustomerContact) {
    const contact = element.technicalCustomerContact;
    return contact.firstName + ' ' + contact.lastName;
  } else if (element?.organizationalCustomerContact) {
    const contact = element.organizationalCustomerContact;
    return contact.firstName + ' ' + contact.lastName;
  }
  if (element?.customer?.customerType['@id'] === '/api/customer_types/1') {
    // Privatkunde
    return element.customer?.nameLine1;
  } else {
    // Geschäftskunde
    return '-';
  }
};

export const getCompanyNameFromOrder = (
  element: Order | OrderShipment
): string => {
  if (!element) {
    return '';
  }

  if (element?.customer?.customerType?.['@id'] === '/api/customer_types/1') {
    // Privatkunde
    return null;
  } else {
    // Geschäftskunde
    return element?.customer?.nameLine1;
  }
};

export const isVipFromOrder = (element: Order | OrderShipment): boolean => {
  return element?.customer?.vip;
};

export const showPartnerLabelForOrder = (
  order: Order | OrderShipment | null
): boolean => {
  if (!order) {
    return false;
  }
  return !!order.broker;
};
