import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ADMINISTRATOR = '[Administrators Module] Create Administrator';
export const CREATE_ADMINISTRATOR_SUCCESS = '[Administrators Module] Create Administrator Success';
export const CREATE_ADMINISTRATOR_FAIL = '[Administrators Module] Create Administrator Fail';

export const CreateAdministrator = createAction(
  CREATE_ADMINISTRATOR,
  props<{ payload: fromModuleModels.Administrator }>()
);

export const CreateAdministratorSuccess = createAction(
  CREATE_ADMINISTRATOR_SUCCESS,
  props<{ response: fromModuleModels.Administrator }>()
);

export const CreateAdministratorFail = createAction(
  CREATE_ADMINISTRATOR_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read

export const READ_ADMINISTRATORS = '[Administrators Module] Load Administrators';
export const READ_ADMINISTRATORS_SUCCESS = '[Administrators Module] Load Administrators Success';
export const READ_ADMINISTRATORS_FAIL = '[Administrators Module] Load Administrators Fail';

export const ReadAdministrators = createAction(
  READ_ADMINISTRATORS
);

export const ReadAdministratorsSuccess = createAction(
  READ_ADMINISTRATORS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadAdministratorsFail = createAction(
  READ_ADMINISTRATORS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ADMINISTRATOR = '[Administrators Module] Update Administrator';
export const UPDATE_ADMINISTRATOR_SUCCESS = '[Administrators Module] Update Administrator Success';
export const UPDATE_ADMINISTRATOR_FAIL = '[Administrators Module] Update Administrator Fail';

export const UpdateAdministrator = createAction(
  UPDATE_ADMINISTRATOR,
  props<{ iri: string, payload: fromModuleModels.Administrator }>()
);

export const UpdateAdministratorSuccess = createAction(
  UPDATE_ADMINISTRATOR_SUCCESS,
  props<{ response: fromModuleModels.Administrator }>()
);

export const UpdateAdministratorFail = createAction(
  UPDATE_ADMINISTRATOR_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ADMINISTRATOR = '[Administrators Module] Delete Administrator';
export const DELETE_ADMINISTRATOR_SUCCESS = '[Administrators Module] Delete Administrator Success';
export const DELETE_ADMINISTRATOR_FAIL = '[Administrators Module] Delete Administrator Fail';

export const DeleteAdministrator = createAction(
  DELETE_ADMINISTRATOR,
  props<{ iri: string }>()
);

export const DeleteAdministratorSuccess = createAction(
  DELETE_ADMINISTRATOR_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteAdministratorFail = createAction(
  DELETE_ADMINISTRATOR_FAIL,
  props<{ response: HttpErrorResponse }>()
);
