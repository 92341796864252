import {BrokersViewComponent} from './brokers-view/brokers-view.component';
import {CancellationInvoiceViewComponent} from './cancellation-invoice-view/cancellation-invoice-view.component';
import {CancellationInvoicesViewComponent} from './cancellation-invoices-view/cancellation-invoices-view.component';
import {CommissionCreditViewComponent} from './commission-credit-view/commission-credit-view.component';
import {CommissionCreditsViewComponent} from './commission-credits-view/commission-credits-view.component';
import {CorrectionInvoiceViewComponent} from './correction-invoice-view/correction-invoice-view.component';
import {CorrectionInvoicesViewComponent} from './correction-invoices-view/correction-invoices-view.component';
import {InvoicesViewComponent} from './invoices-view/invoices-view.component';
import {OfferViewComponent} from './offer-view/offer-view.component';
import {OffersViewComponent} from './offers-view/offers-view.component';
import {PayableInvoiceViewComponent} from './payable-invoice-view/payable-invoice-view.component';
import {PayableInvoicesViewComponent} from './payable-invoices-view/payable-invoices-view.component';
import {CorrectionInvoiceNewComponent} from './correction-invoice-new/correction-invoice-new.component';

export const AllContainers = [
  BrokersViewComponent,
  CommissionCreditViewComponent,
  CommissionCreditsViewComponent,
  CancellationInvoiceViewComponent,
  CancellationInvoicesViewComponent,
  CorrectionInvoiceViewComponent,
  CorrectionInvoicesViewComponent,
  CorrectionInvoiceNewComponent,
  InvoicesViewComponent,
  OfferViewComponent,
  OffersViewComponent,
  PayableInvoiceViewComponent,
  PayableInvoicesViewComponent,
];

export * from './brokers-view/brokers-view.component';
export * from './cancellation-invoice-view/cancellation-invoice-view.component';
export * from './cancellation-invoices-view/cancellation-invoices-view.component';
export * from './commission-credit-view/commission-credit-view.component';
export * from './commission-credits-view/commission-credits-view.component';
export * from './correction-invoice-view/correction-invoice-view.component';
export * from './correction-invoice-new/correction-invoice-new.component';
export * from './correction-invoices-view/correction-invoices-view.component';
export * from './invoices-view/invoices-view.component';
export * from './offer-view/offer-view.component';
export * from './offers-view/offers-view.component';
export * from './payable-invoice-view/payable-invoice-view.component';
export * from './payable-invoices-view/payable-invoices-view.component';
