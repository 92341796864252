import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { BindingOrdersActions } from '../actions';
import { mergeEntities } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.BindingOrder };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  uploadStatus: { type: number; loaded: number; total?: number };
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    BindingOrdersActions.CreateBindingOrder,
    BindingOrdersActions.UploadBindingOrder,
    BindingOrdersActions.ReadBindingOrder,
    BindingOrdersActions.ReadBindingOrders,
    BindingOrdersActions.DeleteBindingOrder,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(BindingOrdersActions.UploadBindingOrderStatus, (state, { response }) => {
    return {
      ...state,
      uploadStatus: response,
      loading: true
    };
  }),
  on(BindingOrdersActions.CreateBindingOrderSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      uploadStatus: null,
      loading: false,
      loaded: false
    };
  }),
  on(BindingOrdersActions.UploadBindingOrderSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response.body['@id']]: response.body
    };

    return {
      ...state,
      entities,
      uploadStatus: null,
      loading: false,
      loaded: false
    };
  }),
  on(BindingOrdersActions.ReadBindingOrdersSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.BindingOrder }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(BindingOrdersActions.ReadBindingOrderSuccess, (state, { response }) => {
    return {
      ...state,
      entities: mergeEntities([response], null, state.entities),
      loading: false,
      loaded: true
    };
  }),
  on(BindingOrdersActions.DeleteBindingOrderSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(
    BindingOrdersActions.ReadBindingOrderFail,
    BindingOrdersActions.UploadBindingOrderFail,
    BindingOrdersActions.CreateBindingOrderFail,
    BindingOrdersActions.DeleteBindingOrderFail,
    state => {
      return {
        ...state,
        uploadStatus: null,
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
