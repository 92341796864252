import { CustomerAccountInvitationsEffects } from './customer-account-invitations.effects';
import { CustomerAccountsEffects } from './customer-accounts.effects';
import { CustomerAddressesEffects } from './customer-addresses.effects';
import { CustomerCommentTagsEffects } from './customer-comment-tags.effects';
import { CustomerCommentsEffects } from './customer-comments.effects';
import { CustomerContactTypesEffects } from './customer-contact-types.effects';
import { CustomerContactsEffects } from './customer-contacts.effects';
import { CustomerInvoicesEffects } from './customer-invoices.effects';
import { CustomerOffersEffects } from './customer-offers.effects';
import { CustomerOrdersEffects } from './customer-orders.effects';
import { CustomerPartnerStatusesEffects } from './customer-partner-statuses.effects';
import { CustomerTypesEffects } from './customer-types.effects';
import { CustomersEffects } from './customers.effects';
import { PartnerWebsiteInvitationsEffects } from './partner-website-invitations.effects';
import { PartnerWebsitesEffects } from './partner-websites.effects';
import { PartnersEffects } from './partners.effects';

export const effects: Array<object> = [
  CustomerAccountInvitationsEffects,
  CustomerAccountsEffects,
  CustomerAddressesEffects,
  CustomerCommentTagsEffects,
  CustomerCommentsEffects,
  CustomerContactTypesEffects,
  CustomerContactsEffects,
  CustomerInvoicesEffects,
  CustomerOffersEffects,
  CustomerOrdersEffects,
  CustomerPartnerStatusesEffects,
  CustomerTypesEffects,
  CustomersEffects,
  PartnerWebsiteInvitationsEffects,
  PartnerWebsitesEffects,
  PartnersEffects
];

export * from './customer-account-invitations.effects';
export * from './customer-accounts.effects';
export * from './customer-comment-tags.effects';
export * from './customer-comments.effects';
export * from './customer-contact-types.effects';
export * from './customer-contacts.effects';
export * from './customer-invoices.effects';
export * from './customer-offers.effects';
export * from './customer-orders.effects';
export * from './customer-partner-statuses.effects';
export * from './customer-types.effects';
export * from './customers.effects';
export * from './partner-website-invitations.effects';
export * from './partner-websites.effects';
export * from './partners.effects';
