import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tracker-input',
  styleUrls: ['./tracker-input.component.scss'],
  template: `
    <div [formGroup]="form">
      <mat-form-field>
        <mat-label>TrackerId</mat-label>
        <span matPrefix>BMTR &nbsp; </span>
        <input type="text" matInput formControlName="value" />
        <mat-error>
          <app-form-error
            [fieldName]="'value'"
            [formGroup]="form"
          ></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>
  `
})
export class TrackerInputComponent extends BaseOnDestroyComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() @Optional() touched = false;
  @Input() readonly = false;

  isLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    @Self() @Optional() public control: NgControl
  ) {
    super();
    this.form = this.fb.group({
      value: this.fb.control('', [Validators.required])
    });

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.form
      .get('value')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        if (!value || value.length <= 0) {
          this.onChange(null);
        } else {
          this.onChange('BMTR' + value);
        }
      });
    if (this.control) {
      // this.form.get('input').setValidators(this.control.validator);
      this.control.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(data => {
          console.log('this.control.valueChanges', data);
        });

      this.control.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(data => {
          console.log(
            'this.control.statusChanges',
            data,
            this.control.control,
            this.control.control.errors,
            this.control.control.touched
          );
          this.form.controls.value.setErrors(this.control.control.errors);
          if (this.control.control.touched) {
            this.form.controls.value.markAllAsTouched();
          }
        });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('value').disable();
    } else {
      this.form.get('value').enable();
    }
  }

  writeValue(value: any): void {
    if (value && value.indexOf('BMTR') > -1) {
      value = value.split('BMTR').join('');
    }
    this.form.get('value').setValue(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.touched.currentValue === true) {
      this.form.get('value').markAsTouched();
    }
  }
}
