import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

// import * as fromCustomerStockItemsReducers from './customer-stock-items.reducers';
// import * as fromCustomerReplacementStockItemsReducers from './customer-replacement-stock-items.reducers';
// import * as fromStockItemsReducers from './stock-items.reducers';

import * as fromCustomerReplacementStockItemsReducers from './customer-replacement-stock-items.reducers';
import * as fromCustomerStockItemsReducers from './customer-stock-items.reducers';
import * as fromDataMediumsReducers from './data-mediums.reducers';
import * as fromEnclosureDataMediumsReducers from './enclosure-data-mediums.reducers';
import * as fromReplacementDataMediumsReducers from './replacement-data-mediums.reducers';
import * as fromReplacementStockItemsReducers from './replacement-stock-items.reducers';
import * as fromStockItemsReducers from './stock-items.reducers';

export const WAREHOUSE_MODULE_FEATURE_KEY = 'warehouseModule';

export const CUSTOMER_REPLACEMENT_STOCK_ITEMS_FEATURE_KEY = 'customerReplacementStockItems';
export const CUSTOMER_STOCK_ITEMS_FEATURE_KEY = 'customerStockItems';
export const DATA_MEDIUMS_FEATURE_KEY = 'dataMediums';
export const ENCLOSURE_DATA_MEDIUMS_FEATURE_KEY = 'enclosureDataMediums';
export const REPLACEMENT_DATA_MEDIUMS_FEATURE_KEY = 'replacementDataMediums';
export const REPLACEMENT_STOCK_ITEMS_FEATURE_KEY = 'replacementStockItems';
export const STOCK_ITEMS_FEATURE_KEY = 'stockItems';

export interface WarehouseModuleState {
  [CUSTOMER_REPLACEMENT_STOCK_ITEMS_FEATURE_KEY]: fromCustomerReplacementStockItemsReducers.State;
  [CUSTOMER_STOCK_ITEMS_FEATURE_KEY]: fromCustomerStockItemsReducers.State;
  [DATA_MEDIUMS_FEATURE_KEY]: fromDataMediumsReducers.State;
  [ENCLOSURE_DATA_MEDIUMS_FEATURE_KEY]: fromEnclosureDataMediumsReducers.State;
  [REPLACEMENT_DATA_MEDIUMS_FEATURE_KEY]: fromReplacementDataMediumsReducers.State;
  [REPLACEMENT_STOCK_ITEMS_FEATURE_KEY]: fromReplacementStockItemsReducers.State;
  [STOCK_ITEMS_FEATURE_KEY]: fromStockItemsReducers.State;
}

export const selectWarehouseModuleState = createFeatureSelector<WarehouseModuleState>(WAREHOUSE_MODULE_FEATURE_KEY);

export function reducers(state: WarehouseModuleState, action: Action) {
  return combineReducers({
    [CUSTOMER_REPLACEMENT_STOCK_ITEMS_FEATURE_KEY]: fromCustomerReplacementStockItemsReducers.reducer,
    [CUSTOMER_STOCK_ITEMS_FEATURE_KEY]: fromCustomerStockItemsReducers.reducer,
    [DATA_MEDIUMS_FEATURE_KEY]: fromDataMediumsReducers.reducer,
    [ENCLOSURE_DATA_MEDIUMS_FEATURE_KEY]: fromEnclosureDataMediumsReducers.reducer,
    [REPLACEMENT_DATA_MEDIUMS_FEATURE_KEY]: fromReplacementDataMediumsReducers.reducer,
    [REPLACEMENT_STOCK_ITEMS_FEATURE_KEY]: fromReplacementStockItemsReducers.reducer,
    [STOCK_ITEMS_FEATURE_KEY]: fromStockItemsReducers.reducer,
  })(state, action);
}
