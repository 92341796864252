import {createSelector} from '@ngrx/store';

import {LEADS_FEATURE_KEY, selectLeadsModuleState} from '../reducers';
// import { getRouterState, RouterStateUrl } from '../../../application-state/store';

export const selectLeadsState = createSelector(
  selectLeadsModuleState,
  (leadsModuleState) => leadsModuleState[LEADS_FEATURE_KEY]
);

export const selectLeadsEntities = createSelector(
  selectLeadsState,
  (state) => state.entities
);

export const selectLeads = createSelector(
  selectLeadsEntities,
  (entities) => Object.values(entities)
);

export const selectLead = createSelector(
  selectLeadsEntities,
  (entities, {iri}) => {
    if (entities[iri]) {
      return entities[iri];
    } else {
      const foundIri = Object.keys(entities).find(e => e.indexOf(iri) > -1);
      if (!foundIri) {
        return null;
      } else {
        return entities[foundIri];
      }
    }

  });

// todo: archived leads should be provided thru dedicated entities; filtering all values is just a temporary solution
export const selectNewLeads = createSelector(
  selectLeads,
  (leads) => leads.filter(lead => lead.state === 'new')
);
export const selectNewLeadsOfColumnOne = createSelector(
  selectNewLeads,
  (leads) => leads.filter(e => e.column === 1)
);

export const selectArchivedLeads = createSelector(
  selectLeads,
  (leads) => leads.filter(lead => lead.state === 'archived')
);

export const selectErrors = createSelector(
  selectLeadsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectLeadsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectLeadsState,
  (state) => state.loaded
);

