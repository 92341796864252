import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromAddressTypesReducers from './address-types.reducers';
import * as fromAnalysisPriorityModesReducers from './analysis-priority-modes.reducers';
import * as fromAnalysisResultCategoriesReducers from './analysis-result-categories.reducers';
import * as fromAnalysisResultsReducers from './analysis-results.reducers';
import * as fromCountriesReducers from './countries.reducers';
import * as fromCurrenciesReducers from './currencies.reducers';
import * as fromCustomerCooperationsReducers from './customer-cooperations.reducers';
import * as fromDamagesReducers from './damages.reducers';
import * as fromDataRecoveryPriorityModesReducers from './data-recovery-priority-modes.reducers';
import * as fromDepartmentLogosReducers from './department-logos.reducers';
import * as fromDepartmentAdsReducers from './department-ads.reducers';
import * as fromDepartmentReducers from './departments.reducers';
import * as fromDestructionTypesReducers from './destruction-types.reducers';
import * as fromDiscountsReducers from './discounts.reducers';
import * as fromDisposalTypesReducers from './disposal-types.reducers';
import * as fromFileSystemsReducers from './file-systems.reducers';
import * as fromGradesReducers from './grades.reducers';
import * as fromLabLocationsReducers from './lab-locations.reducers';
import * as fromLocalesReducers from './locales.reducers';
import * as fromOperatingSystemsReducers from './operating-systems.reducers';
import * as fromProductUnitsReducers from './product-units.reducers';
import * as fromProductsReducers from './products.reducers';
import * as fromReplacementDataMediumSourcesReducers from './replacement-data-medium-sources.reducers';
import * as fromRolesReducers from './roles.reducers';
import * as fromSalutationsReducers from './salutations.reducers';
import * as fromServicesReducers from './services.reducers';
import * as fromSpecialAgreementsReducers from './special-agreements.reducers';
import * as fromStorageLocationsReducers from './storage-locations.reducers';
import * as fromStorageSystemManufacturersReducers from './storage-system-manufacturers.reducers';
import * as fromStorageSystemsReducers from './storage-systems.reducers';
import * as fromSymptomsReducers from './symptoms.reducers';
import * as fromTaxesReducers from './taxes.reducers';
import * as fromTermsAndConditionsReducers from './terms-and-conditions.reducers';
import * as EmailTemplateCategoriesReducers from './email-template-categories.reducers';
import * as EmailTemplatesReducers from './email-templates.reducers';
import * as EmailTemplateContextsReducers from './email-template-contexts.reducers';

export const MASTER_DATA_MODULE_FEATURE_KEY = 'masterDataModule';

export const ADDRESS_TYPES_FEATURE_KEY = 'addressTypes';
export const ANALYSIS_PRIORITY_MODES_FEATURE_KEY = 'analysisPriorityModes';
export const ANALYSIS_RESULTS_FEATURE_KEY = 'analysisResults';
export const ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY = 'analysisResultCategories';
export const COUNTRIES_FEATURE_KEY = 'countries';
export const CUSTOMER_COOPERATIONS_FEATURE_KEY = 'customerCooperations';
export const CURRENCIES_FEATURE_KEY = 'currencies';
export const DAMAGES_FEATURE_KEY = 'damages';
export const DATA_RECOVERY_PRIORITY_MODES_FEATURE_KEY = 'dataRecoveryPriorityModes';
export const DEPARTMENTS_FEATURE_KEY = 'departments';
export const DEPARTMENT_LOGOS_FEATURE_KEY = 'departmentLogos';
export const DEPARTMENT_ADS_FEATURE_KEY = 'departmentAds';
export const DESTRUCTION_TYPES_FEATURE_KEY = 'destructionTypes';
export const DISCOUNTS_FEATURE_KEY = 'discounts';
export const DISPOSAL_TYPES_FEATURE_KEY = 'disposalTypes';
export const FILE_SYSTEMS_FEATURE_KEY = 'fileSystems';
export const GRADES_FEATURE_KEY = 'grades';
export const LAB_LOCATIONS_FEATURE_KEY = 'labLocations';
export const LOCALES_FEATURE_KEY = 'locales';
export const OPERATING_SYSTEMS_FEATURE_KEY = 'operatingSystems';
export const PRODUCTS_FEATURE_KEY = 'products';
export const PRODUCT_UNITS_FEATURE_KEY = 'productUnits';
export const REPLACEMENT_DATA_MEDIUM_SOURCES = 'replacementDataMediumSources';
export const ROLES_FEATURE_KEY = 'roles';
export const SALUTATIONS_FEATURE_KEY = 'salutations';
export const SERVICES_FEATURE_KEY = 'services';
export const SPECIAL_AGREEMENTS_FEATURE_KEY = 'specialAgreements';
export const STORAGE_LOCATIONS_FEATURE_KEY = 'storageLocations';
export const STORAGE_SYSTEMS_FEATURE_KEY = 'storageSystems';
export const STORAGE_SYSTEM_MANUFACTURERS_FEATURE_KEY = 'storageSystemManufacturers';
export const SYMPTOMS_FEATURE_KEY = 'symptoms';
export const TAXES_FEATURE_KEY = 'taxes';
export const TERMS_AND_CONDITIONS_FEATURE_KEY = 'termsAndConditions';
export const EMAIL_TEMPLATE_CATEGORIES = 'emailTemplateCategories';
export const EMAIL_TEMPLATES = 'emailTemplates';
export const EMAIL_TEMPLATE_CONTEXTS = 'emailTemplateContexts';

export interface MasterDataModuleState {
  [ADDRESS_TYPES_FEATURE_KEY]: fromAddressTypesReducers.State;
  [EMAIL_TEMPLATE_CATEGORIES]: EmailTemplateCategoriesReducers.State;
  [EMAIL_TEMPLATE_CONTEXTS]: EmailTemplateContextsReducers.State;
  [EMAIL_TEMPLATES]: EmailTemplatesReducers.State;
  [ANALYSIS_PRIORITY_MODES_FEATURE_KEY]: fromAnalysisPriorityModesReducers.State;
  [ANALYSIS_RESULTS_FEATURE_KEY]: fromAnalysisResultsReducers.State;
  [ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY]: fromAnalysisResultCategoriesReducers.State;
  [COUNTRIES_FEATURE_KEY]: fromCountriesReducers.State;
  [CURRENCIES_FEATURE_KEY]: fromCurrenciesReducers.State;
  [CUSTOMER_COOPERATIONS_FEATURE_KEY]: fromCustomerCooperationsReducers.State;
  [DAMAGES_FEATURE_KEY]: fromDamagesReducers.State;
  [DATA_RECOVERY_PRIORITY_MODES_FEATURE_KEY]: fromDataRecoveryPriorityModesReducers.State;
  [DEPARTMENTS_FEATURE_KEY]: fromDepartmentReducers.State;
  [DEPARTMENT_LOGOS_FEATURE_KEY]: fromDepartmentLogosReducers.State;
  [DEPARTMENT_ADS_FEATURE_KEY]: fromDepartmentAdsReducers.State;
  [DESTRUCTION_TYPES_FEATURE_KEY]: fromDestructionTypesReducers.State;
  [DISCOUNTS_FEATURE_KEY]: fromDiscountsReducers.State;
  [DISPOSAL_TYPES_FEATURE_KEY]: fromDisposalTypesReducers.State;
  [FILE_SYSTEMS_FEATURE_KEY]: fromFileSystemsReducers.State;
  [GRADES_FEATURE_KEY]: fromGradesReducers.State;
  [LAB_LOCATIONS_FEATURE_KEY]: fromLabLocationsReducers.State;
  [LOCALES_FEATURE_KEY]: fromLocalesReducers.State;
  [OPERATING_SYSTEMS_FEATURE_KEY]: fromOperatingSystemsReducers.State;
  [PRODUCTS_FEATURE_KEY]: fromProductsReducers.State;
  [PRODUCT_UNITS_FEATURE_KEY]: fromProductUnitsReducers.State;
  [REPLACEMENT_DATA_MEDIUM_SOURCES]: fromReplacementDataMediumSourcesReducers.State;
  [ROLES_FEATURE_KEY]: fromRolesReducers.State;
  [SALUTATIONS_FEATURE_KEY]: fromSalutationsReducers.State;
  [SERVICES_FEATURE_KEY]: fromServicesReducers.State;
  [SPECIAL_AGREEMENTS_FEATURE_KEY]: fromSpecialAgreementsReducers.State;
  [STORAGE_LOCATIONS_FEATURE_KEY]: fromStorageLocationsReducers.State;
  [STORAGE_SYSTEMS_FEATURE_KEY]: fromStorageSystemsReducers.State;
  [STORAGE_SYSTEM_MANUFACTURERS_FEATURE_KEY]: fromStorageSystemManufacturersReducers.State;
  [SYMPTOMS_FEATURE_KEY]: fromSymptomsReducers.State;
  [TAXES_FEATURE_KEY]: fromTaxesReducers.State;
  [TERMS_AND_CONDITIONS_FEATURE_KEY]: fromTermsAndConditionsReducers.State;
}

export const selectMasterDataModuleState = createFeatureSelector<MasterDataModuleState>(MASTER_DATA_MODULE_FEATURE_KEY);

export function reducers(state: MasterDataModuleState, action: Action) {
  return combineReducers({
    [EMAIL_TEMPLATE_CONTEXTS]: EmailTemplateContextsReducers.reducer,
    [EMAIL_TEMPLATE_CATEGORIES]: EmailTemplateCategoriesReducers.reducer,
    [EMAIL_TEMPLATES]: EmailTemplatesReducers.reducer,
    [ADDRESS_TYPES_FEATURE_KEY]: fromAddressTypesReducers.reducer,
    [ANALYSIS_PRIORITY_MODES_FEATURE_KEY]: fromAnalysisPriorityModesReducers.reducer,
    [ANALYSIS_RESULTS_FEATURE_KEY]: fromAnalysisResultsReducers.reducer,
    [ANALYSIS_RESULT_CATEGORIES_FEATURE_KEY]: fromAnalysisResultCategoriesReducers.reducer,
    [COUNTRIES_FEATURE_KEY]: fromCountriesReducers.reducer,
    [CURRENCIES_FEATURE_KEY]: fromCurrenciesReducers.reducer,
    [CUSTOMER_COOPERATIONS_FEATURE_KEY]: fromCustomerCooperationsReducers.reducer,
    [DAMAGES_FEATURE_KEY]: fromDamagesReducers.reducer,
    [DATA_RECOVERY_PRIORITY_MODES_FEATURE_KEY]: fromDataRecoveryPriorityModesReducers.reducer,
    [DEPARTMENTS_FEATURE_KEY]: fromDepartmentReducers.reducer,
    [DEPARTMENT_LOGOS_FEATURE_KEY]: fromDepartmentLogosReducers.reducer,
    [DEPARTMENT_ADS_FEATURE_KEY]: fromDepartmentAdsReducers.reducer,
    [DESTRUCTION_TYPES_FEATURE_KEY]: fromDestructionTypesReducers.reducer,
    [DISCOUNTS_FEATURE_KEY]: fromDiscountsReducers.reducer,
    [DISPOSAL_TYPES_FEATURE_KEY]: fromDisposalTypesReducers.reducer,
    [FILE_SYSTEMS_FEATURE_KEY]: fromFileSystemsReducers.reducer,
    [GRADES_FEATURE_KEY]: fromGradesReducers.reducer,
    [LAB_LOCATIONS_FEATURE_KEY]: fromLabLocationsReducers.reducer,
    [LOCALES_FEATURE_KEY]: fromLocalesReducers.reducer,
    [OPERATING_SYSTEMS_FEATURE_KEY]: fromOperatingSystemsReducers.reducer,
    [PRODUCTS_FEATURE_KEY]: fromProductsReducers.reducer,
    [PRODUCT_UNITS_FEATURE_KEY]: fromProductUnitsReducers.reducer,
    [REPLACEMENT_DATA_MEDIUM_SOURCES]: fromReplacementDataMediumSourcesReducers.reducer,
    [ROLES_FEATURE_KEY]: fromRolesReducers.reducer,
    [SALUTATIONS_FEATURE_KEY]: fromSalutationsReducers.reducer,
    [SERVICES_FEATURE_KEY]: fromServicesReducers.reducer,
    [SPECIAL_AGREEMENTS_FEATURE_KEY]: fromSpecialAgreementsReducers.reducer,
    [STORAGE_LOCATIONS_FEATURE_KEY]: fromStorageLocationsReducers.reducer,
    [STORAGE_SYSTEMS_FEATURE_KEY]: fromStorageSystemsReducers.reducer,
    [STORAGE_SYSTEM_MANUFACTURERS_FEATURE_KEY]: fromStorageSystemManufacturersReducers.reducer,
    [SYMPTOMS_FEATURE_KEY]: fromSymptomsReducers.reducer,
    [TAXES_FEATURE_KEY]: fromTaxesReducers.reducer,
    [TERMS_AND_CONDITIONS_FEATURE_KEY]: fromTermsAndConditionsReducers.reducer,
  })(state, action);
}
