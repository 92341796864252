import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'notifications-counter',
  styleUrls: ['notifications-counter.component.scss'],
  template: `

    <ng-container *ngIf="notificationsCount != undefined; else loading">
    <span class="inner"
          matRipple
          [matRippleDisabled]="parseInteger(notificationsCount) === 0"
          (click)="handleRequestOpenDropdown()">
      <mat-icon>notifications_active</mat-icon>
      <span class="amount" #amount>{{ this.notificationsCount }}</span>
    </span>
    </ng-container>

    <ng-template #loading>
    <span class="inner placeholder">
      <mat-spinner [diameter]="20"></mat-spinner>
    </span>
    </ng-template>
  `
})
export class NotificationsCounterComponent {

  @Input()
  notificationsCount: number;

  @Output()
  requestOpenDropdown: EventEmitter<void> = new EventEmitter<void>();

  handleRequestOpenDropdown(): void {

    if (this.parseInteger(this.notificationsCount) > 0) {
      this.requestOpenDropdown.emit();
    }
  }

  parseInteger(input) {
    return parseInt(input);
  }
}
