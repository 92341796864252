import {Component, Inject, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {Observable, Subject} from "rxjs";
import {Lead} from "../../../leads/models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApplicationState} from "../../../application-state/store";
import {loadIfNotLoaded} from "../../utilities/observable.utility";
import {EmailTemplateContextsActions} from "../../../master-data/store";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EmailTemplateContextsSelectors} from "../../../master-data/store/selectors";
import {AbstractApiService} from "../../services";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-lead-shipment-mail',
  templateUrl: './lead-shipment-mail.component.html',
  styleUrls: ['./lead-shipment-mail.component.scss']
})
export class LeadShipmentMailComponent implements OnInit {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  emailTemplateContexts$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lead: Lead;
    },
    private fb: FormBuilder,
    private notifier: NotifierService,
    private dialogRef: MatDialogRef<LeadShipmentMailComponent>,
    private apiService: AbstractApiService
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        subject: this.fb.control('', Validators.required),
        body: this.fb.control('', Validators.required)
      }
    )
    this.emailTemplateContexts$ = this.apiService.getObject('/api/template_contexts/ORDER_CONFIRMATION', true);
  }

  handleSendEmail(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  copyTextToClipboard(item: string): void {
    navigator.clipboard.writeText(`{{${item}}}`).then(() => {
      this.notifier.show({type: 'success', message: item + ' Erfolgreich in die Zwischenablage kopiert.'});
    }, (err) => {
      this.notifier.show({type: 'error', message: ' Konnte Text nicht in die Zwischenablage kopieren.' + err});
    });
  }
}
