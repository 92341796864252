import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GradesActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { GradesService } from '../../services/grades.service';

@Injectable()
export class GradesEffects {
  createGrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GradesActions.CreateGrade),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createGrade(payload).pipe(
          map(response => GradesActions.CreateGradeSuccess({ response })),
          catchError(response =>
            of(GradesActions.CreateGradeFail({ response }))
          )
        );
      })
    )
  );
  getGrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GradesActions.ReadGrade),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getGrade(iri).pipe(
          map(response => GradesActions.ReadGradeSuccess({ response })),
          catchError(response => of(GradesActions.ReadGradeFail({ response })))
        );
      })
    )
  );
  getGrades$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GradesActions.ReadGrades),
      map(action => action),
      switchMap(() => {
        return this.service.getGrades().pipe(
          map(response => GradesActions.ReadGradesSuccess({ response })),
          catchError(response => of(GradesActions.ReadGradesFail({ response })))
        );
      })
    )
  );
  updateGrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GradesActions.UpdateGrade),
      map(action => action),
      switchMap(payload => {
        return this.service.updateGrade(payload).pipe(
          map(response => GradesActions.UpdateGradeSuccess({ response })),
          catchError(response =>
            of(GradesActions.UpdateGradeFail({ response }))
          )
        );
      })
    )
  );
  deleteGrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GradesActions.DeleteGrade),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteGrade(iri).pipe(
          map(() => GradesActions.DeleteGradeSuccess({ iri })),
          catchError(response =>
            of(GradesActions.DeleteGradeFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GradesActions.DeleteGradeFail,
          GradesActions.UpdateGradeFail,
          GradesActions.ReadGradesFail,
          GradesActions.ReadGradeFail,
          GradesActions.CreateGradeFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  gradeActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GradesActions.CreateGradeSuccess,
        GradesActions.UpdateGradeSuccess
      ),
      map(({ type }) => {
        const text =
          type === GradesActions.CREATE_GRADE_SUCCESS
            ? 'Eine neuer akademischer Grad wurde angelegt.'
            : 'Der akademische Grad wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: GradesService,
    private notifierService: NotifierService
  ) {}
}
