import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {AbstractApiService} from '../../shared/services';
import {AbstractApiResponse} from '../../shared/models';
import * as fromModuleModels from '../models';

@Injectable()
export class LocalesService {

  constructor(private apiService: AbstractApiService) {
  }

  getLocale(code: string): Observable<fromModuleModels.Locale> {
    return this.apiService.getObject(`/intl/locales/${code}`);
  }

  getLocales(): Observable<AbstractApiResponse | any> {
    return this.apiService.getObject(`/intl/locales`);
  }

  getSupportedLocales(): Observable<any> {
    return this.apiService.getObject(`/intl/supported_locales`);
  }
}
