import {createSelector} from '@ngrx/store';

import {LEAD_CONTACT_TYPES_FEATURE_KEY, selectLeadsModuleState} from '../reducers';
import * as fromLeadsSelectors from './leads.selectors';

export const selectLeadContactTypesState = createSelector(
  selectLeadsModuleState,
  (leadsModuleState) => leadsModuleState[LEAD_CONTACT_TYPES_FEATURE_KEY]
);

export const selectLeadContactTypesEntities = createSelector(
  selectLeadContactTypesState,
  (state) => state.entities
);

export const selectLeadContactTypes = createSelector(
  selectLeadContactTypesEntities,
  (entities) => Object.values(entities)
);
export const selectLeadContactTypeByIri = createSelector(
  selectLeadContactTypesEntities,
  (entities, {iri}) => entities[iri]
);


/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectLeadContactTypesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectLeadContactTypesState,
  (state) => state.loaded
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

