import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Order } from '../../models';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { filter, takeUntil } from 'rxjs/operators';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { ParameterBag } from '../../../shared/models/ParameterBag.interface';

@Component({
  selector: 'app-analysis-done-list-widget',
  styleUrls: ['./analysis-done-list-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Fertige Analysen</span>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class AnalysisDoneListWidgetComponent extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  states: string[];
  // additionalParams: ParameterBag;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.states = ['customer_analysis_created', 'customer_analysis_delayed'];
    // this.store$
    //   .select(UserProfileSelectors.selectUserProfile)
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     filter(e => e['@id'])
    //   )
    //   .subscribe(profile => {
    //     this.additionalParams = {
    //       'salesperson.uuid': getUuidFromIri(extractIri(profile))
    //     };
    //   });
  }

  getCircleColor(order: Order): { [key: string]: string } {
    if (order.rawState.indexOf('customer_analysis_delayed') > -1) {
      return { backgroundColor: 'red' };
    }
    return { backgroundColor: '#ffc400' };
  }
}
