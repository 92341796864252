import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';

@Injectable()
export class CustomerStockItemLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return of(true).pipe(
      // switchMap(() => of(true)),
      catchError(() => of(true))
    );
  }
}
