import * as CustomerAccountInvitationsActions from './customer-account-invitations.actions';
import * as CustomerAccountsActions from './customer-accounts.actions';
import * as CustomerAddressesActions from './customer-addresses.actions';
import * as CustomerCommentTagsActions from './customer-comment-tags.actions';
import * as CustomerCommentsActions from './customer-comments.actions';
import * as CustomerContactTypesActions from './customer-contact-types.actions';
import * as CustomerContactsActions from './customer-contacts.actions';
import * as CustomerInvoicesActions from './customer-invoices.actions';
import * as CustomerOffersActions from './customer-offers.actions';
import * as CustomerOrdersActions from './customer-orders.actions';
import * as CustomerPartnerStatusesActions from './customer-partner-statuses.actions';
import * as CustomerTypesActions from './customer-types.actions';
import * as CustomersActions from './customers.actions';
import * as PartnerWebsiteInvitationsActions from './partner-website-invitations.actions';
import * as PartnerWebsitesActions from './partner-websites.actions';
import * as PartnersActions from './partners.actions';

export {
  CustomerAccountInvitationsActions,
  CustomerAccountsActions,
  CustomerAddressesActions,
  CustomerCommentTagsActions,
  CustomerCommentsActions,
  CustomerContactTypesActions,
  CustomerContactsActions,
  CustomerInvoicesActions,
  CustomerOffersActions,
  CustomerOrdersActions,
  CustomerPartnerStatusesActions,
  CustomerTypesActions,
  CustomersActions,
  PartnerWebsiteInvitationsActions,
  PartnerWebsitesActions,
  PartnersActions
};
