import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrdersSelectors } from '../../store/selectors';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Order } from '../../models';
import { PageEvent } from '@angular/material/paginator';
import { OrdersActions } from '../../store';
import { Ticket } from '../../../tickets/models';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { RouterActions } from '../../../application-state/store/actions';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserProfileSelectors } from '../../../auth/store/selectors';

@Component({
  selector: 'app-datarecovery-done-list-widget',
  styleUrls: ['./datarecovery-done-list-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Fertige Datenrettungen</span>
          <span class="spacer"></span>
          <mat-button-toggle-group
            [(ngModel)]="selectedPlace"
            name="Ort"
            class="m-r--16"
            (change)="changePlace($event)"
          >
            <mat-button-toggle value="all">Alle</mat-button-toggle>
            <mat-button-toggle
              [value]="place.stateFind"
              *ngFor="let place of places"
              >{{ place.name }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="widget_content" style="position: relative;">
          <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
          <table
            class="bmo-table bmo-table-hover table-dense bmo-table-clickable"
          >
            <tbody>
              <tr
                *ngFor="
                  let order of filterState(orders$ | async)
                    | slice: lowValue:highValue
                "
                (click)="handleClick(order)"
              >
                <td class="p-l--32" style="width: 50px; position: relative;">
                  <span
                    class="badge--express"
                    *ngIf="order.analysisPriorityMode.name === 'EXPRESS'"
                    ><span>Express</span></span
                  >
                  <div class="circle" [ngClass]="getCircleColor(order)"></div>
                </td>
                <td style="width: 80px;">
                  <span [class.orange]="!order.orderNumber">
                    {{ order.orderNumber ? order.orderNumber : '-' }}
                  </span>
                </td>
                <td style="width: 100px;">
                  <span> {{ order.analysisPriorityMode.name }} </span>
                </td>
                <td>
                  <span
                    class="badge--vip inline small"
                    *ngIf="order.customer.vip"
                    >VIP</span
                  >
                  <span> {{ order.customer.nameLine1 }} </span>
                  <span *ngIf="order.customer.nameLine2">
                    [{{ order.customer.nameLine2 }}]
                  </span>
                </td>
                <!--<td>
                          <pre>{{invoice|json}}</pre>
                        </td>-->
                <td style="width: 100px;" *ngIf="order.ticket">
                  <button mat-button (click)="openTicket($event, order.ticket)">
                    Ticket #{{ order.ticket.ticketNumber }}
                  </button>
                </td>
                <td style="width: 170px;">
                  <span
                    [matTooltip]="order.createdAt | date: 'dd.MM.YYYY'"
                    matTooltipPosition="above"
                    >{{ order.createdAt | momentDuration }}
                  </span>
                </td>
                <td style="width: 30px;" class="p-r--8">
                  <mat-icon *ngIf="false" style="color:#ffc400;"
                    >report_problem
                  </mat-icon>
                </td>
                <!--<pre> {{order|json}} </pre>-->
              </tr>
              <tr *ngIf="filterState(orders$ | async)?.length <= 0">
                <td class="text-center">
                  <span>Keine Datenrettungen vorhanden</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="widget_footer">
          <app-paginator-unstyled
            [totalItems]="filterState(orders$ | async)?.length"
            [pageSize]="5"
            [pageSizeOptions]="[5, 10, 15, 20]"
            (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
            [showFirstLastButtons]="true"
          ></app-paginator-unstyled>
        </div>
      </div>
    </div>
  `
})
export class DatarecoveryDoneListWidgetComponent implements OnInit, OnDestroy {
  selectedPlace: string = 'all';
  lowValue = 0;
  highValue = 5;
  isLoading$: Observable<boolean>;
  orders$: Observable<Array<Order>>;
  onDestroy$: Subject<any> = new Subject<any>();
  places = [
    { name: 'DR DE', stateFind: '_dr_de' },
    { name: 'DR Ext', stateFind: '_dr_ext' },
    { name: 'DR Remote', stateFind: '_dr_remote' }
  ];

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(OrdersSelectors.isLoading);
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(profile => {
        this.orders$ = this.store$.select(
          OrdersSelectors.sByStateRange(
            'data_recovery_completed'
            // ,{salesperson: extractIri(profile)}
          )
        );

        // console.log(profile);
        this.store$.dispatch(
          OrdersActions.ReadOrders({
            page: -1,
            params: {
              stateRange: ['data_recovery_completed']
              //'salesperson.uuid': getUuidFromIri(extractIri(profile))
            }
          })
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  changePlace(value): void {
    // console.log(value);
    this.lowValue = 0;
    this.highValue = 5;
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  handleClick($event): void {
    const iri = $event['@id'];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    this.store$.dispatch(RouterActions.Go({ path: ['orders', uuid] }));
  }

  openTicket($event: MouseEvent, ticket: Ticket): void {
    $event.stopPropagation();
    const iri = ticket['@id'];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    this.store$.dispatch(RouterActions.Go({ path: ['tickets', uuid] }));
  }

  filterState(l: Array<Order>): Array<Order> {
    if (this.selectedPlace === 'all') {
      return l;
    }
    return l.filter(
      e => !!e.rawState.find(e => e.indexOf(this.selectedPlace) > -1)
    );
  }

  getCircleColor(order: Order): string[] {
    if (order.rawState.indexOf('customer_analysis_delayed') > -1) {
      return ['red'];
    }
    return ['yellow'];
  }
}
