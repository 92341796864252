import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { TicketAttachment } from '../../../tickets/models';
import { Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { filter, takeUntil } from 'rxjs/operators';
import { TicketAttachmentsSelectors } from '../../../tickets/store/selectors';
import { TicketAttachmentsService } from '../../../tickets/services';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { TicketAttachmentsActions } from '../../../tickets/store';
import { FileInputComponent } from '../file-input/file-input.component';
import { Actions, ofType } from '@ngrx/effects';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { extractIri } from '../../utilities/objects.utility';
import { getErrorOfResponse } from '../../utilities/error-utility.utility';

@Component({
  selector: 'app-ticket-attachments',
  styleUrls: ['ticket-attachments.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Dateien zum Auftrag</span>
      </div>

      <div class="card__content p-a--24">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <ol *ngIf="(attachments$ | async)?.length > 0">
          <li *ngFor="let attachment of attachments$ | async">
            {{ attachment.originalName }} ({{ attachment.size | fileSize }})
            <mat-icon class="" (click)="downloadFile(attachment)"
              >download
            </mat-icon>
            <mat-icon
              class="text-color-red"
              (click)="handleRequestDeleteTicketAttachment(attachment)"
              >delete_forever
            </mat-icon>
          </li>
        </ol>
        <span *ngIf="(attachments$ | async)?.length <= 0"
          >Bisher keine Uploads</span
        >
        <div class="" *ngIf="UploadError">
          <div class="alert alert-danger">
            <strong>Fehler beim Upload:</strong><br />{{ UploadError }}
          </div>
        </div>
      </div>

      <div class="card__footer p-l--24 p-r--24">
        <div class="grid">
          <div class="column-10">
            <span class="counter" *ngIf="(attachments$ | async)?.length > 0"
              >{{ (attachments$ | async).length }}
              {{ (attachments$ | async).length === 1 ? 'Datei' : 'Dateien' }}
              hochgeladen</span
            >
          </div>
          <div class="column-4 m-ta--2 ">
            <div class="hidden">
              <app-file-input
                [dispatchAction]="dispatchAction"
                [uploadFailAction]="failAction"
                [allowDeletion]="false"
                [additionalPayload]="additionalUploadData"
                propertyName="ticketAttachment"
                #fic
              ></app-file-input>
            </div>
            <button mat-button (click)="handleRequestUploadTicketAttachment()">
              <mat-icon>upload</mat-icon>
              <span>Upload</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class TicketAttachmentsComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;

  attachments$: Observable<Array<TicketAttachment>>;
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  @Output() requestDeleteTicketAttachment: EventEmitter<
    string
  > = new EventEmitter();
  @Output() requestUploadTicketAttachment: EventEmitter<
    void
  > = new EventEmitter();
  @ViewChild(FileInputComponent) fic!: FileInputComponent;
  dispatchAction = TicketAttachmentsActions.CreateTicketAttachment;
  failAction = TicketAttachmentsActions.CreateTicketAttachmentFail;
  successAction = TicketAttachmentsActions.CreateTicketAttachmentSuccess;
  UploadError: any = null;

  constructor(
    private dialog: MatDialog,
    public tas: TicketAttachmentsService,
    private store$: Store<ApplicationState>,
    private actions$: Actions
  ) {}

  get additionalUploadData(): { ticket: string } {
    return { ticket: getUuidFromIri(extractIri(this.order?.ticket)) };
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(TicketAttachmentsSelectors.isLoading);
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (order.ticket) {
          this.loadAttachments(order.ticket['@id']);
        }
      });
    this.actions$
      .pipe(ofType(this.failAction), takeUntil(this.onDestroy$))
      .subscribe(({ response }) => {
        this.UploadError = getErrorOfResponse(response)?.message;
        console.log(this.UploadError);
      });
    this.actions$
      .pipe(ofType(this.successAction), takeUntil(this.onDestroy$))
      .subscribe(({ response }) => {
        this.UploadError = null;
      });
  }

  doUpload(): void {
    this.fic.handleUpload();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  loadAttachments(ticketIri: string): void {
    this.store$.dispatch(
      TicketAttachmentsActions.ReadTicketAttachments({
        page: -1,
        params: { 'ticket.uuid': getUuidFromIri(ticketIri) }
      })
    );

    this.attachments$ = this.store$.select(
      TicketAttachmentsSelectors.sByTicketIri,
      { iri: ticketIri }
    );
  }

  downloadFile(attachment: TicketAttachment): void {
    this.store$.dispatch(
      TicketAttachmentsActions.DownloadTicketAttachment({
        iri: attachment['@id']
      })
    );
  }

  handleRequestUploadTicketAttachment(): void {
    this.fic.fileInput.nativeElement.click();
  }

  handleRequestDeleteTicketAttachment(attachment: TicketAttachment): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll diese Datei wirklich gelöscht werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          TicketAttachmentsActions.DeleteTicketAttachment({
            iri: attachment['@id'],
            ticketIri: this.order?.ticket['@id']
          })
        );
      });
  }
}
