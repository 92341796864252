import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {CustomerDataMedium, EnclosureDataMedium, ReplacementDataMedium} from '../../../shared/consts/DataMediaStates';

type StateType = 'EnclosureDataMedium' | 'CustomerDataMedium' | 'ReplacementDataMedium';
type ValueLabelType = { value: string, label: string };

@Component({
  selector: 'app-data-media-state-select',
  styleUrls: ['./data-media-state-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DataMediaStateSelectComponent),
    multi: true
  }],

  template: `
    <div [formGroup]="form" class="mat-form-field">
      <ng-select
        [class.required]="required"
        formControlName="selectedElement"
        [items]="items"
        bindLabel="label"
        [searchable]="true"
        bindValue="value"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        [required]="required"
        placeholder="Status">
      </ng-select>
      <mat-error>
        <app-form-error fieldName="selectedElement" [formGroup]="form"></app-form-error>
      </mat-error>

    </div>


  `
})
export class DataMediaStateSelectComponent extends BaseOnDestroyComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<ValueLabelType> = new EventEmitter<ValueLabelType>();
  @Input() required = false;
  @Input() type: StateType;

  possibleSelections: {
    [key: string]: Array<ValueLabelType>
  } = {
    CustomerDataMedium,
    EnclosureDataMedium,
    ReplacementDataMedium
  };
  items: Array<{ value: string, label: string }>;
  selectedItem: ValueLabelType;
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    if (this.required) {
      this.form.get('selectedElement').setValidators([Validators.required]);
      this.form.updateValueAndValidity();
    }
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
    this.items = this.possibleSelections[this.type];
  }

  setSelectedItem(element: ValueLabelType): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: ValueLabelType): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.label.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(value);
  }

}
