import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'reset-password-form',
  styleUrls: ['reset-password-form.component.scss'],
  template: `


    <!--<pre>{{ token | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
  `
})
export class ResetPasswordFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() token: string;

  @Output() requestCreatePasswordReset: EventEmitter<any> = new EventEmitter();

  cf: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.cf = this.fb.group({
      plainPassword: this.fb.control('', [Validators.required]),
      plainPasswordConfirmation: this.fb.control('', [Validators.required]),
      token: this.fb.control(this.token, [Validators.required])
    });
  }

  handleRequestCreateInvitationConfirmation(): void {
    this.requestCreatePasswordReset.emit(this.cf.value);
  }
}
