import {createSelector} from '@ngrx/store';

import {DESTRUCTION_TYPES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectDestructionTypesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[DESTRUCTION_TYPES_FEATURE_KEY]
);

export const selectDestructionTypesEntities = createSelector(
  selectDestructionTypesState,
  (state) => state.entities
);

export const selectDestructionTypes = createSelector(
  selectDestructionTypesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectDestructionTypesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectDestructionTypesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDestructionTypesState,
  (state) => state.loaded
);
