import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { LabLocationsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.LabLocation };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    LabLocationsActions.ReadLabLocation,
    LabLocationsActions.ReadLabLocations,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(LabLocationsActions.ReadLabLocationSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(LabLocationsActions.ReadLabLocationsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.LabLocation }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    LabLocationsActions.ReadLabLocationFail,
    LabLocationsActions.ReadLabLocationsFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
