import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {sortBy} from 'lodash-es';

import {ApplicationState} from '../../../application-state/store';
import {CommissionCreditsActions} from '../../store';
import {PartnersActions} from '../../../customers/store';
import {PartnersSelectors} from '../../../customers/store/selectors';
import {ModalDialogOptions} from '../../../application-state/models';
import {CorrectionInvoice} from '../../models';
import {Customer} from '../../../customers/models';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {PageEvent} from '@angular/material/paginator';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-brokers',
  styleUrls: ['brokers-view.component.scss'],
  template: `
    <div class="col pt-3">
      <div class="row ">
        <div class="col">
          <app-brokers-filter
            *ngIf="searchBoxVisible"
            (updateFilter)="handleUpdateFilter($event)"
          ></app-brokers-filter>

          <!--<offers-filter
            [statuses$]="statuses$"
            [departments$]="departments$"
            [searchBoxVisible$]="searchBoxVisible$"
            (requestHandleAction)="handleFilterFormAction($event)"
          ></offers-filter>-->
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-paginator-group pos-relative">
            <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

            <app-brokers-list
              [items]="
                sortCustomers(filterCustomer(brokers$ | async))
                  | slice: lowValue:highValue
              "
              (requestCreateCommissionCredit)="handleCreateCommissionCredit($event)"
            ></app-brokers-list>

            <app-paginator-unstyled
              [totalItems]="filterCustomer(brokers$ | async).length"
              [pageSize]="15"
              [pageIndex]="currentPage - 1"
              [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
              (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
              [showFirstLastButtons]="true"
            ></app-paginator-unstyled>
          </div>
        </div>
      </div>
    </div>
  `
})
export class BrokersViewComponent implements OnInit, OnDestroy {
  brokers$: Observable<Array<Customer>>;
  isLoading$: Observable<boolean>;
  activeFilter: { [p: string]: string } = {};
  searchBoxVisible = false;
  onDestroy$: Subject<any> = new Subject<any>();
  filterString = '';
  lowValue = 0;
  highValue = 15;
  currentPage: number;

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private router: Router,
    private route: ActivatedRoute,
    private searchAddonDataService: SearchAddonDataService,
    private cd: ChangeDetectorRef
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(PartnersSelectors.isLoading);
    this.store$.dispatch(PartnersActions.ReadPartners({page: 1}));
    this.brokers$ = this.store$.select(PartnersSelectors.selectPartners);

    this.searchAddonDataService
      .getObservable()
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(data => {
        this.filterString = data.filterString;
        this.searchBoxVisible = data.searchBoxVisible;
        this.activeFilter = data.activeFilter;
        this.cd.detectChanges();
      });

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.lowValue = (this.currentPage - 1) * 15;
      this.highValue = this.lowValue + 30;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleCreateCommissionCredit(broker: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text:
            'Möchten Sie für diesen Kunden eine Provisionsrechnung erstellen?',
          heading: 'Rechnung erstellen',
          confirmationText: 'Ja',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          CommissionCreditsActions.CreateCommissionCreditFromBroker({
            payload: {broker}
          })
        );
      });
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.updateUrl();
  }

  filterCustomer(toBeFilteredCustomer: Customer[]): Customer[] {
    const filter = this.activeFilter;
    const filterString = this.filterString;
    return toBeFilteredCustomer.filter(e => {
      let match = true;

      if (!filter && !filterString) {
        return match;
      }

      if (filterString !== '') {
        const parts = filterString.split(' ').map(f => f.toLowerCase());
        match = parts.every(t => {
          return (
            (e.customerNumber &&
              (e.customerNumber + '').toLowerCase().indexOf(t) > -1) ||
            e.nameLine1?.toLowerCase().indexOf(t) > -1 ||
            e.nameLine2?.toLowerCase().indexOf(t) > -1 ||
            (e.customerNumber &&
              (e.customerNumber + '').toLowerCase().indexOf(t) > -1)
          );
        });
      }

      if (filter && filter.broker && e['@id'] !== filter.broker) {
        match = false;
      }

      return match;
    });
  }

  sortCustomers(toBeSortedInvoices: CorrectionInvoice[]): CorrectionInvoice[] {
    return sortBy(toBeSortedInvoices, e => e.openPartnerCommissionAmountTotal.value);
    // return sortBy(toBeSortedInvoices, (e) => moment(e.createdAt).toDate().getTime() * -1);
  }

  handleUpdateFilter(filters: { [p: string]: string }): void {
    this.searchAddonDataService.updateData({activeFilter: filters});
  }
}
