import {
  AnalysisResultCategoryFormComponent
} from './analysis-result-category-form/analysis-result-category-form.component';
import {AnalysisResultFormComponent} from './analysis-result-form/analysis-result-form.component';
import {CustomerCooperationFormComponent} from './customer-cooperation-form/customer-cooperation-form.component';
import {DamageFormComponent} from './damage-form/damage-form.component';
import {DepartmentFormComponent} from './department-form/department-form.component';
import {DiscountFormComponent} from './discount-form/discount-form.component';
import {FileSystemFormComponent} from './file-system-form/file-system-form.component';
import {GradeFormComponent} from './grade-form/grade-form.component';
import {ItemListComponent} from './item-list/item-list.component';
import {OperatingSystemFormComponent} from './operating-system-form/operating-system-form.component';
import {ProductFormComponent} from './product-form/product-form.component';
import {SalutationFormComponent} from './salutation-form/salutation-form.component';
import {ServiceFormComponent} from './service-form/service-form.component';
import {SpecialAgreementFormComponent} from './special-agreement-form/special-agreement-form.component';
import {StorageLocationFormComponent} from './storage-location-form/storage-location-form.component';
import {StorageSystemFormComponent} from './storage-system-form/storage-system-form.component';
import {
  StorageSystemManufacturerFormComponent
} from './storage-system-manufacturer-form/storage-system-manufacturer-form.component';
import {SymptomFormComponent} from './symptom-form/symptom-form.component';
import {TaxFormComponent} from './tax-form/tax-form.component';
import {TermsAndConditionsFormComponent} from './terms-and-conditions-form/terms-and-conditions-form.component';
import {CustomerCooperationSelectComponent} from './customer-cooperation-select/customer-cooperation-select.component';
import {StorageLocationSelectComponent} from './storage-location-select/storage-location-select.component';

export const AllComponents = [
  AnalysisResultCategoryFormComponent,
  AnalysisResultFormComponent,
  CustomerCooperationFormComponent,
  DepartmentFormComponent,
  DiscountFormComponent,
  FileSystemFormComponent,
  GradeFormComponent,
  SymptomFormComponent,
  DamageFormComponent,
  ItemListComponent,
  OperatingSystemFormComponent,
  ProductFormComponent,
  SalutationFormComponent,
  ServiceFormComponent,
  SpecialAgreementFormComponent,
  StorageLocationFormComponent,
  StorageSystemFormComponent,
  StorageSystemManufacturerFormComponent,
  TaxFormComponent,
  TermsAndConditionsFormComponent,
  CustomerCooperationSelectComponent,
  StorageLocationSelectComponent
];

export * from './analysis-result-category-form/analysis-result-category-form.component';
export * from './analysis-result-form/analysis-result-form.component';
export * from './customer-cooperation-form/customer-cooperation-form.component';
export * from './damage-form/damage-form.component';
export * from './department-form/department-form.component';
export * from './discount-form/discount-form.component';
export * from './file-system-form/file-system-form.component';
export * from './grade-form/grade-form.component';
export * from './item-list/item-list.component';
export * from './operating-system-form/operating-system-form.component';
export * from './product-form/product-form.component';
export * from './salutation-form/salutation-form.component';
export * from './service-form/service-form.component';
export * from './special-agreement-form/special-agreement-form.component';
export * from './storage-location-form/storage-location-form.component';
export * from './storage-system-form/storage-system-form.component';
export * from './storage-system-manufacturer-form/storage-system-manufacturer-form.component';
export * from './symptom-form/symptom-form.component';
export * from './tax-form/tax-form.component';
export * from './terms-and-conditions-form/terms-and-conditions-form.component';
export * from './customer-cooperation-select/customer-cooperation-select.component';
export * from './storage-location-select/storage-location-select.component';
