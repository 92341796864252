import { ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

// Additional Form Control Validators
export class ControlValidators {

  public static minArrayLength(min: number): ValidatorFn | any {
    return (control: AbstractControl): {[key: string]: any} => {
      if (!(control instanceof FormControl)) { return; }
      return (control.value.length < min)  ? { minArrayLength: { value: control.value } } : null;
    };
  }
}
