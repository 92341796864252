import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_PRODUCT_UNIT = '[Master Data] Read Product Unit';
export const READ_PRODUCT_UNIT_SUCCESS = '[Master Data] Read Product Unit Success';
export const READ_PRODUCT_UNIT_FAIL = '[Master Data] Read Product Unit Fail';
export const ReadProductUnit = createAction(
  READ_PRODUCT_UNIT,
  props<{ iri: string }>()
);
export const ReadProductUnitSuccess = createAction(
  READ_PRODUCT_UNIT_SUCCESS,
  props<{ response }>()
);
export const ReadProductUnitFail = createAction(
  READ_PRODUCT_UNIT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_PRODUCT_UNITS = '[Master Data] Read Product Units';
export const READ_PRODUCT_UNITS_SUCCESS = '[Master Data] Read Product Units Success';
export const READ_PRODUCT_UNITS_FAIL = '[Master Data] Read Product Units Fail';
export const ReadProductUnits = createAction(
  READ_PRODUCT_UNITS
);
export const ReadProductUnitsSuccess = createAction(
  READ_PRODUCT_UNITS_SUCCESS,
  props<{ response: any }>()
);
export const ReadProductUnitsFail = createAction(
  READ_PRODUCT_UNITS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
