import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {LabLocationsActions} from '../store';
import {LabLocationsSelectors} from '../store/selectors';

@Injectable()
export class LabLocationsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return this.loadLabLocationsDone().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  loadLabLocationsDone() {

    return this.store.pipe(
      select(LabLocationsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(LabLocationsActions.ReadLabLocations());
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
