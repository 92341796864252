import {Component, Input, OnInit} from '@angular/core';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {FormGroup} from '@angular/forms';
import {DataMediumsService} from "../../services";

@Component({
  selector: 'app-customer-data-medium-form',
  styleUrls: ['./customer-data-medium-form.component.scss'],
  template: `
    <div class="row" [formGroup]="formGroup">

      <div class="col">
        <div *ngIf="prependText" class="prepended-text">
          <span class=" mt-7">{{prependText}}</span>
        </div>

        <div class="mat-form-field " [class.prepend-text]="prependText">

          <app-storage-system-manufacturer-select
            formControlName="storageSystemManufacturer" [required]="true"
            [readonly]="readonly"></app-storage-system-manufacturer-select>
        </div>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Modell</mat-label>
          <input matInput formControlName="storageSystemModel" [readonly]="readonly">
          <mat-error>
            <app-form-error fieldName="storageSystemModel" [formGroup]="formGroup"></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Serienummer</mat-label>
          <input matInput formControlName="storageSystemSerialNumber" [readonly]="readonly">
          <mat-error>
            <app-form-error fieldName="storageSystemSerialNumber" [formGroup]="formGroup"></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <app-input-size-in-gb [readonly]="readonly" formControlName="storageSystemSize"></app-input-size-in-gb>
      </div>
      <div class="col" *ngIf="formGroup.get('storageNumber')">
        <mat-form-field>
          <mat-label>Einlagerungsnummer</mat-label>

          <input matInput formControlName="storageNumber" readonly>
          <button matSuffix mat-icon-button (click)="openLabel()">
            <mat-icon>qr_code_2</mat-icon>
          </button>
        </mat-form-field>

      </div>
    </div>
  `
})
export class CustomerDataMediumFormComponent extends BaseOnDestroyComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() prependText: string = null;
  @Input() readonly = false;


  constructor(
    private dataMediaService: DataMediumsService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  openLabel(): void {
    this.dataMediaService.openLabelInPopup(this.formGroup.get('@id').value);
  }
}
