import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {
  CancellationInvoicesActions,
  CommissionCreditsActions,
  CorrectionInvoicesActions,
  OffersActions
} from '../actions/';
import {OffersService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {RouterActions} from '../../../application-state/store/actions';
import {NotifierService} from "angular-notifier";

@Injectable()
export class OffersEffects {

  constructor(private actions$: Actions, private service: OffersService, private notifierService: NotifierService) {
  }


  CreateOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.CreateOffer),
    switchMap(({payload}) => {
      return this.service.createOffer(payload).pipe(
        map((response) => OffersActions.CreateOfferSuccess({response})),
        catchError(response => of(OffersActions.CreateOfferFail({response})))
      );
    })
  ));


  ReadOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.ReadOffer),
    switchMap(({iri}) => {
      return this.service.readOffer(iri).pipe(
        map((response => OffersActions.ReadOfferSuccess({response}))),
        catchError(response => of(OffersActions.ReadOfferFail({response})))
      );
    })
  ));

  ReadPDF$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.ReadOfferPDF),
    switchMap(({iri}) => {
      return this.service.readOfferAsPdf(iri).pipe(
        map((response: any) => OffersActions.ReadOfferPDFSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OffersActions.ReadOfferPDFFail({response})))
      );
    })
  ));
  ReadAcceptedOfferPDF$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.ReadAcceptedOfferPDF),
    switchMap(({iri, fileName}) => {
      return this.service.readAcceptedOfferAsPdf(iri).pipe(
        map((response: any) => OffersActions.ReadAcceptedOfferPDFSuccess({response: {...response, fileName}})),
        catchError((response: HttpErrorResponse) => of(OffersActions.ReadAcceptedOfferPDFFail({response})))
      );
    })
  ));
  SuccessDownloadAction = createEffect(() => this.actions$.pipe(
    ofType(
      OffersActions.ReadOfferPDFSuccess,
      OffersActions.ReadAcceptedOfferPDFSuccess,
    ),
    map(({response: {contentUrl, fileName}}) => {
      const a = document.createElement('a');
      a.href = contentUrl;
      a.download = fileName; // File name Here
      a.click(); // Downloaded file
      a.remove();
    })
  ), {dispatch: false});

  ReadOffers$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.ReadOffers),
    switchMap(({page, params}) => {
      return this.service.readOffers(page, params).pipe(
        map((response) => OffersActions.ReadOffersSuccess({response})),
        catchError(response => of(OffersActions.ReadOffersFail({response})))
      );
    })
  ));


  ReadOfferChunk$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.ReadOfferChunk),
    switchMap(({uri}) => {
      return this.service.readOfferChunk(uri).pipe(
        map((response) => OffersActions.ReadOfferChunkSuccess({response})),
        catchError(response => of(OffersActions.ReadOfferChunkFail({response})))
      );
    })
  ));

  SendMail$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.SendMail),
    switchMap(({iri, payload}) => {
      return this.service.sendMail(iri, payload).pipe(
        map((response) => OffersActions.SendMailSuccess({response})),
        catchError(response => of(OffersActions.SendMailFail({response})))
      );
    })
  ));

  UpdateOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.UpdateOffer),
    switchMap(({iri, payload}) => {
      return this.service.updateOffer(iri, payload).pipe(
        map((response: fromModuleModels.Offer) => OffersActions.UpdateOfferSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OffersActions.UpdateOfferFail({response})))
      );
    })
  ));


  DeleteOffer$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.DeleteOffer),
    switchMap(({iri}) => {
      return this.service.deleteOffer(iri).pipe(
        map(() => OffersActions.DeleteOfferSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(OffersActions.DeleteOfferFail({response})))
      );
    })
  ));


  OfferActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      OffersActions.CreateOfferSuccess,
      OffersActions.UpdateOfferSuccess,
      OffersActions.DeleteOfferSuccess,
      OffersActions.SendMailSuccess,
    ),
    map(({type}) => {

      const textOptions = {
        [OffersActions.CREATE_OFFER_SUCCESS]: `Ein neues Angebot wurde angelegt.`,
        [OffersActions.UPDATE_OFFER_SUCCESS]: 'Das Angebot wurde aktualisiert.',
        [OffersActions.DELETE_OFFER_SUCCESS]: 'Das Angebot wurde gelöscht. Sie werden jetzt zur Übersicht weitergeleitet.',
        [OffersActions.SEND_MAIL_SUCCESS]: 'Die Email wurde erfolgreich verschickt.',
      };

      this.notifierService.show({type: 'success', message: textOptions[type]});

    })
  ), {dispatch: false});
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      OffersActions.CreateOfferFail,
      OffersActions.UpdateOfferFail,
      OffersActions.DeleteOfferFail,
      OffersActions.SendMailFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case OffersActions.CREATE_OFFER_FAIL:
          message = 'Beim Anlegen eines neuen Angebots sind Fehler aufgetreten:' + errors;
          break;
        case OffersActions.UPDATE_OFFER_FAIL:
          message = 'Beim Aktualisieren eines Angebots sind Fehler aufgetreten:' + errors;
          break;
        case OffersActions.DELETE_OFFER_FAIL:
          message = 'Beim Löschen des Angebots ist ein Fehler aufgetreten:' + errors;
          break;
        case OffersActions.SEND_MAIL_FAIL:
          message = 'Beim senden der E-Mail sind Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});


  DeleteOfferSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(OffersActions.DeleteOfferSuccess),
    map(() => RouterActions.Go({path: ['invoices']}))
  ));
}
