import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { InvoiceLike, PayableInvoice } from '../../models';
import { PayableInvoicesActions, PaymentProcessesActions } from '../../store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { DepartmentsActions } from '../../../master-data/store';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../../orders/models';
import { Customer } from '../../../customers/models';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { RouterActions } from '../../../application-state/store/actions';

@Component({
  selector: 'app-payable-invoice-new',
  styleUrls: ['./payable-invoice-new.component.scss'],
  template: `
    <div class="pos-relative">
      <div class="row">
        <div class="col-auto me-auto">
          <h1>Faktura: Rechnung erstellen</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <app-invoice-preview
            [invoice$]="invoice$"
            (updateInvoice)="updateInvoice($event)"
            (updateOrder)="updateOrder($event)"
            (updateCustomer)="updateCustomer($event)"
          ></app-invoice-preview>
        </div>
        <div class="col-3">
          <div class="heading--h3 m-b--32 p-a--16" data-box-style="blue">
            Bevor Abschlagsrechnungen erstellt oder Zahlungen gebucht werden
            können, muss dieser Rechnungsentwurf gespeichert werden.
          </div>

          <ng-container [formGroup]="form">
            <mat-form-field>
              <mat-label>Auftragsdatum</mat-label>
              <input
                matInput
                required
                type="date"
                formControlName="orderDate"
              />
              <mat-error>
                <app-form-error
                  [formGroup]="form"
                  fieldName="orderDate"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Fälligkeitsdatum</mat-label>
              <input matInput required type="date" formControlName="dueDate" />
              <mat-error>
                <app-form-error
                  [formGroup]="form"
                  fieldName="dueDate"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Lieferdatum</mat-label>
              <input
                matInput
                required
                type="date"
                formControlName="deliveryDate"
              />
              <mat-error>
                <app-form-error
                  [formGroup]="form"
                  fieldName="deliveryDate"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <div class="m-ta--2" (click)="form.markAllAsTouched()">
            <button
              [disabled]="form.invalid"
              mat-flat-button
              color="green"
              (click)="handleCreateInvoice()"
            >
              <mat-icon class="m-r--8">save</mat-icon>
              <span>Entwurf speichern und in Rechnung umwandeln</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class PayableInvoiceNewComponent implements OnInit, OnDestroy {
  invoice$: BehaviorSubject<InvoiceLike> = new BehaviorSubject<InvoiceLike>({});
  onDestroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  private order: Order;
  private customer: Customer;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private titleService: AbstractTitleService,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Rechnung erstellen');
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
    this.form = this.fb.group({
      orderDate: this.fb.control(null, Validators.required),
      paymentProcess: this.fb.control(null),
      dueDate: this.fb.control(null, Validators.required),
      deliveryDate: this.fb.control(null, Validators.required),
      issuer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null, [Validators.required]),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null, Validators.required),
        taxNumber: this.fb.control(null)
      }),
      customerNumber: this.fb.control(null, Validators.required),
      customText: this.fb.control('')
    });
    this.form
      .get('orderDate')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(orderDate => {
        this.autoFillDateFields(orderDate);
      });
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(data => {
        console.log(data);
        this.invoice$.next(removeEmptyFormElements(data));
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateInvoice(invoice: InvoiceLike): void {
    // console.log('UPDATE_INVOICE', invoice);
    this.form.patchValue(invoice);
  }

  updateOrder(order: Order): void {
    // console.log('UPDATE_ORDER', order);
    this.order = order;
    this.autoFillDateFields(order.createdAt);
    this.form.get('paymentProcess').setValue(extractIri(order.paymentProcess));
  }

  updateCustomer(customer: Customer): void {
    // console.log('UPDATE_CUSTOMER', customer);
    this.customer = customer;
    this.form.get('customerNumber').setValue(customer.customerNumber);
    this.form.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.form.get('recipient.nameLine2').setValue(customer.nameLine2);
    this.form.get('recipient.taxNumber').setValue(customer.taxNumber);
  }

  autoFillDateFields(orderDate?: string): void {
    // note: date fields can already be populated if order - not customer - has been selected

    const date = orderDate ? new Date(orderDate) : new Date();

    this.form.get('orderDate').setValue(date.toISOString().substr(0, 10));

    this.form.get('deliveryDate').setValue(date.toISOString().substr(0, 10));

    this.form
      .get('dueDate')
      .setValue(
        new Date(date.setDate(date.getDate() + 14)).toISOString().substr(0, 10)
      );
  }

  handleCreateInvoice(): void {
    // Create New Payment Process First then save
    this.store$.dispatch(
      PaymentProcessesActions.CreatePaymentProcess({
        payload: { customer: this.customer['@id'] }
      })
    );
    this.actions$
      .pipe(
        ofType(PaymentProcessesActions.CreatePaymentProcessSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        this.form.get('paymentProcess').setValue(response['@id']);
        const payload = this.form.value;
        if (payload.recipient.addressType) {
          payload.recipient.addressType = extractIri(
            payload.recipient.addressType
          );
        }
        this.store$.dispatch(
          PayableInvoicesActions.CreatePayableInvoice({ payload })
        );
      });
    this.actions$
      .pipe(
        ofType(PayableInvoicesActions.CreatePayableInvoiceSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        this.store$.dispatch(
          RouterActions.Go({
            path: [
              '/invoices',
              'payable',
              extractTypeByIri(response),
              extractUUID(response)
            ]
          })
        );
      });
  }

  saveInvoice(): void {
    this.store$.dispatch(
      PayableInvoicesActions.CreatePayableInvoice({
        payload: this.invoice$.getValue() as PayableInvoice
      })
    );
  }
}
