import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {cloneDeep} from 'lodash-es';

export const getDirtyControlsMap = (form: FormGroup | FormArray | FormControl | AbstractControl): Map<string, any> | any[] | any | undefined => {

  if (!form.dirty) {
    return;
  }

  if (form instanceof FormControl) {
    return form.value;
  }

  if (form instanceof FormGroup) {

    const result = new Map();

    for (const [key, control] of Object.entries(form.controls)) {

      const nestedResult = getDirtyControlsMap(control);

      if (nestedResult) {
        result.set(key, getDirtyControlsMap(control));
      }
    }
    return result;
  }

  if (form instanceof FormArray) {
    const result = [];
    form.controls.forEach(control => {
      const nestedResult = getDirtyControlsMap(control);
      if (nestedResult) {
        result.push(nestedResult);
      }
    });
    return result;
  }
  throw new Error('Form must be a FromGroup, FormArray or FormControl.');
};

export const getDirtyValuesObject = (controlGroup: any): { [key: string]: string } => {

  const dirtyValues = {};

  Object
    .keys(controlGroup.controls)
    .forEach(c => {

      const currentControl = controlGroup.get(c);

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[c] = getDirtyValuesObject(currentControl);
        } else {
          dirtyValues[c] = currentControl.value;
        }
      }
    });
  return dirtyValues;
};

export const getFormValidationErrors = (form: FormGroup) => {
  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });
};

// TODO add options to keep empty Keys and or Null values, to intentional delete values
export const removeEmptyFormElements = (form: FormGroup, skipKeys: Array<string> = [], depth: number = 0) => {
  const localForm = cloneDeep(form);
  if (!localForm) {
    return null;
  }
  for (const key of Object.keys(localForm)) {
    if (localForm[key] === undefined) {

    } else if (localForm[key] && localForm[key] === null) {
      if (!skipKeys.includes(key)) {
        delete localForm[key];
      }
    } else if (localForm[key] && localForm[key].trim && localForm[key].trim() === '') {
      if (!skipKeys.includes(key)) {
        delete localForm[key];
      }
    } else if (typeof localForm[key] === 'object') {
      localForm[key] = removeEmptyFormElements(localForm[key], skipKeys, depth + 1);
      if (localForm[key] === null && !skipKeys.includes(key)) {
        delete localForm[key];
      }
    }
  }
  if (Object.keys(localForm).length === 0) {
    return null;
  }
  return localForm;
};
