import {createSelector} from '@ngrx/store';

// Provider Import
import {ADMINISTRATOR_GROUPS_FEATURE_KEY, selectAdministratorsModuleState} from '../reducers';

const NAMESPACE = ADMINISTRATOR_GROUPS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectAdministratorsModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);

export const sEntities = createSelector(sState, (state) => state.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities));
export const sByIri = createSelector(sEntities, (entities: any, {iri}) => entities[iri]);

