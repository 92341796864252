import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';

import * as fromModuleModels from '../../models';

@Component({
  selector: 'partner-website-list',
  styleUrls: ['partner-website-list.component.scss'],
  template: `

    <div class="card m-b--16 m-l--48 mat-elevation-z1">

      <div class="card__heading">
        <ng-content></ng-content>
      </div>

      <div class="card__content">

        <table mat-table *ngIf="partnerWebsites?.length; else placeholder" [dataSource]="partnerWebsites" class="table">

          <!-- State Column -->
          <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="dot" [class.green]="!!element.enabled"></span>
            </td>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>E-Mail</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.email }}</span>
            </td>
          </ng-container>

          <!-- Website Column -->
          <ng-container matColumnDef="website">
            <th mat-header-cell *matHeaderCellDef>Website</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.website }}</span>
            </td>
          </ng-container>

          <!-- Locked Column -->
          <ng-container matColumnDef="locked">
            <th mat-header-cell *matHeaderCellDef>Gesperrt</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon [innerHTML]="element.locked ? 'check_circle_outline' : 'remove_circle_outline'"
              ></mat-icon>
            </td>
          </ng-container>

          <!-- Locked Column -->
          <ng-container matColumnDef="token">
            <th mat-header-cell *matHeaderCellDef>API-Token</th>
            <td mat-cell *matCellDef="let element">
              <button (click)="handleCopyApiTokenToClipboard(element.apiToken)" mat-icon-button>
                <mat-icon>key</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

              <button [matMenuTriggerFor]="rowMenu" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #rowMenu>
                <button (click)="handleRequestUpdatePartnerWebsiteForm(element)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Bearbeiten</span>
                </button>

                <button (click)="handleDeletePartnerWebsite(element)" mat-menu-item>
                  <mat-icon>delete_forever</mat-icon>
                  <span>Löschen</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>

    <ng-template #placeholder>
      <p class="placeholder">Für diesen Kunden wurde noch kein Account hinterlegt.</p>
    </ng-template>
  `
})
export class PartnerWebsiteListComponent implements OnInit {

  @Input() customer: fromModuleModels.Customer;
  @Input() partnerWebsites: Array<fromModuleModels.PartnerWebsite>;

  @Output() requestDeletePartnerWebsite: EventEmitter<string> = new EventEmitter();
  @Output() requestFetchPartnerWebsites: EventEmitter<string> = new EventEmitter();
  @Output() requestUpdatePartnerWebsiteForm: EventEmitter<fromModuleModels.PartnerWebsite> = new EventEmitter();

  displayedColumns: Array<string> = ['enabled', 'email', 'website', 'locked', 'token', 'actions'];

  constructor(private clipboard: Clipboard, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.requestFetchPartnerWebsites.emit(this.customer['@id']);
  }

  handleCopyApiTokenToClipboard(token: string): void {
    this.clipboard.copy(token);
    this.snackBar.open(`API-Token wurde in die Zwischenablage kopiert.`, null, {duration: 3000});
  }

  handleRequestUpdatePartnerWebsiteForm(website: fromModuleModels.PartnerWebsite): void {
    this.requestUpdatePartnerWebsiteForm.emit(website);
  }

  handleDeletePartnerWebsite(website: fromModuleModels.PartnerWebsite): void {
    this.requestDeletePartnerWebsite.emit(website['@id']);
  }
}
