import {createSelector} from '@ngrx/store';

import {GRADES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectGradesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[GRADES_FEATURE_KEY]
);

export const selectGradesEntities = createSelector(
  selectGradesState,
  (state) => state.entities
);

export const selectGrades = createSelector(
  selectGradesEntities,
  (entities) => Object.values(entities)
);

export const selectGrade = createSelector(
  selectGradesEntities,
  (entities, iri) => entities[iri]
);

export const selectErrors = createSelector(
  selectGradesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectGradesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectGradesState,
  (state) => state.loaded
);
