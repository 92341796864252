import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  FollowUpCreateAndUpdateDialogComponent
} from '../../components/follow-up-create-and-update-dialog/follow-up-create-and-update-dialog.component';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {AuthService} from '../../../auth/services/auth.service';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {UserRoleService} from '../../../shared/services/user-role.service';
import {takeUntil} from 'rxjs/operators';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {combineLatest, Observable} from 'rxjs';
import {LocalStorageService} from "../../../shared/services";
import {FollowUp} from "../../models";

@Component({
  selector: 'app-dashboard',
  styleUrls: ['dashboard.component.scss'],
  template: `
    <div class="row">
      <div class="col-auto me-auto dashboard-heading">
        <h1>
          Dashboard
          <small class="text-color-lightgrey">{{ userType }}</small>

          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            *ngIf="possibleUserType.length > 1"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="userType = type"
              *ngFor="let type of possibleUserType"
            >
              {{ type }}
            </button>
          </mat-menu>
        </h1>
        <mat-slide-toggle
          [(ngModel)]="toggleState"
          (change)="toggleDivVisibility()"
          [matTooltip]="isHidden ? ('dashboard.hide_resubmissions' | translate) : ('dashboard.show_resubmissions' | translate)">
        </mat-slide-toggle>
      </div>
      <div class="col-auto">
        <button mat-flat-button (click)="openCreateFollowUpDialog()">
          Wvl. erstellen
        </button>
      </div>
    </div>
    <div class="row" [hidden]="!isHidden">
      <div class="col-12">
        <app-follow-up-calendar (sendFollowUps)="receiveFollowUps($event)"></app-follow-up-calendar>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-md-6">
        <!--        <app-brokered-leads-widget-->
        <!--          *ngIf="userType === 'Partnerverwaltung'"-->
        <!--        ></app-brokered-leads-widget>-->
        <app-new-leads-widget
          *ngIf="userType === 'Partnerverwaltung'"
        ></app-new-leads-widget>
        <app-binding-orders-open-widget
          [userType]="userType"
          *ngIf="userType === 'Vertrieb' || userType === 'Partnerverwaltung'"
        ></app-binding-orders-open-widget>
        <app-income-data-medium-delayed-widget
          *ngIf="userType === 'Vertrieb' || userType === 'Partnerverwaltung'"
        ></app-income-data-medium-delayed-widget>
        <app-open-analysis-awaiting-package-widget-ext
          *ngIf="userType === 'Technik (extern)'"
        ></app-open-analysis-awaiting-package-widget-ext>
        <app-open-analysis-widget-ext
          *ngIf="userType === 'Technik (extern)'"
        ></app-open-analysis-widget-ext>
        <app-open-purchase-prices-ext-widget
          *ngIf="userType === 'Technik (extern)'"
        ></app-open-purchase-prices-ext-widget>
        <app-orders-data-carriers-return-widget
          *ngIf="userType === 'Technik (extern)' || userType === 'Administrator'"
        ></app-orders-data-carriers-return-widget>
        <app-open-analysis-widget
          *ngIf="userType === 'Technik'"
        ></app-open-analysis-widget>
        <app-missing-data-copy-widget
          *ngIf="userType === 'Technik'"
        ></app-missing-data-copy-widget>
        <app-missing-review-of-analysis-widget
          *ngIf="userType === 'Technik'"
        ></app-missing-review-of-analysis-widget>
        <app-open-overwritten-prices-list-widget
          *ngIf="userType === 'Administrator'"
        ></app-open-overwritten-prices-list-widget>
        <app-open-purchase-price-list-widget
          *ngIf="userType === 'Administrator'"
        ></app-open-purchase-price-list-widget>
        <app-open-selling-prices-list-widget
          *ngIf="userType === 'Administrator'"
        ></app-open-selling-prices-list-widget>
        <app-booked-invoice-list-widget
          *ngIf="userType === 'Faktura'"
        ></app-booked-invoice-list-widget>
        <app-draft-invoice-list-widget
          *ngIf="userType === 'Faktura'"
        ></app-draft-invoice-list-widget>
        <app-open-invoice-list-widget
          *ngIf="userType === 'Faktura'"
        ></app-open-invoice-list-widget>
        <!--        <app-datarecovery-done-list-widget-->
        <!--          *ngIf="userType === 'Vertrieb' || userType === 'Partnerverwaltung'"-->
        <!--        ></app-datarecovery-done-list-widget>-->
        <app-open-dm-shipment-to-customer-widget
          *ngIf="userType === 'Logistik' || userType === 'Technik'"
        ></app-open-dm-shipment-to-customer-widget>
        <app-waiting-for-dm-widget
          *ngIf="userType === 'Logistik' || userType === 'Technik'"
        ></app-waiting-for-dm-widget>
      </div>

      <div class="col-md-6">
        <app-open-dm-shipment-to-external-widget
          *ngIf="userType === 'Logistik' || userType === 'Technik'"
        ></app-open-dm-shipment-to-external-widget>

        <app-analysis-done-list-widget
          *ngIf="userType === 'Vertrieb' || userType === 'Partnerverwaltung'"
        ></app-analysis-done-list-widget>

        <app-feedback-requests-received-widget
          *ngIf="userType === 'Administrator'"
        ></app-feedback-requests-received-widget>
        <app-feedback-requests-sent-widget
          *ngIf="userType === 'Administrator'"
        ></app-feedback-requests-sent-widget>
<!--        <app-feedback-gotten-widget></app-feedback-gotten-widget>-->
        <app-missing-replacement-dm-widget
          *ngIf="userType === 'Vertrieb' || userType === 'Partnerverwaltung'"
        ></app-missing-replacement-dm-widget>
        <app-pending-storage-system-widget
          *ngIf="userType === 'Administrator'"
        ></app-pending-storage-system-widget>
<!--        <app-feedback-send-widget></app-feedback-send-widget>-->
        <app-open-datarecovery-list-widget
          *ngIf="userType === 'Technik'"
        ></app-open-datarecovery-list-widget>
        <app-open-datarecovery-list-ext-widget
          *ngIf="userType === 'Technik (extern)'"
        ></app-open-datarecovery-list-ext-widget>
        <app-orders-list-to-be-destroyed-widget-ext
          *ngIf="userType === 'Technik (extern)'"
        ></app-orders-list-to-be-destroyed-widget-ext>
      </div>
    </div>
  `
})
export class DashboardComponent extends BaseOnDestroyComponent
  implements OnInit {
  // 'Vertrieb' | 'Faktura' | 'Technik' | 'Administrator';
  userType: string;
  possibleUserType: Array<string>;
  toggleState: boolean = false;
  isHidden: boolean = false;
  followups: Observable<Array<FollowUp>>

  constructor(
    public dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private titleService: AbstractTitleService,
    private roleService: UserRoleService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  get allRoles$(): any {
    return this.roleService.availableRoles();
  }

  ngOnInit(): void {
    combineLatest([
      this.authService.isSupervisor$,
      this.authService.isAdmin$,
      this.authService.isAccounting$,
      this.authService.isPartnerManagement$,
      this.authService.isLogistic$,
      this.authService.isSales$,
      this.authService.isSalesExternal$,
      this.authService.isTechnician$,
      this.authService.isTechnicianExternal$
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([
           isSupervisor,
           isAdmin,
           isAccounting,
           isPartnerManagement,
           isLogistic,
           isSales,
           isSalesExternal,
           isTechnician,
           isTechnicianExternal
         ]) => {
          this.possibleUserType = [];
          if (isAdmin || isSupervisor) {
            this.possibleUserType.push('Administrator');
          }
          if (isAccounting) {
            this.possibleUserType.push('Faktura');
          }
          if (isPartnerManagement) {
            this.possibleUserType.push('Partnerverwaltung');
          }
          if (isLogistic) {
            this.possibleUserType.push('Logistik');
          }
          if (isSales || isSalesExternal) {
            this.possibleUserType.push('Vertrieb');
          }
          if (isTechnician) {
            this.possibleUserType.push('Technik');
          }
          if (isTechnicianExternal) {
            this.possibleUserType.push('Technik (extern)');
          }
          this.userType = this.possibleUserType[0];
        }
      );
    this.titleService.setTitle('Dashboard');
    const savedState = this.localStorageService.get('toggleState')
    this.toggleState = savedState ? JSON.parse(savedState) : false;
    this.isHidden = this.toggleState;
  }

  openCreateFollowUpDialog(): void {
    this.dialog.open(FollowUpCreateAndUpdateDialogComponent, {
      disableClose: false
    });
  }

  toggleDivVisibility() {
    this.isHidden = this.toggleState;
    localStorage.setItem('toggleState', JSON.stringify(this.toggleState));
  }
  receiveFollowUps(event: Observable<Array<FollowUp>>) {
    this.followups = event;
  }
}
