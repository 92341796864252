import { Component, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import {
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import {
  StorageSystemManufacturersActions,
  StorageSystemsActions
} from '../../../master-data/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { OrdersService } from '../../../orders/services';
import { OrderShipment } from '../../../orders/models/order-shipment.interface';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { DataMedium } from '../../../warehouse/models';
import { CreateShipmentDialogComponent } from '../../../shared/components/create-shipment-dialog/create-shipment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WarehouseRights } from '../../../shared/security/warehouseRights';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  ReplacementStockItemsActions
} from '../../../warehouse/store';

@Component({
  selector: 'app-open-order-shipments-list',
  styleUrls: ['./open-order-shipments-list.component.scss'],
  template: `
    <!--    <button (click)="showDebug=!showDebug">DEBUG</button>-->

    <div class="row  py-2" [formGroup]="form">
      <div class="col-2">
        <app-order-select formControlName="order"></app-order-select>
      </div>
      <div class="col pt-4">
        <a
          (click)="resetFilter()"
          class="link-primary cursor-pointer text-color-grey text-decoration-none"
          >Filter zurücksetzen</a
        >
      </div>
    </div>
    <div class="pos-relative" style="min-height: 300px;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

      <ng-container *ngIf="items$ | async as items">
        <div class="d-flex shipment-line my-2" *ngFor="let item of items">
          <app-shipment-order-display-line
            class="flex-grow-1 p-3"
            [order]="item"
            [inputSelectedDataMedia]="selectedDataMedia[item['@id']] || []"
            [readonlyArchive]="true"
            [validDataMediaStates]="validDataMediaStates"
            (changeSelectedDataMedia)="
              setSelectedDataMediaForOrder(item, $event)
            "
          ></app-shipment-order-display-line>

          <button
            class="m-3"
            [disabled]="
              !selectedDataMedia[item['@id']] ||
              selectedDataMedia[item['@id']]?.length <= 0
            "
            *ngIf="canCreate$ | async"
            (click)="openShipmentDialog(item)"
          >
            Versand beauftragen
          </button>
          <br />
          <!--          <pre *ngIf="showDebug">{{selectedDataMedia[item['@id']]|json}}</pre>-->
          <!--          <pre *ngIf="showDebug">{{item|json}}</pre>-->
        </div>
        <div
          class="row"
          *ngIf="
            (isLoading$ | async) === false && (!items || items.length <= 0)
          "
        >
          <p>Es sind keine Einträge mit den ausgewählten Filtern verfügbar!</p>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col text-right">
        <app-paginator-unstyled
          showFirstLastButtons="true"
          [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
          [pageSize]="currentPageSetting.pageSize"
          [pageIndex]="currentPageSetting.pageIndex"
          [totalItems]="itemCount$ | async"
          (handleUpdatePageOrSize)="updatePageOrSize($event)"
        ></app-paginator-unstyled>
      </div>
    </div>
  `
})
export class OpenOrderShipmentsListComponent extends BaseOnDestroyComponent
  implements OnInit {
  showDebug = false;
  selectedDataMedia: { [orderIri: string]: Array<string> } = {};
  form: FormGroup;
  currentPageSetting: { pageSize: number; pageIndex: number } = {
    pageIndex: 0,
    pageSize: 15
  };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items$: BehaviorSubject<Array<OrderShipment>> = new BehaviorSubject<
    Array<OrderShipment>
  >([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  validDataMediaStates: Array<string> = [
    'stored_dr_de',
    'reserved',
    'waiting_for_return_shipment'
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private backendService: OrdersService,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
    private warehouseRight: WarehouseRights,
    private titleService: AbstractTitleService
  ) {
    super();
  }

  get canCreate$(): Observable<boolean> {
    return this.warehouseRight.canCreate$;
  }

  dataMediaSelectionFilterFn(item): (dataMedia: DataMedium) => boolean {
    const vm = this;
    return (dataMedia: DataMedium): boolean => {
      const list = vm.selectedDataMedia[extractIri(item)];
      // console.log(list);
      if (!list) {
        return false;
      }
      return list.indexOf(extractIri(dataMedia)) > -1;
    };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Zu versenden an Kunden');
    this.initForm();
    this.actions$
      .pipe(
        ofType(
          CustomerStockItemsActions.UpdateCustomerStockItemSuccess,
          CustomerStockItemsActions.CreateCustomerStockItemSuccess,
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess,
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess,
          ReplacementStockItemsActions.CreateReplacementStockItemSuccess,
          ReplacementStockItemsActions.UpdateReplacementStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loadData();
      });
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(params => {
        this.readQueryParams();
        this.loadData();
        //
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }

  resetFilter(): void {
    this.form.patchValue({
      order: null
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null)
    });
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(values => {
        this.updateQueryParams({ ...values, page: 0 }, true);
      });
  }

  updatePageOrSize($event: any): void {
    this.currentPageSetting = { ...this.currentPageSetting, ...$event };
    this.updateQueryParams({
      pageSize: $event.pageSize,
      page: $event.pageIndex + 1
    });
  }

  readQueryParams(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    for (const key of ['order']) {
      if (this.form.get(key).value !== params[key]) {
        this.form
          .get(key)
          .setValue(params[key], { onlySelf: true, emitEvent: false });
      }
    }
    if (params.pageSize) {
      this.currentPageSetting.pageSize = params.pageSize;
    }
    if (params.page) {
      this.currentPageSetting.pageIndex = params.page + 1;
    }
  }

  updateQueryParams(
    values: { [key: string]: string },
    alwaysLoad = false
  ): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = { ...currentQuery };
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {
      this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }

  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const page =
      parseInt(queryParams.page ? queryParams.page : 0, 10);
    const params: any = {
      itemsPerPage: queryParams.pageSize ? queryParams.pageSize : 15,
      pagination: true,
      'order[customer.customerType.id]': 'asc',
      'order[netTotal.value]': 'desc',
      'awaitingShipmentDirection.id': 'SHIPMENT_DIRECTION_DR_DE_TO_CUSTOMER'
    };

    if (queryParams.order) {
      params['orders.uuid'] = getUuidFromIri(queryParams.order);
    }

    this.backendService.readAwaitingShipmentOrders(page, params).subscribe(
      data => {
        this.items$.next(data['hydra:member']);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }

        this.isLoading$.next(false);
      },
      error => {
        console.log(error);
        this.isLoading$.next(false);
      }
    );
  }

  openShipmentDialog(item: OrderShipment): void {
    const iri = extractIri(item);
    this.dialog
      .open(CreateShipmentDialogComponent, {
        panelClass: 'width-95',
        data: {
          type: 'OrderShipment',
          shipment: {
            order: iri,
            dataMedia: this.selectedDataMedia[iri]
          }
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.loadData();
      });
  }

  setSelectedDataMediaForOrder(
    item: OrderShipment,
    $event: {
      selected: Array<string>;
      unselected: Array<string>;
    }
  ): void {
    const newList = { ...(this.selectedDataMedia || {}) };
    if (!newList[extractIri(item)]) {
      newList[extractIri(item)] = [];
    }
    const list = newList[extractIri(item)];
    for (const unselected of $event.unselected) {
      if (list.indexOf(unselected) > -1) {
        list.splice(list.indexOf(unselected), 1);
      }
    }
    for (const selected of $event.selected) {
      if (list.indexOf(selected) <= -1) {
        list.push(selected);
      }
    }
    this.selectedDataMedia = { ...newList };
  }
}
