import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerTypesActions} from '../actions/';
import {CustomerTypesService} from '../../services';

@Injectable()
export class CustomerTypesEffects {

  constructor(private actions$: Actions, private cts: CustomerTypesService) {
  }


  ReadCustomerTypes$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerTypesActions.LoadCustomerTypes),
    map(action => action),
    switchMap(() => {
      return this.cts.getCustomerTypes().pipe(
        map((response: any) => CustomerTypesActions.LoadCustomerTypesSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CustomerTypesActions.LoadCustomerTypesFail({response})))
      );
    })
  ));
}
