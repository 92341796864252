import { Component, Inject, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import {Shipment, ShipmentAdditionalInsurance, ShipmentDirection} from '../../../shipping/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../dialog/dialog.component';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import {
  ShipmentDirectionsActions,
  ShipmentsActions
} from '../../../shipping/store';
import { AbstractApiService } from '../../services';
import { extractIri, extractType } from '../../utilities/objects.utility';
import { PotentialAction } from '../../models';
import { Actions, ofType } from '@ngrx/effects';
import { OrdersService } from '../../../orders/services';
import { ShipmentDirectionsSelectors } from '../../../shipping/store/selectors';
import { WarehouseRights } from '../../security/warehouseRights';
import { NotifierService } from 'angular-notifier';
import { getDescriptionOfShipmentState } from '../../../shipping/helpers/shipmentStateDescriptionMapper';

@Component({
  selector: 'app-show-shipment-dialog',
  styleUrls: ['./show-shipment-dialog.component.scss'],
  templateUrl: './show-shipment-dialog.component.html'
})
export class ShowShipmentDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shipment: Shipment;
  shipmentAdditionalInsurance: ShipmentAdditionalInsurance;
  shipmentTypes = {
    UpsShipment: 'UPS',
    UpsPickupShipment: 'UPS Pickup',
    DhlShipment: 'DHL',
    GoPickupShipment: 'Go Pickup',
    GenericShipment: 'Generischer Versand'
  };
  shipmentDirectionsEntities: { [key: string]: ShipmentDirection };
  protected readonly getDescriptionOfShipmentState = getDescriptionOfShipmentState;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shipment: string;
      direction: string;
    },
    private dialog: MatDialog,
    private actions$: Actions,
    private dialogRef: MatDialogRef<ShowShipmentDialogComponent>,
    private ordersService: OrdersService,
    private warehouseRight: WarehouseRights,
    private store$: Store<ApplicationState>,
    private apiService: AbstractApiService,
    private notifierService: NotifierService
  ) {
    super();
  }

  get canEdit$(): Observable<boolean> {
    return this.warehouseRight.canEdit$;
  }

  get confirmAction(): PotentialAction {
    return this.shipment?.potentialActions?.find(
      e => e.transition === 'confirm'
    );
  }

  get canDelete$(): Observable<boolean> {
    return this.warehouseRight.canDelete$;
  }

  get canBeDeleted(): boolean {
    if (extractType(this.shipment) === 'GenericShipment') {
      return true;
    }
    return (
      this.shipment?.rawState === 'voided' ||
      this.shipment?.rawState === 'delivered'
    );
  }

  get canShowDeliveryNotePreview(): boolean {
    return (
      this.shipment?.shipmentDirection ===
      '/api/shipment_directions/SHIPMENT_DIRECTION_DR_DE_TO_CUSTOMER'
    );
  }

  ngOnInit(): void {
    this.loadShipment();
    this.loadShipmentDirections();
    console.log(this.data);
  }

  loadShipment(): void {
    this.isLoading$.next(true);
    this.apiService
      .getObject(this.data.shipment, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.shipment = response as Shipment;
          if(!!this.shipment.additionalInsurance) {
            this.loadShipmentAdditionalInsurance(this.shipment.additionalInsurance);
          }
          this.isLoading$.next(false);
          // console.log(response);
        },
        fail => {
          this.isLoading$.next(false);
        }
      );
  }
  loadShipmentAdditionalInsurance(additionalInsurance: string): void {
    this.isLoading$.next(true);
    this.apiService
      .getObject(additionalInsurance, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.shipmentAdditionalInsurance = response as ShipmentAdditionalInsurance;
          this.isLoading$.next(false);
          // console.log(response);
        },
        fail => {
          this.isLoading$.next(false);
        }
      );
  }

  handleDeleteShipment(shipment: Shipment): void {
    const { '@id': iri } = shipment;

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieses Versandpaket wirklich gelöscht werden?',
          heading: 'Versandpaket löschen',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(ShipmentsActions.DeleteShipment({ iri }));
        this.actions$
          .pipe(ofType(ShipmentsActions.DeleteShipmentSuccess), take(1))
          .subscribe(() => {
            this.dialogRef.close(true);
          });
      });
  }

  makeTransitionOfShipment(transitionName: string): void {
    this.isLoading$.next(true);

    const { potentialActions } = this.shipment;
    const possibleAction = potentialActions.find(
      item => item.transition === transitionName
    );

    if (possibleAction) {
      const { workflow, transition } = possibleAction;
      const iri = extractIri(this.shipment);
      this.store$.dispatch(
        ShipmentsActions.ConfirmShipment({
          iri,
          payload: { workflow, transition }
        })
      );
      this.actions$
        .pipe(
          ofType(
            ShipmentsActions.ConfirmShipmentSuccess,
            ShipmentsActions.ConfirmShipmentFail
          ),
          takeUntil(this.onDestroy$),
          take(1)
        )
        .subscribe(({ response }) => {
          this.isLoading$.next(false);
          this.loadShipment();
        });
    } else {
      this.dialog.open(DialogComponent, {
        data: { text: 'Diese Aktion kann leider nicht ausgeführt werden.' }
      });
    }
  }

  downloadDeliveryNodePreview(orderIri: string): void {
    this.ordersService.downloadDeliveryNotePreview(orderIri).subscribe(
      response => {
        const a = document.createElement('a');
        a.href = response?.contentUrl;
        a.download = response?.fileName; // File name Here
        a.click(); // Downloaded file
        a.remove();
      },
      fail => {
        const msg = fail.error['hydra:description'];
        this.notifierService.show({
          type: 'error',
          message: 'Fehler beim herunterladen der Packliste: ' + msg
        });
        console.log(fail);
      }
    );
  }

  private loadShipmentDirections(): void {
    this.store$
      .select(ShipmentDirectionsSelectors.selectShipmentDirectionsEntities)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(e => {
          if (!e || !Object.keys(e) || Object.keys(e).length <= 0) {
            this.store$.dispatch(
              ShipmentDirectionsActions.ReadShipmentDirections()
            );
          }
        })
      )
      .subscribe(e => {
        this.shipmentDirectionsEntities = e;
      });
  }
}
