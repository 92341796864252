import { Component, OnInit } from '@angular/core';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-analysis-widget-ext',
  styleUrls: ['./open-analysis-widget-ext.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Aktuell offene Analysen</span>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenAnalysisWidgetExtComponent extends BaseOnDestroyComponent
  implements OnInit {
  states: string[];

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    // Analysis in EXT
    this.states = [
      // analysis_single_shipping_dr_ext, // -> DT noch nicht eingegangen, warte auf eintreffen
      'analysis_dm_received_dr_ext',
      'analysis_in_progress_dr_ext',
      // 'analysis_provide_purchase_prices_dr_ext'
      'analysis_dm_received_dr_remote',
      'analysis_in_progress_dr_de_remote'
    ];
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }
}
