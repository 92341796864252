import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CustomerCooperationsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { CustomerCooperationsService } from '../../services/customer-cooperations.service';

@Injectable()
export class CustomerCooperationsEffects {
  createCustomerCooperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCooperationsActions.CreateCustomerCooperation),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createCustomerCooperation(payload).pipe(
          map(response =>
            CustomerCooperationsActions.CreateCustomerCooperationSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              CustomerCooperationsActions.CreateCustomerCooperationFail({
                response
              })
            )
          )
        );
      })
    )
  );
  getCustomerCooperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCooperationsActions.ReadCustomerCooperation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getCustomerCooperation(iri).pipe(
          map(response =>
            CustomerCooperationsActions.ReadCustomerCooperationSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              CustomerCooperationsActions.ReadCustomerCooperationFail({
                response
              })
            )
          )
        );
      })
    )
  );
  getCustomerCooperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCooperationsActions.ReadCustomerCooperations),
      map(action => action),
      switchMap(() => {
        return this.service.getCustomerCooperations().pipe(
          map(response =>
            CustomerCooperationsActions.ReadCustomerCooperationsSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              CustomerCooperationsActions.ReadCustomerCooperationsFail({
                response
              })
            )
          )
        );
      })
    )
  );
  updateCustomerCooperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCooperationsActions.UpdateCustomerCooperation),
      map(action => action),
      switchMap(payload => {
        return this.service.updateCustomerCooperation(payload).pipe(
          map(response =>
            CustomerCooperationsActions.UpdateCustomerCooperationSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              CustomerCooperationsActions.UpdateCustomerCooperationFail({
                response
              })
            )
          )
        );
      })
    )
  );
  deleteCustomerCooperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCooperationsActions.DeleteCustomerCooperation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteCustomerCooperation(iri).pipe(
          map(() =>
            CustomerCooperationsActions.DeleteCustomerCooperationSuccess({
              iri
            })
          ),
          catchError(response =>
            of(
              CustomerCooperationsActions.DeleteCustomerCooperationFail({
                response
              })
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerCooperationsActions.DeleteCustomerCooperationFail,
          CustomerCooperationsActions.UpdateCustomerCooperationFail,
          CustomerCooperationsActions.ReadCustomerCooperationsFail,
          CustomerCooperationsActions.ReadCustomerCooperationFail,
          CustomerCooperationsActions.CreateCustomerCooperationFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  CustomerCooperationActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerCooperationsActions.CreateCustomerCooperationSuccess,
        CustomerCooperationsActions.UpdateCustomerCooperationSuccess
      ),
      map(({ type }) => {
        const text =
          type ===
          CustomerCooperationsActions.CREATE_CUSTOMER_COOPERATION_SUCCESS
            ? 'Eine neue Kunden-Kooperation wurde angelegt.'
            : 'Die Kunden-Kooperation wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: CustomerCooperationsService,
    private notifierService: NotifierService
  ) {}
}
