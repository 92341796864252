import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {Actions, ofType} from '@ngrx/effects';
import {OrdersActions} from '../../../orders/store';
import {FormsService} from '../../../shared/services';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {PasswordsActions} from '../../store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-password-form',
  styleUrls: ['password-form.component.scss'],
  template: `

    <form [formGroup]="pf">

      <div class="grid">
        <mat-form-field class="column-14">
          <mat-label>Aktuelles Passwort</mat-label>
          <input type="password" matInput formControlName="oldPassword">
          <mat-error>
            <app-form-error [fieldName]="'oldPassword'" [formGroup]="pf"></app-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="column-14">
          <mat-label>Neues Passwort</mat-label>
          <input type="password" matInput formControlName="plainPassword">
          <mat-error>
            <app-form-error [fieldName]="'plainPassword'" [formGroup]="pf"></app-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="column-14">
          <mat-label>Bestätigung Neues Passwort</mat-label>
          <input type="password" matInput formControlName="plainPasswordConfirmation">
          <mat-error>
            <app-form-error [fieldName]="'plainPasswordConfirmation'" [formGroup]="pf"></app-form-error>
          </mat-error>
        </mat-form-field>

        <div class="mat-form-field  m-ta--2 column-14">
          <button class="m-r--8" type="button" (click)="handleCancelUpdate()" mat-flat-button color="outline">
            <mat-icon class="m-r--8">cancel</mat-icon>
            <span>Abbrechen</span>
          </button>

          <button [disabled]="pf.invalid || pf.untouched" type="submit" (click)="handleRequestUpdatePassword()"
                  mat-flat-button
                  color="green">Speichern
          </button>
        </div>
      </div>
    </form>
  `
})
export class PasswordFormComponent implements OnInit, OnDestroy {


  @Output()
  requestUpdatePassword: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  cancelEdit: EventEmitter<void> = new EventEmitter<void>();

  pf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder,
              private fs: FormsService,
              private store$: Store<ApplicationState>,
              public actions$: Actions) {

  }

  ngOnInit(): void {
    this.initForm();
    this.actions$.pipe(ofType(PasswordsActions.UpdatePasswordFail),
      takeUntil(this.onDestroy$),
    ).subscribe((fail) => {
      console.log(fail);
      if (fail?.response?.error?.violations) {
        this.fs.mergeViolationsIntoForm(fail.response.error.violations, this.pf);
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


  initForm(): void {
    this.pf = this.fb.group({
      oldPassword: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
      plainPassword: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
      plainPasswordConfirmation: this.fb.control(null, [Validators.required, Validators.minLength(8)])
    });
  }

  handleRequestUpdatePassword(): void {
    this.requestUpdatePassword.emit(this.pf.value);
  }

  handleCancelUpdate(): void {
    console.log('cancel');
    this.cancelEdit.emit();
  }
}
