import { CancellationInvoicesLoadedGuard } from './cancellation-invoices-loaded.guard';
import { CommissionCreditsLoadedGuard } from './commission-credits-loaded.guard';
import { CorrectionInvoicesLoadedGuard } from './correction-invoices-loaded.guard';
import { CurrentCancellationInvoiceLoadedGuard } from './current-cancellation-invoice-loaded.guard';
import { CurrentCommissionCreditLoadedGuard } from './current-commission-credit-loaded.guard';
import { CurrentCorrectionInvoiceLoadedGuard } from './current-correction-invoice-loaded.guard';
import { CurrentInvoiceLoadedGuard } from './current-invoice-loaded.guard';
import { CurrentOfferLoadedGuard } from './current-offer-loaded.guard';
import { DocumentDeliveryProvidersLoadedGuard } from './document-delivery-providers-loaded.guard';
import { OffersLoadedGuard } from './offers-loaded.guard';
import { PayableInvoicesLoadedGuard } from './payable-invoices-loaded.guard';

export const AllGuards = [
  CancellationInvoicesLoadedGuard,
  CommissionCreditsLoadedGuard,
  CorrectionInvoicesLoadedGuard,
  CurrentCancellationInvoiceLoadedGuard,
  CurrentCommissionCreditLoadedGuard,
  CurrentCorrectionInvoiceLoadedGuard,
  CurrentInvoiceLoadedGuard,
  CurrentOfferLoadedGuard,
  DocumentDeliveryProvidersLoadedGuard,
  OffersLoadedGuard,
  PayableInvoicesLoadedGuard,
];

export * from './cancellation-invoices-loaded.guard';
export * from './commission-credits-loaded.guard';
export * from './correction-invoices-loaded.guard';
export * from './current-cancellation-invoice-loaded.guard';
export * from './current-commission-credit-loaded.guard';
export * from './current-correction-invoice-loaded.guard';
export * from './current-invoice-loaded.guard';
export * from './current-offer-loaded.guard';
export * from './document-delivery-providers-loaded.guard';
export * from './offers-loaded.guard';
export * from './payable-invoices-loaded.guard';
