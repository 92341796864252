import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { LeadContactTypesActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [k: string]: fromModuleModels.LeadContactType };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(LeadContactTypesActions.LoadLeadContactTypes, state => ({
    ...state,
    loading: true
  })),
  on(
    LeadContactTypesActions.LoadLeadContactTypesSuccess,
    (state, { response }) => {
      const leadContactTypes = response['hydra:member'];
      const entities = leadContactTypes.reduce(
        (
          entities: { [id: string]: fromModuleModels.LeadContactType },
          leadContactType
        ) => {
          return {
            ...entities,
            [leadContactType['@id']]: leadContactType
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
