import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../application-state/store';
import { AuthService } from '../../auth/services/auth.service';
import { RolesSelectors } from '../../master-data/store/selectors';
import * as RolesActions from '../../master-data/store/actions/roles.actions';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { combineLatestArray } from 'rxjs-etc';
import { UserRoleActions } from '../../application-state/store/actions';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private hierarchy$: BehaviorSubject<{
    [p: string]: string[];
  }> = new BehaviorSubject(null);
  private resolvedRoles$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private actions$: Actions
  ) {
    combineLatestArray([
      actions$.pipe(ofType(ROOT_EFFECTS_INIT)),
      this.authService.isLoggedInAndNotExpired$
    ])
      .pipe(
        filter(data => data[0] && data[1]),
        take(1)
      )
      .subscribe(data => {
        console.log('USERROELSERv-', data);
        this.initModule();
      });
  }

  initModule(): void {
    combineLatest([this.authService.userBaseRoles$, this.hierarchy$]).subscribe(
      ([userBaseRoles, hierarchy]) => {
        const roles = this.resolveUserRoles(userBaseRoles, hierarchy);
        if (roles?.length >= 0) {
          this.resolvedRoles$.next(roles);
        }
      }
    );

    this.store$
      .select(RolesSelectors.selectRolesEntities)
      .pipe(filter(e => !!e && Object.keys(e).length > 0))
      .subscribe(roles => {
        this.hierarchy$.next(roles);
      });
    this.authService.isLoggedInAndNotExpired$
      .pipe(filter(e => !!e))
      .subscribe(() => {
        this.store$.dispatch(RolesActions.ReadRoles());
      });
  }

  resolveUserRoles(
    userBaseRoles: Array<string>,
    hierarchy: { [p: string]: string[] }
  ): string[] {
    if (!hierarchy) {
      return null;
    }
    if (userBaseRoles.length <= 0) {
      return null;
    }
    const resolvedResultRoles = [];
    for (const role of userBaseRoles) {
      resolvedResultRoles.push(role);
      const resolvedRoles = this.resolveRole(role, hierarchy);
      if (resolvedRoles.length > 0) {
        resolvedResultRoles.push(...resolvedRoles);
      }
    }
    return this.reduceDuplicates(resolvedResultRoles).sort();
  }

  userHasRole(role: string | Array<string>): Observable<boolean> {
    // console.log('userHasRole', role);
    return new Observable<boolean>(subscriber => {
      this.resolvedRoles$.pipe(filter(e => e?.length > 0)).subscribe(roles => {
        let found = false;
        if (typeof role !== 'string') {
          found = role.some(needRole => roles.indexOf(needRole) > -1);
        } else {
          found = roles.indexOf(role) > -1;
        }
        // console.log('userHasRole-resolvedRoles$', role, roles.length, found);

        subscriber.next(found);
        subscriber.complete();
      });
    });
  }

  userHasRoleFilter<B>(
    role: string | Array<string>,
    cb: (hasRole: boolean) => Observable<B>
  ): Observable<B> {
    this.store$.dispatch(UserRoleActions.UserRoleCheckStart({ role }));
    return this.userHasRole(role).pipe(
      mergeMap(hasRole => {
        if (hasRole) {
          this.store$.dispatch(UserRoleActions.UserRoleCheckSuccess({ role }));
        } else {
          this.store$.dispatch(UserRoleActions.UserRoleCheckFail({ role }));
        }
        return cb(hasRole);
      })
    );
  }

  availableRoles(): Observable<string[]> {
    return this.resolvedRoles$.asObservable();
  }

  reduceDuplicates(list: string[]): string[] {
    const resultObj = {};
    for (const element of list) {
      resultObj[element] = 1;
    }
    return Object.keys(resultObj);
  }

  resolveRole(role: string, hierarchy: { [p: string]: string[] }): string[] {
    const resultRules = [];
    if (hierarchy[role]) {
      for (const childRole of hierarchy[role]) {
        resultRules.push(childRole);
        const resolvedRoles = this.resolveRole(childRole, hierarchy);
        if (resolvedRoles.length > 0) {
          resultRules.push(...resolvedRoles);
        }
      }
    }
    return resultRules;
  }
}
