import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

// Vendor Import
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { NxModule } from '@nrwl/nx';
import { CookieService } from 'ngx-cookie-service';
import { NotifierModule } from 'angular-notifier';

// Provider Import
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CustomersModule } from './customers/customers.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ApplicationStateModule } from './application-state/application-state.module';
import { NavigationModule } from './navigation/navigation.module';
import { AdministratorsModule } from './administrators/administrators.module';

import { ROOT_REDUCERS } from './application-state/store';
import { ROOT_EFFECTS } from './application-state/store/effects';
import { AbstractApiService, AbstractMercureService } from './shared/services';
import { RouterSerializer } from './application-state/serializers/router.serializer';
import { NotificationsModule } from './notifications/notifications.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { UserAuthenticatedShellComponent } from './user-authenticated-shell.component';
import { UserNotAuthenticatedShellComponent } from './user-not-authenticated-shell.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { LeadsModule } from './leads/leads.module';
import { MasterDataModule } from './master-data/master-data.module';
import { WarehouseModule } from './warehouse/warehouse.module';
import { OrdersModule } from './orders/orders.module';
import { TicketsModule } from './tickets/tickets.module';
import { ShippingModule } from './shipping/shipping.module';
import { InvoicesModule } from './invoices/invoices.module';
import { clearStateAction } from './auth/services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {DragToSelectModule} from "ngx-drag-to-select";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';

//TRANSLATION MODULES
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// console.log all actions
export const debug = (reducer: ActionReducer<any>): ActionReducer<any> => (
  state,
  action
) => {
  console.group(
    `${action.type} (${new Date(new Date().getTime()).toLocaleTimeString()})`
  );
  console.log('state', state);
  console.log('action', action);
  console.groupEnd();

  return reducer(state, action);
};

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: Action): any => {
    if (action.type === clearStateAction.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const authProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
registerLocaleData(localeDe);
// eslint-disable-line no-unused-vars
export const metaReducers: MetaReducer<any>[] = [
  // debug,
  clearState
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    UserAuthenticatedShellComponent,
    UserNotAuthenticatedShellComponent
  ],
  imports: [
    AdministratorsModule,
    AppRoutingModule,
    ApplicationStateModule,
    AuthModule,
    BrowserModule,
    CustomersModule,
    DashboardModule,
    InvoicesModule,
    LeadsModule,
    MasterDataModule,
    NavigationModule,
    NotificationsModule,
    OrdersModule,
    RouterModule,
    // SharedModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'top'
        }
      },
      behaviour: {
        onMouseover: 'pauseAutoHide',
        onClick: 'hide',
        showDismissButton: false
      }
      // Custom options in here
    }),
    WarehouseModule,
    ShippingModule,
    TicketsModule,
    NxModule.forRoot(),
    DragToSelectModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, { metaReducers }),
    EffectsModule.forRoot(ROOT_EFFECTS),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatIconModule,
    MatButtonModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    authProvider,
    AbstractApiService,
    AbstractMercureService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: RouterStateSerializer, useClass: RouterSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
