import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Shipment } from '../../../shipping/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { ShipmentsService } from '../../../shipping/services/shipments.service';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { AuthService } from '../../../auth/services/auth.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ShipmentsActions } from '../../../shipping/store';
import { OrdersActions } from '../../../orders/store';
import { extractIri } from '../../utilities/objects.utility';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { ShowShipmentDialogComponent } from '../../components/show-shipment-dialog/show-shipment-dialog.component';

@Component({
  selector: 'app-action-box-open-shipment-info',
  styleUrls: ['./action-box-open-shipment-info.component.scss'],
  template: `
    <div class="action-box warning" *ngIf="localShipments?.length > 0">
      <div class="action-required-label bg-yellow">Aktive Sendungen</div>
      <div class="content ">
        <div class="row">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Aktive Sendung</div>
              <div class="sub-header col-auto">
                Zu diesem Auftrag gibt es aktuell
                {{ localShipments.length }} Sendung{{
                  localShipments.length > 1 ? 'en' : ''
                }}:
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let shipment of localShipments">
          <app-shipment-display-line
            [shipment]="shipment"
          ></app-shipment-display-line>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxOpenShipmentInfoComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;
  order: Order;
  isLoadingShipments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  localShipments: Array<Shipment> = null;

  constructor(
    private store$: Store<ApplicationState>,
    private shipmentsService: ShipmentsService,
    private dialog: MatDialog,
    private actions$: Actions,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe(order => {
        this.order = order;
        this.loadShipment();
      });
    this.actions$
      .pipe(
        ofType(
          ShipmentsActions.ConfirmShipmentSuccess,
          ShipmentsActions.CancelShipmentSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OrdersActions.ReadOrder({ iri: extractIri(this.order) })
        );
      });
  }

  loadShipment(): void {
    this.isLoadingShipments$.next(true);

    const params: any = {
      pagination: true,
      'order[createdAt]': 'desc',
      'orders.uuid': getUuidFromIri(extractIri(this.order)),
      state: ['confirmed', 'created']
    };

    this.shipmentsService.readShipments(-1, params).subscribe(
      data => {
        this.localShipments = data['hydra:member'];
        this.isLoadingShipments$.next(false);
      },
      error => {
        console.log(error);
        this.isLoadingShipments$.next(false);
      }
    );
  }

  openShipment(shipment: Shipment): void {
    this.dialog.open(ShowShipmentDialogComponent, {
      panelClass: 'width-95',
      data: { shipment: extractIri(shipment) }
    });
  }
}
