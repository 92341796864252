import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {OrderSpecialAgreement, OrderSpecialAgreementCreate} from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ORDER_SPECIAL_AGREEMENT = '[Orders Module] Create Order Special Agreement';
export const CREATE_ORDER_SPECIAL_AGREEMENT_SUCCESS = '[Orders Module] Create Order Special Agreement Success';
export const CREATE_ORDER_SPECIAL_AGREEMENT_FAIL = '[Orders Module] Create Order Special Agreement Fail';

export const CreateOrderSpecialAgreement = createAction(
  CREATE_ORDER_SPECIAL_AGREEMENT,
  props<{ payload: OrderSpecialAgreementCreate | OrderSpecialAgreement }>()
);

export const CreateOrderSpecialAgreementSuccess = createAction(
  CREATE_ORDER_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateOrderSpecialAgreementFail = createAction(
  CREATE_ORDER_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER_SPECIAL_AGREEMENT = '[Orders Module] Read Order Special Agreement';
export const READ_ORDER_SPECIAL_AGREEMENT_SUCCESS = '[Orders Module] Read Order Special Agreement Success';
export const READ_ORDER_SPECIAL_AGREEMENT_FAIL = '[Orders Module] Read Order Special Agreement Fail';

export const ReadOrderSpecialAgreement = createAction(
  READ_ORDER_SPECIAL_AGREEMENT,
  props<{ iri: string }>()
);

export const ReadOrderSpecialAgreementSuccess = createAction(
  READ_ORDER_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderSpecialAgreementFail = createAction(
  READ_ORDER_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_SPECIAL_AGREEMENTS = '[Orders Module] Read Order Special Agreements';
export const READ_ORDER_SPECIAL_AGREEMENTS_SUCCESS = '[Orders Module] Read Order Special Agreements Success';
export const READ_ORDER_SPECIAL_AGREEMENTS_FAIL = '[Orders Module] Read Order Special Agreements Fail';

export const ReadOrderSpecialAgreements = createAction(
  READ_ORDER_SPECIAL_AGREEMENTS,
  props<{ params: { [p: string]: any } }>()
);

export const ReadOrderSpecialAgreementsSuccess = createAction(
  READ_ORDER_SPECIAL_AGREEMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderSpecialAgreementsFail = createAction(
  READ_ORDER_SPECIAL_AGREEMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ORDER_SPECIAL_AGREEMENT = '[Orders Module] Update Order Special Agreement';
export const UPDATE_ORDER_SPECIAL_AGREEMENT_SUCCESS = '[Orders Module] Update Order Special Agreement Success';
export const UPDATE_ORDER_SPECIAL_AGREEMENT_FAIL = '[Orders Module] Update Order Special Agreement Fail';

export const UpdateOrderSpecialAgreement = createAction(
  UPDATE_ORDER_SPECIAL_AGREEMENT,
  props<{ iri: string, payload: fromModuleModels.OrderSpecialAgreement }>()
);

export const UpdateOrderSpecialAgreementSuccess = createAction(
  UPDATE_ORDER_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: fromModuleModels.OrderSpecialAgreement }>()
);

export const UpdateOrderSpecialAgreementFail = createAction(
  UPDATE_ORDER_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ORDER_SPECIAL_AGREEMENT = '[Orders Module] Delete Order Special Agreement';
export const DELETE_ORDER_SPECIAL_AGREEMENT_SUCCESS = '[Orders Module] Delete Order Special Agreement Success';
export const DELETE_ORDER_SPECIAL_AGREEMENT_FAIL = '[Orders Module] Delete Order Special Agreement Fail';

export const DeleteOrderSpecialAgreement = createAction(
  DELETE_ORDER_SPECIAL_AGREEMENT,
  props<{ iri: string }>()
);

export const DeleteOrderSpecialAgreementSuccess = createAction(
  DELETE_ORDER_SPECIAL_AGREEMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteOrderSpecialAgreementFail = createAction(
  DELETE_ORDER_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
