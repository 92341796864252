import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ENCLOSURE_DATA_MEDIUM = '[Warehouse Module] Create Enclosure Data Medium';
export const CREATE_ENCLOSURE_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Create Enclosure Data Medium Success';
export const CREATE_ENCLOSURE_DATA_MEDIUM_FAIL = '[Warehouse Module] Create Enclosure Data Medium Fail';

export const CreateEnclosureDataMedium = createAction(
  CREATE_ENCLOSURE_DATA_MEDIUM,
  props<{ payload: fromModuleModels.EnclosureDataMedium }>()
);

export const CreateEnclosureDataMediumSuccess = createAction(
  CREATE_ENCLOSURE_DATA_MEDIUM_SUCCESS,
  props<{ response: fromModuleModels.EnclosureDataMedium | any }>()
);

export const CreateEnclosureDataMediumFail = createAction(
  CREATE_ENCLOSURE_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ENCLOSURE_DATA_MEDIUM = '[Warehouse Module] Read Enclosure Data Medium';
export const READ_ENCLOSURE_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Read Enclosure Data Medium Success';
export const READ_ENCLOSURE_DATA_MEDIUM_FAIL = '[Warehouse Module] Read Enclosure Data Medium Fail';

export const ReadEnclosureDataMedium = createAction(
  READ_ENCLOSURE_DATA_MEDIUM,
  props<{ iri: string }>()
);

export const ReadEnclosureDataMediumSuccess = createAction(
  READ_ENCLOSURE_DATA_MEDIUM_SUCCESS,
  props<{ response: any }>()
);

export const ReadEnclosureDataMediumFail = createAction(
  READ_ENCLOSURE_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ENCLOSURE_DATA_MEDIUM = '[Warehouse Module] Update Enclosure Data Medium';
export const UPDATE_ENCLOSURE_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Update Enclosure Data Medium Success';
export const UPDATE_ENCLOSURE_DATA_MEDIUM_FAIL = '[Warehouse Module] Update Enclosure Data Medium Fail';

export const UpdateEnclosureDataMedium = createAction(
  UPDATE_ENCLOSURE_DATA_MEDIUM,
  props<{ iri: string, payload: fromModuleModels.EnclosureDataMedium }>()
);

export const UpdateEnclosureDataMediumSuccess = createAction(
  UPDATE_ENCLOSURE_DATA_MEDIUM_SUCCESS,
  props<{ response: any }>()
);

export const UpdateEnclosureDataMediumFail = createAction(
  UPDATE_ENCLOSURE_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ENCLOSURE_DATA_MEDIUM = '[Warehouse Module] Delete Enclosure Data Medium';
export const DELETE_ENCLOSURE_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Delete Enclosure Data Medium Success';
export const DELETE_ENCLOSURE_DATA_MEDIUM_FAIL = '[Warehouse Module] Delete Enclosure Data Medium Fail';

export const DeleteEnclosureDataMedium = createAction(
  DELETE_ENCLOSURE_DATA_MEDIUM,
  props<{ iri: string }>()
);

export const DeleteEnclosureDataMediumSuccess = createAction(
  DELETE_ENCLOSURE_DATA_MEDIUM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteEnclosureDataMediumFail = createAction(
  DELETE_ENCLOSURE_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
