import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Administrator} from '../../../administrators/models';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {AdministratorsActions} from '../../../administrators/store';
import {TicketList} from '../../models/ticket-list.interface';
import {TicketsSelectors} from '../../store/selectors';

@Component({
  selector: 'app-ticket-technician-select',
  styleUrls: ['./ticket-technician-select.component.scss'],
  template: `
    <div [formGroup]="formGroup">
      <ng-select
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        placeholder="Techniker"
        [formControlName]="fieldName"
        (change)="setSelectedItem($event)">

        <ng-template ng-label-tmp let-item="item">
          {{item.firstName}} {{item.lastName}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.firstName}} {{item.lastName}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>

        </ng-template>
      </ng-select>
    </div>
  `

})
export class TicketTechnicianSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Output() updateSelectedObject: EventEmitter<Administrator> = new EventEmitter<Administrator>();

  items$: Observable<Array<Administrator>>;
  isLoading$: Observable<boolean>;
  selectedItem: Administrator;
  tickets: TicketList[];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.items$ = this.store$.select(AdministratorsSelectors.sList);
    this.isLoading$ = this.store$.select(AdministratorsSelectors.isLoading);
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());
    this.store$.select(TicketsSelectors.sList).subscribe((tickets) => {
      this.tickets = tickets;
    });

  }

  getOrderCount(adminIri: string): number {
    return this.tickets.filter(e => e.order.technician === adminIri).length || -1;
  }

  setSelectedItem(element: Administrator): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: Administrator): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.firstName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        (item.lastName && item.lastName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        (item.middleName && item.middleName.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }


}
