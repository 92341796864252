import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'void-box',
  styleUrls: ['void-box.component.scss'],
  template: `
    <pre>VOIDBOX</pre>
  `
})
export class VoidBoxComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
