import {Component, Inject, OnInit} from '@angular/core';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {Actions, ofType} from '@ngrx/effects';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {InvoiceItemLike, InvoiceLike} from '../../models';
import {InvoiceItemsActions, OfferItemsActions} from '../../store';
import {
  InvoiceItemsSelectors,
  OfferItemsSelectors,
  OffersSelectors,
  PayableInvoicesSelectors
} from '../../store/selectors';

@Component({
  selector: 'app-invoice-item-dialog',
  styleUrls: ['./invoice-item-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Position {{data.item ? 'bearbeiten' : 'hinzufügen'}} </h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <app-invoice-item-form
        [changeableTypeDisabled]="data.changeableTypeDisabled"
        [invoice]="data.invoice"
        [item]="data.item"
      ></app-invoice-item-form>
    </div>
  `

})
export class InvoiceItemDialogComponent extends BaseOnDestroyComponent implements OnInit {
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<InvoiceItemDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                invoice: InvoiceLike,
                item: InvoiceItemLike,
                changeableTypeDisabled: false
              }) {
    super();
    console.log(this.data);
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(PayableInvoicesSelectors.isLoading),
      this.store$.select(OffersSelectors.isLoading),
      this.store$.select(InvoiceItemsSelectors.isLoading),
      this.store$.select(OfferItemsSelectors.isLoading),
    ]);
    this.actions$.pipe(
      ofType(
        InvoiceItemsActions.CreateInvoiceItemSuccess,
        InvoiceItemsActions.UpdateInvoiceItemSuccess,
        OfferItemsActions.CreateOfferItemSuccess,
        OfferItemsActions.UpdateOfferItemSuccess,
      ),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.dialogRef.close();
    });
    this.actions$.pipe(
      ofType(
        InvoiceItemsActions.CreateInvoiceItemFail,
        InvoiceItemsActions.UpdateInvoiceItemFail,
      ),
      takeUntil(this.onDestroy$)
    ).subscribe((fail) => {
      if (fail?.response?.error['hydra:description']) {
        this.notifierService.notify('error', fail?.response?.error['hydra:description']);
      }
    });

  }

}
