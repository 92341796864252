// Provider Imports
import { AcceptedOffersService } from './accepted-offers.service';
import { CancellationInvoicesService } from './cancellation-invoices.service';
import { CommissionCreditItemsService } from './commission-credit-items.service';
import { CommissionCreditsService } from './commission-credits.service';
import { CorrectionInvoiceItemsService } from './correction-invoice-items.service';
import { CorrectionInvoicesService } from './correction-invoices.service';
import { DocumentDeliveriesService } from './document-deliveries.service';
import { DocumentDeliveryProvidersService } from './document-delivery-providers.service';
import { InvoiceItemsService } from './invoice-items.service';
import { InvoicePaymentsService } from './invoice-payments.service';
import { OfferItemsService } from './offer-items.service';
import { OffersService } from './offers.service';
import { PartialInvoiceItemsService } from './partial-invoice-items.service';
import { PartialInvoicesService } from './partial-invoices.service';
import { PayableInvoicesService } from './payable-invoices.service';
import { PaymentProcessesService } from './payment-processes.service';

export const AllServices: Array<any> = [
  AcceptedOffersService,
  CancellationInvoicesService,
  CommissionCreditItemsService,
  CommissionCreditsService,
  CorrectionInvoiceItemsService,
  CorrectionInvoicesService,
  DocumentDeliveriesService,
  DocumentDeliveryProvidersService,
  InvoiceItemsService,
  InvoicePaymentsService,
  OfferItemsService,
  OffersService,
  PartialInvoiceItemsService,
  PartialInvoicesService,
  PayableInvoicesService,
  PaymentProcessesService,
];

export * from './accepted-offers.service';
export * from './invoice-payments.service';
export * from './cancellation-invoices.service';
export * from './commission-credit-items.service';
export * from './commission-credits.service';
export * from './correction-invoice-items.service';
export * from './correction-invoices.service';
export * from './document-deliveries.service';
export * from './document-delivery-providers.service';
export * from './invoice-items.service';
export * from './offer-items.service';
export * from './offers.service';
export * from './partial-invoice-items.service';
export * from './partial-invoices.service';
export * from './payable-invoices.service';
export * from './payment-processes.service';
