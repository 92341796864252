import {createSelector} from '@ngrx/store';

import {DEPARTMENTS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';
import {selectDataMediumsState} from '../../../warehouse/store/selectors/data-mediums.selectors';

export const selectDepartmentsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[DEPARTMENTS_FEATURE_KEY]
);

export const selectDepartmentsEntities = createSelector(
  selectDepartmentsState,
  (state) => state.entities
);

export const selectDepartments = createSelector(
  selectDepartmentsEntities,
  (entities) => Object.values(entities)
);

export const selectFirstDepartment = createSelector(
  selectDepartments,
  (entities) => entities[0]
);

export const sByIri = (createSelector(
  selectDepartmentsEntities,
  (entities, {iri}) => entities[iri]
));

export const selectDefaultDepartment = createSelector(
  selectDepartments,
  (departments) => departments.find(department => department.isDefault)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectDepartmentsTotalItems = createSelector(
  selectDepartmentsState,
  (state) => state.totalItems
);

export const selectDepartmentsPagination = createSelector(
  selectDepartmentsState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectDepartmentsPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectDepartmentsPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectDepartmentsPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectDepartmentsPagination,
  pagination => pagination.next
);

////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectErrors = createSelector(
  selectDepartmentsState,
  (state) => state.errors
);

////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDepartmentsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDepartmentsState,
  (state) => state.loaded
);
