import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromBindingOrdersReducers from './binding-orders.reducers';
import * as fromDataMediumStatusLogsReducers from './data-medium-status-logs.reducers';
import * as fromDataRecoveryCostsReducers from './data-recovery-costs.reducers';
import * as fromDataRecoveryProtocolsReducers from './data-recovery-protocols.reducers';
import * as fromDataRecoveryResultsReducers from './data-recovery-results.reducers';
import * as fromOrderCommentTagsReducers from './order-comment-tags.reducers';
import * as fromOrderCommentsReducers from './order-comments.reducers';
import * as fromOrderDataMediumsReducers from './order-data-mediums.reducers';
import * as fromOrderSpecialAgreementsReducers from './order-special-agreements.reducers';
import * as fromOrderStateRangesReducers from './order-state-ranges.reducers';
import * as fromOrderStatesReducers from './order-states.reducers';
import * as fromOrdersReducers from './orders.reducers';
import * as fromProductsOrderItemsReducers from './product-order-items.reducers';
import * as fromProofsOfDestructionReducers from './proofs-of-destruction.reducers';
import * as fromSalesReducers from './sales.reducers';
import * as fromSalesSummaryReducers from './sales-summary.reducers';
import * as fromServiceOrderItemsReducers from './service-order-items.reducers';
import * as fromGenericOrderItemsReducers from './generic-order-items.reducers';

export const ORDERS_MODULE_FEATURE_KEY = 'ordersModule';

export const BINDING_ORDERS_FEATURE_KEY = 'bindingOrders';
export const DATA_MEDIUM_STATUS_LOGS_FEATURE_KEY = 'dataMediumStatusLogs';
export const DATA_RECOVERY_COSTS_FEATURE_KEY = 'dataRecoveryCosts';
export const DATA_RECOVERY_PROTOCOLS_FEATURE_KEY = 'dataRecoveryProtocols';
export const DATA_RECOVERY_RESULTS_FEATURE_KEY = 'dataRecoveryResults';
export const ORDERS_FEATURE_KEY = 'orders';
export const ORDER_COMMENTS_FEATURE_KEY = 'orderComments';
export const ORDER_COMMENT_TAGS_FEATURE_KEY = 'orderCommentTags';
export const ORDER_DATA_MEDIUMS_FEATURE_KEY = 'orderDataMediums';
export const ORDER_SPECIAL_AGREEMENTS_FEATURE_KEY = 'orderSpecialAgreements';
export const ORDER_STATES_FEATURE_KEY = 'orderStates';
export const ORDER_STATE_RANGES_FEATURE_KEY = 'orderStateRanges';
export const PRODUCT_ORDER_ITEMS_FEATURE_KEY = 'productsOrderItems';
export const PROOFS_OF_DESTRUCTION_FEATURE_KEY = 'proofsOfDestruction';
export const SALES_FEATURE_KEY = 'sales';
export const SALES_SUMMARY_FEATURE_KEY = 'salesSummary';
export const SERVICE_ORDER_ITEMS_FEATURE_KEY = 'serviceOrderItems';
export const GENERIC_ORDER_ITEMS_FEATURE_KEY = 'genericOrderItems';

export interface OrdersModuleState {
  [BINDING_ORDERS_FEATURE_KEY]: fromBindingOrdersReducers.State;
  [DATA_MEDIUM_STATUS_LOGS_FEATURE_KEY]: fromDataMediumStatusLogsReducers.State;
  [DATA_RECOVERY_COSTS_FEATURE_KEY]: fromDataRecoveryCostsReducers.State;
  [DATA_RECOVERY_PROTOCOLS_FEATURE_KEY]: fromDataRecoveryProtocolsReducers.State;
  [DATA_RECOVERY_RESULTS_FEATURE_KEY]: fromDataRecoveryResultsReducers.State;
  [ORDERS_FEATURE_KEY]: fromOrdersReducers.State;
  [ORDER_COMMENTS_FEATURE_KEY]: fromOrderCommentsReducers.State;
  [ORDER_COMMENT_TAGS_FEATURE_KEY]: fromOrderCommentTagsReducers.State;
  [ORDER_DATA_MEDIUMS_FEATURE_KEY]: fromOrderDataMediumsReducers.State;
  [ORDER_SPECIAL_AGREEMENTS_FEATURE_KEY]: fromOrderSpecialAgreementsReducers.State;
  [ORDER_STATES_FEATURE_KEY]: fromOrderStatesReducers.State;
  [ORDER_STATE_RANGES_FEATURE_KEY]: fromOrderStateRangesReducers.State;
  [PRODUCT_ORDER_ITEMS_FEATURE_KEY]: fromProductsOrderItemsReducers.State;
  [PROOFS_OF_DESTRUCTION_FEATURE_KEY]: fromProofsOfDestructionReducers.State;
  [SALES_FEATURE_KEY]: fromSalesReducers.State;
  [SALES_SUMMARY_FEATURE_KEY]: fromSalesSummaryReducers.State;
  [SERVICE_ORDER_ITEMS_FEATURE_KEY]: fromServiceOrderItemsReducers.State;
  [GENERIC_ORDER_ITEMS_FEATURE_KEY]: fromGenericOrderItemsReducers.State;
}

export const selectOrdersModuleState = createFeatureSelector<OrdersModuleState>(ORDERS_MODULE_FEATURE_KEY);

export function reducers(state: OrdersModuleState, action: Action) {
  return combineReducers({
    [BINDING_ORDERS_FEATURE_KEY]: fromBindingOrdersReducers.reducer,
    [DATA_MEDIUM_STATUS_LOGS_FEATURE_KEY]: fromDataMediumStatusLogsReducers.reducer,
    [DATA_RECOVERY_COSTS_FEATURE_KEY]: fromDataRecoveryCostsReducers.reducer,
    [DATA_RECOVERY_PROTOCOLS_FEATURE_KEY]: fromDataRecoveryProtocolsReducers.reducer,
    [DATA_RECOVERY_RESULTS_FEATURE_KEY]: fromDataRecoveryResultsReducers.reducer,
    [ORDERS_FEATURE_KEY]: fromOrdersReducers.reducer,
    [ORDER_COMMENTS_FEATURE_KEY]: fromOrderCommentsReducers.reducer,
    [ORDER_COMMENT_TAGS_FEATURE_KEY]: fromOrderCommentTagsReducers.reducer,
    [ORDER_DATA_MEDIUMS_FEATURE_KEY]: fromOrderDataMediumsReducers.reducer,
    [ORDER_SPECIAL_AGREEMENTS_FEATURE_KEY]: fromOrderSpecialAgreementsReducers.reducer,
    [ORDER_STATES_FEATURE_KEY]: fromOrderStatesReducers.reducer,
    [ORDER_STATE_RANGES_FEATURE_KEY]: fromOrderStateRangesReducers.reducer,
    [PRODUCT_ORDER_ITEMS_FEATURE_KEY]: fromProductsOrderItemsReducers.reducer,
    [PROOFS_OF_DESTRUCTION_FEATURE_KEY]: fromProofsOfDestructionReducers.reducer,
    [SALES_FEATURE_KEY]: fromSalesReducers.reducer,
    [SALES_SUMMARY_FEATURE_KEY]: fromSalesSummaryReducers.reducer,
    [SERVICE_ORDER_ITEMS_FEATURE_KEY]: fromServiceOrderItemsReducers.reducer,
    [GENERIC_ORDER_ITEMS_FEATURE_KEY]: fromGenericOrderItemsReducers.reducer,
  })(state, action);
}
