<app-dialog-header>
  <h2>Sendung vom {{ shipment?.createdAt | momentDateWithTime }}</h2>
</app-dialog-header>
<div mat-dialog-content style="min-height: 60px;">
  <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
  <div class="alert alert-info" *ngIf="!!confirmAction && canEdit$ | async">
    Die Sendung ist angelegt. Sie wurde aber noch nicht bestätigt.
    <button
      mat-button
      color="green"
      *ngIf="confirmAction.transition === 'confirm'"
      (click)="makeTransitionOfShipment(confirmAction.transition)"
    >
      Paketdaten bestätigen & Label erstellen
    </button>
  </div>
  <div class="row" *ngIf="shipment">
    <div class="col-2 d-flex">
      <div class="text-bold pe-2">Typ:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        {{ shipment['@type'] | myI18nSelect: shipmentTypes }}
      </div>
    </div>
    <div class="col-2 d-flex">
      <div class="text-bold pe-2">Richtung:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        {{
          shipmentDirectionsEntities[shipment.shipmentDirection]?.direction
        }}
      </div>
    </div>
    <div class="col-2 d-flex">
      <div class="text-bold pe-2">Zustand:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
        [matTooltip]="getDescriptionOfShipmentState(shipment.state)"
      >
        {{ shipment.state || '-' }}
      </div>
    </div>
    <div class="col-2 d-flex">
      <div class="text-bold pe-2">Tracker:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        {{ shipment.trackerId || '-' }}
      </div>
    </div>
    <div class="col-2 d-flex" *ngIf="shipment['@type'] === 'DhlShipment'">
      <div class="text-bold pe-2">{{"shared.show_shipment_dialog.additional_insurance_placeholder" | translate}}:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        {{ shipmentAdditionalInsurance?.name || '-' }}
      </div>
    </div>
    <div class="col-2 d-flex">
      <div class="text-bold pe-2">Unterstützt Tracking:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        {{
          shipment.supportsTracking === true
            ? 'ja'
            : shipment.supportsTracking === false
              ? 'nein'
              : '-'
        }}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="shipment">
    <div class="col-3 d-flex" *ngIf="shipment.trackingNumber">
      <div class="text-bold pe-2">Tracking:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        <a
          *ngIf="shipment.trackingLink"
          [href]="shipment.trackingLink"
          target="_blank"
        >
          <button color="green" mat-button>
            {{ shipment.trackingNumber || '-' }}
          </button>
        </a>
        <span *ngIf="!shipment.trackingLink">{{
            shipment.trackingNumber || '-'
          }}</span>
      </div>
    </div>
    <div class="col-3 d-flex" *ngIf="shipment.labelUrl">
      <div class="text-bold pe-2">Label:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        <a [href]="shipment.labelUrl" target="_blank">
          <button color="green" mat-button>Label öffnen</button>
        </a>
      </div>
    </div>
    <div class="col-3 d-flex" *ngIf="canShowDeliveryNotePreview">
      <div class="text-bold pe-2">Packliste:</div>
      <div
        class="rounded-2 bordered flex-grow-1 ps-2"
        style="min-height: 2.5rem;"
      >
        <button
          color="green"
          (click)="downloadDeliveryNodePreview(shipment.orders[0])"
          mat-button
        >
          Packliste herunterladen
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="shipment">
    <hr />
    <app-shipment-detail-display-line
      [shipment]="shipment"
    ></app-shipment-detail-display-line>
  </div>

  <!--      <pre>{{shipment|json}}</pre>-->
</div>
<div
  mat-dialog-actions
  class="text-right"
  *ngIf="shipment?.potentialActions"
>
  <ng-container *ngFor="let action of shipment?.potentialActions">
    <button
      mat-button
      color="green"
      *ngIf="action.transition === 'confirm'"
      (click)="makeTransitionOfShipment(action.transition)"
    >
      Versandauftrag auslösen & Label erstellen
    </button>
    <button
      mat-button
      color="green"
      *ngIf="action.transition === 'confirm_delivery'"
      (click)="makeTransitionOfShipment(action.transition)"
    >
      {{data.direction === 'DR_DE zu Kunde' ? ("shared.show_shipment_dialog.parcel_delivery.drde_to_client" | translate) : data.direction === 'DR DE nach DR EXT' ?  ("shared.show_shipment_dialog.parcel_delivery.drde_to_ext" | translate) : ("shared.show_shipment_dialog.parcel_delivery" | translate) }}
    </button>
    <button
      mat-button
      color="red"
      *ngIf="action.transition === 'void'"
      (click)="makeTransitionOfShipment(action.transition)"
    >
      Paket stornieren
    </button>
  </ng-container>
  <button
    mat-button
    color="red"
    *ngIf="canBeDeleted && canDelete$ | async"
    (click)="handleDeleteShipment(shipment)"
  >
    <mat-icon>delete</mat-icon>
    Löschen
  </button>
</div>
