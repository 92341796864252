import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleServices from '../../services';
import {AdministratorsActions} from '../actions';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class AdministratorsEffects {

  constructor(private actions$: Actions, private as: fromModuleServices.AdministratorsService) {
  }


  CreateAdministrator$ = createEffect(() => this.actions$.pipe(
    ofType(AdministratorsActions.CreateAdministrator),
    map(action => action),
    switchMap(({payload}) => {
      return this.as.createAdministrator(payload).pipe(
        map(response => AdministratorsActions.CreateAdministratorSuccess({response})),
        catchError(response => of(AdministratorsActions.CreateAdministratorFail({response})))
      );
    })
  ));


  ReadAdministrators$ = createEffect(() => this.actions$.pipe(
    ofType(AdministratorsActions.ReadAdministrators),
    map(action => action),
    switchMap(() => {
      return this.as.readAdministrators().pipe(
        map(response => AdministratorsActions.ReadAdministratorsSuccess({response})),
        catchError(response => of(AdministratorsActions.ReadAdministratorsFail({response})))
      );
    })
  ));


  UpdateAdministrator$ = createEffect(() => this.actions$.pipe(
    ofType(AdministratorsActions.UpdateAdministrator),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.as.updateAdministrator(iri, payload).pipe(
        map(response => AdministratorsActions.UpdateAdministratorSuccess({response})),
        catchError(response => of(AdministratorsActions.UpdateAdministratorFail({response})))
      );
    })
  ));


  DeleteAdministrator$ = createEffect(() => this.actions$.pipe(
    ofType(AdministratorsActions.DeleteAdministrator),
    map(action => action),
    switchMap(({iri}) => {
      return this.as.deleteAdministrator(iri).pipe(
        map(() => AdministratorsActions.DeleteAdministratorSuccess({iri})),
        catchError(response => of(AdministratorsActions.DeleteAdministratorFail({response})))
      );
    })
  ));

  administratorActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      AdministratorsActions.CreateAdministratorSuccess,
      AdministratorsActions.UpdateAdministratorSuccess,
    ),
    map(({type}) => {

      const text = type === AdministratorsActions.CREATE_ADMINISTRATOR_SUCCESS
        ? `Ein neuer Administrator wurde angelegt.`
        : 'Der Administrator wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
