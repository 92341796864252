import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TaxesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { TaxesService } from '../../services/taxes.service';

@Injectable()
export class TaxesEffects {
  CreateTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.CreateTax),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createTax(payload).pipe(
          map(response => TaxesActions.CreateTaxSuccess({ response })),
          catchError(response => of(TaxesActions.CreateTaxFail({ response })))
        );
      })
    )
  );
  ReadTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.ReadTax),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readTax(iri).pipe(
          map(response => TaxesActions.ReadTaxSuccess({ response })),
          catchError(response => of(TaxesActions.ReadTaxFail({ response })))
        );
      })
    )
  );
  ReadTaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.ReadTaxes),
      map(action => action),
      switchMap(() => {
        return this.service.readTaxes().pipe(
          map(response => TaxesActions.ReadTaxesSuccess({ response })),
          catchError(response => of(TaxesActions.ReadTaxesFail({ response })))
        );
      })
    )
  );
  UpdateTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.UpdateTax),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.updateTax(iri, payload).pipe(
          map(response => TaxesActions.UpdateTaxSuccess({ response })),
          catchError(response => of(TaxesActions.UpdateTaxFail({ response })))
        );
      })
    )
  );
  DeleteTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.DeleteTax),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteTax(iri).pipe(
          map(response => TaxesActions.DeleteTaxSuccess({ iri })),
          catchError(response => of(TaxesActions.DeleteTaxFail({ response })))
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TaxesActions.DeleteTaxFail,
          TaxesActions.UpdateTaxFail,
          TaxesActions.ReadTaxesFail,
          TaxesActions.ReadTaxFail,
          TaxesActions.CreateTaxFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: TaxesService,
    private notifierService: NotifierService
  ) {}
}
