import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import {ApplicationState} from '../../../application-state/store';
import {ErrorsObject} from '../../utilities/error-utility.utility';
import {DataRecoveryResultsActions, OrdersActions} from '../../../orders/store';
import {DataRecoveryResultsSelectors, OrdersSelectors} from '../../../orders/store/selectors';

@Component({
  selector: 'data-recovery-result-box',
  styleUrls: ['data-recovery-result-box.component.scss'],
  template: `

    <div class="grid">

      <ng-container [formGroup]="drrf">

        <div class="column-2">

          <mat-form-field>
            <mat-placeholder>Datenrettungsergebnis</mat-placeholder>
            <span matSuffix>%</span>
            <input type="number" min="1" max="100" matInput formControlName="recoveredDataPercentage">
            <mat-hint align="start"
                      *ngIf="errors?.recoveredDataPercentage">{{ errors.recoveredDataPercentage.message }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="column-12">
          <mat-form-field>
            <mat-placeholder>Kommentar</mat-placeholder>
            <textarea matInput formControlName="comment" rows="10"></textarea>
            <mat-hint align="start" *ngIf="errors?.comment">{{ errors.comment.message }}</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>

      <div class="column-14">
        <div class="m-ta--2">
          <button
            mat-flat-button color="green"
            type="button"
            [disabled]="drrf.invalid"
            (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>Datenrettungsergebnis speichern</span>
          </button>
        </div>
      </div>
    </div>

    <!--<pre>{{ analysisResultCategories$ | async | json }}</pre>-->
    <!--<pre>{{ analysisResultTemplates$ | async | json }}</pre>-->
  `
})
export class DataRecoveryResultBoxComponent implements OnInit, OnDestroy {

  @Output() requestUpdateDataRecoveryResult: EventEmitter<fromOrdersModuleModels.Order> = new EventEmitter();

  drrf: FormGroup;
  errors: ErrorsObject = {};
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  ngOnInit(): void {

    this.initForm();
    this.initSubscriptions();
  }

  initForm(): void {
    this.drrf = this.fb.group({
      recoveredDataPercentage: this.fb.control(null, Validators.required),
      comment: this.fb.control(''),
    });
  }

  initSubscriptions(): void {

    // Result could be changed from somewhere else
    this.actions$.pipe(
      ofType(DataRecoveryResultsActions.UpdateDataRecoveryResult),
      takeUntil(this.onDestroy$),
    ).subscribe(({type}) => {
      this.initForm();
      this.errors = {};
    });

    this.store$.pipe(
      select(DataRecoveryResultsSelectors.selectDataRecoveryResultErrors),
      takeUntil(this.onDestroy$)
    ).subscribe(errors => {
      this.errors = errors;
    });
  }

  handleSubmit(): void {
    this.requestUpdateDataRecoveryResult.emit(this.drrf.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
