import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'terms-and-conditions-form',
  styleUrls: ['terms-and-conditions-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'AGB bearbeiten' : 'Neue AGB anlegen' }}</span>
      </div>

      <div class="card__content">
        <form [formGroup]="tacf" (ngSubmit)="handleSubmit()" enctype="multipart/form-data">

          <div class="grid">
            <div class="column-14">
              <div class="mat-form-field">
                <mat-label>PDF-Anhang</mat-label>
                <!--<input type="file" matInput formControlName="file" required>
                <mat-icon matSuffix>upload</mat-icon>-->

                <!--<file-upload #fileUpload [control]="fileUploadControl" formControlName="file" accept="application/pdf" multiple="false" animation="true"></file-upload>-->
                <!--<input type="file"  >-->
                <mat-hint align="start" *ngIf="errors?.file">{{ errors.file.message }}</mat-hint>
              </div>
            </div>

            <div class="m-ta--2 column-14">

              <button *ngIf="presets$.getValue() || tacf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                      color="outline" mat-flat-button>
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button [disabled]="tacf.invalid || tacf.untouched" mat-flat-button color="green">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--<pre>{{ tacf.value | json }}</pre>-->
    <!--<pre>{{ uploadedFile$.getValue() | json }}</pre>-->
  `
})
export class TermsAndConditionsFormComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload: any;

  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<any>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromModuleModels.TermsAndConditionsEntity,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.TermsAndConditionsEntity,
    entity: string
  }> = new EventEmitter();

  tacf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  public readonly uploadedFile$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.tacf, preset);
      this.tacf.markAsUntouched();
    });
  }

  initForm(): void {
    this.tacf = this.fb.group({
      file: this.fb.control(null /*[FileUploadValidators.filesLimit(1)]*/)
    });

    this.tacf.get('file').valueChanges.subscribe((values: Array<File>) => {
      console.log(values);
      // console.log(this.filesControl);
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.tacf.get('file').setValue([]);

    this.presets$.next(null);
    this.errors = {};

    // this.fileUploadControl.clear();
  }

  handleStuff(res): void {
    console.log(res);
    return;
    // this.tacf.get('file').setValue();
  }

  handleSubmit(): void {
    const payload: fromModuleModels.TermsAndConditionsEntity = this.tacf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'TermsAndCondition'})
      : this.requestUpdateItem.emit({
        iri: this.presets$.getValue()['@id'],
        payload,
        entity: 'TermsAndCondition'
      });
  }
}
