import {createAction, createSelector} from '@ngrx/store';

import {ORDER_STATE_RANGES_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

const NAMESPACE = ORDER_STATE_RANGES_FEATURE_KEY;
const MODULE_STATE_SELECT = selectOrdersModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);

export const sEntities = createSelector(sState, (state) => state.entities);

export const sList = createSelector(sEntities, (entities) => Object.keys(entities).map(iri => entities[iri]));
