import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DATA_RECOVERY_PRIORITY_MODE = '[Master Data] Read Data Recovery Priority Mode';
export const READ_DATA_RECOVERY_PRIORITY_MODE_SUCCESS = '[Master Data] Read Data Recovery Priority Mode Success';
export const READ_DATA_RECOVERY_PRIORITY_MODE_FAIL = '[Master Data] Read Data Recovery Priority Mode Fail';
export const ReadDataRecoveryPriorityMode = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODE,
  props<{ iri: string }>()
);
export const ReadDataRecoveryPriorityModeSuccess = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODE_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryPriorityMode }>()
);
export const ReadDataRecoveryPriorityModeFail = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DATA_RECOVERY_PRIORITY_MODES = '[Master Data] Read Data Recovery Priority Modes';
export const READ_DATA_RECOVERY_PRIORITY_MODES_SUCCESS = '[Master Data] Read Data Recovery Priority Modes Success';
export const READ_DATA_RECOVERY_PRIORITY_MODES_FAIL = '[Master Data] Read Data Recovery Priority Modes Fail';
export const ReadDataRecoveryPriorityModes = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODES
);
export const ReadDataRecoveryPriorityModesSuccess = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODES_SUCCESS,
  props<{ response: any }>()
);
export const ReadDataRecoveryPriorityModesFail = createAction(
  READ_DATA_RECOVERY_PRIORITY_MODES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
