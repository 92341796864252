import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Create Replacement Stock Item';
export const CREATE_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Create Replacement Stock Item Success';
export const CREATE_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Create Replacement Stock Item Fail';

export const CreateReplacementStockItem = createAction(
  CREATE_REPLACEMENT_STOCK_ITEM,
  props<{ payload: fromModuleModels.ReplacementStockItem }>()
);

export const CreateReplacementStockItemSuccess = createAction(
  CREATE_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: fromModuleModels.ReplacementStockItem | any }>()
);

export const CreateReplacementStockItemFail = createAction(
  CREATE_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_REPLACEMENT_STOCK_ITEMS = '[Warehouse Module] Read Replacement Stock Items';
export const READ_REPLACEMENT_STOCK_ITEMS_SUCCESS = '[Warehouse Module] Read Replacement Stock Items Success';
export const READ_REPLACEMENT_STOCK_ITEMS_FAIL = '[Warehouse Module] Read Replacement Stock Items Fail';

export const ReadReplacementStockItems = createAction(
  READ_REPLACEMENT_STOCK_ITEMS,
  props<{ page: number, params?: any }>()
);

export const ReadReplacementStockItemsSuccess = createAction(
  READ_REPLACEMENT_STOCK_ITEMS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadReplacementStockItemsFail = createAction(
  READ_REPLACEMENT_STOCK_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Read Replacement Stock Item';
export const READ_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Read Replacement Stock Item Success';
export const READ_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Read Replacement Stock Item Fail';

export const ReadReplacementStockItem = createAction(
  READ_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string }>()
);

export const ReadReplacementStockItemSuccess = createAction(
  READ_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadReplacementStockItemFail = createAction(
  READ_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Update Replacement Stock Item';
export const UPDATE_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Update Replacement Stock Item Success';
export const UPDATE_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Update Replacement Stock Item Fail';

export const UpdateReplacementStockItem = createAction(
  UPDATE_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string, payload: fromModuleModels.ReplacementStockItem }>()
);

export const UpdateReplacementStockItemSuccess = createAction(
  UPDATE_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const UpdateReplacementStockItemFail = createAction(
  UPDATE_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Delete Replacement Stock Item';
export const DELETE_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Delete Replacement Stock Item Success';
export const DELETE_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Delete Replacement Stock Item Fail';

export const DeleteReplacementStockItem = createAction(
  DELETE_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string }>()
);

export const DeleteReplacementStockItemSuccess = createAction(
  DELETE_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteReplacementStockItemFail = createAction(
  DELETE_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
