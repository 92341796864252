import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {TicketsActions} from '../actions';
import {TicketsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {DataRecoveryCostsActions} from '../../../orders/store';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class TicketsEffects {

  constructor(private actions$: Actions, private service: TicketsService, private notifierService: NotifierService) {
  }

  ReadTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.ReadTicket),
    switchMap(({iri, id}) => {
      const obs = id ? this.service.getTicketById(id) : this.service.getTicketByIri(iri);
      return obs.pipe(
        map((response: any) => TicketsActions.ReadTicketSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketsActions.ReadTicketFail({response})))
      );
    })
  ));

  ReadTickets$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.ReadTickets),
    switchMap(({page, params}) => {
      return this.service.readTickets(page, params).pipe(
        map((response) => TicketsActions.ReadTicketsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketsActions.ReadTicketsFail({response})))
      );
    })
  ));

  ReadTicketChunk$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.ReadTicketChunk),
    map(action => action),
    switchMap(({uri}) => {
      return this.service.readTicketChunk(uri).pipe(
        map((response) => TicketsActions.ReadTicketChunkSuccess({response})),
        catchError(response => of(TicketsActions.ReadTicketChunkFail({response})))
      );
    })
  ));

  UpdateTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.UpdateTicket),
    switchMap(({iri, payload}) => {
      return this.service.updateTicket(iri, payload).pipe(
        map((response: any) => TicketsActions.UpdateTicketSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketsActions.UpdateTicketFail({response})))
      );
    })
  ));

  ProvideRemoteAccess$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.ProvideRemoteAccess),
    switchMap(({iri, payload}) => {
      return this.service.updateTicket(iri, payload).pipe(
        map((response: any) => TicketsActions.ProvideRemoteAccessSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketsActions.ProvideRemoteAccessFail({response})))
      );
    })
  ));

  DeleteTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketsActions.DeleteTicket),
    switchMap(({iri}) => {

      return this.service.deleteTicket(iri).pipe(
        map(() => TicketsActions.DeleteTicketSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(TicketsActions.DeleteTicketFail({response})))
      );
    })
  ));
  SuccessActions = createEffect(() => this.actions$.pipe(
    ofType(
      TicketsActions.UpdateTicketSuccess,
      TicketsActions.ProvideRemoteAccessSuccess,
    )
    , map(({type}) => {
      const message = 'Das Ticket wurde erfolgreich aktualisiert.';

      this.notifierService.show({type: 'success', message});

    })
  ), {dispatch: false});

}
