import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { CommissionCreditItem } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CommissionCreditItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCommissionCreditItem(
    payload: CommissionCreditItem
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_COMMISSION_CREDIT_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/commission_credit_items`, payload)
    );
  }

  readCommissionCreditItem(iri: string): Observable<CommissionCreditItem> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_COMMISSION_CREDIT_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readCommissionCreditItems(): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_COMMISSION_CREDIT_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/commission_credit_items`, true)
    );
  }

  updateCommissionCreditItem(
    iri: string,
    payload: CommissionCreditItem
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_COMMISSION_CREDIT_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCommissionCreditItem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_COMMISSION_CREDIT_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
