import { Component, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../../orders/services';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import {
  LabLocationsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import {
  LabLocationsActions,
  StorageLocationsActions,
  StorageSystemManufacturersActions,
  StorageSystemsActions
} from '../../../master-data/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { OrderShipment } from '../../../orders/models/order-shipment.interface';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { DataMedium } from '../../../warehouse/models';
import { CreateShipmentDialogComponent } from '../../../shared/components/create-shipment-dialog/create-shipment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  DataMediumsActions,
  ReplacementStockItemsActions
} from '../../../warehouse/store';
import { LabLocation } from '../../../master-data/models';
import { WarehouseRights } from '../../../shared/security/warehouseRights';

@Component({
  selector: 'app-open-external-shipments-list',
  styleUrls: ['./open-external-shipments-list.component.scss'],
  template: `
    <!--    <button (click)="showDebug=!showDebug">DEBUG</button>-->
    <div class="row  py-2" [formGroup]="form">
      <div class="col-4 pos-relative">
        Labor:
        <app-loading-overlay
          *ngIf="isLoadingLabs$ | async"
        ></app-loading-overlay>
        <mat-button-toggle-group
          class="ms-2"
          formControlName="labLocation"
          aria-label="Labor"
        >
          <ng-container *ngFor="let lab of labLocations$ | async">
            <mat-button-toggle [value]="lab['@id']" class="bold-if-checked"
              >{{ lab.name }} ({{ lab.ordersAwaitingShipment }}
              x)
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
      </div>
      <div class="col pt-4">
        <a
          (click)="resetFilter()"
          class="link-primary cursor-pointer text-color-grey text-decoration-none"
          >Filter zurücksetzen</a
        >
      </div>
      <div class="col text-right">
        <button
          mat-button
          color="green"
          *ngIf="canCreate$ | async"
          [disabled]="
            selectedDataMediaIris.length <= 0 || !form.get('labLocation').value
          "
          (click)="openShipmentDialog()"
        >
          <span *ngIf="selectedDataMediaIris.length > 0">
            {{ selectedDataMediaIris.length }}
            Datenträger versenden
          </span>
          <span *ngIf="selectedDataMediaIris.length <= 0"
            >Keine Datenträger ausgewählt</span
          >
        </button>
      </div>
    </div>
    <div class="pos-relative  " style="min-height: 300px;">
      <!--      <pre>{{selectedDataMedia|json}}</pre>-->
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <ng-container *ngIf="items$ | async as items">
        <div class="d-flex shipment-line p-3 my-2" *ngFor="let item of items">
          <div
            class="pt-2 pe-2 cursor-pointer"
            (click)="toggleSelectOrder(item)"
          >
            <mat-icon
              >{{
                stockItemSelected(item) === 'all'
                  ? 'check_box'
                  : stockItemSelected(item) === 'none'
                  ? 'check_box_outline_blank'
                  : 'indeterminate_check_box'
              }}
            </mat-icon>

            <!--            <mat-icon>{{orderIsSelected(item) ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>-->
          </div>
          <app-shipment-order-display-line
            class="flex-grow-1"
            [order]="item"
            [readonlyArchive]="true"
            [validDataMediaStates]="validDataMediaStates"
            [inputSelectedDataMedia]="selectedDataMediaIris"
            (changeSelectedDataMedia)="updateSelectedDataMedia($event)"
          ></app-shipment-order-display-line>
          <!--          <pre>{{getDataMediaOfOrder(item)|json}}</pre>-->
          <!--          <pre *ngIf="showDebug">{{item|json}}</pre>-->
        </div>
        <div
          class="row"
          *ngIf="
            (isLoading$ | async) === false && (!items || items.length <= 0)
          "
        >
          <p>Es sind keine Aufträge mit den ausgewählten Filtern verfügbar!</p>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col text-right">
        <app-paginator-unstyled
          showFirstLastButtons="true"
          [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
          [pageSize]="currentPageSetting.pageSize"
          [pageIndex]="currentPageSetting.pageIndex"
          [totalItems]="itemCount$ | async"
          (handleUpdatePageOrSize)="updatePageOrSize($event)"
        ></app-paginator-unstyled>
      </div>
    </div>
  `
})
export class OpenExternalShipmentsListComponent extends BaseOnDestroyComponent
  implements OnInit {
  showDebug = false;
  form: FormGroup;
  labLocations$: Observable<Array<LabLocation>>;
  isLoadingLabs$: Observable<boolean>;
  currentPageSetting: { pageSize: number; pageIndex: number } = {
    pageIndex: 0,
    pageSize: 15
  };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items$: BehaviorSubject<Array<OrderShipment>> = new BehaviorSubject<
    Array<OrderShipment>
  >([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedDataMedia: { [p: string]: boolean } = {};
  validDataMediaStates: Array<string> = [
    'stored_dr_de',
    'reserved',
    'waiting_for_return_shipment'
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: OrdersService,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private actions$: Actions,
    private titleService: AbstractTitleService,
    private warehouseRight: WarehouseRights
  ) {
    super();
  }

  get canCreate$(): Observable<boolean> {
    return this.warehouseRight.canCreate$;
  }

  get dataMediaIris(): Array<string> {
    const neededIris = [];
    for (const order of this.items$.getValue()) {
      this.getDataMediaOfOrder(order);
    }
    return neededIris;
  }

  get selectedDataMediaIris(): Array<string> {
    return Object.keys(this.selectedDataMedia);
  }

  dataMediaSelectionFilterFn = (dataMedia: DataMedium): boolean => {
    // console.log('dataMediaSelectionFilterFn', dataMedia);
    return this.selectedDataMediaIris.indexOf(extractIri(dataMedia)) > -1;
  };

  ngOnInit(): void {
    this.titleService.setTitle('zu versenden an Dienstleister');
    this.initForm();
    this.actions$
      .pipe(
        ofType(
          CustomerStockItemsActions.UpdateCustomerStockItemSuccess,
          CustomerStockItemsActions.CreateCustomerStockItemSuccess,
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess,
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess,
          ReplacementStockItemsActions.CreateReplacementStockItemSuccess,
          ReplacementStockItemsActions.UpdateReplacementStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loadData();
      });
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(params => {
        this.readQueryParams();
        this.loadData();
        //
      });

    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
    loadIfNotLoaded(
      this.store$,
      StorageLocationsSelectors.isLoaded,
      StorageLocationsActions.ReadStorageLocations()
    );
    this.labLocations$ = this.store$
      .select(LabLocationsSelectors.sList)
      .pipe(map(c => c.filter(e => e.external)));
    this.isLoadingLabs$ = this.store$.select(LabLocationsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  resetFilter(): void {
    this.form.patchValue({
      labLocation: '/api/lab_locations/LAB_LOCATION_DR_EXT_DHE'
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      labLocation: this.fb.control('/api/lab_locations/LAB_LOCATION_DR_EXT_DHE')
    });
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(values => {
        this.updateQueryParams({ ...values, page: 0 }, true);
      });
  }

  updatePageOrSize($event: any): void {
    this.currentPageSetting = { ...this.currentPageSetting, ...$event };
    this.updateQueryParams({
      pageSize: $event.pageSize,
      page: $event.pageIndex + 1
    });
  }

  readQueryParams(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    for (const key of ['labLocation']) {
      if (!!params[key] && this.form.get(key).value !== params[key]) {
        this.form
          .get(key)
          .setValue(params[key], { onlySelf: true, emitEvent: false });
      }
    }
    if (params.pageSize) {
      this.currentPageSetting.pageSize = params.pageSize;
    }
    if (params.page) {
      this.currentPageSetting.pageIndex = params.page + 1;
    }
  }

  updateQueryParams(
    values: { [key: string]: string },
    alwaysLoad = false
  ): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = { ...currentQuery };
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {
      this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }

  getDataMediaOfOrder(order: OrderShipment): Array<string> {
    const dataMedia = [];
    for (const orderItem of order.productOrderItems) {
      dataMedia.push(extractIri(orderItem.replacementDataMedium));
    }
    for (const customerStockItem of order.customerStockItems) {
      if (customerStockItem.enclosure) {
        dataMedia.push(extractIri(customerStockItem.enclosure));
      }
      for (const dm of customerStockItem.dataMediums) {
        dataMedia.push(extractIri(dm));
      }
    }
    return dataMedia;
  }

  stockItemSelected(order: OrderShipment): string {
    if (this.getDataMediaOfOrder(order).length <= 0) {
      return 'none';
    }
    if (
      this.getDataMediaOfOrder(order).every(e => !!this.selectedDataMedia[e])
    ) {
      return 'all';
    } else if (
      this.getDataMediaOfOrder(order).some(e => !!this.selectedDataMedia[e])
    ) {
      return 'partial';
    } else {
      return 'none';
    }
  }

  toggleSelectOrder(order): void {
    const oldList = { ...this.selectedDataMedia };
    const dataMedia = this.getDataMediaOfOrder(order);
    // console.log(dataMedia);
    if (this.stockItemSelected(order) === 'all') {
      for (const media of dataMedia) {
        delete oldList[extractIri(media)];
      }
    } else {
      for (const media of dataMedia) {
        oldList[extractIri(media)] = true;
      }
    }
    this.selectedDataMedia = { ...oldList };
  }

  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const page =
      parseInt(queryParams.page ? queryParams.page : 0, 10);
    const params: any = {
      itemsPerPage: queryParams.pageSize ? queryParams.pageSize : 15,
      pagination: true,
      'order[customer.customerType.id]': 'asc',
      'order[netTotal.value]': 'desc',
      'awaitingShipmentDirection.id': 'SHIPMENT_DIRECTION_DR_DE_TO_DR_EXT'
    };

    if (queryParams.labLocation) {
      params['awaitingShipmentLabLocation.id'] = getUuidFromIri(
        queryParams.labLocation
      );
    } else {
      params['awaitingShipmentLabLocation.id'] = getUuidFromIri(
        this.form.get('labLocation').value
      );
    }

    this.backendService.readAwaitingShipmentOrders(page, params).subscribe(
      data => {
        this.triggerActions(data['hydra:member']);
        this.items$.next(data['hydra:member']);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }

        this.isLoading$.next(false);
      },
      error => {
        console.log(error);
        this.isLoading$.next(false);
      }
    );
  }

  triggerActions(list: Array<OrderShipment>): void {
    for (const order of list) {
      for (const stockItem of order.customerStockItems) {
        for (const dataMedia of [
          ...stockItem.dataMediums,
          stockItem.enclosure
        ]) {
          if (dataMedia) {
            this.store$.dispatch(
              DataMediumsActions.ReadDataMediumSuccess({
                response: { ...dataMedia }
              })
            );
          }
        }
      }
    }
  }

  updateSelectedDataMedia($event: {
    selected: Array<string>;
    unselected: Array<string>;
  }): void {
    // console.log('updateSelectedDataMedia', $event);
    for (const unselectIri of $event.unselected) {
      if (this.selectedDataMedia[unselectIri]) {
        delete this.selectedDataMedia[unselectIri];
      }
    }
    for (const selectedIri of $event.selected) {
      if (!this.selectedDataMedia[selectedIri]) {
        this.selectedDataMedia[selectedIri] = true;
      }
    }
  }

  openShipmentDialog(): void {
    this.dialog
      .open(CreateShipmentDialogComponent, {
        panelClass: 'width-95',
        data: {
          shipTo: this.form.get('labLocation').value,
          shipment: {
            dataMedia: this.selectedDataMediaIris,
            shipmentDirection:
              '/api/shipment_directions/SHIPMENT_DIRECTION_DR_DE_TO_DR_EXT'
          }
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value === true) {
          this.loadData();
        }
      });
  }
}
