import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrdersSelectors} from '../../store/selectors';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {Order} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {MatDialogRef} from '@angular/material/dialog';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {CustomerContactsSelectors, CustomersSelectors} from '../../../customers/store/selectors';
import {takeUntil} from 'rxjs/operators';
import {CustomerContactsActions, CustomersActions} from '../../../customers/store';
import * as OrdersModuleActions from '../../store';
import {Customer} from '../../../customers/models';
import {PageEvent} from '@angular/material/paginator';
import * as moment from 'moment/moment';
import {sortByCreatedAtDate} from '../../../shared/utilities/array.utility';

@Component({
  selector: 'app-order-select-dialog',
  templateUrl: './order-select-dialog.component.html',
  styleUrls: ['./order-select-dialog.component.scss']
})
export class OrderSelectDialogComponent implements OnInit, OnDestroy {

  orders$: Observable<Array<Order>>;
  activeFilter: { [p: string]: string } = {};
  filterString = '';
  lowValue = 0;
  highValue = 15;
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean>;
  customerEntities: { [p: string]: Customer } = {};
  onDestroy$: Subject<any> = new Subject<any>();
  private loadedCustomerContactsUUIDs: Array<string> = [];


  constructor(private store$: Store<ApplicationState>, public dialogRef: MatDialogRef<OrderSelectDialogComponent>) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(CustomerContactsSelectors.isLoading)
    ]);

    this.orders$ = this.store$.select(OrdersSelectors.sList);


    this.store$.dispatch(OrdersModuleActions.OrdersActions.ReadOrders({page: -1}));

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }


  handleSearchInput(term: string): void {
    this.filterString = term;
  }

  handleUpdateFilter(filters: { [p: string]: string }): void {
    this.activeFilter = filters;
  }

  sortOrders(toBeSortedOrders: Order[]): Order[] {
    return toBeSortedOrders.sort(sortByCreatedAtDate);
  }

  filterOrders(toBeFilteredOrders: Order[]): Order[] {
    const filter = this.activeFilter;
    const filterString = this.filterString;
    return toBeFilteredOrders.filter(e => {
      let match = true;


      if (!filter && !filterString) {
        return match;
      }
      if (filterString !== '') {
        const parts = filterString.split(' ').map(f => f.toLowerCase());
        match = parts.every(t => {
          const customer = e.customer;
          // Auftragsnummer,
          // Name,
          // Firma,
          // Datenträger
          // Seriennummer
          // Ticket
          // Auftragsverlauf
          return ((e.orderNumber + '').toLowerCase().indexOf(t) > -1) ||
            (customer?.nameLine1?.toLowerCase().indexOf(t) > -1) ||
            (customer?.nameLine2?.toLowerCase().indexOf(t) > -1) ||
            (e.storageManufacturerSerialNumber && (e.storageManufacturerSerialNumber + '').toLowerCase().indexOf(t) > -1) ||
            (e.storageManufacturerModel && (e.storageManufacturerModel + '').toLowerCase().indexOf(t) > -1) ||
            ((typeof e.storageSystem !== 'string') && (e.storageSystem.name + '').toLowerCase().indexOf(t) > -1) ||
            (e.storageSystemManufacturer && (e.storageSystemManufacturer + '').toLowerCase().indexOf(t) > -1) ||
            (e.ticket && (typeof e.ticket !== 'string' ? e.ticket.ticketNumber + '' : e.ticket).toLowerCase().indexOf(t) > -1);
        });
      }
      if (filter && filter.salesperson && e.salesperson["@id"] !== filter.salesperson) {
        match = false;
      }
      if (filter && filter.status && !e.state[filter.status]) {
        match = false;
      }
      if (filter && (filter.partnerStatus || filter.customerCooperations)) {

        const customer = e.customer;
        if (!customer) {
          match = false;
        } else {
          if (filter.partnerStatus && customer.partnerStatus !== filter.partnerStatus) {
            match = false;
          }
          if (filter.customerCooperations && customer.customerCooperations.indexOf(filter.customerCooperations) <= -1) {
            match = false;
          }
        }
      }

      if (filter && filter.dataRecoveryLocation && e.analysisLocation !== filter.dataRecoveryLocation
        && e.dataRecoveryLocation !== filter.dataRecoveryLocation) {
        match = false;
      }
      if (filter && filter.technician && e.technician !== filter.technician) {
        match = false;
      }
      if (filter && filter.broker && e.broker !== filter.broker) {
        match = false;
      }
      if (filter && (filter.createdAtMin || filter.createdAtMax)) {
        const createdAt = moment(e.createdAt);
        if (filter.createdAtMin && !moment(filter.createdAtMin, 'YYYY-MM-DD').isSameOrBefore(createdAt)) {
          match = false;
        }
        if (filter.createdAtMax && !moment(filter.createdAtMax, 'YYYY-MM-DD').isSameOrAfter(createdAt)) {
          match = false;
        }
      }
      return match;
    });
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
  }

  handleListClick(order: Order): void {
    this.dialogRef.close(order['@id']);

  }

  resetAndClose(): void {
    this.dialogRef.close(null);
  }
}
