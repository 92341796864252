import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/containers';
import { PageNotFoundComponent } from './page-not-found.component';
import { IsAuthenticatedGuard } from './auth/guards';
import {
  BrokersViewComponent,
  CancellationInvoicesViewComponent,
  CancellationInvoiceViewComponent,
  CommissionCreditsViewComponent,
  CommissionCreditViewComponent,
  CorrectionInvoicesViewComponent,
  CorrectionInvoiceViewComponent,
  InvoicesViewComponent,
  OffersViewComponent,
  OfferViewComponent,
  PayableInvoicesViewComponent,
  PayableInvoiceViewComponent
} from './invoices/containers';
import { IsInGroupGuard } from './auth/guards/is-in-group-guard.service';
import {
  LoginScreenComponent,
  ResetPasswordScreenComponent
} from './auth/containers';
import { AdministratorsViewComponent } from './administrators/containers';
import {
  CustomersViewComponent,
  CustomerViewComponent
} from './customers/containers';
import {
  adminRoles,
  allRoles,
  customerRoles,
  fakturaRoles,
  leadRoles,
  orderRoles,
  salesRoles,
  ticketRoles,
  warehouseRoles
} from './accessRoles';
import {
  CustomerAccountInvitationsLoadedGuard,
  CustomerAccountsLoadedGuard,
  CustomerCommentsLoadedGuard,
  CustomerCommentTagsLoadedGuard,
  CustomerContactTypesLoadedGuard,
  CustomerLoadedGuard,
  CustomerPartnerStatusesLoadedGuard,
  CustomerTypesLoadedGuard,
  PartnerWebsiteInvitationsLoadedGuard,
  PartnerWebsitesLoadedGuard
} from './customers/guards';
import {
  AddressTypesLoadedGuard,
  CountriesLoadedGuard,
  CustomerCooperationsLoadedGuard,
  DataRecoveryPriorityModesLoadedGuard,
  DepartmentsLoadedGuard,
  GradesLoadedGuard,
  SalutationsLoadedGuard
} from './master-data/guards';
import {
  LeadsArchiveComponent,
  LeadsViewComponent,
  LeadViewComponent
} from './leads/containers';
import { MasterDataViewComponent } from './master-data/containers';
import {
  OrderStateRangesLoadedGuard,
  SalesLoadedGuard,
  SalesSummaryLoadedGuard
} from './orders/guards';
import { AdministratorsLoadedGuard } from './administrators/guards';
import {
  OrdersViewComponent,
  OrderViewComponent,
  SalesViewComponent
} from './orders/containers';
import { WarehouseViewComponent } from './warehouse/containers';
import {
  TicketsViewComponent,
  TicketViewComponent
} from './tickets/containers';
import { PayableInvoiceNewComponent } from './invoices/containers/payable-invoice-new/payable-invoice-new.component';
import { WarehouseCustomerStockItemsViewComponent } from './warehouse/containers/warehouse-customer-stock-items-view/warehouse-customer-stock-items-view.component';
import { WarehouseCustomerReplacementDataMediaViewComponent } from './warehouse/containers/warehouse-customer-replacement-data-media-view/warehouse-customer-replacement-data-media-view.component';
import { WarehouseArchiveViewComponent } from './warehouse/containers/warehouse-archive-view/warehouse-archive-view.component';
import { WarehouseReplacementDataMediaViewComponent } from './warehouse/containers/warehouse-replacement-data-media-view/warehouse-replacement-data-media-view.component';
import { OpenOrderShipmentsListComponent } from './shipping/containers/open-order-shipments-list/open-order-shipments-list.component';
import { OpenExternalShipmentsListComponent } from './shipping/containers/open-external-shipments-list/open-external-shipments-list.component';
import { CurrentShipmentsListComponent } from './shipping/containers/current-shipments-list/current-shipments-list.component';
import { OldShipmentsListComponent } from './shipping/containers/old-shipments-list/old-shipments-list.component';
import { ShipmentsViewComponent } from './shipping/containers/shipments-view/shipments-view.component';
import { OfferNewComponent } from './invoices/containers/offer-new/offer-new.component';
import { CorrectionInvoiceNewComponent } from './invoices/containers/correction-invoice-new/correction-invoice-new.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // {
  //   path: 'invitations/confirmation/:accountType/:token',
  //   component: InvitationConfirmationScreenComponent
  // },
  {
    path: 'resetting/reset/:lang/:token',
    component: ResetPasswordScreenComponent
  },
  {
    path: 'login',
    component: LoginScreenComponent
  },
  {
    path: 'customers',
    component: CustomersViewComponent,
    canActivate: [
      CustomerContactTypesLoadedGuard,
      CustomerPartnerStatusesLoadedGuard,
      CustomerTypesLoadedGuard,
      CustomerCooperationsLoadedGuard,
      IsInGroupGuard
    ],
    data: { neededRole: customerRoles }
  },
  {
    path: 'customers/:uuid',
    component: CustomerViewComponent,
    canActivate: [
      AdministratorsLoadedGuard,
      CustomerAccountInvitationsLoadedGuard,
      CustomerAccountsLoadedGuard,
      CustomerCommentTagsLoadedGuard,
      CustomerCommentsLoadedGuard,
      CustomerContactTypesLoadedGuard,
      CustomerLoadedGuard,
      CustomerPartnerStatusesLoadedGuard,
      CustomerTypesLoadedGuard,
      PartnerWebsiteInvitationsLoadedGuard,
      PartnerWebsitesLoadedGuard,
      AddressTypesLoadedGuard,
      CountriesLoadedGuard,
      CustomerCooperationsLoadedGuard,
      DepartmentsLoadedGuard,
      GradesLoadedGuard,
      SalutationsLoadedGuard,
      IsInGroupGuard
    ],
    data: { neededRole: customerRoles }
    // children: [
    //   {path: '', component: CustomerTabGeneralComponent},
    //   {path: 'contacts', component: CancellationInvoicesViewComponent},
    //   {path: 'addresses', component: OffersViewComponent},
    //   {path: 'tickets', component: CorrectionInvoicesViewComponent},
    //   {path: 'orders', component: BrokersViewComponent},
    //   {path: 'invoices', component: CommissionCreditsViewComponent},
    //   {path: 'offers', component: CommissionCreditsViewComponent}
    // ]
  },
  {
    path: 'orders',
    component: OrdersViewComponent,
    canActivate: [
      OrderStateRangesLoadedGuard,IsInGroupGuard],
    data: { neededRole: orderRoles }
  },
  {
    path: 'orders/:uuid',
    component: OrderViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: orderRoles }
  },
  {
    path: 'tickets',
    component: TicketsViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: ticketRoles }
  },
  {
    path: 'tickets/:uuid',
    component: TicketViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: ticketRoles }
  },
  {
    path: 'leads',
    component: LeadsViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: leadRoles }
  },
  {
    path: 'leads/new',
    component: LeadViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: leadRoles }
  },
  {
    path: 'leads/archive',
    component: LeadsArchiveComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: leadRoles }
  },
  {
    path: 'leads/archive/:uuid',
    component: LeadViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: leadRoles }
  },
  {
    path: 'leads/:uuid',
    component: LeadViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: leadRoles }
  },
  {
    path: 'invoices',
    component: InvoicesViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles },
    children: [
      { path: '', component: PayableInvoicesViewComponent },
      { path: 'cancellation', component: CancellationInvoicesViewComponent },
      { path: 'offers', component: OffersViewComponent },
      { path: 'corrections', component: CorrectionInvoicesViewComponent },
      { path: 'brokers', component: BrokersViewComponent },
      { path: 'commission-credits', component: CommissionCreditsViewComponent }
    ]
  },
  {
    path: 'invoices/payable/new',
    component: PayableInvoiceNewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/payable/:type/:uuid',
    component: PayableInvoiceViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/corrections/new',
    component: CorrectionInvoiceNewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/corrections/:uuid',
    component: CorrectionInvoiceViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/cancellation/:uuid',
    component: CancellationInvoiceViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/offers/new',
    component: OfferNewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/offers/:uuid',
    component: OfferViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/commission-credits/new',
    component: CommissionCreditViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'invoices/commission-credits/:uuid',
    component: CommissionCreditViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: fakturaRoles }
  },
  {
    path: 'warehouse',
    component: WarehouseViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: warehouseRoles },
    children: [
      { path: '', component: WarehouseCustomerStockItemsViewComponent },
      {
        path: 'customer_replacement_data_media',
        component: WarehouseCustomerReplacementDataMediaViewComponent
      },
      {
        path: 'replacement_data_media',
        component: WarehouseReplacementDataMediaViewComponent
      },
      { path: 'archive', component: WarehouseArchiveViewComponent }
    ]
  },
  {
    path: 'warehouse/add-replacement-hardware-dialog',
    component: WarehouseViewComponent,
    canActivate: [IsInGroupGuard],
    data: {
      neededRole: warehouseRoles,
      callFunctionOnInit: 'openAddReplacementHardwareDialog'
    }
  },
  {
    path: 'warehouse/add-customer-hardware-dialog',
    component: WarehouseViewComponent,
    canActivate: [IsInGroupGuard],
    data: {
      neededRole: warehouseRoles,
      callFunctionOnInit: 'openAddCustomerHardwareDialog'
    }
  },
  /*
  * {
    path: 'warehouse',
    component: WarehouseViewComponent,
    canActivate: [IsInGroupGuard],
    data: {neededRole: warehouseRoles},
    children: [
      {path: '', component: WarehouseCustomerStockItemsViewComponent},
      {path: 'customer_replacement_data_media', component: WarehouseCustomerReplacementDataMediaViewComponent},
      {path: 'replacement_data_media', component: WarehouseReplacementDataMediaViewComponent},
      {path: 'archive', component: WarehouseArchiveViewComponent},
    ]

  },*/
  {
    path: 'shipments',
    component: ShipmentsViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: warehouseRoles },
    children: [
      { path: '', component: OpenOrderShipmentsListComponent },
      {
        path: 'external_shipments',
        component: OpenExternalShipmentsListComponent
      },
      { path: 'list_current', component: CurrentShipmentsListComponent },
      { path: 'list_old', component: OldShipmentsListComponent }
    ]
  },
  {
    path: 'sales',
    component: SalesViewComponent,
    canActivate: [
      OrderStateRangesLoadedGuard,
      AdministratorsLoadedGuard,
      DataRecoveryPriorityModesLoadedGuard,
      SalesLoadedGuard,
      SalesSummaryLoadedGuard,
      IsInGroupGuard
    ],
    data: { neededRole: salesRoles }
  },
  {
    path: 'administrators',
    component: AdministratorsViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: adminRoles }
  },
  {
    path: 'master-data',
    component: MasterDataViewComponent,
    canActivate: [IsInGroupGuard],
    data: { neededRole: adminRoles }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [IsAuthenticatedGuard],
    data: { neededRole: allRoles },
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
