import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Actions, ofType} from '@ngrx/effects';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';

import {ApplicationState} from '../../../application-state/store';
import {DataRecoveryCosts} from 'src/app/orders/models';
import {DataRecoveryCostsActions} from '../../../orders/store';
import {DataRecoveryCostsSelectors} from '../../../orders/store/selectors';
import {ErrorsObject} from '../../utilities/error-utility.utility';

@Component({
  selector: 'price-box',
  styleUrls: ['price-box.component.scss'],
  template: `

    <form class="grid" [formGroup]="drc">

      <div class="column-3 grid">

        <mat-form-field class="column-14" [formGroupName]="priceType + 'PriceInitial'">
          <mat-placeholder>Preis Initialkosten</mat-placeholder>
          <input formControlName="value" type="text" matInput required currencyMask [options]="{ prefix: ''}">
          <mat-hint align="start"
                    *ngIf="errors[priceType + 'PriceInitial' + '.value']">{{ errors[priceType + 'PriceInitial' + '.value'].message }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="column-14" [formGroupName]="priceType + 'PriceDataRecovery'">
          <mat-placeholder>Preis Datenrettung</mat-placeholder>
          <input formControlName="value" type="text" matInput required currencyMask [options]="{ prefix: ''}">
          <mat-hint align="start"
                    *ngIf="errors[priceType + 'PriceDataRecovery' + '.value']">{{ errors[priceType + 'PriceDataRecovery' + '.value'].message }}</mat-hint>
        </mat-form-field>

        <div class="column-14" [formGroupName]="priceType + 'PriceOther'">
          <mat-form-field>
            <mat-placeholder>Preis Sonstiges</mat-placeholder>
            <input formControlName="value" type="text" matInput required currencyMask [options]="{ prefix: ''}">
            <mat-hint align="start"
                      *ngIf="errors[priceType + 'PriceOther' + '.value']">{{ errors[priceType + 'PriceOther' + '.value'].message }}</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="column-11 grid">
        <div class="column-14">
          <mat-form-field>
            <mat-placeholder>Bemerkung</mat-placeholder>
            <textarea matInput [formControlName]="priceType+'Comment'" rows="10"></textarea>
            <mat-hint align="start" *ngIf="errors?.comment">{{ errors.comment.message }}</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="column-14">
        <div class="m-ta--2">
          <button
            mat-flat-button color="green"
            type="button"
            [disabled]="drc.invalid"
            (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>Preis definieren</span>
          </button>
        </div>
      </div>
    </form>
  `
})
export class PriceBoxComponent implements OnInit, OnDestroy {

  @Input() priceType: 'selling' | 'purchase';

  @Output() requestUpdateDataRecoveryCosts: EventEmitter<DataRecoveryCosts> = new EventEmitter();

  drc: FormGroup;
  errors: ErrorsObject = {};
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  ngOnInit(): void {

    this.initForm();
    this.initSubscriptions();
  }

  initForm(): void {
    this.drc = this.fb.group({
      [`${this.priceType}PriceInitial`]: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`${this.priceType}PriceDataRecovery`]: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      [`${this.priceType}PriceOther`]: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      comment: this.fb.control(''),
    });
  }

  initSubscriptions(): void {
    this.actions$.pipe(
      ofType(
        DataRecoveryCostsActions.UpdatePurchasePricesSuccess,
        DataRecoveryCostsActions.UpdateSellingPricesSuccess
      ),
      takeUntil(this.onDestroy$),
    ).subscribe(({type}) => {
      this.initForm();
      this.errors = {};
    });

    this.store$.pipe(
      select(DataRecoveryCostsSelectors.selectDataRecoveryCostsErrors),
      takeUntil(this.onDestroy$)
    ).subscribe(errors => {

      this.errors = errors;
    });
  }

  handleSubmit(): void {

    const payload = {...this.drc.value};

    // Cast amounts to strings as per API
    payload[`${this.priceType}PriceInitial`].value = this.drc.get(`${this.priceType}PriceInitial.value`).value.toString();
    payload[`${this.priceType}PriceDataRecovery`].value = this.drc.get(`${this.priceType}PriceDataRecovery.value`).value.toString();
    payload[`${this.priceType}PriceOther`].value = this.drc.get(`${this.priceType}PriceOther.value`).value.toString();

    this.requestUpdateDataRecoveryCosts.emit(payload);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
