import {NgModule} from '@angular/core';


import {SharedModule} from '../shared/shared.module';
import {DashboardComponent} from './containers';
import {FollowUpCalendarComponent} from './components';
import {
  FollowUpCreateAndUpdateDialogComponent
} from './components/follow-up-create-and-update-dialog/follow-up-create-and-update-dialog.component';
import {FollowUpReasonsService} from './services';
import {FollowUpsService} from './services';
import {FollowUpCardComponent} from './components/follow-up-card/follow-up-card.component';
import {InvoicesModule} from '../invoices/invoices.module';
import {NotificationsModule} from '../notifications/notifications.module';
import {OrdersModule} from '../orders/orders.module';
import {LeadsModule} from '../leads/leads.module';
import {CustomersModule} from "../customers/customers.module";
import {WarehouseModule} from "../warehouse/warehouse.module";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AdministratorsModule} from "../administrators/administrators.module";


@NgModule({
    imports: [
        SharedModule,
        InvoicesModule,
        NotificationsModule,
        OrdersModule,
        LeadsModule,
        CustomersModule,
        WarehouseModule,
        FormsModule,
        TranslateModule,
        AdministratorsModule
    ],
  declarations: [
    DashboardComponent,
    FollowUpCalendarComponent,
    FollowUpCreateAndUpdateDialogComponent,
    FollowUpCardComponent,
  ],
  exports: [
    DashboardComponent,
    FollowUpCalendarComponent,
  ],
  providers: [
    FollowUpReasonsService,
    FollowUpsService
  ]
})
export class DashboardModule {
}
