<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header" style="align-content: space-between">
      <span>Vermittelte Anfragen</span>
    </div>
    <div class="widget_content" style="position: relative;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <table class="bmo-table bmo-table-hover table-dense bmo-table-clickable">
        <tbody>
        <tr *ngFor="let lead of leads$|async| slice: lowValue : highValue">
          <td class="p-l--32" style="width: 50px; position: relative;">
            <a
              [routerLink]="['/leads', extractUUID(lead)]"
              class="stretched-link"
            ></a>
            <span *ngIf="isExpress(lead)"
                  class="badge--express"><span>Express</span></span>
            <div [style.backgroundColor]="getCircleColor(lead)" class="circle"></div>
          </td>
          <td class="pos-relative">
            <a
              [routerLink]="['/leads', extractUUID(lead)]"
              class="stretched-link"
            ></a>
            <span *ngIf="lead.vip" class="badge--vip inline small">VIP</span>
            <span> {{ getCustomerName(lead) }} </span>
          </td>
          <td class="pos-relative">
            <a
              [routerLink]="['/leads', extractUUID(lead)]"
              class="stretched-link"
            ></a>
            <span> {{ getCompanyName(lead) }} </span>
          </td>
          <!--
          <td>
            <pre>{{lead|json}}</pre>
          </td>-->
          <td class="pos-relative" style="width: 170px;">
            <a
              [routerLink]="['/leads', extractUUID(lead)]"
              class="stretched-link"
            ></a>
            <span
              [matTooltip]="(lead.createdAt|date:'dd.MM.YYYY - HH:mm') +'Uhr'"
              matTooltipPosition="above"
            >{{ lead.createdAt|momentDuration }}
            </span>
          </td>
        </tr>
        <tr *ngIf="(leads$|async)?.length<=0">
          <td class="text-center">
            <span>Keine Anfragen vorhanden</span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="widget_footer">
      <app-paginator-unstyled
        (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
        [pageSizeOptions]="[5,10,15,20]"
        [pageSize]="5"
        [showFirstLastButtons]="true"
        [totalItems]="(leads$|async)?.length"
      ></app-paginator-unstyled>

    </div>

  </div>
</div>
