import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { EnclosureDataMedium } from '../models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class EnclosureDataMediumsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createEnclosureDataMedium(
    payload: EnclosureDataMedium
  ): Observable<EnclosureDataMedium> {
    return this.rolesService.userHasRoleFilter<EnclosureDataMedium>(
      'ROLE_MWS_ENCLOSURE_DATA_MEDIUM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/enclosure_data_media`, payload)
    );
  }

  getEnclosureDataMedium(iri: string): Observable<EnclosureDataMedium> {
    return this.rolesService.userHasRoleFilter<EnclosureDataMedium>(
      'ROLE_MWS_ENCLOSURE_DATA_MEDIUM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  update(
    iri: string,
    payload: EnclosureDataMedium
  ): Observable<EnclosureDataMedium> {
    return this.rolesService.userHasRoleFilter<EnclosureDataMedium>(
      'ROLE_MWS_ENCLOSURE_DATA_MEDIUM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteEnclosureDataMedium(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
