import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DISPOSAL_TYPE = '[Master Data] Read Disposal Type Mode';
export const READ_DISPOSAL_TYPE_SUCCESS = '[Master Data] Read Disposal Type Success';
export const READ_DISPOSAL_TYPE_FAIL = '[Master Data] Read Disposal Type Fail';
export const ReadDisposalType = createAction(
  READ_DISPOSAL_TYPE,
  props<{ iri: string }>()
);
export const ReadDisposalTypeSuccess = createAction(
  READ_DISPOSAL_TYPE_SUCCESS,
  props<{ response: fromModuleModels.DisposalType }>()
);
export const ReadDisposalTypeFail = createAction(
  READ_DISPOSAL_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DISPOSAL_TYPES = '[Master Data] Read Disposal Types';
export const READ_DISPOSAL_TYPES_SUCCESS = '[Master Data] Read Disposal Types Success';
export const READ_DISPOSAL_TYPES_FAIL = '[Master Data] Read Disposal Types Fail';
export const ReadDisposalTypes = createAction(
  READ_DISPOSAL_TYPES
);
export const ReadDisposalTypesSuccess = createAction(
  READ_DISPOSAL_TYPES_SUCCESS,
  props<{ response: any }>()
);
export const ReadDisposalTypesFail = createAction(
  READ_DISPOSAL_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
