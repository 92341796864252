import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';


export const generateBaseSelectors = (MODULE_STATE_SELECT, NAMESPACE: string):
  {
    isLoading: MemoizedSelector<unknown, boolean>;
    sById: (id) => MemoizedSelector<unknown, any>;
    sByIri: MemoizedSelectorWithProps<unknown, { readonly iri?: any }, any>;
    sEntities: MemoizedSelector<unknown, any>;
    sList: MemoizedSelector<unknown, any[]>;
    sState: MemoizedSelector<unknown, any>;
    isLoaded: MemoizedSelector<unknown, boolean>
  } => {
  const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
  const isLoading = createSelector(sState, (s) => s.loading);
  const isLoaded = createSelector(sState, (s) => s.loaded);
  const sEntities = createSelector(sState, (state) => state.entities);
  const sList = createSelector(sEntities, (entities) => Object.values(entities));
  const sById = (id) => createSelector(sList, (entities) => entities.find(e => e['@id'].indexOf(id) > -1));
  const sByIri = createSelector(sEntities, (entities, {iri}) => entities[iri]);
  return {sState, isLoaded, isLoading, sEntities, sList, sById, sByIri};
};
