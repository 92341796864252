
// Every time the url changes in any way, this function is called. So we get the new state representation of where we
// are in the app all the time
import { Injectable } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateUrl } from '../store';

@Injectable()
export class RouterSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {

    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) {

      state = state.firstChild;
    }

    const params = state.params;

    return { url, queryParams, params };
  }
}
