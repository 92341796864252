import {createSelector} from '@ngrx/store';

import {CUSTOMER_STOCK_ITEMS_FEATURE_KEY, selectWarehouseModuleState} from '../reducers';

export const selectCustomerStockItemsState = createSelector(
  selectWarehouseModuleState,
  (moduleState) => moduleState[CUSTOMER_STOCK_ITEMS_FEATURE_KEY]
);

export const selectCustomerStockItemsEntities = createSelector(
  selectCustomerStockItemsState,
  (state) => state.entities
);

export const selectCustomerStockItems = createSelector(
  selectCustomerStockItemsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectCustomerStockItemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectCustomerStockItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerStockItemsState,
  (state) => state.loaded
);
