import {createSelector} from '@ngrx/store';

import {INVOICE_ITEMS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectInvoiceItemsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[INVOICE_ITEMS_FEATURE_KEY]
);

export const selectInvoiceItemsEntities = createSelector(
  selectInvoiceItemsState,
  (state) => state.entities
);

export const selectInvoiceItems = createSelector(
  selectInvoiceItemsEntities,
  (payments) => Object.keys(payments).map(iri => payments[iri])
);

export const selectInvoiceItemByIndex = createSelector(
  selectInvoiceItemsEntities,
  (customers, props) => customers[props.iri]
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectInvoiceItemsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectInvoiceItemsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectInvoiceItemsState,
  (state) => state.errors
);
