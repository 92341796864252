import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';
import {CREATE_DEPARTMENT_LOGO_STATUS} from "./department-logos.actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_SPECIAL_AGREEMENT = '[Master Data] Create Special Agreement';
export const CREATE_SPECIAL_AGREEMENT_SUCCESS = '[Master Data] Create Special Agreement Success';
export const CREATE_SPECIAL_AGREEMENT_FAIL = '[Master Data] Create Special Agreement Fail';
export const CreateSpecialAgreement = createAction(
  CREATE_SPECIAL_AGREEMENT,
  props<{ payload: fromModuleModels.SpecialAgreement }>()
);
export const CreateSpecialAgreementSuccess = createAction(
  CREATE_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: fromModuleModels.SpecialAgreement }>()
);
export const CreateSpecialAgreementFail = createAction(
  CREATE_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SPECIAL_AGREEMENT = '[Master Data] Read Special Agreement';
export const READ_SPECIAL_AGREEMENT_SUCCESS = '[Master Data] Read Special Agreement Success';
export const READ_SPECIAL_AGREEMENT_FAIL = '[Master Data] Read Special Agreement Fail';
export const ReadSpecialAgreement = createAction(
  READ_SPECIAL_AGREEMENT,
  props<{ iri: string }>()
);
export const ReadSpecialAgreementSuccess = createAction(
  READ_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadSpecialAgreementFail = createAction(
  READ_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SPECIAL_AGREEMENTS = '[Master Data] Read Special Agreements';
export const READ_SPECIAL_AGREEMENTS_SUCCESS = '[Master Data] Read Special Agreements Success';
export const READ_SPECIAL_AGREEMENTS_FAIL = '[Master Data] Read Special Agreements Fail';
export const ReadSpecialAgreements = createAction(
  READ_SPECIAL_AGREEMENTS
);
export const ReadSpecialAgreementsSuccess = createAction(
  READ_SPECIAL_AGREEMENTS_SUCCESS,
  props<{ response: any }>()
);
export const ReadSpecialAgreementsFail = createAction(
  READ_SPECIAL_AGREEMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_SPECIAL_AGREEMENT = '[Master Data] Update Special Agreement';
export const UPDATE_SPECIAL_AGREEMENT_SUCCESS = '[Master Data] Update Special Agreement Success';
export const UPDATE_SPECIAL_AGREEMENT_FAIL = '[Master Data] Update Special Agreement Fail';
export const UpdateSpecialAgreement = createAction(
  UPDATE_SPECIAL_AGREEMENT,
  props<{ iri: string, payload: fromModuleModels.SpecialAgreement }>()
);
export const UpdateSpecialAgreementSuccess = createAction(
  UPDATE_SPECIAL_AGREEMENT_SUCCESS,
  props<{ response: fromModuleModels.SpecialAgreement }>()
);
export const UpdateSpecialAgreementFail = createAction(
  UPDATE_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_SPECIAL_AGREEMENT = '[Master Data] Delete Special Agreement';
export const DELETE_SPECIAL_AGREEMENT_SUCCESS = '[Master Data] Delete Special Agreement Success';
export const DELETE_SPECIAL_AGREEMENT_FAIL = '[Master Data] Delete Special Agreement Fail';
export const DeleteSpecialAgreement = createAction(
  DELETE_SPECIAL_AGREEMENT,
  props<{ iri: string }>()
);
export const DeleteSpecialAgreementSuccess = createAction(
  DELETE_SPECIAL_AGREEMENT_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteSpecialAgreementFail = createAction(
  DELETE_SPECIAL_AGREEMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
