import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { OrdersMail } from '../models/orders-mail.interface';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { BindingOrderEmail, Order, OrderState } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class OrdersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getOrderById(id: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/orders/${id}`, false)
    );
  }

  getOrderByIri(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readOrders(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/orders', page, params)
            )
    );
  }

  readAwaitingShipmentOrders(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_AWAITING_SHIPMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/orders/awaiting_shipment', page, params)
            )
    );
  }

  readOrderChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  readSales(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SALES_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/orders/sales', page, params)
            )
    );
  }

  readSalesChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SALES_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  readSalesSummary(
    page = 1,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SALES_SUMMARY_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/orders/sales/summary', page, params)
            )
    );
  }

  readProofOfDestructionAsPdf(order: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PROOF_OF_DESTRUCTION_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              order + '/proof_of_destruction_preview?disposition=attachment',
              true
            )
    );
  }

  downloadDeliveryNotePreview(order: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_DELIVERY_NOTE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(order + '/delivery_note_preview', true)
    );
  }

  readDataRecoveryProtocolPdf(order: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              order + '/data_recovery_protocol_preview?disposition=attachment',
              true
            )
    );
  }

  readBindingOrderAsPdf(order: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_BINDING_ORDER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              order + '/binding_order_preview?disposition=attachment',
              true
            )
    );
  }

  sendMail(iri: string, payload: OrdersMail): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  sendProofOfDestructionMail(
    iri: string,
    payload: OrdersMail
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PROOF_OF_DESTRUCTION_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              iri + '/proof_of_destruction_email',
              payload,
              true
            )
    );
  }

  sendDataRecoveryProtocolMail(
    iri: string,
    payload: OrdersMail
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              iri + '/data_recovery_protocol_email',
              payload,
              true
            )
    );
  }

  sendBindingOrderEmail(
    iri: string,
    payload: BindingOrderEmail
  ): Observable<OrderState> {
    return this.rolesService.userHasRoleFilter<OrderState>(
      'ROLE_MWS_BINDING_ORDER_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/binding_order_email`,
              payload,
              true
            )
    );
  }

  updateOrder(iri: string, payload: Order): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  updateAnalysisLocation(
    iri: string,
    payload: { analysisLocation: string }
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_ANALYSIS_LOCATION_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/analysis_location`,
              payload,
              true
            )
    );
  }

  updateDataRecoveryLocation(
    iri: string,
    payload: { dataRecoveryLocation: string }
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_RECOVERY_LOCATION_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/data_recovery_location`,
              payload,
              true
            )
    );
  }

  updateOrderAnalysisResult(
    iri: string,
    payload: { analysisResult: string }
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_ANALYSIS_RESULT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/analysis_result`,
              payload,
              true
            )
    );
  }

  deleteOrder(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
