import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';


import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';

import * as fromModuleGuards from './guards';
import * as fromModuleServices from './services';

import {SharedModule} from '../shared/shared.module';
import {OrdersModule} from '../orders/orders.module';
import {
  TicketPriorityModeSelectComponent
} from './components/ticket-priority-mode-select/ticket-priority-mode-select.component';
import {
  TicketStateRangeSelectComponent
} from './components/ticket-state-range-select/ticket-state-range-select.component';
import {
  TicketTechnicianSelectComponent
} from './components/ticket-technician-select/ticket-technician-select.component';
import {TicketDetailsComponent} from './components';
import {
  TicketCommentFormDialogComponent
} from './components/ticket-comment-form-dialog/ticket-comment-form-dialog.component';
import {TicketCostsFormDialogComponent} from './components/ticket-costs-form-dialog/ticket-costs-form-dialog.component';
import {
  RemoteAccessFormDialogComponent
} from './components/remote-access-form-dialog/remote-access-form-dialog.component';
import {
  TicketDetailFormDialogComponent
} from './components/ticket-detail-form-dialog/ticket-detail-form-dialog.component';
import {
  TicketReplacementMediaCardComponent
} from './components/ticket-replacement-media-card/ticket-replacement-media-card.component';
import {
  TicketProofOfDestructionComponent
} from './components/ticket-proof-of-destruction/ticket-proof-of-destruction.component';
import {
  TicketProofOfDestructionEditModalComponent
} from './components/ticket-proof-of-destruction-edit-modal/ticket-proof-of-destruction-edit-modal.component';
import {
  TicketDataRecoveryResultsComponent
} from './components/ticket-data-recovery-results/ticket-data-recovery-results.component';
import {
  TicketDataRecoveryProtocolEditDialogComponent
} from './components/ticket-data-recovery-protocol-edit-dialog/ticket-data-recovery-protocol-edit-dialog.component';
import {CustomersModule} from '../customers/customers.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        SharedModule,
        OrdersModule,
        CustomersModule,
        TranslateModule
    ],
  declarations: [
    fromModuleComponents.AllComponents,
    fromModuleContainers.AllContainers,
    TicketPriorityModeSelectComponent,
    TicketStateRangeSelectComponent,
    TicketTechnicianSelectComponent,
    TicketDetailsComponent,
    TicketCommentFormDialogComponent,
    TicketCostsFormDialogComponent,
    RemoteAccessFormDialogComponent,
    TicketDetailFormDialogComponent,
    TicketReplacementMediaCardComponent,
    TicketProofOfDestructionComponent,
    TicketProofOfDestructionEditModalComponent,
    TicketDataRecoveryResultsComponent,
    TicketDataRecoveryProtocolEditDialogComponent
  ],
  exports: [],
  providers: [
    fromModuleGuards.AllGuards,
    fromModuleServices.AllServices,
  ]
})
export class TicketsModule {
}
