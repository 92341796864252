import { Component, Inject, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../utilities/observable.utility';
import {
  CountriesSelectors,
  DepartmentsSelectors,
  LabLocationsSelectors
} from '../../../master-data/store/selectors';
import {
  ShipmentAdditionalInsurancesSelectors,
  ShipmentsSelectors,
  ShippingProvidersToDataRecoverySelectors
} from '../../../shipping/store/selectors';
import { LeadsSelectors } from '../../../leads/store/selectors';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Shipment, ShipmentAdditionalInsurance} from '../../../shipping/models';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { getUuidFromIri } from '../../utilities/strings.utility';
import * as moment from 'moment/moment';
import { uniq } from 'lodash-es';
import { DataMedium } from '../../../warehouse/models';
import {
  DataMediumsSelectors,
  ReplacementDataMediumsSelectors
} from '../../../warehouse/store/selectors';
import { extractIri, extractUUID } from '../../utilities/objects.utility';
import {
  CountriesActions,
  DepartmentsActions,
  LabLocationsActions
} from '../../../master-data/store';
import { OrdersSelectors } from '../../../orders/store/selectors';
import {
  CustomerAddressesSelectors,
  CustomerContactsSelectors
} from '../../../customers/store/selectors';
import { Order } from '../../../orders/models';
import {
  DataMediumsActions,
  ReplacementDataMediumsActions
} from '../../../warehouse/store';
import {ShipmentAdditionalInsurancesActions, ShipmentsActions} from '../../../shipping/store';
import { Actions, ofType } from '@ngrx/effects';
import { FormsService } from '../../services';
import { ShowShipmentDialogComponent } from '../show-shipment-dialog/show-shipment-dialog.component';
import { OrdersActions } from '../../../orders/store';
import {
  CustomerAddressesActions,
  CustomerContactsActions
} from '../../../customers/store';

@Component({
  selector: 'app-create-shipment-dialog',
  styleUrls: ['./create-shipment-dialog.component.scss'],
  templateUrl: './create-shipment-dialog.component.html'
})
export class CreateShipmentDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  order$: Observable<Order>;
  order: Order;
  dataMedia$: Observable<Array<DataMedium>>;
  dataMediaState$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  shipmentAdditionalInsurances$: Observable<ShipmentAdditionalInsurance[]>;
  shipmentAdditionalInsurancesIsLoading$: Observable<boolean>;
  dataMediaError$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  shippingProviderId: string;
  columnClass = 'col-4';
  times: Array<{ label?: string; time: string }> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      actionAfterCreation?: string;
      type: string;
      shipTo?: string;
      shipment: Shipment;
    },
    private dialog: MatDialog,
    private actions$: Actions,
    private dialogRef: MatDialogRef<CreateShipmentDialogComponent>,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private formService: FormsService
  ) {
    super();
  }

  get formValue(): any {
    const baseValues = this.form.getRawValue();
    if (this.form.get('pickupDispatcher').disabled) {
      delete baseValues.pickupDispatcher;
    }
    if (this.form.get('pickupDate').disabled) {
      delete baseValues.pickupDate;
      delete baseValues.pickupTimeEarliest;
      delete baseValues.pickupTimeLatest;
    }
    if (this.form.get('trackerId').disabled) {
      delete baseValues.trackerId;
    }
    if (baseValues.trackingNumber?.trim() === '') {
      delete baseValues.trackingNumber;
    }
    if (baseValues.trackingLink?.trim() === '') {
      delete baseValues.trackingLink;
    }
    if (baseValues?.dataMedia?.length >= 0) {
      delete baseValues.order;
    }
    return baseValues;
  }

  get addressForm(): FormGroup {
    return this.fb.group({
      line1: this.fb.control('', Validators.maxLength(35)),
      line2: this.fb.control('', [Validators.required]),
      line3: this.fb.control(''),
      line4: this.fb.control(''),
      city: this.fb.control('', [Validators.required]),
      zipPostcode: this.fb.control('', [Validators.required]),
      stateProvinceCounty: this.fb.control(null),
      country: this.fb.control(null, [Validators.required])
    });
  }

  get showPickupForms(): boolean {
    if (!this.form) {
      return false;
    }
    if (!this.form.get('shippingProvider')) {
      return false;
    }
    const value = this.form.get('shippingProvider').value;
    if (!value) {
      return false;
    }
    return value.indexOf('PICK_UP') > -1 && value.indexOf('PERSONAL') === -1;
  }

  get isGoPickup(): boolean {
    if (!this.showPickupForms) {
      return false;
    }
    return this.form.get('shippingProvider').value?.indexOf('PICK_UP_GO') > -1;
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(CountriesSelectors.isLoading),
      this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(LeadsSelectors.isLoading),
      this.store$.select(ShipmentsSelectors.isLoading)
    ]);
    this.initForm();
    this.loadShipmentAdditionalInsurances();
    const orderIri = this.data?.shipment?.order;
    if (orderIri) {
      this.order$ = this.store$
        .select(OrdersSelectors.sDetailedByIri, { iri: orderIri })
        .pipe(
          takeUntil(this.onDestroy$),
          tap(order => {
            if (!order) {
              this.store$.dispatch(OrdersActions.ReadOrder({ iri: orderIri }));
            }
          }),
          filter(e => !!e)
        );
    }

    if (this.data?.shipment) {
      this.form.patchValue(this.data?.shipment);
    }
    if (this.data?.shipment?.dataMedia?.length > 0) {
      this.selectDataMedia(this.data?.shipment?.dataMedia);
    }
    loadIfNotLoaded(
      this.store$,
      CountriesSelectors.isLoaded,
      CountriesActions.ReadCountries()
    );
    this.times = Array.from(Array(24).keys()).map(e => {
      return {
        time: e + ':00'
      };
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      shipmentDirection: this.fb.control(null, [Validators.required]),
      shippingProvider: this.fb.control(null, [Validators.required]),
      shipmentService: this.fb.control(null, [Validators.required]),
      description: this.fb.control(''),
      trackingNumber: this.fb.control('', []), // optional
      trackingLink: this.fb.control(''), // optional
      trackerId: this.fb.control(null), // optional
      pickupDate: this.fb.control(null, [Validators.required]),
      pickupTimeEarliest: this.fb.control('08:00', [Validators.required]),
      pickupTimeLatest: this.fb.control('14:00', [Validators.required]),
      dataMedia: this.fb.control([]), // optional and readOnly
      order: this.fb.control(null), // optional
      shipFrom: this.fb.group({
        companyName: this.fb.control(''),
        firstName: this.fb.control(''),
        lastName: this.fb.control(''),
        address: this.addressForm,
        labLocation: this.fb.control(null)
      }),
      shipTo: this.fb.group({
        companyName: this.fb.control(''),
        firstName: this.fb.control(''),
        lastName: this.fb.control(''),
        careOf: this.fb.control(''),
        address: this.addressForm,
        labLocation: this.fb.control(null)
      }),
      pickupDispatcher: this.fb.group({
        companyName: this.fb.control(''),
        firstName: this.fb.control(''),
        lastName: this.fb.control(''),
        phone: this.fb.control('', [Validators.required]),
        address: this.addressForm
      })
    });
    this.changeStatePickupDispatcher(false);

    this.form
      .get('shippingProvider')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(iri => {
        this.shippingProviderId = getUuidFromIri(iri);
        const additionalInsurance = this.form.get('additionalInsurance');

        if(this.shippingProviderId === 'SHIPPING_PROVIDER_SHIPPING_LABEL_DHL') {
          this.columnClass = 'col-3';
          this.form.addControl('additionalInsurance', this.fb.control(null));
          const additionalInsurance = this.form.get('additionalInsurance');
          additionalInsurance?.updateValueAndValidity();
        } else {
          this.form.removeControl('additionalInsurance');
        }
        const shipmentService = this.form.get('shipmentService');
        shipmentService?.setValue(null);
        shipmentService?.enable();
        shipmentService?.updateValueAndValidity();

        if (this.shippingProviderId === 'SHIPPING_PROVIDER_GENERIC_SHIPMENT') {
          this.form.get('trackingNumber').enable();
          shipmentService.disable();
        } else {
          this.form.get('trackingNumber').disable();
          shipmentService.enable();
        }

        switch (this.shippingProviderId) {
          case 'SHIPPING_PROVIDER_PICK_UP_GO':
          case 'SHIPPING_PROVIDER_PICK_UP_UPS': {
            this.addFieldsForPickupToFormModel();
            break;
          }
          case 'SHIPPING_PROVIDER_GENERIC_SHIPMENT':
          case 'SHIPPING_PROVIDER_PERSONAL_DELIVERY_PARTNER':
          case 'SHIPPING_PROVIDER_PERSONAL_PICK_UP_DR_LE':
          case 'SHIPPING_PROVIDER_PERSONAL_DELIVERY_DR_LE':
          default: {
            this.removeAdditionalFormFieldsFromFormModel();
            this.form.get('shipmentService')?.setValue(null);
            this.form.get('shipmentService')?.disable();
          }
        }
      });
    this.form
      .get('shipmentDirection')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(iri => {
        const id = getUuidFromIri(iri);
        if (id === 'SHIPMENT_DIRECTION_DR_DE_TO_DR_EXT') {
          this.form.get('trackerId').enable();
          this.form.get('trackerId').setValidators([Validators.required]);
          this.form.get('trackerId').updateValueAndValidity();

          this.form.get('description').setValidators([Validators.required]);
          this.form.get('description').updateValueAndValidity();
        } else {
          this.form.get('trackerId').setValidators([]);
          this.form.get('trackerId').disable();
          this.form.get('trackerId').updateValueAndValidity();
          this.form.get('description').setValidators([]);
          this.form.get('description').updateValueAndValidity();
        }
        if (id.indexOf('DR_DE_TO') > -1) {
          this.fillOutDefaultDepartment('shipFrom');
        }
        if (id.indexOf('TO_DR_DE') > -1) {
          this.fillOutDefaultDepartment('shipTo');
        }
        if (id.indexOf('TO_CUSTOMER') > -1) {
          this.fillOutCustomerFromOrder('shipTo');
        }
        if (id.indexOf('CUSTOMER_TO') > -1) {
          this.fillOutCustomerFromOrder('shipFrom');
        }
        if (id.indexOf('TO_DR_EXT') > -1) {
          this.fillOutExt('shipTo');
        }
        if (id.indexOf('DR_EXT_TO') > -1) {
          this.fillOutExt('shipFrom');
        }
      });
  }

  private loadShipmentAdditionalInsurances(): void {
    this.shipmentAdditionalInsurances$ = this.store$.select(
      ShipmentAdditionalInsurancesSelectors.selectShipmentAdditionalInsurances
    );
    this.shipmentAdditionalInsurancesIsLoading$ = this.store$.select(
      ShipmentAdditionalInsurancesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ShipmentAdditionalInsurancesSelectors.isLoaded,
      ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurances()
    );
  }
  changeStatePickupDispatcher(enable = false): void {
    Object.keys(this.form.controls).forEach(key => {
      if (key.startsWith('pickupDispatcher')) {
        if (enable) {
          this.form.controls[key].enable();
        } else {
          this.form.controls[key].disable();
        }
      }
    });
  }

  addFieldsForPickupToFormModel(): void {
    this.changeStatePickupDispatcher(true);
    this.form.get('pickupDate').enable();
    this.form.get('pickupTimeEarliest').enable();
    this.form.get('pickupTimeLatest').enable();

    if (this.form.get('pickupDispatcher.phone')) {
      const direction = extractUUID(this.form.get('shipmentDirection').value);
      if (direction.indexOf('DR_DE_TO') > -1) {
        // fillPhoneFromDepartment
        this.fillPhoneFromDepartment();
      } else if (direction.indexOf('CUSTOMER_TO') > -1) {
        // fillPhoneFromCustomer
        this.fillPhoneFromCustomer();
      } else if (direction.indexOf('DR_EXT_TO') > -1) {
        // fillPhoneFromExternalLab
      } else {
      }
      // get Phone from data
      // this.form.get('pickupDispatcher.phone').setValue(this.leadContact.phone || this.leadContact.mobile);
    }
    this.form.patchValue({ pickupDispatcher: this.form.get('shipFrom').value });

    this.form.get('pickupDate').setValue(
      moment()
        .add(1, 'day')
        .toDate()
        .toISOString()
        .substring(0, 10)
    );

    // this.form.get('pickupTimeEarliest').setValue(moment().add(1, 'day').toDate().toISOString().substring(0, 10));
    // this.form.get('pickupTimeLatest').setValue(moment().add(1, 'day').toDate().toISOString().substring(0, 10));
  }

  removeAdditionalFormFieldsFromFormModel(): void {
    this.changeStatePickupDispatcher(false);
    this.form.get('pickupLatest')?.disable();
    this.form.get('pickupEarliest')?.disable();
    this.form.get('pickupDate').disable();
    this.form.get('pickupTimeEarliest').disable();
    this.form.get('pickupTimeLatest').disable();
  }

  createShipment(): void {
    const payload = this.formValue;
    if (payload.pickupDate) {
      // FORMAT: 2023-03-23T09:23:33.792Z mit Zeitzone: 2023-03-23T09:23:33+01:00
      payload.pickupEarliest =
        payload.pickupDate + 'T' + payload.pickupTimeEarliest + ':00+01:00';
      payload.pickupLatest =
        payload.pickupDate + 'T' + payload.pickupTimeLatest + ':00+01:00';
    }
    this.actions$
      .pipe(
        ofType(ShipmentsActions.CreateShipmentSuccess),
        takeUntil(this.onDestroy$),
        takeUntil(
          this.actions$.pipe(ofType(ShipmentsActions.CreateShipmentFail))
        )
      )
      .subscribe(({ response }) => {
        this.dialog.open(ShowShipmentDialogComponent, {
          data: { shipment: extractIri(response) }
        });
        this.dialogRef.close(true);

        // if (this.data?.actionAfterCreation === 'CLOSE' || payload.shippingProvider.indexOf('SHIPPING_PROVIDER_GENERIC_SHIPMENT') > -1) {
        // } else if (this.data?.actionAfterCreation === 'OPEN_SHIPMENT') {
        //   this.dialogRef.close();
        // } else {
        //   this.dialogRef.close();
        // }
      });
    this.actions$
      .pipe(
        ofType(ShipmentsActions.CreateShipmentFail),
        takeUntil(this.onDestroy$),
        takeUntil(
          this.actions$.pipe(ofType(ShipmentsActions.CreateShipmentSuccess))
        )
      )
      .subscribe(fail => {
        this.formService.mergeErrorResponseIntoForm(fail, this.form);
      });
    // console.log(payload);
    this.store$.dispatch(ShipmentsActions.CreateShipment({ payload }));
  }

  selectDataMedia(iris: Array<string>): void {
    const customerDataMedia = iris.filter(
      e => e.indexOf('customer_data_media') > -1
    );
    const customerReplacementDataMedia = iris.filter(
      e => e.indexOf('customer_replacement_data_media') > -1
    );
    const replacementDataMedia = iris.filter(
      e => e.indexOf('replacement_data_media') > -1
    );

    this.dataMedia$ = combineLatest([
      this.store$.select(DataMediumsSelectors.selectDataMediums).pipe(
        map(list => list.filter(e => iris.indexOf(extractIri(e)) > -1)),
        tap(list => {
          if (
            !list ||
            list.length <
              customerDataMedia.length + customerReplacementDataMedia.length
          ) {
            const orderIri = this.data?.shipment?.order;
            const orderUUID = extractUUID(orderIri);
            this.store$.dispatch(
              DataMediumsActions.ReadDataMediums({
                page: -1,
                params: { 'stockItem.order.uuid': orderUUID }
              })
            );
          }
        })
      ),
      this.store$
        .select(ReplacementDataMediumsSelectors.sList)
        .pipe(map(list => list.filter(e => iris.indexOf(extractIri(e)) > -1)))
    ]).pipe(
      map(
        ([a, b]): Array<DataMedium> => {
          return [...a, ...b];
        }
      )
    );
    for (const iri of replacementDataMedia) {
      this.store$.dispatch(
        ReplacementDataMediumsActions.ReadReplacementDataMedium({ iri })
      );
    }
    // for (const iri of customerReplacementDataMedia) {
    //   this.store$.dispatch(CustomerDataMediumsService.ReadReplacementDataMedium({iri}));
    // }
    // for (const iri of customerDataMedia) {
    //   this.store$.dispatch(ReplacementDataMediumsActions.ReadReplacementDataMedium({iri}));
    // }
    this.dataMedia$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(dataMedia => {
        const states = uniq(dataMedia.map(e => e.state));
        const invalidStates = [
          'in_shipment_dr_de_to_dr_ext',
          'in_shipment_dr_de_to_customer',
          'in_shipment_dr_ext_to_dr_de',
          'destroyed',
          'delivered_to_customer'
        ];

        const validStates = [
          'stored_dr_de',
          'reserved',
          'technical_department_dr_de',
          'waiting_for_return_shipment'
        ];

        if (states.length <= 1) {
          this.dataMediaState$.next(states[0] || null);
          if (invalidStates.indexOf(states[0]) > -1) {
            this.dataMediaError$.next(
              'Der/Die Datenträger ist/sind im Zustand: ' +
                states[0] +
                '. Daher ist ein Versand nicht möglich!'
            );
          } else {
            this.dataMediaError$.next(null);
          }
        } else if (states.every(e => validStates.indexOf(e) > -1)) {
          this.dataMediaState$.next(states[0]);
          this.dataMediaError$.next(null);
        } else {
          this.dataMediaError$.next(
            'Die ausgewählten Datenträger/Gehäuse sind in unterschiedlichen Zuständen! Daher ist ein Versand eventuell nicht möglich!'
          );
        }
      });
  }

  markAsTouched(): void {
    this.form.markAllAsTouched();
  }

  toggleSelectDataMedium(dataMedium: DataMedium): void {
    const formControl = this.form.get('dataMedia');
    const dataMediumIri = extractIri(dataMedium);
    const value = [...formControl.value] as Array<string>;
    if (this.isDataMediumSelected(dataMedium)) {
      value.splice(value.indexOf(dataMediumIri), 1);
    } else {
      value.push(dataMediumIri);
    }
    formControl.setValue(value);
    formControl.updateValueAndValidity();
  }

  isDataMediumSelected(dataMedium: DataMedium): boolean {
    const formControl = this.form.get('dataMedia');
    const dataMediumIri = extractIri(dataMedium);
    const value = formControl.value as Array<string>;
    return value.indexOf(dataMediumIri) > -1;
  }

  private fillOutCustomerFromOrder(formControlName: string): void {
    const targetForm = this.form.get(formControlName) as FormGroup;
    this.order$?.subscribe(order => {
      const formData: any = {
        firstName: order.organizationalCustomerContact.firstName,
        lastName: order.organizationalCustomerContact.lastName
      };
      if (order?.customer?.nameLine1 && order.customer?.customerType !== '/api/customer_types/1') {
        formData.companyName = order.customer.nameLine1;
      } else {
        formData.companyName = null;
      }
      targetForm.patchValue(formData);
      if (order.deliveryAddress || order.billingAddress) {
        this.store$
          .select(
            CustomerAddressesSelectors.selectCustomerAddressesByCustomerIri,
            { customerIri: extractIri(order.customer) }
          )
          .pipe(
            takeUntil(this.onDestroy$),
            filter(e => !!e)
          )
          .subscribe(addresses => {
            if (order.deliveryAddress) {
              const address = addresses.find(
                e => extractIri(e) === extractIri(order.deliveryAddress)
              );
              if (address) {
                targetForm.patchValue({ address: { ...address.address } });
              } else {
                this.store$.dispatch(
                  CustomerAddressesActions.ReadCustomerAddress({
                    iri: order.deliveryAddress
                  })
                );
              }
            } else if (order.billingAddress) {
              const address = addresses.find(
                e => extractIri(e) === extractIri(order.billingAddress)
              );
              if (address) {
                targetForm.patchValue({ address: { ...address.address } });
              } else {
                this.store$.dispatch(
                  CustomerAddressesActions.ReadCustomerAddress({
                    iri: order.billingAddress
                  })
                );
              }
            }
          });
      }
    });
  }

  private fillPhoneFromCustomer(): void {
    const targetForm = this.form.get('pickupDispatcher.phone');
    this.order$.subscribe(order => {
      console.log(order);
      if (order.organizationalCustomerContact) {
        const iri = extractIri(order.organizationalCustomerContact);
        this.store$
          .select(CustomerContactsSelectors.selectCustomerContactByIndex, iri)
          .pipe(
            takeUntil(this.onDestroy$),
            tap(e => {
              if (!e) {
                this.store$.dispatch(
                  CustomerContactsActions.ReadCustomerContact({ iri })
                );
              }
            }),
            filter(e => !!e),
            take(1)
          )
          .subscribe(contact => {
            if (contact.phone || contact.mobile) {
              targetForm.patchValue(contact.phone || contact.mobile);
            }
          });
      }
    });
  }

  private fillOutExt(formControlName: string): void {
    const targetForm = this.form.get(formControlName) as FormGroup;
    const formData: any = {};

    if (this.data.shipTo) {
      console.log(this.data.shipTo);

      this.store$
        .select(LabLocationsSelectors.sByIri, { iri: this.data.shipTo })
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e && !!e.address)
        )
        .subscribe(lab => {
          console.log(lab);
          formData.labLocation = extractIri(lab);
          formData.address = lab.address;
          formData.firstName = lab.firstName;
          formData.lastName = lab.lastName;
          formData.companyName = lab.companyName;
          targetForm.patchValue(formData);
        });
      this.store$.dispatch(
        LabLocationsActions.ReadLabLocation({ iri: this.data.shipTo })
      );
    } else if (this.order$) {
      this.order$.subscribe(order => {
        if (
          order.analysisLocation &&
          (order.rawStateRange === 'analysis_in_preparation' ||
            order.rawState.indexOf('prepare_analysis_dr_ext') > -1)
        ) {
          // analyseLabor
          this.store$
            .select(LabLocationsSelectors.sByIri, {
              iri: order.analysisLocation
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e && !!e.address)
            )
            .subscribe(lab => {
              formData.labLocation = extractIri(lab);
              formData.address = lab.address;
              formData.firstName = lab.firstName;
              formData.lastName = lab.lastName;
              formData.companyName = lab.companyName;
              targetForm.patchValue(formData);
            });
          this.store$.dispatch(
            LabLocationsActions.ReadLabLocation({ iri: order.analysisLocation })
          );
        } else if (
          order.dataRecoveryLocation &&
          (order.rawStateRange === 'data_recovery_in_preparation' ||
            order.rawState.indexOf('prepare_dr_dr_ext') > -1)
        ) {
          // DR lab
          this.store$
            .select(LabLocationsSelectors.sByIri, {
              iri: order.dataRecoveryLocation
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e && !!e.address)
            )
            .subscribe(lab => {
              formData.labLocation = extractIri(lab);
              formData.address = lab.address;
              formData.firstName = lab.firstName;
              formData.lastName = lab.lastName;
              formData.companyName = lab.companyName;
              targetForm.patchValue(formData);
            });
          this.store$.dispatch(
            LabLocationsActions.ReadLabLocation({
              iri: order.dataRecoveryLocation
            })
          );
        }
      });
    } else {
      console.log('Kein Target', this.data);
    }
  }

  private fillPhoneFromDepartment(): void {
    const targetForm = this.form.get('pickupDispatcher.phone');
    this.store$
      .select(DepartmentsSelectors.selectDefaultDepartment)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e),
        take(1)
      )
      .subscribe(department => {
        targetForm.patchValue(department.phone);
      });
  }

  private fillOutDefaultDepartment(formControlName: string): void {
    const targetForm = this.form.get(formControlName) as FormGroup;
    this.store$
      .select(DepartmentsSelectors.selectDefaultDepartment)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(e => {
          if (!e) {
            this.store$.dispatch(DepartmentsActions.ReadDepartments());
          }
        }),
        filter(e => !!e),
        take(1)
      )
      .subscribe(department => {
        const formData: any = {
          labLocation: '/api/lab_locations/LAB_LOCATION_DR_DE'
        };
        if (department?.companyName) {
          formData.companyName = department.companyName;
        }
        if (department?.generalManager) {
          const prts = department?.generalManager.split(' ');
          formData.firstName = prts[0];
          formData.lastName = prts[1];
        }
        formData.address = department.address;
        targetForm.patchValue(formData);
      });
  }
}
