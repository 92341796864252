import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_DATA_RECOVERY_PROTOCOL = '[Orders Module] Read Data Recovery Protocol';
export const READ_DATA_RECOVERY_PROTOCOL_SUCCESS = '[Orders Module] Read Data Recovery Protocol Success';
export const READ_DATA_RECOVERY_PROTOCOL_FAIL = '[Orders Module] Read Data Recovery Protocol Fail';

export const ReadDataRecoveryProtocol = createAction(
  READ_DATA_RECOVERY_PROTOCOL,
  props<{ iri: string }>()
);

export const ReadDataRecoveryProtocolSuccess = createAction(
  READ_DATA_RECOVERY_PROTOCOL_SUCCESS,
  props<{ response: any }>()
);

export const ReadDataRecoveryProtocolFail = createAction(
  READ_DATA_RECOVERY_PROTOCOL_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_DATA_RECOVERY_PROTOCOLS = '[Orders Module] Read Data Recovery Protocols';
export const READ_DATA_RECOVERY_PROTOCOLS_SUCCESS = '[Orders Module] Read Data Recovery Protocols Success';
export const READ_DATA_RECOVERY_PROTOCOLS_FAIL = '[Orders Module] Read Data Recovery Protocols Fail';

export const ReadDataRecoveryProtocols = createAction(
  READ_DATA_RECOVERY_PROTOCOLS,
  props<{ orderUuid: string }>()
);

export const ReadDataRecoveryProtocolsSuccess = createAction(
  READ_DATA_RECOVERY_PROTOCOLS_SUCCESS,
  props<{ response: any }>()
);

export const ReadDataRecoveryProtocolsFail = createAction(
  READ_DATA_RECOVERY_PROTOCOLS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_DATA_RECOVERY_PROTOCOL = '[Orders Module] Update Data Recovery Protocol';
export const UPDATE_DATA_RECOVERY_PROTOCOL_SUCCESS = '[Orders Module] Update Data Recovery Protocol Success';
export const UPDATE_DATA_RECOVERY_PROTOCOL_FAIL = '[Orders Module] Update Data Recovery Protocol Fail';

export const UpdateDataRecoveryProtocol = createAction(
  UPDATE_DATA_RECOVERY_PROTOCOL,
  props<{ iri: string, payload: fromModuleModels.DataRecoveryProtocol }>()
);

export const UpdateDataRecoveryProtocolSuccess = createAction(
  UPDATE_DATA_RECOVERY_PROTOCOL_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryProtocol }>()
);

export const UpdateDataRecoveryProtocolFail = createAction(
  UPDATE_DATA_RECOVERY_PROTOCOL_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_DATA_RECOVERY_PROTOCOL = '[Orders Module] Delete Data Recovery Protocol';
export const DELETE_DATA_RECOVERY_PROTOCOL_SUCCESS = '[Orders Module] Delete Data Recovery Protocol Success';
export const DELETE_DATA_RECOVERY_PROTOCOL_FAIL = '[Orders Module] Delete Data Recovery Protocol Fail';

export const DeleteDataRecoveryProtocol = createAction(
  DELETE_DATA_RECOVERY_PROTOCOL,
  props<{ iri: string }>()
);

export const DeleteDataRecoveryProtocolSuccess = createAction(
  DELETE_DATA_RECOVERY_PROTOCOL_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteDataRecoveryProtocolFail = createAction(
  DELETE_DATA_RECOVERY_PROTOCOL_FAIL,
  props<{ response: HttpErrorResponse }>()
);
