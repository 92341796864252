import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_CURRENCY = '[Master Data Module] Read Currency';
export const READ_CURRENCY_SUCCESS = '[Master Data Module] Read Currency Success';
export const READ_CURRENCY_FAIL = '[Master Data Module] Read Currency Fail';

export const ReadCurrency = createAction(
  READ_CURRENCY,
  props<{ iri: string }>()
);

export const ReadCurrencySuccess = createAction(
  READ_CURRENCY_SUCCESS,
  props<{ response: fromModuleModels.Currency }>()
);

export const ReadCurrencyFail = createAction(
  READ_CURRENCY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_CURRENCIES = '[Master Data] Read Currencies';
export const READ_CURRENCIES_SUCCESS = '[Master Data] Read Currencies Success';
export const READ_CURRENCIES_FAIL = '[Master Data] Read Currencies Fail';

export const ReadCurrencies = createAction(
  READ_CURRENCIES
);

export const ReadCurrenciesSuccess = createAction(
  READ_CURRENCIES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCurrenciesFail = createAction(
  READ_CURRENCIES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
