import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import { Department } from '../../../master-data/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { InvoiceLike, PayableInvoice } from '../../models';
import { Customer } from '../../../customers/models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CustomersSelectors } from '../../../customers/store/selectors';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import {
  PayableInvoicesSelectors,
  PaymentProcessesSelectors
} from '../../store/selectors';
import { PaymentProcessesActions } from '../../store';
import { Order } from '../../../orders/models';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { OrdersActions } from '../../../orders/store';
import { combineLatestArray } from 'rxjs-etc';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-invoice-head-issuer',
  styleUrls: ['invoice-head-issuer.component.scss'],
  template: `
    <div class="m-ta--2">
      <div class="spacer" *ngIf="!!issuer && !showEdit">
        <button
          *ngIf="isEditButtonActive(invoice)"
          mat-icon-button
          (click)="handleRequestShowDepartmentSelectionModal()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="!!issuer && !showEdit">
        <img
          [src]="departmentLogoUri"
          class="img--resp m-b--32"
          alt="Logo {{ getIssuerData('name') }}"
          *ngIf="departmentLogoUri"
        />

        <p>
          Telefon: {{ getIssuerData('phone') }}<br />
          Fax: {{ getIssuerData('fax') }}
        </p>

        <p>
          Internet: {{ getIssuerData('website') }}<br />
          E-Mail: {{ getIssuerData('email') }}<br />
          USt.-ID: {{ getIssuerData('taxNumber') }}<br />
        </p>

        <p>
          <a
            class="text-color-black text-decoration-none"
            [routerLink]="
              customer?.customerNumber
                ? ['/customers', extractId(customer)]
                : null
            "
            >Kundennummer/ customer ID:
            <span [class.text-color-blue]="customer?.customerNumber">
              {{ customer?.customerNumber || 'noch nicht gewählt' }}</span
            >
          </a>
          <br />
          <ng-container *ngIf="invoice?.deliveryDate"
            >Lieferdatum/ delivery date:
            {{ invoice.deliveryDate | date: 'dd.MM.Y' }}<br />
          </ng-container>
          <ng-container *ngIf="invoice?.orderDate"
            >Datum/ date date: {{ invoice.orderDate | date: 'dd.MM.Y' }}<br />
          </ng-container>
          <ng-container *ngIf="isCorrectionInvoice">
            <a
              class="text-color-black text-decoration-none"
              *ngIf="!!invoice?.correctedInvoice"
              [routerLink]="[
                '/invoices',
                'payable',
                extractTypeByIri(invoice.correctedInvoice),
                extractId(invoice.correctedInvoice)
              ]"
            >
              Korrigierte Rechnung/corrected invoice:
              <span class="text-color-blue">{{
                invoice.correctedInvoice.invoiceNumber
              }}</span></a
            >
            <br />
            <a
              class="text-color-black text-decoration-none"
              *ngIf="!!invoice?.correctedInvoice?.paymentProcess?.order"
              [routerLink]="[
                '/orders',
                extractId(invoice.correctedInvoice.paymentProcess.order)
              ]"
              >Auftragsnummer/order:
              <span class="text-color-blue">{{
                invoice.correctedInvoice.paymentProcess.order.orderNumber
              }}</span></a
            >
          </ng-container>
          <ng-container *ngIf="isCancellationInvoice">
            <a
              class="text-color-black text-decoration-none"
              [routerLink]="[
                '/invoices',
                'payable',
                extractTypeByIri(invoice.canceledInvoice),
                extractId(invoice.canceledInvoice)
              ]"
            >
              Stornierte Rechnung/ cancelled invoice:
              <span class="text-color-blue">{{
                (canceledInvoice$ | async)?.invoiceNumber
              }}</span></a
            >
            <br />
            <a
              class="text-color-black text-decoration-none"
              *ngIf="
                invoice.canceledInvoice?.paymentProcess?.order?.orderNumber
              "
              [routerLink]="[
                '/orders',
                extractId(invoice.canceledInvoice.paymentProcess.order)
              ]"
              >Auftragsnummer/ order:
              {{ invoice.canceledInvoice.paymentProcess.order.orderNumber }}</a
            >
          </ng-container>
          <a
            *ngIf="order$ | async as orderTmp"
            class="text-color-black text-decoration-none"
            [routerLink]="['/orders', extractId(order$ | async)]"
          >
            Auftragsnummer / order no.:
            <span class="text-color-blue">{{ orderTmp.orderNumber }}</span
            ><br />
          </a>
        </p>
      </ng-container>
      <ng-container *ngIf="!issuer || showEdit">
        <div class="issuer-list" style="min-width: 150px;" [formGroup]="form">
          <app-department-select
            (selectDepartment)="handleUpdateIssuer($event)"
            label="Abteilung wählen"
            formControlName="issuer"
          ></app-department-select>
        </div>
      </ng-container>
    </div>
  `
})
export class InvoiceHeadIssuerComponent implements OnInit, OnDestroy {
  customer: Customer;
  deliveryDate: string;
  departments: Array<Department>;
  @Input() @Optional() readonly = false;
  @Input() invoice$: Observable<InvoiceLike>;
  canceledInvoice$: Observable<PayableInvoice>;
  invoice: InvoiceLike;
  issuer: Department;
  departmentLogoUri: SafeUrl;
  // @Input()
  orderDate: string;
  form: FormGroup;
  showEdit = true;
  @Output() updateIssuer: EventEmitter<Department> = new EventEmitter<
    Department
  >();

  order$: Observable<Order>;

  departments$: Observable<Array<Department>>;
  departmentsIsLoading$: Observable<boolean>;

  onDestroy$: Subject<any> = new Subject<any>();

  protected readonly extractIri = extractIri;
  protected readonly extractId = extractUUID;
  protected readonly extractTypeByIri = extractTypeByIri;

  constructor(
    private sanitizer: DomSanitizer,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private dls: DepartmentLogosService
  ) {}

  get isCorrectionInvoice(): boolean {
    return extractTypeByIri(this.invoice, true) === 'correction_invoices';
  }

  get isCancellationInvoice(): boolean {
    return extractTypeByIri(this.invoice, true) === 'cancellation_invoices';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      issuer: this.fb.control(null)
    });

    this.invoice$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(i => !!i),
        distinctUntilChanged()
      )
      .subscribe(invoice => {
        this.invoice = invoice;
        if ('paymentProcess' in invoice && !!invoice.paymentProcess) {
          // console.log('paymentProcess', invoice.paymentProcess, extractIri(invoice.paymentProcess));
          let loadingPaymentProcess = null;
          this.order$ = this.store$
            .select(PaymentProcessesSelectors.sByIri, {
              iri: extractIri(invoice.paymentProcess)
            })
            .pipe(
              tap(p => {
                if (
                  !p &&
                  loadingPaymentProcess !== extractIri(invoice.paymentProcess)
                ) {
                  loadingPaymentProcess = extractIri(invoice.paymentProcess);
                  this.store$.dispatch(
                    PaymentProcessesActions.ReadPaymentProcess({
                      iri: extractIri(invoice.paymentProcess)
                    })
                  );
                }
              }),
              filter(e => !!e && e.order),
              mergeMap(p =>
                combineLatestArray([
                  of(p),
                  this.store$.select(OrdersSelectors.sDetailedByIri, {
                    iri: p.order
                  })
                ])
              ),
              tap(([p, o]) => {
                if (!!p) {
                  loadingPaymentProcess = null;
                }
                if (!o) {
                  this.store$.dispatch(
                    OrdersActions.ReadOrder({ iri: extractIri(p.order) })
                  );
                }
              }),
              filter(([e, f]) => !!f),
              map(([p, o]) => o)
            );
        } else {
        }
        if ('issuer' in invoice) {
          this.setLocalIssuer(extractIri(invoice.issuer, true));
        }

        if ('canceledInvoice' in invoice && invoice.canceledInvoice) {
          this.canceledInvoice$ = this.store$.select(
            PayableInvoicesSelectors.selectPayableInvoiceByIndex,
            { iri: extractIri(invoice.canceledInvoice) }
          );
        }
        if (invoice.customerNumber) {
          this.store$
            .select(CustomersSelectors.sByCustomerNumber, {
              customerNumber: invoice.customerNumber
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e),
              take(1)
            )
            .subscribe(c => {
              this.customer = c;
            });
        }
      });

    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    this.departmentsIsLoading$ = this.store$.select(
      DepartmentsSelectors.isLoading
    );
    this.store$
      .select(DepartmentsSelectors.selectDefaultDepartment)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(department => {
        const field = this.form.get('issuer');
        if (field.value === null) {
          field.setValue(department['@id']);
          this.handleUpdateIssuer(department);
        }
      });
  }

  handleRequestShowDepartmentSelectionModal(): void {
    this.showEdit = true;
    // this.requestShowDepartmentSelectionModal.emit();
  }

  getIssuerData(property?: string): Department | any | null {
    if (property) {
      return this.issuer.hasOwnProperty(property)
        ? this.issuer[property]
        : null;
    } else {
      return this.issuer;
    }
  }

  isEditButtonActive(invoice: InvoiceLike): boolean {
    if (!invoice || extractIri(invoice, true) === null) {
      return true;
    }
    if (this.readonly) {
      return false;
    }

    const isPayableInvoice =
      invoice['@type'] === 'Invoice' || invoice['@type'] === 'PartialInvoice';

    return (
      // @ts-ignore
      (isPayableInvoice && !!!invoice?.bookedBy) ||
      // @ts-ignore
      (!isPayableInvoice && !!!invoice?.bookedAt)
    );
  }

  handleUpdateIssuer(selectedIssuer: Department | any): void {
    this.updateIssuer.emit(selectedIssuer);
    this.showEdit = false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private setLocalIssuer(issuerIri: any): void {
    if (this.form.get('issuer').value !== issuerIri) {
      this.form.get('issuer').setValue(issuerIri);
    }
    if (issuerIri === null) {
      this.issuer = null;
      this.showEdit = false;
      return;
    }

    this.store$
      .select(DepartmentsSelectors.sByIri, { iri: issuerIri })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(i => !!i)
      )
      .subscribe(issuer => {
        this.issuer = issuer;
        this.showEdit = false;
        if (issuer.departmentLogo) {
          this.dls
            .getDepartmentLogoAsImage(issuer.departmentLogo)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              ({ body: blob }) => {
                this.departmentLogoUri = this.sanitizer.bypassSecurityTrustUrl(
                  window.URL.createObjectURL(blob)
                );
              },
              response => {
                console.log(response);
              }
            );
        }
      });
  }
}
