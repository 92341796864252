import * as moment from 'moment/moment';

export const getDiffOfDates = (date1: Date, date2: Date):
  {
    second: number,
    seconds: number,
    minute: number,
    minutes: number,
    hour: number,
    hours: number,
    day: number
  } => {
  const durationMS = date2.valueOf() - date1.valueOf(); // The unit is millisecond
  const hours = durationMS / (60 * 60 * 1000); // Turn the duration into hour format
  const minutes = durationMS / (60 * 1000); // Turn the duration into hour format
  const seconds = durationMS / (1000); // Turn the duration into hour format
  let differenceMsTemp = durationMS;
  // take out milliseconds
  differenceMsTemp = differenceMsTemp / 1000;
  const second = Math.floor(differenceMsTemp % 60);
  differenceMsTemp = differenceMsTemp / 60;
  const minute = Math.floor(differenceMsTemp % 60);
  differenceMsTemp = differenceMsTemp / 60;
  const hour = Math.floor(differenceMsTemp % 24);
  const day = Math.floor(differenceMsTemp / 24);
  return {second, seconds, minute, minutes, hour, hours, day};
};

export const DateStringToDateForm = (dateString: string): string => {
  return moment(dateString).toISOString(true).substring(0, 10);
};
