import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {DataMediumStatusLogsSelectors} from '../store/selectors';
import {DataMediumStatusLogsActions} from '../store';
import {StringsUtility} from '../../shared/utilities/strings.utility';

@Injectable()
export class DataMediumStatusLogLoadedGuard implements CanActivate {

  constructor(private store$: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this.readDataMediumStatusLogDone(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  readDataMediumStatusLogDone(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(DataMediumStatusLogsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          const params = {
            'order.uuid': StringsUtility.getUuidFromIri(route.params.uuid),
            'order[createdAt]': 'desc',
            pagination: false
          };
          this.store$.dispatch(DataMediumStatusLogsActions.ReadDataMediumStatusLogs({page: 1, params}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
