import {AfterViewInit, Component, Input, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';

import {Observable, of, BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import {IDynamicFormField} from '../../../shared/models';
import {DynamicFormComponent} from '../../../shared/containers/dynamic-form/dynamic-form.component';
import * as moment from 'moment/moment';

@Component({
  selector: 'data-media-filter-form',
  styleUrls: ['data-media-filter-form.component.scss'],
  template: `

    <div class="data-media-filter-form__outer" [class.expanded]="searchBoxVisible$.getValue()">
      <div class="data-media-filter-form__wrapper wrap">

        <div class="data-media-filter-form grid">

          <div class="column-14">
            <pre>DIESE FILTER SIND AKTUELL OHNE FUNKTION!</pre>
            <dynamic-form
              [config]="formConfig"
              (requestAction)="handleRequestHandleAction($event)"
            ></dynamic-form>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DataMediaFilterFormComponent implements AfterViewInit, OnDestroy {

  @ViewChild(DynamicFormComponent) dForm: DynamicFormComponent;

  @Input() searchBoxVisible$: BehaviorSubject<boolean>;
  @Input() storageLocations$: Observable<Array<fromMasterDataModuleModels.StorageLocation>>;
  @Input() storageSystems$: Observable<Array<fromMasterDataModuleModels.StorageSystem>>;
  @Input() storageSystemManufacturers$: Observable<Array<fromMasterDataModuleModels.StorageSystemManufacturer>>;

  @Output()
  requestHandleAction: EventEmitter<any> = new EventEmitter<any>();

  onDestroy$: Subject<any> = new Subject<any>();

  formConfig: Array<IDynamicFormField> = [
    {
      type: 'select',
      label: 'Lagerplatz',
      name: 'storageLocationNumber',
      optionsConfig: {
        clearable: true,
        bindValue: 'storageLocationNumber',
        bindLabel: 'storageLocationNumber',
      }
    }, {
      type: 'select',
      label: 'Lagergruppe',
      name: 'stockItemType',
      // todo: this should not be static but served via API thru a dedicated endpoint or something
      options$: of([
        {
          type: 'storage_group.customer_stock_item',
          name: 'Kundenhardware'
        }, {
          type: 'storage_group.customer_replacement_stock_item',
          name: 'Ersatzdatenträger (Kunde)'
        }, {
          type: 'storage_group.replacement_stock_item',
          name: 'Ersatzdatenträger (DR)'
        }
      ]),
      optionsConfig: {
        clearable: true,
        bindValue: 'type',
        bindLabel: 'name',
      }
    }, {
      type: 'select',
      label: 'Datenträgertyp',
      name: 'storageSystemType',
      options$: null,
      optionsConfig: {
        clearable: true,
        bindValue: '@id',
        bindLabel: 'name',
      }
    }, {
      type: 'select',
      label: 'Hersteller',
      name: 'storageSystemManufacturer',
      options$: null,
      optionsConfig: {
        clearable: true,
        bindValue: '@id',
        bindLabel: 'name',
      }
    }, {
      type: 'select',
      label: 'Eingelagert',
      name: 'stored',
      options$: of([{label: 'Ja', value: true}, {label: 'Nein', value: false}]),
      optionsConfig: {
        clearable: true,
        bindValue: 'value',
        bindLabel: 'label',
      }
    }, {
      type: 'select',
      label: 'Archiviert',
      name: 'archived',
      options$: of([{label: 'Ja', value: true}, {label: 'Nein', value: false}]),
      optionsConfig: {
        clearable: true,
        bindValue: 'value',
        bindLabel: 'label',
      }
    }, {
      type: 'date',
      label: 'Eingelagert nach',
      name: 'createdAtMin',
      optionsConfig: {
        clearable: true,
        maxDateToday: true
      }
    }, {
      type: 'date',
      label: 'Eingelagert vor',
      name: 'createdAtMax',
      optionsConfig: {
        clearable: true
      }
    }
  ];

  constructor() {
  }

  ngAfterViewInit(): void {

    this.searchBoxVisible$.pipe(
      takeUntil(this.onDestroy$),
      filter(visible => !!!visible)
    ).subscribe(() => this.dForm.form.reset());

    this.formConfig[0].options$ = this.storageLocations$;
    // note: misnomer; storageSystemType should be storageSystem API-wise;
    this.formConfig[2].options$ = this.storageSystems$;
    this.formConfig[3].options$ = this.storageSystemManufacturers$;
  }

  handleRequestHandleAction(formValues: any): void {

    const values = {...formValues};

    if (!!values?.createdAtMin) {
      values.createdAtMin = moment(values.createdAtMin as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    if (!!values?.createdAtMax) {
      values.createdAtMax = moment(values.createdAtMax as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    // Initial emit from dynamic form component is empty
    if (Object.keys(values).length) {
      this.requestHandleAction.emit(values);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
