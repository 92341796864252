import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_COMMENT = '[Customers Module] Create Customer Comment';
export const CREATE_CUSTOMER_COMMENT_SUCCESS = '[Customers Module] Create Customer Comment Success';
export const CREATE_CUSTOMER_COMMENT_FAIL = '[Customers Module] Create Customer Comment Fail';

export const CreateCustomerComment = createAction(
  CREATE_CUSTOMER_COMMENT,
  props<{ payload: any }>()
);

export const CreateCustomerCommentSuccess = createAction(
  CREATE_CUSTOMER_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateCustomerCommentFail = createAction(
  CREATE_CUSTOMER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const LOAD_CUSTOMER_COMMENT = '[Customers Module] Load Customer Comment';
export const LOAD_CUSTOMER_COMMENT_SUCCESS = '[Customers Module] Load Customer Comment Success';
export const LOAD_CUSTOMER_COMMENT_FAIL = '[Customers Module] Load Customer Comment Fail';

export const ReadCustomerComment = createAction(
  LOAD_CUSTOMER_COMMENT,
  props<{ iri: string }>()
);

export const ReadCustomerCommentSuccess = createAction(
  LOAD_CUSTOMER_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerCommentFail = createAction(
  LOAD_CUSTOMER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const LOAD_CUSTOMER_COMMENTS_SELECTION = '[Customers Module] Load Customer Comments Selection';
export const LOAD_CUSTOMER_COMMENTS_SELECTION_SUCCESS = '[Customers Module] Load Customer Comments Selection Success';
export const LOAD_CUSTOMER_COMMENTS_SELECTION_FAIL = '[Customers Module] Load Customer Comments Selection Fail';

export const LoadCustomerCommentsSelection = createAction(
  LOAD_CUSTOMER_COMMENTS_SELECTION,
  props<{ payload: Array<string> }>()
);

export const LoadCustomerCommentsSelectionSuccess = createAction(
  LOAD_CUSTOMER_COMMENTS_SELECTION_SUCCESS,
  props<{ response: any }>()
);

export const LoadCustomerCommentsSelectionFail = createAction(
  LOAD_CUSTOMER_COMMENTS_SELECTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_CUSTOMER_COMMENTS = '[Customers Module] Read Customer Comments';
export const READ_CUSTOMER_COMMENTS_SUCCESS = '[Customers Module] Read Customer Comments Success';
export const READ_CUSTOMER_COMMENTS_FAIL = '[Customers Module] Read Customer Comments Fail';

export const ReadCustomerComments = createAction(
  READ_CUSTOMER_COMMENTS,
  props<{ customerUuid: string }>()
);

export const ReadCustomerCommentsSuccess = createAction(
  READ_CUSTOMER_COMMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerCommentsFail = createAction(
  READ_CUSTOMER_COMMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_COMMENT = '[Customers Module] Update Customer Comment';
export const UPDATE_CUSTOMER_COMMENT_SUCCESS = '[Customers Module] Update Customer Comment Success';
export const UPDATE_CUSTOMER_COMMENT_FAIL = '[Customers Module] Update Customer Comment Fail';

export const UpdateCustomerComment = createAction(
  UPDATE_CUSTOMER_COMMENT,
  props<{ iri: string, payload: fromModuleModels.CustomerComment }>()
);

export const UpdateCustomerCommentSuccess = createAction(
  UPDATE_CUSTOMER_COMMENT_SUCCESS,
  props<{ response: fromModuleModels.CustomerComment }>()
);

export const UpdateCustomerCommentFail = createAction(
  UPDATE_CUSTOMER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_COMMENT = '[Customers Module] Delete Customer Comment';
export const DELETE_CUSTOMER_COMMENT_SUCCESS = '[Customers Module] Delete Customer Comment Success';
export const DELETE_CUSTOMER_COMMENT_FAIL = '[Customers Module] Delete Customer Comment Fail';

export const DeleteCustomerComment = createAction(
  DELETE_CUSTOMER_COMMENT,
  props<{ iri: string }>()
);

export const DeleteCustomerCommentSuccess = createAction(
  DELETE_CUSTOMER_COMMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerCommentFail = createAction(
  DELETE_CUSTOMER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const READ_CUSTOMER_COMMENT_CHUNK = '[Customers Module] Read Customer Comment Chunk';
export const READ_CUSTOMER_COMMENT_CHUNK_SUCCESS = '[Customers Module] Read Customer Comment Chunk Success';
export const READ_CUSTOMER_COMMENT_CHUNK_FAIL = '[Customers Module] Read Customer Comment Chunk Fail';

export const ReadCustomerCommentChunk = createAction(
  READ_CUSTOMER_COMMENT_CHUNK,
  props<{ uri: string }>()
);

export const ReadCustomerCommentChunkSuccess = createAction(
  READ_CUSTOMER_COMMENT_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerCommentChunkFail = createAction(
  READ_CUSTOMER_COMMENT_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);
