import {Component, OnInit} from '@angular/core';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {DataMedium, ReplacementStockItem, StockItem} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplacementStockItemsService} from '../../services';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {ProductsSelectors, StorageSystemManufacturersSelectors} from '../../../master-data/store/selectors';
import {ProductsActions, StorageSystemManufacturersActions} from '../../../master-data/store';
import {CustomerReplacementStockItemsActions} from '../../store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {Product} from "../../../master-data/models";
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-warehouse-replacement-data-media-view',
  styleUrls: ['./warehouse-replacement-data-media-view.component.scss'],
  template: `
    <div class="row  py-2" [formGroup]="form">
      <div class="col-2 ">
        <app-product-select formControlName="product"
                            (updateSelectedObject)="setSelectedProduct($event)"></app-product-select>
      </div>
      <div class="col-2 ">
        <app-storage-location-select formControlName="storageLocation"></app-storage-location-select>
      </div>
      <div class="col-2 ">
        <app-data-media-state-select formControlName="dataMediaState"
                                     type="ReplacementDataMedium"></app-data-media-state-select>
      </div>
      <div class="col pt-4">
        <a (click)="resetFilter()" class="link-primary cursor-pointer text-color-grey text-decoration-none">Filter
          zurücksetzen</a>
      </div>
      <div class="col text-right">

        <div class="alert" *ngIf="form.get('product').value"
             [class.alert-danger]="(actualStock$|async)<(targetStock$|async)"
             [class.alert-success]="(actualStock$|async)>=(targetStock$|async)">
          <span>Aktueller Bestand: {{actualStock$|async}} </span>
          <span>| Zielbestand: {{targetStock$|async}} </span>
        </div>
      </div>
      <div class="col text-right text-color-grey pt-4">
        <span class="">Anzahl: {{countDataMedia$|async}} </span>

      </div>

    </div>
    <div class="pos-relative" style="min-height: 300px;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <ng-container *ngIf="(items$|async) as items">
        <div class="row" *ngFor="let item of items">
          <app-stock-item-display-line [stockItem]="item"
                                       [selectMode]="'none'"
                                       [filterDataMediaFn]="filterForState"></app-stock-item-display-line>

        </div>
        <div class="row" *ngIf="(isLoading$|async)===false && (!items || items.length<=0)">
          <p>Es sind keine Datenträger mit den ausgewählten Filtern verfügbar!</p>
        </div>

      </ng-container>
      <ng-template #nothing>
      </ng-template>
    </div>


  `

})
export class WarehouseReplacementDataMediaViewComponent extends BaseOnDestroyComponent implements OnInit {
  form: FormGroup;
  storedFilters?: any;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items$: BehaviorSubject<Array<ReplacementStockItem>> = new BehaviorSubject<Array<ReplacementStockItem>>([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  targetStock$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  actualStock$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  countDataMedia$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: ReplacementStockItemsService,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
    public localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(params => {
        this.readQueryParams();
        //
      });

    loadIfNotLoaded(this.store$, StorageSystemManufacturersSelectors.isLoaded, StorageSystemManufacturersActions.ReadStorageSystemManufacturers());
    loadIfNotLoaded(this.store$, ProductsSelectors.isLoaded, ProductsActions.ReadProducts());
    this.actions$
      .pipe(ofType(CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess, CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemSuccess),
        takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadData();
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      product: this.fb.control(null),
      storageLocation: this.fb.control(null),
      dataMediaState: this.fb.control(null),
    });
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(values => {
      this.updateQueryParams({...values});
      this.localStorageService.overwriteObjectByKey('filters', 'warehouse_replacement_data_media', {...values});
    });

    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'warehouse_replacement_data_media');
    if(this.storedFilters) {
      this.updateQueryParams(this.storedFilters, true);
    }
    if (this.form.get('dataMediaState').value === null) {
      this.form.get('dataMediaState').setValue('stored');
    }
  }

  resetFilter(): void {
    this.form.patchValue({
      product: null,
      storageLocation: null,
      dataMediaState: 'stored',
    });
  }

  filterForState = (dm: DataMedium): boolean => {
    if (!this.form?.get('dataMediaState')?.value) {
      return true;
    }
    return dm.archived === false && dm.state === this.form?.get('dataMediaState')?.value;
  }

  readQueryParams(): void {

    const params = this.activatedRoute.snapshot.queryParams;
    for (const key of ['product', 'storageLocation', 'dataMediaState']) {
      if (this.form.get(key).value !== params[key]) {
        this.form.get(key).setValue(params[key], {onlySelf: true, emitEvent: false});
      }
    }

  }

  updateQueryParams(values: { [key: string]: string }, alwaysLoad = false): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = {...currentQuery};
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {

      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }

  setSelectedProduct(p: Product): void {
    this.targetStock$.next(p.targetStock);
    this.actualStock$.next(p.actualStock);
  }

  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const params: any = {
      pagination: false,
      'order[createdAt]': 'desc'
    };

    if (queryParams.product) {
      params['product.uuid'] = getUuidFromIri(queryParams.product);
    }
    if (queryParams.storageLocation) {
      params['storageLocation.uuid'] = getUuidFromIri(queryParams.storageLocation);
    }
    if (queryParams.dataMediaState) {
      params['dataMediums.state'] = getUuidFromIri(queryParams.dataMediaState);
    }
    this.backendService.getReplacementStockItems(-1, params)
      .subscribe((data) => {
        const items = data['hydra:member'] as Array<ReplacementStockItem>;
        this.items$.next(items);
        let count = items.reduce((previousValue, currentValue) => previousValue + currentValue.dataMediums.length, 0);
        if (queryParams.dataMediaState) {
          count = items.reduce((previousValue, currentValue) => previousValue + currentValue.dataMediums.filter(e => e.state === queryParams.dataMediaState).length, 0)
        }
        this.countDataMedia$.next(count);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }
        this.isLoading$.next(false);
      }, (error) => {
        console.log(error);
        this.isLoading$.next(false);

      });

  }
}
