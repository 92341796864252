import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleServices from '../../services';
import {CustomerAccountInvitationsActions} from '../actions/';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {OffersActions} from '../../../invoices/store';
import {RouterActions} from '../../../application-state/store/actions';

@Injectable()
export class CustomerAccountInvitationsEffects {

  constructor(private actions$: Actions, private service: fromModuleServices.CustomerAccountInvitationsService) {
  }


  CreateCustomerAccountInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.CreateCustomerAccountInvitation),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createCustomerAccountInvitation(payload).pipe(
        map(response => CustomerAccountInvitationsActions.CreateCustomerAccountInvitationSuccess({response})),
        catchError(response => of(CustomerAccountInvitationsActions.CreateCustomerAccountInvitationFail({response})))
      );
    })
  ));


  CreateCustomerAccountInvitationConfirmation$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.CreateCustomerAccountInvitationConfirmation),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createCustomerAccountInvitationConfirmation(payload).pipe(
        map(response => CustomerAccountInvitationsActions.CreateCustomerAccountInvitationConfirmationSuccess({response})),
        catchError(response => of(CustomerAccountInvitationsActions.CreateCustomerAccountInvitationConfirmationFail({response})))
      );
    })
  ));


  ReadCustomerAccountInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.ReadCustomerAccountInvitation),
    map(action => action),
    switchMap(({iri}) => {
      return this.service.readCustomerAccountInvitation(iri).pipe(
        map(response => CustomerAccountInvitationsActions.ReadCustomerAccountInvitationSuccess({response})),
        catchError(response => of(CustomerAccountInvitationsActions.ReadCustomerAccountInvitationFail({response})))
      );
    })
  ));


  ReadCustomerAccountInvitations$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.ReadCustomerAccountInvitations),
    map(action => action),
    switchMap(() => {
      return this.service.readCustomerAccountInvitations().pipe(
        map(response => CustomerAccountInvitationsActions.ReadCustomerAccountInvitationsSuccess({response})),
        catchError(response => of(CustomerAccountInvitationsActions.ReadCustomerAccountInvitationsFail({response})))
      );
    })
  ));


  UpdateCustomerAccountInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.UpdateCustomerAccountInvitation),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updateCustomerAccountInvitation(iri, payload).pipe(
        map(response => CustomerAccountInvitationsActions.UpdateCustomerAccountInvitationSuccess({response})),
        catchError(response => of(CustomerAccountInvitationsActions.UpdateCustomerAccountInvitationFail({response})))
      );
    })
  ));


  DeleteCustomerAccountInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.DeleteCustomerAccountInvitation),
    switchMap(({iri}) => {
      return this.service.deleteCustomerAccountInvitation(iri).pipe(
        map(() => CustomerAccountInvitationsActions.DeleteCustomerAccountInvitationSuccess({iri})),
        catchError(response => of(CustomerAccountInvitationsActions.DeleteCustomerAccountInvitationFail({response})))
      );
    })
  ));


  ActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerAccountInvitationsActions.CreateCustomerAccountInvitationSuccess,
      CustomerAccountInvitationsActions.UpdateCustomerAccountInvitationSuccess
    ),
    map(({type}) => {

      const text = type === CustomerAccountInvitationsActions.CREATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS
        ? `Eine Einladung für einen Kundenzugang wurde versendet.`
        : 'Die Einladung für den Kundenzugang wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));

  CreateCustomerAccountInvitationConfirmationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountInvitationsActions.CreateCustomerAccountInvitationConfirmationSuccess),
    map(() => RouterActions.Go({path: ['dashboard']}))
  ));
}
