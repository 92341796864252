import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import * as fromCustomersModuleModels from '../../../customers/models';
import {
  Customer,
  CustomerAddress,
  CustomerContact,
  CustomerContactType
} from '../../../customers/models';
import {
  AnalysisPriorityMode,
  DataRecoveryPriorityMode,
  Department,
  Discount,
  DisposalType,
  LabLocation,
  ReplacementDataMediumSource
} from '../../../master-data/models';
import { Order } from '../../../orders/models';
import { ShippingProvider } from '../../../shipping/models';
import { lowerFirst } from 'lodash-es';

import {
  getUuidFromIri,
  StringsUtility
} from '../../../shared/utilities/strings.utility';
import { Observable, Subject } from 'rxjs';
import { Ticket } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import * as CustomersModuleSelectors from '../../../customers/store/selectors';
import {
  CustomerContactsSelectors,
  CustomerContactTypesSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import { combineLatestArray } from 'rxjs-etc';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { OrdersSelectors } from '../../../orders/store/selectors';
import {
  AnalysisPriorityModesSelectors,
  DataRecoveryPriorityModesSelectors,
  DepartmentsSelectors,
  DiscountsSelectors,
  DisposalTypesSelectors,
  LabLocationsSelectors,
  ReplacementDataMediumSourcesSelectors
} from '../../../master-data/store/selectors';
import { ShippingProvidersToCustomerSelectors } from '../../../shipping/store/selectors';
import { TicketsSelectors } from '../../store/selectors';
import {
  AnalysisPriorityModesActions,
  DataRecoveryPriorityModesActions,
  DepartmentsActions,
  DiscountsActions,
  DisposalTypesActions,
  LabLocationsActions,
  ReplacementDataMediumSourcesActions
} from '../../../master-data/store';
import {
  CustomerAddressesActions,
  CustomerContactTypesActions,
  CustomersActions
} from '../../../customers/store';
import { ShippingProvidersActions } from '../../../shipping/store';
import { TicketDetailFormDialogComponent } from '../ticket-detail-form-dialog/ticket-detail-form-dialog.component';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-ticket-details',
  styleUrls: ['ticket-details.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Allgemeine Daten</span>

        <span class="btn--edit">
          <button mat-icon-button (click)="handleRequestShowTicketForm()">
            <mat-icon>edit</mat-icon>
          </button>
        </span>
      </div>

      <div class="card__content pos-relative">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="overview grid grid-no-gutter">
          <dl class="list--dl column-14 grid p-a--16">
            <dt class="column-5">Auftragsdatum:</dt>
            <dd class="column-9">
              {{ order?.createdAt | date }}
              <span
                class="mx-2"
                *ngIf="getDataRecoveryPriorityMode() !== 'UNSET'"
                >|</span
              >
              <span
                class="badge rounded-pill bg-secondary"
                *ngIf="
                  getDataRecoveryPriorityMode() !== 'EXPRESS' &&
                  getDataRecoveryPriorityMode() !== 'UNSET'
                "
                >{{ getDataRecoveryPriorityMode() }}</span
              >
              <span
                *ngIf="getDataRecoveryPriorityMode() === 'EXPRESS'"
                class="badge rounded-pill text-white bg-warning"
                >{{ getDataRecoveryPriorityMode() }}</span
              >
            </dd>

            <dt class="column-5">Ticket-Nr.:</dt>
            <dd class="column-9">{{ ticket?.ticketNumber }}</dd>

            <ng-container *ngIf="canOpenOrder">
              <dt class="column-5">Auftrags-Nr.:</dt>
              <dd class="column-9">
                <a
                  *ngIf="getOrderUUID(order)"
                  [routerLink]="['/orders', getOrderUUID(order)]"
                  class="text-decoration-none"
                >
                  {{ order?.orderNumber }}
                </a>
              </dd>
            </ng-container>
            <!--<span class="p-l--8">{{ getOrderPropertyName('analysisPriorityModesEntities', 'analysisPriorityMode') }}</span>-->

            <dt class="column-5">Referenz:</dt>
            <dd class="column-9">
              {{ order?.reference ? order.reference : '-' }}
            </dd>

            <dt class="column-5">Status:</dt>
            <dd class="column-9">
              <strong>{{ order?.stateRange }}</strong
              ><br />
              <span
                *ngFor="let key of order?.state | keys; let isLast = last"
                class="text-color-lightgrey"
              >
                {{ key }}<span *ngIf="!isLast">; </span>
              </span>
            </dd>

            <dt class="column-5">Bereich:</dt>
            <dd class="column-9">
              {{ getOrderPropertyName('departmentsEntities', 'department') }}
            </dd>

            <dt class="column-5">Kunde:</dt>
            <dd class="column-9">
              {{ order?.customer?.nameLine1 }}
              <ng-container *ngIf="order?.customer?.nameLine2"
                >[{{ order?.customer?.nameLine2 }}]
              </ng-container>
            </dd>
            <ng-container *ngFor="let t of customerAddressTypes">
              <strong class="column-5">{{ t.name }}</strong>
              <span class="column-9">
                {{ formatAddress(getAddress(t.customerAddressType)) }}
              </span>
            </ng-container>

            <dt class="column-5">Vermittler:</dt>
            <dd class="column-9">{{ getBrokerName() }}</dd>

            <dt class="column-5">Release Date:</dt>
            <dd class="column-9">{{ (order?.releasedAt | date) || '-' }}</dd>

            <dt class="column-5">Analyselabor:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName('labLocationsEntities', 'analysisLocation')
              }}
            </dd>

            <dt class="column-5">Datenrettungslabor:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'labLocationsEntities',
                  'dataRecoveryLocation'
                )
              }}
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <!--<pre>{{ ticket | json }}</pre>-->
    <!--<pre>{{ order | json }}</pre>-->
  `
})
export class TicketDetailsComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;
  @Input() ticket$: Observable<Ticket>;
  ticket: Ticket;

  addresses: Array<CustomerAddress>;
  contacts: Array<CustomerContact>;
  customer: Customer;

  isLoading$: Observable<boolean>;

  analysisPriorityModesEntities: { [iri: string]: AnalysisPriorityMode };
  customerContactTypes: Array<CustomerContactType> = [];
  dataRecoveryPriorityModesEntities: {
    [iri: string]: DataRecoveryPriorityMode;
  };
  departmentsEntities: { [iri: string]: Department };
  discountsEntities: { [iri: string]: Discount };
  disposalTypesEntities: { [iri: string]: DisposalType };
  labLocationsEntities: { [iri: string]: LabLocation };
  brokerEntities: { [iri: string]: Customer };
  replacementDataMediumSourcesEntities: {
    [iri: string]: ReplacementDataMediumSource;
  };
  shippingProvidersToCustomerEntities: { [iri: string]: ShippingProvider };

  @Output() requestShowTicketForm: EventEmitter<void> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();

  customerAddressTypes: Array<{ customerAddressType: string; name: string }> = [
    { customerAddressType: 'BillingAddress', name: 'Rechnungsadresse' },
    { customerAddressType: 'DeliveryAddress', name: 'Lieferadresse' }
  ];

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private auth: AuthService
  ) {}

  get canOpenOrder(): boolean {
    return (
      this.auth.isAdmin() ||
      this.auth.isSupervisor() ||
      this.auth.isPartnerManagement() ||
      this.auth.isSales() ||
      this.auth.isSalesExternal() ||
      this.auth.isTechnician() ||
      this.auth.isAccounting() ||
      this.auth.isLogistic()
    );
  }

  ngOnInit(): void {
    this.ticket$.pipe(takeUntil(this.onDestroy$)).subscribe(ticket => {
      this.ticket = ticket;
    });
    this.order$.pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      this.order = order;
      this.store$
        .select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, {
          customerIri: extractIri(order?.customer)
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(contacts => {
          this.contacts = contacts;
        });
      if (extractIri(order?.customer)) {
        this.store$.dispatch(
          CustomersActions.ReadCustomer({ iri: order?.customer['@id'] })
        );
        this.store$
          .select(CustomersSelectors.selectCustomerByIndex, {
            iri: order?.customer['@id']
          })
          .pipe(
            takeUntil(this.onDestroy$),
            filter(customer => !!customer)
          )
          .subscribe(customer => {
            this.customer = customer;
            this.store$.dispatch(
              CustomerAddressesActions.ReadCustomerAddresses({
                customerIri: extractIri(customer)
              })
            );
          });
        combineLatestArray([
          this.store$.select(
            CustomersModuleSelectors.CustomerAddressesSelectors
              .selectCustomerBillingAddressesByCustomerIri,
            { customerIri: order?.customer['@id'] }
          ),
          this.store$.select(
            CustomersModuleSelectors.CustomerAddressesSelectors
              .selectCustomerDeliveryAddressesByCustomerIri,
            { customerIri: order?.customer['@id'] }
          )
        ])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            this.addresses = [].concat(...data);
          });
      }
    });
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(TicketsSelectors.isLoading)
    ]);

    this.loadAnalysisPriorityModes();
    this.loadCustomerContactTypes();
    this.loadDataRecoveryPriorityModes();
    this.loadDepartments();
    this.loadDiscounts();
    this.loadDisposalTypes();
    this.loadLabLocations();
    this.loadReplacementDataMediumSources();
    this.loadShippingProviders();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getAddress(addressType: string): fromCustomersModuleModels.CustomerAddress {
    if (
      !this.customer ||
      !this.order ||
      !this.addresses ||
      this.addresses.length <= 0
    ) {
      return null;
    }
    const addressTypeWithFirstLetterLowerCase = lowerFirst(addressType);
    const AddressID = this.order[addressTypeWithFirstLetterLowerCase]
      ? this.order[addressTypeWithFirstLetterLowerCase]
      : this.customer[`default${addressType}`];
    return this.addresses.find(
      address => address && address['@id'] === AddressID
    )?.address;
  }

  formatAddress(address: fromCustomersModuleModels.Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Adresse gesetzt';
  }

  getOrderPropertyName(entitiesSet: string, orderProperty: string): string {
    if (
      !this[entitiesSet] ||
      !this.order ||
      !this?.order.hasOwnProperty(orderProperty)
    ) {
      return '-';
    }
    return this[entitiesSet][this.order[orderProperty]]?.name
      ? this[entitiesSet][this.order[orderProperty]].name
      : '-';
  }

  getDataRecoveryPriorityMode(): string {
    if (!this.order || !this.dataRecoveryPriorityModesEntities) {
      return '-';
    }
    if (!this.order.dataRecoveryPriorityMode) {
      return 'UNSET';
    }
    const id =
      this.order.dataRecoveryPriorityMode['@id'] ||
      this.order.dataRecoveryPriorityMode;
    return this.dataRecoveryPriorityModesEntities[id]?.name || 'UNSET';
  }

  getBrokerName(): string {
    if (!this.order || !this.brokerEntities) {
      return '';
    }
    if (!this.order.broker) {
      return '-';
    }
    return this.brokerEntities[this.order.broker['@id']]?.nameLine1 || '-';
  }

  handleRequestShowTicketForm(): void {
    this.dialog.open(TicketDetailFormDialogComponent, {
      data: { order$: this.order$, ticket$: this.ticket$ },
      panelClass: 'card-relative'
    });
  }

  getOrderUUID(order: Order): string {
    if (!order) {
      return null;
    }

    return getUuidFromIri(order['@id']);
  }

  private loadAnalysisPriorityModes(): void {
    this.store$
      .select(
        AnalysisPriorityModesSelectors.selectAnalysisPriorityModesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.analysisPriorityModesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
  }

  private loadCustomerContactTypes(): void {
    this.store$
      .select(CustomerContactTypesSelectors.selectCustomerContactTypes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.customerContactTypes = entities;
      });
    loadIfNotLoaded(
      this.store$,
      CustomerContactTypesSelectors.isLoaded,
      CustomerContactTypesActions.ReadCustomerContactTypes()
    );
  }

  private loadDataRecoveryPriorityModes(): void {
    this.store$
      .select(DataRecoveryPriorityModesSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.dataRecoveryPriorityModesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes()
    );
  }

  private loadDepartments(): void {
    this.store$
      .select(DepartmentsSelectors.selectDepartmentsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.departmentsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }

  private loadDiscounts(): void {
    this.store$
      .select(DiscountsSelectors.selectDiscountsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.discountsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }

  private loadDisposalTypes(): void {
    this.store$
      .select(DisposalTypesSelectors.selectDisposalTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.disposalTypesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }

  private loadLabLocations(): void {
    this.store$.select(LabLocationsSelectors.sEntities).subscribe(entities => {
      this.labLocationsEntities = entities;
    });
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  private loadReplacementDataMediumSources(): void {
    this.store$
      .select(
        ReplacementDataMediumSourcesSelectors.selectReplacementDataMediumSourcesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.replacementDataMediumSourcesEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ReplacementDataMediumSourcesSelectors.isLoaded,
      ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources()
    );
  }

  private loadShippingProviders(): void {
    this.store$
      .select(
        ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomerEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.shippingProvidersToCustomerEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToCustomerSelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToCustomer()
    );
  }
}
