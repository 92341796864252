import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import * as fromModuleModels from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { ShippingProvider } from '../../shipping/models';

@Injectable()
export class AcceptedOffersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createAcceptedOffer(payload: {
    file: File;
    uuid?: string;
  }): Observable<AbstractApiResponse | any> {
    // Manually create FormDate for proper image processing
    const formData: FormData = new FormData();
    formData.append('file', payload.file, payload.file.name);

    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }

    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_ACCEPTED_OFFER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/accepted_offers`, formData)
    );
  }

  readAcceptedOfferInformation(
    iri: string
  ): Observable<fromModuleModels.AcceptedOffer> {
    return this.rolesService.userHasRoleFilter<ShippingProvider>(
      'ROLE_MWS_ACCEPTED_OFFER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readAcceptedOfferAsPdf(offer: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ACCEPTED_OFFER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getPdf(
              offer + '/preview?disposition=attachment',
              true
            )
    );
  }

  readAcceptedOffers(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_ACCEPTED_OFFER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/accepted_offers`)
    );
  }

  deleteAcceptedOffer(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ACCEPTED_OFFER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
