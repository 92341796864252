import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { CurrenciesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { CurrenciesService } from '../../services/currencies.service';

@Injectable()
export class CurrenciesEffects {
  ReadCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrenciesActions.ReadCurrency),
      map(action => action),
      switchMap(({ iri }) => {
        return this.cs.readCurrency(iri).pipe(
          map(response => CurrenciesActions.ReadCurrency({ iri })),
          catchError(response =>
            of(CurrenciesActions.ReadCurrencyFail({ response }))
          )
        );
      })
    )
  );
  ReadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrenciesActions.ReadCurrencies),
      map(action => action),
      switchMap(() => {
        return this.cs.readCurrenciesForProject().pipe(
          map(response =>
            CurrenciesActions.ReadCurrenciesSuccess({ response })
          ),
          catchError(response =>
            of(CurrenciesActions.ReadCurrenciesFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CurrenciesActions.ReadCurrencyFail,
          CurrenciesActions.ReadCurrenciesFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case CurrenciesActions.READ_CURRENCY_FAIL:
              message =
                'Beim Lesen einer Währung sind Fehler aufgetreten:' + errors;
              break;
            case CurrenciesActions.READ_CURRENCIES_FAIL:
              message =
                'Beim Lesen der Währungen sind Fehler aufgetreten:' + errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cs: CurrenciesService,
    private notifierService: NotifierService
  ) {}
}
