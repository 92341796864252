import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FollowUpReasonAction } from '../actions';
import { FollowUpReasonsService } from '../../services';

@Injectable()
export class FollowUpReasonsEffects {
  getFollowUpReason$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FollowUpReasonAction.ReadFollowUpReason),
      map(action => action),
      switchMap(({ iri }) =>
        this.service.readFollowUpReason(iri).pipe(
          map(response =>
            FollowUpReasonAction.ReadFollowUpReasonSuccess({ response })
          ),
          catchError(response =>
            of(FollowUpReasonAction.ReadFollowUpReasonFail({ response }))
          )
        )
      )
    )
  );
  getFollowUpReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FollowUpReasonAction.ReadFollowUpReasons),
      map(action => action),
      switchMap(() =>
        this.service.readFollowUpReasons().pipe(
          map(response =>
            FollowUpReasonAction.ReadFollowUpReasonsSuccess({ response })
          ),
          catchError(response =>
            of(FollowUpReasonAction.ReadFollowUpReasonsFail({ response }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: FollowUpReasonsService
  ) {}
}
