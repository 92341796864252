import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {EMAIL_TEMPLATES, selectMasterDataModuleState} from '../reducers';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {EmailTemplate} from '../../models';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {ApplicationState} from "../../../application-state/store";
import {State} from "../reducers/email-templates.reducers";

type BaseType = EmailTemplate;
type StateType = State;
const NAMESPACE = EMAIL_TEMPLATES;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, any>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, any>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

export const sByCategoryAndContext = createSelector(
  sList,
  (list: EmailTemplate[], {
    category,
    context
  }) => list.filter(e => extractIri(e.emailTemplateCategory) === extractIri(category)
    && extractIri(e.templateContext) === extractIri(context)));

export const selectEmailTemplatesTotalItems = createSelector(
  sState,
  (state) => state.totalItems
);

export const selectEmailTemplatesPagination = createSelector(
  sState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectEmailTemplatesPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectEmailTemplatesPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectEmailTemplatesPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectEmailTemplatesPagination,
  pagination => pagination.next
);

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);

