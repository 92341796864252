import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { combineLatestObject } from 'rxjs-etc';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { CancellationInvoicesService } from '../../services';
import {
  CurrenciesSelectors,
  DepartmentsSelectors,
  ProductsSelectors,
  ProductUnitsSelectors,
  ServicesSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import {
  ErrorsObject,
  ErrorsUtility
} from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { OrdersActions } from '../../../orders/store';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { SendDocumentToolComponent } from '../../components';
import {
  CustomerAddressesActions,
  CustomersActions
} from '../../../customers/store';
import {
  CustomerAddressesSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import {
  CancellationInvoicesActions,
  DocumentDeliveriesActions,
  DocumentDeliveryProvidersActions,
  PayableInvoicesActions
} from '../../store';
import {
  CancellationInvoicesSelectors,
  DocumentDeliveriesSelectors,
  DocumentDeliveryProvidersSelectors,
  InvoiceItemsSelectors,
  InvoicePaymentsSelectors,
  PayableInvoicesSelectors
} from '../../store/selectors';
import { ActivatedRoute } from '@angular/router';
import {
  CancellationInvoice,
  CorrectionInvoice,
  DocumentDelivery,
  DocumentDeliveryProvider,
  InvoiceLike,
  PartialInvoice
} from '../../models';
import { extractIri } from '../../../shared/utilities/objects.utility';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { Administrator } from '../../../administrators/models';
import {
  Currency,
  Department,
  Product,
  ProductUnit,
  Service,
  Tax
} from '../../../master-data/models';
import {
  Address,
  Customer,
  CustomerAddress,
  CustomerContact
} from '../../../customers/models';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-cancellation-invoice-view',
  styleUrls: ['cancellation-invoice-view.component.scss'],
  template: `
    <div
      *ngIf="{
        departments: departments$ | async,
        administratorEntities: administratorEntities$ | async,
        documentDeliveryProviders: documentDeliveryProviders$ | async,
        invoice: invoice$ | async
      } as data"
      class="pos-relative"
    >
      <!-- Note: Only edit action possible -->
      <view-heading
        heading="Faktura: Stornorechnung bearbeiten"
        colRight="text-right"
      >
        <button
          mat-icon-button
          class="text-color-grey"
          (click)="openMailDialog()"
        >
          <mat-icon>mail</mat-icon>
        </button>
      </view-heading>

      <div class="invoice__outer" [formGroup]="form">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
        <div class="wrap invoice__wrap">
          <div class="invoice grid">
            <div class="column-9">
              <app-cancellation-invoice-preview
                [invoice$]="invoice$"
                (updateInvoice)="onUpdateInvoice($event)"
              ></app-cancellation-invoice-preview>
            </div>

            <div class="column-5 p-b--32">
              <div class="p-l--16 p-r--16">
                <app-send-document-tool
                  *ngIf="data.invoice"
                  [invoice]="data.invoice"
                  invoiceTypeName="Storno-Rechnung"
                  [errors]="selectErrorMessages('documentDeliveriesErrors')"
                  [documentDeliveryProviders]="data.documentDeliveryProviders"
                  (requestCreateRegisteredLetterDocumentDelivery)="
                    handleCreateRegisteredLetterDocumentDelivery(
                      $event,
                      data.invoice['@id']
                    )
                  "
                ></app-send-document-tool>
              </div>

              <!--<pre>{{ data.invoice | json }}</pre>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CancellationInvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;

  invoiceUUID: string = null;
  invoiceIri: string = null;
  isLoading$: Observable<boolean>;

  canceledInvoice: CorrectionInvoice | PartialInvoice = null;

  administratorEntities$: Observable<{ [iri: string]: Administrator }>;
  caf: FormGroup;
  cf: FormGroup;
  form: FormGroup;
  currencies$: Observable<Array<Currency>>;
  customer$: BehaviorSubject<Customer> = new BehaviorSubject(null);
  customerAddress$: BehaviorSubject<
    CustomerAddress | any
  > = new BehaviorSubject(null);
  customerAddressPaginationNextLink$: BehaviorSubject<
    string
  > = new BehaviorSubject(null);
  customerAddresses$: Observable<Array<CustomerAddress>>;
  customerContacts$: Observable<Array<CustomerContact>>;
  departmentAdUri: SafeUrl | any = null;
  departmentLogoUri: SafeUrl | any = null;
  departments$: Observable<Array<Department>>;
  documentDeliveryProviders$: Observable<Array<DocumentDeliveryProvider>>;
  dsf: FormGroup;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  invoice$: Observable<CancellationInvoice>;
  invoiceItemType$: BehaviorSubject<
    'custom' | 'product' | 'service'
  > = new BehaviorSubject(null);
  issuer$: BehaviorSubject<Department> = new BehaviorSubject(null);
  onDestroy$: Subject<any> = new Subject<any>();
  osf: FormGroup;
  paginationNextLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  productUnits$: Observable<Array<ProductUnit>>;
  productUnitsEntities$: Observable<{ [iri: string]: ProductUnit }>;
  products$: Observable<Array<Product>>;
  searchResults$: Observable<Array<Customer>>;
  services$: Observable<Array<Service>>;
  taxes$: Observable<Array<Tax>>;

  constructor(
    public dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fs: FormsService,
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private cis: CancellationInvoicesService,
    private activatedRoute: ActivatedRoute,
    private dls: DepartmentLogosService
  ) {}

  getHourDifferential(futureDate: string): number {
    const duration = moment.duration(
      moment(futureDate).diff(moment(new Date()))
    );
    return Math.floor(duration.asHours());
  }

  ngOnInit(): void {
    this.initForms();

    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        console.log('params', params);
        this.invoiceUUID = params.uuid;
        this.invoiceIri = '/api/cancellation_invoices/' + params.uuid;
        this.invoice$ = this.store$.select(
          CancellationInvoicesSelectors.selectCancellationInvoiceByIndex,
          { iri: this.invoiceIri }
        );
        this.invoice$
          .pipe(
            takeUntil(this.onDestroy$),
            filter(i => !!i)
          )
          .subscribe(invoice => {
            this.upsertCancellationInvoiceData(invoice);
            if (invoice.canceledInvoice) {
              this.store$.dispatch(
                PayableInvoicesActions.ReadPayableInvoice({
                  iri: extractIri(invoice.canceledInvoice)
                })
              );
            }
            if (invoice.customerNumber) {
              this.store$.dispatch(
                CustomersActions.ReadCustomers({
                  page: -1,
                  params: { id: invoice.customerNumber }
                })
              );
              this.store$
                .select(CustomersSelectors.sByCustomerNumber, {
                  customerNumber: invoice.customerNumber
                })
                .pipe(
                  filter(e => !!e),
                  take(1)
                )
                .subscribe(c => {
                  this.customer$.next(c);
                });
            }
          });
        this.store$.dispatch(
          CancellationInvoicesActions.ReadCancellationInvoice({
            iri: this.invoiceIri
          })
        );
      });

    this.isLoading$ = isLoadingArray([
      this.store$.select(CancellationInvoicesSelectors.isLoading),
      this.store$.select(PayableInvoicesSelectors.isLoading),
      this.store$.select(CustomersSelectors.isLoading),
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(CurrenciesSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(DocumentDeliveryProvidersSelectors.isLoading),
      this.store$.select(TaxesSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(ServicesSelectors.isLoading)
    ]);

    this.selectData();
    this.selectErrors();
    this.initSubscriptions();
    this.initActionListeners();
  }

  initForms(): void {
    this.form = this.fb.group({
      dueDate: this.fb.control(null, Validators.required),
      deliveryDate: this.fb.control(null, Validators.required),
      issuer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null, [Validators.required]),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null, Validators.required)
      }),
      customText: this.fb.control('')
    });

    this.osf = this.fb.group({
      order: this.fb.control(null)
    });

    this.cf = this.fb.group({
      customer: this.fb.control(null)
    });

    this.caf = this.fb.group({
      address: this.fb.control(null)
    });

    this.dsf = this.fb.group({
      issuer: this.fb.control(null)
    });
  }

  selectData(): void {
    // Observables
    this.administratorEntities$ = this.store$.select(
      AdministratorsSelectors.sEntities
    );
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.searchResults$ = this.store$.select(
      CustomersSelectors.selectSearchResults
    );
    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    this.documentDeliveryProviders$ = this.store$.select(
      DocumentDeliveryProvidersSelectors.selectDocumentDeliveryProviders
    );
    loadIfNotLoaded(
      this.store$,
      DocumentDeliveryProvidersSelectors.isLoaded,
      DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviders()
    );
    // this.orders$ = this.store$.pipe(select(OrdersSelectors.selectOrders));
    this.taxes$ = this.store$.select(TaxesSelectors.selectTaxes);
    this.products$ = this.store$.select(ProductsSelectors.sList);
    this.productUnits$ = this.store$.select(
      ProductUnitsSelectors.selectProductUnits
    );
    this.productUnitsEntities$ = this.store$.select(
      ProductUnitsSelectors.selectProductUnitsEntities
    );
    this.services$ = this.store$.select(ServicesSelectors.selectServices);

    // Behavior Subjects
    // this.store$.pipe(select(OrdersSelectors.selectCurrentOrder)).subscribe(this.order$);
  }

  selectErrors(): void {
    combineLatestObject({
      invoicePaymentsErrors: this.store$.pipe(
        select(InvoicePaymentsSelectors.selectErrors)
      ),
      cancellationInvoicesErrors: this.store$.pipe(
        select(CancellationInvoicesSelectors.selectErrors)
      ),
      cancellationInvoiceItemsErrors: this.store$.pipe(
        select(InvoiceItemsSelectors.selectErrors)
      ),
      documentDeliveriesErrors: this.store$.pipe(
        select(DocumentDeliveriesSelectors.selectErrors)
      )
    }).subscribe(this.errors$);
  }

  selectErrorMessages(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  initSubscriptions(): void {
    this.customer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(customer => !!customer)
      )
      .subscribe(customer => {
        this.handleUpdateFormsAfterCustomerChange(customer);

        // Note: if customer is restored we don't know @id
        if (customer.hasOwnProperty('@id')) {
          this.handleReadCustomerAddresses(customer['@id']);
        }
      });

    /*this.customerAddress$.pipe(
      takeUntil(this.onDestroy$),
      filter(address => !!address)
    ).subscribe((address) => {
      this.handleUpdateInvoiceRecipient([address]);
    });*/

    this.issuer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(issuer => !!issuer)
      )
      .subscribe(issuer => {
        this.form.get('issuer').setValue(issuer['@id']);
        this.dsf.get('issuer').setValue(issuer['@id']);
      });

    this.dialog.afterAllClosed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invoiceItemType$.next(null);
      });
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess
        )
      )
      .subscribe(({ type, invoiceIri = null }) => {
        const triggerRefresh = [
          DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS
        ];

        if (triggerRefresh.includes(type) && invoiceIri) {
          this.sitc.initForm();
          this.sitc.resetTypeSelect();
          this.sitc.documentDeliveryTarget$.next(null);
          this.store$.dispatch(
            CancellationInvoicesActions.ReadCancellationInvoice({
              iri: invoiceIri
            })
          );
        }
      });
  }

  upsertCancellationInvoiceData(invoice: CancellationInvoice): void {
    const existingIssuer = this.issuer$.getValue();

    // Prevent re-fetching issuer every time invoice is updated
    if (!!existingIssuer && existingIssuer['@id'] !== invoice.issuer['@id']) {
      this.issuer$.next(invoice.issuer);
    }

    this.customer$.next({
      nameLine1: invoice.recipient.nameLine1,
      nameLine2: invoice.recipient.nameLine2,
      customerNumber: invoice.customerNumber
    });
    this.customerAddress$.next({
      address: invoice.recipient.address,
      addressType: invoice.recipient.addressType
    });

    this.form.get('customText').setValue(invoice.customText);

    this.form.get('recipient.address').patchValue(invoice.recipient.address);
    if (invoice?.recipient?.addressType) {
      this.form
        .get('recipient.addressType')
        .patchValue(invoice.recipient.addressType['@id']);
    }
  }

  handleUpdateFormsAfterCustomerChange(customer: Customer): void {
    // Note: customerNumber does not exist in API model
    // this.cif.get('customerNumber').setValue(customer.customerNumber);

    // recipient.taxNumber
    if (customer?.taxNumber) {
      this.form.get('recipient.taxNumber').setValue(customer.taxNumber);
    }

    this.form.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.form.get('recipient.nameLine2').setValue(customer.nameLine2);

    // todo: auto-set default address if given?
    /*if(!!customer?.defaultBillingAddress) {
      // Check for and fetch default customer address if set
      this.caf.get('address').setValue(customer.defaultBillingAddress);
    }*/
  }

  handleFetchDepartmentAd(iri: string): void {
    this.departmentAdUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          ({ body: blob }) => {
            this.departmentAdUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleFetchDepartmentLogo(iri: string | null): void {
    this.departmentLogoUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(
          // tap(response => console.log(response)),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          ({ body: blob }) => {
            this.departmentLogoUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleReadCustomer(iri: string): void {
    this.store$.dispatch(CustomersActions.ReadCustomer({ iri }));
  }

  handleReadCustomerAddresses(customerIri: string): void {
    this.store$.dispatch(
      CustomerAddressesActions.ReadCustomerBillingAddresses({ customerIri })
    );
    this.customerAddresses$ = this.store$.select(
      CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
      { customerIri }
    );
  }

  handleResetCustomer(): void {
    this.customer$.next(null);
    this.customerAddress$.next(null);
    // todo customerContact
  }

  handleResetSearchResults(): void {
    // also reset current searchResults and loaded state of customers;
    // so customers are fetched anew when navigating from this component to customers-view component
    this.searchResults$.pipe(switchMap(items => of([])));
    this.store$.dispatch(CustomersActions.ResetIsLoaded());
  }

  handleReadCustomers(payload: {
    page: number;
    params: { [p: string]: number | boolean | string };
  }): void {
    this.store$.dispatch(CustomersActions.ReadCustomers(payload));
  }

  handleSelectCustomer(customer: Customer): void {
    // this.order$.next(null);
    if (customer) {
      this.handleReadCustomer(customer['@id']);
    } else {
      this.handleResetCustomer();
    }
  }

  handleUpdateCancellationInvoiceRecipient(
    selectedAddresses: Array<CustomerAddress> | any
  ): void {
    const customerAddress = selectedAddresses[0];
    const { address, addressType } = customerAddress;

    this.form.get('recipient.address').patchValue(address);
    this.form.get('recipient.addressType').patchValue(addressType['@id']);

    this.customerAddress$.next(customerAddress);
  }

  handleUpdateIssuer(selectedIssuers: Array<Department> | any): void {
    this.issuer$.next(selectedIssuers[0]);
  }

  openMailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'cancellation-invoice', entity$: this.invoice$ }
    });
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleDownloadCancellationInvoice(cancellationInvoice: string): void {
    this.cis
      .readCancellationInvoiceAsPdf(cancellationInvoice)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(response => !!response),
        catchError(error => throwError(cancellationInvoice))
      )
      .subscribe(
        ({ body: blob }) => {
          window.open(window.URL.createObjectURL(blob));
        },
        error => {
          console.log(error);
        }
      );
  }

  handleCreateRegisteredLetterDocumentDelivery(
    payload: DocumentDelivery,
    cancellationInvoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery({
        payload,
        invoiceIri: cancellationInvoice
      })
    );
  }

  onUpdateInvoice($event: any): void {
    this.handleUpdateInvoice(extractIri($event), $event);
  }

  handleUpdateInvoice(invoice: string, iPayload?: InvoiceLike): void {
    const payload = {
      iri: invoice,
      payload: iPayload ? iPayload : this.form.value
    };
    this.store$.dispatch(
      CancellationInvoicesActions.UpdateCancellationInvoice(payload)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    this.store$.dispatch(OrdersActions.ResetCurrentOrder());
    this.store$.dispatch(CustomersActions.ResetCurrentCustomer());
    this.store$.dispatch(
      CancellationInvoicesActions.ResetCurrentCancellationInvoice()
    );
  }
}
