<div class="wrapper p-y--8">
  <div class="widget card">
    <div class="widget_header ps-1">
      <span>
        <button (click)="loadData()" mat-icon-button>
              <mat-icon>autorenew</mat-icon>
            </button>
        Rückfragen Erhalten
      </span>
      <div class="spacer"></div>
    </div>
    <div class="widget_content" style="position: relative;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <table class="bmo-table bmo-table-hover table-dense bmo-table-clickable">
        <tbody>
        <tr (click)="handleClick(feedback)" *ngFor="let feedback of (feedbacks$|async| slice: lowValue : highValue)">
          <td class="p-l--16" style="width: 50px;">
            <div [ngClass]="['yellow']" class="circle"></div>

          </td>
          <td class="" style="width: 70px;">
            {{ feedback.relatedIdentifier }}
          </td>
          <td class="" style="width: 100px;">
            {{ feedback.relatedInstanceType }}
          </td>
          <td class="" style="">
            {{ feedback.comment.content|stripTags|optionalSlice:50 }}
          </td>
          <td style="width: 170px;">
            <span
              [matTooltip]="feedback.createdAt|date:'dd.MM.YYYY'"
              matTooltipPosition="above"
            >{{ feedback.createdAt|momentDuration }}
            </span>
          </td>

        </tr>
        <tr *ngIf="(feedbacks$|async).length<=0">
          <td class="text-center">
            <span>Keine Anfragen vorhanden</span>
          </td>
        </tr>

        </tbody>
      </table>

    </div>
    <div class="widget_footer">
      <app-paginator-unstyled
        (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
        [itemsPerPage]="'Rechnungen pro Seite'"
        [pageSizeOptions]="[5,10,15,20]"
        [pageSize]="5"
        [showFirstLastButtons]="true"
        [totalItems]="(feedbacks$|async).length"
      ></app-paginator-unstyled>

    </div>

  </div>
</div>
