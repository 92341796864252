import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserProfileSelectors} from "../../../auth/store/selectors";
import {filter, takeUntil} from "rxjs/operators";
import {extractIri} from "../../../shared/utilities/objects.utility";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {Subject} from "rxjs";
import {ParameterBag} from "../../../shared/models/ParameterBag.interface";
import {Order} from "../../models";

@Component({
  selector: 'app-orders-data-carriers-return-widget',
  templateUrl: './orders-data-carriers-return-widget.component.html',
  styleUrls: ['./orders-data-carriers-return-widget.component.scss']
})
export class OrdersDataCarriersReturnWidgetComponent implements OnInit, OnDestroy {

  salesperson: string;
  additionalParams: ParameterBag =
  {
    'awaitingShipmentDirection.id': 'SHIPMENT_DIRECTION_DR_EXT_TO_DR_DE',
    'awaitingShipmentLabLocation.id': 'LAB_LOCATION_DR_DE'
  };
  customServiceFnName = 'readAwaitingShipmentOrders';
  onDestroy$: Subject<any> = new Subject<any>();
  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }

}
