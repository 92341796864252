import {Component, Input, OnInit, Optional} from '@angular/core';
import {OrderShipment} from '../../../orders/models/order-shipment.interface';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder,
  isVipFromOrder,
  showPartnerLabelForOrder
} from '../../../orders/helpers';
import {Order} from '../../../orders/models';
import {extractIri} from '../../utilities/objects.utility';
import {getUuidFromIri} from '../../utilities/strings.utility';
import {Observable} from 'rxjs';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrdersSelectors} from '../../../orders/store/selectors';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {OrdersActions} from '../../../orders/store';

@Component({
  selector: 'app-stock-item-order-display-line',
  styleUrls: ['./stock-item-order-display-line.component.scss'],
  template: `
    <div class="pos-relative" style="min-height: 20px;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <ng-container *ngIf="order">
        <p class="text-large"><a [routerLink]="['/orders', getUuidFromIri(extractIri(order))]"> Auftrag
          #{{ (order)?.orderNumber }}</a>
          <small *ngIf="order.customer.customerType === '/api/customer_types/0'" class="ps-2">Geschäftskunde</small>
          <small *ngIf="order.customer.customerType === '/api/customer_types/1'" class="ps-2">Privatkunde</small>
        </p>
        <p>
          {{ getCustomerName(order) }} <span class="" *ngIf="getCompanyName(order)">-  {{getCompanyName(order)}}</span>
          <span class="badge--vip rounded-pill" *ngIf="isVip(order)">
            <span class="material-icons inline-icon">done</span>
            <span class="text"> VIP</span>
          </span>
          <span class="mx-3 badge rounded-pill bg-primary small" *ngIf="showPartnerLabel(order)">
            <small>PARTNER</small>
          </span>
        </p>
      </ng-container>
    </div>
  `
})
export class StockItemOrderDisplayLineComponent extends BaseOnDestroyComponent implements OnInit {
  @Input() @Optional() orderIri: string;
  @Input() @Optional() order: Order | OrderShipment = null;
  showPartnerLabel = showPartnerLabelForOrder;
  getCompanyName = getCompanyNameFromOrder;
  getCustomerName = getCustomerNameFromOrder;
  isVip = isVipFromOrder;
  extractIri = extractIri;
  getUuidFromIri = getUuidFromIri;
  isLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
  ) {
    super();

  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(OrdersSelectors.isLoading);
    if (this.orderIri) {
      this.loadOrder(this.orderIri);
    }
  }

  loadOrder(orderIri): void {
    this.store$.select(OrdersSelectors.sDetailedByIri, {iri: orderIri}).pipe(
      takeUntil(this.onDestroy$),
      tap(order => {
        if (!order) {
          this.store$.dispatch(OrdersActions.ReadOrder({iri: orderIri}));
        }
      }),
      filter(e => !!e)
    ).subscribe(order => {
      this.order = order;
    });
  }

}
