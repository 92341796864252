import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';


import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Order} from '../../models';
import {DataRecoveryCostsActions} from '../../store';
import {AnalysisResultsActions} from '../../../master-data/store';
import {DataRecoveryCostsSelectors} from '../../store/selectors';
import {extractIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-analysis-result',
  styleUrls: ['analysis-result.component.scss'],
  template: `

    <!--    <div class="grid">-->
    <!--      <div class="column-10">-->
    <!--        <span class="heading&#45;&#45;h3">Technisches Analyse-Ergebnis</span>-->
    <!--      </div>-->
    <!--      <div class="column-4 text-right">-->
    <!--        <button mat-icon-button (click)="toggleEdit()">-->
    <!--          <mat-icon>{{showEdit ? 'close' : 'edit'}}</mat-icon>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--      <div class="column-14 box-with-rounded-border result p-2">-->
    <!--        <div [innerHTML]="order?.analysisResult" *ngIf="!showEdit && order?.analysisResult"></div>-->
    <!--        <div *ngIf="!showEdit && !(order?.analysisResult)"> Kein Analyse-Ergebnis eingetragen</div>-->
    <!--        <app-analysis-result-form [order$]="order$" *ngIf="showEdit"-->
    <!--                                  [hideSaveBtn]="true"-->
    <!--                                  (requestUpdateOrder)="updateFormData.emit($event.payload)"></app-analysis-result-form>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="grid">
      <div class="column-4">
        <span class="heading--h3">Analyseergebnis für Intern</span>
      </div>
      <div class="column-6">
        <span class="heading--h3">Analyseergebnis für Kunden</span>
      </div>
      <div class="column-4 text-right">
        <button mat-icon-button (click)="toggleEdit()">
          <mat-icon>{{showEdit ? 'close' : 'edit'}}</mat-icon>
        </button>
      </div>
      <div class="column-4  pe-1 pos-relative result">
        <app-loading-overlay *ngIf="loadingTechnicalResult$|async"></app-loading-overlay>
        <div class=" box-with-rounded-border p-2" *ngFor="let entry of technicalAnalysisResult">
          <span class="text-color-grey">Analyse vom {{entry.date|date: 'dd.MM.Y'}}:</span>
          <ng-container *ngIf="entry.purchasePriceComment">
            <br>
            <strong>EK-Kommentar:</strong>
            <br>
            <div class="pre-like">{{entry.purchasePriceComment}}</div>
          </ng-container>
          <ng-container *ngIf="entry.sellingPriceComment">
            <br>
            <strong>VK-Kommentar:</strong>
            <br>
            <div class="pre-like">{{entry.sellingPriceComment}}</div>
          </ng-container>
          <div *ngIf="!entry.purchasePriceComment && !entry.sellingPriceComment"> Kein Text eingegeben</div>

        </div>
        <div *ngIf="!(technicalAnalysisResult?.length>0)"> Kein Analyseergebnis eingetragen</div>
      </div>
      <div class="column-10  pe-1 result">
        <div class="pos-relative result box-with-rounded-border p-2">
          <div [innerHTML]="order?.analysisResult" *ngIf="!showEdit && order?.analysisResult"></div>
          <div *ngIf="!showEdit && !(order?.analysisResult)"> Kein Analyseergebnis eingetragen</div>
          <app-analysis-result-form [order$]="order$" *ngIf="showEdit"
                                    [hideSaveBtn]="true"
                                    (requestUpdateOrder)="updateFormData.emit($event.payload)"></app-analysis-result-form>
        </div>
      </div>
    </div>
  `
})
export class AnalysisResultComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;
  order: Order;
  technicalAnalysisResult: { date: string; sellingPriceComment: string; purchasePriceComment: string }[] = [];
  loadingTechnicalResult$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  showEdit = false;
  @Output() updateFormData: EventEmitter<any> = new EventEmitter();

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.actions$.pipe(takeUntil(this.onDestroy$), ofType(AnalysisResultsActions.UpdateAnalysisResultSuccess)).subscribe(() => {
      this.showEdit = false;
    });

    this.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      this.order = order;
    });
    this.loadingTechnicalResult$ = this.store$.select(DataRecoveryCostsSelectors.isLoading);
    this.store$.select(DataRecoveryCostsSelectors.sByOrder(extractIri(this.order))).subscribe((results) => {
      if (results?.length > 0) {
        this.technicalAnalysisResult = results.map(e => ({
          date: e.createdAt,
          purchasePriceComment: e.purchasePriceComment,
          sellingPriceComment: e.sellingPriceComment
        }));
      }
    });
    if (this.order.dataRecoveryCosts?.length) {
      const iri = extractIri(this.order.dataRecoveryCosts[this.order.dataRecoveryCosts.length - 1]);
      this.store$.dispatch(DataRecoveryCostsActions.ReadDataRecoveryCosts({iri}));

    }

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  toggleEdit(): void {
    this.showEdit = !this.showEdit;
  }

  handleRequestUpdateOrderAnalysisResult(): void {
  }
}
