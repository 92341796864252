import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_STORAGE_SYSTEM_MANUFACTURER = '[Master Data] Create Storage System Manufacturer';
export const CREATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS = '[Master Data] Create Storage System Manufacturer Success';
export const CREATE_STORAGE_SYSTEM_MANUFACTURER_FAIL = '[Master Data] Create Storage System Manufacturer Fail';
export const CreateStorageSystemManufacturer = createAction(
  CREATE_STORAGE_SYSTEM_MANUFACTURER,
  props<{ payload: fromModuleModels.StorageSystemManufacturer }>()
);
export const CreateStorageSystemManufacturerSuccess = createAction(
  CREATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
  props<{ response: fromModuleModels.StorageSystemManufacturer }>()
);
export const CreateStorageSystemManufacturerFail = createAction(
  CREATE_STORAGE_SYSTEM_MANUFACTURER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_STORAGE_SYSTEM_MANUFACTURERS = '[Master Data] Read Storage System Manufacturers';
export const READ_STORAGE_SYSTEM_MANUFACTURERS_SUCCESS = '[Master Data] Read Storage System Manufacturers Success';
export const READ_STORAGE_SYSTEM_MANUFACTURERS_FAIL = '[Master Data] Read Storage System Manufacturers Fail';
export const ReadStorageSystemManufacturers = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURERS
);
export const ReadStorageSystemManufacturersSuccess = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURERS_SUCCESS,
  props<{ response: any }>()
);
export const ReadStorageSystemManufacturersFail = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_STORAGE_SYSTEM_MANUFACTURER = '[Master Data] Read Storage System Manufacturer';
export const READ_STORAGE_SYSTEM_MANUFACTURER_SUCCESS = '[Master Data] Read Storage System Manufacturer Success';
export const READ_STORAGE_SYSTEM_MANUFACTURER_FAIL = '[Master Data] Read Storage System Manufacturer Fail';
export const ReadStorageSystemManufacturer = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURER,
  props<{ iri: string }>()
);
export const ReadStorageSystemManufacturerSuccess = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
  props<{ response }>()
);
export const ReadStorageSystemManufacturerFail = createAction(
  READ_STORAGE_SYSTEM_MANUFACTURER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_STORAGE_SYSTEM_MANUFACTURER = '[Master Data] Update Storage System Manufacturer';
export const UPDATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS = '[Master Data] Update Storage System Manufacturer Success';
export const UPDATE_STORAGE_SYSTEM_MANUFACTURER_FAIL = '[Master Data] Update Storage System Manufacturer Fail';
export const UpdateStorageSystemManufacturer = createAction(
  UPDATE_STORAGE_SYSTEM_MANUFACTURER,
  props<{ iri: string, payload: fromModuleModels.StorageSystemManufacturer }>()
);
export const UpdateStorageSystemManufacturerSuccess = createAction(
  UPDATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
  props<{ response: fromModuleModels.StorageSystemManufacturer }>()
);
export const UpdateStorageSystemManufacturerFail = createAction(
  UPDATE_STORAGE_SYSTEM_MANUFACTURER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_STORAGE_SYSTEM_MANUFACTURER = '[Master Data] Delete Storage System Manufacturer';
export const DELETE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS = '[Master Data] Delete Storage System Manufacturer Success';
export const DELETE_STORAGE_SYSTEM_MANUFACTURER_FAIL = '[Master Data] Delete Storage System Manufacturer Fail';
export const DeleteStorageSystemManufacturer = createAction(
  DELETE_STORAGE_SYSTEM_MANUFACTURER,
  props<{ iri: string }>()
);
export const DeleteStorageSystemManufacturerSuccess = createAction(
  DELETE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteStorageSystemManufacturerFail = createAction(
  DELETE_STORAGE_SYSTEM_MANUFACTURER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
