import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as fromInvoicesModuleModels from '../../../invoices/models';

@Component({
  selector: 'customer-offer-list',
  styleUrls: ['customer-offer-list.component.scss'],
  template: `

    <div class="card">
      <div class="card__heading">
        <span>Angebote für diesen Kunden</span>
      </div>

      <div class="card__content">
        <div class="order-list__outer">
          <div class="order-list__wrapper wrap">
            <div class="order-list grid">

              <div class="column-14">

                <div *ngIf="!items?.length; else table" style="padding: 24px;">
                  Keine Datensätze vorhanden
                </div>

                <ng-template #table>

                  <table *ngIf="items?.length" class="table" mat-table [dataSource]="items">

                    <!-- Number Column -->
                    <ng-container matColumnDef="offerNumber">
                      <th mat-header-cell *matHeaderCellDef>Nr.</th>
                      <td mat-cell *matCellDef="let element">{{ element.offerNumber }}</td>
                    </ng-container>

                    <!-- createdAt Column -->
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef>Angebot vom</th>
                      <td mat-cell *matCellDef="let element">{{ element.createdAt | date:'dd.MM.Y - HH:mm' }} Uhr</td>
                    </ng-container>

                    <!-- Gross Total Column -->
                    <ng-container matColumnDef="grossTotal">
                      <th mat-header-cell *matHeaderCellDef>Gesamtsumme</th>
                      <td mat-cell
                          *matCellDef="let element">{{ element.grossTotal.value | number:'1.2-2' }} {{ element.grossTotal.currency|currencyShortener }}</td>
                    </ng-container>

                    <!-- validUntil Column -->
                    <ng-container matColumnDef="validUntil">
                      <th mat-header-cell *matHeaderCellDef>Gültig bis</th>
                      <td mat-cell *matCellDef="let element">
                        <strong [class.red]="!!!element.confirmedAt">
                          {{ element.validUntil | date:'dd.MM.Y' }} Uhr
                        </strong>
                      </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">

                        <button mat-icon-button (click)="handleRequestViewCustomerOffer(element['@id'])">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CustomerOfferListComponent implements OnInit {

  @Input() items: Array<fromInvoicesModuleModels.Offer>;

  @Output()
  requestFetchCustomerOffers: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestViewCustomerOffer: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['offerNumber', 'createdAt', 'grossTotal', 'validUntil', 'actions'];

  ngOnInit(): void {
    this.requestFetchCustomerOffers.emit();
  }

  handleRequestViewCustomerOffer(offer: string): void {
    this.requestViewCustomerOffer.emit(offer);
  }

  isInTheRed(value: string): boolean {
    return parseFloat(value) > 0;
  }
}
