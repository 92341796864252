import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  styleUrls: ['dialog.component.scss'],
  template: `

    <h1 mat-dialog-title *ngIf="data.heading">{{data.heading}}</h1>
    <button *ngIf="data.closeButtonTop" mat-mini-fab mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>

    <div mat-dialog-content>
      <div [innerHTML]="data.text | safeHtml" [class]="data?.cssClass" [style]="data?.styles"></div>
    </div>

    <div mat-dialog-actions>
      <button *ngIf="!data.closeButtonTop" mat-button
              mat-dialog-close>{{ data.cancelText ? data.cancelText : 'Close' }}</button>
      <button *ngIf="data.confirmationText" mat-button [mat-dialog-close]="true">{{ data.confirmationText }}</button>
    </div>

    <!--    <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">No Thanks</button>
          <button mat-button [mat-dialog-close]="data.animal">Ok</button>
        </div>-->
  `
})
export class DialogComponent {

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
