import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { StorageLocation } from '../../models';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StorageLocationsSelectors } from '../../store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { StorageLocationsActions } from '../../store';
import { extractIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-storage-location-select',
  styleUrls: ['./storage-location-select.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => StorageLocationSelectComponent),
  //     multi: true
  //   }
  // ],
  template: `
    <div [formGroup]="form" class="mat-form-field">
      <ng-select
        [class.ng-invalid]="form.get('selectedElement')?.errors?.required"
        [class.required]="
          required || form.get('selectedElement')?.errors?.required
        "
        formControlName="selectedElement"
        [items]="items$ | async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$ | async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        [readonly]="readonly"
        [required]="required"
        placeholder="Lagerplatz"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.storageLocationNumber }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.storageLocationNumber }}
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error
          fieldName="selectedElement"
          [formGroup]="form"
        ></app-form-error>
      </mat-error>
    </div>
  `
})
export class StorageLocationSelectComponent extends BaseOnDestroyComponent
  implements OnInit, ControlValueAccessor {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<
    StorageLocation
  > = new EventEmitter<StorageLocation>();
  @Input() required = false;
  @Input() readonly = false;

  items$: Observable<Array<StorageLocation>>;
  isLoading$: Observable<boolean>;
  selectedItem: StorageLocation;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    @Self() @Optional() public control: NgControl
  ) {
    super();
    this.form = this.fb.group({
      selectedElement: this.fb.control(null)
    });
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  ngOnInit(): void {
    if (this.required) {
      this.form.get('selectedElement').setValidators([Validators.required]);
      // this.form.updateValueAndValidity();
      // this.form.markAllAsTouched();
    }
    this.form
      .get('selectedElement')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });
    if (this.control) {
      // this.form.get('input').setValidators(this.control.validator);

      this.control.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(data => {
          console.log(data, this.control.control, this.control.control.errors);
          this.form.controls.selectedElement.setErrors(
            this.control.control.errors
          );
          if (this.control.control.touched) {
            this.form.controls.selectedElement.markAllAsTouched();
          }
        });
    }
    this.items$ = this.store$.select(StorageLocationsSelectors.sList);
    this.isLoading$ = this.store$.select(StorageLocationsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      StorageLocationsSelectors.isLoaded,
      StorageLocationsActions.ReadStorageLocations()
    );
  }

  setSelectedItem(element: StorageLocation): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: StorageLocation): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (
        item.storageLocationNumber.toLowerCase().indexOf(t.toLowerCase()) > -1
      );
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }
}
