import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatestObject } from 'rxjs-etc';
import { select, Store } from '@ngrx/store';

import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import {
  ErrorsObject,
  ErrorsUtility
} from '../../../shared/utilities/error-utility.utility';
import { ModalDialogOptions } from '../../../application-state/models';
import {
  DocumentDeliveryProvidersSelectors,
  OfferItemsSelectors,
  OffersSelectors,
  PaymentProcessesSelectors
} from '../../store/selectors';
import { OffersService } from '../../services';
import { OrdersActions } from '../../../orders/store';
import { OrdersSelectors } from '../../../orders/store/selectors';
import {
  PaymentRecorderComponent,
  SendDocumentToolComponent
} from '../../components';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import { ActivatedRoute } from '@angular/router';
import {
  DocumentDeliveriesActions,
  OfferItemsActions,
  OffersActions,
  PaymentProcessesActions
} from '../../store';
import {
  CustomerAddressesActions,
  CustomersActions
} from '../../../customers/store';
import {
  CustomerAddressesSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CurrenciesSelectors,
  DepartmentsSelectors,
  ProductsSelectors,
  ProductUnitsSelectors,
  ServicesSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import {
  CorrectionInvoiceItem,
  DocumentDelivery,
  Offer,
  OfferItem,
  PaymentProcess
} from '../../models';
import { Address, Customer, CustomerAddress } from '../../../customers/models';
import {
  Department,
  Product,
  ProductUnit,
  Service,
  Tax
} from '../../../master-data/models';
import { Order } from '../../../orders/models';
import { ProductsActions, ServicesActions } from '../../../master-data/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-offer-view',
  styleUrls: ['offer-view.component.scss'],
  template: `
    <view-heading
      [heading]="'Faktura: Angebot bearbeiten'"
      colRight="text-right"
    >
      <span>
        <button
          mat-icon-button
          class="text-color-grey"
          (click)="openMailDialog()"
        >
          <mat-icon>mail</mat-icon>
        </button>
      </span>
    </view-heading>

    <div class="invoice__outer pos-relative" [formGroup]="of">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

      <div class="wrap invoice__wrap">
        <div class="invoice grid">
          <div class="column-9">
            <app-offer-preview
              [offer$]="offer$"
              (updateInvoice)="handleUpdateOffer($event)"
            ></app-offer-preview>

            <!--            <div class="template__outer" [class.draft]="!offer">-->

            <!--              <div class="template__wrap">-->
            <!--                <div class="template grid grid-no-gutter">-->

            <!--                  <div class="column-10">-->

            <!--                    <div class="countdown">-->
            <!--                      <ng-container *ngIf="offer?.editable && !!offer?.editableUntil">-->
            <!--                        Angebot kann noch {{ offer.editableUntil }}h bearbeitet werden.-->
            <!--                      </ng-container>-->
            <!--                    </div>-->

            <!--                    <app-invoice-action-box [invoice]="offer"></app-invoice-action-box>-->

            <!--                    <app-invoice-head-recipient [invoice$]="offer$"></app-invoice-head-recipient>-->
            <!--                  </div>-->

            <!--                  <div class="column-4">-->

            <!--                    <app-invoice-head-issuer [invoice$]="offer$"></app-invoice-head-issuer>-->
            <!--                  </div>-->

            <!--                  <div class="column-14 m-t&#45;&#45;32 m-b&#45;&#45;8 grid grid-no-gutter">-->
            <!--                    <div class="column-7">-->
            <!--                      <span class="heading&#45;&#45;h2">Angebot / Offer {{ offer?.offerNumber }}</span>-->
            <!--                    </div>-->

            <!--                    <div class="column-7 grid-right">-->
            <!--                        <span class="heading&#45;&#45;h2"-->
            <!--                              *ngIf="offer?.createdAt">Datum/Date: {{ offer.createdAt | date: 'dd.MM.Y' }}</span>-->
            <!--                    </div>-->
            <!--                  </div>-->

            <!--                  <div class="column-14 m-b&#45;&#45;64">-->
            <!--                    <p class="m-b&#45;&#45;64">Vielen Dank für Ihr Interesse.<br>-->
            <!--                      Wir bieten Ihnen folgende Lieferungen und Leistungen:-->
            <!--                    </p>-->

            <!--                    <app-invoice-items-summary-->
            <!--                      *ngIf="(productUnitsEntities$ | async) as unitsEntities"-->
            <!--                      [invoice$]="offer$"-->
            <!--                      (requestShowInvoiceItemForm)="handleShowOfferItemForm(oifm, $event)"-->
            <!--                      (requestUpdateInvoice)="handleUpdateOffer(offer['@id'], $event)"-->
            <!--                    ></app-invoice-items-summary>-->
            <!--                  </div>-->

            <!--                  <div class="column-8 p-r&#45;&#45;12">-->

            <!--                    <button-->
            <!--                      [disabled]="!offer"-->
            <!--                      (click)="handleShowOfferItemForm(oifm)"-->
            <!--                      class="m-b&#45;&#45;32" mat-flat-button color="green">-->
            <!--                      <mat-icon class="m-r&#45;&#45;16" style="vertical-align: middle">add</mat-icon>-->
            <!--                      <span class="heading&#45;&#45;h3 m-b&#45;&#45;0">Position zum Angebot hinzufügen</span>-->
            <!--                    </button>-->

            <!--                    <p class="gray">Angebot gültig bis {{ of.value.validUntil | date: 'dd.MM.Y' }}</p>-->
            <!--                  </div>-->

            <!--                  <div class="column-6">-->
            <!--                    <app-invoice-total [invoice]="offer"></app-invoice-total>-->
            <!--                  </div>-->

            <!--                  <div class="column-14 m-t&#45;&#45;32" style="position: relative">-->

            <!--                    <mat-form-field class="white" appearance="outline">-->

            <!--                      <mat-label>Individueller Text</mat-label>-->
            <!--                      <textarea-->
            <!--                        matTextareaAutosize-->
            <!--                        matInput rows="10"-->
            <!--                        formControlName="customText"-->
            <!--                      ></textarea>-->
            <!--                    </mat-form-field>-->

            <!--                    <button-->
            <!--                      *ngIf="offer && of.get('customText').dirty"-->
            <!--                      (click)="handleUpdateOffer(offer['@id'])"-->
            <!--                      style="position: absolute; right: 7px; bottom: 30px; border-radius: 4px !important;"-->
            <!--                      mat-button color="green">Änderungen speichern-->
            <!--                    </button>-->
            <!--                  </div>-->

            <!--                  <div class="column-14">-->

            <!--                    <app-invoice-footer [invoice$]="offer$"></app-invoice-footer>-->
            <!--                  </div>-->
          </div>

          <div class="column-5 p-b--32">
            <div class="p-l--16 p-r--16">
              <app-offer-details
                [offer]="offer"
                (requestDownloadAcceptedOffer)="
                  handleDownloadAcceptedOffer($event)
                "
                [loading]="uploading"
                [formGroup]="of"
              ></app-offer-details>

              <app-send-document-tool
                *ngIf="offer"
                [invoice]="offer"
                invoiceTypeName="Angebot"
                (requestCreateEmailDocumentDelivery)="
                  handleCreateEmailDocumentDelivery($event, offer['@id'])
                "
                (requestCreateLetterXPressDocumentDelivery)="
                  handleCreateLetterXPressDocumentDelivery($event, offer['@id'])
                "
                (requestCreateRegisteredLetterDocumentDelivery)="
                  handleCreateRegisteredLetterDocumentDelivery(
                    $event,
                    offer['@id']
                  )
                "
                (requestDownloadInvoice)="handleDownloadOffer($event)"
              ></app-send-document-tool>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      <ng-template #dsfm>-->

    <!--        <app-dialog-header>-->
    <!--          <h2>Abteilung wählen</h2>-->
    <!--        </app-dialog-header>-->

    <!--        <div class="issuer-list" mat-dialog-content style="min-width: 450px;" [formGroup]="dsf">-->

    <!--          <ng-select-->
    <!--            [items]="data.departments"-->
    <!--            placeholder="Abteilung wählen"-->
    <!--            bindLabel="name"-->
    <!--            bindValue="@id"-->
    <!--            #departmentSelector-->
    <!--            [clearable]="false"-->
    <!--            [markFirst]="false"-->
    <!--            formControlName="issuer"-->
    <!--            appendTo="body"-->
    <!--          ></ng-select>-->

    <!--          <div class="m-ta&#45;&#45;2">-->

    <!--            <button [disabled]="!departmentSelector.hasValue"-->
    <!--                    mat-flat-button color="green"-->
    <!--                    (click)="handleUpdateIssuer(departmentSelector.selectedValues)">-->

    <!--              <mat-icon>save</mat-icon>-->
    <!--              <span>Speichern</span>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </ng-template>-->

    <!--      <ng-template #oifm>-->

    <!--        <div class="dialog__header grid grid-no-gutter">-->
    <!--          <div class="column-12">-->
    <!--            <h2>{{ getOfferItemModalHeading() }}</h2>-->
    <!--          </div>-->
    <!--          <div class="column-2 m-ta&#45;&#45;2">-->
    <!--            <button mat-icon-button mat-dialog-close>-->
    <!--              <mat-icon>close</mat-icon>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div mat-dialog-content>-->

    <!--          <div *ngIf="!!!invoiceItemType$.getValue()" style="min-width: 450px">-->

    <!--            <ng-select-->
    <!--              class="m-b&#45;&#45;16"-->
    <!--              #itemTypeSelector-->
    <!--              [items]="invoiceTypeOptions"-->
    <!--              bindLabel="label"-->
    <!--              bindValue="value"-->
    <!--              appendTo="body"-->
    <!--              placeholder="Art der Position auswählen"-->
    <!--            ></ng-select>-->

    <!--            <div class="m-ta&#45;&#45;2">-->
    <!--              <button mat-flat-button color="green"-->
    <!--                      (click)="invoiceItemType$.next(itemTypeSelector.selectedValues[0]['value'])">-->
    <!--                <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--                <span>Weiter</span>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <app-invoice-item-form-->
    <!--            *ngIf="!!invoiceItemType$.getValue()"-->
    <!--            [currencies$]="currencies$"-->
    <!--            [invoice]="offer"-->
    <!--            [itemType$]="invoiceItemType$"-->
    <!--            [presets$]="presets$"-->
    <!--            [products$]="products$"-->
    <!--            [services$]="services$"-->
    <!--            [taxRates$]="taxes$"-->
    <!--            [errors]="selectErrorMessages('offerItemsErrors')"-->
    <!--            [units$]="productUnits$"-->
    <!--            (requestReadServices)="handleReadServices()"-->
    <!--            (requestReadProducts)="handleReadProducts()"-->
    <!--            (requestCreateInvoiceItem)="handleCreateOfferItem($event, offer['@id'])"-->
    <!--            (requestUpdateInvoiceItem)="handleUpdateOfferItem($event, offer['@id'])"-->
    <!--          ></app-invoice-item-form>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--    </ng-container>-->

    <!--    <ng-template #casm>-->

    <!--      <div class="dialog__header grid grid-no-gutter">-->
    <!--        <div class="column-12">-->
    <!--          <h2>Kunden festlegen</h2>-->
    <!--        </div>-->
    <!--        <div class="column-2 m-ta&#45;&#45;2">-->
    <!--          <button mat-icon-button mat-dialog-close>-->
    <!--            <mat-icon>close</mat-icon>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="issuer-list" mat-dialog-content style="min-width: 550px;">-->

    <!--        <p class="heading&#45;&#45;h3">Bestandskunden oder laufenden Auftrag wählen</p>-->

    <!--        <customer-search-->
    <!--          (requestReadCustomers)="handleReadCustomers($event)"-->
    <!--          (requestResetSearchResults)="handleResetSearchResults()"-->
    <!--          (requestSetCustomer)="handleSelectCustomer($event)"-->
    <!--          [editableSearchTerm]="true"-->
    <!--          [isReadonly]="!!order$.getValue()"-->
    <!--          [formGroup]="cf"-->
    <!--          [clearable]="true"-->
    <!--          appendTo="body"-->
    <!--          tooltipContent="Auftrag resetten, um einen Kunden auswählen zu können"-->
    <!--          [tooltipDisabled]="!!!order$.getValue()"-->
    <!--          [paginationNextLink$]="paginationNextLink$"-->
    <!--          [searchResults$]="searchResults$"-->
    <!--          notFoundText="Keine Ergebnisse"-->
    <!--          placeholder="Firma oder Kunde"-->
    <!--        ></customer-search>-->

    <!--        <ng-container [formGroup]="osf">-->

    <!--          <ng-select-->
    <!--            [items]="orders$ | async"-->
    <!--            placeholder="Auftrag"-->
    <!--            bindLabel="orderNumber"-->
    <!--            bindValue="@id"-->
    <!--            appendTo="body"-->
    <!--            [readonly]="!!customer$.getValue() && !!!order$.getValue()"-->
    <!--            formControlName="order"-->
    <!--            (clear)="handleResetOrder()"-->
    <!--            (change)="handleSelectOrder($event)"-->
    <!--            [clearable]="true"-->
    <!--            [markFirst]="false">-->

    <!--            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">-->

    <!--              <div class="ng-option">-->
    <!--                <div class="grid grid-no-gutter">-->

    <!--                  <div class="column-1">{{ item.orderNumber }}</div>-->

    <!--                  <div class="column-8">-->
    <!--                  <span *ngIf="item.customer.vip" class="left badge&#45;&#45;vip">-->
    <!--                    <mat-icon>check</mat-icon>-->
    <!--                    <span>VIP</span>-->
    <!--                  </span>-->
    <!--                  </div>-->

    <!--                  <div class="column-5">{{ item.customer.nameLine1 }}</div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </ng-template>-->
    <!--          </ng-select>-->
    <!--        </ng-container>-->

    <!--        <p class="heading&#45;&#45;h3">Rechnungsanschrift</p>-->

    <!--        <ng-container [formGroup]="caf">-->
    <!--          <ng-select-->
    <!--            [items]="customerAddresses$ | async"-->
    <!--            notFoundText="Keine Ergebnisse"-->
    <!--            [searchable]="false"-->
    <!--            [clearable]="false"-->
    <!--            [readonly]="!!!customer$.getValue()"-->
    <!--            (change)="handleUpdateOfferRecipient(addressSelector.selectedValues)"-->
    <!--            formControlName="address"-->
    <!--            bindValue="@id"-->
    <!--            placeholder="{{ !!customer$.getValue() ? 'Adressen für diesen Kunden:' : 'Zunächst Kunde oder Auftrag wählen' }}"-->
    <!--            appendTo="body"-->
    <!--            #addressSelector>-->

    <!--            <ng-template ng-label-tmp let-item="item">-->
    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->

    <!--            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">-->

    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->
    <!--          </ng-select>-->
    <!--        </ng-container>-->

    <!--        <div class="m-ta&#45;&#45;2 m-t&#45;&#45;16">-->
    <!--          <button mat-flat-button color="green" (click)="dialog.closeAll()">-->

    <!--            <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--            <span>Speichern und schließen</span>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--<customer-address-search
          (requestReadCustomerAddresses)="handleReadCustomerAddresses($event)"
          (requestResetSearchResults)="handleResetCustomerAddressSearchResults()"
          (requestSetCustomer)="handleSelectCustomer($event)"
          [clearable]="disabled"
          [editableSearchTerm]="true"
          [paginationNextLink$]="customerAddressPaginationNextLink$"
          [searchResults$]="customerAddressesSearchResults$"
          notFoundText="Keine Ergebnisse"
          placeholder="Kunden-Adresse"
        ></customer-address-search>-->
    <!--      </ng-template>-->
  `
})
export class OfferViewComponent extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  @ViewChild(PaymentRecorderComponent) prc: PaymentRecorderComponent;
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;

  offerUUID: string = null;
  offerIri: string = null;
  isLoading$: Observable<boolean>;
  offer$: Observable<Offer>;
  offer: Offer;
  // offer to Create new Offer
  localOffer: Offer;

  caf: FormGroup;
  cf: FormGroup;
  customer$: BehaviorSubject<Customer> = new BehaviorSubject(null);
  customerAddress$: BehaviorSubject<
    CustomerAddress | any
  > = new BehaviorSubject(null);
  customerAddressPaginationNextLink$: BehaviorSubject<
    string
  > = new BehaviorSubject(null);
  customerAddresses$: Observable<Array<CustomerAddress>>;
  departmentAdUri: SafeUrl | any = null;
  departmentLogoUri: SafeUrl | any = null;
  dsf: FormGroup;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  invoiceItemType$: BehaviorSubject<
    'custom' | 'product' | 'service'
  > = new BehaviorSubject(null);
  invoiceTypeOptions = [
    { label: 'Freie Position', value: 'custom' },
    {
      label: 'Produkt',
      value: 'product'
    },
    { label: 'Service', value: 'service' }
  ];
  issuer$: BehaviorSubject<Department> = new BehaviorSubject(null);
  of: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  order$: BehaviorSubject<Order> = new BehaviorSubject(null);
  orders$: Observable<Array<Order>>;
  uploading = false;
  osf: FormGroup;
  paginationNextLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  paymentProcess$: BehaviorSubject<PaymentProcess> = new BehaviorSubject(null);
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  productUnits$: Observable<Array<ProductUnit>>;
  productUnitsEntities$: Observable<{ [iri: string]: ProductUnit }>;
  products$: Observable<Array<Product>>;
  searchResults$: Observable<Array<Customer>>;
  services$: Observable<Array<Service>>;
  taxes$: Observable<Array<Tax>>;

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private dls: DepartmentLogosService,
    private os: OffersService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store$: Store<ApplicationState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForms();
    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.offerUUID = params.uuid;
        this.offerIri = '/api/offers/' + params.uuid;
        this.offer$ = this.store$.select(OffersSelectors.selectOfferByIndex, {
          iri: this.offerIri
        });
        this.offer$
          .pipe(
            takeUntil(this.onDestroy$),
            filter(i => !!i)
          )
          .subscribe(offer => {
            this.upsertOfferData(offer);
            if (offer.customerNumber) {
              this.store$.dispatch(
                CustomersActions.ReadCustomers({
                  page: -1,
                  params: { id: offer.customerNumber }
                })
              );
              this.store$
                .select(CustomersSelectors.sByCustomerNumber, {
                  customerNumber: offer.customerNumber
                })
                .pipe(
                  filter(e => !!e),
                  take(1)
                )
                .subscribe(c => {
                  this.customer$.next(c);
                });
            }
          });
        this.store$.dispatch(OffersActions.ReadOffer({ iri: this.offerIri }));
      });
    this.isLoading$ = isLoadingArray([
      this.store$.select(OffersSelectors.isLoading),
      this.store$.select(CustomersSelectors.isLoading),
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(CurrenciesSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(DocumentDeliveryProvidersSelectors.isLoading),
      this.store$.select(TaxesSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(ServicesSelectors.isLoading)
    ]);

    this.selectData();
    this.selectErrors();
    this.initSubscriptions();
    this.initActionListeners();
  }

  initSubscriptions(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.handleReadCustomer(order.customer['@id']);
      });

    this.customer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(customer => !!customer)
      )
      .subscribe(customer => {
        this.handleUpdateFormsAfterCustomerChange(customer);

        // Note: if customer is restored we don't know @id
        if (customer.hasOwnProperty('@id')) {
          this.handleReadCustomerAddresses(customer['@id']);
          this.handleReadCustomerContacts(customer['@id']);
        }
      });

    /*this.customerAddress$.pipe(
     takeUntil(this.onDestroy$),
     filter(address => !!address)
     ).subscribe((address) => {
     this.handleUpdateOfferRecipient([address]);
     });*/

    this.issuer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(issuer => !!issuer)
      )
      .subscribe(issuer => {
        this.of.get('issuer').setValue(issuer['@id']);
        this.dsf.get('issuer').setValue(issuer['@id']);
      });

    this.offer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(offer => !!offer)
      )
      .subscribe(offer => {
        this.upsertOfferData(offer);
      });

    this.dialog.afterAllClosed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invoiceItemType$.next(null);
      });
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          PaymentProcessesActions.CreatePaymentProcessSuccess,
          OffersActions.CreateOfferSuccess,
          OffersActions.UpdateOfferSuccess,
          OffersActions.UpdateOfferFail,
          OfferItemsActions.CreateOfferItemSuccess,
          OfferItemsActions.UpdateOfferItemSuccess,
          OfferItemsActions.DeleteOfferItemSuccess,
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess
        )
      )
      // @ts-ignore
      .subscribe(({ type, response = null, invoiceIri = null }) => {
        const documentActions = [
          DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
          DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS
        ];

        const resetPresets = [
          OfferItemsActions.CREATE_OFFER_ITEM_SUCCESS,
          OfferItemsActions.UPDATE_OFFER_ITEM_SUCCESS,
          OfferItemsActions.DELETE_OFFER_ITEM_SUCCESS
        ];

        const triggerRefresh = [...documentActions, ...resetPresets];

        if (
          type === OffersActions.UPDATE_OFFER_SUCCESS ||
          type === OffersActions.CREATE_OFFER_SUCCESS
        ) {
          this.of.markAsPristine();
        }

        if (
          type === OffersActions.UPDATE_OFFER_SUCCESS ||
          type === OffersActions.UPDATE_OFFER_FAIL
        ) {
          this.uploading = false;
        }

        // Create offer after payment process has been created
        if (type === PaymentProcessesActions.CREATE_PAYMENT_PROCESS_SUCCESS) {
          const paymentProcessIri = response['@id'];
          this.of.get('paymentProcess').setValue(paymentProcessIri);
          this.store$.dispatch(
            OffersActions.CreateOffer({ payload: this.of.value })
          );
        }

        if (documentActions.includes(type)) {
          this.sitc.initForm();
          this.sitc.resetTypeSelect();
          this.sitc.documentDeliveryTarget$.next(null);
        }

        if (triggerRefresh.includes(type) && invoiceIri) {
          this.store$.dispatch(OffersActions.ReadOffer({ iri: invoiceIri }));
        }

        if (resetPresets.includes(type)) {
          this.presets$.next(null);
        }
      });
  }

  upsertOfferData(offer: Offer): void {
    this.offer = offer;
    this.localOffer = cloneDeep(offer);

    const existingIssuer = this.issuer$.getValue();

    // Prevent re-fetching issuer every time invoice is updated
    if (!!existingIssuer && existingIssuer['@id'] !== offer.issuer['@id']) {
      this.issuer$.next(offer.issuer);
    }

    if (offer.acceptedOffer) {
      this.of.get('acceptedOffer').setValue(offer.acceptedOffer);
    }

    this.customer$.next({
      nameLine1: offer.recipient.nameLine1,
      nameLine2: offer.recipient.nameLine2,
      customerNumber: offer.customerNumber
    });
    this.customerAddress$.next({
      address: offer.recipient.address,
      addressType: offer.recipient.addressType
    });

    this.of.get('recipient.address').patchValue(offer.recipient.address);
    this.of
      .get('recipient.addressType')
      .patchValue(offer.recipient.addressType['@id']);

    this.of.get('customText').setValue(offer.customText);
    this.of.get('paymentProcess').setValue(offer.paymentProcess);

    this.of.get('validUntil').setValue(offer.validUntil);
    this.of.get('confirmedAt').setValue(offer.confirmedAt);
  }

  selectErrors(): void {
    combineLatestObject({
      offersErrors: this.store$.pipe(select(OffersSelectors.selectOfferErrors)),
      offerItemsErrors: this.store$.pipe(
        select(OfferItemsSelectors.selectOfferItemsErrors)
      )
    }).subscribe(this.errors$);
  }

  selectErrorMessages(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  initForms(): void {
    this.of = this.fb.group({
      validUntil: this.fb.control(null, Validators.required),
      paymentProcess: this.fb.control(null),
      confirmedAt: this.fb.control(null),
      issuer: this.fb.control(null),
      acceptedOffer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null),
        taxNumber: this.fb.control(null)
      }),
      customerNumber: this.fb.control(null),
      customText: this.fb.control(null)
    });

    this.cf = this.fb.group({
      customer: this.fb.control(null)
    });

    this.osf = this.fb.group({
      order: this.fb.control(null)
    });

    this.caf = this.fb.group({
      address: this.fb.control(null)
    });

    this.dsf = this.fb.group({
      issuer: this.fb.control(null)
    });
  }

  selectData(): void {
    this.offer$ = this.store$.pipe(select(OffersSelectors.selectCurrentOffer));
    this.orders$ = this.store$.pipe(select(OrdersSelectors.sList));
    this.productUnits$ = this.store$.pipe(
      select(ProductUnitsSelectors.selectProductUnits)
    );
    this.productUnitsEntities$ = this.store$.pipe(
      select(ProductUnitsSelectors.selectProductUnitsEntities)
    );
    this.products$ = this.store$.pipe(select(ProductsSelectors.sList));
    this.searchResults$ = this.store$.pipe(
      select(CustomersSelectors.selectSearchResults)
    );
    this.services$ = this.store$.pipe(select(ServicesSelectors.selectServices));
    this.taxes$ = this.store$.pipe(select(TaxesSelectors.selectTaxes));

    this.store$
      .pipe(select(CustomerAddressesSelectors.selectCurrentCustomerAddress))
      .subscribe(this.customerAddress$);
    this.store$
      .pipe(select(CustomerAddressesSelectors.selectPaginationNextLink))
      .subscribe(this.customerAddressPaginationNextLink$);
    this.store$
      .pipe(select(CustomersSelectors.selectCurrentCustomer))
      .subscribe(this.customer$);
    this.store$
      .pipe(select(CustomersSelectors.selectPaginationNextLink))
      .subscribe(this.paginationNextLink$);
    this.store$
      .pipe(select(DepartmentsSelectors.selectDefaultDepartment))
      .subscribe(this.issuer$);
    this.store$
      .pipe(select(PaymentProcessesSelectors.selectCurrentPaymentProcess))
      .subscribe(this.paymentProcess$);
  }

  handleUpdateFormsAfterCustomerChange(customer: Customer): void {
    this.of.get('customerNumber').setValue(customer.customerNumber);
    this.of.get('recipient.taxNumber').setValue(customer.taxNumber);
    this.of.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.of.get('recipient.nameLine2').setValue(customer.nameLine2);

    this.cf.get('customer').setValue(customer['@id']);

    // todo: auto-set default address if given?
    /*if(!!customer?.defaultBillingAddress) {
     // Check for and fetch default customer address if set
     this.caf.get('address').setValue(customer.defaultBillingAddress);
     }*/
  }

  handleReadCustomer(iri: string): void {
    this.store$.dispatch(CustomersActions.ReadCustomer({ iri }));
  }

  handleReadCustomerAddresses(customerIri: string): void {
    const params = {
      'customer.uuid': StringsUtility.getUuidFromIri(customerIri)
    };
    this.store$.dispatch(
      CustomerAddressesActions.ReadCustomerBillingAddresses({ customerIri })
    );
    this.customerAddresses$ = this.store$.pipe(
      select(
        CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
        { customerIri }
      )
    );
  }

  handleCopyOffer(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text:
            'Möchten Sie eine Kopie dieses Angebots erstellen? Hinweis: ' +
            'Angebotspositionen werden nicht mit kopiert und müssen erneut angelegt werden.',
          heading: 'Angebot kopieren?',
          confirmationText: 'Ja, kopieren',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OffersActions.CreateOffer({ payload: this.of.value })
        );
      });
  }

  handleCreateOffer(): void {
    const order = this.order$.getValue();
    if (order) {
      this.of.get('paymentProcess').setValue(order.paymentProcess);
      this.store$.dispatch(
        OffersActions.CreateOffer({ payload: this.of.value })
      );
    } else {
      // Create New Payment Process First
      this.store$.dispatch(
        PaymentProcessesActions.CreatePaymentProcess({
          payload: { customer: this.customer$.getValue()['@id'] }
        })
      );
    }
  }

  handleUpdateOffer(_payload?: Offer): void {
    if (!extractIri(this.offer, null)) {
      return;
    }

    if (!this.offer) {
      this.localOffer = _payload;
      return;
    }
    const values = _payload ? _payload : this.of.value;
    if (values.issuer) {
      values.issuer = extractIri(values.issuer);
    }

    const payload = { iri: extractIri(this.offer), payload: { ...values } };
    this.store$.dispatch(OffersActions.UpdateOffer(payload));
  }

  handleDeleteOffer(offer: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie dieses Angebot wirklich löschen?',
          heading: 'Angebot löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(OffersActions.DeleteOffer({ iri: offer }));
      });
  }

  handleDownloadOffer(offer: string): void {
    this.store$.dispatch(OffersActions.ReadOfferPDF({ iri: offer }));
  }

  handleDownloadAcceptedOffer(acceptedOffer: string): void {
    console.log(acceptedOffer);
    this.store$.dispatch(
      OffersActions.ReadAcceptedOfferPDF({
        iri: acceptedOffer,
        fileName: 'Bestätigung zu Angebot ' + this.offer.offerNumber
      })
    );
  }

  handleCreateOfferItem(payload: OfferItem, offer: string): void {
    this.store$.dispatch(
      OfferItemsActions.CreateOfferItem({ payload, invoiceIri: offer })
    );
  }

  handleUpdateOfferItem(
    _payload: { iri: string; payload: CorrectionInvoiceItem },
    offer: string
  ): void {
    const { iri, payload } = _payload;
    this.store$.dispatch(
      OfferItemsActions.UpdateOfferItem({ iri, payload, invoiceIri: offer })
    );
  }

  handleFetchDepartmentAd(iri: string): void {
    this.departmentAdUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          ({ body: blob }) => {
            this.departmentAdUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleFetchDepartmentLogo(iri: string | null): void {
    this.departmentLogoUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(
          // tap(response => console.log(response)),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          ({ body: blob }) => {
            this.departmentLogoUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleShowOfferItemForm(ref: TemplateRef<any>, presets?: Offer | any): void {
    // Prepare Edit
    if (presets) {
      this.presets$.next(presets);
      // note: fallback to custom since additional product/service selector is not necessary
      this.invoiceItemType$.next('custom');
    }
    this.openDialog(ref, { disableClose: true });
  }

  handleDeleteOfferItem(iri: string, offer: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Position wirklich löschen?',
          heading: 'Position zum Angebot löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OfferItemsActions.DeleteOfferItem({ iri, invoiceIri: offer })
        );
      });
  }

  handleShowDepartmentSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  handleShowCustomerAddressSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  openDialog(
    ref: TemplateRef<any>,
    config: ModalDialogOptions | any = {}
  ): void {
    this.dialog.open(ref, config);
  }

  getOfferItemModalHeading(): string {
    const headings = {
      custom: 'Freie Position zum Angebot hinzufügen',
      service: 'Service zum Angebot hinzufügen',
      product: 'Produkt zum Angebot hinzufügen'
    };

    if (!!this.invoiceItemType$.getValue()) {
      return headings[this.invoiceItemType$.getValue()];
    }

    return 'Position zum Angebot hinzufügen';
  }

  handleReadServices(): void {
    this.store$.dispatch(ServicesActions.ReadServices());
  }

  handleReadProducts(): void {
    this.store$.dispatch(ProductsActions.ReadProducts());
  }

  handleUpdateIssuer(selectedIssuers: Array<Department> | any): void {
    this.issuer$.next(selectedIssuers[0]);
  }

  handleUpdateOfferRecipient(
    selectedAddresses: Array<CustomerAddress> | any
  ): void {
    const customerAddress = selectedAddresses[0];
    const { address, addressType } = customerAddress;

    this.of.get('recipient.address').patchValue(address);
    this.of.get('recipient.addressType').patchValue(addressType['@id']);

    this.customerAddress$.next(customerAddress);
  }

  handleSelectCustomer(customer: Customer): void {
    this.order$.next(null);
    if (customer) {
      this.handleReadCustomer(customer['@id']);
    } else {
      this.handleResetCustomer();
    }
  }

  handleResetCustomer(): void {
    this.handleResetFormValuesAfterCustomerReset();
    this.customer$.next(null);
    this.customerAddress$.next(null);
    // todo customerContact
  }

  handleResetFormValuesAfterCustomerReset(): void {
    this.cf.get('customer').setValue(null);
    this.caf.get('address').setValue(null);
  }

  handleReadOrder(iri: string): void {
    this.store$.dispatch(OrdersActions.ReadOrder({ iri }));
    this.store$
      .pipe(select(OrdersSelectors.sDetailedByIri, { iri }))
      .subscribe(this.order$);
  }

  handleSelectOrder(order: Order): void {
    if (order) {
      this.handleReadOrder(order['@id']);
    } else {
      this.handleResetOrder();
    }
  }

  handleResetOrder(): void {
    this.handleResetCustomer();
    this.handleResetFormValuesAfterOrderReset();
  }

  handleResetFormValuesAfterOrderReset(): void {
    this.order$.next(null);
  }

  handleResetSearchResults(): void {
    // also reset current searchResults and loaded state of customers;
    // so customers are fetched anew when navigating from this component to customers-view component
    this.searchResults$.pipe(switchMap(items => of([])));
    this.store$.dispatch(CustomersActions.ResetIsLoaded());
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleReadCustomerContacts(customerIri: string): void {
    // this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({ page: 1, params }));
    // this.customerContacts$ = this.store$.pipe(select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, { customerIri }));
  }

  handleCreateEmailDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateEmailDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  handleCreateRegisteredLetterDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  handleCreateLetterXPressDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  openMailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'offer', entity$: this.offer$ }
    });
  }
}
