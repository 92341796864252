import {createSelector} from '@ngrx/store';

import {SALUTATIONS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectSalutationsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[SALUTATIONS_FEATURE_KEY]
);

export const selectSalutationsEntities = createSelector(
  selectSalutationsState,
  (state) => state.entities
);

export const selectSalutations = createSelector(
  selectSalutationsEntities,
  (entities) => Object.values(entities)
);

export const selectSalutation = createSelector(
  selectSalutationsEntities,
  (entities, iri) => entities[iri]
);

export const selectErrors = createSelector(
  selectSalutationsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectSalutationsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectSalutationsState,
  (state) => state.loaded
);
