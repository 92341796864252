import {BindingOrdersService} from './binding-orders.service';
import {DataMediumStatusLogsService} from './data-medium-status-log.service';
import {DataRecoveryCostsService} from './data-recovery-costs.service';
import {DataRecoveryProtocolsService} from './data-recovery-protocols.service';
import {DataRecoveryResultsService} from './data-recovery-results.service';
import {OrderCommentTagsService} from './order-comment-tags.service';
import {OrderCommentsService} from './order-comments.service';
import {OrderSpecialAgreementsService} from './order-special-agreements.service';
import {OrderStateRangesService} from './order-state-ranges.service';
import {OrderStatesService} from './order-states.service';
import {OrdersService} from './orders.service';
import {ProductOrderItemsService} from './product-order-items.service';
import {ProofsOfDestructionService} from './proofs-of-destruction.service';
import {ServiceOrderItemsService} from './service-order-items.service';
import {GenericOrderItemsService} from './generic-order-items.service';

export const AllServices = [
  BindingOrdersService,
  DataMediumStatusLogsService,
  DataRecoveryCostsService,
  DataRecoveryProtocolsService,
  DataRecoveryResultsService,
  OrderCommentTagsService,
  OrderCommentsService,
  OrderSpecialAgreementsService,
  OrderStateRangesService,
  OrderStatesService,
  OrdersService,
  ProductOrderItemsService,
  ProofsOfDestructionService,
  ServiceOrderItemsService,
  GenericOrderItemsService
];

export * from './binding-orders.service';
export * from './data-medium-status-log.service';
export * from './data-recovery-costs.service';
export * from './data-recovery-protocols.service';
export * from './data-recovery-results.service';
export * from './order-comment-tags.service';
export * from './order-comments.service';
export * from './order-special-agreements.service';
export * from './order-state-ranges.service';
export * from './order-states.service';
export * from './orders.service';
export * from './product-order-items.service';
export * from './proofs-of-destruction.service';
export * from './service-order-items.service';
export * from './generic-order-items.service';
