import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {Product} from '../../models';

@Component({
  selector: 'analysis-result-form',
  styleUrls: ['analysis-result-form.component.scss'],
  templateUrl: "analysis-result-form.component.html"
})
export class AnalysisResultFormComponent implements OnInit {

  @Input() analysisResultCategories: Array<fromModuleModels.AnalysisResultCategory>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromModuleModels.AnalysisResult>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromModuleModels.AnalysisResult,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.AnalysisResult,
    entity: string
  }> = new EventEmitter();

  arf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {

    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(presets => {
      this.initForm();
      this.fs.patchForm(this.arf, presets);
      this.arf.markAsUntouched();
    });
  }

  initForm() {
    this.arf = this.fb.group({
      name: this.fb.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
      content: this.fb.control('', [Validators.required]),
      category: this.fb.control(null, [Validators.required])
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: Product = this.arf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'AnalysisResult'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'AnalysisResult'});
  }
}
