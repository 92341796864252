import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { AddressTypesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.AddressType };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    AddressTypesActions.ReadAddressType,
    AddressTypesActions.ReadAddressTypes,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(AddressTypesActions.ReadAddressTypeSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(AddressTypesActions.ReadAddressTypesSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.AddressType }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    AddressTypesActions.ReadAddressTypeFail,
    AddressTypesActions.ReadAddressTypesFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
