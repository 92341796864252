import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Order } from '../../models';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-datarecovery-list-ext-widget',
  styleUrls: ['./open-datarecovery-list-ext-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Aktuell offene Datenrettungen</span>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenDatarecoveryListExtWidgetComponent
  extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  states: string[];

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.states = [
      'waiting_for_data_recovery_dr_ext',
      'data_recovery_delayed_dr_ext',
      'data_recovery_in_progress_dr_ext', // aktuell in Datenrettung
      // 'data_recovery_completed_dr_ext',
      'provide_data_recovery_result_dr_ext'
    ];
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }
}
