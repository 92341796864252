import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LocalesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { LocalesService } from '../../services/locales.service';

@Injectable()
export class LocalesEffects {
  ReadLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalesActions.ReadLocale),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getLocale(iri).pipe(
          map(response => LocalesActions.ReadLocaleSuccess({ response })),
          catchError(response =>
            of(LocalesActions.ReadLocaleFail({ response }))
          )
        );
      })
    )
  );
  ReadLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalesActions.ReadLocales),
      map(action => action),
      switchMap(() => {
        return this.service.getSupportedLocales().pipe(
          map(response => LocalesActions.ReadLocalesSuccess({ response })),
          catchError(response =>
            of(LocalesActions.ReadLocalesFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalesActions.ReadLocaleFail, LocalesActions.ReadLocalesFail),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: LocalesService,
    private notifierService: NotifierService
  ) {}
}
