import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { DepartmentLogosActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.DepartmentLogo };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
  uploadStatus: { type: number; loaded: number; total?: number };
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DepartmentLogosActions.CreateDepartmentLogo,
    DepartmentLogosActions.ReadDepartmentLogo,
    DepartmentLogosActions.ReadDepartmentLogos,
    DepartmentLogosActions.UpdateDepartmentLogo,
    DepartmentLogosActions.DeleteDepartmentLogo,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    DepartmentLogosActions.ReadDepartmentLogoSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    DepartmentLogosActions.ReadDepartmentLogosSuccess,
    (state, { response }) => {
      const fss = response['hydra:member'];
      const entities = fss.reduce(
        (entities: { [id: string]: fromModuleModels.DepartmentLogo }, fs) => {
          return {
            ...entities,
            [fs['@id']]: fs
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    DepartmentLogosActions.CreateDepartmentLogoStatus,
    (state, { response }) => {
      return {
        ...state,
        uploadStatus: response,
        loading: true
      };
    }
  ),

  on(
    DepartmentLogosActions.CreateDepartmentLogoSuccess,
    DepartmentLogosActions.UpdateDepartmentLogoSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(DepartmentLogosActions.DeleteDepartmentLogoSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    DepartmentLogosActions.CreateDepartmentLogoFail,
    DepartmentLogosActions.ReadDepartmentLogoFail,
    DepartmentLogosActions.ReadDepartmentLogosFail,
    DepartmentLogosActions.UpdateDepartmentLogoFail,
    DepartmentLogosActions.DeleteDepartmentLogoFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
