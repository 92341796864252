<div class="leads-list__outer m-b--32">
  <div class="leads-list__wrapper wrap">
    <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
    <dts-select-container
      (contextmenu)="openContextMenu($event, true)"
      [(selectedItems)]="selectedLeads"
      custom="true"
      [disabled]="isCdkDragActive"
      [disableRangeSelection]="isCdkDragActive"
      [disableDrag]="isCdkDragActive"
      selectWithShortcut="true"
    >
    <div cdkDropListGroup class="leads-list grid">
      <div
        *ngFor="
              let index of [1, 2, 3, 4, 5, 6];
              let last = last;
              let first = first
            "
        class="col-wrapper column-auto"
        [class.first]="first"
        [class.last]="last"
      >
        <div class="col-label">
          <span>{{ headingsMapping[index] }}</span>
        </div>

        <div
          class="col-content"
          *ngIf="leads[index]"
          [attr.data-column]="index"
          cdkDropList
          [cdkDropListData]="leads[index]"
          (cdkDropListDropped)="handleRequestUpdateLead($event)"
        >
          <div
            *ngFor="let lead of leads[index]"
            cdkDrag
            [cdkDragDisabled]="selectedLeads && selectedLeads.length > 0"
            (cdkDragStarted)="onCdkDragStarted()"
            (cdkDragEnded)="onCdkDragEnded()"
            [dtsSelectItem]="lead"
            [attr.data-iri]="lead['@id']"
            (click)="selectedLeads.length === 0 && handleRequestUpdateLeadView($event, lead['@id'])"
          >
            <leads-list-card-wrapper
              (contextmenu)="openContextMenu($event, false, lead)"
              [isSelected]="isLeadInArrayIndex(selectedLeads, lead) !== -1"
              [lead]="lead" (menuItems)="loadLeadMenuItems($event)"
              [leadOriginTypesEntities]="leadOriginTypesEntities">
            </leads-list-card-wrapper>
            <div *cdkDragPlaceholder class="leads-placeholder">
              <leads-list-card-wrapper
                [lead]="lead"
                (menuItems)="loadLeadMenuItems($event)"
                [leadOriginTypesEntities]="leadOriginTypesEntities"
              ></leads-list-card-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
    </dts-select-container>
  </div>
</div>


<!--
MATERIAL MENU
TODO: MOVE TO SEPARATE COMPONENT IF POSSIBLE
-->
<div
  style="visibility: hidden; position: fixed;"
  [style.left]="contextMenuPosition.left"
  [style.top]="contextMenuPosition.top"
  [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-container *ngFor="let item of contextMenuItems">
    <button
      *ngIf="!item.submenu"
      mat-menu-item
      (click)="handleItemClick(item)"
      [style.background-color]="item.backgroundColor"
    >
      <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
      {{ item.label | translate }}
    </button>
    <button
      *ngIf="item.submenu"
      mat-menu-item
      [matMenuTriggerFor]="submenu"
      (mouseenter)="handleSubmenuClick(item)"
      [style.background-color]="item.backgroundColor && item.backgroundColor"
    >
      <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
      {{ item.label | translate }}
    </button>
  </ng-container>
</mat-menu>
<mat-menu #submenu="matMenu">
  <ng-container *ngFor="let subItem of selectedSubmenuItems">
    <button mat-menu-item (click)="handleItemClick(subItem)">
      <mat-icon *ngIf="subItem.icon" [style.color]="subItem.backgroundColor">{{ subItem.icon }}</mat-icon>
      {{ subItem.label | translate }}
    </button>
  </ng-container>
</mat-menu>

