import {createSelector} from '@ngrx/store';

// Provider Import
import {ADMINISTRATORS_FEATURE_KEY, selectAdministratorsModuleState} from '../reducers';
import {UserProfileSelectors} from '../../../auth/store/selectors';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {Administrator} from '../../models';
import {State} from '../reducers/administrators.reducers';

const NAMESPACE = ADMINISTRATORS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectAdministratorsModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);


export const sPossibleAdmins = createSelector(
  sList,
  (administrators: Administrator[]) => administrators.filter(e => e.enabled && !e.locked)
);
export const sListWithDisabledAttribute = createSelector(
  sList,
  (administrators: Administrator[]) => administrators.map(e => ({...e, disabled: (!e.enabled || e.locked)}))
);

export const selectAdministratorsWithoutLoggedInUser = createSelector(
  sList,
  UserProfileSelectors.selectUserProfile,
  (administrators: Administrator[], user) => administrators.filter(e => e['@id'] !== user['@id'])
);
export const selectSalesMenRestrictedToSelect = createSelector(
  sList,
  UserProfileSelectors.selectUserProfile,
  (administrators: Administrator[], user) => {
    console.log(user);
    if (!user.roles) {
      return [];
    }
    if (user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPERVISOR')) {
      return administrators;
    } else {
      return administrators.filter(e => e['@id'] === user['@id']);
    }
  }
);

export const selectTechnicians = createSelector(
  sListWithDisabledAttribute,
  (administrators: Administrator[]) => administrators
    .filter(admin => admin.roles.includes('ROLE_TECHNICIAN')
      || admin.roles.includes('ROLE_TECHNICIAN_EXTERNAL_DHE')
      || admin.roles.includes('ROLE_TECHNICIAN_EXTERNAL_DRE'))
);

export const selectSalesMen = createSelector(
  sListWithDisabledAttribute,
  (administrators: Administrator[]) => administrators
    .filter(admin => admin.roles.includes('ROLE_SALES') || admin.roles.includes('ROLE_SALES_EXTERNAL'))
);

export const selectFormErrors = createSelector(
  sState,
  (state: State) => state.errors
);

