import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';
import {
  BOOK_COMMISSION_CREDIT,
  BOOK_COMMISSION_CREDIT_FAIL,
  BOOK_COMMISSION_CREDIT_SUCCESS
} from './commission-credits.actions';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CORRECTION_INVOICE_ITEM = '[Invoices Module] Create Correction Invoice Item';
export const CREATE_CORRECTION_INVOICE_ITEM_FAIL = '[Invoices Module] Create Correction Invoice Item Fail';
export const CREATE_CORRECTION_INVOICE_ITEM_SUCCESS = '[Invoices Module] Create Correction Invoice Item Success';

export const CreateCorrectionInvoiceItem = createAction(
  CREATE_CORRECTION_INVOICE_ITEM,
  props<{ payload: fromInvoicesModuleModels.CorrectionInvoiceItem, invoiceIri: string }>()
);

export const CreateCorrectionInvoiceItemSuccess = createAction(
  CREATE_CORRECTION_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.CorrectionInvoiceItem, invoiceIri: string }>()
);

export const CreateCorrectionInvoiceItemFail = createAction(
  CREATE_CORRECTION_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CORRECTION_INVOICE_ITEM = '[Invoices Module] Read Correction Invoice Item';
export const READ_CORRECTION_INVOICE_ITEM_SUCCESS = '[Invoices Module] Read Correction Invoice Item Success';
export const READ_CORRECTION_INVOICE_ITEM_FAIL = '[Invoices Module] Read Correction Invoice Item Fail';

export const ReadCorrectionInvoiceItem = createAction(
  READ_CORRECTION_INVOICE_ITEM,
  props<{ iri: string }>()
);

export const ReadCorrectionInvoiceItemSuccess = createAction(
  READ_CORRECTION_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.CorrectionInvoiceItem | any }>()
);

export const ReadCorrectionInvoiceItemFail = createAction(
  READ_CORRECTION_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CORRECTION_INVOICE_ITEM = '[Invoices Module] Update Correction Invoice Item';
export const UPDATE_CORRECTION_INVOICE_ITEM_SUCCESS = '[Invoices Module] Update Correction Invoice Item Success';
export const UPDATE_CORRECTION_INVOICE_ITEM_FAIL = '[Invoices Module] Update Correction Invoice Item Fail';

export const UpdateCorrectionInvoiceItem = createAction(
  UPDATE_CORRECTION_INVOICE_ITEM,
  props<{ iri: string, payload: fromInvoicesModuleModels.CorrectionInvoiceItem | any, invoiceIri: string }>()
);

export const UpdateCorrectionInvoiceItemSuccess = createAction(
  UPDATE_CORRECTION_INVOICE_ITEM_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const UpdateCorrectionInvoiceItemFail = createAction(
  UPDATE_CORRECTION_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_CORRECTION_INVOICE_ITEM = '[Invoices Module] Delete Correction Invoice Item';
export const DELETE_CORRECTION_INVOICE_ITEM_SUCCESS = '[Invoices Module] Delete Correction Invoice Item Success';
export const DELETE_CORRECTION_INVOICE_ITEM_FAIL = '[Invoices Module] Delete Correction Invoice Item Fail';

export const DeleteCorrectionInvoiceItem = createAction(
  DELETE_CORRECTION_INVOICE_ITEM,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteCorrectionInvoiceItemSuccess = createAction(
  DELETE_CORRECTION_INVOICE_ITEM_SUCCESS,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteCorrectionInvoiceItemFail = createAction(
  DELETE_CORRECTION_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
