import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchAddonDataService {

  constructor() {
  }

  addOnData$: BehaviorSubject<{
    filterString?: string,
    searchBoxVisible?: boolean
  }> = new BehaviorSubject({});

  public setData(data: any): void {
    this.addOnData$.next(data);
  }

  public updateData(data: any): void {
    const newData = {...this.addOnData$.value, ...data};
    this.addOnData$.next(newData);
  }

  getObservable(): Observable<any> {
    return this.addOnData$.asObservable();
  }
}
