import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EmailTemplatesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { EmailTemplateService } from '../../services/email-template.service';

@Injectable()
export class EmailTemplatesEffects {
  actionCollection = EmailTemplatesActions;
  resourceNameDE = 'E-Mail Template';
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.CreateEmailTemplate),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.create(payload).pipe(
          map(response => this.actionCollection.CreateEmailTemplateSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.CreateEmailTemplateFail({ response }))
          )
        );
      })
    )
  );
  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.ReadEmailTemplate),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readOne(iri).pipe(
          map(response => this.actionCollection.ReadEmailTemplateSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadEmailTemplateFail({ response }))
          )
        );
      })
    )
  );
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.ReadEmailTemplates),
      map(action => action),
      switchMap(({page, params}) => {
        return this.service.readList(page, params).pipe(
          map(response => this.actionCollection.ReadEmailTemplatesSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadEmailTemplatesFail({ response }))
          )
        );
      })
    )
  );
  getListChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.ReadEmailTemplatesChunk),
      map(action => action),
      switchMap(({uri}) => {
        return this.service.readListChunk(uri).pipe(
          map(response => this.actionCollection.ReadEmailTemplatesChunkSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadEmailTemplatesChunkFail({ response }))
          )
        );
      })
    )
  );
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.UpdateEmailTemplate),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.update(iri, payload).pipe(
          map(response => this.actionCollection.UpdateEmailTemplateSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.UpdateEmailTemplateFail({ response }))
          )
        );
      })
    )
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.DeleteEmailTemplate),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.delete(iri).pipe(
          map(() => this.actionCollection.DeleteEmailTemplateSuccess({ iri })),
          catchError(response =>
            of(this.actionCollection.DeleteEmailTemplateFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          this.actionCollection.DeleteEmailTemplateFail,
          this.actionCollection.UpdateEmailTemplateFail,
          this.actionCollection.ReadEmailTemplatesFail,
          this.actionCollection.ReadEmailTemplateFail,
          this.actionCollection.CreateEmailTemplateFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei ' + type + ': ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          this.actionCollection.CreateEmailTemplateSuccess,
          this.actionCollection.UpdateEmailTemplateSuccess,
          this.actionCollection.DeleteEmailTemplateSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case this.actionCollection.CREATE_EMAIL_TEMPLATE_SUCCESS:
              message =
                'Die Ressource (' + this.resourceNameDE + ') wurder angelegt.';
              break;
            case this.actionCollection.UPDATE_EMAIL_TEMPLATE_SUCCESS:
              message =
                'Die Ressource (' +
                this.resourceNameDE +
                ') wurder aktualisiert.';
              break;
            case this.actionCollection.DELETE_EMAIL_TEMPLATE_SUCCESS:
              message =
                'Die Ressource (' + this.resourceNameDE + ') wurder gelöscht.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: EmailTemplateService,
    private notifierService: NotifierService
  ) {}
}
