import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import * as fromInvoicesModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {MatDialog} from '@angular/material/dialog';
import {WriteEMailDialogComponent} from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import {
  Offer,
  CommissionCredit,
  CancellationInvoice,
  CorrectionInvoice,
  PartialInvoice,
  PayableInvoice
} from '../../models';
import {
  CancellationInvoicesActions, CommissionCreditsActions, CorrectionInvoicesActions,
  DocumentDeliveriesActions,
  DocumentDeliveryProvidersActions, OffersActions,
  PayableInvoicesActions
} from '../../store';
import {extractIri, extractTypeByIri} from '../../../shared/utilities/objects.utility';
import {ApplicationState} from '../../../application-state/store';
import {Store} from '@ngrx/store';
import * as InvoicesModuleSelectors from '../../store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {DocumentDeliveryProvidersSelectors} from '../../store/selectors';

@Component({
  selector: 'app-send-document-tool',
  styleUrls: ['send-document-tool.component.scss'],
  template: `

    <div class="card mt-3 mat-elevation-z1" *ngIf="invoice">

      <div class="card__heading" [class.green]="!!invoice?.documentDeliveries?.length">
        <span>{{ invoiceTypeName }} versenden</span>
      </div>

      <div class="card__content">

        <div class="row">
          <div class="col-12">
            <p class="my-3 text-right"
               [innerHTML]="!!!invoice?.documentDeliveries?.length ? invoiceTypeName + ' noch nicht verschickt!' : getDeliveryMessages(invoice.documentDeliveries)">
            </p>
          </div>
        </div>
        <ng-container [formGroup]="form">


          <div class="row my-2">
            <div class="col-12">
              <div class="mat-form-field">
                <mat-label>{{getDeliveryTypeName(invoiceTypeName)}}</mat-label>
                <mat-button-toggle-group formControlName="deliveryType" class="block small mt-1">
                  <mat-button-toggle *ngFor="let deliveryType of documentDeliveryProviders"
                                     [value]="deliveryType['@id']"
                                     class="white-if-checked">{{deliveryType.name}}</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="form.contains('trackingNumber')">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Tracking-ID</mat-label>
                <input type="text" matInput formControlName="trackingNumber">
                <mat-error>
                  <app-form-error fieldName="trackingNumber" [formGroup]="form"></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-6">
            <button mat-flat-button
                    (click)="handleRequestDownloadInvoice(invoice['@id'])"
                    style="color: #bbb">{{ invoiceTypeName }} herunterladen
            </button>
          </div>

          <div class="col-6 text-right">
            <button
              mat-flat-button [disabled]="!!!documentDeliveryTarget$.getValue() || (invoice && form.invalid)"
              [color]="!!invoice?.documentDeliveries?.length ? 'orange' : 'green'"
              (click)="handleRequestSendInvoice(invoice)">
              <mat-icon class="m-r--8">mail</mat-icon>
              <span>{{ invoiceTypeName }} versenden</span>
            </button>
          </div>
        </div>
      </div>
      <div class="blocker-is-new" *ngIf="isNewInvoice">
        <span class="bg-white p-2 rounded-4">{{invoiceTypeName}} muss erst angelegt werden.</span>
      </div>
    </div>

    <!--<pre>{{ documentDeliveryProviders | json }}</pre>-->
    <!--<pre>{{ ddf.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
    <!--<pre>{{ !!!documentDeliveryTarget$.getValue() | json }}</pre>-->
  `
})
export class SendDocumentToolComponent implements OnInit, OnDestroy {

  documentDeliveryProviders: Array<fromInvoicesModuleModels.DocumentDeliveryProvider> = [];
  @Input() errors: ErrorsObject;
  @Input() invoice: CorrectionInvoice | Offer | CancellationInvoice | CommissionCredit | PayableInvoice | PartialInvoice;
  @Input() invoiceTypeName: string;

  @Output() requestCreateLetterXPressDocumentDelivery: EventEmitter<any> = new EventEmitter();
  @Output() requestCreateRegisteredLetterDocumentDelivery: EventEmitter<any> = new EventEmitter();
  @Output() requestDownloadInvoice: EventEmitter<string> = new EventEmitter();

  form: FormGroup;
  documentDeliveryTarget$: BehaviorSubject<string> = new BehaviorSubject(null);
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
  ) {
  }

  get isNewInvoice(): boolean {
    try {
      extractIri(this.invoice);
    } catch (e) {
      return true;
    }
    return false;
  }

  getDeliveryTypeName(invoiceTypeName: string): string {
    return `Versandart ${invoiceTypeName === 'Angebot' ? 'des' : 'der'} ${invoiceTypeName}`;
  }

  getDeliveryMessages(documentDeliveries: Array<fromInvoicesModuleModels.DocumentDelivery>): string {

    let messages = [];

    documentDeliveries.forEach((dd) => {
      messages = [
        ...messages,
        `${this.invoiceTypeName} am ${dd.hasOwnProperty('createdAt') ? formatDate(dd.createdAt, 'dd.MM.Y', this.locale) : 'XX.XX.XXXX'} ${this.getDocumentDeliveryTypeLabel(dd['@type'])} verschickt.`
      ];
    });

    return messages.join('<br>');
  }

  getDocumentDeliveryTypeLabel(type: string): string {
    return {
      email_document_deliveries: 'per Mail',
      EmailDocumentDelivery: 'per Mail',
      registered_letter_document_deliveries: 'per Einschreiben',
      RegisteredLetterDocumentDelivery: 'per Einschreiben',
      letter_xpress_document_deliveries: 'per Brief',
      LetterXpressDocumentDelivery: 'per Brief'
    }[type];
  }

  ngOnInit(): void {
    this.initForm();
    this.store$.select(InvoicesModuleSelectors.DocumentDeliveryProvidersSelectors.selectDocumentDeliveryProviders)
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
      .subscribe(e => {
        this.documentDeliveryProviders = e;
      });
    loadIfNotLoaded(this.store$, DocumentDeliveryProvidersSelectors.isLoaded,
      DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviders());

    this.documentDeliveryTarget$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(target => {
      this.removeAdditionalControls();
      if (target) {
        this.provideAdditionalControls(target);
      }
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      deliveryType: this.fb.control(null),
      document: this.fb.control(null)
    });
    this.form.get('deliveryType').valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.documentDeliveryTarget$.next(this.extractUuid(value));
      });
  }

  removeAdditionalControls(): void {
    this.form.removeControl('trackingNumber');
  }

  provideAdditionalControls(_for: string): void {

    if (_for === 'registered_letter_document_deliveries') {
      this.form.addControl('trackingNumber', this.fb.control(null, Validators.required));
    }
  }


  handleRequestSendInvoice(invoice: CorrectionInvoice | Offer | CancellationInvoice | CommissionCredit): void {
    console.log(invoice);
    if (!invoice) {
      alert('Invoice must be set');
      return;
    }

    this.form.get('document').setValue(invoice);
    const payload = this.form.value;
    const target = this.documentDeliveryTarget$.getValue();
    console.log(target);
    if (target === 'email_document_deliveries') {
      this.dialog.open(WriteEMailDialogComponent, {
        data: {
          type: 'email_document_deliveries',
          entity$: of(invoice)
        }
      });
    }

    if (target === 'registered_letter_document_deliveries') {
      console.log(payload);
      this.store$.dispatch(
        DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery({
          payload: {...payload, document: extractIri(payload.document)},
          invoiceIri: extractIri(this.invoice)
        })
      );

    }

    if (target === 'letter_xpress_document_deliveries') {
      this.store$.dispatch(
        DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery({
          payload: {document: extractIri(payload.document)},
          invoiceIri: extractIri(this.invoice)
        })
      );
    }
  }

  handleRequestDownloadInvoice(invoice: string): void {
    switch (extractTypeByIri(invoice)) {
      case 'invoices':
      case 'partial_invoices':
        this.store$.dispatch(PayableInvoicesActions.ReadPayableInvoicePDF({iri: invoice}));
        break;
      case 'cancellation_invoices':
        this.store$.dispatch(CancellationInvoicesActions.ReadCancellationInvoicePDF({iri: invoice}));
        break;
      case 'offers':
        this.store$.dispatch(OffersActions.ReadOfferPDF({iri: invoice}));
        break;
      case 'correction_invoices':
        this.store$.dispatch(CorrectionInvoicesActions.ReadPDF({iri: invoice}));
        break;
      case 'commission_credits':
        this.store$.dispatch(CommissionCreditsActions.ReadPDF({iri: invoice}));
        break;


    }
    this.requestDownloadInvoice.emit(invoice);
  }

  extractUuid(iri: string): string {
    return StringsUtility.getUuidFromIri(iri);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  resetTypeSelect(): void {
    this.form.get('deliveryType').patchValue(null);
  }
}
