import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { FollowUp } from '../models';
import { formatParams } from '../../shared/utilities/urlParams.utility';
import { AbstractApiResponse } from '../../shared/models';
import * as moment from 'moment';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class FollowUpsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createFollowUp(payload: FollowUp): Observable<FollowUp> {
    const date = moment(payload.date).toISOString(true);
    return this.rolesService.userHasRoleFilter<FollowUp>(
      'ROLE_MWS_FOLLOW_UP_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/follow_ups`, { ...payload, date })
    );
  }

  loadFollowUps(fromDate, toDate): Observable<Array<FollowUp>> {
    const params = {};
    if (fromDate) {
      params['date[after]'] = moment(fromDate).toISOString(true);
    }
    if (toDate) {
      params['date[before]'] = moment(toDate).toISOString(true);
    }
    return this.rolesService.userHasRoleFilter<Array<FollowUp>>(
      'ROLE_MWS_FOLLOW_UP_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject('/follow_ups?' + formatParams(params))
    );
  }

  loadFollowUp(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<FollowUp>(
      'ROLE_MWS_FOLLOW_UP_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateFollowUp(
    iri: string,
    payload: FollowUp | { date: Date } | { deletedAt: string | null }
  ): Observable<FollowUp> {
    const newPayload: any = { ...payload };
    if ('date' in payload) {
      newPayload.date = moment(payload.date).toISOString(true);
    }
    return this.rolesService.userHasRoleFilter<FollowUp>(
      'ROLE_MWS_FOLLOW_UP_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, newPayload, true)
    );
  }

  deleteFollowUp(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<FollowUp>(
      'ROLE_MWS_FOLLOW_UP_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
