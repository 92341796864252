import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { LeadCommentTagsActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.LeadCommentTag };
  errors: ErrorsObject;
  leadCommentTagsErrors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  leadCommentTagsErrors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    LeadCommentTagsActions.LoadLeadCommentTag,
    LeadCommentTagsActions.LoadLeadCommentTags,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    LeadCommentTagsActions.LoadLeadCommentTagsSuccess,
    (state, { response }) => {
      const leadCommentTags = response['hydra:member'];
      const entities = leadCommentTags.reduce(
        (
          entities: { [id: string]: fromModuleModels.LeadCommentTag },
          leadCommentTag
        ) => {
          return {
            ...entities,
            [leadCommentTag['@id']]: leadCommentTag
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    LeadCommentTagsActions.LoadLeadCommentTagFail,
    LeadCommentTagsActions.LoadLeadCommentTagsFail,
    state => {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
