import {
  AfterViewInit,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  OnInit
} from '@angular/core';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import * as moment from 'moment/moment';

import {Offer, CorrectionInvoice} from '../../models';
import {Department} from '../../../master-data/models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {removeEmptyFormElements} from '../../../shared/utilities/forms.utility';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {DepartmentsActions} from '../../../master-data/store';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-offers-filter',
  styleUrls: ['offers-filter-form.component.scss'],
  template: `
    <div [formGroup]="form" class="form">
      <div class="row">
        <div class="col-9">
          <div class="row">
            <div class="col-2">
              <mat-form-field>
                <input
                  type="text"
                  matInput
                  placeholder="Autragsnummer"
                  formControlName="orderNumber"
                />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>{{"invoices.invoices_preview.invoices_filter.exact_sum" | translate}}</mat-label>
                <input type="text" matInput formControlName="exactGrossPrice" currencyMask
                       [options]="{ prefix: '', suffix: '€', allowNegative: false}">
                <mat-error>
                  <app-form-error
                    fieldName="exactGrossPrice"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <ng-select
                placeholder="Bereich"
                [items]="departments$ | async"
                [loading]="departmentIsLoading$ | async"
                bindValue="@id"
                bindLabel="name"
                [markFirst]="false"
                [clearable]="true"
                formControlName="department"
              ></ng-select>
            </div>
            <div class="col-2 pt-4">
              <a (click)="resetFilter()" class="link-primary cursor-pointer">Filter zurücksetzen</a>
            </div>

          </div>
        </div>

        <div class="col-3">
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label>Angebotsdatum vom</mat-label>
                <input type="date" matInput formControlName="createdAtMin"/>
                <mat-error>
                  <app-form-error
                    fieldName="createdAtMin"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Angebotsdatum bis</mat-label>
                <input type="date" matInput formControlName="createdAtMax"/>
                <mat-error>
                  <app-form-error
                    fieldName="createdAtMax"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OffersFilterFormComponent implements OnInit, OnDestroy {
  @Output() updateFilter: EventEmitter<{
    [key: string]: string;
  }> = new EventEmitter<{ [key: string]: string }>();
  @Input() items: Array<Offer>;

  departments$: Observable<Array<Department>>;
  departmentIsLoading$: Observable<boolean>;

  form: FormGroup;
  storedFilters?: any;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private searchAddonDataService: SearchAddonDataService,
    public localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    this.loadDepartments();
    this.form = this.fb.group({
      orderNumber: this.fb.control(null),
      department: this.fb.control(null),
      createdAtMin: this.fb.control(null),
      exactGrossPrice: this.fb.control(0),
      createdAtMax: this.fb.control(null)
    });
    this.form.valueChanges.subscribe(value => {
      this.updateFilter.emit(removeEmptyFormElements(value));
    });
  }

  resetFilter(): void {
    this.form.patchValue({
      orderNumber: null,
      department: null,
      state: null,
      createdAtMin: null,
      createdAtMax: null,
      exactGrossPrice: 0,
    });
    this.form.valueChanges.subscribe(value => {
      this.updateFilter.emit(removeEmptyFormElements(value));
      this.localStorageService.overwriteObjectByKey('filters', 'offers_invoices', value);
    });

    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'offers_invoices');
    if(this.storedFilters) {
      this.updateFilter.emit(this.storedFilters);
      this.form.patchValue(this.storedFilters);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private loadDepartments(): void {
    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    this.departmentIsLoading$ = this.store$.select(
      DepartmentsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }
}
