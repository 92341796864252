import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {RemoteAccessReasonsActions} from '../actions';
import {RemoteAccessReasonsService} from '../../services';

@Injectable()
export class RemoteAccessReasonsEffects {

  constructor(private actions$: Actions, private service: RemoteAccessReasonsService) {
  }


  ReadRemoteAccessReason$ = createEffect(() => this.actions$.pipe(
    ofType(RemoteAccessReasonsActions.ReadRemoteAccessReason),
    switchMap(({iri}) => {
      return this.service.readRemoteAccessReason(iri).pipe(
        map((response: any) => RemoteAccessReasonsActions.ReadRemoteAccessReasonSuccess({response})),
        catchError((response: HttpErrorResponse) => of(RemoteAccessReasonsActions.ReadRemoteAccessReasonFail({response})))
      );
    })
  ));


  ReadRemoteAccessReasonTags$ = createEffect(() => this.actions$.pipe(
    ofType(RemoteAccessReasonsActions.ReadRemoteAccessReasons),
    switchMap(() => {
      return this.service.readRemoteAccessReasons().pipe(
        map((response: any) => RemoteAccessReasonsActions.ReadRemoteAccessReasonsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(RemoteAccessReasonsActions.ReadRemoteAccessReasonsFail({response})))
      );
    })
  ));
}
