import { Injectable } from '@angular/core';

export class LocalStorageService {
  constructor() {}

  // Get data from local storage
  get(key: string): any {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        console.error('Error parsing local storage item:', error);
        return null;
      }
    }
    return null;
  }

  // Set data to local storage
  set(key: string, value: any): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error serializing local storage item:', error);
    }
  }

  // Remove data from local storage
  remove(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all data from local storage
  clear(): void {
    localStorage.clear();
  }

  // Overwrite an object in local storage based on a key within that object
  overwriteObjectByKey(parentKey: string, key: string, newObject: any): void {
    const existingObject = this.get(parentKey) || {};

    if (newObject && Object.keys(newObject).length > 0) {
      // Merge newObject with existingObject
      existingObject[key] = { ...existingObject[key], ...newObject };
      // Check conditions and delete keys if necessary
      for (const k in newObject) {
        if (
          newObject[k] === null ||
          newObject[k] === undefined ||
          newObject[k] === '' ||
          newObject[k] === 0 ||
          newObject[k] === 'Invalid date'
        ) {
          delete existingObject[key][k];
        }
      }
    }
    Object.keys(existingObject[key]).length === 0 && delete existingObject[key];
    // Set the updated object to local storage
    this.set(parentKey, existingObject);
  }

  // Get object by key and convert it to a JSON object
  getObjectByKey(parentKey: string, key: string): any {
    const parentObject = this.get(parentKey);
    return parentObject ? parentObject[key] : null;
  }
}
