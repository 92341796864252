import {createSelector} from '@ngrx/store';

import {SERVICES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectServicesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[SERVICES_FEATURE_KEY]
);

export const selectServicesEntities = createSelector(
  selectServicesState,
  (state) => state.entities
);

export const selectServices = createSelector(
  selectServicesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectServicesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectServicesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectServicesState,
  (state) => state.loaded
);
