import {createSelector} from '@ngrx/store';

import {DAMAGES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectDamagesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[DAMAGES_FEATURE_KEY]
);

export const selectDamagesEntities = createSelector(
  selectDamagesState,
  (state) => state.entities
);

export const selectDamages = createSelector(
  selectDamagesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectDamagesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectDamagesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDamagesState,
  (state) => state.loaded
);
