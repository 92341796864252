import {createSelector} from '@ngrx/store';

import {DOCUMENT_DELIVERY_PROVIDERS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectDocumentDeliveryProvidersState = createSelector(
  selectInvoicesModuleState,
  (state) => state[DOCUMENT_DELIVERY_PROVIDERS_FEATURE_KEY]
);

export const selectDocumentDeliveryProvidersEntities = createSelector(
  selectDocumentDeliveryProvidersState,
  (state) => state.entities
);

export const selectDocumentDeliveryProviders = createSelector(
  selectDocumentDeliveryProvidersEntities,
  (provider) => Object.keys(provider).map(iri => provider[iri])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDocumentDeliveryProvidersState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectDocumentDeliveryProvidersState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectDocumentDeliveryProvidersState,
  (state) => state.errors
);
