import {AfterViewInit, Component, HostBinding} from '@angular/core';
import {FormGroup} from '@angular/forms';

import * as formModuleModels from '../../models';

@Component({
  selector: 'form-button',
  styleUrls: ['form-button.component.scss'],
  template: `

    <div class="dynamic-field form-button" [formGroup]="group">
      <button mat-raised-button [color]="config.color ? config.color : 'accent'"
              type="submit">{{ config.label }}</button>
    </div>
  `,
})
export class FormButtonComponent implements AfterViewInit {

  @HostBinding('attr.class')
  column = 'column-auto';

  config;

  group: FormGroup;

  ngAfterViewInit(): void {
    if (this?.config?.colSpan) {
      this.column = `column-${this?.config?.colSpan}`;
    }
  }
}
