import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_FILE_SYSTEM = '[Master Data] Create File System';
export const CREATE_FILE_SYSTEM_SUCCESS = '[Master Data] Create File System Success';
export const CREATE_FILE_SYSTEM_FAIL = '[Master Data] Create File System Fail';
export const CreateFileSystem = createAction(
  CREATE_FILE_SYSTEM,
  props<{ payload: fromModuleModels.FileSystem }>()
);
export const CreateFileSystemSuccess = createAction(
  CREATE_FILE_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.FileSystem }>()
);
export const CreateFileSystemFail = createAction(
  CREATE_FILE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_FILE_SYSTEM = '[Master Data] Read File System';
export const READ_FILE_SYSTEM_SUCCESS = '[Master Data] Read File System Success';
export const READ_FILE_SYSTEM_FAIL = '[Master Data] Read File System Fail';
export const ReadFileSystem = createAction(
  READ_FILE_SYSTEM,
  props<{ iri: string }>()
);
export const ReadFileSystemSuccess = createAction(
  READ_FILE_SYSTEM_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadFileSystemFail = createAction(
  READ_FILE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_FILE_SYSTEMS = '[Master Data] Read File Systems';
export const READ_FILE_SYSTEMS_SUCCESS = '[Master Data] Read File Systems Success';
export const READ_FILE_SYSTEMS_FAIL = '[Master Data] Read File Systems Fail';
export const ReadFileSystems = createAction(
  READ_FILE_SYSTEMS
);
export const ReadFileSystemsSuccess = createAction(
  READ_FILE_SYSTEMS_SUCCESS,
  props<{ response: any }>()
);
export const ReadFileSystemsFail = createAction(
  READ_FILE_SYSTEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_FILE_SYSTEM = '[Master Data] Update File System';
export const UPDATE_FILE_SYSTEM_SUCCESS = '[Master Data] Update File System Success';
export const UPDATE_FILE_SYSTEM_FAIL = '[Master Data] Update File System Fail';
export const UpdateFileSystem = createAction(
  UPDATE_FILE_SYSTEM,
  props<{ iri: string, payload: fromModuleModels.FileSystem }>()
);
export const UpdateFileSystemSuccess = createAction(
  UPDATE_FILE_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.FileSystem }>()
);
export const UpdateFileSystemFail = createAction(
  UPDATE_FILE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_FILE_SYSTEM = '[Master Data] Delete File System';
export const DELETE_FILE_SYSTEM_SUCCESS = '[Master Data] Delete File System Success';
export const DELETE_FILE_SYSTEM_FAIL = '[Master Data] Delete File System Fail';
export const DeleteFileSystem = createAction(
  DELETE_FILE_SYSTEM,
  props<{ iri: string }>()
);
export const DeleteFileSystemSuccess = createAction(
  DELETE_FILE_SYSTEM_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteFileSystemFail = createAction(
  DELETE_FILE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
