import {createSelector} from '@ngrx/store';

import {PARTNER_WEBSITES_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectPartnerWebsitesState = createSelector(
  selectCustomersModuleState,
  (state) => state[PARTNER_WEBSITES_FEATURE_KEY]
);

export const selectPartnerWebsiteEntities = createSelector(
  selectPartnerWebsitesState,
  (state) => state.entities
);

export const selectPartnerWebsiteByIndex = createSelector(
  selectPartnerWebsiteEntities,
  (websites, {iri}) => websites[iri]
);

export const selectPartnerWebsites = createSelector(
  selectPartnerWebsiteEntities,
  (websites) => Object.values(websites)
);
export const selectCustomerWebsites = createSelector(
  selectPartnerWebsites,
  (websites) => websites.filter(e => !!e.customer)
);

export const selectPartnerWebsitesByCustomerIri = createSelector(
  selectPartnerWebsites,
  (websites, {iri}) => websites.filter(contact => contact.customer === iri)
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectPartnerWebsitesState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectPartnerWebsitesState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Error

export const selectErrors = createSelector(
  selectPartnerWebsitesState,
  (state) => state.errors
);
