import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { AuthService } from '../../../auth/services/auth.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ShipmentsService } from '../../../shipping/services/shipments.service';
import { MatDialog } from '@angular/material/dialog';
import { Shipment } from '../../../shipping/models';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { extractIri } from '../../utilities/objects.utility';
import { ShowShipmentDialogComponent } from '../../components/show-shipment-dialog/show-shipment-dialog.component';
import { ShipmentsActions } from '../../../shipping/store';
import { OrdersActions } from '../../../orders/store';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { TransitionsActions } from '../../../application-state/store/actions';

@Component({
  selector: 'app-action-box-shipment-on-the-way-to-dr-de',
  styleUrls: ['./action-box-shipment-on-the-way-to-dr-de.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row">
          <div class=" col-auto my-2 align-items-center">
            <div class="row " *ngIf="canMakeTransition">
              <div class=" col-auto header">Datenträger zurück in Leipzig?</div>
              <div class="sub-header col-auto">
                Ist das Speichersystem bereits in Leipzig eingetroffen?
              </div>
            </div>
            <div class="row " *ngIf="!canMakeTransition">
              <div class=" col-auto header">
                Warte das Datenträger in Leipzig eintrifft.
              </div>
              <div class="sub-header col-auto"></div>
            </div>
          </div>
          <div class="actions col text-right">
            <button
              class="decision-btn me-3"
              mat-button
              [color]="canMakeTransition ? 'green' : ''"
              (click)="openShipment()"
              [disabled]="(isLoadingShipments$ | async) || !lastShipment"
            >
              <span>Sendung öffnen</span>
            </button>
            <br *ngIf="canMakeTransition" />
            <br *ngIf="canMakeTransition" />
            <br *ngIf="canMakeTransition" />
            <a
              class="me-3 text-color-grey text-decoration-none"
              *ngIf="canMakeTransition"
              (click)="handleMakeTransition()"
              [class.text-color-lightgrey]="!okAction"
              [class.cursor-block]="!okAction"
            >
              <span>Versand manuell bestätigen (nur im Notfall)</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxShipmentOnTheWayToDrDeComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  isLoadingShipments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  lastShipment: Shipment = null;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private shipmentsService: ShipmentsService,
    private dialog: MatDialog,
    private actions$: Actions,
    private authService: AuthService
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' && this.authService.isLogistic()) ||
      this.authService.isAdmin() ||
      this.authService.isSupervisor() ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf(
              'dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext_to_dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext'
            ) > -1 && !e.error
        );
        this.loadShipment();
      });
    this.actions$
      .pipe(
        ofType(ShipmentsActions.ConfirmShipmentSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OrdersActions.ReadOrder({ iri: extractIri(this.order) })
        );
      });
  }

  loadShipment(): void {
    this.isLoadingShipments$.next(true);

    const params: any = {
      pagination: true,
      'order[createdAt]': 'desc',
      'orders.uuid': getUuidFromIri(extractIri(this.order))
    };

    this.shipmentsService.readShipments(-1, params).subscribe(
      data => {
        this.lastShipment = data['hydra:member'][0];
        this.isLoadingShipments$.next(false);
      },
      error => {
        console.log(error);
        this.isLoadingShipments$.next(false);
      }
    );
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  openShipment(): void {
    this.dialog.open(ShowShipmentDialogComponent, {
      panelClass: 'width-95',
      data: { shipment: extractIri(this.lastShipment) }
    });
  }
}
