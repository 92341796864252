import {Injectable} from "@angular/core";
import {AbstractApiService} from "../../shared/services";
import {UserRoleService} from "../../shared/services/user-role.service";
import {EMPTY, Observable} from "rxjs";
import {FeedbackRequest} from "../models";
import {HttpParams} from "@angular/common/http";

@Injectable()
export class NewFeedbackRequestsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {
  }

  getSentFeedbackRequests(iri: string, params?: Array<string>): Observable<Array<FeedbackRequest>> {
    let p = new HttpParams();

    if (params) {
      params.forEach((item, index) => {
        p = p.append(item, 'true');
      });
    }
    return this.rolesService.userHasRoleFilter<Array<FeedbackRequest>>(
      'ROLE_MWS_FEEDBACK_REQUEST_LIST_SENT',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`${iri}/feedback_requests/sent${params && `?${p}`}`, true)
    );
  }

  getReceivedFeedbackRequests(iri: string, params?: Array<string>): Observable<Array<FeedbackRequest>> {
    let p = new HttpParams();

    if (params) {
      params.forEach((item, index) => {
        p = p.append(item, 'true');
      });
    }
    return this.rolesService.userHasRoleFilter<Array<FeedbackRequest>>(
      'ROLE_MWS_FEEDBACK_REQUEST_LIST_SENT',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`${iri}/feedback_requests/received${params && `?${p}`}`, true)
    );
  }


  patchFeedbackRequest(
    iri: string,
    module: 'customer' | 'lead' | 'order' | 'ticket',
    variation: 'answer' | 'resolve',
    payload: any
  ): Observable<any> {

    // const roles = [
    //   "ROLE_MWS_CUSTOMER_COMMENT_FEEDBACK_REQUEST_ANSWER",
    //   "ROLE_MWS_CUSTOMER_COMMENT_FEEDBACK_REQUEST_RESOLVE",
    //   "ROLE_MWS_LEAD_COMMENT_FEEDBACK_REQUEST_ANSWER",
    //   "ROLE_MWS_LEAD_COMMENT_FEEDBACK_REQUEST_RESOLVE",
    //   "ROLE_MWS_ORDER_COMMENT_FEEDBACK_REQUEST_ANSWER",
    //   "ROLE_MWS_ORDER_COMMENT_FEEDBACK_REQUEST_RESOLVE",
    //   "ROLE_MWS_TICKET_COMMENT_FEEDBACK_REQUEST_ANSWER",
    //   "ROLE_MWS_TICKET_COMMENT_FEEDBACK_REQUEST_RESOLVE"
    // ]
    // let role: string;
    //
    // for (const value of roles) {
    //   if (value.includes(module.toUpperCase()) && value.includes(variation.toUpperCase())) {
    //     role = value;
    //   }
    // }

    return this.apiService.patchObject(`${iri}/feedback_request/${variation}`, payload, true);
  }
}
