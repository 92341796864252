import {createSelector} from '@ngrx/store';

import {SERVICE_ORDER_ITEMS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectServiceOrderItemsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[SERVICE_ORDER_ITEMS_FEATURE_KEY]
);

export const selectServiceOrderItemsEntities = createSelector(
  selectServiceOrderItemsState,
  (state) => state.entities
);

export const selectServiceOrderItems = createSelector(
  selectServiceOrderItemsEntities,
  (entities) => Object.values(entities)
);

export const selectServiceOrderItemsForOrder = createSelector(
  selectServiceOrderItems,
  (entities, props) => entities.filter(service => service.order === props.iri)
);

export const selectErrors = createSelector(
  selectServiceOrderItemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectServiceOrderItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectServiceOrderItemsState,
  (state) => state.loaded
);
