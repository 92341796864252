import {createSelector} from '@ngrx/store';

import {DATA_MEDIUM_STATUS_LOGS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectDataMediumStatusLogsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[DATA_MEDIUM_STATUS_LOGS_FEATURE_KEY]
);

export const selectDataMediumStatusLogsEntities = createSelector(
  selectDataMediumStatusLogsState,
  (state) => state.entities
);

export const selectDataMediumStatusLogs = createSelector(
  selectDataMediumStatusLogsEntities,
  (entities) => Object.values(entities)
);


export const selectDataMediumStatusLogsByOrder = createSelector(
  selectDataMediumStatusLogs,
  (entities, props) => entities.filter(entry => entry.order === props.iri)
);

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectDataMediumStatusLogsErrors = createSelector(
  selectDataMediumStatusLogsState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDataMediumStatusLogsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDataMediumStatusLogsState,
  (state) => state.loaded
);
