import {Component, HostBinding, OnInit} from '@angular/core';

import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

// Provider Import
import {ApplicationState} from './application-state/store';
import {AuthSelectors} from './auth/store/selectors';
import {MenuItem} from './navigation/models';
import {environment} from '../environments/environment';
import {selectRouterUrl} from './application-state/store/selectors';

@Component({
  selector: 'authenticated-app-shell',
  template: `

    <!--<pre data-master-debug cdkDrag *ngIf="!isProduction" [style]="styles">{{ store$ | async | json }}</pre>-->

    <header>
      <app-smart-bar
      ></app-smart-bar>
      <app-navbar
        [menuItems]="menuItems"
      ></app-navbar>
    </header>

    <main [class]="slug$ | async">
      <router-outlet
      ></router-outlet>
    </main>

    <footer>
      <app-footer
      ></app-footer>
    </footer>
  `
})
export class UserAuthenticatedShellComponent implements OnInit {

  // Helper class for hiding debug output if left in source code
  @HostBinding('class.debug')
  debug = !this.isProduction;

  isAuthenticated$: Observable<boolean>;
  menuItems: Array<MenuItem>;
  slug$: any;
  store$: Observable<ApplicationState>;
  styles = `
    left: 30px;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transform: translateY(-50%);
    resize: both;
    width: 450px;`;
  title = 'bmoffice24';

  get isProduction(): boolean {
    return environment.production;
  }

  constructor(private store: Store<ApplicationState>) {

    this.menuItems = [
      {
        iconIdentifier: 'speed',
        label: 'Dashboard',
        linkTarget: 'dashboard'
      }, {
        iconIdentifier: 'people_outline',
        label: 'Admins',
        linkTarget: 'administrators'
      }, {
        iconIdentifier: 'people',
        label: 'Kunden',
        linkTarget: 'customers'
      }, {
        iconIdentifier: 'message',
        label: 'Tickets',
        linkTarget: 'tickets'
      }, {
        iconIdentifier: 'insert_emoticon',
        label: 'Aufträge',
        linkTarget: 'orders',
      }, {
        iconIdentifier: 'content_paste',
        label: 'Faktura',
        linkTarget: 'invoices',
      }, {
        iconIdentifier: 'dashboard',
        label: 'Anfragen',
        linkTarget: 'leads',
      }, {
        iconIdentifier: 'topic',
        label: 'Lager',
        linkTarget: 'warehouse',
      }, {
        iconIdentifier: 'local_shipping',
        label: 'Versand',
        linkTarget: 'shipments'
      }, {
        iconIdentifier: 'campaign',
        label: 'Vertrieb',
        linkTarget: 'sales',
      }, {
        iconIdentifier: 'how_to_reg',
        label: 'Partner',
        linkTarget: 'partners',
        disabled: true
      }/*, {
        iconIdentifier: 'insert_chart_outlined',
        label: 'Berichte',
        linkTarget: 'reports',
        disabled: true
      }*/, {
        iconIdentifier: 'api',
        label: 'Stammdaten',
        linkTarget: 'master-data',
      }
    ];
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.store.pipe(select(AuthSelectors.selectIsAuthenticated));
    this.slug$ = this.store.pipe(select(selectRouterUrl), map((slug: string) => slug.split('/')[1]));
    this.store$ = this.store.pipe();
  }
}
