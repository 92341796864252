import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

import {filter, map, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromCustomersModuleModels from '../../../customers/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {extractIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'customer-address-form',
  styleUrls: ['customer-address-form.component.scss'],
  template: `
    <div class="card" [class.mat-elevation-z1]="!isExternalCall">
      <div class="card__heading">
        <span
        >Adresse {{ !!presets$?.getValue() ? 'bearbeiten' : 'anlegen' }}</span
        >
      </div>

      <div class="card__content p-a--24">
        <div [formGroup]="caf" [class.standalone]="isExternalCall">
          <div class="grid">
            <mat-form-field class="column-8">
              <mat-label>Adress-Typ</mat-label>
              <mat-select
                (valueChange)="updateFormTarget($event)"
                [value]="getAddressType()"
                #addressTypeInput
                required
              >
                <mat-option value="/api/billing_addresses"
                >{{ "customers.customer_view.customer_address_form_address_type.billing_address" | translate }}
                </mat-option>
                <mat-option value="/api/delivery_addresses"
                >{{ "customers.customer_view.customer_address_form_address_type.delivery_address" | translate }}
                </mat-option>
                <mat-option value="/api/partner_branch_office_addresses" *ngIf="isPartner"
                >{{ "customers.customer_view.customer_address_form_address_type.partner_office_address" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="column-6">
              <mat-form-field>
                <mat-label>Art der Adresse</mat-label>
                <mat-select formControlName="addressType" required>
                  <mat-option
                    *ngFor="let addressType of addressTypes$ | async"
                    [value]="addressType['@id']"
                  >{{ addressType.name }}
                  </mat-option
                  >
                </mat-select>
                <mat-hint align="start" *ngIf="errors?.addressType">{{
                    errors.addressType.message
                  }}
                </mat-hint>
              </mat-form-field>
            </div>

            <ng-container formGroupName="address">
              <mat-form-field class="column-9">
                <mat-label>Straße</mat-label>
                <input type="text" matInput formControlName="line1" required/>
                <mat-hint align="start" *ngIf="errors?.line1">{{
                    errors.line1.message
                  }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>Hausnummer</mat-label>
                <input type="text" matInput formControlName="line2" required/>
                <mat-hint align="start" *ngIf="errors?.line2">{{
                    errors.line2.message
                  }}
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="column-14">
                <mat-label>c/o, Adresszusatz</mat-label>
                <input formControlName="line3" matInput type="text"/>
                <mat-error>
                  <app-form-error
                    [fieldName]="'line3'"
                    [formGroup]="caf"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>PLZ</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="zipPostcode"
                  required
                />
                <mat-hint align="start" *ngIf="errors?.zipPostcode">{{
                    errors.zipPostcode.message
                  }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="column-9">
                <mat-label>Stadt</mat-label>
                <input type="text" matInput formControlName="city" required/>
                <mat-hint align="start" *ngIf="errors?.city">{{
                    errors.city.message
                  }}
                </mat-hint>
              </mat-form-field>

              <div class="mat-form-field column-14">
                <ng-select
                  placeholder="Land*"
                  [items]="countries$ | async"
                  bindLabel="name"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="country"
                  bindValue="code"
                  [appendTo]="isExternalCall ? 'body' : null"
                ></ng-select>
                <mat-hint align="start" *ngIf="errors?.country">{{
                    errors.country.message
                  }}
                </mat-hint>
              </div>
            </ng-container>

            <mat-form-field  class="column-14" *ngIf="isPartnerBranchOfficeType">
              <mat-label>Partner Branch Office Manager</mat-label>
              <mat-select formControlName="partnerBranchOfficeManager" required>
                <mat-option
                  *ngFor="let contact of contacts"
                  [value]="contact"
                >{{ contact.firstName }} {{ contact.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="m-ta--2 column-14">
              <button
                *ngIf="presets$?.getValue() || isExternalCall"
                (click)="handleCancelEdit()"
                type="button"
                color="outline"
                mat-flat-button
              >
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <span class="p-r--10"></span>

              <button
                [disabled]="
                  caf.invalid || caf.pristine || !!!addressTypeInput.value
                "
                mat-flat-button
                color="green"
                (click)="handleSubmit()"
              >
                <mat-icon>save</mat-icon>
                <span>{{
                    presets$?.getValue() && !isCopying$.getValue()
                      ? 'Aktualisieren'
                      : 'Anlegen'
                  }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ formTarget$.getValue() | json }}</pre>-->
    <!--<pre>{{ isExternalCall | json }}</pre>-->
  `
})
export class CustomerAddressFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() addressTypes$: Observable<
    Array<fromMasterDataModuleModels.AddressType>
  >;
  @Input() countries$: Observable<Array<fromMasterDataModuleModels.Country>>;
  @Input() contacts$: Observable<Array<fromCustomersModuleModels.CustomerContact>> = of([]);
  @Input() errors: ErrorsObject;
  @Input() isCopying$: BehaviorSubject<boolean>;
  @Input() isPartner: boolean;
  @Input() isExternalCall: boolean;
  @Input() presets$: BehaviorSubject<fromCustomersModuleModels.CustomerAddress>;

  @Output() requestCloseModal: EventEmitter<void> = new EventEmitter();
  @Output() requestCreateCustomerAddress: EventEmitter<{
    apiRoute: string;
    payload: fromCustomersModuleModels.CustomerAddress;
  }> = new EventEmitter();
  @Output() requestUpdateCustomerAddress: EventEmitter<{
    iri: string;
    payload: fromCustomersModuleModels.CustomerAddress;
  }> = new EventEmitter();

  caf: FormGroup;
  formTarget$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  onDestroy$: Subject<any> = new Subject<any>();
  isPartnerBranchOfficeType: boolean = false;
  contacts: any;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  getAddressType(address?: fromCustomersModuleModels.CustomerAddress): string {
    // presets$ can be undefined
    const presets = address ? address : this.presets$?.getValue();
    if (presets && presets.hasOwnProperty('@type')) {
      const addressType = presets['@type'];

      if (addressType === 'BillingAddress') {
        return '/api/billing_addresses';
      }

      if (addressType === 'DeliveryAddress') {
        return '/api/delivery_addresses';
      }

      if (addressType === 'PartnerBranchOfficeAddress') {
        return '/api/partner_branch_office_addresses';
      }
    }
    return null;
  }

  ngOnInit(): void {
    this.initForm();
    this.contacts$
      .pipe(
        takeUntil(this.onDestroy$),
        map(contacts =>
          contacts.filter(contact => contact.customerContactTypes.includes("/api/customer_contact_types/3")))
      ).subscribe(contacts => this.contacts = contacts);
  }

  ngAfterViewInit(): void {
    if (!this.isExternalCall) {
      this.presets$
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e)
        )
        .subscribe(address => {
          const payload = {
            ...address,
            addressType: extractIri(address.addressType)
          };
          this.fs.patchForm(this.caf, payload);
          this.formTarget$.next(this.getAddressType(address));
        });
    }
  }

  // todo: Migrate to FormsService.patchForm()
  patchForm(presets: fromCustomersModuleModels.CustomerAddress) {
    Object.keys(presets).forEach(key => {
      console.log(
        `${key} exists on form; will be patched with value`,
        presets[key]
      );
      console.log(typeof presets[key]);
      const formInput = this.caf.get(key);

      if (formInput && !!presets[key]) {
        if (key === 'addressType') {
          formInput.setValue(presets[key]['@id']);
          return;
        }
        // handle nested value objects. maybe recursion or by using ngx-neat
        if (key === 'address' && typeof presets[key] === 'object') {
          const nested = presets[key];

          Object.keys(nested).forEach(nestedKey => {
            const nestedInput = this.caf.get(`${key}.${nestedKey}`);

            if (nestedInput && !!nested[nestedKey]) {
              nestedInput.setValue(nested[nestedKey]);
            }
          });
          return;
        }

        formInput.setValue(presets[key]);
        formInput.markAsTouched();
      }
    });
  }

  initForm(resetValidators = false): void {
    this.caf = this.fb.group({
      addressType: this.fb.control(null, [Validators.required]),
      address: this.fb.group({
        line1: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(35)
        ]),
        line2: this.fb.control(null, [
          Validators.required,
          Validators.maxLength(35)
        ]),
        line3: this.fb.control(''),
        line4: this.fb.control(''),
        city: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50)
        ]),
        zipPostcode: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(10)
        ]),
        stateProvinceCounty: this.fb.control(null),
        country: this.fb.control(null, [Validators.required])
      }),
      partnerBranchOfficeManager: [{value: '', disabled: true}, Validators.required],
      showOnMap: this.fb.control(false, [Validators.required])
    });

    // Avoid useless "error" messages when resetting / emptying form
    if (resetValidators) {
      const controls: Array<AbstractControl> = Object.keys(
        this.caf.controls
      ).map(key => this.caf.controls[key]);
      controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
          const innerControls: Array<AbstractControl> = Object.keys(
            control.controls
          ).map(key => control.controls[key]);
          innerControls.forEach((innerControl: AbstractControl) => {
            innerControl.clearValidators();
          });
        }
        control.clearValidators();
      });
      this.caf.markAsUntouched();
    }
  }

  handleSubmit(): void {
    const payload = {
      iri: this.presets$?.getValue()?.['@id'],
      payload: {...this.caf.value}
    };

    this.presets$?.getValue() && !this.isCopying$.getValue()
      ? this.requestUpdateCustomerAddress.emit(payload)
      : this.requestCreateCustomerAddress.emit({
        apiRoute: this.formTarget$.getValue(),
        payload: this.caf.value
      });
  }

  handleCancelEdit() {
    this.initForm();
    this.isCopying$.next(false);
    this.presets$?.next(null);
    this.formTarget$.next(null);
    this.requestCloseModal.emit();
  }

  updateFormTarget(type: string): void {
    this.formTarget$.next(type);
    if (type === '/api/partner_branch_office_addresses') {
      this.isPartnerBranchOfficeType = true;
      this.caf.get('partnerBranchOfficeManager').enable();
      this.caf.get('partnerBranchOfficeManager').setValidators(Validators.required);
    } else {
      this.isPartnerBranchOfficeType = false;
      this.caf.get('partnerBranchOfficeManager').disable();
      this.caf.get('partnerBranchOfficeManager').clearValidators();
    }
    this.caf.get('partnerBranchOfficeManager').updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
