import { Component, OnInit } from '@angular/core';
import { without } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { PayableInvoice } from '../../models';
import { PageEvent } from '@angular/material/paginator';
import { RouterActions } from '../../../application-state/store/actions';
import { payableInvoiceStateMapDE } from '../../helper/invoice-state-maps';
import {
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { PayableInvoicesService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { PayableInvoicesActions } from '../../store';

@Component({
  selector: 'app-open-invoice-list-widget',
  templateUrl: './open-invoice-list-widget.component.html',
  styleUrls: ['./open-invoice-list-widget.component.scss']
})
export class OpenInvoiceListWidgetComponent extends BaseOnDestroyComponent
  implements OnInit {
  pagination$: BehaviorSubject<{ [p: string]: string }> = new BehaviorSubject<{
    [p: string]: string;
  }>(null);
  params$: BehaviorSubject<{
    [p: string]: string | string[] | number;
  }> = new BehaviorSubject({
    'order[dueDate]': 'desc',
    // payment_status: 'overdue',
    itemsPerPage: 10,
    pageIndex: 0,
    state: ['booked', 'first_reminder', 'second_reminder', 'dept_collection']
  });
  subscription: any;

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  totalItems$: BehaviorSubject<number> = new BehaviorSubject(0);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  payableInvoices$: BehaviorSubject<
    Array<PayableInvoice>
  > = new BehaviorSubject<Array<PayableInvoice>>([]);
  stateMap = payableInvoiceStateMapDE;
  protected readonly extractUUID = extractUUID;
  protected readonly extractTypeByIri = extractTypeByIri;

  constructor(
    private store$: Store<ApplicationState>,
    private apiService: PayableInvoicesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
    this.params$.subscribe(() => {
      this.loadData();
    });
    this.page$.subscribe(() => {
      this.loadData();
    });
  }

  loadData(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.isLoading$.next(true);
    this.subscription = this.apiService
      .readPayableInvoices(this.page$.getValue(), this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        console.log(response);
        this.payableInvoices$.next(response['hydra:member']);
        this.totalItems$.next(response['hydra:totalItems']);
        this.store$.dispatch(
          PayableInvoicesActions.ReadPayableInvoicesSuccess({
            response
          })
        );

        this.isLoading$.next(false);
      });
  }

  getCircleColor(element: PayableInvoice): string[] {
    if (
      element.state === 'booked' &&
      !element.overdue &&
      parseFloat(element.amountOutstanding.value) === 0.0
    ) {
      return ['green'];
    } else if (element.state === 'booked' && !element.overdue) {
      return [];
    } else if (
      element.state === 'first_reminder' ||
      element.state === 'second_reminder' ||
      (element.state === 'booked' && element.overdue)
    ) {
      return ['yellow'];
    } else if (element.state === 'dept_collection') {
      return ['red'];
    } else {
      return [];
    }
  }

  hasSelectedState(state: string): boolean {
    // @ts-ignore
    return this.params$.getValue().state.indexOf(state) > -1;
  }

  toggleSelectedStates(state): void {
    if (this.hasSelectedState(state)) {
      this.params$.next({
        ...this.params$.getValue(),
        state: without(this.params$.getValue().state, state)
      });
    } else {
      this.params$.next({
        ...this.params$.getValue(),
        // @ts-ignore
        state: [...this.params$.getValue().state, state]
      });
    }
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.page$.next(event.pageIndex + 1);
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: event.pageSize.toString()
    });
  }

  handleClick($event): void {
    console.log('handleClick', $event);
    this.store$.dispatch(
      RouterActions.Go({ path: ['invoices', 'payable', $event['@id']] })
    );
  }
}
