import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { Tax } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class TaxesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createTax(payload: Tax): Observable<Tax> {
    return this.rolesService.userHasRoleFilter<Tax>('ROLE_MWS_TAX_CREATE', () =>
      this.apiService.createObject(`/taxes`, payload)
    );
  }

  readTaxes(): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TAX_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/taxes`)
    );
  }

  readTax(iri: string): Observable<Tax> {
    return this.rolesService.userHasRoleFilter<Tax>('ROLE_MWS_TAX_VIEW', () =>
      this.apiService.getObject(iri, true)
    );
  }

  updateTax(iri, payload): Observable<Tax> {
    return this.rolesService.userHasRoleFilter<Tax>('ROLE_MWS_TAX_EDIT', () =>
      this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteTax(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TAX_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
