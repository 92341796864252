import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {CorrectionInvoicesSelectors} from '../store/selectors';
import {CorrectionInvoicesActions} from '../store';

@Injectable()
export class CurrentCorrectionInvoiceLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const iri = route.params.iri;

    return this.currentCorrectionInvoiceLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentCorrectionInvoiceLoaded(iri: string): Observable<boolean | any> {
    return this.store.pipe(
      select(CorrectionInvoicesSelectors.selectCurrentCorrectionInvoice),
      tap(invoice => {
        if (!invoice) {
          this.store.dispatch(CorrectionInvoicesActions.ReadCorrectionInvoice({iri}));
        }
      }),
      filter(invoice => !!invoice),
      take(2)
    );
  }
}
