import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {CustomersActions} from '../store';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {CustomersSelectors} from '../store/selectors';

@Injectable()
export class CustomerLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const uuid = route.params.uuid;
    const iri = `/api/customers/${uuid}`;

    return this.currentCustomerLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentCustomerLoaded(iri: string): Observable<boolean | any> {
    return this.store.pipe(
      select(CustomersSelectors.selectCurrentCustomer),
      tap(customer => {
        if (!customer) {
          this.store.dispatch(CustomersActions.ReadCustomer({iri}));
        }
      }),
      filter(ticket => !!ticket),
      take(1)
    );
  }
}
