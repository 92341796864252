import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Create Customer Replacement Stock Item';
export const CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Create Customer Replacement Stock Item Success';
export const CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Create Customer Replacement Stock Item Fail';

export const CreateCustomerReplacementStockItem = createAction(
  CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM,
  props<{ payload: fromModuleModels.CustomerReplacementStockItem }>()
);

export const CreateCustomerReplacementStockItemSuccess = createAction(
  CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: fromModuleModels.CustomerReplacementStockItem | any }>()
);

export const CreateCustomerReplacementStockItemFail = createAction(
  CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS = '[Warehouse Module] Read Customer Replacement Stock Items';
export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS_SUCCESS = '[Warehouse Module] Read Customer Replacement Stock Items Success';
export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS_FAIL = '[Warehouse Module] Read Customer Replacement Stock Items Fail';

export const ReadCustomerReplacementStockItems = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS,
  props<{ page: number, params?: any }>()
);

export const ReadCustomerReplacementStockItemsSuccess = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerReplacementStockItemsFail = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Read Customer Replacement Stock Item';
export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Read Customer Replacement Stock Item Success';
export const READ_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Read Customer Replacement Stock Item Fail';

export const ReadCustomerReplacementStockItem = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string }>()
);

export const ReadCustomerReplacementStockItemSuccess = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerReplacementStockItemFail = createAction(
  READ_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Update Customer Replacement Stock Item';
export const UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Update Customer Replacement Stock Item Success';
export const UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Update Customer Replacement Stock Item Fail';

export const UpdateCustomerReplacementStockItem = createAction(
  UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string, payload: fromModuleModels.CustomerReplacementStockItem }>()
);

export const UpdateCustomerReplacementStockItemSuccess = createAction(
  UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const UpdateCustomerReplacementStockItemFail = createAction(
  UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM = '[Warehouse Module] Delete Customer Replacement Stock Item';
export const DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS = '[Warehouse Module] Delete Customer Replacement Stock Item Success';
export const DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL = '[Warehouse Module] Delete Customer Replacement Stock Item Fail';

export const DeleteCustomerReplacementStockItem = createAction(
  DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM,
  props<{ iri: string }>()
);

export const DeleteCustomerReplacementStockItemSuccess = createAction(
  DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerReplacementStockItemFail = createAction(
  DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
