import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { Customer, CustomerContact } from '../../../customers/models';
import {
  AnalysisPriorityMode,
  DataRecoveryPriorityMode,
  Discount,
  DisposalType,
  OperatingSystem,
  ReplacementDataMediumSource
} from '../../../master-data/models';
import { FormsService } from '../../../shared/services';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  AnalysisPriorityModesSelectors,
  DataRecoveryPriorityModesSelectors,
  DiscountsSelectors,
  DisposalTypesSelectors,
  OperatingSystemsSelectors,
  ReplacementDataMediumSourcesSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import {
  AnalysisPriorityModesActions,
  DataRecoveryPriorityModesActions,
  DiscountsActions,
  DisposalTypesActions,
  OperatingSystemsActions,
  ReplacementDataMediumSourcesActions
} from '../../../master-data/store';
import { ShippingProvider } from '../../../shipping/models';
import { ShippingProvidersToCustomerSelectors } from '../../../shipping/store/selectors';
import { ShippingProvidersActions } from '../../../shipping/store';
import { Actions, ofType } from '@ngrx/effects';
import { OrdersActions } from '../../store';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import { Order } from '../../models';
import { convertNumberToFloatString } from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-binding-order-detail-form',
  styleUrls: ['binding-order-detail-form.component.scss'],
  template: `
    <div class="" [formGroup]="of">
      <div class="row">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Ersatzdatenträger"
              [items]="replacementDataMediumSources$ | async"
              [loading]="replacementDataMediumSourcesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              class="ng-select-auto-width"
              formControlName="replacementDataMediumSource"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'replacementDataMediumSource'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Alte Datenträger"
              [items]="disposalTypes$ | async"
              [loading]="disposalTypesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              appendTo="body"
              [markFirst]="false"
              [clearable]="false"
              formControlName="disposalType"
              class="ng-select-auto-width"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'disposalType'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-form-field">
            <ng-select
              placeholder="Betriebssystem Ersatzdatenträger"
              [items]="replacementOperatingSystems$ | async"
              [loading]="replacementOperatingSystemsIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="replacementDataMediumOperatingSystem"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'replacementDataMediumOperatingSystem'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Rabatt"
              [items]="discounts$ | async"
              bindValue="@id"
              #discountInput
              bindLabel="name"
              [markFirst]="false"
              [searchable]="false"
              [clearable]="true"
              formControlName="discount"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }} ({{ item.value | percentage }} %)
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                {{ item.name }} ({{ item.value | percentage }} %)
              </ng-template>
            </ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'discount'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>

        <div class="col-6">
          <!--<pre>{{ discountInput.selectedValues[0] | json}}</pre>-->
          <mat-form-field>
            <mat-placeholder>Sonderrabatt in %</mat-placeholder>
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="of.get('specialDiscount').setValue(null); sdi.blur()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <input
              type="text"
              matInput
              formControlName="specialDiscount"
              #sdi
              currencyMask
              [options]="{ prefix: '', suffix: '%' }"
            />
            <mat-error>
              <app-form-error
                [fieldName]="'specialDiscount'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Analyse-Modus"
              [items]="analysisPriorityModes$ | async"
              [loading]="analysisPriorityModesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="analysisPriorityMode"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'analysisPriorityMode'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>

        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Modus Datenrettung"
              [items]="dataRecoveryPriorityModes$ | async"
              [loading]="dataRecoveryPriorityModesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="dataRecoveryPriorityMode"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'dataRecoveryPriorityMode'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              placeholder="Übergabe an Kunden"
              [items]="shippingProvidersToCustomer$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              class="ng-select-auto-width"
              formControlName="shippingProviderToCustomer"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'shippingProviderToCustomer'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-placeholder>% Anzahlung</mat-placeholder>
            <input
              type="string"
              matInput
              formControlName="downPayment"
              currencyMask
              [options]="{ prefix: '', suffix: '%' }"
            />
            <mat-error>
              <app-form-error
                [fieldName]="'downPayment'"
                [formGroup]="of"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-placeholder>Zusätzliche Vereinbarungen</mat-placeholder>
          <textarea
            matInput
            rows="4"
            formControlName="additionalAgreements"
            matTextareaAutosize
          ></textarea>
          <mat-error>
            <app-form-error
              [fieldName]="'additionalAgreements'"
              [formGroup]="of"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>

      <!--- ALT -->

      <!--<pre>{{ of.value | json }}</pre>-->
      <!--<pre>{{ errors | json }}</pre>-->
      <!--<pre>{{ customerContacts | json }}</pre>-->
      <!--<pre>{{ of.value | json }}</pre>-->
    </div>
  `
})
export class BindingOrderDetailFormComponent implements OnInit, OnDestroy {
  customer: Customer;
  customerContacts: Array<CustomerContact>;

  analysisPriorityModes$: Observable<Array<AnalysisPriorityMode>>;
  analysisPriorityModesIsLoading$: Observable<boolean>;

  replacementOperatingSystems$: Observable<Array<OperatingSystem>>;
  replacementOperatingSystemsIsLoading$: Observable<boolean>;

  dataRecoveryPriorityModes$: Observable<Array<DataRecoveryPriorityMode>>;
  dataRecoveryPriorityModesIsLoading$: Observable<boolean>;

  discounts$: Observable<Array<Discount>>;
  discountsIsLoading$: Observable<boolean>;

  shippingProvidersToCustomer$: Observable<Array<ShippingProvider>>;
  shippingProvidersToCustomerIsLoading$: Observable<boolean>;

  disposalTypes$: Observable<Array<DisposalType>>;
  disposalTypesIsLoading$: Observable<boolean>;

  replacementDataMediumSources$: Observable<Array<ReplacementDataMediumSource>>;
  replacementDataMediumSourcesIsLoading$: Observable<boolean>;

  @Input() order$: Observable<Order>;
  order: Order;

  @Output() updateFormData: EventEmitter<any> = new EventEmitter();

  of: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    public actions$: Actions
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        const formData: any = { ...order };
        if (order.analysisPriorityMode && order.analysisPriorityMode['@id']) {
          formData.analysisPriorityMode = order.analysisPriorityMode['@id'];
        }
        if (
          order.dataRecoveryPriorityMode &&
          order.dataRecoveryPriorityMode['@id']
        ) {
          formData.dataRecoveryPriorityMode =
            order.dataRecoveryPriorityMode['@id'];
        }
        this.fs.patchForm(this.of, formData);
      });
    this.of
      .get('specialDiscount')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(specialDiscount => {
        if (specialDiscount) {
          this.of.get('discount').disable();
        } else {
          this.of.get('discount').enable();
        }
      });
    this.of
      .get('discount')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(discount => {
        if (discount) {
          this.of.get('specialDiscount').disable();
        } else {
          this.of.get('specialDiscount').enable();
        }
      });
    this.of.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(values => {
        values.downPayment = convertNumberToFloatString(values.downPayment);

        if (values.specialDiscount) {
          values.specialDiscount += '';
        } else {
          values.specialDiscount = null;
        }
        // console.log(removeEmptyFormElements(values, ['specialDiscount', 'discount']));
        this.updateFormData.emit(
          removeEmptyFormElements(values, ['specialDiscount', 'discount'])
        );
      });

    this.loadDataRecoveryPriorityModes();
    this.loadDiscounts();
    this.loadAnalysisPriorityModes();
    this.loadShippingProvidersToCustomer();
    this.loadDisposalTypes();
    this.loadReplacementDataMediumSources();
    this.loadOperatingSystems();
    this.actions$
      .pipe(
        ofType(
          OrdersActions.UpdateOrderFail,
          OrdersActions.ReadBindingOrderPDFFail
        )
      )
      .subscribe(fail => {
        console.log(fail);
        if (fail?.response?.error?.violations) {
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.of
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  cancelEdit(): void {
    this.initForm();
  }

  initForm(): void {
    this.of = this.fb.group({
      replacementDataMediumSource: this.fb.control(null, [Validators.required]),
      disposalType: this.fb.control(null, [Validators.required]),
      replacementDataMediumOperatingSystem: this.fb.control(null, [
        Validators.required
      ]),
      discount: this.fb.control(null),
      specialDiscount: this.fb.control(null),
      analysisPriorityMode: this.fb.control(null, [Validators.required]),
      dataRecoveryPriorityMode: this.fb.control(null, [Validators.required]),
      shippingProviderToCustomer: this.fb.control(null, [Validators.required]),
      downPayment: this.fb.control('0', [Validators.required]),
      additionalAgreements: this.fb.control(null)
    });
  }

  private loadDataRecoveryPriorityModes(): void {
    this.dataRecoveryPriorityModes$ = this.store$.select(
      DataRecoveryPriorityModesSelectors.sList
    );
    this.dataRecoveryPriorityModesIsLoading$ = this.store$.select(
      DataRecoveryPriorityModesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes()
    );
  }

  private loadDiscounts(): void {
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.discountsIsLoading$ = this.store$.select(DiscountsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }

  private loadAnalysisPriorityModes(): void {
    this.analysisPriorityModes$ = this.store$.select(
      AnalysisPriorityModesSelectors.selectAnalysisPriorityModes
    );
    this.analysisPriorityModesIsLoading$ = this.store$.select(
      AnalysisPriorityModesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
  }

  private loadShippingProvidersToCustomer(): void {
    this.shippingProvidersToCustomer$ = this.store$.select(
      ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomer
    );
    this.shippingProvidersToCustomerIsLoading$ = this.store$.select(
      ShippingProvidersToCustomerSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToCustomerSelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToCustomer()
    );
  }

  private loadDisposalTypes(): void {
    this.disposalTypes$ = this.store$.select(
      DisposalTypesSelectors.selectDisposalTypes
    );
    this.disposalTypesIsLoading$ = this.store$.select(
      DisposalTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }

  private loadReplacementDataMediumSources(): void {
    this.replacementDataMediumSources$ = this.store$.select(
      ReplacementDataMediumSourcesSelectors.selectReplacementDataMediumSources
    );
    this.replacementDataMediumSourcesIsLoading$ = this.store$.select(
      ReplacementDataMediumSourcesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ReplacementDataMediumSourcesSelectors.isLoaded,
      ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources()
    );
  }

  private loadOperatingSystems(): void {
    this.replacementOperatingSystems$ = this.store$.select(
      OperatingSystemsSelectors.selectOperatingSystems
    );
    this.replacementOperatingSystemsIsLoading$ = this.store$.select(
      OperatingSystemsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      OperatingSystemsSelectors.isLoaded,
      OperatingSystemsActions.ReadOperatingSystems()
    );
  }
}
