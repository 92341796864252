import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'myI18nSelect'
})
export class MyI18nSelectPipe implements PipeTransform {

  transform(value: string, mapping: { [key: string]: string }): unknown {
    return mapping[value] ? mapping[value] : ('!' + value);
  }

}
