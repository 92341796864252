import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FooterComponent, NavbarComponent, SmartBarComponent} from './containers';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import * as fromModuleComponents from './components';
import {NotificationsModule} from '../notifications/notifications.module';
import {AuthModule} from '../auth/auth.module';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    SmartBarComponent,
    fromModuleComponents.LogoutButtonComponent,
    fromModuleComponents.UserMenuComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    NotificationsModule,
    AuthModule,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SmartBarComponent,
    fromModuleComponents.LogoutButtonComponent,
    fromModuleComponents.UserMenuComponent,
  ]
})
export class NavigationModule {
}
