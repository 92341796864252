<div class="device-details-form__outer px-2 pt-3">
  <div class="device-details-form__wrapper">

    <div class="device-details-form">
      <ng-container [formGroup]="ddf">
        <!--<pre>{{lead$|async |json}}</pre>-->
        <div class="row">
          <div class="col-6">
            <div class="mat-form-field">

              <ng-select
                [clearable]="false"
                [items]="priorityModes$ | async"
                [loading]="priorityModesIsLoading$ | async"
                [required]="true"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="analysisPriorityMode"
                placeholder="Analyse-Modus*"
              ></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="analysisPriorityMode"></app-form-error>
              </mat-error>
            </div>
          </div>

          <div class="col-6">
            <div class="mat-form-field">
              <ng-select
                (change)="handleChangeShippingProvider($event)"
                [clearable]="false"
                [dropdownPosition]="'auto'"
                [items]="shippingProviders$ | async"
                [loading]="shippingProvidersIsLoading$ | async"
                bindLabel="name"
                bindValue="@id"
                class="ng-select-auto-width"
                formControlName="shippingProviderToDR"
                placeholder="Versand*"
                rxnotEmpty
              ></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="shippingProviderToDR"></app-form-error>
              </mat-error>
            </div>
          </div>

          <div class="col-6">
            <div class="mat-form-field">

              <ng-select
                [clearable]="false"
                [items]="storageSystems$ | async"
                [loading]="storageSystems$IsLoading | async"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="storageSystem"
                placeholder="Speichersystem*"
              ></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="storageSystem"></app-form-error>
              </mat-error>
            </div>
          </div>

          <div class="col-6">
            <div class="mat-form-field">

              <ng-select
                [clearable]="false"
                [items]="storageSystemManufacturers$ | async"
                [loading]="storageSystemManufacturersIsLoading$ | async"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="storageSystemManufacturer"
                placeholder="Speichersystem-Hersteller*"
              ></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="storageSystemManufacturer"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-12">

            <mat-form-field>
              <mat-label>Speichersystem-Modell</mat-label>
              <input formControlName="storageManufacturerModel" matInput type="text">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="storageManufacturerModel"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">

            <mat-form-field>
              <mat-label>Speichersystem-Seriennummer</mat-label>
              <input formControlName="storageManufacturerSerialNumber" matInput type="text">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="storageManufacturerSerialNumber"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>Datum Datenverlust</mat-label>
              <input formControlName="dataLossAt" matInput required type="text">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="dataLossAt"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>Datenverlust Feststellung</mat-label>
              <input formControlName="dataLossDetectedAt" matInput required type="text">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="dataLossDetectedAt"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>Anzahl Datenträger</mat-label>
              <input formControlName="numberOfDataMedia" matInput max="20" min="1" type="number">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="numberOfDataMedia"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>Größe in GB</mat-label>
              <input formControlName="sizeInGB" matInput max="50000" min="0" required type="number">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="sizeInGB"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>PIN/Passwort/Entsperrmuster</mat-label>
              <input formControlName="pinPassword" matInput>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="pinPassword"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <mat-label>Host</mat-label>
              <input formControlName="host" matInput type="text">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="host"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <ng-select
                [clearable]="false"
                [items]="fileSystems$ | async"
                [loading]="fileSystemsIsLoading$ | async"
                [searchable]="false"
                bindLabel="name"
                bindValue="@id"
                formControlName="fileSystem"
                placeholder="Dateisystem*"
              ></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="fileSystem"></app-form-error>
              </mat-error>
            </div>
          </div>

          <div class="col-6">
            <div class="mat-form-field">
              <ng-select [clearable]="false"
                         [items]="operatingSystems$ | async"
                         [searchable]="false"
                         bindLabel="name"
                         bindValue="@id"
                         formControlName="operatingSystem"
                         placeholder="Betriebssystem*"></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="operatingSystem"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-12">

            <mat-form-field>
              <mat-label>Anzahl Partitionen</mat-label>
              <input formControlName="numberOfPartitions" matInput max="10" min="1" type="number">
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="numberOfPartitions"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mat-form-field col-12">
            <ng-select
              [clearable]="false"
              [items]="symptoms$ | async"
              bindLabel="name"
              bindValue="@id"
              formControlName="symptom"
              placeholder="Symptom*"
              required></ng-select>
            <mat-error>
              <app-form-error [formGroup]="ddf" fieldName="symptom"></app-form-error>
            </mat-error>
          </div>

          <div class=" col-12">
            <div class="mat-form-field ">
              <ng-select
                [clearable]="false"
                [items]="damageTypes$ | async"
                [loading]="damageTypesIsLoading$ | async"
                bindLabel="name"
                bindValue="@id"
                formControlName="damage"
                placeholder="Schaden*"
                required></ng-select>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="damage"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-12">
            <mat-form-field>
              <mat-label>Historie</mat-label>
              <textarea cdkTextareaAutosize formControlName="history" matInput rows="4"></textarea>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="history"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">

            <mat-form-field>
              <mat-label>Bereits erfolgte Schritte</mat-label>
              <textarea cdkTextareaAutosize formControlName="stepsAlreadyTaken" matInput rows="4"></textarea>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="stepsAlreadyTaken"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12">

            <mat-form-field>
              <mat-label>Benötigte Daten</mat-label>
              <textarea cdkTextareaAutosize formControlName="requiredData" matInput rows="4"></textarea>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="requiredData"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <mat-label>Verschlüsselte Daten</mat-label>
              <mat-button-toggle-group aria-label="Verschlüsselte Daten"
                                       class="block small"
                                       formControlName="encryptedData">
                <mat-button-toggle (click)="toggleBtnClicked('encryptedData',true)" [value]="true">Ja
                </mat-button-toggle>
                <mat-button-toggle (click)="toggleBtnClicked('encryptedData',false)" [value]="false">Nein
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="encryptedData"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <mat-label>Virtuelle Verschlüsselung</mat-label>
              <mat-button-toggle-group aria-label="Virtuelle Verschlüsselung"
                                       class="block small"
                                       formControlName="virtualEncryption">
                <mat-button-toggle (click)="toggleBtnClicked('virtualEncryption',true)" [value]="true">Ja
                </mat-button-toggle>
                <mat-button-toggle (click)="toggleBtnClicked('virtualEncryption',false)" [value]="false">Nein
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="virtualEncryption"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <mat-label>Datenbanken</mat-label>
              <mat-button-toggle-group aria-label="Datenbanken"
                                       class="block small"
                                       formControlName="databases">
                <mat-button-toggle (click)="toggleBtnClicked('databases',true)" [value]="true">
                  Ja
                </mat-button-toggle>
                <mat-button-toggle (click)="toggleBtnClicked('databases',false)" [value]="false">
                  Nein
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="databases"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <mat-label>Virtuelle Systeme</mat-label>
              <mat-button-toggle-group aria-label="Virtuelle Systeme"
                                       class="block small"
                                       formControlName="virtualSystems">
                <mat-button-toggle (click)="toggleBtnClicked('virtualSystems',true)" [value]="true">Ja
                </mat-button-toggle>
                <mat-button-toggle (click)="toggleBtnClicked('virtualSystems',false)" [value]="false">Nein
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="virtualSystems"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-12">
            <mat-form-field>
              <mat-label>Spezifische Informationen</mat-label>
              <textarea cdkTextareaAutosize formControlName="specificInformation" matInput rows="4"></textarea>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="specificInformation"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="mat-form-field">
              <mat-label>Priorität</mat-label>
              <mat-button-toggle-group aria-label="Priorität"
                                       class="block small"
                                       formControlName="priority">
                <mat-button-toggle value="Niedrig">Niedrig</mat-button-toggle>
                <mat-button-toggle value="Mittel">Mittel</mat-button-toggle>
                <mat-button-toggle value="Hoch">Hoch</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error [formGroup]="ddf" fieldName="priority"></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="mat-form-field">
            <ng-select
              #discountInput
              [clearable]="true"
              [items]="discounts$ | async"
              [loading]="discountsIsLoading$ | async"
              [markFirst]="false"
              [searchable]="false"
              bindLabel="name"
              bindValue="@id"
              formControlName="discount"
              placeholder="Rabatt"
            >
              <ng-template let-item="item" ng-label-tmp>
                {{ item.name }} ({{ item.value | percentage }} %)
              </ng-template>

              <ng-template let-item="item" ng-option-tmp>
                {{ item.name }} ({{ item.value | percentage }} %)
              </ng-template>
            </ng-select>
            <mat-error>
              <app-form-error
                [formGroup]="ddf"
                fieldName="discount"
              ></app-form-error>
            </mat-error>
          </div>
          <div class="mat-form-field">
            <!--<pre>{{ discountInput.selectedValues[0] | json}}</pre>-->
            <mat-form-field>
              <mat-placeholder>Sonderrabatt in %</mat-placeholder>
              <button
                (click)="
                        ddf.get('specialDiscount').setValue(null);
                        sdi.blur()
                      "
                aria-label="Clear"
                mat-icon-button
                matSuffix
              >
                <mat-icon>close</mat-icon>
              </button>
              <input
                #sdi
                [options]="{ prefix: '', suffix: '%' }"
                currencyMask
                formControlName="specialDiscount"
                matInput
                type="text"
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'specialDiscount'"
                  [formGroup]="ddf"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-12"> Analyse Preis</div>
          </div>
          <div class="row">

            <div [formGroupName]="'priceAnalysisNet'" class="col-4">
              <mat-form-field>
                <mat-placeholder>Netto</mat-placeholder>
                <input (keyup)="handleUpdateGrossValue()" [options]="{ prefix: '', suffix: ' €' }"
                       currencyMask
                       formControlName="value"
                       matInput
                       required
                       type="text">
                <mat-error>
                  <app-form-error [fieldName]="'priceAnalysisNet.value'"
                                  [formGroup]="ddf"></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div [formGroupName]="'priceAnalysisGross'" class="col-4">
              <mat-form-field>
                <mat-placeholder>Brutto</mat-placeholder>
                <input (keyup)="handleUpdateNetValue()" [options]="{ prefix: '', suffix: ' €' }"
                       currencyMask
                       formControlName="value"
                       matInput
                       required
                       type="text">
                <mat-error>
                  <app-form-error [fieldName]="'priceAnalysisGross.value'"
                                  [formGroup]="ddf"></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>


            <div class=" col-4">
              <div class="mat-form-field ">
                <ng-select
                  (change)="handleUpdateGrossValue()"
                  [clearable]="false"
                  [formControlName]="'taxAnalysis'"
                  [items]="taxes$|async"
                  [loading]="taxesIsLoading$|async"
                  [searchable]="false"
                  bindLabel="name"
                  bindValue="@id"
                  placeholder="USt."
                ></ng-select>
                <mat-error>
                  <app-form-error [fieldName]="'taxAnalysis.value'" [formGroup]="ddf"></app-form-error>
                </mat-error>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

    </div>
  </div>
</div>

<!--<pre>Valid: {{ ddf.valid | json }}</pre>-->
<!--<pre>Invalid: {{ ddf.invalid | json }}</pre>-->
