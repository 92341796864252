import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {CustomersActions} from '../actions/';
import {CustomersService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {RouterActions} from '../../../application-state/store/actions';

@Injectable()
export class CustomersEffects {

  constructor(private actions$: Actions, private service: CustomersService) {
  }

  CreateCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.CreateCustomer),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createCustomer(payload).pipe(
        map((response) => CustomersActions.CreateCustomerSuccess({response})),
        catchError(error => of(CustomersActions.CreateCustomerFail(error)))
      );
    })
  ));

  ReadCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.ReadCustomer),
    mergeMap(({iri}) => {
      return this.service.readCustomer(iri).pipe(
        map((response => CustomersActions.ReadCustomerSuccess({response}))),
        catchError(response => of(CustomersActions.ReadCustomerFail({response})))
      );
    })
  ));

  ReadPartner$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.ReadPartner),
    switchMap(({iri}) => {
      return this.service.readCustomer(iri).pipe(
        map((response => CustomersActions.ReadPartnerSuccess({response}))),
        catchError(response => of(CustomersActions.ReadPartnerFail({response})))
      );
    })
  ));

  ReadCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.ReadCustomers),
    map(action => action),
    switchMap(({page, params}) => {
      return this.service.readCustomers(page, params).pipe(
        map((response) => CustomersActions.ReadCustomersSuccess({
          response: {
            ...response,
            hasQueryParam: params?.hasOwnProperty('fulltext_search')
          }
        })),
        catchError(response => of(CustomersActions.ReadCustomersFail({response})))
      );
    })
  ));

  ReadCustomerChunk$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.ReadCustomerChunk),
    map(action => action),
    switchMap(({uri}) => {
      return this.service.readCustomerChunk(uri).pipe(
        map((response) => CustomersActions.ReadCustomerChunkSuccess({response})),
        catchError(response => of(CustomersActions.ReadCustomerChunkFail({response})))
      );
    })
  ));

  UpdateCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.UpdateCustomer),
    map(action => action),
    switchMap(({iri, payload}) => {

      return this.service.updateCustomer(iri, payload).pipe(
        map((response: fromModuleModels.Customer) => CustomersActions.UpdateCustomerSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CustomersActions.UpdateCustomerFail({response})))
      );
    })
  ));

  SetDefaultCustomerAddress$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.SetDefaultCustomerAddress),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updateCustomer(iri, payload).pipe(
        map((response: fromModuleModels.Customer) => CustomersActions.SetDefaultCustomerAddressSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CustomersActions.SetDefaultCustomerAddressFail({response})))
      );
    })
  ));

  SetDefaultCustomerContact$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.SetDefaultCustomerContact),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updateCustomer(iri, payload).pipe(
        map((response: fromModuleModels.Customer) => CustomersActions.SetDefaultCustomerContactSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CustomersActions.SetDefaultCustomerContactFail({response})))
      );
    })
  ));

  DeleteCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.DeleteCustomer),
    map(action => action),
    switchMap(({iri}) => {

      return this.service.deleteCustomer(iri).pipe(
        map(() => CustomersActions.DeleteCustomerSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(CustomersActions.DeleteCustomerFail({response})))
      );
    })
  ));

  CustomerActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomersActions.CreateCustomerSuccess,
      CustomersActions.UpdateCustomerSuccess,
    ),
    map(({type}) => {

      const text = type === CustomersActions.CREATE_CUSTOMER_SUCCESS
        ? `Ein neuer Kunde wurde angelegt.`
        : 'Die Daten des Kunden wurden aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));

  DeleteCustomerSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CustomersActions.DeleteCustomerSuccess),
    map(() => RouterActions.Go({path: ['customers']}))
  ));
}
