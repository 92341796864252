import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_DATA_MEDIUMS = '[Orders Module] Read Order Data Mediums';
export const READ_ORDER_DATA_MEDIUMS_SUCCESS = '[Orders Module] Read Order Data Mediums Success';
export const READ_ORDER_DATA_MEDIUMS_FAIL = '[Orders Module] Read Order Data Mediums Fail';

export const ReadOrderDataMediums = createAction(
  READ_ORDER_DATA_MEDIUMS,
  props<{ page: number, params?: { [p: string]: number | string | boolean | string[] } }>()
);

export const ReadOrderDataMediumsSuccess = createAction(
  READ_ORDER_DATA_MEDIUMS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadOrderDataMediumsFail = createAction(
  READ_ORDER_DATA_MEDIUMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Misc

export const RESET_ORDER_MEDIUMS_STATE = '[Orders Module] Reset Order Mediums State';

export const ResetOrderMediumsState = createAction(
  RESET_ORDER_MEDIUMS_STATE,
);


