import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Order} from "../../../orders/models";
import {ActionBoxInputTypes} from "../../models/action-box-input-types.type";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PotentialAction} from "../../models";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {AuthService} from "../../../auth/services/auth.service";
import {Actions, ofType} from "@ngrx/effects";
import {filter, takeUntil} from "rxjs/operators";
import {DataRecoveryCostsActions} from "../../../orders/store";
import {DataRecoveryCostsSelectors} from "../../../orders/store/selectors";
import {BaseOnDestroyComponent} from "../../injectables/BaseOnDestroy.component";
import {TransitionsActions} from "../../../application-state/store/actions";

@Component({
  selector: 'app-action-box-to-data-recovery-purchase-price-checked',
  templateUrl: './action-box-to-data-recovery-purchase-price-checked.component.html',
  styleUrls: ['./action-box-to-data-recovery-purchase-price-checked.component.scss']
})
export class ActionBoxToDataRecoveryPurchasePriceCheckedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
@Input() order$: Observable<Order>;
@Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  form: FormGroup;

protected readonly parseFloat = parseFloat;
private costs: any;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isAccounting() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  get purchasePriceDataRecoveryDifference(): number {
    const calculatedPrice = this.form?.get(
      'calculatedPurchasePriceDataRecovery.value'
    )?.value;
    const userPrice = this.form?.get('purchasePriceDataRecovery.value')?.value;
    if (!calculatedPrice || !userPrice) {
      return Number.POSITIVE_INFINITY;
    }
    return this.calculatePriceDifference(calculatedPrice, userPrice);
  }

  get purchasePriceDataRecoveryDifferenceString(): string {
    const difference = this.purchasePriceDataRecoveryDifference;
    if (!Number.isFinite(difference)) {
      return '-';
    } else if (difference === 0.0) {
      return '0 %';
    } else if (difference > 0.0) {
      return '+ ' + Math.round(difference * 1000) / 10 + ' %';
    } else {
      return Math.round(difference * 1000) / 10 + ' %';
    }
  }

  get purchasePriceOtherDifference(): number {
    const calculatedPrice = this.form?.get('calculatedPurchasePriceOther.value')
      ?.value;
    const userPrice = this.form?.get('purchasePriceOther.value')?.value;
    if (!calculatedPrice || !userPrice) {
      return 0.0;
    }
    return this.calculatePriceDifference(calculatedPrice, userPrice);
  }

  get purchasePriceOtherDifferenceString(): string {
    const difference = this.purchasePriceOtherDifference;
    if (!Number.isFinite(difference)) {
      return '-';
    } else if (difference === 0.0) {
      return '0 %';
    } else if (difference > 0.0) {
      return '+ ' + Math.round(difference * 1000) / 10 + ' %';
    } else {
      return Math.round(difference * 1000) / 10 + ' %';
    }
  }

  get purchasePriceInitialDifference(): number {
    const calculatedPrice = this.form?.get('calculatedPurchasePriceInitial.value')
      ?.value;
    const userPrice = this.form?.get('purchasePriceInitial.value')?.value;
    if (!calculatedPrice || !userPrice) {
      return 0.0;
    }
    return this.calculatePriceDifference(calculatedPrice, userPrice);
  }

  get purchasePriceInitialDifferenceString(): string {
    const difference = this.purchasePriceInitialDifference;
    if (!Number.isFinite(difference)) {
      return '-';
    } else if (difference === 0.0) {
      return '0 %';
    } else if (difference > 0.0) {
      return '+ ' + Math.round(difference * 1000) / 10 + ' %';
    } else {
      return Math.round(difference * 1000) / 10 + ' %';
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (order.dataRecoveryCosts && order.dataRecoveryCosts.length > 0) {
          const lastDataRecoveryCosts =
            order.dataRecoveryCosts[order.dataRecoveryCosts.length - 1];
          this.store$.dispatch(
            DataRecoveryCostsActions.ReadDataRecoveryCosts({
              iri: lastDataRecoveryCosts
            })
          );
          this.store$
            .select(DataRecoveryCostsSelectors.sByIri, {
              iri: lastDataRecoveryCosts
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(DRCosts => !!DRCosts)
            )
            .subscribe(costs => {
              this.costs = costs;
              this.form.patchValue(costs);
            });
        }
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('to_data_recovery_purchase_prices_checked') >
            -1 && !e.error
        );
      });
  }

  calculatePriceDifference(calculatedPrice: string, userPrice: string): number {
    const fUserPrice = parseFloat(userPrice);
    const fCalculatedPrice = parseFloat(calculatedPrice);
    if (fUserPrice === fCalculatedPrice) {
      return 0.0;
    } else if (fUserPrice > fCalculatedPrice) {
      return (fUserPrice - fCalculatedPrice) / fCalculatedPrice;
    } else {
      return (fUserPrice - fCalculatedPrice) / fCalculatedPrice;
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      purchasePriceDataRecovery: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      purchasePriceInitial: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      calculatedPurchasePriceInitial: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      purchasePriceOther: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      calculatedPurchasePriceDataRecovery: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      calculatedPurchasePriceOther: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      purchasePriceComment: this.fb.control('')
    });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const lastDRCostsIri = this.order.dataRecoveryCosts[
    this.order.dataRecoveryCosts.length - 1
      ];
    const values = this.form.getRawValue();
    const payload: any = { ['@id']: lastDRCostsIri, ...values };
  payload.purchasePriceDataRecovery.value =
    payload.purchasePriceDataRecovery.value.toString() || '0.0';
  payload.purchasePriceOther.value =
    payload.purchasePriceOther.value.toString() || '0.0';
    payload.purchasePriceInitial.value =
      payload.purchasePriceInitial.value.toString() || '0.0';
  this.store$.dispatch(
    DataRecoveryCostsActions.UpdatePurchasePrices({
      iri: lastDRCostsIri,
      payload
    })
  );
  this.actions$
    .pipe(
      ofType(DataRecoveryCostsActions.UpdatePurchasePricesSuccess),
      takeUntil(this.onDestroy$)
    )
    .subscribe(() => {
      this.store$.dispatch(
        DataRecoveryCostsActions.UpdateCheckedBySupervisor({
          iri: lastDRCostsIri,
          payload: { checkedBySupervisor: true }
        })
      );
      this.makeTransition();
    });
}
}
