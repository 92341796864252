import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCE = '[Master Data] Read Replacement Data Medium Source';
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCE_SUCCESS = '[Master Data] Read Replacement Data Medium Source Success';
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCE_FAIL = '[Master Data] Read Replacement Data Medium Source Fail';
export const ReadReplacementDataMediumSource = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCE,
  props<{ iri: string }>()
);
export const ReadReplacementDataMediumSourceSuccess = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCE_SUCCESS,
  props<{ response: fromModuleModels.ReplacementDataMediumSource }>()
);
export const ReadReplacementDataMediumSourceFail = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCES = '[Master Data] Read Replacement Data Medium Sources';
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCES_SUCCESS = '[Master Data] Read Replacement Data Medium Sources Success';
export const READ_REPLACEMENT_DATA_MEDIUM_SOURCES_FAIL = '[Master Data] Read Replacement Data Medium Sources Fail';
export const ReadReplacementDataMediumSources = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCES
);
export const ReadReplacementDataMediumSourcesSuccess = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCES_SUCCESS,
  props<{ response: any }>()
);
export const ReadReplacementDataMediumSourcesFail = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SOURCES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
