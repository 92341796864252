import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as fromMasterDataModuleModels from '../../models';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'damage-form',
  styleUrls: ['damage-form.component.scss'],
  template: `
    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Schadensart bearbeiten' : 'Neue Schadensart anlegen' }}</span>
      </div>

      <div class="card__content p-a--24">

        <div class="grid" [formGroup]="sf">
          <mat-form-field class="column-11">
            <mat-label>Bezeichnung</mat-label>
            <input type="text" formControlName="name" matInput required>
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>

          <div class="column-3">
            <mat-slide-toggle formControlName="isActive" [checked]="true">Aktiv</mat-slide-toggle>
          </div>

          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || sf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="sf.invalid || sf.untouched"
                    (click)="handleSubmit()"
                    mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DamageFormComponent implements OnInit, AfterViewInit {

  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromMasterDataModuleModels.Damage>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromMasterDataModuleModels.Damage,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromMasterDataModuleModels.Damage,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  sf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(presets => {
      this.initForm();
      this.fs.patchForm(this.sf, presets);
      this.fs.resetFormErrors(this.sf);
    });
  }

  initForm() {
    this.sf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      isActive: this.fb.control(false, [Validators.required])
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromMasterDataModuleModels.Damage = this.sf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Damage'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Damage'});
  }
}
