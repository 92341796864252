import {createSelector} from '@ngrx/store';

import {CUSTOMER_ADDRESSES_FEATURE_KEY, selectCustomersModuleState} from '../reducers';
import {getRouterState, RouterStateUrl} from '../../../application-state/store';

export const selectCustomerAddressesState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_ADDRESSES_FEATURE_KEY]
);

export const selectCustomerAddressesEntities = createSelector(
  selectCustomerAddressesState,
  (state) => state.entities
);

export const selectCustomerAddresses = createSelector(
  selectCustomerAddressesEntities,
  (addresses) => Object.keys(addresses).map(iri => addresses[iri])
);

export const selectCustomerAddressesByCustomerIri = createSelector(
  selectCustomerAddresses,
  (addresses, {customerIri}) => addresses.filter(address => address.customer === customerIri)
);

export const selectCustomerBillingAddressesByCustomerIri = createSelector(
  selectCustomerAddressesByCustomerIri,
  (addresses, {customerIri}) => addresses.filter(address => (address.customer === customerIri) && (address['@type'] === 'BillingAddress'))
);

export const selectCustomerDeliveryAddressesByCustomerIri = createSelector(
  selectCustomerAddressesByCustomerIri,
  (addresses, {customerIri}) => addresses.filter(address => (address.customer === customerIri) && (address['@type'] === 'DeliveryAddress'))
);

export const selectCustomersPartnerBranchOfficeByCustomerIri = createSelector(
  selectCustomerAddressesByCustomerIri,
  (addresses, {customerIri}) => addresses.filter(address => (address.customer === customerIri) && (address['@type'] === 'PartnerBranchOfficeAddress'))
);

export const selectCurrentCustomerAddress = createSelector(
  selectCustomerAddressesState,
  state => state.current
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectCustomerAddressesTotalItems = createSelector(
  selectCustomerAddressesState,
  (state) => state.totalItems
);

export const selectCustomerAddressesPagination = createSelector(
  selectCustomerAddressesState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectCustomerAddressesPagination,
  pagination => pagination?.current
);

export const selectPaginationFirstLink = createSelector(
  selectCustomerAddressesPagination,
  pagination => pagination?.first
);

export const selectPaginationPreviousLink = createSelector(
  selectCustomerAddressesPagination,
  pagination => pagination?.previous
);

export const selectPaginationNextLink = createSelector(
  selectCustomerAddressesPagination,
  pagination => pagination?.next
);

export const selectPaginationLastLink = createSelector(
  selectCustomerAddressesPagination,
  pagination => pagination?.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCustomerAddressesState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCustomerAddressesState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectCustomerAddressesErrors = createSelector(
  selectCustomerAddressesState,
  state => state.errors
);
