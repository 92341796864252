import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ANALYSIS_RESULT = '[Master Data Module] Create Analysis Result';
export const CREATE_ANALYSIS_RESULT_SUCCESS = '[Master Data Module] Create Analysis Result Success';
export const CREATE_ANALYSIS_RESULT_FAIL = '[Master Data Module] Create Analysis Result Fail';

export const CreateAnalysisResult = createAction(
  CREATE_ANALYSIS_RESULT,
  props<{ payload: fromModuleModels.AnalysisResult }>()
);

export const CreateAnalysisResultSuccess = createAction(
  CREATE_ANALYSIS_RESULT_SUCCESS,
  props<{ response: any }>()
);

export const CreateAnalysisResultFail = createAction(
  CREATE_ANALYSIS_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ANALYSIS_RESULT = '[Master Data Module] Read Analysis Result';
export const READ_ANALYSIS_RESULT_SUCCESS = '[Master Data Module] Read Analysis Result Success';
export const READ_ANALYSIS_RESULT_FAIL = '[Master Data Module] Read Analysis Result Fail';

export const ReadAnalysisResult = createAction(
  READ_ANALYSIS_RESULT,
  props<{ iri: string }>()
);

export const ReadAnalysisResultSuccess = createAction(
  READ_ANALYSIS_RESULT_SUCCESS,
  props<{ response: any }>()
);

export const ReadAnalysisResultFail = createAction(
  READ_ANALYSIS_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ANALYSIS_RESULTS = '[Master Data Module] Read Analysis Results';
export const READ_ANALYSIS_RESULTS_SUCCESS = '[Master Data Module] Read Analysis Results Success';
export const READ_ANALYSIS_RESULTS_FAIL = '[Master Data Module] Read Analysis Results Fail';

export const ReadAnalysisResults = createAction(
  READ_ANALYSIS_RESULTS,
  (payload: { page?: number, params?: { [p: string]: any } } = {}) => ({...payload})
);

export const ReadAnalysisResultsSuccess = createAction(
  READ_ANALYSIS_RESULTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadAnalysisResultsFail = createAction(
  READ_ANALYSIS_RESULTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read Chunk


export const READ_ANALYSIS_RESULTS_CHUNK = '[Master Data] Read Analysis Results Chunk';
export const READ_ANALYSIS_RESULTS_CHUNK_SUCCESS = '[Master Data] Read Analysis Results Chunk Success';
export const READ_ANALYSIS_RESULTS_CHUNK_FAIL = '[Master Data] Read Analysis Results Chunk Fail';

export const ReadAnalysisResultsChunk = createAction(
  READ_ANALYSIS_RESULTS_CHUNK,
  props<{ uri: string }>()
);

export const ReadAnalysisResultsChunkSuccess = createAction(
  READ_ANALYSIS_RESULTS_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadAnalysisResultsChunkFail = createAction(
  READ_ANALYSIS_RESULTS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ANALYSIS_RESULT = '[Master Data Module] Update Analysis Result';
export const UPDATE_ANALYSIS_RESULT_SUCCESS = '[Master Data Module] Update Analysis Result Success';
export const UPDATE_ANALYSIS_RESULT_FAIL = '[Master Data Module] Update Analysis Result Fail';

export const UpdateAnalysisResult = createAction(
  UPDATE_ANALYSIS_RESULT,
  props<{ iri: string, payload: fromModuleModels.AnalysisResult }>()
);

export const UpdateAnalysisResultSuccess = createAction(
  UPDATE_ANALYSIS_RESULT_SUCCESS,
  props<{ response: fromModuleModels.AnalysisResult }>()
);

export const UpdateAnalysisResultFail = createAction(
  UPDATE_ANALYSIS_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ANALYSIS_RESULT = '[Master Data Module] Delete Analysis Result';
export const DELETE_ANALYSIS_RESULT_SUCCESS = '[Master Data Module] Delete Analysis Result Success';
export const DELETE_ANALYSIS_RESULT_FAIL = '[Master Data Module] Delete Analysis Result Fail';

export const DeleteAnalysisResult = createAction(
  DELETE_ANALYSIS_RESULT,
  props<{ iri: string }>()
);

export const DeleteAnalysisResultSuccess = createAction(
  DELETE_ANALYSIS_RESULT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteAnalysisResultFail = createAction(
  DELETE_ANALYSIS_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
