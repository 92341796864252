import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import * as moment from 'moment/moment';
import { DynamicFormComponent } from '../../../shared/containers/dynamic-form/dynamic-form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import { AuthService } from '../../../auth/services/auth.service';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-sales-filter-form',
  styleUrls: ['sales-filter-form.component.scss'],
  template: `
    <div class="row" *ngIf="searchBoxVisible$ | async" [formGroup]="form">
      <div class="col-8">
        <div class="row">
          <div
            class="col-2"
            *ngIf="authService.isAdmin() || authService.isSupervisor()"
          >
            <app-salesperson-select
              fieldName="salesperson"
              [formGroup]="form"
            ></app-salesperson-select>
          </div>
          <div
            class="col-2"
            *ngIf="!(authService.isAdmin() || authService.isSupervisor())"
          >
            <div class="mat-form-field">
              <mat-label>Verkäufer</mat-label>
              <mat-button-toggle-group
                formControlName="salesperson"
                aria-label="Verkäufer"
                class="block small"
              >
                <mat-button-toggle [value]="null">Alle</mat-button-toggle>
                <mat-button-toggle [value]="selfUserId$ | async"
                  >Nur meine</mat-button-toggle
                >
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error
                  fieldName="salesperson"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-4">
            <app-order-staterange-select
              formControlName="stateRange"
            ></app-order-staterange-select>
          </div>
          <div class="col-2">
            <app-order-priority-mode-select
              formControlName="dataRecoveryPriorityMode"
            ></app-order-priority-mode-select>
          </div>
          <div class="col-2">
            <app-order-analysis-location-select
              formControlName="analysisLocation"
            ></app-order-analysis-location-select>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <app-order-data-recovery-location-select
              formControlName="dataRecoveryLocation"
            ></app-order-data-recovery-location-select>
          </div>
          <div class="col-2">
            <app-order-partner-status-select
              formControlName="partnerStatus"
            ></app-order-partner-status-select>
          </div>
          <div class="col-2">
            <app-customer-cooperation-select
              formControlName="customerCooperation"
            ></app-customer-cooperation-select>
          </div>
          <div class="col-2">
            <div class="mat-form-field">
              <ng-select
                [items]="brokerOptions"
                bindLabel="name"
                [searchable]="false"
                bindValue="value"
                [clearable]="false"
                placeholder="Wurde Vermittelt?"
                formControlName="broker"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{'shared.price_min_input.label' | translate}}</mat-label>
              <input
                type="string"
                currencyMask
                matInput
                formControlName="priceMin"
                [options]="{ prefix: '', suffix: ' €', allowNegative: true }"
              />
              <mat-error>
                <app-form-error
                  fieldName="priceMin"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{'shared.price_max_input.label' | translate}}</mat-label>
              <input
                type="string"
                currencyMask
                matInput
                formControlName="priceMax"
                [options]="{ prefix: '', suffix: ' €', allowNegative: true }"
              />
              <mat-error>
                <app-form-error
                  fieldName="priceMax"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{"shared.order_created_date_from_input.label" | translate}}</mat-label>
              <input type="date" matInput formControlName="createdAtMin" />
              <mat-error>
                <app-form-error
                  fieldName="createdAtMin"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{"shared.order_created_date_to_input.label" | translate}}</mat-label>
              <input type="date" matInput formControlName="createdAtMax" />
              <mat-error>
                <app-form-error
                  fieldName="createdAtMax"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-12">-->
    <!--      <dynamic-form-->
    <!--        [config]="formConfig"-->
    <!--        (requestAction)="handleRequestHandleAction($event)"-->
    <!--      ></dynamic-form>-->
    <!--    </div>-->
  `
})
export class SalesFilterFormComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicFormComponent) dForm: DynamicFormComponent;

  @Input() searchBoxVisible$: Observable<boolean>;

  @Output() requestHandleAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateFilter: EventEmitter<{
    [key: string]: string;
  }> = new EventEmitter<{ [key: string]: string }>();

  brokerOptions = [
    {
      name: 'Alle',
      value: 'null'
    },
    {
      name: 'Ja',
      value: true
    },
    {
      name: 'Nein',
      value: false
    }
  ];
  storedFilters?: any;
  onDestroy$: Subject<any> = new Subject<any>();
  selfUserId$: Observable<string>;

  form: FormGroup;

  constructor(
    public authService: AuthService,
    public store$: Store<ApplicationState>,
    public localStorageService: LocalStorageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.selfUserId$ = this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id']),
        map(e => e['@id'])
      );
    // Initial emit from dynamic form component is empty
    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'sales');
    if(this.storedFilters) {
      this.updateFilter.emit(this.storedFilters);
      this.form.patchValue(this.storedFilters);
    }
    this.form.valueChanges.subscribe(value => {
      if (!!value?.createdAtMin) {
        value.createdAtMin = moment(
          value.createdAtMin as string,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');
      }

      if (!!value?.createdAtMax) {
        value.createdAtMax = moment(
          value.createdAtMax as string,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');
      }

      this.updateFilter.emit(removeEmptyFormElements(value));
      console.log(value);
      if (Object.keys(value).length) {
        this.localStorageService.overwriteObjectByKey('filters', 'sales', value);
        this.updateFilter.emit(removeEmptyFormElements(value));
      }
    });
    this.searchBoxVisible$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(visible => {
        if (!visible) {
          this.form.reset({ broker: 'null' });
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private initForm(): void {
    this.form = this.fb.group({
      broker: this.fb.control('null'),
      priceMin: this.fb.control(null),
      priceMax: this.fb.control(null),
      stateRange: this.fb.control(null),
      salesperson: this.fb.control(null),
      partnerStatus: this.fb.control(null),
      createdAtMin: this.fb.control(null),
      createdAtMax: this.fb.control(null),
      analysisLocation: this.fb.control(null),
      dataRecoveryLocation: this.fb.control(null),
      customerCooperation: this.fb.control(null),
      dataRecoveryPriorityMode: this.fb.control(null)
    });
  }
}
