import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PayableInvoice } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { RouterActions } from '../../../application-state/store/actions';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { payableInvoiceStateMapDE } from '../../helper/invoice-state-maps';

@Component({
  selector: 'app-payable-invoices-list',
  styleUrls: ['payable-invoices-list.component.scss'],
  template: `
    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >
      <!-- Dot Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef style="width: 20px;"></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          <!--<span class="badge--express" *ngIf="isVIP(element)">
                    <span>Express</span>
                  </span>-->
          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>

      <!-- Invoice Number -->
      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">RE.</th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="!element.invoiceNumber ? 'Entwurf' : ''"
          class="pos-relative"
        >
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          <span [class.orange]="!element.invoiceNumber">{{
            element.invoiceNumber ? element.invoiceNumber : 'E'
          }}</span>
        </td>
      </ng-container>

      <!-- Order Number -->
      <ng-container matColumnDef="orderNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Auftrag"
          style="width: 50px;"
        >
          A
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          {{
            element?.paymentProcess?.order
              ? element.paymentProcess.order.orderNumber
              : ''
          }}
        </td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Empfänger [Zusatz]</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          <span *ngIf="element?.isVip" class="badge--vip m-0 small">
            <span>VIP</span>
          </span>
          {{ element.recipient.nameLine1 }}

          <ng-container *ngIf="element.recipient.nameLine2">
            [{{ element.recipient.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>
      <!-- state Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Zustand</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>
          <mat-icon
            *ngIf="element.state === 'first_reminder'"
            class="text-color-orange inline-icon"
            >warning</mat-icon
          >
          <mat-icon
            *ngIf="element.state === 'second_reminder'"
            class="text-color-red inline-icon"
            >warning</mat-icon
          >
          <mat-icon
            *ngIf="element.state === 'debt_collection'"
            class="text-color-red inline-icon"
            >warning</mat-icon
          >
          {{ element.state | myI18nSelect: payableInvoiceStateMapDE }}
        </td>
      </ng-container>

      <!-- Issuer Column -->
      <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef>Bereich</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>
          {{ element.issuer.companyName }} ({{ element.issuer.name }})
        </td>
      </ng-container>

      <!-- Gross Total Column -->
      <ng-container matColumnDef="grossTotal">
        <th mat-header-cell *matHeaderCellDef style="width: 160px;">
          Gesamtsumme
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          {{ element.grossTotal.value | number: '1.2-2' }}
          {{ element.grossTotal.currency | currencyShortener }}
        </td>
      </ng-container>

      <!-- amountPaid Column -->
      <ng-container matColumnDef="amountPaid">
        <th mat-header-cell *matHeaderCellDef style="width: 160px;">
          Betrag Gezahlt
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          <ng-container *ngIf="element.amountPaid.value === '0.00'"
            >-
          </ng-container>
          <ng-container *ngIf="element.amountPaid.value !== '0.00'">
            <span class="text-color-green"
              >{{ element.amountPaid.value | number: '1.2-2' }}
              {{ element.amountPaid.currency | currencyShortener }}</span
            >
          </ng-container>
        </td>
      </ng-container>

      <!-- amountOutstanding Column -->
      <ng-container matColumnDef="amountOutstanding">
        <th mat-header-cell *matHeaderCellDef style="width: 160px;">
          Offener Betrag
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>

          <span matTooltip="Der Offenen Betrag ist negativ."
            ><mat-icon
              *ngIf="(element.amountOutstanding.value | float) < 0"
              class="text-color-red icon-inline"
              >report_problem
            </mat-icon></span
          >

          <span
            [class.text-color-red]="
              (element.amountOutstanding.value | float) > 0
            "
            [class.text-color-grey]="
              (element.amountOutstanding.value | float) === 0
            "
          >
            {{ element.amountOutstanding.value | number: '1.2-2' }}
            {{ element.amountOutstanding.currency | currencyShortener }}
          </span>
        </td>
      </ng-container>

      <!-- dueDate Column -->
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef>Fällig am</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="width: 120px;"
          class="pos-relative"
        >
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>
          <!--          <mat-icon *ngIf="element.overdue" class="text-color-red">warning</mat-icon>-->
          <span [class.text-color-red]="element.overdue">{{
            element.dueDate | momentDate
          }}</span>
        </td>
      </ng-container>

      <!-- orderDate Column -->
      <ng-container matColumnDef="orderDate">
        <th mat-header-cell *matHeaderCellDef style="width: 120px;">
          Erstellt am
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="[
              '/invoices',
              'payable',
              extractTypeByIri(element),
              extractId(element)
            ]"
          ></a>
          {{ element.orderDate | momentDate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        [class.canceled]="row.state === 'canceled'"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>

    <!--<pre>{{ items | json }}</pre>-->
  `
})
export class PayableInvoicesListComponent {
  @Input() items: Array<PayableInvoice>;
  @Input() totalItems: number;

  @Output()
  requestGoToPayableInvoiceView: EventEmitter<string> = new EventEmitter();

  @Output()
  requestReadInvoices: EventEmitter<'next' | 'previous'> = new EventEmitter<
    'next' | 'previous'
  >();

  displayedColumns: Array<string> = [
    'dot',
    'invoiceNumber',
    'orderNumber',
    'recipient',
    'state',
    'issuer',
    'grossTotal',
    'amountPaid',
    'amountOutstanding',
    'dueDate',
    'orderDate'
  ];

  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractIri = extractIri;
  protected readonly extractId = extractUUID;
  protected readonly payableInvoiceStateMapDE = payableInvoiceStateMapDE;

  constructor(private store$: Store<ApplicationState>) {}

  handleClick(invoice: PayableInvoice): void {
    // TODO use UUID not iri
    // this.store$.dispatch(RouterActions.Go({path: ['invoices', 'payable', getUuidFromIri(invoice['@id'])]}));
    this.store$.dispatch(
      RouterActions.Go({ path: ['invoices', 'payable', invoice['@id']] })
    );
  }

  isVIP(invoice: PayableInvoice): boolean {
    return invoice.isVip;
  }

  getCircleColorClass(element: PayableInvoice): string[] {
    if (element.overdue) {
      return ['red'];
    } else if (!element.invoiceNumber) {
      return ['grey'];
    } else {
      return ['green'];
    }
  }
}
