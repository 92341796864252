import {createSelector} from '@ngrx/store';

import {PAYMENT_PROCESSES_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectPaymentProcessesState = createSelector(
  selectInvoicesModuleState,
  (state) => state[PAYMENT_PROCESSES_FEATURE_KEY]
);

export const selectPaymentProcessesEntities = createSelector(
  selectPaymentProcessesState,
  (state) => state.entities
);

export const sByIri = createSelector(
  selectPaymentProcessesEntities,
  (entities, {iri}) => entities[iri]
);

export const selectPaymentProcesses = createSelector(
  selectPaymentProcessesEntities,
  (paymentProcess) => Object.keys(paymentProcess).map(iri => paymentProcess[iri])
);

export const selectCurrentPaymentProcess = createSelector(
  selectPaymentProcessesState,
  state => state.current
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectPaymentProcessesState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectPaymentProcessesState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectPaymentProcessesState,
  (state) => state.errors
);
