import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import { BindingOrderEmail, Order } from '../../models';
import { BindingOrderPDFResponse } from '../../models/binding-order-pdf-response.interface';
import { ProofOfDestructionPdfResponse } from '../../models/proof-of-destruction-pdf-response.interface';
import { DataRecoveryProtocolPdfResponse } from '../../models/data-recovery-protocol-pdf-response.interface';
import { OrdersMail } from '../../models/orders-mail.interface';

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
//
// export const CREATE_ORDER = '[Orders Module] Create Order';
// export const CREATE_ORDER_SUCCESS = '[Orders Module] Create Order Success';
// export const CREATE_ORDER_FAIL = '[Orders Module] Create Order Fail';
//
// export const CreateOrder = createAction(
//   CREATE_ORDER,
//   props<{ payload: any }>()
// );
//
// export const CreateOrderSuccess = createAction(
//   CREATE_ORDER_SUCCESS,
//   props<{ response: any }>()
// );
//
// export const CreateOrderFail = createAction(
//   CREATE_ORDER_FAIL,
//   props<{ response: HttpErrorResponse }>()
// );

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER = '[Orders Module] Read Order';
export const READ_ORDER_SUCCESS = '[Orders Module] Read Order Success';
export const READ_ORDER_FAIL = '[Orders Module] Read Order Fail';

export const ReadOrder = createAction(
  READ_ORDER,
  props<{ iri?: string; id?: string }>()
);

export const ReadOrderSuccess = createAction(
  READ_ORDER_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const ReadOrderFail = createAction(
  READ_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDERS = '[Orders Module] Read Orders';
export const READ_ORDERS_SUCCESS = '[Orders Module] Read Orders Success';
export const READ_ORDERS_FAIL = '[Orders Module] Read Orders Fail';

export const ReadOrders = createAction(
  READ_ORDERS,
  props<{
    page: number;
    params?: { [p: string]: string | number | boolean | string[] };
  }>()
);

export const ReadOrdersSuccess = createAction(
  READ_ORDERS_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrdersFail = createAction(
  READ_ORDERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_ORDER_CHUNK = '[Orders Module] Read Order Chunk';
export const READ_ORDER_CHUNK_SUCCESS =
  '[Orders Module] Read Order Chunk Success';
export const READ_ORDER_CHUNK_FAIL = '[Orders Module] Read Order Chunk Fail';

export const ReadOrderChunk = createAction(
  READ_ORDER_CHUNK,
  props<{ uri: string }>()
);

export const ReadOrderChunkSuccess = createAction(
  READ_ORDER_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderChunkFail = createAction(
  READ_ORDER_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_SALES = '[Orders Module] Read Sales';
export const READ_SALES_SUCCESS = '[Orders Module] Read Sales Success';
export const READ_SALES_FAIL = '[Orders Module] Read Sales Fail';

export const ReadSales = createAction(
  READ_SALES,
  props<{ page: number; params?: { [p: string]: boolean | number | string } }>()
);

export const ReadSalesSuccess = createAction(
  READ_SALES_SUCCESS,
  props<{ response: any }>()
);

export const ReadSalesFail = createAction(
  READ_SALES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_SALES_CHUNK = '[Orders Module] Read Sales Chunk';
export const READ_SALES_CHUNK_SUCCESS =
  '[Orders Module] Read Sales Chunk Success';
export const READ_SALES_CHUNK_FAIL = '[Orders Module] Read Sales Chunk Fail';

export const ReadSalesChunk = createAction(
  READ_SALES_CHUNK,
  props<{ uri: string }>()
);

export const ReadSalesChunkSuccess = createAction(
  READ_SALES_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadSalesChunkFail = createAction(
  READ_SALES_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_SALES_SUMMARY = '[Orders Module] Read Sales Summary';
export const READ_SALES_SUMMARY_SUCCESS =
  '[Orders Module] Read Sales Summary Success';
export const READ_SALES_SUMMARY_FAIL =
  '[Orders Module] Read Sales Summary Fail';

export const ReadSalesSummary = createAction(
  READ_SALES_SUMMARY,
  props<{ params?: { [p: string]: boolean | number | string } }>()
);

export const ReadSalesSummarySuccess = createAction(
  READ_SALES_SUMMARY_SUCCESS,
  props<{ response: any }>()
);

export const ReadSalesSummaryFail = createAction(
  READ_SALES_SUMMARY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ORDER = '[Orders Module] Update Order';
export const UPDATE_ORDER_SUCCESS = '[Orders Module] Update Order Success';
export const UPDATE_ORDER_FAIL = '[Orders Module] Update Order Fail';

export const UpdateOrder = createAction(
  UPDATE_ORDER,
  props<{ iri: string; payload: fromOrdersModuleModels.Order }>()
);

export const UpdateOrderSuccess = createAction(
  UPDATE_ORDER_SUCCESS,
  props<{ response: any }>()
);

export const UpdateOrderFail = createAction(
  UPDATE_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// SendMail
export const MAIL_ORDER = '[Orders Module] Mail Order';
export const MAIL_ORDER_SUCCESS = '[Orders Module] Mail Order Success';
export const MAIL_ORDER_FAIL = '[Orders Module] Mail Order Fail';

export const MailOrder = createAction(
  MAIL_ORDER,
  props<{ iri: string; payload: OrdersMail }>()
);

export const MailOrderSuccess = createAction(
  MAIL_ORDER_SUCCESS,
  props<{ response: Order }>()
);
export const MailOrderFail = createAction(
  MAIL_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////// Read binding-orders-preview-PDF

export const READ_BINDING_ORDER_PDF = '[Orders Module] Read Binding Order pdf';
export const READ_BINDING_ORDER_PDF_SUCCESS =
  '[Orders Module] Read Binding Order pdf Success';
export const READ_BINDING_ORDER_PDF_FAIL =
  '[Orders Module] Read Binding Order pdf Fail';

export const ReadBindingOrderPDF = createAction(
  READ_BINDING_ORDER_PDF,
  props<{ iri?: string }>()
);

export const ReadBindingOrderPDFSuccess = createAction(
  READ_BINDING_ORDER_PDF_SUCCESS,
  props<{ response: BindingOrderPDFResponse }>()
);

export const ReadBindingOrderPDFFail = createAction(
  READ_BINDING_ORDER_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////// Read proof-of-destruction-preview-PDF

export const READ_PROOF_OF_DESTRUCTION_PDF =
  '[Orders Module] Read Proof Of Destruction pdf';
export const READ_PROOF_OF_DESTRUCTION_PDF_SUCCESS =
  '[Orders Module] Read Proof Of Destruction pdf Success';
export const READ_PROOF_OF_DESTRUCTION_PDF_FAIL =
  '[Orders Module] Read Proof Of Destruction pdf Fail';

export const ReadProofOfDestructionPDF = createAction(
  READ_PROOF_OF_DESTRUCTION_PDF,
  props<{ iri?: string }>()
);

export const ReadProofOfDestructionPDFSuccess = createAction(
  READ_PROOF_OF_DESTRUCTION_PDF_SUCCESS,
  props<{ response: ProofOfDestructionPdfResponse }>()
);

export const ReadProofOfDestructionPDFFail = createAction(
  READ_PROOF_OF_DESTRUCTION_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Send proof-of-destruction Mail

export const SEND_PROOF_OF_DESTRUCTION_PDF =
  '[Orders Module] Send Proof Of Destruction pdf';
export const SEND_PROOF_OF_DESTRUCTION_PDF_SUCCESS =
  '[Orders Module] Send Proof Of Destruction pdf Success';
export const SEND_PROOF_OF_DESTRUCTION_PDF_FAIL =
  '[Orders Module] Send Proof Of Destruction pdf Fail';

export const SendProofOfDestructionPDF = createAction(
  SEND_PROOF_OF_DESTRUCTION_PDF,
  props<{ iri?: string; payload: OrdersMail }>()
);

export const SendProofOfDestructionPDFSuccess = createAction(
  SEND_PROOF_OF_DESTRUCTION_PDF_SUCCESS,
  props<{ response: Order }>()
);

export const SendProofOfDestructionPDFFail = createAction(
  SEND_PROOF_OF_DESTRUCTION_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Send proof-of-destruction Mail

export const SEND_DATA_RECOVERY_PROTOCOL =
  '[Orders Module] Send Data Recovery protocol pdf';
export const SEND_DATA_RECOVERY_PROTOCOL_SUCCESS =
  '[Orders Module] Send Data Recovery protocol pdf Success';
export const SEND_DATA_RECOVERY_PROTOCOL_FAIL =
  '[Orders Module] Send Data Recovery protocol pdf Fail';

export const SendDataRecoveryProtocol = createAction(
  SEND_DATA_RECOVERY_PROTOCOL,
  props<{ iri?: string; payload: OrdersMail }>()
);

export const SendDataRecoveryProtocolSuccess = createAction(
  SEND_DATA_RECOVERY_PROTOCOL_SUCCESS,
  props<{ response: Order }>()
);

export const SendDataRecoveryProtocolFail = createAction(
  SEND_DATA_RECOVERY_PROTOCOL_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read Data-recovery-protocol-preview-PDF

export const READ_DATA_RECOVERY_PROTOCOL_PDF =
  '[Orders Module] Read Data Recovery protocol pdf';
export const READ_DATA_RECOVERY_PROTOCOL_PDF_SUCCESS =
  '[Orders Module] Read Data Recovery protocol pdf Success';
export const READ_DATA_RECOVERY_PROTOCOL_PDF_FAIL =
  '[Orders Module] Read Data Recovery protocol pdf Fail';

export const ReadDataRecoveryProtocolPDF = createAction(
  READ_DATA_RECOVERY_PROTOCOL_PDF,
  props<{ iri?: string }>()
);

export const ReadDataRecoveryProtocolPDFSuccess = createAction(
  READ_DATA_RECOVERY_PROTOCOL_PDF_SUCCESS,
  props<{ response: DataRecoveryProtocolPdfResponse }>()
);

export const ReadDataRecoveryProtocolPDFFail = createAction(
  READ_DATA_RECOVERY_PROTOCOL_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////// Update Analysis Result

export const UPDATE_ORDER_ANALYSIS_RESULT =
  '[Orders Module] Update Order Analysis Result';
export const UPDATE_ORDER_ANALYSIS_RESULT_SUCCESS =
  '[Orders Module] Update Order Analysis Result Success';
export const UPDATE_ORDER_ANALYSIS_RESULT_FAIL =
  '[Orders Module] Update Order Analysis Result Fail';

export const UpdateOrderAnalysisResult = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT,
  props<{ iri: string; payload: { analysisResult: string } }>()
);

export const UpdateOrderAnalysisResultSuccess = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const UpdateOrderAnalysisResultFail = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX =
  '[Orders Module] Update Order Analysis Result From Action Box';
export const UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX_SUCCESS =
  '[Orders Module] Update Order Analysis Result From Action Box Success';
export const UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX_FAIL =
  '[Orders Module] Update Order Analysis Result From Action Box Fail';

export const UpdateOrderAnalysisResultFromActionBox = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX,
  props<{ iri: string; payload: { analysisResult: string } }>()
);

export const UpdateOrderAnalysisResultFromActionBoxSuccess = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const UpdateOrderAnalysisResultFromActionBoxFail = createAction(
  UPDATE_ORDER_ANALYSIS_RESULT_FROM_ACTION_BOX_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////// Update Analysis Location

export const UPDATE_ANALYSIS_LOCATION =
  '[Orders Module] Update Analysis Location';
export const UPDATE_ANALYSIS_LOCATION_SUCCESS =
  '[Orders Module] Update Analysis Location Success';
export const UPDATE_ANALYSIS_LOCATION_FAIL =
  '[Orders Module] Update Analysis Location Fail';

export const UpdateAnalysisLocation = createAction(
  UPDATE_ANALYSIS_LOCATION,
  props<{ iri: string; payload: { analysisLocation: string } }>()
);

export const UpdateAnalysisLocationSuccess = createAction(
  UPDATE_ANALYSIS_LOCATION_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const UpdateAnalysisLocationFail = createAction(
  UPDATE_ANALYSIS_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////// Update Binding Order

export const SEND_BINDING_ORDER = '[Orders Module] Send Binding Order';
export const SEND_BINDING_ORDER_SUCCESS =
  '[Orders Module] Send Binding Order Success';
export const SEND_BINDING_ORDER_FAIL =
  '[Orders Module] Send Binding Order Fail';

export const SendBindingOrder = createAction(
  SEND_BINDING_ORDER,
  props<{ iri: string; payload: BindingOrderEmail }>()
);

export const SendBindingOrderSuccess = createAction(
  SEND_BINDING_ORDER_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const SendBindingOrderFail = createAction(
  SEND_BINDING_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////// Update Disposal Type

export const UPDATE_DISPOSAL_TYPE = '[Orders Module] Update Disposal Type';
export const UPDATE_DISPOSAL_TYPE_SUCCESS =
  '[Orders Module] Update Disposal Type Success';
export const UPDATE_DISPOSAL_TYPE_FAIL =
  '[Orders Module] Update Disposal Type Fail';

export const UpdateDisposalType = createAction(
  UPDATE_DISPOSAL_TYPE,
  props<{ iri: string; payload: { disposalType: string } }>()
);

export const UpdateDisposalTypeSuccess = createAction(
  UPDATE_DISPOSAL_TYPE_SUCCESS,
  props<{ response: fromOrdersModuleModels.Order }>()
);

export const UpdateDisposalTypeFail = createAction(
  UPDATE_DISPOSAL_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////// Update Data Recovery Location

export const UPDATE_DATA_RECOVERY_LOCATION =
  '[Orders Module] Update Data Recovery Location';
export const UPDATE_DATA_RECOVERY_LOCATION_SUCCESS =
  '[Orders Module] Update Data Recovery Location Success';
export const UPDATE_DATA_RECOVERY_LOCATION_FAIL =
  '[Orders Module] Update Data Recovery Location Fail';

export const UpdateDataRecoveryLocation = createAction(
  UPDATE_DATA_RECOVERY_LOCATION,
  props<{ iri: string; payload: { dataRecoveryLocation: string } }>()
);

export const UpdateDataRecoveryLocationSuccess = createAction(
  UPDATE_DATA_RECOVERY_LOCATION_SUCCESS,
  props<{ response: any }>()
);

export const UpdateDataRecoveryLocationFail = createAction(
  UPDATE_DATA_RECOVERY_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ORDER = '[Orders Module] Delete Order';
export const DELETE_ORDER_SUCCESS = '[Orders Module] Delete Order Success';
export const DELETE_ORDER_FAIL = '[Orders Module] Delete Order Fail';

export const DeleteOrder = createAction(DELETE_ORDER, props<{ iri: string }>());

export const DeleteOrderSuccess = createAction(
  DELETE_ORDER_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteOrderFail = createAction(
  DELETE_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////////// Reset Current

export const RESET_CURRENT_ORDER = '[Orders Module] Reset Current Order';

export const ResetCurrentOrder = createAction(RESET_CURRENT_ORDER);

export const RESET_ORDERS_LOADED = '[Orders Module] Reset Orders Loaded';

export const ResetOrdersLoaded = createAction(RESET_ORDERS_LOADED);
