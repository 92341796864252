import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {ShippingProvider} from '../../../shipping/models';
import {combineLatest, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {extractIri} from '../../utilities/objects.utility';
import {
  ShipmentDirectionsSelectors,
} from '../../../shipping/store/selectors';
import {loadIfNotLoaded} from '../../utilities/observable.utility';
import {ShipmentDirectionsActions} from '../../../shipping/store';
import {Order} from "../../../orders/models";

@Component({
  selector: 'app-shipment-direction-select',
  styleUrls: ['./shipment-direction-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ShipmentDirectionSelectComponent),
    multi: true
  }],
  template: `
    <div [formGroup]="form">

      <ng-select
        formControlName="selectedElement"
        [items]="items$|async"
        [class.required]="required"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        [required]="required"
        (touchstart)="onTouched($event)"
        notFoundText="Der Versand dieser Datenträger ist nicht vorgesehen!"
        placeholder="Versand Richtung">

        <ng-template ng-label-tmp let-item="item">
          {{item.direction}}

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.direction}}
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error fieldName="selectedElement" [formGroup]="form"></app-form-error>
      </mat-error>

    </div>
  `

})
export class ShipmentDirectionSelectComponent extends BaseOnDestroyComponent implements OnInit, ControlValueAccessor, OnChanges {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<ShippingProvider> = new EventEmitter<ShippingProvider>();
  @Input() @Optional() dataMediaState$: Observable<string | null>;
  @Input() @Optional() order$: Observable<Order>;
  @Input() required = false;
  items$: Observable<Array<ShippingProvider>>;
  isLoading$: Observable<boolean>;
  selectedItem: ShippingProvider;

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
    this.items$ = combineLatest([(this.order$ || of(null)), this.store$.select(ShipmentDirectionsSelectors.selectShipmentDirections), this.dataMediaState$])
      .pipe(
        map(([order, a, dataMediaState]) => a.filter(e => {
          switch (dataMediaState) {
            case null:
              return true;
            case 'stored_dr_ext':
              return extractIri(e).indexOf('DR_EXT_TO') > -1;
            case 'stored_dr_de':
            case 'reserved':
            case 'technical_department_dr_de':
            case 'waiting_for_return_shipment':
              // lookup order if ext lab is involved
              if (order) {
                if ((extractIri(order.analysisLocation)?.indexOf('DR_EXT') > -1 || extractIri(order.dataRecoveryLocation)?.indexOf('DR_EXT') > -1)) {
                  return extractIri(e).indexOf('DR_DE_TO') > -1;
                } else {
                  return extractIri(e).indexOf('DR_DE_TO_CUSTOMER') > -1;
                }
              } else {
                return true;
              }
            case 'in_shipment_dr_de_to_dr_ext':
            case 'in_shipment_dr_de_to_customer':
            case 'in_shipment_dr_ext_to_dr_de':
            case 'destroyed':
            case 'delivered_to_customer':
              return false;
            default:
              return true;
          }

        })),
        tap(list => {
          if (list.length === 1) {
            this.form.get('selectedElement').setValue(extractIri(list[0]));
          }
        }));
    this.isLoading$ = this.store$.select(ShipmentDirectionsSelectors.isLoading);
    loadIfNotLoaded(this.store$, ShipmentDirectionsSelectors.isLoaded, ShipmentDirectionsActions.ReadShipmentDirections());
    this.updateRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required) {
      this.updateRequired();
    }
  }


  setSelectedItem(element: ShippingProvider): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: ShippingProvider): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }


  private updateRequired(): void {
    if (this.required && this.form?.get('selectedElement')) {
      this.form.get('selectedElement').setValidators([Validators.required]);
      this.form.updateValueAndValidity();
    }
  }
}
