import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_SALUTATION = '[MasterDataModule] Create Salutation';
export const CREATE_SALUTATION_SUCCESS = '[MasterDataModule] Create Salutation Success';
export const CREATE_SALUTATION_FAIL = '[MasterDataModule] Create Salutation Fail';
export const CreateSalutation = createAction(
  CREATE_SALUTATION,
  props<{ payload: fromModuleModels.Salutation }>()
);
export const CreateSalutationSuccess = createAction(
  CREATE_SALUTATION_SUCCESS,
  props<{ response: fromModuleModels.Salutation }>()
);
export const CreateSalutationFail = createAction(
  CREATE_SALUTATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SALUTATIONS = '[MasterDataModule] Read Salutations';
export const READ_SALUTATIONS_SUCCESS = '[MasterDataModule] Read Salutations Success';
export const READ_SALUTATIONS_FAIL = '[MasterDataModule] Read Salutations Fail';
export const ReadSalutations = createAction(
  READ_SALUTATIONS
);
export const ReadSalutationsSuccess = createAction(
  READ_SALUTATIONS_SUCCESS,
  props<{ response: any }>()
);
export const ReadSalutationsFail = createAction(
  READ_SALUTATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SALUTATION = '[MasterDataModule] Read Salutation';
export const READ_SALUTATION_SUCCESS = '[MasterDataModule] Read Salutation Success';
export const READ_SALUTATION_FAIL = '[MasterDataModule] Read Salutation Fail';
export const ReadSalutation = createAction(
  READ_SALUTATION,
  props<{ iri: string }>()
);
export const ReadSalutationSuccess = createAction(
  READ_SALUTATION_SUCCESS,
  props<{ response }>()
);
export const ReadSalutationFail = createAction(
  READ_SALUTATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_SALUTATION = '[MasterDataModule] Update Salutation';
export const UPDATE_SALUTATION_SUCCESS = '[MasterDataModule] Update Salutation Success';
export const UPDATE_SALUTATION_FAIL = '[MasterDataModule] Update Salutation Fail';
export const UpdateSalutation = createAction(
  UPDATE_SALUTATION,
  props<{ iri: string, payload: fromModuleModels.Salutation }>()
);
export const UpdateSalutationSuccess = createAction(
  UPDATE_SALUTATION_SUCCESS,
  props<{ response: fromModuleModels.Salutation }>()
);
export const UpdateSalutationFail = createAction(
  UPDATE_SALUTATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_SALUTATION = '[MasterDataModule] Delete Salutation';
export const DELETE_SALUTATION_SUCCESS = '[MasterDataModule] Delete Salutation Success';
export const DELETE_SALUTATION_FAIL = '[MasterDataModule] Delete Salutation Fail';
export const DeleteSalutation = createAction(
  DELETE_SALUTATION,
  props<{ iri: string }>()
);
export const DeleteSalutationSuccess = createAction(
  DELETE_SALUTATION_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteSalutationFail = createAction(
  DELETE_SALUTATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
