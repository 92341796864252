import { createReducer, on } from '@ngrx/store';

import { EmailTemplateCategoriesActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities,
  removeEntity
} from '../../../shared/utilities/reducer.utility';
import { EmailTemplateCategory } from '../../models';
import { UserRoleActions } from '../../../application-state/store/actions';

type baseType = EmailTemplateCategory;
const Actions = EmailTemplateCategoriesActions;

export interface State {
  entities: { [iri: string]: baseType };
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    Actions.Create,
    Actions.Read,
    Actions.ReadList,
    Actions.Update,
    Actions.Delete,
    state => ({
      ...state,
      loading: true
    })
  ),
  on(Actions.ReadSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities([response], null, state.entities),
    loading: false,
    loaded: false
  })),
  on(Actions.ReadListSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities(response['hydra:member'], null, state.entities),
    loading: false,
    loaded: true
  })),
  on(Actions.CreateSuccess, Actions.UpdateSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities([response], null, state.entities),
    loading: false
  })),
  on(Actions.DeleteSuccess, (state, { iri }) => {
    return {
      ...state,
      entities: removeEntity(iri, state.entities),
      loading: false
    };
  }),
  on(
    Actions.CreateFail,
    Actions.ReadFail,
    Actions.ReadListFail,
    Actions.UpdateFail,
    Actions.DeleteFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
