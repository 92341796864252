import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDateWithTime'
})
export class MomentDateWithTime implements PipeTransform {

  transform(value: Date | string | moment.Moment): string {
    if (value === null || value === undefined) {
      return '';
    }
    return moment(value).calendar({
      sameDay: '[Heute], HH:mm [Uhr]',
      nextDay: '[Morgen], HH:mm [Uhr]',
      nextWeek: 'DD.MM.YY, HH:mm [Uhr]',
      lastDay: '[Gestern], HH:mm [Uhr]',
      lastWeek: 'DD.MM.YY, HH:mm [Uhr]',
      sameElse: 'DD.MM.YY, HH:mm [Uhr]'
    });

  }

}
