import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ProductsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { ProductsService } from '../../services/products.service';

@Injectable()
export class ProductsEffects {
  CreateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.CreateProduct),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createProduct(payload).pipe(
          map(response => ProductsActions.CreateProductSuccess({ response })),
          catchError(response =>
            of(ProductsActions.CreateProductFail({ response }))
          )
        );
      })
    )
  );
  ReadProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ReadProduct),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getProduct(iri).pipe(
          map(response => ProductsActions.ReadProductSuccess({ response })),
          catchError(response =>
            of(ProductsActions.ReadProductFail({ response }))
          )
        );
      })
    )
  );
  ReadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.ReadProducts),
      map(action => action),
      switchMap(() => {
        return this.service.getProducts().pipe(
          map(response => ProductsActions.ReadProductsSuccess({ response })),
          catchError(response =>
            of(ProductsActions.ReadProductsFail({ response }))
          )
        );
      })
    )
  );
  SearchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.SearchProducts),
      map(action => action),
      switchMap(({ page, params }) => {
        return this.service.searchProducts(page, params).pipe(
          map(response => ProductsActions.SearchProductsSuccess({ response })),
          catchError(response =>
            of(ProductsActions.SearchProductsFail({ response }))
          )
        );
      })
    )
  );
  UpdateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.UpdateProduct),
      map(action => action),
      switchMap(payload => {
        return this.service.updateProduct(payload).pipe(
          map(response => ProductsActions.UpdateProductSuccess({ response })),
          catchError(response =>
            of(ProductsActions.UpdateProductFail({ response }))
          )
        );
      })
    )
  );
  DeleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.DeleteProduct),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteProduct(iri).pipe(
          map(() => ProductsActions.DeleteProductSuccess({ iri })),
          catchError(response =>
            of(ProductsActions.DeleteProductFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsActions.DeleteProductFail,
          ProductsActions.UpdateProductFail,
          ProductsActions.SearchProductsFail,
          ProductsActions.ReadProductsFail,
          ProductsActions.ReadProductFail,
          ProductsActions.CreateProductFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  CreateOrUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProductsActions.CreateProductSuccess,
        ProductsActions.UpdateProductSuccess
      ),
      map(({ type }) => {
        const text =
          type === ProductsActions.CREATE_PRODUCT_SUCCESS
            ? 'Eine neues Produkt wurde angelegt.'
            : 'Das Produkt wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: ProductsService,
    private notifierService: NotifierService
  ) {}
}
