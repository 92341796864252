import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataRecoveryProtocolsActions, OrdersActions} from '../../../orders/store';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {DataRecoveryProtocolsSelectors} from '../../../orders/store/selectors';
import {Observable, Subject} from 'rxjs';
import {DataRecoveryProtocol, Order} from '../../../orders/models';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../shared/services';
import {Actions, ofType} from '@ngrx/effects';
import {NotifierService} from 'angular-notifier';
import {filter, takeUntil} from 'rxjs/operators';
import {
  TicketDataRecoveryProtocolEditDialogComponent
} from "../ticket-data-recovery-protocol-edit-dialog/ticket-data-recovery-protocol-edit-dialog.component";

@Component({
  selector: 'app-ticket-data-recovery-results',
  styleUrls: ['./ticket-data-recovery-results.component.scss'],
  template: `
    <div class="card">

      <div class="card__heading">
        <span>Datenrettungsprotokoll</span>
        <span class="btn--edit">
            <button mat-icon-button (click)="showUpdateDataRecoveryProtocolModal()">
              <mat-icon>edit</mat-icon>
            </button>

        </span>
      </div>

      <div class="card__content p-a--24">

        <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
        <p>Der Kunde wünscht sich ein Datenrettungsprotokoll, nach Abschluss der
          Datenrettung.</p>
        <p class="my-3" *ngIf="currentProtocol?.sendDates?.length>0">Protokoll verschickt,
          <span class="text-color-green" *ngFor="let date of currentProtocol?.sendDates">
            {{date|date:'dd.mm.YYYY'}}
          </span>
          <!--{{currentProtocol.sendDates}}--></p>
        <p class="my-3" *ngIf="!(currentProtocol?.sendDates?.length>0)">Protokoll wurde noch nicht verschickt.</p>
      </div>

      <div class="card__footer p-l--24 p-r--24">
        <div class="row">
          <div class="col-6 text-left text-color-grey">
            <button mat-button (click)="downloadPreview()">
              <mat-icon>description</mat-icon>
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  `
})
export class TicketDataRecoveryResultsComponent implements OnInit, OnDestroy {
  order: Order;
  form: FormGroup;

  @Input() order$: Observable<Order>;
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  currentProtocol: DataRecoveryProtocol;
  possibleProtocols: Array<DataRecoveryProtocol>;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private notify: NotifierService
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(DataRecoveryProtocolsSelectors.isLoading),
    ]);
    this.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      this.order = order;
      if (order.dataRecoveryProtocol) {
        this.store$.dispatch(DataRecoveryProtocolsActions.ReadDataRecoveryProtocol({iri: order.dataRecoveryProtocol}));
        this.store$.select(DataRecoveryProtocolsSelectors.sByIri, {iri: order.dataRecoveryProtocol})
          .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
          .subscribe(p => {
            this.currentProtocol = p;
          });
      }

    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


  downloadPreview(): void {
    this.store$.dispatch(OrdersActions.ReadDataRecoveryProtocolPDF({iri: extractIri(this.order)}));
  }

  showUpdateDataRecoveryProtocolModal(): void {
    this.dialog.open(TicketDataRecoveryProtocolEditDialogComponent, {data: {order$: this.order$}, width: '85%'});

  }


}
