import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { environment } from '../../../../environments/environment';

import { fromEvent, Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PotentialAction } from '../../models';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BindingOrdersService } from '../../../orders/services';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { filter, takeUntil } from 'rxjs/operators';
import { BindingOrdersActions } from '../../../orders/store';
import { BindingOrdersSelectors } from '../../../orders/store/selectors';
import { Actions, ofType } from '@ngrx/effects';
import { TransitionsActions } from '../../../application-state/store/actions';
import { BytesPipe } from 'ngx-pipes';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-bo-received-via-email',
  styleUrls: ['./action-box-to-bo-received-via-email.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Verbindliche Bestellung eingegangen?
              </div>
              <div class="sub-header col-auto">
                {{"orders.action_required.is_order_received" | translate}}
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right" [formGroup]="form">
            <div class="row" *ngIf="uploadStatus$ | async as status">
              <div class="col-12 text-center">
                <span *ngIf="status.type === 1"> Lade hoch ... </span>
                <span *ngIf="status.type === 3"> Verarbeite Datei ... </span>
                <mat-progress-bar
                  [mode]="status.type === 1 ? 'determinate' : 'indeterminate'"
                  [value]="(status.loaded / status.total) * 100"
                ></mat-progress-bar>
              </div>
            </div>
            <div
              class="d-flex justify-content-end"
              [class.hidden]="uploadStatus$ | async"
            >
              <div class="">
                <mat-form-field>
                  <mat-label>Bestätigungsdatum</mat-label>
                  <input type="date" matInput formControlName="date" />
                </mat-form-field>
              </div>
              <div class="pt-1 px-2">
                <button
                  mat-button
                  color="transparent"
                  (click)="fileInput.click()"
                >
                  <mat-icon class="me-2">upload</mat-icon>
                  <span>Datei auswählen</span>
                </button>
                <input
                  type="file"
                  formControlName="file"
                  #fileInput
                  style="display: none"
                />
              </div>
              <div class="">
                <button
                  class="decision-btn me-3 btn-wider float-right"
                  mat-button
                  color="green"
                  (click)="submitForm()"
                  [disabled]="form.invalid"
                >
                  <mat-icon class="me-2">upload</mat-icon>
                  <span>Verbindliche Bestellung hochladen</span>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="uploadError">
              <div class="col-12 text-color-red">{{ uploadError }}</div>
            </div>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">VB:</div>
          <div class="sub-header col-auto">Warte auf Eingang der VB</div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToBoReceivedViaEmailComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  uploadError: string = null;
  uploadStatus$: Observable<{ type: number; loaded: number; total?: number }>;
  onDestroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  okAction: PotentialAction = null;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    public bos: BindingOrdersService,
    private authService: AuthService,
    private actions$: Actions,
    private store$: Store<ApplicationState>
  ) {}

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isSales() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngAfterViewInit(): void {
    if (!this.fileInput) {
      return;
    }
    fromEvent(this.fileInput.nativeElement, 'change')
      .pipe(
        takeUntil(this.onDestroy$),
        filter(
          (changeEvent: any) =>
            changeEvent.target.files && changeEvent.target.files.length
        )
      )
      .subscribe((changeEvent: any) => {
        const file = changeEvent.target.files[0];
        this.form.patchValue({ file });
      });
  }

  ngOnInit(): void {
    this.uploadStatus$ = this.store$.select(
      BindingOrdersSelectors.sUploadStatus
    );
    this.form = this.fb.group({
      date: this.fb.control(
        moment()
          .toISOString(true)
          .substring(0, 10),
        [Validators.required]
      ),
      file: this.fb.control(null, [Validators.required]),
      order: this.fb.control(null, [Validators.required])
    });
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(getUuidFromIri(order['@id']));
        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_bo_received_via_email') > -1
        );
      });
    this.actions$
      .pipe(
        ofType(BindingOrdersActions.UploadBindingOrderFail),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fail => {
        console.log(fail);
        if (fail?.response?.error) {
          this.uploadError = fail?.response?.error['hydra:description'];
        }
      });
    this.actions$
      .pipe(
        ofType(BindingOrdersActions.UploadBindingOrderSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.makeTransition();
        this.uploadError = null;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const values = this.form.getRawValue();
    console.log(values);
    if (values.file?.size > environment.maxUploadFileSize) {
      this.uploadError =
        'Datei ist zu groß: ' +
        new BytesPipe().transform(values.file?.size, 2) +
        '. Maximale erlaubte Dateigröße: ' +
        new BytesPipe().transform(environment.maxUploadFileSize, 2);
      return;
    }
    values.createdAt = moment(values.date).toISOString(true);
    this.store$.dispatch(
      BindingOrdersActions.UploadBindingOrder({
        payload: {
          ...values,
          file: this.form.value.file
        }
      })
    );
  }
}
