import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Order} from '../../../orders/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PotentialAction} from '../../models';
import {LabLocation} from '../../../master-data/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {filter, takeUntil} from 'rxjs/operators';
import {LabLocationsSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../utilities/observable.utility';
import {LabLocationsActions} from '../../../master-data/store';
import {OrdersActions} from '../../../orders/store';
import {TransitionsActions} from '../../../application-state/store/actions';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {ActionBoxInputTypes} from '../../models/action-box-input-types.type';
import {AuthService} from '../../../auth/services/auth.service';
import {FormsService} from '../../services';

@Component({
  selector: 'app-action-box-to-prepare-dr',
  styleUrls: ['./action-box-to-prepare-dr.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Wo soll die Datenrettung erfolgen?
              </div>
              <div class="sub-header col-auto">
                Bitte Ort für Datenrettung definieren
              </div>
            </div>
          </div>
          <div
            class="actions col my-2 text-right"
            [formGroup]="form"
            *ngIf="canMakeTransition"
          >
            <button
              class="decision-btn me-3 btn-wider float-right"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Ort Datenrettung festlegen</span>
            </button>

            <ng-select
              style="width: 300px; display: inline-block; float:right;"
              class="me-4"
              placeholder="Datenrettung in"
              [items]="labLocations$ | async"
              [loading]="labLocationsIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              formControlName="dataRecoveryLocation"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'dataRecoveryLocation'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Datenrettungsort:</div>
          <div class="sub-header col-auto">
            Warte auf Definierung des Datenrettungsort
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToPrepareDrComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;

  labLocations$: Observable<Array<LabLocation>>;
  labLocationsIsLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private fb: FormBuilder,
    private formService: FormsService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadLabLocations();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(this.order['@id']);
        this.form
          .get('dataRecoveryLocation')
          .setValue(this.order.dataRecoveryLocation);
        this.findOkAction(order);
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      dataRecoveryLocation: this.fb.control(null)
    });
  }

  findOkAction(order) {
    this.okAction = order.potentialActions?.find(
      e =>
        e.transition.indexOf(
          'customer_analysis_created_to_data_recovery_provide_purchase_prices'
        ) > -1 && !e.error
    );
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({uri, payload}));
  }

  submitForm(): void {
    const formData = this.form.value;
    this.store$.dispatch(
      OrdersActions.UpdateDataRecoveryLocation({
        iri: formData.order,
        payload: {dataRecoveryLocation: formData.dataRecoveryLocation}
      })
    );
    this.actions$
      .pipe(
        ofType(OrdersActions.UpdateDataRecoveryLocationSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({response}) => {
        console.log(response);
        this.order = response;
        this.findOkAction(response);
        this.makeTransition();
      });
    this.actions$
      .pipe(
        ofType(OrdersActions.UpdateDataRecoveryLocationFail),
        takeUntil(this.onDestroy$),
        takeUntil(this.actions$.pipe(ofType(OrdersActions.UpdateDataRecoveryLocationSuccess)))
      )
      .subscribe((failData) => {
        this.formService.mergeErrorResponseIntoForm(failData, this.form);
      });
  }

  private loadLabLocations(): void {
    this.labLocations$ = this.store$.select(LabLocationsSelectors.sList);
    this.labLocationsIsLoading$ = this.store$.select(
      LabLocationsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }
}
