import * as fromAdministratorsModuleModels from '../../administrators/models';
import * as fromCustomersModuleModels from '../../customers/models';

export const getUuidFromIri = (iri: string): string => {
  return iri?.match(/[^\/]+(?=\/$|$)/)[0];
};

export const parseGermanFloat = (
  input: string | number,
  removeStringBeforeParse = null
): number => {
  if (typeof input === 'number') {
    return input;
  } else {
    if (
      removeStringBeforeParse &&
      input.indexOf &&
      input.indexOf(removeStringBeforeParse) > -1
    ) {
      input = input.replace(removeStringBeforeParse, '');
    }
    return parseFloat(
      input
        .trim()
        .split('.')
        .join('')
        .replace(',', '.')
    );
  }
};

const formatAddress = (address: fromCustomersModuleModels.Address): string => {
  let result = `${address.line1} ${address.line2}`;
  if (address.line3) {
    result += `, ${address.line3}`;
  }
  result += `, ${address.zipPostcode} ${address.city}`;
  return result;
};

const formatName = (
  contact: fromCustomersModuleModels.CustomerContact
): string => {
  /*${address.zipPostcode} ${address.city}*/
  return `${contact.firstName} ${contact.lastName}`;
};
export const convertNumberToFloatString = (
  value: any,
  decimalPlaces = 2
): string => {
  if (!value) {
    return '0.0';
  } else if (typeof value !== 'string') {
    return value.toFixed(decimalPlaces);
  }
  return value;
};

const formatCustomerName = (
  customer: fromCustomersModuleModels.Customer
): string => {
  return `${customer.nameLine1} ${
    customer?.nameLine2 ? customer.nameLine2 : ''
  }`;
};

const formatAdministratorName = (
  administrator: fromAdministratorsModuleModels.Administrator
): string => {
  return `${administrator?.firstName} ${administrator?.lastName}`;
};

export const StringsUtility = {
  formatAddress,
  formatAdministratorName,
  formatCustomerName,
  formatName,
  getUuidFromIri,
  convertNumberToFloatString
};
