import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {AdministratorFeedbackRequestsActions} from "../../../administrators/store";
import {extractUUID} from "../../utilities/objects.utility";
import {AdministratorFeedbackRequestsSelectors} from "../../../administrators/store/selectors";
import {Observable, Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";
import {FeedbackRequest} from "../../../administrators/models";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-feedback-request-list',
  templateUrl: './feedback-request-list.component.html',
  styleUrls: ['./feedback-request-list.component.scss']
})
export class FeedbackRequestListComponent implements OnInit, OnChanges, OnDestroy {
  displayedColumns: string[] = ['circle', 'type', 'createdAt', 'content', 'updatedAt'];
  @Input() profile: any;
  @Input() selectedValues: Array<string>;
  @Input() dispatchAction: any;
  @Input() feedbacks$: Observable<FeedbackRequest[]>;
  dataSource = new MatTableDataSource<any>([{id: null, name: 'Keine Elemente vorhanden', age: null}]);

  @ViewChild(MatSort) sort: MatSort;

  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();
  sent: string;
  received: string;
  constructor(
    private store$: Store<ApplicationState>
  ) { }


  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(AdministratorFeedbackRequestsSelectors.isLoading);
  }

  ngOnChanges(changes:SimpleChanges): void {
    this.store$.dispatch(this.dispatchAction({
      iri: this.profile,
      params: this.selectedValues && this.selectedValues
    }));

    // Subscribe to the observable and update the data source
    this.feedbacks$.subscribe(feedbacks => {
      this.dataSource.data = [...feedbacks];
      this.dataSource.sort = this.sort;
    });
  }


  protected readonly extractUUID = extractUUID;
}
