<div class="wrapper p-y--8">
  <div class="widget card">
    <div class="widget_header">
      <span>
        {{"dashboard.widget.overdue_storage_system_title" | translate}}
      </span>
      <div class="spacer"></div>
    </div>
    <app-orders-list-for-widget
      [states]="states"
      [additionalParams]="additionalParams"
      [indicatorStyleFn]="getCircleColor"
    ></app-orders-list-for-widget>
  </div>
</div>
