import {createSelector} from '@ngrx/store';

import {SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FEATURE_KEY, selectShippingModuleState} from '../reducers';

export const selectShippingProvidersToDataRecoveryState = createSelector(
  selectShippingModuleState,
  (moduleState) => moduleState[SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FEATURE_KEY]
);

export const selectShippingProvidersToDataRecoveryEntities = createSelector(
  selectShippingProvidersToDataRecoveryState,
  (state) => state.entities
);

export const selectShippingProvidersToDataRecovery = createSelector(
  selectShippingProvidersToDataRecoveryEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectShippingProvidersToDataRecoveryState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectShippingProvidersToDataRecoveryState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectShippingProvidersToDataRecoveryState,
  (state) => state.loaded
);
