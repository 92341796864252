import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';

import {ApplicationState} from '../../application-state/store';
import {ShipmentServicesActions} from '../store';
import {ShipmentServicesSelectors} from '../store/selectors';

@Injectable()
export class ShipmentServicesLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return this.loadShipmentServicesDone().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  loadShipmentServicesDone() {

    return this.store.pipe(
      select(ShipmentServicesSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(ShipmentServicesActions.ReadShipmentServices({serviceType: ''}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
