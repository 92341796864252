import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FormsService} from '../../../shared/services';
import {LocationType, Order} from '../../../orders/models';
import {
  Address,
  BillingAddress,
  Customer,
  CustomerAccount,
  CustomerContact,
  DeliveryAddress,
  PartnerWebsite
} from '../../../customers/models';
import {
  AnalysisPriorityMode,
  Currency,
  DataRecoveryPriorityMode,
  Department,
  Discount, DisposalType, LabLocation
} from '../../../master-data/models';
import {ShippingProvider} from '../../../shipping/models';
import {
  CustomerAccountsSelectors,
  CustomerAddressesSelectors,
  CustomerContactsSelectors,
  CustomersSelectors, PartnerWebsitesSelectors
} from '../../../customers/store/selectors';
import {
  CustomerAccountsActions,
  CustomerContactsActions,
  CustomersActions, PartnerWebsitesActions
} from '../../../customers/store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {OrdersActions} from '../../../orders/store';
import {
  AnalysisPriorityModesSelectors,
  CurrenciesSelectors,
  DataRecoveryPriorityModesSelectors,
  DepartmentsSelectors,
  DiscountsSelectors, DisposalTypesSelectors, LabLocationsSelectors
} from '../../../master-data/store/selectors';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {
  AnalysisPriorityModesActions,
  CurrenciesActions,
  DataRecoveryPriorityModesActions,
  DepartmentsActions,
  DiscountsActions, DisposalTypesActions, LabLocationsActions
} from '../../../master-data/store';
import {ShippingProvidersToCustomerSelectors} from '../../../shipping/store/selectors';
import {ShippingProvidersActions} from '../../../shipping/store';
import {removeEmptyFormElements} from '../../../shared/utilities/forms.utility';
import * as OrdersModuleActions from '../../../orders/store';
import {combineLatestArray} from 'rxjs-etc';
import {extractIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-ticket-form',
  styleUrls: ['ticket-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

    <div class="grid grid-top" [formGroup]="form">

      <mat-form-field class="column-14">
        <mat-placeholder>Referenz</mat-placeholder>
        <input type="text" matInput formControlName="reference">
        <mat-error>
          <app-form-error [fieldName]="'reference'" [formGroup]="form"></app-form-error>
        </mat-error>

      </mat-form-field>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Bereich"
          [items]="departments$ | async"
          [loading]="departmentsIsLoading$ | async"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          [clearable]="false"
          formControlName="department"
        ></ng-select>
        <mat-error>
          <app-form-error [fieldName]="'department'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <mat-form-field class="column-14">
        <mat-placeholder>Kunde</mat-placeholder>
        <input type="text" matInput disabled [value]="getCustomerName(customer)">
      </mat-form-field>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Rechnungsadresse"
          [items]="customerBillingAddresses"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          formControlName="billingAddress">

          <ng-template ng-label-tmp let-item="item">
            <span class="p-r--8">{{ getFormattedAddress(item.address) }}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span>{{ getFormattedAddress(item.address) }}</span>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error [fieldName]="'billingAddress'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Lieferadresse"
          [items]="customerDeliveryAddresses"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          formControlName="deliveryAddress">

          <ng-template ng-label-tmp let-item="item">
            <span class="p-r--8">{{ getFormattedAddress(item.address) }}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span>{{ getFormattedAddress(item.address) }}</span>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error [fieldName]="'deliveryAddress'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">

        <ng-select
          placeholder="Technischer Ansprechpartner"
          [items]="technicalCustomerContacts"
          bindValue="@id"
          dropdownPosition="top"
          [markFirst]="false"
          [searchable]="false"
          formControlName="technicalCustomerContact">

          <ng-template ng-label-tmp let-item="item">
            <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="ng-option">
              <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
            </div>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error [fieldName]="'technicalCustomerContact'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Entscheider"
          [items]="decisionMakerCustomerContacts"
          dropdownPosition="top"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          [searchable]="false"
          formControlName="decisionMakerCustomerContact">

          <ng-template ng-label-tmp let-item="item">
            <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="ng-option">
              <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
            </div>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error [fieldName]="'decisionMakerCustomerContact'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <app-customer-select
          label="Vermittler"
          (selectCustomer)="selectBroker($event)"></app-customer-select>
        <mat-error>
          <app-form-error [fieldName]="'broker'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          [items]="brokerContacts$|async"
          bindLabel="name"
          [searchable]="true"
          [loading]="brokerContactsIsLoading$| async"
          bindValue="@id"
          [clearable]="true"
          [searchFn]="findBrokerContact"
          placeholder="Vermittler Kontakt wählen"
          formControlName="brokerContact">

          <ng-template ng-label-tmp let-item="item">
                  <span *ngIf="item['@type'] == 'PartnerWebsite'">
                    <strong>W:</strong>
                    {{item.website}}
                  </span>
            <span *ngIf="item['@type'] == 'CustomerAccount'">
                    <strong>P:</strong>
              {{item.firstName}} {{item.lastName}}
              <span *ngIf="item.email"> ({{item.email}})</span>
                  </span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
                  <span *ngIf="item['@type'] === 'PartnerWebsite'">
                    <strong>W:</strong>
                    {{item.website}}
                  </span>
            <span *ngIf="item['@type'] === 'CustomerAccount'">
                    <strong>P:</strong>
              {{item.firstName}} {{item.lastName}}
              <span *ngIf="item.email"> ({{item.email}})</span>
                  </span>
          </ng-template>

        </ng-select>
        <mat-error>
          <app-form-error [fieldName]="'brokerContact'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Analyse in"
          [items]="labLocations$ | async"
          [loading]="labLocationsIsLoading$ | async"
          bindValue="@id"
          (change)="handleRequestUpdateLocation('analysisLocation', $event)"
          bindLabel="name"
          [markFirst]="false"
          dropdownPosition="top"
          formControlName="analysisLocation"
        ></ng-select>
        <mat-error>
          <app-form-error [fieldName]="'analysisLocation'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Datenrettung in"
          [items]="labLocations$ | async"
          [loading]="labLocationsIsLoading$ | async"
          bindValue="@id"
          bindLabel="name"
          (change)="handleRequestUpdateLocation('dataRecoveryLocation', $event)"
          [markFirst]="false"
          dropdownPosition="top"
          formControlName="dataRecoveryLocation"
        ></ng-select>
        <mat-error>
          <app-form-error [fieldName]="'dataRecoveryLocation'" [formGroup]="form"></app-form-error>
        </mat-error>
      </div>

      <div class="column-14 m-ta--2">
        <button (click)="handleSubmit()" mat-button color="green" [disabled]="form.invalid">
          <mat-icon class="m-r--8">edit</mat-icon>
          <span>Ticket aktualisieren</span>
        </button>
      </div>
    </div>

    <!--<pre>{{ tf.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
    <!--<pre>{{ customerContacts | json }}</pre>-->
    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
    <!--<pre>{{ labLocations | json }}</pre>-->
  `
})
export class TicketFormComponent implements OnInit, OnDestroy {

  customer: Customer;
  customerBillingAddresses: Array<BillingAddress>;
  customerContacts: Array<CustomerContact>;
  customerDeliveryAddresses: Array<DeliveryAddress>;

  analysisPriorityModes$: Observable<Array<AnalysisPriorityMode>>;
  analysisPriorityModesIsLoading$: Observable<boolean>;

  dataRecoveryPriorityModes$: Observable<Array<DataRecoveryPriorityMode>>;
  dataRecoveryPriorityModesIsLoading$: Observable<boolean>;

  departments$: Observable<Array<Department>>;
  departmentsIsLoading$: Observable<boolean>;


  brokerContacts$: Observable<Array<CustomerContact | PartnerWebsite>>;
  brokerContactsIsLoading$: Observable<boolean>;

  discounts$: Observable<Array<Discount>>;
  discountsIsLoading$: Observable<boolean>;

  currencies$: Observable<Array<Currency>>;
  currenciesIsLoading$: Observable<boolean>;

  shippingProvidersToCustomer$: Observable<Array<ShippingProvider>>;
  shippingProvidersToCustomerIsLoading$: Observable<boolean>;

  disposalTypes$: Observable<Array<DisposalType>>;
  disposalTypesIsLoading$: Observable<boolean>;

  labLocations$: Observable<Array<LabLocation>>;
  labLocationsIsLoading$: Observable<boolean>;

  @Input() order$: Observable<Order>;
  order: Order;

  @Output() requestUpdateOrder: EventEmitter<{ iri: string, payload: Order }> = new EventEmitter();

  technicalCustomerContacts: Array<CustomerContact>;
  organizationalCustomerContacts: Array<CustomerContact>;
  decisionMakerCustomerContacts: Array<CustomerContact>;

  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  selectedBroker: string;

  constructor(private fb: FormBuilder, private fs: FormsService, private store$: Store<ApplicationState>, public actions$: Actions) {
  }

  ngOnInit(): void {
    this.initForm();
    this.handleBrokerChange();

    this.order$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((order: Order) => {
      this.order = order;
      const formData: any = {...this.order};
      if (this.order.analysisPriorityMode && this.order.analysisPriorityMode['@id']) {
        formData.analysisPriorityMode = this.order.analysisPriorityMode['@id'];
      }
      if (this.order.billingAddress && this.order.billingAddress['@id']) {
        formData.billingAddress = this.order.billingAddress['@id'];
      }
      if (this.order.deliveryAddress && this.order.deliveryAddress['@id']) {
        formData.deliveryAddress = this.order.deliveryAddress['@id'];
      }
      if (this.order.dataRecoveryPriorityMode && this.order.dataRecoveryPriorityMode['@id']) {
        formData.dataRecoveryPriorityMode = this.order.dataRecoveryPriorityMode['@id'];
      }
      if (this.order.broker && this.order.broker['@id']) {
        formData.broker = this.order.broker['@id'];
      }
      if (this.order.brokerContact && this.order.brokerContact['@id']) {
        formData.brokerContact = this.order.brokerContact['@id'];
      }
      this.technicalCustomerContacts = [];
      this.organizationalCustomerContacts = [];
      this.decisionMakerCustomerContacts = [];
      this.readCustomerData();

      this.fs.patchForm(this.form, formData);

      if (!this.analysisLocationCanBeChanged(order)) {
        this.form.get('analysisLocation').disable();
      }
      if (!this.dataRecoveryLocationCanBeChanged(order)) {
        this.form.get('dataRecoveryLocation').disable();
      }

      this.form.markAsUntouched();

    });

    this.loadDepartments();
    this.loadDataRecoveryPriorityModes();
    this.loadDiscounts();
    this.loadCurrencies();
    this.loadAnalysisPriorityModes();
    this.loadShippingProvidersToCustomer();
    this.loadDisposalTypes();
    this.loadLabLocations();
    this.loadBrokerContacts();

    this.actions$.pipe(
      ofType(
        OrdersActions.UpdateOrderFail,
        OrdersActions.UpdateDataRecoveryLocationFail,
        OrdersActions.UpdateAnalysisLocationFail
      )).subscribe((fail) => {
      if (fail?.response?.error?.violations) {
        this.fs.mergeViolationsIntoForm(fail.response.error.violations, this.form);
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  analysisLocationCanBeChanged(order): boolean {
    const potentialActionWithError = order?.potentialActions?.filter(
      e => e?.error?.detail === '/: No analysis lab location selected');

    return potentialActionWithError?.length > 0 && !order?.analysisLocation;
  }

  dataRecoveryLocationCanBeChanged(order): boolean {
    const potentialActionWithError = order?.potentialActions?.filter(
      e => e?.error?.detail === '/: No data recovery lab location selected');

    return potentialActionWithError?.length > 0 && !order?.dataRecoveryLocation;
  }

  readCustomerData(): void {
    this.store$.select(CustomersSelectors.selectCustomerByIndex, {iri: this.order.customer['@id']}).subscribe((customer) => {
      if (!customer) {
        return;
      }
      this.customer = customer;

      this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({
        page: -1,
        params: {'customer.uuid': getUuidFromIri(customer['@id'])}
      }));
      this.store$.select(
        CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
        {customerIri: customer['@id']})
        .subscribe((adresses) => {
          this.customerBillingAddresses = adresses;
        });
      this.store$.select(
        CustomerAddressesSelectors.selectCustomerDeliveryAddressesByCustomerIri,
        {customerIri: customer['@id']})
        .subscribe((adresses) => {
          this.customerDeliveryAddresses = adresses;
        });

      this.store$.select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, {customerIri: customer['@id']})
        .subscribe((customerContacts) => {
          this.technicalCustomerContacts = customerContacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/0'));
          this.organizationalCustomerContacts = customerContacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/1'));
          this.decisionMakerCustomerContacts = customerContacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/2'));
        });
    });
  }

  getFormattedAddress(address: Address): string {
    if (!address) {
      return '';
    }

    return `${address.line1} ${address.line2}${address.line3 ? (' ' + address.line3) : ''} ${address.zipPostcode}, ${address.city}`;
  }

  getCustomerName(customer: Customer): string {
    if (!customer) {
      return '';
    }

    let name = `${customer.nameLine1}`;
    if (!!customer.nameLine2) {
      name = `${name} [${customer.nameLine2}]`;
    }
    return name;
  }


  initForm(): void {
    this.form = this.fb.group({
      billingAddress: this.fb.control(null),
      deliveryAddress: this.fb.control(null),
      customer: this.fb.control(null),
      broker: this.fb.control(null),
      technicalCustomerContact: this.fb.control(null),
      organizationalCustomerContact: this.fb.control(null),
      decisionMakerCustomerContact: this.fb.control(null),
      analysisLocation: this.fb.control(null),
      dataRecoveryLocation: this.fb.control(null),
      department: this.fb.control(null),
      reference: this.fb.control(null),
      brokerContact: this.fb.control(null),
    });
  }

  handleSubmit(): void {
    this.handleRequestUpdateTicket();
    // this.handleRequestUpdateOrder();
  }

  handleRequestUpdateTicket(): void {
    const formValue = removeEmptyFormElements({...this.form.value});
    this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateOrder({iri: this.order['@id'], payload: formValue}));
  }

  handleUpdateLocation({locationType, iri, payload}: {
    locationType: LocationType,
    iri: string,
    payload: LabLocation
  }): void {

    if (locationType === 'analysisLocation') {
      this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateAnalysisLocation({
        iri,
        payload: {analysisLocation: payload['@id']}
      }));
    }

    if (locationType === 'dataRecoveryLocation') {
      this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateDataRecoveryLocation({
        iri,
        payload: {dataRecoveryLocation: payload['@id']}
      }));
    }
  }

  selectBroker(broker: Customer): void {
    this.form.get('broker').patchValue({broker: extractIri(broker)});
    this.handleBrokerChange();
  }

  handleBrokerChange(): void {
    this.form.get('broker').valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((brokerId) => {
        this.form.get('brokerContact').setValue(null);
        this.selectedBroker = brokerId;
        this.brokerContacts$ = combineLatestArray([
          this.store$.select(CustomerAccountsSelectors.selectCustomerAccountsByCustomerIri,
            {iri: this.selectedBroker ? this.selectedBroker : null}),
          this.store$.select(PartnerWebsitesSelectors.selectPartnerWebsites)
        ], (results) => {
          const result = [];
          result.push(...results[0], ...results[1]);
          return result;
        });

      });
  }

  findBrokerContact(term: string, item: CustomerAccount | PartnerWebsite): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return 'firstName' in item && item.firstName.toLowerCase().indexOf(t.toLowerCase()) > -1 ||
        ('lastName' in item && item.lastName && item.lastName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        ('website' in item && item.website && item.website.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  handleRequestUpdateLocation(locationType: LocationType, payload: LabLocation): void {
    this.handleUpdateLocation({locationType, iri: this.order['@id'], payload});
  }

  private loadDepartments(): void {
    this.departments$ = this.store$.select(DepartmentsSelectors.selectDepartments);
    this.departmentsIsLoading$ = this.store$.select(DepartmentsSelectors.isLoading);
    loadIfNotLoaded(this.store$, DepartmentsSelectors.isLoaded, DepartmentsActions.ReadDepartments());
  }

  private loadDataRecoveryPriorityModes(): void {
    this.dataRecoveryPriorityModes$ = this.store$.select(DataRecoveryPriorityModesSelectors.sList);
    this.dataRecoveryPriorityModesIsLoading$ = this.store$.select(DataRecoveryPriorityModesSelectors.isLoading);
    loadIfNotLoaded(this.store$, DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes());
  }


  private loadDiscounts(): void {
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.discountsIsLoading$ = this.store$.select(DiscountsSelectors.isLoading);
    loadIfNotLoaded(this.store$, DiscountsSelectors.isLoaded, DiscountsActions.ReadDiscounts());

  }

  private loadCurrencies(): void {
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.currenciesIsLoading$ = this.store$.select(CurrenciesSelectors.isLoading);
    loadIfNotLoaded(this.store$, CurrenciesSelectors.isLoaded, CurrenciesActions.ReadCurrencies());
  }

  private loadAnalysisPriorityModes(): void {
    this.analysisPriorityModes$ = this.store$.select(AnalysisPriorityModesSelectors.selectAnalysisPriorityModes);
    this.analysisPriorityModesIsLoading$ = this.store$.select(AnalysisPriorityModesSelectors.isLoading);
    loadIfNotLoaded(this.store$, AnalysisPriorityModesSelectors.isLoaded, AnalysisPriorityModesActions.ReadAnalysisPriorityModes());
  }

  private loadShippingProvidersToCustomer(): void {
    this.shippingProvidersToCustomer$ = this.store$.select(ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomer);
    this.shippingProvidersToCustomerIsLoading$ = this.store$.select(ShippingProvidersToCustomerSelectors.isLoading);
    loadIfNotLoaded(this.store$, ShippingProvidersToCustomerSelectors.isLoaded, ShippingProvidersActions.ReadShippingProvidersToCustomer());
  }

  private loadDisposalTypes(): void {
    this.disposalTypes$ = this.store$.select(DisposalTypesSelectors.selectDisposalTypes);
    this.disposalTypesIsLoading$ = this.store$.select(DisposalTypesSelectors.isLoading);
    loadIfNotLoaded(this.store$, DisposalTypesSelectors.isLoaded, DisposalTypesActions.ReadDisposalTypes());

  }

  private loadLabLocations(): void {
    this.labLocations$ = this.store$.select(LabLocationsSelectors.sList);
    this.labLocationsIsLoading$ = this.store$.select(LabLocationsSelectors.isLoading);
    loadIfNotLoaded(this.store$, LabLocationsSelectors.isLoaded, LabLocationsActions.ReadLabLocations());
  }

  private loadBrokerContacts(): void {
    this.brokerContactsIsLoading$ = isLoadingArray([
      this.store$.select(CustomerAccountsSelectors.isLoading),
      this.store$.select(PartnerWebsitesSelectors.isLoading)
    ]);
    loadIfNotLoaded(this.store$, CustomerAccountsSelectors.isLoaded, CustomerAccountsActions.ReadCustomerAccounts({page: -1}));
    loadIfNotLoaded(this.store$, PartnerWebsitesSelectors.isLoaded, PartnerWebsitesActions.ReadPartnerWebsites({page: -1}));

  }


}
