import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_TERMS_AND_CONDITIONS_ENTITY = '[Master Data] Create Terms and Conditions Entity';
export const CREATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS = '[Master Data] Create Terms and Conditions Entity Success';
export const CREATE_TERMS_AND_CONDITIONS_ENTITY_STATUS = '[Master Data] Create Terms and Conditions Entity Status';
export const CREATE_TERMS_AND_CONDITIONS_ENTITY_FAIL = '[Master Data] Create Terms and Conditions Entity Fail';
export const CreateTermsAndConditionsEntity = createAction(
  CREATE_TERMS_AND_CONDITIONS_ENTITY,
  props<{ payload: { file: File, uuid?: string } }>()
);
export const CreateTermsAndConditionsEntitySuccess = createAction(
  CREATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
  props<{ response: fromModuleModels.TermsAndConditionsEntity }>()
);
export const CreateTermsAndConditionsEntityStatus = createAction(
  CREATE_TERMS_AND_CONDITIONS_ENTITY_STATUS,
  props<{ response: any }>()
);
export const CreateTermsAndConditionsEntityFail = createAction(
  CREATE_TERMS_AND_CONDITIONS_ENTITY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_TERMS_AND_CONDITIONS_ENTITIES = '[Master Data] Read Terms and Conditions Entities';
export const READ_TERMS_AND_CONDITIONS_ENTITIES_SUCCESS = '[Master Data] Read Terms and Conditions Entities Success';
export const READ_TERMS_AND_CONDITIONS_ENTITIES_FAIL = '[Master Data] Read Terms and Conditions Entities Fail';
export const ReadTermsAndConditionsEntities = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITIES
);
export const ReadTermsAndConditionsEntitiesSuccess = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITIES_SUCCESS,
  props<{ response: any }>()
);
export const ReadTermsAndConditionsEntitiesFail = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITIES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1n
export const READ_TERMS_AND_CONDITIONS_ENTITY = '[Master Data] Read Terms and Conditions Entity';
export const READ_TERMS_AND_CONDITIONS_ENTITY_SUCCESS = '[Master Data] Read Terms and Conditions Entity Success';
export const READ_TERMS_AND_CONDITIONS_ENTITY_FAIL = '[Master Data] Read Terms and Conditions Entity Fail';
export const ReadTermsAndConditionsEntity = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITY,
  props<{ iri: string }>()
);
export const ReadTermsAndConditionsEntitySuccess = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
  props<{ response }>()
);
export const ReadTermsAndConditionsEntityFail = createAction(
  READ_TERMS_AND_CONDITIONS_ENTITY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_TERMS_AND_CONDITIONS_ENTITY = '[Master Data] Update Terms and Conditions Entity';
export const UPDATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS = '[Master Data] Update Terms and Conditions Entity Success';
export const UPDATE_TERMS_AND_CONDITIONS_ENTITY_FAIL = '[Master Data] Update Terms and Conditions Entity Fail';
export const UpdateTermsAndConditionsEntity = createAction(
  UPDATE_TERMS_AND_CONDITIONS_ENTITY,
  props<{ iri: string, payload: fromModuleModels.TermsAndConditionsEntity }>()
);
export const UpdateTermsAndConditionsEntitySuccess = createAction(
  UPDATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
  props<{ response: fromModuleModels.TermsAndConditionsEntity }>()
);
export const UpdateTermsAndConditionsEntityFail = createAction(
  UPDATE_TERMS_AND_CONDITIONS_ENTITY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_TERMS_AND_CONDITIONS_ENTITY = '[Master Data] Delete Terms and Conditions Entity';
export const DELETE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS = '[Master Data] Delete Terms and Conditions Entity Success';
export const DELETE_TERMS_AND_CONDITIONS_ENTITY_FAIL = '[Master Data] Delete Terms and Conditions Entity Fail';
export const DeleteTermsAndConditionsEntity = createAction(
  DELETE_TERMS_AND_CONDITIONS_ENTITY,
  props<{ iri: string }>()
);
export const DeleteTermsAndConditionsEntitySuccess = createAction(
  DELETE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteTermsAndConditionsEntityFail = createAction(
  DELETE_TERMS_AND_CONDITIONS_ENTITY_FAIL,
  props<{ response: HttpErrorResponse }>()
);
