import {Pipe, PipeTransform} from '@angular/core';
import {Price} from "../../master-data/models";
import {DecimalPipe} from "@angular/common";


@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(priceObject: Price): string {
    let currency = '';
    switch (priceObject.currency) {
      case 'EUR':
        currency = '€';
        break;
      default:
        currency = priceObject.currency;
        break;
    }
    const numberPipe = new DecimalPipe('de-DE');
    const formattedMoney = numberPipe.transform(priceObject.value, '1.2-2');
    return `${formattedMoney} ${currency}`;
  }

}
