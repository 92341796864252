import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {InvoicePaymentsActions} from '../actions/';
import {InvoicePaymentsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {CustomerStockItemsActions} from "../../../warehouse/store";
import {NotifierService} from "angular-notifier";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class InvoicePaymentsEffects {

  constructor(
    private actions$: Actions,
    private service: InvoicePaymentsService,
    private notifierService: NotifierService,
    private  translateService: TranslateService
  ) {
  }

  CreateInvoicePayment$ = createEffect(() => this.actions$.pipe(
    ofType(InvoicePaymentsActions.CreateInvoicePayment),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createInvoicePayment(payload).pipe(
        map((response) => InvoicePaymentsActions.CreateInvoicePaymentSuccess({response, invoiceIri})),
        catchError(response => of(InvoicePaymentsActions.CreateInvoicePaymentFail({response})))
      );
    })
  ));

  ReadInvoicePayment$ = createEffect(() => this.actions$.pipe(
    ofType(InvoicePaymentsActions.ReadInvoicePayment),
    switchMap(({iri}) => {
      return this.service.readInvoicePayment(iri).pipe(
        map((response => InvoicePaymentsActions.ReadInvoicePaymentSuccess({response}))),
        catchError(response => of(InvoicePaymentsActions.ReadInvoicePaymentFail({response})))
      );
    })
  ));

  ReadInvoicePayments$ = createEffect(() => this.actions$.pipe(
    ofType(InvoicePaymentsActions.ReadInvoicePayments),
    switchMap(({page, params}) => {
      return this.service.readInvoicePayments(page, params).pipe(
        map((response) => InvoicePaymentsActions.ReadInvoicePaymentsSuccess({response})),
        catchError(response => of(InvoicePaymentsActions.ReadInvoicePaymentsFail({response})))
      );
    })
  ));

  UpdateInvoicePayment$ = createEffect(() => this.actions$.pipe(
    ofType(InvoicePaymentsActions.UpdateInvoicePayment),
    switchMap(({iri, payload}) => {
      return this.service.updateInvoicePayment(iri, payload).pipe(
        map((response: fromModuleModels.InvoicePayment) => InvoicePaymentsActions.UpdateInvoicePaymentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(InvoicePaymentsActions.UpdateInvoicePaymentFail({response})))
      );
    })
  ));

  DeleteInvoicePayment$ = createEffect(() => this.actions$.pipe(
    ofType(InvoicePaymentsActions.DeleteInvoicePayment),
    switchMap(({iri}) => {

      return this.service.deleteInvoicePayment(iri).pipe(
        map(() => InvoicePaymentsActions.DeleteInvoicePaymentSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(InvoicePaymentsActions.DeleteInvoicePaymentFail({response})))
      );
    })
  ));

  InvoicePaymentActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      InvoicePaymentsActions.CreateInvoicePaymentSuccess,
      InvoicePaymentsActions.UpdateInvoicePaymentSuccess,
    ),
    map(({ type }) => {
      let message = 'Erfolgreich';
      if(type === InvoicePaymentsActions.CREATE_INVOICE_PAYMENT_SUCCESS) {
        message = 'Die Zahlung wurde erfasst';
        this.translateService.get('invoices.invoices_payment_effects.payment_creation_label').subscribe((res: string) => message = res);
      }
      this.notifierService.show({ type: 'success', message });
    })
  ) ,{ dispatch: false }
  );
}
