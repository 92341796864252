<div class="row">
  <div class="col-auto me-auto">
    <h1>Auftrag #{{ (order$|async)?.orderNumber }} - {{ getCustomerName(order$|async) }}
      <span *ngIf="getCompanyName(order$|async)" class="text-color-darkgrey"> ({{ getCompanyName(order$|async) }}
        )</span>
      <span *ngIf="isVip(order$|async)" class="badge--vip rounded-pill">
        <span class="material-icons inline-icon">done</span>
        <span class="text"> VIP</span>

      </span>
      <span *ngIf="showPartnerLabel(order$|async)" class="mx-3 badge rounded-pill bg-primary small">
          <small>PARTNER</small>
        </span>
      <span *ngIf="showBrokeredToPartnerLabel(order$|async)" class="mx-3 badge rounded-pill bg-primary small">
          <small>Vermittelt an Partner</small>
        </span>
    </h1>
  </div>
  <div class="col-auto cta-col">
    <button (click)="openTransitionLog()" *ngIf="canViewTransitionLog" mat-icon-button>
      <mat-icon matSuffix>rss_feed</mat-icon>
    </button>
    <button (click)="openEmailDialog()" mat-icon-button>
      <mat-icon matSuffix>mail</mat-icon>
    </button>
    <!--<button mat-icon-button (click)="handleShowAnalysisResultForm()">
      <mat-icon matSuffix>analytics</mat-icon>
    </button>-->

    <button (click)="handleShowTrackingHistoryDialog()" mat-icon-button>
      <mat-icon matSuffix>local_shipping</mat-icon>
    </button>
    <button (click)="transferOrderToLead()" mat-icon-button
            [matTooltip]="'orders.order_view.create_lead_from_order' | translate" class="transform-to-lead" *ngIf="(order$|async)?.stateRange === 'Auftrag abgeschlossen'">
      <mat-icon matSuffix *ngIf="converted">note_add</mat-icon>
      <mat-spinner *ngIf="! converted" [diameter]="20"></mat-spinner>
    </button>
    <span *ngIf="canBeCanceled"
          [matTooltip]="'orders.order_view.cancel_complete_order' | translate">
      <button (click)="handleCancelOrder()" [disabled]="!canBeCanceled" mat-icon-button>
        <mat-icon>block</mat-icon>
      </button>
    </span>
    <!--<button mat-icon-button (click)="handleDeleteOrder()" matTooltip="Auftrag löschen">
      <mat-icon color="warn">delete</mat-icon>
    </button>-->

  </div>
</div>
<div class="row">
  <div class="col-auto completed-order" *ngIf="(order$|async)?.stateRange === 'Auftrag abgeschlossen'">
    <span>{{ "orders.order-view.order_completed_notice" | translate }}</span>
  </div>
</div>
<div class="row">
  <div class="col">
    <app-action-box [order$]="order$" inputType="order"></app-action-box>
  </div>

</div>
<div class="row">
  <div class="col-4">
    <div class="row mt-3">
      <div class="col-12">
        <app-order-details [order$]="order$"></app-order-details>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-device-details [order$]="order$"></app-device-details>
      </div>
    </div>
<!--    <div class="row mt-3">-->
<!--      <div class="col-12">-->
<!--        <app-order-ticket-details [order$]="order$"></app-order-ticket-details>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row mt-3">
      <div class="col-12">
        <app-data-recovery-result [dataRecoveryResult]="dataRecoveryResult$|async"></app-data-recovery-result>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-order-invoice-list [order$]="order$"></app-order-invoice-list>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button
          (click)="showUTMDetails(utmModal)"
          [disabled]="!showUTMButton"
          class="btn--analytics"
          mat-icon-button>

          <mat-icon style="color: #afafaf">analytics</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <div class="col-8">
    <div class="row mt-3">
      <div class="col-12">
        <app-order-comments [order$]="order$"></app-order-comments>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-order-special-items [order$]="order$"></app-order-special-items>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <app-order-stock-item-widget *ngIf="canViewWarehouse|async" [order$]="order$"></app-order-stock-item-widget>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row mt-3">
          <div class="col-12">
            <app-pod-status [order$]="order$"></app-pod-status>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <app-ticket-attachments [order$]="order$"></app-ticket-attachments>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <app-ticket-costs-summary *ngIf="!isTechnicianExternal" [order$]="order$"></app-ticket-costs-summary>
          </div>
        </div>

      </div>
      <div class="col-6">
        <div class="row mt-3">
          <div class="col-12">
            <app-binding-order-tool [order$]="order$"></app-binding-order-tool>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #utmModal let-order="order">

  <div mat-dialog-content>
    <div class="dialog--utm">
      <div class="dialog__header">
        <span>Kampagnen-Details</span>
      </div>
      <div class="dialog__content">

        <app-order-tracking-details
          [order]="order$|async"
        ></app-order-tracking-details>
      </div>
    </div>
  </div>
</ng-template>
