import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';

import {DataMediumsActions} from '../actions';
import {DataMediumsService} from '../../services';

@Injectable()
export class DataMediumsEffects {

  constructor(private actions$: Actions, private service: DataMediumsService) {
  }


  ReadDataMediums$ = createEffect(() => this.actions$.pipe(
    ofType(DataMediumsActions.ReadDataMediums),
    mergeMap(({page, params}) => {
      return this.service.readDataMediums(page, params).pipe(
        map((response: any) => DataMediumsActions.ReadDataMediumsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataMediumsActions.ReadDataMediumsFail({response})))
      );
    })
  ));


  ReadDataMediumsChunk$ = createEffect(() => this.actions$.pipe(
    ofType(DataMediumsActions.ReadDataMediumsChunk),
    switchMap(({uri}) => {
      return this.service.readDataMediumsChunk(uri).pipe(
        map((response: any) => DataMediumsActions.ReadDataMediumsChunkSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataMediumsActions.ReadDataMediumsChunkFail({response})))
      );
    })
  ));
}
