import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {AbstractApiResponse} from '../../../shared/models';
import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY = '[Shipping Module] Read Shipment Direction to Data Recovery';
export const READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY_SUCCESS = '[Shipping Module] Read Shipment Direction to Data Recovery Success';
export const READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY_FAIL = '[Shipping Module] Read Shipment Direction to Data Recovery Fail';
export const ReadShipmentDirection = createAction(
  READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY,
  props<{ iri: string }>()
);
export const ReadShipmentDirectionSuccess = createAction(
  READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY_SUCCESS,
  props<{ response: fromModuleModels.ShipmentDirection }>()
);
export const ReadShipmentDirectionFail = createAction(
  READ_SHIPMENT_DIRECTION_TO_DATA_RECOVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY = '[Shipping Module] Read Shipment Directions to Data Recovery';
export const READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY_SUCCESS = '[Shipping Module] Read Shipment Directions to Data Recovery Success';
export const READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY_FAIL = '[Shipping Module] Read Shipment Directions to Data Recovery Fail';
export const ReadShipmentDirections = createAction(
  READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY
);
export const ReadShipmentDirectionsSuccess = createAction(
  READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY_SUCCESS,
  props<{ response: AbstractApiResponse | any }>()
);
export const ReadShipmentDirectionsFail = createAction(
  READ_SHIPMENT_DIRECTIONS_TO_DATA_RECOVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);
