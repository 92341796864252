import { AuthService } from './auth/services/auth.service';

export const allRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.salesExternal,
  AuthService.mapping.technician,
  AuthService.mapping.technicianExternalDRE,
  AuthService.mapping.technicianExternalDHE,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const customerRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.salesExternal,
  AuthService.mapping.technician,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const orderRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.salesExternal,
  AuthService.mapping.technician,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const ticketRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.technician,
  AuthService.mapping.technicianExternal,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const leadRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.salesExternal
];

export const fakturaRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const warehouseRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.sales,
  AuthService.mapping.technician,
  AuthService.mapping.accounting,
  AuthService.mapping.logistics
];
export const salesRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin,
  AuthService.mapping.supervisor,
  AuthService.mapping.partnerManagement,
  AuthService.mapping.sales,
  AuthService.mapping.accounting
];
export const adminRoles = [
  AuthService.mapping.administrator,
  AuthService.mapping.superAdmin
];
