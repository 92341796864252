import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { Currency } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';

@Injectable()
export class CurrenciesService {
  // @SECURITY no roles implemented

  constructor(private apiService: AbstractApiService) {}

  readCurrency(code: string): Observable<Currency> {
    return this.apiService.getObject(`/intl/currencies/${code}`);
  }

  readCurrenciesForProject(): Observable<AbstractApiResponse> {
    return of({
      'hydra:member': [
        {
          '@id': '/api/intl/currencies/EUR',
          '@type': 'Currency',
          code: 'EUR',
          name: 'Euro'
        }
      ]
    });
  }
}
