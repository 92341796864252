import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { take, takeUntil } from 'rxjs/operators';
import { Product } from '../../../master-data/models';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions
} from '../../store';
import {
  CustomerReplacementStockItemsSelectors,
  CustomerStockItemsSelectors,
  DataMediumsSelectors
} from '../../store/selectors';
import { ReplacementStockItem } from '../../models';
import { Actions, ofType } from '@ngrx/effects';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { RouterActions } from '../../../application-state/store/actions';
import { NotifierService } from 'angular-notifier';
import { FormsService } from '../../../shared/services';
import { ShowCustomerHardwareDialogComponent } from '../show-customer-hardware-dialog/show-customer-hardware-dialog.component';

@Component({
  selector: 'app-add-customer-hardware-dialog',
  styleUrls: ['./add-customer-hardware-dialog.component.scss'],
  template: `
    <form [formGroup]="form">
      <div mat-dialog-title style="min-width: 1000px;">
        <div class="row">
          <div class="col-6">
            <h1>
              {{
                form.get('hardwareTyp').value === 'CustomerStockItem'
                  ? 'Kundenhardware'
                  : 'Ersatzdatenträger (Kunde) '
              }}
              einlagern
            </h1>
          </div>
          <div class="col-5">
            <mat-button-toggle-group
              formControlName="hardwareTyp"
              aria-label="Typ des Datenträgers"
              class="mb-2 block smaller"
              (change)="updateSelectedHardwareTyp($event.value)"
            >
              <mat-button-toggle
                value="CustomerStockItem"
                class="bold-if-checked white-if-checked lightgrey-else"
              >
                Kundenhardware
              </mat-button-toggle>
              <mat-button-toggle
                value="CustomerReplacementStockItem"
                class="bold-if-checked white-if-checked lightgrey-else"
                >Ersatzdatenträger
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-1 text-right">
            <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        mat-dialog-content
        style="min-height: 500px; overflow: auto;"
        class="pos-relative"
      >
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="row">
          <div class="col-5">
            <div class="mat-form-field">
              <app-order-select
                [required]="this.form.touched"
                formControlName="order"
              ></app-order-select>
            </div>
          </div>
          <div class="col-4">
            <div class="mat-form-field">
              <app-storage-system-type-select
                [required]="this.form.touched"
                formControlName="storageSystemType"
              ></app-storage-system-type-select>
            </div>
          </div>
          <div class="col-3">
            <div class="mat-form-field">
              <app-storage-location-select
                [required]="this.form.touched"
                formControlName="storageLocation"
              ></app-storage-location-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h1 [class.text-strike]="!form.get('withEnclosure').value">
              Gehäuse
            </h1>
          </div>
          <div class="col-6 text-right">
            <mat-slide-toggle class="" formControlName="withEnclosure">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-customer-data-medium-form
              [formGroup]="fg('enclosure')"
            ></app-customer-data-medium-form>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Datenträger</h1>
          </div>
          <div class="col-7 text-right">
            <button mat-button color="green" (click)="addDataMediaLine()">
              <mat-icon>add</mat-icon>
              Festplatte hinzufügen
            </button>
          </div>
        </div>
        <div formArrayName="dataMediums">
          <div
            class="row"
            *ngFor="
              let subForm of dataMediumFormArray.controls;
              let index = index
            "
          >
            <div class="col-12">
              <app-customer-data-medium-form
                [prependText]="index + 1 + '.'"
                [formGroup]="makeFg(subForm)"
              ></app-customer-data-medium-form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Bemerkung / Zubehör</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Bemerkung</mat-label>
              <textarea
                matInput
                formControlName="description"
                rows="12"
                mat-autosize
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="description"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 ">
            <mat-form-field>
              <mat-label>Zubehör</mat-label>
              <textarea
                matInput
                formControlName="accessories"
                rows="12"
                mat-autosize
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="accessories"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          class="row"
          [hidden]="!form.get('dataMediaOkLogisticsDepartment').enabled"
        >
          <div class="col-12">
            <mat-button-toggle-group
              formControlName="dataMediaOkLogisticsDepartment"
              aria-label="Typ des Datenträgers"
              class="mb-2 block"
              [class.text-color-red]="
                form.get('dataMediaOkLogisticsDepartment')?.errors?.required
              "
            >
              <mat-button-toggle
                value="true"
                class="bold-if-checked green-if-checked lightgrey-else"
              >
                Datenträger OK
              </mat-button-toggle>
              <mat-button-toggle
                value="false"
                class="bold-if-checked red-if-checked lightgrey-else"
                >Datenträger weißt Probleme auf
              </mat-button-toggle>
            </mat-button-toggle-group>
            <div
              class="mat-error"
              *ngIf="form.get('dataMediaOkLogisticsDepartment').errors"
            >
              <app-form-error
                fieldName="dataMediaOkLogisticsDepartment"
                [formGroup]="form"
              ></app-form-error>
            </div>
          </div>
        </div>

        <!--<pre>{{toSaveToStockItem|json}}</pre>-->
        <!--<pre>{{stockItems$|async|json}}</pre>-->
      </div>
      <div mat-dialog-actions class="row">
        <div class="offset-6 col-6 text-right">
          <button mat-button color="green" (click)="submitSave()">
            <mat-icon>save</mat-icon>
            Speichern
          </button>
          <!--          <button mat-button color="green" *ngIf="form.get('hardwareTyp').value==='CustomerStockItem'"-->
          <!--                  (click)="submitSaveAndAddReplacement()">-->
          <!--            <mat-icon>save</mat-icon>-->
          <!--            Speichern und Ersatzdatenträger hinzufügen-->
          <!--          </button>-->
        </div>
      </div>
    </form>
  `
})
export class AddCustomerHardwareDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  product$: Observable<Product>;
  seperatedSerialNumbers = [];
  toSaveToStockItem: ReplacementStockItem = null;

  stockItems$: Observable<Array<ReplacementStockItem>>;
  private actionAfterClose: string;

  constructor(
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    private dialog: MatDialog,
    private actions$: Actions,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<AddCustomerHardwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      hardwareTyp: string;
      shipment: any;
      actionAfterClose: string;
    }
  ) {
    super();
  }

  get dataMediumFormArray(): FormArray {
    return this.form.controls.dataMediums as FormArray;
  }

  get mediaFormTemplate(): FormGroup {
    return this.fb.group({
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control(''),
      storageSystemSize: this.fb.control(0, []),
      storageSystemSerialNumber: this.fb.control('', [])
    });
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(CustomerReplacementStockItemsSelectors.isLoading),
      this.store$.select(CustomerStockItemsSelectors.isLoading),
      this.store$.select(DataMediumsSelectors.isLoading)
    ]);
    this.initForm();
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.actionAfterClose = this.data?.actionAfterClose || 'DO_NOTHING';
  }

  makeFg(formGroup: any): FormGroup {
    return formGroup as FormGroup;
  }

  fg(name): FormGroup {
    return this.form.get(name) as FormGroup;
  }

  updateSelectedHardwareTyp(value: string): void {
    this.form.get('dataMediaOkLogisticsDepartment').enable();
  }

  submitSave(closeAfterSave = true): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      // this.notifierService.show({
      //   type: 'error',
      //   message: 'Formular enthält Fehler. Bitte Prüfen! '
      // });
      // console.log(this.form);
    }

    const rawValues = this.form.getRawValue();
    const payload = {
      ...rawValues,
      dataMediaOkLogisticsDepartment:
        rawValues.dataMediaOkLogisticsDepartment === 'true'
          ? true
          : rawValues.dataMediaOkLogisticsDepartment === 'false'
          ? false
          : null
    };
    if (!payload.withEnclosure) {
      delete payload.enclosure;
    }
    if (this.form.get('hardwareTyp').value === 'CustomerStockItem') {
      this.actions$
        .pipe(
          ofType(CustomerStockItemsActions.CreateCustomerStockItemSuccess),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomerStockItemsActions.CreateCustomerStockItemFail)
            )
          )
        )
        .subscribe(({ response }) => {
          console.log(response);
          this.dialog.open(ShowCustomerHardwareDialogComponent, {
            data: { stockItem: response }
          });
          this.dialogRef.close();
        });

      this.actions$
        .pipe(
          ofType(CustomerStockItemsActions.CreateCustomerStockItemFail),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomerStockItemsActions.CreateCustomerStockItemSuccess)
            )
          )
        )
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });
      this.store$.dispatch(
        CustomerStockItemsActions.CreateCustomerStockItem({ payload })
      );
    } else {
      this.actions$
        .pipe(
          ofType(
            CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess
          ),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail
              )
            )
          )
        )
        .subscribe(({ response }) => {
          this.dialog.open(ShowCustomerHardwareDialogComponent, {
            data: { stockItem: response }
          });
          this.dialogRef.close();
        });

      this.actions$
        .pipe(
          ofType(
            CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail
          ),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess
              )
            )
          )
        )
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });
      this.store$.dispatch(
        CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItem(
          { payload }
        )
      );
    }
  }

  close(reason: string = null): void {
    if (this.actionAfterClose === 'GO_TO_WAREHOUSE') {
      this.store$.dispatch(RouterActions.Go({ path: ['warehouse'] }));
    }
    this.dialogRef.close(reason);
  }

  addDataMediaLine(): void {
    (this.form.controls.dataMediums as FormArray).push(this.mediaFormTemplate);
  }

  private initForm(): void {
    this.form = this.fb.group({
      hardwareTyp: this.fb.control('CustomerStockItem', [Validators.required]),
      order: this.fb.control(null, [Validators.required]),
      storageSystemType: this.fb.control(null, [Validators.required]),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(''),
      accessories: this.fb.control(''),
      dataMediaOkLogisticsDepartment: this.fb.control(null, [
        Validators.required
      ]),
      withEnclosure: this.fb.control(false),
      enclosure: this.mediaFormTemplate,
      dataMediums: this.fb.array([this.mediaFormTemplate])
    });
    Object.values((this.form.get('enclosure') as FormGroup).controls).forEach(
      e => {
        e.disable();
      }
    );

    this.form
      .get('withEnclosure')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(withEnclosure => {
        if (withEnclosure) {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.enable();
          });
        } else {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.disable();
          });
        }
      });

    if (this.data?.hardwareTyp) {
      this.form.patchValue({ hardwareTyp: this.data.hardwareTyp });
      this.updateSelectedHardwareTyp(this.data.hardwareTyp);
    }
    if (this.data?.shipment) {
      this.form.patchValue(this.data.shipment);
    }
  }
}
