import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';

// Note: this is not an actual POST but a PUT; this action is used with intend to dispatch a transition afterwards

export const CREATE_PROOF_OF_DESTRUCTION = '[Orders Module] Create Proof of Destruction';
export const CREATE_PROOF_OF_DESTRUCTION_SUCCESS = '[Orders Module] Create Proof of Destruction Success';
export const CREATE_PROOF_OF_DESTRUCTION_FAIL = '[Orders Module] Create Proof of Destruction Fail';

export const CreateProofOfDestruction = createAction(
  CREATE_PROOF_OF_DESTRUCTION,
  props<{ iri: string, payload: fromOrdersModuleModels.ProofOfDestruction }>()
);
export const CreateProofOfDestructionSuccess = createAction(
  CREATE_PROOF_OF_DESTRUCTION_SUCCESS,
  props<{ response: fromOrdersModuleModels.ProofOfDestruction }>()
);
export const CreateProofOfDestructionFail = createAction(
  CREATE_PROOF_OF_DESTRUCTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PROOF_OF_DESTRUCTION = '[Orders Module] Read Proof of Destruction';
export const READ_PROOF_OF_DESTRUCTION_SUCCESS = '[Orders Module] Read Proof of Destruction Success';
export const READ_PROOF_OF_DESTRUCTION_FAIL = '[Orders Module] Read Proof of Destruction Fail';

export const ReadProofOfDestruction = createAction(
  READ_PROOF_OF_DESTRUCTION,
  props<{ iri: string }>()
);

export const ReadProofOfDestructionSuccess = createAction(
  READ_PROOF_OF_DESTRUCTION_SUCCESS,
  props<{ response: fromOrdersModuleModels.ProofOfDestruction }>()
);

export const ReadProofOfDestructionFail = createAction(
  READ_PROOF_OF_DESTRUCTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_PROOFS_OF_DESTRUCTION = '[Orders Module] Read Proofs of Destruction';
export const READ_PROOFS_OF_DESTRUCTION_SUCCESS = '[Orders Module] Read Proofs of Destruction Success';
export const READ_PROOFS_OF_DESTRUCTION_FAIL = '[Orders Module] Read Proofs of Destruction Fail';

export const ReadProofsOfDestruction = createAction(
  READ_PROOFS_OF_DESTRUCTION,
);

export const ReadProofsOfDestructionSuccess = createAction(
  READ_PROOFS_OF_DESTRUCTION_SUCCESS,
  props<{ response: any }>()
);

export const ReadProofsOfDestructionFail = createAction(
  READ_PROOFS_OF_DESTRUCTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PROOF_OF_DESTRUCTION = '[Orders Module] Update Proof of Destruction';
export const UPDATE_PROOF_OF_DESTRUCTION_SUCCESS = '[Orders Module] Update Proof of Destruction Success';
export const UPDATE_PROOF_OF_DESTRUCTION_FAIL = '[Orders Module] Update Proof of Destruction Fail';

export const UpdateProofOfDestruction = createAction(
  UPDATE_PROOF_OF_DESTRUCTION,
  props<{ iri: string, payload: fromOrdersModuleModels.ProofOfDestruction }>()
);

export const UpdateProofOfDestructionSuccess = createAction(
  UPDATE_PROOF_OF_DESTRUCTION_SUCCESS,
  props<{ response: fromOrdersModuleModels.ProofOfDestruction }>()
);

export const UpdateProofOfDestructionFail = createAction(
  UPDATE_PROOF_OF_DESTRUCTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_PROOF_OF_DESTRUCTION = '[Orders Module] Delete Proof of Destruction';
export const DELETE_PROOF_OF_DESTRUCTION_SUCCESS = '[Orders Module] Delete Proof of Destruction Success';
export const DELETE_PROOF_OF_DESTRUCTION_FAIL = '[Orders Module] Delete Proof of Destruction Fail';

export const DeleteProofOfDestruction = createAction(
  DELETE_PROOF_OF_DESTRUCTION,
  props<{ iri: string }>()
);

export const DeleteProofOfDestructionSuccess = createAction(
  DELETE_PROOF_OF_DESTRUCTION_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteProofOfDestructionFail = createAction(
  DELETE_PROOF_OF_DESTRUCTION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset State

export const RESET_PROOFS_OF_DESTRUCTION_STATE = '[Orders Module] Reset Proofs Of Destruction State';

export const ResetProofsOfDestructionState = createAction(
  RESET_PROOFS_OF_DESTRUCTION_STATE,
);
