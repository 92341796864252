import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { Shipment } from '../../../shipping/models';
import { ShipmentsService } from '../../../shipping/services/shipments.service';
import { MatDialog } from '@angular/material/dialog';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { extractIri } from '../../utilities/objects.utility';
import { ShowShipmentDialogComponent } from '../../components/show-shipment-dialog/show-shipment-dialog.component';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-shipment-received-dr-ext',
  styleUrls: ['./action-box-shipment-received-dr-ext.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row">
          <div class=" col-auto my-2 align-items-center">
            <div class="row " *ngIf="canMakeTransition">
              <div class=" col-auto header">Paket eingegangen?</div>
              <div class="sub-header col-auto">
                Ist das Paket mit dem Datenträger/den Datenträgern bei Ihnen
                eingegangen?
              </div>
            </div>
            <div class="row " *ngIf="!canMakeTransition">
              <div class=" col-auto header">Eingang bei Dienstleister</div>
              <div class="sub-header col-auto">
                Warte auf Bestätigung des Paketeingangs
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3 btn-wider"
              mat-button
              color="green"
              (click)="openShipment()"
              [disabled]="(isLoadingShipments$ | async) || !lastShipment"
            >
              <span>Paket mit Datenträger/n öffnen</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxShipmentReceivedDrExtComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  isLoadingShipments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  lastShipment: Shipment = null;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private authService: AuthService,
    private shipmentsService: ShipmentsService,
    private fb: FormBuilder,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.loadShipment();
      });
  }

  loadShipment(): void {
    this.isLoadingShipments$.next(true);

    const params: any = {
      pagination: true,
      'order[createdAt]': 'desc',
      'orders.uuid': getUuidFromIri(extractIri(this.order))
    };

    this.shipmentsService.readShipments(-1, params).subscribe(
      data => {
        this.lastShipment = data['hydra:member'][0];
        this.isLoadingShipments$.next(false);
      },
      error => {
        console.log(error);
        this.isLoadingShipments$.next(false);
      }
    );
  }

  openShipment(): void {
    this.dialog.open(ShowShipmentDialogComponent, {
      panelClass: 'width-95',
      data: { shipment: extractIri(this.lastShipment) }
    });
  }
}
