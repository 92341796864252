import { createReducer, on } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import { FollowUpReasonAction } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FollowUpReason } from '../../models/follow-up-reason.interface';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: FollowUpReason };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    FollowUpReasonAction.ReadFollowUpReason,
    FollowUpReasonAction.ReadFollowUpReasons,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(FollowUpReasonAction.ReadFollowUpReasonSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(FollowUpReasonAction.ReadFollowUpReasonsSuccess, (state, { response }) => {
    return {
      ...state,
      entities: keyBy(response['hydra:member'], '@id'),
      loading: false,
      loaded: true
    };
  }),
  on(
    FollowUpReasonAction.ReadFollowUpReasonFail,
    FollowUpReasonAction.ReadFollowUpReasonsFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: true,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
