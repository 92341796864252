import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerContactTypesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [id: string]: fromModuleModels.CustomerContactType };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    CustomerContactTypesActions.LoadCustomerContactType,
    CustomerContactTypesActions.ReadCustomerContactTypes,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    CustomerContactTypesActions.ReadCustomerContactTypesSuccess,
    (state, { response }) => {
      const contactTypes = response['hydra:member'];
      const entities = contactTypes.reduce(
        (
          entities: { [id: string]: fromModuleModels.CustomerContactType },
          contactType
        ) => {
          return {
            ...entities,
            [contactType['@id']]: contactType
          };
        },
        { ...state.entities }
      );

      return {
        entities,
        errors: {},
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    CustomerContactTypesActions.LoadCustomerContactTypeFail,
    CustomerContactTypesActions.ReadCustomerContactTypesFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
