import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { Department } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DepartmentsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createDepartment(payload: Department): Observable<Department> {
    return this.rolesService.userHasRoleFilter<Department>(
      'ROLE_MWS_DEPARTMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/departments`, payload)
    );
  }

  getDepartment(iri: string): Observable<Department> {
    return this.rolesService.userHasRoleFilter<Department>(
      'ROLE_MWS_DEPARTMENT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getDepartments(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/departments`)
    );
  }

  updateDepartment({ iri, payload }): Observable<Department> {
    return this.rolesService.userHasRoleFilter<Department>(
      'ROLE_MWS_DEPARTMENT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteDepartment(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
