import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { DepartmentLogo } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DepartmentLogosService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createDepartmentLogo(payload: {
    file: File;
    uuid?: string;
  }): Observable<AbstractApiResponse | any> {
    // Manually create FormDate for proper image processing
    const formData: FormData = new FormData();
    console.log(payload.file);
    formData.append('file', payload.file, payload.file.name);

    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_LOGO_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/department_logos`, formData)
    );
  }

  getDepartmentLogoInformation(iri: string): Observable<DepartmentLogo> {
    return this.rolesService.userHasRoleFilter<DepartmentLogo>(
      'ROLE_MWS_DEPARTMENT_LOGO_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getDepartmentLogoAsImage(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_LOGO_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getImage(iri + '?disposition=inline', true)
    );
  }

  getDepartmentLogos(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DEPARTMENT_LOGO_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/department_logos`)
    );
  }

  // No real usage existing, Backend do not have this route

  // updateDepartmentLogo({iri, payload}): Observable<DepartmentLogo> {
  //   return  this.apiService.updateObject(iri, payload, true);
  // }

  deleteDepartmentLogo(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_DEPARTMENT_LOGO_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
