import {createAction, createSelector} from '@ngrx/store';

import {ORDER_STATES_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectOrderStatesState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[ORDER_STATES_FEATURE_KEY]
);

export const selectOrderStatesEntities = createSelector(
  selectOrderStatesState,
  (state) => state.entities
);

export const selectOrderStates = createSelector(
  selectOrderStatesEntities,
  (entities) => Object.values(entities)
);

export const selectOrderStatesByName = createSelector(
  selectOrderStates,
  (entities, names) => entities ? entities.filter(e => names.indexOf(e.id) > -1) : []
);

export const selectCurrentProofOfDestruction = createSelector(
  selectOrderStatesState,
  state => state.current
);

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectOrderStatesErrors = createSelector(
  selectOrderStatesState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectOrderStatesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectOrderStatesState,
  (state) => state.loaded
);
