import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as fromOrdersModuleModels from '../../../orders/models';

@Component({
  selector: 'customer-order-list',
  styleUrls: ['customer-order-list.component.scss'],
  template: `

    <div class="card">
      <div class="card__heading">
        <span>Aufträge für diesen Kunden</span>
      </div>

      <div class="card__content">
        <div class="order-list__outer">
          <div class="order-list__wrapper wrap">
            <div class="order-list grid">

              <div class="column-14">

                <div *ngIf="!items?.length; else table" style="padding: 24px;">
                  Keine Datensätze vorhanden
                </div>

                <ng-template #table>

                  <table *ngIf="items?.length" class="table" mat-table [dataSource]="items">

                    <!-- Order Number Column -->
                    <ng-container matColumnDef="orderNumber">
                      <th mat-header-cell *matHeaderCellDef>Nr.</th>
                      <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="state">
                      <th mat-header-cell *matHeaderCellDef>Status</th>
                      <td mat-cell *matCellDef="let element">{{ element.stateRange }}</td>
                    </ng-container>

                    <!-- createdAt Column -->
                    <ng-container matColumnDef="createdAt">
                      <th mat-header-cell *matHeaderCellDef>Erstellt</th>
                      <td mat-cell *matCellDef="let element">{{ element.createdAt | date:'dd.MM.Y - HH:mm' }} Uhr</td>
                    </ng-container>

                    <!-- updatedAt Column -->
                    <ng-container matColumnDef="updatedAt">
                      <th mat-header-cell *matHeaderCellDef>Letzte Aktivität</th>
                      <td mat-cell *matCellDef="let element">{{ element.updatedAt | date:'dd.MM.Y - HH:mm' }} Uhr</td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">

                        <button mat-icon-button (click)="handleRequestViewOrder(element['@id'])">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CustomerOrderListComponent implements OnInit {

  @Input() items: Array<fromOrdersModuleModels.Order>;

  @Output()
  requestFetchOrders: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestViewOrder: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['orderNumber', 'state', 'createdAt', 'updatedAt', 'actions'];

  constructor() {
  }

  ngOnInit(): void {
    this.requestFetchOrders.emit();
  }

  handleRequestViewOrder(orderIri: string): void {
    this.requestViewOrder.emit(orderIri);
  }
}
