import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'tax-form',
  styleUrls: ['tax-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Steuersatz bearbeiten' : 'Neuen Steuersatz anlegen' }}</span>
      </div>

      <div class="card__content p-a--24" [formGroup]="tf">

        <div class="grid">
          <mat-form-field class="column-11">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" required
                   [readonly]="!!presets$.getValue()"
                   matTooltip="Dieses Feld ist nur bei neu angelegten Datensätzen bearbeitbar."
                   [matTooltipDisabled]="!!!presets$.getValue()">
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>

          <div class="column-3">
            <mat-slide-toggle formControlName="isActive">Aktiv</mat-slide-toggle>
          </div>

          <mat-form-field class="column-9">
            <mat-label>Wert</mat-label>
            <input type="text" matInput formControlName="value" required
                   currencyMask [options]="{ prefix: '', suffix: '%' }"
                   [readonly]="!!presets$.getValue()"
                   matTooltip="Dieses Feld ist nur bei neu angelegten Datensätzen bearbeitbar."
                   [matTooltipDisabled]="!!!presets$.getValue()">
            <mat-hint align="start" *ngIf="errors?.value">{{ errors.value.message }}</mat-hint>
          </mat-form-field>

          <div class="column-5">
            <mat-slide-toggle formControlName="isDefault">Standard-Steuersatz</mat-slide-toggle>
          </div>

          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || tf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="tf.invalid || tf.pristine" (click)="handleSubmit()" mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
  `
})
export class TaxFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<any>;

  @Output() requestCreateItem: EventEmitter<{ payload: fromModuleModels.Tax, entity: string }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.Tax,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  tf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(presets => {
      this.initForm();
      this.fs.patchForm(this.tf, presets);
      this.tf.markAsUntouched();
    });
  }

  initForm() {
    this.tf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      value: this.fb.control(null, [Validators.required]),
      isActive: this.fb.control(false),
      isDefault: this.fb.control(false)
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromModuleModels.Tax = {...this.tf.value};

    payload.value = payload.value.toString();

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Tax'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Tax'});
  }
}
