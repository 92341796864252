import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_CONTACT = '[Customers Module] Create Customer Contact ';
export const CREATE_CUSTOMER_CONTACT_SUCCESS = '[Customers Module] Create Customer Contact Success';
export const CREATE_CUSTOMER_CONTACT_FAIL = '[Customers Module] Create Customer Contact Fail';

export const CreateCustomerContact = createAction(
  CREATE_CUSTOMER_CONTACT,
  props<{ customer: fromModuleModels.Customer, payload: fromModuleModels.CustomerContact }>()
);

export const CreateCustomerContactSuccess = createAction(
  CREATE_CUSTOMER_CONTACT_SUCCESS,
  props<{ customer: fromModuleModels.Customer, response: HttpResponse<any> | any }>()
);

export const CreateCustomerContactFail = createAction(
  CREATE_CUSTOMER_CONTACT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read (New)

export const READ_CUSTOMER_CONTACTS = '[Customers Module] Read Customer Contacts';
export const READ_CUSTOMER_CONTACTS_SUCCESS = '[Customers Module] Read Customer Contacts Success';
export const READ_CUSTOMER_CONTACTS_FAIL = '[Customers Module] Read Customer Contacts Fail';

export const ReadCustomerContacts = createAction(
  READ_CUSTOMER_CONTACTS,
  props<{ page: number, params?: { [p: string]: any } }>()
);

export const ReadCustomerContactsSuccess = createAction(
  READ_CUSTOMER_CONTACTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerContactsFail = createAction(
  READ_CUSTOMER_CONTACTS_FAIL,
  props<{ response: any }>()
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read (1)

export const READ_CUSTOMER_CONTACT = '[Customers Module] Read Customer Contact';
export const READ_CUSTOMER_CONTACT_SUCCESS = '[Customers Module] Read Customer Contact Success';
export const READ_CUSTOMER_CONTACT_FAIL = '[Customers Module] Read Customer Contact Fail';

export const ReadCustomerContact = createAction(
  READ_CUSTOMER_CONTACT,
  props<{ iri: string }>()
);

export const ReadCustomerContactSuccess = createAction(
  READ_CUSTOMER_CONTACT_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerContactFail = createAction(
  READ_CUSTOMER_CONTACT_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_CONTACT = '[Customers Module] Update Customer Contact';
export const UPDATE_CUSTOMER_CONTACT_SUCCESS = '[Customers Module] Update Customer Contact Success';
export const UPDATE_CUSTOMER_CONTACT_FAIL = '[Customers Module] Update Customer Contact Fail';

export const UpdateCustomerContact = createAction(
  UPDATE_CUSTOMER_CONTACT,
  props<{ iri: string, payload: fromModuleModels.CustomerContact }>()
);

export const UpdateCustomerContactSuccess = createAction(
  UPDATE_CUSTOMER_CONTACT_SUCCESS,
  props<{ response: fromModuleModels.CustomerContact }>()
);

export const UpdateCustomerContactFail = createAction(
  UPDATE_CUSTOMER_CONTACT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_CONTACT = '[Customers Module] Delete Customer Contact';
export const DELETE_CUSTOMER_CONTACT_SUCCESS = '[Customers Module] Delete Customer Contact Success';
export const DELETE_CUSTOMER_CONTACT_FAIL = '[Customers Module] Delete Customer Contact Fail';

export const DeleteCustomerContact = createAction(
  DELETE_CUSTOMER_CONTACT,
  props<{ iri: string }>()
);

export const DeleteCustomerContactSuccess = createAction(
  DELETE_CUSTOMER_CONTACT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerContactFail = createAction(
  DELETE_CUSTOMER_CONTACT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
