import {createSelector} from '@ngrx/store';

import {INVOICE_PAYMENTS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectInvoicePaymentsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[INVOICE_PAYMENTS_FEATURE_KEY]
);

export const selectInvoicePaymentsEntities = createSelector(
  selectInvoicePaymentsState,
  (state) => state.entities
);

export const selectInvoicePayments = createSelector(
  selectInvoicePaymentsEntities,
  (payments) => Object.keys(payments).map(iri => payments[iri])
);

export const selectInvoicePaymentByIndex = createSelector(
  selectInvoicePaymentsEntities,
  (customers, props) => customers[props.iri]
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectInvoicePaymentsState,
  state => state.totalItems
);
export const selectInvoicePaymentPagination = createSelector(
  selectInvoicePaymentsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectInvoicePaymentPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectInvoicePaymentPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectInvoicePaymentPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectInvoicePaymentPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectInvoicePaymentPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectInvoicePaymentsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectInvoicePaymentsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectInvoicePaymentsState,
  (state) => state.errors
);
