import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {
  ReplacementDataMediumsActions,
  ReplacementStockItemsActions
} from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.ReplacementStockItem };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    ReplacementStockItemsActions.CreateReplacementStockItem,
    ReplacementStockItemsActions.ReadReplacementStockItem,
    ReplacementStockItemsActions.UpdateReplacementStockItem,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    ReplacementStockItemsActions.CreateReplacementStockItemSuccess,
    (state, { response }) => {
      const entities = {
        [response['@id']]: response,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    ReplacementDataMediumsActions.CreateReplacementDataMediumSuccess,
    (state, { response }) => {
      let updateEntity = state.entities[response.stockItem];
      if (updateEntity) {
        updateEntity = {
          ...updateEntity,
          dataMediums: [...updateEntity.dataMediums, response]
        };
      }

      return {
        ...state,
        entities: mergeEntities(updateEntity, null, state.entities),
        loading: false
      };
    }
  ),
  on(
    ReplacementStockItemsActions.ReadReplacementStockItemSuccess,
    ReplacementStockItemsActions.UpdateReplacementStockItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    ReplacementStockItemsActions.CreateReplacementStockItemFail,
    ReplacementStockItemsActions.ReadReplacementStockItemFail,
    ReplacementStockItemsActions.UpdateReplacementStockItemFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(
    ReplacementStockItemsActions.DeleteReplacementStockItemSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
