import {createSelector} from '@ngrx/store';

import {CUSTOMER_TYPES, selectCustomersModuleState} from '../reducers';

export const selectCustomerTypesState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_TYPES]
);

export const selectCustomerTypesEntities = createSelector(
  selectCustomerTypesState,
  (state) => state.entities
);

export const selectCustomerTypes = createSelector(
  selectCustomerTypesEntities,
  (statuses) => Object.keys(statuses).map(id => statuses[id])
);

export const isLoaded = createSelector(
  selectCustomerTypesState,
  (state) => state.loaded
);
export const isLoading = createSelector(
  selectCustomerTypesState,
  (state) => state.loading
);
