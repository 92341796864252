import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_INVOICE_PAYMENT = '[Invoices Module] Create Invoice Payment';
export const CREATE_INVOICE_PAYMENT_FAIL = '[Invoices Module] Create Invoice Payment Fail';
export const CREATE_INVOICE_PAYMENT_SUCCESS = '[Invoices Module] Create Invoice Payment Success';

export const CreateInvoicePayment = createAction(
  CREATE_INVOICE_PAYMENT,
  props<{ payload: fromInvoicesModuleModels.InvoicePayment, invoiceIri: string }>()
);

export const CreateInvoicePaymentSuccess = createAction(
  CREATE_INVOICE_PAYMENT_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoicePayment, invoiceIri: string }>()
);

export const CreateInvoicePaymentFail = createAction(
  CREATE_INVOICE_PAYMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_INVOICE_PAYMENT = '[Invoices Module] Read Invoice Payment';
export const READ_INVOICE_PAYMENT_SUCCESS = '[Invoices Module] Read Invoice Payment Success';
export const READ_INVOICE_PAYMENT_FAIL = '[Invoices Module] Read Invoice Payment Fail';

export const ReadInvoicePayment = createAction(
  READ_INVOICE_PAYMENT,
  props<{ iri: string }>()
);

export const ReadInvoicePaymentSuccess = createAction(
  READ_INVOICE_PAYMENT_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoicePayment | any }>()
);

export const ReadInvoicePaymentFail = createAction(
  READ_INVOICE_PAYMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_INVOICE_PAYMENTS = '[Invoices Module] Read Invoice Payments';
export const READ_INVOICE_PAYMENTS_SUCCESS = '[Invoices Module] Read Invoice Payments Success';
export const READ_INVOICE_PAYMENTS_FAIL = '[Invoices Module] Read Invoice Payments Fail';

export const ReadInvoicePayments = createAction(
  READ_INVOICE_PAYMENTS,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadInvoicePaymentsSuccess = createAction(
  READ_INVOICE_PAYMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadInvoicePaymentsFail = createAction(
  READ_INVOICE_PAYMENTS_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_INVOICE_PAYMENT = '[Invoices Module] Update Invoice Payment';
export const UPDATE_INVOICE_PAYMENT_SUCCESS = '[Invoices Module] Update Invoice Payment Success';
export const UPDATE_INVOICE_PAYMENT_FAIL = '[Invoices Module] Update Invoice Payment Fail';

export const UpdateInvoicePayment = createAction(
  UPDATE_INVOICE_PAYMENT,
  props<{ iri: string, payload: fromInvoicesModuleModels.InvoicePayment | any }>()
);

export const UpdateInvoicePaymentSuccess = createAction(
  UPDATE_INVOICE_PAYMENT_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoicePayment }>()
);

export const UpdateInvoicePaymentFail = createAction(
  UPDATE_INVOICE_PAYMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_INVOICE_PAYMENT = '[Invoices Module] Delete Invoice Payment';
export const DELETE_INVOICE_PAYMENT_SUCCESS = '[Invoices Module] Delete Invoice Payment Success';
export const DELETE_INVOICE_PAYMENT_FAIL = '[Invoices Module] Delete Invoice Payment Fail';

export const DeleteInvoicePayment = createAction(
  DELETE_INVOICE_PAYMENT,
  props<{ iri: string }>()
);

export const DeleteInvoicePaymentSuccess = createAction(
  DELETE_INVOICE_PAYMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteInvoicePaymentFail = createAction(
  DELETE_INVOICE_PAYMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
