import {AfterViewInit, Component, Input, Output, EventEmitter, ViewChild, OnDestroy, OnInit} from '@angular/core';

import {Observable, BehaviorSubject, Subject, of} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import * as moment from 'moment/moment';

import {DynamicFormComponent} from '../../../shared/containers/dynamic-form/dynamic-form.component';
import {IDynamicFormField} from '../../../shared/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {CommissionCredit, CorrectionInvoice} from "../../models";
import {Department} from "../../../master-data/models";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {SearchAddonDataService} from "../../services/search-addon-data.service";
import {removeEmptyFormElements} from "../../../shared/utilities/forms.utility";
import {DepartmentsSelectors} from "../../../master-data/store/selectors";
import {loadIfNotLoaded} from "../../../shared/utilities/observable.utility";
import {DepartmentsActions} from "../../../master-data/store";
import {CustomersSelectors} from "../../../customers/store/selectors";
import {CustomersActions} from "../../../customers/store";
import {Customer} from "../../../customers/models";
import {extractIri} from "../../../shared/utilities/objects.utility";
import {commissionCreditStateMapDE} from "../../helper/invoice-state-maps";
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-commission-credits-filter',
  styleUrls: ['commission-credits-filter-form.component.scss'],
  template: `

    <div [formGroup]="form" class="form">
      <div class="row">
        <div class="col-9">
          <div class="row">
            <div class="col-3">
              <app-customer-select
                label="Vermittler"
                (selectCustomer)="updateBroker($event)"
              ></app-customer-select>

            </div>
            <div class="col-3">
              <ng-select
                placeholder="Bereich"
                [items]="departments$ | async"
                [loading]="departmentIsLoading$ | async"
                bindValue="@id"
                bindLabel="name"
                [markFirst]="false"
                [clearable]="true"
                formControlName="issuer"
              ></ng-select>
            </div>
            <div class="col-3">
              <ng-select
                placeholder="Status"
                [items]="states"
                bindValue="value"
                bindLabel="label"
                [markFirst]="false"
                [clearable]="true"
                formControlName="state"
              ></ng-select>
            </div>
            <div class="col-2 pt-4">
              <a (click)="resetFilter()" class="link-primary cursor-pointer">Filter zurücksetzen</a>
            </div>

          </div>
        </div>

        <div class="col-3">
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label>Gutschrift vom</mat-label>
                <input type="date" matInput formControlName="createdAtMin"/>
                <mat-error>
                  <app-form-error
                    fieldName="createdAtMin"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Gutschrift bis</mat-label>
                <input type="date" matInput formControlName="createdAtMax"/>
                <mat-error>
                  <app-form-error
                    fieldName="createdAtMax"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CommissionCreditsFilterFormComponent implements OnInit, OnDestroy {
  @Output() updateFilter: EventEmitter<{ [key: string]: string; }> = new EventEmitter<{ [key: string]: string }>();
  @Input() items: Array<CommissionCredit>;

  departments$: Observable<Array<Department>>;
  departmentIsLoading$: Observable<boolean>;

  brokers$: Observable<Array<Customer>>;
  brokersIsLoading$: Observable<boolean>;

  states = Object.keys(commissionCreditStateMapDE).map(e => ({label: commissionCreditStateMapDE[e], value: e}));

  form: FormGroup;
  storedFilters?: any;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private searchAddonDataService: SearchAddonDataService,
    public localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    this.loadDepartments();
    this.form = this.fb.group({
      broker: this.fb.control(null),
      issuer: this.fb.control(null),
      state: this.fb.control(null),
      createdAtMin: this.fb.control(null),
      createdAtMax: this.fb.control(null)
    });
    this.form.valueChanges.subscribe(value => {
      this.updateFilter.emit(removeEmptyFormElements(value));
      this.localStorageService.overwriteObjectByKey('filters', 'commission_credits_invoices', value);
    });

    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'cancellation_invoices');
    if(this.storedFilters) {
      this.updateFilter.emit(this.storedFilters);
      this.form.patchValue(this.storedFilters);
    }
  }

  resetFilter(): void {
    this.form.patchValue({
      broker: null,
      issuer: null,
      state: null,
      createdAtMin: null,
      createdAtMax: null,
    });
  }

  updateBroker(broker: Customer): void {
    this.form.patchValue({broker: extractIri(broker)});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private loadDepartments(): void {
    this.departments$ = this.store$.select(DepartmentsSelectors.selectDepartments);
    this.departmentIsLoading$ = this.store$.select(DepartmentsSelectors.isLoading);
    loadIfNotLoaded(this.store$, DepartmentsSelectors.isLoaded, DepartmentsActions.ReadDepartments()
    );
  }

}
