import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import {ApplicationState} from '../../../application-state/store';
import {ErrorsObject} from '../../utilities/error-utility.utility';
import {OrdersActions} from '../../../orders/store';
import {OrdersSelectors} from '../../../orders/store/selectors';

@Component({
  selector: 'analysis-result-box',
  styleUrls: ['analysis-result-box.component.scss'],
  template: `

    <div class="grid">

      <div class="grid column-14">

        <div class="mat-form-field column-3 off-8">
          <ng-select
            [items]="analysisResultCategories$ | async"
            [searchable]="false"
            [markFirst]="false"
            #templateCategorySelection
            (change)="handleUpdateAnalysisResultCategory($event); templateSelection.writeValue(null)"
            bindValue="@id"
            placeholder="Vorlagen-Kategorie"
            bindLabel="name"
          ></ng-select>
        </div>

        <div class="mat-form-field column-3">
          <ng-select
            [items]="analysisResultOptions$.getValue()"
            [searchable]="false"
            [markFirst]="false"
            [readonly]="!templateCategorySelection.hasValue"
            bindValue="@id"
            notFoundText="Diese Kategorie hat keine Vorlagen"
            #templateSelection
            (change)="handleSetTemplateText($event)"
            placeholder="Vorlage wählen"
            bindLabel="name"
          ></ng-select>
        </div>
      </div>

      <div class="column-14" [formGroup]="arf">
        <mat-form-field>
          <mat-placeholder>Analyseergebnis für Kunden</mat-placeholder>
          <textarea matInput formControlName="analysisResult" rows="10"></textarea>
          <mat-hint align="start" *ngIf="errors?.analysisResult">{{ errors.analysisResult.message }}</mat-hint>
        </mat-form-field>
      </div>


      <div class="column-14">
        <div class="m-ta--2">
          <button
            mat-flat-button color="green"
            type="button"
            [disabled]="arf.invalid"
            (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>Analyseergebnis speichern</span>
          </button>
        </div>
      </div>
    </div>

    <!--<pre>{{ analysisResultCategories$ | async | json }}</pre>-->
    <!--<pre>{{ analysisResultTemplates$ | async | json }}</pre>-->
  `
})
export class AnalysisResultBoxComponent implements OnInit, OnDestroy {

  @Input() analysisResultCategories$: Observable<Array<fromMasterDataModuleModels.AnalysisResultCategory>>;
  @Input() analysisResultTemplates$: Observable<Array<fromMasterDataModuleModels.AnalysisResult>>;

  @Output() requestUpdateAnalysisResult: EventEmitter<fromOrdersModuleModels.Order> = new EventEmitter();

  arf: FormGroup;
  analysisResultOptions$: BehaviorSubject<Array<fromMasterDataModuleModels.AnalysisResult>> = new BehaviorSubject([]);
  analysisResultTemplates: Array<fromMasterDataModuleModels.AnalysisResult>;
  errors: ErrorsObject = {};
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  ngOnInit(): void {

    this.initForm();
    this.initSubscriptions();
  }

  initForm(): void {
    this.arf = this.fb.group({
      analysisResult: this.fb.control(''),
    });
  }

  handleUpdateAnalysisResultCategory(option: fromMasterDataModuleModels.AnalysisResult): void {
    this.analysisResultOptions$.next(this.analysisResultTemplates.filter(template => template.category === option['@id']));
  }

  handleSetTemplateText(option: fromMasterDataModuleModels.AnalysisResult): void {

    this.arf.get('analysisResult').setValue(option.content);
  }

  initSubscriptions(): void {

    this.analysisResultTemplates$.pipe(
      takeUntil(this.onDestroy$),
      filter(items => !!items.length)
    ).subscribe((templates) => {
      this.analysisResultTemplates = templates;
    });

    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderAnalysisResultFromActionBoxSuccess),
      takeUntil(this.onDestroy$),
    ).subscribe(({type}) => {
      this.initForm();
    });
  }

  handleSubmit(): void {
    this.requestUpdateAnalysisResult.emit(this.arf.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
