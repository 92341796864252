import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, flatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromModuleServices from '../../services';
import { LeadsActions } from '../actions';
import { RouterActions } from '../../../application-state/store/actions';
import { NotifierService } from 'angular-notifier';
import {
  extractIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';

@Injectable()
export class LeadsEffects {
  CreateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.CreateLead),
      switchMap(({ payload }) => {
        return this.ls.createLead(payload).pipe(
          map(response => LeadsActions.CreateLeadSuccess({ response })),
          catchError(response => of(LeadsActions.CreateLeadFail({ response })))
        );
      })
    )
  );
  ReadLeads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.ReadLeads),
      switchMap(() => {
        return this.ls.readLeads(-1).pipe(
          map(response => LeadsActions.ReadLeadsSuccess({ response })),
          catchError(response => of(LeadsActions.ReadLeadsFail({ response })))
        );
      })
    )
  );
  ReadLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.ReadLead),
      switchMap(({ iri, id }) => {
        const obs = id ? this.ls.getLeadById(id) : this.ls.getLeadByIri(iri);
        return obs.pipe(
          map(response => LeadsActions.ReadLeadSuccess({ response })),
          catchError(response => of(LeadsActions.ReadLeadFail({ response })))
        );
      })
    )
  );
  UpdateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.UpdateLead),
      mergeMap(payload => {
        return this.ls.updateLead(payload).pipe(
          map(response => LeadsActions.UpdateLeadSuccess({ response })),
          catchError(response => of(LeadsActions.UpdateLeadFail({ response })))
        );
      })
    )
  );
  UpdateLeadSilently$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.UpdateLeadSilently),
      mergeMap(payload => {
        return this.ls.updateLead(payload).pipe(
          map(response => LeadsActions.UpdateLeadSilentlySuccess({ response })),
          catchError(response =>
            of(LeadsActions.UpdateLeadSilentlyFail({ response }))
          )
        );
      })
    )
  );
  MailLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.MailLead),
      switchMap(({ iri, payload }) => {
        return this.ls.sendMail(iri, payload).pipe(
          map(response => LeadsActions.MailLeadSuccess({ response })),
          catchError(response => of(LeadsActions.MailLeadFail({ response })))
        );
      })
    )
  );
  UpdateLeadColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.UpdateLeadColumns),
      switchMap(payload => {
        return this.ls.updateLeadColumnsAndRanks(payload).pipe(
          map(response => LeadsActions.UpdateLeadColumnsSuccess({ response })),
          catchError(response =>
            of(LeadsActions.UpdateLeadColumnsFail({ response }))
          )
        );
      })
    )
  );
  TransformLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.TransformLead),
      switchMap(({ iri, payload }) => {
        return this.ls.transformLead(iri, payload).pipe(
          map(response => LeadsActions.TransformLeadSuccess({ iri, response })),
          catchError(response =>
            of(LeadsActions.TransformLeadFail({ response }))
          )
        );
      })
    )
  );
  DeleteLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.DeleteLead),
      mergeMap(({ iri }) => {
        return this.ls.deleteLead(iri).pipe(
          map(() => LeadsActions.DeleteLeadSuccess({ iri })),
          catchError(response => of(LeadsActions.DeleteLeadFail({ response })))
        );
      })
    )
  );
  DeleteArchivedLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.DeleteArchivedLead),
      mergeMap(({ iri }) => {
        return this.ls.deleteLead(iri).pipe(
          map(() => LeadsActions.DeleteArchivedLeadSuccess({ iri })),
          catchError(response =>
            of(LeadsActions.DeleteArchivedLeadFail({ response }))
          )
        );
      })
    )
  );
  ArchiveLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.ArchiveLead),
      mergeMap(payload => {
        return this.ls.patchLead(payload).pipe(
          map(response =>
            LeadsActions.ArchiveLeadSuccess({ iri: response['@id'], response })
          ),
          catchError(response => of(LeadsActions.ArchiveLeadFail({ response })))
        );
      })
    )
  );
  UnArchiveLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.UnArchiveLead),
      switchMap(payload => {
        return this.ls.patchLead(payload).pipe(
          map(response =>
            LeadsActions.UnArchiveLeadSuccess({
              iri: response['@id'],
              response
            })
          ),
          catchError(response =>
            of(LeadsActions.UnArchiveLeadFail({ response }))
          )
        );
      })
    )
  );
  SuccessfulActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LeadsActions.CreateLeadSuccess,
          LeadsActions.UpdateLeadSuccess,
          LeadsActions.TransformLeadSuccess,
          LeadsActions.ArchiveLeadSuccess,
          LeadsActions.UnArchiveLeadSuccess,
          LeadsActions.MailLeadSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case LeadsActions.TRANSFORM_LEAD_SUCCESS:
              message = 'Ein neuer Analyseauftrag wurde erstellt.';
              break;
            case LeadsActions.CREATE_LEAD_SUCCESS:
              message = 'Eine neue Anfrage wurde erstellt.';
              break;
            case LeadsActions.ARCHIVE_LEAD_SUCCESS:
              message = 'Anfrage wurde erfolgreich archiviert.';
              break;
            case LeadsActions.UN_ARCHIVE_LEAD_SUCCESS:
              message = 'Anfrage wurde erfolgreich aus dem Archiv geholt.';
              break;
            case LeadsActions.UPDATE_LEAD_SUCCESS:
              message = 'Die Anfrage wurde aktualisiert.';
              break;
            case LeadsActions.MAIL_LEAD_SUCCESS:
              message = 'Die Email wurde versendet.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  FailActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LeadsActions.CreateLeadFail,
          LeadsActions.ReadLeadFail,
          LeadsActions.ReadLeadsFail,
          LeadsActions.UpdateLeadFail,
          LeadsActions.DeleteLeadFail,
          LeadsActions.MailLeadFail,
          LeadsActions.TransformLeadFail
        ),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case LeadsActions.CREATE_LEAD_FAIL:
              message =
                'Beim Anlegen der Anfrage sind Fehler aufgetreten:' + errors;
              break;
            case LeadsActions.UPDATE_LEAD_FAIL:
              message =
                'Beim Aktualisieren der Anfrage sind Fehler aufgetreten:' +
                errors;
              break;
            case LeadsActions.READ_LEADS_FAIL:
              message =
                'Beim Lesen der Anfragen sind Fehler aufgetreten:' + errors;
              break;
            case LeadsActions.DELETE_LEAD_FAIL:
              message =
                'Beim Löschen der Anfrage ist ein Fehler aufgetreten:' + errors;
              break;
            case LeadsActions.TRANSFORM_LEAD_FAIL:
              message =
                'Beim Umwandeln der Anfrage sind Fehler aufgetreten:' + errors;
              break;
            case LeadsActions.MAIL_LEAD_FAIL:
              message =
                'Beim senden der E-Mail sind Fehler aufgetreten:' + errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  DeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        // todo: update URL
        // LeadsActions.CreateLeadSuccess,
        LeadsActions.DeleteLeadSuccess
      ),
      map(() => RouterActions.Go({ path: ['leads'] }))
    )
  );
  TransformSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.TransformLeadSuccess),
      map(({ response }) => {
        const orderIri = extractIri(response);
        return RouterActions.Go({ path: ['/orders', extractUUID(orderIri)] });
      })
    )
  );
  DeleteArchivedLeadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsActions.DeleteArchivedLeadSuccess),
      map(() => RouterActions.Go({ path: ['leads', 'archive'] }))
    )
  );

  constructor(
    private actions$: Actions,
    private ls: fromModuleServices.LeadsService,
    private notifierService: NotifierService
  ) {}
}
