import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ServicesActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { ServicesService } from '../../services/services.service';

@Injectable()
export class ServicesEffects {
  createService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.CreateService),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createService(payload).pipe(
          map(response => ServicesActions.CreateServiceSuccess({ response })),
          catchError(response =>
            of(ServicesActions.CreateServiceFail({ response }))
          )
        );
      })
    )
  );
  getService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.ReadService),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getService(iri).pipe(
          map(response => ServicesActions.ReadServiceSuccess({ response })),
          catchError(response =>
            of(ServicesActions.ReadServiceFail({ response }))
          )
        );
      })
    )
  );
  getServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.ReadServices),
      map(action => action),
      switchMap(() => {
        return this.service.getServices().pipe(
          map(response => ServicesActions.ReadServicesSuccess({ response })),
          catchError(response =>
            of(ServicesActions.ReadServicesFail({ response }))
          )
        );
      })
    )
  );
  updateService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.UpdateService),
      map(action => action),
      switchMap(payload => {
        return this.service.updateService(payload).pipe(
          map(response => ServicesActions.UpdateServiceSuccess({ response })),
          catchError(response =>
            of(ServicesActions.UpdateServiceFail({ response }))
          )
        );
      })
    )
  );
  deleteService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServicesActions.DeleteService),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteService(iri).pipe(
          map(() => ServicesActions.DeleteServiceSuccess({ iri })),
          catchError(response =>
            of(ServicesActions.DeleteServiceFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ServicesActions.DeleteServiceFail,
          ServicesActions.UpdateServiceFail,
          ServicesActions.ReadServicesFail,
          ServicesActions.ReadServiceFail,
          ServicesActions.CreateServiceFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  fileSystemActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ServicesActions.CreateServiceSuccess,
        ServicesActions.UpdateServiceSuccess
      ),
      map(({ type }) => {
        const text =
          type === ServicesActions.CREATE_SERVICE_SUCCESS
            ? 'Eine neue Dienstleistung wurde angelegt.'
            : 'Die Dienstleistung wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: ServicesService,
    private notifierService: NotifierService
  ) {}
}
