import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerContactTypesActions} from '../actions/';
import {CustomerContactTypesService} from '../../services';

@Injectable()
export class CustomerContactTypesEffects {

  constructor(private actions$: Actions, private ccts: CustomerContactTypesService) {
  }


  getCustomerContactType$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactTypesActions.LoadCustomerContactType),
    map(action => action),
    switchMap(({iri}) => {

      return this.ccts
        .getCustomerContactType(iri)
        .pipe(
          map((response: any) => CustomerContactTypesActions.LoadCustomerContactTypeSuccess({response})),
          catchError(response => of(CustomerContactTypesActions.LoadCustomerContactTypeFail({response})))
        );
    })
  ));


  getCustomerContactTypes$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactTypesActions.ReadCustomerContactTypes),
    map(action => action),
    switchMap(() => {

      return this.ccts
        .getCustomerContactTypes()
        .pipe(
          map((response: any) => CustomerContactTypesActions.ReadCustomerContactTypesSuccess({response})),
          catchError(response => of(CustomerContactTypesActions.ReadCustomerContactTypesFail({response})))
        );
    })
  ));
}
