import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {BehaviorSubject, Observable} from 'rxjs';

import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromInvoicesModuleModels from '../../../invoices/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'commission-credit-facturer',
  styleUrls: ['commission-credit-facturer.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading" [class.green]="!!credit?.bookedAt">
        <span>Gutschrift-Details</span>
      </div>

      <div class="card__content grid">

        <ng-container [formGroup]="parentFormGroup">

          <div class="mat-form-field column-7">
            <ng-select
              placeholder="Steuersatz"
              [items]="taxRates$ | async"
              bindValue="@id"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              [disabled]="!canEdit"
              formControlName="taxRate"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.taxRate">{{ errors.taxRate.message }}</mat-hint>
          </div>

          <div class="column-7 mat-form-field">

            <app-customer-select label="Gutschrift für Vermittler (Partner)"
                                 [partnerOnly]="true"
                                 [readonly]="credit && !canEdit"
                                 formControlName="broker"></app-customer-select>
            <!--            <customer-search-->
            <!--              (requestReadCustomers)="handleRequestReadBrokers($event)"-->
            <!--              (requestResetSearchResults)="handleRequestResetSearchResults()"-->
            <!--              [editableSearchTerm]="true"-->
            <!--              formControlName="broker"-->
            <!--              [formGroup]="parentFormGroup"-->
            <!--              [clearable]="true"-->
            <!--              typeToSearchText="Nach Vermittler suchen"-->
            <!--              appendTo="body"-->
            <!--              [paginationNextLink$]="paginationNextLink$"-->
            <!--              [searchResults$]="searchResults$"-->
            <!--              [staticSelection$]="broker$"-->
            <!--              notFoundText="Keine Ergebnisse"-->
            <!--              placeholder="Gutschrift für Vermittler (Partner)"-->
            <!--            ></customer-search>-->

            <!--<ng-select
              placeholder="Gutschrift für Vermittler (Partner)"
              [items]="partners"
              formControlName="broker"
              bindLabel="nameLine1"
              bindValue="@id"
              [searchable]="false">

              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="ng-option custom-layout">
                  <span style="padding-right: 7px">
                    <mat-icon [inline]="true" color="accent">person</mat-icon>
                  </span>
                  <span style="padding-right: 7px">{{ item.nameLine1 }}</span>
                  <strong *ngIf="item.nameLine2" style="font-size: 12px">({{ item.nameLine2 }})</strong>
                </div>
              </ng-template>
            </ng-select>
            <mat-hint align="start" *ngIf="errors?.broker">{{ errors.broker.message }}</mat-hint>-->
          </div>

          <mat-form-field class="column-7" formGroupName="recipient">

            <mat-label>USt.-ID Rechnungsempfänger</mat-label>
            <input type="text" matInput formControlName="taxNumber"
                   [disabled]="!canEdit"
            >
            <mat-hint align="start"
                      *ngIf="errors.hasOwnProperty('recipient.taxNumber')">{{ errors['recipient.taxNumber'].message }}</mat-hint>
          </mat-form-field>
        </ng-container>

        <div class="column-14 m-ta--2">

          <button color="outline"
                  mat-flat-button
                  class="m-r--16"
                  [disabled]="!isCreditBookable(credit)"
                  (click)="handleRequestBookCorrectionInvoice(credit)">
            <mat-icon class="m-r--8">recommend</mat-icon>
            <span>Gutschrift buchen</span>
          </button>

          <button mat-flat-button
                  color="green" [disabled]="parentFormGroup.invalid || parentFormGroup.pristine || !canEdit"
                  (click)="handleRequestRequestCreateOrUpdateCommissionCredit(credit)">
            <mat-icon class="m-r--8">{{ credit ? 'update' : 'description' }}</mat-icon>
            <span>{{ credit ? 'Aktualisieren' : 'Speichern' }}</span>
          </button>
        </div>
      </div>

      <div class="card__footer">
        <div class="grid">

        <span class="column-6">

          <button
            *ngIf="(credit?.state === 'booked') || credit?.state === 'paid_out'"
            mat-flat-button
            (click)="handleRequestDownloadCommissionCredit(credit)"
            style="color: #bbb">
            <mat-icon class="m-r--8">description</mat-icon>
            <span>Download</span>
          </button>
        </span>

          <span class="column-8 m-ta--2">
          <span *ngIf="credit?.bookedAt" class="booked-wrapper">
            <mat-icon class="booked-icon m-r--8">thumb_up</mat-icon>
            <span>Gutschrift am {{ credit.bookedAt | date: 'dd.MM.Y' }} gebucht</span>
          </span>
        </span>
        </div>
      </div>
    </div>

    <!--<pre>{{ formGroup.value | json }}</pre>-->
    <!--<pre>{{ parentFormGroup.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
  `
})
export class CommissionCreditFacturerComponent {

  @Input() credit: fromInvoicesModuleModels.CommissionCredit | null;
  @Input() errors: ErrorsObject;
  @Input() paginationNextLink$: BehaviorSubject<string | null | undefined>;
  @Input() parentFormGroup: FormGroup;
  @Input() partners: Array<fromCustomersModuleModels.Customer>;
  @Input() searchResults$: Observable<Array<fromCustomersModuleModels.Customer>>;
  @Input() taxRates$: Observable<Array<fromMasterDataModuleModels.Tax>>;
  @Input() broker$: Observable<fromCustomersModuleModels.Customer>;

  @Output() requestBookCommissionCredit: EventEmitter<string> = new EventEmitter();
  @Output() requestCreateCommissionCredit: EventEmitter<string> = new EventEmitter();
  @Output() requestDownloadCommissionCredit: EventEmitter<string> = new EventEmitter();
  @Output() requestReadBrokers: EventEmitter<{
    page: number,
    params: { [p: string]: number | boolean | string }
  }> = new EventEmitter();
  @Output() requestResetSearchResults: EventEmitter<void> = new EventEmitter();
  @Output() requestUpdateCommissionCredit: EventEmitter<void> = new EventEmitter();

  get canEdit(): boolean {
    return this.credit && !(!!this.credit?.payoutAt || !!this.credit?.bookedAt);
  }

  constructor() {
  }

  handleRequestDownloadCommissionCredit(credit: fromInvoicesModuleModels.CommissionCredit): void {
    this.requestDownloadCommissionCredit.emit(credit['@id']);
  }

  handleRequestBookCorrectionInvoice(credit: fromInvoicesModuleModels.CommissionCredit): void {
    if (!credit) {
      alert('Credit must be set');
      return;
    }
    this.requestBookCommissionCredit.emit(credit['@id']);
  }

  handleRequestRequestCreateOrUpdateCommissionCredit(credit?: fromInvoicesModuleModels.CommissionCredit): void {

    if (!credit) {
      this.requestCreateCommissionCredit.emit();
    } else {
      this.requestUpdateCommissionCredit.emit();
    }
  }

  isCreditBookable(credit: fromInvoicesModuleModels.CommissionCredit): boolean {
    return !!credit && !!credit.potentialActions?.filter(action => action.transition === 'book').length;
  }

  handleRequestReadBrokers(_payload: { page: number, params: { [p: string]: number | boolean | string } }): void {

    const payload = {
      page: _payload.page,
      params: {
        ..._payload.params,
        isPartner: true
      }
    };
    this.requestReadBrokers.emit(payload);
  }

  handleRequestResetSearchResults(): void {
    this.requestResetSearchResults.emit();
  }
}
