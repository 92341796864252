import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { CustomerDataMedium } from '../models';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerDataMediumsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomerDataMedium(
    payload: CustomerDataMedium
  ): Observable<CustomerDataMedium> {
    return this.rolesService.userHasRoleFilter<CustomerDataMedium>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/customer_data_media`, payload)
    );
  }

  readCustomerDataMedium(iri: string): Observable<Array<CustomerDataMedium>> {
    return this.rolesService.userHasRoleFilter<Array<CustomerDataMedium>>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readCustomerDataMediums(
    page: number,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/customer_data_media', page, params)
            )
    );
  }

  update(
    iri: string,
    payload: CustomerDataMedium
  ): Observable<CustomerDataMedium> {
    return this.rolesService.userHasRoleFilter<CustomerDataMedium>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomerDataMedium(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_DATA_MEDIUM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
