import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { PayableInvoice } from '../../models';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { payableInvoiceStateMapDE } from '../../helper/invoice-state-maps';
import { PayableInvoicesService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { OrdersActions } from '../../../orders/store';
import {
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-draft-invoice-list-widget',
  styleUrls: ['./draft-invoice-list-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header ps-1">
          <span
            ><button (click)="loadData()" mat-icon-button>
              <mat-icon>autorenew</mat-icon>
            </button>
            Zu Buchende Rechnungen</span
          >
        </div>
        <div class="widget_content" style="position: relative;">
          <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
          <table
            class="bmo-table bmo-table-hover table-dense bmo-table-clickable"
          >
            <tbody>
              <tr *ngFor="let invoice of payableInvoices$ | async">
                <td
                  style="width: 50px; position: relative;"
                  class="pos-relative p-l--16"
                >
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <span class="badge--express" *ngIf="invoice.express"
                    ><span>Express</span></span
                  >
                  <div class="circle yellow"></div>
                </td>
                <td style="width: 80px;" class="pos-relative">
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <!--                  <span [class.orange]="!invoice.invoiceNumber">-->
                  <!--                    {{ invoice.invoiceNumber ? invoice.invoiceNumber : 'E' }}-->
                  <!--                  </span>-->
                  <!--                  <pre>{{ invoice | json }}</pre>-->
                  <span *ngIf="invoice?.paymentProcess?.order?.orderNumber">
                    A: {{ invoice?.paymentProcess?.order?.orderNumber }}
                  </span>
                </td>
                <td class="pos-relative">
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <span class="badge--vip inline small" *ngIf="invoice.vip"
                    >VIP</span
                  >
                  <span> {{ invoice.recipient.nameLine1 }} </span>
                  <span *ngIf="invoice.recipient.nameLine2">
                    [{{ invoice.recipient.nameLine2 }}]
                  </span>
                </td>
                <td style="width: 100px;" class="pos-relative">
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <span>
                    {{
                      extractTypeByIri(invoice) === 'partial_invoices'
                        ? 'Teilrechnung'
                        : 'Rechnung'
                    }}
                  </span>
                </td>

                <td>
                  <!--                  <pre>{{ invoice | json }}</pre>-->
                </td>
                <td style="width: 170px;" class="pos-relative text-right">
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <span
                    >{{ invoice.netTotal.value | number: '1.2-2' }}
                    {{ invoice.netTotal.currency | currencyShortener }}
                  </span>
                </td>
                <td style="width: 170px;" class="pos-relative">
                  <a
                    class="stretched-link"
                    [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
                  ></a>
                  <span
                    [matTooltip]="invoice.dueDate | date: 'dd.MM.YYYY'"
                    matTooltipPosition="above"
                    >{{ invoice.dueDate | momentDuration }}
                  </span>
                </td>
              </tr>
              <tr *ngIf="(payableInvoices$ | async).length <= 0">
                <td class="text-center">
                  <span>Keine Rechnungen vorhanden</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rounded-bottom px-2">
          <mat-paginator
            [length]="orderCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="showFirstLastButtons"
            (page)="onChangedPage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  `
})
export class DraftInvoiceListWidgetComponent extends MatPaginatorIntl
  implements OnInit, OnDestroy {
  firstPageLabel = ' Erste Seite';
  nextPageLabel = ' Nächste Seite';
  previousPageLabel = ' Vorherige Seite';
  lastPageLabel = ' Letzte Seite';
  itemsPerPageLabel = ' Zeilen pro Seite';
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onDestroy$: Subject<any> = new Subject<any>();

  pagination$: BehaviorSubject<{ [p: string]: string }> = new BehaviorSubject<{
    [p: string]: string;
  }>(null);
  currentPage = 1;
  orderCount = 1;
  pageSize = 10;
  pageSizeOptions = [5, 10, 15, 20, 25];
  showFirstLastButtons = true;

  totalItems$: Observable<number>;
  payableInvoices$: BehaviorSubject<
    Array<PayableInvoice>
  > = new BehaviorSubject([]);
  stateMap = payableInvoiceStateMapDE;
  subscription: any;

  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{
    [p: string]: any;
  }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc',
    state: ['draft']
  });
  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractUUID = extractUUID;

  constructor(
    private store$: Store<ApplicationState>,
    private backendService: PayableInvoicesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(element: PayableInvoice): string[] {
    if (
      element.state === 'booked' &&
      !element.overdue &&
      parseFloat(element.amountOutstanding.value) === 0.0
    ) {
      return ['green'];
    } else if (element.state === 'booked' && !element.overdue) {
      return [];
    } else if (
      element.state === 'first_reminder' ||
      element.state === 'second_reminder' ||
      (element.state === 'booked' && element.overdue)
    ) {
      return ['yellow'];
    } else if (element.state === 'dept_collection') {
      return ['red'];
    } else {
      return [];
    }
  }

  onChangedPage(pageData: PageEvent): void {
    this.currentPage = pageData.pageIndex + 1;
    this.pageSize = pageData.pageSize;

    console.log(pageData);
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });

    this.loadData();
  }

  loadData(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.isLoading$.next(true);
    this.subscription = this.backendService
      .readPayableInvoices(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.isLoading$.next(false);

          console.log(response);
          this.orderCount = response['hydra:totalItems'];
          this.payableInvoices$.next(response['hydra:member']);
          // this.store$.dispatch(OrdersActions.ReadOrdersSuccess({ response }));
          // this.orders$.next(orders$)
        },
        fail => {
          this.isLoading$.next(false);
          console.error(fail);
        }
      );
  }

  handleClick($event): void {
    // console.log('handleClick', $event);
    // this.store$.dispatch(
    //   RouterActions.Go({ path: ['invoices', 'payable', $event['@id']] })
    // );
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `Keine Seiten: ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `Seite ${page + 1}: ${startIndex + 1} - ${endIndex} von ${length}`;
  };
}
