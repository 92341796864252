import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { Service } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ServicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createService(payload: Service): Observable<Service> {
    return this.rolesService.userHasRoleFilter<Service>(
      'ROLE_MWS_PRODUCT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/services`, payload)
    );
  }

  getService(iri: string): Observable<Service> {
    return this.rolesService.userHasRoleFilter<Service>(
      'ROLE_MWS_PRODUCT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getServices(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PRODUCT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/abstract_products?instance_of=Service`)
    );
  }

  updateService({ iri, payload }): Observable<Service> {
    return this.rolesService.userHasRoleFilter<Service>(
      'ROLE_MWS_PRODUCT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteService(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PRODUCT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
