import { createReducer, on } from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrderStatesActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromOrdersModuleModels.OrderState;
  entities: { [iri: string]: fromOrdersModuleModels.OrderState };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  current: null,
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    OrderStatesActions.ReadOrderState,
    OrderStatesActions.ReadOrderStates,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(OrderStatesActions.ReadOrderStateSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      current: response,
      loading: false
    };
  }),
  on(OrderStatesActions.ReadOrderStatesSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (
        entities: { [iri: string]: fromOrdersModuleModels.OrderState },
        item
      ) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    OrderStatesActions.ReadOrderStateFail,
    OrderStatesActions.ReadOrderStatesFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
