import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'invitation-confirmation-form',
  styleUrls: ['invitation-confirmation-form.component.scss'],
  template: `

    <div class="grid" [formGroup]="cf">

      <div class="column-14">
        <div class="herobox m-t--24 m-b--32" data-box-style="blue">
          <span class="heading--h3 m-b--0">Sie wurden zu bmoffice24 eingeladen. Vergeben Sie nun ein Passwort für sich und schon sind Sie angemeldet.</span>
        </div>
      </div>

      <mat-form-field class="column-14">
        <mat-label>Passwort</mat-label>
        <input type="password" matInput formControlName="plainPassword" required #plainPasswordInput>
        <mat-icon
          matSuffix style="margin-right: 12px; cursor: pointer; opacity: .8"
          (mousedown)="plainPasswordInput.type = 'text'"
          (mouseup)="plainPasswordInput.type = 'password'">visibility
        </mat-icon>
        <mat-hint align="start" *ngIf="errors?.plainPassword">{{ errors.plainPassword.message }}</mat-hint>
      </mat-form-field>

      <mat-form-field class="column-14">
        <mat-label>Passwort-Bestätigung</mat-label>
        <input type="password" matInput formControlName="plainPasswordConfirmation" required
               #plainPasswordConfirmationInput>
        <mat-icon
          matSuffix style="margin-right: 12px; cursor: pointer; opacity: .8"
          (mousedown)="plainPasswordConfirmationInput.type = 'text'"
          (mouseup)="plainPasswordConfirmationInput.type = 'password'">visibility
        </mat-icon>
        <mat-hint align="start"
                  *ngIf="errors?.plainPasswordConfirmation">{{ errors.plainPasswordConfirmation.message }}</mat-hint>
      </mat-form-field>

      <div class="mat-form-field submit column-14">
        <div class="m-ta--2">
          <button
            [disabled]="cf.invalid || cf.untouched"
            (click)="handleRequestCreateInvitationConfirmation()" mat-flat-button color="accent">Passwort setzen und
            einloggen
          </button>
        </div>
      </div>
    </div>

    <!--<pre>{{ invitationToken | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
  `
})
export class InvitationConfirmationFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() invitationToken: string;

  @Output() requestCreateInvitationConfirmation: EventEmitter<any> = new EventEmitter();

  cf: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.cf = this.fb.group({
      plainPassword: this.fb.control('', [Validators.required]),
      plainPasswordConfirmation: this.fb.control('', [Validators.required]),
      invitationToken: this.fb.control(this.invitationToken, [Validators.required])
    });
  }

  handleRequestCreateInvitationConfirmation(): void {
    this.requestCreateInvitationConfirmation.emit(this.cf.value);
  }
}
