import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { SpecialAgreement } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class SpecialAgreementsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createSpecialAgreement(
    payload: SpecialAgreement
  ): Observable<SpecialAgreement> {
    return this.rolesService.userHasRoleFilter<SpecialAgreement>(
      'ROLE_MWS_SPECIAL_AGREEMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/special_agreements`, payload)
    );
  }

  getSpecialAgreement(iri: string): Observable<SpecialAgreement> {
    return this.rolesService.userHasRoleFilter<SpecialAgreement>(
      'ROLE_MWS_SPECIAL_AGREEMENT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getSpecialAgreements(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SPECIAL_AGREEMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/special_agreements`)
    );
  }

  updateSpecialAgreement({ iri, payload }): Observable<SpecialAgreement> {
    return this.rolesService.userHasRoleFilter<SpecialAgreement>(
      'ROLE_MWS_SPECIAL_AGREEMENT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteSpecialAgreement(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SPECIAL_AGREEMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
