import {createSelector} from '@ngrx/store';

import {CUSTOMER_COOPERATIONS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectCustomerCooperationsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[CUSTOMER_COOPERATIONS_FEATURE_KEY]
);

export const selectCustomerCooperationsEntities = createSelector(
  selectCustomerCooperationsState,
  (state) => state.entities
);

export const selectCustomerCooperations = createSelector(
  selectCustomerCooperationsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectCustomerCooperationsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectCustomerCooperationsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerCooperationsState,
  (state) => state.loaded
);
