import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleServices from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {PartnerWebsiteInvitationsActions} from '../actions/';
import {RouterActions} from '../../../application-state/store/actions';

@Injectable()
export class PartnerWebsiteInvitationsEffects {

  constructor(private actions$: Actions, private service: fromModuleServices.PartnerWebsiteInvitationsService) {
  }


  CreatePartnerWebsiteInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitation),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createPartnerWebsiteInvitation(payload).pipe(
        map(response => PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationSuccess({response})),
        catchError(response => of(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationFail({response})))
      );
    })
  ));


  CreatePartnerWebsiteInvitationConfirmation$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationConfirmation),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createPartnerWebsiteInvitationConfirmation(payload).pipe(
        map(response => PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationConfirmationSuccess({response})),
        catchError(response => of(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationConfirmationFail({response})))
      );
    })
  ));


  ReadPartnerWebsiteInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitation),
    map(action => action),
    switchMap(({iri}) => {
      return this.service.readPartnerWebsiteInvitation(iri).pipe(
        map(response => PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitationSuccess({response})),
        catchError(response => of(PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitationFail({response})))
      );
    })
  ));


  ReadPartnerWebsiteInvitations$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitations),
    map(action => action),
    switchMap(() => {
      return this.service.readPartnerWebsiteInvitations().pipe(
        map(response => PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitationsSuccess({response})),
        catchError(response => of(PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitationsFail({response})))
      );
    })
  ));


  UpdatePartnerWebsiteInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.UpdatePartnerWebsiteInvitation),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updatePartnerWebsiteInvitation(iri, payload).pipe(
        map(response => PartnerWebsiteInvitationsActions.UpdatePartnerWebsiteInvitationSuccess({response})),
        catchError(response => of(PartnerWebsiteInvitationsActions.UpdatePartnerWebsiteInvitationFail({response})))
      );
    })
  ));


  DeletePartnerWebsiteInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.DeletePartnerWebsiteInvitation),
    switchMap(({iri}) => {
      return this.service.deletePartnerWebsiteInvitation(iri).pipe(
        map(() => PartnerWebsiteInvitationsActions.DeletePartnerWebsiteInvitationSuccess({iri})),
        catchError(response => of(PartnerWebsiteInvitationsActions.DeletePartnerWebsiteInvitationFail({response})))
      );
    })
  ));


  ActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationSuccess,
      PartnerWebsiteInvitationsActions.UpdatePartnerWebsiteInvitationSuccess
    ),
    map(({type}) => {

      const text = type === PartnerWebsiteInvitationsActions.CREATE_PARTNER_WEBSITE_INVITATION_SUCCESS
        ? `Eine neuer Kundenzugang (Website) wurde angelegt.`
        : 'Der Kundenzugang (Website) wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));


  CreatePartnerWebsiteInvitationConfirmationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationConfirmationSuccess),
    map(() => RouterActions.Go({path: ['dashboard']}))
  ));
}
