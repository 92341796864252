import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as fromModuleModels from '../../models';
import {OfferItemsActions} from '../actions/';
import {OfferItemsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class OfferItemsEffects {

  constructor(private actions$: Actions, private service: OfferItemsService, private notifierService: NotifierService) {
  }

  CreateOfferItem$ = createEffect(() => this.actions$.pipe(
    ofType(OfferItemsActions.CreateOfferItem),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createOfferItem(payload).pipe(
        map((response) => OfferItemsActions.CreateOfferItemSuccess({response, invoiceIri})),
        catchError(response => of(OfferItemsActions.CreateOfferItemFail({response})))
      );
    })
  ));

  ReadOfferItem$ = createEffect(() => this.actions$.pipe(
    ofType(OfferItemsActions.ReadOfferItem),
    switchMap(({iri}) => {
      return this.service.readOfferItem(iri).pipe(
        map((response => OfferItemsActions.ReadOfferItemSuccess({response}))),
        catchError(response => of(OfferItemsActions.ReadOfferItemFail({response})))
      );
    })
  ));

  ReadOfferItems$ = createEffect(() => this.actions$.pipe(
    ofType(OfferItemsActions.ReadOfferItems),
    switchMap(() => {
      return this.service.readOfferItems().pipe(
        map((response) => OfferItemsActions.ReadOfferItemsSuccess({response})),
        catchError(response => of(OfferItemsActions.ReadOfferItemsFail({response})))
      );
    })
  ));

  UpdateOfferItem$ = createEffect(() => this.actions$.pipe(
    ofType(OfferItemsActions.UpdateOfferItem),
    switchMap(({iri, payload, invoiceIri}) => {
      return this.service.updateOfferItem(iri, payload).pipe(
        map((response: fromModuleModels.OfferItem) => OfferItemsActions.UpdateOfferItemSuccess({response, invoiceIri})),
        catchError((response: HttpErrorResponse) => of(OfferItemsActions.UpdateOfferItemFail({response})))
      );
    })
  ));

  DeleteOfferItem$ = createEffect(() => this.actions$.pipe(
    ofType(OfferItemsActions.DeleteOfferItem),
    switchMap(({iri, invoiceIri}) => {
      return this.service.deleteOfferItem(iri).pipe(
        map(() => OfferItemsActions.DeleteOfferItemSuccess({iri, invoiceIri})),
        catchError((response: HttpErrorResponse) => of(OfferItemsActions.DeleteOfferItemFail({response})))
      );
    })
  ));
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      OfferItemsActions.CreateOfferItemFail,
      OfferItemsActions.UpdateOfferItemFail,
      OfferItemsActions.DeleteOfferItemFail,
    ),
    map(({type, response}) => {
      console.log({response});
      const errors = response?.error['hydra:description'];
      const message = 'Fehler bei der Anfrage: ' + errors;
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      OfferItemsActions.CreateOfferItemSuccess,
      OfferItemsActions.UpdateOfferItemSuccess,
      OfferItemsActions.DeleteOfferItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case OfferItemsActions.CREATE_OFFER_ITEM_SUCCESS:
          message = 'Ein neue Position wurde angelegt.';
          break;
        case OfferItemsActions.UPDATE_OFFER_ITEM_SUCCESS:
          message = 'Die Position wurde aktualisiert.';
          break;
        case OfferItemsActions.DELETE_OFFER_ITEM_SUCCESS:
          message = 'Die Position wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});
}
