import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { UserProfilesActions } from '../actions/';
import { UserProfilesService } from '../../services/user-profiles.service';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';

@Injectable()
export class UserProfilesEffects {
  ReadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfilesActions.ReadUserProfile),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readUserProfile(iri).pipe(
          map(response =>
            UserProfilesActions.ReadUserProfileSuccess({ response })
          ),
          catchError(response =>
            of(UserProfilesActions.ReadUserProfileFail({ response }))
          )
        );
      })
    )
  );
  UpdateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfilesActions.UpdateUserProfile),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.updateUserProfile(iri, payload).pipe(
          map(response =>
            UserProfilesActions.UpdateUserProfileSuccess({ response })
          ),
          catchError(response =>
            of(UserProfilesActions.UpdateUserProfileFail({ response }))
          )
        );
      })
    )
  );
  UserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfilesActions.UpdateUserProfileSuccess),
      map(() => {
        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text: 'Das Nutzer-Profil wurde aktualisiert',
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: UserProfilesService
  ) {}
}
