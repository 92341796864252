import { createReducer, on } from '@ngrx/store';

import { GenericOrderItemsActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { GenericOrderItem } from '../../models/generic-order-item.interface';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: GenericOrderItem };
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    GenericOrderItemsActions.CreateGenericOrderItem,
    GenericOrderItemsActions.ReadGenericOrderItem,
    GenericOrderItemsActions.ReadGenericOrderItems,
    GenericOrderItemsActions.UpdateGenericOrderItem,
    GenericOrderItemsActions.DeleteGenericOrderItem,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    GenericOrderItemsActions.CreateGenericOrderItemSuccess,
    GenericOrderItemsActions.UpdateGenericOrderItemSuccess,
    (state, { response }) => {
      return {
        ...state,
        entities: mergeEntities([response], null, state.entities),
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    GenericOrderItemsActions.ReadGenericOrderItemsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      return {
        ...state,
        entities: mergeEntities(items, null, state.entities),
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    GenericOrderItemsActions.DeleteGenericOrderItemSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    GenericOrderItemsActions.CreateGenericOrderItemFail,
    GenericOrderItemsActions.UpdateGenericOrderItemFail,
    GenericOrderItemsActions.DeleteGenericOrderItemFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
