import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_TICKET_COMMENT_TAG = '[Tickets Module] Read Ticket Comment Tag';
export const READ_TICKET_COMMENT_TAG_SUCCESS = '[Tickets Module] Read Ticket Comment Tag Success';
export const READ_TICKET_COMMENT_TAG_FAIL = '[Tickets Module] Read Ticket Comment Tag Fail';

export const ReadTicketCommentTag = createAction(
  READ_TICKET_COMMENT_TAG,
  props<{ iri: string }>()
);

export const ReadTicketCommentTagSuccess = createAction(
  READ_TICKET_COMMENT_TAG_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketCommentTagFail = createAction(
  READ_TICKET_COMMENT_TAG_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_TICKET_COMMENT_TAGS = '[Tickets Module] Read Ticket Comment Tags';
export const READ_TICKET_COMMENT_TAGS_SUCCESS = '[Tickets Module] Read Ticket Comment Tags Success';
export const READ_TICKET_COMMENT_TAGS_FAIL = '[Tickets Module] Read Ticket Comment Tags Fail';

export const ReadTicketCommentTags = createAction(
  READ_TICKET_COMMENT_TAGS,
);

export const ReadTicketCommentTagsSuccess = createAction(
  READ_TICKET_COMMENT_TAGS_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketCommentTagsFail = createAction(
  READ_TICKET_COMMENT_TAGS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
