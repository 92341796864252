import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {CustomerCommentsActions} from '../actions/';
import {CustomerCommentsService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class CustomerCommentsEffects {

  constructor(private actions$: Actions, private service: CustomerCommentsService) {
  }


  createCustomerComment$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentsActions.CreateCustomerComment),
    map(action => action),
    switchMap(({payload}) => {

      return this.service.createCustomerComment(payload).pipe(
        map((response) => CustomerCommentsActions.CreateCustomerCommentSuccess({response})),
        catchError((response) => of(CustomerCommentsActions.CreateCustomerCommentFail({response})))
      );
    })
  ));


  ReadCustomerComments$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentsActions.ReadCustomerComments),
    map(action => action),
    switchMap(({customerUuid}) => {
      return this.service.readCustomerComments(customerUuid).pipe(
        map((response) => CustomerCommentsActions.ReadCustomerCommentsSuccess({response})),
        catchError((response) => of(CustomerCommentsActions.ReadCustomerCommentsFail({response})))
      );
    })
  ));

  ReadCustomerCommentChunk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerCommentsActions.ReadCustomerCommentChunk),
      switchMap(({uri}) => {
        return this.service.readOrderCommentChunk(uri).pipe(
          map((response) => CustomerCommentsActions.ReadCustomerCommentChunkSuccess({response})),
          catchError((response) => of(CustomerCommentsActions.ReadCustomerCommentChunkFail({response})))
        );
      })
    );
  });


  LoadCustomerCommentsSelection$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentsActions.LoadCustomerCommentsSelection),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.getCustomerCommentsSelection(payload).pipe(
        map((response) => CustomerCommentsActions.LoadCustomerCommentsSelectionSuccess({response})),
        catchError((response) => of(CustomerCommentsActions.LoadCustomerCommentsSelectionFail({response})))
      );
    })
  ));


  UpdateCustomerComment$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentsActions.UpdateCustomerComment),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updateCustomerComment(iri, payload).pipe(
        map(response => CustomerCommentsActions.UpdateCustomerCommentSuccess({response})),
        catchError(response => of(CustomerCommentsActions.UpdateCustomerCommentFail({response})))
      );
    })
  ));


  DeleteCustomerComment$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentsActions.DeleteCustomerComment),
    map(action => action),
    switchMap(({iri}) => {
      return this.service.deleteCustomerComment(iri).pipe(
        map(() => CustomerCommentsActions.DeleteCustomerCommentSuccess({iri})),
        catchError(response => of(CustomerCommentsActions.DeleteCustomerCommentFail({response})))
      );
    })
  ));


  CustomerCommentActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerCommentsActions.CreateCustomerCommentSuccess,
      CustomerCommentsActions.UpdateCustomerCommentSuccess
    ),
    map(({type}) => {

      const text = type === CustomerCommentsActions.CREATE_CUSTOMER_COMMENT_SUCCESS
        ? `Ein neuer Kunden-Kommentar wurde angelegt.`
        : 'Der Kunden-Kommentar wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
