import {Component, Input, OnInit} from '@angular/core';
import {PartialInvoice, PayableInvoice} from '../../../invoices/models';
import {extractTypeByIri, extractUUID} from '../../../shared/utilities/objects.utility';
import {payableInvoiceStateMapDE} from "../../../invoices/helper/invoice-state-maps";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-order-invoice-list-item]',
  styleUrls: ['./order-invoice-list-item.component.scss'],
  template: `
    <td class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>

      <div class="circle" [ngClass]="getCircleColorClass(invoice)"></div>

    </td>
    <td class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>
      <span>{{invoiceTypeName}}</span>
      <span *ngIf="invoice.invoiceNumber"> # {{invoice.invoiceNumber}}</span>
      <span *ngIf="!invoice.invoiceNumber"> Entwurf</span>

    </td>
    <td class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>
      {{invoice.netTotal.value|number:'1.2-2'}} {{invoice.netTotal.currency|currencyShortener}}
    </td>
    <td
      class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>
      {{invoice.amountOutstanding.value|number:'1.2-2'}} {{invoice.amountOutstanding.currency|currencyShortener}}
    </td>
    <td class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>
      {{invoice.state| myI18nSelect: payableInvoiceStateMapDE}}
    </td>
    <td class="pos-relative">
      <a class="stretched-link"
         [routerLink]="['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]"></a>
      {{invoice.orderDate|momentDateWithTime}}
    </td>

  `

})
export class OrderInvoiceListItemComponent implements OnInit {
  @Input() invoice: PayableInvoice | PartialInvoice;

  extractUUID = extractUUID;
  extractTypeByIri = extractTypeByIri;
  readonly payableInvoiceStateMapDE = payableInvoiceStateMapDE;

  getCircleColorClass(element: PayableInvoice | PartialInvoice): string[] {
    if (element.overdue) {
      return ['red'];
    } else if (!element.invoiceNumber) {
      return ['grey'];
    } else {
      return ['green'];
    }
  }

  get invoiceTypeName(): string {
    switch (extractTypeByIri(this.invoice)) {
      case 'partial_invoices':
        return 'Teilrechung';
      case 'invoices':
        return 'Rechnung';
      default:
        return extractTypeByIri(this.invoice);
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
