import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Offer} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {extractUUID, extractTypeByIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-offers-list',
  styleUrls: ['offers-list.component.scss'],
  template: `
    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >
      <!-- State Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>

          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>

      <!-- Offer Number Column -->
      <ng-container matColumnDef="offerNumber">
        <th mat-header-cell *matHeaderCellDef>Angebotsnr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{ element?.offerNumber }}</td>
      </ng-container>

      <!-- Order Number Column -->
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>A</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{element?.paymentProcess?.order ? element.paymentProcess.order.orderNumber : ''}}
        </td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Empfänger [Zusatz]</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>

          {{ element.recipient.nameLine1 }}

          <ng-container *ngIf="element.recipient.nameLine2">
            [{{ element.recipient.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>

      <!-- Issuer Column -->
      <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef>Bereich</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{ element.issuer.name }}</td>
      </ng-container>

      <!-- Net Total Column -->
      <ng-container matColumnDef="grossTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (brutto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{ element.grossTotal.value | number: '1.2-2' }}
          {{ element.grossTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Valid Until Column -->
      <ng-container matColumnDef="validUntil">
        <th mat-header-cell *matHeaderCellDef>Gültig bis</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          <strong [ngClass]="!!!element.confirmedAt ? 'red' : 'green'">{{
            element.validUntil | date: 'dd.MM.Y'
            }}</strong>
        </td>
      </ng-container>

      <!-- Confirmed At Column -->
      <ng-container matColumnDef="confirmedAt">
        <th mat-header-cell *matHeaderCellDef>Bestätigt am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{element.confirmedAt ? (element.confirmedAt | date: 'dd.MM.Y') : '-'}}
        </td>
      </ng-container>

      <!-- Net Total Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'offers', extractId(element)]"></a>
          {{ element.createdAt | date: 'dd.MM.Y' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        [class.green]="!!element.confirmedAt"
      ></tr>
    </table>
  `
})
export class OffersListComponent {
  @Input() items: Array<Offer>;
  @Input() totalItems: number;

  @Output() requestGoToOfferView: EventEmitter<string> = new EventEmitter();

  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractId = extractUUID;

  displayedColumns: Array<string> = [
    'dot',
    'offerNumber',
    'orderNumber',
    'recipient',
    'issuer',
    'grossTotal',
    'validUntil',
    'confirmedAt',
    'createdAt'
  ];

  constructor(private store$: Store<ApplicationState>) {
  }

  getCircleColorClass(element: Offer): string[] {
    if (!element.confirmedAt) {
      return ['grey'];
    } else {
      return ['green'];
    }
  }
}
