import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { LocalesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Locale };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(LocalesActions.ReadLocale, LocalesActions.ReadLocales, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(LocalesActions.ReadLocaleSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(LocalesActions.ReadLocalesSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Locale }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    LocalesActions.ReadLocaleFail,
    LocalesActions.ReadLocalesFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
