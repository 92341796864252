import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { TaxesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Tax };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    TaxesActions.CreateTax,
    TaxesActions.ReadTax,
    TaxesActions.ReadTaxes,
    TaxesActions.UpdateTax,
    TaxesActions.DeleteTax,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(TaxesActions.CreateTaxSuccess, (state, { response }) => {
    const entities = {
      [response['@id']]: response,
      ...state.entities
    };

    return {
      ...state,
      entities,
      errors: {},
      loading: false
    };
  }),
  on(
    TaxesActions.ReadTaxSuccess,
    TaxesActions.UpdateTaxSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(TaxesActions.ReadTaxesSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [id: string]: fromModuleModels.Tax }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      errors: {},
      loading: false,
      loaded: true
    };
  }),
  on(TaxesActions.DeleteTaxSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(
    TaxesActions.CreateTaxFail,
    TaxesActions.ReadTaxFail,
    TaxesActions.ReadTaxesFail,
    TaxesActions.UpdateTaxFail,
    TaxesActions.DeleteTaxFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
