import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit
} from '@angular/core';

import { formatNumber } from '@angular/common';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { DataRecoveryCosts, Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { isLoadingArray } from '../../utilities/observable.utility';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import {
  DataRecoveryCostsSelectors,
  OrderCommentsSelectors
} from '../../../orders/store/selectors';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DataRecoveryCostsActions } from '../../../orders/store';
import { TicketCostsFormDialogComponent } from '../../../tickets/components/ticket-costs-form-dialog/ticket-costs-form-dialog.component';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-ticket-costs-summary',
  styleUrls: ['ticket-costs-summary.component.scss'],
  template: `
    <div class="card ">
      <div class="card__heading">
        <span>Analyseergebnis für Intern / Kosten</span>

        <span class="btn--edit" *ngIf="canEdit">
          <button
            mat-icon-button
            [disabled]="!dataRecoveryCosts || dataRecoveryCosts.length <= 0"
            (click)="handleRequestTicketCostsForm()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </span>
      </div>
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <ng-container *ngFor="let dataRecoveryCost of dataRecoveryCosts">
        <div
          class="card__content p-a--24"
          *ngIf="
            order &&
            dataRecoveryCosts.length > 0 &&
            (couldSeePrices(dataRecoveryCost) | async)
          "
        >
          <!--<pre>{{dataRecoveryCost|json}}</pre>-->

          <dl class="list--dl grid">
            <dt class="column-4">Datum:</dt>
            <dd class="column-10 grid">
              <span class="column-14">{{
                dataRecoveryCost.createdAt | momentDate
              }}</span>
            </dd>

            <dt class="column-4">Analyselabor:</dt>
            <dd class="column-10 grid">
              <span class="column-14">{{
                dataRecoveryCost.analysisLocation
              }}</span>
            </dd>

            <dt class="column-4">Preis Initialkosten:</dt>
            <dd class="column-10 grid">
              <span class="column-7"
                >{{
                  getPrice('purchasePriceInitial', dataRecoveryCost)
                }}
                EK</span
              >
              <span class="column-7"
                >{{
                  getPrice('sellingPriceInitial', dataRecoveryCost)
                }}
                VK</span
              >
            </dd>

            <dt class="column-4">Preis Datenrettung:</dt>
            <dd class="column-10 grid">
              <span class="column-7"
                >{{
                  getPrice('purchasePriceDataRecovery', dataRecoveryCost)
                }}
                EK</span
              >
              <span class="column-7"
                >{{
                  getPrice('sellingPriceDataRecovery', dataRecoveryCost)
                }}
                VK</span
              >
            </dd>

            <dt class="column-4">Preis Sonstiges:</dt>
            <dd class="column-10 grid">
              <span class="column-7"
                >{{ getPrice('purchasePriceOther', dataRecoveryCost) }} EK</span
              >
              <span class="column-7"
                >{{ getPrice('sellingPriceOther', dataRecoveryCost) }} VK</span
              >
            </dd>

            <dt class="column-4">Bemerkung:</dt>
            <dd class="column-10 grid">
              <span class="column-7">{{
                getComment('purchasePriceComment', dataRecoveryCost)
              }}</span>
              <span class="column-7">{{
                getComment('sellingPriceComment', dataRecoveryCost)
              }}</span>
            </dd>
          </dl>
        </div>
      </ng-container>
      <div
        class="card__content p-a--24"
        *ngIf="!dataRecoveryCosts || dataRecoveryCosts.length <= 0"
      >
        Es sind noch keine Kosten definiert worden.
        <!--<button color="green" mat-button (click)="handleRequestNewTicketCostsForm()">Preise festlegen</button>-->
      </div>
    </div>

    <!--<pre>{{ dataRecoveryCosts | json }}</pre>-->
  `
})
export class TicketCostsSummaryComponent implements OnInit, OnDestroy {
  @Input() order$: BehaviorSubject<Order>;
  order: Order;

  dataRecoveryCosts: Array<DataRecoveryCosts>;

  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  get canEdit(): boolean {
    if (!this.dataRecoveryCosts || this.dataRecoveryCosts.length <= 0) {
      return false;
    }
    return (
      this.auth.isTechnician() ||
      this.auth.isTechnicianExternal() ||
      this.auth.isSupervisor() ||
      this.auth.isAdmin()
    );
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(OrderCommentsSelectors.isLoading),
      this.store$.select(DataRecoveryCostsSelectors.isLoading)
    ]);
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.loadDataRecoveryCosts(order);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestTicketCostsForm(): void {
    this.dialog.open(TicketCostsFormDialogComponent, {
      data: { order$: this.order$ }
    });
  }

  couldSeePrices(costs: DataRecoveryCosts): Observable<boolean> {
    return combineLatest([
      this.auth.isAdmin$,
      this.auth.isSupervisor$,
      this.auth.isTechnician$
    ]).pipe(
      map(([isAdmin, isSupervisor, isTechnician]) => {
        return (
          isAdmin ||
          isSupervisor ||
          (isTechnician && costs.analysisLocation.indexOf('_REMOTE') <= -1)
        );
      })
    );
  }

  handleRequestNewTicketCostsForm(): void {
    this.dialog.open(TicketCostsFormDialogComponent, {
      data: { order$: this.order$, create: true }
    });
  }

  getComment(commentName: string, dataRecoveryCost: DataRecoveryCosts): string {
    if (!dataRecoveryCost || !dataRecoveryCost[commentName]) {
      return '-';
    }
    return dataRecoveryCost[commentName];
  }

  getPrice(priceName: string, dataRecoveryCost: DataRecoveryCosts): any {
    if (!dataRecoveryCost || !dataRecoveryCost[priceName]) {
      return '-';
    }

    const price = dataRecoveryCost[priceName];
    return `${formatNumber(parseFloat(price.value), this.locale, '1.2-2')} ${
      price.currency
    }`;
  }

  private loadDataRecoveryCosts(order: Order): void {
    this.store$
      .select(DataRecoveryCostsSelectors.sByOrder(order['@id']))
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        filter(costs => !!costs)
      )
      .subscribe(costs => {
        this.dataRecoveryCosts = costs;
      });
    if (order.dataRecoveryCosts) {
      for (const iri of order.dataRecoveryCosts) {
        this.store$.dispatch(
          DataRecoveryCostsActions.ReadDataRecoveryCosts({ iri })
        );
      }
    }
  }
}
