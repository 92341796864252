<div class="grid grid-top" [formGroup]="ossf">

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Typ"
      [items]="storageSystemsS|async"
      [loading]="storageSystemsIsLoading$|async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [searchable]="false"
      [clearable]="true"
      formControlName="storageSystem"
    ></ng-select>
    <mat-error>
      <app-form-error [fieldName]="'storageSystem'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Betriebssystem"
      [items]="operatingSystems$|async"
      [loading]="operatingSystemsIsLoading$|async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="operatingSystem">
    </ng-select>
    <mat-error>
      <app-form-error [fieldName]="'operatingSystem'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Dateisystem"
      [items]="fileSystems$|async"
      [loading]="fileSystemsIsLoading$|async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [searchable]="false"
      [clearable]="false"
      formControlName="fileSystem"
    ></ng-select>
    <mat-error>
      <app-form-error [fieldName]="'fileSystem'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Künftiges Dateisystem"
      [items]="fileSystems$|async"
      [loading]="fileSystemsIsLoading$|async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [searchable]="false"
      [clearable]="true"
      formControlName="futureFileSystem"
    ></ng-select>
    <mat-error>
      <app-form-error [fieldName]="'futureFileSystem'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </div>

  <div class="column-7">
    <mat-form-field>
      <mat-label>Datenverlust Feststellung*</mat-label>
      <input type="text"
             matInput formControlName="dataLossDetectedAt">
      <mat-error>
        <app-form-error [fieldName]="'dataLossDetectedAt'" [formGroup]="ossf"></app-form-error>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="column-7">
    <mat-form-field>
      <mat-label>Datum Datenverlust*</mat-label>
      <input type="text"
             matInput formControlName="dataLossAt">
      <mat-error>
        <app-form-error [fieldName]="'dataLossAt'" [formGroup]="ossf"></app-form-error>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-form-field class="column-7">
    <mat-label>Host</mat-label>
    <input type="text" matInput formControlName="host">
    <mat-error>
      <app-form-error [fieldName]="'host'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>


  <mat-form-field class="column-7">
    <mat-label>PIN/Passwort</mat-label>
    <input matInput formControlName="pinPassword">
    <mat-error>
      <app-form-error [fieldName]="'pinPassword'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>


  <mat-form-field class="column-7">
    <mat-label>{{"orders.storage_system_form.serail_number" | translate}}</mat-label>
    <input matInput formControlName="storageManufacturerSerialNumber">
    <mat-error>
      <app-form-error [fieldName]="'storageManufacturerSerialNumber'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Symptom"
      [items]="symptoms$|async"
      [loading]="symptomsIsLoading$|async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [searchable]="false"
      [clearable]="true"
      formControlName="symptom"
    ></ng-select>
    <mat-error>
      <app-form-error [fieldName]="'symptom'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </div>

  <mat-form-field class="column-14">
    <mat-label>Historie</mat-label>
    <textarea matInput cdkTextareaAutosize rows="10"
              formControlName="history"
    ></textarea>
    <mat-error>
      <app-form-error [fieldName]="'history'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="column-14">
    <mat-label>Bereits erfolgte Schritte</mat-label>
    <textarea matInput cdkTextareaAutosize rows="10"
              formControlName="stepsAlreadyTaken"
    ></textarea>
    <mat-error>
      <app-form-error [fieldName]="'stepsAlreadyTaken'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="column-14">
    <mat-label>Benötigte Daten</mat-label>
    <textarea matInput
              cdkTextareaAutosize
              rows="10"
              formControlName="requiredData"
    ></textarea>
    <mat-error>
      <app-form-error [fieldName]="'requiredData'" [formGroup]="ossf"></app-form-error>
    </mat-error>
  </mat-form-field>

</div>
<!--<pre>{{ of.value | json }}</pre>-->
<!--<pre>{{ errors | json }}</pre>-->

<div class="row">

  <div class="col text-right">
    <button (click)="handleSubmit()" mat-button color="green">
      <mat-icon class="m-r--8">save</mat-icon>
      <span>Daten speichern</span>
    </button>
  </div>
</div>
