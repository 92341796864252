import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { Ticket, TicketComment, TicketCommentTag } from '../../models';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AdministratorFeedbackRequestsActions, AdministratorsActions} from '../../../administrators/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import {
  TicketCommentsSelectors,
  TicketCommentTagsSelectors
} from '../../store/selectors';
import { TicketCommentsActions, TicketCommentTagsActions } from '../../store';
import { TicketCommentFormDialogComponent } from '../ticket-comment-form-dialog/ticket-comment-form-dialog.component';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { Administrator } from '../../../administrators/models';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import {FeedbackRequestsActions, FeedbackRequestsSelectors} from '../../../notifications/store';
import { ReplyCommentModalComponent } from '../../../shared/components/reply-comment-modal/reply-comment-modal.component';
import { AuthService } from '../../../auth/services/auth.service';
import {AbstractApiService} from "../../../shared/services";
import {FeedbackRequest} from "../../../notifications/models";
import {Actions, ofType} from "@ngrx/effects";

@Component({
  selector: 'app-ticket-comments',
  styleUrls: ['ticket-comments.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Kommentare</span>
      </div>

      <div class="card__content">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="ticket__comments">
          <div *ngIf="(comments$ | async).length <= 0" class="item comment">
            <span *ngIf="isLoading$ | async">Kommentare werden geladen...</span>
            <span *ngIf="(isLoading$ | async) === false"
              >Keine Kommentare vorhanden.</span
            >
          </div>

          <div
            *ngFor="let comment of comments$ | async"
            class="item comment"
            [id]="comment['@id']"
            [ngClass]="{ active: comment['@id'] === targetComment }"
            [class.yellow]="comment.isFeedbackRequest && !comment.feedbackRequestResolved"
          >
            <div class="grid">
              <div class="column-10">
                <span class="created-by" *ngIf="comment.isFeedbackRequest"
                  >Rückfrage von
                  {{ getAdministratorName(comment.createdBy) }} an
                  {{
                    comment.recipient === currentUser['@id']
                      ? 'Dich'
                      : getAdministratorName(comment.recipient)
                  }}
                </span>
                <span class="created-by" *ngIf="!comment.isFeedbackRequest">{{
                  comment.additionalContent
                    ? 'Kundenkommentar'
                    : getAdministratorName(comment.createdBy)
                }}</span>
                <span class="created-at">
                  {{
                    comment.additionalContent
                      ? '(' + getAdministratorName(comment.createdBy) + ')'
                      : ''
                  }}
                  {{ comment.createdAt | date: 'medium' }}
                  <span
                    [hidden]="comment.createdAt === comment.updatedAt"
                    [matTooltip]="
                      'Bearbeitet: ' + (comment.updatedAt | date: 'medium')
                    "
                    class="text-small"
                    >(bearbeitet)
                  </span>
                </span>
                <span class="comment__tag" *ngIf="comment.commentTag">{{
                  getCommentTag(comment.commentTag)
                }}</span>
                <span class="action__tag" *ngIf="comment.actionTag">{{
                  comment.actionTag
                }}</span>
              </div>

              <div class="column-4 m-ta--2">
                <div
                  class="feedback-requests-actions"
                  *ngIf="comment.isFeedbackRequest && canEditAndDelete"
                >
                  <button
                    mat-icon-button
                    [matTooltip]="comment.feedbackRequestResolved ? ('shared.tooltip.mark_as_resolved' | translate) : ('shared.tooltip.mark_as_resolved' | translate)"
                    (click)="resolveFeedback(comment)"
                    [class.resolved]="comment.feedbackRequestResolved"
                  >
                    <mat-icon>check</mat-icon>
                  </button>
                  <!--                  <button mat-button color="green" class="small-button">-->
                  <!--                    <mat-icon>check</mat-icon>-->
                  <!--                    {{comment.feedbackRequestAnswered ? 'Mark as unanswered' : 'Mark as answered'}}-->
                  <!--                  </button>-->

                  <button
                    [matMenuTriggerFor]="dropdownMenu"
                    mat-icon-button
                    *ngIf="
                    (comment.isFeedbackRequest &&
                      comment.recipient === currentUser['@id']) ||
                    canEditAndDelete ||
                    comment.createdBy === currentUser['@id']
                  "
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                </div>

                <mat-menu #dropdownMenu xPosition="before">
                  <button
                    (click)="replyToComment(comment)"
                    data-action="reply"
                    *ngIf="
                      comment.isFeedbackRequest &&
                      comment.recipient === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Antworten</span>
                  </button>

                  <button
                    (click)="handleRequestTicketCommentForm(comment)"
                    data-action="edit"
                    *ngIf="
                      canEditAndDelete ||
                      comment.createdBy === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Kommentar bearbeiten</span>
                  </button>
                  <button
                    (click)="handleRequestDeleteTicketComment(comment)"
                    data-action="delete"
                    *ngIf="
                      canEditAndDelete ||
                      comment.createdBy === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Kommentar löschen</span>
                  </button>
                </mat-menu>
              </div>

              <div
                class="{{
                  comment.additionalContent ? 'column-12' : 'column-14'
                }}"
              >
                <div
                  *ngIf="comment?.content"
                  class="comment__content"
                  [innerHTML]="comment?.content | safeHtml"
                ></div>
                <div
                  *ngIf="!comment || !comment.content"
                  class="comment__content text-color-lightgrey"
                >
                  Kein Kommentar Text vorhanden
                </div>
              </div>

              <div *ngIf="comment.additionalContent" class="column-2 m-ta--2">
                <button
                  mat-icon-button
                  (click)="additionalContent.classList.toggle('is-expanded')"
                >
                  <mat-icon
                    >{{
                      additionalContent.classList.contains('is-expanded')
                        ? 'keyboard_arrow_up'
                        : 'keyboard_arrow_down'
                    }}
                  </mat-icon>
                </button>
              </div>

              <div class="column-14 comment__ac-wrapper" #additionalContent>
                <div
                  class="comment__additional-content"
                  *ngIf="comment.additionalContent"
                  [innerHTML]="comment.additionalContent | safeHtml"
                ></div>
              </div>
            </div>
          </div>

          <!--<div *ngIf="!!pagination$.getValue()?.next">
            <button
              class="btn--load-more"
              mat-button color="blue" (click)="handleRequestReadTicketComments()">
              <mat-icon>expand_more</mat-icon>
              <span>Ältere Kommentare laden</span>
            </button>
          </div>-->
        </div>
        <hr />
        <app-ticket-comment-form [ticket$]="ticket$"></app-ticket-comment-form>
      </div>
    </div>
  `
})
export class TicketCommentsComponent implements OnInit, OnDestroy {
  currentUser: any;

  @Input() ticket$: Observable<Ticket>;
  ticket: Ticket;

  administratorsEntities: { [iri: string]: Administrator };
  comments$: Observable<Array<TicketComment>> = of([]);
  tagsEntities: { [iri: string]: TicketCommentTag };

  targetComment = '';
  isLoading$: Observable<boolean>;
  userType: string;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private apiService: AbstractApiService
  ) {}

  get canEditAndDelete(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(TicketCommentsSelectors.isLoading),
      this.store$.select(TicketCommentTagsSelectors.isLoading)
    ]);
    this.scrollToSelectedComment();
    this.loadAdministrators();
    this.loadOrderCommentTags();
    this.ticket$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(t => !!t)
      )
      .subscribe(ticket => {
        this.ticket = ticket;
        this.loadComments(ticket);
      });
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getAdministratorName(iri: string): string {
    return (
      this.administratorsEntities[iri]?.firstName +
      ' ' +
      this.administratorsEntities[iri]?.lastName
    );
  }

  getCommentTag(iri: string): string | null {
    if (this.tagsEntities[iri]) {
      return this.tagsEntities[iri]?.commentTag;
    }
    return null;
  }

  scrollToSelectedComment(): void {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.targetComment = '/api/ticket_comments/' + params.target_comment;
      });
    this.comments$.pipe(takeUntil(this.onDestroy$)).subscribe(comments => {
      const vm = this;
      setTimeout(() => {
        if (vm.targetComment) {
          const target = document.getElementById(vm.targetComment);
          if (target) {
            target.scrollIntoView();
          }
        }
      }, 500);
    });
  }

  handleRequestTicketCommentForm(comment: TicketComment): void {
    this.dialog.open(TicketCommentFormDialogComponent, {
      disableClose: false,
      data: { ticket$: this.ticket$, comment$: new BehaviorSubject(comment), variation: 'answer', type: 'ticket' }
    });
  }

  handleRequestDeleteTicketComment(comment: TicketComment): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Kommentar löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          TicketCommentsActions.DeleteTicketComment({ iri: comment['@id'] })
        );
      });
  }

  replyToComment(comment: TicketComment): void {

    this.apiService.getObject(comment['@id'], true).subscribe((response) => {
      console.log(response);
      this.dialog.open(ReplyCommentModalComponent, {
        disableClose: true,
        panelClass: 'pos-relative',
        data: { response }
      });
    });

    //Update comments after close of dialog
    this.dialog.afterAllClosed.subscribe(
      () => {
        this.actions$
          .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.loadComments(this.ticket);
          })
      }
    )
  }

  private loadAdministrators(): void {
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.administratorsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }

  private loadOrderCommentTags(): void {
    this.store$
      .select(TicketCommentTagsSelectors.selectTicketCommentTagsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.tagsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      TicketCommentTagsSelectors.isLoaded,
      TicketCommentTagsActions.ReadTicketCommentTags()
    );
  }

  private loadComments(ticket: Ticket): void {
    this.comments$ = this.store$.select(TicketCommentsSelectors.sByTicketIri, {
      iri: ticket['@id']
    });
    this.store$.dispatch(
      TicketCommentsActions.ReadTicketComments({
        page: -1,
        params: { 'ticket.uuid': getUuidFromIri(ticket['@id']) }
      })
    );
  }


  resolveFeedback(comment: TicketComment): void {
    this.store$.dispatch(
      AdministratorFeedbackRequestsActions.PatchFeedbackRequests({
        iri: comment['@id'],
        module: 'ticket',
        variation: "resolve",
        payload: { feedbackRequestResolved: !comment.feedbackRequestResolved }
      })
    )
    this.actions$
      .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
      this.loadComments(this.ticket);
    })
  }
}
