import { IsAuthenticatedGuard } from './is-authenticated.guard';
import { IsNotAuthenticatedGuard } from './is-not-authenticated.guard';

export const AllGuards = [
  IsAuthenticatedGuard,
  IsNotAuthenticatedGuard
];

export * from './is-authenticated.guard';
export * from './is-not-authenticated.guard';
