import { createReducer, on } from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';
import { DocumentDeliveryProvidersActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: {
    [iri: string]: fromInvoicesModuleModels.DocumentDeliveryProvider;
  };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvider,
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviders,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviderSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvidersSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];

      const entities = items.reduce(
        (
          entities: {
            [id: string]: fromInvoicesModuleModels.DocumentDeliveryProvider;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviderFail,
    DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvidersFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
