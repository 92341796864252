import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { Grade } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class GradesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createGrade(payload: Grade): Observable<Grade> {
    return this.rolesService.userHasRoleFilter<Grade>(
      'ROLE_MWS_CUSTOMER_GRADE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/grades`, payload)
    );
  }

  getGrade(iri: string): Observable<Grade> {
    return this.rolesService.userHasRoleFilter<Grade>(
      'ROLE_MWS_CUSTOMER_GRADE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getGrades(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_GRADE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/grades`)
    );
  }

  updateGrade({ iri, payload }): Observable<Grade> {
    return this.rolesService.userHasRoleFilter<Grade>(
      'ROLE_MWS_CUSTOMER_GRADE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteGrade(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_GRADE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
