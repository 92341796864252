import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';
import {ParameterBag} from '../../../shared/models/ParameterBag.interface';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_DATA_MEDIUM_STATUS_LOG = '[Orders Module] Read Data Medium Status Log';
export const READ_DATA_MEDIUM_STATUS_LOG_SUCCESS = '[Orders Module] Read Data Medium Status Log Success';
export const READ_DATA_MEDIUM_STATUS_LOG_FAIL = '[Orders Module] Read Data Medium Status Log Fail';

export const ReadDataMediumStatusLog = createAction(
  READ_DATA_MEDIUM_STATUS_LOG,
  props<{ iri: string }>()
);

export const ReadDataMediumStatusLogSuccess = createAction(
  READ_DATA_MEDIUM_STATUS_LOG_SUCCESS,
  props<{ response: fromOrdersModuleModels.DataMediumStatusLog }>()
);

export const ReadDataMediumStatusLogFail = createAction(
  READ_DATA_MEDIUM_STATUS_LOG_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_DATA_MEDIUM_STATUS_LOGS = '[Orders Module] Read Data Medium Status Logs';
export const READ_DATA_MEDIUM_STATUS_LOGS_SUCCESS = '[Orders Module] Read Data Medium Status Logs Success';
export const READ_DATA_MEDIUM_STATUS_LOGS_FAIL = '[Orders Module] Read Data Medium Status Logs Fail';

export const ReadDataMediumStatusLogs = createAction(
  READ_DATA_MEDIUM_STATUS_LOGS,
  props<{ page: number, params: ParameterBag }>()
);

export const ReadDataMediumStatusLogsSuccess = createAction(
  READ_DATA_MEDIUM_STATUS_LOGS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadDataMediumStatusLogsFail = createAction(
  READ_DATA_MEDIUM_STATUS_LOGS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset State

export const RESET_DATA_MEDIUM_STATUS_LOGS_STATE = '[Orders Module] Reset Data Medium Status Logs State';

export const ResetDataMediumStatusLogsState = createAction(
  RESET_DATA_MEDIUM_STATUS_LOGS_STATE,
);
