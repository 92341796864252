import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerTypesActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [id: string]: fromModuleModels.CustomerType };
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(CustomerTypesActions.LoadCustomerTypes, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(CustomerTypesActions.LoadCustomerTypesSuccess, (state, { response }) => {
    const partnerStatuses = response['hydra:member'];
    const entities = partnerStatuses.reduce(
      (
        entities: { [id: string]: fromModuleModels.CustomerContactType },
        partnerStatus
      ) => {
        return {
          ...entities,
          [partnerStatus['@id']]: partnerStatus
        };
      },
      { ...state.entities }
    );

    return {
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
