import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import { ApplicationState } from '../../../application-state/store';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  DataMediumStatusLogsSelectors,
  ProductOrderItemsSelectors
} from '../../store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { DataMediumStatusLogsActions } from '../../store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataMediumStatusLog, Order } from '../../models';
import { DataMediumsActions } from '../../../warehouse/store';
import { DataMediumsSelectors } from '../../../warehouse/store/selectors';
import { DataMedium } from '../../../warehouse/models';
import { Shipment } from '../../../shipping/models';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { ShipmentsService } from '../../../shipping/services/shipments.service';
import { getDescriptionOfShipmentState } from '../../../shipping/helpers/shipmentStateDescriptionMapper';

@Component({
  selector: 'app-order-data-media-log',
  styleUrls: ['./order-data-media-log.component.scss'],
  template: `
    <div class="m-0">
      <app-dialog-header>
        <h2>Pakete / Versandstatus zu diesem Auftrag</h2>
      </app-dialog-header>

      <div mat-dialog-content class="pos-relative">
        <app-loading-overlay
          *ngIf="(isLoading$ | async) || (isLoadingShipments$ | async)"
        ></app-loading-overlay>
        <mat-tab-group>
          <mat-tab label="Pakete">
            <ng-container *ngIf="items$ | async as items">
              <div class="" *ngFor="let item of items">
                <app-shipment-display-line
                  [shipment]="item"
                ></app-shipment-display-line>
              </div>
              <div
                class="py-3"
                *ngIf="
                  (isLoading$ | async) === false &&
                  (!items || items.length <= 0)
                "
              >
                <p>Es sind keine Sendungen verfügbar!</p>
              </div>
            </ng-container>
          </mat-tab>
          <mat-tab label="Versandlogs" class="p-1">
            <div class="py-3" *ngIf="logs$ | async as logs">
              <p *ngIf="!logs || logs.length <= 0">
                Es sind keine Einträge vorhanden.
              </p>
              <table
                *ngIf="logs.length > 0"
                class="table table-striped bmo-table-hover"
              >
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Vorgang</th>
                    <th>Beschreibung</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let log of logs">
                    <td>{{ log.createdAt | momentDateWithTime }}</td>
                    <td>
                      {{ log.state | myI18nSelect: stateMapping }}
                      <span
                        class="badge rounded-pill text-bg-info"
                        *ngIf="
                          log.dataMedium &&
                          getDataMedia(log.dataMedium['@id']) &&
                          getDataMedia(log.dataMedium['@id']).state ===
                            log.state
                        "
                        >Letzer Zustand</span
                      >
                    </td>
                    <td>
                      <div *ngIf="log.dataMedium" class="">
                        <div class="col-md-6">
                          <ng-container
                            *ngIf="!getDataMedia(log.dataMedium['@id'])"
                          >
                            <p>Dieser Datenträger wurde nicht gefunden.</p>
                          </ng-container>
                          <ng-container
                            *ngIf="getDataMedia(log.dataMedium['@id'])"
                          >
                            <pre
                              >{{ log.dataMedium | json }}
                          {{ getDataMedia(log.dataMedium['@id']) | json }}</pre
                            >
                            <strong
                              >{{
                                getDataMedia(log.dataMedium['@id'])
                                  ?.storageSystemType?.name
                              }}:</strong
                            >
                            {{
                              getDataMedia(log.dataMedium['@id'])
                                ?.storageSystemManufacturer?.name
                            }},
                            {{
                              getDataMedia(log.dataMedium['@id'])
                                ?.storageSystemSize *
                                1024 *
                                1024 *
                                1024 | bytes: 2
                            }},
                            {{
                              getDataMedia(log.dataMedium['@id'])
                                ?.storageSystemModel
                            }}
                            ({{
                              getDataMedia(log.dataMedium['@id'])
                                ?.storageSystemSerialNumber
                            }})
                          </ng-container>
                        </div>
                        <div class="col-md-6">
                          <ng-container
                            *ngIf="getDataMedia(log.dataMedium['@id'])"
                          >
                            <strong>Lager:</strong>
                            {{
                              getDataMedia(log.dataMedium['@id'])
                                ?.storageLocationNumber
                            }}
                            <br />

                            <strong>Lagerplatz:</strong>
                            {{
                              getDataMedia(log.dataMedium['@id'])?.storageNumber
                            }}
                          </ng-container>
                        </div>
                      </div>
                      <hr *ngIf="log.dataMedium && log.shipment" />
                      <div *ngIf="log.shipment" class="">
                        <!--<pre>{{log.shipment|json}}</pre>-->
                        <div
                          class="col-md-6"
                          [matTooltip]="
                            getDescriptionOfShipmentState(log.shipment['state'])
                          "
                        >
                          <strong>{{
                            log.shipment['@type'] | myI18nSelect: shipmentTypes
                          }}</strong>
                          <p>
                            {{
                              log.shipment['state']
                                | myI18nSelect: shipmentState
                            }}
                          </p>
                        </div>
                        <div
                          class="col-md-6"
                          *ngIf="
                            log.shipment.trackingLink &&
                            log.shipment.trackingNumber
                          "
                        >
                          Tracking:
                          <a
                            [href]="log.shipment.trackingLink"
                            target="_blank"
                            >{{ log.shipment.trackingNumber }}</a
                          >
                        </div>
                        <div
                          class="col-md-6"
                          *ngIf="
                            !log.shipment.trackingLink &&
                            log.shipment.trackingNumber
                          "
                        >
                          TrackingNr: {{ log.shipment.trackingNumber }}
                        </div>
                        <div
                          class="col-md-6"
                          *ngIf="
                            !log.shipment.trackingLink &&
                            !log.shipment.trackingNumber
                          "
                        >
                          Kein Tracking verfügbar.
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  `
})
export class OrderDataMediaLogComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;
  items$: BehaviorSubject<Array<Shipment>> = new BehaviorSubject<
    Array<Shipment>
  >([]);
  isLoadingShipments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  logs$: Observable<Array<DataMediumStatusLog>>;

  onDestroy$: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;
  dataMediumsEntities: { [key: string]: DataMedium };

  shipmentTypes: { [key: string]: string } = {
    UpsShipment: 'Versand UPS',
    GoPickupShipment: 'Abholung GO',
    DhlShipment: 'Labelversand DHL',
    GenericShipment: 'Generischer Versand'
  };
  stateMapping: { [key: string]: string } = {
    reserved: 'Reserviert',
    in_shipment_dr_de_to_dr_ext: 'Warenausgang DR DE nach DR EXT',
    stored_dr_de: 'Eingelagert in DR DE'
  };
  shipmentState: { [key: string]: string } = {
    vorgemerkt: 'Der Versand wurde vorgemerkt.',
    zugestellt: 'Das Paket wurde zugestellt.',
    // tslint:disable-next-line
    bestätigt: 'Der Versand wurde bestätigt.'
  };
  protected readonly getDescriptionOfShipmentState = getDescriptionOfShipmentState;

  constructor(
    private backendService: ShipmentsService,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<OrderDataMediaLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order> }
  ) {}

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProductOrderItemsSelectors.isLoading),
      this.store$.select(DataMediumsSelectors.isLoading)
    ]);
    this.data.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        const orderUUID = getUuidFromIri(order['@id']);
        this.store$.dispatch(
          DataMediumsActions.ReadDataMediums({
            page: -1,
            params: { 'stockItem.order.uuid': orderUUID }
          })
        );
        this.store$
          .select(DataMediumsSelectors.selectDataMediumsByOrderId, {
            orderIri: order['@id']
          })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(data => {
            this.dataMediumsEntities = keyBy(data, '@id');
          });

        this.store$.dispatch(
          DataMediumStatusLogsActions.ReadDataMediumStatusLogs({
            page: -1,
            params: { 'order.uuid': getUuidFromIri(order['@id']) }
          })
        );
        this.logs$ = this.store$.select(
          DataMediumStatusLogsSelectors.selectDataMediumStatusLogsByOrder,
          { iri: order['@id'] }
        );
        this.loadShipments();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getDataMedia(iri): DataMedium {
    if (!this.dataMediumsEntities) {
      return null;
    }
    return this.dataMediumsEntities[iri];
  }

  close(): void {
    this.matDialogRef.close();
  }

  loadShipments(): void {
    this.isLoadingShipments$.next(true);

    const params: any = {
      pagination: true,
      'order[createdAt]': 'desc',
      'orders.uuid': getUuidFromIri(extractIri(this.order))
    };

    this.backendService.readShipments(-1, params).subscribe(
      data => {
        this.items$.next(data['hydra:member']);
        this.isLoadingShipments$.next(false);
      },
      error => {
        console.log(error);
        this.isLoadingShipments$.next(false);
      }
    );
  }
}
