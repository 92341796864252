import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PotentialAction } from '../../models';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AnalysisResultCategoriesSelectors,
  AnalysisResultsSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import {
  AnalysisResultCategoriesActions,
  AnalysisResultsActions
} from '../../../master-data/store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  AnalysisResult,
  AnalysisResultCategory
} from '../../../master-data/models';
import { OrdersActions } from '../../../orders/store';
import { TransitionsActions } from '../../../application-state/store/actions';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-customer-analysis-created',
  styleUrls: ['./action-box-to-customer-analysis-created.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Analyseergebnis für Kunden</div>
              <div class="sub-header col-auto">
                Bitte Analyseergebnis für Kunden definieren
              </div>
            </div>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Analyseergebnis für Kunden</div>
          <div class="sub-header col-auto">
            Warte auf Definierung des Analyseergebnis für Kunden
          </div>
        </div>
      </div>
      <div class="additional-content p-4" *ngIf="canMakeTransition">
        <div class="row">
          <div class="offset-sm-6 col-sm-3">
            <ng-select
              [items]="analysisResultCategories$ | async"
              [loading]="analysisResultCategoriesIsLoading$ | async"
              [searchable]="false"
              [markFirst]="false"
              (change)="handleUpdateAnalysisResultCategory($event)"
              bindValue="@id"
              placeholder="Vorlagen-Kategorie"
              bindLabel="name"
            ></ng-select>
          </div>
          <div class="col-sm-3">
            <ng-select
              [items]="analysisResults$ | async"
              [loading]="analysisResultsIsLoading$ | async"
              [searchable]="false"
              [markFirst]="false"
              bindValue="@id"
              (change)="handleSetTemplateText($event)"
              [(ngModel)]="selectedAnalysisResult"
              placeholder="Vorlage auswählen"
              bindLabel="name"
            ></ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" [formGroup]="form">
            <app-text-editor
              formControlName="analysisResult"
              label="Analyseergebnis für Kunden"
              placeholder="Analyseergebnis für Kunden hier eingeben"
            ></app-text-editor>
          </div>
        </div>
        <div class="row" style="margin-top: 50px;">
          <div class="col text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid || !okAction"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Analyseergebnis speichern</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToCustomerAnalysisCreatedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;

  analysisResultCategories$: Observable<Array<AnalysisResultCategory>>;
  analysisResultCategoriesIsLoading$: Observable<boolean>;
  analysisResults$: Observable<Array<AnalysisResult>>;
  analysisResultsIsLoading$: Observable<boolean>;

  form: FormGroup;
  selectedAnalysisResult: string = null;

  okAction: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private fb: FormBuilder,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadAnalysisResultCategories();
    this.loadAnalysisResults();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (this.order.analysisResult) {
          this.form.get('analysisResult').setValue(this.order.analysisResult);
        }
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('to_customer_analysis_created') > -1 &&
            !e.error
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      analysisResult: this.fb.control(this.order?.analysisResult, [
        Validators.required
      ])
    });
  }

  makeTransition2(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    this.store$.dispatch(
      OrdersActions.UpdateOrderAnalysisResult({
        iri: this.order['@id'],
        payload: { analysisResult: this.form.get('analysisResult').value }
      })
    );

    this.actions$
      .pipe(
        ofType(OrdersActions.UpdateOrderAnalysisResultSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.makeTransition2();
      });
  }

  handleSetTemplateText(option: AnalysisResult): void {
    this.form.get('analysisResult').setValue(option.content);
  }

  handleUpdateAnalysisResultCategory(option: AnalysisResult): void {
    console.log(option);
    this.analysisResults$ = this.store$.select(
      AnalysisResultsSelectors.byCategory(option['@id'])
    );
    this.selectedAnalysisResult = null;
  }

  private loadAnalysisResultCategories(): void {
    this.analysisResultCategories$ = this.store$.select(
      AnalysisResultCategoriesSelectors.selectAnalysisResultCategories
    );
    this.analysisResultCategoriesIsLoading$ = this.store$.select(
      AnalysisResultCategoriesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisResultCategoriesSelectors.isLoaded,
      AnalysisResultCategoriesActions.ReadAnalysisResultCategories()
    );
  }

  private loadAnalysisResults(): void {
    this.analysisResultsIsLoading$ = this.store$.select(
      AnalysisResultsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisResultsSelectors.isLoaded,
      AnalysisResultsActions.ReadAnalysisResults()
    );
  }
}
