import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Order, ProductOrderItem} from '../../../orders/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MatDialog} from '@angular/material/dialog';
import {filter, takeUntil} from 'rxjs/operators';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {ProductOrderItemsActions} from '../../../orders/store';
import {ProductOrderItemsSelectors} from '../../../orders/store/selectors';
import {OrderItemDialogComponent} from '../../../orders/components/order-item-dialog/order-item-dialog.component';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-ticket-replacement-media-card',
  styleUrls: ['./ticket-replacement-media-card.component.scss'],
  template: `
    <div class="card">

      <div class="card__heading">
        <span>Ersatzdatenträger</span>
        <span class="text-right btn--edit">
          <button class="button--add color-white" (click)="handleRequestCreateProductOrderItemForm()" color="green"
                  mat-button>
            <mat-icon>add</mat-icon>
          </button>
        </span>
      </div>


      <div class="card__content p-a--24" *ngIf="isLoading$|async">
        <app-loading-overlay></app-loading-overlay>

      </div>
      <div class="card__content p-a--24 container-fluid" *ngIf="(dataMedia$|async) as medias">
        <p *ngIf="!medias || medias.length<=0">Es ist kein Ersatzdatenträger hinzugefügt.</p>
        <ng-container *ngFor="let media of medias">
          <!--<pre>{{media|json}}</pre>-->
          <div class="row border-bottom align-items-center">
            <div class="col-10 ">
              <div class="fw-bold my-2">
                {{media.product?.storageSystemManufacturer?.name}},
                {{media.product?.storageSystemSize * 1024 * 1024 * 1024|bytes:2}},
                {{media.product?.name}}
              </div>
              <div class="row my-2">
                <div class="col-6">
                  Lagerplatz: {{media.replacementDataMedium.storageNumber}}
                </div>
                <div class="col-6">
                  Datenträger Nr: {{media.replacementDataMedium.storageSystemSerialNumber}}

                </div>
              </div>
            </div>
            <div class="col-2 text-center">
              <mat-icon class="text-color-red cursor-pointer" (click)="deleteProductOrderItem(media)">
                delete_forever
              </mat-icon>

            </div>
          </div>
        </ng-container>
      </div>
    </div>

  `
})
export class TicketReplacementMediaCardComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;

  dataMedia$: Observable<Array<ProductOrderItem>>;

  onDestroy$: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;

  constructor(private store$: Store<ApplicationState>, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProductOrderItemsSelectors.isLoading),
    ]);

    this.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      this.order = order;
      this.store$.dispatch(ProductOrderItemsActions.ReadProductOrderItems({
        page: -1,
        orderUuid: getUuidFromIri(order['@id'])
      }));
      this.dataMedia$ = this.store$.select(ProductOrderItemsSelectors.selectProductOrderItemsForOrder, {iri: order['@id']});
    });


  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestCreateProductOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {data: {order: this.order, type: 'product'}});
  }

  deleteProductOrderItem(media: ProductOrderItem): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Ersatzdatenträger wirklich entfernt werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, entfernen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(ProductOrderItemsActions.DeleteProductOrderItem({iri: media['@id']}));
      });

  }
}
