import { Component, Input, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { PotentialAction } from '../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { AuthService } from '../../../auth/services/auth.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { DataRecoveryCostsActions } from '../../../orders/store';
import { DataRecoveryCostsSelectors } from '../../../orders/store/selectors';

@Component({
  selector: 'app-action-box-data-recovery-costs-checked-by-supervisor',
  styleUrls: [
    './action-box-data-recovery-costs-checked-by-supervisor.component.scss'
  ],
  template: `
    <div class="action-box" *ngIf="canMakeTransition">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">EK Preis bestätigen</div>
              <div class="sub-header col-auto">
                Bitte bestätigen Sie den Einkaufspreis
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right"></div>
        </div>
      </div>
      <div
        class="additional-content p-2"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-lg-5">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1  mx-1">
                <h4 class="mb-1">Berechneter Preis Datenrettung</h4>
                <mat-form-field
                  formGroupName="calculatedPurchasePriceDataRecovery"
                >
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    readonly
                    matInput
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                </mat-form-field>
              </div>
              <div class="flex-grow-1  mx-1">
                <h4 class="mb-1">Preis Datenrettung überschreiben</h4>
                <mat-form-field formGroupName="purchasePriceDataRecovery">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    matInput
                    required
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'purchasePriceDataRecovery.value'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div
                class="text-color-black flex-grow-0"
                style="min-width: 26px; display: inline-block; white-space: nowrap;"
              >
                <span
                  style="white-space: nowrap;"
                  [class.text-color-red]="
                    purchasePriceDataRecoveryDifference > 0
                  "
                  [class.text-color-green]="
                    purchasePriceDataRecoveryDifference < 0
                  "
                  >{{ purchasePriceDataRecoveryDifferenceString }}</span
                >
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1  mx-1">
                <h4 class="mb-1">Berechneter Preis Sonstiges</h4>
                <mat-form-field formGroupName="calculatedPurchasePriceOther">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    readonly
                    matInput
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                </mat-form-field>
              </div>
              <div class="flex-grow-1  mx-1">
                <h4 class="mb-1">Preis Sonstiges überschreiben</h4>
                <mat-form-field formGroupName="purchasePriceOther">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    matInput
                    required
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'purchasePriceOther.value'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div
                class="text-color-black flex-grow-0"
                style="min-width: 26px; display: inline-block; white-space: nowrap;"
              >
                <span
                  style="white-space: nowrap;"
                  [class.text-color-red]="purchasePriceOtherDifference > 0"
                  [class.text-color-green]="purchasePriceOtherDifference < 0"
                  >{{ purchasePriceOtherDifferenceString }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <mat-form-field>
              <mat-label>Kommentar</mat-label>
              <textarea
                matInput
                formControlName="purchasePriceComment"
                rows="10"
                cdkTextareaAutosize
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'purchasePriceComment'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                class="decision-btn me-3"
                mat-button
                color="green"
                (click)="submitForm()"
                [disabled]="form.invalid"
              >
                <mat-icon class="me-2">done</mat-icon>
                <span>Preise bestätigen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxDataRecoveryCostsCheckedBySupervisorComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  form: FormGroup;

  protected readonly parseFloat = parseFloat;
  private costs: any;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isAdmin() || this.authService.isSupervisor()) &&
        this.costs?.supervisorCheckRequired === true &&
        this.costs?.checkedBySupervisor === false) ||
      false
    );
  }

  get purchasePriceDataRecoveryDifference(): number {
    const calculatedPrice = this.form?.get(
      'calculatedPurchasePriceDataRecovery.value'
    )?.value;
    const userPrice = this.form?.get('purchasePriceDataRecovery.value')?.value;
    if (!calculatedPrice || !userPrice) {
      return Number.POSITIVE_INFINITY;
    }
    return this.calculatePriceDifference(calculatedPrice, userPrice);
  }

  get purchasePriceDataRecoveryDifferenceString(): string {
    const difference = this.purchasePriceDataRecoveryDifference;
    if (!Number.isFinite(difference)) {
      return '-';
    } else if (difference === 0.0) {
      return '0 %';
    } else if (difference > 0.0) {
      return '+ ' + Math.round(difference * 1000) / 10 + ' %';
    } else {
      return Math.round(difference * 1000) / 10 + ' %';
    }
  }

  get purchasePriceOtherDifference(): number {
    const calculatedPrice = this.form?.get('calculatedPurchasePriceOther.value')
      ?.value;
    const userPrice = this.form?.get('purchasePriceOther.value')?.value;
    if (!calculatedPrice || !userPrice) {
      return 0.0;
    }
    return this.calculatePriceDifference(calculatedPrice, userPrice);
  }

  get purchasePriceOtherDifferenceString(): string {
    const difference = this.purchasePriceOtherDifference;
    if (!Number.isFinite(difference)) {
      return '-';
    } else if (difference === 0.0) {
      return '0 %';
    } else if (difference > 0.0) {
      return '+ ' + Math.round(difference * 1000) / 10 + ' %';
    } else {
      return Math.round(difference * 1000) / 10 + ' %';
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (order.dataRecoveryCosts && order.dataRecoveryCosts.length > 0) {
          const lastDataRecoveryCosts =
            order.dataRecoveryCosts[order.dataRecoveryCosts.length - 1];
          this.store$.dispatch(
            DataRecoveryCostsActions.ReadDataRecoveryCosts({
              iri: lastDataRecoveryCosts
            })
          );
          this.store$
            .select(DataRecoveryCostsSelectors.sByIri, {
              iri: lastDataRecoveryCosts
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(DRCosts => !!DRCosts)
            )
            .subscribe(costs => {
              this.costs = costs;
              this.form.patchValue(costs);
            });
        }
      });
  }

  calculatePriceDifference(calculatedPrice: string, userPrice: string): number {
    const fUserPrice = parseFloat(userPrice);
    const fCalculatedPrice = parseFloat(calculatedPrice);
    if (fUserPrice === fCalculatedPrice) {
      return 0.0;
    } else if (fUserPrice > fCalculatedPrice) {
      return (fUserPrice - fCalculatedPrice) / fCalculatedPrice;
    } else {
      return (fUserPrice - fCalculatedPrice) / fCalculatedPrice;
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      purchasePriceDataRecovery: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      purchasePriceOther: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      calculatedPurchasePriceDataRecovery: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      calculatedPurchasePriceOther: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      purchasePriceComment: this.fb.control('')
    });
  }

  submitForm(): void {
    const lastDRCostsIri = this.order.dataRecoveryCosts[
      this.order.dataRecoveryCosts.length - 1
    ];
    const values = this.form.getRawValue();
    const payload: any = { ['@id']: lastDRCostsIri, ...values };
    payload.purchasePriceDataRecovery.value =
      payload.purchasePriceDataRecovery.value.toString() || '0.0';
    payload.purchasePriceOther.value =
      payload.purchasePriceOther.value.toString() || '0.0';
    this.store$.dispatch(
      DataRecoveryCostsActions.UpdatePurchasePrices({
        iri: lastDRCostsIri,
        payload
      })
    );
    this.actions$
      .pipe(
        ofType(DataRecoveryCostsActions.UpdatePurchasePricesSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          DataRecoveryCostsActions.UpdateCheckedBySupervisor({
            iri: lastDRCostsIri,
            payload: { checkedBySupervisor: true }
          })
        );
      });
  }
}
