import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {LeadOriginTypesActions} from '../actions';
import * as fromModuleServices from '../../services';

@Injectable()
export class LeadOriginTypesEffects {

  constructor(private actions$: Actions, private los: fromModuleServices.LeadOriginTypesService) {
  }


  loadLeadOriginTypes$ = createEffect(() => this.actions$.pipe(
    ofType(LeadOriginTypesActions.LoadLeadOriginTypes),
    map(action => action),
    switchMap(() => {

      return this.los
        .getLeadOriginTypes()
        .pipe(
          map((response: any) => LeadOriginTypesActions.LoadLeadOriginTypesSuccess({response}),
            catchError((response: HttpErrorResponse) => of(LeadOriginTypesActions.LoadLeadOriginTypesFail({response}))))
        );
    })
  ));


  loadLeadOriginType$ = createEffect(() => this.actions$.pipe(
    ofType(LeadOriginTypesActions.LoadLeadOriginType),
    map(action => action),
    switchMap(({iri}) => {

      return this.los
        .getLeadOriginType(iri)
        .pipe(
          map((response: any) => LeadOriginTypesActions.LoadLeadOriginTypeSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadOriginTypesActions.LoadLeadOriginTypeFail({response})))
        );
    })
  ));
}
