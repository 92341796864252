import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SalutationsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { SalutationsService } from '../../services/salutations.service';

@Injectable()
export class SalutationsEffects {
  createSalutation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalutationsActions.CreateSalutation),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createSalutation(payload).pipe(
          map(response =>
            SalutationsActions.CreateSalutationSuccess({ response })
          ),
          catchError(response =>
            of(SalutationsActions.CreateSalutationFail({ response }))
          )
        );
      })
    )
  );
  getSalutation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalutationsActions.ReadSalutation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getSalutation(iri).pipe(
          map(response =>
            SalutationsActions.ReadSalutationSuccess({ response })
          ),
          catchError(response =>
            of(SalutationsActions.ReadSalutationFail({ response }))
          )
        );
      })
    )
  );
  getSalutations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalutationsActions.ReadSalutations),
      map(action => action),
      switchMap(() => {
        return this.service.getSalutations().pipe(
          map(response =>
            SalutationsActions.ReadSalutationsSuccess({ response })
          ),
          catchError(response =>
            of(SalutationsActions.ReadSalutationsFail({ response }))
          )
        );
      })
    )
  );
  updateSalutation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalutationsActions.UpdateSalutation),
      map(action => action),
      switchMap(payload => {
        return this.service.updateSalutation(payload).pipe(
          map(response =>
            SalutationsActions.UpdateSalutationSuccess({ response })
          ),
          catchError(response =>
            of(SalutationsActions.UpdateSalutationFail({ response }))
          )
        );
      })
    )
  );
  deleteSalutation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalutationsActions.DeleteSalutation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteSalutation(iri).pipe(
          map(() => SalutationsActions.DeleteSalutationSuccess({ iri })),
          catchError(response =>
            of(SalutationsActions.DeleteSalutationFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SalutationsActions.DeleteSalutationFail,
          SalutationsActions.UpdateSalutationFail,
          SalutationsActions.ReadSalutationsFail,
          SalutationsActions.ReadSalutationFail,
          SalutationsActions.CreateSalutationFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  fileSystemActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SalutationsActions.CreateSalutationSuccess,
        SalutationsActions.UpdateSalutationSuccess
      ),
      map(({ type }) => {
        const text =
          type === SalutationsActions.CREATE_SALUTATION_SUCCESS
            ? 'Eine neue Anrede wurde angelegt.'
            : 'Die Anrede wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: SalutationsService,
    private notifierService: NotifierService
  ) {}
}
