import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';


import {SharedModule} from '../shared/shared.module';
import * as fromModuleContainers from './containers';
import * as fromModuleComponents from './components';
import * as fromModuleGuards from './guards';
import * as fromModuleServices from './services';
import {SalespersonSelectComponent} from './components/salesperson-select/salesperson-select.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { FeedbackRequestsReceivedWidgetComponent } from './components/feedback-requests-received-widget/feedback-requests-received-widget.component';
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    TranslateModule,
    MatButtonToggleModule,
    MatSortModule,
  ],
  providers: [
    ...fromModuleServices.AllServices,
    ...fromModuleGuards.AllGuards
  ],
  declarations: [
    ...fromModuleContainers.AllContainers,
    ...fromModuleComponents.AllComponents,
    SalespersonSelectComponent,
    FeedbackRequestsReceivedWidgetComponent,
  ],
  exports: [
    ...fromModuleContainers.AllContainers,
    ...fromModuleComponents.AllComponents,
    SalespersonSelectComponent,
    FeedbackRequestsReceivedWidgetComponent,
  ]
})
export class AdministratorsModule {
}
