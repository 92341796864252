import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {CancellationInvoicesActions, PayableInvoicesActions} from '../actions/';
import {CancellationInvoicesService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {NotifierService} from "angular-notifier";
import {OrdersActions} from "../../../orders/store";

@Injectable()
export class CancellationInvoicesEffects {

  constructor(private actions$: Actions, private service: CancellationInvoicesService, private notifierService: NotifierService) {
  }


  CreateCancellationInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.CreateCancellationInvoice),
    switchMap(({payload}) => {
      return this.service.createCancellationInvoice(payload).pipe(
        map((response) => CancellationInvoicesActions.CreateCancellationInvoiceSuccess({response})),
        catchError(response => of(CancellationInvoicesActions.CreateCancellationInvoiceFail({response})))
      );
    })
  ));


  ReadCancellationInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.ReadCancellationInvoice),
    switchMap(({iri}) => {
      return this.service.readCancellationInvoice(iri).pipe(
        map((response => CancellationInvoicesActions.ReadCancellationInvoiceSuccess({response}))),
        catchError(response => of(CancellationInvoicesActions.ReadCancellationInvoiceFail({response})))
      );
    })
  ));
  ReadPDF$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.ReadCancellationInvoicePDF),
    switchMap(({iri}) => {
      return this.service.readCancellationInvoiceAsPdf(iri).pipe(
        map((response: any) => CancellationInvoicesActions.ReadCancellationInvoicePDFSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CancellationInvoicesActions.ReadCancellationInvoicePDFFail({response})))
      );
    })
  ));
  SuccessDownloadAction = createEffect(() => this.actions$.pipe(
    ofType(
      CancellationInvoicesActions.ReadCancellationInvoicePDFSuccess,
    ),
    map(({response: {contentUrl, fileName}}) => {
      const a = document.createElement('a');
      a.href = contentUrl;
      a.download = fileName; // File name Here
      a.click(); // Downloaded file
      a.remove();
    })
  ), {dispatch: false});


  ReadCancellationInvoices$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.ReadCancellationInvoices),
    switchMap(({page, params}) => {
      return this.service.readCancellationInvoices(page, params).pipe(
        map((response) => CancellationInvoicesActions.ReadCancellationInvoicesSuccess({response})),
        catchError(response => of(CancellationInvoicesActions.ReadCancellationInvoicesFail({response})))
      );
    })
  ));


  ReadCancellationInvoiceChunk$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.ReadCancellationInvoiceChunk),
    switchMap(({uri}) => {
      return this.service.readCancellationInvoiceChunk(uri).pipe(
        map((response) => CancellationInvoicesActions.ReadCancellationInvoiceChunkSuccess({response})),
        catchError(response => of(CancellationInvoicesActions.ReadCancellationInvoiceChunkFail({response})))
      );
    })
  ));


  UpdateCancellationInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.UpdateCancellationInvoice),
    switchMap(({iri, payload}) => {
      return this.service.updateCancellationInvoice(iri, payload).pipe(
        map((response: fromModuleModels.Offer) => CancellationInvoicesActions.UpdateCancellationInvoiceSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CancellationInvoicesActions.UpdateCancellationInvoiceFail({response})))
      );
    })
  ));


  DeleteCancellationInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.DeleteCancellationInvoice),
    switchMap(({iri}) => {
      return this.service.deleteCancellationInvoice(iri).pipe(
        map(() => CancellationInvoicesActions.DeleteCancellationInvoiceSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(CancellationInvoicesActions.DeleteCancellationInvoiceFail({response})))
      );
    })
  ));
  MailInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CancellationInvoicesActions.MailCancellationInvoice),
    switchMap(({iri, payload}) => {
      return this.service.sendMail(iri, payload).pipe(
        map((response) => CancellationInvoicesActions.MailCancellationInvoiceSuccess({response})),
        catchError(response => of(CancellationInvoicesActions.MailCancellationInvoiceFail({response})))
      );
    })
  ));

  success$ = createEffect(() => this.actions$.pipe(
    ofType(
      CancellationInvoicesActions.CreateCancellationInvoiceSuccess,
      CancellationInvoicesActions.UpdateCancellationInvoiceSuccess,
      CancellationInvoicesActions.DeleteCancellationInvoiceSuccess,
      CancellationInvoicesActions.MailCancellationInvoiceSuccess,
    ),
    map(({type}) => {

      const textOptions = {
        [CancellationInvoicesActions.CREATE_CANCELLATION_INVOICE_SUCCESS]: 'Die Stornorechnung wurde erstellt.',
        [CancellationInvoicesActions.UPDATE_CANCELLATION_INVOICE_SUCCESS]: 'Die Stornorechnung wurde aktualisiert.',
        [CancellationInvoicesActions.DELETE_CANCELLATION_INVOICE_SUCCESS]: 'Die Stornorechnung wurde gelöscht.',
        [CancellationInvoicesActions.MAIL_CANCELLATION_INVOICE_SUCCESS]: 'Die Email wurde erfolgreich verschickt.',
      };
      this.notifierService.show({type: 'success', message: textOptions[type]});

    })
  ), {dispatch: false});
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      CancellationInvoicesActions.CreateCancellationInvoiceFail,
      CancellationInvoicesActions.UpdateCancellationInvoiceFail,
      CancellationInvoicesActions.DeleteCancellationInvoiceFail,
      CancellationInvoicesActions.MailCancellationInvoiceFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case CancellationInvoicesActions.CREATE_CANCELLATION_INVOICE_FAIL:
          message = 'Beim Anlegen einer neuen Stornorechnung sind Fehler aufgetreten:' + errors;
          break;
        case CancellationInvoicesActions.UPDATE_CANCELLATION_INVOICE_FAIL:
          message = 'Beim Aktualisieren der Stornorechnung sind Fehler aufgetreten:' + errors;
          break;
        case CancellationInvoicesActions.DELETE_CANCELLATION_INVOICE_FAIL:
          message = 'Beim Löschen der Stornorechnung ist ein Fehler aufgetreten:' + errors;
          break;
        case CancellationInvoicesActions.MAIL_CANCELLATION_INVOICE_FAIL:
          message = 'Beim senden der E-Mail sind Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});


}
