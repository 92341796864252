import {createSelector} from '@ngrx/store';

import {COMMISSION_CREDIT_ITEMS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectCommissionCreditItemsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[COMMISSION_CREDIT_ITEMS_FEATURE_KEY]
);

export const selectCommissionCreditItemsEntities = createSelector(
  selectCommissionCreditItemsState,
  (state) => state.entities
);

export const selectCommissionCreditItems = createSelector(
  selectCommissionCreditItemsEntities,
  (customers) => Object.keys(customers).map(iri => customers[iri])
);

export const selectCommissionCreditItemByIndex = createSelector(
  selectCommissionCreditItemsEntities,
  (customers, props) => customers[props.iri]
);

export const selectCurrentCommissionCreditItem = createSelector(
  selectCommissionCreditItemsState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectCommissionCreditItemsState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectCommissionCreditItemsEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectCommissionCreditItemsState,
  state => state.totalItems
);
export const selectCommissionCreditItemPagination = createSelector(
  selectCommissionCreditItemsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectCommissionCreditItemPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectCommissionCreditItemPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectCommissionCreditItemPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectCommissionCreditItemPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectCommissionCreditItemPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCommissionCreditItemsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCommissionCreditItemsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCommissionCreditItemsState,
  (state) => state.errors
);
