import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';
import {WarehouseModule} from '../warehouse/warehouse.module';
import {
  OpenOrderShipmentsListComponent
} from './containers/open-order-shipments-list/open-order-shipments-list.component';
import {
  OpenExternalShipmentsListComponent
} from './containers/open-external-shipments-list/open-external-shipments-list.component';
import {CurrentShipmentsListComponent} from './containers/current-shipments-list/current-shipments-list.component';
import {OldShipmentsListComponent} from './containers/old-shipments-list/old-shipments-list.component';
import {OrdersModule} from '../orders/orders.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ShipmentsService} from './services/shipments.service';
import {ShipmentDirectionsService} from './services/shipment-directions.service';
import {ShipmentServicesService} from './services/shipment-services.service';
import {ShippingProvidersService} from './services/shipping-providers.service';
import {ShipmentDirectionsLoadedGuard} from './guards/shipment-directions-loaded.guard';
import {ShipmentServicesLoadedGuard} from './guards/shipment-services-loaded.guard';
import {ShippingProvidersToCustomerLoadedGuard} from './guards/shipping-providers-to-customer-loaded.guard';
import {ShippingProvidersToDataRecoveryLoadedGuard} from './guards/shipping-providers-to-data-recovery-loaded.guard';
import {ShipmentsViewComponent} from './containers/shipments-view/shipments-view.component';

@NgModule({
  imports: [
    SharedModule,
    WarehouseModule,
    OrdersModule,
    MatButtonToggleModule
  ],
  exports: [],
  declarations: [
    ShipmentsViewComponent,
    OpenOrderShipmentsListComponent,
    OpenExternalShipmentsListComponent,
    CurrentShipmentsListComponent,
    OldShipmentsListComponent,
  ],
  providers: [
    ShipmentsService,
    ShipmentDirectionsService,
    ShipmentServicesService,
    ShippingProvidersService,
    ShipmentDirectionsLoadedGuard,
    ShipmentServicesLoadedGuard,
    ShippingProvidersToCustomerLoadedGuard,
    ShippingProvidersToDataRecoveryLoadedGuard
  ],
})
export class ShippingModule {
}
