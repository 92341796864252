import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PayableInvoicesActions } from '../actions/';
import { PayableInvoicesService } from '../../services';
import { RouterActions } from '../../../application-state/store/actions';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { PayableInvoice } from '../../models';

@Injectable()
export class PayableInvoicesEffects {
  CreatePayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.CreatePayableInvoice),
      switchMap(({ payload }) => {
        return this.service.createPayableInvoice(payload).pipe(
          map(response =>
            PayableInvoicesActions.CreatePayableInvoiceSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.CreatePayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  CopyPayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.CopyPayableInvoice),
      switchMap(({ iri, payload }) => {
        return this.service.copyPayableInvoice(iri, payload).pipe(
          map(response =>
            PayableInvoicesActions.CopyPayableInvoiceSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.CopyPayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  CreatePayableInvoiceFromInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.CreatePayableInvoiceFromInvoice),
      switchMap(({ payload }) => {
        return this.service.createPayableInvoiceFromInvoice(payload).pipe(
          map(response =>
            PayableInvoicesActions.CreatePayableInvoiceFromInvoiceSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              PayableInvoicesActions.CreatePayableInvoiceFromInvoiceFail({
                response
              })
            )
          )
        );
      })
    )
  );
  MailPayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.MailPayableInvoice),
      switchMap(({ iri, payload }) => {
        return this.service.sendMail(iri, payload).pipe(
          map(response =>
            PayableInvoicesActions.MailPayableInvoiceSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.MailPayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  ReadPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.ReadPayableInvoicePDF),
      switchMap(({ iri }) => {
        return this.service.readPayableInvoiceAsPdf(iri).pipe(
          map((response: any) =>
            PayableInvoicesActions.ReadPayableInvoicePDFSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(PayableInvoicesActions.ReadPayableInvoicePDFFail({ response }))
          )
        );
      })
    )
  );
  ReadFirstReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.DownloadFirstReminder),
      switchMap(({ iri }) => {
        return this.service.readFirstReminderAsPdf(iri).pipe(
          map((response: any) =>
            PayableInvoicesActions.DownloadFirstReminderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(PayableInvoicesActions.DownloadFirstReminderFail({ response }))
          )
        );
      })
    )
  );
  ReadSecondReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.DownloadSecondReminder),
      switchMap(({ iri }) => {
        return this.service.readSecondReminderAsPdf(iri).pipe(
          map((response: any) =>
            PayableInvoicesActions.DownloadSecondReminderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(PayableInvoicesActions.DownloadSecondReminderFail({ response }))
          )
        );
      })
    )
  );
  ReadPayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.ReadPayableInvoice),
      switchMap(({ iri }) => {
        return this.service.readPayableInvoice(iri).pipe(
          map(response =>
            PayableInvoicesActions.ReadPayableInvoiceSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.ReadPayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  ReadPayableInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.ReadPayableInvoices),
      mergeMap(({ page, params }) => {
        return this.service.readPayableInvoices(page, params).pipe(
          map(response =>
            PayableInvoicesActions.ReadPayableInvoicesSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.ReadPayableInvoicesFail({ response }))
          )
        );
      })
    )
  );
  ReadPayableInvoiceChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.ReadPayableInvoiceChunk),
      switchMap(({ uri }) => {
        return this.service.readPayableInvoiceChunk(uri).pipe(
          map(response =>
            PayableInvoicesActions.ReadPayableInvoiceChunkSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.ReadPayableInvoiceChunkFail({ response }))
          )
        );
      })
    )
  );
  UpdatePayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.UpdatePayableInvoice),
      switchMap(({ iri, payload }) => {
        return this.service.updatePayableInvoice(iri, payload).pipe(
          map((response: PayableInvoice) =>
            PayableInvoicesActions.UpdatePayableInvoiceSuccess({ response })
          ),
          catchError(response =>
            of(PayableInvoicesActions.UpdatePayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  BookPayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.BookPayableInvoice),
      switchMap(({ iri }) => {
        return this.service.bookPayableInvoice(iri).pipe(
          map(() =>
            PayableInvoicesActions.BookPayableInvoiceSuccess({
              invoiceIri: iri
            })
          ),
          catchError(response =>
            of(PayableInvoicesActions.BookPayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  CancelPayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.CancelPayableInvoice),
      switchMap(({ iri }) => {
        return this.service.cancelPayableInvoice(iri).pipe(
          map(() =>
            PayableInvoicesActions.CancelPayableInvoiceSuccess({
              invoiceIri: iri
            })
          ),
          catchError(response =>
            of(PayableInvoicesActions.CancelPayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  BookFullPaymentReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.BookFullPaymentReceived),
      switchMap(({ iri }) => {
        return this.service.bookFullPaymentReceived(iri).pipe(
          map(() =>
            PayableInvoicesActions.BookFullPaymentReceivedSuccess({
              invoiceIri: iri
            })
          ),
          catchError(response =>
            of(PayableInvoicesActions.BookFullPaymentReceivedFail({ response }))
          )
        );
      })
    )
  );
  DeletePayableInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.DeletePayableInvoice),
      switchMap(({ iri }) => {
        return this.service.deletePayableInvoice(iri).pipe(
          map(() =>
            PayableInvoicesActions.DeletePayableInvoiceSuccess({ iri })
          ),
          catchError(response =>
            of(PayableInvoicesActions.DeletePayableInvoiceFail({ response }))
          )
        );
      })
    )
  );
  SuccessDownloadAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PayableInvoicesActions.ReadPayableInvoicePDFSuccess,
          PayableInvoicesActions.DownloadFirstReminderSuccess,
          PayableInvoicesActions.DownloadSecondReminderSuccess
        ),
        map(({ response: { contentUrl, fileName } }) => {
          const a = document.createElement('a');
          a.href = contentUrl;
          a.download = fileName; // File name Here
          a.click(); // Downloaded file
          a.remove();
        })
      ),
    { dispatch: false }
  );
  InvoiceActionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PayableInvoicesActions.CreatePayableInvoiceSuccess,
          PayableInvoicesActions.CopyPayableInvoiceSuccess,
          PayableInvoicesActions.CreatePayableInvoiceFromInvoiceSuccess,
          PayableInvoicesActions.UpdatePayableInvoiceSuccess,
          PayableInvoicesActions.BookPayableInvoiceSuccess,
          PayableInvoicesActions.BookFullPaymentReceivedSuccess,
          PayableInvoicesActions.CancelPayableInvoiceSuccess,
          PayableInvoicesActions.DeletePayableInvoiceSuccess,
          PayableInvoicesActions.MailPayableInvoiceSuccess
        ),
        map(({ type }) => {
          const textOptions = {
            [PayableInvoicesActions.CREATE_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde erstellt. Sie werden jetzt zu dieser weitergeleitet.',
            [PayableInvoicesActions.COPY_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde Kopiert. Sie werden jetzt zu dieser weitergeleitet.',
            [PayableInvoicesActions.CREATE_PAYABLE_INVOICE_FROM_INVOICE_SUCCESS]:
              'Die Abschlagsrechnung wurde erstellt. Sie werden jetzt zu dieser weitergeleitet.',
            [PayableInvoicesActions.UPDATE_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde aktualisiert.',
            [PayableInvoicesActions.BOOK_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde gebucht.',
            [PayableInvoicesActions.BOOK_FULL_PAYMENT_RECEIVED_SUCCESS]:
              'Die Rechnung wurde als komplett bezahlt markiert.',
            [PayableInvoicesActions.CANCEL_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde storniert.',
            [PayableInvoicesActions.DELETE_PAYABLE_INVOICE_SUCCESS]:
              'Die Rechnung wurde gelöscht. Sie werden jetzt zur Übersicht weitergeleitet.',
            [PayableInvoicesActions.MAIL_PAYABLE_INVOICE_SUCCESS]:
              'Die Email wurde erfolgreich verschickt.'
          };
          this.notifierService.show({
            type: 'success',
            message: textOptions[type]
          });
        })
      ),
    { dispatch: false }
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PayableInvoicesActions.CreatePayableInvoiceFail,
          PayableInvoicesActions.CopyPayableInvoiceFail,
          PayableInvoicesActions.CreatePayableInvoiceFromInvoiceFail,
          PayableInvoicesActions.UpdatePayableInvoiceFail,
          PayableInvoicesActions.BookPayableInvoiceFail,
          PayableInvoicesActions.BookFullPaymentReceivedFail,
          PayableInvoicesActions.CancelPayableInvoiceFail,
          PayableInvoicesActions.DeletePayableInvoiceFail,
          PayableInvoicesActions.MailPayableInvoiceFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Es ist der folgende Fehler aufgetreten: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  DeleteInvoiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayableInvoicesActions.DeletePayableInvoiceSuccess),
      map(() => RouterActions.Go({ path: ['invoices'] }))
    )
  );

  constructor(
    private actions$: Actions,
    private service: PayableInvoicesService,
    private notifierService: NotifierService
  ) {}
}
