import {Injectable, isDevMode} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {AbstractApiService} from '../../../shared/services';
import {TransitionsActions} from '../actions';
import {OrdersActions} from '../../../orders/store';
import {AdministratorsActions} from '../../../administrators/store';
import {NotifierService} from 'angular-notifier';
import {PayableInvoicesActions} from "../../../invoices/store";
import {extractIri} from "../../../shared/utilities/objects.utility";

@Injectable()
export class TransitionsEffects {

  constructor(private actions$: Actions, private service: AbstractApiService, private notifierService: NotifierService) {
  }

  MakeTransition$ = createEffect(() => this.actions$.pipe(
    ofType(TransitionsActions.MakeTransition),
    switchMap(({uri, payload}) => {
      return this.service.patchObject(uri, payload, true).pipe(
        map(response => TransitionsActions.MakeTransitionSuccess({response})),
        catchError(response => of(TransitionsActions.MakeTransitionFail({response})))
      );
    })
  ));

  /*MakeTransitionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TransitionsActions.MakeTransitionSuccess),
    map(() =>  {
      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text: 'Transition erfolgreich',
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({ payload });
    })
  ));*/
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      TransitionsActions.MakeTransitionFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];

      this.notifierService.show({
        type: 'error',
        message: 'Beim Ausführen der Transition sind Fehler aufgetreten:' + errors
      });
    })
  ), {dispatch: false});

  FollowUpTransition$ = createEffect(() => this.actions$.pipe(
    ofType(TransitionsActions.MakeTransitionSuccess),
    switchMap(({response}) => {
      if (isDevMode()) {

        // this.notifierService.show({type: 'success', message: 'Die Transition wurde ausgeführt!'});
      }
      console.log('Die Transition wurde ausgeführt!');
      const type = response['@type'];
      if (type === 'Order') {
        // console.log('transitions.effects');
        return of(OrdersActions.UpdateOrderSuccess({response}));
      } else if (type === 'Invoice') {
        return of(PayableInvoicesActions.ReadPayableInvoice({iri: extractIri(response)}));
      }
    })));
}
