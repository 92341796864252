import {createSelector} from '@ngrx/store';

import {selectTicketsModuleState, TICKETS_FEATURE_KEY} from '../reducers';

const NAMESPACE = TICKETS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectTicketsModuleState;


export const sState = createSelector(MODULE_STATE_SELECT, (moduleState) => moduleState[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const sEntities = createSelector(sState, (state) => state.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities));
export const sByIri = createSelector(sEntities, (tickets, props) => tickets[props.iri]);
export const sById = createSelector(sList, (tickets, props) => tickets.find(e => e['@id'].indexOf(props.id) > -1));

export const sByCustomerIri = createSelector(
  sList,
  (tickets, {customerIri}) => tickets.filter(ticket => ticket?.order?.customer['@id'] === customerIri)
);

/**
 * @deprecated
 */
export const selectCurrentTicket = createSelector(sState, (state) => state.current);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  sState,
  (state) => state.totalItems
);

export const selectTicketPagination = createSelector(
  sState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectTicketPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectTicketPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectTicketPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectTicketPagination,
  pagination => pagination.next
);

export const selectPaginationLastLink = createSelector(
  selectTicketPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);
