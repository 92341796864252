import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';

import {ApplicationState} from '../../application-state/store';
import {PartnerWebsiteInvitationsActions} from '../store';
import {PartnerWebsiteInvitationsSelectors} from '../store/selectors';

@Injectable()
export class PartnerWebsiteInvitationsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return this.loadPartnerWebsiteInvitations().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  loadPartnerWebsiteInvitations(): Observable<boolean> {

    return this.store.pipe(
      select(PartnerWebsiteInvitationsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(PartnerWebsiteInvitationsActions.ReadPartnerWebsiteInvitations({page: 1}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
