import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { TermsAndConditionsEntity } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class TermsAndConditionsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createTermsAndConditionsEntity(payload: {
    file: File;
    uuid?: string;
  }): Observable<TermsAndConditionsEntity> {
    // Manually create FormDate for proper file processing
    const formData: FormData = new FormData();
    formData.append('file', payload.file, payload.file.name);

    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }

    return this.rolesService.userHasRoleFilter<TermsAndConditionsEntity>(
      'ROLE_MWS_TERMS_AND_CONDITIONS_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/terms_and_conditions`, formData)
    );
  }

  getTermsAndConditionsEntity(
    iri: string
  ): Observable<TermsAndConditionsEntity> {
    return this.rolesService.userHasRoleFilter<TermsAndConditionsEntity>(
      'ROLE_MWS_TERMS_AND_CONDITIONS_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getTermsAndConditionsEntities(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TERMS_AND_CONDITIONS_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/terms_and_conditions`)
    );
  }

  updateTermsAndConditionsEntity({
    iri,
    payload
  }): Observable<TermsAndConditionsEntity> {
    return this.rolesService.userHasRoleFilter<TermsAndConditionsEntity>(
      'ROLE_MWS_TERMS_AND_CONDITIONS_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteTermsAndConditionsEntity(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TERMS_AND_CONDITIONS_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
