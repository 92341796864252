import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-list-to-be-destroyed-widget-ext',
  templateUrl: './orders-list-to-be-destroyed-widget-ext.component.html'
})
export class OrdersListToBeDestroyedWidgetExtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  getCircleColor(): { [key: string]: string } {
    return {backgroundColor: '#ffc400'};
  }

}
