import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CommissionCreditItemsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromModuleModels.CommissionCreditItem;
  entities: { [iri: string]: fromModuleModels.CommissionCreditItem };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
  searchEntities: Array<string>;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  searchEntities: [],
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    CommissionCreditItemsActions.CreateCommissionCreditItem,
    CommissionCreditItemsActions.ReadCommissionCreditItem,
    CommissionCreditItemsActions.ReadCommissionCreditItems,
    CommissionCreditItemsActions.UpdateCommissionCreditItem,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    CommissionCreditItemsActions.ReadCommissionCreditItemsSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      const totalItems = response['hydra:totalItems'];
      let pagination = {};
      let searchEntities = [];

      const entities = items.reduce(
        (
          entities: { [id: string]: fromModuleModels.CommissionCreditItem },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        // intentionally "reset" entities for proper pagination as opposed to accumulating values
        // todo: local caching? maybe use custom MatDataSource?
        {}
        // {...state.entities}
      );

      if (response.hasQueryParam) {
        items.forEach(item => {
          searchEntities = [...searchEntities, item['@id']];
        });
      }

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        searchEntities,
        totalItems,
        pagination,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    CommissionCreditItemsActions.ReadCommissionCreditItemSuccess,
    CommissionCreditItemsActions.UpdateCommissionCreditItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    CommissionCreditItemsActions.DeleteCommissionCreditItemSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removedItem, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    CommissionCreditItemsActions.CreateCommissionCreditItemFail,
    CommissionCreditItemsActions.UpdateCommissionCreditItemFail,
    CommissionCreditItemsActions.ReadCommissionCreditItemFail,
    CommissionCreditItemsActions.ReadCommissionCreditItemsFail,
    CommissionCreditItemsActions.UpdateCommissionCreditItemFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
