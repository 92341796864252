import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Input, OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {ArrayUtility} from '../../../shared/utilities/array.utility';
import * as OrdersModuleActions from '../../store';
import {OrderSpecialAgreementsSelectors, OrdersSelectors} from '../../store/selectors';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {OrdersActions, OrderSpecialAgreementsActions} from '../../store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {SpecialAgreement} from '../../../master-data/models';
import {Order, OrderSpecialAgreement} from '../../models';
import {SpecialAgreementsSelectors} from '../../../master-data/store/selectors';
import {SpecialAgreementsActions} from '../../../master-data/store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-special-agreements-selector',
  styleUrls: ['special-agreements-selector.component.scss'],
  template: `

    <span class="heading--h3">Sondervereinbarungen</span>

    <div class="m-b--32 pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

      <div class="agreements-list" cdkDropListLockAxis="y"
           cdkDropList
           (cdkDropListDropped)="handleUpdateListSorting($event)">

        <div *ngFor="let item of (orderSpecialAgreements)"
             [class.hidden]="!specialAgreementsEntities[item.specialAgreement]"
             cdkDragBoundary=".agreements-list"
             [cdkDragDisabled]="isLoading$|async"
             class="item m-b--8 cursor-pointer"
             [class.inactive]="isLoading$|async"
             cdkDrag>
          <span>{{ specialAgreementsEntities[item.specialAgreement]?.name }}</span>
          <mat-icon class="remove-handle" (click)="removeSpecialAgreement(item)" style="float: right">remove</mat-icon>
          <mat-icon class="drag-handle" cdkDragHandle style="float: right">menu</mat-icon>
        </div>
      </div>
      <!-- NotSelectedList -->
      <div class="agreements-list">

        <div *ngFor="let item of (specialAgreements$|async)"
             class="item m-b--8  lightgrey"
             (click)="addSpecialAgreement(item)"
             [class.hidden]="isItemSelected(item)"
        >
          <span>{{ item.name }}</span>
          <mat-icon class="add-handle" cdkDragHandle style="float: right">add</mat-icon>
        </div>
      </div>
    </div>

    <!--<pre>{{ saf.value | json }}</pre>-->
    <!--<pre>{{ specialAgreementsSelection$.getValue() | json }}</pre>-->
  `
})
export class SpecialAgreementsSelectorComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;

  @Output() updateFormData: EventEmitter<any> = new EventEmitter();

  order: Order;

  isLoading$: Observable<boolean>;

  specialAgreements$: Observable<Array<SpecialAgreement>>;
  specialAgreementsIsLoading$: Observable<boolean>;
  specialAgreementsEntities: { [p: string]: SpecialAgreement };

  orderSpecialAgreements: Array<OrderSpecialAgreement>;

  onDestroy$: Subject<any> = new Subject<any>();

  saf: FormGroup;

  constructor(private fb: FormBuilder, private dialog: MatDialog, private cd: ChangeDetectorRef,
              private store$: Store<ApplicationState>
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrderSpecialAgreementsSelectors.isLoading),
      this.store$.select(SpecialAgreementsSelectors.isLoading),
      this.store$.select(OrdersSelectors.isLoading)
    ]);

    this.initForm();
    this.loadSpecialAgreements();
    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe(order => {
      this.order = order;
      this.loadOrderSpecialAgreements(order);
      this.orderSpecialAgreements = order.orderSpecialAgreements || [];
      this.saf.patchValue({order: order['@id']});
    });
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.saf = this.fb.group({
      order: this.fb.control(null),
      sort: this.fb.control(null, [Validators.required]),
      specialAgreement: this.fb.control(null, [Validators.required])
    });
  }

  isItemSelected(item: SpecialAgreement): boolean {
    return !!this.orderSpecialAgreements.find(e => e.specialAgreement === item['@id']);
  }


  handleUpdateListSorting(event: CdkDragDrop<Array<string>> | any): void {

    this.orderSpecialAgreements = ArrayUtility.move(this.orderSpecialAgreements, event.previousIndex, event.currentIndex);
    this.updateSpecialAgreements();
    // this.store$.dispatch(OrdersActions.UpdateOrder({iri: this.order['@id'], payload}));
  }

  addSpecialAgreement(item: SpecialAgreement): void {
    this.orderSpecialAgreements.push({specialAgreement: item['@id'], order: this.order['@id']});
    this.updateSpecialAgreements();
  }

  updateSpecialAgreements(): void {
    const payload = {
      orderSpecialAgreements: this.orderSpecialAgreements.map((e, i) => {
        const obj: { sort: number, specialAgreement?: any, '@id'?: string } = {sort: i};
        if (e['@id']) {
          obj['@id'] = e['@id'];
        }
        if (e.specialAgreement) {
          obj.specialAgreement = e.specialAgreement['@id'] ? e.specialAgreement['@id'] : e.specialAgreement;
        }
        return obj;
      })
    };
    this.updateFormData.emit(payload);

  }

  removeSpecialAgreement(item: SpecialAgreement): void {
    if (!item) {
      return;
    }

    const index = this.orderSpecialAgreements.findIndex(e => e === item);
    this.orderSpecialAgreements.splice(index, 1);
    this.updateSpecialAgreements();
  }


  private loadSpecialAgreements(): void {
    this.specialAgreements$ = this.store$.select(SpecialAgreementsSelectors.selectSpecialAgreements);
    this.specialAgreementsIsLoading$ = this.store$.select(SpecialAgreementsSelectors.isLoading);
    this.store$.select(SpecialAgreementsSelectors.selectSpecialAgreementsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.specialAgreementsEntities = entities;
      });
    loadIfNotLoaded(this.store$, SpecialAgreementsSelectors.isLoaded, SpecialAgreementsActions.ReadSpecialAgreements());
  }

  private loadOrderSpecialAgreements(order): void {
    this.store$.select(OrderSpecialAgreementsSelectors.selectOrderSpecialAgreementsByOrder, {iri: order['@id']})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(orderSpecialAgreements => {
        this.orderSpecialAgreements = orderSpecialAgreements;
      });
    this.store$.dispatch(OrderSpecialAgreementsActions.ReadOrderSpecialAgreements({params: {'order.uuid': getUuidFromIri(order['@id'])}}));
  }

}
