import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as fromInvoicesModuleServices from '../../services';
import {PaymentProcessesActions} from '../actions';

@Injectable()
export class PaymentProcessesEffects {

  constructor(private actions$: Actions, private service: fromInvoicesModuleServices.PaymentProcessesService) {
  }


  CreatePaymentProcess$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProcessesActions.CreatePaymentProcess),
    switchMap(({payload}) => {
      return this.service.createPaymentProcess(payload).pipe(
        map((response) => PaymentProcessesActions.CreatePaymentProcessSuccess({response})),
        catchError(response => of(PaymentProcessesActions.CreatePaymentProcessFail({response})))
      );
    })
  ));


  ReadPaymentProcess$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentProcessesActions.ReadPaymentProcess),
    switchMap(({iri}) => {
      return this.service.readPaymentProcess(iri).pipe(
        map(response => PaymentProcessesActions.ReadPaymentProcessSuccess({response})),
        catchError(response => of(PaymentProcessesActions.ReadPaymentProcessFail({response})))
      );
    })
  ));
}
