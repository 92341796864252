import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AdministratorFormComponent} from '../../components';
import {AdministratorGroupsSelectors, AdministratorsSelectors} from '../../store/selectors';
import {AdministratorsActions} from '../../store';
import {ApplicationState} from '../../../application-state/store';
import {DialogActions} from '../../../application-state/store/actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-administrators-view',
  template: `

    <view-heading heading="Administratoren" colLeft="column-4" colRight="column-4" offsetRight="6"
                  colRightClass="m-ta--2">

    <span class="m-ta--2">
      <!--<button class="m-r&#45;&#45;16" disabled mat-flat-button (click)="handleInviteAdministrator()">Neuen Admin einladen</button>-->
      <button mat-flat-button (click)="handleRequestAdministratorForm(administratorForm)">Neuen Admin anlegen</button>
    </span>
    </view-heading>

    <ng-container *ngIf="(administrators$ | async) as administrators">

      <app-administrators-table
        [admins]="administrators"
        (requestUpdateAdministratorForm)="handleUpdateAdministratorForm($event, administratorForm)"
        (requestDeleteAdministrator)="handleDeleteAdministrator($event)"></app-administrators-table>
    </ng-container>

    <ng-template #administratorForm>

      <div class="mat-dialog-content">

        <app-administrator-form
          (requestUpdateAdministrator)="handleUpdateAdministrator($event)"
          (requestCreateAdministrator)="handleCreateAdministrator($event)"
          (cancelEdit)="dialog.closeAll()"
          [presets$]="presets$">

          <h2 class="form__heading">Admin anlegen</h2>
        </app-administrator-form>
      </div>
    </ng-template>
  `
})
export class AdministratorsViewComponent implements OnInit, OnDestroy {

  administratorGroups$: Observable<Array<fromModuleModels.AdministratorGroup>>;
  administrators$: Observable<Array<fromModuleModels.Administrator>>;
  onDestroy$: Subject<any> = new Subject<any>();
  presets$: BehaviorSubject<fromModuleModels.Administrator> = new BehaviorSubject(null);

  constructor(public dialog: MatDialog, private store: Store<ApplicationState>, private updateSuccess$: Actions) {

    this.updateSuccess$.pipe(
      ofType(
        DialogActions.DialogCloseWithConfirmation,
        DialogActions.DialogCloseWithoutConfirmation
      ),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.presets$.next(null));
  }

  ngOnInit(): void {
    this.store.dispatch(AdministratorsActions.ReadAdministrators());
    this.administrators$ = this.store.pipe(select(AdministratorsSelectors.sList));
    this.administratorGroups$ = this.store.pipe(select(AdministratorGroupsSelectors.sList));
  }

  handleCreateAdministrator(payload: fromModuleModels.Administrator) {
    this.store.dispatch(AdministratorsActions.CreateAdministrator({payload}));
  }

  handleInviteAdministrator() {
    alert('TODO: Implement handleInviteAdministrator()');
  }

  handleUpdateAdministratorForm(administrator: fromModuleModels.Administrator, ref: TemplateRef<AdministratorFormComponent>) {

    this.presets$.next(administrator);
    this.handleRequestAdministratorForm(ref);
  }

  handleUpdateAdministrator(payload: { iri: string, payload: fromModuleModels.Administrator }): void {

    this.store.dispatch(AdministratorsActions.UpdateAdministrator(payload));
  }

  handleDeleteAdministrator(iri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Nutzer wirklich gelöscht werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store.dispatch(AdministratorsActions.DeleteAdministrator({iri}));
      });
  }

  handleRequestAdministratorForm(ref: TemplateRef<AdministratorFormComponent>, modalOptions = {}): void {
    this.dialog.open(ref, {disableClose: true, ...modalOptions, width: '95%', minWidth: '600px', maxWidth: '95%'});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
