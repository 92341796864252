import {Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {ApplicationState} from '../../../application-state/store';
import {
  ShipmentDirectionsSelectors,
  ShipmentServicesSelectors,
  ShippingProvidersToCustomerSelectors, ShippingProvidersToDataRecoverySelectors
} from '../../store/selectors';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {
  CountriesSelectors,
  ProductsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors, StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import {StockItemsSelectors} from '../../../warehouse/store/selectors';
import {
  CreateShipmentDialogComponent
} from '../../../shared/components/create-shipment-dialog/create-shipment-dialog.component';

@Component({
  selector: 'app-shipments-view',
  styleUrls: ['shipments-view.component.scss'],
  template: `
    <div class="row">
      <div class="col-auto me-auto">
        <h1>Versand</h1>
      </div>
      <div class="col-auto text-right">
        <!--<button mat-button color="green" (click)="showCreateShipmentDialog()">
          <mat-icon>add</mat-icon>
          Neuen Versand auslösen
        </button>-->
      </div>
    </div>
    <div class="row">

      <nav mat-tab-nav-bar class="col">
        <a
          mat-tab-link
          *ngFor="let link of links"
          [active]="isActive(link)"
          [routerLinkActive]="link.url"
          #rla="routerLinkActive"
          [routerLink]="link.url"
        >
          {{ link.label }}
        </a>
      </nav>
    </div>
    <div class="row">
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>


  `
})
export class ShipmentsViewComponent extends BaseOnDestroyComponent implements OnInit {

  isLoading$: Observable<boolean>;
  links: Array<{
    url: string;
    label: string;
    value: string;
  }> = [
    {
      url: '/shipments',
      value: 'order_shipments',
      label: 'Zu versenden an Kunden'
    },
    {
      url: '/shipments/external_shipments',
      value: 'external_shipments',
      label: 'zu versenden an Dienstleister'
    },
    {
      url: '/shipments/list_current',
      value: 'all_shipments',
      label: 'Sendungen'
    },
    // {
    //   url: '/shipments/list_old',
    //   value: 'all_shipments',
    //   label: 'Abgeschlossene Sendungen'
    // }
  ];

  get activeLink(): {
    url: string;
    label: string;
    value: string;
  } {
    return this.links.find(this.isActive.bind(this));
  }

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private activatedRoute$: ActivatedRoute,
    private router: Router,
    private title: AbstractTitleService) {
    super();
  }

  isActive(link: any): boolean {
    let url = this.router.url.toLowerCase();
    if (this.router.url.indexOf('?') > -1) {
      url = this.router.url.toLowerCase().split('?')[0];
    }
    return url === link.url.toLowerCase();
  }

  ngOnInit(): void {
    this.activatedRoute$.data.pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.callFunctionOnInit && this[data?.callFunctionOnInit]) {
          this[data?.callFunctionOnInit](this.activatedRoute$?.snapshot?.queryParams ? this.activatedRoute$.snapshot.queryParams : null);
        }
      });
    this.isLoading$ = isLoadingArray([
      this.store$.select(CountriesSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ShipmentDirectionsSelectors.isLoading),
      this.store$.select(ShipmentServicesSelectors.isLoading),
      this.store$.select(ShippingProvidersToCustomerSelectors.isLoading),
      this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading),
      this.store$.select(StockItemsSelectors.isLoading),
      this.store$.select(StorageLocationsSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading),
      this.store$.select(StorageSystemsSelectors.isLoading),
    ]);
    //this.title.setTitle('Versand');
  }


  showCreateShipmentDialog(): void {
    this.dialog.open(CreateShipmentDialogComponent, {data: {}});
  }
}
