import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {OrderStateRangesActions} from '../actions';
import {OrderStateRangesService} from '../../services';

@Injectable()
export class OrderStateRangesEffects {

  constructor(private actions$: Actions, private service: OrderStateRangesService) {
  }


  ReadOrderStateRange$ = createEffect(() => this.actions$.pipe(
    ofType(OrderStateRangesActions.ReadOrderStateRange),
    switchMap(({iri}) => {
      return this.service.readOrderStateRange(iri).pipe(
        map((response: any) => OrderStateRangesActions.ReadOrderStateRangeSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OrderStateRangesActions.ReadOrderStateRangeFail({response})))
      );
    })
  ));


  ReadOrderStateRanges$ = createEffect(() => this.actions$.pipe(
    ofType(OrderStateRangesActions.ReadOrderStateRanges),
    switchMap(() => {
      return this.service.readOrderStateRanges().pipe(
        map((response: any) => OrderStateRangesActions.ReadOrderStateRangesSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OrderStateRangesActions.ReadOrderStateRangesFail({response})))
      );
    })
  ));
}
