import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_EMAIL_DOCUMENT_DELIVERY = '[Invoices Module] Create Email Document Delivery';
export const CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Create Email Document Delivery Success';
export const CREATE_EMAIL_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Create Email Document Delivery Fail';

export const CreateEmailDocumentDelivery = createAction(
  CREATE_EMAIL_DOCUMENT_DELIVERY,
  props<{ payload: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateEmailDocumentDeliverySuccess = createAction(
  CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateEmailDocumentDeliveryFail = createAction(
  CREATE_EMAIL_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY = '[Invoices Module] Create Registered Letter Document Delivery';
export const CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Create Registered Letter Document Delivery Success';
export const CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Create Registered Letter Document Delivery Fail';

export const CreateRegisteredLetterDocumentDelivery = createAction(
  CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY,
  props<{ payload: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateRegisteredLetterDocumentDeliverySuccess = createAction(
  CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateRegisteredLetterDocumentDeliveryFail = createAction(
  CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY = '[Invoices Module] Create Letter Xpress Document Delivery';
export const CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Create Letter Xpress Document Delivery Fail';
export const CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Create Letter Xpress Document Delivery Success';

export const CreateLetterXPressDocumentDelivery = createAction(
  CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY,
  props<{ payload: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateLetterXPressDocumentDeliverySuccess = createAction(
  CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: fromInvoicesModuleModels.DocumentDelivery, invoiceIri: string }>()
);

export const CreateLetterXPressDocumentDeliveryFail = createAction(
  CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_EMAIL_DOCUMENT_DELIVERY = '[Invoices Module] Read Email Document Delivery';
export const READ_EMAIL_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Read Email Document Delivery Success';
export const READ_EMAIL_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Read Email Document Delivery Fail';

export const ReadEmailDocumentDelivery = createAction(
  READ_EMAIL_DOCUMENT_DELIVERY,
  props<{ iri: string }>()
);

export const ReadEmailDocumentDeliverySuccess = createAction(
  READ_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: fromInvoicesModuleModels.DocumentDelivery }>()
);

export const ReadEmailDocumentDeliveryFail = createAction(
  READ_EMAIL_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_REGISTERED_LETTER_DOCUMENT_DELIVERY = '[Invoices Module] Read Registered Letter Document Delivery';
export const READ_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Read Registered Letter Document Delivery Success';
export const READ_REGISTERED_LETTER_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Read Registered Letter Document Delivery Fail';

export const ReadRegisteredLetterDocumentDelivery = createAction(
  READ_REGISTERED_LETTER_DOCUMENT_DELIVERY,
  props<{ iri: string }>()
);

export const ReadRegisteredLetterDocumentDeliverySuccess = createAction(
  READ_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: any }>()
);

export const ReadRegisteredLetterDocumentDeliveryFail = createAction(
  READ_REGISTERED_LETTER_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_LETTER_XPRESS_DOCUMENT_DELIVERY = '[Invoices Module] Read Letter XPress Document Delivery';
export const READ_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS = '[Invoices Module] Read Letter XPress Document Delivery Success';
export const READ_LETTER_XPRESS_DOCUMENT_DELIVERY_FAIL = '[Invoices Module] Read Letter XPress Document Delivery Fail';

export const ReadLetterXPressDocumentDelivery = createAction(
  READ_LETTER_XPRESS_DOCUMENT_DELIVERY,
  props<{ iri: string }>()
);

export const ReadLetterXPressDocumentDeliverySuccess = createAction(
  READ_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
  props<{ response: fromInvoicesModuleModels.DocumentDelivery }>()
);

export const ReadLetterXPressDocumentDeliveryFail = createAction(
  READ_LETTER_XPRESS_DOCUMENT_DELIVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);
