import {createSelector} from '@ngrx/store';

import {FOLLOW_UP_REASONS_FEATURE_KEY, selectDashboardModuleState} from '../reducers';

export const selectFollowUpReasonsState = createSelector(
  selectDashboardModuleState,
  (moduleState) => moduleState[FOLLOW_UP_REASONS_FEATURE_KEY]
);
export const selectFollowUpReasonsEntities = createSelector(
  selectFollowUpReasonsState,
  (state) => state.entities
);

export const selectFollowUpReasons = createSelector(
  selectFollowUpReasonsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectFollowUpReasonsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectFollowUpReasonsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectFollowUpReasonsState,
  (state) => state.loaded
);
