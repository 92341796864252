<view-heading heading="Stammdatenverwaltung"></view-heading>

<div class="wrap pos-relative">
  <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

  <mat-tab-group
    animationDuration="0"
    (selectedTabChange)="handleTabChange($event)"
    [selectedIndex]="activeLinkIndex"
  >
<!--
    PRODUKTE TAB
-->
    <mat-tab label="Produkte">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <product-form
              [currencies]="currencies$ | async"
              [storageSystems$]="storageSystems$"
              [storageSystemManufacturers$]="storageSystemManufacturers$"
              [productUnits]="productUnits$ | async"
              [taxRates]="taxes$ | async"
              [presets$]="presets$"
              [errors]="selectErrors('productsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></product-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [mappingData]="mappingData$ | async"
                [data]="products$ | async"
                [tableColumns]="columnDefs.product"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Produkte</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

<!--
    Leistungen TAB
-->
    <mat-tab label="Leistungen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <service-form
              [currencies]="currencies$ | async"
              [productUnits]="productUnits$ | async"
              [taxRates]="taxes$ | async"
              [presets$]="presets$"
              [errors]="selectErrors('servicesErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></service-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="services$ | async"
                [tableColumns]="columnDefs.service"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Leistungen</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
<!--
    Kunden-Kooperationen TAB
-->
    <mat-tab label="Kunden-Kooperationen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <customer-cooperation-form
              [presets$]="presets$"
              [errors]="selectErrors('customerCooperationsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></customer-cooperation-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="customerCooperations$ | async"
                [tableColumns]="columnDefs.customerCooperation"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Kunden-Kooperationen</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Abteilungen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <app-department-form
              [presets$]="presets$"
              [errors]="selectErrors('departmentsErrors')"
              [countryOptions$]="countries$"
              [technicians$]="technicians$"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></app-department-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="departments$ | async"
                [tableColumns]="columnDefs.department"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Departments</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Schadensarten">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <damage-form
              [presets$]="presets$"
              [errors]="selectErrors('damagesErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></damage-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="damages$ | async"
                [tableColumns]="columnDefs.damage"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Schadensarten</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Symptome">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <symptom-form
              [presets$]="presets$"
              [errors]="selectErrors('symptomsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></symptom-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="symptoms$ | async"
                [tableColumns]="columnDefs.symptom"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Symptome</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Analysevorlagen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <analysis-result-form
              [presets$]="presets$"
              [errors]="selectErrors('analysisResultsErrors')"
              [analysisResultCategories]="analysisResultCategories$ | async"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></analysis-result-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="(analysisResults$ | async).slice(0, (params$ | async).AnalysisResult.itemsPerPage)"
                [tableColumns]="columnDefs.analysisResult"
                (requestDeleteItem)="handleDeleteItem($event)"
                [totalItems]="(itemsTotal$ | async)?.AnalysisResult"
                (requestReadItemPage)="handleReadItemsPage($event)"
                (requestReadItemChunk)="handleReadItemChunk($event)"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                [hasPagination]="(itemsTotal$ | async)?.AnalysisResult > 30"
              >
                <span>Rabattstufen</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Analysevorlagen-Kategorien">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <analysis-result-category-form
              [presets$]="presets$"
              [errors]="selectErrors('analysisResultCategoriesErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></analysis-result-category-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="analysisResultCategories$ | async"
                [tableColumns]="columnDefs.analysisResultCategory"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Rabattstufen</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Rabattstufen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <discount-form
              [presets$]="presets$"
              [errors]="selectErrors('discountsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></discount-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="discounts$ | async"
                [tableColumns]="columnDefs.discount"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Rabattstufen</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Lagerplätze">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-4">
            <storage-location-form
              [presets$]="presets$"
              [errors]="selectErrors('storageLocationsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></storage-location-form>
          </div>
          <div class="column-10">
            <div class="m-l--48">
              <app-item-list
                [data]="(storageLocations$ | async).slice(0, (params$ | async).StorageLocation.itemsPerPage)"
                [tableColumns]="columnDefs.storageLocation"
                (requestDeleteItem)="handleDeleteItem($event)"
                [totalItems]="(itemsTotal$ | async)?.StorageLocation"
                (requestReadItemPage)="handleReadItemsPage($event)"
                (requestReadItemChunk)="handleReadItemChunk($event)"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                [hasPagination]="(itemsTotal$ | async)?.StorageLocation > 30">
                <span>Lagerplätze</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <!--<mat-tab label="E-Mail-Vorlagen">
            <div class="inner wrap">
              <div class="grid grid-no-gutter">
                <div class="column-5">
                  <div class="card mat-elevation-z1">
                    <div style="height: 450px"></div>
                  </div>
                </div>
                <div class="column-9">
                  <div class="m-l&#45;&#45;50">
                    <div class="card mat-elevation-z1">
                      <div style="height: 750px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>-->

    <mat-tab label="Dateisysteme">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <file-system-form
              [presets$]="presets$"
              [errors]="selectErrors('fileSystemsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></file-system-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="fileSystems$ | async"
                [tableColumns]="columnDefs.fileSystem"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Dateisysteme</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Betriebssysteme">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <operating-system-form
              [presets$]="presets$"
              [errors]="selectErrors('operatingSystemsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></operating-system-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="operatingSystems$ | async"
                [tableColumns]="columnDefs.operatingSystem"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Betriebssysteme</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Speichersysteme">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <storage-system-form
              [presets$]="presets$"
              [errors]="selectErrors('storageSystemsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></storage-system-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="storageSystems$ | async"
                [tableColumns]="columnDefs.storageSystem"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Speichersysteme</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Sondervereinbarungen">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <special-agreement-form
              [presets$]="presets$"
              [errors]="selectErrors('specialAgreementsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></special-agreement-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="specialAgreements$ | async"
                [tableColumns]="columnDefs.specialAgreement"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Sondervereinbarung</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Speichersystem-Hersteller">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <storage-system-manufacturer-form
              [presets$]="presets$"
              [errors]="selectErrors('storageSystemManufacturersErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></storage-system-manufacturer-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="storageSystemManufacturers$ | async"
                [tableColumns]="columnDefs.storageSystemManufacturer"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Speichersystem-Hersteller</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Steuersätze">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <tax-form
              [presets$]="presets$"
              [errors]="selectErrors('taxesErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></tax-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="taxes$ | async"
                [tableColumns]="columnDefs.tax"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Steuersätze</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Anreden">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <salutation-form
              [presets$]="presets$"
              [locales$]="locales$"
              [errors]="selectErrors('salutationsErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></salutation-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="salutations$ | async"
                [tableColumns]="columnDefs.salutation"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Anreden</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Akad. Grade">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <grade-form
              [presets$]="presets$"
              [locales$]="locales$"
              [errors]="selectErrors('gradesErrors')"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></grade-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="grades$ | async"
                [tableColumns]="columnDefs.grade"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Akademische Grade</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Email-Template Kategorien">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-5">
            <app-email-template-categories-form
              [presets$]="presets$"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></app-email-template-categories-form>
          </div>
          <div class="column-9">
            <div class="m-l--48">
              <app-item-list
                [data]="emailTemplateCategories$ | async"
                [tableColumns]="columnDefs.emailTemplateCategories"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                (requestDeleteItem)="handleDeleteItem($event)"
              >
                <span>Email-Template Kategorien</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Email-Templates">
      <div class="inner wrap">
        <div class="grid grid-no-gutter">
          <div class="column-10">
            <app-email-templates-form
              [presets$]="presets$"
              (requestCreateItem)="handleCreateItem($event)"
              (requestUpdateItem)="handleUpdateItem($event)"
            ></app-email-templates-form>
          </div>
          <div class="column-4">
            <div class="m-l--48">
              <app-item-list
                [pageSize]="15"
                [data]="(emailTemplates$ | async).slice(0, (params$ | async).EmailTemplate.itemsPerPage)"
                [tableColumns]="columnDefs.emailTemplates"
                (requestDeleteItem)="handleDeleteItem($event)"
                [totalItems]="(itemsTotal$ | async)?.EmailTemplate"
                (requestReadItemPage)="handleReadItemsPage($event)"
                (requestReadItemChunk)="handleReadItemChunk($event)"
                (requestUpdateItemForm)="handleUpdateItemForm($event)"
                [hasPagination]="(itemsTotal$ | async)?.EmailTemplate > 30"
              >
                <span>Email-Templates</span>
              </app-item-list>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!--<pre>{{ errors$.getValue() | json }}</pre>-->
  <!--<pre>{{ paginations$.getValue() | json }}</pre>-->
  <!--<pre>{{ params$.getValue() | json }}</pre>-->
  <!--<pre>{{ data.itemsTotal | json }}</pre>-->
</div>
