import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromModuleModels from '../../models';
import { AuthActions } from '../actions/';
import { AuthService } from '../../services/auth.service';
import { Go } from '../../../application-state/store/actions/router.actions';
import { RouterActions } from '../../../application-state/store/actions';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class AuthEffects {
  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginUser),
      map(action => action),
      switchMap((payload: fromModuleModels.Credentials) => {
        return this.authService.login(payload).pipe(
          map(response => AuthActions.LoginUserSuccess({ response })),
          catchError(response => of(AuthActions.LoginUserFail({ response })))
        );
      })
    )
  );
  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LogoutUser),
      switchMap(() => {
        return this.authService.logout().pipe(
          map(() => AuthActions.LogoutUserSuccess()),
          catchError(() => of(AuthActions.LogoutUserFail()))
        );
      })
    )
  );
  loginUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginUserSuccess),
        map(action => action),
        tap(() => {
          this.authService.setDefaultLocale();
          // return Go({path: ['dashboard']});
        })
      ),
    { dispatch: false }
  );
  logoutUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LogoutUserSuccess),
      map(action => action),
      map(() => Go({ path: ['login'], query: { reason: 'LOGOUT_SUCCESS' } }))
    )
  );
  CreatePasswordResetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CreatePasswordResetRequest),
      switchMap(({ payload }) => {
        return this.authService.createPasswordResetRequest(payload).pipe(
          map(response => {
            this.notify.show({
              type: 'success',
              message:
                'Eine E-Mail zum Zurücksetzen des Password wurde verschickt'
            });
            return AuthActions.CreatePasswordResetRequestSuccess({ response });
          }),
          catchError(response => {
            // console.log(response);
            this.notify.show({
              type: 'error',
              message:
                'Das Zurücksetzen des Passwords war nicht erfolgreich:' +
                response?.error?.['hydra:description']
            });

            return of(AuthActions.CreatePasswordResetRequestFail({ response }));
          })
        );
      })
    )
  );
  CreatePasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CreatePasswordReset),
      switchMap(({ payload }) => {
        return this.authService.createPasswordReset(payload).pipe(
          map(response => AuthActions.CreatePasswordResetSuccess({ response })),
          catchError(response =>
            of(AuthActions.CreatePasswordResetFail({ response }))
          )
        );
      })
    )
  );
  CreatePasswordResetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.CreatePasswordResetSuccess),
      map(() => RouterActions.Go({ path: ['dashboard'] }))
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private notify: NotifierService
  ) {}
}
