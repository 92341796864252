import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';
import { AbstractApiService } from '../../shared/services';

@Injectable()
export class UserProfilesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  // SECURITY:  Everyone that is logged in can view the profile route  */
  readUserProfile(iri: string): Observable<AbstractApiResponse> {
    return this.apiService.getObject(`${iri}/profile`, true);
  }

  updateUserProfile(
    iri: string,
    payload: any
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_USER_ADMINISTRATOR_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(`${iri}/profile`, payload, true)
    );
  }
}
