import {Injectable} from "@angular/core";
import * as fromModuleServices from '../../services';
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AdministratorFeedbackRequestsActions, AdministratorsActions} from "../actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()

export class FeedbackRequestsEffects {
  constructor(private actions$: Actions, private as: fromModuleServices.NewFeedbackRequestsService) {
  }

  ReadReceivedAdministratorFeedbackRequests$ = createEffect(() => this.actions$.pipe(
      ofType(AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequests),
      map(action => action),
      switchMap(({iri, params}) => {
        return this.as.getReceivedFeedbackRequests(iri, params).pipe(
            map(response => AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequestsSuccess({response})),
            catchError(response => of(AdministratorFeedbackRequestsActions.LoadReceivedAdministratorFeedbackRequestsFail({response})))
        );
      })
  ));

  ReadSentAdministratorFeedbackRequests$ = createEffect(() => this.actions$.pipe(
      ofType(AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequests),
      map(action => action),
      switchMap(({iri, params}) => {
        return this.as.getSentFeedbackRequests(iri, params).pipe(
            map(response => AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequestsSuccess({response})),
            catchError(response => of(AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequestsFail({response})))
        );
      })
  ));

  PatchFeedbackRequests$ = createEffect(() => this.actions$.pipe(
      ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequests),
      map(action => action),
      switchMap(({iri, module, variation, payload}) => {
        return this.as.patchFeedbackRequest(iri, module, variation, payload).pipe(
            map(response => AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess({response})),
            catchError(response => of(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsFail({response})))
        );
      })
  ));
}



