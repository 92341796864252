import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class BaseOnDestroyComponent implements OnDestroy {

  onDestroy$: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


}
