import {createSelector} from '@ngrx/store';

import {selectTicketsModuleState, REMOTE_ACCESS_REASONS_FEATURE_KEY} from '../reducers';
import {ADMINISTRATORS_FEATURE_KEY, selectAdministratorsModuleState} from '../../../administrators/store';

const NAMESPACE = REMOTE_ACCESS_REASONS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectTicketsModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const sEntities = createSelector(sState, (s) => s.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities));
export const sById = (id) => createSelector(sList, (entities) => entities.find(e => e['@id'].indexOf(id) > -1));
export const sByIri = createSelector(sEntities, (entities, {iri}) => entities[iri]);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);

