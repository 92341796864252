import {createSelector} from '@ngrx/store';

import {REPLACEMENT_DATA_MEDIUM_SOURCES, selectMasterDataModuleState} from '../reducers';

export const selectReplacementDataMediumSourcesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[REPLACEMENT_DATA_MEDIUM_SOURCES]
);

export const selectReplacementDataMediumSourcesEntities = createSelector(
  selectReplacementDataMediumSourcesState,
  (state) => state.entities
);

export const selectReplacementDataMediumSources = createSelector(
  selectReplacementDataMediumSourcesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectReplacementDataMediumSourcesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectReplacementDataMediumSourcesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectReplacementDataMediumSourcesState,
  (state) => state.loaded
);
