import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, QueryList,
  ViewChildren
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {QuillEditorComponent} from 'ngx-quill';
import {filter, takeUntil} from 'rxjs/operators';
import {keyBy} from 'lodash-es';
import * as fromAdministratorsModuleModels from '../../../administrators/models';
import * as fromTicketsModuleModels from '../../../tickets/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {Ticket, TicketComment, TicketCommentTag} from '../../models';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {AdministratorsActions} from '../../../administrators/store';
import {OrderCommentTagsSelectors} from '../../../orders/store/selectors';
import {OrderCommentTagsActions} from '../../../orders/store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrderComment} from '../../../orders/models';
import * as OrdersModuleActions from '../../../orders/store';
import {TicketCommentsActions, TicketCommentTagsActions, TicketsActions} from '../../store';
import {TicketCommentTagsSelectors} from "../../store/selectors";
import {Actions, ofType} from "@ngrx/effects";

@Component({
  selector: 'app-ticket-comment-form',
  styleUrls: ['ticket-comment-form.component.scss'],
  template: `

    <div class="ticket__comment-form p-a--24" [class.in-modal]="comment$ && comment$.getValue()" [formGroup]="form"
         *ngIf="ticket">

      <div class="filter grid grid-no-gutter">

        <div class="column-7">
        <span *ngFor="let tag of ticketCommentTags" class="filter__item">

          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="getCommentTagValue == tag['@id']"
                  (click)="toggleCommentTag(tag['@id'])"
          >{{ tag.commentTag }}</button>
        </span>
        </div>
        <div class="column-7 m-ta--2">
        <span class="filter__item">
          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'recipient'"
                  (click)="toggleAdditionalInputVisible('recipient')"
          >Rückfrage</button>
        </span>

          <span class="filter__item">
          <button class="filter__button"
                  mat-button
                  color="outline"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'additionalContent'"
                  (click)="toggleAdditionalInputVisible('additionalContent')"
          >Kundenkommentar</button>
        </span>
        </div>
      </div>

      <app-text-editor formControlName="content" [label]="getRecipientValue"></app-text-editor>


      <div class="grid" *ngIf="additionalInputVisible === 'recipient'">

        <div class="column-6 off-8 m-t--16">
          <ng-select placeholder="Rückfrage richten an ..."
                     [items]="administrators"
                     bindValue="@id"
                     bindLabel="username"
                     [searchable]="false"
                     formControlName="recipient">

            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.firstName }} {{ item.lastName }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="ng-option">
                <span>{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div *ngIf="additionalInputVisible === 'additionalContent'" style="margin-top: 25px">
        <app-text-editor formControlName="additionalContent" label="Kundenkommentar"></app-text-editor>
      </div>

      <div class="m-ta--2 m-t--24">
        <button mat-flat-button color="green" type="button"
                [disabled]="form.invalid"
                (click)="handleSubmit()">{{ !!comment$?.getValue() ? 'Aktualisieren' : 'Kommentar anlegen' }}</button>
      </div>
    </div>

    <!--<pre>{{ tcf.value | json }}</pre>-->
    <!--<pre>{{ tcf.invalid | json }}</pre>-->
  `
})
export class TicketCommentFormComponent implements OnInit, OnDestroy {

  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  administratorsEntities: { [iri: string]: fromAdministratorsModuleModels.Administrator };
  administrators: Array<fromAdministratorsModuleModels.Administrator>;

  @Input() comment$: BehaviorSubject<TicketComment>;

  @Input() ticket$: Observable<Ticket>;
  ticket: Ticket;
  ticketCommentTags: Array<TicketCommentTag>;

  @Output() requestCreateTicketComment: EventEmitter<TicketComment> = new EventEmitter();
  @Output() requestUpdateTicketComment: EventEmitter<{ iri: string, payload: TicketComment }> = new EventEmitter();

  additionalInputVisible: string;
  additionalInputsChoices: Array<string> = ['additionalContent', 'recipient'];
  onDestroy$: Subject<any> = new Subject<any>();
  form: FormGroup;

  constructor(private cd: ChangeDetectorRef,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private actions$: Actions,
              private store$: Store<ApplicationState>) {
  }

  get getCommentTagValue(): string | null {
    return this.form.get('commentTag').value;
  }

  get getRecipientValue(): string | null {

    const inputValue = this.form.get('recipient').value;
    const admin = this.administratorsEntities[inputValue];
    const fullName = `@${admin?.username} (${admin?.firstName} ${admin?.lastName})`;

    return inputValue ? fullName : 'Eigener Kommentar';
  }

  ngOnInit(): void {
    this.initForm();
    this.form.markAsUntouched();

    this.initSubscriptions();
    this.loadAdministrators();
    this.loadTicketCommentTags();
    this.ticket$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(ticket => !!ticket)
      )
      .subscribe((ticket) => {
        this.ticket = ticket;
        this.form.get('ticket').setValue(this.ticket['@id']);
      });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


  initSubscriptions(): void {
    this.actions$.pipe(ofType(TicketCommentsActions.CreateTicketCommentSuccess), takeUntil(this.onDestroy$)).subscribe(() => {
      this.initForm();
    });

    if (!this.comment$) {
      return;
    }

    this.comment$.pipe(
      takeUntil(this.onDestroy$),
      filter(comment => !!comment)
    ).subscribe((comment: TicketComment) => {
      this.initForm();
      this.form.markAsUntouched();
      this.restoreFormState(comment);
    });
  }


  initForm(): void {
    this.form = this.fb.group({
      ticket: this.fb.control(this.ticket ? this.ticket['@id'] : null, [Validators.required]),
      commentTag: this.fb.control(null),
      content: this.fb.control('', [Validators.required]),
      additionalContent: this.fb.control(null),
      recipient: this.fb.control(null)
    });

    this.additionalInputVisible = null;
  }

  restoreFormState(comment: fromTicketsModuleModels.TicketComment) {

    Object.keys(comment).forEach(key => {

      const formInput = this.form.get(key);

      // check if both default value and formInput for given key exist
      if (formInput && !!comment[key]) {
        formInput.setValue(comment[key]);
        formInput.markAsTouched();

        if (key === 'recipient') {
          this.toggleAdditionalInputVisible('recipient');
          formInput.setValidators([Validators.required]);
        }

        if (key === 'additionalContent') {
          this.toggleAdditionalInputVisible('additionalContent');
          formInput.setValidators([Validators.required]);
        }
      }
    });
  }

  toggleCommentTag(iri: string): void {
    this.form.patchValue({commentTag: (this.getCommentTagValue === iri) ? null : iri});
  }

  // todo: side effects
  toggleAdditionalInputVisible(to: string): void {

    const currentValue = String(this.additionalInputVisible);
    const formField = this.form.get(currentValue);
    const informationLoss = this.additionalInputsChoices.includes(currentValue) && formField.dirty && (formField.value != '');

    if (informationLoss) {

      this.dialog.open(DialogComponent, {
        data: {
          heading: 'Achtung',
          text: 'Hierdurch geht eingegebener Text bzw. die Auswahl verloren. Fortfahren?',
          confirmationText: 'Weiter',
          cancelText: 'Abbrechen',
          disableClose: true,
        }
      }).afterClosed().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        (hasConfirmed: boolean) => {

          if (!!hasConfirmed) {

            this.additionalInputVisible = (currentValue === to) ? undefined : to;
            formField.patchValue(null);
            formField.markAsPristine();
          }
        }
      );
    } else {
      this.additionalInputVisible = (currentValue === to) ? undefined : to;
    }
  }

  handleSubmit(): void {
    const payload: TicketComment = this.form.value;
    this.comment$?.getValue()
      ? this.handleUpdateTicketComment({iri: this.comment$.getValue()['@id'], payload})
      : this.handleCreateTicketComment(payload);
  }

  handleCreateTicketComment(comment: TicketComment): void {
    this.store$.dispatch(TicketCommentsActions.CreateTicketComment({payload: comment}));
  }

  handleUpdateTicketComment(payload: { iri: string, payload: TicketComment }): void {
    this.store$.dispatch(TicketCommentsActions.UpdateTicketComment(payload));
  }

  getAdministratorName(admin: fromAdministratorsModuleModels.Administrator): string {
    return `${admin.firstName} ${admin.lastName}`;
  }

  private loadAdministrators(): void {
    this.store$.select(AdministratorsSelectors.selectAdministratorsWithoutLoggedInUser)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(list => {
        this.administratorsEntities = keyBy(list, '@id');
        this.administrators = list;
      });
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());

  }

  private loadTicketCommentTags(): void {
    this.store$.select(TicketCommentTagsSelectors.selectTicketCommentTags)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.ticketCommentTags = entities;
      });
    loadIfNotLoaded(this.store$, TicketCommentTagsSelectors.isLoaded, TicketCommentTagsActions.ReadTicketCommentTags());

  }

}
