import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_SYMPTOM = '[Master Data Module] Create Symptom';
export const CREATE_SYMPTOM_SUCCESS = '[Master Data Module] Create Symptom Success';
export const CREATE_SYMPTOM_FAIL = '[Master Data Module] Create Symptom Fail';
export const CreateSymptom = createAction(
  CREATE_SYMPTOM,
  props<{ payload: fromModuleModels.Symptom }>()
);
export const CreateSymptomSuccess = createAction(
  CREATE_SYMPTOM_SUCCESS,
  props<{ response: fromModuleModels.Symptom }>()
);
export const CreateSymptomFail = createAction(
  CREATE_SYMPTOM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SYMPTOMS = '[Master Data Module] Read Symptoms';
export const READ_SYMPTOMS_SUCCESS = '[Master Data Module] Read Symptoms Success';
export const READ_SYMPTOMS_FAIL = '[Master Data Module] Read Symptoms Fail';
export const ReadSymptoms = createAction(
  READ_SYMPTOMS
);
export const ReadSymptomsSuccess = createAction(
  READ_SYMPTOMS_SUCCESS,
  props<{ response: any }>()
);
export const ReadSymptomsFail = createAction(
  READ_SYMPTOMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SYMPTOM = '[Master Data Module] Read Symptom';
export const READ_SYMPTOM_SUCCESS = '[Master Data Module] Read Symptom Success';
export const READ_SYMPTOM_FAIL = '[Master Data Module] Read Symptom Fail';
export const ReadSymptom = createAction(
  READ_SYMPTOM,
  props<{ iri: string }>()
);
export const ReadSymptomSuccess = createAction(
  READ_SYMPTOM_SUCCESS,
  props<{ response }>()
);
export const ReadSymptomFail = createAction(
  READ_SYMPTOM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_SYMPTOM = '[Master Data Module] Update Symptom';
export const UPDATE_SYMPTOM_SUCCESS = '[Master Data Module] Update Symptom Success';
export const UPDATE_SYMPTOM_FAIL = '[Master Data Module] Update Symptom Fail';
export const UpdateSymptom = createAction(
  UPDATE_SYMPTOM,
  props<{ iri: string, payload: fromModuleModels.Symptom }>()
);
export const UpdateSymptomSuccess = createAction(
  UPDATE_SYMPTOM_SUCCESS,
  props<{ response: fromModuleModels.Symptom }>()
);
export const UpdateSymptomFail = createAction(
  UPDATE_SYMPTOM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_SYMPTOM = '[Master Data Module] Delete Symptom';
export const DELETE_SYMPTOM_SUCCESS = '[Master Data Module] Delete Symptom Success';
export const DELETE_SYMPTOM_FAIL = '[Master Data Module] Delete Symptom Fail';
export const DeleteSymptom = createAction(
  DELETE_SYMPTOM,
  props<{ iri: string }>()
);
export const DeleteSymptomSuccess = createAction(
  DELETE_SYMPTOM_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteSymptomFail = createAction(
  DELETE_SYMPTOM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
