import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { Shipment } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ShipmentsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createShipment(payload: Shipment): Observable<Shipment> {
    return this.rolesService.userHasRoleFilter<Shipment>(
      'ROLE_MWS_SHIPMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/shipments`, payload)
    );
  }

  readShipment(iri: string): Observable<Shipment> {
    return this.rolesService.userHasRoleFilter<Shipment>(
      [
        'ROLE_MWS_SHIPMENT_LIST',
        'ROLE_MWS_UPS_SHIPMENT_VIEW',
        'ROLE_MWS_UPS_PICKUP_SHIPMENT_VIEW',
        'ROLE_MWS_DHL_SHIPMENT_VIEW',
        'ROLE_MWS_GO_PICKUP_SHIPMENT_VIEW',
        'ROLE_MWS_GENERIC_SHIPMENT_VIEW'
      ],
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readShipments(page: number, params: any): Observable<Array<Shipment>> {
    return this.rolesService.userHasRoleFilter<Array<Shipment>>(
      'ROLE_MWS_SHIPMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(`/shipments`, page, params)
            )
    );
  }

  updateShipment(iri: string, payload: Shipment): Observable<Shipment> {
    return this.rolesService.userHasRoleFilter<Shipment>(
      'ROLE_MWS_SHIPMENT_EDITOR',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteShipment(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_UPS_PICKUP_SHIPMENT_DELETE',
        'ROLE_MWS_UPS_SHIPMENT_DELETE',
        'ROLE_MWS_DHL_SHIPMENT_DELETE',
        'ROLE_MWS_GO_PICKUP_SHIPMENT_DELETE',
        'ROLE_MWS_GENERIC_SHIPMENT_DELETE'
      ],
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }

  patchShipment(
    iri: string,
    payload: { workflow: string; transition: string }
  ): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_UPS_SHIPMENT_TRANSITION',
        'ROLE_MWS_UPS_PICKUP_SHIPMENT_TRANSITION',
        'ROLE_MWS_DHL_SHIPMENT_TRANSITION',
        'ROLE_MWS_GO_PICKUP_SHIPMENT_TRANSITION',
        'ROLE_MWS_GENERIC_SHIPMENT_TRANSITION'
      ],
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.patchObject(url, payload, true)
    );
  }
}
