import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../models';
import { DataMedium } from '../../../warehouse/models';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { OrdersSelectors } from '../../store/selectors';
import {
  DisposalTypesSelectors,
  FileSystemsSelectors,
  OperatingSystemsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors,
  SymptomsSelectors
} from '../../../master-data/store/selectors';
import {
  DisposalTypesActions,
  FileSystemsActions,
  OperatingSystemsActions,
  StorageSystemManufacturersActions,
  StorageSystemsActions,
  SymptomsActions
} from '../../../master-data/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { DataMediumsSelectors } from '../../../warehouse/store/selectors';
import { DataMediumsActions } from '../../../warehouse/store';
import {
  DisposalType,
  FileSystem,
  OperatingSystem,
  StorageSystem,
  StorageSystemManufacturer,
  Symptom
} from '../../../master-data/models';
import { DeviceDetailsEditDialogComponent } from '../device-details-edit-dialog/device-details-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Actions } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-device-details',
  styleUrls: ['device-details.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Speichersystem</span>
        <span class="btn--edit">
          <button
            mat-icon-button
            [disabled]="isLoading$ | async"
            (click)="openDeviceDetailEditDialog()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </span>
      </div>
      <div class="card__content pos-relative py-3">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="overview grid grid-no-gutter" *ngIf="order">
          <dl class="list--dl grid column-14  p-a--16">
            <dt class="column-5">Datenträger OK</dt>
            <dd class="column-9" [matTooltip]="getDataMediaOk()">
              L:
              <mat-icon
                color="green"
                class="me-2"
                *ngIf="order.dataMediaOkLogisticsDepartment === true"
                >check
              </mat-icon>
              <mat-icon
                color="red"
                class="me-2"
                *ngIf="order.dataMediaOkLogisticsDepartment === false"
                >close
              </mat-icon>
              <mat-icon
                color="lightgrey"
                class="ms-2"
                *ngIf="
                  order.dataMediaOkLogisticsDepartment === undefined ||
                  order.dataMediaOkLogisticsDepartment === null
                "
                >question_mark
              </mat-icon>

              T:
              <mat-icon
                color="green"
                class="ms-2"
                *ngIf="order.dataMediaOkTechnicalDepartment === true"
                >check
              </mat-icon>
              <mat-icon
                color="red"
                class="ms-2"
                *ngIf="order.dataMediaOkTechnicalDepartment === false"
                >close
              </mat-icon>
              <mat-icon
                color="lightgrey"
                class="ms-2"
                *ngIf="
                  order.dataMediaOkTechnicalDepartment === undefined ||
                  order.dataMediaOkTechnicalDepartment === null
                "
                >question_mark
              </mat-icon>
            </dd>

            <dt class="column-5">Typ:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName('storageSystemsEntities', 'storageSystem')
              }}
            </dd>

            <dt class="column-5">Größe:</dt>
            <dd class="column-9">
              {{ (order?.sizeInGB | number) + ' GB' || '-' }}
            </dd>

            <dt class="column-5">Hersteller:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'storageSystemManufacturersEntities',
                  'storageSystemManufacturer'
                )
              }}
            </dd>

            <dt class="column-5">Modell:</dt>
            <dd class="column-9">
              {{
                order?.storageManufacturerModel
                  ? order.storageManufacturerModel
                  : '-'
              }}
            </dd>

            <dt class="column-5">Seriennummer:</dt>
            <dd class="column-9">
              {{
                order?.storageManufacturerSerialNumber
                  ? order.storageManufacturerSerialNumber
                  : '-'
              }}
            </dd>

            <dt class="column-5">Datum Datenverlust:</dt>
            <dd class="column-9">
              {{
                order?.dataLossAt
                  ? isValidDateFormat(order?.dataLossAt)
                    ? (order.dataLossAt | date: 'dd.MM.Y')
                    : order.dataLossAt
                  : '-'
              }}
            </dd>

            <dt class="column-5">Datum Feststellung:</dt>
            <dd class="column-9">
              {{
                order?.dataLossDetectedAt
                  ? isValidDateFormat(order?.dataLossDetectedAt)
                    ? (order.dataLossDetectedAt | date: 'dd.MM.Y')
                    : order.dataLossDetectedAt
                  : '-'
              }}
            </dd>

            <dt class="column-5">Dateisystem:</dt>
            <dd class="column-9">
              {{ getOrderPropertyName('fileSystemsEntities', 'fileSystem') }}
            </dd>

            <dt class="column-5">Künftiges Dateisystem:</dt>
            <dd class="column-9">
              {{
                order?.futureFileSystem
                  ? getOrderPropertyName(
                      'fileSystemsEntities',
                      'futureFileSystem'
                    )
                  : '-'
              }}
            </dd>

            <dt class="column-5">Betriebssystem:</dt>
            <dd class="column-9">
              {{
                getOrderPropertyName(
                  'operatingSystemsEntities',
                  'operatingSystem'
                )
              }}
            </dd>

            <dt class="column-5">Host:</dt>
            <dd class="column-9">{{ order?.host ? order.host : '-' }}</dd>

            <dt class="column-5">PIN:</dt>
            <dd class="column-9">
              {{ order?.pinPassword ? order.pinPassword : '-' }}
            </dd>

            <dt class="column-5">Symptom:</dt>
            <dd class="column-9">
              {{
                order?.symptom
                  ? getOrderPropertyName('symptomEntities', 'symptom')
                  : '-'
              }}
            </dd>
          </dl>
        </div>

        <div class="more">
          <mat-accordion displayMode="flat" style="width: 100%" [multi]="true">
            <mat-expansion-panel
              class="mat-elevation-z0"
              style="border-bottom: 1px solid #ebebeb;"
            >
              <mat-expansion-panel-header
                collapsedHeight="32px"
                expandedHeight="32px"
              >
                <mat-panel-title>Datenträger</mat-panel-title>
              </mat-expansion-panel-header>

              <dl class="list--dl row">
                <dt class="col-4">Datenträgerzerstörung</dt>
                <dd class="col-8">
                  {{
                    getOrderPropertyName(
                      'disposalTypesEntities',
                      'disposalType'
                    )
                  }}
                </dd>
                <dt class="col-4">Anzahl (laut Auftrag):</dt>
                <dd class="col-8">
                  {{ order?.numberOfDataMedia ? order.numberOfDataMedia : '-' }}
                </dd>
                <dt class="col-4">Datenträger/Gehäuse im Lager:</dt>
                <dd class="col-8">{{ dataMediums?.length }}</dd>
              </dl>
              <app-loading-overlay
                *ngIf="loadingMediums$ | async"
              ></app-loading-overlay>
              <p *ngIf="dataMediums?.length <= 0">
                Diesem Auftrag sind keine Datenträger zugewiesen.
              </p>

              <dl
                class="row py-3"
                style="background-color: #ebebeb"
                *ngIf="dataMediums?.length > 0"
              >
                <ng-container *ngFor="let item of dataMediums; let last = last">
                  <dt class="col-4">Type</dt>
                  <dd class="col-8">
                    {{
                      (item && item['@type'] ? item['@type'] : '')
                        | i18nSelect: customerMediaTypeMap
                    }}
                  </dd>
                  <dt class="col-4">Speichersystem</dt>
                  <dd class="col-8">{{ item.storageSystemType?.name }}</dd>
                  <dt class="col-4">Hersteller</dt>
                  <dd class="col-8">
                    {{ item.storageSystemManufacturer?.name }}
                  </dd>

                  <dt class="col-4">Modell</dt>
                  <dd class="col-8">{{ item.storageSystemModel }}</dd>

                  <dt class="col-4">Seriennummer</dt>
                  <dd class="col-8">{{ item.storageSystemSerialNumber }}</dd>

                  <dt class="col-4">Speichergröße</dt>
                  <dd class="col-8">{{ item.storageSystemSize }} GB</dd>

                  <dt class="col-4">Status</dt>
                  <dd class="col-8">{{ item.state }}</dd>

                  <div class="column-14" *ngIf="!last">
                    <hr />
                  </div>
                </ng-container>
              </dl>
            </mat-expansion-panel>
            <mat-expansion-panel
              class="mat-elevation-z0"
              style="border-bottom: 1px solid #ebebeb"
              *ngFor="let item of accordionProperties"
              [disabled]="!order || !order[item.propertyName]"
            >
              <mat-expansion-panel-header
                collapsedHeight="32px"
                expandedHeight="32px"
              >
                <mat-panel-title>{{ item.caption }}</mat-panel-title>
              </mat-expansion-panel-header>
              <span
                *ngIf="order && order[item.propertyName]"
                class="pre-like"
                >{{ order[item.propertyName] }}</span
              >

              <!--<ng-container *ngIf="!!order[item.propertyName]; else nothing">
                <span>{{ order[item.propertyName] }}</span>
              </ng-container>-->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  `
})
export class DeviceDetailsComponent implements OnInit {
  @Input() order$: Observable<Order>;
  order: Order;
  isLoading$: Observable<boolean>;
  loadingMediums$: Observable<boolean>;

  dataMediums: Array<DataMedium>;
  disposalTypesEntities: { [iri: string]: DisposalType };
  fileSystemsEntities: { [iri: string]: FileSystem };
  operatingSystemsEntities: { [iri: string]: OperatingSystem };
  storageSystemsEntities: { [iri: string]: StorageSystem };
  symptomEntities: { [iri: string]: Symptom };
  storageSystemManufacturersEntities: {
    [iri: string]: StorageSystemManufacturer;
  };

  accordionProperties: Array<{ propertyName: string; caption: string }> = [
    // {
    //   propertyName: 'symptom',
    //   caption: 'Symptome'
    // },
    {
      propertyName: 'history',
      caption: 'Historie'
    },
    {
      propertyName: 'stepsAlreadyTaken',
      caption: 'Bereits erfolgte Schritte'
    },
    {
      propertyName: 'requiredData',
      caption: 'Benötigte Daten'
    }
  ];
  customerMediaTypeMap = {
    CustomerDataMedium: 'Speichermedium Kunde',
    EnclosureDataMedium: 'Gehäuse Kunde',
    other: 'bla'
  };

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private notifierService: NotifierService,
    public actions$: Actions
  ) {}

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(DisposalTypesSelectors.isLoading),
      this.store$.select(StorageSystemsSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading),
      this.store$.select(FileSystemsSelectors.isLoading),
      this.store$.select(SymptomsSelectors.isLoading)
    ]);
    this.loadingMediums$ = isLoadingArray([
      this.store$.select(DataMediumsSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading)
    ]);

    this.order$.subscribe(order => {
      if (!order) {
        return;
      }
      this.order = order;
      const orderIri = this.order['@id'];
      this.loadDataMediums(orderIri);
    });
    this.loadDisposalTypes();
    this.loadStorageSystemManufacturersEntities();
    this.loadStorageSystems();
    this.loadFileSystems();
    this.loadOperatingSystems();
    this.loadSymptoms();
  }

  getOrderPropertyName(entitiesSet: string, orderProperty: string): string {
    if (
      !this[entitiesSet] ||
      !this.order ||
      !this?.order.hasOwnProperty(orderProperty)
    ) {
      return '-';
    }
    const key = this.order[orderProperty]['@id']
      ? this.order[orderProperty]['@id']
      : this.order[orderProperty];
    return this[entitiesSet][key]?.name ? this[entitiesSet][key].name : '-';
  }

  openDeviceDetailEditDialog(): void {
    this.dialog.open(DeviceDetailsEditDialogComponent, {
      disableClose: false,
      data: { order: this.order }
    });
  }

  isValidDateFormat(input: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return regex.test(input);
  }

  getDataMediaOk(): string {
    const logistik = this.order.dataMediaOkLogisticsDepartment;
    const technik = this.order.dataMediaOkTechnicalDepartment;
    return `Logistik: ${
      logistik === null || logistik === undefined
        ? 'unbekannt'
        : logistik === true
        ? 'ok'
        : 'nicht OK'
    },
    Technik: ${
      technik === null || technik === undefined
        ? 'unbekannt'
        : technik === true
        ? 'ok'
        : 'nicht OK'
    }`;
  }

  private loadDataMediums(orderIri): void {
    const orderUUID = getUuidFromIri(orderIri);
    this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, { orderIri })
      .subscribe(entities => (this.dataMediums = entities));
    this.store$.dispatch(
      DataMediumsActions.ReadDataMediums({
        page: -1,
        params: { 'stockItem.order.uuid': orderUUID }
      })
    );
  }

  private loadDisposalTypes(): void {
    this.store$
      .select(DisposalTypesSelectors.selectDisposalTypesEntities)
      .subscribe(entities => (this.disposalTypesEntities = entities));
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }

  private loadStorageSystemManufacturersEntities(): void {
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .subscribe(
        entities => (this.storageSystemManufacturersEntities = entities)
      );
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }

  private loadStorageSystems(): void {
    this.store$
      .select(StorageSystemsSelectors.sEntities)
      .subscribe(entities => (this.storageSystemsEntities = entities));
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }

  private loadFileSystems(): void {
    this.store$
      .select(FileSystemsSelectors.selectFileSystemsEntities)
      .subscribe(entities => (this.fileSystemsEntities = entities));
    loadIfNotLoaded(
      this.store$,
      FileSystemsSelectors.isLoaded,
      FileSystemsActions.ReadFileSystems()
    );
  }

  private loadOperatingSystems(): void {
    this.store$
      .select(OperatingSystemsSelectors.selectOperatingSystemsEntities)
      .subscribe(entities => (this.operatingSystemsEntities = entities));
    loadIfNotLoaded(
      this.store$,
      OperatingSystemsSelectors.isLoaded,
      OperatingSystemsActions.ReadOperatingSystems()
    );
  }

  private loadSymptoms(): void {
    this.store$
      .select(SymptomsSelectors.selectSymptomsEntities)
      .subscribe(entities => (this.symptomEntities = entities));
    loadIfNotLoaded(
      this.store$,
      SymptomsSelectors.isLoaded,
      SymptomsActions.ReadSymptoms()
    );
  }
}
