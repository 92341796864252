import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Order, OrderState} from '../../models';
import {OrdersSelectors, OrderStatesSelectors} from '../../store/selectors';
import {OrderStatesActions} from '../../store';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-order-status-select',
  styleUrls: ['./order-status-select.component.scss'],
  template: `
    <div [formGroup]="formGroup">

      <ng-select
        bindValue="id"
        [clearable]="true"
        bindLabel="name"
        [searchable]="true"
        [searchFn]="findItem"
        [items]="items$|async"
        [loading]="isLoading$| async"
        [formControlName]="fieldName"
        (change)="setSelectedItem($event)"
        [placeholder]="'orders.order_status_select.placeholder' | translate">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item.name)>0">({{getOrderCount(item.name)}}x)</span>

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item.name)>0">({{getOrderCount(item.name)}}x)</span>
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderStatusSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Output() updateSelectedObject: EventEmitter<OrderState> = new EventEmitter<OrderState>();

  items$: Observable<Array<OrderState>>;
  isLoading$: Observable<boolean>;
  selectedItem: OrderState;

  orders: Order[];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.items$ = this.store$.select(OrderStatesSelectors.selectOrderStates);
    this.isLoading$ = this.store$.select(OrderStatesSelectors.isLoading);
    loadIfNotLoaded(this.store$, OrderStatesSelectors.isLoaded, OrderStatesActions.ReadOrderStates());
    this.store$.select(OrdersSelectors.sList).subscribe((orders) => {
      this.orders = orders;
    });
  }

  getOrderCount(statusName: string): number {
    return this.orders.filter(e => e.state[statusName]).length || -1;
  }

  setSelectedItem(element: OrderState): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: OrderState): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

}
