import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'lab-location-selection-box',
  styleUrls: ['lab-location-selection-box.component.scss'],
  template: `

    <div class="grid grid-middle">

      <div class="column-7" [formGroup]="alf">

        <ng-select
          placeholder="Ort wählen"
          [items]="labLocations"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="true"
          [clearable]="false"
          [formControlName]="locationPropertyName"
        ></ng-select>
      </div>

      <div class="column-7">
        <button mat-button color="green" (click)="handleSubmit()" [disabled]="alf.invalid">
          <mat-icon class="m-r--8">save</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>
      </div>
    </div>

    <!--<pre>{{ locationPropertyName | json }}</pre>-->
  `
})
export class LabLocationSelectionBoxComponent implements OnInit {

  @Input() leftButtonLabel: string;
  @Input() locationPropertyName: string;

  @Output() requestUpdateOrder: EventEmitter<any> = new EventEmitter();

  alf: FormGroup;
  // todo: should be served via @Input()
  labLocations = [
    {
      '@id': '/api/lab_locations/LAB_LOCATION_DR_DE',
      '@type': 'LabLocation',
      name: 'DR DE'
    },
    {
      '@id': '/api/lab_locations/LAB_LOCATION_DR_DE_REMOTE',
      '@type': 'LabLocation',
      name: 'DR DE (Remote)'
    },
    {
      '@id': '/api/lab_locations/LAB_LOCATION_DR_EXT_DHE',
      '@type': 'LabLocation',
      name: 'DR EXT (DHE)'
    },
    {
      '@id': '/api/lab_locations/LAB_LOCATION_DR_EXT_DRE',
      '@type': 'LabLocation',
      name: 'DR EXT (DRE)'
    }
  ];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.alf = this.fb.group({
      [this.locationPropertyName]: this.fb.control(null, [Validators.required])
    });
  }

  handleSubmit(): void {
    this.requestUpdateOrder.emit(this.alf.value);
  }
}
