import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import * as fromTicketsModuleModels from '../../../tickets/models';
import {Observable, Subject} from 'rxjs';
import {Ticket} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {takeUntil} from 'rxjs/operators';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {RemoteAccessReasonsSelectors} from '../../store/selectors';
import {RemoteAccessReasonsActions} from '../../store';
import {RemoteAccessFormDialogComponent} from '../remote-access-form-dialog/remote-access-form-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {extractIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-remote-access-details',
  styleUrls: ['remote-access-details.component.scss'],
  template: `

    <div class="card">

      <div class="card__heading">
        <span>Remote-Zugriff</span>

        <span class="btn--edit">
        <button mat-icon-button (click)="handleRequestRemoteAccessForm()">
          <mat-icon>edit</mat-icon>
        </button>
      </span>
      </div>

      <div class="card__content p-a--24" *ngIf="(ticket$|async) as ticket">
        <p *ngIf="!(ticket && ticket.remoteAccess && ticket.remoteAccess.ip)">Es ist kein Remotezugriff
          geplant.</p>
        <dl class="list--dl grid" *ngIf="ticket?.remoteAccess?.ip">
          <dt class="column-4">Typ:</dt>
          <dd class="column-10">{{ getRemoteAccessReasonName(ticket?.remoteAccess?.remoteAccessReason) }}</dd>

          <dt class="column-4">URL:</dt>
          <dd class="column-10">{{ ticket?.remoteAccess?.ip || '-' }}</dd>

          <dt class="column-4">User:</dt>
          <dd class="column-10">{{ ticket?.remoteAccess?.user || '-' }}</dd>

          <dt class="column-4">Passwort:</dt>
          <dd class="column-10">
            <span *ngIf="ticket?.remoteAccess?.password && !showPassword">********</span>
            <span
              *ngIf="ticket?.remoteAccess?.password && showPassword">{{ ticket?.remoteAccess?.password || '-' }}</span>
            <span *ngIf="!ticket?.remoteAccess?.password">-</span>
            <button mat-button mat-icon-button (click)="showPassword=!showPassword"
                    *ngIf="ticket?.remoteAccess?.password">
              <mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
          </dd>

          <dt class="column-4">Bemerkungen:</dt>
          <dd class="column-10">{{ ticket?.remoteAccess?.comment || '-' }}</dd>
        </dl>
      </div>
    </div>

    <!--<pre>{{ ticket?.remoteAccess? | json }}</pre>-->
  `
})
export class RemoteAccessDetailsComponent implements OnInit, OnDestroy {

  remoteAccessReasonsEntities: { [iri: string]: fromTicketsModuleModels.RemoteAccessReason };
  @Input() ticket$: Observable<Ticket>;

  @Output() requestShowRemoteAccessForm: EventEmitter<void> = new EventEmitter<void>();
  onDestroy$: Subject<any> = new Subject<any>();
  showPassword = false;

  constructor(private store$: Store<ApplicationState>,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadRemoteAccessReasons();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestRemoteAccessForm(): void {
    this.dialog.open(RemoteAccessFormDialogComponent, {data: {ticket$: this.ticket$}});
  }

  getRemoteAccessReasonName(iri: string): string {
    if (!this.remoteAccessReasonsEntities) {
      return '-';
    }
    return this.remoteAccessReasonsEntities[extractIri(iri)]?.name || '-';
  }

  private loadRemoteAccessReasons(): void {
    this.store$.select(RemoteAccessReasonsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.remoteAccessReasonsEntities = entities;
      });
    loadIfNotLoaded(this.store$, RemoteAccessReasonsSelectors.isLoaded, RemoteAccessReasonsActions.ReadRemoteAccessReasons());

  }

}
