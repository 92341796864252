import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as fromMasterDataModuleModels from '../../models';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'service-form',
  styleUrls: ['service-form.component.scss'],
  template: `
    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Leistung bearbeiten' : 'Neue Leistung anlegen' }}</span>
      </div>

      <div class="card__content">
        <div class="grid" [formGroup]="sf">

          <div class="column-14">
            <mat-form-field>
              <mat-label>Name der Leistung</mat-label>
              <input type="text" formControlName="name" matInput>
              <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
            </mat-form-field>
          </div>

          <ng-container formGroupName="price">

            <mat-form-field class="column-7">
              <mat-label>Preis (netto)</mat-label>
              <input type="text" formControlName="value" currencyMask required matInput>
              <mat-hint align="start"
                        *ngIf="errors.hasOwnProperty('price.value')">{{ errors['price.value'].message }}</mat-hint>
            </mat-form-field>

            <div class="column-7 mat-form-field">

              <ng-select
                placeholder="Währung*"
                [items]="currencies"
                bindLabel="name"
                [searchable]="false"
                [clearable]="false"
                formControlName="currency"
                bindValue="code"
              ></ng-select>
              <mat-hint align="start"
                        *ngIf="errors.hasOwnProperty('price.currency')">{{ errors['price.currency'].message }}</mat-hint>
            </div>
          </ng-container>

          <div class="column-7 mat-form-field">

            <ng-select
              placeholder="Standard-Steuersatz"
              [items]="taxRates"
              bindValue="@id"
              bindLabel="name"
              required
              [clearable]="false"
              [searchable]="false"
              formControlName="defaultTax"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.defaultTax">{{ errors.defaultTax.message }}</mat-hint>
          </div>

          <div class="column-7 mat-form-field">

            <ng-select
              placeholder="Einheit"
              [items]="productUnits"
              bindValue="@id"
              appendTo="body"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="productUnit"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.productUnit">{{ errors.productUnit.message }}</mat-hint>
          </div>

          <div class="column-14">
            <mat-form-field>
              <mat-label>Beschreibung</mat-label>
              <textarea formControlName="description" matInput matTextareaAutosize></textarea>
              <mat-hint align="start" *ngIf="errors?.description">{{ errors.description.message }}</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="grid">
          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || sf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="sf.invalid || sf.untouched" mat-flat-button color="green" (click)="handleSubmit()">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ sf.value | json }}</pre>-->
    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
  `
})
export class ServiceFormComponent implements OnInit {

  @Input() currencies: Array<fromMasterDataModuleModels.Currency>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromMasterDataModuleModels.Service>;
  @Input() productUnits: Array<fromMasterDataModuleModels.ProductUnit>;
  @Input() taxRates: Array<fromMasterDataModuleModels.Tax>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromMasterDataModuleModels.Service,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromMasterDataModuleModels.Service,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  sf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.sf, preset);
      this.fs.resetFormErrors(this.sf);
    });
  }

  initForm(): void {
    this.sf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      description: this.fb.control(null, [Validators.minLength(10)]),
      price: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      defaultTax: this.fb.control(null, Validators.required),
      productUnit: this.fb.control(null, Validators.required)
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromMasterDataModuleModels.Service = {...this.sf.value};


    // Cast float to string as required per API
    payload.price.value = payload.price.value.toString();

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Service'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Service'});
  }
}
