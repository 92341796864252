import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {CustomerAddressesSelectors, CustomersSelectors} from '../../../customers/store/selectors';
import {CustomerAddressesActions, CustomersActions} from '../../../customers/store';
import {OrdersSelectors} from '../../../orders/store/selectors';
import {OrdersActions} from '../../../orders/store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Order} from '../../../orders/models';
import {Address, Customer, CustomerAddress} from '../../../customers/models';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {distinctUntilChanged, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {extractIri, extractUUID} from '../../../shared/utilities/objects.utility';
import {InvoiceLike} from '../../models';
import {CountriesSelectors} from "../../../master-data/store/selectors";
import {CountriesActions} from "../../../master-data/store";
import * as fromMasterDataModuleModels from "../../../master-data/models";
import {ErrorsObject} from "../../../shared/utilities/error-utility.utility";

@Component({
  selector: 'app-invoice-select-recipient-modal',
  styleUrls: ['./invoice-select-recipient-modal.component.scss'],
  template: `
    <div class="dialog__header grid grid-no-gutter">
      <div class="column-12">
        <h2>Kunden festlegen</h2>
      </div>
      <div class="column-2 m-ta--2">
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="issuer-list" mat-dialog-content style="min-width: 550px;" [formGroup]="form">
      <p class="heading--h3">Kunden wählen</p>
      <!-- <ng-select
         [items]="orders$ | async"
         [loading]="ordersIsLoading$ | async"
         placeholder="Auftrag"
         bindLabel="orderNumber"
         bindValue="@id"
         [searchFn]="findOrder"
         (change)="handleSelectOrder($event)"
         formControlName="order"
         [clearable]="true"
         [markFirst]="false"
       >
         <ng-template ng-option-tmp let-item="item">
           <span>#{{ item.orderNumber }}</span>
           <span *ngIf="item.customer.vip" class="left badge--vip">
               <mat-icon>check</mat-icon>
               <span>VIP</span>
             </span>
           <span class="column-5">{{ item.customer.nameLine1 }}</span>
         </ng-template>
         <ng-template ng-label-tmp let-item="item">
           <span>#{{ item.orderNumber }}</span>
           <span *ngIf="item.customer.vip" class="left badge--vip">
               <mat-icon>check</mat-icon>
               <span>VIP</span>
             </span>
           <span class="column-5">{{ item.customer.nameLine1 }}</span>
         </ng-template>
       </ng-select>
 -->
      <app-customer-select
        label="Firma oder Kunde"
        [preset$]="customer$"
        [readonly]="customerReadOnly"
        (selectCustomer)="handleSelectCustomer($event)"></app-customer-select>


      <p class="heading--h3">Rechnungsanschrift</p>
      <div class="alert alert-warning" *ngIf="!!customer &&( customerAddresses$ | async).length<=0">Leider existieren
        für den ausgewählten Kunden keine Rechnungsadressen. Bitte zum Kunden wechseln und Addresse anlegen:
        <a [routerLink]="['/customers',extractUUID(customer)]" (click)="close()">Zum Kunden</a></div>

      <ng-container>
        <ng-select
          [items]="customerAddresses$ | async"
          notFoundText="Keine Ergebnisse"
          [searchable]="false"
          [clearable]="false"
          [readonly]="!customer "
          (change)="handleUpdateInvoiceRecipient($event)"
          formControlName="address"
          bindValue="@id"
          [placeholder]="!!customer? 'Adressen für diesen Kunden:': 'Zunächst Kunde oder Auftrag wählen'"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="ng-option">
              <span>{{ formatAddress(item.address) }}</span>
            </div>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="ng-option">
              <span>{{ formatAddress(item.address) }}</span>
            </div>
          </ng-template>
        </ng-select>
      </ng-container>
      <mat-checkbox
        *ngIf="!!customer"
        class="edit-address m-a--8"
        formControlName="editAddress"
      > {{'invoices.invoices_preview.invoices_select_recipient_modal.overwrite_address' | translate}}
      </mat-checkbox>
      <div class="address-preview p-4" *ngIf="!isEditAddressChecked">
        <p *ngIf="customer">
          <span class="name-line-1">{{ customer.nameLine1 }}</span>
          <span class="name-line-2"> ({{ customer.nameLine2 }})</span>
        </p>
        <address *ngIf="customerAddress else noAddress">
          {{ customerAddress.address.line1 }} {{ customerAddress.address.line2 }}<br>
          <ng-container *ngIf="customerAddress?.address?.line3">
            {{ customerAddress.address.line3 }}<br>
          </ng-container>
          {{ customerAddress.address.zipPostcode }} {{ customerAddress.address.city }}<br>
          {{ customerAddress.address.country }}
        </address>
        <ng-template #noAddress>Keine Addresse ausgewählt</ng-template>
      </div>
      <div class="edit-address-wrapper" *ngIf="isEditAddressChecked">
        <div class="card__content p-y--24">
          <div class="grid">
            <mat-form-field class="column-7">
              <mat-label>Firma</mat-label>
              <input type="text" matInput formControlName="nameLine1">
              <mat-hint align="start" *ngIf="errors?.nameLine1">{{ errors.nameLine1.message }}</mat-hint>
            </mat-form-field>
            <mat-form-field class="column-7">
              <mat-label>Zusatz</mat-label>
              <input type="text" matInput formControlName="nameLine2">
              <mat-hint align="start" *ngIf="errors?.nameLine2">{{ errors.nameLine2.message }}</mat-hint>
            </mat-form-field>
              <mat-form-field class="column-9">
                <mat-label>Straße</mat-label>
                <input type="text" matInput formControlName="line1" />
                <mat-hint align="start" *ngIf="errors?.line1">{{
                    errors.line1.message
                  }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>Hausnummer</mat-label>
                <input type="text" matInput formControlName="line2" />
                <mat-hint align="start" *ngIf="errors?.line2">{{
                    errors.line2.message
                  }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>PLZ</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="zipPostcode"
                />
                <mat-hint align="start" *ngIf="errors?.zipPostcode">{{
                    errors.zipPostcode.message
                  }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="column-9">
                <mat-label>Stadt</mat-label>
                <input type="text" matInput formControlName="city" />
                <mat-hint align="start" *ngIf="errors?.city">{{
                    errors.city.message
                  }}</mat-hint>
              </mat-form-field>

              <mat-form-field class="column-7">
                <mat-label>c/o, Adresszusatz</mat-label>
                <input formControlName="line3" matInput type="text" />
                <mat-error>
                  <app-form-error
                    [fieldName]="'line3'"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
              <div class="mat-form-field column-7">
                <ng-select
                  placeholder="Land*"
                  [items]="countries$ | async"
                  bindLabel="name"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="country"
                  bindValue="code"
                ></ng-select>
                <mat-hint align="start" *ngIf="errors?.country">{{
                    errors.country.message
                  }}</mat-hint>
              </div>
          </div>
        </div>
      </div>
      <div class="m-ta--2 m-t--16">
        <button mat-flat-button color="green" (click)="save()">
          <mat-icon class="m-r--8">save</mat-icon>
          <span>Speichern und schließen</span>
        </button>
      </div>
    </div>

  `
})
export class InvoiceSelectRecipientModalComponent implements OnInit, OnDestroy {
  @Input() errors: ErrorsObject;
  onDestroy$: Subject<any> = new Subject<any>();
  orders$: Observable<Array<Order>>;
  ordersIsLoading$: Observable<boolean>;
  customers$: Observable<Array<Customer>>;
  customersIsLoading$: Observable<boolean>;

  countries$: Observable<Array<fromMasterDataModuleModels.Country>>;
  countriesIsLoading$: Observable<boolean>;

  customerAddresses$: Observable<Array<CustomerAddress>>;
  customerReadOnly = false;

  form: FormGroup;
  customer: Customer;
  customer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  order: Order;
  customerAddress: CustomerAddress;
  loadedInvoice: InvoiceLike;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InvoiceSelectRecipientModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { invoice$: Observable<InvoiceLike>, customerReadOnly: boolean}
  ) {
    if (this.data.customerReadOnly) {
      this.customerReadOnly = this.data.customerReadOnly;
    }
  }

  ngOnInit(): void {

    this.customers$ = this.store$.select(CustomersSelectors.selectCustomers);
    this.customersIsLoading$ = this.store$.select(CustomersSelectors.isLoading);
    this.ordersIsLoading$ = this.store$.select(OrdersSelectors.isLoading);
    loadIfNotLoaded(this.store$, OrdersSelectors.isLoaded, OrdersActions.ReadOrders({page: -1}));
    this.form = this.fb.group({
      order: this.fb.control(null),
      address: this.fb.control(null),
      customer: this.fb.control(null),
      editAddress: this.fb.control(null),
      nameLine1: this.fb.control(""),
      nameLine2: this.fb.control(""),
      line1: this.fb.control(""),
      line2: this.fb.control(""),
      line3: this.fb.control(""),
      country: this.fb.control(""),
      zipPostcode: this.fb.control(""),
      city: this.fb.control(""),
    });
    this.data.invoice$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), filter(i => !!i))
      .subscribe(invoice => {
        this.loadedInvoice = invoice;
        if (invoice.customerNumber) {
          this.store$.dispatch(CustomersActions.ReadCustomers({page: 1, params: {id: invoice.customerNumber}}));
          this.store$.select(CustomersSelectors.sByCustomerNumber, {customerNumber: invoice.customerNumber})
            .pipe(takeUntil(this.onDestroy$), filter(i => !!i), take(1))
            .subscribe(c => {
              this.form.get('customer').setValue(c['@id'], {selfOnly: false});
              this.handleSelectCustomer(c);
            });

        }

      });

    this.form.get('customer').valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe((newValue) => {
        if (newValue === null) {
          this.form.get('address').setValue(null);
        }
        if (this.order && extractIri(this.order.customer) !== newValue) {
          this.form.get('order').setValue(null);
        }
      });
    this.loadCountries();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


  findCustomer(term: string, item: Customer): boolean {
    return item.nameLine1.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  findOrder(term: string, item: Order): boolean {
    return (item.orderNumber + '').toLowerCase().indexOf(term.toLowerCase()) > -1
      || item.customer.nameLine1.toLowerCase().indexOf(term.toLowerCase()) > -1
      || item.customer.nameLine2.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleSelectCustomer(customer: Customer): void {
    if (customer) {
      this.setCustomerAndReadCustomerAddresses(customer['@id']);
    }
  }

  handleSelectOrder(order: Order): void {
    this.order = order;
    if (!order) {
      return;
    }
    this.form.get('customer').setValue(order.customer['@id'], {selfOnly: false});
    this.setCustomerAndReadCustomerAddresses(order.customer['@id']);
  }

  handleUpdateInvoiceRecipient(recipient: CustomerAddress): void {
    this.customerAddress = recipient;
      this.setCustomerAddress();
  }

  save(): void {
    if(this.form.get('editAddress').value) {
      this.customer = {
        ...this.customer,
      };
      this.customerAddress = {
        ...this.customerAddress,
        address: {
          line1: this.form.get('line1').value,
          line2: this.form.get('line2').value,
          line3: this.form.get('line3').value,
          country: this.form.get('country').value,
          zipPostcode: this.form.get('zipPostcode').value,
          city: this.form.get('city').value
        },
        nameLine1: this.form.get('nameLine1').value,
        nameLine2: this.form.get('nameLine2').value,
      };
    }
    this.dialogRef.close({order: this.order, customer: this.customer, customerAddress: this.customerAddress});

  }

  close(): void {
    this.dialogRef.close(null);
  }

  setCustomerAndReadCustomerAddresses(customerIri: string): void {
    this.store$.select(CustomersSelectors.selectCustomerByIndex, {iri: customerIri})
      .pipe(tap(e => {
          if (!e) {
            this.store$.dispatch(CustomersActions.ReadCustomer({iri: customerIri}));
          }
        }),
        filter(e => !!e)
        , take(1)).subscribe(c => {
      this.customer = c;
      this.customer$.next(c);

    });

    this.customerAddresses$ = this.store$.select(CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri, {customerIri});
    this.customerAddresses$
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
      .subscribe(addresses => {
        if (this.loadedInvoice?.recipient && this.form.get('address').value === null) {
          const foundAddress = addresses.find(e => e.address && this.loadedInvoice?.recipient?.address &&
            e.address.line1 === this.loadedInvoice.recipient.address.line1 &&
            e.address.line2 === this.loadedInvoice.recipient.address.line2 &&
            e.address.city === this.loadedInvoice.recipient.address.city &&
            e.address.country === this.loadedInvoice.recipient.address.country &&
            e.address.zipPostcode === this.loadedInvoice.recipient.address.zipPostcode
          );
          if (!foundAddress) {
            this.setCustomerAddress();
            return;
          }
          this.handleUpdateInvoiceRecipient(foundAddress);
          this.form.get('address').setValue(foundAddress['@id'], {selfOnly: false});
          return;
        }
        const addressIri = this.order?.billingAddress ? this.order?.billingAddress : this.customer?.defaultBillingAddress;
        if (addressIri) {
          this.form.get('address').setValue(addressIri, {selfOnly: false});
        } else {
          this.form.get('address').setValue(null, {selfOnly: false});

        }
        this.handleUpdateInvoiceRecipient(addresses.find(e => e['@id'] === addressIri));
      });
    this.store$.dispatch(CustomerAddressesActions.ReadCustomerBillingAddresses({customerIri}));

  }

  private loadCountries(): void {
    this.countries$ = this.store$.select(CountriesSelectors.selectCountries);
    this.countriesIsLoading$ = this.store$.select(CountriesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      CountriesSelectors.isLoaded,
      CountriesActions.ReadCountries()
    );
  }
  setCustomerAddress(): void {
    this.form.get('country').setValue(this.loadedInvoice?.recipient?.address?.country);
    this.form.get('nameLine1').setValue(this.customer?.nameLine1);
    this.form.get('nameLine2').setValue(this.customer?.nameLine2);
    this.form.get('line1').setValue(this.loadedInvoice?.recipient?.address?.line1);
    this.form.get('line2').setValue(this.loadedInvoice?.recipient?.address?.line2);
    this.form.get('line3').setValue(this.loadedInvoice?.recipient?.address?.line3);
    this.form.get('country').setValue(this.loadedInvoice?.recipient?.address?.country);
    this.form.get('zipPostcode').setValue(this.loadedInvoice?.recipient?.address?.zipPostcode);
    this.form.get('city').setValue(this.loadedInvoice?.recipient?.address?.city);
  }
  get isEditAddressChecked(): boolean {
    return this.form.get('editAddress').value;
  }

  protected readonly extractUUID = extractUUID;
}
