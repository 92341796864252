import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { formatNumber } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { ModalDialogOptions } from '../../../application-state/models';
import { OrdersActions } from '../../../orders/store';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { PayableInvoicesService } from '../../services';
import {
  PaymentRecorderComponent,
  SendDocumentToolComponent
} from '../../components';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import {
  RouterActions,
  TransitionsActions
} from '../../../application-state/store/actions';
import * as moment from 'moment';
import {
  DocumentDeliveriesActions,
  DocumentDeliveryProvidersActions,
  InvoiceItemsActions,
  InvoicePaymentsActions,
  PartialInvoiceItemsActions,
  PayableInvoicesActions,
  PaymentProcessesActions
} from '../../store';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import {
  DocumentDeliveryProvidersSelectors,
  PayableInvoicesSelectors,
  PaymentProcessesSelectors
} from '../../store/selectors';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {
  CurrenciesActions,
  DepartmentsActions,
  ProductsActions,
  ProductUnitsActions,
  ServicesActions,
  TaxesActions
} from '../../../master-data/store/actions';
import {
  CurrenciesSelectors,
  DepartmentsSelectors,
  ProductUnitsSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import { Administrator } from '../../../administrators/models';
import {
  Currency,
  Department,
  Product,
  ProductUnit,
  Service,
  Tax
} from '../../../master-data/models';
import {
  Address,
  Customer,
  CustomerAddress,
  CustomerContact
} from '../../../customers/models';
import {
  CorrectionInvoice,
  DocumentDelivery,
  DocumentDeliveryProvider,
  InvoiceItem,
  InvoiceLike,
  InvoicePayment,
  PartialInvoiceItem,
  PayableInvoice,
  PaymentProcess
} from '../../models';
import { Order } from '../../../orders/models';
import { CustomerAddressesSelectors } from '../../../customers/store/selectors';
import {
  CustomerAddressesActions,
  CustomersActions
} from '../../../customers/store';
import { InvoiceRecipient } from '../../models/invoice-recipient.interface';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { AdministratorsActions } from '../../../administrators/store';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-payable-invoice-view',
  styleUrls: ['payable-invoice-view.component.scss'],
  template: `
    <div class="pos-relative">
      <view-heading [heading]="getViewHeading(invoice)" colRight="text-right">
        <span>
          <button
            mat-icon-button
            class="text-color-grey"
            (click)="openMailDialog()"
          >
            <mat-icon>mail</mat-icon>
          </button>
        </span>
      </view-heading>

      <div class="invoice__outer" [formGroup]="form">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
        <div class="wrap invoice__wrap p-b--32 m-b--32" *ngIf="invoice">
          <div class="invoice grid">
            <div class="column-9">
              <app-invoice-preview
                [invoice$]="invoice$"
                (updateInvoice)="onUpdateInvoice($event)"
              ></app-invoice-preview>
            </div>

            <div class="column-5">
              <div class="card mat-elevation-z1">
                <div class="card__heading">
                  <span>Details</span>
                </div>

                <div class="card__content p-4">
                  <div class="grid grid-no-gutter">
                    <ng-container [formGroup]="form">
                      <mat-form-field>
                        <mat-label>Auftragssdatum</mat-label>
                        <input
                          matInput
                          type="date"
                          formControlName="orderDate"
                          [readonly]="!isEditable"
                        />
                        <mat-error>
                          <app-form-error
                            [formGroup]="form"
                            fieldName="orderDate"
                          ></app-form-error>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field *ngIf="invoice.bookedDate">
                        <mat-label>Rechnungsdatum</mat-label>
                        <input
                          matInput
                          type="date"
                          formControlName="bookedDate"
                          [readonly]="true"
                        />
                        <mat-error>
                          <app-form-error
                            [formGroup]="form"
                            fieldName="bookedAt"
                          ></app-form-error>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field *ngIf="!isPartialInvoice">
                        <mat-label>Lieferdatum</mat-label>
                        <input
                          matInput
                          type="date"
                          formControlName="deliveryDate"
                          [readonly]="!isEditable"
                        />
                        <mat-error>
                          <app-form-error
                            [formGroup]="form"
                            fieldName="deliveryDate"
                          ></app-form-error>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>Fälligkeitsdatum</mat-label>
                        <input
                          matInput
                          type="date"
                          formControlName="dueDate"
                          [readonly]="!isEditable"
                        />
                        <mat-error>
                          <app-form-error
                            [formGroup]="form"
                            fieldName="dueDate"
                          ></app-form-error>
                        </mat-error>
                      </mat-form-field>
                      <div class="column-14 text-right">
                        <button
                          class="btn--submit mb-2"
                          [disabled]="!form.touched"
                          mat-flat-button
                          color="green"
                          (click)="saveInvoiceDates()"
                        >
                          <mat-icon class="m-r--8">save</mat-icon>
                          <span>speichern</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div [class.disabled]="!!!invoice">
                <app-base-invoice-viewer
                  *ngIf="invoice && !!paymentProcess$.getValue()"
                  [invoice]="invoice"
                  [paymentProcess]="paymentProcess$.getValue()"
                  (requestViewBaseInvoice)="handleNavigateToBaseInvoice($event)"
                ></app-base-invoice-viewer>

                <partial-invoice-creator
                  *ngIf="invoice && invoice['@type'] === 'Invoice'"
                  [invoice]="invoice"
                  [paymentProcess]="paymentProcess$.getValue()"
                  (requestCreateInvoiceFromFinalInvoice)="
                    handleCreateInvoiceFromFinalInvoice($event)
                  "
                ></partial-invoice-creator>

                <invoice-facturer
                  *ngIf="invoice"
                  [invoice]="invoice"
                  (requestDownloadInvoice)="handleDownloadInvoice($event)"
                  (requestBookInvoice)="handleBookInvoice($event)"
                ></invoice-facturer>

                <app-payment-recorder
                  *ngIf="invoice"
                  [invoice]="invoice"
                  (requestCreateInvoicePayment)="
                    handleCreateInvoicePayment($event, invoice['@id'])
                  "
                  (requestBookFullPaymentReceived)="
                    handleBookFullPaymentReceived($event)
                  "
                ></app-payment-recorder>

                <app-send-document-tool
                  *ngIf="invoice"
                  [invoice]="invoice$ | async"
                  invoiceTypeName="Rechnung"
                  [documentDeliveryProviders]="
                    documentDeliveryProviders$ | async
                  "
                  (requestCreateEmailDocumentDelivery)="
                    handleCreateEmailDocumentDelivery($event, invoice['@id'])
                  "
                  (requestCreateLetterXPressDocumentDelivery)="
                    handleCreateLetterXPressDocumentDelivery(
                      $event,
                      invoice['@id']
                    )
                  "
                  (requestCreateRegisteredLetterDocumentDelivery)="
                    handleCreateRegisteredLetterDocumentDelivery(
                      $event,
                      invoice['@id']
                    )
                  "
                  (requestDownloadInvoice)="handleDownloadInvoice($event)"
                ></app-send-document-tool>

                <div class="blocker"></div>
              </div>

              <div class="blocker"></div>
            </div>
          </div>
        </div>
      </div>

      <!--<pre>{{ ic.value | json }}</pre>-->
      <!--<pre>{{ customerAddresses$ | async | json }}</pre>-->
    </div>
  `
})
export class PayableInvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentRecorderComponent) prc: PaymentRecorderComponent;
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;

  invoiceType: string = null;
  invoiceUUID: string = null;
  invoiceIri: string = null;

  invoice$: BehaviorSubject<PayableInvoice> = new BehaviorSubject<
    PayableInvoice
  >(null);
  invoice: PayableInvoice;

  administratorEntities$: Observable<{
    [iri: string]: Administrator;
  }>;
  caf: FormGroup;
  cf: FormGroup;
  currencies$: Observable<Array<Currency>>;
  customer$: BehaviorSubject<Customer> = new BehaviorSubject(null);
  customerAddress$: BehaviorSubject<
    CustomerAddress | any
  > = new BehaviorSubject(null);
  customerAddressPaginationNextLink$: BehaviorSubject<
    string
  > = new BehaviorSubject(null);
  customerAddresses$: Observable<Array<CustomerAddress>>;
  customerContacts$: Observable<Array<CustomerContact>>;
  dateFields = [
    { name: 'orderDate', label: 'Auftragsdatum' },
    {
      name: 'dueDate',
      label: 'Fälligkeitsdatum'
    },
    { name: 'deliveryDate', label: 'Lieferdatum' }
  ];
  departmentAdUri: SafeUrl | any = null;
  departmentLogoUri: SafeUrl | any = null;
  departments$: Observable<Array<Department>>;
  documentDeliveryProviders$: Observable<Array<DocumentDeliveryProvider>>;
  dsf: FormGroup;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  form: FormGroup;

  invoiceItemType$: BehaviorSubject<
    'custom' | 'product' | 'service'
  > = new BehaviorSubject(null);
  invoiceType$: BehaviorSubject<
    'Invoice' | 'PartialInvoice' | string
  > = new BehaviorSubject(null);
  invoiceTypeOptions = [
    { label: 'Freie Position', value: 'custom' },
    {
      label: 'Produkt',
      value: 'product'
    },
    { label: 'Service', value: 'service' }
  ];
  issuer$: BehaviorSubject<Department> = new BehaviorSubject(null);
  onDestroy$: Subject<any> = new Subject<any>();
  order$: BehaviorSubject<Order> = new BehaviorSubject(null);
  orders$: Observable<Array<Order>>;
  osf: FormGroup;
  paginationNextLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  paymentProcess$: BehaviorSubject<PaymentProcess> = new BehaviorSubject(null);
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  productUnits$: Observable<Array<ProductUnit>>;
  productUnitsEntities$: Observable<{
    [iri: string]: ProductUnit;
  }>;
  products$: Observable<Array<Product>>;
  searchResults$: Observable<Array<Customer>>;
  services$: Observable<Array<Service>>;
  taxes$: Observable<Array<Tax>>;
  isLoading$: Observable<boolean>;
  invoiceUpdateAction = PayableInvoicesActions.UpdatePayableInvoice;

  constructor(
    public dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fs: FormsService,
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private pis: PayableInvoicesService,
    private dls: DepartmentLogosService,
    private activatedRoute: ActivatedRoute
  ) {}

  get isEditable(): boolean {
    return this.invoice && 'editable' in this.invoice && this.invoice?.editable;
  }

  get isPartialInvoice(): boolean {
    return extractTypeByIri(this.invoice) === 'partial_invoices';
  }

  get today(): Date {
    return new Date();
  }

  getHourDifferential(futureDate: string): number {
    const duration = moment.duration(
      moment(futureDate).diff(moment(new Date()))
    );
    return Math.floor(duration.asHours());
  }

  getInvoiceHeading(invoice?: PayableInvoice): string {
    if (!!!invoice) {
      return 'Rechnung oder Abschlagsrechnung';
    }

    const typeDe =
      invoice['@type'] === 'PartialInvoice' ? 'Abschlagsrechnung' : 'Rechnung';
    const typeEn =
      invoice['@type'] === 'PartialInvoice' ? 'Partial Invoice' : 'Invoice';

    return `${typeDe} / ${typeEn} ${
      invoice?.invoiceNumber ? invoice?.invoiceNumber : ''
    }`;
  }

  getViewHeading(invoice?: PayableInvoice): string {
    if (!!!invoice) {
      return 'laden..';
    }
    return `Faktura: ${
      invoice['@type'] === 'PartialInvoice' ? 'Abschlagsrechnung' : 'Rechnung'
    } bearbeiten`;
  }

  ngOnInit(): void {
    this.initForms();

    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        console.log('params', params);
        this.invoiceType = params.type;
        this.invoiceUUID = params.uuid;
        this.invoiceIri = '/api/' + params.type + '/' + params.uuid;
        //
        this.store$.dispatch(
          PayableInvoicesActions.ReadPayableInvoice({ iri: this.invoiceIri })
        );
        this.store$
          .select(PayableInvoicesSelectors.selectPayableInvoiceByIndex, {
            iri: this.invoiceIri
          })
          .pipe(
            takeUntil(this.onDestroy$),
            filter(e => !!e)
          )
          .subscribe(e => {
            this.invoice$.next(e);
          });
        this.invoice$
          .pipe(
            takeUntil(this.onDestroy$),
            filter(invoice => !!invoice)
            // tap(invoice => console.log(invoice))
          )
          .subscribe(invoice => {
            this.upsertInvoiceData(invoice);
          });
      });
    // this.selectData();
    // this.selectErrors();
    // this.initActionListeners();
    this.actions$
      .pipe(
        ofType(
          DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
          PartialInvoiceItemsActions.UpdatePartialInvoiceItemSuccess,
          PartialInvoiceItemsActions.CreatePartialInvoiceItemSuccess,
          PartialInvoiceItemsActions.DeletePartialInvoiceItemSuccess,
          InvoiceItemsActions.CreateInvoiceItemSuccess,
          InvoiceItemsActions.UpdateInvoiceItemSuccess,
          InvoiceItemsActions.DeleteInvoiceItemSuccess,
          PayableInvoicesActions.BookPayableInvoiceSuccess,
          PayableInvoicesActions.CancelPayableInvoiceSuccess,
          TransitionsActions.MakeTransitionSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          PayableInvoicesActions.ReadPayableInvoice({ iri: this.invoiceIri })
        );
      });

    this.loadDocumentDeliveryProviders();
    this.loadTaxes();
    this.loadCurrencies();
    this.loadDepartments();
    this.loadProductUnits();
    this.loadAdministrators();

    this.isLoading$ = isLoadingArray([
      this.store$.select(DocumentDeliveryProvidersSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(CurrenciesSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(TaxesSelectors.isLoading)
    ]);
  }

  initForms(): void {
    this.form = this.fb.group({
      bookedDate: this.fb.control(null),
      orderDate: this.fb.control(null, Validators.required),
      paymentProcess: this.fb.control(null),
      dueDate: this.fb.control(null, Validators.required),
      deliveryDate: this.fb.control(
        null,
        this.isPartialInvoice ? null : Validators.required
      ),
      issuer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null, [Validators.required]),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null, Validators.required),
        taxNumber: this.fb.control(null)
      }),
      customerNumber: this.fb.control(null, Validators.required),
      customText: this.fb.control('')
    });

    this.cf = this.fb.group({
      customer: this.fb.control(null)
    });

    this.osf = this.fb.group({
      order: this.fb.control(null)
    });

    this.caf = this.fb.group({
      address: this.fb.control(null)
    });
  }

  upsertInvoiceData(invoice: PayableInvoice): void {
    console.log('upsertInvoiceData', invoice);
    this.invoice = { ...invoice };
    this.store$.dispatch(
      PaymentProcessesActions.ReadPaymentProcess({
        iri: extractIri(this.invoice.paymentProcess)
      })
    );
    this.store$
      .select(PaymentProcessesSelectors.sByIri, {
        iri: extractIri(this.invoice.paymentProcess)
      })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(i => !!i)
      )
      .subscribe(p => {
        console.log('newPP:', p.invoices);
        this.paymentProcess$.next(p);
        if (p.order) {
          this.store$.dispatch(
            OrdersActions.ReadOrder({ iri: extractIri(p.order) })
          );
          this.store$
            .select(PaymentProcessesSelectors.sByIri, {
              iri: extractIri(p.order)
            })
            .subscribe(this.order$);
        }
      });
    this.invoiceType$.next(this.invoice['@type']);

    const existingIssuer = this.issuer$.getValue();

    // Prevent re-fetching issuer every time invoice is updated
    if (!!existingIssuer && existingIssuer['@id'] !== invoice.issuer['@id']) {
      this.issuer$.next(invoice.issuer);
    }

    this.customer$.next({
      nameLine1: invoice.recipient.nameLine1,
      nameLine2: invoice.recipient.nameLine2,
      customerNumber: invoice.customerNumber
    });
    this.customerAddress$.next({
      address: invoice.recipient.address,
      addressType: invoice.recipient.addressType
    });

    this.form.get('recipient.address').patchValue(invoice.recipient.address);
    if (invoice?.recipient?.addressType) {
      this.form
        .get('recipient.addressType')
        .patchValue(invoice.recipient.addressType['@id']);
    }
    this.form
      .get('customText')
      .setValue(invoice.customText ? invoice.customText : null);

    this.form.get('deliveryDate').setValue(invoice.deliveryDate);
    if (invoice.dueDate) {
      this.form
        .get('dueDate')
        .setValue(invoice.dueDate.substr(0, 10), { onlySelf: true });
    }
    this.form
      .get('orderDate')
      .setValue(invoice.orderDate.substr(0, 10), { onlySelf: true });
    if (invoice.deliveryDate) {
      this.form
        .get('deliveryDate')
        .setValue(invoice.deliveryDate.substr(0, 10), { onlySelf: true });
    }
    this.form.get('paymentProcess').setValue(invoice.paymentProcess);
  }

  autoFillDateFields(orderDate?: string): void {
    // note: date fields can already be populated if order - not customer - has been selected

    const date = orderDate ? new Date(orderDate) : new Date();

    if (!!!this.form.get('dueDate').value) {
      this.form.get('dueDate').setValue(date.toISOString());
    }
    if (!!!this.form.get('orderDate').value) {
      this.form.get('orderDate').setValue(date.toISOString());
    }

    // Keep Ordering!
    if (!!!this.form.get('deliveryDate').value) {
      this.form
        .get('deliveryDate')
        .setValue(new Date(date.setDate(date.getDate() + 14)).toISOString());
    }
  }

  handleUpdateFormsAfterCustomerChange(customer: Customer): void {
    this.form.get('customerNumber').setValue(customer.customerNumber);
    this.form.get('recipient.taxNumber').setValue(customer.taxNumber);
    this.form.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.form.get('recipient.nameLine2').setValue(customer.nameLine2);

    this.cf.get('customer').setValue(customer['@id']);

    // todo: auto-set default address if given?
    /*if(!!customer?.defaultBillingAddress) {
      // Check for and fetch default customer address if set
      this.caf.get('address').setValue(customer.defaultBillingAddress);
    }*/
  }

  handleSelectCustomer(customer: Customer): void {
    this.order$.next(null);
    if (customer) {
      this.handleReadCustomer(customer['@id']);
    } else {
      this.handleResetCustomer();
    }
  }

  handleUpdateIssuer(selectedIssuers: Department): void {
    this.issuer$.next(selectedIssuers);
    this.invoice.issuer = selectedIssuers;
    this.upsertInvoiceData(this.invoice);
  }

  handleUpdateInvoiceRecipient(
    selectedAddresses: Array<CustomerAddress> | any
  ): void {
    const customerAddress = selectedAddresses[0];
    const { address, addressType } = customerAddress;

    this.form.get('recipient.address').patchValue(address);
    this.form.get('recipient.addressType').patchValue(addressType['@id']);

    this.customerAddress$.next(customerAddress);
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleCreateEmailDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateEmailDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  handleCreateRegisteredLetterDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  handleCreateLetterXPressDocumentDelivery(
    payload: DocumentDelivery,
    invoice: string
  ): void {
    this.store$.dispatch(
      DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery({
        payload,
        invoiceIri: invoice
      })
    );
  }

  handleDownloadInvoice(invoice: string): void {
    this.store$.dispatch(
      PayableInvoicesActions.ReadPayableInvoicePDF({ iri: invoice })
    );

    // this.pis
    //   .readPayableInvoiceAsPdf(invoice)
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     filter(response => !!response),
    //     catchError(error => throwError(error))
    //     // tap(v => console.log(v))
    //   )
    //   .subscribe(
    //     ({body: blob}) => {
    //       window.open(window.URL.createObjectURL(blob));
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );
  }

  handleFetchDepartmentAd(iri: string): void {
    this.departmentAdUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          ({ body: blob }) => {
            this.departmentAdUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleFetchDepartmentLogo(iri: string | null): void {
    this.departmentLogoUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(
          // tap(response => console.log(response)),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          ({ body: blob }) => {
            this.departmentLogoUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleReadCustomer(iri: string): void {
    this.store$.dispatch(CustomersActions.ReadCustomer({ iri }));
  }

  handleReadCustomerAddresses(customerIri: string): void {
    const params = {
      'customer.uuid': StringsUtility.getUuidFromIri(customerIri)
    };
    this.store$.dispatch(
      CustomerAddressesActions.ReadCustomerBillingAddresses({ customerIri })
    );
    this.customerAddresses$ = this.store$.pipe(
      select(
        CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
        { customerIri }
      )
    );
  }

  handleReadCustomerContacts(customerIri: string): void {
    // this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({ page: 1, params }));
    // this.customerContacts$ = this.store$.pipe(select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, { customerIri }));
  }

  handleReadOrder(iri: string): void {
    this.store$.dispatch(OrdersActions.ReadOrder({ iri }));
    this.store$
      .pipe(select(OrdersSelectors.sDetailedByIri, { iri }))
      .subscribe(this.order$);
  }

  handleCancelInvoice(payload: { iri: string }): void {}

  handleBookInvoice(invoice: string): void {
    this.store$.dispatch(
      PayableInvoicesActions.BookPayableInvoice({
        iri: invoice
      })
    );
  }

  handleBookFullPaymentReceived(invoice: string): void {
    this.store$.dispatch(
      PayableInvoicesActions.BookFullPaymentReceived({
        iri: invoice
      })
    );
  }

  handleShowDepartmentSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  handleShowCustomerAddressSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  handleSelectOrder(order: Order): void {
    if (order) {
      this.handleReadOrder(order['@id']);
    } else {
      this.handleResetOrder();
    }
  }

  handleResetCustomer(): void {
    this.customer$.next(null);
    this.customerAddress$.next(null);
    // todo customerContact
    this.handleResetFormValuesAfterCustomerReset();
  }

  handleResetOrder(): void {
    this.handleResetCustomer();
    this.handleResetFormValuesAfterOrderReset();
  }

  handleResetFormValuesAfterOrderReset(): void {
    this.order$.next(null);
  }

  handleResetFormValuesAfterCustomerReset(): void {
    this.form.get('customerNumber').setValue(null);
    this.form.get('paymentProcess').setValue(null);

    this.form.get('recipient.nameLine1').setValue('');
    this.form.get('recipient.nameLine2').setValue('');
    this.form.get('recipient.taxNumber').setValue(null);

    this.form.get('recipient.address.line1').setValue('');
    this.form.get('recipient.address.line2').setValue('');
    this.form.get('recipient.address.line3').setValue('');
    this.form.get('recipient.address.line4').setValue('');
    this.form.get('recipient.address.city').setValue(null);
    this.form.get('recipient.address.zipPostcode').setValue(null);
    this.form.get('recipient.address.country').setValue(null);
    this.form.get('recipient.taxNumber').setValue(null);

    this.cf.get('customer').setValue(null);
    this.caf.get('address').setValue(null);

    this.form.get('orderDate').setValue(null);
    this.form.get('deliveryDate').setValue(null);
    this.form.get('dueDate').setValue(null);
  }

  handleResetSearchResults(): void {
    // also reset current searchResults and loaded state of customers;
    // so customers are fetched anew when navigating from this component to customers-view component
    this.searchResults$.pipe(switchMap(items => of([])));
    this.store$.dispatch(CustomersActions.ResetIsLoaded());
  }

  handleReadCustomers(payload: {
    page: number;
    params: { [p: string]: number | boolean | string };
  }): void {
    this.store$.dispatch(CustomersActions.ReadCustomers(payload));
  }

  handleCreateInvoice(): void {
    const order = this.order$.getValue();
    if (order) {
      this.form.get('paymentProcess').setValue(order.paymentProcess);
      this.store$.dispatch(
        PayableInvoicesActions.CreatePayableInvoice({
          payload: this.form.value
        })
      );
    } else {
      // Create New Payment Process First
      this.store$.dispatch(
        PaymentProcessesActions.CreatePaymentProcess({
          payload: { customer: this.customer$.getValue()['@id'] }
        })
      );
    }
  }

  handleCreateInvoiceFromFinalInvoice(payload: {
    finalInvoice: string;
    percentageOfFinalInvoice: string;
  }): void {
    this.store$.dispatch(
      PayableInvoicesActions.CreatePayableInvoiceFromInvoice({ payload })
    );
  }

  handleUpdateInvoice(_payload?: InvoiceLike): void {
    const payload = {
      iri: extractIri(this.invoice),
      payload: _payload ? _payload : this.form.value
    };
    if (payload.payload.issuer) {
      payload.payload.issuer = extractIri(payload.payload.issuer);
    }
    this.store$.dispatch(PayableInvoicesActions.UpdatePayableInvoice(payload));
  }

  handleCopyInvoice(): void {}

  handleCreateCorrectionInvoice(payload: CorrectionInvoice): void {}

  handleDeleteInvoice(invoice: string): void {}

  getInvoiceGrossSum(invoice?: PayableInvoice): string {
    if (invoice) {
      return `${formatNumber(
        parseFloat(invoice.grossTotal.value),
        this.locale,
        '1.2-2'
      )} ${invoice.grossTotal.currency}`;
    } else {
      return '-';
    }
  }

  getCustomerTaxNumber(): string {
    if (!!this.customer$.getValue() && this.customer$.getValue()?.taxNumber) {
      return this.customer$.getValue().taxNumber;
    } else {
      return '-';
    }
  }

  handleCreateInvoicePayment(payload: InvoicePayment, invoice: string): void {
    this.store$.dispatch(
      InvoicePaymentsActions.CreateInvoicePayment({
        payload,
        invoiceIri: invoice
      })
    );
    this.actions$
      .pipe(ofType(InvoicePaymentsActions.CreateInvoicePaymentSuccess), take(1))
      .subscribe(() => {
        this.store$.dispatch(
          PayableInvoicesActions.ReadPayableInvoice({ iri: this.invoiceIri })
        );
      });
  }

  handlePrepareCreateInvoiceItem(
    payload: InvoiceItem | PartialInvoiceItem,
    invoiceIri: string
  ): void {
    const invoiceType = this.invoiceType$.getValue();

    if (invoiceType === 'Invoice') {
      this.handleCreateInvoiceItem(payload, invoiceIri);
    }

    if (invoiceType === 'PartialInvoice') {
      this.handleCreatePartialInvoiceItem(payload, invoiceIri);
    }
  }

  handlePrepareUpdateInvoiceItem(
    _payload: {
      iri: string;
      payload: InvoiceItem | PartialInvoiceItem;
    },
    invoiceIri: string
  ): void {
    const invoiceType = this.invoiceType$.getValue();
    const { iri, payload } = _payload;

    if (invoiceType === 'Invoice') {
      this.handleUpdateInvoiceItem(iri, payload, invoiceIri);
    }

    if (invoiceType === 'PartialInvoice') {
      this.handleUpdatePartialInvoiceItem(iri, payload, invoiceIri);
    }
  }

  handleCreateInvoiceItem(payload: InvoiceItem, invoiceIri: string): void {
    this.store$.dispatch(
      InvoiceItemsActions.CreateInvoiceItem({
        payload,
        invoiceIri
      })
    );
  }

  handleCreatePartialInvoiceItem(
    payload: PartialInvoiceItem,
    invoiceIri: string
  ): void {
    this.store$.dispatch(
      PartialInvoiceItemsActions.CreatePartialInvoiceItem({
        payload,
        invoiceIri
      })
    );
  }

  handleUpdateInvoiceItem(
    iri: string,
    payload: InvoiceItem,
    invoiceIri: string
  ): void {
    this.store$.dispatch(
      InvoiceItemsActions.UpdateInvoiceItem({
        iri,
        payload,
        invoiceIri
      })
    );
  }

  handleUpdatePartialInvoiceItem(
    iri: string,
    payload: PartialInvoiceItem,
    invoiceIri: string
  ): void {
    this.store$.dispatch(
      PartialInvoiceItemsActions.UpdatePartialInvoiceItem({
        iri,
        payload,
        invoiceIri
      })
    );
  }

  clearDate(formControlName: string): void {
    this.form.get(formControlName).setValue(null);
  }

  getInvoiceItemModalHeading(): string {
    const headings = {
      custom: 'Freie Position zur Rechnung hinzufügen',
      service: 'Service zur Rechnung hinzufügen',
      product: 'Produkt zur Rechnung hinzufügen'
    };

    if (!!this.invoiceItemType$.getValue()) {
      return headings[this.invoiceItemType$.getValue()];
    }

    return 'Position zur Rechnung hinzufügen';
  }

  handleReadServices(): void {
    this.store$.dispatch(ServicesActions.ReadServices());
  }

  updateRecipient(recipient: InvoiceRecipient): void {
    this.form.patchValue({ recipient });
  }

  handleReadProducts(): void {
    this.store$.dispatch(ProductsActions.ReadProducts());
  }

  handleShowInvoiceItemForm(
    ref: TemplateRef<any>,
    presets?: InvoiceItem
  ): void {
    // Prepare Edit
    if (presets) {
      this.presets$.next(presets);
      // note: fallback to custom since additional product/service selector is not necessary
      this.invoiceItemType$.next('custom');
    }
    this.openDialog(ref, { disableClose: true });
  }

  handleDeleteInvoiceItem(iri: string, invoiceIri: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Position wirklich löschen?',
          heading: 'Position zur Rechnung löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          InvoiceItemsActions.DeleteInvoiceItem({
            iri,
            invoiceIri
          })
        );
      });
  }

  openDialog(
    ref: TemplateRef<any>,
    config: ModalDialogOptions | any = {}
  ): void {
    this.dialog.open(ref, config);
  }

  handleNavigateToBaseInvoice(invoice: string): void {
    this.store$.dispatch(
      RouterActions.Go({
        path: [
          'invoices',
          'payable',
          extractTypeByIri(invoice),
          extractUUID(invoice)
        ]
      })
    );
    this.store$.dispatch(
      PayableInvoicesActions.ReadPayableInvoice({
        iri: invoice
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  openMailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'payable-invoice', entity$: this.invoice$ }
    });
  }

  onUpdateInvoice($event: any): void {
    this.handleUpdateInvoice($event);
  }

  public saveInvoiceDates(): void {
    this.handleUpdateInvoice({
      '@id': extractIri(this.invoice),
      dueDate: this.form.get('dueDate').value,
      orderDate: this.form.get('orderDate').value
    });
  }

  private loadDocumentDeliveryProviders(): void {
    this.documentDeliveryProviders$ = this.store$.select(
      DocumentDeliveryProvidersSelectors.selectDocumentDeliveryProviders
    );
    loadIfNotLoaded(
      this.store$,
      DocumentDeliveryProvidersSelectors.isLoaded,
      DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviders()
    );
  }

  private loadDepartments(): void {
    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }

  private loadCurrencies(): void {
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    loadIfNotLoaded(
      this.store$,
      CurrenciesSelectors.isLoaded,
      CurrenciesActions.ReadCurrencies()
    );
  }

  private loadProductUnits(): void {
    this.productUnits$ = this.store$.select(
      ProductUnitsSelectors.selectProductUnits
    );
    loadIfNotLoaded(
      this.store$,
      ProductUnitsSelectors.isLoaded,
      ProductUnitsActions.ReadProductUnits()
    );
  }

  private loadTaxes(): void {
    this.taxes$ = this.store$.select(TaxesSelectors.selectTaxes);
    loadIfNotLoaded(
      this.store$,
      TaxesSelectors.isLoaded,
      TaxesActions.ReadTaxes()
    );
  }

  private loadAdministrators(): void {
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }
}
