import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RolesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { RolesService } from '../../services/roles.service';

@Injectable()
export class RolesEffects {
  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.ReadRoles),
      switchMap(() => {
        return this.service.readRoles().pipe(
          map(response => RolesActions.ReadRolesSuccess({ response })),
          catchError(response => of(RolesActions.ReadRolesFail({ response })))
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesActions.ReadRolesFail),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: RolesService,
    private notifierService: NotifierService
  ) {}
}
