import { createReducer, on } from '@ngrx/store';

import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { PasswordsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  errors: ErrorsObject;
}

export const baseState: State = {
  loading: false,
  loaded: false,
  errors: {}
};

export const reducer = createReducer(
  baseState,
  on(PasswordsActions.UpdatePassword, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(PasswordsActions.UpdatePasswordSuccess, state => {
    return {
      ...state,
      loading: false
    };
  }),
  on(PasswordsActions.UpdatePasswordFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
