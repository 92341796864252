import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromAdministrators from '../reducers/administrators.reducers';
import * as fromAdministratorGroups from '../reducers/administrator-groups.reducers';
import * as fromAdministratorFeedbackRequests from './feedback-requests.reducers';

export const ADMINISTRATORS_MODULE_FEATURE_KEY = 'administratorsModule';
export const ADMINISTRATORS_FEATURE_KEY = 'administrators';
export const ADMINISTRATOR_GROUPS_FEATURE_KEY = 'administratorGroups';
export const ADMINISTRATOR_FEEDBACK_REQUESTS_FEATURE_KEY = 'administratorFeedbackRequests';

export interface AdministratorsModuleState {
  [ADMINISTRATORS_FEATURE_KEY]: fromAdministrators.State;
  [ADMINISTRATOR_GROUPS_FEATURE_KEY]: fromAdministratorGroups.State;
  [ADMINISTRATOR_FEEDBACK_REQUESTS_FEATURE_KEY]: fromAdministratorFeedbackRequests.State;
}

export const selectAdministratorsModuleState = createFeatureSelector<AdministratorsModuleState>(ADMINISTRATORS_MODULE_FEATURE_KEY);

export function reducers(state: AdministratorsModuleState, action: Action) {

  return combineReducers({
    [ADMINISTRATORS_FEATURE_KEY]: fromAdministrators.reducer,
    [ADMINISTRATOR_GROUPS_FEATURE_KEY]: fromAdministratorGroups.reducer,
    [ADMINISTRATOR_FEEDBACK_REQUESTS_FEATURE_KEY]: fromAdministratorFeedbackRequests.reducer
  })(state, action);
}
