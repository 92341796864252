import { createSelector } from '@ngrx/store';

import { ORDERS_FEATURE_KEY, selectOrdersModuleState } from '../reducers';
import { intersection } from 'lodash-es';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';

const NAMESPACE = ORDERS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectOrdersModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, s => s[NAMESPACE]);
export const isLoading = createSelector(sState, s => s.loading);
export const isLoaded = createSelector(sState, s => s.loaded);

export const sEntities = createSelector(sState, state => state.entities);
export const sDetailEntities = createSelector(
  sState,
  state => state.detailedEntities
);

export const sList = createSelector(sEntities, entities =>
  Object.values(entities).sort(sortByCreatedAtDate)
);
export const sDetailedList = createSelector(sDetailEntities, entities =>
  Object.values(entities)
);
export const sByIri = createSelector(
  sEntities,
  (orders, props) => orders[props.iri]
);
export const sDetailedByIri = createSelector(
  sDetailEntities,
  (orders, props) => orders[props.iri]
);
export const sById = createSelector(sDetailedList, (orders, { id }) =>
  orders.find(e => e['@id'].indexOf(id) > -1)
);
export const sByCustomerIri = createSelector(sList, (orders, { customerIri }) =>
  orders.filter(order => order?.customer['@id'] === customerIri)
);

export const analysisDoneOrders = createSelector(sList, orders =>
  orders.filter(e => e.rawState.indexOf('customer_analysis_created') > -1)
);
export const sByDataRecoveryCostsSupervisorCheckRequired = createSelector(
  sList,
  orders =>
    orders.filter(e => e.sByDataRecoveryCostsSupervisorCheckRequired === true)
);
export const openDataRecoveryOrders = createSelector(sList, orders =>
  orders.filter(
    e => e.rawState.indexOf('provide_data_recovery_result_dr_de') > -1
  )
);

export const sByStates = (states, { salesperson } = { salesperson: null }) =>
  createSelector(sList, orders =>
    orders.filter(
      e =>
        intersection(e.rawState, states).length > 0 &&
        (!salesperson || e.salesperson["@id"] === salesperson)
    )
  );
export const sByStateRange = (
  stateRange,
  { salesperson } = { salesperson: null }
) =>
  createSelector(sList, orders =>
    orders.filter(
      e =>
        e.rawStateRange === stateRange &&
        (!salesperson || e.salesperson["@id"] === salesperson)
    )
  );

export const selectErrors = createSelector(sState, state => state.errors);
