import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CorrectionInvoiceItemsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.CorrectionInvoiceItem };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItem,
    CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItem,
    CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItem,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItemSuccess,
    CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItemSuccess,
    // CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItemSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removedItem, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItemFail,
    CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItemFail,
    CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItemFail,
    CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItemFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
