import { createReducer, on } from '@ngrx/store';

import * as fromWarehouseModuleModels from '../../models';
import {
  DataMediumsActions,
  EnclosureDataMediumsActions,
  ReplacementDataMediumsActions
} from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromWarehouseModuleModels.DataMedium };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(DataMediumsActions.ReadDataMediums, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(
    DataMediumsActions.ReadDataMediumsSuccess,
    DataMediumsActions.ReadDataMediumsChunkSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const totalItems = response['hydra:totalItems'];
      let pagination = {};
      const items = response['hydra:member'];
      const entities = mergeEntities(items, {}, state.entities);

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        errors: {},
        pagination,
        totalItems,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    EnclosureDataMediumsActions.DeleteEnclosureDataMediumSuccess,
    ReplacementDataMediumsActions.DeleteReplacementDataMediumSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(DataMediumsActions.ReadDataMediumsFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false
    };
  }),
  // read DataMedia from CustomerStocKItem request
  on(DataMediumsActions.ReadDataMediumSuccess, (state, { response }) => {
    console.log(response);
    const newState = {
      ...state,
      entities: { ...state.entities, [extractIri(response)]: { ...response } }
    };
    return newState;
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
