import { A11yModule } from '@angular/cdk/a11y';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContentLoaderModule } from '@ngneat/content-loader';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { DynamicIoModule } from 'ng-dynamic-component';
import {
  NgArrayPipesModule,
  NgPipesModule,
  NgStringPipesModule
} from 'ngx-pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import * as fromModuleContainers from './containers';
import * as fromModuleDirectives from './directives';
import * as fromModuleServices from './services';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { PaginatorUnstyledComponent } from './components/paginator-unstyled/paginator-unstyled.component';
import { PaginatorTableComponent } from './components/paginator-table/paginator-table.component';
import { MomentDurationPipe } from './pipes/moment-duration.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { OptionalSlicePipe } from './pipes/optional-slice.pipe';
import { MomentDateWithTime } from './pipes/moment-date-with-time.pipe';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { NgxMatIntlTelInputModule } from 'ngx-11-mat-intl-tel-input';
import { MomentDate } from './pipes/moment-date.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MyI18nSelectPipe } from './pipes/my-i18n-select.pipe';
import { FloatPipe } from './pipes/float.pipe';
import { ActionBoxGoToWarehouseComponent } from './action-box-components/action-box-go-to-warehouse/action-box-go-to-warehouse.component';
import { ActionBoxToIncomingDmDelayedComponent } from './action-box-components/action-box-to-incoming-dm-delayed/action-box-to-incoming-dm-delayed.component';
import { DaysSincePipe } from './pipes/days-since.pipe';
import { ActionBoxToDmInEngineeringDepartmentComponent } from './action-box-components/action-box-to-dm-in-engineering-department/action-box-to-dm-in-engineering-department.component';
import { ActionBoxToDmOkEngineeringDepartmentComponent } from './action-box-components/action-box-to-dm-ok-engineering-department/action-box-to-dm-ok-engineering-department.component';
import { ActionBoxToAssignAnalysisComponent } from './action-box-components/action-box-to-assign-analysis/action-box-to-assign-analysis.component';
import { ActionBoxAssignAnalysisLocationComponent } from './action-box-components/action-box-assign-analysis-location/action-box-assign-analysis-location.component';
import { ActionBoxToAnalysisInProgressComponent } from './action-box-components/action-box-to-analysis-in-progress/action-box-to-analysis-in-progress.component';
import { ActionBoxToAnalysisCompletedComponent } from './action-box-components/action-box-to-analysis-completed/action-box-to-analysis-completed.component';
import { ActionBoxToAnalysisSellingPricesComponent } from './action-box-components/action-box-to-analysis-selling-prices/action-box-to-analysis-selling-prices.component';
import { ActionBoxToAnalysisOkComponent } from './action-box-components/action-box-to-analysis-ok/action-box-to-analysis-ok.component';
import { ActionBoxToAnalysisPurchasePricesComponent } from './action-box-components/action-box-to-analysis-purchase-prices/action-box-to-analysis-purchase-prices.component';
import { ActionBoxToCustomerAnalysisCreatedComponent } from './action-box-components/action-box-to-customer-analysis-created/action-box-to-customer-analysis-created.component';
import { ActionBoxToPrepareDrComponent } from './action-box-components/action-box-to-prepare-dr/action-box-to-prepare-dr.component';
import { ActionBoxToBoSentComponent } from './action-box-components/action-box-to-bo-send/action-box-to-bo-sent.component';
import { ActionBoxToBoReceivedViaEmailComponent } from './action-box-components/action-box-to-bo-received-via-email/action-box-to-bo-received-via-email.component';
import { ActionBoxToAccessDataProvidedComponent } from './action-box-components/action-box-to-access-data-provided/action-box-to-access-data-provided.component';
import { ActionBoxToDataRecoveryInProgressComponent } from './action-box-components/action-box-to-data-recovery-in-progress/action-box-to-data-recovery-in-progress.component';
import { ActionBoxToDataRecoveryCompletedComponent } from './action-box-components/action-box-to-data-recovery-completed/action-box-to-data-recovery-completed.component';
import { ActionBoxToDataRecoveryProvidedComponent } from './action-box-components/action-box-to-data-recovery-provided/action-box-to-data-recovery-provided.component';
import { ActionBoxToDataRecoverySuccessfulComponent } from './action-box-components/action-box-to-data-recovery-successful/action-box-to-data-recovery-successful.component';
import { ActionBoxToRemoteAppointmentNecessaryComponent } from './action-box-components/action-box-to-remote-appointment-necessary/action-box-to-remote-appointment-necessary.component';
import { ActionBoxToRemoteAppointmentDataOkComponent } from './action-box-components/action-box-to-remote-appointment-data-ok/action-box-to-remote-appointment-data-ok.component';
import { ActionBoxToProvideDisposalTypeComponent } from './action-box-components/action-box-to-provide-disposal-type/action-box-to-provide-disposal-type.component';
import { ActionBoxToDisposalTypeProvidedComponent } from './action-box-components/action-box-to-disposal-type-provided/action-box-to-disposal-type-provided.component';
import { ActionBoxToDisposalDestroyDmComponent } from './action-box-components/action-box-to-disposal-destroy-dm/action-box-to-disposal-destroy-dm.component';
import { ActionBoxToDisposalDmDestroyedComponent } from './action-box-components/action-box-to-disposal-dm-destroyed/action-box-to-disposal-dm-destroyed.component';
import { ActionBoxToDisposalProofOfDestructionProvidedComponent } from './action-box-components/action-box-to-disposal-proof-of-destruction-provided/action-box-to-disposal-proof-of-destruction-provided.component';
import { ActionBoxToDisposalDataRecoveryProtocolProvidedComponent } from './action-box-components/action-box-to-disposal-data-recovery-protocol-provided/action-box-to-disposal-data-recovery-protocol-provided.component';
import { ActionBoxToCopyDataComponent } from './action-box-components/action-box-to-copy-data/action-box-to-copy-data.component';
import { ActionBoxReplacementDmNotAvailableToCopyDataComponent } from './action-box-components/action-box-replacement-dm-not-available-to-copy-data/action-box-replacement-dm-not-available-to-copy-data.component';
import { ActionBoxCopyDataToDataCopiedComponent } from './action-box-components/action-box-copy-data-to-data-copied/action-box-copy-data-to-data-copied.component';
import { ActionBoxDataCopiedToDataCheckinSuccessfulComponent } from './action-box-components/action-box-data-copied-to-data-checkin-successful/action-box-data-copied-to-data-checkin-successful.component';
import { ActionBoxDataCheckinSuccessfulToReplacementDmReadyForReturnShipmentComponent } from './action-box-components/action-box-data-checkin-successful-to-replacement-dm-ready-for-return-shipment/action-box-data-checkin-successful-to-replacement-dm-ready-for-return-shipment.component';
import { ActionBoxCreateFinalInvoiceToWaitForPaymentComponent } from './action-box-components/action-box-create-final-invoice-to-wait-for-payment/action-box-create-final-invoice-to-wait-for-payment.component';
import { ActionBoxWaitForPaymentComponent } from './action-box-components/action-box-wait-for-payment/action-box-wait-for-payment.component';
import { ActionBoxToOrderClosedComponent } from './action-box-components/action-box-to-order-closed/action-box-to-order-closed.component';
import { ActionBoxToReturnShipmentComponent } from './action-box-components/action-box-to-return-shipment/action-box-to-return-shipment.component';
import { ActionBoxAnalysisNokToAssignAnalysisComponent } from './action-box-components/action-box-analysis-nok-to-assign-analysis/action-box-analysis-nok-to-assign-analysis.component';
import { ActionBoxToDmOkLogisticComponent } from './action-box-components/action-box-to-dm-ok-logistic/action-box-to-dm-ok-logistic.component';
import { ActionBoxToInvoiceCreateFinalInvoiceComponent } from './action-box-components/action-box-to-invoice-create-final-invoice/action-box-to-invoice-create-final-invoice.component';
import { ActionBoxReturnShipmentGeneralComponent } from './action-box-components/action-box-return-shipment-general/action-box-return-shipment-general.component';
import { ActionBoxGotoAutocreatedPartialInvoiceComponent } from './action-box-components/action-box-goto-autocreated-partial-invoice/action-box-goto-autocreated-partial-invoice.component';
import { ActionBoxShipmentToDrExtComponent } from './action-box-components/action-box-shipment-to-dr-ext/action-box-shipment-to-dr-ext.component';
import { ReplyCommentModalComponent } from './components/reply-comment-modal/reply-comment-modal.component';
import { ActionBoxToAnalysisDmReceivedDrExtComponent } from './action-box-components/action-box-to-analysis-dm-received-dr-ext/action-box-to-analysis-dm-received-dr-ext.component';
import { WriteEMailDialogComponent } from './components/write-email-dialog/write-email-dialog.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { DepartmentSelectComponent } from './components/department-select/department-select.component';
import { MailAttachmentListComponent } from './components/mail-attachment-list/mail-attachment-list.component';
import { OrderSelectComponent } from './components/order-select/order-select.component';
import { OrderStockItemSelectListComponent } from './components/order-stock-item-select-list/order-stock-item-select-list.component';
import { PricePipe } from './pipes/price.pipe';
import { ShipmentDirectionSelectComponent } from './components/shipment-direction-select/shipment-direction-select.component';
import { ShippingProviderSelectComponent } from './components/shipping-provider-select/shipping-provider-select.component';
import { ShippingServiceSelectComponent } from './components/shipping-service-select/shipping-service-select.component';
import { ShipmentFormComponent } from './components/shipment-form/shipment-form.component';
import { AnalysisResultBoxComponent } from './components/analysis-result-box/analysis-result-box.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { ContactPersonDetailsComponent } from './components/contact-person-details/contact-person-details.component';
import { DataRecoveryProtocolBoxComponent } from './components/data-recovery-protocol-box/data-recovery-protocol-box.component';
import { DataRecoveryResultBoxComponent } from './components/data-recovery-result-box/data-recovery-result-box.component';
import { DecisionBoxComponent } from './components/decision-box/decision-box.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { DisposalTypeSelectionBoxComponent } from './components/disposal-type-selection-box/disposal-type-selection-box.component';
import { DoSomethingBoxComponent } from './components/do-something-box/do-something-box.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormDateInputComponent } from './components/form-date-input/form-date-input.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { GrossPriceOutputComponent } from './components/gross-price-output/gross-price-output.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { LabLocationSelectionBoxComponent } from './components/lab-location-selection-box/lab-location-selection-box.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PriceBoxComponent } from './components/price-box/price-box.component';
import { RemoteAccessBoxComponent } from './components/remote-access-box/remote-access-box.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ShippingAddressComponent } from './components/shipping-address/shipping-address.component';
import { TicketAttachmentsComponent } from './components/ticket-attachments/ticket-attachments.component';
import { ViewHeadingComponent } from './components/view-heading/view-heading.component';
import { VoidBoxComponent } from './components/void-box/void-box.component';
import { StorageSystemManufacturerSelectComponent } from './components/hardware-producer-select/storage-system-manufacturer-select.component';
import { DataMediaLineComponent } from './components/data-media-line/data-media-line.component';
import { StockItemDisplayLineComponent } from './components/stock-item-display-line/stock-item-display-line.component';
import { ShowShipmentDialogComponent } from './components/show-shipment-dialog/show-shipment-dialog.component';
import { CreateShipmentDialogComponent } from './components/create-shipment-dialog/create-shipment-dialog.component';
import { StorageSystemTypeSelectComponent } from './components/storage-system-type-select/storage-system-type-select.component';
import { InputSizeInGbComponent } from './components/input-size-in-Gb/input-size-in-gb.component';
import { TrackerInputComponent } from './components/tracker-input/tracker-input.component';
import { ShipmentDetailDisplayLineComponent } from './components/shipment-detail-display-line/shipment-detail-display-line.component';
import { ShipmentAddressBlockComponent } from './components/shipment-address-block/shipment-address-block.component';
import { ShipmentDisplayLineComponent } from './components/shipment-display-line/shipment-display-line.component';
import { ShipmentOrderDisplayLineComponent } from './components/shipment-order-display-line/shipment-order-display-line.component';
import { StockItemOrderDisplayLineComponent } from './components/stock-item-order-display-line/stock-item-order-display-line.component';
import { ActionBoxToOrderClosedOrReturnShipmentComponent } from './action-box-components/action-box-to-order-closed-or-return-shipment/action-box-to-order-closed-or-return-shipment.component';
import { WarehouseRights } from './security/warehouseRights';
import { ActionBoxShipmentReceivedDrExtComponent } from './action-box-components/action-box-shipment-received-dr-ext/action-box-shipment-received-dr-ext.component';
import { ActionBoxShipmentOnTheWayToDrDeComponent } from './action-box-components/action-box-shipment-on-the-way-to-dr-de/action-box-shipment-on-the-way-to-dr-de.component';
import { ActionBoxOpenShipmentInfoComponent } from './action-box-components/action-box-open-shipment-info/action-box-open-shipment-info.component';
import { CurrencyShortenerPipe } from './pipes/currency-shortener.pipe';
import { JoinWithPipe } from './pipes/join-with.pipe';
import { TicketCostsSummaryComponent } from './components/ticket-costs-summary/ticket-costs-summary.component';
import { ActionBoxToDataRecoveryPurchasePricesProvidesDrDeComponent } from './action-box-components/action-box-to-data-recovery-purchase-prices-provides-dr-de/action-box-to-data-recovery-purchase-prices-provides-dr-de.component';
import { ActionBoxDataRecoveryCostsCheckedBySupervisorComponent } from './action-box-components/action-box-data-recovery-costs-checked-by-supervisor/action-box-data-recovery-costs-checked-by-supervisor.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionBoxFromCheckReturnShipmentNecessaryComponent } from './action-box-components/action-box-from-check-return-shipment-necessary/action-box-from-check-return-shipment-necessary.component';
import { ActionBoxFinalInvoiceNeededComponent } from './action-box-components/action-box-final-invoice-needed/action-box-final-invoice-needed.component';
import { ActionBoxToDataRecoveryPurchasePriceCheckedComponent } from './action-box-components/action-box-to-data-recovery-purchase-price-checked/action-box-to-data-recovery-purchase-price-checked.component';
import { FeedbackRequestListComponent } from './components/feedback-request-list/feedback-request-list.component';
import {LeadShipmentMailComponent} from "./components/lead-shipment-mail/lead-shipment-mail.component";

export const currencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: ' €',
  thousands: '.',
  nullable: false,
  inputMode: CurrencyMaskInputMode.NATURAL
};

// hmm ... parse input does not
export const LOCAL_FORMAT = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LLLL',
    monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({
  declarations: [
    AnalysisResultBoxComponent,
    ConfirmationBoxComponent,
    ContactPersonDetailsComponent,
    DataRecoveryProtocolBoxComponent,
    DataRecoveryResultBoxComponent,
    DecisionBoxComponent,
    DialogComponent,
    DialogHeaderComponent,
    DisposalTypeSelectionBoxComponent,
    DoSomethingBoxComponent,
    FileInputComponent,
    FormButtonComponent,
    FormDateInputComponent,
    FormInputComponent,
    FormSelectComponent,
    GrossPriceOutputComponent,
    ImageInputComponent,
    LabLocationSelectionBoxComponent,
    PaginatorComponent,
    PriceBoxComponent,
    RemoteAccessBoxComponent,
    SearchInputComponent,
    ShippingAddressComponent,
    TicketAttachmentsComponent,
    ViewHeadingComponent,
    VoidBoxComponent,
    StorageSystemTypeSelectComponent,
    StorageSystemManufacturerSelectComponent,
    InputSizeInGbComponent,
    OrderStockItemSelectListComponent,
    DataMediaLineComponent,
    StockItemDisplayLineComponent,
    TrackerInputComponent,
    ShowShipmentDialogComponent,
    CreateShipmentDialogComponent,
    ShipmentDetailDisplayLineComponent,
    fromModuleContainers.AllContainers,
    fromModuleDirectives.AllDirectives,
    // LoadingDirective,
    SafeHtmlPipe,
    CurrencyShortenerPipe,
    PricePipe,
    LoadingOverlayComponent,
    PaginatorUnstyledComponent,
    PaginatorTableComponent,
    MomentDurationPipe,
    MomentDateWithTime,
    MomentDate,
    ContainsPipe,
    OptionalSlicePipe,
    FormErrorComponent,
    FileSizePipe,
    MyI18nSelectPipe,
    FloatPipe,
    ActionBoxGoToWarehouseComponent,
    ActionBoxToIncomingDmDelayedComponent,
    DaysSincePipe,
    ActionBoxToDmInEngineeringDepartmentComponent,
    ActionBoxToDmOkEngineeringDepartmentComponent,
    ActionBoxToAssignAnalysisComponent,
    ActionBoxAssignAnalysisLocationComponent,
    ActionBoxToAnalysisInProgressComponent,
    ActionBoxToAnalysisCompletedComponent,
    ActionBoxToAnalysisSellingPricesComponent,
    ActionBoxToAnalysisOkComponent,
    ActionBoxToAnalysisPurchasePricesComponent,
    ActionBoxToCustomerAnalysisCreatedComponent,
    ActionBoxToPrepareDrComponent,
    ActionBoxToBoSentComponent,
    ActionBoxToBoReceivedViaEmailComponent,
    ActionBoxToAccessDataProvidedComponent,
    ActionBoxToDataRecoveryInProgressComponent,
    ActionBoxToDataRecoveryCompletedComponent,
    ActionBoxToDataRecoveryProvidedComponent,
    ActionBoxToDataRecoverySuccessfulComponent,
    ActionBoxToRemoteAppointmentNecessaryComponent,
    ActionBoxToRemoteAppointmentDataOkComponent,
    ActionBoxToProvideDisposalTypeComponent,
    ActionBoxToDisposalTypeProvidedComponent,
    ActionBoxToDisposalDestroyDmComponent,
    ActionBoxToDisposalDmDestroyedComponent,
    ActionBoxToDisposalProofOfDestructionProvidedComponent,
    ActionBoxToDisposalDataRecoveryProtocolProvidedComponent,
    ActionBoxToCopyDataComponent,
    ActionBoxReplacementDmNotAvailableToCopyDataComponent,
    ActionBoxCopyDataToDataCopiedComponent,
    ActionBoxDataCopiedToDataCheckinSuccessfulComponent,
    ActionBoxDataCheckinSuccessfulToReplacementDmReadyForReturnShipmentComponent,
    ActionBoxCreateFinalInvoiceToWaitForPaymentComponent,
    ActionBoxWaitForPaymentComponent,
    ActionBoxToOrderClosedComponent,
    ActionBoxToReturnShipmentComponent,
    ActionBoxAnalysisNokToAssignAnalysisComponent,
    ActionBoxToDmOkLogisticComponent,
    ActionBoxToInvoiceCreateFinalInvoiceComponent,
    ActionBoxReturnShipmentGeneralComponent,
    ActionBoxGotoAutocreatedPartialInvoiceComponent,
    ActionBoxShipmentToDrExtComponent,
    ReplyCommentModalComponent,
    ActionBoxToAnalysisDmReceivedDrExtComponent,
    WriteEMailDialogComponent,
    TextEditorComponent,
    DepartmentSelectComponent,
    MailAttachmentListComponent,
    OrderSelectComponent,
    StorageSystemTypeSelectComponent,
    InputSizeInGbComponent,
    InputSizeInGbComponent,
    OrderStockItemSelectListComponent,
    TrackerInputComponent,
    ShipmentDirectionSelectComponent,
    ShippingProviderSelectComponent,
    ShippingServiceSelectComponent,
    ShipmentDetailDisplayLineComponent,
    ShipmentFormComponent,
    ShipmentAddressBlockComponent,
    ShipmentDisplayLineComponent,
    ShipmentOrderDisplayLineComponent,
    StockItemOrderDisplayLineComponent,
    ActionBoxToOrderClosedOrReturnShipmentComponent,
    ActionBoxShipmentReceivedDrExtComponent,
    ActionBoxShipmentOnTheWayToDrDeComponent,
    ActionBoxOpenShipmentInfoComponent,
    JoinWithPipe,
    TicketCostsSummaryComponent,
    ActionBoxToDataRecoveryPurchasePricesProvidesDrDeComponent,
    ActionBoxDataRecoveryCostsCheckedBySupervisorComponent,
    ActionBoxFromCheckReturnShipmentNecessaryComponent,
    ActionBoxFinalInvoiceNeededComponent,
    ActionBoxToDataRecoveryPurchasePriceCheckedComponent,
    FeedbackRequestListComponent,
    LeadShipmentMailComponent
  ],
  entryComponents: [
    FormButtonComponent,
    FormDateInputComponent,
    FormInputComponent,
    FormSelectComponent
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    // CommonModule,
    ReactiveFormsModule,

    // Angular Material
    A11yModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    AnalysisResultBoxComponent,
    ConfirmationBoxComponent,
    ContactPersonDetailsComponent,
    DataRecoveryProtocolBoxComponent,
    DataRecoveryResultBoxComponent,
    DecisionBoxComponent,
    DialogComponent,
    DialogHeaderComponent,
    DisposalTypeSelectionBoxComponent,
    DoSomethingBoxComponent,
    FileInputComponent,
    FormButtonComponent,
    FormDateInputComponent,
    FormInputComponent,
    FormSelectComponent,
    GrossPriceOutputComponent,
    ImageInputComponent,
    LabLocationSelectionBoxComponent,
    PaginatorComponent,
    PriceBoxComponent,
    RemoteAccessBoxComponent,
    SearchInputComponent,
    ShippingAddressComponent,
    TicketAttachmentsComponent,
    ViewHeadingComponent,
    VoidBoxComponent,
    StorageSystemTypeSelectComponent,
    StorageSystemManufacturerSelectComponent,
    InputSizeInGbComponent,
    OrderStockItemSelectListComponent,
    DataMediaLineComponent,
    StockItemDisplayLineComponent,
    TrackerInputComponent,
    ShowShipmentDialogComponent,
    CreateShipmentDialogComponent,
    fromModuleContainers.AllContainers,
    fromModuleDirectives.AllDirectives,
    SafeHtmlPipe,
    NgSelectModule,
    QuillModule,
    ContentLoaderModule,
    NgArrayPipesModule,
    NgStringPipesModule,
    NgxCurrencyModule,
    NgxMaskModule,
    RxReactiveFormsModule,
    NgPipesModule,
    DynamicIoModule,
    LoadingOverlayComponent,
    PaginatorTableComponent,
    PaginatorUnstyledComponent,
    MomentDurationPipe,
    ContainsPipe,
    OptionalSlicePipe,
    MomentDateWithTime,
    MomentDate,
    FormErrorComponent,
    MyI18nSelectPipe,
    FloatPipe,
    DepartmentSelectComponent,
    TextEditorComponent,
    OrderSelectComponent,
    InputSizeInGbComponent,
    OrderStockItemSelectListComponent,
    PricePipe,
    FileSizePipe,
    CurrencyShortenerPipe,
    ShipmentDirectionSelectComponent,
    ShippingProviderSelectComponent,
    ShippingServiceSelectComponent,
    ShipmentFormComponent,
    ShipmentAddressBlockComponent,
    ShipmentDisplayLineComponent,
    ShipmentOrderDisplayLineComponent,
    JoinWithPipe,
    TicketCostsSummaryComponent,
    FeedbackRequestListComponent
  ],
  imports: [
    // Core Stuff
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    // CommonModule, exported in browser-module already
    ReactiveFormsModule,

    // Angular Material
    A11yModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    // 3rd-party Modules
    NgSelectModule,
    QuillModule.forRoot({
      modules: {
        // syntax: true,
        toolbar: [
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ color: [] }, { background: [] }],
          ['bold', 'italic', 'underline', 'strike']
        ]
      },
      placeholder: 'Kommentar hier eingeben'
    }),
    ContentLoaderModule,
    NgArrayPipesModule,
    NgStringPipesModule,
    NgxCurrencyModule.forRoot(currencyMaskConfig),
    NgxMaskModule.forRoot(),
    RxReactiveFormsModule,
    FormsModule,
    NgPipesModule,
    DynamicIoModule,
    NgxMatIntlTelInputModule,
    TranslateModule
  ],
  providers: [
    fromModuleServices.AbstractApiService,
    fromModuleServices.LocalStorageService,
    fromModuleServices.FormsService,
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: LOCAL_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorUnstyledComponent
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorTableComponent
    },
    WarehouseRights
  ]
})
export class SharedModule {}
