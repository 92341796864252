import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import * as fromModuleServices from '../../services';
import {AdministratorGroupsActions} from '../actions';

@Injectable()
export class AdministratorGroupsEffects {

  constructor(private actions$: Actions, private ags: fromModuleServices.AdministratorGroupsService) {
  }


  loadAdministratorGroups$ = createEffect(() => this.actions$.pipe(
    ofType(AdministratorGroupsActions.LoadAdministratorGroups),
    map(action => action),
    switchMap(() => {

      return this.ags
        .getAdministratorGroups()
        .pipe(
          map(response => AdministratorGroupsActions.LoadAdministratorGroupsSuccess({response})),
          catchError(response => of(AdministratorGroupsActions.LoadAdministratorGroupsFail({response})))
        );
    })
  ));
}
