import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as fromModuleModels from '../../models';

@Component({
  selector: 'customer-account-list',
  styleUrls: ['customer-account-list.component.scss'],
  template: `

    <div class="card m-b--16 m-l--48 mat-elevation-z1">

      <div class="card__heading">
        <ng-content></ng-content>
      </div>
      <div class="card__content">

        <table style="width: 100%; table-layout: fixed" mat-table *ngIf="customerAccounts?.length; else placeholder"
               [dataSource]="customerAccounts" class="table">

          <!-- Dot Column -->
          <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="dot" [class.green]="!!element.enabled"></span>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.firstName }} {{ element.lastName }}</span>
            </td>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>E-Mail</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.email }}</span>
            </td>
          </ng-container>

          <!-- Locked Column -->
          <ng-container matColumnDef="locked">
            <th mat-header-cell *matHeaderCellDef>Gesperrt</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon [innerHTML]="element.locked ? 'check_circle_outline' : 'remove_circle_outline'"></mat-icon>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

              <button [matMenuTriggerFor]="rowMenu" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #rowMenu>
                <button (click)="handleClick('update', element)" data-action="delete" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Bearbeiten</span>
                </button>

                <button (click)="handleClick('delete', element)" data-action="delete" mat-menu-item>
                  <mat-icon>delete_forever</mat-icon>
                  <span>Löschen</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>

    <ng-template #placeholder>
      <p class="placeholder">Für diesen Kunden wurde noch kein Account hinterlegt.</p>
    </ng-template>
  `
})
export class CustomerAccountListComponent implements OnInit {

  @Input() customer: fromModuleModels.Customer;
  @Input() customerAccounts: Array<fromModuleModels.CustomerAccount>;

  @Output() requestDeleteCustomerAccount: EventEmitter<string> = new EventEmitter();
  @Output() requestFetchCustomerAccounts: EventEmitter<string> = new EventEmitter();
  @Output() requestUpdateCustomerAccountForm: EventEmitter<fromModuleModels.CustomerAccount> = new EventEmitter();

  displayedColumns: Array<string> = ['enabled', 'name', 'email', 'locked', 'actions'];

  constructor() {
  }

  ngOnInit(): void {
    this.requestFetchCustomerAccounts.emit(this.customer['@id']);
  }

  handleClick(action: string, account: fromModuleModels.CustomerAccount): void {

    const iri = account['@id'];

    action === 'delete'
      ? this.requestDeleteCustomerAccount.emit(iri)
      : this.requestUpdateCustomerAccountForm.emit(account);
  }
}
