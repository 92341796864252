import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CountriesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { CountriesService } from '../../services/countries.service';

@Injectable()
export class CountriesEffects {
  readCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.ReadCountry),
      map(action => action),
      switchMap(({ iri }) => {
        return this.cs.getCountry(iri).pipe(
          map(response => CountriesActions.ReadCountrySuccess({ response })),
          catchError(response =>
            of(CountriesActions.ReadCountryFail({ response }))
          )
        );
      })
    )
  );
  readCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.ReadCountries),
      map(action => action),
      switchMap(() => {
        return this.cs.getCountries().pipe(
          map(response => CountriesActions.ReadCountriesSuccess({ response })),
          catchError(response =>
            of(CountriesActions.ReadCountriesFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CountriesActions.ReadCountriesFail,
          CountriesActions.ReadCountryFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case CountriesActions.READ_COUNTRY_FAIL:
              message =
                'Beim Lesen eines Landes sind Fehler aufgetreten:' + errors;
              break;
            case CountriesActions.READ_COUNTRIES_FAIL:
              message =
                'Beim Lesen der Länder sind Fehler aufgetreten:' + errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cs: CountriesService,
    private notifierService: NotifierService
  ) {}
}
