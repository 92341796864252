import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {CustomerCommentTagsActions} from '../actions/';
import {CustomerCommentTagsService} from '../../services';

@Injectable()
export class CustomerCommentTagsEffects {

  constructor(private actions$: Actions, private ccts: CustomerCommentTagsService) {
  }


  getCustomerCommentTag$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentTagsActions.LoadCustomerCommentTag),
    map(action => action),
    switchMap(({iri}) => {

      return this.ccts
        .getCustomerCommentTag(iri)
        .pipe(
          map((response: any) => CustomerCommentTagsActions.LoadCustomerCommentTagSuccess({response})),
          catchError(response => of(CustomerCommentTagsActions.LoadCustomerCommentTagFail({response})))
        );
    })
  ));


  getCustomerCommentTags$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerCommentTagsActions.LoadCustomerCommentTags),
    map(action => action),
    switchMap(() => {

      return this.ccts
        .getCustomerCommentTags()
        .pipe(
          map((response: any) => CustomerCommentTagsActions.LoadCustomerCommentTagsSuccess({response})),
          catchError(response => of(CustomerCommentTagsActions.LoadCustomerCommentTagsFail({response})))
        );
    })
  ));
}
