import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-disposal-destroy-dm',
  styleUrls: ['./action-box-to-disposal-destroy-dm.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Datenträger zur Vernichtung da?
              </div>
              <div class="sub-header col-auto">
                Ist der Datenträger zur Vernichtung eingegangen?
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition()"
              [disabled]="!okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">SS zu Vernichtung da?</div>
          <div class="sub-header col-auto">
            Prüfung, ob SS zur Vernichtung da ist
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToDisposalDestroyDmComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    const extTransition = this.order.potentialActions?.find(
      e => e.transition.indexOf('dr_ext') > -1 && !e.error
    );
    return (
      (this.authService.isTechnicianExternal() && !!extTransition) ||
      this.authService.isLogistic() ||
      this.authService.isAdmin() ||
      this.authService.isSupervisor() ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;

        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_disposal_destroy_dm') > -1 && !e.error
        );
      });
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }
}
