import {createSelector} from '@ngrx/store';
import {createFeatureSelector} from '@ngrx/store';

import {NotificationsModuleState, FEEDBACK_REQUESTS_FEATURE_KEY} from '../reducers';
import * as fromModuleModels from '../../models';
import {FeedbackRequest} from '../../models';

const selectNotificationsState = createFeatureSelector<NotificationsModuleState>('notifications');

export const selectFeedbackRequestsState = createSelector(
  selectNotificationsState,
  (state) => state[FEEDBACK_REQUESTS_FEATURE_KEY]
);

export const selectFeedbackRequestsEntities = createSelector(
  selectFeedbackRequestsState,
  (state) => state.entities
);
export const selectSendFeedbackRequestsEntities = createSelector(
  selectFeedbackRequestsState,
  (state) => state.sendEntities
);

export const selectFeedbackRequests = createSelector(
  selectFeedbackRequestsEntities,
  (feedbackRequest): FeedbackRequest[] => Object.values(feedbackRequest)
);
export const selectSendFeedbackRequests = createSelector(
  selectSendFeedbackRequestsEntities,
  (feedbackRequest): FeedbackRequest[] => Object.values(feedbackRequest)
);

export const selectAmountFeedbackRequests = createSelector(
  selectFeedbackRequests,
  (entities: Array<fromModuleModels.FeedbackRequest>) => entities.length
);
//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectFeedbackRequestsState,
  state => state.loading
);
export const selectSendIsLoading = createSelector(
  selectFeedbackRequestsState,
  state => state.loadingSendFeedbackRequests
);

export const selectFeedbackRequestByRelatedInstance = createSelector(
  selectFeedbackRequests,
  (state, {instance}) => state.find(e => e.relatedInstance === instance)
);
export const selectFeedbackRequestByCommentIri = createSelector(
  selectFeedbackRequests,
  (state, {commentIri}) => state.find(e => e.commentIri === commentIri)
);
