import {ActivatedRoute, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {CustomerReplacementStockItemsSelectors} from '../store/selectors';
import {CustomerReplacementStockItemsActions} from '../store';

@Injectable()
export class CustomerReplacementStockItemLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>, private router: ActivatedRoute) {
  }

  canActivate(): Observable<boolean> {

    return this.loadCustomerReplacementStockItemDone(this.router).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  loadCustomerReplacementStockItemDone(router: any) {
    return this.store.pipe(
      select(CustomerReplacementStockItemsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem(router.params.uuid));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
