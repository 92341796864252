import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as moment from 'moment/moment';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromCustomersModuleModels from '../../../customers/models';
import { DynamicFormComponent } from '../../../shared/containers/dynamic-form/dynamic-form.component';
import { IDynamicFormField } from '../../../shared/models';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'customer-filter',
  styleUrls: ['customer-filter-form.component.scss'],
  template: `
    <div
      class="filter-form__outer"
      [class.expanded]="searchBoxVisible$.getValue()"
    >
      <div class="filter-form__wrapper">
        <div class="filter-form mb-3">
          <dynamic-form
            [config]="formConfig"
            [storedFilters]="localStorageService.getObjectByKey('filters', 'customers')"
            (requestAction)="handleRequestHandleAction($event)"
          ></dynamic-form>
        </div>
      </div>
    </div>

    <!--<pre>{{ customerCooperations$ | async | json }}</pre>-->
  `
})
export class CustomerFilterFormComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DynamicFormComponent) dForm: DynamicFormComponent;

  @Input() customerCooperations$: Observable<
    Array<fromMasterDataModuleModels.Product>
  >;
  @Input() customerPartnerStatuses$: Observable<
    Array<fromCustomersModuleModels.CustomerPartnerStatus>
  >;
  @Input() customerTypes$: Observable<
    Array<fromCustomersModuleModels.CustomerType>
  >;
  @Input() searchBoxVisible$: BehaviorSubject<boolean>;

  @Output() requestHandleAction: EventEmitter<{
    [k: string]: string | number | boolean;
  }> = new EventEmitter();

  formConfig: Array<IDynamicFormField> = [
    {
      type: 'input',
      label: 'Kundennummer / ID',
      name: 'id',
      colSpan: '2',
      optionsConfig: {
        clearable: true
      }
    },
    {
      type: 'select',
      label: 'Kundentyp',
      name: 'customerType',
      options$: null,
      colSpan: '2',
      optionsConfig: {
        clearable: true,
        bindValue: '@id',
        bindLabel: 'customerType'
      }
    },
    {
      type: 'select',
      label: 'Partnertyp',
      name: 'partnerType',
      options$: null,
      colSpan: '2',
      optionsConfig: {
        clearable: true,
        bindValue: '@id',
        bindLabel: 'name'
      }
    },
    {
      type: 'select',
      label: 'Kooperationen',
      name: 'customerCooperation',
      colSpan: '2',
      options$: null,
      optionsConfig: {
        clearable: true,
        bindValue: '@id',
        bindLabel: 'name'
      }
    },
    {
      type: 'date',
      label: 'Angelegt nach',
      name: 'createdAtMin',
      colSpan: '2 off-2',
      optionsConfig: {
        clearable: true
      }
    },
    {
      type: 'date',
      label: 'Angelegt vor',
      name: 'createdAtMax',
      colSpan: '2',
      optionsConfig: {
        clearable: true
      }
    }
  ];
  storedFilters?: any;
  onDestroy$: Subject<any> = new Subject<any>();
  constructor(public localStorageService: LocalStorageService) {
  }
  ngAfterViewInit(): void {
    this.searchBoxVisible$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(visible => !!!visible)
      )
      .subscribe(() => this.dForm.form.reset());

    this.formConfig[1].options$ = this.customerTypes$;
    this.formConfig[2].options$ = this.customerPartnerStatuses$;
    this.formConfig[3].options$ = this.customerCooperations$;
    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'customers');
    if(this.storedFilters) {
      this.requestHandleAction.emit(this.storedFilters);
    }
  }

  handleRequestHandleAction(formValues: {
    [k: string]: string | number | boolean;
  }): void {
    const values = { ...formValues };

    if (formValues.hasOwnProperty('createdAtMin')) {
      values.createdAtMin = moment(
        formValues.createdAtMin as string,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');
    }

    if (formValues.hasOwnProperty('createdAtMax')) {
      values.createdAtMax = moment(
        formValues.createdAtMax as string,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');
    }

    if (formValues.hasOwnProperty('releasedAtMin')) {
      values.releasedAtMin = moment(
        formValues.releasedAtMin as string,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');
    }

    if (formValues.hasOwnProperty('releasedAtMax')) {
      values.releasedAtMax = moment(
        formValues.releasedAtMax as string,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');
    }

    // Initial emit from dynamic form component is empty
    if (Object.keys(values).length) {
      this.localStorageService.overwriteObjectByKey('filters', 'customers', values);
      this.requestHandleAction.emit(values);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
