import {Component, Input} from '@angular/core';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {filter, take, takeUntil} from 'rxjs/operators';
import {CorrectionInvoicesActions, PayableInvoicesActions} from '../../store';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {extractIri, extractTypeByIri, extractUUID} from '../../../shared/utilities/objects.utility';
import {InvoiceLike, PayableInvoice} from '../../models';
import {Actions, ofType} from "@ngrx/effects";
import {CopyPayableInvoiceSuccess} from "../../store/actions/payable-invoices.actions";
import {RouterActions} from "../../../application-state/store/actions";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-invoice-action-box',
  styleUrls: ['invoice-action-box.component.scss'],
  template: `
    <div class="d-flex flex-row" *ngIf="invoice">
      <div class="" *ngIf="canCopy">
        <button mat-button (click)="handleRequestCopyInvoice()">
          <mat-icon class="m-r--8">content_copy</mat-icon>
          <span>Kopieren</span>
        </button>
      </div>

      <div class="" *ngIf=" canCancel">
        <button [disabled]="invoice?.state === 'canceled'" mat-button (click)="handleRequestCancelInvoice()">
          <mat-icon class="m-r--8">cancel_presentation</mat-icon>
          <span>Stornieren</span>
        </button>
      </div>

      <div class="" *ngIf="canCorrect">
        <button mat-button (click)="handleRequestCreateCorrectionInvoice()">
          <mat-icon class="m-r--8">euro</mat-icon>
          <span>Gutschrift erstellen</span>
        </button>
      </div>

      <div class="" *ngIf="canDelete">
        <button mat-button (click)="handleRequestDeleteInvoice()">
          <mat-icon class="m-r--8">delete_forever</mat-icon>
          <span>Löschen</span>
        </button>
      </div>
    </div>
  `
})
export class InvoiceActionBoxComponent extends BaseOnDestroyComponent {

  @Input() invoice: InvoiceLike;
  // @Input() actions: Array<string>;

  // @Output() requestCancelInvoice: EventEmitter<{ iri: string }> = new EventEmitter();
  // @Output() requestCopyInvoice: EventEmitter<any> = new EventEmitter<any>();
  // @Output() requestCreateCorrectionInvoice: EventEmitter<fromInvoicesModuleModels.CorrectionInvoice> = new EventEmitter();
  // @Output() requestDeleteInvoice: EventEmitter<string> = new EventEmitter();

  get canCancel(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'offers') {
      return false;
    }

    return this.invoice && 'potentialActions' in this.invoice && !!this.invoice.potentialActions.find(action => action.transition === 'cancel');
  }

  get canCopy(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'cancellation_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'correction_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'commission_credits') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'offers') {
      return false;
    }
    return (extractTypeByIri(this.invoice, true) === 'invoices'
        || extractTypeByIri(this.invoice, true) === 'partial_invoices')
      && !!this.invoice['@id'];
  }

  get canDelete(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'cancellation_invoices') {
      return false;
    }

    return this.invoice && !!this.invoice['@id'] && !('bookedAt' in this.invoice && !!this.invoice?.bookedAt);
  }

  get canCorrect(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'cancellation_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'commission_credits') {
      return false;
    }

    if (extractTypeByIri(this.invoice, true) === 'correction_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'offers') {
      return false;
    }


    return !!this.invoice['@id'];
  }

  constructor(
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<ApplicationState>,
  ) {
    super();
  }

  handleRequestCopyInvoice(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie eine Kopie dieser Rechnung erstellen?',
          heading: 'Rechnung kopieren?',
          confirmationText: 'Ja, kopieren',
          cancelText: 'Abbruch'
        }
      }
    };

    const errorResponse = new HttpErrorResponse({
      error: {
        'hydra:description': 'Die Rechnung konnte nicht kopiert werden.'
      },
      status: 400,
      statusText: 'Bad Request',
      headers: null,
      url: null
    });

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        console.log(this.invoice);
        if(!(this.invoice as PayableInvoice).paymentProcess && !(this.invoice as PayableInvoice).recipient) {
          this.store$.dispatch(PayableInvoicesActions.CopyPayableInvoiceFail({response: errorResponse}));
        }
        this.actions$.pipe(ofType(PayableInvoicesActions.CopyPayableInvoiceSuccess), takeUntil(this.onDestroy$))
          .subscribe(({response}) => {
            this.store$.dispatch(RouterActions.Go({path: ['/invoices', 'payable', extractTypeByIri(response), extractUUID(response)]}));
          });
        this.store$.dispatch(PayableInvoicesActions.CopyPayableInvoice({
            iri: extractIri(this.invoice),
            payload: {
              paymentProcess: (this.invoice as PayableInvoice).paymentProcess['@id'],
              recipient: {
                nameLine1: (this.invoice as PayableInvoice).recipient.nameLine1,
                nameLine2: (this.invoice as PayableInvoice).recipient.nameLine2,
                address: {
                  line1: (this.invoice as PayableInvoice).recipient.address.line1,
                  line2: (this.invoice as PayableInvoice).recipient.address.line2,
                  line3: (this.invoice as PayableInvoice).recipient.address.line3,
                  line4: (this.invoice as PayableInvoice).recipient.address.line4,
                  city: (this.invoice as PayableInvoice).recipient.address.city,
                  zipPostcode: (this.invoice as PayableInvoice).recipient.address.zipPostcode,
                  country: (this.invoice as PayableInvoice).recipient.address.country
                },
                addressType: (this.invoice as PayableInvoice).recipient.addressType['@id'],
              }
            }
          }
        ));
      });
  }

  handleRequestCancelInvoice(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Rechnung stornieren?',
          heading: 'Rechnung stornieren?',
          confirmationText: 'Ja, stornieren',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(PayableInvoicesActions.CancelPayableInvoice({iri: extractIri(this.invoice)}));
      });
  }

  handleRequestCreateCorrectionInvoice(): void {

    const payload = {
      correctedInvoice: this.invoice['@id'],
      issuer: this.invoice.issuer['@id'],
      recipient: {
        nameLine1: this.invoice.recipient.nameLine1,
        nameLine2: '',
        address: {
          line1: this.invoice.recipient.address.line1,
          line2: '',
          line3: '',
          line4: '',
          city: this.invoice.recipient.address.city,
          zipPostcode: this.invoice.recipient.address.zipPostcode,
          country: this.invoice.recipient.address.country
        },
        addressType: this.invoice.recipient.addressType['@id'],
      },
      customerNumber: this.invoice.customerNumber
    };

    // @ts-ignore
    if (!!this.invoice.recipient?.taxNumber) {
      // @ts-ignore
      payload.recipient.taxNumber = this.invoice.recipient.taxNumber;
    }

    if (!!this.invoice.recipient.nameLine2) {
      payload.recipient.nameLine2 = this.invoice.recipient.nameLine2;
    }
    if (!!this.invoice.recipient.address.line2) {
      payload.recipient.address.line2 = this.invoice.recipient.address.line2;
    }
    if (!!this.invoice.recipient.address.line3) {
      payload.recipient.address.line3 = this.invoice.recipient.address.line3;
    }
    if (!!this.invoice.recipient.address.line3) {
      payload.recipient.address.line4 = this.invoice.recipient.address.line4;
    }


    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie zu dieser Rechnung eine Gutschrift erstellen?',
          heading: 'Gutschrift erstellen',
          confirmationText: 'Ja',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(CorrectionInvoicesActions.CreateCorrectionInvoice({payload}));
        const success = this.actions$.pipe(ofType(CorrectionInvoicesActions.CreateCorrectionInvoiceSuccess), takeUntil(this.onDestroy$));
        const fail = this.actions$.pipe(ofType(CorrectionInvoicesActions.CreateCorrectionInvoiceFail), takeUntil(this.onDestroy$));
        success.pipe(takeUntil(fail), take(1)).subscribe(({response}) => {
          const uuid = extractUUID(response);
          this.store$.dispatch(RouterActions.Go({path: ['/invoices', 'corrections', uuid]}));
        });
      });
  }

  handleRequestDeleteInvoice(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Rechnung wirklich löschen?',
          heading: 'Rechnung löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(PayableInvoicesActions.DeletePayableInvoice({iri: extractIri(this.invoice)}));
      });
  }
}
