import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_DATA_RECOVERY_COSTS =
  '[Orders Module] Read Data Recovery Costs';
export const READ_DATA_RECOVERY_COSTS_SUCCESS =
  '[Orders Module] Read Data Recovery Costs Success';
export const READ_DATA_RECOVERY_COSTS_FAIL =
  '[Orders Module] Read Data Recovery Costs Fail';

export const ReadDataRecoveryCosts = createAction(
  READ_DATA_RECOVERY_COSTS,
  props<{ iri: string }>()
);

export const ReadDataRecoveryCostsSuccess = createAction(
  READ_DATA_RECOVERY_COSTS_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const ReadDataRecoveryCostsFail = createAction(
  READ_DATA_RECOVERY_COSTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CHECKED_BY_SUPERVISOR =
  '[Orders Module] Update Checked by Supervisor';
export const UPDATE_CHECKED_BY_SUPERVISOR_SUCCESS =
  '[Orders Module] Update Checked by Supervisor Success';
export const UPDATE_CHECKED_BY_SUPERVISOR_FAIL =
  '[Orders Module] Update Checked by Supervisor Fail';

export const UpdateCheckedBySupervisor = createAction(
  UPDATE_CHECKED_BY_SUPERVISOR,
  props<{ iri: string; payload: { checkedBySupervisor: boolean } }>()
);

export const UpdateCheckedBySupervisorSuccess = createAction(
  UPDATE_CHECKED_BY_SUPERVISOR_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdateCheckedBySupervisorFail = createAction(
  UPDATE_CHECKED_BY_SUPERVISOR_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_PURCHASE_PRICES = '[Orders Module] Update Purchase Price';
export const UPDATE_PURCHASE_PRICES_SUCCESS =
  '[Orders Module] Update Purchase Price Success';
export const UPDATE_PURCHASE_PRICES_FAIL =
  '[Orders Module] Update Purchase Price Fail';

export const UpdatePurchasePrices = createAction(
  UPDATE_PURCHASE_PRICES,
  props<{ iri: string; payload: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdatePurchasePricesSuccess = createAction(
  UPDATE_PURCHASE_PRICES_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdatePurchasePricesFail = createAction(
  UPDATE_PURCHASE_PRICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_SELLING_PRICES = '[Orders Module] Update Selling Price';
export const UPDATE_SELLING_PRICES_SUCCESS =
  '[Orders Module] Update Selling Price Success';
export const UPDATE_SELLING_PRICES_FAIL =
  '[Orders Module] Update Selling Price Fail';

export const UpdateSellingPrices = createAction(
  UPDATE_SELLING_PRICES,
  props<{ iri: string; payload: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdateSellingPricesSuccess = createAction(
  UPDATE_SELLING_PRICES_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdateSellingPricesFail = createAction(
  UPDATE_SELLING_PRICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_PURCHASE_PRICES_FROM_TICKET =
  '[Orders Module] Update Purchase Prices From Ticket';
export const UPDATE_PURCHASE_PRICES_FROM_TICKET_SUCCESS =
  '[Orders Module] Update Purchase Prices From Ticket Success';
export const UPDATE_PURCHASE_PRICES_FROM_TICKET_FAIL =
  '[Orders Module] Update Purchase Prices From Ticket Fail';

export const UpdatePurchasePricesFromTicket = createAction(
  UPDATE_PURCHASE_PRICES_FROM_TICKET,
  props<{ iri: string; payload: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdatePurchasePricesFromTicketSuccess = createAction(
  UPDATE_PURCHASE_PRICES_FROM_TICKET_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdatePurchasePricesFromTicketFail = createAction(
  UPDATE_PURCHASE_PRICES_FROM_TICKET_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_SELLING_PRICES_FROM_TICKET =
  '[Orders Module] Update Selling Prices From Ticket';
export const UPDATE_SELLING_PRICES_FROM_TICKET_SUCCESS =
  '[Orders Module] Update Selling Prices From Ticket Success';
export const UPDATE_SELLING_PRICES_FROM_TICKET_FAIL =
  '[Orders Module] Update Selling Prices From Ticket Fail';

export const UpdateSellingPricesFromTicket = createAction(
  UPDATE_SELLING_PRICES_FROM_TICKET,
  props<{ iri: string; payload: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdateSellingPricesFromTicketSuccess = createAction(
  UPDATE_SELLING_PRICES_FROM_TICKET_SUCCESS,
  props<{ response: fromModuleModels.DataRecoveryCosts }>()
);

export const UpdateSellingPricesFromTicketFail = createAction(
  UPDATE_SELLING_PRICES_FROM_TICKET_FAIL,
  props<{ response: HttpErrorResponse }>()
);
