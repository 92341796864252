import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AddressType, Damage } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DamagesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createDamage(payload: Damage): Observable<Damage> {
    return this.rolesService.userHasRoleFilter<AddressType>(
      'ROLE_MWS_DAMAGE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/damages`, payload)
    );
  }

  readDamage(iri: string): Observable<Damage> {
    return this.rolesService.userHasRoleFilter<AddressType>(
      'ROLE_MWS_DAMAGE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readDamages(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AddressType>(
      'ROLE_MWS_DAMAGE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/damages`)
    );
  }

  updateDamage(iri: string, payload: Damage): Observable<Damage> {
    return this.rolesService.userHasRoleFilter<AddressType>(
      'ROLE_MWS_DAMAGE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteDamage(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DAMAGE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
