import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_TICKET_COMMENT = '[Tickets Module] Create Ticket Comment';
export const CREATE_TICKET_COMMENT_SUCCESS = '[Tickets Module] Create Ticket Comment Success';
export const CREATE_TICKET_COMMENT_FAIL = '[Tickets Module] Create Ticket Comment Fail';

export const CreateTicketComment = createAction(
  CREATE_TICKET_COMMENT,
  props<{ payload: any }>()
);

export const CreateTicketCommentSuccess = createAction(
  CREATE_TICKET_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateTicketCommentFail = createAction(
  CREATE_TICKET_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_TICKET_COMMENT = '[Tickets Module] Read Ticket Comment';
export const READ_TICKET_COMMENT_SUCCESS = '[Tickets Module] Read Ticket Comment Success';
export const READ_TICKET_COMMENT_FAIL = '[Tickets Module] Read Ticket Comment Fail';

export const ReadTicketComment = createAction(
  READ_TICKET_COMMENT,
  props<{ iri: string }>()
);

export const ReadTicketCommentSuccess = createAction(
  READ_TICKET_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketCommentFail = createAction(
  READ_TICKET_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_TICKET_COMMENTS = '[Tickets Module] Read Ticket Comments';
export const READ_TICKET_COMMENTS_SUCCESS = '[Tickets Module] Read Ticket Comments Success';
export const READ_TICKET_COMMENTS_FAIL = '[Tickets Module] Read Ticket Comments Fail';

export const ReadTicketComments = createAction(
  READ_TICKET_COMMENTS,
  props<{ page: number, params?: { [p: string]: any } }>()
);

export const ReadTicketCommentsSuccess = createAction(
  READ_TICKET_COMMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketCommentsFail = createAction(
  READ_TICKET_COMMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const READ_TICKET_COMMENT_CHUNK = '[Tickets Module] Read Ticket Comment Chunk';
export const READ_TICKET_COMMENT_CHUNK_SUCCESS = '[Tickets Module] Read Ticket Comment Chunk Success';
export const READ_TICKET_COMMENT_CHUNK_FAIL = '[Tickets Module] Read Ticket Comment Chunk Fail';

export const ReadTicketCommentChunk = createAction(
  READ_TICKET_COMMENT_CHUNK,
  props<{ uri: string }>()
);

export const ReadTicketCommentChunkSuccess = createAction(
  READ_TICKET_COMMENT_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketCommentChunkFail = createAction(
  READ_TICKET_COMMENT_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_TICKET_COMMENT = '[Tickets Module] Update Ticket Comment';
export const UPDATE_TICKET_COMMENT_SUCCESS = '[Tickets Module] Update Ticket Comment Success';
export const UPDATE_TICKET_COMMENT_FAIL = '[Tickets Module] Update Ticket Comment Fail';

export const UpdateTicketComment = createAction(
  UPDATE_TICKET_COMMENT,
  props<{ iri: string, payload: fromModuleModels.TicketComment }>()
);

export const UpdateTicketCommentSuccess = createAction(
  UPDATE_TICKET_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const UpdateTicketCommentFail = createAction(
  UPDATE_TICKET_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_TICKET_COMMENT = '[Tickets Module] Delete Ticket Comment';
export const DELETE_TICKET_COMMENT_SUCCESS = '[Tickets Module] Delete Ticket Comment Success';
export const DELETE_TICKET_COMMENT_FAIL = '[Tickets Module] Delete Ticket Comment Fail';

export const DeleteTicketComment = createAction(
  DELETE_TICKET_COMMENT,
  props<{ iri: string }>()
);

export const DeleteTicketCommentSuccess = createAction(
  DELETE_TICKET_COMMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteTicketCommentFail = createAction(
  DELETE_TICKET_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Misc

export const RESET_TICKET_COMMENTS_LOADED = '[Tickets Module] Reset Ticket Comments Loaded';

export const ResetTicketCommentsLoaded = createAction(
  RESET_TICKET_COMMENTS_LOADED
);
