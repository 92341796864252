import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromShipmentDirectionsReducers from './shipment-directions.reducers';
import * as fromShipmentServicesReducers from './shipment-services.reducers';
import * as fromShipmentsReducers from './shipments.reducers';
import * as fromShippingProvidersToCustomerReducers from './shipping-providers-to-customer.reducers';
import * as fromShippingProvidersToDataRecoveryReducers from './shipping-providers-to-data-recovery.reducers';
import * as fromShipmentAdditionalInsurancesReducers from './shipment-additional-insurances.reducers';

export const SHIPPING_MODULE_FEATURE_KEY = 'shippingModule';
export const SHIPMENT_DIRECTIONS_FEATURE_KEY = 'shipmentDirections';
export const SHIPMENT_ADDITIONAL_INSURANCES_FEATURE_KEY = 'shipmentAdditionalInsurances';
export const SHIPMENT_SERVICES_FEATURE_KEY = 'shipmentServices';
export const SHIPMENTS_FEATURE_KEY = 'shipments';
export const SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FEATURE_KEY = 'shippingProvidersToDataRecovery';
export const SHIPPING_PROVIDERS_TO_CUSTOMER_FEATURE_KEY = 'shippingProvidersToCustomer';

export interface ShippingModuleState {
  [SHIPMENT_DIRECTIONS_FEATURE_KEY]: fromShipmentDirectionsReducers.State;
  [SHIPMENT_SERVICES_FEATURE_KEY]: fromShipmentServicesReducers.State;
  [SHIPMENT_ADDITIONAL_INSURANCES_FEATURE_KEY]: fromShipmentAdditionalInsurancesReducers.State;
  [SHIPMENTS_FEATURE_KEY]: fromShipmentsReducers.State;
  [SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FEATURE_KEY]: fromShippingProvidersToDataRecoveryReducers.State;
  [SHIPPING_PROVIDERS_TO_CUSTOMER_FEATURE_KEY]: fromShippingProvidersToCustomerReducers.State;
}

export const selectShippingModuleState = createFeatureSelector<ShippingModuleState>(SHIPPING_MODULE_FEATURE_KEY);

export function reducers(state: ShippingModuleState, action: Action) {
  return combineReducers({
    [SHIPMENT_DIRECTIONS_FEATURE_KEY]: fromShipmentDirectionsReducers.reducer,
    [SHIPMENT_SERVICES_FEATURE_KEY]: fromShipmentServicesReducers.reducer,
    [SHIPMENT_ADDITIONAL_INSURANCES_FEATURE_KEY]: fromShipmentAdditionalInsurancesReducers.reducer,
    [SHIPMENTS_FEATURE_KEY]: fromShipmentsReducers.reducer,
    [SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FEATURE_KEY]: fromShippingProvidersToDataRecoveryReducers.reducer,
    [SHIPPING_PROVIDERS_TO_CUSTOMER_FEATURE_KEY]: fromShippingProvidersToCustomerReducers.reducer,
  })(state, action);
}
