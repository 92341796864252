import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  ReplacementStockItemsActions,
  StockItemsActions
} from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.StockItem };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(StockItemsActions.ReadStockItems, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(
    CustomerStockItemsActions.CreateCustomerStockItemSuccess,
    CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess,
    ReplacementStockItemsActions.CreateReplacementStockItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(StockItemsActions.ReadStockItemsSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.StockItem }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(StockItemsActions.ReadStockItemsFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false,
      loaded: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
