import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { CustomersService } from '../../services';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { Customer } from '../../models';
import { extractIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-similar-customer-display',
  styleUrls: ['./similar-customer-display.component.scss'],
  template: `
    <div class="wrapper pos-relative mb-3">
      <app-loading-overlay
        *ngIf="isLoading$ | async"
        [size]="15"
      ></app-loading-overlay>

      <div
        class="search-box-wrapper d-block py-1 px-2 "
        *ngIf="items?.length > 0"
      >
        <div class="heading">Ähnliche Kunden gefunden:</div>
        <div class="results">
          <div
            class="d-flex justify-content-between on-hover-bg-lightgrey cursor-pointer rounded-3 "
            *ngFor="let result of items"
            (click)="selectCustomer.emit(result)"
          >
            <span class="mx-2">
              <span *ngIf="result.customerNumber"
                >#{{ result.customerNumber }}:</span
              >
              {{ result.nameLine1 }}
              <span *ngIf="result.nameLine2">, {{ result.nameLine2 }}</span>
              <span *ngIf="result.defaultBillingAddress"
                >,
                {{ result.defaultBillingAddress?.address?.zipPostcode }}
                {{ result.defaultBillingAddress?.address?.city }}</span
              >
            </span>
            <mat-icon class="mx-2">check</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <!--    <pre>{{ items | json }}</pre>-->
  `
})
export class SimilarCustomerDisplayComponent extends BaseOnDestroyComponent
  implements OnInit, OnChanges {
  @Input() companyName: string;
  @Input() hideCustomer: string | Customer;
  term$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items: any[];
  @Output() selectCustomer: EventEmitter<Customer> = new EventEmitter<
    Customer
  >();
  readonly extractIri = extractIri;

  constructor(
    private store$: Store<ApplicationState>,
    private notifierService: NotifierService,
    private customerService: CustomersService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyName) {
      this.term$.next(changes.companyName.currentValue);
    }
  }

  ngOnInit(): void {
    this.term$
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(500),
        filter(e => e?.length > 3)
      )
      .subscribe(term => {
        this.loadCustomerList(term);
      });
  }

  loadCustomerList(term): void {
    if (!term || term.trim() === '') {
      this.items = [];
      return;
    }
    const params: any = { nameLine1: term };
    this.isLoading$.next(true);

    this.customerService
      .readCustomers(1, params)
      .pipe(take(1))
      .subscribe(
        response => {
          this.isLoading$.next(false);
          this.items =
            response['hydra:member'].filter(
              e =>
                !!!this.hideCustomer ||
                extractIri(e) !== extractIri(this.hideCustomer)
            ) || [];
        },
        data => {
          this.isLoading$.next(false);
          console.error(data);
          const errors = data?.response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          // const message = 'Fehler';
          this.notifierService.show({ type: 'error', message });
        }
      );
  }
}
