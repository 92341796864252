import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { DataRecoveryCosts } from '../models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DataRecoveryCostsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readDataRecoveryCosts(iri: string): Observable<DataRecoveryCosts> {
    return this.rolesService.userHasRoleFilter<DataRecoveryCosts>(
      'ROLE_MWS_DATA_RECOVERY_COSTS_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateSellingPrices(
    iri: string,
    payload: DataRecoveryCosts
  ): Observable<DataRecoveryCosts> {
    const uri = `${iri}/selling_prices`;
    return this.rolesService.userHasRoleFilter<DataRecoveryCosts>(
      'ROLE_MWS_DATA_RECOVERY_COSTS_SELLING_PRICES_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(uri, payload, true)
    );
  }

  updatePurchasePrices(
    iri: string,
    payload: DataRecoveryCosts
  ): Observable<DataRecoveryCosts> {
    const uri = `${iri}/purchase_prices`;
    return this.rolesService.userHasRoleFilter<DataRecoveryCosts>(
      'ROLE_MWS_DATA_RECOVERY_COSTS_PURCHASE_PRICES_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(uri, payload, true)
    );
  }

  updateCheckedBySupervisor(
    iri: string,
    payload: {
      checkedBySupervisor: boolean;
    }
  ): Observable<DataRecoveryCosts> {
    const uri = `${iri}/checked_by_supervisor`;
    return this.rolesService.userHasRoleFilter<DataRecoveryCosts>(
      'ROLE_MWS_DATA_RECOVERY_COSTS_CHECKED_BY_SUPERVISOR_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(uri, payload, true)
    );
  }
}
