import { createReducer, on } from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';
import { PaymentProcessesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current?: fromInvoicesModuleModels.PaymentProcess;
  entities: { [iri: string]: fromInvoicesModuleModels.PaymentProcess };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    PaymentProcessesActions.CreatePaymentProcess,
    PaymentProcessesActions.ReadPaymentProcess,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    PaymentProcessesActions.CreatePaymentProcessSuccess,
    PaymentProcessesActions.ReadPaymentProcessSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(PaymentProcessesActions.ResetCurrentPaymentProcessSuccess, state => {
    return {
      ...state,
      current: null
    };
  }),
  on(
    PaymentProcessesActions.CreatePaymentProcessFail,
    PaymentProcessesActions.ReadPaymentProcessFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
