import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {LeadCommentTagsActions} from '../actions';
import * as fromModuleServices from '../../services';

@Injectable()
export class LeadCommentTagsEffects {

  constructor(private actions$: Actions, private los: fromModuleServices.LeadCommentTagsService) {
  }


  loadLeadCommentTags$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentTagsActions.LoadLeadCommentTags),
    map(action => action),
    switchMap(() => {

      return this.los
        .getLeadCommentTags()
        .pipe(
          map((response: any) => LeadCommentTagsActions.LoadLeadCommentTagsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadCommentTagsActions.LoadLeadCommentTagsFail({response})))
        );
    })
  ));


  loadLeadCommentTag$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentTagsActions.LoadLeadCommentTag),
    map(action => action),
    switchMap(({iri}) => {

      return this.los
        .getLeadCommentTag(iri)
        .pipe(
          map((response: any) => LeadCommentTagsActions.LoadLeadCommentTagSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadCommentTagsActions.LoadLeadCommentTagFail({response})))
        );
    })
  ));
}
