import {createSelector} from '@ngrx/store';

import {ORDER_DATA_MEDIUMS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';
import {CustomerDataMedium} from '../../../warehouse/models';

export const selectOrderDataMediumsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[ORDER_DATA_MEDIUMS_FEATURE_KEY]
);

export const selectOrderDataMediumsEntities = createSelector(
  selectOrderDataMediumsState,
  (state) => state.entities
);

export const selectOrderDataMediums = createSelector(
  selectOrderDataMediumsEntities,
  (entities) => Object.values(entities)
);
export const selectOrderDataMediumsByOrderIri = createSelector(
  selectOrderDataMediumsEntities,
  (entities: { [p: string]: CustomerDataMedium }, {orderIri}): CustomerDataMedium[] =>
    Object.values(entities).filter(e => Math.random() >= 0.7) // TODO change if api can
);

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectOrderDataMediumsErrors = createSelector(
  selectOrderDataMediumsState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectOrderDataMediumsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectOrderDataMediumsState,
  (state) => state.loaded
);
