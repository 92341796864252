import { InvitationConfirmationScreenComponent } from './invitation-confirmation-screen/invitation-confirmation-screen.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { ResetPasswordScreenComponent } from './reset-password-screen/reset-password-screen.component';

export const AllContainers = [
  LoginScreenComponent,
  InvitationConfirmationScreenComponent,
  ResetPasswordScreenComponent
];

export * from './invitation-confirmation-screen/invitation-confirmation-screen.component';
export * from './login-screen/login-screen.component';
export * from './reset-password-screen/reset-password-screen.component';
