import {createSelector} from '@ngrx/store';

import * as AddressTypesSelectors from './address-types.selectors';
import * as AnalysisPriorityModesSelectors from './analysis-priority-modes.selectors';
import * as AnalysisResultCategoriesSelectors from './analysis-result-categories.selectors';
import * as AnalysisResultsSelectors from './analysis-results.selectors';
import * as CountriesSelectors from './countries.selectors';
import * as CurrenciesSelectors from './currencies.selectors';
import * as CustomerCooperationsSelectors from './customer-cooperations.selectors';
import * as DamagesSelectors from './damages.selectors';
import * as DataRecoveryPriorityModesSelectors from './data-recovery-priority-modes.selectors';
import * as DepartmentAdsSelectors from './department-ads.selectors';
import * as DepartmentLogosSelectors from './department-logos.selectors';
import * as DepartmentsSelectors from './departments.selectors';
import * as DestructionTypesSelectors from './destruction-types.selectors';
import * as DiscountsSelectors from './discounts.selectors';
import * as DisposalTypesSelectors from './disposal-types.selectors';
import * as FileSystemsSelectors from './file-systems.selectors';
import * as GradesSelectors from './grades.selectors';
import * as LabLocationsSelectors from './lab-locations.selectors';
import * as LocalesSelectors from './locales.selectors';
import * as OperatingSystemsSelectors from './operating-systems.selectors';
import * as ProductUnitsSelectors from './product-units.selectors';
import * as ProductsSelectors from './products.selectors';
import * as ReplacementDataMediumSourcesSelectors from './replacement-data-medium-sources.selectors';
import * as RolesSelectors from './roles.selectors';
import * as SalutationsSelectors from './salutations.selectors';
import * as ServicesSelectors from './services.selectors';
import * as SpecialAgreementsSelectors from './special-agreements.selectors';
import * as StorageLocationsSelectors from './storage-locations.selectors';
import * as StorageSystemManufacturersSelectors from './storage-system-manufacturers.selectors';
import * as StorageSystemsSelectors from './storage-systems.selectors';
import * as SymptomsSelectors from './symptoms.selectors';
import * as TaxesSelectors from './taxes.selectors';
import * as TermsAndConditionsSelectors from './terms-and-conditions.selectors';
import * as EmailTemplateCategoriesSelectors from './email-template-categories.selectors';
import * as EmailTemplatesSelectors from './email-templates.selectors';
import * as EmailTemplateContextsSelectors from './email-template-contexts.selectors';

export const selectMasterDataErrorsPartOne = createSelector(
  CustomerCooperationsSelectors.selectErrors,
  AnalysisResultsSelectors.selectErrors,
  DepartmentLogosSelectors.selectErrors,
  DepartmentsSelectors.selectErrors,
  FileSystemsSelectors.selectErrors,
  GradesSelectors.selectErrors,
  AnalysisResultCategoriesSelectors.selectErrors,
  ReplacementDataMediumSourcesSelectors.selectErrors,
  (_0, _1, _2, _3, _4, _5, _6, _6a) => {
    return {_0, _1, _2, _3, _4, _5, _6, _6a};
  }
);

export const selectMasterDataErrorsPartTwo = createSelector(
  OperatingSystemsSelectors.selectErrors,
  ProductsSelectors.selectErrors,
  SalutationsSelectors.selectErrors,
  ServicesSelectors.selectErrors,
  StorageSystemManufacturersSelectors.selectErrors,
  CountriesSelectors.selectErrors,
  (_7, _8, _9, _10, _11, _11a) => {
    return {_7, _8, _9, _10, _11, _11a};
  }
);

export const selectMasterDataErrorsPartThree = createSelector(
  StorageLocationsSelectors.selectErrors,
  StorageSystemsSelectors.selectErrors,
  RolesSelectors.selectErrors,
  TermsAndConditionsSelectors.selectErrors,
  LabLocationsSelectors.selectErrors,
  DestructionTypesSelectors.selectErrors,
  (_12, _13, _14, _15, _16, _16a) => {
    return {_12, _13, _14, _15, _16, _16a};
  }
);

export const selectMasterDataErrorsPartFour = createSelector(
  TaxesSelectors.selectErrors,
  ProductUnitsSelectors.selectErrors,
  SpecialAgreementsSelectors.selectErrors,
  DiscountsSelectors.selectErrors,
  DataRecoveryPriorityModesSelectors.selectErrors,
  DisposalTypesSelectors.selectErrors,
  DamagesSelectors.selectErrors,
  (_17, _18, _19, _20, _21, _22, _22a) => {
    return {_17, _18, _19, _20, _21, _22, _22a};
  }
);

export const selectMasterDataErrorsPartFive = createSelector(
  AddressTypesSelectors.selectErrors,
  SymptomsSelectors.selectErrors,
  CurrenciesSelectors.selectErrors,
  (_50, _51, _52) => ({_50, _51, _52})
);

export const selectMasterDataErrors = createSelector(
  selectMasterDataErrorsPartOne,
  selectMasterDataErrorsPartTwo,
  selectMasterDataErrorsPartThree,
  selectMasterDataErrorsPartFour,
  selectMasterDataErrorsPartFive,
  (one, two, three, four, five) => {
    const errors = {...one, ...two, ...three, ...four, ...five};
    let allErrors = {};
    Object.keys(errors).forEach(key => {
      const error: { [property: string]: { message: string } } = errors[key];
      if (error && Object.keys(error).length > 0 && error.constructor === Object) {
        allErrors = {
          ...allErrors,
          ...error
        };
      }
    });
    return allErrors;
    // return {...one, ...two};
  }
);

export {
  AddressTypesSelectors,
  AnalysisPriorityModesSelectors,
  AnalysisResultCategoriesSelectors,
  AnalysisResultsSelectors,
  CountriesSelectors,
  CurrenciesSelectors,
  CustomerCooperationsSelectors,
  DamagesSelectors,
  DataRecoveryPriorityModesSelectors,
  DepartmentAdsSelectors,
  DepartmentLogosSelectors,
  DepartmentsSelectors,
  DestructionTypesSelectors,
  DiscountsSelectors,
  DisposalTypesSelectors,
  FileSystemsSelectors,
  GradesSelectors,
  LabLocationsSelectors,
  LocalesSelectors,
  OperatingSystemsSelectors,
  ProductUnitsSelectors,
  ProductsSelectors,
  ReplacementDataMediumSourcesSelectors,
  RolesSelectors,
  SalutationsSelectors,
  ServicesSelectors,
  SpecialAgreementsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors,
  SymptomsSelectors,
  TaxesSelectors,
  TermsAndConditionsSelectors,
  EmailTemplateCategoriesSelectors,
  EmailTemplatesSelectors,
  EmailTemplateContextsSelectors
};
