import {createSelector} from '@ngrx/store';

import {DATA_RECOVERY_RESULTS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectDataRecoveryResultsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[DATA_RECOVERY_RESULTS_FEATURE_KEY]
);

export const selectDataRecoveryResultsEntities = createSelector(
  selectDataRecoveryResultsState,
  (state) => state.entities
);

export const selectDataRecoveryResults = createSelector(
  selectDataRecoveryResultsEntities,
  (entities) => Object.values(entities)
);

export const selectCurrentDataRecoveryResult = createSelector(
  selectDataRecoveryResultsState,
  state => state.current
);

export const selectDataRecoveryResultByIri = createSelector(
  selectDataRecoveryResultsEntities,
  (entities, {iri}) => entities[iri]
);

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectDataRecoveryResultErrors = createSelector(
  selectDataRecoveryResultsState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDataRecoveryResultsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDataRecoveryResultsState,
  (state) => state.loaded
);
