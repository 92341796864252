import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { SymptomsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Symptom };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    SymptomsActions.CreateSymptom,
    SymptomsActions.ReadSymptom,
    SymptomsActions.ReadSymptoms,
    SymptomsActions.UpdateSymptom,
    SymptomsActions.DeleteSymptom,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(SymptomsActions.ReadSymptomSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(SymptomsActions.ReadSymptomsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Symptom }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    SymptomsActions.CreateSymptomSuccess,
    SymptomsActions.UpdateSymptomSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(SymptomsActions.DeleteSymptomSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    SymptomsActions.CreateSymptomFail,
    SymptomsActions.ReadSymptomFail,
    SymptomsActions.ReadSymptomsFail,
    SymptomsActions.UpdateSymptomFail,
    SymptomsActions.DeleteSymptomFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
