import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';

import {Subject} from 'rxjs';

import * as fromWarehouseModuleModels from '../../models';

@Component({
  selector: 'data-media-list',
  styleUrls: ['./data-media-list.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

    <table class="bmo-table bmo-table-hover bmo-table-heading-uppercase" mat-table
           [dataSource]="items">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- storageLocationNumber Column -->
      <ng-container matColumnDef="storageLocationNumber">
        <th mat-header-cell *matHeaderCellDef>Lagerpl.-Nr.</th>
        <td mat-cell *matCellDef="let element">{{ element.storageLocationNumber }}</td>
      </ng-container>

      <!-- storageNumber Column -->
      <ng-container matColumnDef="storageNumber">
        <th mat-header-cell *matHeaderCellDef>Einl.-Nr.</th>
        <td mat-cell *matCellDef="let element">{{ element.storageNumber }}</td>
      </ng-container>

      <!-- customer Column -->
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.customerName }}
          <ng-container *ngIf="element?.customerCompany">({{ element.customerCompany }})</ng-container>
        </td>
      </ng-container>

      <!-- state Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>

      <!-- storageGroup Column -->
      <ng-container matColumnDef="storageGroup">
        <th mat-header-cell *matHeaderCellDef>Lagergruppe</th>
        <td mat-cell *matCellDef="let element">{{ element.storageGroup }}</td>
      </ng-container>

      <!-- storageSystemType Column -->
      <ng-container matColumnDef="storageSystemType">
        <th mat-header-cell *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let element">{{ element.storageSystemType?.name }}</td>
      </ng-container>

      <!-- storageSystemManufacturer Column -->
      <ng-container matColumnDef="storageSystemManufacturer">
        <th mat-header-cell *matHeaderCellDef>Hersteller</th>
        <td mat-cell *matCellDef="let element">{{ element.storageSystemManufacturer?.name }}</td>
      </ng-container>

      <!-- storageSystemSize Column -->
      <ng-container matColumnDef="storageSystemSize">
        <th mat-header-cell *matHeaderCellDef>Speichergröße</th>
        <td mat-cell *matCellDef="let element">{{ element.storageSystemSize }} GB</td>
      </ng-container>

      <!-- storageSystemSerialNumber Column -->
      <ng-container matColumnDef="storageSystemSerialNumber">
        <th mat-header-cell *matHeaderCellDef>Seriennummer</th>
        <td mat-cell *matCellDef="let element">{{ element.storageSystemSerialNumber }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button (click)="handleRequestShowDataMediumForm(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="handleRequestDeleteDataMedium(element)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"></tr>
    </table>
    <!--<pre>{{ items | json }}</pre>-->
  `,
})
export class DataMediaListComponent implements OnInit, OnDestroy {

  @Input() items: Array<fromWarehouseModuleModels.DataMedium> = [];

  @Output() requestDeleteDataMedium: EventEmitter<fromWarehouseModuleModels.DataMedium> = new EventEmitter();
  @Output() requestShowDataMediumForm: EventEmitter<fromWarehouseModuleModels.DataMedium> = new EventEmitter();

  displayedColumns: Array<string> = [
    'select',
    'storageLocationNumber',
    'storageNumber',
    'customer',
    'state',
    'storageGroup',
    'storageSystemType',
    'storageSystemManufacturer',
    'storageSystemSize',
    'storageSystemSerialNumber',
    'actions'
  ];

  onDestroy$: Subject<any> = new Subject<any>();
  selection: SelectionModel<fromWarehouseModuleModels.DataMedium>;

  constructor() {
  }

  ngOnInit(): void {
    this.selection = new SelectionModel<fromWarehouseModuleModels.DataMedium>(true, []);
  }

  handleRequestDeleteDataMedium(dataMedium: fromWarehouseModuleModels.DataMedium): void {
    this.requestDeleteDataMedium.emit(dataMedium);
  }

  handleRequestShowDataMediumForm(item: fromWarehouseModuleModels.DataMedium): void {
    this.requestShowDataMediumForm.emit(item);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
