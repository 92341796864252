import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';

import {ReplacementDataMediumsActions} from '../actions';
import {ReplacementDataMediumsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class ReplacementDataMediumsEffects {

  constructor(private actions$: Actions, private service: ReplacementDataMediumsService, private notifierService: NotifierService) {
  }


  createReplacementDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementDataMediumsActions.CreateReplacementDataMedium),
    switchMap(({payload}) => {

      return this.service
        .createReplacementDataMedium(payload)
        .pipe(
          map((response: any) => ReplacementDataMediumsActions.CreateReplacementDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementDataMediumsActions.CreateReplacementDataMediumFail({response})))
        );
    })
  ));


  readReplacementDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementDataMediumsActions.ReadReplacementDataMedium),
    mergeMap(({iri}) => {

      return this.service
        .getReplacementDataMedium(iri)
        .pipe(
          map((response: any) => ReplacementDataMediumsActions.ReadReplacementDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementDataMediumsActions.ReadReplacementDataMediumFail({response})))
        );
    })
  ));


  updateReplacementDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementDataMediumsActions.UpdateReplacementDataMedium),
    switchMap(({iri, payload}) => {

      return this.service
        .update(iri, payload)
        .pipe(
          map((response: any) => ReplacementDataMediumsActions.UpdateReplacementDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementDataMediumsActions.UpdateReplacementDataMediumFail({response})))
        );
    })
  ));


  deleteReplacementDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementDataMediumsActions.DeleteReplacementDataMedium),
    switchMap(({iri}) => {

      return this.service
        .deleteReplacementDataMedium(iri)
        .pipe(
          map(() => ReplacementDataMediumsActions.DeleteReplacementDataMediumSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(ReplacementDataMediumsActions.DeleteReplacementDataMediumFail({response})))
        );
    })
  ));


  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      ReplacementDataMediumsActions.CreateReplacementDataMediumSuccess,
      ReplacementDataMediumsActions.UpdateReplacementDataMediumSuccess,
      ReplacementDataMediumsActions.DeleteReplacementDataMediumSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case ReplacementDataMediumsActions.CREATE_REPLACEMENT_DATA_MEDIUM_SUCCESS:
          message = 'Ein neuer Ersatzdatenträger wurde angelegt.';
          break;
        case ReplacementDataMediumsActions.UPDATE_REPLACEMENT_DATA_MEDIUM_SUCCESS:
          message = 'Der Ersatzdatenträger wurde aktualisiert.';
          break;
        case ReplacementDataMediumsActions.DELETE_REPLACEMENT_DATA_MEDIUM_SUCCESS:
          message = 'Der Ersatzdatenträger wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      ReplacementDataMediumsActions.CreateReplacementDataMediumFail,
      ReplacementDataMediumsActions.UpdateReplacementDataMediumFail,
      ReplacementDataMediumsActions.ReadReplacementDataMediumFail,
      ReplacementDataMediumsActions.DeleteReplacementDataMediumFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case ReplacementDataMediumsActions.CREATE_REPLACEMENT_DATA_MEDIUM_FAIL:
          message = 'Beim Anlegen eines neuen Ersatzdatenträger sind Fehler aufgetreten:' + errors;
          break;
        case ReplacementDataMediumsActions.UPDATE_REPLACEMENT_DATA_MEDIUM_FAIL:
          message = 'Beim Aktualisieren des Ersatzdatenträgers sind Fehler aufgetreten:' + errors;
          break;
        case ReplacementDataMediumsActions.DELETE_REPLACEMENT_DATA_MEDIUM_FAIL:
          message = 'Beim Löschen des Ersatzdatenträgers ist ein Fehler aufgetreten:' + errors;
          break;
        case ReplacementDataMediumsActions.READ_REPLACEMENT_DATA_MEDIUM_FAIL:
          message = 'Beim lesen des Ersatzdatenträgers sind Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});
}
