import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {OrderDataMediumsActions} from '../actions';
import {CustomerDataMediumsService} from '../../../warehouse/services';

@Injectable()
export class OrderDataMediumsEffects {

  constructor(private actions$: Actions, private service: CustomerDataMediumsService) {
  }

  ReadDataRecoveryResult$ = createEffect(() => this.actions$.pipe(
    ofType(OrderDataMediumsActions.ReadOrderDataMediums),
    switchMap(({page, params}) => {
      return this.service.readCustomerDataMediums(page, params).pipe(
        map((response) => OrderDataMediumsActions.ReadOrderDataMediumsSuccess({response})),
        catchError((response) => of(OrderDataMediumsActions.ReadOrderDataMediumsFail({response})))
      );
    })
  ));
}
