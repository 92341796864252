import { Component, OnInit } from '@angular/core';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-overwritten-prices-list-widget',
  styleUrls: ['./open-overwritten-prices-list-widget.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Offene geänderte Einkaufspreise</span>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [additionalParams]="{
            dataRecoveryCostsSupervisorCheckRequired: true
          }"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenOverwrittenPricesListWidgetComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {}

  getCircleColor(order: Order): { backgroundColor: string } {
    return { backgroundColor: '#ffc400' };
  }
}
