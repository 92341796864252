import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { Observable, Subject, Subscription } from 'rxjs';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import { Department } from '../../../master-data/models';
import * as fromShippingModuleModels from '../../../shipping/models';
import {ShipmentAdditionalInsurance, ShipmentService} from '../../../shipping/models';
import { ErrorsObject } from '../../utilities/error-utility.utility';
import { StringsUtility } from '../../utilities/strings.utility';
import { CustomerAddress, CustomerContact } from '../../../customers/models';
import {
  CountriesActions,
  DepartmentsActions
} from '../../../master-data/store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  CountriesSelectors,
  DepartmentsSelectors
} from '../../../master-data/store/selectors';
import {ShipmentAdditionalInsurancesSelectors, ShipmentServicesSelectors} from '../../../shipping/store/selectors';
import {ShipmentAdditionalInsurancesActions, ShipmentServicesActions} from '../../../shipping/store';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-form',
  styleUrls: ['shipment-form.component.scss'],
  templateUrl: 'shipment-form.component.html'
})
export class ShipmentFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() errors: ErrorsObject;
  @Input() leadContact: CustomerContact | CustomerAddress | any;
  @Input() shippingProvider: string;

  @Output() updateShipment: EventEmitter<
    fromShippingModuleModels.Shipment
  > = new EventEmitter();
  @Output() updateForm: EventEmitter<FormGroup> = new EventEmitter();

  countries$: Observable<Array<fromMasterDataModuleModels.Country>>;
  countriesIsLoading$: Observable<boolean>;
  shipmentServices$: Observable<Array<ShipmentService>>;
  shipmentServicesIsLoading$: Observable<boolean>;
  shipmentAdditionalInsurances$: Observable<ShipmentAdditionalInsurance[]>;
  shipmentAdditionalInsurancesIsLoading$: Observable<boolean>;
  defaultDepartment$: Observable<Department>;
  defaultDepartmentIsLoading$: Observable<boolean>;


  destinations: { [key: string]: string } = {
    shipFrom: 'Absender',
    shipTo: 'Empfänger'
  };
  onDestroy$: Subject<any> = new Subject<any>();
  sf: FormGroup;
  times: Array<{ label?: string; time: string }> = [];
  private shipmentServicesSub: Subscription;
  private pickupSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>
  ) {}

  get destinationKeys(): string[] {
    return Object.keys(this.destinations);
  }

  get isGoPickup(): boolean {
    const shippingProviderId = StringsUtility.getUuidFromIri(
      this.shippingProvider
    );
    return shippingProviderId === 'SHIPPING_PROVIDER_PICK_UP_GO';
  }

  getDestinationValue(key: string): string {
    return this.destinations[key];
  }

  ngOnInit(): void {
    this.initForm();

    this.loadCountries();
    this.loadDefaultDepartment();
    this.times = Array.from(Array(24).keys()).map(e => {
      return {
        time: e + ':00'
      };
    });
  }

  ngAfterViewInit(): void {
    this.addOrRemoveFormControls();
  }

  initForm(): void {
    this.sf = this.fb.group({
      description: this.fb.control(''),
      trackingNumber: this.fb.control(''),
      additionalInsurance: this.fb.control(null),
      // todo: this should not be static
      shipmentDirection: this.fb.control(
        '/api/shipment_directions/SHIPMENT_DIRECTION_CUSTOMER_TO_DR_DE',
        [Validators.required]
      ),
      shippingProvider: this.fb.control(this.shippingProvider, [
        Validators.required
      ]),
      shipmentService: this.fb.control(null, [Validators.required]),
      shipFrom: this.fb.group({
        companyName: this.fb.control(this.leadContact?.companyName),
        firstName: this.fb.control(this.leadContact.firstName ?? ''),
        lastName: this.fb.control(this.leadContact.lastName ?? ''),
        address: this.fb.group({
          line1: this.fb.control(this.leadContact?.address?.line1 ?? '', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control(this.leadContact?.address?.line2 ?? '', [
            Validators.required,
            Validators.maxLength(35)
          ]),
          line3: this.fb.control(this.leadContact?.address?.line3 ?? ''),
          city: this.fb.control(this.leadContact?.address?.city ?? '', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(
            this.leadContact?.address?.zipPostcode ?? '',
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(10)
            ]
          ),
          country: this.fb.control(this.leadContact?.address?.country ?? null, [
            Validators.required
          ])
        })
      }),
      shipTo: this.fb.group({
        companyName: this.fb.control(''),
        firstName: this.fb.control(''),
        lastName: this.fb.control(''),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', [Validators.required]),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          country: this.fb.control(null, [Validators.required])
        })
      })
    });
    this.sf.statusChanges.subscribe(x => {
      if (
        this.sf.get('pickupDate') &&
        this.sf.get('pickupTimeEarliest') &&
        this.sf.get('pickupTimeLatest') &&
        !this.pickupSub
      ) {
        this.pickupSub = this.sf
          .get('pickupDate')
          .valueChanges.subscribe(() => {
            this.updatePickUpDates();
          });
        this.pickupSub = this.sf
          .get('pickupTimeEarliest')
          .valueChanges.subscribe(() => {
            this.updatePickUpDates();
          });
        this.pickupSub = this.sf
          .get('pickupTimeLatest')
          .valueChanges.subscribe(() => {
            this.updatePickUpDates();
          });
      }
      this.updateForm.emit(this.sf);
    });
  }

  updatePickUpDates(): void {
    const pickupDate = this.sf.get('pickupDate').value;
    const pickupTimeEarliest = this.sf.get('pickupTimeEarliest').value;
    const pickupTimeLatest = this.sf.get('pickupTimeLatest').value;
    this.sf
      .get('pickupEarliest')
      .setValue(pickupDate + 'T' + pickupTimeEarliest + ':00+01:00');
    this.sf
      .get('pickupLatest')
      .setValue(pickupDate + 'T' + pickupTimeLatest + ':00+01:00');
  }

  addOrRemoveFormControls(): void {
    const shippingProviderId = StringsUtility.getUuidFromIri(
      this.shippingProvider
    );
    // Reset shipment control
    this.sf.get('trackingNumber').disable();
    this.sf.get('additionalInsurance').disable();
    this.sf.get('shipmentService')?.setValue(null);
    this.sf.get('shipmentService')?.updateValueAndValidity();

    this.loadShipmentServices(shippingProviderId);

    switch (shippingProviderId) {
      case 'SHIPPING_PROVIDER_PICK_UP_GO':
      case 'SHIPPING_PROVIDER_PICK_UP_UPS': {
        this.addFieldsForPickupToFormModel();
        break;
      }
      case 'SHIPPING_PROVIDER_GENERIC_SHIPMENT': {
        this.sf.get('trackingNumber').enable();
        this.sf.get('shipmentService')?.setValue(null);
        this.sf.addControl('trackingNumber', this.fb.control(''));
        this.removeFormControlValidators('shipmentService');
        break;
      }
      case 'SHIPPING_PROVIDER_SHIPPING_LABEL_DHL': {
        this.loadShipmentAdditionalInsurances();
        this.sf.get('additionalInsurance').enable();
        break;
      }

      case 'SHIPPING_PROVIDER_PERSONAL_DELIVERY_PARTNER':
      case 'SHIPPING_PROVIDER_PERSONAL_DELIVERY_DR_LE': {
        this.removeFormControlValidators('shipmentService');
        break;
      }

      default: {
        this.addFormControlValidators('shipmentService', [Validators.required]);
        this.removeAdditionalFormFieldsFromFormModel();
      }
    }
    if (shippingProviderId === 'SHIPPING_PROVIDER_PICK_UP_GO') {
      this.addFormControlValidators('description', [Validators.required]);
    } else {
      this.removeFormControlValidators('description');
    }
  }

  removeFormControlValidators(control: string): void {
    // todo: from Angular 12 on use:
    //  console.log(this.sf.get(control).removeValidators());
    this.sf.get(control).clearValidators();
    this.sf.get(control).setValidators([]);
    this.sf.get(control).updateValueAndValidity();
  }

  addFormControlValidators(
    control: string,
    validators: Array<ValidatorFn>
  ): void {
    this.sf.get(control).setValidators(validators);
    this.sf.get(control).updateValueAndValidity();
  }

  addFieldsForPickupToFormModel(): void {
    this.sf.addControl(
      'pickupDispatcher',
      this.fb.group({
        address: this.fb.group({
          line1: this.fb.control('', Validators.maxLength(35)),
          line2: this.fb.control(''),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(''),
          zipPostcode: this.fb.control(''),
          country: this.fb.control('', [Validators.required])
        }),
        firstName: this.fb.control(''),
        lastName: this.fb.control(''),
        companyName: this.fb.control(''),
        phone: this.fb.control(null, [Validators.required])
      })
    );
    if (this.sf.get('pickupDispatcher.phone')) {
      // get Phone from data
      this.sf
        .get('pickupDispatcher.phone')
        .setValue(this.leadContact.phone || this.leadContact.mobile);
    }
    this.sf.patchValue({ pickupDispatcher: this.sf.get('shipFrom').value });
    this.sf.addControl(
      'pickupLatest',
      this.fb.control(null, [Validators.required])
    );
    this.sf.addControl(
      'pickupEarliest',
      this.fb.control(null, [Validators.required])
    );
    this.sf.addControl(
      'pickupDate',
      this.fb.control(
        moment()
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substring(0, 10),
        [Validators.required]
      )
    );
    this.sf.addControl(
      'pickupTimeEarliest',
      this.fb.control('08:00', [Validators.required])
    );
    this.sf.addControl(
      'pickupTimeLatest',
      this.fb.control('14:00', [Validators.required])
    );
    this.updatePickUpDates();

    this.destinations = { ...this.destinations, pickupDispatcher: 'Abhol-Ort' };
  }

  removeAdditionalFormFieldsFromFormModel(): void {
    delete this.destinations.pickupDispatcher;

    if (this.sf.get('pickupDispatcher')) {
      this.sf.removeControl('pickupDispatcher');
    }

    if (this.sf.get('pickupDate')) {
      this.sf.removeControl('pickupDate');
    }
    if (this.sf.get('pickupTimeEarliest')) {
      this.sf.removeControl('pickupTimeEarliest');
    }

    if (this.sf.get('pickupTimeLatest')) {
      this.sf.removeControl('pickupTimeLatest');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    this.shipmentServicesSub.unsubscribe();
  }

  private loadCountries(): void {
    this.countries$ = this.store$.select(CountriesSelectors.selectCountries);
    this.countriesIsLoading$ = this.store$.select(CountriesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      CountriesSelectors.isLoaded,
      CountriesActions.ReadCountries()
    );
  }

  private loadDefaultDepartment(): void {
    this.defaultDepartment$ = this.store$.select(
      DepartmentsSelectors.selectDefaultDepartment
    );
    this.defaultDepartment$.subscribe(department => {
      if (!department) {
        return;
      }
      const newDepartment = {
        ...department,
        firstName: department.generalManager.split(' ')[0],
        lastName: department.generalManager.split(' ')[
          department.generalManager.split(' ').length - 1
        ]
      };
      this.sf.patchValue({ shipTo: newDepartment });
    });
    this.defaultDepartmentIsLoading$ = this.store$.select(
      DepartmentsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }

  private loadShipmentServices(shippingProvider: string): void {
    this.shipmentServices$ = this.store$.select(
      ShipmentServicesSelectors.selectShipmentServices
    );
    this.shipmentServicesIsLoading$ = this.store$.select(
      ShipmentServicesSelectors.isLoading
    );
    this.store$.dispatch(
      ShipmentServicesActions.ReadShipmentServices({
        serviceType: shippingProvider
      })
    );
    if (!this.shipmentServicesSub) {
      this.shipmentServicesSub = this.shipmentServices$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(values => {
          if (values.length === 1 && this.sf.get('shipmentService')) {
            this.sf.patchValue({ shipmentService: values[0]['@id'] });
          } else {
            this.sf.patchValue({ shipmentService: null });
          }
        });
    }
  }
  private loadShipmentAdditionalInsurances(): void {
    this.shipmentAdditionalInsurances$ = this.store$.select(
      ShipmentAdditionalInsurancesSelectors.selectShipmentAdditionalInsurances
    );
    this.shipmentAdditionalInsurancesIsLoading$ = this.store$.select(
      ShipmentAdditionalInsurancesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ShipmentAdditionalInsurancesSelectors.isLoaded,
      ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurances()
    );
  }
}
