
<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header">
      <span>Offene Verbindliche Bestellungen</span>
      <div class="spacer"></div>
      <mat-button-toggle-group
        [(ngModel)]="BOTypeSelectValue"
        name="BOTypeSelect"
        aria-label="BO Type Select"
        class="m-r--16"
      >
        <mat-button-toggle value="missingBO">Fehlende VB</mat-button-toggle>
        <mat-button-toggle value="missingDownPayment"
        >Fehlende Anzahlung
        </mat-button-toggle>
        <mat-button-toggle value="BoReceived" *ngIf="userType !== 'Vertrieb'"
        >VB Eingegangen
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <app-orders-list-for-widget
      [indicatorStyleFn]="getCircleColor"
      [states]="bindingOrdersStates[BOTypeSelectValue]"
      [salesperson]="salesperson"
      [additionalParams]="
            BOTypeSelectValue === 'BoReceived'
              ? {
                  dataRecoveryLocation: '/api/lab_locations/LAB_LOCATION_DR_DE'
                }
              : { dataRecoveryLocation: null }
          "
    ></app-orders-list-for-widget>
  </div>
</div>

