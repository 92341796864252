import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import * as fromModuleModels from '../../models';
import {BillingAddress} from "../../models";

@Component({
  selector: 'customer-address-list',
  styleUrls: ['customer-address-list.component.scss'],
  template: `

    <div class="card m-l--48 mat-elevation-z1">

      <div class="card__heading">
        <ng-content></ng-content>
      </div>
      <div class="card__content">

        <table mat-table [dataSource]="addresses" class="mat-elevation-z1"
               *ngIf="addresses?.length; else placeholder">

          <!-- addressLine Column -->
          <ng-container matColumnDef="addressLine">
            <th mat-header-cell *matHeaderCellDef>Anschrift</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.address?.line1}} {{ element.address?.line2}} {{element.address.line3 ? (' ' + element.address.line3) : '' }}</span>
            </td>
          </ng-container>

          <!-- setAsDefault Column -->
          <ng-container matColumnDef="setAsDefault" *ngIf="!isPartner">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button matTooltip="Als Standardadresse setzen"
                      [disabled]="element['@id'] == defaultAddress['@id'] || element['@id'] == defaultAddress"
                      class="toggle-switch" (click)="handleRequestSetDefaultAddress(element['@id'])">
                <mat-icon>verified</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- ZIP Column -->
          <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef>PLZ</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.address?.zipPostcode }}</span>
            </td>
          </ng-container>

          <!-- City Column -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>Stadt</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.address?.city }}</span>
            </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Art der Adresse</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element?.addressType?.name }}</span>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

              <button mat-icon-button (click)="handleClick('copy', element)">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button [matMenuTriggerFor]="rowMenu" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #rowMenu>
                <button (click)="handleClick('update', element)" data-action="delete" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Bearbeiten</span>
                </button>

                <button (click)="handleClick('delete', element)" data-action="delete" mat-menu-item>
                  <mat-icon>delete_forever</mat-icon>
                  <span>Löschen</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="isPartner ? displayedColumnsPartner : displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: isPartner ? displayedColumnsPartner : displayedColumns;">
          </tr>
        </table>
      </div>
    </div>

    <ng-template #placeholder>
      <p class="placeholder">Für diese Adressart wurde noch keine Adresse hinterlegt.</p>
    </ng-template>
  `
})
export class CustomerAddressListComponent implements OnInit {

  @Input() addresses: Array<fromModuleModels.CustomerAddress>;
  @Input() defaultAddress: string | BillingAddress;
  @Input() isCopying$: BehaviorSubject<boolean>;

  @Output() requestUpdateDefaultCustomerAddress: EventEmitter<string> = new EventEmitter();
  @Output() requestDeleteCustomerAddress: EventEmitter<string> = new EventEmitter();
  @Output() requestUpdateCustomerAddressForm: EventEmitter<fromModuleModels.CustomerAddress> = new EventEmitter();
  @Output() requestFetchAddressData: EventEmitter<void> = new EventEmitter();

  displayedColumns: Array<string> = ['addressLine', 'setAsDefault', 'zip', 'city', 'type', 'actions'];
  displayedColumnsPartner: Array<string> = ['addressLine', 'zip', 'city', 'type', 'actions'];

  constructor() {
  }

  ngOnInit(): void {
    this.requestFetchAddressData.emit();
  }

  handleRequestSetDefaultAddress(addressUUid: string): void {
    this.requestUpdateDefaultCustomerAddress.emit(addressUUid);
  }

  handleClick(action: string, address: fromModuleModels.CustomerAddress): void {

    if (action === 'copy') {
      this.isCopying$.next(true);
    }

    action === 'delete'
      ? this.requestDeleteCustomerAddress.emit(address['@id'])
      : this.requestUpdateCustomerAddressForm.emit(address);
  }
}
