import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {IpVersion, RxwebValidators} from '@rxweb/reactive-form-validators';
import {Subject} from 'rxjs';

import * as fromModuleModels from '../../../tickets/models';
import {ErrorsObject} from '../../utilities/error-utility.utility';
import {select, Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {TicketsSelectors} from '../../../tickets/store/selectors';
import {Actions, ofType} from '@ngrx/effects';
import {takeUntil} from 'rxjs/operators';
import {TicketsActions} from '../../../tickets/store';

@Component({
  selector: 'remote-access-box',
  styleUrls: ['remote-access-box.component.scss'],
  template: `

    <form class="grid" [formGroup]="raf">

      <div class="column-4 grid">

        <mat-form-field class="column-14">
          <mat-placeholder>IP (Format: XXX.XXX.XXX.XXX)</mat-placeholder>
          <input formControlName="ip" type="text" minlength="7" maxlength="15" size="15" matInput required>
          <mat-hint align="start" *ngIf="errors?.ip">{{ errors.ip.message }}</mat-hint>
        </mat-form-field>


        <mat-form-field class="column-14">
          <mat-placeholder>User</mat-placeholder>
          <input matInput formControlName="user" type="text" required>
          <mat-hint align="start" *ngIf="errors?.user">{{ errors.user.message }}</mat-hint>
        </mat-form-field>


        <mat-form-field class="column-14">
          <mat-placeholder>Passwort</mat-placeholder>
          <input matInput formControlName="password" type="password" #passInput>
          <mat-icon
            matSuffix style="margin-right: 12px; cursor: pointer; opacity: .8"
            (mousedown)="passInput.type = 'text'"
            (mouseup)="passInput.type = 'password'">visibility
          </mat-icon>
          <mat-hint align="start" *ngIf="errors?.password">{{ errors.password.message }}</mat-hint>
        </mat-form-field>
      </div>

      <div class="column-10 grid">
        <div class="column-14">
          <mat-form-field>
            <mat-placeholder>Bemerkung</mat-placeholder>
            <textarea matInput formControlName="comment" rows="10"></textarea>
            <mat-hint align="start" *ngIf="errors?.comment">{{ errors.comment.message }}</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="column-14">
        <div class="m-ta--2">
          <button
            mat-flat-button color="green"
            type="button"
            [disabled]="raf.invalid"
            (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>Zugriffsdaten zur Verfügung stellen</span>
          </button>
        </div>
      </div>
    </form>
  `
})
export class RemoteAccessBoxComponent implements OnInit, OnDestroy {

  @Output() requestUpdateTicket: EventEmitter<{ remoteAccess: fromModuleModels.RemoteAccess }> = new EventEmitter();

  errors: ErrorsObject;
  raf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  ngOnInit(): void {
    this.initForm();

    this.actions$.pipe(
      takeUntil(this.onDestroy$),
      ofType(TicketsActions.ProvideRemoteAccessSuccess)
    ).subscribe(({type}) => {
      this.initForm();
    });
  }

  initForm(): void {
    this.raf = this.fb.group({
      ip: this.fb.control(''),
      user: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null),
      comment: this.fb.control(null),
    });

    this.store$.pipe(
      takeUntil(this.onDestroy$),
      select(TicketsSelectors.selectErrors)
    ).subscribe(errors => {

      console.log();
      this.errors = errors;
    });
  }

  handleSubmit(): void {
    this.requestUpdateTicket.emit({remoteAccess: this.raf.value});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
