import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DataRecoveryCostsActions } from '../actions';
import { DataRecoveryCostsService } from '../../services';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class DataRecoveryCostsEffects {
  ReadDataRecoveryCosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.ReadDataRecoveryCosts),
      mergeMap(({ iri }) => {
        return this.service.readDataRecoveryCosts(iri).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.ReadDataRecoveryCostsSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(DataRecoveryCostsActions.ReadDataRecoveryCostsFail({ response }))
          )
        );
      })
    )
  );
  UpdateSellingPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdateSellingPrices),
      switchMap(({ iri, payload }) => {
        return this.service.updateSellingPrices(iri, payload).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.UpdateSellingPricesSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(DataRecoveryCostsActions.UpdateSellingPricesFail({ response }))
          )
        );
      })
    )
  );
  UpdateSellingPricesFromTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdateSellingPricesFromTicket),
      switchMap(({ iri, payload }) => {
        return this.service.updateSellingPrices(iri, payload).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.UpdateSellingPricesFromTicketSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              DataRecoveryCostsActions.UpdateSellingPricesFromTicketFail({
                response
              })
            )
          )
        );
      })
    )
  );
  UpdatePurchasePrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdatePurchasePrices),
      switchMap(({ iri, payload }) => {
        return this.service.updatePurchasePrices(iri, payload).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.UpdatePurchasePricesSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(DataRecoveryCostsActions.UpdatePurchasePricesFail({ response }))
          )
        );
      })
    )
  );
  UpdateCheckedBySupervisor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdateCheckedBySupervisor),
      switchMap(({ iri, payload }) => {
        return this.service.updateCheckedBySupervisor(iri, payload).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.UpdateCheckedBySupervisorSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              DataRecoveryCostsActions.UpdateCheckedBySupervisorFail({
                response
              })
            )
          )
        );
      })
    )
  );

  UpdatePurchasePricesFromTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdatePurchasePricesFromTicket),
      switchMap(({ iri, payload }) => {
        return this.service.updatePurchasePrices(iri, payload).pipe(
          map((response: any) =>
            DataRecoveryCostsActions.UpdatePurchasePricesFromTicketSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              DataRecoveryCostsActions.UpdatePurchasePricesFromTicketFail({
                response
              })
            )
          )
        );
      })
    )
  );
  UpdatePricesSuccessful = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DataRecoveryCostsActions.UpdateSellingPricesSuccess,
          DataRecoveryCostsActions.UpdateSellingPricesFromTicketSuccess,
          DataRecoveryCostsActions.UpdatePurchasePricesSuccess,
          DataRecoveryCostsActions.UpdatePurchasePricesFromTicketSuccess
        ),
        map(({ type }) => {
          const message = 'Preise erfolgreich aktualisiert.';

          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  UpdateCheckedBySupervisorSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataRecoveryCostsActions.UpdateCheckedBySupervisorSuccess),
        map(({ type }) => {
          const message = 'Preise erfolgreich bestätigt.';

          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  UpdatePricesFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DataRecoveryCostsActions.UpdateCheckedBySupervisorFail,
          DataRecoveryCostsActions.UpdateSellingPricesFail,
          DataRecoveryCostsActions.UpdateSellingPricesFromTicketFail,
          DataRecoveryCostsActions.UpdatePurchasePricesFail,
          DataRecoveryCostsActions.UpdatePurchasePricesFromTicketFail
        ),
        map(({ type, response }) => {
          const message =
            'Preise konnten nicht aktualisiert/bestätigt werden: ' +
            response?.error['hydra:description'];
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: DataRecoveryCostsService,
    private notifierService: NotifierService
  ) {}
}
