import {createSelector} from '@ngrx/store';

import {OPERATING_SYSTEMS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectOperatingSystemsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[OPERATING_SYSTEMS_FEATURE_KEY]
);

export const selectOperatingSystemsEntities = createSelector(
  selectOperatingSystemsState,
  (state) => state.entities
);

export const selectOperatingSystems = createSelector(
  selectOperatingSystemsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectOperatingSystemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectOperatingSystemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectOperatingSystemsState,
  (state) => state.loaded
);
