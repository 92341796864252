import {createSelector} from '@ngrx/store';

import {ADDRESS_TYPES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectAddressTypesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[ADDRESS_TYPES_FEATURE_KEY]
);

export const selectAddressTypesEntities = createSelector(
  selectAddressTypesState,
  (state) => state.entities
);

export const selectAddressTypes = createSelector(
  selectAddressTypesEntities,
  (entities) => Object.values(entities)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectAddressTypesState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectAddressTypesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectAddressTypesState,
  (state) => state.loaded
);
