import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';

import {FormsService} from '../../../shared/services';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import * as fromModuleModels from '../../models';

@Component({
  selector: 'partner-website-form',
  styleUrls: ['partner-website-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

    <div class="card m-b--25 mat-elevation-z1">

      <div class="card__heading">

        <div class="grid">
          <div class="column-10">
            <ng-content></ng-content>
          </div>
          <div class="column-4 m-ta--2" style="padding-right: 0">
            <mat-checkbox
              [checked]="isInvitation$.getValue()"
              (change)="isInvitation$.next(!isInvitation$.getValue())"
            >Einladen
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="card__content p-a--24">

        <div [formGroup]="pwf" class="grid">

          <mat-form-field class="column-14">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" required>
            <mat-hint align="start" *ngIf="errors?.email">{{ errors.email.message }}</mat-hint>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Website / URL</mat-label>
            <input type="url" matInput formControlName="website" required>
            <mat-hint align="start" *ngIf="errors?.website">{{ errors.website.message }}</mat-hint>
          </mat-form-field>

          <mat-form-field class="column-14" *ngIf="this.pwf.contains('plainPassword')">
            <mat-label>Passwort</mat-label>
            <input type="password" matInput formControlName="plainPassword" #plainPasswordInput>

            <mat-icon
              matSuffix style="margin-right: 12px; cursor: pointer; opacity: .8"
              (mousedown)="plainPasswordInput.type = 'text'"
              (mouseup)="plainPasswordInput.type = 'password'">visibility
            </mat-icon>

            <mat-hint align="start" *ngIf="errors?.plainPassword">{{ errors.plainPassword.message }}</mat-hint>
          </mat-form-field>

          <mat-form-field class="column-14" *ngIf="this.pwf.contains('plainPasswordConfirmation')">
            <mat-label>Passwort-Bestätigung</mat-label>
            <input type="password" matInput formControlName="plainPasswordConfirmation" #plainPasswordConfirmationInput>

            <mat-icon
              matSuffix style="margin-right: 12px; cursor: pointer; opacity: .8"
              (mousedown)="plainPasswordConfirmationInput.type = 'text'"
              (mouseup)="plainPasswordConfirmationInput.type = 'password'">visibility
            </mat-icon>
            <mat-hint align="start"
                      *ngIf="errors?.plainPasswordConfirmation">{{ errors.plainPasswordConfirmation.message }}</mat-hint>
          </mat-form-field>

          <div class="column-14 grid">

            <div class="column-4">
              <div class="mat-form-field checkbox">
                <mat-checkbox formControlName="enabled">Aktiviert</mat-checkbox>
              </div>
            </div>
            <div class="column-4">
              <div class="mat-form-field checkbox">
                <mat-checkbox formControlName="locked">Gesperrt</mat-checkbox>
              </div>
            </div>
          </div>

          <div class="m-t--16 m-ta--2 column-14">

            <button
              *ngIf="pwf.dirty || isMatchingPreset(presets$.getValue()?.type)" class="m-r--8"
              (click)="cancelEdit()" type="button" color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button (click)="handleSubmit()" [disabled]="pwf.invalid || pwf.pristine" mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ getSubmitButtonLabel() }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ errors | json }}</pre>-->
    <!--<pre>{{ pwf.value | json }}</pre>-->
    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
  `
})
export class PartnerWebsiteFormComponent implements OnInit {

  @Input() customer: fromModuleModels.Customer;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<any>;

  @Output() requestCreatePartnerWebsite: EventEmitter<fromModuleModels.PartnerWebsite> = new EventEmitter();
  @Output() requestCreatePartnerWebsiteInvitation: EventEmitter<fromModuleModels.PartnerWebsiteInvitation> = new EventEmitter();
  @Output() requestUpdatePartnerWebsite: EventEmitter<{
    iri: string,
    payload: fromModuleModels.PartnerWebsite
  }> = new EventEmitter();

  allowedPresets = ['InvitationPartnerWebsite', 'PartnerWebsite'];
  isInvitation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onDestroy$: Subject<any> = new Subject<any>();
  pwf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  isMatchingPreset(type?: string): boolean {
    if (!type) {
      return false;
    }
    return this.allowedPresets.includes(type);
  }

  ngOnInit(): void {

    this.isInvitation$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(isInvitation => {

      if (undefined === this.pwf) {
        this.initForm();
      }

      if (isInvitation) {
        this.removeNonInvitationFields();
      } else {
        this.addNonInvitationFields();
      }
    });

    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((presets) => {

      if (undefined === this.pwf || null === presets) {
        this.initForm();
      }

      if (presets?.type === 'PartnerWebsite') {
        this.fs.patchForm(this.pwf, presets.presets);
        this.pwf.markAsUntouched();
      }
    });
  }

  getSubmitButtonLabel(): string {
    return (this.presets$?.getValue() && this.presets$?.getValue().type === 'PartnerWebsite')
      ? 'Aktualisieren'
      : this.isInvitation$.getValue()
        ? 'Einladen'
        : 'Anlegen';
  }

  handleSubmit(): void {
    const payload: fromModuleModels.PartnerWebsite = this.pwf.value;

    if (this.presets$?.getValue() && this.presets$?.getValue().type === 'PartnerWebsite') {
      this.requestUpdatePartnerWebsite.emit({iri: this.presets$.getValue().presets['@id'], payload});
    } else {
      if (this.isInvitation$.getValue()) {
        this.requestCreatePartnerWebsiteInvitation.emit(payload);
      } else {
        this.requestCreatePartnerWebsite.emit(payload);
      }
    }
  }

  initForm(): void {
    this.pwf = this.fb.group({
      customer: this.fb.control(this.customer['@id'], [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      website: this.fb.control(null, [Validators.required]),
      plainPassword: this.fb.control(''),
      plainPasswordConfirmation: this.fb.control(''),
      enabled: this.fb.control(true),
      locked: this.fb.control(false)
    });

    // Reset Error Messages also
    // see https://github.com/angular/components/issues/4190#issuecomment-305118229
    // Object.keys(this.pwf.controls).forEach(key => this.pwf.controls[key].setErrors(null));
  }

  removeNonInvitationFields(): void {
    this.pwf.removeControl('plainPassword');
    this.pwf.removeControl('plainPasswordConfirmation');
    this.pwf.removeControl('enabled');
    this.pwf.removeControl('locked');
  }

  addNonInvitationFields(): void {
    this.pwf.addControl('plainPassword', this.fb.control(''));
    this.pwf.addControl('plainPasswordConfirmation', this.fb.control(''));
    this.pwf.addControl('enabled', this.fb.control(true));
    this.pwf.addControl('locked', this.fb.control(false));
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}

