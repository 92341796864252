import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Vendor Import
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { getDiffOfDates } from '../../../shared/utilities/date.utility';
import { filter, map, takeUntil, timeInterval } from 'rxjs/operators';
import { combineLatestArray } from 'rxjs-etc';
import Timeout = NodeJS.Timeout;

// Provider

@Component({
  selector: 'app-user-menu',
  styleUrls: ['user-menu.component.scss'],
  template: `
    <div
      class="inner grid grid-no-gutter pos-relative"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
    >
      <mat-spinner
        *ngIf="!profile || !getFullName"
        class="my-2"
        diameter="40"
      ></mat-spinner>

      <div class="column-auto column-middle" *ngIf="!!profile && !!getFullName">
        <p class="text">{{ getFullName }}</p>
        <p class="sub">{{ profile['email'] }}</p>
      </div>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="handleRequestShowUserProfileForm()">
          <mat-icon>account_circle</mat-icon>
          <span>Profil bearbeiten</span>
        </button>
        <button mat-menu-item (click)="handleRequestShowPasswordForm()">
          <mat-icon>lock</mat-icon>
          <span>Passwort bearbeiten</span>
        </button>
        <button mat-menu-item disabled class="text-center">
          <span>Logout in {{ remainingSessionTime$ | async }}</span>
        </button>
      </mat-menu>
    </div>
  `
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @Input() profile: any;

  @Output()
  requestShowPasswordForm: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestShowProfileForm: EventEmitter<void> = new EventEmitter<void>();

  remainingSessionTime$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  onDestroy$: Subject<any> = new Subject<any>();

  interval: Timeout = null;

  constructor(private as: AuthService) {}

  get getFullName(): string {
    return this.profile?.website
      ? this.profile.website
      : `${this.profile.firstName} ${this.profile.lastName}`;
  }

  ngOnInit(): void {
    combineLatestArray([
      interval(1000).pipe(
        timeInterval(),
        map(e => e.value)
      ),
      this.as.jwtPayload$.pipe(
        filter(e => !!e?.exp),
        map(e => e.exp)
      )
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        map(([t, e]) => {
          const diff = getDiffOfDates(new Date(), new Date(e * 1000));
          return diff.minute > 0
            ? ` ${diff.minute}m ${diff.second}s`
            : ` ${diff.second}s`;
        })
      )
      .subscribe(e => {
        this.remainingSessionTime$.next(e);
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestShowPasswordForm(): void {
    this.requestShowPasswordForm.emit();
  }

  handleRequestShowUserProfileForm(): void {
    this.requestShowProfileForm.emit();
  }
}
