import { Injectable } from '@angular/core';

import { AbstractApiService } from '../../shared/services';
import * as fromModuleModels from '../models';
import { CustomerContact } from '../models';
import { EMPTY, forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerContactsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomerContact(payload: CustomerContact): Observable<any> {
    return this.rolesService.userHasRoleFilter<CustomerContact>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject('/customer_contacts', payload)
    );
  }

  readCustomerContacts(
    customerContactUuids: Array<string>
  ): Observable<Array<CustomerContact>> {
    return this.rolesService.userHasRoleFilter<Array<CustomerContact>>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      () =>
        forkJoin(
          customerContactUuids.map(uuid =>
            this.apiService.getObject(uuid, true)
          )
        )
    );
  }

  _readContacts(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/customer_contacts', page, params)
            )
    );
  }

  readContact(iri: string): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateCustomerContact(
    iri: string,
    payload: fromModuleModels.CustomerContact
  ): Observable<any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomerContact(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
