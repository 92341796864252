import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSelectionList } from '@angular/material/list';

import { BehaviorSubject, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import * as fromCustomersModuleModels from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getDirtyValuesObject } from '../../../shared/utilities/forms.utility';

@Component({
  selector: 'partner-status-form',
  styleUrls: ['partner-status-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card mat-elevation-z1">
      <div class="card__heading">
        <span>Partner-Details</span>
      </div>

      <div class="card__content p-a--24">
        <ng-container [formGroup]="pdf">
          <div class="grid">
            <div class="mat-form-field column-14">
              <ng-select
                placeholder="Partner-Status"
                [items]="customerPartnerStatuses"
                bindValue="@id"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
                formControlName="partnerStatus"
              ></ng-select>
            </div>
          </div>

          <div [class.disabled]="isActualPartner$.getValue()">
            <div class="inputs">
              <div class="grid">
                <div class="column-7">
                  <mat-form-field class="mat-form-field-align-right">
                    <mat-label>Provision % Analyse</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      [options]="{
                        min: 0,
                        max: 100,
                        suffix: ' %',
                        prefix: ' ',
                        align: 'right'
                      }"
                      formControlName="partnerCommissionPercentageAnalysis"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionPercentageAnalysis"
                      >{{
                        errors.partnerCommissionPercentageAnalysis.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="column-7">
                  <mat-form-field>
                    <mat-label>Provision € Analyse Fix</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      formControlName="partnerCommissionFixedAnalysis"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionFixedAnalysis"
                      >{{
                        errors.partnerCommissionFixedAnalysis.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="column-7">
                  <mat-form-field class="mat-form-field-align-right">
                    <mat-label>Provision % Datenrettung</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      [options]="{
                        min: 0,
                        max: 100,
                        suffix: ' %',
                        prefix: ' ',
                        align: 'right'
                      }"
                      formControlName="partnerCommissionPercentageDatarecovery"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionPercentageDatarecovery"
                      >{{
                        errors.partnerCommissionPercentageDatarecovery.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="column-7">
                  <mat-form-field>
                    <mat-label>Provision € Datenrettung Fix</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      formControlName="partnerCommissionFixedDatarecovery"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionFixedDatarecovery"
                      >{{
                        errors.partnerCommissionFixedDatarecovery.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="column-7">
                  <mat-form-field class="mat-form-field-align-right">
                    <mat-label>Provision % Sonstiges</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      [options]="{
                        min: 0,
                        max: 100,
                        suffix: ' %',
                        prefix: ' ',
                        align: 'right'
                      }"
                      formControlName="partnerCommissionPercentageOther"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionPercentageOther"
                      >{{
                        errors.partnerCommissionPercentageOther.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="column-7">
                  <mat-form-field>
                    <mat-label>Provision € Sonstiges Fix</mat-label>
                    <input
                      type="text"
                      currencyMask
                      matInput
                      formControlName="partnerCommissionFixedOther"
                    />
                    <mat-hint
                      align="start"
                      *ngIf="errors?.partnerCommissionFixedOther"
                      >{{
                        errors.partnerCommissionFixedOther.message
                      }}</mat-hint
                    >
                  </mat-form-field>
                </div>
              </div>

              <mat-selection-list formControlName="customerCooperations">
                <mat-list-option
                  *ngFor="let coop of customerCooperations"
                  checkboxPosition="before"
                  [disableRipple]="true"
                  [value]="coop['@id']"
                  >{{ coop.name }}</mat-list-option
                >
              </mat-selection-list>

              <hr />

              <div class="mat-form-field checkbox-dense">
                <mat-checkbox formControlName="hasCooperationAgreement"
                  >Kooperationsvereinbarung vorhanden
                </mat-checkbox>
              </div>
              <div class="mat-form-field checkbox-dense">
                <mat-checkbox formControlName="hasADContract"
                  >AD-Vertrag vorhanden
                </mat-checkbox>
              </div>
              <div class="mat-form-field checkbox-dense">
                <mat-checkbox formControlName="hasNDA"
                  >NDA vorhanden
                </mat-checkbox>
              </div>
            </div>

            <div class="blocker"></div>
          </div>

          <div class="submit-grid grid">
            <div class="m-ta--2 column-14">
              <hr />

              <button
                class="btn--cancel m-r--8"
                [disabled]="pdf.invalid || !pdf.dirty"
                (click)="cancelEdit()"
                type="button"
                color="outline"
                mat-flat-button
              >
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button
                class="btn--submit"
                [disabled]="pdf.invalid || !pdf.dirty"
                mat-flat-button
                color="green"
                (click)="requestHandleUpdateCustomer()"
              >
                <mat-icon class="m-r--8">save</mat-icon>
                <span>Absenden</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class PartnerStatusFormComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelectionList) coopOptions: any;

  @Input() errors: ErrorsObject;
  @Input() customer: fromCustomersModuleModels.Customer;
  @Input() customerPartnerStatuses: Array<
    fromCustomersModuleModels.CustomerPartnerStatus
  >;
  @Input() customerCooperations: Array<fromMasterDataModuleModels.Product>;

  @Output() requestUpdateCustomer: EventEmitter<{
    iri: string;
    payload: fromCustomersModuleModels.Customer;
  }> = new EventEmitter();

  pdf: FormGroup;
  isActualPartner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.updateIsActualPartner(this.customer.partnerStatus);

    this.pdf.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(() => of(getDirtyValuesObject(this.pdf))),
        tap(changes => {
          Object.keys(changes).map(key => {
            if ('partnerStatus' === key) {
              this.updateIsActualPartner(changes[key]);
            }
          });
        })
      )
      .subscribe();
  }

  getIsActualPartner(value: string): boolean {
    return value === '/api/customer_partner_statuses/0';
  }

  updateIsActualPartner(partnerStatus: string): void {
    if (this.getIsActualPartner(partnerStatus)) {
      this.isActualPartner$.next(true);
    } else {
      this.isActualPartner$.next(false);
    }
  }

  initForm(): void {
    this.pdf = this.fb.group({
      partnerStatus: this.fb.control(this.customer.partnerStatus, [
        Validators.required
      ]),
      partnerCommissionPercentageAnalysis: this.fb.control(
        this.customer.partnerCommissionPercentageAnalysis,
        [Validators.requiredTrue, Validators.min(0), Validators.max(100)]
      ),
      partnerCommissionFixedAnalysis: this.fb.control(
        this.customer.partnerCommissionFixedAnalysis,
        [Validators.requiredTrue]
      ),
      partnerCommissionPercentageDatarecovery: this.fb.control(
        this.customer.partnerCommissionPercentageDatarecovery,
        [Validators.requiredTrue, Validators.min(0), Validators.max(100)]
      ),
      partnerCommissionFixedDatarecovery: this.fb.control(
        this.customer.partnerCommissionFixedDatarecovery,
        [Validators.requiredTrue]
      ),
      partnerCommissionPercentageOther: this.fb.control(
        this.customer.partnerCommissionPercentageOther,
        [Validators.requiredTrue, Validators.min(0), Validators.max(100)]
      ),
      partnerCommissionFixedOther: this.fb.control(
        this.customer.partnerCommissionFixedOther,
        [Validators.requiredTrue]
      ),
      hasCooperationAgreement: this.fb.control(
        this.customer.hasCooperationAgreement,
        [Validators.required]
      ),
      hasADContract: this.fb.control(this.customer.hasADContract, [
        Validators.required
      ]),
      hasNDA: this.fb.control(this.customer.hasNDA, [Validators.required]),
      customerCooperations: this.fb.control(this.customer.customerCooperations)
    });

    const controls: Array<AbstractControl> = Object.keys(this.pdf.controls).map(
      key => this.pdf.controls[key]
    );
    controls.forEach((control: AbstractControl) => control.clearValidators());
  }

  cancelEdit() {
    this.initForm();
  }

  requestHandleUpdateCustomer(): void {
    const values = getDirtyValuesObject(this.pdf);
    let payload: fromCustomersModuleModels.Customer = { ...values };

    // Cast some values to certain types to be consumed by API platform properly
    Object.keys(values).map((key: string) => {
      if (
        key == 'partnerCommissionPercentageAnalysis' ||
        key == 'partnerCommissionPercentageDatarecovery' ||
        key == 'partnerCommissionPercentageOther'
      ) {
        // @ts-ignore
        payload = {
          ...payload,
          [key]: parseInt(values[key])
        };
      }

      if (
        key == 'partnerCommissionFixedAnalysis' ||
        key == 'partnerCommissionFixedDatarecovery' ||
        key == 'partnerCommissionFixedOther'
      ) {
        payload = {
          ...payload,
          [key]: values[key].toString()
        };
      }
    });
    this.requestUpdateCustomer.emit({ iri: this.customer['@id'], payload });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
