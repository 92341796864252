import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import {AnalysisResultsActions} from '../actions';
import { NotifierService } from 'angular-notifier';
import { AnalysisResultsService } from '../../services/analysis-results.service';

@Injectable()
export class AnalysisResultsEffects {
  createAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.CreateAnalysisResult),
      switchMap(({ payload }) => {
        return this.service.createAnalysisResult(payload).pipe(
          map((response: any) =>
            AnalysisResultsActions.CreateAnalysisResultSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(AnalysisResultsActions.CreateAnalysisResultFail({ response }))
          )
        );
      })
    )
  );
  readAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.ReadAnalysisResult),
      switchMap(({ iri }) => {
        return this.service.readAnalysisResult(iri).pipe(
          map((response: any) =>
            AnalysisResultsActions.ReadAnalysisResultSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(AnalysisResultsActions.ReadAnalysisResultFail({ response }))
          )
        );
      })
    )
  );
  readAnalysisResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.ReadAnalysisResults),
      map(action => action),
      switchMap(({ page, params }) => {
        return this.service.readAnalysisResults(page, params).pipe(
          map((response: any) =>
            AnalysisResultsActions.ReadAnalysisResultsSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(AnalysisResultsActions.ReadAnalysisResultsFail({ response }))
          )
        );
      })
    )
  );

  readAnalysisResultsChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.ReadAnalysisResultsChunk),
      map(action => action),
      switchMap(({ uri }) => {
        return this.service.readAnalysisResultsChunk(uri).pipe(
          map(response =>
            AnalysisResultsActions.ReadAnalysisResultsChunkSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              AnalysisResultsActions.ReadAnalysisResultsChunkFail({
                response
              })
            )
          )
        );
      })
    )
  );
  updateAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.UpdateAnalysisResult),
      switchMap(({ iri, payload }) => {
        return this.service.updateAnalysisResult(iri, payload).pipe(
          map((response: any) =>
            AnalysisResultsActions.UpdateAnalysisResultSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(AnalysisResultsActions.UpdateAnalysisResultFail({ response }))
          )
        );
      })
    )
  );
  deleteAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultsActions.DeleteAnalysisResult),
      switchMap(({ iri }) => {
        return this.service.deleteAnalysisResult(iri).pipe(
          map(() =>
            AnalysisResultsActions.DeleteAnalysisResultSuccess({ iri })
          ),
          catchError((response: HttpErrorResponse) =>
            of(AnalysisResultsActions.DeleteAnalysisResultFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AnalysisResultsActions.DeleteAnalysisResultFail,
          AnalysisResultsActions.UpdateAnalysisResultFail,
          AnalysisResultsActions.ReadAnalysisResultsChunkFail,
          AnalysisResultsActions.ReadAnalysisResultsFail,
          AnalysisResultsActions.ReadAnalysisResultFail,
          AnalysisResultsActions.CreateAnalysisResultFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: AnalysisResultsService,
    private notifierService: NotifierService
  ) {}
}
