import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_ANALYSIS_PRIORITY_MODE = '[Master Data] Read Analysis Priority Mode';
export const READ_ANALYSIS_PRIORITY_MODE_SUCCESS = '[Master Data] Read AnalysisPriorityMode Success';
export const READ_ANALYSIS_PRIORITY_MODE_FAIL = '[Master Data] Read AnalysisPriorityMode Fail';
export const ReadAnalysisPriorityMode = createAction(
  READ_ANALYSIS_PRIORITY_MODE,
  props<{ iri: string }>()
);
export const ReadAnalysisPriorityModeSuccess = createAction(
  READ_ANALYSIS_PRIORITY_MODE_SUCCESS,
  props<{ response: fromModuleModels.AnalysisPriorityMode }>()
);
export const ReadAnalysisPriorityModeFail = createAction(
  READ_ANALYSIS_PRIORITY_MODE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_ANALYSIS_PRIORITY_MODES = '[Master Data] Read AnalysisPriorityModes';
export const READ_ANALYSIS_PRIORITY_MODES_SUCCESS = '[Master Data] Read AnalysisPriorityModes Success';
export const READ_ANALYSIS_PRIORITY_MODES_FAIL = '[Master Data] Read AnalysisPriorityModes Fail';
export const ReadAnalysisPriorityModes = createAction(
  READ_ANALYSIS_PRIORITY_MODES
);
export const ReadAnalysisPriorityModesSuccess = createAction(
  READ_ANALYSIS_PRIORITY_MODES_SUCCESS,
  props<{ response: any }>()
);
export const ReadAnalysisPriorityModesFail = createAction(
  READ_ANALYSIS_PRIORITY_MODES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
