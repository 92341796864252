import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CustomerReplacementStockItem,
  CustomerStockItem,
  DataMedium,
  ReplacementStockItem
} from '../../../warehouse/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../utilities/observable.utility';
import {
  CustomerReplacementStockItemsSelectors,
  CustomerStockItemsSelectors,
  DataMediumsSelectors
} from '../../../warehouse/store/selectors';
import {
  ProductsSelectors,
  StorageLocationsSelectors
} from '../../../master-data/store/selectors';
import {
  ProductsActions,
  StorageLocationsActions
} from '../../../master-data/store';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ProductOrderItemsSelectors } from '../../../orders/store/selectors';
import { extractIri, extractUUID } from '../../utilities/objects.utility';
import { getUuidFromIri } from '../../utilities/strings.utility';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  DataMediumsActions,
  ReplacementStockItemsActions
} from '../../../warehouse/store';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ProductOrderItemsActions } from '../../../orders/store';

@Component({
  selector: 'app-order-stock-item-select-list',
  styleUrls: ['./order-stock-item-select-list.component.scss'],
  template: `
    <div class="pos-relative">
      <!--      <pre>{{ replacementDataMedia | json }}</pre>-->
      <!--      <pre>{{ customerStockItems | json }}</pre>-->

      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <!--      <pre>app-order-stock-item-select-list: {{inputSelectedDataMedia|json}}</pre>-->
      <ng-container
        *ngIf="
          (isLoading$ | async) === false &&
          (noStockItemsAvailable ||
            (hideCustomerStockItems &&
              hideCustomerReplacementStockItems &&
              hideReplacementStockItems))
        "
      >
        <div class="alert alert-info">
          Es sind keine Datenträger oder Ersatzdatenträger verfügbar
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !hideCustomerStockItems &&
          customerStockItems &&
          customerStockItems.length > 0
        "
      >
        <div class="row">
          <div class="col"><h1>Datenträger/Gehäuse</h1></div>
          <div class="col text-right">
            <mat-slide-toggle
              class="d-inline-block"
              [(ngModel)]="hideCustomerStockItemsWithoutDataMedia"
              >Nur Einträge mit Datenträgern anzeigen
            </mat-slide-toggle>
          </div>
        </div>
        <app-stock-item-display-line
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          *ngFor="let stockItem of filteredCustomerStockItems"
          [filterDataMediaFn]="dataMediaFilterFn"
          [filterDataMedia]="inputFilteredDataMedia"
          [hideToOrderBtn]="true"
          [inputSelectedDataMedia]="inputSelectedDataMedia"
          [readonlyArchive]="readonlyArchive"
          [readonly]="readonly"
          [stockItem]="stockItem"
          [validDataMediaStates]="validDataMediaStates"
          selectMode="dataMedium"
        ></app-stock-item-display-line>
        <div
          class="alert alert-info"
          *ngIf="filteredCustomerStockItems.length <= 0"
        >
          Leider sind keine Datenträger verfügbar.
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !hideCustomerReplacementStockItems &&
          customerReplacementStockItems &&
          customerReplacementStockItems.length > 0
        "
      >
        <div class="row">
          <div class="col"><h1>Ersatzdatenträger/-gehäuse vom Kunden</h1></div>
          <div class="col text-right">
            <mat-slide-toggle
              class="d-inline-block"
              [(ngModel)]="hideCustomerReplacementStockItemsWithoutDataMedia"
              >Nur Einträge mit Datenträgern anzeigen
            </mat-slide-toggle>
          </div>
        </div>

        <app-stock-item-display-line
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          *ngFor="let stockItem of filteredCustomerReplacementStockItems"
          [filterDataMediaFn]="dataMediaFilterFn"
          [filterDataMedia]="inputFilteredDataMedia"
          [hideToOrderBtn]="true"
          [inputSelectedDataMedia]="inputSelectedDataMedia"
          [readonlyArchive]="readonlyArchive"
          [readonly]="readonly"
          [stockItem]="stockItem"
          [validDataMediaStates]="validDataMediaStates"
          selectMode="dataMedium"
        ></app-stock-item-display-line>
        <div
          class="alert alert-info"
          *ngIf="filteredCustomerReplacementStockItems.length <= 0"
        >
          Leider sind keine Ersatzdatenträger verfügbar.
        </div>
      </ng-container>
      <!--      <pre>{{customerReplacementStockItems|json}}</pre>-->

      <ng-container
        *ngIf="
          !hideReplacementStockItems &&
          replacementStockItems &&
          replacementStockItems.length > 0
        "
      >
        <h1>Ersatzdatenträger</h1>
        <app-stock-item-display-line
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          *ngFor="let stockItem of replacementStockItems"
          [filterDataMediaFn]="dataMediaFilterFn"
          [filterDataMedia]="filteredReplacementDataMedia"
          [hideToOrderBtn]="true"
          [inputSelectedDataMedia]="inputSelectedDataMedia"
          [readonlyArchive]="readonlyArchive"
          [readonly]="readonly"
          [stockItem]="stockItem"
          [validDataMediaStates]="validDataMediaStates"
          selectMode="dataMedium"
        ></app-stock-item-display-line>
      </ng-container>

      <!--      <pre>{{replacementStockItems|json}}</pre>-->
    </div>
  `
})
export class OrderStockItemSelectListComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() disableAutoLoad = false;
  @Input() @Optional() orderIri: string = null;
  @Input() @Optional() readonly = false;
  @Input() @Optional() readonlyArchive = false;
  @Input() @Optional() dataMediaSelectionFilterFn: (
    dataMedium: DataMedium
  ) => boolean;

  @Input() @Optional() dataMediaFilterFn: (dataMedium: DataMedium) => boolean;
  @Input() @Optional() inputSelectedDataMedia: Array<string> = [];
  @Input() @Optional() validDataMediaStates: Array<string> = [];
  @Input() @Optional() inputFilteredDataMedia: Array<string> = null;
  @Input() @Optional() customerReplacementStockItems: Array<
    CustomerReplacementStockItem
  > = [];
  @Input() @Optional() customerStockItems: Array<CustomerStockItem> = [];
  @Input() @Optional() replacementStockItems: Array<ReplacementStockItem> = [];

  @Output() updateSelectedDataMedia: EventEmitter<
    Array<string>
  > = new EventEmitter<Array<string>>();
  @Output() changeSelectedDataMedia: EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }> = new EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }>();
  order: Order;
  hideCustomerStockItemsWithoutDataMedia = true;
  hideCustomerReplacementStockItemsWithoutDataMedia = true;

  form: FormGroup;

  isLoading$: Observable<boolean>;
  loadingStockItems: Array<string> = [];
  replacementDataMedia: Array<string> = [];

  selectedDataMedia: Array<string> = [];

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public actions$: Actions
  ) {
    super();
  }

  get noStockItemsAvailable(): boolean {
    if (
      !this.customerStockItems &&
      !this.customerReplacementStockItems &&
      !this.replacementStockItems
    ) {
      return false;
    } else if (
      this.customerStockItems.length <= 0 &&
      this.customerReplacementStockItems.length <= 0 &&
      this.replacementStockItems.length <= 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  get filteredReplacementDataMedia(): Array<string> {
    if (this.inputFilteredDataMedia && this.inputFilteredDataMedia.length > 0) {
      return this.replacementDataMedia.filter(
        e => this.inputFilteredDataMedia.indexOf(extractIri(e)) > -1
      );
    } else if (this.disableAutoLoad) {
      return null;
    } else {
      return this.replacementDataMedia;
    }
  }

  get hideCustomerStockItems(): boolean {
    if (!this.customerStockItems) {
      return false;
    }
    if (!this.inputFilteredDataMedia) {
      return false;
    }
    return this.filteredCustomerStockItems.every(stockItem =>
      this.stockItemHasNoFilteredDataMedia(stockItem)
    );
  }

  get hideCustomerReplacementStockItems(): boolean {
    if (!this.customerStockItems) {
      return false;
    }
    if (!this.inputFilteredDataMedia) {
      return false;
    }

    return this.filteredCustomerReplacementStockItems.every(stockItem =>
      this.stockItemHasNoFilteredDataMedia(stockItem)
    );
  }

  get hideReplacementStockItems(): boolean {
    if (!this.replacementStockItems) {
      return false;
    }
    if (!this.inputFilteredDataMedia) {
      return false;
    }

    return this.replacementStockItems.every(stockItem =>
      this.stockItemHasNoFilteredDataMedia(stockItem)
    );
  }

  get filteredCustomerStockItems(): Array<CustomerStockItem> {
    if (this.hideCustomerStockItemsWithoutDataMedia === false) {
      return this.customerStockItems;
    } else {
      return this.customerStockItems.filter(
        e => e.dataMediums.length > 0 || !!e.enclosure
      );
    }
  }

  get filteredCustomerReplacementStockItems(): Array<
    CustomerReplacementStockItem
  > {
    if (this.hideCustomerReplacementStockItemsWithoutDataMedia === false) {
      return this.customerReplacementStockItems;
    } else {
      return this.customerReplacementStockItems.filter(
        e => e.dataMediums.length > 0 || !!e.enclosure
      );
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      dataMedia: this.fb.control([], Validators.required)
    });
    this.form.get('dataMedia').valueChanges.subscribe(list => {
      console.log('dataMedia.valueChanges', list);
      this.updateSelectedDataMedia.emit(list);
    });

    this.isLoading$ = isLoadingArray([
      this.store$.select(DataMediumsSelectors.isLoading),
      this.store$.select(CustomerReplacementStockItemsSelectors.isLoading),
      this.store$.select(CustomerStockItemsSelectors.isLoading),
      this.store$.select(ProductOrderItemsSelectors.isLoading)
    ]);

    loadIfNotLoaded(
      this.store$,
      ProductsSelectors.isLoaded,
      ProductsActions.ReadProducts()
    );
    loadIfNotLoaded(
      this.store$,
      StorageLocationsSelectors.isLoaded,
      StorageLocationsActions.ReadStorageLocations()
    );
    this.actions$
      .pipe(
        ofType(
          CustomerStockItemsActions.UpdateCustomerStockItemSuccess,
          CustomerStockItemsActions.CreateCustomerStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        const index = this.customerStockItems.findIndex(
          e => extractIri(e) === iri
        );
        this.customerStockItems.splice(index, 1);
        this.loadStockItemsFromDataMediums([{ stockItem: iri }]);
      });
    this.actions$
      .pipe(
        ofType(
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess,
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        const index = this.customerReplacementStockItems.findIndex(
          e => extractIri(e) === iri
        );
        this.customerReplacementStockItems.splice(index, 1);
        this.loadStockItemsFromDataMediums([{ stockItem: iri }]);
      });

    if (this.disableAutoLoad) {
      console.log('LoadNothing');
    } else if (this.orderIri) {
      this.loadDataForOrder(this.orderIri);
    } else if (this.order$) {
      this.order$
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e),
          distinctUntilChanged()
        )
        .subscribe(order => {
          this.order = order;
        });
    } else if (this.activatedRoute.snapshot.paramMap.get('uuid')) {
      this.activatedRoute.paramMap
        .pipe(
          filter(e => !!e && !!e.get('uuid')),
          takeUntil(this.onDestroy$)
        )
        .subscribe(params => {
          const iri = '/api/orders/' + params.get('uuid');
          this.loadDataForOrder(iri);
        });
    }
  }

  stockItemHasNoFilteredDataMedia(
    stockItem:
      | CustomerStockItem
      | CustomerReplacementStockItem
      | ReplacementStockItem
  ): boolean {
    return !stockItem.dataMediums.some(e => {
      const dataMediumIri = extractIri(e);
      return (
        (this.inputFilteredDataMedia &&
          this.inputFilteredDataMedia.length > 0 &&
          this.inputFilteredDataMedia.indexOf(dataMediumIri) > -1) ||
        (this.dataMediaFilterFn && this.dataMediaFilterFn(e))
      );
    });
  }

  loadStockItemsFromDataMediums(entities): void {
    // console.log(entities);

    this.actions$
      .pipe(
        ofType(CustomerStockItemsActions.ReadCustomerStockItemSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.customerStockItems.push(response);
        }
      });
    this.actions$
      .pipe(
        ofType(
          CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.customerReplacementStockItems.push(response);
        }
      });
    this.actions$
      .pipe(
        ofType(ReplacementStockItemsActions.ReadReplacementStockItemSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.replacementStockItems.push(response);
        }
      });
    const customerReplacementStockItemIris: { [iri: string]: true } = {};
    const replacementStockItemIris: { [iri: string]: true } = {};
    const customerStockItemsIris: { [iri: string]: true } = {};
    entities.forEach(e => {
      const iri = extractIri(e.stockItem);
      if (iri.indexOf('/customer_stock_items') > -1) {
        customerStockItemsIris[iri] = true;
      } else if (iri.indexOf('/customer_replacement_stock_items') > -1) {
        customerReplacementStockItemIris[iri] = true;
      } else if (iri.indexOf('/replacement_stock_items') > -1) {
        replacementStockItemIris[iri] = true;
      } else {
        console.log('FEHLER:', e);
      }
    });
    for (const iri of Object.keys(customerStockItemsIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.customerStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          CustomerStockItemsActions.ReadCustomerStockItem({ iri })
        );
      }
    }
    for (const iri of Object.keys(customerReplacementStockItemIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.customerReplacementStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem(
            { iri }
          )
        );
      }
    }
    for (const iri of Object.keys(replacementStockItemIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.replacementStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          ReplacementStockItemsActions.ReadReplacementStockItem({ iri })
        );
      }
    }
  }

  //
  setSelectedDataMedia($event: {
    selected: Array<string>;
    unselected: Array<string>;
  }): void {
    this.changeSelectedDataMedia.emit($event);
    const formField = this.form.get('dataMedia');
    const value = [...(formField.value as Array<string>)];
    for (const unselectIri of $event.unselected) {
      if (value.indexOf(unselectIri) > -1) {
        value.splice(value.indexOf(unselectIri), 1);
      }
    }
    for (const selectedIri of $event.selected) {
      if (value.indexOf(selectedIri) <= -1) {
        value.push(selectedIri);
      }
    }
    this.form.get('dataMedia').setValue(value);
  }

  //              this.store$.dispatch(CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem({iri}));

  private loadCustomerDataMediums(orderIri): void {
    const orderUUID = getUuidFromIri(orderIri);
    this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, { orderIri })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e.length > 0),
        distinctUntilChanged()
      )
      .subscribe(entities => {
        this.loadStockItemsFromDataMediums(entities);
      });

    this.store$.dispatch(
      DataMediumsActions.ReadDataMediums({
        page: -1,
        params: { 'stockItem.order.uuid': orderUUID }
      })
    );
  }

  private loadDataForOrder(iri: string): void {
    // console.log('loadDataForOrder', iri);
    if (this.disableAutoLoad === true) {
      return;
    }
    this.loadCustomerDataMediums(iri);
    this.store$
      .select(ProductOrderItemsSelectors.selectProductOrderItemsForOrder, {
        iri
      })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e?.length > 0)
      )
      .subscribe(items => {
        // console.log('selectProductOrderItemsForOrder', items);
        const dataMediaList = items.map(e => e.replacementDataMedium);
        console.log(dataMediaList);
        this.replacementDataMedia.push(...dataMediaList.map(extractIri));
        this.loadStockItemsFromDataMediums(dataMediaList);
      });
    this.store$.dispatch(
      ProductOrderItemsActions.ReadProductOrderItems({
        orderUuid: extractUUID(this.order)
      })
    );
  }
}
