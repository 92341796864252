import {createSelector} from '@ngrx/store';

import {OFFERS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectOffersState = createSelector(
  selectInvoicesModuleState,
  (state) => state[OFFERS_FEATURE_KEY]
);

export const selectOffersEntities = createSelector(
  selectOffersState,
  (state) => state.entities
);

export const selectOffers = createSelector(
  selectOffersEntities,
  (customers) => Object.keys(customers).map(iri => customers[iri])
);

export const selectOfferByIndex = createSelector(
  selectOffersEntities,
  (customers, props) => customers[props.iri]
);

export const selectCurrentOffer = createSelector(
  selectOffersState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectOffersState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectOffersEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectOffersState,
  state => state.totalItems
);
export const selectOfferPagination = createSelector(
  selectOffersState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectOfferPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectOfferPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectOfferPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectOfferPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectOfferPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectOffersState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectOffersState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectOfferErrors = createSelector(
  selectOffersState,
  (state) => state.errors
);
