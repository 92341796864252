import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../models';

@Component({
  selector: 'app-order-tracking-details',
  styleUrls: ['./order-tracking-details.component.scss'],
  template: `
    <dl class="grid">
      <dt class="column-4">UTM-Source</dt>
      <dd class="column-10">{{ order?.UTMSource ? order.UTMSource : '-' }}</dd>

      <dt class="column-4">UTM-Medium</dt>
      <dd class="column-10">{{ order?.UTMMedium ? order.UTMMedium : '-' }}</dd>

      <dt class="column-4">UTM-Campaign</dt>
      <dd class="column-10">{{ order?.UTMCampaign ? order.UTMCampaign : '-' }}</dd>

      <dt class="column-4">UTM-Content</dt>
      <dd class="column-10">{{ order?.UTMContent ? order.UTMContent : '-' }}</dd>

      <dt class="column-4">UTM-Term</dt>
      <dd class="column-10">{{ order?.UTMTerm ? order.UTMTerm : '-' }}</dd>

      <dt class="column-4">City</dt>
      <dd class="column-10">{{ order?.UTMCity ? order.UTMCity : '-' }}</dd>

      <dt class="column-4">Entry-URL</dt>
      <dd class="column-10">{{ order?.UTMEntryUrl ? order.UTMEntryUrl : '-' }}</dd>

      <dt class="column-4">Submitted from URL</dt>
      <dd class="column-10">{{ order?.UTMSubmittedFromUrl ? order.UTMSubmittedFromUrl : '-' }}</dd>

      <dt class="column-4">Used Form</dt>
      <dd class="column-10">{{ order?.UTMUsedForm ? order.UTMUsedForm : '-' }}</dd>
    </dl>
  `

})
export class OrderTrackingDetailsComponent implements OnInit {

  @Input()
  order: Order;

  constructor() {
  }

  ngOnInit(): void {
  }

}
