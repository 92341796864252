import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {OrderDataMediumsSelectors} from '../store/selectors';
import {OrderDataMediumsActions} from '../store';
import {StringsUtility} from '../../shared/utilities/strings.utility';

@Injectable()
export class OrderDataMediumsLoadedGuard implements CanActivate {

  constructor(private store$: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(true);

  }

}
