import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {FollowUpsService} from '../../services';
import {FollowUpActions} from '../actions';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class FollowUpsEffects {

  constructor(private actions$: Actions, private backendService: FollowUpsService) {
  }

  createFollowUp$ = createEffect(() => this.actions$.pipe(
    ofType(FollowUpActions.CreateFollowUp),
    map(action => action),
    switchMap(({payload}) => {

      return this.backendService
        .createFollowUp(payload)
        .pipe(
          map(response => FollowUpActions.CreateFollowUpSuccess({response})),
          catchError(response => of(FollowUpActions.CreateFollowUpFail({response})))
        );
    })
  ));


  loadFollowUps$ = createEffect(() => this.actions$.pipe(
    ofType(FollowUpActions.LoadFollowUps),
    map(action => action),
    switchMap(({fromDate, toDate}) => {

      return this.backendService
        .loadFollowUps(fromDate, toDate)
        .pipe(
          map(response => FollowUpActions.LoadFollowUpsSuccess({response})),
          catchError(response => of(FollowUpActions.LoadFollowUpsFail({response})))
        );
    })
  ));

  loadFollowUp$ = createEffect(() => this.actions$.pipe(
    ofType(FollowUpActions.LoadFollowUp),
    switchMap(({iri}) => {
      return this.backendService
        .loadFollowUp(iri)
        .pipe(
          map((response: any) => FollowUpActions.LoadFollowUpSuccess({response})),
          catchError((response: HttpErrorResponse) => of(FollowUpActions.LoadFollowUpFail({response})))
        );
    })
  ));


  updateFollowUp$ = createEffect(() => this.actions$.pipe(
    ofType(FollowUpActions.UpdateFollowUp),
    map(action => action),
    switchMap(({iri, payload}) => {

      return this.backendService
        .updateFollowUp(iri, payload)
        .pipe(
          map(response => FollowUpActions.UpdateFollowUpSuccess({response})),
          catchError(response => of(FollowUpActions.UpdateFollowUpFail({response})))
        );
    })
  ));


  deleteFollowUp$ = createEffect(() => this.actions$.pipe(
    ofType(FollowUpActions.DeleteFollowUp),
    map(action => action),
    switchMap(({iri}) => {

      return this.backendService
        .deleteFollowUp(iri)
        .pipe(
          map(() => FollowUpActions.DeleteFollowUpSuccess({iri})),
          catchError(response => of(FollowUpActions.DeleteFollowUpFail({response})))
        );
    })
  ));

}
