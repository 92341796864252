import {Component, EventEmitter, Input, Output} from '@angular/core';

import {RouterActions} from '../../../application-state/store/actions';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {CommissionCredit} from '../../models';
import {extractUUID} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-commission-credits-list',
  styleUrls: ['commission-credits-list.component.scss'],
  template: `

    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >

      <!-- Dot Column -->
      <ng-container matColumnDef="dot" style="width: 20px;">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>

          <span class="circle" [class.green]="!!element?.payoutAt"></span>
        </td>
      </ng-container>

      <!-- Number Column -->
      <ng-container matColumnDef="commissionCreditNumber">
        <th mat-header-cell *matHeaderCellDef>Nr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>
          {{ element.commissionCreditNumber }}</td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>{{ getStateLabel(element.state) }}
        </td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Empfänger [Zusatz]</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>{{ element.recipient.nameLine1 }}

          <ng-container *ngIf="element.recipient.nameLine2">
            [{{ element.recipient.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>

      <!-- Issuer Column -->
      <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef>Bereich</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>{{ element.issuer.name }}</td>
      </ng-container>

      <!-- Net Total Column -->
      <ng-container matColumnDef="netTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (netto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>
          <ng-container *ngIf="!!element.netTotal.length">
            {{ element.netTotal.value | number:'1.2-2' }} {{ element.netTotal.currency|currencyShortener }}
          </ng-container>
          <ng-container *ngIf="!!!element.netTotal.length">-</ng-container>
        </td>
      </ng-container>

      <!-- Gross Total Column -->
      <ng-container matColumnDef="grossTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (brutto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>

          <ng-container *ngIf="!!element.grossTotal.length">
            {{ element.grossTotal.value | number:'1.2-2' }} {{ element.grossTotal.currency|currencyShortener }}
          </ng-container>
          <ng-container *ngIf="!!!element.grossTotal.length">-</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="payoutAt">
        <th mat-header-cell *matHeaderCellDef>Auszahlung am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'commission-credits', extractUUID(element)]"></a>
          <ng-container>{{ element.payoutAt | momentDate }}</ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.green]="!!element?.payoutAt"></tr>
    </table>
  `
})
export class CommissionCreditsListComponent {

  @Input() items: Array<CommissionCredit>;
  @Input() totalItems: number;

  @Output() requestGoToCommissionCreditView: EventEmitter<string> = new EventEmitter();

  displayedColumns: Array<string> = ['dot', 'commissionCreditNumber', 'state', 'recipient', 'issuer', 'netTotal', 'grossTotal', 'payoutAt'];

  constructor(private store$: Store<ApplicationState>) {
  }

  getStateLabel(state: string): string {
    return {
      booked: 'Gebucht',
      draft: 'Entwurf',
      paid_out: 'Ausgezahlt',
    }[state];
  }

  handleClick(invoice: CommissionCredit): void {
    // TODO use UUID not iri
    // this.store$.dispatch(RouterActions.Go({path: ['invoices', 'commission-credits', getUuidFromIri(invoice['@id'])]}));
    this.store$.dispatch(RouterActions.Go({path: ['invoices', 'commission-credits', invoice['@id']]}));
  }

  protected readonly extractUUID = extractUUID;
}
