import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';
import { InvoicePayment } from '../models';

@Injectable()
export class InvoicePaymentsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createInvoicePayment(payload: InvoicePayment): Observable<InvoicePayment> {
    return this.rolesService.userHasRoleFilter<InvoicePayment>(
      'ROLE_MWS_INVOICE_PAYMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/invoice_payments`, payload)
    );
  }

  readInvoicePayment(iri: string): Observable<InvoicePayment> {
    return this.rolesService.userHasRoleFilter<InvoicePayment>(
      'ROLE_MWS_INVOICE_PAYMENT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readInvoicePayments(
    page = 1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_PAYMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/invoice_payments', page, params)
            )
    );
  }

  updateInvoicePayment(
    iri: string,
    payload: InvoicePayment
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_PAYMENT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteInvoicePayment(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_PAYMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
