import {EMAIL_TEMPLATE_CONTEXTS, selectMasterDataModuleState} from '../reducers';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {createSelector} from '@ngrx/store';
import {EmailTemplateContext} from '../../models';

const NAMESPACE = EMAIL_TEMPLATE_CONTEXTS;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sList,
  sById,
  sByIri
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

export const sListSortedByName = createSelector(
  sList,
  (entities: EmailTemplateContext[]) => entities.sort((a, b) => a.name.localeCompare(b.name)));
