import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { Subject } from 'rxjs';

@Component({
  selector: 'paginator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-paginator
      [pageIndex]="pageIndex - 1"
      class="rounded-bottom-2"
      [length]="totalItems"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      (page)="handleRequestPaginationChange($event)"
    >
    </mat-paginator>
  `
})
export class PaginatorComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() totalItems: number;
  @Input() pageSize = 30;
  @Input() pageIndex = 1;
  @Input() pageSizeOptions: Array<number>;

  @Output() requestChangePaginationPage: EventEmitter<
    'next' | 'previous' | 'first'
  > = new EventEmitter();
  @Output() requestChangePaginationChunkSize: EventEmitter<
    number
  > = new EventEmitter<number>();

  onDestroy$: Subject<any> = new Subject<any>();
  previousPageSize: number;

  handleRequestPaginationChange(event: PageEvent): void {
    if (this.previousPageSize === event.pageSize) {
      const direction =
        event.pageIndex > event.previousPageIndex
          ? 'next'
          : event.pageIndex === 0
          ? 'first'
          : 'previous';
      this.requestChangePaginationPage.emit(direction);
    } else {
      this.requestChangePaginationChunkSize.emit(event.pageSize);
      this.paginator.firstPage();
    }
    this.previousPageSize = event.pageSize;
  }

  ngAfterViewInit(): void {
    this.previousPageSize = this.pageSize;
  }
}
