import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { DamagesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { DamagesService } from '../../services/damages.service';

@Injectable()
export class DamagesEffects {
  CreateDamage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamagesActions.CreateDamage),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createDamage(payload).pipe(
          map(response => DamagesActions.CreateDamageSuccess({ response })),
          catchError(response =>
            of(DamagesActions.CreateDamageFail({ response }))
          )
        );
      })
    )
  );
  ReadDamage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamagesActions.ReadDamage),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readDamage(iri).pipe(
          map(response => DamagesActions.ReadDamageSuccess({ response })),
          catchError(response =>
            of(DamagesActions.ReadDamageFail({ response }))
          )
        );
      })
    )
  );
  ReadDamages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamagesActions.ReadDamages),
      map(action => action),
      switchMap(() => {
        return this.service.readDamages().pipe(
          map(response => DamagesActions.ReadDamagesSuccess({ response })),
          catchError(response =>
            of(DamagesActions.ReadDamagesFail({ response }))
          )
        );
      })
    )
  );
  UpdateDamage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamagesActions.UpdateDamage),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.updateDamage(iri, payload).pipe(
          map(response => DamagesActions.UpdateDamageSuccess({ response })),
          catchError(response =>
            of(DamagesActions.UpdateDamageFail({ response }))
          )
        );
      })
    )
  );
  DeleteDamage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamagesActions.DeleteDamage),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteDamage(iri).pipe(
          map(() => DamagesActions.DeleteDamageSuccess({ iri })),
          catchError(response =>
            of(DamagesActions.DeleteDamageFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DamagesActions.DeleteDamageFail,
          DamagesActions.UpdateDamageFail,
          DamagesActions.ReadDamagesFail,
          DamagesActions.ReadDamageFail,
          DamagesActions.CreateDamageFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  DamageActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DamagesActions.CreateDamageSuccess,
        DamagesActions.UpdateDamageSuccess
      ),
      map(({ type }) => {
        const text =
          type === DamagesActions.CREATE_DAMAGE_SUCCESS
            ? 'Eine neue Schadensart wurde angelegt.'
            : 'Die Schadensart wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: DamagesService,
    private notifierService: NotifierService
  ) {}
}
