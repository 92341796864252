import {NgModule} from '@angular/core';
import {ClipboardModule} from '@angular/cdk/clipboard';


import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';
import * as fromModuleGuards from './guards';
import * as fromModuleServices from './services';
import {SharedModule} from '../shared/shared.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CustomerSelectComponent} from './components/customer-select/customer-select.component';
import {FormsModule} from '@angular/forms';
import {
  SimilarCustomerDisplayComponent
} from './components/similar-customer-display/similar-customer-display.component';
import {TranslateModule} from "@ngx-translate/core";
import {NgxMatIntlTelInputModule} from "ngx-11-mat-intl-tel-input";

@NgModule({
  declarations: [
    ...fromModuleContainers.AllContainers,
    ...fromModuleComponents.AllComponents,
    CustomerSelectComponent,
    SimilarCustomerDisplayComponent,
  ],
  imports: [
    SharedModule,
    ClipboardModule,
    MatButtonToggleModule,
    FormsModule,
    TranslateModule,
    NgxMatIntlTelInputModule
  ],
  exports: [
    ...fromModuleContainers.AllContainers,
    ...fromModuleComponents.AllComponents,
    CustomerSelectComponent,
    SimilarCustomerDisplayComponent,
  ],
  providers: [
    ...fromModuleServices.AllServices,
    ...fromModuleGuards.AllGuards
  ]
})
export class CustomersModule {
}
