import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {CorrectionInvoice, InvoiceMail, Offer} from "../../models";
import {InvoicePdfResponse} from "../../models/invoice-pdf-Response";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_OFFER = '[Invoices Module] Create Offer';
export const CREATE_OFFER_FAIL = '[Invoices Module] Create Offer Fail';
export const CREATE_OFFER_SUCCESS = '[Invoices Module] Create Offer Success';

export const CreateOffer = createAction(
  CREATE_OFFER,
  props<{ payload: any }>()
);

export const CreateOfferSuccess = createAction(
  CREATE_OFFER_SUCCESS,
  props<{ response: any }>()
);

export const CreateOfferFail = createAction(
  CREATE_OFFER_FAIL,
  props<{ response: any }>()
);

///////////////////////////////////////////////////////////////////// Read PDF

export const READ_OFFER_PDF = '[Invoices Module] Offer as pdf';
export const READ_OFFER_PDF_SUCCESS = '[Invoices Module] Offer as pdf Success';
export const READ_OFFER_PDF_FAIL = '[Invoices Module] Offer as pdf Fail';

export const ReadOfferPDF = createAction(
  READ_OFFER_PDF,
  props<{ iri?: string }>()
);

export const ReadOfferPDFSuccess = createAction(
  READ_OFFER_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadOfferPDFFail = createAction(
  READ_OFFER_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read readAcceptedOfferAsPdf PDF

export const READ_ACCEPTED_OFFER_PDF = '[Invoices Module] Accepted Offer as pdf';
export const READ_ACCEPTED_OFFER_PDF_SUCCESS = '[Invoices Module] Accepted Offer as pdf Success';
export const READ_ACCEPTED_OFFER_PDF_FAIL = '[Invoices Module] Accepted Offer as pdf Fail';

export const ReadAcceptedOfferPDF = createAction(
  READ_ACCEPTED_OFFER_PDF,
  props<{ iri?: string, fileName: string }>()
);

export const ReadAcceptedOfferPDFSuccess = createAction(
  READ_ACCEPTED_OFFER_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadAcceptedOfferPDFFail = createAction(
  READ_ACCEPTED_OFFER_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_OFFER = '[Invoices Module] Read AcceptedOffer';
export const READ_OFFER_SUCCESS = '[Invoices Module] Read Offer Success';
export const READ_OFFER_FAIL = '[Invoices Module] Read Offer Fail';

export const ReadOffer = createAction(
  READ_OFFER,
  props<{ iri: string }>()
);

export const ReadOfferSuccess = createAction(
  READ_OFFER_SUCCESS,
  props<{ response: fromModuleModels.Offer | any }>()
);

export const ReadOfferFail = createAction(
  READ_OFFER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_OFFERS = '[Invoices Module] Read Offers';
export const READ_OFFERS_SUCCESS = '[Invoices Module] Read Offers Success';
export const READ_OFFERS_FAIL = '[Invoices Module] Read Offers Fail';

export const ReadOffers = createAction(
  READ_OFFERS,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadOffersSuccess = createAction(
  READ_OFFERS_SUCCESS,
  props<{ response: any }>()
);

export const ReadOffersFail = createAction(
  READ_OFFERS_FAIL,
  props<{ response: any }>()
);

export const READ_OFFER_CHUNK = '[Invoices Module] Read Offer Chunk';
export const READ_OFFER_CHUNK_SUCCESS = '[Invoices Module] Read Offer Chunk Success';
export const READ_OFFER_CHUNK_FAIL = '[Invoices Module] Read Offer Chunk Fail';

export const ReadOfferChunk = createAction(
  READ_OFFER_CHUNK,
  props<{ uri: string }>()
);

export const ReadOfferChunkSuccess = createAction(
  READ_OFFER_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadOfferChunkFail = createAction(
  READ_OFFER_CHUNK_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// SendMail

export const SEND_MAIL = '[Invoices Module] Send Mail Offer ';
export const SEND_MAIL_SUCCESS = '[Invoices Module] Send Mail Offer Success';
export const SEND_MAIL_FAIL = '[Invoices Module] Send Mail Offer Fail';

export const SendMail = createAction(
  SEND_MAIL,
  props<{ iri: string, payload: InvoiceMail | any }>()
);

export const SendMailSuccess = createAction(
  SEND_MAIL_SUCCESS,
  props<{ response: Offer }>()
);

export const SendMailFail = createAction(
  SEND_MAIL_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_OFFER = '[Invoices Module] Update Offer';
export const UPDATE_OFFER_SUCCESS = '[Invoices Module] Update Offer Success';
export const UPDATE_OFFER_FAIL = '[Invoices Module] Update Offer Fail';

export const UpdateOffer = createAction(
  UPDATE_OFFER,
  props<{ iri: string, payload: fromModuleModels.Offer | any }>()
);

export const UpdateOfferSuccess = createAction(
  UPDATE_OFFER_SUCCESS,
  props<{ response: fromModuleModels.Offer }>()
);

export const UpdateOfferFail = createAction(
  UPDATE_OFFER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_OFFER = '[Invoices Module] Delete Offer';
export const DELETE_OFFER_SUCCESS = '[Invoices Module] Delete Offer Success';
export const DELETE_OFFER_FAIL = '[Invoices Module] Delete Offer Fail';

export const DeleteOffer = createAction(
  DELETE_OFFER,
  props<{ iri: string }>()
);

export const DeleteOfferSuccess = createAction(
  DELETE_OFFER_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteOfferFail = createAction(
  DELETE_OFFER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_OFFER = '[Invoices Module] Reset Current Offer';

export const ResetCurrentOffer = createAction(
  RESET_CURRENT_OFFER
);

export const RESET_OFFERS_LOADED = '[Invoices Module] Reset Offers Loaded';

export const ResetOffersLoaded = createAction(
  RESET_OFFERS_LOADED
);
