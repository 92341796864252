import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_DAMAGE = '[Master Data Module] Create Damage';
export const CREATE_DAMAGE_SUCCESS = '[Master Data Module] Create Damage Success';
export const CREATE_DAMAGE_FAIL = '[Master Data Module] Create Damage Fail';
export const CreateDamage = createAction(
  CREATE_DAMAGE,
  props<{ payload: fromModuleModels.Damage }>()
);
export const CreateDamageSuccess = createAction(
  CREATE_DAMAGE_SUCCESS,
  props<{ response: fromModuleModels.Damage }>()
);
export const CreateDamageFail = createAction(
  CREATE_DAMAGE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DAMAGES = '[Master Data Module] Read Damages';
export const READ_DAMAGES_SUCCESS = '[Master Data Module] Read Damages Success';
export const READ_DAMAGES_FAIL = '[Master Data Module] Read Damages Fail';
export const ReadDamages = createAction(
  READ_DAMAGES
);
export const ReadDamagesSuccess = createAction(
  READ_DAMAGES_SUCCESS,
  props<{ response: any }>()
);
export const ReadDamagesFail = createAction(
  READ_DAMAGES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DAMAGE = '[Master Data Module] Read Damage';
export const READ_DAMAGE_SUCCESS = '[Master Data Module] Read Damage Success';
export const READ_DAMAGE_FAIL = '[Master Data Module] Read Damage Fail';
export const ReadDamage = createAction(
  READ_DAMAGE,
  props<{ iri: string }>()
);
export const ReadDamageSuccess = createAction(
  READ_DAMAGE_SUCCESS,
  props<{ response }>()
);
export const ReadDamageFail = createAction(
  READ_DAMAGE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_DAMAGE = '[Master Data Module] Update Damage';
export const UPDATE_DAMAGE_SUCCESS = '[Master Data Module] Update Damage Success';
export const UPDATE_DAMAGE_FAIL = '[Master Data Module] Update Damage Fail';
export const UpdateDamage = createAction(
  UPDATE_DAMAGE,
  props<{ iri: string, payload: fromModuleModels.Damage }>()
);
export const UpdateDamageSuccess = createAction(
  UPDATE_DAMAGE_SUCCESS,
  props<{ response: fromModuleModels.Damage }>()
);
export const UpdateDamageFail = createAction(
  UPDATE_DAMAGE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_DAMAGE = '[Master Data Module] Delete Damage';
export const DELETE_DAMAGE_SUCCESS = '[Master Data Module] Delete Damage Success';
export const DELETE_DAMAGE_FAIL = '[Master Data Module] Delete Damage Fail';
export const DeleteDamage = createAction(
  DELETE_DAMAGE,
  props<{ iri: string }>()
);
export const DeleteDamageSuccess = createAction(
  DELETE_DAMAGE_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteDamageFail = createAction(
  DELETE_DAMAGE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
