import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { filter, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-provide-disposal-type',
  styleUrls: ['./action-box-to-provide-disposal-type.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Erneute Datenrettung gewünscht?
              </div>
              <div class="sub-header col-auto">
                Soll eine erneute Datenrettung stattfinden?
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition()"
              [disabled]="!okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
            <button
              class="decision-btn"
              mat-button
              color="red"
              (click)="handleMakeTransition2()"
              [disabled]="!nokAction"
            >
              <mat-icon class="me-2">cancel</mat-icon>
              <span>Nein</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Datenrettung:</div>
          <div class="sub-header col-auto">
            Warte auf Prüfung, ob eine erneute Datenrettung statt finden soll
          </div>
        </div>
      </div>
      <!-- <div class="additional-content">
         <div class="row">
           <div class="col"></div>
         </div>
       </div>-->
    </div>
  `
})
export class ActionBoxToProvideDisposalTypeComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  nokAction: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isSales() ||
          this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('_to_assign_analysis') > -1 && !e.error
        );
        this.nokAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('_to_provide_disposal_type') > -1 && !e.error
        );
      });
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleMakeTransition2(): void {
    const action = this.nokAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }
}
