import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {DataRecoveryProtocolsActions} from '../actions';
import {DataRecoveryProtocolsService} from '../../services';
import {RouterActions, TransitionsActions} from '../../../application-state/store/actions';

@Injectable()
export class DataRecoveryProtocolsEffects {

  constructor(private actions$: Actions, private service: DataRecoveryProtocolsService) {
  }


  ReadDataRecoveryProtocol$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryProtocolsActions.ReadDataRecoveryProtocol),
    switchMap(({iri}) => {
      return this.service.readDataRecoveryProtocol(iri).pipe(
        map((response: any) => DataRecoveryProtocolsActions.ReadDataRecoveryProtocolSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryProtocolsActions.ReadDataRecoveryProtocolFail({response})))
      );
    })
  ));


  ReadDataRecoveryProtocols$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryProtocolsActions.ReadDataRecoveryProtocols),
    switchMap(() => {
      return this.service.readDataRecoveryProtocols().pipe(
        map((response: any) => DataRecoveryProtocolsActions.ReadDataRecoveryProtocolsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryProtocolsActions.ReadDataRecoveryProtocolsFail({response})))
      );
    })
  ));


  UpdateDataRecoveryProtocol$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryProtocolsActions.UpdateDataRecoveryProtocol),
    switchMap(({iri, payload}) => {
      return this.service.updateDataRecoveryProtocol(iri, payload).pipe(
        map((response: any) => DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolFail({response})))
      );
    })
  ));


  DeleteDataRecoveryProtocol$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryProtocolsActions.DeleteDataRecoveryProtocol),
    switchMap(({iri}) => {
      return this.service.deleteDataRecoveryProtocol(iri).pipe(
        map(() => DataRecoveryProtocolsActions.DeleteDataRecoveryProtocolSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryProtocolsActions.DeleteDataRecoveryProtocolFail({response})))
      );
    })
  ));
}
