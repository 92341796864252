import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { DataMediumStatusLog } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DataMediumStatusLogsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readDataMediumStatusLog(iri: string): Observable<DataMediumStatusLog> {
    return this.rolesService.userHasRoleFilter<DataMediumStatusLog>(
      'ROLE_MWS_DATA_MEDIUM_STATUS_LOG_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readDataMediumStatusLogs(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DATA_MEDIUM_STATUS_LOG_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/data_medium_status_logs', page, params)
            )
    );
  }
}
