import {HttpErrorResponse} from '@angular/common/http';


import {createAction, props} from '@ngrx/store';

import * as fromCustomersModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_ADDRESS = '[Customers Module] Create Customer Address';
export const CREATE_CUSTOMER_ADDRESS_SUCCESS = '[Customers Module] Create Customer Address Success';
export const CREATE_CUSTOMER_ADDRESS_FAIL = '[Customers Module] Create Customer Address Fail';

export const CreateCustomerAddress = createAction(
  CREATE_CUSTOMER_ADDRESS,
  props<{
    apiRoute: string,
    payload: fromCustomersModuleModels.Address,
    customer: fromCustomersModuleModels.Customer | null
  }>()
);

export const CreateCustomerAddressSuccess = createAction(
  CREATE_CUSTOMER_ADDRESS_SUCCESS,
  props<{ response: fromCustomersModuleModels.CustomerAddress, customer: fromCustomersModuleModels.Customer | null }>()
);

export const CreateCustomerAddressFail = createAction(
  CREATE_CUSTOMER_ADDRESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER_ADDRESS = '[Customers Module] Read Customer Address';
export const READ_CUSTOMER_ADDRESS_FAIL = '[Customers Module] Read Customer Address Fail';
export const READ_CUSTOMER_ADDRESS_SUCCESS = '[Customers Module] Read Customer Address Success';

export const ReadCustomerAddress = createAction(
  READ_CUSTOMER_ADDRESS,
  props<{ iri: string }>()
);

export const ReadCustomerAddressSuccess = createAction(
  READ_CUSTOMER_ADDRESS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAddressFail = createAction(
  READ_CUSTOMER_ADDRESS_FAIL,
  props<{ response: any }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_ADDRESSES = '[Customers Module] Read Customer Addresses';
export const READ_CUSTOMER_ADDRESSES_SUCCESS = '[Customers Module] Read Customer Addresses Success';
export const READ_CUSTOMER_ADDRESSES_FAIL = '[Customers Module] Read Customer Addresses Fail';

export const ReadCustomerAddresses = createAction(
  READ_CUSTOMER_ADDRESSES,
  props<{ customerIri: string }>()
);

export const ReadCustomerAddressesSuccess = createAction(
  READ_CUSTOMER_ADDRESSES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAddressesFail = createAction(
  READ_CUSTOMER_ADDRESSES_FAIL,
  props<{ response: any }>()
);

export const READ_CUSTOMER_BILLING_ADDRESSES = '[Customers Module] Read Customer Billing Addresses';
export const READ_CUSTOMER_BILLING_ADDRESSES_SUCCESS = '[Customers Module] Read Customer Billing Addresses Success';
export const READ_CUSTOMER_BILLING_ADDRESSES_FAIL = '[Customers Module] Read Customer Billing Addresses Fail';

export const ReadCustomerBillingAddresses = createAction(
  READ_CUSTOMER_BILLING_ADDRESSES,
  props<{ customerIri }>()
);

export const ReadCustomerBillingAddressesSuccess = createAction(
  READ_CUSTOMER_BILLING_ADDRESSES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerBillingAddressesFail = createAction(
  READ_CUSTOMER_BILLING_ADDRESSES_FAIL,
  props<{ response: any }>()
);

export const READ_CUSTOMER_DELIVERY_ADDRESSES = '[Customers Module] Read Customer Delivery Addresses';
export const READ_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS = '[Customers Module] Read Customer Delivery Addresses Success';
export const READ_CUSTOMER_DELIVERY_ADDRESSES_FAIL = '[Customers Module] Read Customer Delivery Addresses Fail';

export const ReadCustomerDeliveryAddresses = createAction(
  READ_CUSTOMER_DELIVERY_ADDRESSES,
  props<{ customerIri: string }>()
);

export const ReadCustomerDeliveryAddressesSuccess = createAction(
  READ_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerDeliveryAddressesFail = createAction(
  READ_CUSTOMER_DELIVERY_ADDRESSES_FAIL,
  props<{ response: any }>()
);

export const READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES = '[Customers Module] Read Customer Partner Branch Office Addresses';
export const READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES_SUCCESS = '[Customers Module] Read Customer Partner Branch Office Addresses Success';
export const READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES_FAIL = '[Customers Module] Read Customer Partner Branch Office Addresses Fail';

export const ReadCustomerPartnerBranchOfficeAddresses = createAction(
  READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES,
  props<{ customerIri: string }>()
);

export const ReadCustomerPartnerBranchOfficeAddressesSuccess = createAction(
  READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerPartnerBranchOfficeAddressesFail = createAction(
  READ_CUSTOMER_PARTNER_BRANCH_OFFICE_ADDRESSES_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_ADDRESS = '[Customers Module] Update Customer Address';
export const UPDATE_CUSTOMER_ADDRESS_FAIL = '[Customers Module] Update Customer AddressFail';
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = '[Customers Module] Update Customer AddressSuccess';

export const UpdateCustomerAddress = createAction(
  UPDATE_CUSTOMER_ADDRESS,
  props<{ iri: string; payload: fromCustomersModuleModels.CustomerAddress }>()
);

export const UpdateCustomerAddressSuccess = createAction(
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  props<{ response: fromCustomersModuleModels.CustomerAddress }>()
);

export const UpdateCustomerAddressFail = createAction(
  UPDATE_CUSTOMER_ADDRESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_ADDRESS = '[Customers Module] Delete Customer Address';
export const DELETE_CUSTOMER_ADDRESS_SUCCESS = '[Customers Module] Delete Customer Address Success';
export const DELETE_CUSTOMER_ADDRESS_FAIL = '[Customers Module] Delete Customer Address Fail';

export const DeleteCustomerAddress = createAction(
  DELETE_CUSTOMER_ADDRESS,
  props<{ iri: string }>()
);
export const DeleteCustomerAddressSuccess = createAction(
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteCustomerAddressFail = createAction(
  DELETE_CUSTOMER_ADDRESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
