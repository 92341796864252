import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CustomerContact } from '../../../customers/models';
import { ObjectsUtility } from '../../../shared/utilities/objects.utility';
import { TicketList } from '../../models/ticket-list.interface';
import { Order } from '../../../orders/models';
import { Subject } from 'rxjs';
import { CustomerContactsSelectors } from '../../../customers/store/selectors';
import {map, takeUntil} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {LabLocationsSelectors} from "../../../master-data/store/selectors";
import {LabLocation} from "../../../master-data/models";
import {loadIfNotLoaded} from "../../../shared/utilities/observable.utility";
import {LabLocationsActions} from "../../../master-data/store";

@Component({
  selector: 'app-ticket-list',
  styleUrls: ['./ticket-list.component.scss'],
  template: `
    <div class="ticket-list__outer">
      <div class="ticket-list__wrapper">
        <div class="ticket-list">
          <table
            class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
            mat-table
            [dataSource]="items"
          >
            <!-- Dot Column -->
            <ng-container matColumnDef="dot">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="pos-relative">
                <span class="badge--express" *ngIf="isExpress(element)">
                  <span>Express</span>
                </span>
                <div
                  class="circle"
                  [ngClass]="getCircleColorClass(element)"
                ></div>
              </td>
            </ng-container>

            <!-- Ticket Number Column -->
            <ng-container matColumnDef="ticketNumber">
              <th mat-header-cell *matHeaderCellDef>Nr.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.ticketNumber }}
              </td>
            </ng-container>

            <!-- Order Column -->
            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef>Auftrag</th>
              <td mat-cell *matCellDef="let element">
                {{ element.order.orderNumber }}
              </td>
            </ng-container>

            <!-- DR Mode Column -->
            <ng-container matColumnDef="mode">
              <th mat-header-cell *matHeaderCellDef>Modus</th>
              <td mat-cell *matCellDef="let element">
                {{ getModeName(element.order, 'dataRecoveryPriorityMode') }}
                <span class="text-muted small">
                  ({{
                    getModeName(element.order, 'analysisPriorityMode')
                  }})</span
                >
              </td>
            </ng-container>

            <!-- Customer/Name Column -->
            <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef>Name</th>

              <td mat-cell *matCellDef="let element">
                <span *ngIf="element?.order?.customer?.vip" class="badge--vip">
                  <span>VIP</span>
                </span>
                {{ getCustomerName(element) }}
              </td>
            </ng-container>

            <!-- DR Mode Column -->
            <ng-container matColumnDef="customerCompany">
              <th mat-header-cell *matHeaderCellDef>Firma</th>
              <td mat-cell *matCellDef="let element">
                {{ getCompanyName(element) }}
              </td>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <strong>{{ element.order.stateRange }}</strong>
                <!--<br>
                <span *ngFor="let key of (element.order.state|keys); let isLast= last;" class="text-color-lightgrey">
                  {{key}}<span *ngIf="!isLast">; </span>
                </span>-->
              </td>
            </ng-container>

            <!-- createdAt Column -->
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Erstellt</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | momentDateWithTime }}
              </td>
            </ng-container>

            <!-- updatedAt Column -->
            <ng-container matColumnDef="updatedAt">
              <th mat-header-cell *matHeaderCellDef>Letzte Aktivität</th>
              <td mat-cell *matCellDef="let element">
                {{ element.updatedAt | momentDateWithTime }} Uhr
              </td>
            </ng-container>

            <!-- storageSystem Column -->
            <ng-container matColumnDef="storageSystem">
              <th mat-header-cell *matHeaderCellDef>Speichersystem</th>
              <td mat-cell *matCellDef="let element">
                {{ element.order?.storageSystem?.name }}
              </td>
            </ng-container>

            <!-- Service Dienstleister Column -->
            <ng-container matColumnDef="SD">
              <th mat-header-cell *matHeaderCellDef>SD</th>
              <td mat-cell *matCellDef="let element">
                {{ labLocationsEntities[element.order?.analysisLocation]?.name ? labLocationsEntities[element.order?.analysisLocation]?.name : '-'}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="handleRequestViewTicket(row)"
            ></tr>
          </table>

          <!--<pre>{{ storageSystemsEntities | json }}</pre>-->
        </div>
      </div>
    </div>
  `
})
export class TicketListComponent implements OnInit, OnDestroy {
  @Input() items: Array<TicketList>;

  @Output() requestViewTicket: EventEmitter<TicketList> = new EventEmitter();
  customerContacts: { [p: string]: CustomerContact };
  onDestroy$: Subject<any> = new Subject<any>();
  labLocationsEntities: { [iri: string]: LabLocation };

  displayedColumns: Array<string> = [
    'dot',
    'ticketNumber',
    'order',
    'mode',
    'customerName',
    'customerCompany',
    'state',
    'createdAt',
    'updatedAt',
    'storageSystem',
    'SD'
  ];

  constructor(private store$: Store<ApplicationState>) {}

  keysAsString(obj): string {
    if (obj && obj instanceof Object) {
      return ObjectsUtility.getObjectKeys(obj).join('\n');
    }
    return '';
  }

  ngOnInit(): void {
    this.store$
      .select(CustomerContactsSelectors.selectCustomerContactEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.customerContacts = entities;
      });
    this.loadLabLocations();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private loadLabLocations(): void {
    this.store$.select(LabLocationsSelectors.sEntities).subscribe(entities => {
      this.labLocationsEntities = entities;
    });
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  getCustomerName(ticket: TicketList): string {
    const element = ticket.order;

    if (element?.decisionMakerCustomerContact) {
      const contact = element.decisionMakerCustomerContact;
      return contact.firstName + ' ' + contact.lastName;
    } else if (element?.technicalCustomerContact) {
      const contact = element.technicalCustomerContact;
      return contact.firstName + ' ' + contact.lastName;
    } else if (element?.organizationalCustomerContact) {
      const contact = element.organizationalCustomerContact;
      return contact.firstName + ' ' + contact.lastName;
    }
    if (element.customer.customerType['@id'] === '/api/customer_types/1') {
      // Privatkunde
      return element.customer.nameLine1;
    } else {
      // Geschäftskunde
      return '-';
    }
  }

  getCompanyName(element: TicketList): string {
    if (element.order.customer?.nameLine1) {
      return element.order.customer?.nameLine1;
    } else if (
      element.order?.customer?.customerType['@id'] === '/api/customer_types/1'
    ) {
      // Privat
      return '-';
    } else {
      // Geschäftskunde

      return element.order.customer?.nameLine1;
    }
  }

  getCircleColorClass(element: Order): string[] {
    if (element.stateRange === 'Warte auf Schlusszahlung') {
      return ['red'];
    } else if (element.stateRange === 'Auftrag abgeschlossen') {
      return ['grey'];
    } else {
      return ['green'];
    }
  }

  isExpress(ticket: TicketList): boolean {
    return (
      ticket.order?.dataRecoveryPriorityMode?.name?.toLowerCase() === 'express'
    );
  }

  getModeName(order: any, which: string): string {
    return order[which]?.name ? order[which]?.name : '-';
  }

  handleRequestViewTicket(ticket: TicketList): void {
    this.requestViewTicket.emit(ticket);
  }
}
