import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProofsOfDestructionActions } from '../actions';
import { ProofsOfDestructionService } from '../../services';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ProofsOfDestructionEffects {
  ReadProofOfDestruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProofsOfDestructionActions.ReadProofOfDestruction),
      switchMap(({ iri }) => {
        return this.service.readProofOfDestruction(iri).pipe(
          map((response: any) =>
            ProofsOfDestructionActions.ReadProofOfDestructionSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              ProofsOfDestructionActions.ReadProofOfDestructionFail({
                response
              })
            )
          )
        );
      })
    )
  );
  ReadProofsOfDestruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProofsOfDestructionActions.ReadProofsOfDestruction),
      switchMap(() => {
        return this.service.readProofsOfDestruction().pipe(
          map((response: any) =>
            ProofsOfDestructionActions.ReadProofsOfDestructionSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              ProofsOfDestructionActions.ReadProofsOfDestructionFail({
                response
              })
            )
          )
        );
      })
    )
  );
  UpdateProofOfDestruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProofsOfDestructionActions.UpdateProofOfDestruction),
      switchMap(({ iri, payload }) => {
        return this.service.updateProofOfDestruction(iri, payload).pipe(
          map((response: any) =>
            ProofsOfDestructionActions.UpdateProofOfDestructionSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              ProofsOfDestructionActions.UpdateProofOfDestructionFail({
                response
              })
            )
          )
        );
      })
    )
  );
  DeleteProofOfDestruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProofsOfDestructionActions.DeleteProofOfDestruction),
      switchMap(({ iri }) => {
        return this.service.deleteProofOfDestruction(iri).pipe(
          map(() =>
            ProofsOfDestructionActions.DeleteProofOfDestructionSuccess({ iri })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              ProofsOfDestructionActions.DeleteProofOfDestructionFail({
                response
              })
            )
          )
        );
      })
    )
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProofsOfDestructionActions.CreateProofOfDestructionSuccess,
          ProofsOfDestructionActions.UpdateProofOfDestructionSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case ProofsOfDestructionActions.CREATE_PROOF_OF_DESTRUCTION_SUCCESS:
              message = 'Ein neuer Vernichtungsnachweis wurde angelegt.';
              break;
            case ProofsOfDestructionActions.UPDATE_PROOF_OF_DESTRUCTION_SUCCESS:
              message = 'Der Vernichtungsnachweis wurde aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProofsOfDestructionActions.CreateProofOfDestructionFail,
          ProofsOfDestructionActions.ReadProofsOfDestructionFail,
          ProofsOfDestructionActions.UpdateProofOfDestructionFail,
          ProofsOfDestructionActions.DeleteProofOfDestructionFail
        ),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          const message = 'Es ist der folgenden Fehler aufgetreten: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: ProofsOfDestructionService,
    private notifierService: NotifierService
  ) {}
}
