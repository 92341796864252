import {createSelector} from '@ngrx/store';

import {DATA_MEDIUMS_FEATURE_KEY, selectWarehouseModuleState} from '../reducers';
import {DataMedium} from '../../models';

export const selectDataMediumsState = createSelector(
  selectWarehouseModuleState,
  (state) => state[DATA_MEDIUMS_FEATURE_KEY]
);

export const selectDataMediumsEntities = createSelector(
  selectDataMediumsState,
  (state) => state.entities
);

export const selectDataMediums = createSelector(
  selectDataMediumsEntities,
  (entities) => Object.values(entities)
);
export const selectDataMediumsByOrderId = createSelector(
  selectDataMediums,
  (list, {orderIri}): DataMedium[] => list.filter((e: DataMedium) => e.order === orderIri)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectDataMediumsTotalItems = createSelector(
  selectDataMediumsState,
  (state) => state.totalItems
);

export const selectDataMediumsPagination = createSelector(
  selectDataMediumsState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectDataMediumsPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectDataMediumsPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectDataMediumsPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectDataMediumsPagination,
  pagination => pagination.next
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectDataMediumsState,
  (state) => state.errors
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDataMediumsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDataMediumsState,
  (state) => state.loaded
);
