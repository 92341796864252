import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions} from '@ngrx/effects';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';

import * as MasterDataModuleSelectors from '../../../master-data/store/selectors';
import * as OrdersModuleSelectors from '../../../orders/store/selectors';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import * as fromWarehouseModuleModels from '../../../warehouse/models';
import {ApplicationState} from '../../../application-state/store';
import {DataRecoveryProtocolsSelectors} from '../../../orders/store/selectors';
import {ErrorsObject} from '../../utilities/error-utility.utility';

@Component({
  selector: 'data-recovery-protocol-box',
  styleUrls: ['data-recovery-protocol-box.component.scss'],
  template: `

    <ngcontainer [formGroup]="drpf">

      <div class="grid m-b--32">

        <div class="column-3 m-b--24">

          <mat-form-field>
            <mat-label>Datum Beginn der Wiederherstellung</mat-label>

            <input type="text" matInput [matDatepicker]="picker" formControlName="dataRecoveryStartedAt" readonly
                   required>

            <mat-datepicker-toggle matSuffix [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker
            ></mat-datepicker>

            <mat-hint *ngIf="errors?.dataRecoveryStartedAt">{{ errors.dataRecoveryStartedAt.message }}</mat-hint>
          </mat-form-field>

          <div class="mat-form-field">
            <ng-select
              [clearable]="false"
              [searchable]="false"
              bindValue="@id"
              bindLabel="name"
              required
              formControlName="fileSystem"
              [items]="fileSystems$ | async"
              placeholder="Dateisystem*"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.fileSystem">{{ errors.fileSystem.message }}</mat-hint>
          </div>

          <div class="mat-form-field">
            <ng-select
              [clearable]="false"
              [searchable]="false"
              bindValue="@id"
              bindLabel="name"
              required
              formControlName="damage"
              [items]="damageTypes$ | async"
              placeholder="Schaden*"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.damage">{{ errors.damage.message }}</mat-hint>
          </div>

          <div class="mat-form-field">
            <ng-select
              [clearable]="false"
              [searchable]="false"
              bindValue="@id"
              bindLabel="name"
              required
              formControlName="symptom"
              [items]="symptoms$ | async"
              placeholder="Symptome*"
            ></ng-select>
            <mat-hint align="start" *ngIf="errors?.symptom">{{ errors.symptom.message }}</mat-hint>
          </div>
        </div>

        <div class="column-auto">
          <mat-form-field>
            <mat-label>Beschreibung der verlorenen Daten</mat-label>
            <textarea
              matTextareaAutosize
              matInput rows="11"
              formControlName="requiredData"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="column-auto">
          <mat-form-field>
            <mat-label>Ergänzung durch Reverse-Engineer</mat-label>
            <textarea
              matTextareaAutosize
              matInput rows="11"
              formControlName="reverseEngineerAddition"
            ></textarea>
          </mat-form-field>
        </div>

        <!-- Checkliste -->
        <div class="column-14 grid m-b--32">

          <div class="column-9">
            <span class="heading--h2">Checkliste</span>
          </div>

          <div class="column-3">
            <span class="heading--h2">Datenrettung</span>
          </div>

          <div class="column-3">
            <div class="mat-form-field checkbox" *ngFor="let checkbox of columnOneCheckboxes">
              <mat-checkbox [formControlName]="checkbox.property">{{ checkbox.label }}</mat-checkbox>
            </div>
          </div>

          <div class="column-3">
            <div class="mat-form-field checkbox" *ngFor="let checkbox of columnTwoCheckboxes">
              <mat-checkbox [formControlName]="checkbox.property">{{ checkbox.label }}</mat-checkbox>
            </div>
          </div>

          <div class="column-3">

            <div class="mat-form-field checkbox" *ngFor="let checkbox of columnThreeCheckboxes">
              <mat-checkbox [formControlName]="checkbox.property">{{ checkbox.label }}</mat-checkbox>
            </div>
          </div>

          <div class="column-3">
            <div class="mat-form-field checkbox">
              <mat-checkbox formControlName="dataRecoveryInitiated">Datenwiederherstellung eingeleitet</mat-checkbox>
            </div>
            <div class="mat-form-field checkbox">
              <mat-checkbox formControlName="dataRecoveryPossible">Datenwiederherstellung möglich</mat-checkbox>
            </div>
          </div>
        </div>

        <!-- Datenträger -->
        <div class="column-14 m-b--32">
          <span class="heading--h2">Datenträger</span>

          <div class="table mat-elevation-z1">

            <div class="thead column-14 grid grid-no-gutter">
              <div class="th column-2">Einl-Nr.</div>
              <div class="th column-2">Seriennummer</div>
              <div class="th column-2">Typ</div>
              <div class="th column-2">Hersteller</div>
              <div class="th column-1">Speichergröße</div>
              <div class="th column-5">Status</div>
            </div>

            <mat-selection-list formControlName="customerDataMedia">

              <mat-list-option
                *ngFor="let medium of dataMediums;"
                checkboxPosition="after"
                [disableRipple]="true"
                [value]="medium['@id']">

                <ng-template-outlet
                  [ngTemplateOutlet]="dataMediumRow"
                  [ngTemplateOutletContext]="{ medium: medium }"
                ></ng-template-outlet>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>

        <div class="column-14">
          <div class="m-ta--2">
            <button mat-button color="green"
                    [disabled]="drpf.invalid"
                    (click)="handleRequestUpdateDataRecoveryProtocol()">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>Protokoll erstellen</span>
            </button>
          </div>
        </div>
      </div>
    </ngcontainer>

    <ng-template #dataMediumRow let-medium="medium">
      <div class="grid grid-no-gutter tr">
        <div class="td column-2">{{ medium.storageNumber }}</div>
        <div class="td column-2">{{ medium.storageSystemSerialNumber }}</div>
        <div class="td column-2">{{ medium?.type ? medium.type : '-' }}</div>
        <div class="td column-2">{{ getStorageSystemManufacturerName(medium.storageSystemManufacturer) }}</div>
        <div class="td column-1">{{ medium.storageSystemSize }} GB</div>
        <div class="td column-5">{{ medium.state }}</div>
      </div>
    </ng-template>

    <!--<pre>{{ dataRecoveryResult | json }}</pre>-->
    <!--<pre>{{ drpf.value | json }}</pre>-->
  `
})
export class DataRecoveryProtocolBoxComponent implements OnInit, OnDestroy {

  @Output() requestUpdateDataRecoveryProtocol: EventEmitter<fromOrdersModuleModels.DataRecoveryProtocol> = new EventEmitter();

  damageTypes$: Observable<Array<fromMasterDataModuleModels.Damage>>;
  dataMediums: Array<fromWarehouseModuleModels.CustomerDataMedium> = [];
  drpf: FormGroup;
  errors: ErrorsObject;
  fileSystems$: Observable<Array<fromMasterDataModuleModels.FileSystem>>;
  onDestroy$: Subject<any> = new Subject();
  storageSystemManufacturersEntities: { [k: string]: fromMasterDataModuleModels.StorageSystemManufacturer };
  symptoms$: Observable<Array<fromMasterDataModuleModels.Symptom>>;

  columnOneCheckboxes = [
    {
      property: 'expressProcessingRequired',
      label: 'Expressbearbeitung erforderlich',
    }, {
      property: 'rescueAttemptsAlreadyMade',
      label: 'Bereits zuvor erfolgte Rettungsversuche',
    }, {
      property: 'dataMediumOpenedByCustomer',
      label: 'Datenträger durch Kunde geöffnet',
    }, {
      property: 'visualInspectionPerformed',
      label: 'Sichtprüfung erfolgt',
    }, {
      property: 'dataMediumTechnicallyDefective',
      label: 'Datenträger technisch defekt',
    }, {
      property: 'electronicDamageDetected',
      label: 'Elektronikschaden festgestellt',
    },
  ];

  columnTwoCheckboxes = [
    {
      property: 'logicalDistributedDamage',
      label: 'Logische Beschädigungen (verteilt)',
    }, {
      property: 'encryptionInFileSystem',
      label: 'Verschlüsselung im Dateisystem',
    }, {
      property: 'controllerEncryption',
      label: 'Controller-Verschlüsselung',
    }, {
      property: 'virtualFileSystem',
      label: 'Virtuelles Dateisystem',
    }, {
      property: 'proprietaryFileSystem',
      label: 'Proprietäres Dateisystem',
    }, {
      property: 'diskOpenDuringDiagnostics',
      label: 'Datenträger bei Diagnose geöffnet',
    },
  ];

  columnThreeCheckboxes = [
    {
      property: 'diskOpenDuringRecovery',
      label: 'Datenträger bei Recovery geöffnet',
    }, {
      property: 'disposalRequested',
      label: 'Entsorgung angefordert',
    }, {
      property: 'sectorsDefective',
      label: 'Sektoren defekt ',
    }, {
      property: 'destructiveWriteAccessDetected',
      label: 'Destruktive Schreibzugriffe festgestellt',
    }
  ];

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  getCustomerMediaDataIris(): Array<string> {
    return this.dataMediums.reduce((acc, cur) => {
        return [
          ...acc,
          cur['@id']
        ];
      },
      []
    );
  }

  getStorageSystemManufacturerName(manufacturer: string): string {
    return this.storageSystemManufacturersEntities?.manufacturer ? this.storageSystemManufacturersEntities[manufacturer]?.name : '-';
  }

  ngOnInit(): void {
    this.initForm();
    this.selectData();
    this.initSubscriptions();
  }

  initForm(): void {
    this.drpf = this.fb.group({
      controllerEncryption: this.fb.control(false),
      customerDataMedia: this.fb.control(null, Validators.required),
      damage: this.fb.control(null, Validators.required),
      dataMediumOpenedByCustomer: this.fb.control(false),
      dataMediumTechnicallyDefective: this.fb.control(false),
      dataRecoveryInitiated: this.fb.control(false),
      dataRecoveryPossible: this.fb.control(false),
      dataRecoveryStartedAt: this.fb.control(null, Validators.required),
      destructiveWriteAccessDetected: this.fb.control(false),
      diskOpenDuringDiagnostics: this.fb.control(false),
      diskOpenDuringRecovery: this.fb.control(false),
      disposalRequested: this.fb.control(false),
      electronicDamageDetected: this.fb.control(false),
      encryptionInFileSystem: this.fb.control(false),
      expressProcessingRequired: this.fb.control(false),
      fileSystem: this.fb.control(null, Validators.required),
      logicalDistributedDamage: this.fb.control(false),
      proprietaryFileSystem: this.fb.control(false),
      requiredData: this.fb.control(''),
      rescueAttemptsAlreadyMade: this.fb.control(false),
      reverseEngineerAddition: this.fb.control(''),
      sectorsDefective: this.fb.control(false),
      symptom: this.fb.control(null, Validators.required),
      virtualFileSystem: this.fb.control(false),
      visualInspectionPerformed: this.fb.control(false),
    });
  }

  selectData(): void {
    this.damageTypes$ = this.store$.pipe(select(MasterDataModuleSelectors.DamagesSelectors.selectDamages));
    this.fileSystems$ = this.store$.pipe(select(MasterDataModuleSelectors.FileSystemsSelectors.selectFileSystems));
    this.symptoms$ = this.store$.pipe(select(MasterDataModuleSelectors.SymptomsSelectors.selectSymptoms));
  }

  initSubscriptions(): void {

    this.store$.pipe(
      select(OrdersModuleSelectors.OrderDataMediumsSelectors.selectOrderDataMediums),
      takeUntil(this.onDestroy$)
    ).subscribe(dataMediums => {
      if (dataMediums) {
        this.dataMediums = dataMediums;
        this.drpf.get('customerDataMedia').setValue(this.getCustomerMediaDataIris());
      }
    });

    this.store$.pipe(
      select(DataRecoveryProtocolsSelectors.selectDataRecoveryProtocolsErrors),
      takeUntil(this.onDestroy$)
    ).subscribe(errors => {
      this.errors = errors;
    });

    this.store$.pipe(
      select(MasterDataModuleSelectors.StorageSystemManufacturersSelectors.sEntities),
      takeUntil(this.onDestroy$)
    ).subscribe(storageSystemManufacturersEntities => {
      this.storageSystemManufacturersEntities = storageSystemManufacturersEntities;
    });
  }

  handleRequestUpdateDataRecoveryProtocol(): void {
    this.requestUpdateDataRecoveryProtocol.emit(this.drpf.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
