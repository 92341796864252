import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1n
export const READ_LOCALE = '[Master Data] Read Locale';
export const READ_LOCALE_SUCCESS = '[Master Data] Read Locale Success';
export const READ_LOCALE_FAIL = '[Master Data] Read Locale Fail';
export const ReadLocale = createAction(
  READ_LOCALE,
  props<{ iri: string }>()
);
export const ReadLocaleSuccess = createAction(
  READ_LOCALE_SUCCESS,
  props<{ response }>()
);
export const ReadLocaleFail = createAction(
  READ_LOCALE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LOCALES = '[Master Data] Read Locales';
export const READ_LOCALES_SUCCESS = '[Master Data] Read Locales Success';
export const READ_LOCALES_FAIL = '[Master Data] Read Locales Fail';
export const ReadLocales = createAction(
  READ_LOCALES
);
export const ReadLocalesSuccess = createAction(
  READ_LOCALES_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadLocalesFail = createAction(
  READ_LOCALES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
