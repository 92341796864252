import { Injectable } from '@angular/core';

import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import {CustomerAddress} from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerAddressesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  // POST /billing_address or POST /delivery_addresses
  createCustomerAddress(
    iri: string,
    payload: CustomerAddress
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(iri, payload, true)
    );
  }

  // GET /billing_address/{uuid} or GET /delivery_addresses/{uuid}
  readCustomerAddress(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  // GET /billing_address/{uuid} and GET /delivery_addresses/{uuid}
  // todo: maybe delivery and billing addresses should not be combined but treated separately
  readCustomerAddresses(
    customerIri: string,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      () =>
        forkJoin([
          this.apiService.getObject(`${customerIri}/billing_addresses`, true),
          this.apiService.getObject(`${customerIri}/delivery_addresses`, true),
          this.apiService.getObject(`${customerIri}/partner_branch_office_addresses`, true)
        ]).pipe(
          switchMap(addresses => {
            const [billingAddresses, deliveryAddresses] = addresses;

            return of({
              'hydra:member': [
                ...billingAddresses['hydra:member'],
                ...deliveryAddresses['hydra:member']
              ]
            });
          })
        )
    );
  }

  // GET /billing_address/{uuid}
  readCustomerBillingAddresses(
    customerIri,
    page = 1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(
                `${customerIri}/billing_addresses`,
                page,
                params
              ),
              true
            )
    );
  }

  // GET /delivery_addresses/{uuid}
  readCustomerDeliveryAddresses(
    customerIri: string,
    page = 1,
    params?: ParameterBag
  ): Observable<Array<CustomerAddress>> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(
                `${customerIri}/delivery_addresses`,
                page,
                params
              ),
              true
            )
    );
  }

  // GET /partner_branch_office_addresses/{uuid}
  readCustomerPartnerBranchOfficeAddresses(
    customerIri: string,
    page = 1,
    params?: ParameterBag
  ): Observable<Array<CustomerAddress>> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
            getUriWithPageAndParams(
              `${customerIri}/partner_branch_office_addresses`,
              page,
              params
            ),
            true
          )
    );
  }

  // PUT /billing_address/{uuid} or PUT /delivery_addresses/{uuid}
  updateCustomerAddress(
    iri: string,
    payload: CustomerAddress
  ): Observable<CustomerAddress> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  // DELETE /billing_address/{uuid} or DELETE /delivery_addresses/{uuid}
  deleteCustomerAddress(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
