import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ORDER_COMMENT = '[Orders Module] Create Order Comment';
export const CREATE_ORDER_COMMENT_SUCCESS = '[Orders Module] Create Order Comment Success';
export const CREATE_ORDER_COMMENT_FAIL = '[Orders Module] Create Order Comment Fail';

export const CreateOrderComment = createAction(
  CREATE_ORDER_COMMENT,
  props<{ payload: any }>()
);

export const CreateOrderCommentSuccess = createAction(
  CREATE_ORDER_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateOrderCommentFail = createAction(
  CREATE_ORDER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER_COMMENT = '[Orders Module] Read Order Comment';
export const READ_ORDER_COMMENT_SUCCESS = '[Orders Module] Read Order Comment Success';
export const READ_ORDER_COMMENT_FAIL = '[Orders Module] Read Order Comment Fail';

export const ReadOrderComment = createAction(
  READ_ORDER_COMMENT,
  props<{ iri: string }>()
);

export const ReadOrderCommentSuccess = createAction(
  READ_ORDER_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderCommentFail = createAction(
  READ_ORDER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_COMMENTS = '[Orders Module] Read Order Comments';
export const READ_ORDER_COMMENTS_SUCCESS = '[Orders Module] Read Order Comments Success';
export const READ_ORDER_COMMENTS_FAIL = '[Orders Module] Read Order Comments Fail';

export const ReadOrderComments = createAction(
  READ_ORDER_COMMENTS,
  props<{ page: number, params: { [p: string]: any } }>()
);

export const ReadOrderCommentsSuccess = createAction(
  READ_ORDER_COMMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderCommentsFail = createAction(
  READ_ORDER_COMMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_ORDER_COMMENT_CHUNK = '[Orders Module] Read Order Comment Chunk';
export const READ_ORDER_COMMENT_CHUNK_SUCCESS = '[Orders Module] Read Order Comment Chunk Success';
export const READ_ORDER_COMMENT_CHUNK_FAIL = '[Orders Module] Read Order Comment Chunk Fail';

export const ReadOrderCommentChunk = createAction(
  READ_ORDER_COMMENT_CHUNK,
  props<{ uri: string }>()
);

export const ReadOrderCommentChunkSuccess = createAction(
  READ_ORDER_COMMENT_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderCommentChunkFail = createAction(
  READ_ORDER_COMMENT_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ORDER_COMMENT = '[Orders Module] Update Order Comment';
export const UPDATE_ORDER_COMMENT_SUCCESS = '[Orders Module] Update Order Comment Success';
export const UPDATE_ORDER_COMMENT_FAIL = '[Orders Module] Update Order Comment Fail';

export const UpdateOrderComment = createAction(
  UPDATE_ORDER_COMMENT,
  props<{ iri: string, payload: fromModuleModels.OrderComment }>()
);

export const UpdateOrderCommentSuccess = createAction(
  UPDATE_ORDER_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const UpdateOrderCommentFail = createAction(
  UPDATE_ORDER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ORDER_COMMENT = '[Orders Module] Delete Order Comment';
export const DELETE_ORDER_COMMENT_SUCCESS = '[Orders Module] Delete Order Comment Success';
export const DELETE_ORDER_COMMENT_FAIL = '[Orders Module] Delete Order Comment Fail';

export const DeleteOrderComment = createAction(
  DELETE_ORDER_COMMENT,
  props<{ iri: string }>()
);

export const DeleteOrderCommentSuccess = createAction(
  DELETE_ORDER_COMMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteOrderCommentFail = createAction(
  DELETE_ORDER_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Misc

export const RESET_LOADED_STATE = '[Orders Module] Reset Loaded State';

export const ResetOrderCommentsLoaded = createAction(
  RESET_LOADED_STATE,
);
