import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { StockItemsActions } from '../actions';
import { StockItemsService } from '../../services';

@Injectable()
export class StockItemsEffects {
  readStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockItemsActions.ReadStockItems),
      switchMap(() => {
        return this.service.getStockItems().pipe(
          map((response: any) =>
            StockItemsActions.ReadStockItemsSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(StockItemsActions.ReadStockItemsFail({ response }))
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private service: StockItemsService) {}
}
