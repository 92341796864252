import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl
} from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { extractIri } from '../../utilities/objects.utility';
import { StorageSystem } from '../../../master-data/models';
import { StorageSystemsSelectors } from '../../../master-data/store/selectors';
import { StorageSystemsActions } from '../../../master-data/store';

@Component({
  selector: 'app-storage-system-type-select',
  styleUrls: ['./storage-system-type-select.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => StorageSystemTypeSelectComponent),
  //     multi: true
  //   }
  // ],
  template: `
    <div [formGroup]="form">
      <ng-select
        formControlName="selectedElement"
        [items]="items$ | async"
        bindLabel="name"
        [searchable]="true"
        [readonly]="readonly"
        [loading]="isLoading$ | async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        placeholder="Hardware Typ"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  `
})
export class StorageSystemTypeSelectComponent extends BaseOnDestroyComponent
  implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<
    StorageSystem
  > = new EventEmitter<StorageSystem>();
  @Input() readonly = false;

  items$: Observable<Array<StorageSystem>>;
  isLoading$: Observable<boolean>;
  selectedItem: StorageSystem;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    @Self() @Optional() public control: NgControl
  ) {
    super();
    this.form = this.fb.group({
      selectedElement: this.fb.control(null)
    });
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  ngOnInit(): void {
    this.form
      .get('selectedElement')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });
    if (this.control) {
      // this.form.get('input').setValidators(this.control.validator);

      this.control.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(data => {
          console.log(data, this.control.control, this.control.control.errors);
          this.form.controls.selectedElement.setErrors(
            this.control.control.errors
          );
        });
    }
    this.items$ = this.store$.select(StorageSystemsSelectors.sOrderedList);
    this.isLoading$ = this.store$.select(StorageSystemsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.required?.currentValue === true) {
      this.form.markAllAsTouched();
    }
  }

  setSelectedItem(element: StorageSystem): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem = (term: string, item: StorageSystem): boolean => {
    const vm = this;
    const parts = term.split(' ');
    return parts.every(t => {
      return item.name.toLowerCase().indexOf(t.toLowerCase()) > -1;
    });
  };

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }
}
