import { Component, Input, OnInit } from '@angular/core';
import { LabLocationsSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { LabLocationsActions } from '../../../master-data/store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { filter, takeUntil } from 'rxjs/operators';
import { PotentialAction } from '../../models';
import { LabLocation } from '../../../master-data/models';
import { OrdersActions } from '../../../orders/store';
import { TransitionsActions } from '../../../application-state/store/actions';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-assign-analysis-location',
  styleUrls: ['./action-box-assign-analysis-location.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Wo findet die Analyse statt?</div>
              <div class="sub-header col-auto">
                Wo wird die Analyse für das Speichersystem durchgeführt? Bitte
                auswählen
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right" [formGroup]="form">
            <button
              class="decision-btn me-3 btn-wider float-right"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid && !okAction"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Analyseort Speichern</span>
            </button>

            <ng-select
              style="width: 300px; display: inline-block; float:right;"
              class="me-4"
              placeholder="Analyse in"
              [items]="labLocations$ | async"
              [loading]="labLocationsIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              formControlName="analysisLocation"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'analysisLocation'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">
            Warte auf Festlegung des Analyse-Orts:
          </div>
          <div class="sub-header col-auto">
            Es muss entschieden werden, wo der Datenträger analysiert wird.
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxAssignAnalysisLocationComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;

  labLocations$: Observable<Array<LabLocation>>;
  labLocationsIsLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
    private authService: AuthService
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadLabLocations();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(this.order['@id']);
        this.form.get('analysisLocation').setValue(this.order.analysisLocation);

        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_prepare_analysis') > -1 && !e.error
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      analysisLocation: this.fb.control(null)
    });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const formData = this.form.value;
    this.store$.dispatch(
      OrdersActions.UpdateAnalysisLocation({
        iri: formData.order,
        payload: { analysisLocation: formData.analysisLocation }
      })
    );
    this.actions$
      .pipe(
        ofType(OrdersActions.UpdateAnalysisLocationSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        this.order = response;
        this.okAction = response.potentialActions.find(
          e => e.transition.indexOf('to_prepare_analysis') > -1 && !e.error
        );

        this.makeTransition();
      });
  }

  private loadLabLocations(): void {
    this.labLocations$ = this.store$.select(LabLocationsSelectors.sList);
    this.labLocationsIsLoading$ = this.store$.select(
      LabLocationsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }
}
