import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { AnalysisPriorityMode } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class AnalysisPriorityModesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getAnalysisPriorityMode(iri: string): Observable<AnalysisPriorityMode> {
    return this.rolesService.userHasRoleFilter<AnalysisPriorityMode>(
      'ROLE_MWS_ANALYSIS_PRIORITY_MODE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getAnalysisPriorityModes(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AnalysisPriorityMode>(
      'ROLE_MWS_ANALYSIS_PRIORITY_MODE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/analysis_priority_modes`)
    );
  }
}
