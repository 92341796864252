import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { PartnerWebsite } from '../models';

@Injectable()
export class PartnerWebsitesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createPartnerWebsite(
    payload: PartnerWebsite
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/partner_websites`, payload)
    );
  }

  readPartnerWebsite(iri: string): Observable<PartnerWebsite> {
    return this.rolesService.userHasRoleFilter<PartnerWebsite>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readPartnerWebsiteProfile(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`${iri}/profile`, true)
    );
  }

  readPartnerWebsites(page = 1): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/partner_websites?page=${page}`)
    );
  }

  readPartnerWebsiteChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updatePartnerWebsitePassword(
    iri: string,
    payload: {
      oldPassword: string;
      plainPassword: string;
      plainPasswordConfirmation: string;
    }
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(
              `${iri}/change_password`,
              payload,
              true
            )
    );
  }

  updatePartnerWebsiteProfile(
    iri: string,
    payload: any
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_PROFILE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(`${iri}/profile`, payload, true)
    );
  }

  updatePartnerWebsite(
    iri: string,
    payload: PartnerWebsite
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deletePartnerWebsite(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_PARTNER_WEBSITE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
