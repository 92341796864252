import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_BINDING_ORDER = '[Orders Module] Create Binding Order';
export const CREATE_BINDING_ORDER_SUCCESS = '[Orders Module] Create Binding Order Success';
export const CREATE_BINDING_ORDER_FAIL = '[Orders Module] Create Binding Order Fail';

export const CreateBindingOrder = createAction(
  CREATE_BINDING_ORDER,
  props<{ payload: fromModuleModels.BindingOrder }>()
);

export const CreateBindingOrderSuccess = createAction(
  CREATE_BINDING_ORDER_SUCCESS,
  props<{ response: any }>()
);

export const CreateBindingOrderFail = createAction(
  CREATE_BINDING_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Upload

export const UPLOAD_BINDING_ORDER = '[Orders Module] Upload Binding Order';
export const UPLOAD_BINDING_ORDER_STATUS = '[Orders Module] Upload Binding Order Status';
export const UPLOAD_BINDING_ORDER_SUCCESS = '[Orders Module] Upload Binding Order Success';
export const UPLOAD_BINDING_ORDER_FAIL = '[Orders Module] Upload Binding Order Fail';

export const UploadBindingOrder = createAction(
  UPLOAD_BINDING_ORDER,
  props<{ payload: { file: File, uuid?: string, order, createdAt?: string } }>()
);

export const UploadBindingOrderSuccess = createAction(
  UPLOAD_BINDING_ORDER_SUCCESS,
  props<{ response: any }>()
);
export const UploadBindingOrderStatus = createAction(
  UPLOAD_BINDING_ORDER_STATUS,
  props<{ response: any }>()
);

export const UploadBindingOrderFail = createAction(
  UPLOAD_BINDING_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_BINDING_ORDER = '[Orders Module] Read Binding Order';
export const READ_BINDING_ORDER_SUCCESS = '[Orders Module] Read Binding Order Success';
export const READ_BINDING_ORDER_FAIL = '[Orders Module] Read Binding Order Fail';

export const ReadBindingOrder = createAction(
  READ_BINDING_ORDER,
  props<{ iri: string }>()
);

export const ReadBindingOrderSuccess = createAction(
  READ_BINDING_ORDER_SUCCESS,
  props<{ response: any }>()
);

export const ReadBindingOrderFail = createAction(
  READ_BINDING_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_BINDING_ORDERS = '[Orders Module] Read Binding Orders';
export const READ_BINDING_ORDERS_SUCCESS = '[Orders Module] Read Binding Orders Success';
export const READ_BINDING_ORDERS_FAIL = '[Orders Module] Read Binding Orders Fail';

export const ReadBindingOrders = createAction(
  READ_BINDING_ORDERS,
);

export const ReadBindingOrdersSuccess = createAction(
  READ_BINDING_ORDERS_SUCCESS,
  props<{ response: any }>()
);

export const ReadBindingOrdersFail = createAction(
  READ_BINDING_ORDERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_BINDING_ORDER = '[Orders Module] Delete Binding Order';
export const DELETE_BINDING_ORDER_SUCCESS = '[Orders Module] Delete Binding Order Success';
export const DELETE_BINDING_ORDER_FAIL = '[Orders Module] Delete Binding Order Fail';

export const DeleteBindingOrder = createAction(
  DELETE_BINDING_ORDER,
  props<{ iri: string }>()
);

export const DeleteBindingOrderSuccess = createAction(
  DELETE_BINDING_ORDER_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteBindingOrderFail = createAction(
  DELETE_BINDING_ORDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
