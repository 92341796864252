import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromWarehouseModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';

@Component({
  selector: 'data-medium-form',
  styleUrls: ['data-medium-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container [formGroup]="dmf">
      <div class="grid">
        <div class="column-14">
          <ng-content></ng-content>
        </div>

        <div class="mat-form-field column-14">
          <ng-select
            class="white"
            [items]="storageSystemManufacturers$ | async"
            placeholder="Hersteller"
            bindLabel="name"
            appendTo="body"
            bindValue="@id"
            formControlName="storageSystemManufacturer"
            [clearable]="false"
            [searchable]="false"
            [markFirst]="false"
          ></ng-select>
        </div>

        <mat-form-field class="white column-8">
          <mat-label>Modell</mat-label>
          <input matInput type="text" formControlName="storageSystemModel" />
          <mat-hint align="start" *ngIf="errors?.storageSystemModel">{{
            errors?.storageSystemModel?.message
          }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="white column-6">
          <mat-label>Größe</mat-label>
          <input
            matInput
            type="number"
            formControlName="storageSystemSize"
            min="1"
          />
          <span matSuffix>GB</span>
          <mat-hint align="start" *ngIf="errors?.storageSystemSize">{{
            errors?.storageSystemSize?.message
          }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="white column-14">
          <mat-label>Seriennummer</mat-label>
          <input
            matInput
            type="text"
            formControlName="storageSystemSerialNumber"
          />
          <mat-hint align="start" *ngIf="errors?.storageSystemSerialNumber">{{
            errors?.storageSystemSerialNumber?.message
          }}</mat-hint>
        </mat-form-field>

        <div class="column-7 off-7 m-ta--2">
          <button
            [disabled]="dmf.invalid || dmf.pristine"
            (click)="handleRequestUpdateDataMedium()"
            mat-button
            color="green"
          >
            Datenträger bearbeiten
          </button>
        </div>
      </div>
    </ng-container>

    <!--<pre>{{ dmf.value | json }}</pre>-->
    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
  `
})
export class DataMediumFormComponent implements OnInit, OnDestroy {
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromWarehouseModuleModels.StockItem | any>;
  @Input() storageLocationsEntities: {
    [iri: string]: fromMasterDataModuleModels.StorageLocation;
  };
  @Input() storageSystemManufacturers$: Observable<
    Array<fromMasterDataModuleModels.StorageSystemManufacturer>
  >;

  @Output() requestUpdateDataMedium: EventEmitter<{
    type: string;
    iri: string;
    payload: fromWarehouseModuleModels.DataMedium;
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  dmf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {}

  ngOnInit(): void {
    this.initForm();
    this.presets$.pipe(takeUntil(this.onDestroy$)).subscribe(presets => {
      console.log(presets);
      const formValues = { ...presets };
      if (
        formValues.storageSystemManufacturer &&
        formValues.storageSystemManufacturer['@id']
      ) {
        formValues.storageSystemManufacturer =
          formValues.storageSystemManufacturer['@id'];
      }
      this.fs.patchForm(this.dmf, formValues);
      this.dmf.markAsUntouched();
    });
  }

  initForm(): void {
    this.dmf = this.fb.group({
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control('', [Validators.required]),
      storageSystemSize: this.fb.control(null, [
        Validators.required,
        Validators.min(1)
      ]),
      storageSystemSerialNumber: this.fb.control(null, [Validators.required])
    });
  }

  handleRequestUpdateDataMedium(): void {
    const payload = {
      type: this.presets$.getValue()['@type'],
      iri: this.presets$.getValue()['@id'],
      payload: this.dmf.value
    };
    this.requestUpdateDataMedium.emit(payload);
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
