import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CountriesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Country };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(CountriesActions.ReadCountry, CountriesActions.ReadCountries, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(CountriesActions.ReadCountrySuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(CountriesActions.ReadCountriesSuccess, (state, { response }) => {
    const countries = response['hydra:member'];
    return {
      ...state,
      entities: mergeEntities(countries, { delete: true }, state.entities),
      loading: false,
      loaded: true
    };
  }),
  on(
    CountriesActions.ReadCountryFail,
    CountriesActions.ReadCountriesFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
