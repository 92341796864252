import {createAction, props} from '@ngrx/store';

export const DIALOG_OPEN = '[Application State Module] Open Dialog';
export const DIALOG_CLOSE_WITH_CONFIRMATION = '[Application State Module] Close Dialog With Confirmation';
export const DIALOG_CLOSE_WITHOUT_CONFIRMATION = '[Application State Module] Close Dialog Without Confirmation';

export const DialogOpen = createAction(DIALOG_OPEN, props<{ payload: any }>());
export const DialogCloseWithConfirmation = createAction(DIALOG_CLOSE_WITH_CONFIRMATION);
export const DialogCloseWithoutConfirmation = createAction(DIALOG_CLOSE_WITHOUT_CONFIRMATION);

//////////////////////////////////////////////////////////////////////////////////////////// Open Dialog From Anywhere

export const CALL_OPEN_DIALOG_FROM_GLOBAL = '[Application State Module] Open Dialog From Global';

export const CallOpenDialogFromGlobal = createAction(
  CALL_OPEN_DIALOG_FROM_GLOBAL,
  props<{ dialogIdentifier: string }>()
);
