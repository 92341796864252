import {Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {InvoiceLike} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormBuilder, FormGroup} from '@angular/forms';
import {filter, takeUntil} from 'rxjs/operators';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {
  CancellationInvoicesActions,
  CommissionCreditsActions,
  CorrectionInvoicesActions, OffersActions,
  PayableInvoicesActions
} from '../../store';
import {
  CancellationInvoicesSelectors,
  CorrectionInvoicesSelectors,
  PayableInvoicesSelectors
} from '../../store/selectors';
import {extractIri, extractTypeByIri} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-invoice-custom-text',
  styleUrls: ['./invoice-custom-text.component.scss'],
  template: `
    <div class="row" [matTooltipDisabled]="!isBooked"
         matTooltip="Gebuchte Dokumente können nicht bearbeitet werden." [formGroup]="form">
      <div class="col-12 pos-relative">
        <app-loading-overlay *ngIf="loading$|async"></app-loading-overlay>
        <mat-form-field
          class="white"
          appearance="outline"
          [ngStyle]="{'pointer-events': isBooked? 'none': 'initial'}"
        >
          <mat-label>Individueller Text</mat-label>
          <textarea
            [readonly]="isBooked || readonly"
            matTextareaAutosize
            matInput
            rows="10"
            formControlName="customText"
          ></textarea>
        </mat-form-field>

        <button
          *ngIf="!readonly && showSave && (loading$|async)===false"
          (click)="saveData()"
          style="position: absolute; right: 16px; bottom: 40px; border-radius: 4px !important;"
          mat-button
          color="green"
        >
          Änderungen speichern
        </button>
      </div>
    </div>
  `
})
export class InvoiceCustomTextComponent implements OnInit, OnDestroy {
  @Input() invoice$: Observable<InvoiceLike>;
  @Input() saveAction: any;
  @Input() @Optional() readonly = false;
  @Output() updateCustomText: EventEmitter<{ customText: string }> = new EventEmitter<{ customText: string }>();
  invoice: InvoiceLike;
  form: FormGroup;
  loading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
  }

  get showSave(): boolean {
    return this.invoice && this.form.get('customText').dirty && !this.isBooked;
  }

  get isBooked(): boolean {
    if (this.invoice && 'state' in this.invoice && (this.invoice.state === 'booked' || this.invoice.state === 'paid_out')) {
      return true;
    }
    if (this.invoice && 'confirmedAt' in this.invoice && 'acceptedOffer' in this.invoice && !!this.invoice.acceptedOffer) {
      return true;
    }
    return this.invoice && 'bookedBy' in this.invoice && !!this.invoice?.bookedBy;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customText: this.fb.control(null)
    });
    this.loading$ = isLoadingArray([
      this.store$.select(PayableInvoicesSelectors.isLoading),
      this.store$.select(CancellationInvoicesSelectors.isLoading),
      this.store$.select(CorrectionInvoicesSelectors.isLoading),
      this.store$.select(CorrectionInvoicesSelectors.isLoading),
    ]);
    this.invoice$
      .pipe(takeUntil(this.onDestroy$), filter(i => !!i))
      .subscribe(invoice => {
        this.invoice = invoice;
        switch (extractTypeByIri(invoice, true)) {
          case 'invoices':
          case 'partial_invoices':
            this.saveAction = PayableInvoicesActions.UpdatePayableInvoice;
            break;
          case 'offers':
            this.saveAction = OffersActions.UpdateOffer;
            break;
          case 'correction_invoices':
            this.saveAction = CorrectionInvoicesActions.UpdateCorrectionInvoice;
            break;
          case 'commission_credits':
            this.saveAction = CommissionCreditsActions.UpdateCommissionCredit;
            break;
          case 'cancellation_invoices':
            this.saveAction = CancellationInvoicesActions.UpdateCancellationInvoice;
            break;
          default:
            console.log('type', extractTypeByIri(invoice, true));

        }
        this.form.get('customText').setValue(invoice.customText || null);

        this.form.markAsUntouched();
        this.form.markAsPristine();

      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  saveData(): void {
    if (!extractIri(this.invoice, true)) {
      this.updateCustomText.emit({customText: this.form.get('customText').value});
    } else {
      this.store$.dispatch(this.saveAction({iri: extractIri(this.invoice), payload: this.form.getRawValue()}));
    }
  }

}
