import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { CorrectionInvoiceItem } from '../models';

@Injectable()
export class CorrectionInvoiceItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCorrectionInvoiceItem(
    payload: CorrectionInvoiceItem
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<CorrectionInvoiceItem>(
      'ROLE_MWS_CORRECTION_INVOICE_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/correction_invoice_items`, payload)
    );
  }

  readCorrectionInvoiceItem(iri: string): Observable<CorrectionInvoiceItem> {
    return this.rolesService.userHasRoleFilter<CorrectionInvoiceItem>(
      'ROLE_MWS_CORRECTION_INVOICE_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateCorrectionInvoiceItem(
    iri: string,
    payload: CorrectionInvoiceItem
  ): Observable<CorrectionInvoiceItem> {
    return this.rolesService.userHasRoleFilter<CorrectionInvoiceItem>(
      'ROLE_MWS_CORRECTION_INVOICE_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCorrectionInvoiceItem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<CorrectionInvoiceItem>(
      'ROLE_MWS_CORRECTION_INVOICE_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
