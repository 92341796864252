import {Injectable, Injector} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, tap} from 'rxjs/operators';

import {ModalDialogOptions} from '../../models';
import {DialogActions} from '../actions';
import {DialogComponent} from "../../../shared/components/dialog/dialog.component";

@Injectable()
export class DialogEffects {

  constructor(private actions$: Actions, private injector: Injector, private dialog: MatDialog) {
  }


  openDialog$ = createEffect(() => this.actions$.pipe(
    ofType(DialogActions.DialogOpen),
    // todo: needs to be renamed to modalConf
    switchMap(({payload}: { payload: ModalDialogOptions }) => {

        const config = {
          disableClose: true,
          ...payload.config
        };

        return this.dialog
          .open(DialogComponent, config)
          .afterClosed()
          .pipe(
            map(hasConfirmedModal => hasConfirmedModal ? DialogActions.DialogCloseWithConfirmation() : DialogActions.DialogCloseWithoutConfirmation())
          );
      }
    )
  ));


  closeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(
      DialogActions.DialogCloseWithConfirmation,
      DialogActions.DialogCloseWithoutConfirmation
    ),
    tap(() => this.dialog.closeAll())
  ), {dispatch: false});
}
