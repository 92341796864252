import {NavigationExtras} from '@angular/router';

import {createAction, props} from '@ngrx/store';

export const ROUTER_GO = '[Router] Go';
export const ROUTER_BACK = '[Router] Back';
export const ROUTER_FORWARD = '[Router] Forward';
export const CALL_NAVIGATE_TO_FROM_GLOBAL = '[Router] Call NavigateTo From Global';

export const Go = createAction(
  ROUTER_GO,
  props<{ path: Array<string>; query?: object; extras?: NavigationExtras | any }>()
);

export const Back = createAction(ROUTER_BACK);

export const Forward = createAction(ROUTER_FORWARD);

export const CallNavigateToFromGlobal = createAction(
  CALL_NAVIGATE_TO_FROM_GLOBAL,
  props<{ routerToken: string }>()
);
