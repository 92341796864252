import { RemoteAccessReasonsEffects } from './remote-access-reasons.effects';
import { TicketAttachmentsEffects } from './ticket-attachments.effects';
import { TicketCommentTagsEffects } from './ticket-comment-tags.effects';
import { TicketCommentsEffects } from './ticket-comments.effects';
import { TicketsEffects } from './tickets.effects';

export const effects = [
  RemoteAccessReasonsEffects,
  TicketAttachmentsEffects,
  TicketCommentTagsEffects,
  TicketCommentsEffects,
  TicketsEffects
];

export * from './remote-access-reasons.effects';
export * from './ticket-attachments.effects';
export * from './ticket-comment-tags.effects';
export * from './ticket-comments.effects';
export * from './tickets.effects';
