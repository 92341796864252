import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { Lead, UpdateLeadColumnsApiResponse } from '../models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { LeadsMail } from '../models/leads-mail.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

type LeadState = 'new' | 'archived' | 'anonymous';

@Injectable()
export class LeadsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  transformLead(iri: string, payload = {}): Observable<AbstractApiResponse> {
    const url = `${iri}/order_transformation`;
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ORDER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.patchObject(url, payload, true)
    );
  }

  createLead(payload: Lead): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/leads`, payload)
    );
  }

  getLeadById(id: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject('/leads/' + id)
    );
  }

  getLeadByIri(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readLeads(
    page = -1,
    itemsPerPage = 100,
    state?: LeadState
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/leads', page, { itemsPerPage, state })
            )
    );
  }

  updateLead({
    iri,
    payload
  }: {
    iri: string;
    payload: Lead;
  }): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  sendMail(iri: string, payload: LeadsMail): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_LEAD_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  updateLeadColumnsAndRanks({
    payload
  }: {
    payload: { leads: Lead[] };
  }): Observable<UpdateLeadColumnsApiResponse> {
    return this.rolesService.userHasRoleFilter<UpdateLeadColumnsApiResponse>(
      'ROLE_MWS_LEAD_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject('/leads/column_ranks', payload)
    );
  }

  deleteLead(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_LEAD_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }

  patchLead({
    iri,
    payload
  }: {
    iri: string;
    payload: { transition: string; workflow: string };
  }): Observable<AbstractApiResponse | any> {
    const url = `${iri}/transitions`;
    return this.apiService.patchObject(url, payload, true);
  }
}
