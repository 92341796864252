import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {PayableInvoicesActions} from '../store';
import {PayableInvoicesSelectors} from '../store/selectors';
import {CustomersSelectors} from '../../customers/store/selectors';
import {CustomersActions} from '../../customers/store';

@Injectable()
export class CurrentInvoiceLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const iri = route.params.iri;

    return this.currentInvoiceLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentInvoiceLoaded(iri: string): Observable<boolean | any> {
    return this.store.pipe(
      select(PayableInvoicesSelectors.selectCurrentInvoice),
      tap(invoice => {
        if (!invoice) {
          this.store.dispatch(PayableInvoicesActions.ReadPayableInvoice({iri}));
        }
      }),
      filter(invoice => !!invoice),
      take(2)
    );
  }
}
