import {FollowUpReasonsService} from './follow-up-reasons.service';
import {FollowUpsService} from './follow-up.service';

export const AllServices = [
  FollowUpReasonsService,
  FollowUpsService
];

export * from './follow-up-reasons.service';
export * from './follow-up.service';
