import {createSelector} from '@ngrx/store';

import {selectMasterDataModuleState, SYMPTOMS_FEATURE_KEY} from '../reducers';

export const selectSymptomsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[SYMPTOMS_FEATURE_KEY]
);

export const selectSymptomsEntities = createSelector(
  selectSymptomsState,
  (state) => state.entities
);

export const selectSymptoms = createSelector(
  selectSymptomsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectSymptomsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectSymptomsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectSymptomsState,
  (state) => state.loaded
);
