import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TermsAndConditionsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';

@Injectable()
export class TermsAndConditionsEffects {
  createTermsAndConditionsEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActions.CreateTermsAndConditionsEntity),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createTermsAndConditionsEntity(payload).pipe(
          map((data: any) => {
            console.log(data);
            if (!!data.body) {
              return TermsAndConditionsActions.CreateTermsAndConditionsEntitySuccess(
                { response: data.body }
              );
            } else {
              return TermsAndConditionsActions.CreateTermsAndConditionsEntityStatus(
                { response: data }
              );
            }
          }), // response => SpecialAgreementsActions.CreateSpecialAgreementSuccess({response})),
          catchError(response =>
            of(
              TermsAndConditionsActions.CreateTermsAndConditionsEntityFail({
                response
              })
            )
          )
        );
      })
    )
  );
  getTermsAndConditionsEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActions.ReadTermsAndConditionsEntity),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getTermsAndConditionsEntity(iri).pipe(
          map(response =>
            TermsAndConditionsActions.ReadTermsAndConditionsEntitySuccess({
              response
            })
          ),
          catchError(response =>
            of(
              TermsAndConditionsActions.ReadTermsAndConditionsEntityFail({
                response
              })
            )
          )
        );
      })
    )
  );
  getTermsAndConditionsEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActions.ReadTermsAndConditionsEntities),
      map(action => action),
      switchMap(() => {
        return this.service.getTermsAndConditionsEntities().pipe(
          map(response =>
            TermsAndConditionsActions.ReadTermsAndConditionsEntitiesSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              TermsAndConditionsActions.ReadTermsAndConditionsEntitiesFail({
                response
              })
            )
          )
        );
      })
    )
  );
  updateTermsAndConditionsEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActions.UpdateTermsAndConditionsEntity),
      map(action => action),
      switchMap(payload => {
        return this.service.updateTermsAndConditionsEntity(payload).pipe(
          map(response =>
            TermsAndConditionsActions.UpdateTermsAndConditionsEntitySuccess({
              response
            })
          ),
          catchError(response =>
            of(
              TermsAndConditionsActions.UpdateTermsAndConditionsEntityFail({
                response
              })
            )
          )
        );
      })
    )
  );
  deleteTermsAndConditionsEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActions.DeleteTermsAndConditionsEntity),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteTermsAndConditionsEntity(iri).pipe(
          map(() =>
            TermsAndConditionsActions.DeleteTermsAndConditionsEntitySuccess({
              iri
            })
          ),
          catchError(response =>
            of(
              TermsAndConditionsActions.DeleteTermsAndConditionsEntityFail({
                response
              })
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TermsAndConditionsActions.DeleteTermsAndConditionsEntityFail,
          TermsAndConditionsActions.UpdateTermsAndConditionsEntityFail,
          TermsAndConditionsActions.ReadTermsAndConditionsEntitiesFail,
          TermsAndConditionsActions.ReadTermsAndConditionsEntityFail,
          TermsAndConditionsActions.CreateTermsAndConditionsEntityFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TermsAndConditionsActions.CreateTermsAndConditionsEntitySuccess,
          TermsAndConditionsActions.UpdateTermsAndConditionsEntitySuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case TermsAndConditionsActions.CREATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS:
              message = 'Neue AGBs wurden angelegt.';
              break;
            case TermsAndConditionsActions.UPDATE_TERMS_AND_CONDITIONS_ENTITY_SUCCESS:
              message = 'Die AGBs wurden aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: TermsAndConditionsService,
    private notifierService: NotifierService
  ) {}
}
