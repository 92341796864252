import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';
import { Administrator } from '../models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';

@Injectable()
export class AdministratorsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readAdministrators(): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_USER_ADMINISTRATOR_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(`/administrators`, -1)
            )
    );
  }

  createAdministrator(payload: Administrator): Observable<Administrator> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_USER_ADMINISTRATOR_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/administrators`, payload)
    );
  }

  updateAdministrator(
    iri: string,
    payload: Administrator
  ): Observable<Administrator> {
    return this.rolesService.userHasRoleFilter<Administrator>(
      'ROLE_MWS_USER_ADMINISTRATOR_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteAdministrator(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_USER_ADMINISTRATOR_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
