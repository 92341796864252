import { Component, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { StorageSystemManufacturersSelectors } from '../../../master-data/store/selectors';
import { StorageSystemManufacturersActions } from '../../../master-data/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { Shipment } from '../../models';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { ShipmentsActions } from '../../store';
import { ShipmentsService } from '../../services/shipments.service';
import { getDescriptionOfShipmentState } from '../../helpers/shipmentStateDescriptionMapper';

@Component({
  selector: 'app-current-shipments-list',
  styleUrls: ['./current-shipments-list.component.scss'],
  template: `
    <div class="row  py-2" [formGroup]="form">
      <div class="col-2">
        <app-order-select formControlName="order"></app-order-select>
      </div>
      <div class="col-2">
        <app-tracker-input formControlName="trackerId"></app-tracker-input>
      </div>
      <div class="col-2">
        <mat-form-field>
          <mat-label>TrackingNumber</mat-label>
          <input type="text" formControlName="trackingNumber" matInput />
          <mat-error>
            <app-form-error
              [fieldName]="'trackingNumber'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field>
          <mat-label>Sendungsdatum</mat-label>
          <input type="date" formControlName="createdAt" matInput />
          <mat-error>
            <app-form-error
              [fieldName]="'createdAt'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        Zustand:
        <mat-button-toggle-group
          formControlName="state"
          aria-label="Shipment Zustand"
          multiple
        >
          <mat-button-toggle
            value="created"
            class="bold-if-checked"
            [matTooltip]="getDescriptionOfShipmentState('vorgemerkt')"
            >vorgemerkt
          </mat-button-toggle>
          <mat-button-toggle
            value="confirmed"
            class="bold-if-checked"
            [matTooltip]="getDescriptionOfShipmentState('bestätigt')"
            >bestätigt
          </mat-button-toggle>
          <mat-button-toggle
            value="voided"
            class="bold-if-checked"
            [matTooltip]="getDescriptionOfShipmentState('Storniert')"
            >Storniert
          </mat-button-toggle>
          <mat-button-toggle
            value="delivered"
            class="bold-if-checked"
            [matTooltip]="getDescriptionOfShipmentState('Zugestellt')"
            >Zugestellt
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="col pt-4">
        <a
          (click)="resetFilter()"
          class="link-primary cursor-pointer text-color-grey text-decoration-none"
          >Filter zurücksetzen</a
        >
      </div>
    </div>
    <div class="pos-relative" style="min-height: 300px;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <ng-container *ngIf="items$ | async as items">
        <div class="row" *ngFor="let item of items">
          <app-shipment-display-line
            [shipment]="item"
          ></app-shipment-display-line>
        </div>
        <div
          class="row"
          *ngIf="
            (isLoading$ | async) === false && (!items || items.length <= 0)
          "
        >
          <p>Es sind keine Sendungen mit den ausgewählten Filtern verfügbar!</p>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col text-right">
        <app-paginator-unstyled
          showFirstLastButtons="true"
          [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
          [pageSize]="currentPageSetting.pageSize"
          [pageIndex]="currentPageSetting.pageIndex"
          [totalItems]="itemCount$ | async"
          (handleUpdatePageOrSize)="updatePageOrSize($event)"
        ></app-paginator-unstyled>
      </div>
    </div>
  `
})
export class CurrentShipmentsListComponent extends BaseOnDestroyComponent
  implements OnInit {
  form: FormGroup;
  currentPageSetting: { pageSize: number; pageIndex: number } = {
    pageIndex: 0,
    pageSize: 15
  };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items$: BehaviorSubject<Array<Shipment>> = new BehaviorSubject<
    Array<Shipment>
  >([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly getDescriptionOfShipmentState = getDescriptionOfShipmentState;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: ShipmentsService,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
    private titleService: AbstractTitleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Aktive Sendungen');

    this.initForm();
    this.actions$
      .pipe(
        ofType(
          ShipmentsActions.DeleteShipmentSuccess,
          ShipmentsActions.ConfirmShipmentSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loadData();
      });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(params => {
        this.readQueryParams();
        this.loadData();
        //
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }

  resetFilter(): void {
    this.form.patchValue({
      order: null,
      state: ['created', 'confirmed'],
      trackingNumber: null,
      trackerId: null,
      createdAt: null
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null),
      trackerId: this.fb.control('', [Validators.required]),
      trackingNumber: this.fb.control(''),
      createdAt: this.fb.control(null),
      state: this.fb.control(['created', 'confirmed'])
    });
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(values => {
        this.updateQueryParams({ ...values, page: 0 }, true);
      });
  }

  updatePageOrSize($event: any): void {
    this.currentPageSetting = { ...this.currentPageSetting, ...$event };
    console.log($event);
    this.updateQueryParams({
      pageSize: $event.pageSize,
      page: $event.pageIndex + 1
    });
  }

  readQueryParams(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    for (const key of [
      'order',
      'state',
      'trackerId',
      'trackingNumber',
      'createdAt'
    ]) {
      if (params[key] && this.form.get(key).value !== params[key]) {
        this.form
          .get(key)
          .setValue(params[key], { onlySelf: true, emitEvent: false });
      }
    }
    if (params.pageSize) {
      this.currentPageSetting.pageSize = params.pageSize;
    }
    if (params.page) {
      this.currentPageSetting.pageIndex = params.page - 1;
    }
  }

  updateQueryParams(
    values: { [key: string]: string },
    alwaysLoad = false
  ): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = { ...currentQuery };
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {
      this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }

  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const page =
      parseInt(queryParams.page ? queryParams.page : 0, 10);
    const params: any = {
      itemsPerPage: queryParams.pageSize ? queryParams.pageSize : 15,
      pagination: true,
      'order[createdAt]': 'desc'
    };

    if (queryParams.order) {
      params['orders.uuid'] = getUuidFromIri(queryParams.order);
    }
    if (queryParams.trackerId) {
      params.trackerId = queryParams.trackerId;
    }
    if (queryParams.trackingNumber) {
      params.trackingNumber = queryParams.trackingNumber;
    }
    if (queryParams.createdAt) {
      params[
        'createdAt[strictly_before]'
      ] = `${queryParams.createdAt}T24:59:59`;
      params['createdAt[after]'] = `${queryParams.createdAt}T00:00:00`;
    }
    if (queryParams.state) {
      params.state = queryParams.state;
    } else {
      params.state = ['created', 'confirmed'];
    }

    this.backendService.readShipments(page, params).subscribe(
      data => {
        this.items$.next(data['hydra:member']);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }

        this.isLoading$.next(false);
      },
      error => {
        console.log(error);
        this.isLoading$.next(false);
      }
    );
  }
}
