import {Component} from '@angular/core';


@Component({
  selector: 'not-authenticated-app-shell',
  styleUrls: ['./app.component.scss'],
  template: `
    <router-outlet></router-outlet>`
})
export class UserNotAuthenticatedShellComponent {
}
