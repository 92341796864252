import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { ShipmentsActions } from '../../../shipping/store';
import { MatDialog } from '@angular/material/dialog';
import { CreateShipmentDialogComponent } from '../../components/create-shipment-dialog/create-shipment-dialog.component';
import { extractIri } from '../../utilities/objects.utility';
import { DataMedium } from '../../../warehouse/models';
import { OrdersActions } from '../../../orders/store';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-return-shipment-general',
  styleUrls: ['./action-box-return-shipment-general.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Versand zurück</div>
              <div class="sub-header col-auto">
                Bitte senden Sie den Datenträger zurück nach Leipzig
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right" [formGroup]="form">
            <button
              class="decision-btn me-3 btn-wider float-right"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="!dataMedia || dataMedia.length <= 0"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Versenden</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Rücksendung SS</div>
          <div class="sub-header col-auto">
            Warte auf Rücksendung des Speichersystem
          </div>
        </div>
      </div>
      <div class="additional-content p-4" *ngIf="canMakeTransition">
        <div class="row" *ngIf="order">
          <app-order-stock-item-select-list
            (updateSelectedDataMedia)="setSelectedDataMedia($event)"
            [orderIri]="extractIri(this.order)"
            [readonlyArchive]="true"
            [dataMediaFilterFn]="filterDrExtDT"
          ></app-order-stock-item-select-list>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxReturnShipmentGeneralComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;
  dataMedia: Array<string> = [];

  extractIri = extractIri;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        this.authService.isTechnicianExternal()) ||
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  filterDrExtDT = (dm: DataMedium) => {
    return (
      dm &&
      dm['@type'] === 'CustomerDataMedium' &&
      (!dm.state || (dm.state && dm.state === 'stored_dr_ext'))
    );
  };

  ngOnInit(): void {
    this.initForm();
    this.actions$
      .pipe(
        ofType(ShipmentsActions.CreateShipmentSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OrdersActions.ReadOrder({ iri: extractIri(this.order) })
        );
      });
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(this.order['@id']);
        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_prepare_dr') > -1 && !e.error
        );
      });
  }

  setSelectedDataMedia(list: Array<string>): void {
    this.dataMedia = list;
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      trackingNumber: this.fb.control('', [Validators.required]),
      shippingProvider: this.fb.control(
        '/api/shipping_providers_to_dr/SHIPPING_PROVIDER_GENERIC_SHIPMENT',
        [Validators.required]
      )
    });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    this.dialog.open(CreateShipmentDialogComponent, {
      panelClass: 'width-95',
      data: {
        shipment: {
          shippingProvider:
            '/api/shipping_providers_to_dr/SHIPPING_PROVIDER_GENERIC_SHIPMENT',
          order: extractIri(this.order),
          dataMedia: this.dataMedia,
          shipmentDirection:
            '/api/shipment_directions/SHIPMENT_DIRECTION_DR_EXT_TO_DR_DE'
        }
      }
    });
  }
}
