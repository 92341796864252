import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject} from 'rxjs';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'customer-form',
  template: `

    <form [formGroup]="cf">

      <div class="grid">
        <div class="column-14">

          <mat-form-field>
            <mat-label>Firmenname bei Firmen / Voller Name bei Privatpersonen</mat-label>
            <input type="text" matInput formControlName="nameLine1">
            <mat-hint align="start" *ngIf="errors?.nameLine1">{{ errors.nameLine1.message }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="column-14">

          <mat-form-field>
            <mat-label>Zusatz</mat-label>
            <input type="text" matInput formControlName="nameLine2">
            <mat-hint align="start" *ngIf="errors?.nameLine2">{{ errors.nameLine2.message }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="column-10" *ngIf="!presets$ || !presets$.getValue()">

          <div class="mat-form-field">

            <ng-select
              placeholder="Partner-Status"
              [items]="customerPartnerStatuses"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="partnerStatus"></ng-select>
            <mat-hint align="start" *ngIf="errors?.partnerStatus">{{ errors.partnerStatus.message }}</mat-hint>
          </div>
        </div>

        <div class="column-4">
          <mat-slide-toggle formControlName="vip" checked="false">VIP</mat-slide-toggle>
        </div>

        <div class="column-{{ presets$ && presets$.getValue() ? '10' : '14' }} mat-form-field">

          <ng-select
            placeholder="Partner-Typ"
            [items]="customerTypes"
            bindValue="@id"
            bindLabel="customerType"
            [clearable]="false"
            formControlName="customerType"></ng-select>
          <mat-hint align="start" *ngIf="errors?.customerType">{{ errors.customerType.message }}</mat-hint>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Sonstige Informationen</mat-label>
            <textarea matInput formControlName="customerInformation" rows="10"></textarea>
          </mat-form-field>
        </div>

        <div class="m-ta--2 column-14">

          <hr>

          <button class="btn--cancel m-r--8" (click)="handleCancelEdit()" type="button" color="outline" mat-flat-button>
            <mat-icon class="m-r--8">cancel</mat-icon>
            <span>Abbrechen</span>
          </button>

          <button class="btn--submit" [disabled]="cf.invalid || cf.untouched"
                  mat-flat-button color="green"
                  (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>{{ presets$ && !!presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
          </button>
        </div>
      </div>
    </form>
  `
})
export class CustomerFormComponent implements OnInit, AfterViewInit {

  @Input() customerPartnerStatuses: Array<fromModuleModels.CustomerPartnerStatus>;
  @Input() customerTypes: Array<fromModuleModels.CustomerType>;
  @Input() errors: ErrorsObject;
  @Input() isExternalCall: boolean;
  @Input() presets$: BehaviorSubject<fromModuleModels.Customer>;

  @Output()
  cancelEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestCreateCustomer: EventEmitter<fromModuleModels.Customer> = new EventEmitter<fromModuleModels.Customer>();

  @Output()
  requestUpdateCustomer: EventEmitter<{ iri: string, payload: fromModuleModels.Customer }> =
    new EventEmitter<{ iri: string, payload: fromModuleModels.Customer }>();

  cf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.cf = this.fb.group({
      nameLine1: this.fb.control(null, [Validators.required]),
      nameLine2: this.fb.control(null),
      vip: this.fb.control(false, [Validators.required]),
      customerInformation: this.fb.control(''),
      customerType: this.fb.control('', [Validators.required]),
      partnerStatus: this.fb.control('', [Validators.required])
    });
  }

  ngAfterViewInit(): void {
    // todo: subscribe?

    if (this.presets$ && !!this.presets$.getValue()) {
      this.fs.patchForm(this.cf, this.presets$.getValue());
    } else {
      this.cf.get('customerType').setValue(this.customerTypes[0]['@id']);
      this.cf.get('partnerStatus').setValue(this.customerPartnerStatuses[0]['@id']);
    }
  }

  handleSubmit(): void {

    const payload: fromModuleModels.Customer = this.cf.value;
    this.presets$ && !!this.presets$.getValue()
      ? this.requestUpdateCustomer.emit({iri: this.presets$.getValue()['@id'], payload})
      : this.requestCreateCustomer.emit(payload);
  }

  handleCancelEdit(): void {
    this.presets$?.next(null);
    this.cf.reset();
    this.cancelEdit.emit();
  }
}
