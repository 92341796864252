import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'request-reset-password-form',
  styleUrls: ['request-reset-password-form.component.scss'],
  template: `
    <form [formGroup]="form">
      <div class="grid">
        <div class=" column-14 alert alert-info">
          Das Zurücksetzen eines Passwortes ist nur alle 2 Stunden möglich.
          <br />Der versendete Zurücksetzten-Link hat eine Gültigkeit von 24
          Stunden.
        </div>
        <mat-form-field class="column-14">
          <mat-label>Nutzername oder E-Mail</mat-label>
          <input type="text" matInput formControlName="username" />
          <mat-error>
            <app-form-error
              fieldName="username"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>

        <div class="mat-form-field submit m-ta--2 column-14">
          <button
            [disabled]="form.invalid"
            (click)="handleRequestCreateResetPasswordRequest()"
            mat-flat-button
            color="green"
          >
            Anforderung senden
          </button>
        </div>
      </div>
    </form>
  `
})
export class RequestResetPasswordFormComponent implements OnInit {
  @Input() errors: ErrorsObject;

  @Output() requestCreateResetPasswordRequest: EventEmitter<{
    username: string;
  }> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      username: this.fb.control(null, Validators.required)
    });
  }

  handleRequestCreateResetPasswordRequest(): void {
    this.requestCreateResetPasswordRequest.emit(this.form.value);
  }
}
