import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ShipmentDirectionsActions} from '../actions';
import {ShipmentDirectionsService} from '../../services/shipment-directions.service';

@Injectable()
export class ShipmentDirectionsEffects {

  constructor(private actions$: Actions, private service: ShipmentDirectionsService) {
  }


  getShipmentDirection$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentDirectionsActions.ReadShipmentDirection),
    map(action => action),
    switchMap(({iri}) => {
      return this.service
        .getShipmentDirection(iri)
        .pipe(
          map(response => ShipmentDirectionsActions.ReadShipmentDirectionSuccess({response})),
          catchError(response => of(ShipmentDirectionsActions.ReadShipmentDirectionFail({response})))
        );
    })
  ));


  getShipmentDirections$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentDirectionsActions.ReadShipmentDirections),
    map(action => action),
    switchMap(() => {
      return this.service
        .getShipmentDirections()
        .pipe(
          map(response => ShipmentDirectionsActions.ReadShipmentDirectionsSuccess({response})),
          catchError(response => of(ShipmentDirectionsActions.ReadShipmentDirectionsFail({response})))
        );
    })
  ));
}
