import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_COMMISSION_CREDIT_ITEM = '[Invoices Module] Create Commission Credit Item';
export const CREATE_COMMISSION_CREDIT_ITEM_FAIL = '[Invoices Module] Create Commission Credit Item Fail';
export const CREATE_COMMISSION_CREDIT_ITEM_SUCCESS = '[Invoices Module] Create Commission Credit Item Success';

export const CreateCommissionCreditItem = createAction(
  CREATE_COMMISSION_CREDIT_ITEM,
  props<{ payload: any, invoiceIri: string }>()
);

export const CreateCommissionCreditItemSuccess = createAction(
  CREATE_COMMISSION_CREDIT_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.CommissionCreditItem, invoiceIri: string }>()
);

export const CreateCommissionCreditItemFail = createAction(
  CREATE_COMMISSION_CREDIT_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_COMMISSION_CREDIT_ITEM = '[Invoices Module] Read Commission Credit Item';
export const READ_COMMISSION_CREDIT_ITEM_SUCCESS = '[Invoices Module] Read Commission Credit Item Success';
export const READ_COMMISSION_CREDIT_ITEM_FAIL = '[Invoices Module] Read Commission Credit Item Fail';

export const ReadCommissionCreditItem = createAction(
  READ_COMMISSION_CREDIT_ITEM,
  props<{ iri: string }>()
);

export const ReadCommissionCreditItemSuccess = createAction(
  READ_COMMISSION_CREDIT_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.CommissionCreditItem | any }>()
);

export const ReadCommissionCreditItemFail = createAction(
  READ_COMMISSION_CREDIT_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_COMMISSION_CREDIT_ITEMS = '[Invoices Module] Read Commission Credit Items';
export const READ_COMMISSION_CREDIT_ITEMS_SUCCESS = '[Invoices Module] Read Commission Credit Items Success';
export const READ_COMMISSION_CREDIT_ITEMS_FAIL = '[Invoices Module] Read Commission Credit Items Fail';

export const ReadCommissionCreditItems = createAction(
  READ_COMMISSION_CREDIT_ITEMS
);

export const ReadCommissionCreditItemsSuccess = createAction(
  READ_COMMISSION_CREDIT_ITEMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCommissionCreditItemsFail = createAction(
  READ_COMMISSION_CREDIT_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_COMMISSION_CREDIT_ITEM = '[Invoices Module] Update Commission Credit Item';
export const UPDATE_COMMISSION_CREDIT_ITEM_SUCCESS = '[Invoices Module] Update Commission Credit Item Success';
export const UPDATE_COMMISSION_CREDIT_ITEM_FAIL = '[Invoices Module] Update Commission Credit Item Fail';

export const UpdateCommissionCreditItem = createAction(
  UPDATE_COMMISSION_CREDIT_ITEM,
  props<{ iri: string, payload: fromInvoicesModuleModels.CommissionCreditItem | any, invoiceIri: string }>()
);

export const UpdateCommissionCreditItemSuccess = createAction(
  UPDATE_COMMISSION_CREDIT_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.CommissionCreditItem, invoiceIri: string }>()
);

export const UpdateCommissionCreditItemFail = createAction(
  UPDATE_COMMISSION_CREDIT_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_COMMISSION_CREDIT_ITEM = '[Invoices Module] Delete Commission Credit Item';
export const DELETE_COMMISSION_CREDIT_ITEM_SUCCESS = '[Invoices Module] Delete Commission Credit Item Success';
export const DELETE_COMMISSION_CREDIT_ITEM_FAIL = '[Invoices Module] Delete Commission Credit Item Fail';

export const DeleteCommissionCreditItem = createAction(
  DELETE_COMMISSION_CREDIT_ITEM,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteCommissionCreditItemSuccess = createAction(
  DELETE_COMMISSION_CREDIT_ITEM_SUCCESS,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteCommissionCreditItemFail = createAction(
  DELETE_COMMISSION_CREDIT_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
