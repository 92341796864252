import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BindingOrdersActions } from '../actions';
import { BindingOrdersService } from '../../services';

@Injectable()
export class BindingOrdersEffects {
  createBindingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BindingOrdersActions.CreateBindingOrder),
      switchMap(({ payload }) => {
        return this.service.createBindingOrder(payload).pipe(
          map((response: any) =>
            BindingOrdersActions.CreateBindingOrderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(BindingOrdersActions.CreateBindingOrderFail({ response }))
          )
        );
      })
    )
  );
  uploadBindingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BindingOrdersActions.UploadBindingOrder),
      switchMap(({ payload }) => {
        return this.service.createBindingOrderEntity(payload).pipe(
          map((data: any) => {
            if (!!data.body) {
              return BindingOrdersActions.UploadBindingOrderSuccess({
                response: data
              });
            } else {
              return BindingOrdersActions.UploadBindingOrderStatus({
                response: data
              });
            }
          }),
          catchError((response: HttpErrorResponse) =>
            of(BindingOrdersActions.UploadBindingOrderFail({ response }))
          )
        );
      })
    )
  );
  readBindingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BindingOrdersActions.ReadBindingOrder),
      switchMap(({ iri }) => {
        return this.service.readBindingOrder(iri).pipe(
          map((response: any) =>
            BindingOrdersActions.ReadBindingOrderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(BindingOrdersActions.ReadBindingOrderFail({ response }))
          )
        );
      })
    )
  );
  readBindingOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BindingOrdersActions.ReadBindingOrders),
      switchMap(() => {
        return this.service.readBindingOrders().pipe(
          map((response: any) =>
            BindingOrdersActions.ReadBindingOrdersSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(BindingOrdersActions.ReadBindingOrdersFail({ response }))
          )
        );
      })
    )
  );
  deleteBindingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BindingOrdersActions.DeleteBindingOrder),
      switchMap(({ iri }) => {
        return this.service.deleteBindingOrder(iri).pipe(
          map(() => BindingOrdersActions.DeleteBindingOrderSuccess({ iri })),
          catchError((response: HttpErrorResponse) =>
            of(BindingOrdersActions.DeleteBindingOrderFail({ response }))
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: BindingOrdersService
  ) {}
}
