import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER_COMMENT_TAG = '[Orders Module] Read Order Comment Tag';
export const READ_ORDER_COMMENT_TAG_SUCCESS = '[Orders Module] Read Order Comment Tag Success';
export const READ_ORDER_COMMENT_TAG_FAIL = '[Orders Module] Read Order Comment Tag Fail';

export const ReadOrderCommentTag = createAction(
  READ_ORDER_COMMENT_TAG,
  props<{ iri: string }>()
);

export const ReadOrderCommentTagSuccess = createAction(
  READ_ORDER_COMMENT_TAG_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderCommentTagFail = createAction(
  READ_ORDER_COMMENT_TAG_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_COMMENT_TAGS = '[Orders Module] Read Order Comment Tags';
export const READ_ORDER_COMMENT_TAGS_SUCCESS = '[Orders Module] Read Order Comment Tags Success';
export const READ_ORDER_COMMENT_TAGS_FAIL = '[Orders Module] Read Order Comment Tags Fail';

export const ReadOrderCommentTags = createAction(
  READ_ORDER_COMMENT_TAGS,
);

export const ReadOrderCommentTagsSuccess = createAction(
  READ_ORDER_COMMENT_TAGS_SUCCESS,
  props<{ response: any }>()
);

export const ReadOrderCommentTagsFail = createAction(
  READ_ORDER_COMMENT_TAGS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
