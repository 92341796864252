import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromFollowUpReasonsReducers from './follow-up-reasons.reducers';
import * as fromFollowUpReducers from './follow-up.reducers';

export const DASHBOARD_MODULE_FEATURE_KEY = 'dashboardModule';

export const FOLLOW_UPS_FEATURE_KEY = 'followUps';
export const FOLLOW_UP_REASONS_FEATURE_KEY = 'followUpReasons';

export interface DashboardModuleState {
  [FOLLOW_UP_REASONS_FEATURE_KEY]: fromFollowUpReasonsReducers.State;
  [FOLLOW_UPS_FEATURE_KEY]: fromFollowUpReducers.State;
}

export const selectDashboardModuleState = createFeatureSelector<DashboardModuleState>(DASHBOARD_MODULE_FEATURE_KEY);

export function reducers(state: DashboardModuleState, action: Action): any {
  return combineReducers({
    [FOLLOW_UP_REASONS_FEATURE_KEY]: fromFollowUpReasonsReducers.reducer,
    [FOLLOW_UPS_FEATURE_KEY]: fromFollowUpReducers.reducer,
  })(state, action);
}
