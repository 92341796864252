import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {Ticket, TicketComment} from '../../models';
import {takeUntil} from 'rxjs/operators';
import {TicketCommentsActions} from '../../store';

@Component({
  selector: 'app-ticket-comment-form-dialog',
  styleUrls: ['./ticket-comment-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Kommentar bearbeiten</h2>
    </app-dialog-header>
    <div mat-dialog-content>
      <app-ticket-comment-form
        *ngIf="data"
        [ticket$]="data.ticket$"
        [comment$]="data.comment$"
      ></app-ticket-comment-form>
    </div>
  `


})
export class TicketCommentFormDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                ticket$: BehaviorSubject<Ticket>,
                comment$: BehaviorSubject<TicketComment>;
              },
              public dialogRef: MatDialogRef<TicketCommentFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions
  ) {
  }

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(TicketCommentsActions.CreateTicketCommentSuccess, TicketCommentsActions.UpdateTicketCommentSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
