import {createAction, props} from '@ngrx/store';

import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

export const LOAD_CUSTOMER_TYPES = '[Customers] Load Customer Types';
export const LOAD_CUSTOMER_TYPES_SUCCESS = '[Customers] Load Customer Types Success';
export const LOAD_CUSTOMER_TYPES_FAIL = '[Customers] Load Customer Types Fail';

export const LoadCustomerTypes = createAction(
  LOAD_CUSTOMER_TYPES
);

export const LoadCustomerTypesSuccess = createAction(
  LOAD_CUSTOMER_TYPES_SUCCESS,
  props<{ response: any }>()
);

export const LoadCustomerTypesFail = createAction(
  LOAD_CUSTOMER_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
