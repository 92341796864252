import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {take, tap, switchMap, catchError} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';

import {OrderCommentsActions} from '../store';
import {OrderCommentsSelectors} from '../store/selectors';
import {StringsUtility} from '../../shared/utilities/strings.utility';

@Injectable()
export class OrderCommentsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const uuid = route.params.uuid;

    return this.orderCommentsLoaded(uuid).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  orderCommentsLoaded(orderUuid: string): Observable<boolean> {

    return this.store.pipe(
      select(OrderCommentsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(OrderCommentsActions.ReadOrderComments({
            page: 1,
            params: {'order.uuid': orderUuid, 'order[createdAt]': 'desc'}
          }));
        }
      }),
      take(1)
    );
  }
}
