import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormsService} from '../../../shared/services';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {Address, BillingAddress, Customer, CustomerAddress} from '../../../customers/models';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {Observable} from 'rxjs';
import {CustomerAddressesSelectors, CustomersSelectors} from '../../../customers/store/selectors';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {CustomerAddressesActions, CustomersActions} from '../../../customers/store';
import {Order} from '../../../orders/models';
import {extractIri, extractUUID} from '../../../shared/utilities/objects.utility';
import {CorrectionInvoicesActions} from '../../store';
import {RouterActions} from '../../../application-state/store/actions';
import {Department} from '../../../master-data/models';
import {CorrectionInvoicesSelectors} from "../../store/selectors";
import {isLoadingArray} from "../../../shared/utilities/observable.utility";

@Component({
  selector: 'app-correction-invoice-new',
  styleUrls: ['correction-invoice-new.component.scss'],
  template: `

    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="card pos-relative">
            <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
            <div class="card-header"><h1>Faktura: Korrekturrechnung erstellen</h1></div>
            <div class="card-body" [formGroup]="form">
              <div class="row">
                <div class="col">
                  <app-department-select formControlName="issuer"
                                         required
                                         (selectDepartment)="handleSelectIssuer($event)"></app-department-select>
                </div>
              </div>
              <div class="row">

                <div class="col-6">
                  <h2>Auftrag auswählen</h2>
                  <app-order-select formControlName="order"
                                    (updateSelectedObject)="handleSelectOrder($event)"
                  ></app-order-select>
                </div>
                <div class="col-6">
                  <h2>Kunden auswählen</h2>

                  <app-customer-select
                    label="Firma oder Kunde"
                    formControlName="customer"
                    required
                    (selectCustomer)="handleSelectCustomer($event)"></app-customer-select>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ng-select
                    [items]="customerAddresses$ | async"
                    notFoundText="Keine Ergebnisse"
                    [searchable]="false"
                    [clearable]="false"
                    required
                    [readonly]="!form.get('customer').value"
                    (change)="handleUpdateRecipient($event)"
                    formControlName="address"
                    bindValue="@id"
                    placeholder="{{!!form.get('customer').value? 'Adressen für diesen Kunden:': 'Zunächst Kunde oder Auftrag wählen'}}"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="ng-option">
                        <span>{{ formatAddress(item.address) }}</span>
                      </div>
                    </ng-template>

                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <div class="ng-option">
                        <span>{{ formatAddress(item.address) }}</span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>

              <pre>{{form.getRawValue()|json}}</pre>
            </div>
            <div class="card-footer text-right">
              <button mat-flat-button color="green" [disabled]="form.invalid" (click)="save()">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  `
})
export class CorrectionInvoiceNewComponent extends BaseOnDestroyComponent implements OnInit {

  form: FormGroup;
  customerAddresses$: Observable<Array<CustomerAddress>>;
  customerAddress: CustomerAddress;
  isLoading$: Observable<boolean>;
  order: Order;
  issuer: Department;
  customer: Customer;

  constructor(public dialog: MatDialog,
              private store$: Store<ApplicationState>,
              private fb: FormBuilder,
              private fs: FormsService,
              @Inject(LOCALE_ID) private locale: string,
              private actions$: Actions,
  ) {
    super();
  }


  ngOnInit(): void {
    this.initForms();
    this.isLoading$ = isLoadingArray([
      this.store$.select(CorrectionInvoicesSelectors.isLoading),
      this.store$.select(CustomerAddressesSelectors.isLoading),
    ]);

  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  initForms(): void {
    this.form = this.fb.group({
      issuer: this.fb.control(null, [Validators.required]),
      customer: this.fb.control(null, [Validators.required]),
      address: this.fb.control(null, [Validators.required]),
      order: this.fb.control(null),
    });
  }

  handleSelectCustomer(customer: Customer): void {
    if (customer) {
      if (this.form.get('order')) {
        this.form.get('order').setValue(null);
      }
      this.setCustomerAndReadCustomerAddresses(customer['@id']);
    }
  }

  handleUpdateRecipient(recipient: CustomerAddress): void {
    this.customerAddress = recipient;
  }

  setCustomerAndReadCustomerAddresses(customerIri: string): void {
    this.store$.select(CustomersSelectors.selectCustomerByIndex, {iri: customerIri})
      .pipe(tap(e => {
          if (!e) {
            this.store$.dispatch(CustomersActions.ReadCustomer({iri: customerIri}));
          }
        }),
        filter(e => !!e)
        , take(1)).subscribe(c => {
      console.log('customer=', c);
      this.customer = c;

    });

    this.customerAddresses$ = this.store$.select(CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri, {customerIri});
    this.customerAddresses$
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e && e.length > 0))
      .subscribe(addresses => {
        console.log(addresses);
        const addressIri = this.order?.billingAddress ? this.order?.billingAddress : this.customer?.defaultBillingAddress;
        if (addressIri) {
          this.form.get('address').setValue(addressIri, {selfOnly: false});
          this.handleUpdateRecipient(addresses.find(e => e['@id'] === addressIri));
        } else {
          if (addresses.length === 1) {
            this.handleUpdateRecipient(addresses[0]);
            this.form.get('address').setValue(extractIri(addresses[0]), {selfOnly: false});

          } else {
            this.form.get('address').setValue(null, {selfOnly: false});
          }
        }
      });
    this.store$.dispatch(CustomerAddressesActions.ReadCustomerBillingAddresses({customerIri}));

  }

  handleSelectOrder(order: Order): void {
    console.log(order);
    this.order = order;
    if (!order) {
      return;
    }
    this.form.get('customer').setValue(order.customer['@id'], {selfOnly: false});
    this.setCustomerAndReadCustomerAddresses(order.customer['@id']);
  }


  save(): void {
    // if(!this.customer || !this.customerAddress || !this.issuer){
    //   this.form.
    // }

    const billingAddress = this.customerAddress;
    console.log('department', this.issuer);
    const issuer = this.form.get('issuer').value;
    console.log('address', this.customerAddress);
    const payload = {
      correctedInvoice: null,
      // paymentProcess,
      issuer,
      recipient: {
        taxNumber: this.customer.taxNumber,
        nameLine1: this.customer.nameLine1,
        nameLine2: this.customer.nameLine2,
        address: {
          line1: billingAddress.address.line1,
          line2: billingAddress.address.line2,
          line3: billingAddress.address.line3,
          line4: billingAddress.address.line4,
          city: billingAddress.address.city,
          zipPostcode: billingAddress.address.zipPostcode,
          stateProvinceCounty: billingAddress.address.stateProvinceCounty,
          country: billingAddress.address.country
        },
        addressType: extractIri(billingAddress.addressType)
      },
      customerNumber: this.customer.customerNumber,
    };
    this.store$.dispatch(CorrectionInvoicesActions.CreateCorrectionInvoice({payload}));
    this.actions$.pipe(ofType(CorrectionInvoicesActions.CreateCorrectionInvoiceSuccess), take(1))
      .subscribe((invoiceResult) => {
        this.store$.dispatch(
          RouterActions.Go({
            path:
              ['/invoices', 'corrections', extractUUID(invoiceResult.response)]
          })
        );

      });
  }

  handleSelectIssuer($event: Department): void {
    this.issuer = $event;
  }
}
