import {createSelector} from '@ngrx/store';

import {LOCALES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectLocalesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[LOCALES_FEATURE_KEY]
);

export const selectLocalesEntities = createSelector(
  selectLocalesState,
  (state) => state.entities
);

export const selectLocales = createSelector(
  selectLocalesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectLocalesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectLocalesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectLocalesState,
  (state) => state.loaded
);
