import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_LAB_LOCATION = '[Master Data] Read Lab Location';
export const READ_LAB_LOCATION_SUCCESS = '[Master Data] Read Lab Location Success';
export const READ_LAB_LOCATION_FAIL = '[Master Data] Read Lab Location Fail';
export const ReadLabLocation = createAction(
  READ_LAB_LOCATION,
  props<{ iri: string }>()
);
export const ReadLabLocationSuccess = createAction(
  READ_LAB_LOCATION_SUCCESS,
  props<{ response }>()
);
export const ReadLabLocationFail = createAction(
  READ_LAB_LOCATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LAB_LOCATIONS = '[Master Data] Read Lab Locations';
export const READ_LAB_LOCATIONS_SUCCESS = '[Master Data] Read Lab Locations Success';
export const READ_LAB_LOCATIONS_FAIL = '[Master Data] Read Lab Locations Fail';
export const ReadLabLocations = createAction(
  READ_LAB_LOCATIONS
);
export const ReadLabLocationsSuccess = createAction(
  READ_LAB_LOCATIONS_SUCCESS,
  props<{ response: any }>()
);
export const ReadLabLocationsFail = createAction(
  READ_LAB_LOCATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
