import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageLocationsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { StorageLocationsService } from '../../services/storage-locations.service';

@Injectable()
export class StorageLocationsEffects {
  CreateStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.CreateStorageLocation),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createStorageLocation(payload).pipe(
          map(response =>
            StorageLocationsActions.CreateStorageLocationSuccess({ response })
          ),
          catchError(response =>
            of(StorageLocationsActions.CreateStorageLocationFail({ response }))
          )
        );
      })
    )
  );
  ReadStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.ReadStorageLocation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getStorageLocation(iri).pipe(
          map(response =>
            StorageLocationsActions.ReadStorageLocationSuccess({ response })
          ),
          catchError(response =>
            of(StorageLocationsActions.ReadStorageLocationFail({ response }))
          )
        );
      })
    )
  );
  ReadStorageLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.ReadStorageLocations),
      map(action => action),
      switchMap(({ page, params }) => {
        return this.service.getStorageLocations(page, params).pipe(
          map(response =>
            StorageLocationsActions.ReadStorageLocationsSuccess({ response })
          ),
          catchError(response =>
            of(StorageLocationsActions.ReadStorageLocationsFail({ response }))
          )
        );
      })
    )
  );
  ReadStorageLocationsChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.ReadStorageLocationsChunk),
      map(action => action),
      switchMap(({ uri }) => {
        return this.service.readStorageLocationsChunk(uri).pipe(
          map(response =>
            StorageLocationsActions.ReadStorageLocationsChunkSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              StorageLocationsActions.ReadStorageLocationsChunkFail({
                response
              })
            )
          )
        );
      })
    )
  );
  UpdateStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.UpdateStorageLocation),
      map(action => action),
      switchMap(payload => {
        return this.service.updateStorageLocation(payload).pipe(
          map(response =>
            StorageLocationsActions.UpdateStorageLocationSuccess({ response })
          ),
          catchError(response =>
            of(StorageLocationsActions.UpdateStorageLocationFail({ response }))
          )
        );
      })
    )
  );
  DeleteStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationsActions.DeleteStorageLocation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteStorageLocation(iri).pipe(
          map(() =>
            StorageLocationsActions.DeleteStorageLocationSuccess({ iri })
          ),
          catchError(response =>
            of(StorageLocationsActions.DeleteStorageLocationFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StorageLocationsActions.DeleteStorageLocationFail,
          StorageLocationsActions.UpdateStorageLocationFail,
          StorageLocationsActions.ReadStorageLocationsChunkFail,
          StorageLocationsActions.ReadStorageLocationsFail,
          StorageLocationsActions.ReadStorageLocationFail,
          StorageLocationsActions.CreateStorageLocationFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  CreateOrUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageLocationsActions.CreateStorageLocationSuccess,
        StorageLocationsActions.UpdateStorageLocationSuccess
      ),
      map(({ type }) => {
        const text =
          type === StorageLocationsActions.CREATE_STORAGE_LOCATION_SUCCESS
            ? 'Eine neue Lagerplatznummer wurde angelegt.'
            : 'Die Lagerplatznummer wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: StorageLocationsService,
    private notifierService: NotifierService
  ) {}
}
