import {createSelector} from "@ngrx/store";

//IMPORT
import {ADMINISTRATOR_FEEDBACK_REQUESTS_FEATURE_KEY, selectAdministratorsModuleState} from "../reducers";
import {FeedbackRequest} from "../../models/administrator-feedback.interface";

const NAMESPACE = ADMINISTRATOR_FEEDBACK_REQUESTS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectAdministratorsModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const selectSendFeedbackRequestsEntities = createSelector(
  sState,
  (state) => state.sendEntities
);
export const selectRecievedFeedbackRequestsEntities = createSelector(
  sState,
  (state) => state.recievedEntities
);

// export const sList = createSelector(sEntities, (entities) => Object.values(entities));
// export const sByIri = createSelector(sEntities, (entities: any, {iri}) => entities[iri]);

export const selectRecievedFeedbackRequests = createSelector(
  selectSendFeedbackRequestsEntities,
  (feedbackRequest): FeedbackRequest[] => Object.values(feedbackRequest)
);
export const selectSendFeedbackRequests = createSelector(
  selectRecievedFeedbackRequestsEntities,
  (feedbackRequest): FeedbackRequest[] => Object.values(feedbackRequest)
);
