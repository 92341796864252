<div [ngStyle]="getBackgroundColor()" class="wrapper">
  <h2 mat-dialog-title>Wiedervorlage
    <mat-icon (click)="deleteFollowUp()" *ngIf="data && data.followUp"
              class="mat-dialog-title-action text-color-red cursor-pointer">delete_forever
    </mat-icon>
    <mat-icon (click)="merkAsDone()" *ngIf="data && data.followUp"
              class="mat-dialog-title-action text-color-green m-r--8 cursor-pointer">
      check_circle
    </mat-icon>
  </h2>
  <mat-dialog-content>
    <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
    <form [formGroup]="followUpForm" autocomplete="off">
      <ng-content></ng-content>

      <div class="grid">
        <div class="column-5">
          <ng-select
            [clearable]="false"
            [items]="COLORS"
            [searchable]="false"
            bindLabel="text"
            bindValue="color"
            class="dense"
            formControlName="color"
            notFoundText="Keine Farbe."
          >

            <ng-template let-item="item" ng-label-tmp>
              <mat-icon *ngIf="item.color" [style.color]="item.color" style="font-size: 12px;">circle</mat-icon>
              <div *ngIf="item.color" class="color-text">{{ (item.text) }}</div>
              <span *ngIf="!item.color">Keine Farbe.</span>
            </ng-template>

            <ng-template let-item="item" ng-option-tmp>
              <mat-icon [style.color]="item.color" style="font-size: 12px;">circle</mat-icon>
              <div class="color-text">{{ (item.text) }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="grid">

        <div class="column-auto">
          <ng-select
            (open)="openOrderSelectDialog()"
            [clearable]="true"
            [items]="orders$ | async"
            [searchable]="false"
            bindLabel="text"
            bindValue="@id"
            class="dense"
            formControlName="order"
            placeholder="Auftrag"
          >

            <ng-template let-item="item" ng-label-tmp>
              <span *ngIf="item.orderNumber"
                    class="m-r--8">#{{item.orderNumber}}</span>
            </ng-template>

            <ng-template let-item="item" ng-option-tmp>
              <span *ngIf="item.orderNumber"
                    class="m-r--8">#{{item.orderNumber}}</span>
            </ng-template>

          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'order'" [formGroup]="followUpForm"></app-form-error>
          </mat-error>
        </div>
      </div>
      <div class="grid">

        <div class="column-auto">
          <app-customer-select
            (selectCustomer)="selectCustomer($event)"
            [preset$]="customer$"
            label="Kunde*"
          ></app-customer-select>

          <mat-error>
            <app-form-error [fieldName]="'customer'" [formGroup]="followUpForm"></app-form-error>
          </mat-error>

        </div>
      </div>

      <div class="grid">

        <div class="column-7">
          <ng-select
            [clearable]="false"
            [items]="followUpReasons$ | async"
            [loading]="followUpReasonsIsLoading$ | async"
            [searchable]="false"
            bindLabel="name"
            bindValue="@id"
            formControlName="reason"
            placeholder="Benachrichtigungsgrund*"
          ></ng-select>
          <mat-error>
            <app-form-error [fieldName]="'reason'" [formGroup]="followUpForm"></app-form-error>
          </mat-error>
        </div>

        <div class="column-7">
          <ng-select
            [clearable]="false"
            [items]="employees$ | async"
            [loading]="employeesIsLoading$ | async"
            [searchFn]="findEmployee"
            [searchable]="true"
            bindLabel="text"
            bindValue="@id"
            class="dense"
            formControlName="employee"
            placeholder="Mitarbeiter"
          >

            <ng-template let-item="item" ng-label-tmp>
              <span>{{item.firstName}} {{item.lastName}}</span>
            </ng-template>

            <ng-template let-item="item" ng-option-tmp>
              <span>{{item.firstName}} {{item.lastName}}</span>
            </ng-template>
          </ng-select>
          <mat-error>
            <app-form-error [fieldName]="'employee'" [formGroup]="followUpForm"></app-form-error>
          </mat-error>
        </div>
      </div>

      <div class="grid">

        <div class="column-7">
          <mat-form-field>
            <mat-label>Datum</mat-label>
            <input formControlName="date" matInput required type="date">
            <mat-error>
              <app-form-error [fieldName]="'date'" [formGroup]="followUpForm"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-7">
          <mat-form-field>
            <mat-label>Uhrzeit</mat-label>
            <input formControlName="time" matInput required type="time">
            <mat-error>
              <app-form-error [fieldName]="'time'" [formGroup]="followUpForm"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="grid">

        <div class="column-auto">
          <mat-form-field>
            <mat-label>Title der Wiedervorlage<sup>*</sup></mat-label>
            <input formControlName="title" matInput type="text">
            <mat-error>
              <app-form-error [fieldName]="'title'" [formGroup]="followUpForm"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="grid">

        <div class="column-auto">
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <textarea formControlName="description" matInput rows="7" type="text"> </textarea>
            <mat-error>
              <app-form-error [fieldName]="'description'" [formGroup]="followUpForm"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <button (click)="resetAndClose()" class="btn--cancel mx-2" color="outline" mat-flat-button type="button">
      <mat-icon class="m-r--8">cancel</mat-icon>
      <span>Abbrechen</span>
    </button>
    <button (click)="handleSubmit()" [disabled]="followUpForm.invalid || followUpForm.untouched"
            class="btn--submit mx-2" color="green" mat-flat-button>
      <mat-icon class="m-r--8">save</mat-icon>
      <span>Wiedervorlage {{ data && data.followUp && data.followUp['@id'] ? 'ändern' : 'anlegen' }}</span>
    </button>
  </mat-dialog-actions>
</div>
