import { LeadViewComponent } from './lead-view/lead-view.component';
import { LeadsArchiveComponent } from './leads-archive/leads-archive.component';
import { LeadsViewComponent } from './leads-view/leads-view.component';

export const AllContainers = [
  LeadViewComponent,
  LeadsArchiveComponent,
  LeadsViewComponent
];

export * from './lead-view/lead-view.component';
export * from './leads-archive/leads-archive.component';
export * from './leads-view/leads-view.component';
