import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

// Read
export const LOAD_LEAD_ORIGIN_TYPES = '[Leads Module] Load Lead Origin Types';
export const LOAD_LEAD_ORIGIN_TYPES_SUCCESS = '[Leads Module] Load Lead Origin Types Success';
export const LOAD_LEAD_ORIGIN_TYPES_FAIL = '[Leads Module] Load Lead Origin Types Fail';

export const LoadLeadOriginTypes = createAction(
  LOAD_LEAD_ORIGIN_TYPES
);
export const LoadLeadOriginTypesSuccess = createAction(
  LOAD_LEAD_ORIGIN_TYPES_SUCCESS,
  props<{ response: any }>()
);
export const LoadLeadOriginTypesFail = createAction(
  LOAD_LEAD_ORIGIN_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const LOAD_LEAD_ORIGIN_TYPE = '[Leads Module] Load Lead Origin Type';
export const LOAD_LEAD_ORIGIN_TYPE_SUCCESS = '[Leads Module] Load Lead Origin Type Success';
export const LOAD_LEAD_ORIGIN_TYPE_FAIL = '[Leads Module] Load Lead Origin Type Fail';

export const LoadLeadOriginType = createAction(
  LOAD_LEAD_ORIGIN_TYPE,
  props<{ iri: string }>()
);
export const LoadLeadOriginTypeSuccess = createAction(
  LOAD_LEAD_ORIGIN_TYPE_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);
export const LoadLeadOriginTypeFail = createAction(
  LOAD_LEAD_ORIGIN_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
