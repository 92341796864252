import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const LOAD_CUSTOMER_COMMENT_TAG = '[Customers Module] Load Customer Comment Tag';
export const LOAD_CUSTOMER_COMMENT_TAG_SUCCESS = '[Customers Module] Load Customer Comment Tag Success';
export const LOAD_CUSTOMER_COMMENT_TAG_FAIL = '[Customers Module] Load Customer Comment Tag Fail';

export const LoadCustomerCommentTag = createAction(
  LOAD_CUSTOMER_COMMENT_TAG,
  props<{ iri: string }>()
);
export const LoadCustomerCommentTagSuccess = createAction(
  LOAD_CUSTOMER_COMMENT_TAG_SUCCESS,
  props<{ response: fromModuleModels.CustomerCommentTag }>()
);
export const LoadCustomerCommentTagFail = createAction(
  LOAD_CUSTOMER_COMMENT_TAG_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const LOAD_CUSTOMER_COMMENT_TAGS = '[Customers Module] Load Customer Comment Tags ';
export const LOAD_CUSTOMER_COMMENT_TAGS_SUCCESS = '[Customers Module] Load Customer Comment Tags Success';
export const LOAD_CUSTOMER_COMMENT_TAGS_FAIL = '[Customers Module] Load Customer Comment Tags Fail';

export const LoadCustomerCommentTags = createAction(
  LOAD_CUSTOMER_COMMENT_TAGS
);
export const LoadCustomerCommentTagsSuccess = createAction(
  LOAD_CUSTOMER_COMMENT_TAGS_SUCCESS,
  props<{ response: HttpResponse<AbstractApiResponse> }>()
);
export const LoadCustomerCommentTagsFail = createAction(
  LOAD_CUSTOMER_COMMENT_TAGS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
