import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { DocumentDelivery } from '../models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DocumentDeliveriesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createEmailDocumentDelivery(
    payload: DocumentDelivery
  ): Observable<DocumentDelivery> {
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_EMAIL_DOCUMENT_DELIVERY_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/email_document_deliveries`, payload)
    );
  }

  createRegisteredLetterDocumentDelivery(
    payload: DocumentDelivery
  ): Observable<DocumentDelivery> {
    console.log('createRegisteredLetterDocumentDelivery', payload);
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_REGISTERED_LETTER_DOCUMENT_DELIVERY_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/registered_letter_document_deliveries`,
              payload
            )
    );
  }

  createLetterXPressDocumentDelivery(
    payload: DocumentDelivery
  ): Observable<DocumentDelivery> {
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_LETTER_XPRESS_DOCUMENT_DELIVERY_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/letter_xpress_document_deliveries`,
              payload
            )
    );
  }

  readEmailDocumentDelivery(iri: string): Observable<DocumentDelivery> {
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_EMAIL_DOCUMENT_DELIVERY_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readRegisteredLetterDocumentDelivery(
    iri: string
  ): Observable<DocumentDelivery> {
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_REGISTERED_LETTER_DOCUMENT_DELIVERY_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readLetterXPressDocumentDelivery(iri: string): Observable<DocumentDelivery> {
    return this.rolesService.userHasRoleFilter<DocumentDelivery>(
      'ROLE_MWS_LETTER_XPRESS_DOCUMENT_DELIVERY_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }
}
