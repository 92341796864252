import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subject} from 'rxjs';

import * as fromInvoicesModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';

@Component({
  selector: 'payout-tool',
  styleUrls: ['payout-tool.component.scss'],
  template: `

    <div class="card m-t--32 mat-elevation-z1">

      <div class="card__heading" [class.green]="credit?.state === 'paid_out'">
        <span>Gutschrift Auszahlung</span>
      </div>

      <div class="card__content grid grid-no-gutter">

        <div class="column-14">
          <p class="m-ta--2 m-b--16 m-t--16" *ngIf="credit?.state === 'paid_out'">Gutschrift wurde ausgezahlt
            am {{ credit.payoutAt | date: 'dd.MM.Y' }}.</p>
        </div>

        <ng-container [formGroup]="ddf" *ngIf="credit?.state !== 'paid_out'">

          <mat-form-field class="column-14">
            <mat-label>Auszahlung am</mat-label>

            <input type="text" matInput [matDatepicker]="picker" formControlName="payoutAt" readonly required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-hint *ngIf="errors?.payoutAt">{{ errors.payoutAt.message }}</mat-hint>
          </mat-form-field>
        </ng-container>

        <!-- [disabled]="credit.state === 'paid_out'" -->
        <div class="column-14 m-ta--2">
          <button
            mat-flat-button
            [disabled]="!credit || ddf.invalid || (credit?.state === 'paid_out')"
            [color]="credit?.state === 'paid_out' ? 'orange' : 'green'"
            (click)="handleRequestBookPayOut()">
            <mat-icon class="m-r--8">euro</mat-icon>
            <span>Gutschrift auszahlen</span>
          </button>
        </div>
      </div>
    </div>
  `
})
export class PayoutToolComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() credit: fromInvoicesModuleModels.CommissionCredit;

  @Output()
  requestBookPayOut: EventEmitter<{ payoutAt: string }> = new EventEmitter();

  ddf: FormGroup;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, @Inject(LOCALE_ID) private locale: string,) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.ddf = this.fb.group({
      payoutAt: this.fb.control(null, Validators.required)
    });
  }

  handleRequestBookPayOut(): void {
    this.requestBookPayOut.emit(this.ddf.value);
  }
}
