import { AdministratorGroupsLoadedGuard } from './administrator-groups-loaded.guard';
import { AdministratorsLoadedGuard } from './administrators-loaded.guard';

export const AllGuards = [
  AdministratorGroupsLoadedGuard,
  AdministratorsLoadedGuard
];

export * from './administrator-groups-loaded.guard';
export * from './administrators-loaded.guard';
