import {createSelector} from '@ngrx/store';

import {CUSTOMER_PARTNER_STATUSES, selectCustomersModuleState} from '../reducers';

export const selectCustomerPartnerStatusesState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_PARTNER_STATUSES]
);

export const selectCustomerPartnerStatusesEntities = createSelector(
  selectCustomerPartnerStatusesState,
  (state) => state.entities
);

export const selectCustomerPartnerStatuses = createSelector(
  selectCustomerPartnerStatusesEntities,
  (statuses) => Object.keys(statuses).map(id => statuses[id])
);

export const isLoaded = createSelector(
  selectCustomerPartnerStatusesState,
  (state) => state.loaded
);
export const isLoading = createSelector(
  selectCustomerPartnerStatusesState,
  (state) => state.loading
);
