import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { DiscountsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Discount };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DiscountsActions.CreateDiscount,
    DiscountsActions.ReadDiscount,
    DiscountsActions.ReadDiscounts,
    DiscountsActions.UpdateDiscount,
    DiscountsActions.DeleteDiscount,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(DiscountsActions.ReadDiscountSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(DiscountsActions.ReadDiscountsSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.Discount }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    DiscountsActions.CreateDiscountSuccess,
    DiscountsActions.UpdateDiscountSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(DiscountsActions.DeleteDiscountSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(
    DiscountsActions.CreateDiscountFail,
    DiscountsActions.ReadDiscountFail,
    DiscountsActions.ReadDiscountsFail,
    DiscountsActions.UpdateDiscountFail,
    DiscountsActions.DeleteDiscountFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
