import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {ApplicationState} from '../../../application-state/store';
import {
  DeviceDetailsFormComponent,
  LeadContactFormComponent,
  LeadMetaFormComponent,
  LeadShipmentDialogComponent, MenuWithSubmenuItem
} from '../../components';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {
  getErrorOfResponse,
  getErrorsOfForm
} from '../../../shared/utilities/error-utility.utility';
import {
  LeadCommentsActions,
  LeadContactTypesActions,
  LeadOriginTypesActions,
  LeadsActions
} from '../../store';
import {
  LeadContactTypesSelectors,
  LeadOriginTypesSelectors,
  LeadsSelectors
} from '../../store/selectors';
import {ModalDialogOptions} from '../../../application-state/models';
import {ActivatedRoute} from '@angular/router';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import * as moment from 'moment';
import {Lead, LeadComment, LeadContactType} from '../../models';
import {NotifierService} from 'angular-notifier';
import {FormsService} from '../../../shared/services';
import {
  ArchiveLeadFail,
  UnArchiveLeadFail,
  UpdateLeadSuccess
} from '../../store/actions/leads.actions';
import {SenderAddress} from '../../models/sender-address';
import {removeEmptyFormElements} from '../../../shared/utilities/forms.utility';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {WriteEMailDialogComponent} from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import {Customer} from '../../../customers/models';
import {ShipmentsActions} from '../../../shipping/store';
import {RouterActions} from '../../../application-state/store/actions';
import {extractUUID} from '../../../shared/utilities/objects.utility';
import {faker, fakerDE} from '@faker-js/faker';
import {environment} from '../../../../environments/environment';
import {convertNumberToFloatString} from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-lead-view',
  styleUrls: ['lead-view.component.scss'],
  template: `
    <div class="row">
      <div class="col-11">
        <h2>{{ getHeading }}</h2>
      </div>
      <div class="col-1 col-auto text-right">
        <ng-container *ngIf="!environment.production && !lead$.getValue()">
          <button mat-icon-button (click)="generateRandomData()">
            <mat-icon>person_add</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="lead$.getValue()">
          <button mat-icon-button (click)="openMailDialog()">
            <mat-icon>mail</mat-icon>
          </button>
          <button [matMenuTriggerFor]="dropdownMenu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #dropdownMenu xPosition="before">
            <ng-container *ngIf="!isArchivedLead">
              <ng-container *ngFor="let item of contextMenuItems">
                <button
                  *ngIf="!item.submenu"
                  mat-menu-item
                  [style.background-color]="item.backgroundColor"
                >
                  <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                  {{ item.label | translate }}
                </button>
                <button
                  *ngIf="item.submenu"
                  mat-menu-item
                  [matMenuTriggerFor]="submenu"
                  [style.background-color]="item.backgroundColor && item.backgroundColor"
                >
                  <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
                  {{ item.label | translate }}

                  <mat-menu #submenu="matMenu">
                    <ng-container *ngFor="let subItem of item.submenu">
                      <button mat-menu-item (click)="handleSubmenuClick(subItem)">
                        <mat-icon *ngIf="subItem.icon" [style.color]="subItem.backgroundColor">{{ subItem.icon }}</mat-icon>
                        {{ subItem.label | translate }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </button>
              </ng-container>
            </ng-container>
            <button
              (click)="handleArchiveLead()"
              data-action="edit"
              mat-menu-item
            >
              <mat-icon>archive</mat-icon>
              <span
              >Anfrage
                {{ isArchivedLead ? 'Aus Archiv holen' : 'archivieren' }}</span
              >
            </button>
            <button
              (click)="handleDeleteLead(isArchivedLead)"
              data-action="delete"
              mat-menu-item
            >
              <mat-icon>delete</mat-icon>
              <span>Anfrage löschen (Spam/Doppelt)</span>
            </button>
          </mat-menu>
        </ng-container>
        <a mat-icon-button [routerLink]="['..']">
          <mat-icon>close</mat-icon>
        </a>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 lead-form pos-relative"
        [class.is-locked]="isArchivedLead"
      >
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
        <div class="row">
          <div
            class="main-column first col-5"
            [attr.data-box-style]="contactOriginType"
          >
            <app-lead-meta-form [lead$]="lead$" #lmfc></app-lead-meta-form>
            <hr/>
            <app-lead-comments [lead$]="lead$"></app-lead-comments>
            <hr/>
            <div *ngIf="lead$ | async">
              <app-lead-comment-form [lead$]="lead$"></app-lead-comment-form>
            </div>
          </div>
          <div class="main-column col-4" data-box-style="white">
            <app-lead-contact-form
              #lcfc
              [lead$]="lead$"
              (updateSenderAddress)="updateSenderAddress($event)"
              (updateLeadContactType)="updateLeadContactType($event)"
            ></app-lead-contact-form>
          </div>

          <div class="main-column last col-3" data-box-style="gray">
            <app-device-details-form
              [lead$]="lead$"
              #ddfc
            ></app-device-details-form>
          </div>
        </div>

        <div class="bottom-row row">
          <div class="col-1">
            <button
              *ngIf="!!lead$.getValue() && isFromExternalSource"
              class="btn--analytics"
              [disabled]="!isFromExternalSource"
              (click)="showUTMDetails(utmModal)"
            >
              <mat-icon style="color: #afafaf">analytics</mat-icon>
            </button>
          </div>
          <!-- [matTooltip]="getErrorsForTooltip" -->
          <div class="col-11 text-right">
            <span
              [matTooltip]="canBeSaved === true ? '' : canBeSaved + ''"
              matTooltipPosition="above"
            >
              <button
                [class.m-r--24]="lead$.getValue()"
                mat-raised-button
                color="green"
                [disabled]="!(canBeSaved === true) || !haveUnsavedData"
                (click)="handleSave()"
              >
                {{ lead$.getValue() ? 'Daten speichern' : 'Anfrage erstellen' }}
              </button>
            </span>
            <span
              [matTooltip]="
                !(lead$.getValue() && lead$.getValue()['@id'])
                  ? 'Bitte erst Anfrage erstellen'
                  : ''
              "
              matTooltipPosition="above"
            >
              <button
                *ngIf="
                  lead$.getValue() &&
                  lead$.getValue()['@id'] &&
                  leadRequiresPriorShipment(
                    ddfc.ddf?.get('shippingProviderToDR')?.value
                  ) &&
                  !ddfc.ddf?.get('shipment')?.value
                "
                mat-raised-button
                color="blue"
                [disabled]="isArchivedLead"
                (click)="showShipmentDialog()"
              >
                {{ haveUnsavedData ? 'Anfrage speichern & ' : '' }}Versand
                auslösen
              </button>
            </span>

            <span>
              <button
                *ngIf="
                  (lead$.getValue() &&
                    lead$.getValue()['@id'] &&
                    !leadRequiresPriorShipment(
                      ddfc.ddf?.get('shippingProviderToDR')?.value
                    )) ||
                  !!ddfc.ddf?.get('shipment')?.value
                "
                mat-raised-button
                color="blue"
                [disabled]="
                  !(lead$.getValue() && lead$.getValue()['@id']) ||
                  isArchivedLead
                "
                (click)="handleTransition()"
              >
                {{
                  haveUnsavedData ? 'Anfrage speichern & ' : ''
                }}Analyseauftrag erstellen
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-template #utmModal let-lead="lead">
      <div mat-dialog-content>
        <div class="dialog--utm">
          <div class="dialog__header">
            <span>Kampagnen-Details</span>
          </div>
          <div class="dialog__content">
            <app-tracking-details
              [lead]="lead$.getValue()"
            ></app-tracking-details>
          </div>
        </div>
      </div>
    </ng-template>
  `
})
export class LeadViewComponent implements OnInit, OnDestroy {
  @ViewChild(LeadContactFormComponent)
  leadContactForm!: LeadContactFormComponent;
  @ViewChild(LeadMetaFormComponent) leadMetaForm!: LeadMetaFormComponent;
  @ViewChild(DeviceDetailsFormComponent)
  deviceDetailsForm!: DeviceDetailsFormComponent;
  contextMenuItems: MenuWithSubmenuItem[] = [];
  selectedSubmenuItems: MenuWithSubmenuItem[] = [];

  leadId: string = null;
  isLoading$: Observable<boolean>;
  lead$: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);
  isArchivedLead: boolean;
  onDestroy$: Subject<any> = new Subject<any>();
  presets$: BehaviorSubject<LeadComment | Customer | any> = new BehaviorSubject(
    null
  );
  selectedLeadContactType: LeadContactType = {styleName: 'yellow'};
  readonly environment = environment;
  private senderAddress: SenderAddress;

  constructor(
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<ApplicationState>,
    private activatedRoute: ActivatedRoute,
    private title: AbstractTitleService,
    private formService: FormsService,
    private notifierService: NotifierService
  ) {
  }

  get contactOriginType(): string {
    const cot = this.selectedLeadContactType;
    return cot && Object.keys(cot).length ? cot.styleName : 'yellow';
  }

  get getHeading(): string {
    return this.isArchivedLead
      ? 'Archivierte Anfrage'
      : this.lead$.getValue()
        ? 'Anfrage bearbeiten oder Analyseauftrag erstellen'
        : 'Neue Anfrage erstellen';
  }

  get haveUnsavedData(): boolean {
    return !(
      this.leadContactForm?.lcf?.pristine &&
      this.leadMetaForm?.lmf?.pristine &&
      this.deviceDetailsForm?.ddf?.pristine
    );
  }

  get isFromExternalSource(): boolean {
    return (
      !!this.lead$.getValue() &&
      this.lead$.getValue().createdBy.includes('partner_websites')
    );
  }

  get getErrorsForTooltip(): string {
    if (!this.leadContactForm?.lcf || !this.deviceDetailsForm?.ddf) {
      return '';
    }
    const errorsLMF = getErrorsOfForm(this.leadMetaForm.lmf);
    const errorsLCF = getErrorsOfForm(this.leadContactForm.lcf);
    const errorsDDF = getErrorsOfForm(this.deviceDetailsForm.ddf);
    const errors = [];
    errors.push(...errorsLMF, ...errorsLCF, ...errorsDDF);
    if (
      errorsLMF.length <= 0 &&
      errorsLCF.length <= 0 &&
      errorsDDF.length <= 0
    ) {
      return 'Keine Fehler';
    } else {
      return errors
        .map(e => 'Feld ' + e.field + ': ' + e.errors.join(', '))
        .join('; ');
    }
  }

  get canBeSaved(): boolean | string {
    if (!this.leadContactForm || !this.leadContactForm.lcf) {
      return 'loading';
    }
    const form = this.leadContactForm.lcf;
    if (
      form.get('customer').enabled &&
      (!form.get('customer').value || form.get('customer').value.trim() === '')
    ) {
      return 'Bitte einen Kunden angeben.';
    }
    if (form.get('nameLine1').enabled && !form.get('customerType').value) {
      return 'Bitte Kundentyp festlegen.';
    }
    if (
      form.get('nameLine1').enabled &&
      (!form.get('nameLine1').value ||
        form.get('nameLine1').value.trim() === '')
    ) {
      return 'Bitte den Namen des Kunden angeben.';
    }
    if (
      form.get('email').enabled &&
      (!form.get('email').value || form.get('email').value.trim() === '') &&
      form.get('phone').enabled &&
      (!form.get('phone').value || form.get('phone').value.trim() === '') &&
      form.get('mobile').enabled &&
      (!form.get('mobile').value || form.get('mobile').value.trim() === '')
    ) {
      return 'Bitte eine Kontakmöglichkeit angeben: E-Mail oder Telefon oder Mobil';
    }
    if (form.get('brokeredToPartner') && form.get('brokeredToPartner').value && !form.get('partnerBranchOfficeAddress').value) {
      console.log(form.get('brokeredToPartner'), form.get('brokeredToPartner').value, form.get('partnerBranchOfficeAddress').value);
      return 'Cannot be saved because there is no partner address';
    }
    return true;
  }

  generateRandomData(): void {
    this.leadContactForm.updateSelectedCustomerType('NewCustomer', true);
    this.leadContactForm.lcf.patchValue({
      leadContactType: '/api/lead_contact_types/0',
      vip: faker.datatype.boolean(),
      customerTypeToggle: 'NewCustomer',
      salutation: '/api/salutations/' + faker.number.int({min: 0, max: 4}),
      nameLine1: '',
      nameLine2: '',
      nameLine3: '',
      nameLine4: '',
      marketingPermissionEmail: faker.datatype.boolean(),
      marketingPermissionPhone: faker.datatype.boolean(),
      marketingPermissionPostal: faker.datatype.boolean(),
      partnerStatus: '/api/customer_partner_statuses/0',
      customerType: '/api/customer_types/1',
      customerInformation: fakerDE.hacker.phrase(),
      firstName: fakerDE.person.firstName(),
      lastName: fakerDE.person.lastName(),
      phone: fakerDE.phone.number(),
      email: fakerDE.internet.email(),
      customerContactTypeToggle: 'NewCustomerContact',
      customerBillingAddressTypeToggle: 'NewCustomerBillingAddress',
      billingAddress: {
        address: {
          line1: fakerDE.location.street(),
          line2: fakerDE.location.buildingNumber(),
          line3: '',
          line4: '',
          city: fakerDE.location.city(),
          zipPostcode: fakerDE.location.zipCode(),
          country: 'DE'
        },
        addressType: '/api/address_types/0'
      },
      availability: fakerDE.lorem.words({min: 5, max: 15})
    });

    this.deviceDetailsForm.ddf.patchValue({
      analysisPriorityMode:
        '/api/analysis_priority_modes/' + faker.number.int({min: 0, max: 1}),
      damage: '/api/damages/' + faker.number.int({min: 0, max: 6}),
      databases: faker.datatype.boolean(),
      encryptedData: faker.datatype.boolean(),
      fileSystem: '/api/file_systems/' + faker.number.int({min: 0, max: 13}),
      operatingSystem:
        '/api/operating_systems/' + faker.number.int({min: 0, max: 9}),
      numberOfDataMedia: fakerDE.number.int({min: 1, max: 19}),
      numberOfPartitions: fakerDE.number.int({min: 1, max: 265}),
      pinPassword: fakerDE.string.alphanumeric(10),
      sizeInGB: faker.number.int({min: 1, max: 1234}),
      virtualEncryption: faker.datatype.boolean(),
      priceAnalysisNet: {
        value: fakerDE.number.int({min: 15, max: 265 * 2 * 2 * 2})
      },
      virtualSystems: faker.datatype.boolean()
    });
    this.leadMetaForm.lmf.patchValue({
      leadOriginType: '/api/lead_origin_types/0',
      leadText: fakerDE.lorem.words({min: 5, max: 150})
    });
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(LeadOriginTypesSelectors.isLoading),
      this.store$.select(LeadsSelectors.isLoading)
    ]);
    this.title.setTitle('Anfrage erstellen');
    this.lead$.pipe(takeUntil(this.onDestroy$)).subscribe(lead => {
      if (!lead) {
        return;
      }
      this.isArchivedLead = !!lead.archivedAt;
    });

    this.initActionListeners();

    this.store$.dispatch(LeadContactTypesActions.LoadLeadContactTypes());
    this.store$.dispatch(LeadOriginTypesActions.LoadLeadOriginTypes());
    // loading

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.leadId = params.get('uuid');
        if (this.leadId) {
          this.title.setTitle('Anfrage bearbeiten');

          this.loadLead();
        }
      });
    this.actions$
      .pipe(
        ofType(
          LeadsActions.CreateLeadFail,
          LeadsActions.TransformLeadFail,
          LeadsActions.UpdateLeadFail
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fail => {
        console.log(fail);
        if (fail?.response?.error?.violations) {
          this.formService.resetFormErrors(this.leadContactForm.lcf);
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.leadContactForm.lcf
          );
          this.formService.resetFormErrors(this.leadMetaForm.lmf);
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.leadMetaForm.lmf
          );
          this.deviceDetailsForm.ddf
            .get('priceAnalysisNet.value')
            ?.setErrors(null);

          this.formService.resetFormErrors(this.deviceDetailsForm.ddf);
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.deviceDetailsForm.ddf
          );
          // this.notifierService.notify('error', fail?.response?.error['hydra:description']);
        }
      });
    this.loadLeadContactTypes();
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        ofType(ArchiveLeadFail, UnArchiveLeadFail),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({type, response}) => {
        const {message} = getErrorOfResponse(response);
        this.notifierService.show({
          type: 'error',
          message: 'Aktion Fehlgeschlagen: ' + message
        });
      });
    // this.actions$.pipe(
    //   ofType(CreateLeadFail, UpdateLeadFail),
    //   takeUntil(this.onDestroy$)
    // ).subscribe(({type, response}) => {
    //   const {message} = getErrorOfResponse(response);
    //   this.notifierService.show({type: 'error', message: 'Speichern Fehlgeschlagen: ' + message});
    // });
    this.actions$
      .pipe(
        ofType(
          UpdateLeadSuccess,
          LeadCommentsActions.UpdateLeadCommentSuccess,
          ShipmentsActions.CreateShipmentSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({type, response}) => {
      });
    this.actions$
      .pipe(ofType(LeadsActions.CreateLeadSuccess), takeUntil(this.onDestroy$))
      .subscribe(({response}) => {
        console.log(response);
        this.store$.dispatch(
          RouterActions.Go({path: ['/leads', extractUUID(response)]})
        );
      });
    this.actions$
      .pipe(ofType(LeadsActions.UpdateLeadSuccess), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.leadMetaForm.lmf.markAsUntouched();
        this.leadMetaForm.lmf.markAsPristine();
        this.leadContactForm.lcf.markAsUntouched();
        this.leadContactForm.lcf.markAsPristine();
        this.deviceDetailsForm.ddf.markAsUntouched();
        this.deviceDetailsForm.ddf.markAsPristine();
      });

    this.actions$
      .pipe(
        ofType(ShipmentsActions.CreateShipmentSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({type, response}) => {
        this.resetPresets();

        if (type === ShipmentsActions.CREATE_SHIPMENT_SUCCESS) {
          if (this.deviceDetailsForm.ddf.contains('shipment')) {
            this.deviceDetailsForm.ddf
              .get('shipment')
              .setValue(response['@id']);
            this.store$.dispatch(
              LeadsActions.UpdateLeadSilently({
                iri: this.lead$.getValue()['@id'],
                payload: {shipment: response['@id']}
              })
            );
          }
        }
      });
  }

  showUTMDetails(ref: TemplateRef<any>): void {
    this.dialog.open(ref);
  }

  handleArchiveLead(): void {
    const transitionTo = this.isArchivedLead ? 'to_new' : 'to_archived';
    const payload = {
      iri: this.lead$.getValue()['@id'],
      payload: {
        workflow: 'lead_status',
        transition: transitionTo
      }
    };

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: this.isArchivedLead
            ? 'Möchtest du die Anfrage aus dem Archiv holen?'
            : 'Möchtest du diese Anfrage archivieren? Achtung: Nach 8 Wochen wird diese Anfrage unkenntlich gemacht.',
          heading: this.isArchivedLead
            ? 'Anfrage aus dem Archiv holen'
            : 'Anfrage archivieren',
          confirmationText: this.isArchivedLead ? 'Ja' : 'Ja, archivieren',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() =>
        this.isArchivedLead
          ? this.store$.dispatch(LeadsActions.UnArchiveLead(payload))
          : this.store$.dispatch(LeadsActions.ArchiveLead(payload))
      );
  }

  handleDeleteLead(isArchivedLead = false): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchtest du diese Anfrage unwiderruflich löschen?',
          heading: 'Anfrage löschen',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => {
        if (isArchivedLead) {
          this.store$.dispatch(
            LeadsActions.DeleteArchivedLead({
              iri: this.lead$.getValue()['@id']
            })
          );
        } else {
          this.store$.dispatch(
            LeadsActions.DeleteLead({iri: this.lead$.getValue()['@id']})
          );
        }
      });
  }

  handleTransition(): void {
    this.showErrors();

    if (
      this.leadContactForm.lcf.invalid ||
      this.leadMetaForm.lmf.invalid ||
      this.deviceDetailsForm.ddf.invalid
    ) {
      return;
    }

    this.leadMetaForm.lmf.markAllAsTouched();
    this.leadContactForm.lcf.markAllAsTouched();
    this.deviceDetailsForm.ddf.markAllAsTouched();
    // Save should only test for canBeSaved

    if (!this.canBeSaved) {
    } else if (
      this.leadMetaForm.lmf.invalid ||
      this.leadContactForm.lcf.invalid ||
      this.deviceDetailsForm.ddf.invalid
    ) {
      this.notifierService.show({
        type: 'info',
        message:
          'Die Formulare enthalten Fehler. Bitte korrigieren.' +
          this.getErrorsForTooltip
      });
      return;
    } else if (
      this.deviceDetailsForm.ddf?.invalid ||
      this.leadContactForm.lcf?.invalid ||
      this.leadMetaForm.lmf?.invalid
    ) {
      this.notifierService.show({
        type: 'info',
        message: 'Die Formulare enthalten Fehler. Bitte korrigieren.'
      });
    }
    // Debug
    /*this.store$.dispatch(LeadsModuleActions.LeadsActions.CreateLead({ payload }));
    return;*/

    const settings: ModalDialogOptions = {
      config: {
        disableClose: true,
        data: {
          text: 'Möchten Sie diesen Analyse-Auftrag erstellen?',
          heading: 'Analyse-Auftrag erstellen',
          confirmationText: 'Erstellen',
          cancelText: 'Abbrechen'
        }
      }
    };
    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => {
        this.openMailDialog();
      });
  }

  handleSave(): void {
    this.showErrors();
    // Save should only test for canBeSaved
    if (!this.canBeSaved) {
    }
    let payload = {
      ...this.leadMetaForm.lmf.value,
      ...this.leadContactForm.lcf.value,
      ...this.deviceDetailsForm.ddf.value
    };

    if (payload.customerContactTypeToggle === 'SelectExistingCustomerContact') {
      payload.salutation = null;
      payload.grade = null;
      payload.firstName = null;
      payload.middleName = null;
      payload.lastName = null;
      payload.phone = null;
      payload.mobile = null;
      payload.email = null;
    } else if (payload.customerContactTypeToggle === 'NewCustomerContact') {
      payload.decisionMakerCustomerContact = null;
    }

    if (payload.priceAnalysisNet?.value !== null) {
      payload.priceAnalysisNet.value = convertNumberToFloatString(
        payload.priceAnalysisNet.value
      );
    }
    // if (payload.specialDiscount === null) {
    //   payload.specialDiscount = '0.0';
    // } else
    if (payload.specialDiscount) {
      payload.specialDiscount = convertNumberToFloatString(
        payload.specialDiscount
      );
    }

    // cast nameLine2 to a string explicitly; could have become null during patch processes in child components
    if (payload.nameLine2 == null) {
      payload = {
        ...payload,
        nameLine2: ''
      };
    }
    // remove Objects and use IRI
    // remove Empty elements in form
    payload = removeEmptyFormElements(payload, [
      'decisionMakerCustomerContact',
      'salutation',
      'grade',
      'firstName',
      'sizeInGB',
      'brokerContact',
      'middleName',
      'lastName',
      'phone',
      'mobile',
      'email',
      'databases',
      'virtualSystems',
      'encryptedData',
      'specialDiscount',
      'discount',
      'virtualEncryption'
    ]);
    if (!!payload.customerBillingAddress) {
      delete payload.billingAddress;
    }
    if (!!payload.customerDeliveryAddress) {
      delete payload.deliveryAddress;
    }
    if (!!payload.customerBillingAddress) {
      delete payload.billingAddress;
    }

    if (this.lead$.getValue() && this.lead$.getValue()['@id']) {
      this.store$.dispatch(
        LeadsActions.UpdateLead({iri: this.lead$.getValue()['@id'], payload})
      );
    } else {
      this.store$.dispatch(LeadsActions.CreateLead({payload}));
    }
  }

  showShipmentDialog(): void {
    this.showErrors();

    this.actions$
      .pipe(
        ofType(LeadsActions.UpdateLeadSuccess),
        takeUntil(this.onDestroy$),
        take(1)
      )
      .subscribe(() => {
        this.dialog.open(LeadShipmentDialogComponent, {
          disableClose: true,
          data: {
            lead$: this.lead$,
            leadIri: this.lead$.getValue()['@id'],
            shippingProvider: this.deviceDetailsForm.ddf.get(
              'shippingProviderToDR'
            ).value,
            leadContact: this.senderAddress
          }
        });
      });
    this.handleSave();
  }

  resetPresets(): void {
    this.presets$.next(null);
  }

  leadRequiresPriorShipment(value?: string): boolean {
    // Pickup
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_PICK_UP_GO
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_PICK_UP_UPS

    // Label
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_SHIPPING_LABEL_UPS
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_SHIPPING_LABEL_DHL

    // Personal
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_PERSONAL_DELIVERY_PARTNER
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_PERSONAL_DELIVERY_DR_LE

    // Generic Shipment
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_GENERIC_SHIPMENT

    // Shipment by customer
    // /api/shipping_providers_to_dr/SHIPPING_PROVIDER_CUSTOMER_SHIPMENT

    if (!value) {
      return false;
    }

    return (
      !value.includes('PERSONAL') &&
      !value.includes('SHIPPING_PROVIDER_CUSTOMER_SHIPMENT')
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateSenderAddress($event: SenderAddress): void {
    this.senderAddress = $event;
  }

  updateLeadContactType($event: LeadContactType): void {
    this.selectedLeadContactType = $event;
  }

  showErrors(): void {
    if (this.leadContactForm.lcf.invalid) {
      this.leadContactForm.lcf.markAllAsTouched();
    }
    if (this.leadMetaForm.lmf.invalid) {
      this.leadMetaForm.lmf.markAllAsTouched();
    }
    if (this.deviceDetailsForm.ddf.invalid) {
      this.deviceDetailsForm.ddf.markAllAsTouched();
    }
  }

  openMailDialog(): void {
    const dialogRef = this.dialog.open(WriteEMailDialogComponent, {
      data: {type: 'lead', entity$: this.lead$, collectMail: true}
    });

    dialogRef.afterClosed().subscribe((response) => {
      console.log(response);
      this.actions$
        .pipe(
          ofType(LeadsActions.UpdateLeadSuccess),
          takeUntil(this.onDestroy$),
          take(1)
        )
        .subscribe(() => {
          this.store$.dispatch(
            LeadsActions.TransformLead({
              iri: this.lead$.getValue()['@id'],
              payload: {
                orderConfirmationEmail: {
                  body: response.body,
                  subject: response.subject
                }
              }
            })
          );
        });
      this.handleSave();
    });
  }

  private loadLead(): void {
    if (this.leadId) {
      // console.log(this.leadId);
      this.store$.dispatch(LeadsActions.ReadLead({id: this.leadId}));
      this.store$
        .select(LeadsSelectors.selectLead, {iri: this.leadId})
        .pipe(
          takeUntil(this.onDestroy$),
          filter(lead => !!lead)
        )
        .subscribe(lead => {
          this.title.setTitle(
            'Anfrage vom ' + moment(lead.createdAt).format() + ' bearbeiten'
          );
          this.lead$.next(lead);
        });
    }
  }


  private loadLeadContactTypes(): void {
    this.store$
      .select(LeadContactTypesSelectors.selectLeadContactTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        Object.keys(value).map(key => {
          this.selectedSubmenuItems.push({
            label: value[key].name,
            value: key,
            icon: "circle",
            backgroundColor: value[key].backgroundColor
          });
          this.contextMenuItems = [
            {
              label: 'leads.contextmenu.move',
              value: 'Verschieben',
              icon: 'open_in_new',
              submenu: [
                {label: 'leads.contextmenu.submenu.move.new_inquiries', value: '1'},
                {label: 'leads.contextmenu.submenu.move.day_1', value: '2'},
                {label: 'leads.contextmenu.submenu.move.day_2', value: '3'},
                {label: 'leads.contextmenu.submenu.move.day_3', value: '4'},
                {label: 'leads.contextmenu.submenu.move.night_contact', value: '5'},
                {label: 'leads.contextmenu.submenu.move.final_closed', value: '6'},
              ]
            },
            {
              label: 'leads.contextmenu.status',
              value: 'leads.contextmenu.status',
              icon: 'circle',
              submenu: this.selectedSubmenuItems
            },
          ];
        });
      });
    loadIfNotLoaded(
      this.store$,
      LeadContactTypesSelectors.isLoaded,
      LeadContactTypesActions.LoadLeadContactTypes()
    );
  }


  handleSubmenuClick(item: any): void {
    console.log(item);

    switch (item.label) {
      case'leads.contextmenu.submenu.move.day_1':
      case'leads.contextmenu.submenu.move.day_2':
      case'leads.contextmenu.submenu.move.day_3':
      case'leads.contextmenu.submenu.move.final_closed':
      case'leads.contextmenu.submenu.move.night_contact':
      case'leads.contextmenu.submenu.move.new_inquiries':
        this.store$.dispatch(LeadsActions.UpdateLeadColumns({payload: {
          leads: [
            Object.assign(
              {}, this.lead$.getValue(),
              {
                column: Number(item.value)
              }
              )]
          }}));
        break;
      case 'Neu':
      case 'Vormittagskontakt':
      case 'Nachmittagskontakt':
      case 'Rückfrage extern':
      case 'Rückfrage intern':
      case 'Partner-Anfrage':
        this.store$.dispatch(
          LeadsActions.UpdateLead({
            iri: this.lead$.getValue()['@id'],
            payload: {
              leadContactType: item.value
            }
          })
        );
        break;
      default:
        break;
    }
  }

}
