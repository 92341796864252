import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { LeadsSelectors } from '../../store/selectors';
import {
  ShipmentsSelectors,
  ShippingProvidersToDataRecoverySelectors
} from '../../../shipping/store/selectors';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { CustomerAddress, CustomerContact } from '../../../customers/models';
import { Shipment, ShippingProvider } from '../../../shipping/models';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CountriesSelectors,
  DepartmentsSelectors
} from '../../../master-data/store/selectors';
import { FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import * as LeadsModuleActions from '../../store';
import { Router } from '@angular/router';
import { FormsService } from '../../../shared/services';
import {
  TransformLeadFail,
  TransformLeadSuccess
} from '../../store/actions/leads.actions';
import {
  LeadShipmentMailComponent
} from "../../../shared/components/lead-shipment-mail/lead-shipment-mail.component";
import {WriteEMailDialogComponent} from "../../../shared/components/write-email-dialog/write-email-dialog.component";
import {Lead} from "../../models";

@Component({
  selector: 'app-lead-shipment-dialog',
  templateUrl: './lead-shipment-dialog.component.html',
  styleUrls: ['./lead-shipment-dialog.component.scss']
})
export class LeadShipmentDialogComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  shipment: Shipment;
  shippingProviderEntities: { [iri: string]: ShippingProvider };
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lead$: Lead;
      leadIri: string;
      shippingProvider: string;
      leadContact: CustomerContact | CustomerAddress | any;
    },
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LeadShipmentDialogComponent>,
    private dialogMailRef: MatDialogRef<WriteEMailDialogComponent>,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private router: Router,
    private notifierService: NotifierService,
    private fs: FormsService
  ) {}

  get shippingProviderObject(): ShippingProvider {
    return this.shippingProviderEntities[this.data?.shippingProvider] || null;
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(CountriesSelectors.isLoading),
      this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(LeadsSelectors.isLoading),
      this.store$.select(ShipmentsSelectors.isLoading)
    ]);
    this.store$
      .select(
        ShippingProvidersToDataRecoverySelectors.selectShippingProvidersToDataRecoveryEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(provider => {
        this.shippingProviderEntities = provider;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateShipment($event): void {
    console.log('updateShipment', $event);
  }

  updateForm($event): void {
    this.form = $event;
  }

  // openMailDialog(): void {
  //   this.dialog.open(WriteEMailDialogComponent, {
  //     data: { type: 'offer', entity$: this.offer$ }
  //   });
  // }

  openMailComponent(): void {
    this.dialogMailRef = this.dialog.open(WriteEMailDialogComponent, {
      data: {type: 'lead', entity$: this.data.lead$, collectMail: true}
    });

    this.dialogMailRef.afterClosed().subscribe((response) => {
      this.createShipment(response);
    });
  }
  createShipment(mail: any): void {
    console.log(mail);
    const shipment = removeEmptyFormElements(this.form.value);
    const settings: ModalDialogOptions = {
      config: {
        disableClose: true,
        data: {
          text:
            'Möchten Sie den Versand vorbereiten und einen Analyse-Auftrag erstellen?',
          heading: 'Versand vorbereiten und Analyse-Auftrag erstellen',
          confirmationText: 'Versand vorbereiten & Auftrag Erstellen',
          cancelText: 'Abbrechen'
        }
      }
    };
    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => {
        // console.log(shipment);
        this.store$.dispatch(
          LeadsModuleActions.LeadsActions.TransformLead({
            iri: this.data.leadIri,
            payload: {
              ...{ shipment },
              orderConfirmationEmail: {
                body: mail.body,
                subject: mail.subject
              }
            }
          })
        );
        // this.store$.dispatch(ShipmentsActions.CreateShipment({payload}));
        this.actions$
          .pipe(
            ofType(TransformLeadSuccess),
            take(1),
            takeUntil(this.onDestroy$),
            takeUntil(this.actions$.pipe(ofType(TransformLeadFail)))
          )
          .subscribe(({ iri, response }) => {
            this.dialogRef.close();
          });
        this.actions$
          .pipe(
            ofType(TransformLeadFail),
            take(1),
            takeUntil(this.onDestroy$),
            takeUntil(this.actions$.pipe(ofType(TransformLeadSuccess)))
          )
          .subscribe(({ response }) => {
            this.fs.mergeErrorResponseIntoForm({ response }, this.form);
          });
      });
  }

  markAsTouched(): void {
    if (!this.form) {
      return;
    }
    this.form.markAllAsTouched();
  }
}
