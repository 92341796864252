import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { CustomerStockItemsActions } from '../actions';
import { CustomerStockItemsService } from '../../services';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CustomerStockItemsEffects {
  createCustomerStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerStockItemsActions.CreateCustomerStockItem),
      switchMap(({ payload }) => {
        return this.service.createCustomerStockItem(payload).pipe(
          map((response: any) =>
            CustomerStockItemsActions.CreateCustomerStockItemSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerStockItemsActions.CreateCustomerStockItemFail({
                response
              })
            )
          )
        );
      })
    )
  );
  readCustomerStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerStockItemsActions.ReadCustomerStockItem),
      mergeMap(({ iri }) => {
        return this.service.getCustomerStockItem(iri).pipe(
          map((response: any) =>
            CustomerStockItemsActions.ReadCustomerStockItemSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerStockItemsActions.ReadCustomerStockItemFail({ response })
            )
          )
        );
      })
    )
  );
  readCustomerStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerStockItemsActions.ReadCustomerStockItems),
      switchMap(({ page, params }) => {
        return this.service.getCustomerStockItems(page, params).pipe(
          map(response =>
            CustomerStockItemsActions.ReadCustomerStockItemsSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerStockItemsActions.ReadCustomerStockItemsFail({ response })
            )
          )
        );
      })
    )
  );
  updateCustomerStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerStockItemsActions.UpdateCustomerStockItem),
      switchMap(({ iri, payload }) => {
        return this.service.updateCustomerStockItem(iri, payload).pipe(
          map((response: any) =>
            CustomerStockItemsActions.UpdateCustomerStockItemSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerStockItemsActions.UpdateCustomerStockItemFail({
                response
              })
            )
          )
        );
      })
    )
  );
  deleteCustomerStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerStockItemsActions.DeleteCustomerStockItem),
      switchMap(({ iri }) => {
        return this.service.deleteCustomerStockItem(iri).pipe(
          map(() =>
            CustomerStockItemsActions.DeleteCustomerStockItemSuccess({ iri })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerStockItemsActions.DeleteCustomerStockItemFail({
                response
              })
            )
          )
        );
      })
    )
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerStockItemsActions.CreateCustomerStockItemSuccess,
          CustomerStockItemsActions.UpdateCustomerStockItemSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case CustomerStockItemsActions.CREATE_CUSTOMER_STOCK_ITEM_SUCCESS:
              message = 'Ein neuer Lagereintrag wurde angelegt.';
              break;
            case CustomerStockItemsActions.UPDATE_CUSTOMER_STOCK_ITEM_SUCCESS:
              message = 'Der Lagereintrag wurde aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerStockItemsActions.CreateCustomerStockItemFail,
          CustomerStockItemsActions.UpdateCustomerStockItemFail,
          CustomerStockItemsActions.DeleteCustomerStockItemFail,
          CustomerStockItemsActions.ReadCustomerStockItemFail
        ),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case CustomerStockItemsActions.CREATE_CUSTOMER_STOCK_ITEM_FAIL:
              message =
                'Beim Anlegen eines neuen Lagereintrags sind Fehler aufgetreten:' +
                errors;
              break;
            case CustomerStockItemsActions.UPDATE_CUSTOMER_STOCK_ITEM_FAIL:
              message =
                'Beim Aktualisieren des Lagereintrags sind Fehler aufgetreten:' +
                errors;
              break;
            case CustomerStockItemsActions.DELETE_CUSTOMER_STOCK_ITEM_FAIL:
              message =
                'Beim Löschen des Lagereintrag ist ein Fehler aufgetreten:' +
                errors;
              break;
            case CustomerStockItemsActions.READ_CUSTOMER_STOCK_ITEM_FAIL:
              message =
                'Beim lesen des Lagereintrags sind Fehler aufgetreten:' +
                errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: CustomerStockItemsService,
    private notifierService: NotifierService
  ) {}
}
