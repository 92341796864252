import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {QuillEditorComponent} from 'ngx-quill';

import * as fromModuleModels from '../../models';
import * as fromAdministratorsModuleModels from '../../../administrators/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'customer-comment-form',
  styleUrls: ['customer-comment-form.component.scss'],
  template: `

    <div class="customer__comment-form">

      <form [formGroup]="ccf">

        <div class="filter grid grid-no-gutter">

        <span *ngFor="let tag of customerCommentTags" class="filter__item column-2">

          <button class="filter__button"
                  mat-button color="gray"
                  [disableRipple]="true"
                  [class.selected]="getCommentTagValue == tag['@id']"
                  (click)="toggleCommentTag(tag['@id'])">{{ tag.commentTag }}</button>
        </span>

          <div class="spacer column-auto"></div>

          <span class="filter__item column-2">
          <button class="filter__button" mat-button
                  color="gray"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'recipient'"
                  (click)="toggleAdditionalInputVisible('recipient')">Rückfrage</button>
        </span>

          <span class="filter__item column-3">
          <button class="filter__button" mat-button
                  color="gray"
                  [disableRipple]="true"
                  [class.selected]="additionalInputVisible === 'additionalContent'"
                  (click)="toggleAdditionalInputVisible('additionalContent')">Kundenkommentar</button>
        </span>
        </div>
        <app-text-editor formControlName="content" [label]="getRecipientValue"
                         placeholder=""></app-text-editor>

        <div class="grid" *ngIf="additionalInputVisible === 'recipient'">

          <div class="column-6 off-8 m-t--16">
            <ng-select
              placeholder="Rückfrage richten an ..."
              [items]="administrators$ | async"
              bindValue="@id"
              bindLabel="username"
              [searchable]="false"
              appendTo="body"
              formControlName="recipient">

              <ng-template ng-label-tmp let-item="item">
                <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
                <strong style="font-size: 12px">(@{{ item.username }})</strong>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="ng-option">
                  <span style="padding-right: 7px">
                    <mat-icon [inline]="true" color="accent">person</mat-icon>
                  </span>
                  <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
                  <strong style="font-size: 12px">(@{{ item.username }})</strong>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div *ngIf="additionalInputVisible === 'additionalContent'" style="margin-top: 25px">
          <app-text-editor formControlName="additionalContent" label="Kundenkommentar"
                           placeholder=""></app-text-editor>
        </div>

        <div class="m-ta--2 m-t--24">

          <button *ngIf="presets$.getValue()" (click)="cancelEdit()" class="m-r--8" type="button" color="outline"
                  mat-flat-button mat-dialog-close>
            <mat-icon class="m-r--8">cancel</mat-icon>
            <span>Abbrechen</span>
          </button>

          <button mat-flat-button color="green" type="button"
                  [disabled]="ccf.invalid || !ccf.dirty"
                  (click)="handleSubmit()">{{ presets$.getValue() ? 'Aktualisieren' : 'Kommentar anlegen' }}</button>
        </div>
      </form>
      <!--<pre>{{ ccf.value | json }}</pre>-->
    </div>
  `
})
export class CustomerCommentFormComponent implements OnInit, OnDestroy {

  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  @Input() administratorsEntities: { [iri: string]: fromAdministratorsModuleModels.Administrator };
  @Input() customer: fromModuleModels.Customer;
  @Input() customerCommentTags: Array<fromModuleModels.CustomerCommentTag>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromModuleModels.CustomerComment>;

  @Output() requestCreateCustomerComment: EventEmitter<fromModuleModels.CustomerComment> = new EventEmitter();
  @Output() requestUpdateCustomerComment: EventEmitter<{
    iri: string,
    payload: fromModuleModels.CustomerComment
  }> = new EventEmitter();

  additionalInputVisible: string;
  additionalInputsChoices: Array<string> = ['additionalContent', 'recipient'];
  administrators$: Observable<Array<fromAdministratorsModuleModels.Administrator>>;
  ccf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, public dialog: MatDialog) {
  }

  get getCommentTagValue(): string | null {
    return this.ccf.get('commentTag').value;
  }

  get getRecipientValue(): string | null {

    const inputValue = this.ccf.get('recipient').value;
    const admin = this.administratorsEntities[inputValue];
    const fullName = `@${admin?.username} (${admin?.firstName} ${admin?.lastName})`;

    return inputValue ? fullName : 'Eigener Kommentar';
  }

  ngOnInit(): void {

    this.administrators$ = of(Object.keys(this.administratorsEntities).map((key: string) => this.administratorsEntities[key]));

    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((comment: fromModuleModels.CustomerComment) => {
      this.initForm();
      this.ccf.markAsUntouched();

      if (comment) {
        this.restoreFormState(comment);
      }
    });
  }

  initForm(): void {

    this.ccf = this.fb.group({

      customer: this.fb.control(this.customer['@id'], [Validators.required]),
      commentTag: this.fb.control(null),
      content: this.fb.control(null, [Validators.required]),
      additionalContent: this.fb.control(null),
      recipient: this.fb.control(null)
    });

    const controls: Array<AbstractControl> = Object.keys(this.ccf.controls).map(key => this.ccf.controls[key]);
    controls.forEach((control: AbstractControl) => control.clearValidators());
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  restoreFormState(comment: fromModuleModels.CustomerComment) {

    Object.keys(comment).forEach(key => {

      const formInput = this.ccf.get(key);

      // check if both default value and formInput for given key exist
      if (formInput && !!comment[key]) {
        formInput.setValue(comment[key]);
        formInput.markAsTouched();

        if (key === 'recipient') {
          this.toggleAdditionalInputVisible('recipient');
          formInput.setValidators([Validators.required]);
        }

        if (key === 'additionalContent') {
          this.toggleAdditionalInputVisible('additionalContent');
          formInput.setValidators([Validators.required]);
        }
      }
    });
  }

  handleTextColorChange(color: string, propertyName: string, editorIndex: number): void {

    const editor = this.editors.toArray()[editorIndex].quillEditor;

    const defaultColor = '#000000';
    const {index, length} = editor.getSelection();
    const {color: currentColor} = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(
      index,
      length,
      {color: (currentColor == color) ? defaultColor : color}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.ccf.patchValue({[propertyName]: editorHTML});
  }

  toggleCommentTag(iri: string): void {
    this.ccf.patchValue({commentTag: (this.getCommentTagValue == iri) ? null : iri});
  }

  // todo: side effects
  toggleAdditionalInputVisible(to: string): void {

    const currentValue = String(this.additionalInputVisible);
    const formField = this.ccf.get(currentValue);
    const informationLoss = this.additionalInputsChoices.includes(currentValue) && formField.dirty && (formField.value != '');

    if (informationLoss) {

      this.dialog.open(DialogComponent, {
        data: {
          heading: 'Achtung',
          text: 'Hierdurch geht eingegebener Text bzw. die Auswahl verloren. Fortfahren?',
          confirmationText: 'Weiter',
          cancelText: 'Abbrechen',
          disableClose: true,
        }
      }).afterClosed().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        (hasConfirmed: boolean) => {

          if (!!hasConfirmed) {

            this.additionalInputVisible = (currentValue === to) ? undefined : to;
            formField.patchValue(null);
            formField.markAsPristine();
          }
        }
      );
    } else {
      this.additionalInputVisible = (currentValue === to) ? undefined : to;
    }
  }

  handleSubmit(): void {

    const payload: fromModuleModels.CustomerComment = this.ccf.value;

    this.presets$.getValue()
      ? this.requestUpdateCustomerComment.emit({iri: this.presets$.getValue()['@id'], payload})
      : this.requestCreateCustomerComment.emit(payload);
  }

  handleSearch(term: string, item: fromAdministratorsModuleModels.Administrator): boolean {
    // console.log(`${item.firstName}${item.lastName}${item.username}`.search(term))
    return `${item.firstName}${item.lastName}${item.username}`.search(term) !== -1;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
