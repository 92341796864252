import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { ShipmentDirection } from '../models';

@Injectable()
export class ShipmentDirectionsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getShipmentDirection(id: string): Observable<ShipmentDirection> {
    return this.rolesService.userHasRoleFilter<ShipmentDirection>(
      'ROLE_MWS_SHIPMENT_DIRECTION_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipment_directions/${id}`)
    );
  }

  getShipmentDirections(): Observable<Array<ShipmentDirection>> {
    return this.rolesService.userHasRoleFilter<Array<ShipmentDirection>>(
      'ROLE_MWS_SHIPMENT_DIRECTION_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipment_directions`)
    );
  }
}
