import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { AdministratorGroupsActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: Array<fromModuleModels.AdministratorGroup>;
  error?: any;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(AdministratorGroupsActions.LoadAdministratorGroups, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(
    AdministratorGroupsActions.LoadAdministratorGroupsSuccess,
    (state: State, { response }) => {
      const groups = response['hydra:member'];
      const entities = groups.reduce(
        (
          entities: { [id: string]: fromModuleModels.AdministratorGroup },
          group
        ) => {
          return {
            ...entities,
            [group['@id']]: group
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
export const selectAdministratorGroupsEntities = state => state.entities;
