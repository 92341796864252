import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LabLocationsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { LabLocationsService } from '../../services/lab-locations.service';

@Injectable()
export class LabLocationsEffects {
  getLabLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LabLocationsActions.ReadLabLocation),
      map(action => action),
      switchMap(({ iri }) => {
        return this.aps.readLabLocation(iri).pipe(
          map(response =>
            LabLocationsActions.ReadLabLocationSuccess({ response })
          ),
          catchError(response =>
            of(LabLocationsActions.ReadLabLocationFail({ response }))
          )
        );
      })
    )
  );
  getLabLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LabLocationsActions.ReadLabLocations),
      map(action => action),
      switchMap(() => {
        return this.aps.readLabLocations().pipe(
          map(response =>
            LabLocationsActions.ReadLabLocationsSuccess({ response })
          ),
          catchError(response =>
            of(LabLocationsActions.ReadLabLocationsFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LabLocationsActions.ReadLabLocationFail,
          LabLocationsActions.ReadLabLocationsFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aps: LabLocationsService,
    private notifierService: NotifierService
  ) {}
}
