import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class TicketAttachmentsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createTicketAttachment(payload: {
    file: File;
    uuid?: string;
    ticket: string;
  }): Observable<AbstractApiResponse> {
    console.log(payload);
    // Manually create FormDate for proper image processing
    const formData: FormData = new FormData();
    formData.append('file', payload.file, payload.file.name);
    formData.append('ticket', payload.ticket);

    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TICKET_ATTACHMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/ticket_attachments`, formData)
    );
  }

  readTicketAttachments(
    page: number,
    params: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TICKET_ATTACHMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/ticket_attachments', page, params)
            )
    );
  }

  readTicketAttachment(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TICKET_ATTACHMENT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  deleteTicketAttachment(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_TICKET_ATTACHMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
