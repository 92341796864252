import {createSelector} from '@ngrx/store';

import {PASSWORD_FEATURE_KEY, selectAuthFeature} from '../reducers';

export const selectPasswordsState = createSelector(
  selectAuthFeature,
  (state) => state[PASSWORD_FEATURE_KEY]
);

export const selectPasswordsErrors = createSelector(
  selectPasswordsState,
  (state) => state.errors
);
export const isLoading = createSelector(
  selectPasswordsState,
  (state) => state.loading
);
