<app-dialog-header>
  <h2>{{"leads.leads_view.search_leads.dialog_heading" | translate}}</h2>
</app-dialog-header>
<mat-form-field [formGroup]="lfg">
  <mat-label>{{"leads.leads_view.search_leads.search_label" | translate}}</mat-label>
  <input formControlName="leads" [placeholder]="'leads.leads_view.search_leads.placeholder' | translate" (keyup)="filterLeads($event)" matInput type="text">
</mat-form-field>
<div mat-dialog-content class="col-12 leads-wrapper">
  <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
  <mat-divider class="mat-divider"></mat-divider>
  <div
    *ngFor="let lead of leads"
    class="col-12 leads-item"
    (click)="handleRequestUpdateLeadView(lead['@id'])"
  >
      <leads-list-card-wrapper
        [lead]="lead" [draggable]="false">
      </leads-list-card-wrapper>
  </div>
</div>
