import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_OFFER_ITEM = '[Invoices Module] Create Offer Item';
export const CREATE_OFFER_ITEM_FAIL = '[Invoices Module] Create Offer Item Fail';
export const CREATE_OFFER_ITEM_SUCCESS = '[Invoices Module] Create Offer Item Success';

export const CreateOfferItem = createAction(
  CREATE_OFFER_ITEM,
  props<{ payload: any, invoiceIri: string }>()
);

export const CreateOfferItemSuccess = createAction(
  CREATE_OFFER_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.OfferItem, invoiceIri: string }>()
);

export const CreateOfferItemFail = createAction(
  CREATE_OFFER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_OFFER_ITEM = '[Invoices Module] Read Offer Item';
export const READ_OFFER_ITEM_SUCCESS = '[Invoices Module] Read Offer Item Success';
export const READ_OFFER_ITEM_FAIL = '[Invoices Module] Read Offer Item Fail';

export const ReadOfferItem = createAction(
  READ_OFFER_ITEM,
  props<{ iri: string }>()
);

export const ReadOfferItemSuccess = createAction(
  READ_OFFER_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.OfferItem | any }>()
);

export const ReadOfferItemFail = createAction(
  READ_OFFER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_OFFER_ITEMS = '[Invoices Module] Read Offer Items';
export const READ_OFFER_ITEMS_SUCCESS = '[Invoices Module] Read Offer Items Success';
export const READ_OFFER_ITEMS_FAIL = '[Invoices Module] Read Offer Items Fail';

export const ReadOfferItems = createAction(
  READ_OFFER_ITEMS
);

export const ReadOfferItemsSuccess = createAction(
  READ_OFFER_ITEMS_SUCCESS,
  props<{ response: fromInvoicesModuleModels.OfferItem }>()
);

export const ReadOfferItemsFail = createAction(
  READ_OFFER_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_OFFER_ITEM = '[Invoices Module] Update Offer Item';
export const UPDATE_OFFER_ITEM_SUCCESS = '[Invoices Module] Update Offer Item Success';
export const UPDATE_OFFER_ITEM_FAIL = '[Invoices Module] Update Offer Item Fail';

export const UpdateOfferItem = createAction(
  UPDATE_OFFER_ITEM,
  props<{ iri: string, payload: fromInvoicesModuleModels.OfferItem | any, invoiceIri: string }>()
);

export const UpdateOfferItemSuccess = createAction(
  UPDATE_OFFER_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.OfferItem, invoiceIri: string }>()
);

export const UpdateOfferItemFail = createAction(
  UPDATE_OFFER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_OFFER_ITEM = '[Invoices Module] Delete Offer Item';
export const DELETE_OFFER_ITEM_SUCCESS = '[Invoices Module] Delete Offer Item Success';
export const DELETE_OFFER_ITEM_FAIL = '[Invoices Module] Delete Offer Item Fail';

export const DeleteOfferItem = createAction(
  DELETE_OFFER_ITEM,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteOfferItemSuccess = createAction(
  DELETE_OFFER_ITEM_SUCCESS,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteOfferItemFail = createAction(
  DELETE_OFFER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
