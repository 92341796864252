import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {CorrectionInvoiceItemsActions} from '../actions/';
import {CorrectionInvoiceItemsService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class CorrectionInvoiceItemsEffects {

  constructor(private actions$: Actions, private service: CorrectionInvoiceItemsService) {
  }

  CreateCorrectionInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItem),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createCorrectionInvoiceItem(payload).pipe(
        map((response) => CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItemSuccess({response, invoiceIri})),
        catchError(response => of(CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItemFail({response})))
      );
    })
  ));

  ReadCorrectionInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItem),
    switchMap(({iri}) => {
      return this.service.readCorrectionInvoiceItem(iri).pipe(
        map((response => CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItemSuccess({response}))),
        catchError(response => of(CorrectionInvoiceItemsActions.ReadCorrectionInvoiceItemFail({response})))
      );
    })
  ));

  UpdateCorrectionInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItem),
    switchMap(({iri, payload, invoiceIri}) => {
      return this.service.updateCorrectionInvoiceItem(iri, payload).pipe(
        map(() => CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItemSuccess({invoiceIri})),
        catchError((response) => of(CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItemFail({response})))
      );
    })
  ));

  DeleteCorrectionInvoiceItem$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItem),
    switchMap(({iri, invoiceIri}) => {
      return this.service.deleteCorrectionInvoiceItem(iri).pipe(
        map(() => CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItemSuccess({iri, invoiceIri})),
        catchError((response) => of(CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItemFail({response})))
      );
    })
  ));

  CorrectionInvoiceItemActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItemSuccess,
      CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItemSuccess,
    ),
    map(({type}) => {

      const text = type === CorrectionInvoiceItemsActions.CREATE_CORRECTION_INVOICE_ITEM_SUCCESS
        ? `Die Position wurde angelegt.`
        : 'Die Position wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt.',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
