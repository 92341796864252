import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as fromModuleModels from '../../models';
import { AdministratorGroup } from '../../models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ControlValidators } from '../../../shared/validators/control.validators';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import {
  AdministratorGroupsSelectors,
  AdministratorsSelectors
} from '../../store/selectors';
import { FormsService } from '../../../shared/services';
import { RolesSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { RolesActions } from '../../../master-data/store';
import { AdministratorGroupsActions } from '../../store';

@Component({
  selector: 'app-administrator-form',
  styleUrls: ['administrator-form.component.scss'],
  template: `
    <form [formGroup]="adminForm" autocomplete="off">
      <ng-content></ng-content>

      <div class="grid">
        <div class="column-7">
          <mat-form-field>
            <mat-label>Vorname</mat-label>
            <input type="text" matInput formControlName="firstName" required />
            <mat-error>
              <app-form-error
                fieldName="firstName"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-7">
          <mat-form-field>
            <mat-label>Nachname</mat-label>
            <input type="text" matInput formControlName="lastName" required />
            <mat-error>
              <app-form-error
                fieldName="lastName"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-auto">
          <mat-form-field>
            <mat-label>Zweiter Vorname</mat-label>
            <input type="text" matInput formControlName="middleName" />
            <mat-error>
              <app-form-error
                fieldName="middleName"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="grid">
        <div class="column-7">
          <mat-form-field>
            <mat-label>Benutzername</mat-label>
            <input type="text" matInput formControlName="username" required />
            <mat-error>
              <app-form-error
                fieldName="username"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-7">
          <mat-form-field>
            <mat-label>E-Mail-Adresse</mat-label>
            <input type="email" matInput formControlName="email" required />
            <mat-error>
              <app-form-error
                fieldName="email"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="grid">
        <div class="column-7">
          <mat-form-field>
            <mat-label>Gewünschtes Passwort</mat-label>
            <input type="password" matInput formControlName="plainPassword" />
            <mat-error>
              <app-form-error
                fieldName="plainPassword"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column-7">
          <mat-form-field>
            <mat-label>Passwort bestätigen</mat-label>
            <input
              type="password"
              matInput
              formControlName="plainPasswordConfirmation"
            />
            <mat-error>
              <app-form-error
                fieldName="plainPasswordConfirmation"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="grid">
        <div class="column-6">
          <div class="mat-form-field col-5">
            <ng-select
              [items]="roles$ | async"
              [loading]="rolesIsLoading$ | async"
              [clearable]="true"
              [searchable]="true"
              appendTo="body"
              placeholder="Rollen"
              [readonly]="adminForm.get('sendRoles').value !== true"
              [multiple]="true"
              formControlName="roles"
              bindLabel="name"
              bindValue="name"
            ></ng-select>
            <mat-error>
              <app-form-error
                fieldName="roles"
                [formGroup]="adminForm"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="column-1">
          <div class="mat-form-field col-1">
            <mat-checkbox formControlName="sendRoles"
              >Rollen senden
            </mat-checkbox>
          </div>
        </div>

        <div class="column-auto">
          <ng-select
            [items]="groups$ | async"
            [loading]="groupsIsLoading$ | async"
            [clearable]="true"
            [searchable]="true"
            placeholder="Gruppen"
            [multiple]="true"
            formControlName="groups"
            bindLabel="name"
            bindValue="@id"
          ></ng-select>
          <mat-error>
            <app-form-error
              fieldName="groups"
              [formGroup]="adminForm"
            ></app-form-error>
          </mat-error>
        </div>
      </div>

      <div class="s-pt--15 grid">
        <div class="column-4">
          <div class="mat-form-field checkbox">
            <mat-checkbox formControlName="enabled">Aktiviert</mat-checkbox>
          </div>
        </div>
        <div class="column-4">
          <div class="mat-form-field checkbox">
            <mat-checkbox formControlName="locked">Gesperrt</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="submit-grid grid">
        <div class="m-ta--2 column-14">
          <hr />

          <button
            class="btn--cancel"
            (click)="handleCancelEdit()"
            type="button"
            color="outline"
            mat-flat-button
          >
            <mat-icon class="m-r--8">cancel</mat-icon>
            <span>Abbrechen</span>
          </button>

          <button
            class="btn--submit"
            [disabled]="
              adminForm.invalid || adminForm.untouched || adminForm.pristine
            "
            mat-flat-button
            color="green"
            (click)="handleSubmit()"
          >
            <mat-icon>save</mat-icon>
            <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
          </button>
        </div>
      </div>
    </form>
  `
})
export class AdministratorFormComponent implements OnInit, OnDestroy {
  @Input()
  presets$: BehaviorSubject<fromModuleModels.Administrator | null>;

  roles$: Observable<Array<{ name: string }>>;
  rolesIsLoading$: Observable<boolean>;
  groups$: Observable<Array<AdministratorGroup>>;
  groupsIsLoading$: Observable<boolean>;

  @Output()
  cancelEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestCreateAdministrator: EventEmitter<
    fromModuleModels.Administrator
  > = new EventEmitter<fromModuleModels.Administrator>();

  @Output()
  requestUpdateAdministrator: EventEmitter<{
    iri: string;
    payload: fromModuleModels.Administrator;
  }> = new EventEmitter<{
    iri: string;
    payload: fromModuleModels.Administrator;
  }>();

  onDestroy$: Subject<any> = new Subject<any>();

  adminForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private updates$: Actions,
    private formService: FormsService
  ) {}

  ngOnInit(): void {
    this.loadRoles();
    this.loadGroups();

    this.initForm();

    this.presets$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(admin => !!admin)
      )
      .subscribe(admin => {
        this.initForm();
        this.adminForm.patchValue(admin);
        this.adminForm.markAsUntouched();
      });
    this.store$
      .select(AdministratorsSelectors.selectFormErrors)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        this.formService.mergeErrorsIntoForm(data, this.adminForm);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.adminForm = this.fb.group({
      sendRoles: this.fb.control(false),
      username: this.fb.control('', [Validators.required]),
      firstName: this.fb.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      middleName: this.fb.control('', [Validators.minLength(2)]),
      lastName: this.fb.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      plainPassword: this.fb.control(''),
      plainPasswordConfirmation: this.fb.control(''),
      enabled: this.fb.control(true),
      locked: this.fb.control(false),
      roles: this.fb.control([]),
      groups: this.fb.control([], ControlValidators.minArrayLength(1)) // todo: validator does not work
    });

    // const controls: Array<AbstractControl> = Object.keys(this.adminForm.controls).map(key => this.adminForm.controls[key]);
    // controls.forEach((control: AbstractControl) => control.clearValidators())
  }

  // todo: use zc function
  patchForm(admin: fromModuleModels.Administrator) {
    Object.keys(admin).forEach(key => {
      const formInput = this.adminForm.get(key);

      if (formInput && !!admin[key]) {
        // console.log(`${key} exists on form; will be patched with value`, admin[key])
        formInput.setValue(admin[key]);
        formInput.markAsTouched();
      }
    });
  }

  handleCancelEdit() {
    this.cancelEdit.emit();
    this.presets$.next(null);
  }

  handleSubmit(): void {
    const payload: fromModuleModels.Administrator = this.adminForm.value;
    if (this.adminForm.value.sendRoles !== true) {
      delete payload.roles;
    }

    this.presets$.getValue()
      ? this.requestUpdateAdministrator.emit({
          iri: this.presets$.getValue()['@id'],
          payload
        })
      : this.requestCreateAdministrator.emit(this.adminForm.value);
  }

  private loadRoles(): void {
    this.roles$ = this.store$
      .select(RolesSelectors.selectRoles)
      .pipe(map(list => list.map(element => ({ name: element }))));
    this.rolesIsLoading$ = this.store$.select(RolesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      RolesSelectors.isLoaded,
      RolesActions.ReadRoles()
    );
  }

  private loadGroups(): void {
    this.groups$ = this.store$.select(AdministratorGroupsSelectors.sList);
    this.groupsIsLoading$ = this.store$.select(
      AdministratorGroupsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AdministratorGroupsSelectors.isLoaded,
      AdministratorGroupsActions.LoadAdministratorGroups()
    );
  }
}
