<div class="card" [ngStyle]="{background: followUp.color}" (click)="onClick(followUp)">
  <span class="badge--vip" *ngIf="followUp.isCustomerVip">VIP</span>
  <span class="badge--express" *ngIf="followUp.isExpress">
    <span>EXPRESS</span>
  </span>
  <div class="grid">
    <strong class="column-auto card-title">{{followUp.title}}</strong>
  </div>
  <div>
    <span class="car-sub-title">{{followUp.customerName}}</span>
  </div>
  <hr>
  <div class="grid footer">
    <span class="column-9">{{followUp.reason}} </span>
    <span class="date m-t--2 column-5" *ngIf="showDate">{{followUp.date|date:'dd.MM.YYYY HH:mm'}}&nbsp;Uhr</span>
    <span class="date m-t--2 column-5" *ngIf="!showDate">{{followUp.date|date:'HH:mm'}}&nbsp;Uhr</span>
  </div>

</div>
