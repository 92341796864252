import {keyBy} from 'lodash-es';
import {ErrorsObject, getErrorsObject} from './error-utility.utility';

export const mergeEntities = (
  entities: Array<object>,
  options: any = {id: '@id', delete: false},
  oldEntities: { [iri: string]: object } = {}
): { [iri: string]: object } => {
  const result = keyBy(entities, options?.id || '@id');
  if (options?.delete) {
    return result;
  } else {
    return {...oldEntities, ...result};
  }
};
export const removeEntity = (iri: string,
                             oldEntities: { [iri: string]: object }
): { [iri: string]: object } => {
  const copyOfOld = {...oldEntities};
  delete copyOfOld[iri];
  return copyOfOld;
};

export const getAndHandleBackendErrors = (response): ErrorsObject => {
  const {error} = response;
  if (!error) {
    console.error(response);
    return;
  }
  let errors = {};
  if (error.hasOwnProperty('violations')) {
    errors = getErrorsObject(error);
  } else if (error.hasOwnProperty('hydra:description')) {
    console.error(error['hydra:description']);
  } else {
    console.error(error.message);
  }
  return errors;
};
