import {createSelector} from '@ngrx/store';

import {SHIPMENT_DIRECTIONS_FEATURE_KEY, selectShippingModuleState} from '../reducers';

export const selectShipmentDirectionsState = createSelector(
  selectShippingModuleState,
  (moduleState) => moduleState[SHIPMENT_DIRECTIONS_FEATURE_KEY]
);

export const selectShipmentDirectionsEntities = createSelector(
  selectShipmentDirectionsState,
  (state) => state.entities
);

export const selectShipmentDirections = createSelector(
  selectShipmentDirectionsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectShipmentDirectionsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectShipmentDirectionsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectShipmentDirectionsState,
  (state) => state.loaded
);
