import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../../invoices/models';
import {AbstractApiResponse} from '../../../shared/models';

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_OFFERS = '[Customers Module] Read Customer Offers';
export const READ_CUSTOMER_OFFERS_SUCCESS = '[Customers Module] Read Customer Offers Success';
export const READ_CUSTOMER_OFFERS_FAIL = '[Customers Module] Read Customer Offers Fail';

export const ReadCustomerOffers = createAction(
  READ_CUSTOMER_OFFERS,
  props<{ iri: string }>()
);

export const ReadCustomerOffersSuccess = createAction(
  READ_CUSTOMER_OFFERS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerOffersFail = createAction(
  READ_CUSTOMER_OFFERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_CUSTOMER_OFFERS_CHUNK = '[Customers Module] Read Customer Offers Chunk';
export const READ_CUSTOMER_OFFERS_CHUNK_SUCCESS = '[Customers Module] Read Customer Offers Chunk Success';
export const READ_CUSTOMER_OFFERS_CHUNK_FAIL = '[Customers Module] Read Customer Offers Chunk Fail';

export const ReadCustomerOffersChunk = createAction(
  READ_CUSTOMER_OFFERS_CHUNK,
  props<{ iri: string }>()
);

export const ReadCustomerOffersChunkSuccess = createAction(
  READ_CUSTOMER_OFFERS_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerOffersChunkFail = createAction(
  READ_CUSTOMER_OFFERS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset
export const RESET_CUSTOMER_OFFERS_STATE = '[Customers Module] Reset Customer Offers State';

export const ResetCustomerOffersState = createAction(
  RESET_CUSTOMER_OFFERS_STATE
);
