import {createSelector} from '@ngrx/store';

import {CUSTOMER_CONTACT_TYPES_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerContactTypesState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_CONTACT_TYPES_FEATURE_KEY]
);

export const selectCustomerContactTypeEntities = createSelector(
  selectCustomerContactTypesState,
  (state) => state.entities
);

export const selectCustomerContactTypes = createSelector(
  selectCustomerContactTypeEntities,
  (tags) => Object.keys(tags).map(id => tags[id])
);

export const selectErrors = createSelector(
  selectCustomerContactTypesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectCustomerContactTypesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerContactTypesState,
  (state) => state.loaded
);
