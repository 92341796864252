import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ProductsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Product };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  searchResults: { [iri: string]: fromModuleModels.Product };
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  searchResults: {}
};

export const reducer = createReducer(
  initialState,
  on(
    ProductsActions.CreateProduct,
    ProductsActions.ReadProduct,
    ProductsActions.ReadProducts,
    ProductsActions.UpdateProduct,
    ProductsActions.DeleteProduct,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(ProductsActions.ReadProductSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(ProductsActions.ReadProductsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Product }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(ProductsActions.SearchProductsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Product }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      searchResults: entities,
      loading: false
    };
  }),
  on(
    ProductsActions.CreateProductSuccess,
    ProductsActions.UpdateProductSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(ProductsActions.DeleteProductSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(
    ProductsActions.CreateProductFail,
    ProductsActions.ReadProductFail,
    ProductsActions.ReadProductsFail,
    ProductsActions.UpdateProductFail,
    ProductsActions.DeleteProductFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(ProductsActions.ResetSearchResults, state => {
    return {
      ...state,
      searchResults: {}
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
