import { createReducer, on } from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../../invoices/models';
import { CustomerOffersActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromInvoicesModuleModels.Offer };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(CustomerOffersActions.ReadCustomerOffers, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(CustomerOffersActions.ReadCustomerOffersSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromInvoicesModuleModels.Offer }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      errors: {},
      loaded: true,
      loading: false
    };
  }),
  on(
    CustomerOffersActions.ReadCustomerOffersFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(CustomerOffersActions.ResetCustomerOffersState, state => {
    return {
      ...initialState
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
