import {AddressTypesEffects} from './address-types.effects';
import {AnalysisPriorityModesEffects} from './analysis-priority-modes.effects';
import {AnalysisResultCategoriesEffects} from './analysis-result-categories.effects';
import {AnalysisResultsEffects} from './analysis-results.effects';
import {CountriesEffects} from './countries.effects';
import {CurrenciesEffects} from './currencies.effects';
import {CustomerCooperationsEffects} from './customer-cooperations.effects';
import {DamagesEffects} from './damages.effects';
import {DataRecoveryPriorityModesEffects} from './data-recovery-priority-modes.effects';
import {DepartmentAdsEffects} from './department-ads.effects';
import {DepartmentLogosEffects} from './department-logos.effects';
import {DepartmentsEffects} from './departments.effects';
import {DestructionTypesEffects} from './destruction-types.effects';
import {DiscountsEffects} from './discounts.effects';
import {DisposalTypesEffects} from './disposal-types.effects';
import {FileSystemsEffects} from './file-systems.effects';
import {GradesEffects} from './grades.effects';
import {LabLocationsEffects} from './lab-locations.effects';
import {LocalesEffects} from './locales.effects';
import {OperatingSystemsEffects} from './operating-systems.effects';
import {ProductUnitsEffects} from './product-units.effects';
import {ProductsEffects} from './products.effects';
import {ReplacementDataMediumSourcesEffects} from './replacement-data-medium-sources.effects';
import {RolesEffects} from './roles.effects';
import {SalutationsEffects} from './salutations.effects';
import {ServicesEffects} from './services.effects';
import {SpecialAgreementsEffects} from './special-agreements.effects';
import {StorageLocationsEffects} from './storage-locations.effects';
import {StorageSystemManufacturersEffects} from './storage-system-manufacturers.effects';
import {StorageSystemsEffects} from './storage-systems.effects';
import {SymptomsEffects} from './symptoms.effects';
import {TaxesEffects} from './taxes.effects';
import {TermsAndConditionsEffects} from './terms-and-conditions.effects';
import {EmailTemplateCategoriesEffects} from './email-template-categories.effects';
import {EmailTemplatesEffects} from './email-templates.effects';
import {EmailTemplateContextsEffects} from './email-template-contexts.effects';

export const effects: Array<any> = [
  AddressTypesEffects,
  AnalysisPriorityModesEffects,
  AnalysisResultCategoriesEffects,
  AnalysisResultsEffects,
  CountriesEffects,
  CurrenciesEffects,
  CustomerCooperationsEffects,
  DamagesEffects,
  DataRecoveryPriorityModesEffects,
  DepartmentAdsEffects,
  DepartmentLogosEffects,
  DepartmentsEffects,
  DestructionTypesEffects,
  DiscountsEffects,
  DisposalTypesEffects,
  FileSystemsEffects,
  GradesEffects,
  LabLocationsEffects,
  LocalesEffects,
  OperatingSystemsEffects,
  ProductUnitsEffects,
  ProductsEffects,
  ReplacementDataMediumSourcesEffects,
  RolesEffects,
  SalutationsEffects,
  ServicesEffects,
  SpecialAgreementsEffects,
  StorageLocationsEffects,
  StorageSystemManufacturersEffects,
  StorageSystemsEffects,
  SymptomsEffects,
  TaxesEffects,
  TermsAndConditionsEffects,
  EmailTemplateCategoriesEffects,
  EmailTemplatesEffects,
  EmailTemplateContextsEffects
];

export * from './address-types.effects';
export * from './analysis-priority-modes.effects';
export * from './analysis-result-categories.effects';
export * from './analysis-results.effects';
export * from './countries.effects';
export * from './currencies.effects';
export * from './customer-cooperations.effects';
export * from './damages.effects';
export * from './data-recovery-priority-modes.effects';
export * from './department-ads.effects';
export * from './department-logos.effects';
export * from './departments.effects';
export * from './destruction-types.effects';
export * from './discounts.effects';
export * from './disposal-types.effects';
export * from './file-systems.effects';
export * from './grades.effects';
export * from './lab-locations.effects';
export * from './locales.effects';
export * from './operating-systems.effects';
export * from './product-units.effects';
export * from './products.effects';
export * from './replacement-data-medium-sources.effects';
export * from './roles.effects';
export * from './salutations.effects';
export * from './services.effects';
export * from './storage-locations.effects';
export * from './storage-system-manufacturers.effects';
export * from './storage-systems.effects';
export * from './symptoms.effects';
export * from './taxes.effects';
export * from './terms-and-conditions.effects';
export * from './email-template-categories.effects';
export * from './email-templates.effects';
export * from './email-template-contexts.effects';
