import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FollowUp} from '../../models';

@Component({
  selector: 'app-follow-up-card',
  templateUrl: './follow-up-card.component.html',
  styleUrls: ['./follow-up-card.component.scss']
})
export class FollowUpCardComponent implements OnInit {

  @Input()
  public followUp: FollowUp;

  @Input()
  public showDate: boolean;

  @Output()
  public selectFollowUp: EventEmitter<FollowUp> = new EventEmitter<FollowUp>();

  constructor() {
  }

  onClick(followUp): void {
    this.selectFollowUp.emit(followUp);

  }

  ngOnInit(): void {
    this.showDate = this.showDate !== undefined;

  }

}
