import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { filter, takeUntil } from 'rxjs/operators';
import { BindingOrderDialogComponent } from '../../../orders/components/binding-order-dialog/binding-order-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-bo-sent',
  styleUrls: ['./action-box-to-bo-sent.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Erstellung verbindliche Bestellung
              </div>
              <div class="sub-header col-auto">
                Bitte verbindliche Bestellung erstellen
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3 btn-wider float-right"
              mat-button
              color="green"
              (click)="openDialog()"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Formular öffnen</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Verbindliche Bestellung</div>
          <div class="sub-header col-auto">Warte auf Erstellung der VB</div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToBoSentComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;

  constructor(private dialog: MatDialog, private authService: AuthService) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isSales() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
      });
  }

  openDialog(): void {
    this.dialog.open(BindingOrderDialogComponent, {
      width: '90%',
      maxWidth: '2000px',
      maxHeight: '95%',
      height: 'auto',
      panelClass: 'no-padding',
      data: { order$: this.order$ }
    });
  }
}
