import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'do-something-box',
  styleUrls: ['do-something-box.component.scss'],
  template: `

    <div class="grid m-ta--2">

      <div class="column-14">
        <button *ngIf="somethingWhat === 'routeTo'" mat-button color="green" [routerLink]="['/', target]">
          <mat-icon *ngIf="leftButtonIconIdentifier" class="m-r--8">{{ leftButtonIconIdentifier }}</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>

        <button *ngIf="somethingWhat === 'dispatchRouterAction'" mat-button color="green"
                (click)="handleRequestDispatchRouterAction()">
          <mat-icon *ngIf="leftButtonIconIdentifier" class="m-r--8">{{ leftButtonIconIdentifier }}</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>

        <button *ngIf="somethingWhat === 'openDialog'" mat-button color="green"
                (click)="handleRequestOpenSomeDialog(dialogIdentifier)">
          <mat-icon *ngIf="leftButtonIconIdentifier" class="m-r--8">{{ leftButtonIconIdentifier }}</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>
      </div>
    </div>
  `
})
export class DoSomethingBoxComponent {

  @Input() dialogIdentifier: string;
  @Input() leftButtonIconIdentifier: string;
  @Input() leftButtonLabel: string;
  @Input() somethingWhat: string;
  @Input() target: string;

  @Output() requestOpenSomeDialog: EventEmitter<{ dialogIdentifier: string }> = new EventEmitter();
  @Output() requestDispatchRouterAction: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  handleRequestDispatchRouterAction(): void {
    this.requestDispatchRouterAction.emit();
  }

  handleRequestOpenSomeDialog(dialogIdentifier: string): void {
    this.requestOpenSomeDialog.emit({dialogIdentifier});
  }
}
