import {Component, forwardRef, Input, OnDestroy, OnInit, Optional, QueryList, ViewChildren} from '@angular/core';
import {QuillEditorComponent} from 'ngx-quill';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-text-editor',
  styleUrls: ['./text-editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextEditorComponent),
    multi: true
  }],
  template: `
    <div [formGroup]="form">
      <quill-editor formControlName="editor" [placeholder]="placeholder" (change)="onChange($event)"
                    (touchstart)="onTouched($event)">
        <div quill-editor-toolbar>
          <span class="ql-heading">{{label}}</span>
          <span class="ql-formats">
              <button type="button" [matTooltip]="'SignRequest Link auf markierten Text einfügen'"
                      *ngIf="showSignRequestLink" (click)="handleAddLink()" class="preselect"><mat-icon>link</mat-icon></button>
              <button type="button" (click)="handleBGColorChange('yellow')" class="preselect yellow"><mat-icon>font_download</mat-icon></button>
              <button type="button" (click)="handleTextColorChange('blue')" class="preselect blue"><mat-icon>opacity</mat-icon></button>
              <button type="button" (click)="handleTextColorChange('#db0000')" class="preselect red"><mat-icon>opacity</mat-icon></button>
              <button type="button" (click)="handleTextColorChange('#45a452')" class="preselect green"><mat-icon>opacity</mat-icon></button>
              <button type="button" class="ql-bold"></button>
              <button type="button" class="ql-italic"></button>
              <button type="button" class="ql-underline"></button>
              <button type="button" class="ql-strike"></button>
            </span>
        </div>
      </quill-editor>
    </div>
  `
})
export class TextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  @Input() @Optional() showSignRequestLink = false;
  @Input() label = 'Text';
  @Input() placeholder = '';
  form: FormGroup;

  onDestroy$: Subject<any> = new Subject<any>();

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      editor: this.fb.control(null)
    });
    this.form.get('editor').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleAddLink(): void {
    const editor = this.editors.toArray()[0].quillEditor;
    if (!editor || !editor.getSelection()) {
      return;
    }
    const {index, length} = editor?.getSelection();

    if (length <= 0) {
      this.notifier.show({type: 'error', message: 'Bitte einen Text markieren, der den Link bekommen soll.'});
      return;
    }
    // formatText ensures the correct editor is targeted

    editor.formatText(
      index,
      length,
      {link: 'https://app.datareverse.de/signrequest-auftragsbestaetigung/?jwt={{signRequestJwt}}&body={{signRequestBodyB64}}'}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.form.patchValue({editor: editorHTML});

  }

  handleBGColorChange(color: string): void {

    const editor = this.editors.toArray()[0].quillEditor;
    if (!editor || !editor.getSelection()) {
      return;
    }

    const defaultColor = 'transparent';
    const {index, length} = editor.getSelection();
    const {background: currentColor} = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(
      index,
      length,
      {background: (currentColor === color) ? defaultColor : color}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.form.patchValue({editor: editorHTML});
  }

  handleTextColorChange(color: string): void {

    const editor = this.editors.toArray()[0].quillEditor;
    if (!editor || !editor.getSelection()) {
      return;
    }

    const defaultColor = '#000000';
    const {index, length} = editor.getSelection();
    const {color: currentColor} = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(
      index,
      length,
      {color: (currentColor === color) ? defaultColor : color}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.form.patchValue({editor: editorHTML});
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('editor').disable();
    } else {
      this.form.get('editor').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('editor').setValue(value);

  }

}
