import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {take, tap, switchMap, catchError} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';

import {OrdersActions, OrdersModuleState} from '../store';
import {OrdersSelectors, ProofsOfDestructionSelectors} from '../store/selectors';
import * as fromModuleModels from '../models';

@Injectable()
export class OrderLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const uuid = route.params.uuid;
    const iri = `/api/orders/${uuid}`;

    return this.currentOrderLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentOrderLoaded(iri: string) {
    return this.store.pipe(
      select(OrdersSelectors.sDetailedByIri, {iri}),
      tap((order: fromModuleModels.Order) => {
        // context marks a "fully loaded" object
        if (!order || !order?.hasOwnProperty('@context')) {
          this.store.dispatch(OrdersActions.ReadOrder({iri}));
        }
      }),
      take(1)
    );
  }
}
