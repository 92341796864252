import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_ACCOUNT = '[Customers Module] Create Customer Account';
export const CREATE_CUSTOMER_ACCOUNT_SUCCESS = '[Customers Module] Create Customer Account  Success';
export const CREATE_CUSTOMER_ACCOUNT_FAIL = '[Customers Module] Create Customer Account  Fail';

export const CreateCustomerAccount = createAction(
  CREATE_CUSTOMER_ACCOUNT,
  props<{ payload: fromModuleModels.CustomerAccount }>()
);

export const CreateCustomerAccountSuccess = createAction(
  CREATE_CUSTOMER_ACCOUNT_SUCCESS,
  props<{ response: HttpResponse<any> | any }>()
);

export const CreateCustomerAccountFail = createAction(
  CREATE_CUSTOMER_ACCOUNT_FAIL,
  props<{ response: any }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER_ACCOUNT = '[Customers Module] Read Customer Account';
export const READ_CUSTOMER_ACCOUNT_SUCCESS = '[Customers Module] Read Customer Account  Success';
export const READ_CUSTOMER_ACCOUNT_FAIL = '[Customers Module] Read Customer Account  Fail';

export const ReadCustomerAccount = createAction(
  READ_CUSTOMER_ACCOUNT,
  props<{ iri: string }>()
);

export const ReadCustomerAccountSuccess = createAction(
  READ_CUSTOMER_ACCOUNT_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAccountFail = createAction(
  READ_CUSTOMER_ACCOUNT_FAIL,
  props<{ response: any }>()
);

export const READ_CUSTOMER_ACCOUNT_PROFILE = '[Customers Module] Read Customer Account Profile';
export const READ_CUSTOMER_ACCOUNT_PROFILE_SUCCESS = '[Customers Module] Read Customer Account Profile Success';
export const READ_CUSTOMER_ACCOUNT_PROFILE_FAIL = '[Customers Module] Read Customer Account Profile Fail';

export const ReadCustomerAccountProfile = createAction(
  READ_CUSTOMER_ACCOUNT_PROFILE,
  props<{ iri: string }>()
);

export const ReadCustomerAccountProfileSuccess = createAction(
  READ_CUSTOMER_ACCOUNT_PROFILE_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAccountProfileFail = createAction(
  READ_CUSTOMER_ACCOUNT_PROFILE_FAIL,
  props<{ response: any }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_ACCOUNTS = '[Customers Module] Read Customer Accounts';
export const READ_CUSTOMER_ACCOUNTS_SUCCESS = '[Customers Module] Read Customer Accounts Success';
export const READ_CUSTOMER_ACCOUNTS_FAIL = '[Customers Module] Read Customer Accounts Fail';

export const ReadCustomerAccounts = createAction(
  READ_CUSTOMER_ACCOUNTS,
  props<{ page: number }>()
);

export const ReadCustomerAccountsSuccess = createAction(
  READ_CUSTOMER_ACCOUNTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAccountsFail = createAction(
  READ_CUSTOMER_ACCOUNTS_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_ACCOUNT = '[Customers Module] Update Customer Account';
export const UPDATE_CUSTOMER_ACCOUNT_SUCCESS = '[Customers Module] Update Customer Account  Success';
export const UPDATE_CUSTOMER_ACCOUNT_FAIL = '[Customers Module] Update Customer Account  Fail';

export const UpdateCustomerAccount = createAction(
  UPDATE_CUSTOMER_ACCOUNT,
  props<{ iri: string, payload: fromModuleModels.CustomerAccount }>()
);

export const UpdateCustomerAccountSuccess = createAction(
  UPDATE_CUSTOMER_ACCOUNT_SUCCESS,
  props<{ response: fromModuleModels.CustomerAccount }>()
);

export const UpdateCustomerAccountFail = createAction(
  UPDATE_CUSTOMER_ACCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_CUSTOMER_ACCOUNT_PROFILE = '[Customers Module] Update Customer Account Profile';
export const UPDATE_CUSTOMER_ACCOUNT_PROFILE_SUCCESS = '[Customers Module] Update Customer Account Profile Success';
export const UPDATE_CUSTOMER_ACCOUNT_PROFILE_FAIL = '[Customers Module] Update Customer Account Profile Fail';

export const UpdateCustomerAccountProfile = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PROFILE,
  props<{ iri: string, payload: any }>()
);

export const UpdateCustomerAccountProfileSuccess = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PROFILE_SUCCESS,
  props<{ response: fromModuleModels.CustomerAccount }>()
);

export const UpdateCustomerAccountProfileFail = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PROFILE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_CUSTOMER_ACCOUNT_PASSWORD = '[Customers Module] Update Customer Account Password';
export const UPDATE_CUSTOMER_ACCOUNT_PASSWORD_SUCCESS = '[Customers Module] Update Customer Account Password Success';
export const UPDATE_CUSTOMER_ACCOUNT_PASSWORD_FAIL = '[Customers Module] Update Customer Password Profile Fail';

export const UpdateCustomerAccountPassword = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PASSWORD,
  props<{ iri: string, payload: { oldPassword: string, plainPassword: string, plainPasswordConfirmation: string } }>()
);

export const UpdateCustomerAccountPasswordSuccess = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PASSWORD_SUCCESS,
  props<{ response: any }>()
);

export const UpdateCustomerAccountPasswordFail = createAction(
  UPDATE_CUSTOMER_ACCOUNT_PASSWORD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_ACCOUNT = '[Customers Module] Delete Customer Account';
export const DELETE_CUSTOMER_ACCOUNT_SUCCESS = '[Customers Module] Delete Customer Account Success';
export const DELETE_CUSTOMER_ACCOUNT_FAIL = '[Customers Module] Delete Customer Account Fail';

export const DeleteCustomerAccount = createAction(
  DELETE_CUSTOMER_ACCOUNT,
  props<{ iri: string }>()
);

export const DeleteCustomerAccountSuccess = createAction(
  DELETE_CUSTOMER_ACCOUNT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerAccountFail = createAction(
  DELETE_CUSTOMER_ACCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// State

export const RESET_CUSTOMER_ACCOUNTS_LOADED = '[Customers Module] Reset Customer Accounts Loaded';

export const ResetCustomerAccountsLoaded = createAction(
  RESET_CUSTOMER_ACCOUNTS_LOADED
);
