import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Order, OrderComment} from '../../models';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {OrderCommentsActions} from '../../store';
import {Actions, ofType} from '@ngrx/effects';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-order-comment-form-dialog',
  styleUrls: ['./order-comment-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Kommentar bearbeiten</h2>
    </app-dialog-header>
    <div mat-dialog-content>
      <app-order-comment-form
        *ngIf="data"
        [order$]="data.order$"
        [comment$]="data.comment$"
      ></app-order-comment-form>
    </div>
  `

})
export class OrderCommentFormDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                order$: BehaviorSubject<Order>,
                comment$: BehaviorSubject<OrderComment>
              },
              public dialogRef: MatDialogRef<OrderCommentFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions
  ) {
  }


  ngOnInit(): void {
    this.actions$.pipe(
      ofType(OrderCommentsActions.CreateOrderCommentSuccess, OrderCommentsActions.UpdateOrderCommentSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
