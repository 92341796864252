import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';

import { ApplicationState } from '../../../application-state/store';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthActions } from '../../store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { Actions, ofType } from '@ngrx/effects';
import { FormsService } from '../../../shared/services';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-reset-password-screen',
  styleUrls: ['reset-password-screen.component.scss'],
  template: `
    <div class="content__wrapper wrap">
      <div class="form-card mat-elevation-z1 pos-relative">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
        <div class="row mb-3">
          <div class="logo col-10 ">
            <img
              src="assets/images/logos/logo-bmoffice24.png"
              class="img--resp"
              alt="Logo Bindig Media"
            />
          </div>

          <div class="icon col-2 text-right">
            <mat-icon>account_circle</mat-icon>
          </div>
        </div>
        <div class="row">
          <h3 class="col">Neues Password setzen</h3>
        </div>

        <form [formGroup]="form">
          <div class="row">
            <mat-form-field>
              <mat-label>Passwort</mat-label>
              <input
                type="password"
                matInput
                formControlName="plainPassword"
                required
                #plainPasswordInput
              />
              <mat-icon
                matSuffix
                style="margin-right: 12px; cursor: pointer; opacity: .8"
                (mousedown)="plainPasswordInput.type = 'text'"
                (mouseup)="plainPasswordInput.type = 'password'"
                >visibility
              </mat-icon>
              <mat-error>
                <app-form-error
                  fieldName="plainPassword"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="column-14">
              <mat-label>Passwort-Bestätigung</mat-label>
              <input
                type="password"
                matInput
                formControlName="plainPasswordConfirmation"
                required
                #plainPasswordConfirmationInput
              />
              <mat-icon
                matSuffix
                style="margin-right: 12px; cursor: pointer; opacity: .8"
                (mousedown)="plainPasswordConfirmationInput.type = 'text'"
                (mouseup)="plainPasswordConfirmationInput.type = 'password'"
                >visibility
              </mat-icon>
              <mat-error>
                <app-form-error
                  fieldName="plainPassword"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="mat-form-field submit column-14">
              <div class="m-ta--2">
                <button
                  mat-button
                  color="green"
                  [disabled]="form.invalid || form.untouched"
                  (click)="submit()"
                >
                  <mat-icon class="m-r--8">login</mat-icon>
                  <span>Neues Passwort setzen</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  `
})
export class ResetPasswordScreenComponent extends BaseOnDestroyComponent
  implements OnInit {
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  token: string;
  form: FormGroup;
  isLoading$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private formService: FormsService,
    private notifier: NotifierService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      plainPassword: this.fb.control('', [Validators.required]),
      plainPasswordConfirmation: this.fb.control('', [Validators.required]),
      token: this.fb.control(null, [Validators.required])
    });

    this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ token }) => {
        this.form.patchValue({ token });
      });
  }

  submit(): void {
    const success = this.actions$.pipe(
      ofType(AuthActions.CreatePasswordResetSuccess),
      takeUntil(this.onDestroy$)
    );
    const fail = this.actions$.pipe(
      ofType(AuthActions.CreatePasswordResetFail),
      takeUntil(this.onDestroy$)
    );
    fail.pipe(takeUntil(success), take(1)).subscribe(failData => {
      this.formService.mergeErrorResponseIntoForm(failData, this.form);
    });
    success.pipe(takeUntil(fail), take(1)).subscribe(() => {
      this.notifier.show({
        type: 'success',
        message: 'Das Password wurde geändert. Sie werden nun weitergeleitet.'
      });
    });

    const payload = this.form.value;
    this.store$.dispatch(AuthActions.CreatePasswordReset({ payload }));
  }
}
