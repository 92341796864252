import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_FOLLOW_UP_REASONS = '[DashboardModule] Read FollowUpReasons';
export const READ_FOLLOW_UP_REASONS_SUCCESS = '[DashboardModule] Read FollowUpReasons Success';
export const READ_FOLLOW_UP_REASONS_FAIL = '[DashboardModule] Read FollowUpReasons Fail';
export const ReadFollowUpReasons = createAction(
  READ_FOLLOW_UP_REASONS
);
export const ReadFollowUpReasonsSuccess = createAction(
  READ_FOLLOW_UP_REASONS_SUCCESS,
  props<{ response: any }>()
);
export const ReadFollowUpReasonsFail = createAction(
  READ_FOLLOW_UP_REASONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_FOLLOW_UP_REASON = '[DashboardModule] Read FollowUpReason';
export const READ_FOLLOW_UP_REASON_SUCCESS = '[DashboardModule] Read FollowUpReason Success';
export const READ_FOLLOW_UP_REASON_FAIL = '[DashboardModule] Read FollowUpReason Fail';
export const ReadFollowUpReason = createAction(
  READ_FOLLOW_UP_REASON,
  props<{ iri: string }>()
);
export const ReadFollowUpReasonSuccess = createAction(
  READ_FOLLOW_UP_REASON_SUCCESS,
  props<{ response }>()
);
export const ReadFollowUpReasonFail = createAction(
  READ_FOLLOW_UP_REASON_FAIL,
  props<{ response: HttpErrorResponse }>()
);
