import {createSelector} from '@ngrx/store';

// Provider COMMISSION_CREDITS_FEATURE_KEY
import {COMMISSION_CREDITS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectCommissionCreditsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[COMMISSION_CREDITS_FEATURE_KEY]
);

export const selectCommissionCreditsEntities = createSelector(
  selectCommissionCreditsState,
  (state) => state.entities
);

export const selectCommissionCredits = createSelector(
  selectCommissionCreditsEntities,
  (customers) => Object.keys(customers).map(iri => customers[iri])
);

export const selectCommissionCreditByIndex = createSelector(
  selectCommissionCreditsEntities,
  (customers, props) => customers[props.iri]
);

export const selectCurrentCommissionCredit = createSelector(
  selectCommissionCreditsState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectCommissionCreditsState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectCommissionCreditsEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectCommissionCreditsState,
  state => state.totalItems
);
export const selectCommissionCreditPagination = createSelector(
  selectCommissionCreditsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectCommissionCreditPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectCommissionCreditPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectCommissionCreditPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectCommissionCreditPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectCommissionCreditPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCommissionCreditsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCommissionCreditsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCommissionCreditsState,
  (state) => state.errors
);
