import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { DataRecoveryCostsActions } from '../../../orders/store';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { DataRecoveryCostsSelectors } from '../../../orders/store/selectors';
import { AuthService } from '../../../auth/services/auth.service';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Discount } from '../../../master-data/models';
import { DiscountsSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { DiscountsActions } from '../../../master-data/store';
import { extractIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-action-box-to-analysis-selling-prices',
  styleUrls: ['./action-box-to-analysis-selling-prices.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">VK Preis definieren</div>
              <div class="sub-header col-auto">
                Bitte definieren Sie den Verkaufspreis
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right"></div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Warte auf VK Preis</div>
          <div class="sub-header col-auto">
            Warte auf Definierung der VK Preise
          </div>
        </div>
      </div>
      <div
        class="additional-content p-4"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-sm-4">
            <div class="row">
              <div class="col" formGroupName="order">
                <h4 class="mb-1">Rabatte</h4>
                <div class="mat-form-field">
                  <ng-select
                    placeholder="Rabatt"
                    [items]="discounts$ | async"
                    [loading]="discountsIsLoading$ | async"
                    bindValue="@id"
                    #discountInput
                    bindLabel="name"
                    [markFirst]="false"
                    [searchable]="false"
                    [clearable]="true"
                    formControlName="discount"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.name }} ({{ item.value | percentage }} %)
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                      {{ item.name }} ({{ item.value | percentage }} %)
                    </ng-template>
                  </ng-select>
                  <mat-error>
                    <app-form-error
                      fieldName="order.discount"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </div>
                <div class="mat-form-field">
                  <!--<pre>{{ discountInput.selectedValues[0] | json}}</pre>-->
                  <mat-form-field>
                    <mat-placeholder>Sonderrabatt in %</mat-placeholder>
                    <button
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                      (click)="
                        form.get('order.specialDiscount').setValue(null);
                        sdi.blur()
                      "
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                    <input
                      type="text"
                      matInput
                      formControlName="specialDiscount"
                      #sdi
                      currencyMask
                      [options]="{ prefix: '', suffix: '%' }"
                    />
                    <mat-error>
                      <app-form-error
                        [fieldName]="'order.specialDiscount'"
                        [formGroup]="form"
                      ></app-form-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="mb-1">Preis Initialkosten</h4>
                <mat-form-field formGroupName="sellingPriceInitial">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    matInput
                    required
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'sellingPriceInitial.value'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="mb-1">Preis Datenrettung</h4>

                <mat-form-field formGroupName="sellingPriceDataRecovery">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    matInput
                    required
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'sellingPriceDataRecovery.value'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="mb-1">Preis Sonstiges</h4>
                <mat-form-field formGroupName="sellingPriceOther">
                  <mat-label>Preis in €</mat-label>
                  <input
                    formControlName="value"
                    type="text"
                    matInput
                    required
                    currencyMask
                    [options]="{ prefix: '' }"
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'sellingPriceOther.value'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <mat-form-field>
              <mat-label>Kommentar</mat-label>
              <textarea
                matInput
                formControlName="sellingPriceComment"
                rows="10"
                cdkTextareaAutosize
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'sellingPriceComment'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                class="decision-btn me-3"
                mat-button
                color="green"
                (click)="submitForm()"
                [disabled]="form.invalid || !okAction"
              >
                <mat-icon class="me-2">done</mat-icon>
                <span>Preis definieren</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToAnalysisSellingPricesComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  form: FormGroup;

  discounts$: Observable<Array<Discount>>;
  discountsIsLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
    this.initForm();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isAdmin() ||
          (extractIri(this.order?.analysisLocation)?.indexOf(
            'LAB_LOCATION_DR_DE'
          ) > -1 &&
            this.authService.isTechnician()) ||
          (extractIri(this.order?.analysisLocation)?.indexOf(
            'LAB_LOCATION_DR_DE_REMOTE'
          ) > -1 &&
            this.authService.isSupervisor()) ||
          (extractIri(this.order?.analysisLocation)?.indexOf(
            'LAB_LOCATION_DR_EXT_'
          ) > -1 &&
            this.authService.isSupervisor()))) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order),
        tap(order => {
          this.order = order;
        }),
        filter(() => this.canMakeTransition)
      )
      .subscribe(order => {
        if (order.dataRecoveryCosts) {
          const lastDataRecoveryCosts =
            order.dataRecoveryCosts[order.dataRecoveryCosts.length - 1];
          this.store$.dispatch(
            DataRecoveryCostsActions.ReadDataRecoveryCosts({
              iri: lastDataRecoveryCosts
            })
          );
          this.store$
            .select(DataRecoveryCostsSelectors.sByIri, {
              iri: lastDataRecoveryCosts
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(DRCosts => !!DRCosts)
            )
            .subscribe(costs => {
              this.form.patchValue(costs);
            });
        }
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('_to_data_recovery_selling_prices_provided') >
              -1 && !e.error
        );
      });
    this.loadDiscounts();
  }

  initForm(): void {
    this.form = this.fb.group({
      sellingPriceInitial: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      sellingPriceDataRecovery: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      sellingPriceOther: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      order: this.fb.group({
        '@id': this.fb.control(null),
        discount: this.fb.control(null),
        specialDiscount: this.fb.control(null)
      }),
      sellingPriceComment: this.fb.control('')
    });
    this.form
      .get('order.discount')
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
        filter(value => value !== null)
      )
      .subscribe(values => {
        this.form.get('order.specialDiscount').patchValue(null);
      });
    this.form
      .get('order.specialDiscount')
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.onDestroy$),
        filter(value => value !== null)
      )
      .subscribe(values => {
        this.form.get('order.discount').patchValue(null);
      });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const lastDRCostsIri = this.order.dataRecoveryCosts[
      this.order.dataRecoveryCosts.length - 1
    ];
    const values = this.form.getRawValue();
    const payload: any = {}; // { ['@id']: lastDRCostsIri };
    for (const key of Object.keys(values)) {
      if (values[key] && values[key].value && values[key].currency) {
        payload[key] = {
          value: values[key].value + '',
          currency: values[key].currency
        };
      } else {
        payload[key] = values[key];
      }
    }
    if (payload.order?.specialDiscount) {
      payload.order.specialDiscount += '';
    }
    console.log(payload);
    this.store$.dispatch(
      DataRecoveryCostsActions.UpdateSellingPrices({
        iri: lastDRCostsIri,
        payload
      })
    );
    this.actions$
      .pipe(
        ofType(DataRecoveryCostsActions.UpdateSellingPricesSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.makeTransition();
      });
  }

  private loadDiscounts(): void {
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.discountsIsLoading$ = this.store$.select(DiscountsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }
}
