import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_STORAGE_SYSTEM = '[Master Data] Create Storage System';
export const CREATE_STORAGE_SYSTEM_SUCCESS = '[Master Data] Create Storage System Success';
export const CREATE_STORAGE_SYSTEM_FAIL = '[Master Data] Create Storage System Fail';
export const CreateStorageSystem = createAction(
  CREATE_STORAGE_SYSTEM,
  props<{ payload: fromModuleModels.StorageSystem }>()
);
export const CreateStorageSystemSuccess = createAction(
  CREATE_STORAGE_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.StorageSystem }>()
);
export const CreateStorageSystemFail = createAction(
  CREATE_STORAGE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_STORAGE_SYSTEMS = '[Master Data] Read Storage Systems';
export const READ_STORAGE_SYSTEMS_SUCCESS = '[Master Data] Read Storage Systems Success';
export const READ_STORAGE_SYSTEMS_FAIL = '[Master Data] Read Storage Systems Fail';
export const ReadStorageSystems = createAction(
  READ_STORAGE_SYSTEMS,
  props<{
    page?: number;
    params?: { [p: string]: string | number | boolean | string[] };
  }>()
);
export const ReadStorageSystemsSuccess = createAction(
  READ_STORAGE_SYSTEMS_SUCCESS,
  props<{ response: any }>()
);
export const ReadStorageSystemsFail = createAction(
  READ_STORAGE_SYSTEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_STORAGE_SYSTEM = '[Master Data] Read Storage System';
export const READ_STORAGE_SYSTEM_SUCCESS = '[Master Data] Read Storage System Success';
export const READ_STORAGE_SYSTEM_FAIL = '[Master Data] Read Storage System Fail';
export const ReadStorageSystem = createAction(
  READ_STORAGE_SYSTEM,
  props<{ iri: string }>()
);
export const ReadStorageSystemSuccess = createAction(
  READ_STORAGE_SYSTEM_SUCCESS,
  props<{ response }>()
);
export const ReadStorageSystemFail = createAction(
  READ_STORAGE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_STORAGE_SYSTEM = '[Master Data] Update Storage System';
export const UPDATE_STORAGE_SYSTEM_SUCCESS = '[Master Data] Update Storage System Success';
export const UPDATE_STORAGE_SYSTEM_FAIL = '[Master Data] Update Storage System Fail';
export const UpdateStorageSystem = createAction(
  UPDATE_STORAGE_SYSTEM,
  props<{ iri: string, payload: fromModuleModels.StorageSystem }>()
);
export const UpdateStorageSystemSuccess = createAction(
  UPDATE_STORAGE_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.StorageSystem }>()
);
export const UpdateStorageSystemFail = createAction(
  UPDATE_STORAGE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_STORAGE_SYSTEM = '[Master Data] Delete Storage System';
export const DELETE_STORAGE_SYSTEM_SUCCESS = '[Master Data] Delete Storage System Success';
export const DELETE_STORAGE_SYSTEM_FAIL = '[Master Data] Delete Storage System Fail';
export const DeleteStorageSystem = createAction(
  DELETE_STORAGE_SYSTEM,
  props<{ iri: string }>()
);
export const DeleteStorageSystemSuccess = createAction(
  DELETE_STORAGE_SYSTEM_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteStorageSystemFail = createAction(
  DELETE_STORAGE_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
