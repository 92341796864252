import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromLeadCommentTags from './lead-comment-tags.reducers';
import * as fromLeadComments from './lead-comments.reducers';
import * as fromLeadContactTypes from './lead-contact-types.reducers';
import * as fromLeadOriginTypes from './lead-origin-types.reducers';
import * as fromLeadsReducers from './leads.reducers';

export const LEADS_MODULE_FEATURE_KEY = 'leadsModule';

export const LEADS_FEATURE_KEY = 'leads';
export const LEAD_COMMENTS_FEATURE_KEY = 'leadComments';
export const LEAD_COMMENT_TAGS_FEATURE_KEY = 'leadCommentTags';
export const LEAD_CONTACT_TYPES_FEATURE_KEY = 'leadContactTypes';
export const LEAD_ORIGIN_TYPES_FEATURE_KEY = 'leadOriginTypes';

export interface LeadsModuleState {
  [LEADS_FEATURE_KEY]: fromLeadsReducers.State;
  [LEAD_COMMENTS_FEATURE_KEY]: fromLeadComments.State;
  [LEAD_COMMENT_TAGS_FEATURE_KEY]: fromLeadCommentTags.State;
  [LEAD_CONTACT_TYPES_FEATURE_KEY]: fromLeadContactTypes.State;
  [LEAD_ORIGIN_TYPES_FEATURE_KEY]: fromLeadOriginTypes.State;
}

export const selectLeadsModuleState = createFeatureSelector<LeadsModuleState>(LEADS_MODULE_FEATURE_KEY);

export function reducers(state: LeadsModuleState, action: Action) {
  return combineReducers({
    [LEADS_FEATURE_KEY]: fromLeadsReducers.reducer,
    [LEAD_COMMENTS_FEATURE_KEY]: fromLeadComments.reducer,
    [LEAD_COMMENT_TAGS_FEATURE_KEY]: fromLeadCommentTags.reducer,
    [LEAD_CONTACT_TYPES_FEATURE_KEY]: fromLeadContactTypes.reducer,
    [LEAD_ORIGIN_TYPES_FEATURE_KEY]: fromLeadOriginTypes.reducer,
  })(state, action);
}
