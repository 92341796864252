import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { extractIri } from '../../utilities/objects.utility';
import { Order } from '../../../orders/models';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { OrdersActions } from '../../../orders/store';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder
} from '../../../orders/helpers';

@Component({
  selector: 'app-order-select',
  styleUrls: ['./order-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderSelectComponent),
      multi: true
    }
  ],
  template: `
    <div [formGroup]="form">
      <ng-select
        formControlName="selectedElement"
        [items]="items$ | async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$ | async"
        (change)="setSelectedItem($event)"
        bindValue="@id"
        appendTo="body"
        [clearable]="true"
        [searchFn]="findItem"
        [readonly]="readonly"
        (touchstart)="onTouched($event)"
        [placeholder]="placeholder"
      >
        <ng-template ng-label-tmp let-item="item">
          #{{ item.orderNumber }}:
          {{ getCustomerName(item) }}
          <span class="text-color-lightgrey" *ngIf="getCompanyName(item)">
            ({{ getCompanyName(item) }})</span
          >
          <span class="badge--vip rounded-pill" *ngIf="isVip(item)">
            <span class="material-icons inline-icon">done</span>
            <span class="text"> VIP</span>
          </span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          #{{ item.orderNumber }}:
          {{ getCustomerName(item) }}
          <span class="text-color-lightgrey" *ngIf="getCompanyName(item)">
            ({{ getCompanyName(item) }})</span
          >
          <span class="badge--vip rounded-pill" *ngIf="isVip(item)">
            <span class="material-icons inline-icon">done</span>
            <span class="text"> VIP</span>
          </span>
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderSelectComponent extends BaseOnDestroyComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<Order> = new EventEmitter<
    Order
  >();
  @Input() readonly = false;
  @Input() @Optional() placeholder = 'Auftrag';
  items$: Observable<Array<Order>>;
  isLoading$: Observable<boolean>;
  selectedItem: Order;
  getCustomerName = getCustomerNameFromOrder;
  getCompanyName = getCompanyNameFromOrder;

  // getCustomerName(element: Order): string {
  //   if (element?.decisionMakerCustomerContact) {
  //     const contact = element.decisionMakerCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.technicalCustomerContact) {
  //     const contact = element.technicalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.organizationalCustomerContact) {
  //     const contact = element.organizationalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   }
  //   if (element?.customer?.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return element.customer.nameLine1;
  //   } else {
  //     // Geschäftskunde
  //     return '-';
  //   }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.required?.currentValue === true) {
      this.form.markAllAsTouched();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null)
    });
    this.form
      .get('selectedElement')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });

    this.items$ = this.store$.select(OrdersSelectors.sList);
    this.isLoading$ = this.store$.select(OrdersSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      OrdersSelectors.isLoaded,
      OrdersActions.ReadOrders({ page: -1 })
    );
  }

  setSelectedItem(element: Order): void {
    console.log(element);
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem = (term: string, item: Order): boolean => {
    const vm = this;
    const parts = term.split(' ');
    return parts.every(t => {
      let itemString = item.orderNumber + ' ';
      itemString += vm.getCustomerName(item);
      if (vm.getCompanyName(item)) {
        itemString += vm.getCompanyName(item);
      }
      if (vm.isVip(item)) {
        itemString += ' vip';
      }
      return itemString.toLowerCase().indexOf(t.toLowerCase()) > -1;
    });
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }

  // getCompanyName(element: Order): string {
  //   if (!element) {
  //     return '';
  //   }
  //
  //   if (element?.customer && element.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return null;
  //   } else {
  //     // Geschäftskunde
  //     return element?.customer?.nameLine1;
  //   }
  // }

  isVip(element: Order): boolean {
    return element?.customer?.vip;
  }
}
