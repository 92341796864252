import {ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormSelectComponent} from "../components/form-select/form-select.component";
import {FormDateInputComponent} from "../components/form-date-input/form-date-input.component";
import {FormInputComponent} from "../components/form-input/form-input.component";
import {FormButtonComponent} from "../components/form-button/form-button.component";


const components = {
  button: FormButtonComponent,
  date: FormDateInputComponent,
  input: FormInputComponent,
  select: FormSelectComponent
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit {

  @Input() config;
  @Input() group: FormGroup;

  component;

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {
  }

  ngOnInit(): void {
    const component = components[this.config.type];
    const factory = this.resolver.resolveComponentFactory<any>(component);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
