import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';
import {Lead, UpdateLeadColumnsApiResponse} from '../../models';
import {LeadsMail} from "../../models/leads-mail.interface";
import {Order} from "../../../orders/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_LEAD = '[Leads Module] Create Lead';
export const CREATE_LEAD_SUCCESS = '[Leads Module] Create Lead Success';
export const CREATE_LEAD_FAIL = '[Leads Module] Create Lead Fail';

export const CreateLead = createAction(
  CREATE_LEAD,
  props<{ payload: fromModuleModels.Lead }>()
);

export const CreateLeadSuccess = createAction(
  CREATE_LEAD_SUCCESS,
  props<{ response: fromModuleModels.Lead | any }>()
);

export const CreateLeadFail = createAction(
  CREATE_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const LOAD_LEAD = '[Leads Module] Read Lead';
export const LOAD_LEAD_SUCCESS = '[Leads Module] Read Lead Success';
export const LOAD_LEAD_FAIL = '[Leads Module] Read Lead Fail';

export const ReadLead = createAction(
  LOAD_LEAD,
  props<{ iri?: string, id?: string }>()
);

export const ReadLeadSuccess = createAction(
  LOAD_LEAD_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);

export const ReadLeadFail = createAction(
  LOAD_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LEADS = '[Leads Module] Read Leads';
export const READ_LEADS_SUCCESS = '[Leads Module] Read Leads Success';
export const READ_LEADS_FAIL = '[Leads Module] Read Leads Fail';

export const ReadLeads = createAction(
  READ_LEADS,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadLeadsSuccess = createAction(
  READ_LEADS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadLeadsFail = createAction(
  READ_LEADS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_LEAD = '[Leads Module] Update Lead';
export const UPDATE_LEAD_SUCCESS = '[Leads Module] Update Lead Success';
export const UPDATE_LEAD_FAIL = '[Leads Module] Update Lead Fail';

export const UpdateLead = createAction(
  UPDATE_LEAD,
  props<{ iri: string, payload: fromModuleModels.Lead }>()
);

export const UpdateLeadSuccess = createAction(
  UPDATE_LEAD_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);

export const NotificationHandled = createAction('[Leads] Notification Handled');
export const NotificationSkipped = createAction('[Leads] Notification Skipped');
export const UpdateLeadFail = createAction(
  UPDATE_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_LEAD_SILENTLY = '[Leads Module] Update Lead Silently';
export const UPDATE_LEAD_SILENTLY_SUCCESS = '[Leads Module] Update Lead Silently Success';
export const UPDATE_LEAD_SILENTLY_FAIL = '[Leads Module] Update Lead Silently Fail';

export const UpdateLeadSilently = createAction(
  UPDATE_LEAD_SILENTLY,
  props<{ iri: string, payload: fromModuleModels.Lead }>()
);

export const UpdateLeadSilentlySuccess = createAction(
  UPDATE_LEAD_SILENTLY_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);

export const UpdateLeadSilentlyFail = createAction(
  UPDATE_LEAD_SILENTLY_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// SendMail
export const MAIL_LEAD = '[Leads Module] Mail Lead';
export const MAIL_LEAD_SUCCESS = '[Leads Module] Mail Lead Success';
export const MAIL_LEAD_FAIL = '[Leads Module] Mail Lead Fail';

export const MailLead = createAction(
  MAIL_LEAD,
  props<{ iri: string, payload: LeadsMail }>()
);

export const MailLeadSuccess = createAction(
  MAIL_LEAD_SUCCESS,
  props<{ response: Lead }>()
);
export const MailLeadFail = createAction(
  MAIL_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////// Update Lead Column

export const UPDATE_LEAD_COLUMNS = '[Leads Module] Update Lead Columns';
export const UPDATE_LEAD_COLUMNS_SUCCESS = '[Leads Module] Update Lead Columns Success';
export const UPDATE_LEAD_COLUMNS_FAIL = '[Leads Module] Update Lead Columns Fail';

export const UpdateLeadColumns = createAction(
  UPDATE_LEAD_COLUMNS,
  props<{ payload: { leads: Lead[] } }>()
);

export const UpdateLeadColumnsSuccess = createAction(
  UPDATE_LEAD_COLUMNS_SUCCESS,
  props<{ response: UpdateLeadColumnsApiResponse }>()
);

export const UpdateLeadColumnsFail = createAction(
  UPDATE_LEAD_COLUMNS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////// Transform (PATCH)
export const TRANSFORM_LEAD = '[Leads Module] Transform Lead';
export const TRANSFORM_LEAD_SUCCESS = '[Leads Module] Transform Lead Success';
export const TRANSFORM_LEAD_FAIL = '[Leads Module] Transform Lead Fail';

export const TransformLead = createAction(
  TRANSFORM_LEAD,
  props<{ iri: string, payload?: any }>()
);

export const TransformLeadSuccess = createAction(
  TRANSFORM_LEAD_SUCCESS,
  props<{ iri: string, response: Order }>()
);

export const TransformLeadFail = createAction(
  TRANSFORM_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_LEAD = '[Leads Module] Delete Lead';
export const DELETE_LEAD_SUCCESS = '[Leads Module] Delete Lead Success';
export const DELETE_LEAD_FAIL = '[Leads Module] Delete Lead Fail';

export const DeleteLead = createAction(
  DELETE_LEAD,
  props<{ iri: string }>()
);

export const DeleteLeadSuccess = createAction(
  DELETE_LEAD_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteLeadFail = createAction(
  DELETE_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////// Delete Archived Lead
export const DELETE_ARCHIVED_LEAD = '[Leads Module] Delete Archived Lead';
export const DELETE_ARCHIVED_LEAD_SUCCESS = '[Leads Module] Delete Archived Lead Success';
export const DELETE_ARCHIVED_LEAD_FAIL = '[Leads Module] Delete Archived Lead Fail';

export const DeleteArchivedLead = createAction(
  DELETE_ARCHIVED_LEAD,
  props<{ iri: string }>()
);

export const DeleteArchivedLeadSuccess = createAction(
  DELETE_ARCHIVED_LEAD_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteArchivedLeadFail = createAction(
  DELETE_ARCHIVED_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////// Archive
export const ARCHIVE_LEAD = '[Leads Module] Archive Lead';
export const ARCHIVE_LEAD_SUCCESS = '[Leads Module] Archive Lead Success';
export const ARCHIVE_LEAD_FAIL = '[Leads Module] Archive Lead Fail';

export const ArchiveLead = createAction(
  ARCHIVE_LEAD,
  props<{ iri: string, payload: { workflow: string, transition: string } }>()
);

export const ArchiveLeadSuccess = createAction(
  ARCHIVE_LEAD_SUCCESS,
  props<{ iri: string, response: fromModuleModels.Lead }>()
);

export const ArchiveLeadFail = createAction(
  ARCHIVE_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Un-Archive
export const UN_ARCHIVE_LEAD = '[Leads Module] Un-Archive Lead';
export const UN_ARCHIVE_LEAD_SUCCESS = '[Leads Module] Un-Archive Lead Success';
export const UN_ARCHIVE_LEAD_FAIL = '[Leads Module] Un-Archive Lead Fail';

export const UnArchiveLead = createAction(
  UN_ARCHIVE_LEAD,
  props<{ iri: string, payload: { workflow: string, transition: string } }>()
);

export const UnArchiveLeadSuccess = createAction(
  UN_ARCHIVE_LEAD_SUCCESS,
  props<{ iri: string, response: fromModuleModels.Lead }>()
);

export const UnArchiveLeadFail = createAction(
  UN_ARCHIVE_LEAD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_LEAD = '[Leads Module] Reset Current Lead';

export const ResetCurrentLead = createAction(
  RESET_CURRENT_LEAD
);

export const RESET_LEADS_LOADED = '[Leads Module] Reset Loads Loaded';

export const ResetLeadsLoaded = createAction(
  RESET_LEADS_LOADED
);
