import { CustomerAccountInvitationsService } from './customer-account-invitations.service';
import { CustomerAccountsService } from './customer-accounts.service';
import { CustomerAddressesService } from './customer-addresses.service';
import { CustomerCommentTagsService } from './customer-comment-tags.service';
import { CustomerCommentsService } from './customer-comments.service';
import { CustomerContactTypesService } from './customer-contact-types.service';
import { CustomerContactsService } from './customer-contacts.service';
import { CustomerPartnerStatusesService } from './customer-partner-statuses.service';
import { CustomerTypesService } from './customer-types.service';
import { CustomersService } from './customers.service';
import { PartnerWebsiteInvitationsService } from './partner-website-invitations.service';
import { PartnerWebsitesService } from './partner-websites.service';

export const AllServices = [
  CustomerAccountInvitationsService,
  CustomerAccountsService,
  CustomerAddressesService,
  CustomerCommentTagsService,
  CustomerCommentsService,
  CustomerContactTypesService,
  CustomerContactsService,
  CustomerPartnerStatusesService,
  CustomerTypesService,
  CustomersService,
  PartnerWebsiteInvitationsService,
  PartnerWebsitesService,
];

export * from './customer-account-invitations.service';
export * from './customer-accounts.service';
export * from './customer-addresses.service';
export * from './customer-comment-tags.service';
export * from './customer-comments.service';
export * from './customer-contact-types.service';
export * from './customer-contacts.service';
export * from './customer-partner-statuses.service';
export * from './customer-types.service';
export * from './customers.service';
export * from './partner-website-invitations.service';
export * from './partner-websites.service';
