import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {AbstractApiResponse} from '../../../shared/models';
import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SHIPMENT_SERVICE = '[Shipping Module] Read Shipment Service';
export const READ_SHIPMENT_SERVICE_SUCCESS = '[Shipping Module] Read Shipment Service Success';
export const READ_SHIPMENT_SERVICE_FAIL = '[Shipping Module] Read Shipment Service Fail';
export const ReadShipmentService = createAction(
  READ_SHIPMENT_SERVICE,
  props<{ iri: string }>()
);
export const ReadShipmentServiceSuccess = createAction(
  READ_SHIPMENT_SERVICE_SUCCESS,
  props<{ response: fromModuleModels.ShipmentService }>()
);
export const ReadShipmentServiceFail = createAction(
  READ_SHIPMENT_SERVICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SHIPMENT_SERVICES = '[Shipping Module] Read Shipment Services';
export const READ_SHIPMENT_SERVICES_SUCCESS = '[Shipping Module] Read Shipment Services Success';
export const READ_SHIPMENT_SERVICES_FAIL = '[Shipping Module] Read Shipment Services Fail';
export const ReadShipmentServices = createAction(
  READ_SHIPMENT_SERVICES,
  props<{ serviceType: string }>()
);
export const ReadShipmentServicesSuccess = createAction(
  READ_SHIPMENT_SERVICES_SUCCESS,
  props<{ response: AbstractApiResponse | any }>()
);
export const ReadShipmentServicesFail = createAction(
  READ_SHIPMENT_SERVICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
