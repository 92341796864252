import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SpecialAgreementsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { SpecialAgreementsService } from '../../services/special-agreements.service';

@Injectable()
export class SpecialAgreementsEffects {
  createSpecialAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecialAgreementsActions.CreateSpecialAgreement),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createSpecialAgreement(payload).pipe(
          map(response =>
            SpecialAgreementsActions.CreateSpecialAgreementSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              SpecialAgreementsActions.CreateSpecialAgreementFail({ response })
            )
          )
        );
      })
    )
  );
  getSpecialAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecialAgreementsActions.ReadSpecialAgreement),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getSpecialAgreement(iri).pipe(
          map(response =>
            SpecialAgreementsActions.ReadSpecialAgreementSuccess({ response })
          ),
          catchError(response =>
            of(SpecialAgreementsActions.ReadSpecialAgreementFail({ response }))
          )
        );
      })
    )
  );
  getSpecialAgreements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecialAgreementsActions.ReadSpecialAgreements),
      map(action => action),
      switchMap(() => {
        return this.service.getSpecialAgreements().pipe(
          map(response =>
            SpecialAgreementsActions.ReadSpecialAgreementsSuccess({ response })
          ),
          catchError(response =>
            of(SpecialAgreementsActions.ReadSpecialAgreementsFail({ response }))
          )
        );
      })
    )
  );
  updateSpecialAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecialAgreementsActions.UpdateSpecialAgreement),
      map(action => action),
      switchMap(payload => {
        return this.service.updateSpecialAgreement(payload).pipe(
          map(response =>
            SpecialAgreementsActions.UpdateSpecialAgreementSuccess({ response })
          ),
          catchError(response =>
            of(
              SpecialAgreementsActions.UpdateSpecialAgreementFail({ response })
            )
          )
        );
      })
    )
  );
  deleteSpecialAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecialAgreementsActions.DeleteSpecialAgreement),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteSpecialAgreement(iri).pipe(
          map(() =>
            SpecialAgreementsActions.DeleteSpecialAgreementSuccess({ iri })
          ),
          catchError(response =>
            of(
              SpecialAgreementsActions.DeleteSpecialAgreementFail({ response })
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SpecialAgreementsActions.DeleteSpecialAgreementFail,
          SpecialAgreementsActions.UpdateSpecialAgreementFail,
          SpecialAgreementsActions.ReadSpecialAgreementsFail,
          SpecialAgreementsActions.ReadSpecialAgreementFail,
          SpecialAgreementsActions.CreateSpecialAgreementFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  specialAgreementActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SpecialAgreementsActions.CreateSpecialAgreementSuccess,
        SpecialAgreementsActions.UpdateSpecialAgreementSuccess
      ),
      map(({ type }) => {
        const text =
          type === SpecialAgreementsActions.CREATE_SPECIAL_AGREEMENT_SUCCESS
            ? 'Eine neue Sondervereinbarung wurde angelegt.'
            : 'Das Sondervereinbarung wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: SpecialAgreementsService,
    private notifierService: NotifierService
  ) {}
}
