import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { ApplicationState } from '../../../application-state/store';
import { AuthService } from '../../../auth/services/auth.service';
import { FollowUpCreateAndUpdateDialogComponent } from '../../../dashboard/components/follow-up-create-and-update-dialog/follow-up-create-and-update-dialog.component';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  PasswordsSelectors,
  UserProfileSelectors
} from '../../../auth/store/selectors';
import {
  LogoutUser,
  PasswordsActions,
  UserProfilesActions
} from '../../../auth/store';
import { PasswordUpdate } from '../../../auth/models';
import { Actions } from '@ngrx/effects';
import { combineLatestArray } from 'rxjs-etc';

@Component({
  selector: 'app-smart-bar',
  styleUrls: ['smartbar.component.scss'],
  template: `
    <div class="smartbar__outer">
      <div class="smartbar__wrapper wrap">
        <div class="smartbar grid">
          <div class="column-9">
            <span routerLink="dashboard" class="logo">
              <img
                src="assets/images/logos/logo-bmoffice24.png"
                class="img--resp"
                alt="Zurück zum Dashboard"
              />
            </span>
          </div>

          <div class="column-5 grid grid-no-gutter grid-right">
            <button class="follow-up-btn" (click)="openCreateFollowUpDialog()">
              <mat-icon class="m-r--8">
                add
              </mat-icon>
              <span>Wvl erstellen</span>
            </button>
            <app-user-notifications></app-user-notifications>
            <app-user-menu
              [profile]="profile$ | async"
              (requestShowPasswordForm)="
                handleShowPasswordFormModal(passwordFormModal)
              "
              (requestShowProfileForm)="
                handleShowProfileFormModal(userProfileFormModal)
              "
            ></app-user-menu>
            <logout-button
              (requestLogoutUser)="handleLogoutUser()"
            ></logout-button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #passwordFormModal>
      <app-loading-overlay
        *ngIf="passwordUpdateIsLoading$ | async"
      ></app-loading-overlay>
      <mat-card-header><h2>Password bearbeiten</h2></mat-card-header>
      <mat-card-content>
        <app-password-form
          (requestUpdatePassword)="handleUpdatePassword($event)"
          (cancelEdit)="handleCloseModal()"
        ></app-password-form>
      </mat-card-content>
    </ng-template>

    <ng-template #userProfileFormModal>
      <user-profile-form
        [profile$]="profile$"
        (cancelEdit)="handleCloseModal()"
        (requestUpdateUserProfile)="handleRequestUpdateUserProfile($event)"
      ></user-profile-form>
    </ng-template>

    <ng-template #loading>
      <mat-spinner diameter="100"></mat-spinner>
    </ng-template>
  `
})
export class SmartBarComponent implements OnInit, OnDestroy {
  profile$: Observable<any>;
  onDestroy$: Subject<any> = new Subject<any>();
  passwordUpdateIsLoading$: Observable<boolean>;
  lastRetry = 500;
  private userIri = '';

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private store$: Store<ApplicationState>,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    combineLatestArray([
      this.authService.jwtPayload$.pipe(
        map(e => e?.id),
        filter(e => !!e)
      ),
      this.authService.userType$.pipe(filter(e => !!e))
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([id, userType]) => {
        this.userIri = `/api/${userType}s/${id}`;
        this.store$.dispatch(
          UserProfilesActions.ReadUserProfile({ iri: this.userIri })
        );
      });
    this.passwordUpdateIsLoading$ = this.store$.select(
      PasswordsSelectors.isLoading
    );
    // this.actions$
    //   .pipe(
    //     ofType(UserProfilesActions.ReadUserProfileFail),
    //     takeUntil(this.onDestroy$)
    //   )
    //   .subscribe(() => {
    //     setTimeout(() => {
    //       vm.store$.dispatch(UserProfilesActions.ReadUserProfile({ iri }));
    //     }, Math.round(Math.random() * 60) + this.lastRetry);
    //   });
    this.profile$ = this.store$.select(UserProfileSelectors.selectUserProfile);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleCloseModal(): void {
    this.dialog.closeAll();
  }

  handleUpdatePassword(payload: PasswordUpdate): void {
    this.store$.dispatch(
      PasswordsActions.UpdatePassword({ iri: this.userIri, payload })
    );
  }

  handleRequestUpdateUserProfile(payload: { iri: string; payload: any }): void {
    this.store$.dispatch(UserProfilesActions.UpdateUserProfile(payload));
  }

  handleShowProfileFormModal(ref: TemplateRef<any>): void {
    this.dialog.open(ref, { disableClose: true });
  }

  handleShowPasswordFormModal(ref: TemplateRef<any>): void {
    this.dialog.open(ref, { disableClose: false, panelClass: 'card-relative' });
  }

  handleLogoutUser(): void {
    this.store$.dispatch(LogoutUser());
  }

  openCreateFollowUpDialog(): void {
    this.dialog.open(FollowUpCreateAndUpdateDialogComponent, {
      disableClose: false
    });
  }
}
