import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { StorageSystemManufacturer } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class StorageSystemManufacturersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createStorageSystemManufacturer(
    payload: StorageSystemManufacturer
  ): Observable<StorageSystemManufacturer> {
    return this.rolesService.userHasRoleFilter<StorageSystemManufacturer>(
      'ROLE_MWS_PRODUCT_MANUFACTURER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/storage_system_manufacturers`,
              payload
            )
    );
  }

  getStorageSystemManufacturer(
    iri: string
  ): Observable<StorageSystemManufacturer> {
    return this.rolesService.userHasRoleFilter<StorageSystemManufacturer>(
      'ROLE_MWS_PRODUCT_MANUFACTURER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getStorageSystemManufacturers(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PRODUCT_MANUFACTURER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/storage_system_manufacturers`)
    );
  }

  updateStorageSystemManufacturer({
    iri,
    payload
  }): Observable<StorageSystemManufacturer> {
    return this.rolesService.userHasRoleFilter<StorageSystemManufacturer>(
      'ROLE_MWS_PRODUCT_MANUFACTURER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteStorageSystemManufacturer(
    iri: string
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PRODUCT_MANUFACTURER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
