import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import {
  CustomerDataMedium,
  CustomerReplacementStockItem,
  CustomerStockItem,
  DataMedium,
  EnclosureDataMedium,
  ReplacementStockItem
} from '../../../warehouse/models';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CustomerReplacementStockItemsService,
  CustomerStockItemsService,
  ReplacementStockItemsService
} from '../../../warehouse/services';
import { extractIri } from '../../utilities/objects.utility';
import { takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  ProductsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import {
  Product,
  StorageLocation,
  StorageSystem,
  StorageSystemManufacturer
} from '../../../master-data/models';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { MatDialog } from '@angular/material/dialog';
import { EditCustomerHardwareDialogComponent } from '../../../warehouse/components/edit-customer-hardware-dialog/edit-customer-hardware-dialog.component';
import { ShowCustomerHardwareDialogComponent } from '../../../warehouse/components/show-customer-hardware-dialog/show-customer-hardware-dialog.component';
import { WarehouseRights } from '../../security/warehouseRights';

@Component({
  selector: 'app-stock-item-display-line',
  styleUrls: ['./stock-item-display-line.component.scss'],
  template: `
    <div class="card pos-relative my-2 p-3" style="min-height: 60px;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <!--      <pre>{{ this.detailedStockItem | json }}</pre>-->
      <!--      <pre>{{ this.inputSelectedDataMedia | json }}</pre>-->
      <!--      <pre>{{ this.allSelectedDataMedia | json }}</pre>-->
      <!--      <pre>{{ this.localSelectedDataMedia | json }}</pre>-->
      <!--            <pre>{{validDataMediaStates|json}}</pre>-->
      <ng-container *ngIf="detailedStockItem">
        <div class="d-flex border-bottom-lightgrey pe-2">
          <div
            class="pt-4 pe-2 cursor-pointer"
            *ngIf="selectMode === 'both' || selectMode === 'dataMedium'"
            (click)="toggleSelectStockItem()"
          >
            <mat-icon
              >{{
                stockItemSelected === 'all'
                  ? 'check_box'
                  : stockItemSelected === 'none'
                  ? 'check_box_outline_blank'
                  : 'indeterminate_check_box'
              }}
            </mat-icon>
          </div>
          <div class="flex-grow-1">
            <div class="row">
              <div class="col-xl-3 col-lg-6 px-2">
                <div
                  class="row ps-2"
                  *ngIf="detailedStockItem.order && !hideToOrderBtn"
                >
                  <app-stock-item-order-display-line
                    [orderIri]="detailedStockItem.order"
                  ></app-stock-item-order-display-line>
                </div>
                <div class="d-flex" *ngIf="detailedStockItem.storageSystemType">
                  <div class="text-bold p-2" style="width: 132px;">
                    Datenträgertyp:
                  </div>
                  <div class="flex-grow-1 p-2">
                    {{
                      detailedStockItem.storageSystemType &&
                      storageSystemTypeEntities[
                        detailedStockItem.storageSystemType
                      ]
                        ? storageSystemTypeEntities[
                            detailedStockItem.storageSystemType
                          ]?.name
                        : '-'
                    }}
                  </div>
                </div>
                <div
                  class="d-flex"
                  *ngIf="
                    detailedStockItem.storageLocation &&
                    storageLocationEntities[detailedStockItem.storageLocation]
                  "
                >
                  <div class="text-bold p-2" style="width: 132px;">
                    Lagerplatz:
                  </div>
                  <div class="flex-grow-1 p-2">
                    {{
                      detailedStockItem.storageLocation &&
                      storageLocationEntities[detailedStockItem.storageLocation]
                        ? storageLocationEntities[
                            detailedStockItem.storageLocation
                          ]?.storageLocationNumber
                        : '-'
                    }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="text-bold p-2" style="width: 132px;">
                    Angelegt:
                  </div>
                  <div class="flex-grow-1 p-2">
                    {{
                      (detailedStockItem.createdAt | momentDateWithTime) || '-'
                    }}
                  </div>
                </div>
                <div
                  class="d-flex"
                  *ngIf="
                    detailedStockItem.createdAt !== detailedStockItem.updatedAt
                  "
                >
                  <div class="text-bold p-2" style="width: 132px;">
                    Bearbeitet:
                  </div>
                  <div class="flex-grow-1 p-2">
                    {{
                      (detailedStockItem.updatedAt | momentDateWithTime) || '-'
                    }}
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6">
                <div class="d-flex ">
                  <div class="text-bold p-2">Bemerkung:</div>
                  <div
                    class="rounded-2 bordered flex-grow-1 p-2 pre-like"
                    style="min-height: 2.5rem;  "
                  >
                    {{ detailedStockItem.description || '-' }}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-4 col-lg-6"
                *ngIf="detailedStockItem['@type'] !== 'ReplacementStockItem'"
              >
                <div class="d-flex ">
                  <div class="text-bold p-2">Zubehör:</div>
                  <div
                    class="rounded-2 bordered flex-grow-1 p-2 pre-like"
                    style="min-height: 2.5rem;"
                  >
                    {{ detailedStockItem.accessories || '-' }}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-6 text-right px-0"
                *ngIf="detailedStockItem['@type'] !== 'ReplacementStockItem'"
              >
                <div
                  style="width: 42px; display: inline-block;"
                  *ngIf="detailedStockItem['@type'] === 'CustomerStockItem'"
                >
                  <mat-icon
                    *ngIf="detailedStockItem.dataMediaOkLogisticsDepartment"
                    style="width: 18px;"
                    [matTooltip]="
                      'OK Logistik: ' +
                      (detailedStockItem.dataMediaOkLogisticsDepartment === null
                        ? 'unbekannt'
                        : detailedStockItem.dataMediaOkLogisticsDepartment
                        ? 'ja'
                        : 'nein')
                    "
                    class="inline-icon pe-1"
                    >{{
                      detailedStockItem.dataMediaOkLogisticsDepartment === null
                        ? 'help_outline'
                        : detailedStockItem.dataMediaOkLogisticsDepartment
                        ? 'check'
                        : 'close'
                    }}
                  </mat-icon>
                  <span
                    *ngIf="
                      detailedStockItem.dataMediaOkLogisticsDepartment &&
                      detailedStockItem.dataMediaOkTechnicalDepartment
                    "
                    >/</span
                  >
                  <mat-icon
                    style="width: 18px;"
                    *ngIf="detailedStockItem.dataMediaOkTechnicalDepartment"
                    [matTooltip]="
                      'OK Technik: ' +
                      (detailedStockItem.dataMediaOkTechnicalDepartment === null
                        ? 'unbekannt'
                        : detailedStockItem.dataMediaOkTechnicalDepartment
                        ? 'ja'
                        : 'nein')
                    "
                    class="inline-icon"
                    >{{
                      detailedStockItem.dataMediaOkTechnicalDepartment === null
                        ? 'help_outline'
                        : detailedStockItem.dataMediaOkTechnicalDepartment
                        ? 'check'
                        : 'close'
                    }}
                  </mat-icon>
                </div>
                <div style="width: 65px; display: inline-block;">
                  <mat-icon
                    class="cursor-pointer m-1"
                    *ngIf="
                      detailedStockItem['@type'] !== 'ReplacementStockItem'
                    "
                    (click)="showDetailsDialog()"
                    >visibility
                  </mat-icon>
                  <mat-icon
                    class="cursor-pointer m-1"
                    *ngIf="
                      detailedStockItem['@type'] !== 'ReplacementStockItem' &&
                        canEdit$ | async
                    "
                    (click)="showEditDialog()"
                    >edit
                  </mat-icon>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="detailedStockItem.product">
              <div class="col-xl-3 col-lg-6 d-flex">
                <div class="text-bold p-2" style="width: 132px">Produkt:</div>
                <div class="flex-grow-1 p-2">
                  {{ productEntities[detailedStockItem.product]?.name || '-' }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 d-flex">
                <div class="text-bold p-2" style="width: 90px">Hersteller:</div>
                <div class="flex-grow-1 p-2">
                  {{
                    storageSystemManufacturersEntities[
                      productEntities[detailedStockItem.product]
                        ?.storageSystemManufacturer
                    ]?.name || '-'
                  }}
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 d-flex">
                <div class="text-bold p-2" style="width: 90px">Größe:</div>
                <div class="flex-grow-1 p-2">
                  {{
                    productEntities[detailedStockItem.product]
                      ?.storageSystemSize | number: '1.0'
                  }}
                  GB
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--    </div>-->
        <ng-container *ngIf="!!enclosure">
          <app-data-media-line
            (toggleSelect)="toggleSelectDataMedia(enclosure)"
            (updateDataMedium)="updateEnclosureDataMedium($event)"
            [dataMedia]="enclosure"
            [readonlyArchive]="readonlyArchive"
            [readonly]="readonly"
            [selected]="isSelected(enclosure)"
            [selectionTyp]="selectMode ? selectMode : 'select'"
            [validDataMediaStates]="validDataMediaStates"
          ></app-data-media-line>
        </ng-container>
        <ng-container *ngIf="!!dataMediums">
          <app-data-media-line
            (toggleSelect)="toggleSelectDataMedia(dataMedium)"
            (updateDataMedium)="updateDataMedium($event)"
            *ngFor="let dataMedium of dataMediums"
            [dataMedia]="dataMedium"
            [readonlyArchive]="readonlyArchive"
            [readonly]="readonly"
            [selected]="isSelected(dataMedium)"
            [selectionTyp]="selectMode ? selectMode : 'select'"
            [validDataMediaStates]="validDataMediaStates"
          ></app-data-media-line>
        </ng-container>
        <div
          *ngIf="detailedStockItem && dataMediums.length <= 0 && !enclosure"
          class="p-2"
        >
          Keine Datenträger vorhanden
        </div>

        <!--<ng-container *ngIf="!detailedStockItem || detailedStockItem['@type']!=='CustomerStockItem'">
                <hr>

                <div class="p-2">
                  <pre *ngIf="!detailedStockItem">{{stockItem|json}}</pre>
                  <pre
                    *ngIf="detailedStockItem">{{detailedStockItem|json}}</pre>
                </div>
              </ng-container>-->
      </ng-container>
      <!--      <pre>{{ dataMediums | json }}</pre>-->
    </div>
  `
})
export class StockItemDisplayLineComponent extends BaseOnDestroyComponent
  implements OnInit, OnChanges {
  @Input() stockItem:
    | ReplacementStockItem
    | CustomerStockItem
    | CustomerReplacementStockItem;
  @Input() @Optional() hideToOrderBtn = false;
  @Input() @Optional() selectMode = 'none'; // possible values: none, both, dataMedia, StockItems
  @Input() @Optional() filterDataMedia: Array<string> = null;
  @Input() @Optional() inputSelectedDataMedia: Array<string> = [];
  @Input() @Optional() validDataMediaStates: Array<string> = [];
  // @Input() @Optional() dataMediaSelectionFilterFn: (dataMedium: DataMedium) => boolean;
  @Input() @Optional() filterDataMediaFn: (args: DataMedium) => boolean = null;
  @Input() @Optional() readonly = false;
  @Input() @Optional() readonlyArchive = false;

  @Output() changeSelectedDataMedia: EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }> = new EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }>();
  detailedStockItem:
    | ReplacementStockItem
    | CustomerStockItem
    | CustomerReplacementStockItem;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  storageLocationEntities: { [p: string]: StorageLocation };
  storageSystemTypeEntities: { [p: string]: StorageSystem };
  storageSystemManufacturersEntities: {
    [p: string]: StorageSystemManufacturer;
  };

  productEntities: { [p: string]: Product };

  allSelectedDataMedia: { [p: string]: boolean } = {};

  constructor(
    private customerStockItemService: CustomerStockItemsService,
    private replacementStockItemsService: ReplacementStockItemsService,
    private customerReplacementStockItemsService: CustomerReplacementStockItemsService,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private warehouseRight: WarehouseRights
  ) {
    super();
  }

  get canEdit$(): Observable<boolean> {
    return this.warehouseRight.canEdit$;
  }

  get dataMediaIris(): Array<string> {
    const neededIris = [];
    if (this.enclosure) {
      neededIris.push(extractIri(this.enclosure));
    }
    if (this.dataMediums?.length) {
      neededIris.push(...this.dataMediums.map(e => extractIri(e)));
    }
    return neededIris;
  }

  get localSelectedDataMedia(): { [p: string]: boolean } {
    const returnObj = {};
    for (const iri of this.dataMediaIris) {
      if (this.allSelectedDataMedia[iri]) {
        returnObj[iri] = true;
      }
    }
    return returnObj;
  }

  get stockItemSelected(): string {
    if (!this.detailedStockItem) {
      return 'none';
    }
    if (this.dataMediaIris.length <= 0) {
      return 'none';
    }
    if (this.dataMediaIris.every(e => this.allSelectedDataMedia[e])) {
      return 'all';
    } else if (this.dataMediaIris.some(e => this.allSelectedDataMedia[e])) {
      return 'partial';
    } else {
      return 'none';
    }
  }

  get orderLink(): string[] | null {
    if (!this.detailedStockItem) {
      return null;
    }
    if (!('order' in this.detailedStockItem)) {
      return null;
    }
    return [
      '/orders',
      getUuidFromIri(extractIri(this.detailedStockItem.order))
    ];
  }

  get enclosure(): null | EnclosureDataMedium {
    if (!this.detailedStockItem) {
      return null;
    } else if (!('enclosure' in this.detailedStockItem)) {
      return null;
    } else if (
      this.filterDataMediaFn &&
      !this.filterDataMediaFn(this.detailedStockItem.enclosure)
    ) {
      return null;
    } else if (
      this.filterDataMedia &&
      this.filterDataMedia.indexOf(
        extractIri(this.detailedStockItem.enclosure)
      ) <= -1
    ) {
      return null;
    } else {
      return this.detailedStockItem.enclosure;
    }
  }

  get dataMediums(): null | DataMedium[] {
    if (!this.detailedStockItem) {
      return null;
    } else if (!('dataMediums' in this.detailedStockItem)) {
      return null;
    }
    if (this.filterDataMedia || this.filterDataMediaFn) {
      return this.detailedStockItem.dataMediums.filter(
        e =>
          (!this.filterDataMedia ||
            (this.filterDataMedia &&
              this.filterDataMedia.indexOf(extractIri(e)) > -1)) &&
          (!this.filterDataMediaFn ||
            (this.filterDataMediaFn && this.filterDataMediaFn(e)))
      );
    }
    return this.detailedStockItem.dataMediums;
  }

  ngOnInit(): void {
    this.loadDetails();
    this.store$
      .select(StorageLocationsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageLocationEntities = entities;
      });
    this.store$
      .select(ProductsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.productEntities = entities;
      });
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemManufacturersEntities = entities;
      });
    this.store$
      .select(StorageSystemsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemTypeEntities = entities;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputSelectedDataMedia) {
      for (const iri of changes.inputSelectedDataMedia.currentValue as Array<
        string
      >) {
        this.allSelectedDataMedia[iri] = true;
      }
      for (const missingIri of Object.keys(this.allSelectedDataMedia).filter(
        e => changes.inputSelectedDataMedia.currentValue.indexOf(e) <= -1
      )) {
        delete this.allSelectedDataMedia[missingIri];
      }
    } else {
      this.allSelectedDataMedia = {};
    }
  }

  updateParentSelectedDataMedia(): void {
    this.changeSelectedDataMedia.emit({
      selected: Object.keys(this.localSelectedDataMedia),
      unselected: this.dataMediaIris.filter(
        e => Object.keys(this.localSelectedDataMedia).indexOf(e) <= -1
      )
    });
  }

  toggleSelectStockItem(): void {
    const tempSelected = { ...this.allSelectedDataMedia };
    if (this.readonly) {
      return;
    }
    if (this.stockItemSelected === 'all') {
      for (const iri of this.dataMediaIris) {
        delete tempSelected[iri];
      }
    } else {
      for (const iri of this.dataMediaIris) {
        tempSelected[iri] = true;
      }
    }
    this.allSelectedDataMedia = tempSelected;
    this.updateParentSelectedDataMedia();
  }

  toggleSelectDataMedia(dataMedium: CustomerDataMedium): void {
    const iri = extractIri(dataMedium);
    if (this.allSelectedDataMedia[iri]) {
      delete this.allSelectedDataMedia[iri];
    } else {
      this.allSelectedDataMedia[iri] = true;
    }
    this.updateParentSelectedDataMedia();
  }

  isSelected(dataMedium: CustomerDataMedium): boolean {
    // if (this.dataMediaSelectionFilterFn) {
    //   let selected = this.dataMediaSelectionFilterFn(dataMedium);
    //   if (selected === true) {
    //     if (!this.selectedDataMedia[extractIri(dataMedium)]) {
    //       this.selectedDataMedia[extractIri(dataMedium)] = true;
    //     }
    //     selected = true;
    //   }
    //   if (selected === false) {
    //     if (this.selectedDataMedia[extractIri(dataMedium)] === true) {
    //       this.selectedDataMedia[extractIri(dataMedium)] = false;
    //     }
    //     selected = false;
    //   }
    //   return selected;
    // } else {
    return this.allSelectedDataMedia[extractIri(dataMedium)];
    // }
  }

  loadDetails(): void {
    if (
      this.stockItem['@type'] === 'CustomerStockItem' &&
      !('description' in this.stockItem)
    ) {
      this.loadCustomerStockItem();
    } else if (
      this.stockItem['@type'] === 'ReplacementStockItem' &&
      !('description' in this.stockItem)
    ) {
      this.loadReplacementStockItem();
    } else if (
      this.stockItem['@type'] === 'CustomerReplacementStockItem' &&
      !('description' in this.stockItem)
    ) {
      this.loadCustomerReplacementStockItem();
    } else {
      this.detailedStockItem = this.stockItem;
    }
  }

  showEditDialog(): void {
    this.dialog.open(EditCustomerHardwareDialogComponent, {
      data: { stockItem: this.detailedStockItem }
    });
  }

  showDetailsDialog(): void {
    this.dialog.open(ShowCustomerHardwareDialogComponent, {
      data: { stockItem: this.detailedStockItem }
    });
  }

  updateEnclosureDataMedium($event: EnclosureDataMedium): void {
    if (
      'enclosure' in this.detailedStockItem &&
      extractIri(this.detailedStockItem.enclosure) === extractIri($event)
    ) {
      this.detailedStockItem = { ...this.detailedStockItem, enclosure: $event };
    }
  }

  updateDataMedium($event: DataMedium): void {
    const iri = extractIri($event);
    const copy = cloneDeep(this.detailedStockItem.dataMediums);
    const index = copy?.findIndex(e => extractIri(e) === iri);
    if (index > -1) {
      copy[index] = $event;
    }
    this.detailedStockItem = { ...this.detailedStockItem, dataMediums: copy };
  }

  private loadCustomerStockItem(): void {
    this.isLoading$.next(true);
    this.customerStockItemService
      .getCustomerStockItem(extractIri(this.stockItem))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        data => {
          this.detailedStockItem = data;
          this.isLoading$.next(false);
        },
        error => {
          console.log(error);
          this.isLoading$.next(false);
        }
      );
  }

  private loadReplacementStockItem(): void {
    this.isLoading$.next(true);
    this.replacementStockItemsService
      .getReplacementStockItem(extractIri(this.stockItem))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        data => {
          this.detailedStockItem = data;
          this.isLoading$.next(false);
        },
        error => {
          console.log(error);
          this.isLoading$.next(false);
        }
      );
  }

  private loadCustomerReplacementStockItem(): void {
    this.isLoading$.next(true);
    this.customerReplacementStockItemsService
      .getCustomerReplacementStockItem(extractIri(this.stockItem))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        data => {
          this.detailedStockItem = data;
          this.isLoading$.next(false);
        },
        error => {
          console.log(error);
          this.isLoading$.next(false);
        }
      );
  }
}
