import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';

import {LAB_LOCATIONS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {LabLocation} from '../../models';
import {ApplicationState} from '../../../application-state/store';
import {State} from '../reducers/lab-locations.reducers';

type BaseType = LabLocation;
type StateType = State;

const NAMESPACE = LAB_LOCATIONS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, BaseType>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, BaseType>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);

