import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerContactsActions, CustomersActions} from '../actions/';
import {CustomerContactsService} from '../../services';
import * as fromModuleModels from '../../models';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';

@Injectable()
export class CustomerContactsEffects {

  constructor(private actions$: Actions, private ccs: CustomerContactsService) {
  }


  CreateCustomerContact$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactsActions.CreateCustomerContact),
    map(action => action),
    switchMap(({customer, payload}) => {
      return this.ccs.createCustomerContact(payload).pipe(
        map((response: any) => CustomerContactsActions.CreateCustomerContactSuccess({customer, response})),
        catchError(response => of(CustomerContactsActions.CreateCustomerContactFail({response})))
      );
    })
  ));


  ReadCustomerContacts$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactsActions.ReadCustomerContacts,),
    map(action => action),
    switchMap(({page, params}) => {
      return this.ccs._readContacts(page, params).pipe(
        map((response: any) => CustomerContactsActions.ReadCustomerContactsSuccess({response})),
        catchError(response => of(CustomerContactsActions.ReadCustomerContactsFail({response})))
      );
    })
  ));
  ReadCustomerContact$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactsActions.ReadCustomerContact,),
    map(action => action),
    switchMap(({iri}) => {
      return this.ccs.readContact(iri).pipe(
        map((response: any) => CustomerContactsActions.ReadCustomerContactSuccess({response})),
        catchError(response => of(CustomerContactsActions.ReadCustomerContactFail({response})))
      );
    })
  ));


  UpdateCustomerContact$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactsActions.UpdateCustomerContact),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.ccs.updateCustomerContact(iri, payload).pipe(
        map((response: fromModuleModels.CustomerContact) => CustomerContactsActions.UpdateCustomerContactSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CustomerContactsActions.UpdateCustomerContactFail({response})))
      );
    })
  ));


  DeleteCustomerContact$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerContactsActions.DeleteCustomerContact),
    map(action => action),
    switchMap(({iri}) => {
      return this.ccs.deleteCustomerContact(iri).pipe(
        map(() => CustomerContactsActions.DeleteCustomerContactSuccess({iri})),
        catchError((response) => of(CustomerContactsActions.DeleteCustomerContactFail({response})))
      );
    })
  ));


  ConfirmSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerContactsActions.CreateCustomerContactSuccess,
      CustomerContactsActions.UpdateCustomerContactSuccess,
    ),
    map(({type}) => {

      const text = type === CustomerContactsActions.CREATE_CUSTOMER_CONTACT_SUCCESS
        ? `Ein neuer Kundenkontakt wurde angelegt.`
        : 'Die Daten des Kundenkontaktes wurden aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
