import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { OperatingSystem } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class OperatingSystemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createOperatingSystem(payload: OperatingSystem): Observable<OperatingSystem> {
    return this.rolesService.userHasRoleFilter<OperatingSystem>(
      'ROLE_MWS_OPERATING_SYSTEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/operating_systems`, payload)
    );
  }

  getOperatingSystem(iri: string): Observable<OperatingSystem> {
    return this.rolesService.userHasRoleFilter<OperatingSystem>(
      'ROLE_MWS_OPERATING_SYSTEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getOperatingSystems(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OPERATING_SYSTEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/operating_systems`)
    );
  }

  updateOperatingSystem({ iri, payload }): Observable<OperatingSystem> {
    return this.rolesService.userHasRoleFilter<OperatingSystem>(
      'ROLE_MWS_OPERATING_SYSTEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteOperatingSystem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OPERATING_SYSTEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
