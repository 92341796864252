import {Component, EventEmitter, Input, Output} from '@angular/core';
import {extractUUID, extractTypeByIri} from '../../../shared/utilities/objects.utility';
import {DocumentDelivery, PartialInvoice, PayableInvoice, PaymentProcess} from '../../models';

@Component({
  selector: 'app-base-invoice-viewer',
  styleUrls: ['base-invoice-viewer.component.scss'],
  template: `
    <div class="card mat-elevation-z1 mt-3">

      <div class="card__heading">
        <span>Verknüpfte Rechnungen</span>
      </div>

      <div class="card__content">
        <div class="grid grid-no-gutter">
          <!--          <pre>{{paymentProcess|json}}</pre>-->
          <div class="column-14">
            <!--            <pre>{{paymentProcess|json}}</pre>-->
            <p
              *ngIf="!(paymentProcess?.partialInvoices?.length>0 ||paymentProcess?.invoices?.length>0 ||paymentProcess?.offers?.length>0)">
              Keine verknüpften Rechnungen vorhanden.</p>
            <ol
              *ngIf="(paymentProcess?.partialInvoices?.length>0 ||paymentProcess?.invoices?.length>0 ||paymentProcess?.offers?.length>0)">
              <!--              TODO Link to Invoice-->
              <li class=""
                  *ngFor="let pi of paymentProcess?.partialInvoices; let i = index">
                <a [routerLink]="['/invoices','payable',extractTypeByIri(pi), extractId(pi)]">
                  {{ pi?.invoiceNumber ? 'Abschlagsrechnung #' + pi.invoiceNumber : 'Entwurf Abschlagsrechnung' }}
                  (vom {{pi.createdAt|momentDateWithTime}})
                </a>
              </li>
              <li class=""
                  *ngFor="let pi of paymentProcess?.invoices; let i = index">
                <a [routerLink]="['/invoices','payable',extractTypeByIri(pi), extractId(pi)]">
                  {{ pi?.invoiceNumber ? ('Rechnung #' + pi.invoiceNumber) : 'Entwurf Rechnung' }}
                  (vom {{pi.createdAt|momentDateWithTime}})
                </a>
              </li>
              <li class=""
                  *ngFor="let pi of paymentProcess?.offers; let i = index">
                <a [routerLink]="['/invoices','offers',extractId(pi)]">
                  {{ pi?.invoiceNumber ? ('Angebot #' + pi.invoiceNumber) : 'Entwurf Angebot' }}
                  (vom {{pi.createdAt|momentDateWithTime}})
                </a>
              </li>
            </ol>
          </div>

          <!--          <div class="column-6">-->
          <!--            <div class="m-ta&#45;&#45;2">-->
          <!--              <button mat-button color="green"-->
          <!--                      (click)="handleRequestViewBaseInvoice(paymentProcess.invoices[0]['@id'])">-->
          <!--                <mat-icon class="m-r&#45;&#45;8">description</mat-icon>-->
          <!--                <span>Zur Rechnung</span>-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <!--<pre>{{ paymentProcess | json }}</pre>-->
  `
})
export class BaseInvoiceViewerComponent {

  @Input() invoice: PartialInvoice | PayableInvoice;
  @Input() documentDeliveries: Array<DocumentDelivery>;
  @Input() paymentProcess: PaymentProcess;

  @Output()
  requestViewBaseInvoice: EventEmitter<string> = new EventEmitter();
  extractTypeByIri = extractTypeByIri;
  extractId = extractUUID;

  constructor() {
  }

  handleRequestViewBaseInvoice(invoice: string): void {
    this.requestViewBaseInvoice.emit(invoice);
  }
}
