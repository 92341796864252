import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {CustomerCommentsActions} from '../store';
import {CustomerCommentsSelectors} from '../store/selectors';

@Injectable()
export class CustomerCommentsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    this.store.dispatch(CustomerCommentsActions.ReadCustomerComments({customerUuid: route.params.uuid}));
    return of(true);

    /*return this.loadCustomerCommentsDone(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );*/
  }

  loadCustomerCommentsDone(route: ActivatedRouteSnapshot) {

    return this.store.pipe(
      select(CustomerCommentsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {

          this.store.dispatch(CustomerCommentsActions.ReadCustomerComments({customerUuid: route.params.uuid}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
