import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { FormsService } from '../../../shared/services';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {
  Currency,
  Product,
  ProductUnit,
  Service,
  Tax
} from '../../../master-data/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import { ProductOrderItem, ServiceOrderItem } from '../../models';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  CurrenciesSelectors,
  ProductsSelectors,
  ProductUnitsSelectors,
  ServicesSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import {
  CurrenciesActions,
  ProductsActions,
  ProductUnitsActions,
  ServicesActions,
  TaxesActions
} from '../../../master-data/store';
import {
  GenericOrderItemsActions,
  ProductOrderItemsActions,
  ServiceOrderItemsActions
} from '../../store';
import { GenericOrderItem } from '../../models/generic-order-item.interface';
import { sortProducts } from '../../../shared/utilities/array.utility';
import { extractIri } from '../../../shared/utilities/objects.utility';

export type OrderItemType = 'product' | 'service' | 'generic';

@Component({
  selector: 'app-order-item-form',
  styleUrls: ['order-item-form.component.scss'],
  template: `
    <ng-container [formGroup]="sof">
      <div class="grid">
        <div class="column-14">
          <mat-button-toggle-group
            formControlName="itemType"
            aria-label="Art der Position"
            [disabled]="changeableTypeDisabled"
            class="m-b--16 block"
            (change)="updateSelectedType($event.value)"
          >
            <mat-button-toggle value="product">Produkt</mat-button-toggle>
            <mat-button-toggle value="service">Service</mat-button-toggle>
            <mat-button-toggle value="generic"
              >Freie Position</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>

        <div class="column-14">
          <div class="mat-form-field checkbox m-b--32">
            <mat-checkbox formControlName="upsell"
              >Upsell-Position</mat-checkbox
            >
          </div>
        </div>

        <div class="column-14">
          <ng-container *ngIf="!!sof.get('product')">
            <ng-select
              placeholder="Produkt auswählen"
              (change)="handleItemSelect($event)"
              [items]="products$ | async"
              [loading]="productsIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="product"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }} ({{ item.actualStock }}x)
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                {{ item.name }} ({{ item.actualStock }}x)
              </ng-template>
            </ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'product'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </ng-container>
          <ng-container *ngIf="!!sof.get('service')">
            <ng-select
              placeholder="Service auswählen"
              (change)="handleItemSelect($event)"
              [items]="services$ | async"
              [loading]="servicesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="service"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'service'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </ng-container>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Anzahl</mat-label>
            <input type="number" matInput formControlName="quantity" required />
            <mat-error>
              <app-form-error
                [fieldName]="'quantity'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14">
          <ng-select
            placeholder="Einheit"
            [items]="productUnits$ | async"
            [loading]="productUnitsIsLoading$ | async"
            bindValue="@id"
            bindLabel="name"
            required
            [clearable]="false"
            [searchable]="false"
            formControlName="unit"
          ></ng-select>

          <mat-error>
            <app-form-error
              [fieldName]="'unit'"
              [formGroup]="sof"
            ></app-form-error>
          </mat-error>
        </div>

        <ng-container formGroupName="netPricePerUnit">
          <div class="column-6">
            <mat-form-field>
              <mat-label>Preis netto je Einheit</mat-label>
              <input
                type="text"
                currencyMask
                matInput
                formControlName="value"
                required
                [options]="{ prefix: '', suffix: '', allowNegative: true }"
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'netPricePerUnit.value'"
                  [formGroup]="sof"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="mat-form-field column-6">
            <ng-select
              placeholder="Währung"
              [items]="currencies$ | async"
              [loading]="currenciesIsLoading$ | async"
              [markFirst]="true"
              bindValue="code"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="currency"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'netPricePerUnit.currency'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </div>
        </ng-container>

        <div class="mat-form-field column-2">
          <ng-select
            placeholder="Steuersatz"
            [items]="taxRates$ | async"
            [loading]="taxRatesIsLoading$ | async"
            bindValue="@id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            formControlName="taxRate"
          ></ng-select>
          <mat-error>
            <app-form-error
              [fieldName]="'taxRate'"
              [formGroup]="sof"
            ></app-form-error>
          </mat-error>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Name der Position</mat-label>
            <input type="text" matInput formControlName="title" />
            <mat-error>
              <app-form-error
                [fieldName]="'title'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <textarea
              matInput
              rows="8"
              formControlName="description"
            ></textarea>
            <mat-error>
              <app-form-error
                [fieldName]="'description'"
                [formGroup]="sof"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14 m-ta--2">
          <button
            [disabled]="sof.invalid || sof.pristine"
            mat-flat-button
            color="green"
            (click)="handleSubmit()"
          >
            <mat-icon class="m-r--8">save</mat-icon>
            <span>{{ item ? 'Aktualisieren' : 'Hinzufügen' }}</span>
          </button>
        </div>
      </div>
    </ng-container>

    <!--<pre>{{ sof.value | json }}</pre>-->
    <!--<pre>{{ productUnits$ | async | json }}</pre>-->
    <!--<pre>{{ presets$ | json }}</pre>-->
    <!--<pre>{{ products$ | async | json }}</pre>-->
  `
})
export class OrderItemFormComponent implements OnInit, OnDestroy {
  currencies$: Observable<Array<Currency>>;
  currenciesIsLoading$: Observable<boolean>;
  @Input() itemType: OrderItemType;
  @Input() items: Array<any>;
  @Input() changeableTypeDisabled: boolean;
  onDestroy$: Subject<any> = new Subject<any>();
  @Input() order: fromOrdersModuleModels.Order;
  @Input() item: GenericOrderItem | ServiceOrderItem | ProductOrderItem;
  productUnits$: Observable<Array<ProductUnit>>;
  productUnitsIsLoading$: Observable<boolean>;
  products$: Observable<Array<Product>>;
  productsIsLoading$: Observable<boolean>;
  services$: Observable<Array<Service>>;
  servicesIsLoading$: Observable<boolean>;
  taxRates$: Observable<Array<Tax>>;
  taxRatesIsLoading$: Observable<boolean>;

  @Output() changeItemType: EventEmitter<OrderItemType> = new EventEmitter();

  sof: FormGroup;
  products: Product[];

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadCurrencies();
    this.loadProductUnits();
    this.loadTaxRates();
    this.updateSelectedType(this.itemType);
    if (this.item) {
      if(this.item['@type'] === 'PartialInvoiceItem') {
        this.item.description = '';
      }
      this.fs.patchForm(this.sof, this.item);
      if (this.item.taxRate['@id']) {
        this.sof.patchValue({ taxRate: this.item.taxRate['@id'] });
      }
      if ('product' in this.item && this.item.product['@id']) {
        this.sof.patchValue({ product: this.item.product['@id'] });
      }

      this.sof.markAsUntouched();
    }
    /*this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(item => {
      this.fs.patchForm(this.sof, item);
      this.sof.markAsUntouched();
    });
*/
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.sof = this.fb.group({
      order: this.fb.control(this.order['@id'], Validators.required),
      itemType: this.fb.control(null, Validators.required),
      upsell: this.fb.control(false),
      quantity: this.fb.control(1, Validators.required),
      unit: this.fb.control(null, Validators.required),
      title: this.fb.control(null, Validators.required),
      description: this.fb.control(null),
      netPricePerUnit: this.fb.group({
        value: this.fb.control(null, [Validators.required, Validators.min(0)]),
        currency: this.fb.control(null, Validators.required)
      }),
      taxRate: this.fb.control(null, Validators.required)
    });
  }

  handleItemSelect(
    item:
      | fromMasterDataModuleModels.Product
      | fromMasterDataModuleModels.Service
      | any
  ): void {
    // Set values manually since property names differ
    const {
      price: { currency, value },
      description,
      name,
      defaultTax
    } = item;
    this.sof.get('title').setValue(name);
    this.sof.get('netPricePerUnit.currency').setValue(currency);
    this.sof.get('netPricePerUnit.value').setValue(value);
    this.sof.get('taxRate').setValue(defaultTax);
  }

  updateSelectedType(newValue: OrderItemType): void {
    this.changeItemType.emit(newValue);
    this.sof.get('itemType').setValue(newValue);
    if (newValue === 'product') {
      this.sof.addControl(
        'product',
        this.fb.control(null, Validators.required)
      );
      this.sof.removeControl('service');
      this.loadProducts();
      this.sof.get('unit').setValue('/api/product_units/PIECE');
      this.sof.get('quantity').setValue(1);
      this.sof.get('quantity').disable();
    } else if (newValue === 'service') {
      this.sof.removeControl('product');
      this.sof.addControl(
        'service',
        this.fb.control(null, Validators.required)
      );
      this.loadServices();
      this.sof.get('quantity').enable();
    } else {
      this.sof.removeControl('product');
      this.sof.removeControl('service');
      this.sof.get('quantity').enable();
    }
  }

  handleSubmit(): void {
    this.sof
      .get('netPricePerUnit.value')
      .setValue(this.sof.get('netPricePerUnit.value').value.toString());
    this.sof
      .get('quantity')
      .setValue(this.sof.get('quantity').value.toString());
    const payload = {
      ...this.sof.value,
      quantity: this.sof.get('quantity').value.toString()
    };
    if (this.item) {
      // UPDATE
      const iri = this.item['@id'];
      if (this.sof.get('itemType').value === 'product') {
        this.store$.dispatch(
          ProductOrderItemsActions.UpdateProductOrderItem({ iri, payload })
        );
      } else if (this.sof.get('itemType').value === 'service') {
        this.store$.dispatch(
          ServiceOrderItemsActions.UpdateServiceOrderItem({ iri, payload })
        );
      } else {
        this.store$.dispatch(
          GenericOrderItemsActions.UpdateGenericOrderItem({ iri, payload })
        );
      }
    } else {
      // CREATE
      if (this.sof.get('itemType').value === 'product') {
        this.store$.dispatch(
          ProductOrderItemsActions.CreateProductOrderItem({ payload })
        );
      } else if (this.sof.get('itemType').value === 'service') {
        this.store$.dispatch(
          ServiceOrderItemsActions.CreateServiceOrderItem({ payload })
        );
      } else {
        this.store$.dispatch(
          GenericOrderItemsActions.CreateGenericOrderItem({ payload })
        );
      }
    }
  }

  private loadCurrencies(): void {
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.currenciesIsLoading$ = this.store$.select(
      CurrenciesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      CurrenciesSelectors.isLoaded,
      CurrenciesActions.ReadCurrencies()
    );
    this.currencies$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(currencies => !!currencies && currencies.length > 0)
      )
      .subscribe(currencies => {
        // console.log(this.sof.get('netPricePerUnit.currency').value, currencies);
        if (this.sof.get('netPricePerUnit.currency').value === null) {
          this.sof.patchValue({
            netPricePerUnit: { currency: currencies[0]?.code || null }
          });
        }
      });
  }

  private loadProducts(): void {
    this.products$ = this.store$.select(ProductsSelectors.sList).pipe(
      map(list =>
        list
          .map(e => ({
            ...e,
            disabled: e.actualStock <= 0
          }))
          .sort(sortProducts)
      )
    );
    this.productsIsLoading$ = this.store$.select(ProductsSelectors.isLoading);
    this.products$.pipe(takeUntil(this.onDestroy$)).subscribe(products => {
      this.products = products;
    });
    this.store$.dispatch(ProductsActions.ReadProducts());
  }

  private loadServices(): void {
    this.services$ = this.store$.select(ServicesSelectors.selectServices);
    this.servicesIsLoading$ = this.store$.select(ServicesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      ServicesSelectors.isLoaded,
      ServicesActions.ReadServices()
    );
  }

  private loadTaxRates(): void {
    this.taxRates$ = this.store$.select(TaxesSelectors.selectTaxes);
    this.taxRatesIsLoading$ = this.store$.select(TaxesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      TaxesSelectors.isLoaded,
      TaxesActions.ReadTaxes()
    );
    this.taxRates$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(taxRates => !!taxRates && taxRates.length > 0)
      )
      .subscribe(taxRates => {
        if (this.item?.taxRate?.value) {
          // console.log(taxRates);
          const taxRate = taxRates.find(
            e => e.value === this.item?.taxRate?.value
          );
          this.sof.patchValue({ taxRate: taxRate['@id'] });
        } else if (this.sof.get('taxRate').value === null) {
          this.sof.patchValue({
            taxRate: extractIri(taxRates.find(e => e.isDefault === true))
          });
        }
      });
  }

  private loadProductUnits(): void {
    this.productUnits$ = this.store$.select(
      ProductUnitsSelectors.selectProductUnits
    );
    this.productUnitsIsLoading$ = this.store$.select(
      ProductUnitsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ProductUnitsSelectors.isLoaded,
      ProductUnitsActions.ReadProductUnits()
    );
  }
}
