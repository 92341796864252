import {createSelector} from '@ngrx/store';

import {DATA_RECOVERY_PROTOCOLS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectDataRecoveryProtocolsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[DATA_RECOVERY_PROTOCOLS_FEATURE_KEY]
);

export const selectDataRecoveryProtocolsEntities = createSelector(
  selectDataRecoveryProtocolsState,
  (state) => state.entities
);

export const selectDataRecoveryProtocols = createSelector(
  selectDataRecoveryProtocolsEntities,
  (entities) => Object.values(entities)
);

export const sByIri = createSelector(
  selectDataRecoveryProtocolsEntities,
  (entities, {iri}) => entities[iri]
);

export const selectCurrentDataRecoveryProtocol = createSelector(
  selectDataRecoveryProtocolsState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectDataRecoveryProtocolsErrors = createSelector(
  selectDataRecoveryProtocolsState,
  (state) => state.errors
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDataRecoveryProtocolsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDataRecoveryProtocolsState,
  (state) => state.loaded
);
