import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import * as fromModuleModels from '../../models';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'grade-form',
  styleUrls: ['grade-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Akademischen Grad bearbeiten' : 'Neuen Akademischen Grad anlegen' }}</span>
      </div>

      <div class="card__content p-a--24" [formGroup]="gf">

        <div class="grid">
          <mat-form-field class="column-14">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" required>
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>

          <mat-form-field class="column-5">
            <mat-label>Abkürzung</mat-label>
            <input type="text" matInput formControlName="abbreviation">
            <mat-hint align="start" *ngIf="errors?.abbreviation">{{ errors.abbreviation.message }}</mat-hint>
          </mat-form-field>

          <div class="mat-form-field column-9">
            <ng-select
              placeholder="Sprache"
              [items]="locales$ | async"
              required
              bindValue="locale"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="locale"
            ></ng-select>
          </div>

          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || gf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="gf.invalid || gf.untouched" (click)="handleSubmit()" mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class GradeFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() locales$: Observable<Array<fromModuleModels.Locale>>;
  @Input() presets$: BehaviorSubject<fromModuleModels.Grade>;

  @Output() requestCreateItem: EventEmitter<{ payload: fromModuleModels.Grade, entity: string }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.Grade,
    entity: string
  }> = new EventEmitter();

  gf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.gf, preset);
      this.gf.markAsUntouched();
    });
  }

  initForm() {
    this.gf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      abbreviation: this.fb.control(null, [Validators.required]),
      locale: this.fb.control('de_DE', [Validators.required])
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromModuleModels.Grade = this.gf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Grade'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Grade'});
  }
}
