import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import {
  ErrorsObject,
  getErrorsObject
} from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  isAuthenticated: boolean;
  loading: boolean;
  loaded: boolean;
  errors?: ErrorsObject;
}

export const state: State = {
  isAuthenticated: false,
  loading: false,
  loaded: false,
  errors: {}
};

export const reducer = createReducer(
  state,
  on(AuthActions.LoginUser, AuthActions.LogoutUser, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(AuthActions.LoginUserSuccess, state => {
    return {
      ...state,
      error: {},
      loading: false,
      loaded: true,
      isAuthenticated: true
    };
  }),
  on(AuthActions.LoginUserFail, (state, { response }) => {
    const { error } = response;

    const _errors = {
      ...error,
      violations: [
        {
          message: error.message,
          propertyPath: 'error'
        }
      ]
    };

    const errors = getErrorsObject(_errors);

    return {
      ...state,
      errors,
      loaded: false,
      loading: false
    };
  }),
  on(
    AuthActions.CreatePasswordResetRequestFail,
    AuthActions.CreatePasswordResetFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(AuthActions.LogoutUserSuccess, state => {
    return {
      ...state,
      errors: {},
      loading: false,
      loaded: false,
      isAuthenticated: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
