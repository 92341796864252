import {NgModule} from '@angular/core';

import * as fromModuleContainers from './containers';
import * as fromModuleComponents from './components';
import * as fromModuleServices from './services';
import * as fromModuleGuards from './guards';
import {StockItemFormDialogComponent} from './components/stock-item-form-dialog/stock-item-form-dialog.component';
import {CustomerStockItemFormComponent} from './components/customer-stock-item-form/customer-stock-item-form.component';
import {
  AddReplacementHardwareDialogComponent
} from './components';
import {MasterDataModule} from '../master-data/master-data.module';
import {FormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {
  CustomerDataMediumFormComponent
} from './components/customer-data-medium-form/customer-data-medium-form.component';
import {
  WarehouseCustomerStockItemsViewComponent
} from './containers/warehouse-customer-stock-items-view/warehouse-customer-stock-items-view.component';
import {
  WarehouseCustomerReplacementDataMediaViewComponent
} from './containers/warehouse-customer-replacement-data-media-view/warehouse-customer-replacement-data-media-view.component';
import {
  WarehouseReplacementDataMediaViewComponent
} from './containers/warehouse-replacement-data-media-view/warehouse-replacement-data-media-view.component';
import {WarehouseArchiveViewComponent} from './containers/warehouse-archive-view/warehouse-archive-view.component';
import {DataMediaStateSelectComponent} from './components/data-media-state-select/data-media-state-select.component';
import {OrderStockItemWidgetComponent} from './components/order-stock-item-widget/order-stock-item-widget.component';
import {
  EditCustomerHardwareDialogComponent
} from './components/edit-customer-hardware-dialog/edit-customer-hardware-dialog.component';
import {SharedModule} from '../shared/shared.module';
import {
  ShowCustomerHardwareDialogComponent
} from './components/show-customer-hardware-dialog/show-customer-hardware-dialog.component'
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    MasterDataModule,
    FormsModule,
    MatButtonToggleModule,
    SharedModule,
    TranslateModule
  ],
  exports: [
    ...fromModuleContainers.AllContainers,
    ...fromModuleComponents.AllComponents,
    OrderStockItemWidgetComponent,
  ],
  declarations: [
    ...fromModuleComponents.AllComponents,
    ...fromModuleContainers.AllContainers,
    StockItemFormDialogComponent,
    CustomerStockItemFormComponent,
    AddReplacementHardwareDialogComponent,
    CustomerDataMediumFormComponent,
    WarehouseCustomerStockItemsViewComponent,
    WarehouseCustomerReplacementDataMediaViewComponent,
    WarehouseReplacementDataMediaViewComponent,
    WarehouseArchiveViewComponent,
    DataMediaStateSelectComponent,
    OrderStockItemWidgetComponent,
    EditCustomerHardwareDialogComponent,
    ShowCustomerHardwareDialogComponent,
  ],
  providers: [
    ...fromModuleServices.AllServices,
    ...fromModuleGuards.AllGuards,
  ],
})
export class WarehouseModule {
}
