import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleServices from '../../services';
import {PartnerWebsitesActions} from '../actions/';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class PartnerWebsitesEffects {

  constructor(private actions$: Actions, private service: fromModuleServices.PartnerWebsitesService) {
  }


  CreatePartnerWebsite$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.CreatePartnerWebsite),
    map(action => action),
    switchMap(({payload}) => {
      return this.service.createPartnerWebsite(payload).pipe(
        map(response => PartnerWebsitesActions.CreatePartnerWebsiteSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.CreatePartnerWebsiteFail({response})))
      );
    })
  ));


  ReadPartnerWebsite$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.ReadPartnerWebsite),
    map(action => action),
    switchMap(({iri}) => {
      return this.service.readPartnerWebsite(iri).pipe(
        map(response => PartnerWebsitesActions.ReadPartnerWebsiteSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.ReadPartnerWebsiteFail({response})))
      );
    })
  ));


  ReadPartnerWebsiteProfile$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.ReadPartnerWebsiteProfile),
    map(action => action),
    switchMap(({iri}) => {
      return this.service.readPartnerWebsiteProfile(iri).pipe(
        map(response => PartnerWebsitesActions.ReadPartnerWebsiteProfileSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.ReadPartnerWebsiteProfileFail({response})))
      );
    })
  ));


  ReadPartnerWebsites$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.ReadPartnerWebsites),
    map(action => action),
    switchMap(() => {
      return this.service.readPartnerWebsites().pipe(
        map(response => PartnerWebsitesActions.ReadPartnerWebsitesSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.ReadPartnerWebsitesFail({response})))
      );
    })
  ));


  UpdatePartnerWebsite$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.UpdatePartnerWebsite),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updatePartnerWebsite(iri, payload).pipe(
        map(response => PartnerWebsitesActions.UpdatePartnerWebsiteSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.UpdatePartnerWebsiteFail({response})))
      );
    })
  ));


  UpdatePartnerWebsiteProfile$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.UpdatePartnerWebsiteProfile),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updatePartnerWebsiteProfile(iri, payload).pipe(
        map(response => PartnerWebsitesActions.UpdatePartnerWebsiteProfileSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.UpdatePartnerWebsiteProfileFail({response})))
      );
    })
  ));


  UpdatePartnerWebsitePassword$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.UpdatePartnerWebsitePassword),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.service.updatePartnerWebsitePassword(iri, payload).pipe(
        map(response => PartnerWebsitesActions.UpdatePartnerWebsitePasswordSuccess({response})),
        catchError(response => of(PartnerWebsitesActions.UpdatePartnerWebsitePasswordFail({response})))
      );
    })
  ));


  DeletePartnerWebsite$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerWebsitesActions.DeletePartnerWebsite),
    switchMap(({iri}) => {
      return this.service.deletePartnerWebsite(iri).pipe(
        map(() => PartnerWebsitesActions.DeletePartnerWebsiteSuccess({iri})),
        catchError(response => of(PartnerWebsitesActions.DeletePartnerWebsiteFail({response})))
      );
    })
  ));


  ActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      PartnerWebsitesActions.CreatePartnerWebsiteSuccess,
      PartnerWebsitesActions.UpdatePartnerWebsiteSuccess
    ),
    map(({type}) => {

      const text = type === PartnerWebsitesActions.CREATE_PARTNER_WEBSITE_SUCCESS
        ? `Eine neuer Kundenzugang (Website) wurde angelegt.`
        : 'Der Kundenzugang (Website) wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
