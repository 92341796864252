import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as fromModuleModels from '../../models';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'partner-website-invitation-list',
  styleUrls: ['partner-website-invitation-list.component.scss'],
  template: `

    <div class="card m-b--16 m-l--48 mat-elevation-z1">

      <div class="card__heading">
        <ng-content></ng-content>
      </div>
      <div class="card__content">

        <table mat-table *ngIf="partnerWebsiteInvitations?.length; else placeholder"
               [dataSource]="partnerWebsiteInvitations" class="mat-elevation-z1">

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>E-Mail</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.email }}</span>
            </td>
          </ng-container>

          <!-- Website Column -->
          <ng-container matColumnDef="website">
            <th mat-header-cell *matHeaderCellDef>Website</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.website }}</span>
            </td>
          </ng-container>

          <!-- Invited At Column -->
          <ng-container matColumnDef="invitedAt">
            <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
            <td mat-cell
                *matCellDef="let element">{{ element.invitedAt ? (element.invitedAt | date: 'dd.MM.Y') : '-' }}</td>
          </ng-container>

          <!-- Expires At Column -->
          <ng-container matColumnDef="expiresAt">
            <th mat-header-cell *matHeaderCellDef>Einladung gültig bis</th>
            <td mat-cell
                *matCellDef="let element">{{ element.expiresAt ? (element.expiresAt | date: 'dd.MM.Y - HH:mm') + ' Uhr' : '-' }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button (click)="handleDeletePartnerWebsiteInvitation(element)" mat-icon-button>
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>

    <ng-template #placeholder>
      <p class="placeholder">Für diesen Kunden gibt es aktuell keine offenen Einladungen.</p>
    </ng-template>
  `
})
export class PartnerWebsiteInvitationListComponent {

  @Input() partnerWebsiteInvitations: Array<fromModuleModels.PartnerWebsiteInvitation>;

  @Output() requestDeletePartnerWebsiteInvitation: EventEmitter<string> = new EventEmitter();

  displayedColumns: Array<string> = ['email', 'website', 'invitedAt', 'expiresAt', 'actions'];

  constructor() {
  }

  handleDeletePartnerWebsiteInvitation(account: fromModuleModels.CustomerAccount): void {

    this.requestDeletePartnerWebsiteInvitation.emit(account['@id']);
  }
}
