import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_CUSTOMER_COOPERATION = '[Master Data] Create Customer Cooperation';
export const CREATE_CUSTOMER_COOPERATION_SUCCESS = '[Master Data] Create Customer Cooperation Success';
export const CREATE_CUSTOMER_COOPERATION_FAIL = '[Master Data] Create Customer Cooperation Fail';
export const CreateCustomerCooperation = createAction(
  CREATE_CUSTOMER_COOPERATION,
  props<{ payload: fromModuleModels.Product }>()
);
export const CreateCustomerCooperationSuccess = createAction(
  CREATE_CUSTOMER_COOPERATION_SUCCESS,
  props<{ response: fromModuleModels.Product }>()
);
export const CreateCustomerCooperationFail = createAction(
  CREATE_CUSTOMER_COOPERATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_CUSTOMER_COOPERATIONS = '[Master Data] Read Customer Cooperations';
export const READ_CUSTOMER_COOPERATIONS_SUCCESS = '[Master Data] Read Customer Cooperations Success';
export const READ_CUSTOMER_COOPERATIONS_FAIL = '[Master Data] Read Customer Cooperations Fail';
export const ReadCustomerCooperations = createAction(
  READ_CUSTOMER_COOPERATIONS
);
export const ReadCustomerCooperationsSuccess = createAction(
  READ_CUSTOMER_COOPERATIONS_SUCCESS,
  props<{ response: any }>()
);
export const ReadCustomerCooperationsFail = createAction(
  READ_CUSTOMER_COOPERATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1n
export const READ_CUSTOMER_COOPERATION = '[Master Data] Read Customer Cooperation';
export const READ_CUSTOMER_COOPERATION_SUCCESS = '[Master Data] Read Customer Cooperation Success';
export const READ_CUSTOMER_COOPERATION_FAIL = '[Master Data] Read Customer Cooperation Fail';
export const ReadCustomerCooperation = createAction(
  READ_CUSTOMER_COOPERATION,
  props<{ iri: string }>()
);
export const ReadCustomerCooperationSuccess = createAction(
  READ_CUSTOMER_COOPERATION_SUCCESS,
  props<{ response }>()
);
export const ReadCustomerCooperationFail = createAction(
  READ_CUSTOMER_COOPERATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_CUSTOMER_COOPERATION = '[Master Data] Update Customer Cooperation';
export const UPDATE_CUSTOMER_COOPERATION_SUCCESS = '[Master Data] Update Customer Cooperation Success';
export const UPDATE_CUSTOMER_COOPERATION_FAIL = '[Master Data] Update Customer Cooperation Fail';
export const UpdateCustomerCooperation = createAction(
  UPDATE_CUSTOMER_COOPERATION,
  props<{ iri: string, payload: fromModuleModels.Product }>()
);
export const UpdateCustomerCooperationSuccess = createAction(
  UPDATE_CUSTOMER_COOPERATION_SUCCESS,
  props<{ response: fromModuleModels.Product }>()
);
export const UpdateCustomerCooperationFail = createAction(
  UPDATE_CUSTOMER_COOPERATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_CUSTOMER_COOPERATION = '[Master Data] Delete Customer Cooperation';
export const DELETE_CUSTOMER_COOPERATION_SUCCESS = '[Master Data] Delete Customer Cooperation Success';
export const DELETE_CUSTOMER_COOPERATION_FAIL = '[Master Data] Delete Customer Cooperation Fail';
export const DeleteCustomerCooperation = createAction(
  DELETE_CUSTOMER_COOPERATION,
  props<{ iri: string }>()
);
export const DeleteCustomerCooperationSuccess = createAction(
  DELETE_CUSTOMER_COOPERATION_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteCustomerCooperationFail = createAction(
  DELETE_CUSTOMER_COOPERATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
