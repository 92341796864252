<div class="card mat-elevation-z1">

  <div class="card__heading">
    <span>{{ presets$.getValue() ? 'Analysevorlagen bearbeiten' : 'Neue Analysevorlage anlegen' }}</span>
  </div>

  <div class="card__content p-a--24" [formGroup]="arf" [class.disabled]="!analysisResultCategories.length">

    <div class="grid">
      <div class="column-14">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input type="text" matInput formControlName="name" required>
          <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
        </mat-form-field>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Vorlagen-Kategorie"
          [items]="analysisResultCategories"
          bindValue="@id"
          [required]="true"
          appendTo="main"
          bindLabel="name"
          [clearable]="false"
          [searchable]="false"
          formControlName="category">
        </ng-select>
        <mat-hint align="start" *ngIf="errors?.category">{{ errors.category.message }}</mat-hint>
      </div>

      <div class="column-14 m-b--32 mat-form-field">
        <app-text-editor [formControl]="arf.controls['content']" label="Analyse Ergebnis"
                         placeholder="Inhalt eingeben"></app-text-editor>
      </div>

      <div class="m-ta--2 column-14">
        <button *ngIf="presets$.getValue() || arf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                color="outline" mat-flat-button>
          <mat-icon class="m-r--8">cancel</mat-icon>
          <span>Abbrechen</span>
        </button>

        <button [disabled]="arf.invalid || arf.untouched" (click)="handleSubmit()" mat-flat-button color="green">
          <mat-icon class="m-r--8">save</mat-icon>
          <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
        </button>
      </div>
    </div>

    <div class="text">Bitte mind. eine Vorlagenkategorie erstellen.</div>
    <div class="blocker"></div>
  </div>
</div>

<!--<pre>{{ errors | json }}</pre>-->
<!--<pre>{{ presets$.getValue() | json }}</pre>-->
