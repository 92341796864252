import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromTransitionsReducers from './transitions.reducers';

export const APPLICATION_STATE_MODULE_FEATURE_KEY = 'applicationState';

export const TRANSITIONS_FEATURE_KEY = 'transitions';

export interface ApplicationStateModuleState {
  [TRANSITIONS_FEATURE_KEY]: fromTransitionsReducers.State;
}

export const selectApplicationStateModuleState = createFeatureSelector<ApplicationStateModuleState>(APPLICATION_STATE_MODULE_FEATURE_KEY);

export function reducers(state: ApplicationStateModuleState, action: Action) {
  return combineReducers({
    [TRANSITIONS_FEATURE_KEY]: fromTransitionsReducers.reducer
  })(state, action);
}
