import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {sortBy} from 'lodash-es';

import {ApplicationState} from '../../../application-state/store';
import {CorrectionInvoicesActions, PayableInvoicesActions} from '../../store';
import {CorrectionInvoicesSelectors} from '../../store/selectors';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {CorrectionInvoice, Offer} from '../../models';
import {extractIri} from '../../../shared/utilities/objects.utility';
import * as moment from 'moment';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "../../../shared/services";
import {CorrectionInvoicesService, OffersService} from "../../services";
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";

@Component({
  selector: 'app-correction-invoices',
  styleUrls: ['correction-invoices-view.component.scss'],
  template: `
    <div class="col pt-3">
      <div class="row ">
        <div class="col">
          <app-correction-invoices-filter
            *ngIf="searchBoxVisible"
            [items]="correctionInvoices$ | async"
            (updateFilter)="handleFilterFormAction($event)"
          ></app-correction-invoices-filter>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-paginator-group pos-relative">
            <app-loading-overlay *ngIf="loadingTable$|async"></app-loading-overlay>

            <app-correction-invoices-list
              *ngIf="correctionInvoices$ | async"
              [items]="correctionInvoices$ | async">
            </app-correction-invoices-list>

            <app-paginator-unstyled
              [totalItems]="correctionInvoicesCount"
              [pageSize]="pageSize"
              [pageIndex]="currentPage - 1"
              [pageSizeOptions]="pageSizeOptions"
              (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
              [showFirstLastButtons]="true"
            ></app-paginator-unstyled>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CorrectionInvoicesViewComponent implements OnInit, OnDestroy {
  correctionInvoices$: BehaviorSubject<Array<CorrectionInvoice>> = new BehaviorSubject<Array<CorrectionInvoice>>([]);
  currentPage = 1;
  pageSize = 30;
  pageSizeOptions = [5, 10, 15, 20, 25];
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [p: string]: any }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc',
  });
  activeFilter: { [p: string]: string } = {};
  searchBoxVisible = false;
  onDestroy$: Subject<any> = new Subject<any>();
  filterString = '';
  correctionInvoicesCount: number;
  subscriptionList: any;

  constructor(
    private store$: Store<ApplicationState>,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private searchAddonDataService: SearchAddonDataService,
    private correctionInvoicesService: CorrectionInvoicesService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.loadCorrectionInvoices();
    const filters = this.localStorageService.getObjectByKey('filters', 'offers');
    if(filters) {
      this.handleFilterFormAction(filters);
    }

    this.searchAddonDataService
      .getObservable()
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(data => {
        this.handleSearchInput(data.filterString);
        this.cd.detectChanges();
      });

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.updateParams();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.updateParams();
    this.loadCorrectionInvoices();
    this.updateUrl();
  }
  handleFilterFormAction(filters: any): void {
    const params = {
      department: null,
      orderNumber: null,
      state: null,
      'grossTotal.value[gte]': null,
      'grossTotal.value[lte]': null,
      'createdAt[strictly_after]': null,
      'createdAt[strictly_before]': null,
    };

    if (filters?.department) {
      params['issuer.uuid'] = getUuidFromIri(extractIri(filters.department));
    } else {
      params['issuer.uuid'] = null;
    }
    if (filters?.orderNumber) {
      params.orderNumber =  String(filters.orderNumber);
    }


    if (filters?.state) {
      params['state'] = filters.state;
    }

    if (filters?.exactGrossPrice) {
      params['grossTotal.value[lte]'] = filters.exactGrossPrice;
      params['grossTotal.value[gte]'] = filters.exactGrossPrice;
    }

    if (filters?.createdAtMin) {
      params['createdAt[strictly_after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[strictly_before]'] = filters.createdAtMax;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });
    this.updateParams();
    this.loadCorrectionInvoices();
  }
  loadCorrectionInvoices(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    this.loadingTable$.next(true);
    this.subscriptionList = this.correctionInvoicesService.readCorrectionInvoices(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.loadingTable$.next(false);
        this.correctionInvoicesCount = response['hydra:totalItems'];
        this.correctionInvoices$.next(this.sortInvoices(response['hydra:member']));
      }, (fail) => {
        this.loadingTable$.next(false);
        console.error(fail);
      });
  }

  sortInvoices(toBeSortedInvoices: CorrectionInvoice[]): CorrectionInvoice[] {
    // return sortBy(toBeSortedInvoices, e => e.correctionInvoiceNumber);
    return sortBy(toBeSortedInvoices, (e) => moment(e.bookedAt ? e.bookedAt : null).toDate().getTime() * -1);
  }

  // handleUpdateFilter(filters: { [p: string]: string }): void {
  //   this.searchAddonDataService.updateData({ activeFilter: filters });
  // }

  handleSearchInput(term: string): void {
    this.params$.next({
      ...this.params$.getValue(),
      fulltext_search: term ? term : ''
    });

    this.store$.dispatch(
      CorrectionInvoicesActions.ReadCorrectionInvoices({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
    this.loadCorrectionInvoices();
  }

  private updateParams(): void {
    if (this.searchBoxVisible === false) {
      this.params$.next({
        ...this.params$.getValue(),
        'order[createdAt]': 'desc',
        fulltext_search: this.params$.getValue().hasOwnProperty('fulltext_search') ? this.params$.getValue().fulltext_search : ''
      });
    }
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });
  }
}
