import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromCustomersModuleModels from '../../models';
import {InvitationConfirmation} from '../../../auth/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PARTNER_WEBSITE_INVITATION = '[Customers Module] Create Partner Website Invitation';
export const CREATE_PARTNER_WEBSITE_INVITATION_SUCCESS = '[Customers Module] Create Partner Website Invitation Success';
export const CREATE_PARTNER_WEBSITE_INVITATION_FAIL = '[Customers Module] Create Partner Website Invitation Fail';

export const CreatePartnerWebsiteInvitation = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION,
  props<{ payload: fromCustomersModuleModels.PartnerWebsiteInvitation }>()
);

export const CreatePartnerWebsiteInvitationSuccess = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION_SUCCESS,
  props<{ response: fromCustomersModuleModels.PartnerWebsiteInvitation }>()
);

export const CreatePartnerWebsiteInvitationFail = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION = '[Customers Module] Create Partner Website Invitation Confirmation';
export const CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION_SUCCESS = '[Customers Module] Create Partner Website Invitation Confirmation Success';
export const CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION_FAIL = '[Customers Module] Create Partner Website Invitation Confirmation Fail';

export const CreatePartnerWebsiteInvitationConfirmation = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION,
  props<{ payload: InvitationConfirmation }>()
);

export const CreatePartnerWebsiteInvitationConfirmationSuccess = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION_SUCCESS,
  props<{ response: fromCustomersModuleModels.PartnerWebsite }>()
);

export const CreatePartnerWebsiteInvitationConfirmationFail = createAction(
  CREATE_PARTNER_WEBSITE_INVITATION_CONFIRMATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PARTNER_WEBSITE_INVITATION = '[Customers Module] Read Partner Website Invitation';
export const READ_PARTNER_WEBSITE_INVITATION_SUCCESS = '[Customers Module] Read Partner Website Invitation  Success';
export const READ_PARTNER_WEBSITE_INVITATION_FAIL = '[Customers Module] Read Partner Website Invitation  Fail';

export const ReadPartnerWebsiteInvitation = createAction(
  READ_PARTNER_WEBSITE_INVITATION,
  props<{ iri: string }>()
);

export const ReadPartnerWebsiteInvitationSuccess = createAction(
  READ_PARTNER_WEBSITE_INVITATION_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsiteInvitationFail = createAction(
  READ_PARTNER_WEBSITE_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_PARTNER_WEBSITE_INVITATIONS = '[Customers Module] Read Partner Website Invitations';
export const READ_PARTNER_WEBSITE_INVITATIONS_SUCCESS = '[Customers Module] Read Partner Website Invitations Success';
export const READ_PARTNER_WEBSITE_INVITATIONS_FAIL = '[Customers Module] Read Partner Website Invitations Fail';

export const ReadPartnerWebsiteInvitations = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS,
  props<{ page: number }>()
);

export const ReadPartnerWebsiteInvitationsSuccess = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsiteInvitationsFail = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_PARTNER_WEBSITE_INVITATIONS_CHUNK = '[Customers Module] Read Partner Website Invitations Chunk';
export const READ_PARTNER_WEBSITE_INVITATIONS_CHUNK_SUCCESS = '[Customers Module] Read Partner Website Invitations Chunk Success';
export const READ_PARTNER_WEBSITE_INVITATIONS_CHUNK_FAIL = '[Customers Module] Read Partner Website Invitations Chunk Fail';

export const ReadPartnerWebsiteInvitationsChunk = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS_CHUNK,
  props<{ iri: string }>()
);

export const ReadPartnerWebsiteInvitationsChunkSuccess = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsiteInvitationsChunkFail = createAction(
  READ_PARTNER_WEBSITE_INVITATIONS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PARTNER_WEBSITE_INVITATION = '[Customers Module] Update Partner Website Invitation';
export const UPDATE_PARTNER_WEBSITE_INVITATION_SUCCESS = '[Customers Module] Update Partner Website Invitation Success';
export const UPDATE_PARTNER_WEBSITE_INVITATION_FAIL = '[Customers Module] Update Partner Website Invitation Fail';

export const UpdatePartnerWebsiteInvitation = createAction(
  UPDATE_PARTNER_WEBSITE_INVITATION,
  props<{ iri: string, payload: fromCustomersModuleModels.PartnerWebsiteInvitation }>()
);

export const UpdatePartnerWebsiteInvitationSuccess = createAction(
  UPDATE_PARTNER_WEBSITE_INVITATION_SUCCESS,
  props<{ response: fromCustomersModuleModels.PartnerWebsiteInvitation }>()
);

export const UpdatePartnerWebsiteInvitationFail = createAction(
  UPDATE_PARTNER_WEBSITE_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_PARTNER_WEBSITE_INVITATION = '[Customers Module] Delete Partner Website Invitation';
export const DELETE_PARTNER_WEBSITE_INVITATION_SUCCESS = '[Customers Module] Delete Partner Website Invitation  Success';
export const DELETE_PARTNER_WEBSITE_INVITATION_FAIL = '[Customers Module] Delete Partner Website Invitation  Fail';

export const DeletePartnerWebsiteInvitation = createAction(
  DELETE_PARTNER_WEBSITE_INVITATION,
  props<{ iri: string }>()
);

export const DeletePartnerWebsiteInvitationSuccess = createAction(
  DELETE_PARTNER_WEBSITE_INVITATION_SUCCESS,
  props<{ iri: string }>()
);

export const DeletePartnerWebsiteInvitationFail = createAction(
  DELETE_PARTNER_WEBSITE_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
