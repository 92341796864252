import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'contains'
})
export class ContainsPipe implements PipeTransform {

  transform(haystack: string[], needle: string): boolean {
    return haystack.indexOf(needle) > -1;
  }

}
