import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { FileInputComponent } from '../../../shared/components/file-input/file-input.component';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { ModalDialogOptions } from '../../../application-state/models';
import {
  DocumentDeliveryProvidersSelectors,
  OffersSelectors
} from '../../store/selectors';
import { AcceptedOffersService, OffersService } from '../../services';
import {
  PaymentRecorderComponent,
  SendDocumentToolComponent
} from '../../components';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { ActivatedRoute } from '@angular/router';
import { OffersActions, PaymentProcessesActions } from '../../store';
import { CustomersSelectors } from '../../../customers/store/selectors';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CurrenciesSelectors,
  DepartmentsSelectors,
  ProductsSelectors,
  ProductUnitsSelectors,
  ServicesSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import { InvoiceLike, Offer } from '../../models';
import { Address, Customer, CustomerAddress } from '../../../customers/models';
import { Product } from '../../../master-data/models';
import { Order } from '../../../orders/models';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import * as moment from 'moment/moment';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { OrdersActions } from '../../../orders/store';
import {
  extractIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { CustomersActions } from '../../../customers/store';
import { RouterActions } from '../../../application-state/store/actions';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-offer-view',
  styleUrls: ['offer-new.component.scss'],
  template: `
    <div class="pos-relative">
      <div class="row">
        <div class="col-auto me-auto">
          <h1>Faktura: Angebot erstellen</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <app-offer-preview
            [offer$]="offer$"
            (updateInvoice)="updateInvoice($event)"
            (updateCustomer)="updateCustomer($event)"
          ></app-offer-preview>
        </div>
        <div class="col-3" [formGroup]="form">
          <div
            class="heading--h3 mb-2 p-3 d-block rounded-2"
            data-box-style="blue"
          >
            Dieses Angebot muss noch angelegt werden.
          </div>
          <div class="row">
            <div class="col">
              <app-order-select
                formControlName="order"
                placeholder="Verknüpfter Auftrag (optional)"
                (updateSelectedObject)="onSelectOrder($event)"
              ></app-order-select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field>
                <mat-label>Angebot gültig bis</mat-label>
                <input type="date" matInput formControlName="validUntil" />
                <mat-error>
                  <app-form-error
                    fieldName="validUntil"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-right pb-2">
              <button
                mat-button
                color="green"
                [disabled]="form.invalid"
                (click)="submitOffer()"
              >
                <mat-icon>save</mat-icon>
                Angebot anlegen
              </button>
            </div>
          </div>
          <div class="">
            <!--            <offer-details-->
            <!--              [offer]="offer"-->
            <!--              (requestCreateOffer)="handleCreateOffer()"-->
            <!--              (requestUpdateOffer)="handleChangedOffer($event)"-->
            <!--              (requestDownloadAcceptedOffer)="handleDownloadAcceptedOffer($event)"-->
            <!--              (requestUploadAcceptedOffer)="fic.fileInput.nativeElement.click()"-->
            <!--              [loading]="uploading"-->
            <!--              [formGroup]="form"-->
            <!--            ></offer-details>-->

            <!--            <app-send-document-tool [invoice]="offer" invoiceTypeName="Angebot"></app-send-document-tool>-->
          </div>
        </div>
      </div>
    </div>
    <pre>{{ offer$ | async | json }}</pre>
  `
})
export class OfferNewComponent extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  @ViewChild(FileInputComponent) fic!: FileInputComponent;
  @ViewChild(PaymentRecorderComponent) prc: PaymentRecorderComponent;
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;

  isLoading$: Observable<boolean>;
  offer$: BehaviorSubject<Partial<Offer>> = new BehaviorSubject<Partial<Offer>>(
    {}
  );
  offer: Offer;

  cf: FormGroup;
  customer$: BehaviorSubject<Customer> = new BehaviorSubject(null);
  customerAddresses$: Observable<Array<CustomerAddress>>;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  order$: BehaviorSubject<Order> = new BehaviorSubject(null);
  orders$: Observable<Array<Order>>;
  uploading = false;
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  products$: Observable<Array<Product>>;

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private dls: DepartmentLogosService,
    private os: OffersService,
    public aos: AcceptedOffersService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store$: Store<ApplicationState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForms();
    this.isLoading$ = isLoadingArray([
      this.store$.select(OffersSelectors.isLoading),
      this.store$.select(CustomersSelectors.isLoading),
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(CurrenciesSelectors.isLoading),
      this.store$.select(DepartmentsSelectors.isLoading),
      this.store$.select(DocumentDeliveryProvidersSelectors.isLoading),
      this.store$.select(TaxesSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(ServicesSelectors.isLoading)
    ]);

    // this.initActionListeners();
  }

  // initActionListeners(): void {
  //
  //   this.actions$.pipe(
  //     takeUntil(this.onDestroy$),
  //     ofType(
  //       PaymentProcessesActions.CreatePaymentProcessSuccess,
  //       OffersActions.CreateOfferSuccess,
  //       OffersActions.UpdateOfferSuccess,
  //       OffersActions.UpdateOfferFail,
  //       OfferItemsActions.CreateOfferItemSuccess,
  //       OfferItemsActions.UpdateOfferItemSuccess,
  //       OfferItemsActions.DeleteOfferItemSuccess,
  //       DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
  //       DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
  //       DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
  //     )
  //     // @ts-ignore
  //   ).subscribe(({type, response = null, invoiceIri = null}) => {
  //
  //     const documentActions = [
  //       DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
  //       DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
  //       DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS,
  //     ];
  //
  //     const resetPresets = [
  //       OfferItemsActions.CREATE_OFFER_ITEM_SUCCESS,
  //       OfferItemsActions.UPDATE_OFFER_ITEM_SUCCESS,
  //       OfferItemsActions.DELETE_OFFER_ITEM_SUCCESS,
  //     ];
  //
  //     const triggerRefresh = [
  //       ...documentActions,
  //       ...resetPresets
  //     ];
  //
  //     if (type === OffersActions.UPDATE_OFFER_SUCCESS ||
  //       type === OffersActions.CREATE_OFFER_SUCCESS) {
  //       this.form.markAsPristine();
  //     }
  //
  //     if (type === OffersActions.UPDATE_OFFER_SUCCESS || type === OffersActions.UPDATE_OFFER_FAIL) {
  //       this.uploading = false;
  //     }
  //
  //     if (type === OffersActions.UPDATE_OFFER_SUCCESS) {
  //       this.fic.handleClearFile(false);
  //     }
  //
  //     // Create offer after payment process has been created
  //     if (type === PaymentProcessesActions.CREATE_PAYMENT_PROCESS_SUCCESS) {
  //       const paymentProcessIri = response['@id'];
  //       this.form.get('paymentProcess').setValue(paymentProcessIri);
  //       this.store$.dispatch(OffersActions.CreateOffer({payload: this.form.value}));
  //     }
  //
  //     if (documentActions.includes(type)) {
  //       this.sitc.initForm();
  //       this.sitc.resetTypeSelect();
  //       this.sitc.documentDeliveryTarget$.next(null);
  //     }
  //
  //     if (triggerRefresh.includes(type) && invoiceIri) {
  //       this.store$.dispatch(OffersActions.ReadOffer({iri: invoiceIri}));
  //     }
  //
  //     if (resetPresets.includes(type)) {
  //       this.presets$.next(null);
  //     }
  //   });
  // }

  initForms(): void {
    this.form = this.fb.group({
      validUntil: this.fb.control(
        moment()
          .add(14, 'day')
          .toDate()
          .toISOString()
          .substring(0, 10),
        Validators.required
      ),
      order: this.fb.control(null),
      paymentProcess: this.fb.control(null),
      confirmedAt: this.fb.control(null),
      issuer: this.fb.control(null),
      acceptedOffer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null),
        taxNumber: this.fb.control(null)
      }),
      customerNumber: this.fb.control(null),
      customText: this.fb.control(null)
    });
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe((data: any) => {
        console.log(data);
        if (
          data.order !== null &&
          extractIri(this.order$.getValue()) !== data.order
        ) {
          this.loadOrderData(data.order);
        }
        this.handleChangedOffer(data);
      });
    this.form
      .get('order')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(order => {
        if (order === null) {
          this.form.get('paymentProcess').setValue(null);
        }
      });
  }

  onSelectOrder($event: Order): void {}

  getFormValues(): any {
    const formValues = this.form.value;
    const payload = {
      ...formValues,
      issuer: extractIri(formValues.issuer),
      recipient: {
        ...formValues.recipient,
        addressType: extractIri(formValues.recipient.addressType)
      }
    };
    console.log(payload);

    return payload;
  }

  submitOffer(): void {
    let payload = this.getFormValues();
    if (!payload.paymentProcess) {
      this.actions$
        .pipe(
          ofType(PaymentProcessesActions.CreatePaymentProcessSuccess),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(PaymentProcessesActions.CreatePaymentProcessFail)
            )
          )
        )
        .subscribe(({ response }) => {
          console.log(
            'PaymentProcessesActions.CreatePaymentProcessSuccess',
            response
          );
          this.form.get('paymentProcess').setValue(extractIri(response));
          payload = this.getFormValues();
          this.store$.dispatch(OffersActions.CreateOffer({ payload }));
        });
      this.store$
        .select(CustomersSelectors.sByCustomerNumber, {
          customerNumber: payload.customerNumber
        })
        .subscribe(customer => {
          this.store$.dispatch(
            PaymentProcessesActions.CreatePaymentProcess({
              payload: { customer: extractIri(customer) }
            })
          );
        });
    } else {
      this.store$.dispatch(OffersActions.CreateOffer({ payload }));
    }
    this.actions$
      .pipe(
        ofType(OffersActions.CreateOfferSuccess),
        takeUntil(this.onDestroy$),
        takeUntil(this.actions$.pipe(ofType(OffersActions.CreateOfferFail)))
      )
      .subscribe(({ response }) => {
        const newUUID = extractUUID(response);
        this.store$.dispatch(
          RouterActions.Go({ path: ['/invoices', 'offers', newUUID] })
        );
      });
  }

  loadOrderData(orderIri): void {
    this.store$
      .select(OrdersSelectors.sDetailedByIri, { iri: orderIri })
      .pipe(
        takeUntil(this.onDestroy$),
        tap(e => {
          if (!e) {
            this.store$.dispatch(OrdersActions.ReadOrder({ iri: orderIri }));
          }
        }),
        filter(e => !!e)
      )
      .subscribe(order => {
        console.log('order', order);
        if (extractIri(order.customer, true)) {
          const iri = extractIri(order.customer, true);
          this.store$
            .select(CustomersSelectors.selectCustomerByIndex, { iri })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e)
            )
            .subscribe(customer => {
              this.form.get('customerNumber').setValue(customer.customerNumber);
            });
          this.store$.dispatch(CustomersActions.ReadCustomer({ iri }));
        }

        this.order$.next(order);
        if (extractIri(order.paymentProcess, true)) {
          console.log(extractIri(order.paymentProcess, true));
          this.form.patchValue(
            { paymentProcess: extractIri(order.paymentProcess, true) },
            { onlySelf: true }
          );
        }
      });
  }

  handleCreateOffer(): void {
    const order = this.order$.getValue();
    if (order) {
      this.form.get('paymentProcess').setValue(order.paymentProcess);
      this.store$.dispatch(
        OffersActions.CreateOffer({ payload: this.form.value })
      );
    } else {
      // Create New Payment Process First
      this.store$.dispatch(
        PaymentProcessesActions.CreatePaymentProcess({
          payload: { customer: this.customer$.getValue()['@id'] }
        })
      );
    }
  }

  handleChangedOffer(_payload?: Offer | any): void {
    console.log('handleUpdateOffer', _payload, this.offer);
    this.offer = { ...this.offer, ..._payload };
    this.offer$.next(this.offer);
  }

  handleUpdateCustomer(_payload?: Customer): void {
    console.log('handleUpdateOffer', _payload, this.offer);
    this.offer = { ...this.offer, ..._payload };
    this.offer$.next(this.offer);
  }

  handleUpdateOfferAfterAcceptedOfferUpload(
    offer: string,
    _payload?: { acceptedOffer: string }
  ): void {
    this.uploading = true;

    const payload = {
      iri: offer,
      payload: {
        ..._payload,
        confirmedAt: this.form.get('confirmedAt').value
      }
    };

    this.store$.dispatch(OffersActions.UpdateOffer(payload));
  }

  handleDeleteOffer(offer: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie dieses Angebot wirklich löschen?',
          heading: 'Angebot löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(OffersActions.DeleteOffer({ iri: offer }));
      });
  }

  handleDownloadOffer(offer: string): void {
    this.os
      .readOfferAsPdf(offer)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(response => !!response),
        catchError(error => throwError(error))
      )
      .subscribe(
        ({ body: blob }) => {
          window.open(window.URL.createObjectURL(blob));
        },
        error => {
          console.log(error);
        }
      );
  }

  handleDownloadAcceptedOffer(acceptedOffer: string): void {
    this.aos
      .readAcceptedOfferAsPdf(acceptedOffer)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(response => !!response),
        catchError(error => throwError(error))
      )
      .subscribe(
        ({ body: blob }) => {
          window.open(window.URL.createObjectURL(blob));
        },
        error => {
          console.log(error);
        }
      );
  }

  updateInvoice(invoice: InvoiceLike): void {
    console.log('UPDATE_INVOICE', invoice);
    this.form.patchValue(invoice);
  }

  updateCustomer(customer: Customer): void {
    // console.log('UPDATE_CUSTOMER', customer);
    this.form.get('customerNumber').setValue(customer.customerNumber);
    this.form.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.form.get('recipient.nameLine2').setValue(customer.nameLine2);
    this.form.get('recipient.taxNumber').setValue(customer.taxNumber);
  }

  formatAddress(address: Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleReadCustomerContacts(customerIri: string): void {
    // this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({ page: 1, params }));
    // this.customerContacts$ = this.store$.pipe(select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, { customerIri }));
  }
}
