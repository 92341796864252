import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {Order} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {Product} from '../../../master-data/models';
import {CustomerCooperationsSelectors} from '../../../master-data/store/selectors';
import {CustomerCooperationsActions} from '../../../master-data/store';
import {Customer} from '../../../customers/models';
import {CustomersSelectors} from '../../../customers/store/selectors';

@Component({
  selector: 'app-order-cooperation-select',
  styleUrls: ['./order-cooperation-select.component.scss'],
  template: `
    <div [formGroup]="formGroup">

      <ng-select
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        [placeholder]="'orders.order_cooperation_select.placeholder' | translate"
        [formControlName]="fieldName"
        (change)="setSelectedItem($event)">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderCooperationSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Output() updateSelectedObject: EventEmitter<Product> = new EventEmitter<Product>();

  items$: Observable<Array<Product>>;
  isLoading$: Observable<boolean>;
  selectedItem: Product;
  customerEntities: { [p: string]: Customer } = {};

  orders: Order[];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.items$ = this.store$.select(CustomerCooperationsSelectors.selectCustomerCooperations);
    this.isLoading$ = this.store$.select(CustomerCooperationsSelectors.isLoading);
    loadIfNotLoaded(this.store$, CustomerCooperationsSelectors.isLoaded, CustomerCooperationsActions.ReadCustomerCooperations());
    this.store$.select(OrdersSelectors.sList).subscribe((orders) => {
      this.orders = orders;
    });
    this.store$.select(CustomersSelectors.selectCustomersEntities).subscribe((customers) => {
      this.customerEntities = customers;
    });


  }

  getOrderCount(ident: string): number {
    return this.orders.filter(e => {
      const customer = this.customerEntities[e.customer['@id'] ? e.customer['@id'] : e.customer];
      return (customer && customer.customerCooperations.indexOf(ident) > -1);
    }).length || -1;
  }

  setSelectedItem(element: Product): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: Product): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }


}
