import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {TicketAttachmentsActions} from '../actions';
import {TicketAttachmentsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class TicketAttachmentsEffects {

  constructor(private actions$: Actions, private service: TicketAttachmentsService, private notifierService: NotifierService) {
  }

  CreateTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketAttachmentsActions.CreateTicketAttachment),
    switchMap(({payload}) => {
      return this.service.createTicketAttachment(payload).pipe(
        map((response) => TicketAttachmentsActions.CreateTicketAttachmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketAttachmentsActions.CreateTicketAttachmentFail({response})))
      );
    })
  ));

  ReadTicketAttachments$ = createEffect(() => this.actions$.pipe(
    ofType(TicketAttachmentsActions.ReadTicketAttachments),
    switchMap(({page, params}) => {
      return this.service.readTicketAttachments(page, params).pipe(
        map((response) => TicketAttachmentsActions.ReadTicketAttachmentsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketAttachmentsActions.ReadTicketAttachmentsFail({response})))
      );
    })
  ));

  DeleteTicketComment$ = createEffect(() => this.actions$.pipe(
    ofType(TicketAttachmentsActions.DeleteTicketAttachment),
    switchMap(({iri, ticketIri}) => {
      return this.service.deleteTicketAttachment(iri).pipe(
        map(() => TicketAttachmentsActions.DeleteTicketAttachmentSuccess({iri, ticketIri})),
        catchError((response: HttpErrorResponse) => of(TicketAttachmentsActions.DeleteTicketAttachmentFail({response})))
      );
    })
  ));

  DownloadTicketAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(TicketAttachmentsActions.DownloadTicketAttachment),
    switchMap(({iri}) => {
      return this.service.readTicketAttachment(iri).pipe(
        map((response: any) => TicketAttachmentsActions.DownloadTicketAttachmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketAttachmentsActions.DownloadTicketAttachmentFail({response})))
      );
    })
  ));
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      TicketAttachmentsActions.CreateTicketAttachmentFail,
      TicketAttachmentsActions.DeleteTicketAttachmentFail,
      TicketAttachmentsActions.ReadTicketAttachmentsFail,
      TicketAttachmentsActions.ReadTicketAttachmentFail
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case TicketAttachmentsActions.CREATE_TICKET_ATTACHMENT_FAIL:
          message = 'Während des Hochladens ist ein Fehler aufgetreten:' + errors;
          break;
        case TicketAttachmentsActions.DELETE_TICKET_ATTACHMENT_FAIL:
          message = 'Während des Löschens ist ein Fehler aufgetreten:' + errors;
          break;
        case TicketAttachmentsActions.READ_TICKET_ATTACHMENT_FAIL:
          message = 'Während des Lesens ist ein Fehler aufgetreten:' + errors;
          break;
        case TicketAttachmentsActions.READ_TICKET_ATTACHMENTS_FAIL:
          message = 'Während des Lesens ist ein Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  SuccessDownloadTicketAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(
      TicketAttachmentsActions.DownloadTicketAttachmentSuccess,
    ),
    map(({response: {contentUrl, originalName}}) => {
      const a = document.createElement('a');
      a.href = contentUrl;
      a.download = originalName; // File name Here
      a.click(); // Downloaded file
      a.remove();
    })
  ), {dispatch: false});

}
