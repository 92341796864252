import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Order, ProofOfDestruction} from '../../../orders/models';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ApplicationState} from '../../../application-state/store';
import {Store} from '@ngrx/store';
import {ProofsOfDestructionSelectors} from '../../../orders/store/selectors';
import {OrdersActions, ProofsOfDestructionActions} from '../../../orders/store';
import {DestructionTypesSelectors, DisposalTypesSelectors} from '../../../master-data/store/selectors';
import {DestructionType, DisposalType} from '../../../master-data/models';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {DestructionTypesActions, DisposalTypesActions} from '../../../master-data/store';
import {MatDialog} from '@angular/material/dialog';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {
  TicketProofOfDestructionEditModalComponent
} from '../ticket-proof-of-destruction-edit-modal/ticket-proof-of-destruction-edit-modal.component';

@Component({
  selector: 'app-ticket-proof-of-destruction',
  styleUrls: ['./ticket-proof-of-destruction.component.scss'],
  template: `
    <div class="card">

      <div class="card__heading">
        <span>Vernichtungsnachweis</span>
        <span class="btn--edit">
            <button mat-icon-button (click)="showUpdateProofOfDestructionModal()">
              <mat-icon>edit</mat-icon>
            </button>

        </span>
      </div>

      <div class="card__content p-a--24">

        <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
        <dl class="col-12">
          <div class="row">
            <dt class="col-4">Kundenwunsch:</dt>
            <dd class="col-6">{{ disposalTypeName  }}</dd>
          </div>
          <div class="row">
            <dt class="col-4">Ausgeführte Vernichtung:</dt>
            <dd class="col-6">{{ destructionTypeName  }}</dd>
          </div>
          <div class="row">
            <dt class="col-4">Datum Vernichtung:</dt>
            <dd class="col-6">{{proofOfDestruction?.date|momentDateWithTime}}</dd>
          </div>
          <div class="row">
            <dt class="col-4">Nachweis verschickt:</dt>
            <dd class="col-6">
              <ng-container *ngFor="let date of order?.proofOfDestructionSentDates">
                {{date|date:'dd.mm.YYYY'}}
              </ng-container>
              <p *ngIf="order?.proofOfDestructionSentDates.length<=0">Noch nicht versendet!</p>
            </dd>
          </div>
        </dl>

      </div>

      <div class="card__footer p-l--24 p-r--24">
        <div class="row">
          <div class="col-6 text-left text-color-grey">
            <button mat-button (click)="downloadProofOfDestruction()">
              <mat-icon>description</mat-icon>
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class TicketProofOfDestructionComponent implements OnInit, OnDestroy {
  proofOfDestruction: ProofOfDestruction;
  order: Order;
  destructionTypeEntities: { [key: string]: DestructionType };
  disposalTypeEntities: { [key: string]: DisposalType };
  @Input() order$: Observable<Order>;
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  get destructionTypeName(): string {
    return this.destructionTypeEntities?.[this.proofOfDestruction?.destructionType]?.name;
  }

  get disposalTypeName(): string {
    return this.disposalTypeEntities?.[this.order?.disposalType]?.name;
  }

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProofsOfDestructionSelectors.isLoading),
      this.store$.select(DestructionTypesSelectors.isLoading)
    ]);
    this.order$.pipe(takeUntil(this.onDestroy$), filter(e => !!e && !!e.proofOfDestruction))
      .subscribe(order => {
        this.order = order;
        console.log(order);
        this.store$.dispatch(ProofsOfDestructionActions.ReadProofOfDestruction({iri: order.proofOfDestruction}));
        this.store$.select(ProofsOfDestructionSelectors.sByIri, {iri: order.proofOfDestruction})
          .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
          .subscribe(p => {
            console.log(p);
            this.proofOfDestruction = p;
          });

      });
    this.loadDestructionTypes();
    this.loadDisposalTypes();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  showUpdateProofOfDestructionModal(): void {
    this.dialog.open(TicketProofOfDestructionEditModalComponent, {data: {order$: this.order$}, width: '85%'});
  }

  downloadProofOfDestruction(): void {
    this.store$.dispatch(OrdersActions.ReadProofOfDestructionPDF({iri: extractIri(this.order)}));
  }

  private loadDestructionTypes(): void {
    this.store$.select(DestructionTypesSelectors.selectDestructionTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(e => {
        this.destructionTypeEntities = e;
      });
    loadIfNotLoaded(this.store$, DestructionTypesSelectors.isLoaded, DestructionTypesActions.ReadDestructionTypes());
  }

  private loadDisposalTypes(): void {
    this.store$.select(DisposalTypesSelectors.selectDisposalTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(e => {
        this.disposalTypeEntities = e;
      });
    loadIfNotLoaded(this.store$, DisposalTypesSelectors.isLoaded, DisposalTypesActions.ReadDisposalTypes());
  }
}
