import { RemoteAccessReasonsService } from './remote-access-reasons.service';
import { TicketAttachmentsService } from './ticket-attachments.service';
import { TicketCommentTagsService } from './ticket-comment-tags.service';
import { TicketCommentsService } from './ticket-comments.service';
import { TicketsService } from './tickets.service';

export const AllServices = [
  RemoteAccessReasonsService,
  TicketAttachmentsService,
  TicketCommentTagsService,
  TicketCommentsService,
  TicketsService,
];

export * from './remote-access-reasons.service';
export * from './ticket-attachments.service';
export * from './ticket-comment-tags.service';
export * from './ticket-comments.service';
export * from './tickets.service';
