import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PotentialAction } from '../../models';
import { FormsService } from '../../services';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { CustomerDataMedium } from '../../../warehouse/models';
import { DataMediumsSelectors } from '../../../warehouse/store/selectors';
import {
  Damage,
  FileSystem,
  StorageSystemManufacturer,
  Symptom
} from '../../../master-data/models';
import {
  DamagesSelectors,
  FileSystemsSelectors,
  StorageSystemManufacturersSelectors,
  SymptomsSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import {
  DamagesActions,
  FileSystemsActions,
  StorageSystemManufacturersActions,
  SymptomsActions
} from '../../../master-data/store';
import { DataRecoveryProtocolsActions } from '../../../orders/store';
import { TransitionsActions } from '../../../application-state/store/actions';
import { DateStringToDateForm } from '../../utilities/date.utility';
import { WriteEMailDialogComponent } from '../../components/write-email-dialog/write-email-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';
import { extractTypeByIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-action-box-to-disposal-data-recovery-protocol-provided',
  styleUrls: [
    './action-box-to-disposal-data-recovery-protocol-provided.component.scss'
  ],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Erstellung Datenrettungsprotokoll
              </div>
              <div class="sub-header col-auto">
                Bitte erstellen Sie das Datenrettungsprotokoll.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right"></div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Erstellung Datenrettungsprotokoll:</div>
          <div class="sub-header col-auto">
            Warte auf Erstellung des Datenrettungsprotokolls
          </div>
        </div>
      </div>
      <div
        class="additional-content p-4"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <mat-label>Datum Beginn der Wiederherstellung</mat-label>

                  <input
                    type="date"
                    matInput
                    formControlName="dataRecoveryStartedAt"
                    required
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'dataRecoveryStartedAt'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mat-form-field ">
                  <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    bindValue="@id"
                    bindLabel="name"
                    required
                    formControlName="fileSystem"
                    [items]="fileSystems$ | async"
                    [loading]="fileSystemsIsLoading$ | async"
                    placeholder="Dateisystem*"
                  ></ng-select>
                  <mat-error>
                    <app-form-error
                      [fieldName]="'fileSystem'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mat-form-field">
                  <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    bindValue="@id"
                    bindLabel="name"
                    required
                    formControlName="damage"
                    [items]="damageTypes$ | async"
                    [loading]="damageTypesIsLoading$ | async"
                    placeholder="Schaden*"
                  ></ng-select>
                  <mat-error>
                    <app-form-error
                      [fieldName]="'damage'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mat-form-field">
                  <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    bindValue="@id"
                    bindLabel="name"
                    required
                    formControlName="symptom"
                    [items]="symptoms$ | async"
                    placeholder="Symptome*"
                  ></ng-select>
                  <mat-error>
                    <app-form-error
                      [fieldName]="'requiredData'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <mat-form-field>
              <mat-label>Beschreibung der verlorenen Daten</mat-label>
              <textarea
                matTextareaAutosize
                matInput
                rows="11"
                formControlName="requiredData"
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'requiredData'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field>
              <mat-label>Ergänzung durch Reverse-Engineer</mat-label>
              <textarea
                matTextareaAutosize
                matInput
                rows="11"
                formControlName="reverseEngineerAddition"
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'reverseEngineerAddition'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-9">
            <h2>Checkliste</h2>
          </div>

          <div class="col-3">
            <h2>Datenrettung</h2>
          </div>

          <div class="col-3">
            <div
              class="mat-form-field checkbox"
              *ngFor="let checkbox of columnOneCheckboxes"
            >
              <mat-checkbox [formControlName]="checkbox.property"
                >{{ checkbox.label }}
              </mat-checkbox>
            </div>
          </div>

          <div class="col-3">
            <div
              class="mat-form-field checkbox"
              *ngFor="let checkbox of columnTwoCheckboxes"
            >
              <mat-checkbox [formControlName]="checkbox.property"
                >{{ checkbox.label }}
              </mat-checkbox>
            </div>
          </div>

          <div class="col-3">
            <div
              class="mat-form-field checkbox"
              *ngFor="let checkbox of columnThreeCheckboxes"
            >
              <mat-checkbox [formControlName]="checkbox.property"
                >{{ checkbox.label }}
              </mat-checkbox>
            </div>
          </div>

          <div class="col-3">
            <div class="mat-form-field checkbox">
              <mat-checkbox formControlName="dataRecoveryInitiated"
                >Datenwiederherstellung eingeleitet
              </mat-checkbox>
            </div>
            <div class="mat-form-field checkbox">
              <mat-checkbox formControlName="dataRecoveryPossible"
                >Datenwiederherstellung möglich
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table
              class="bmo-table bmo-table-heading-uppercase bmo-table-bordered bmo-table-rounded bmo-table-hover bmo-table-clickable"
            >
              <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th class="th column-2">Einl-Nr.</th>
                  <th class="th column-2">Seriennummer</th>
                  <th class="th column-2">Typ</th>
                  <th class="th column-2">Hersteller</th>
                  <th class="th column-1">Speichergröße</th>
                  <th class="th column-5">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="customerDataMediaIsLoading$ | async">
                  <td colspan="6">
                    <app-loading-overlay></app-loading-overlay>
                  </td>
                </tr>
                <tr
                  *ngFor="let medium of customerDataMedia$ | async"
                  (click)="toggleDataMedium(medium)"
                >
                  <td>
                    <mat-icon
                      >{{
                        isChecked(medium)
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </mat-icon>
                  </td>
                  <td class="td column-2">{{ medium.storageNumber }}</td>
                  <td class="td column-2">
                    {{ medium.storageSystemSerialNumber }}
                  </td>
                  <td class="td column-2">
                    {{
                      medium?.storageSystemType?.name
                        ? medium.storageSystemType.name
                        : (medium | json)
                    }}
                  </td>
                  <td class="td column-2">
                    {{
                      getStorageSystemManufacturerName(
                        medium.storageSystemManufacturer
                      )
                    }}
                  </td>
                  <td class="td column-1">{{ medium.storageSystemSize }} GB</td>
                  <td class="td column-5">{{ medium.state }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 text-right mt-3">
            <button
              mat-flat-button
              color="gray"
              class="me-2 text-color-black"
              (click)="handleSubmitAction()"
            >
              <mat-icon class="m-r--8">save</mat-icon>
              <span>Speichern & Aktion schließen</span>
            </button>
            <button
              mat-flat-button
              color="green"
              (click)="handleSubmitAndSendAction()"
            >
              <mat-icon class="m-r--8">mail</mat-icon>
              <span>Speichern & per E-Mail versenden</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--<pre>{{possibleAction|json}}</pre>-->
  `
})
export class ActionBoxToDisposalDataRecoveryProtocolProvidedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;
  damageTypes$: Observable<Array<Damage>>;
  damageTypesIsLoading$: Observable<boolean>;
  fileSystems$: Observable<Array<FileSystem>>;
  fileSystemsIsLoading$: Observable<boolean>;

  customerDataMedia$: Observable<Array<CustomerDataMedium>>;
  customerDataMediaIsLoading$: Observable<boolean>;

  symptoms$: Observable<Array<Symptom>>;
  symptomsIsLoading: Observable<boolean>;
  storageSystemManufacturersEntities: {
    [iri: string]: StorageSystemManufacturer;
  };

  columnOneCheckboxes = [
    {
      property: 'expressProcessingRequired',
      label: 'Expressbearbeitung erforderlich'
    },
    {
      property: 'rescueAttemptsAlreadyMade',
      label: 'Bereits zuvor erfolgte Rettungsversuche'
    },
    {
      property: 'dataMediumOpenedByCustomer',
      label: 'Datenträger durch Kunde geöffnet'
    },
    {
      property: 'visualInspectionPerformed',
      label: 'Sichtprüfung erfolgt'
    },
    {
      property: 'dataMediumTechnicallyDefective',
      label: 'Datenträger technisch defekt'
    },
    {
      property: 'electronicDamageDetected',
      label: 'Elektronikschaden festgestellt'
    }
  ];
  columnTwoCheckboxes = [
    {
      property: 'logicalDistributedDamage',
      label: 'Logische Beschädigungen (verteilt)'
    },
    {
      property: 'encryptionInFileSystem',
      label: 'Verschlüsselung im Dateisystem'
    },
    {
      property: 'controllerEncryption',
      label: 'Controller-Verschlüsselung'
    },
    {
      property: 'virtualFileSystem',
      label: 'Virtuelles Dateisystem'
    },
    {
      property: 'proprietaryFileSystem',
      label: 'Proprietäres Dateisystem'
    },
    {
      property: 'diskOpenDuringDiagnostics',
      label: 'Datenträger bei Diagnose geöffnet'
    }
  ];
  columnThreeCheckboxes = [
    {
      property: 'diskOpenDuringRecovery',
      label: 'Datenträger bei Recovery geöffnet'
    },
    {
      property: 'disposalRequested',
      label: 'Entsorgung angefordert'
    },
    {
      property: 'sectorsDefective',
      label: 'Sektoren defekt '
    },
    {
      property: 'destructiveWriteAccessDetected',
      label: 'Destruktive Schreibzugriffe festgestellt'
    }
  ];

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private authService: AuthService,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private actions$: Actions,
    private notify: NotifierService
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDamageTypes();
    this.loadFileSystems();
    this.loadSymptoms();
    this.loadStorageSystemManufacturersEntities();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf(
              '_to_disposal_data_recovery_protocol_provided'
            ) > -1 && !e.error
        );
        this.loadCustomerDataMedium(order);
        this.form.patchValue({
          dataRecoveryStartedAt: DateStringToDateForm(order.createdAt),
          symptom: order.symptom,
          damage: order.damage,
          fileSystem: order.fileSystem,
          requiredData: order.requiredData
        });
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      controllerEncryption: this.fb.control(false),
      customerDataMedia: this.fb.control([], Validators.required),
      damage: this.fb.control(null, Validators.required),
      dataMediumOpenedByCustomer: this.fb.control(false),
      dataMediumTechnicallyDefective: this.fb.control(false),
      dataRecoveryInitiated: this.fb.control(false),
      dataRecoveryPossible: this.fb.control(false),
      dataRecoveryStartedAt: this.fb.control(null, Validators.required),
      destructiveWriteAccessDetected: this.fb.control(false),
      diskOpenDuringDiagnostics: this.fb.control(false),
      diskOpenDuringRecovery: this.fb.control(false),
      disposalRequested: this.fb.control(false),
      electronicDamageDetected: this.fb.control(false),
      encryptionInFileSystem: this.fb.control(false),
      expressProcessingRequired: this.fb.control(false),
      fileSystem: this.fb.control(null, Validators.required),
      logicalDistributedDamage: this.fb.control(false),
      proprietaryFileSystem: this.fb.control(false),
      requiredData: this.fb.control(''),
      rescueAttemptsAlreadyMade: this.fb.control(false),
      reverseEngineerAddition: this.fb.control(''),
      sectorsDefective: this.fb.control(false),
      symptom: this.fb.control(null, Validators.required),
      virtualFileSystem: this.fb.control(false),
      visualInspectionPerformed: this.fb.control(false)
    });
  }

  isChecked(medium: CustomerDataMedium): boolean {
    return this.form.get('customerDataMedia').value.indexOf(medium['@id']) > -1;
  }

  toggleDataMedium(medium: CustomerDataMedium): void {
    const value = [];
    value.push(...this.form.get('customerDataMedia').value);
    const indexOfElement = value.indexOf(medium['@id']);
    if (indexOfElement > -1) {
      value.splice(indexOfElement, 1);
    } else {
      value.push(medium['@id']);
    }
    this.form.get('customerDataMedia').patchValue(value);
  }

  handleSubmitAction(): void {
    const iri = this.order?.dataRecoveryProtocol;
    if (!iri) {
      return;
    }

    if (this.form.value.customerDataMedia.length <= 0) {
      this.notify.show({
        type: 'error',
        message:
          'Bitte wenigstens einen Datenträger zur Datenrettung auswählen.'
      });
      return;
    }
    const payload = this.form.getRawValue();
    this.store$.dispatch(
      DataRecoveryProtocolsActions.UpdateDataRecoveryProtocol({ iri, payload })
    );

    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolSuccess)
      )
      .subscribe(() => {
        this.form.markAsPristine();
        this.handleMakeTransition();
      });
  }

  handleSubmitAndSendAction(): void {
    const iri = this.order?.dataRecoveryProtocol;
    if (!iri) {
      return;
    }

    if (this.form.value.customerDataMedia.length <= 0) {
      this.notify.show({
        type: 'error',
        message:
          'Bitte wenigstens einen Datenträger zur Datenrettung auswählen.'
      });
      return;
    }
    const payload = this.form.getRawValue();
    this.store$.dispatch(
      DataRecoveryProtocolsActions.UpdateDataRecoveryProtocol({ iri, payload })
    );

    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolSuccess),
        take(1)
      )
      .subscribe(() => {
        this.dialog
          .open(WriteEMailDialogComponent, {
            data: {
              type: 'dataRecoveryProtocol',
              entity$: of(this.order)
            }
          })
          .afterClosed()
          .subscribe(success => {
            if (success) {
              this.handleMakeTransition();
            }
          });
      });
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  getStorageSystemManufacturerName(
    manufacturer: string | { name?: string }
  ): string {
    if (typeof manufacturer !== 'string' && manufacturer?.name) {
      return manufacturer.name;
    }
    if (typeof manufacturer === 'string') {
      return this.storageSystemManufacturersEntities[manufacturer]?.name;
    }
  }

  private loadDamageTypes(): void {
    this.damageTypes$ = this.store$.select(DamagesSelectors.selectDamages);
    this.damageTypesIsLoading$ = this.store$.select(DamagesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DamagesSelectors.isLoaded,
      DamagesActions.ReadDamages()
    );
  }

  private loadFileSystems(): void {
    this.fileSystems$ = this.store$.select(
      FileSystemsSelectors.selectFileSystems
    );
    this.fileSystemsIsLoading$ = this.store$.select(
      FileSystemsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      FileSystemsSelectors.isLoaded,
      FileSystemsActions.ReadFileSystems()
    );
  }

  private loadSymptoms(): void {
    this.symptoms$ = this.store$.select(SymptomsSelectors.selectSymptoms);
    this.symptomsIsLoading = this.store$.select(SymptomsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      SymptomsSelectors.isLoaded,
      SymptomsActions.ReadSymptoms()
    );
  }

  private loadCustomerDataMedium(order): void {
    this.customerDataMedia$ = this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, {
        orderIri: order['@id']
      })
      .pipe(
        map(e => e.filter(f => extractTypeByIri(f) === 'customer_data_media'))
      );

    this.customerDataMediaIsLoading$ = this.store$.select(
      DataMediumsSelectors.isLoading
    );
    this.customerDataMedia$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(data => !!data)
      )
      .subscribe(entities => {
        this.form
          .get('customerDataMedia')
          .setValue(entities.map(e => e['@id']));
      });
  }

  private loadStorageSystemManufacturersEntities(): void {
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemManufacturersEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }
}
