<div class="card-wrapper">
  <div
    class="badge-wrapper"
    *ngIf="lead.vip || lead.brokeredToPartner || isExpress(lead)"
  >
                  <span *ngIf="lead.vip" class="vip">
                    <span>VIP</span>
                  </span>
    <span *ngIf="lead.brokeredToPartner" class="brokered">
                    <span>VM</span>
                  </span>
    <span *ngIf="isExpress(lead)" class="express">
    <span>EXPRESS</span>
  </span>
  </div>
  <div
    class="card"
    [attr.data-box-style]="
                    leadContactTypesEntities[lead.leadContactType]
                      ? leadContactTypesEntities[lead.leadContactType][
                          'styleName'
                        ]
                      : ''
                  "
  >
    <div [class.selected]="isSelected"></div>
    <div class="grid">
      <strong class="column-12">{{ getCustomerContactName(lead) }}</strong>
      <span class="icon-wrapper column-2" *ngIf="leadOriginTypesEntities[lead.leadOriginType]">
        <span class="icon">{{
            leadOriginTypesEntities[lead.leadOriginType]
              ? leadOriginTypesEntities[lead.leadOriginType][
                'short'
                ]
              : ''
          }}</span>
      </span>
    </div>
    <!-- {{lead.columnRank}}-->
    <div>{{ getCompanyName(lead) }}</div>

    <hr />

    <div class="grid">
                    <span class="column-7 storage-sys">{{
                        lead.storageSystem &&
                        storageSystemsEntities[lead.storageSystem]
                          ? storageSystemsEntities[lead.storageSystem]['name']
                          : '-'
                      }}</span>
      <span class="date m-ta--2 column-7">{{
          lead.createdAt | momentDateWithTime
        }}</span>
    </div>

    <div class="drag-handle" cdkDragHandle *ngIf="draggable">
      <mat-icon>drag_indicator</mat-icon>
    </div>
  </div>
</div>
