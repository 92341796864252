import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import * as fromLeadsModuleModels from '../../models';
import * as fromShippingModuleModels from '../../../shipping/models';
import { ShippingProvider } from '../../../shipping/models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import {
  AnalysisPriorityMode,
  Damage,
  Discount,
  FileSystem,
  OperatingSystem,
  StorageSystem,
  StorageSystemManufacturer,
  Symptom,
  Tax
} from '../../../master-data/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  AnalysisPriorityModesSelectors,
  DamagesSelectors,
  DiscountsSelectors,
  FileSystemsSelectors,
  OperatingSystemsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors,
  SymptomsSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import {
  AnalysisPriorityModesActions,
  DamagesActions,
  DiscountsActions,
  FileSystemsActions,
  OperatingSystemsActions,
  StorageSystemManufacturersActions,
  StorageSystemsActions,
  SymptomsActions,
  TaxesActions
} from '../../../master-data/store';
import { ShippingProvidersToDataRecoverySelectors } from '../../../shipping/store/selectors';
import { ShippingProvidersActions } from '../../../shipping/store';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';

@Component({
  selector: 'app-device-details-form',
  styleUrls: ['device-details-form.component.scss'],
  templateUrl: 'device-details-form.component.html'
})
export class DeviceDetailsFormComponent implements OnInit, OnDestroy {
  lastValueOfToggles = {
    databases: null,
    encryptedData: null,
    virtualSystems: null,
    virtualEncryption: null
  };
  @Input() lead$: BehaviorSubject<fromLeadsModuleModels.Lead>;
  @Input() errors: ErrorsObject;

  discounts$: Observable<Array<Discount>>;
  discountsIsLoading$: Observable<boolean>;

  damageTypes$: Observable<Array<Damage>>;
  damageTypesIsLoading$: Observable<boolean>;

  fileSystems$: Observable<Array<FileSystem>>;
  fileSystemsIsLoading$: Observable<boolean>;

  operatingSystems$: Observable<Array<OperatingSystem>>;
  operatingSystemsIsLoading$: Observable<boolean>;

  priorityModes$: Observable<Array<AnalysisPriorityMode>>;
  priorityModesIsLoading$: Observable<boolean>;

  shippingProviders$: Observable<Array<ShippingProvider>>;
  shippingProvidersIsLoading$: Observable<boolean>;

  storageSystemManufacturers$: Observable<Array<StorageSystemManufacturer>>;
  storageSystemManufacturersIsLoading$: Observable<boolean>;

  storageSystems$: Observable<Array<StorageSystem>>;
  storageSystems$IsLoading: Observable<boolean>;

  symptoms$: Observable<Array<Symptom>>;
  symptomsIsLoading$: Observable<boolean>;

  taxes$: Observable<Array<Tax>>;
  taxesIsLoading$: Observable<boolean>;
  taxesEntities: { [iri: string]: Tax };

  ddf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  private defaultTax: Tax;

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>
  ) {}

  get today(): Date {
    return new Date();
  }

  ngOnInit(): void {
    this.initForm();

    this.loadDamageTypes();
    this.loadFilesystems();
    this.loadOperatingSystems();
    this.loadPriorityModes();
    this.loadShippingProviders();
    this.loadStorageSystemManufacturers();
    this.loadStorageSystems();
    this.loadSymptoms();
    this.loadTaxes();
    this.loadDiscounts();

    this.lead$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(lead => {
        if (!lead) {
          return;
        }
        const newLead = {
          ...lead
        };
        if (lead.shipment) {
          this.ddf.get('shippingProviderToDR').disable();
        }
        if (lead.taxAnalysis && lead.taxAnalysis['@id']) {
          lead.taxAnalysis = lead.taxAnalysis['@id'];
        }
        this.lastValueOfToggles.databases = newLead.databases;
        this.lastValueOfToggles.encryptedData = newLead.encryptedData;
        this.lastValueOfToggles.virtualEncryption = newLead.virtualEncryption;
        this.lastValueOfToggles.virtualSystems = newLead.virtualSystems;
        this.handleUpdateGrossValue();
        this.fs.patchForm(this.ddf, newLead);
        this.ddf.markAsUntouched();

        this.enableOrDisableFormFields(lead?.shippingProviderToDR);
      });
  }

  initForm(): void {
    this.ddf = this.fb.group({
      analysisPriorityMode: this.fb.control(null, Validators.required),
      costAnalysis: this.fb.control(''),
      damage: this.fb.control(null, [Validators.required]),
      dataLossAt: this.fb.control(null, [Validators.required]),
      dataLossDetectedAt: this.fb.control(null, [Validators.required]),
      databases: this.fb.control(null),
      encryptedData: this.fb.control(null),
      fileSystem: this.fb.control(null, [Validators.required]),
      history: this.fb.control(''),
      host: this.fb.control(''),
      numberOfDataMedia: this.fb.control(1, [
        Validators.min(1),
        Validators.max(20)
      ]),
      numberOfPartitions: this.fb.control(1, [
        Validators.required,
        Validators.min(0)
      ]),
      operatingSystem: this.fb.control(null, [Validators.required]),
      pinPassword: this.fb.control(''),
      priority: this.fb.control(null, [Validators.required]),
      requiredData: this.fb.control(''),
      shippingProviderToDR: this.fb.control(null, [
        Validators.required,
        Validators.minLength(5)
      ]),
      sizeInGB: this.fb.control(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(50000)
      ]),
      specificInformation: this.fb.control(''),
      stepsAlreadyTaken: this.fb.control(''),
      storageManufacturerModel: this.fb.control(''),
      storageManufacturerSerialNumber: this.fb.control(''),
      storageSystem: this.fb.control(null, [Validators.required]),
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      symptom: this.fb.control(null, [Validators.required]),
      virtualEncryption: this.fb.control(null),
      virtualSystems: this.fb.control(null),
      shipment: this.fb.control(null),
      priceAnalysisGross: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      priceAnalysisNet: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      taxAnalysis: this.fb.control(null),
      discount: this.fb.control(null),
      specialDiscount: this.fb.control(null)
    });
  }

  handleChangeShippingProvider(
    provider?: fromShippingModuleModels.ShippingProvider
  ): void {
    if (!provider) {
      return;
    }

    this.enableOrDisableFormFields(provider['@id']);
  }

  enableOrDisableFormFields(provider: string): void {
    if (this.leadRequiresPriorShipment(provider)) {
      this.ddf.get('shipment').enable();
    } else {
      // this.ddf.removeControl('shipment');
      this.ddf.get('shipment').disable();
    }
    this.ddf.get('shipment').updateValueAndValidity();
  }

  getShipmentControl(): FormControl {
    return this.fb.control(null, [
      Validators.required,
      Validators.minLength(4)
    ]);
  }

  leadRequiresPriorShipment(value?: string): boolean {
    if (!value) {
      return false;
    }
    return !value.includes('PERSONAL');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  setDefaultTaxesIfNecessary(): void {
    if (!this.defaultTax) {
      return;
    }
    if (
      this.ddf.controls.taxAnalysis.value === '' ||
      this.ddf.controls.taxAnalysis.value === null
    ) {
      this.ddf.controls.taxAnalysis.setValue(this.defaultTax['@id']);
    }
  }

  handleUpdateGrossValue(): void {
    const taxField = 'taxAnalysis';
    const netField = 'priceAnalysisNet';
    const grossField = 'priceAnalysisGross';
    const netValue = parseFloat(this.ddf.get(netField + '.value').value);
    if (
      !this.ddf.get(taxField).value ||
      !this.taxesEntities[this.ddf.get(taxField).value]
    ) {
      return;
    }
    const taxValue = parseFloat(
      this.taxesEntities[this.ddf.get(taxField).value].value
    );
    const grossValue = Math.round(netValue * (taxValue / 100 + 1) * 100) / 100;
    this.ddf.get(grossField + '.value').patchValue(grossValue);
  }

  handleUpdateNetValue(): void {
    const taxField = 'taxAnalysis';
    const netField = 'priceAnalysisNet';
    const grossField = 'priceAnalysisGross';
    const grossValue = parseFloat(this.ddf.get(grossField + '.value').value);
    if (
      !this.ddf.get(taxField).value ||
      !this.taxesEntities[this.ddf.get(taxField).value]
    ) {
      return;
    }
    const taxValue = parseFloat(
      this.taxesEntities[this.ddf.get(taxField).value].value
    );
    const netValue =
      Math.round((grossValue / (taxValue / 100 + 1)) * 100) / 100;
    this.ddf.get(netField + '.value').patchValue(netValue);
  }

  toggleBtnClicked(field: string, valueClicked: any): void {
    if (this.lastValueOfToggles[field] !== valueClicked) {
      this.lastValueOfToggles[field] = valueClicked;
    } else {
      this.ddf.get(field).setValue(null);
      this.ddf.get(field).markAsDirty();
      this.lastValueOfToggles[field] = null;
    }
  }

  private loadFilesystems(): void {
    this.fileSystems$ = this.store$.select(
      FileSystemsSelectors.selectFileSystems
    );
    this.fileSystemsIsLoading$ = this.store$.select(
      FileSystemsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      FileSystemsSelectors.isLoaded,
      FileSystemsActions.ReadFileSystems()
    );
  }

  private loadOperatingSystems(): void {
    this.operatingSystems$ = this.store$.select(
      OperatingSystemsSelectors.selectOperatingSystems
    );
    this.operatingSystemsIsLoading$ = this.store$.select(
      OperatingSystemsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      OperatingSystemsSelectors.isLoaded,
      OperatingSystemsActions.ReadOperatingSystems()
    );
  }

  private loadDamageTypes(): void {
    this.damageTypes$ = this.store$.select(DamagesSelectors.selectDamages);
    this.damageTypesIsLoading$ = this.store$.select(DamagesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DamagesSelectors.isLoaded,
      DamagesActions.ReadDamages()
    );
  }

  private setDefaultAnalysisMode(
    analysisPriorityMode: AnalysisPriorityMode
  ): void {
    if (
      analysisPriorityMode &&
      this.ddf.get('analysisPriorityMode').value === null
    ) {
      this.ddf
        .get('analysisPriorityMode')
        .setValue(analysisPriorityMode['@id']);
    }
  }

  private loadPriorityModes(): void {
    this.priorityModes$ = this.store$.select(
      AnalysisPriorityModesSelectors.selectAnalysisPriorityModes
    );
    this.priorityModesIsLoading$ = this.store$.select(
      AnalysisPriorityModesSelectors.isLoading
    );
    this.priorityModes$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0)
      )
      .subscribe(modes => {
        const defaultMode = modes.find(e => e.name === 'STANDARD');
        this.setDefaultAnalysisMode(defaultMode);
      });

    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
  }

  private loadShippingProviders(): void {
    this.shippingProviders$ = this.store$.select(
      ShippingProvidersToDataRecoverySelectors.selectShippingProvidersToDataRecovery
    );
    this.shippingProvidersIsLoading$ = this.store$.select(
      ShippingProvidersToDataRecoverySelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToDataRecoverySelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToDataRecovery()
    );
  }

  private loadStorageSystemManufacturers(): void {
    this.storageSystemManufacturers$ = this.store$.select(
      StorageSystemManufacturersSelectors.sOrderedList
    );
    this.storageSystemManufacturersIsLoading$ = this.store$.select(
      StorageSystemManufacturersSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }

  private loadStorageSystems(): void {
    this.storageSystems$ = this.store$.select(
      StorageSystemsSelectors.sOrderedList
    );
    this.storageSystems$IsLoading = this.store$.select(
      StorageSystemsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }

  private loadSymptoms(): void {
    this.symptoms$ = this.store$.select(SymptomsSelectors.selectSymptoms);
    this.symptomsIsLoading$ = this.store$.select(SymptomsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      SymptomsSelectors.isLoaded,
      SymptomsActions.ReadSymptoms()
    );
  }

  private loadTaxes(): void {
    this.taxes$ = this.store$.select(TaxesSelectors.selectTaxes);

    this.store$
      .select(TaxesSelectors.selectTaxesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(taxes => {
        this.taxesEntities = taxes;
      });
    this.taxes$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(taxes => !!taxes && taxes.length > 0)
      )
      .subscribe(taxes => {
        this.defaultTax = taxes.find(tax => tax.isDefault);
        this.setDefaultTaxesIfNecessary();
        this.handleUpdateGrossValue();
      });
    this.taxesIsLoading$ = this.store$.select(TaxesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      TaxesSelectors.isLoaded,
      TaxesActions.ReadTaxes()
    );
  }

  private loadDiscounts(): void {
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.discountsIsLoading$ = this.store$.select(DiscountsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }
}
