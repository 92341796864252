import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageSystemManufacturersActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { StorageSystemManufacturersService } from '../../services/storage-system-manufacturers.service';

@Injectable()
export class StorageSystemManufacturersEffects {
  createStorageSystemManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemManufacturersActions.CreateStorageSystemManufacturer),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createStorageSystemManufacturer(payload).pipe(
          map(response =>
            StorageSystemManufacturersActions.CreateStorageSystemManufacturerSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              StorageSystemManufacturersActions.CreateStorageSystemManufacturerFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  getStorageSystemManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemManufacturersActions.ReadStorageSystemManufacturer),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getStorageSystemManufacturer(iri).pipe(
          map(response =>
            StorageSystemManufacturersActions.ReadStorageSystemManufacturerSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              StorageSystemManufacturersActions.ReadStorageSystemManufacturerFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  getStorageSystemManufacturers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemManufacturersActions.ReadStorageSystemManufacturers),
      map(action => action),
      switchMap(() => {
        return this.service.getStorageSystemManufacturers().pipe(
          map(response =>
            StorageSystemManufacturersActions.ReadStorageSystemManufacturersSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              StorageSystemManufacturersActions.ReadStorageSystemManufacturersFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  updateStorageSystemManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemManufacturersActions.UpdateStorageSystemManufacturer),
      map(action => action),
      switchMap(payload => {
        return this.service.updateStorageSystemManufacturer(payload).pipe(
          map(response =>
            StorageSystemManufacturersActions.UpdateStorageSystemManufacturerSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              StorageSystemManufacturersActions.UpdateStorageSystemManufacturerFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  deleteStorageSystemManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemManufacturersActions.DeleteStorageSystemManufacturer),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteStorageSystemManufacturer(iri).pipe(
          map(() =>
            StorageSystemManufacturersActions.DeleteStorageSystemManufacturerSuccess(
              { iri }
            )
          ),
          catchError(response =>
            of(
              StorageSystemManufacturersActions.DeleteStorageSystemManufacturerFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StorageSystemManufacturersActions.DeleteStorageSystemManufacturerFail,
          StorageSystemManufacturersActions.UpdateStorageSystemManufacturerFail,
          StorageSystemManufacturersActions.ReadStorageSystemManufacturersFail,
          StorageSystemManufacturersActions.ReadStorageSystemManufacturerFail,
          StorageSystemManufacturersActions.CreateStorageSystemManufacturerFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  fileSystemActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageSystemManufacturersActions.CreateStorageSystemManufacturerSuccess,
        StorageSystemManufacturersActions.UpdateStorageSystemManufacturerSuccess
      ),
      map(({ type }) => {
        const text =
          type ===
          StorageSystemManufacturersActions.CREATE_STORAGE_SYSTEM_MANUFACTURER_SUCCESS
            ? 'Eine neuer Speichersystemhersteller wurde angelegt.'
            : 'Der Speichersystemhersteller wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: StorageSystemManufacturersService,
    private notifierService: NotifierService
  ) {}
}
