import {Component, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Order} from "../../models";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {filter, takeUntil} from "rxjs/operators";
import {extractIri, extractUUID} from "../../../shared/utilities/objects.utility";
import {UserProfileSelectors} from "../../../auth/store/selectors";
import {ParameterBag} from "../../../shared/models/ParameterBag.interface";

@Component({
  selector: 'app-pending-storage-system-widget',
  templateUrl: './pending-storage-system-widget.component.html',
  styleUrls: ['./pending-storage-system-widget.component.scss']
})
export class PendingStorageSystemWidgetComponent implements OnInit {

  onDestroy$: Subject<any> = new Subject<any>();
  states: Array<string> = [];
  additionalParams: ParameterBag;
  salesperson: string;

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(profile => {
        this.salesperson = extractIri(profile);
      });// Get the current date
    this.additionalParams = {
      'updatedAt[strictly_before]': this.getFourWeeksFromNow()
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(order: Order): { color: string } {
    return { color: 'grey' };
  }
  getFourWeeksFromNow(): string {

    const currentDate = new Date();
    const fourWeeksAgo = new Date(currentDate.getTime() - (4 * 7 * 24 * 60 * 60 * 1000));
    const timezoneOffset = '+01:00';

    let isoString = fourWeeksAgo.toISOString();
    isoString = isoString.substring(0, isoString.length - 1) + timezoneOffset;

    return decodeURIComponent(isoString);
  }
}
