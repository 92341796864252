import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_COUNTRY = '[Master Data] Read Country';
export const READ_COUNTRY_SUCCESS = '[Master Data] Read Country Success';
export const READ_COUNTRY_FAIL = '[Master Data] Read Country Fail';
export const ReadCountry = createAction(
  READ_COUNTRY,
  props<{ iri: string }>()
);
export const ReadCountrySuccess = createAction(
  READ_COUNTRY_SUCCESS,
  props<{ response: fromModuleModels.Country }>()
);
export const ReadCountryFail = createAction(
  READ_COUNTRY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_COUNTRIES = '[Master Data] Read Countries';
export const READ_COUNTRIES_SUCCESS = '[Master Data] Read Countries Success';
export const READ_COUNTRIES_FAIL = '[Master Data] Read Countries Fail';
export const ReadCountries = createAction(
  READ_COUNTRIES
);
export const ReadCountriesSuccess = createAction(
  READ_COUNTRIES_SUCCESS,
  props<{ response: any }>()
);
export const ReadCountriesFail = createAction(
  READ_COUNTRIES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
