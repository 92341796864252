import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {ShipmentsActions} from '../actions';
import {NotifierService} from 'angular-notifier';
import {ShipmentsService} from '../../services/shipments.service';

@Injectable()
export class ShipmentsEffects {

  constructor(private actions$: Actions, private service: ShipmentsService, private notifierService: NotifierService) {
  }


  CreateShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.CreateShipment),
    switchMap(({payload}) => {
      return this.service.createShipment(payload).pipe(
        map((response: any) => ShipmentsActions.CreateShipmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.CreateShipmentFail({response})))
      );
    })
  ));


  ReadShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.ReadShipment),
    switchMap(({iri}) => {
      return this.service.readShipment(iri).pipe(
        map((response: any) => ShipmentsActions.ReadShipmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.ReadShipmentFail({response})))
      );
    })
  ));


  ReadShipments$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.ReadShipments),
    switchMap(({page, params}) => {
      return this.service.readShipments(page, params).pipe(
        map((response: any) => ShipmentsActions.ReadShipmentsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.ReadShipmentsFail({response})))
      );
    })
  ));


  UpdateShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.UpdateShipment),
    switchMap(({iri, payload}) => {
      return this.service.updateShipment(iri, payload).pipe(
        map((response: any) => ShipmentsActions.UpdateShipmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.UpdateShipmentFail({response})))
      );
    })
  ));


  CancelShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.CancelShipment),
    switchMap(({iri, payload}) => {
      return this.service.patchShipment(iri, payload).pipe(
        map((response: any) => ShipmentsActions.CancelShipmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.CancelShipmentFail({response})))
      );
    })
  ));


  ConfirmShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.ConfirmShipment),
    switchMap(({iri, payload}) => {
      return this.service.patchShipment(iri, payload).pipe(
        map((response: any) => ShipmentsActions.ConfirmShipmentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.ConfirmShipmentFail({response})))
      );
    })
  ));


  DeleteShipment$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentsActions.DeleteShipment),
    switchMap(({iri}) => {
      return this.service.deleteShipment(iri).pipe(
        map(() => ShipmentsActions.DeleteShipmentSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(ShipmentsActions.DeleteShipmentFail({response})))
      );
    })
  ));


  successActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      ShipmentsActions.CreateShipmentSuccess,
      ShipmentsActions.UpdateShipmentSuccess,
      ShipmentsActions.ConfirmShipmentSuccess,
      ShipmentsActions.DeleteShipmentSuccess
    ),
    map(({type}) => {
      let message = 'Erfolgreich';
      switch (type) {
        case ShipmentsActions.CREATE_SHIPMENT_SUCCESS:
          message = 'Ein neues Versandpaket wurde angelegt.';
          break;
        case ShipmentsActions.UPDATE_SHIPMENT_SUCCESS:
          message = 'Das Versandpaket wurde aktualisiert.';
          break;
        case ShipmentsActions.CONFIRM_SHIPMENT_SUCCESS:
          message = 'Die Aktion wurde erfolgreich ausgeführt.';
          break;
        case ShipmentsActions.DELETE_SHIPMENT_SUCCESS:
          message = 'Das Versandpaket wurde erfolgreich gelöscht.';
          break;
      }

      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      ShipmentsActions.CreateShipmentFail,
      ShipmentsActions.UpdateShipmentFail,
      ShipmentsActions.DeleteShipmentFail,
      ShipmentsActions.CancelShipmentFail,
      ShipmentsActions.ConfirmShipmentFail
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case ShipmentsActions.CREATE_SHIPMENT_FAIL:
          message = 'Beim Anlegen eines neuen Versandpakets sind Fehler aufgetreten:' + errors;
          break;
        case ShipmentsActions.UPDATE_SHIPMENT_FAIL:
          message = 'Beim Aktualisieren des Versandpakets sind Fehler aufgetreten:' + errors;
          break;
        case ShipmentsActions.DELETE_SHIPMENT_FAIL:
          message = 'Beim Löschen des Versandpakets ist ein Fehler aufgetreten:' + errors;
          break;
        case ShipmentsActions.CANCEL_SHIPMENT_FAIL:
          message = 'Beim Abbrechen des Versandpakets ist ein Fehler aufgetreten:' + errors;
          break;
        case ShipmentsActions.CONFIRM_SHIPMENT_FAIL:
          message = 'Beim Bestätigen des Versandpakets ist ein Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  cancelShipmentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      ShipmentsActions.CancelShipmentSuccess
    ),
    map(({type}) => {

      const message = 'Das Versandpaket wurde storniert und kann jetzt gelöscht werden.';
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});
}
