import {createSelector} from '@ngrx/store';

import {CUSTOMER_OFFERS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerOffersState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_OFFERS_FEATURE_KEY]
);

export const selectCustomerOfferEntities = createSelector(
  selectCustomerOffersState,
  (state) => state.entities
);

export const selectCustomerOffers = createSelector(
  selectCustomerOfferEntities,
  (contacts) => Object.keys(contacts).map(iri => contacts[iri])
);

export const selectCustomerOffersByCustomerIri = createSelector(
  selectCustomerOffers,
  (contacts, {customerIri}) => contacts.filter(contact => contact.customer === customerIri)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoaded = createSelector(
  selectCustomerOffersState,
  state => state.loaded
);

export const isLoading = createSelector(
  selectCustomerOffersState,
  state => state.loading
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomerOffersState,
  (state) => state.errors
);
