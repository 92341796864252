import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'decision-box',
  styleUrls: ['decision-box.component.scss'],
  template: `

    <div class="grid">
      <div class="column-{{ (transitionNameIfFeedbackNegative && rightButtonLabel) ? '7' : '9 off-5' }}">
        <button mat-button color="green" (click)="handleMakeTransition(transitionNameIfFeedbackPositive)">
          <mat-icon class="m-r--8">{{ leftButtonIconIdentifier ? leftButtonIconIdentifier : 'done' }}</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>
      </div>

      <div class="column-7" *ngIf="transitionNameIfFeedbackNegative && rightButtonLabel ">
        <button mat-button color="red" (click)="handleMakeTransition(transitionNameIfFeedbackNegative)">
          <mat-icon class="m-r--8">{{ rightButtonIconIdentifier ? rightButtonIconIdentifier : 'cancel' }}</mat-icon>
          <span>{{ rightButtonLabel }}</span>
        </button>
      </div>
    </div>
  `
})
export class DecisionBoxComponent {

  @Input() leftButtonLabel: string;
  @Input() leftButtonIconIdentifier: string;
  @Input() rightButtonLabel: string;
  @Input() rightButtonIconIdentifier: string;
  @Input() transitionNameIfFeedbackPositive: string;
  @Input() transitionNameIfFeedbackNegative: string;

  @Output() requestMakeTransition: EventEmitter<string> = new EventEmitter();

  handleMakeTransition(transition: string): void {
    this.requestMakeTransition.emit(transition);
  }
}
