import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as fromInvoicesModuleServices from '../../services';
import {DocumentDeliveryProvidersActions} from '../actions';

@Injectable()
export class DocumentDeliveryProvidersEffects {

  constructor(private actions$: Actions, private service: fromInvoicesModuleServices.DocumentDeliveryProvidersService) {
  }


  readDocumentDeliveryProvider$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvider),
    switchMap(({iri}) => {
      return this.service.readDocumentDeliveryProvider(iri).pipe(
        map(response => DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviderSuccess({response})),
        catchError(response => of(DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviderFail({response})))
      );
    })
  ));


  readDocumentDeliveryProviders$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveryProvidersActions.ReadDocumentDeliveryProviders),
    switchMap(() => {
      return this.service.readDocumentDeliveryProviders().pipe(
        map(response => DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvidersSuccess({response})),
        catchError(response => of(DocumentDeliveryProvidersActions.ReadDocumentDeliveryProvidersFail({response})))
      );
    })
  ));
}
