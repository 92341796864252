<div class="pos-relative">
  <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
  <div class="row">
    <div class="col-auto me-auto">
      <h1>Ticket #{{ (ticket$|async)?.ticketNumber }} - {{ getCustomerName(order$|async) }}
        <span class="text-color-lightgrey" *ngIf="getCompanyName(order$|async)"> ({{getCompanyName(order$|async)}}
          )</span>
        <span class="badge--vip rounded-pill" *ngIf="isVip(order$|async)">
        <span class="material-icons inline-icon">done</span>
        <span class="text"> VIP</span>

      </span>
        <span class="mx-3 badge rounded-pill bg-primary small" *ngIf="showPartnerLabel(order$|async)">
          <small>PARTNER</small>
        </span>
      </h1>
    </div>
    <div class="col-auto">
      <button mat-icon-button (click)="openEmailDialog()">
        <mat-icon matSuffix>mail</mat-icon>
      </button>

      <button mat-icon-button (click)="handleShowTrackingHistoryDialog()">
        <mat-icon matSuffix>local_shipping</mat-icon>
      </button>

      <span *ngIf="canBeCanceled" [matTooltip]="'Auftrag abbrechen'">
      <button mat-icon-button (click)="handleCancelOrder()">
        <mat-icon>block</mat-icon>
      </button>
    </span>

    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-action-box [order$]="order$" inputType="ticket"></app-action-box>
    </div>
    <div class="col-md-4">
      <div class="row mt-4">
        <div class="col-12">
          <app-ticket-details [order$]="order$" [ticket$]="ticket$"></app-ticket-details>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-device-details [order$]="order$"></app-device-details>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-ticket-replacement-media-card *ngIf="!isTechnicianExternal"
                                             [order$]="order$"></app-ticket-replacement-media-card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-data-recovery-result [dataRecoveryResult]="dataRecoveryResult$| async"></app-data-recovery-result>
        </div>
      </div>

    </div>
    <div class="col-md-8">
      <div class="row mt-4">
        <div class="col-12">
          <app-ticket-comments [ticket$]="ticket$"></app-ticket-comments>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-order-special-items *ngIf="!isTechnicianExternal" [order$]="order$"></app-order-special-items>
        </div>
      </div>

      <div class="row ">
        <div class="col-sm-6 mt-4">
          <app-ticket-costs-summary *ngIf="!isTechnicianExternal" [order$]="order$"></app-ticket-costs-summary>
        </div>
        <div class="col-sm-6 mt-4" *ngIf="ticket?.remoteAccess?.ip">
          <app-remote-access-details [ticket$]="ticket$"></app-remote-access-details>
        </div>
        <div class="col-sm-6 mt-4">
          <app-ticket-attachments [order$]="order$"></app-ticket-attachments>
        </div>
        <div class="col-sm-6 mt-4" *ngIf="order?.proofOfDestruction">
          <app-ticket-proof-of-destruction [order$]="order$"></app-ticket-proof-of-destruction>
        </div>
        <div class="col-sm-6 mt-4" *ngIf="order?.dataRecoveryResults?.length>0">
          <app-ticket-data-recovery-results [order$]="order$"></app-ticket-data-recovery-results>
        </div>

      </div>

    </div>

  </div>
</div>

