import {InjectionToken} from '@angular/core';
import {Params} from '@angular/router';

import {ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromAdministrators from '../../administrators/store';
import * as fromAuth from '../../auth/store';
import * as fromCustomers from '../../customers/store';
import * as fromDashboard from '../../dashboard/store';
import * as fromInvoicesModule from '../../invoices/store';
import * as fromLeads from '../../leads/store';
import * as fromMasterDataModule from '../../master-data/store';
import * as fromNotifications from '../../notifications/store';
import * as fromOrdersModule from '../../orders/store';
import * as fromShippingModule from '../../shipping/store';
import * as fromTicketsModule from '../../tickets/store';
import * as fromWarehouseModule from '../../warehouse/store';
import * as fromApplicationStateModuleReducers from './reducers';

import {ADMINISTRATORS_MODULE_FEATURE_KEY} from '../../administrators/store';
import {CUSTOMERS_MODULE_FEATURE_KEY} from '../../customers/store';
import {INVOICES_MODULE_FEATURE_KEY} from '../../invoices/store';
import {LEADS_MODULE_FEATURE_KEY} from '../../leads/store';
import {MASTER_DATA_MODULE_FEATURE_KEY} from '../../master-data/store';
import {ORDERS_MODULE_FEATURE_KEY} from '../../orders/store';
import {SHIPPING_MODULE_FEATURE_KEY} from '../../shipping/store';
import {TICKETS_MODULE_FEATURE_KEY} from '../../tickets/store';
import {WAREHOUSE_MODULE_FEATURE_KEY} from '../../warehouse/store';
import {APPLICATION_STATE_MODULE_FEATURE_KEY} from './reducers';
import {DASHBOARD_MODULE_FEATURE_KEY} from '../../dashboard/store';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface ApplicationState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  // maserDataModule: fromMasterDataModule.MasterDataModuleState
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<ApplicationState>>('Root Reducers Token', {
  factory: () => ({
    // [fromLayout.layoutFeatureKey]: fromLayout.reducer,
    [APPLICATION_STATE_MODULE_FEATURE_KEY]: fromApplicationStateModuleReducers.reducers,
    [ADMINISTRATORS_MODULE_FEATURE_KEY]: fromAdministrators.reducers,
    [CUSTOMERS_MODULE_FEATURE_KEY]: fromCustomers.reducers,
    [DASHBOARD_MODULE_FEATURE_KEY]: fromDashboard.reducers,
    auth: fromAuth.reducers,
    [LEADS_MODULE_FEATURE_KEY]: fromLeads.reducers,
    [MASTER_DATA_MODULE_FEATURE_KEY]: fromMasterDataModule.reducers,
    notifications: fromNotifications.reducers,
    [ORDERS_MODULE_FEATURE_KEY]: fromOrdersModule.reducers,
    [SHIPPING_MODULE_FEATURE_KEY]: fromShippingModule.reducers,
    [TICKETS_MODULE_FEATURE_KEY]: fromTicketsModule.reducers,
    [WAREHOUSE_MODULE_FEATURE_KEY]: fromWarehouseModule.reducers,
    [INVOICES_MODULE_FEATURE_KEY]: fromInvoicesModule.reducers,
    router: fromRouter.routerReducer
  }),
});

/*export const reducers: ActionReducerMap<ApplicationState> = {
  router: fromRouter.routerReducer,
  // customers: fromCustomers.customersReducer,
  // projects: fromProjects.projectsReducer
  // auth: fromAuthModuleStore.getAuthState
};*/

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');
// todo: getNotificationsState
// ...
