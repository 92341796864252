import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment/moment';
import {Moment} from 'moment';
import {ApplicationState} from '../../../application-state/store';
import {Store} from '@ngrx/store';
import {FollowUpDate} from '../../models/follow-up-date.interface';
import {
  isLoading,
  lastReversibleFollowUp,
  selectFollowUpsByDate,
  selectFollowUpsOpen,
  UNDELETE_TIMEOUT
} from '../../store/selectors/follow-up.selectors';
import {FollowUpActions} from '../../store';
import {Actions, ofType} from '@ngrx/effects';
import {FollowUp} from '../../models';
import {
  FollowUpCreateAndUpdateDialogComponent
} from '../follow-up-create-and-update-dialog/follow-up-create-and-update-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {SenderAddress} from "../../../leads/models/sender-address";

@Component({
  selector: 'app-follow-up-calendar',
  styleUrls: ['follow-up-calendar.component.scss'],
  templateUrl: './follow-up-calendar.component.html'
})
export class FollowUpCalendarComponent implements OnInit, OnDestroy {

  headingsMapping = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];

  public selectedWeek$: BehaviorSubject<Moment>;
  public isLoading$: Observable<boolean>;
  public remainingPercentage$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(0);
  @Output() sendFollowUps: EventEmitter<Observable<Array<FollowUp>>> = new EventEmitter<Observable<Array<FollowUp>>>();
  public openFollowUps$: Observable<Array<FollowUp>>;
  public daysOfWeek$: BehaviorSubject<
    Array<FollowUpDate>
  > = new BehaviorSubject<Array<FollowUpDate>>([]);
  revertFollowUp$: BehaviorSubject<FollowUp> = new BehaviorSubject<FollowUp>(
    null
  );
  private timer: NodeJS.Timeout;

  constructor(
    private store$: Store<ApplicationState>,
    updates$: Actions,
    public dialog: MatDialog
  ) {
    this.isLoading$ = this.store$.select(isLoading);

    updates$
      .pipe(
        ofType(
          FollowUpActions.CreateFollowUpSuccess,
          FollowUpActions.UpdateFollowUpSuccess,
          FollowUpActions.DeleteFollowUpSuccess
        )
      )
      .subscribe(() => {
        this.changeWeek(0);
      });
  }

  ngOnInit(): void {
    this.watchWeek();
    this.selectedWeek$.subscribe(this.onUpdateWeek.bind(this));
    // this.daysOfWeek$.subscribe((value) => console.log('daysOfWeeks', value));
    this.openFollowUps$ = this.store$.select(selectFollowUpsOpen);
    this.sendFollowUps.emit(this.openFollowUps$);
    this.store$.select(lastReversibleFollowUp).subscribe(value => {
      this.revertFollowUp$.next(value);
    });
    this.timer = setInterval(() => {
      if (!this.revertFollowUp$ || !this.revertFollowUp$?.value) {
        return;
      }
      const now = new Date();
      const revertFollowUp = this.revertFollowUp$?.value;
      const seconds =
        now.getTime() - new Date(revertFollowUp.deletedAt).getTime();
      this.remainingPercentage$.next(
        Math.min(100, (seconds / 1000 / UNDELETE_TIMEOUT) * 100)
      );
    }, 100);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  undelete(followUp: FollowUp): void {
    this.store$.dispatch(
      FollowUpActions.UpdateFollowUp({
        iri: extractIri(followUp),
        payload: {deletedAt: null}
      })
    );
  }

  changeWeek(amount: number): void {
    this.selectedWeek$.next(this.selectedWeek$.getValue().add(amount, 'week'));
  }

  onUpdateWeek(momentDate): void {
    const followUpDates: Array<FollowUpDate> = [];
    for (let day = 1; day <= 7; day++) {
      const startDate: Moment = momentDate
        .clone()
        .isoWeekday(day)
        .hour(0)
        .minute(0)
        .second(0);
      const endDate: Moment = momentDate
        .clone()
        .isoWeekday(day)
        .hour(23)
        .minute(59)
        .second(59);
      const newDateObj: FollowUpDate = {
        header: startDate.format('dddd (DD.MM.)'),
        startDate,
        endDate,
        dataPoints: this.store$.select(selectFollowUpsByDate, startDate),
        today: startDate.isSameOrBefore() && endDate.isSameOrAfter()
      };
      followUpDates.push(newDateObj);
    }
    this.daysOfWeek$.next(followUpDates);
  }

  selectFollowUp(followUp: FollowUp): void {
    this.dialog.open(FollowUpCreateAndUpdateDialogComponent, {
      disableClose: false,
      data: {followUp}
    });
  }

  getIsoDate(date: Date): string {
    return moment(date).toISOString(true);
  }

  drop(event: CdkDragDrop<any>): void {
    const iri = event.item.element.nativeElement.dataset.iri;
    const oldDate = event.item.element.nativeElement.dataset.date;
    const newDate = event.container.element.nativeElement.dataset.date;
    const toBeChangedFollowUp = {date: null};
    const oldTime = moment(oldDate);
    toBeChangedFollowUp.date = moment(newDate)
      .minute(oldTime.minute())
      .hour(oldTime.hour())
      .toDate();
    this.store$.dispatch(
      FollowUpActions.UpdateFollowUp({iri, payload: toBeChangedFollowUp})
    );
  }

  watchWeek(): void {
    this.selectedWeek$ = new BehaviorSubject<moment.Moment>(moment());
    this.selectedWeek$.subscribe(weekMoment => {
      const fromDate = weekMoment
        .clone()
        .isoWeekday(1)
        .hour(0)
        .minute(0)
        .second(0);
      const toDate = fromDate
        .clone()
        .add(5, 'week')
        .hour(23)
        .minute(59)
        .second(59);
      this.store$.dispatch(
        FollowUpActions.LoadFollowUps({
          fromDate: weekMoment.isSame(moment(), 'week')
            ? null
            : fromDate.toDate(),
          toDate: toDate.toDate()
        })
      );
    });
  }
}
