import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PARTNER_WEBSITE = '[Customers Module] Create Partner Website';
export const CREATE_PARTNER_WEBSITE_SUCCESS = '[Customers Module] Create Partner Website  Success';
export const CREATE_PARTNER_WEBSITE_FAIL = '[Customers Module] Create Partner Website  Fail';

export const CreatePartnerWebsite = createAction(
  CREATE_PARTNER_WEBSITE,
  props<{ payload: fromModuleModels.PartnerWebsite }>()
);

export const CreatePartnerWebsiteSuccess = createAction(
  CREATE_PARTNER_WEBSITE_SUCCESS,
  props<{ response: fromModuleModels.PartnerWebsite }>()
);

export const CreatePartnerWebsiteFail = createAction(
  CREATE_PARTNER_WEBSITE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PARTNER_WEBSITE = '[Customers Module] Read Partner Website';
export const READ_PARTNER_WEBSITE_SUCCESS = '[Customers Module] Read Partner Website  Success';
export const READ_PARTNER_WEBSITE_FAIL = '[Customers Module] Read Partner Website  Fail';

export const ReadPartnerWebsite = createAction(
  READ_PARTNER_WEBSITE,
  props<{ iri: string }>()
);

export const ReadPartnerWebsiteSuccess = createAction(
  READ_PARTNER_WEBSITE_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsiteFail = createAction(
  READ_PARTNER_WEBSITE_FAIL,
  props<{ response: any }>()
);

export const READ_PARTNER_WEBSITE_PROFILE = '[Customers Module] Read Partner Website Profile';
export const READ_PARTNER_WEBSITE_PROFILE_SUCCESS = '[Customers Module] Read Partner Website Profile Success';
export const READ_PARTNER_WEBSITE_PROFILE_FAIL = '[Customers Module] Read Partner Website Profile Fail';

export const ReadPartnerWebsiteProfile = createAction(
  READ_PARTNER_WEBSITE_PROFILE,
  props<{ iri: string }>()
);

export const ReadPartnerWebsiteProfileSuccess = createAction(
  READ_PARTNER_WEBSITE_PROFILE_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsiteProfileFail = createAction(
  READ_PARTNER_WEBSITE_PROFILE_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_PARTNER_WEBSITES = '[Customers Module] Read Partner Websites';
export const READ_PARTNER_WEBSITES_SUCCESS = '[Customers Module] Read Partner Websites Success';
export const READ_PARTNER_WEBSITES_FAIL = '[Customers Module] Read Partner Websites Fail';

export const ReadPartnerWebsites = createAction(
  READ_PARTNER_WEBSITES,
  props<{ page: number }>()
);

export const ReadPartnerWebsitesSuccess = createAction(
  READ_PARTNER_WEBSITES_SUCCESS,
  props<{ response: any }>()
);

export const ReadPartnerWebsitesFail = createAction(
  READ_PARTNER_WEBSITES_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PARTNER_WEBSITE = '[Customers Module] Update Partner Website';
export const UPDATE_PARTNER_WEBSITE_SUCCESS = '[Customers Module] Update Partner Website  Success';
export const UPDATE_PARTNER_WEBSITE_FAIL = '[Customers Module] Update Partner Website  Fail';

export const UpdatePartnerWebsite = createAction(
  UPDATE_PARTNER_WEBSITE,
  props<{ iri: string, payload: fromModuleModels.PartnerWebsite }>()
);

export const UpdatePartnerWebsiteSuccess = createAction(
  UPDATE_PARTNER_WEBSITE_SUCCESS,
  props<{ response: fromModuleModels.PartnerWebsite }>()
);

export const UpdatePartnerWebsiteFail = createAction(
  UPDATE_PARTNER_WEBSITE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_PARTNER_WEBSITE_PROFILE = '[Customers Module] Update Partner Website Profile';
export const UPDATE_PARTNER_WEBSITE_PROFILE_SUCCESS = '[Customers Module] Update Partner Website Profile Success';
export const UPDATE_PARTNER_WEBSITE_PROFILE_FAIL = '[Customers Module] Update Partner Website Profile Fail';

export const UpdatePartnerWebsiteProfile = createAction(
  UPDATE_PARTNER_WEBSITE_PROFILE,
  props<{ iri: string, payload: any }>()
);

export const UpdatePartnerWebsiteProfileSuccess = createAction(
  UPDATE_PARTNER_WEBSITE_PROFILE_SUCCESS,
  props<{ response: fromModuleModels.PartnerWebsite }>()
);

export const UpdatePartnerWebsiteProfileFail = createAction(
  UPDATE_PARTNER_WEBSITE_PROFILE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const UPDATE_PARTNER_WEBSITE_PASSWORD = '[Customers Module] Update Partner Website Password';
export const UPDATE_PARTNER_WEBSITE_PASSWORD_SUCCESS = '[Customers Module] Update Partner Website Password Success';
export const UPDATE_PARTNER_WEBSITE_PASSWORD_FAIL = '[Customers Module] Update Partner Website Password Fail';

export const UpdatePartnerWebsitePassword = createAction(
  UPDATE_PARTNER_WEBSITE_PASSWORD,
  props<{ iri: string, payload: { oldPassword: string, plainPassword: string, plainPasswordConfirmation: string } }>()
);

export const UpdatePartnerWebsitePasswordSuccess = createAction(
  UPDATE_PARTNER_WEBSITE_PASSWORD_SUCCESS,
  props<{ response: any }>()
);

export const UpdatePartnerWebsitePasswordFail = createAction(
  UPDATE_PARTNER_WEBSITE_PASSWORD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_PARTNER_WEBSITE = '[Customers Module] Delete Partner Website';
export const DELETE_PARTNER_WEBSITE_SUCCESS = '[Customers Module] Delete Partner Website  Success';
export const DELETE_PARTNER_WEBSITE_FAIL = '[Customers Module] Delete Partner Website  Fail';

export const DeletePartnerWebsite = createAction(
  DELETE_PARTNER_WEBSITE,
  props<{ iri: string }>()
);

export const DeletePartnerWebsiteSuccess = createAction(
  DELETE_PARTNER_WEBSITE_SUCCESS,
  props<{ iri: string }>()
);

export const DeletePartnerWebsiteFail = createAction(
  DELETE_PARTNER_WEBSITE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// State

export const RESET_PARTNER_WEBSITES_LOADED = '[Customers Module] Reset Partner Websites Loaded';

export const ResetPartnerWebsitesLoaded = createAction(
  RESET_PARTNER_WEBSITES_LOADED
);
