import {Component, OnDestroy, OnInit} from '@angular/core';

import {Store} from '@ngrx/store';

import {Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {LeadCommentFormDialogComponent} from "../../components";
import {ApplicationState} from '../../../application-state/store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {Go} from '../../../application-state/store/actions/router.actions';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {LeadsSearchDialogComponent} from "../../components/leads-search-dialog/leads-search-dialog.component";
import {Lead} from "../../models";

@Component({
  selector: 'app-leads-view',
  styleUrls: ['leads-view.component.scss'],
  template: `

    <view-heading heading="Anfragen" colLeft="column-2">
      <button mat-flat-button [matTooltip]="'leads.leads_view.search_leads.placeholder' | translate" [matTooltipPosition]="'left'" class="m-r--16"
              (click)="searchLeads()">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-flat-button color="accent" class="m-r--16"
              (click)="handleNavigateToLeadView('new')">
        <mat-icon>add</mat-icon>
        Neue Anfrage erstellen
      </button>
      <button mat-flat-button (click)="handleNavigateToLeadView('archive')">Archivierte Anfragen</button>
    </view-heading>
    <app-leads-list></app-leads-list>
  `
})
export class LeadsViewComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();
  leads: Lead[] = [];

  constructor(
    private store$: Store<ApplicationState>,
    private title: AbstractTitleService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Anfragen');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  searchLeads(): void {
    this.dialog.open(LeadsSearchDialogComponent, {
      width: '500px',
      height: '800px',
    });
  }


  handleNavigateToLeadView(lead: string): void {
    this.store$.dispatch(Go({path: ['leads', getUuidFromIri(lead)]}));
  }
}
