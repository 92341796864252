import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Order} from '../../models';
import {Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrdersSelectors} from '../../store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {CustomerPartnerStatusesSelectors} from '../../../customers/store/selectors';
import {CustomerPartnerStatusesActions} from '../../../customers/store';
import {CustomerPartnerStatus} from '../../../customers/models';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-order-partner-status-select',
  styleUrls: ['./order-partner-status-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrderPartnerStatusSelectComponent),
    multi: true
  }],

  template: `
    <div [formGroup]="form">

      <ng-select
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        [placeholder]="'orders.partnertype_select.placeholder' | translate"
        formControlName="selectedElement">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderPartnerStatusSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form: FormGroup;

  items$: Observable<Array<CustomerPartnerStatus>>;
  isLoading$: Observable<boolean>;
  selectedItem: CustomerPartnerStatus;

  orders: Order[];
  onDestroy$: Subject<any> = new Subject<any>();
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });

    this.items$ = this.store$.select(CustomerPartnerStatusesSelectors.selectCustomerPartnerStatuses);
    this.isLoading$ = this.store$.select(CustomerPartnerStatusesSelectors.isLoading);
    loadIfNotLoaded(this.store$, CustomerPartnerStatusesSelectors.isLoaded, CustomerPartnerStatusesActions.LoadCustomerPartnerStatuses());
    this.store$.select(OrdersSelectors.sList).subscribe((orders) => {
      this.orders = orders;
    });
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });

  }

  getOrderCount(ident: string): number {
    return this.orders.filter(e => e.partner === ident).length || -1;
  }

  findItem(term: string, item: CustomerPartnerStatus): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }


}
