import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { SalutationsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Salutation };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    SalutationsActions.CreateSalutation,
    SalutationsActions.ReadSalutation,
    SalutationsActions.ReadSalutations,
    SalutationsActions.UpdateSalutation,
    SalutationsActions.DeleteSalutation,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(SalutationsActions.ReadSalutationSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(SalutationsActions.ReadSalutationsSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.Salutation }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    SalutationsActions.CreateSalutationSuccess,
    SalutationsActions.UpdateSalutationSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(SalutationsActions.DeleteSalutationSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    SalutationsActions.CreateSalutationFail,
    SalutationsActions.ReadSalutationFail,
    SalutationsActions.ReadSalutationsFail,
    SalutationsActions.UpdateSalutationFail,
    SalutationsActions.DeleteSalutationFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
