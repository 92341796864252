import { createReducer, on } from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { DataRecoveryResultsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromOrdersModuleModels.DataRecoveryResult;
  entities: { [iri: string]: fromOrdersModuleModels.DataRecoveryResult };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  current: null,
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DataRecoveryResultsActions.ReadDataRecoveryResult,
    DataRecoveryResultsActions.UpdateDataRecoveryResult,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    DataRecoveryResultsActions.ReadDataRecoveryResultSuccess,
    DataRecoveryResultsActions.UpdateDataRecoveryResultSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    DataRecoveryResultsActions.ReadDataRecoveryResultFail,
    DataRecoveryResultsActions.UpdateDataRecoveryResultFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(DataRecoveryResultsActions.ResetDataRecoveryResultsState, () => {
    return {
      ...initialState
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
