import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, QueryList,
  ViewChildren
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {QuillEditorComponent} from 'ngx-quill';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromAdministratorsModuleModels from '../../../administrators/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {Order, OrderComment, OrderCommentTag} from '../../models';
import {Administrator} from '../../../administrators/models';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {AdministratorsActions} from '../../../administrators/store';
import {OrderCommentTagsSelectors} from '../../store/selectors';
import {OrderCommentsActions, OrderCommentTagsActions} from '../../store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import * as OrdersModuleActions from '../../store';
import {Actions, ofType} from '@ngrx/effects';
import {LeadsActions} from '../../../leads/store';

@Component({
  selector: 'app-order-comment-form',
  styleUrls: ['order-comment-form.component.scss'],
  template: `

    <div class="order__comment-form" [class.in-modal]="comment$?.getValue()" *ngIf="order">

      <form [formGroup]="ocf">

        <div class="filter grid grid-no-gutter">

          <div class="column-7">
            <span *ngFor="let tag of orderCommentTags" class="filter__item">

              <button class="filter__button"
                      mat-button
                      color="outline"
                      [disableRipple]="true"
                      [class.selected]="getCommentTagValue == tag['@id']"
                      (click)="toggleCommentTag(tag['@id'])"
              >{{ tag.commentTag }}</button>
            </span>
          </div>
          <div class="column-7 m-ta--2">
            <span class="filter__item">
              <button class="filter__button"
                      mat-button
                      color="outline"
                      [disableRipple]="true"
                      [class.selected]="additionalInputVisible === 'recipient'"
                      (click)="toggleAdditionalInputVisible('recipient')"
              >Rückfrage</button>
            </span>

            <span class="filter__item">
              <button class="filter__button"
                      mat-button
                      color="outline"
                      [disableRipple]="true"
                      [class.selected]="additionalInputVisible === 'additionalContent'"
                      (click)="toggleAdditionalInputVisible('additionalContent')"
              >Kundenkommentar</button>
            </span>
          </div>
        </div>
        <app-text-editor formControlName="content" [placeholder]="getRecipientValue"></app-text-editor>
        <div class="grid" *ngIf="additionalInputVisible === 'recipient'">

          <div class="column-6 off-8 m-t--16">
            <ng-select placeholder="Rückfrage richten an ..."
                       [items]="administrators$ | async"
                       bindValue="@id"
                       appendTo="body"
                       bindLabel="username"
                       [searchable]="false"
                       formControlName="recipient">

              <ng-template ng-label-tmp let-administrator="item">
                <span style="">{{ getAdministratorName(administrator) }}</span>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="ng-option">
                  <span style="">{{ getAdministratorName(item) }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div *ngIf="additionalInputVisible === 'additionalContent'" style="margin-top: 25px">

          <quill-editor formControlName="additionalContent">
            <div quill-editor-toolbar>

              <span class="ql-heading">Kundenkommentar</span>
              <span class="ql-formats">
                <button type="button" (click)="handleTextColorChange('#db0000', 'additionalContent', 1)"
                        class="preselect red"><mat-icon>opacity</mat-icon></button>
                <button type="button" (click)="handleTextColorChange('#45a452', 'additionalContent', 1)"
                        class="preselect green"><mat-icon>opacity</mat-icon></button>
                <button type="button" class="ql-bold"></button>
                <button type="button" class="ql-italic"></button>
                <button type="button" class="ql-underline"></button>
                <button type="button" class="ql-strike"></button>
              </span>
            </div>
          </quill-editor>
        </div>

        <div class="m-ta--2 m-t--24">
          <button
            mat-flat-button color="green" type="button"
            [disabled]="ocf.invalid"
            (click)="handleSubmit()">{{ !!comment$?.getValue() ? 'Aktualisieren' : 'Kommentar anlegen' }}</button>
        </div>
      </form>
    </div>

    <!--<pre>{{ ocf.value | json }}</pre>-->
    <!--<pre>{{ comment$ |async| json }}</pre>-->
    <!--<pre>{{ ocf.get('content').value | json }}</pre>-->
    <!--<pre>{{ ocf.invalid | json }}</pre>-->
  `
})
export class OrderCommentFormComponent implements OnInit, OnDestroy {

  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  orderCommentTags: Array<OrderCommentTag>;

  @Input() order$: Observable<Order>;
  order: Order;

  @Input() comment$: BehaviorSubject<OrderComment>;

  additionalInputVisible: string;
  additionalInputsChoices: Array<string> = ['additionalContent', 'recipient'];
  administrators$: Observable<Array<Administrator>>;
  administratorsEntities: { [key: string]: Administrator };
  onDestroy$: Subject<any> = new Subject<any>();
  ocf: FormGroup;


  constructor(private cd: ChangeDetectorRef,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private store$: Store<ApplicationState>,
              private actions$: Actions,) {
  }

  ngOnInit(): void {
    this.initForm();
    this.ocf.markAsUntouched();

    this.initSubscriptions();
    this.loadAdministrators();
    this.loadOrderCommentTags();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe((order) => {
        this.order = order;
        this.ocf.get('order').setValue(this.order['@id']);
      });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  get getCommentTagValue(): string | null {
    return this.ocf.get('commentTag').value;
  }

  get getRecipientValue(): string | null {

    const inputValue = this.ocf.get('recipient').value;
    const admin = this.administratorsEntities[inputValue];
    const fullName = `${admin?.firstName} ${admin?.lastName}`;
    return inputValue ? fullName : 'Eigener Kommentar';
  }

  initSubscriptions(): void {
    this.actions$.pipe(
      ofType(OrderCommentsActions.CreateOrderCommentSuccess),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.ocf.patchValue({
        commentTag: null,
        content: null,
        additionalContent: null,
        recipient: null,
      });
    });

    if (!this.comment$) {
      return;
    }
    this.comment$.pipe(
      takeUntil(this.onDestroy$),
      filter(comment => !!comment)
    ).subscribe((comment: OrderComment) => {
      this.restoreFormState(comment);
      this.ocf.markAsUntouched();
    });


  }

  initForm(): void {
    this.ocf = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      commentTag: this.fb.control(null),
      content: this.fb.control(null, [Validators.required, Validators.minLength(2)]),
      additionalContent: this.fb.control(null),
      recipient: this.fb.control(null)
    });

    this.additionalInputVisible = null;
  }

  restoreFormState(comment: OrderComment): void {

    Object.keys(comment).forEach(key => {

      const formInput = this.ocf.get(key);

      // check if both default value and formInput for given key exist
      if (formInput && !!comment[key]) {
        formInput.setValue(comment[key]);
        formInput.markAsTouched();

        if (key === 'recipient') {
          this.toggleAdditionalInputVisible('recipient');
          formInput.setValidators([Validators.required]);
        }

        if (key === 'additionalContent') {
          this.toggleAdditionalInputVisible('additionalContent');
          formInput.setValidators([Validators.required]);
        }
      }
    });
  }

  handleTextColorChange(color: string, propertyName: string, editorIndex: number): void {

    const editor = this.editors.toArray()[editorIndex].quillEditor;

    const defaultColor = '#000000';
    const {index, length} = editor.getSelection();
    const {color: currentColor} = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(
      index,
      length,
      {color: (currentColor === color) ? defaultColor : color}
    );

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.ocf.patchValue({[propertyName]: editorHTML});
  }

  toggleCommentTag(iri: string): void {
    this.ocf.patchValue({commentTag: (this.getCommentTagValue == iri) ? null : iri});
  }

  // todo: side effects
  toggleAdditionalInputVisible(to: string): void {

    const currentValue = String(this.additionalInputVisible);
    const formField = this.ocf.get(currentValue);
    const informationLoss = this.additionalInputsChoices.includes(currentValue) && formField.dirty && (formField.value != '');

    console.group();
    console.log(this.additionalInputsChoices);
    console.log(this.additionalInputVisible);
    console.log(currentValue);
    console.log(formField);
    console.log(informationLoss);
    console.groupEnd();

    if (informationLoss) {

      this.dialog.open(DialogComponent, {
        data: {
          heading: 'Achtung',
          text: 'Hierdurch geht eingegebener Text bzw. die Auswahl verloren. Fortfahren?',
          confirmationText: 'Weiter',
          cancelText: 'Abbrechen',
          disableClose: true,
        }
      }).afterClosed().pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        (hasConfirmed: boolean) => {

          if (!!hasConfirmed) {

            this.additionalInputVisible = (currentValue === to) ? undefined : to;
            formField.patchValue(null);
            formField.markAsPristine();
          }
        }
      );
    } else {
      this.additionalInputVisible = (currentValue === to) ? undefined : to;
    }
  }

  handleSubmit(): void {
    const payload: OrderComment = this.ocf.value;

    this.comment$?.getValue()
      ? this.handleUpdateOrderComment({iri: this.comment$.getValue()['@id'], payload})
      : this.handleCreateOrderComment(payload);
  }

  handleCreateOrderComment(comment: OrderComment): void {
    this.store$.dispatch(OrdersModuleActions.OrderCommentsActions.CreateOrderComment({payload: comment}));
  }

  handleUpdateOrderComment(payload: { iri: string, payload: OrderComment }): void {
    this.store$.dispatch(OrdersModuleActions.OrderCommentsActions.UpdateOrderComment(payload));
  }

  getAdministratorName(admin: fromAdministratorsModuleModels.Administrator): string {
    return `${admin.firstName} ${admin.lastName}`;
  }

  private loadAdministrators(): void {
    this.store$.select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.administratorsEntities = entities;
      });
    this.administrators$ = this.store$.select(AdministratorsSelectors.selectAdministratorsWithoutLoggedInUser);
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());

  }

  private loadOrderCommentTags(): void {
    this.store$.select(OrderCommentTagsSelectors.selectOrderCommentTags)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.orderCommentTags = entities;
      });
    loadIfNotLoaded(this.store$, OrderCommentTagsSelectors.isLoaded, OrderCommentTagsActions.ReadOrderCommentTags());

  }

}
