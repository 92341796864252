import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import {LocalStorageService} from "../../../shared/services";
import * as moment from "moment/moment";

@Component({
  selector: 'app-orders-filter-form',
  styleUrls: ['orders-filter-form.component.scss'],
  template: `
    <div class="orders-filter__outer mb-3" *ngIf="searchBoxVisible$ | async">
      <div class="orders-filter__wrapper">
        <div class="orders-filter pt-2" [formGroup]="form">
          <div class="container-fluid">
            <div class="row orders-filter-container">
              <div class="col-8">
                <div class="row">
                  <div class="col-4">
                    <app-order-status-select
                      fieldName="state"
                      [formGroup]="form"
                    ></app-order-status-select>
                  </div>
                  <div class="col-3">
                    <app-order-staterange-select
                      formControlName="stateRange"
                    ></app-order-staterange-select>
                  </div>
                  <div class="col-2">
                    <div class="mat-form-field">
                      <ng-select
                        [items]="analysisOptions"
                        bindLabel="name"
                        [searchable]="false"
                        bindValue="value"
                        [clearable]="true"
                        [placeholder]="'Priorität Analyse'"
                        formControlName="dataRecoveryPriorityMode"
                      >
                      </ng-select>
                    </div>
                  </div>
              </div>
                <div class="row">
                  <div class="col-2">
                    <app-order-analysis-location-select
                      formControlName="analysisLocation"
                    ></app-order-analysis-location-select>
                  </div>
                  <div class="col-2">
                    <app-order-data-recovery-location-select
                      formControlName="dataRecoveryLocation"
                    ></app-order-data-recovery-location-select>
                  </div>
                  <div class="col-3">
                    <app-order-partner-status-select
                      formControlName="partnerStatus"
                    ></app-order-partner-status-select>
                  </div>
                  <div class="col-3">
                    <app-order-cooperation-select
                      fieldName="customerCooperation"
                      [formGroup]="form"
                    ></app-order-cooperation-select>
                  </div>
<!--                  <div class="col-2">-->
<!--                    <app-order-technician-select-->
<!--                      fieldName="technician"-->
<!--                      [formGroup]="form"-->
<!--                    ></app-order-technician-select>-->
<!--                  </div>-->
                  <div class="col-2">
                    <div class="mat-form-field">
                      <ng-select
                        [items]="brokerOptions"
                        bindLabel="name"
                        [searchable]="false"
                        bindValue="value"
                        [clearable]="true"
                        [placeholder]="'orders.broker_exists.placeholder' | translate"
                        formControlName="brokerExists"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-2" *ngIf="!!form.get('brokerExists').value">
                    <app-broker-select
                      fieldName="broker"
                      [formGroup]="form"
                    ></app-broker-select>
                  </div>
                </div>
            </div>

              <div class="col-3">
                <div class="row">
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>{{'shared.price_min_input.label' | translate}}</mat-label>
                      <input
                        type="string"
                        currencyMask
                        matInput
                        formControlName="priceMin"
                        [options]="{ prefix: '', suffix: ' €', allowNegative: true }"
                      />
                      <mat-error>
                        <app-form-error
                          fieldName="priceMin"
                          [formGroup]="form"
                        ></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>{{'shared.price_max_input.label' | translate}}</mat-label>
                      <input
                        type="string"
                        currencyMask
                        matInput
                        formControlName="priceMax"
                        [options]="{ prefix: '', suffix: ' €', allowNegative: true }"
                      />
                      <mat-error>
                        <app-form-error
                          fieldName="priceMax"
                          [formGroup]="form"
                        ></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>{{"shared.order_created_date_from_input.label" | translate}}</mat-label>
                      <input
                        type="date"
                        matInput
                        formControlName="createdAtMin"
                      />
                      <mat-error>
                        <app-form-error
                          fieldName="createdAtMin"
                          [formGroup]="form"
                        ></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>{{"shared.order_created_date_to_input.label" | translate}}</mat-label>
                      <input
                        type="date"
                        matInput
                        formControlName="createdAtMax"
                      />
                      <mat-error>
                        <app-form-error
                          fieldName="createdAtMax"
                          [formGroup]="form"
                        ></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  `
})
export class OrdersFilterFormComponent implements OnInit, OnDestroy {
  @Input() searchBoxVisible$: BehaviorSubject<boolean>;

  @Output() requestHandleAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateFilter: EventEmitter<{
    [key: string]: string;
  }> = new EventEmitter<{ [key: string]: string }>();
  form: FormGroup;
  storedFilters?: any;

  analysisOptions = [
    {
      name: 'STANDARD',
      value: '0'
    },
    {
      name: 'EXPRESS',
      value: '1'
    }
  ];

  brokerOptions = [
    {
      name: 'Alle',
      value: 'null'
    },
    {
      name: 'Ja',
      value: true
    },
    {
      name: 'Nein',
      value: false
    }
  ];

  onDestroy$: Subject<any> = new Subject<any>();

  // verkäufer       - salesperson.uuid
  // auftragsstatus  - status
  // partnerStatus      - customer.partnerStatus
  // kooperationen   - customer.customerCooperations.id
  // dienstleister   - dataRecoveryLocation
  // techniker       - technician.uuid
  // vermittler      - broker.uuid
  constructor(private fb: FormBuilder, public localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges.subscribe(value => {
      if (!!value?.createdAtMin) {
        value.createdAtMin = moment(
          value.createdAtMin as string,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');
      }

      if (!!value?.createdAtMax) {
        value.createdAtMax = moment(
          value.createdAtMax as string,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');
      }

      this.updateFilter.emit(removeEmptyFormElements(value));
      this.localStorageService.overwriteObjectByKey('filters', 'orders', value);
    });
    this.searchBoxVisible$.subscribe(visible => {
      if (!visible) {
        this.form.reset();
      }
    });
    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'orders');
    if(this.storedFilters) {
      this.requestHandleAction.emit(this.storedFilters);
      this.form.patchValue(this.storedFilters);
    }
  }
private initForm() {
  this.form = this.fb.group({
    state: this.fb.control(null),
    broker: this.fb.control(null),
    priceMin: this.fb.control(null),
    priceMax: this.fb.control(null),
    technician: this.fb.control(null),
    stateRange: this.fb.control(null),
    brokerExists: this.fb.control(null),
    salesperson: this.fb.control(null),
    partnerStatus: this.fb.control(null),
    createdAtMin: this.fb.control(null),
    createdAtMax: this.fb.control(null),
    analysisLocation: this.fb.control(null),
    dataRecoveryLocation: this.fb.control(null),
    customerCooperation: this.fb.control(null),
    dataRecoveryPriorityMode: this.fb.control(null)
    // dataRecoveryLocation: this.fb.control(null),
  });
}
  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
