import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {GenericOrderItemsActions, ServiceOrderItemsActions} from '../actions';
import {GenericOrderItemsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class GenericOrderItemsEffects {

  constructor(private actions$: Actions, private service: GenericOrderItemsService, private notifierService: NotifierService) {
  }


  createGenericOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(GenericOrderItemsActions.CreateGenericOrderItem),
    switchMap(({payload}) => {

      return this.service
        .createGenericOrderItem(payload)
        .pipe(
          map((response: any) => GenericOrderItemsActions.CreateGenericOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(GenericOrderItemsActions.CreateGenericOrderItemFail({response})))
        );
    })
  ));


  readGenericOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(GenericOrderItemsActions.ReadGenericOrderItem),
    switchMap(({iri}) => {

      return this.service
        .readGenericOrderItem(iri)
        .pipe(
          map((response: any) => GenericOrderItemsActions.ReadGenericOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(GenericOrderItemsActions.ReadGenericOrderItemFail({response})))
        );
    })
  ));


  readGenericOrderItems$ = createEffect(() => this.actions$.pipe(
    ofType(GenericOrderItemsActions.ReadGenericOrderItems),
    switchMap(({orderUuid}) => {

      return this.service
        .readGenericOrderItems(orderUuid)
        .pipe(
          map((response: any) => GenericOrderItemsActions.ReadGenericOrderItemsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(GenericOrderItemsActions.ReadGenericOrderItemsFail({response})))
        );
    })
  ));


  updateGenericOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(GenericOrderItemsActions.UpdateGenericOrderItem),
    switchMap(({iri, payload}) => {

      return this.service
        .updateGenericOrderItem(iri, payload)
        .pipe(
          map((response: any) => GenericOrderItemsActions.UpdateGenericOrderItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(GenericOrderItemsActions.UpdateGenericOrderItemFail({response})))
        );
    })
  ));


  deleteGenericOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(GenericOrderItemsActions.DeleteGenericOrderItem),
    switchMap(({iri}) => {

      return this.service
        .deleteGenericOrderItem(iri)
        .pipe(
          map(() => GenericOrderItemsActions.DeleteGenericOrderItemSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(GenericOrderItemsActions.DeleteGenericOrderItemFail({response})))
        );
    })
  ));

  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      GenericOrderItemsActions.CreateGenericOrderItemSuccess,
      GenericOrderItemsActions.UpdateGenericOrderItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case GenericOrderItemsActions.CREATE_GENERIC_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde angelegt.';
          break;
        case GenericOrderItemsActions.UPDATE_GENERIC_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde aktualisiert.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

}
