import { ShipmentDirectionsEffects } from './shipment-directions.effects';
import { ShipmentServicesEffects } from './shipment-services.effects';
import { ShipmentsEffects } from './shipments.effects';
import { ShipmentAdditionalInsurancesEffects } from './shipment-additional-insurances.effects';
import { ShippingProvidersEffects } from './shipping-providers.effects';

export const effects = [
  ShipmentDirectionsEffects,
  ShipmentServicesEffects,
  ShipmentsEffects,
  ShippingProvidersEffects,
  ShipmentAdditionalInsurancesEffects
];
