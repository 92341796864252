import { Injectable } from '@angular/core';

import { EMPTY, forkJoin, Observable } from 'rxjs';

import { AbstractApiService } from '../../shared/services';
import { CustomerComment } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerCommentsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomerComment(payload): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_COMMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject('/customer_comments', payload)
    );
  }

  readCustomerComments(
    orderUuid: string,
    page = 1,
    itemsPerPage = 5,
    order: 'asc' | 'desc' = 'desc'
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_COMMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/customer_comments?page=${page}&itemsPerPage=${itemsPerPage}&customer.uuid=${orderUuid}&order[id]=${order}`
            )
    );
  }

  readOrderCommentChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_COMMENT_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  /*getCustomerComments(customerUuid: string): Observable<any> {
    return this.apiService.getObject(`/customer_comments?customer.uuid=${customerUuid}`);
  }*/

  getCustomerCommentsSelection(
    customerCommentUuids: Array<string>
  ): Observable<any> {
    return forkJoin(
      customerCommentUuids.map(iri => this.apiService.getObject(iri, true))
    );
  }

  updateCustomerComment(
    iri: string,
    payload: CustomerComment
  ): Observable<CustomerComment> {
    return this.rolesService.userHasRoleFilter<CustomerComment>(
      'ROLE_MWS_CUSTOMER_COMMENT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomerComment(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<CustomerComment>(
      'ROLE_MWS_CUSTOMER_COMMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
