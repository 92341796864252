<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header ps-1">
      <span>
        <button (click)="loadData()" mat-icon-button><mat-icon>autorenew</mat-icon></button>
        Aktuell Offene Rechnungen</span>
      <div class="spacer"></div>
      <button [matMenuTriggerFor]="menu" aria-label="Rechnungsarten festlegen"
              mat-icon-button
              matTooltip="Rechnungsarten festlegen"
              matTooltipPosition="above">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="toggleSelectedStates(state)" *ngFor="let state of stateMap|keys;" mat-menu-item>
          <mat-icon>{{ ((params$|async).state| contains:state) ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
          <span>{{ state| i18nSelect: stateMap }}</span>
        </button>
      </mat-menu>
    </div>
    <div class="widget_content" style="position: relative;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <table class="bmo-table bmo-table-hover table-dense bmo-table-clickable">
        <tbody>
        <tr *ngFor="let invoice of payableInvoices$|async">
          <td class="p-l--16" style="width: 50px; position: relative;">

            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <span *ngIf="invoice.express"
                  class="badge--express"><span>Express</span></span>
            <div [ngClass]="getCircleColor(invoice)" class="circle"></div>
          </td>
          <td class="pos-relative" style="width: 80px;">

            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <span [class.orange]="!invoice.invoiceNumber">
              {{ invoice.invoiceNumber ? invoice.invoiceNumber : '-' }}
            </span>
          </td>
          <td class="pos-relative">
            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <span *ngIf="invoice.vip" class="badge--vip inline small">VIP</span>
            <span> {{ invoice.recipient.nameLine1 }} </span>
            <span *ngIf="invoice.recipient.nameLine2"> [{{ invoice.recipient.nameLine2 }}] </span>
          </td>
          <td class="pos-relative" style="width: 100px;">
            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <span> {{ invoice.state| i18nSelect: stateMap }} </span>
          </td>
          <!--<td>
            <pre>{{invoice|json}}</pre>
          </td>-->
          <td class="pos-relative" style="width: 170px;">
            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <span
              [matTooltip]="invoice.dueDate|date:'dd.MM.YYYY'"
              matTooltipPosition="above"
            >{{ invoice.dueDate|momentDuration }}
            </span>
          </td>
          <td class="p-r--8 pos-relative" style="width: 30px;">
            <a
              [routerLink]="[
                      '/invoices',
                      'payable',
                      extractTypeByIri(invoice),
                      extractUUID(invoice)
                    ]"
              class="stretched-link"
            ></a>
            <mat-icon *ngIf="invoice.state === 'dept_collection' " style="color:#ffc400;">report_problem</mat-icon>
          </td>

        </tr>
        <tr *ngIf="(payableInvoices$|async).length<=0">
          <td class="text-center">
            <span>Keine Rechnungen vorhanden</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    <div class="widget_footer">
      <app-paginator-unstyled
        (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
        [itemsPerPage]="'Rechnungen pro Seite'"
        [pageSizeOptions]="[5,10,15,20]"
        [pageSize]="(params$|async).itemsPerPage"
        [showFirstLastButtons]="true"
        [totalItems]="totalItems$ | async"
      ></app-paginator-unstyled>

    </div>

  </div>
</div>
