import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-paginator-table',
  styleUrls: ['./paginator-table.component.scss'],
  template: `
    <mat-paginator
      class="rounded-bottom"
      style="border: thin solid grey; border-top:none;"
      [length]="totalItems"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      (page)="handleRequestPaginationChange($event)"
      [showFirstLastButtons]="showFirstLastButtons"
    ></mat-paginator>
  `
})
export class PaginatorTableComponent extends MatPaginatorIntl implements AfterViewInit, OnInit, OnChanges {
  firstPageLabel = ' Erste Seite';
  nextPageLabel = ' Nächste Seite';
  previousPageLabel = ' Vorherige Seite';
  lastPageLabel = ' Letzte Seite';
  itemsPerPageLabel = ' Zeilen pro Seite';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() totalItems: number;
  @Input() pageSize = 5;
  @Input() pageSizeOptions: Array<number>;
  @Input() showFirstLastButtons: boolean;

  @Output() handleUpdatePageOrSize: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  onDestroy$: Subject<any> = new Subject<any>();
  previousPageSize: number;

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `Keine Seiten: ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `Seite ${page + 1}: ${startIndex + 1} - ${endIndex} von ${length}`;
  }

  ngOnInit(): void {
    // this.changes.subscribe(() => {
    // console.log('changes', this.itemsPerPageLabel);
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showFirstLastButtons) {
      this.showFirstLastButtons = changes.showFirstLastButtons.currentValue !== undefined;
    }
    if (changes.itemsPerPage) {
      this.itemsPerPageLabel = changes.itemsPerPage.currentValue;
    }
    this.changes.next();
  }


  handleRequestPaginationChange(event: PageEvent): void {
    this.handleUpdatePageOrSize.emit(event);
  }

  ngAfterViewInit(): void {
    this.previousPageSize = this.pageSize;
  }
}
