import {createSelector} from '@ngrx/store';

import {PRODUCT_UNITS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectProductUnitsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[PRODUCT_UNITS_FEATURE_KEY]
);

export const selectProductUnitsEntities = createSelector(
  selectProductUnitsState,
  (state) => state.entities
);

export const selectProductUnits = createSelector(
  selectProductUnitsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectProductUnitsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectProductUnitsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectProductUnitsState,
  (state) => state.loaded
);
