import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { GradesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Grade };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    GradesActions.CreateGrade,
    GradesActions.ReadGrade,
    GradesActions.ReadGrades,
    GradesActions.UpdateGrade,
    GradesActions.DeleteGrade,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(GradesActions.ReadGradeSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(GradesActions.ReadGradesSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.Grade }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    GradesActions.CreateGradeSuccess,
    GradesActions.UpdateGradeSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(GradesActions.DeleteGradeSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    GradesActions.CreateGradeFail,
    GradesActions.ReadGradeFail,
    GradesActions.ReadGradesFail,
    GradesActions.UpdateGradeFail,
    GradesActions.DeleteGradeFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
