import {createSelector} from '@ngrx/store';

import {selectApplicationStateModuleState, TRANSITIONS_FEATURE_KEY} from '../reducers';


export const selectTransitionsState = createSelector(
  selectApplicationStateModuleState,
  (moduleState) => moduleState[TRANSITIONS_FEATURE_KEY]
);

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectTransitionsErrors = createSelector(
  selectTransitionsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectTransitionsState,
  (state) => state.loading
);
