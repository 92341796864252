import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Lead, LeadContactType } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  LeadContactTypesSelectors,
  LeadsSelectors
} from '../../store/selectors';
import { LeadContactTypesActions, LeadsActions } from '../../store';
import { PageEvent } from '@angular/material/paginator';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {
  AnalysisPriorityModesSelectors,
  GradesSelectors
} from '../../../master-data/store/selectors';
import {
  AnalysisPriorityModesActions,
  GradesActions
} from '../../../master-data/store';
import { AnalysisPriorityMode, Grade } from '../../../master-data/models';
import { CustomersSelectors } from '../../../customers/store/selectors';
import { Customer } from '../../../customers/models';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { extractUUID } from '../../../shared/utilities/objects.utility';
import { AuthService } from '../../../auth/services/auth.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-leads-widget',
  templateUrl: './new-leads-widget.component.html',
  styleUrls: ['./new-leads-widget.component.scss']
})
export class NewLeadsWidgetComponent implements OnInit, OnDestroy {
  lowValue = 0;
  highValue = 5;
  isLoading$: Observable<boolean>;
  leads$: Observable<Array<Lead>>;
  onDestroy$: Subject<any> = new Subject<any>();
  selectedFilter$ = new FormControl('');

  protected readonly extractUUID = extractUUID;
  private leadContactTypes: { [iri: string]: LeadContactType };
  private customers: { [iri: string]: Customer };
  private grades: Array<Grade>;
  private analysisPriorityModesEntities: {
    [iri: string]: AnalysisPriorityMode;
  };

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService
  ) {}

  get isPartnerManagement$(): Observable<boolean> {
    return this.authService.isPartnerManagement$;
  }

  get isSales$(): Observable<boolean> {
    return this.authService.isSales$;
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(LeadsSelectors.isLoading),
      this.store$.select(CustomersSelectors.isLoading),
      this.store$.select(LeadContactTypesSelectors.isLoading)
    ]);
    this.leads$ = combineLatest([
      this.store$.select(LeadsSelectors.selectNewLeadsOfColumnOne),
      this.selectedFilter$.valueChanges.pipe(startWith('all'))
    ]).pipe(
      takeUntil(this.onDestroy$),
      map(([entities, selectedFilter]): Lead[] =>
        entities
          .filter(e => {
            console.log(e, selectedFilter);
            if (selectedFilter === 'all') {
              return true;
            } else if (selectedFilter === 'brokeredToPartner') {
              return e.brokeredToPartner === true;
            } else if (selectedFilter === 'partner') {
              return (
                e.brokeredToPartner === false &&
                // @ts-ignore
                (e.broker?.partnerStatus ===
                  '/api/customer_partner_statuses/1' ||
                  // @ts-ignore
                  e.broker?.partnerStatus ===
                    '/api/customer_partner_statuses/2')
              );
            }
          })
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
      )
    );
    this.store$.dispatch(
      LeadsActions.ReadLeads({ page: -1, params: { column: 0 } })
    );
    this.loadLeadContactType();
    this.loadGrades();
    this.loadAnalysisPriorityModes();
    this.selectedFilter$.setValue('all', { emitEvent: true });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  getCircleColor(lead: Lead): string {
    return this.getLeadContactType(lead)?.backgroundColor;
  }

  isExpress(lead: Lead): boolean {
    const mode = this.getAnalysisPriorityModes(lead);
    return mode?.name === 'EXPRESS';
  }

  getCustomerName(lead: Lead): string {
    let result = '';
    if (!lead.customer) {
      if (lead.grade && this.grades) {
        const grade = this.grades.find(e => e['@id'] === lead.grade);
        result += (grade?.name || '') + ' ';
      }
      if (lead.firstName) {
        result += lead.firstName;
      }
      if (lead.lastName) {
        result += ' ' + lead.lastName;
      }
      if (result === '') {
        result = '-';
      }
      return result;
    }
    if (lead.customer && typeof lead.customer !== 'string') {
      return (
        lead.customer?.defaultDecisionMakerCustomerContact?.firstName +
        ' ' +
        lead.customer?.defaultDecisionMakerCustomerContact?.lastName
      );
    }
  }

  getCompanyName(lead: Lead): string {
    if (lead.nameLine1 && lead.nameLine1 !== '') {
      return lead.nameLine1;
    }
    if (typeof lead.customer !== 'string') {
      return lead.customer?.nameLine1;
    }
    return '-';
  }

  private getLeadContactType(lead: Lead): LeadContactType {
    return this.leadContactTypes
      ? this.leadContactTypes[lead.leadContactType]
      : null;
  }

  private getAnalysisPriorityModes(lead: Lead): AnalysisPriorityMode {
    return this.analysisPriorityModesEntities
      ? this.analysisPriorityModesEntities[lead.analysisPriorityMode]
      : null;
  }

  private loadLeadContactType(): void {
    loadIfNotLoaded(
      this.store$,
      LeadContactTypesSelectors.isLoaded,
      LeadContactTypesActions.LoadLeadContactTypes()
    );
    this.store$
      .select(LeadContactTypesSelectors.selectLeadContactTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(leadContactTypesEntities => {
        this.leadContactTypes = leadContactTypesEntities;
      });
  }

  private loadAnalysisPriorityModes(): void {
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
    this.store$
      .select(
        AnalysisPriorityModesSelectors.selectAnalysisPriorityModesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(analysisPriorityModesEntities => {
        this.analysisPriorityModesEntities = analysisPriorityModesEntities;
      });
  }

  private loadGrades(): void {
    loadIfNotLoaded(
      this.store$,
      GradesSelectors.isLoaded,
      GradesActions.ReadGrades()
    );
    this.store$
      .select(GradesSelectors.selectGrades)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(grades => {
        this.grades = grades;
      });
  }
}
