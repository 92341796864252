import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import * as fromOrdersModuleModels from '../../models';
import { BindingOrder, Order } from '../../models';
import { BindingOrdersService, OrdersService } from '../../services';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BindingOrderDialogComponent } from '../binding-order-dialog/binding-order-dialog.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { BindingOrdersActions, OrdersActions } from '../../store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { BindingOrdersSelectors, OrdersSelectors } from '../../store/selectors';
import { Actions } from '@ngrx/effects';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { FileInputComponent } from '../../../shared/components/file-input/file-input.component';

@Component({
  selector: 'app-binding-order-tool',
  styleUrls: ['binding-order-tool.component.scss'],
  template: `
    <div class="card">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div
        class="card__heading {{
          downPaymentDelayed()
            ? 'down-payment-delayed'
            : order?.bindingOrder
            ? 'bo-confirmed'
            : ''
        }}"
      >
        <span class="col-8">Analyse und verbindliche Bestellung</span>
        <span
          *ngIf="order?.downPayment > 0"
          class="col-4 text-right pe-2 percent"
        >
          <strong>{{ order.downPayment | number }} % &euro;</strong>
        </span>
      </div>

      <div class="card__content">
        <div
          class="order-special-agreements-wrapper"
          *ngIf="
            order?.orderSpecialAgreements &&
            order.orderSpecialAgreements.length > 0
          "
        >
          <span
            class="badge rounded-pill order-special-agreement my-1 mx-2"
            *ngFor="let item of order.orderSpecialAgreements"
          >
            <mat-icon class="m-r--8">done</mat-icon>
            {{ item.specialAgreement.name }}</span
          >
        </div>

        <p
          *ngIf="
            !order ||
            !order.bindingOrderSentDates ||
            order.bindingOrderSentDates.length <= 0
          "
        >
          Bisher wurde noch keine verbindliche Bestellung verschickt.
        </p>
        <div *ngIf="order?.bindingOrderSentDates?.length > 0" class="mt-2">
          <p *ngFor="let date of order.bindingOrderSentDates">
            Analyse und verbindliche Bestellung verschickt am
            <strong>{{ date | momentDateWithTime }}</strong>
          </p>
        </div>
        <p *ngIf="order?.bindingOrder && bindingOrder">
          Bestätigung der Verbindlichen Bestellung vom:
          {{ bindingOrder.createdAt | momentDate }}
        </p>

        <!-- color="{{ requiresAnalysis() ? 'orange' : 'green' }}" -->
        <button
          mat-flat-button
          color="green"
          class="m-r--8"
          (click)="showDialog()"
        >
          <mat-icon class="m-r--8">shopping_basket</mat-icon>
          <span>{{ !isValid() ? 'Erstellen' : 'Bearbeiten' }}</span>
        </button>
        <span
          [matTooltip]="
            !canBeSend() ? 'Auftrag nicht in richtigem Zustand!' : ''
          "
        >
          <button
            mat-flat-button
            [disabled]="!canBeSend()"
            color="{{ wasSend() ? 'orange' : 'green' }}"
            (click)="handleSendBindingOrder()"
            *ngIf="isValid()"
          >
            <mat-icon class="m-r&#45;&#45;8">mail</mat-icon>
            <span>E-Mail versenden</span>
          </button>
        </span>

        <div
          *ngIf="showBindingOrderUpload"
          [formGroup]="bindingOrderForm"
          class="inline-form my-3"
        >
          <div style="display: none"></div>

          <div class="row text-center" [class.hidden]="!uploadDone">
            <p>Upload erfolgreich!</p>
          </div>
          <div class="row" [class.hidden]="uploadDone">
            <div class="col-4 pt-3 ps-4">
              <mat-form-field>
                <mat-label>Bestätigungsdatum</mat-label>
                <input type="date" matInput formControlName="date" required />
              </mat-form-field>
            </div>
            <div class="col-8 pt-3 text-center pe-4">
              <app-file-input
                actionNameCreate="createBindingOrderEntity"
                [allowDeletion]="false"
                propertyName="bindingOrder"
                [additionalPayload]="{
                  order: getUUID(extractIri(order)),
                  createdAt: bindingOrderForm.get('date').value
                }"
                #fileInput
                maxFiles="1"
                [service]="bos"
                [entity$]="order$"
                [preventAutoUpload]="true"
                (uploadComplete)="onUploadComplete($event)"
                (requestPatchParentForm)="handleRequestUpdateOrder($event)"
              ></app-file-input>
            </div>
          </div>
          <div class="row" [class.hidden]="uploadDone">
            <div class="col-12 text-center pb-3">
              <button
                mat-flat-button
                style="display: block; width: 100%;"
                (click)="handleFileUpload()"
                [disabled]="fileInput.loading"
              >
                <mat-icon class="m-r--8">upload</mat-icon>
                <span>Datei hochladen</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card__footer" *ngIf="order && isValid()">
        <button
          mat-flat-button
          class="m-r--8"
          (click)="handleRequestDownloadBindingOrder()"
        >
          <mat-icon class="m-r--8">description</mat-icon>
          <span>Download VB</span>
        </button>
        <button
          mat-flat-button
          class="m-r--8"
          *ngIf="bindingOrder && bindingOrder.contentUrl"
          (click)="handleRequestDownloadApprovedBindingOrder()"
        >
          <mat-icon class="m-r--8">description</mat-icon>
          <span>Download unterschriebene VB</span>
        </button>

        <button
          mat-flat-button
          (click)="showBindingOrderUpload = !showBindingOrderUpload"
        >
          <mat-icon class="m-r--8">upload</mat-icon>
          <span>Upload</span>
        </button>
      </div>

      <div class="blocker"></div>
    </div>
  `
})
export class BindingOrderToolComponent implements OnInit, OnDestroy {
  @ViewChild(FileInputComponent) fic!: FileInputComponent;

  @Input() order$: BehaviorSubject<Order>;
  bindingOrder: BindingOrder;
  isLoading$: Observable<boolean>;
  order: Order;
  showBindingOrderUpload = false;
  uploadDone = false;
  orderId: string;
  bindingOrderForm: FormGroup;
  @Output() requestDownloadBindingOrder: EventEmitter<
    void
  > = new EventEmitter();
  @Output() requestShowCombinationForm: EventEmitter<void> = new EventEmitter();
  @Output() requestUpdateOrder: EventEmitter<{
    iri: string;
    payload: fromOrdersModuleModels.Order;
  }> = new EventEmitter();
  extractIri = extractIri;
  getUUID = getUuidFromIri;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    public bos: BindingOrdersService,
    public orderService: OrdersService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private actions$: Actions,
    private store$: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.bindingOrderForm = this.fb.group({
      date: this.fb.control(
        moment()
          .toISOString(true)
          .substring(0, 10),
        [Validators.required]
      )
    });
    this.isLoading$ = isLoadingArray([
      this.store$.select(BindingOrdersSelectors.isLoading),
      this.store$.select(OrdersSelectors.isLoading)
    ]);
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.orderId = order['@id'];
        this.order = order;
        if (order.bindingOrder) {
          this.store$.dispatch(
            BindingOrdersActions.ReadBindingOrder({ iri: order.bindingOrder })
          );
          this.store$
            .select(BindingOrdersSelectors.selectBindingOrderByIri, {
              iri: order.bindingOrder
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e)
            )
            .subscribe(bindingOrder => {
              this.bindingOrder = bindingOrder;
            });
        }
      });
  }

  downPaymentDelayed(): boolean {
    return !(
      !this.order ||
      !this.order.state ||
      !this.order.state['Anzahlung Datenrettung verspätet'] ||
      this.order.state['Anzahlung Datenrettung verspätet'] !== 1
    );
  }

  isValid(): boolean {
    if (!this.order) {
      return false;
    }
    if (!this.order.replacementDataMediumSource) {
      return false;
    }
    if (!this.order.shippingProviderToCustomer) {
      return false;
    }
    if (!this.order.disposalType) {
      return false;
    }
    return true;
  }

  canBeSend(): boolean {
    if (!this.order || !this.order.potentialActions) {
      return false;
    }
    return !!this.order.potentialActions?.find(
      e => !e.error && e.transition.indexOf('to_bo_sent') > -1
    );
  }

  wasSend(): boolean {
    if (!this.order || !this.order.potentialActions) {
      return false;
    }
    return !!this.order.potentialActions?.find(
      e => !e.error && e.transition.indexOf('bo_sent_to') > -1
    );
  }

  showDialog(): void {
    this.dialog.open(BindingOrderDialogComponent, {
      width: '90%',
      maxWidth: '2000px',
      maxHeight: '95%',
      height: 'auto',
      panelClass: 'no-padding',
      data: { order$: this.order$ }
    });
  }

  handleRequestDownloadBindingOrder(): void {
    this.store$.dispatch(
      OrdersActions.ReadBindingOrderPDF({ iri: this.order['@id'] })
    );
  }

  handleRequestDownloadApprovedBindingOrder(): void {
    const a = document.createElement('a');
    a.href = this.bindingOrder.contentUrl;
    a.download = this.bindingOrder.originalName; // File name Here
    a.click(); // Downloaded file
    a.remove();
  }

  handleFileUpload(): void {
    const values = this.bindingOrderForm.getRawValue();
    values.createdAt = moment(values.date).toISOString(true);
    values.order = getUuidFromIri(this.order['@id']);
    this.fic.handleUpload(values);
  }

  handleRequestUpdateOrder(
    bindingOrder: fromOrdersModuleModels.BindingOrder | any
  ): void {
    this.requestUpdateOrder.emit({
      iri: this.orderId,
      payload: { bindingOrder: bindingOrder['@id'] }
    });
  }

  onUploadComplete($event: BindingOrder): void {
    // console.log('BindingOrdersActions.CreateBindingOrderSuccess', $event);
    this.bindingOrder = $event;
    this.uploadDone = true;
    this.store$.dispatch(OrdersActions.ReadOrder({ id: this.orderId }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleSendBindingOrder(): void {
    this.dialog
      .open(WriteEMailDialogComponent, {
        data: {
          type: 'bindingOrder',
          entity$: this.order$
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
        }
      });
  }
}
