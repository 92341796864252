import {createSelector} from '@ngrx/store';

import {PRODUCT_ORDER_ITEMS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectProductOrderItemsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[PRODUCT_ORDER_ITEMS_FEATURE_KEY]
);

export const selectProductOrderItemsEntities = createSelector(
  selectProductOrderItemsState,
  (state) => state.entities
);

export const selectProductOrderItems = createSelector(
  selectProductOrderItemsEntities,
  (entities) => Object.values(entities)
);

export const selectProductOrderItemsForOrder = createSelector(
  selectProductOrderItems,
  (entities, props) => entities.filter(product => product.order === props.iri)
);

export const selectErrors = createSelector(
  selectProductOrderItemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectProductOrderItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectProductOrderItemsState,
  (state) => state.loaded
);
