import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { CommissionCredit } from '../../models';
import { Order } from '../../../orders/models';
import { Customer } from '../../../customers/models';
import { Department } from '../../../master-data/models';
import {
  extractIri,
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CustomersSelectors } from '../../../customers/store/selectors';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { now } from 'moment/moment';
import { cloneDeep } from 'lodash-es';
import { InvoiceItemDialogComponent } from '../invoice-item-dialog/invoice-item-dialog.component';
import { InvoiceRecipient } from '../../models/invoice-recipient.interface';

@Component({
  selector: 'app-commission-credit-preview',
  styleUrls: ['./commission-credit-preview.component.scss'],
  template: `
    <div class="invoice-wrapper">
      <div
        class="state-wrapper"
        [style.background-color]="getStateBackgroundColor()"
      >
        <span *ngIf="!credit || !credit['@id']"
          >Nicht gespeicherter Entwurf!</span
        >
        <span *ngIf="credit && credit.state === 'draft'"
          >✔ Provisionsrechnung nicht gebucht</span
        >
        <span *ngIf="credit && credit.state === 'booked'"
          >✔ Provisionsrechnung gebucht</span
        >
        <span *ngIf="credit && credit.state === 'paid_out'"
          >✔ Provisionsrechnung gebucht und ausgezahlt</span
        >
        <span *ngIf="credit && credit.state === 'canceled'"
          >✔ Provisionsrechnung storniert</span
        >
      </div>

      <div class="invoice-content">
        <div class="row invoice-header">
          <div class="col-8">
            <div class="invoice-action-box-wrapper my-3">
              <app-invoice-action-box
                *ngIf="credit"
                [invoice]="credit"
              ></app-invoice-action-box>
            </div>
            <div class="invoice-head-recipient-wrapper mt-5">
              <app-invoice-head-recipient
                class="my-5"
                [readonly]="!canEditInvoice"
                [invoice$]="credit$"
                [customerReadOnly]="!isNew"
                (updateCustomer)="handleUpdateCustomer($event)"
                (updateOrder)="handleUpdateOrder($event)"
                (updateRecipient)="handleUpdateRecipient($event)"
              ></app-invoice-head-recipient>
            </div>
          </div>
          <div class="col-4">
            <app-invoice-head-issuer
              [readonly]="!canEditInvoice"
              [invoice$]="credit$"
              (updateIssuer)="handleUpdateIssuer($event)"
            ></app-invoice-head-issuer>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-8">
            <span class="heading--h2"
              >Provisionsrechnung / Credit
              {{ credit?.commissionCreditNumber }}</span
            >
          </div>

          <div class="col-4 text-right">
            <span class="heading--h2">
              Datum/Date:
              {{
                (credit?.createdAt ? credit.createdAt : now) | date: 'dd.MM.Y'
              }}
            </span>
          </div>
        </div>
        <div class="row my-2">
          <p class="m-b--64">Sie erhalten heute diese Provisionsrechung:</p>
        </div>
        <div class="row my-2 mb-4">
          <app-invoice-items-summary
            [invoice$]="credit$"
            (requestUpdateInvoice)="updateInvoice.emit($event)"
          ></app-invoice-items-summary>
        </div>
        <div class="row">
          <div class="col-7 ">
            <button
              mat-flat-button
              class="mb-2"
              *ngIf="canEditInvoice"
              (click)="addPosition()"
            >
              <mat-icon class="text-bg-green text-white border-radius-25"
                >add
              </mat-icon>
              <span class="pt-1 ms-2">
                Position hinzufügen
              </span>
            </button>
            <p class="text-color-grey" *ngIf="credit?.recipient?.taxNumber">
              Steuernummer Leistungserbringer:
              {{ credit?.recipient?.taxNumber || '-' }}
            </p>
          </div>
          <div class="col-5">
            <app-invoice-total [invoice]="credit"></app-invoice-total>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p
              class="text-color-grey"
              *ngIf="
                credit.vatLiability ===
                'VAT_LIABILITY_REVERSE_CHARGE_EUROPEAN_UNION'
              "
            >
              Steuerfreie innergemeinschaftliche Lieferung/Leistung. Reverse
              Charge.
            </p>
            <p
              class="text-color-grey"
              *ngIf="
                credit.vatLiability ===
                'VAT_LIABILITY_REVERSE_CHARGE_THIRD_COUNTRY'
              "
            >
              Steuerfreie Ausfuhrlieferung/Leistung (Drittland). Reverse Charge.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-invoice-custom-text
              [invoice$]="credit$"
            ></app-invoice-custom-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-invoice-footer [invoice$]="credit$"></app-invoice-footer>
          </div>
        </div>
      </div>
      <!--      <pre>{{credit$|async|json}}</pre>-->
    </div>
  `
})
export class CommissionCreditPreviewComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() credit$: Observable<CommissionCredit>;
  credit: CommissionCredit;
  creditWithChanges: CommissionCredit;
  @Output() updateInvoice: EventEmitter<CommissionCredit> = new EventEmitter<
    CommissionCredit
  >();
  @Output() updateOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() updateCustomer: EventEmitter<Customer> = new EventEmitter<
    Customer
  >();
  customer: Customer;
  department: Department;
  now: number = now();
  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractId = extractUUID;
  protected readonly extractIri = extractIri;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private store$: Store<ApplicationState>
  ) {
    super();
  }

  get canEditInvoice(): boolean {
    return !this.isNew && !this.credit.payoutAt;
  }

  get isNew(): boolean {
    return !this.credit || !this.credit['@id'];
  }

  getStateBackgroundColor(): string {
    if (!this.credit || !('state' in this.credit)) {
      return '';
    }
    switch (this.credit?.state) {
      case 'booked':
      case 'paid_out':
        return '#45a352';
      case 'draft':
        return '#6e7c89';
      case 'canceled':
        return '#da0000';
      default:
        return '#6e7c89';
    }
  }

  ngOnInit(): void {
    this.credit$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(invoice => !!invoice)
      )
      .subscribe(credit => {
        this.credit = credit;
        this.creditWithChanges = cloneDeep(credit);
        if (credit.customerNumber) {
          this.store$
            .select(CustomersSelectors.sByCustomerNumber, {
              customerNumber: credit.customerNumber
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i),
              take(1)
            )
            .subscribe(c => {
              this.customer = c;
            });
        }
        if (credit.issuer) {
          this.store$
            .select(DepartmentsSelectors.sByIri, {
              iri: extractIri(credit.issuer)
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i),
              take(1)
            )
            .subscribe(c => {
              this.department = c;
            });
        }
      });
  }

  handleUpdateIssuer(selectedIssuers: Department): void {
    const invoice: CommissionCredit = cloneDeep(this.creditWithChanges || {});
    invoice.issuer = selectedIssuers;

    this.updateInvoice.emit(invoice);
    this.creditWithChanges = invoice;
  }

  handleUpdateCustomer(customer: Customer): void {
    this.updateCustomer.emit(customer);
    const invoice: CommissionCredit = cloneDeep(this.creditWithChanges || {});
    invoice.recipient.nameLine1 = customer.nameLine1;
    invoice.recipient.nameLine2 = customer.nameLine2;
    this.updateInvoice.emit(invoice);
    this.creditWithChanges = invoice;
  }

  handleUpdateOrder(order: Order): void {
    this.updateOrder.emit(order);
  }

  addPosition(): void {
    this.dialog.open(InvoiceItemDialogComponent, {
      data: { invoice: this.credit }
    });
  }

  handleUpdateRecipient(c: InvoiceRecipient): void {
    if (!this.creditWithChanges) {
      return;
    }
    const invoice: CommissionCredit = cloneDeep(this.creditWithChanges || {});
    invoice.recipient.address = { ...invoice.recipient.address, ...c.address };
    invoice.recipient = { ...invoice.recipient, ...c };
    this.updateInvoice.emit(invoice);
    this.creditWithChanges = invoice;
  }
}
