import {createSelector} from '@ngrx/store';

import {ANALYSIS_PRIORITY_MODES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectAnalysisPriorityModesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[ANALYSIS_PRIORITY_MODES_FEATURE_KEY]
);

export const selectAnalysisPriorityModesEntities = createSelector(
  selectAnalysisPriorityModesState,
  (state) => state.entities
);

export const selectAnalysisPriorityModes = createSelector(
  selectAnalysisPriorityModesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectAnalysisPriorityModesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectAnalysisPriorityModesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectAnalysisPriorityModesState,
  (state) => state.loaded
);
