import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../../customers/models';

@Component({
  selector: 'app-shipment-address-block',
  styleUrls: ['./shipment-address-block.component.scss'],
  template: `
    <h1 *ngIf="address['@type'] === 'Dispatcher'">Absender</h1>
    <h1 *ngIf="address['@type'] === 'Receiver'">Empfänger</h1>
    <h1 *ngIf="address['@type'] === 'PickupDispatcher'">Abhol-Ort</h1>
    <address *ngIf="address?.address">
      {{ address?.companyName }}<br>
      {{ address?.firstName }} {{ address?.lastName }}<br>
      {{ address?.address.line1 }} {{ address?.address.line2 }}<br>
      <ng-container *ngIf="address?.address?.line3">
        {{address.address.line3}}<br>
      </ng-container>
      {{ address?.address.zipPostcode }} {{ address?.address.city }}<br>
      {{ address?.address.country }}
      <ng-container *ngIf="address.phone"><br>
        Phone: {{ address?.phone }}

      </ng-container>
    </address>
    <!--    <pre>{{address|json}}</pre>-->
  `
})
export class ShipmentAddressBlockComponent implements OnInit {
  @Input() address: Partial<{
    address: Address, companyName?: string, firstName?: string, lastName?: string, phone?: string, careOf?: string
  }>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
