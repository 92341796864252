import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_STOCK_ITEM = '[Warehouse Module] Create Customer Stock Item';
export const CREATE_CUSTOMER_STOCK_ITEM_SUCCESS = '[Warehouse Module] Create Customer Stock Item Success';
export const CREATE_CUSTOMER_STOCK_ITEM_FAIL = '[Warehouse Module] Create Customer Stock Item Fail';

export const CreateCustomerStockItem = createAction(
  CREATE_CUSTOMER_STOCK_ITEM,
  props<{ payload: fromModuleModels.CustomerStockItem }>()
);

export const CreateCustomerStockItemSuccess = createAction(
  CREATE_CUSTOMER_STOCK_ITEM_SUCCESS,
  props<{ response: fromModuleModels.CustomerStockItem | any }>()
);

export const CreateCustomerStockItemFail = createAction(
  CREATE_CUSTOMER_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_STOCK_ITEMS = '[Warehouse Module] Read Customer Stock Items';
export const READ_CUSTOMER_STOCK_ITEMS_SUCCESS = '[Warehouse Module] Read Customer Stock Items Success';
export const READ_CUSTOMER_STOCK_ITEMS_FAIL = '[Warehouse Module] Read Customer Stock Items Fail';

export const ReadCustomerStockItems = createAction(
  READ_CUSTOMER_STOCK_ITEMS,
  props<{ page: number, params?: any }>()
);

export const ReadCustomerStockItemsSuccess = createAction(
  READ_CUSTOMER_STOCK_ITEMS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerStockItemsFail = createAction(
  READ_CUSTOMER_STOCK_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER_STOCK_ITEM = '[Warehouse Module] Read Customer Stock Item';
export const READ_CUSTOMER_STOCK_ITEM_SUCCESS = '[Warehouse Module] Read Customer Stock Item Success';
export const READ_CUSTOMER_STOCK_ITEM_FAIL = '[Warehouse Module] Read Customer Stock Item Fail';

export const ReadCustomerStockItem = createAction(
  READ_CUSTOMER_STOCK_ITEM,
  props<{ iri: string }>()
);

export const ReadCustomerStockItemSuccess = createAction(
  READ_CUSTOMER_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerStockItemFail = createAction(
  READ_CUSTOMER_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_STOCK_ITEM = '[Warehouse Module] Update Customer Stock Item';
export const UPDATE_CUSTOMER_STOCK_ITEM_SUCCESS = '[Warehouse Module] Update Customer Stock Item Success';
export const UPDATE_CUSTOMER_STOCK_ITEM_FAIL = '[Warehouse Module] Update Customer Stock Item Fail';

export const UpdateCustomerStockItem = createAction(
  UPDATE_CUSTOMER_STOCK_ITEM,
  props<{ iri: string, payload: fromModuleModels.CustomerStockItem }>()
);

export const UpdateCustomerStockItemSuccess = createAction(
  UPDATE_CUSTOMER_STOCK_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const UpdateCustomerStockItemFail = createAction(
  UPDATE_CUSTOMER_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_STOCK_ITEM = '[Warehouse Module] Delete Customer Stock Item';
export const DELETE_CUSTOMER_STOCK_ITEM_SUCCESS = '[Warehouse Module] Delete Customer Stock Item Success';
export const DELETE_CUSTOMER_STOCK_ITEM_FAIL = '[Warehouse Module] Delete Customer Stock Item Fail';

export const DeleteCustomerStockItem = createAction(
  DELETE_CUSTOMER_STOCK_ITEM,
  props<{ iri: string }>()
);

export const DeleteCustomerStockItemSuccess = createAction(
  DELETE_CUSTOMER_STOCK_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerStockItemFail = createAction(
  DELETE_CUSTOMER_STOCK_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
