import {ObjectsUtility} from './objects.utility';
import {Observable} from 'rxjs';
import {combineLatestArray} from 'rxjs-etc';
import {ApplicationState} from '../../application-state/store';
import {MemoizedSelector, Store} from '@ngrx/store';

export const isLoadingArray = (observables: Observable<any>[]): Observable<boolean> =>
  combineLatestArray(observables, (results) => results.indexOf(true) > -1);
export const loadIfNotLoaded = (store$: Store<ApplicationState>, isLoadedSelect: MemoizedSelector<any, any>, loadingAction: any) => {
  const subscription = store$.select(isLoadedSelect)
    .subscribe((loaded: boolean) => {
      if (!loaded) {
        store$.dispatch(loadingAction);
      }
      setTimeout(() => {
        subscription.unsubscribe();
      }, 0);

    });
};
export default {
  isLoading: isLoadingArray
};
