import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CreateShipmentDialogComponent } from '../../components/create-shipment-dialog/create-shipment-dialog.component';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { extractIri } from '../../utilities/objects.utility';
import { Actions, ofType } from '@ngrx/effects';
import { ShipmentsActions } from '../../../shipping/store';
import { OrdersActions } from '../../../orders/store';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-shipment-to-dr-ext',
  styleUrls: ['./action-box-shipment-to-dr-ext.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Datenträger an Dienstleister verschicken
              </div>
              <div class="sub-header col-auto">
                Der Datenträger ist an den Dienstleister zu verschicken!
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              mat-button
              color="green"
              [disabled]="dataMedia.length <= 0"
              (click)="handleClickAddShipment()"
            >
              <mat-icon class="m-r--8">local_shipping</mat-icon>
              <span>Versand beauftragen</span>
            </button>
            od.
            <button
              mat-button
              color="green"
              [routerLink]="['/shipments', 'external_shipments']"
            >
              <mat-icon class="m-r--8">keyboard_arrow_right</mat-icon>
              <span>Alle zu versendenden Datenträger anzeigen</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Versand an Dienstleister</div>
          <div class="sub-header col-auto">
            Warte auf Versand des Datenträgers an Dienstleister
          </div>
        </div>
      </div>
      <div class="additional-content p-4" *ngIf="canMakeTransition">
        <div class="row">
          <app-order-stock-item-select-list
            [readonlyArchive]="true"
            (updateSelectedDataMedia)="setSelectedDataMedia($event)"
          ></app-order-stock-item-select-list>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxShipmentToDrExtComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;
  order: Order;
  dataMedia: Array<string> = [];

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private dialog: MatDialog,
    private actions$: Actions,
    private fb: FormBuilder
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        ofType(ShipmentsActions.CreateShipmentSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OrdersActions.ReadOrder({ iri: extractIri(this.order) })
        );
      });
    this.order$.pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      this.order = order;
    });
  }

  setSelectedDataMedia(list: Array<string>): void {
    this.dataMedia = list;
  }

  handleClickAddShipment(): void {
    this.dialog.open(CreateShipmentDialogComponent, {
      panelClass: 'width-95',
      data: {
        actionAfterCreation: 'OPEN_SHIPMENT',
        shipment: {
          order: extractIri(this.order),
          dataMedia: this.dataMedia,
          shipmentDirection:
            '/api/shipment_directions/SHIPMENT_DIRECTION_DR_DE_TO_DR_EXT'
        }
      }
    });
  }
}
