import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DATA_MEDIUM = '[Warehouse Module] Read Data Medium';
export const READ_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Read Data Medium Success';
export const READ_DATA_MEDIUM_FAIL = '[Warehouse Module] Read Data Medium Fail';

export const ReadDataMedium = createAction(
  READ_DATA_MEDIUM,
  props<{ iri: string }>()
);

export const ReadDataMediumSuccess = createAction(
  READ_DATA_MEDIUM_SUCCESS,
  props<{ response: any }>()
);

export const ReadDataMediumFail = createAction(
  READ_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_DATA_MEDIUMS = '[Warehouse Module] Read Data Mediums';
export const READ_DATA_MEDIUMS_SUCCESS = '[Warehouse Module] Read Data Mediums Success';
export const READ_DATA_MEDIUMS_FAIL = '[Warehouse Module] Read Data Mediums Fail';

export const ReadDataMediums = createAction(
  READ_DATA_MEDIUMS,
  props<{ page: number, params?: { [p: string]: string | boolean | number } }>()
);

export const ReadDataMediumsSuccess = createAction(
  READ_DATA_MEDIUMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadDataMediumsFail = createAction(
  READ_DATA_MEDIUMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_DATA_MEDIUMS_CHUNK = '[Warehouse Module] Read Data Mediums Chunk';
export const READ_DATA_MEDIUMS_CHUNK_SUCCESS = '[Warehouse Module] Read Data Mediums Chunk Success';
export const READ_DATA_MEDIUMS_CHUNK_FAIL = '[Warehouse Module] Read Data Mediums Chunk Fail';

export const ReadDataMediumsChunk = createAction(
  READ_DATA_MEDIUMS_CHUNK,
  props<{ uri: string }>()
);

export const ReadDataMediumsChunkSuccess = createAction(
  READ_DATA_MEDIUMS_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadDataMediumsChunkFail = createAction(
  READ_DATA_MEDIUMS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);
