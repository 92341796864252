import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import {Order, ProofOfDestruction} from '../../../orders/models';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {CustomerDataMedium} from '../../../warehouse/models';
import {DestructionType, StorageSystemManufacturer} from '../../../master-data/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {DataMediumsSelectors} from '../../../warehouse/store/selectors';
import * as moment from 'moment';
import {Actions, ofType} from '@ngrx/effects';
import {ProofsOfDestructionActions} from '../../../orders/store';
import {DataMediumsActions} from '../../../warehouse/store';
import * as OrdersModuleActions from '../../../orders/store';
import {ProofsOfDestructionSelectors} from '../../../orders/store/selectors';
import {DestructionTypesSelectors, StorageSystemManufacturersSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {DestructionTypesActions, StorageSystemManufacturersActions} from '../../../master-data/store';
import {FormsService} from '../../../shared/services';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {NotifierService} from 'angular-notifier';
import {WriteEMailDialogComponent} from "../../../shared/components/write-email-dialog/write-email-dialog.component";
import {extractTypeByIri} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-ticket-proof-of-destruction-edit-modal',
  styleUrls: ['./ticket-proof-of-destruction-edit-modal.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Vernichtungsnachweis</h2>
    </app-dialog-header>
    <div mat-dialog-content>
      <div class="additional-content" [formGroup]="form">
        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="mat-form-field col-12">
                <ng-select
                  placeholder="Art der Vernichtung"
                  [items]="destructionTypes$ | async"
                  [loading]="destructionTypesIsLoading$ | async"
                  bindValue="@id"
                  bindLabel="name"
                  [markFirst]="false"
                  [clearable]="false"
                  formControlName="destructionType"
                ></ng-select>
                <mat-error>
                  <app-form-error [fieldName]="'destructionType'" [formGroup]="form"></app-form-error>
                </mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <mat-label>Datum</mat-label>
                  <input type="date" matInput formControlName="date" required>
                  <mat-error>
                    <app-form-error [fieldName]="'date'" [formGroup]="form"></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-8">
            <mat-form-field>
              <mat-label>Beschreibung</mat-label>
              <textarea
                matInput
                formControlName="comment"
                cdkTextareaAutosize
                rows="6"
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="8"
              ></textarea>
              <mat-error>
                <app-form-error [fieldName]="'comment'" [formGroup]="form"></app-form-error>
              </mat-error>
            </mat-form-field>

          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <table
              class="bmo-table bmo-table-heading-uppercase bmo-table-bordered bmo-table-rounded bmo-table-hover bmo-table-clickable">
              <thead>
              <tr>
                <th style="width: 30px;"></th>
                <th class="th column-2">Einl-Nr.</th>
                <th class="th column-2">Seriennummer</th>
                <th class="th column-2">Typ</th>
                <th class="th column-2">Hersteller</th>
                <th class="th column-1">Speichergröße</th>
                <th class="th column-5">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="customerDataMediaIsLoading$|async">
                <td colspan="6">
                  <app-loading-overlay></app-loading-overlay>
                </td>
              </tr>
              <tr *ngFor="let medium of customerDataMedia$|async;" (click)="toggleDataMedium(medium)">
                <td>
                  <mat-icon>{{isChecked(medium) ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
                </td>
                <td class="td column-2">{{ medium.storageNumber }}</td>
                <td class="td column-2">{{ medium.storageSystemSerialNumber }}</td>
                <td
                  class="td column-2">{{ medium?.storageSystemType?.name ? medium.storageSystemType.name : (medium|json) }}</td>
                <td
                  class="td column-2">{{ getStorageSystemManufacturerName(medium.storageSystemManufacturer) }}</td>
                <td class="td column-1">{{ medium.storageSystemSize }} GB</td>
                <td class="td column-5">{{ medium.state }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <pre>{{form.value|json}}</pre>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <p *ngFor="let date of order?.proofOfDestructionSentDates">Der Vernichtungsnachweis wurde <span
            class="text-color-green">{{date|momentDateWithTime}}</span> per Mail verschickt.</p>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="me-2" style="justify-content: end;">
      <!--<button class="m-r--8" mat-flat-button color="green">
        <mat-icon class="m-r--8">description</mat-icon>
        <span>Datenträger hinzufügen</span>
      </button>-->

      <button mat-button color="gray" class="m-r--8 text-color-black" (click)="saveAndClose()">
        <mat-icon class="m-r--8">save</mat-icon>
        <span>Speichern & schließen</span>
      </button>

      <button mat-flat-button [color]="isAlreadySend?'orange':'green'" (click)="saveAndSend()">
        <mat-icon class="m-r--8">mail</mat-icon>
        <span>Speichern & per E-Mail versenden</span>
      </button>
    </div>
  `
})
export class TicketProofOfDestructionEditModalComponent implements OnInit, OnDestroy {

  order: Order;
  onDestroy$: Subject<any> = new Subject<any>();
  customerDataMedia$: Observable<Array<CustomerDataMedium>>;
  customerDataMediaIsLoading$: Observable<boolean>;
  proofOfDestruction$: Observable<ProofOfDestruction>;
  destructionTypes$: Observable<Array<DestructionType>>;
  destructionTypesIsLoading$: Observable<boolean>;
  storageSystemManufacturersEntities: { [iri: string]: StorageSystemManufacturer };
  orderPODIri: string = null;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private notify: NotifierService,
    private dialogRef: MatDialogRef<TicketProofOfDestructionEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order> }
  ) {
  }

  get isAlreadySend(): boolean {
    return this.order.proofOfDestructionSentDates.length > 0
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDestructionTypes();
    this.loadStorageSystemManufacturersEntities();
    this.data.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      this.order = order;
      this.orderPODIri = order.proofOfDestruction;
      this.form.get('destructionType').setValue(order.disposalType);
      this.loadProofOfDestruction(order);
      this.loadCustomerDataMedium(order);
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getStorageSystemManufacturerName(manufacturer: string | { name?: string }): string {
    if (typeof manufacturer !== 'string' && manufacturer?.name) {
      return manufacturer.name;
    }
    if (typeof manufacturer === 'string') {
      return this.storageSystemManufacturersEntities[manufacturer]?.name;
    }

  }

  private loadCustomerDataMedium(order): void {
    const orderUUID = getUuidFromIri(order['@id']);
    this.customerDataMedia$ = this.store$.select(DataMediumsSelectors.selectDataMediumsByOrderId, {orderIri: order['@id']})
      .pipe(map(e => e.filter(f => extractTypeByIri(f) === 'customer_data_media')));
    this.customerDataMediaIsLoading$ = this.store$.select(DataMediumsSelectors.isLoading);
    this.customerDataMedia$.pipe(
      takeUntil(this.onDestroy$),
      filter(list => list.length > 0)
    ).subscribe(mediaList => {
      if (this.form.get('customerDataMedia').value.length <= 0) {
        this.form.get('customerDataMedia').setValue(mediaList.map(e => e['@id']));
      }
    });
    this.proofOfDestruction$.pipe(
      takeUntil(this.onDestroy$),
      filter(pod => !!pod)
    ).subscribe(pod => {
      const values = {...pod, date: moment(pod.date).toISOString(true).substring(0, 10)};
      this.form.patchValue(values);
      this.form.markAsUntouched();
    });
    this.actions$.pipe(
      takeUntil(this.onDestroy$),
      ofType(
        ProofsOfDestructionActions.CreateProofOfDestructionFail,
        ProofsOfDestructionActions.UpdateProofOfDestructionFail,
      )).subscribe(errors => {
      this.fs.mergeErrorsIntoForm(errors, this.form);
    });


    this.store$.dispatch(DataMediumsActions.ReadDataMediums({
      page: -1,
      params: {'stockItem.order.uuid': orderUUID}
    }));
  }

  initForm(): void {
    this.form = this.fb.group({
      destructionType: this.fb.control(null, [Validators.required]),
      date: this.fb.control(moment().toISOString(true).substring(0, 10), [Validators.required]),
      customerDataMedia: this.fb.control([], Validators.required),
      comment: this.fb.control('')
    });
  }

  isChecked(medium: CustomerDataMedium): boolean {
    return this.form.get('customerDataMedia').value.indexOf(medium['@id']) > -1;
  }

  toggleDataMedium(medium: CustomerDataMedium): void {
    const value = [];
    value.push(...this.form.get('customerDataMedia').value);
    const indexOfElement = value.indexOf(medium['@id']);
    if (indexOfElement > -1) {
      value.splice(indexOfElement, 1);
    } else {
      value.push(medium['@id']);
    }
    this.form.get('customerDataMedia').patchValue(value);

  }

  save(): void {
    if (this.form.value.customerDataMedia.length <= 0) {
      this.notify.show({type: 'error', message: 'Bitte wenigstens einen Datenträger zur Zerstörung auswählen.'});
      return;
    }
    this.store$.dispatch(ProofsOfDestructionActions.UpdateProofOfDestruction({
      iri: this.orderPODIri,
      payload: this.form.value
    }));

  }

  saveAndClose(): void {
    this.save();
    this.actions$.pipe(
      takeUntil(this.onDestroy$),
      ofType(ProofsOfDestructionActions.UpdateProofOfDestructionSuccess),
      take(1)
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  saveAndSend(): void {
    this.save();
    this.actions$.pipe(
      takeUntil(this.onDestroy$),
      ofType(ProofsOfDestructionActions.UpdateProofOfDestructionSuccess),
      take(1)
    ).subscribe(() => {
      this.dialog.open(WriteEMailDialogComponent, {
        data: {
          type: 'proofOfDestruction', entity$: of(this.order)
        }
      }).afterClosed().pipe(take(1)).subscribe(value => {
        if (value) {
          this.dialogRef.close();
        }
      });
    });

  }

  private loadProofOfDestruction(order): void {
    this.proofOfDestruction$ = this.store$.select(ProofsOfDestructionSelectors.selectProofOfDestructionForOrder, {iri: order['@id']});
    if (order?.proofOfDestruction) {
      this.store$.dispatch(ProofsOfDestructionActions.ReadProofOfDestruction({iri: order.proofOfDestruction}));
    }
  }

  private loadDestructionTypes(): void {
    this.destructionTypes$ = this.store$.select(DestructionTypesSelectors.selectDestructionTypes);
    this.destructionTypesIsLoading$ = this.store$.select(DestructionTypesSelectors.isLoading);
    loadIfNotLoaded(this.store$, DestructionTypesSelectors.isLoaded, DestructionTypesActions.ReadDestructionTypes());

  }

  private loadStorageSystemManufacturersEntities(): void {
    this.store$.select(StorageSystemManufacturersSelectors.sEntities).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(entities => {
      this.storageSystemManufacturersEntities = entities;
    });
    loadIfNotLoaded(this.store$, StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers());
  }


}
