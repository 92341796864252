import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {select, Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {Actions} from "@ngrx/effects";
import {isLoadingArray} from "../../../shared/utilities/observable.utility";
import {LeadsSelectors} from "../../store/selectors";
import {takeUntil} from "rxjs/operators";
import * as LeadsModuleSelectors from "../../store/selectors";
import {Lead} from "../../models";
import {Go} from "../../../application-state/store/actions/router.actions";
import {StringsUtility} from "../../../shared/utilities/strings.utility";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-leads-search-dialog',
  templateUrl: './leads-search-dialog.component.html',
  styleUrls: ['./leads-search-dialog.component.scss']
})
export class LeadsSearchDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;
  leads: Lead[];
  lfg: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LeadsSearchDialogComponent>,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder
  ) {
  }


  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(LeadsSelectors.isLoading),
    ]);
    this.loadLeads();
    this.lfg = this.fb.group({
      leads: this.fb.control(null),
    });
  }
  loadLeads(): void {
    this.store$
      .pipe(
        takeUntil(this.onDestroy$),
        select(LeadsModuleSelectors.LeadsSelectors.selectNewLeads)
      ).subscribe(data => this.leads = data)
  }
  handleRequestUpdateLeadView(iri): void {
    this.dialogRef.close()
    this.store$.dispatch(
      Go({path: ['leads', StringsUtility.getUuidFromIri(iri)]})
    );
  }

  filterLeads(event: Event): void {
    const value = this.lfg.value.leads?.toLowerCase();
    setTimeout(() => {
      if(value.length > 0) {
        this.leads = this.leads.filter(lead => lead.lastName?.toLowerCase().includes(value) || lead.firstName?.toLowerCase().includes(value) || lead.email?.toLowerCase().includes(value) || lead.nameLine1?.toLowerCase().includes(value) || lead.nameLine2?.toLowerCase().includes(value));
      } else {
        this.loadLeads();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  protected readonly escape = escape;
  protected readonly event = event;
}
