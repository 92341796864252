import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import {CommissionCredit, InvoiceMail} from '../../models';
import {InvoicePdfResponse} from "../../models/invoice-pdf-Response";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_COMMISSION_CREDIT = '[Invoices Module] Create Commission Credit';
export const CREATE_COMMISSION_CREDIT_FAIL = '[Invoices Module] Create Commission Credit Fail';
export const CREATE_COMMISSION_CREDIT_SUCCESS = '[Invoices Module] Create Commission Credit Success';

export const CreateCommissionCredit = createAction(
  CREATE_COMMISSION_CREDIT,
  props<{ payload: any }>()
);

export const CreateCommissionCreditSuccess = createAction(
  CREATE_COMMISSION_CREDIT_SUCCESS,
  props<{ response: any }>()
);

export const CreateCommissionCreditFail = createAction(
  CREATE_COMMISSION_CREDIT_FAIL,
  props<{ response: any }>()
);

export const CREATE_COMMISSION_CREDIT_FROM_BROKER = '[Invoices Module] Create Commission Credit From Broker';
export const CREATE_COMMISSION_CREDIT_FROM_BROKER_FAIL = '[Invoices Module] Create Commission Credit From Broker Fail';
export const CREATE_COMMISSION_CREDIT_FROM_BROKER_SUCCESS = '[Invoices Module] Create Commission Credit From Broker Success';

export const CreateCommissionCreditFromBroker = createAction(
  CREATE_COMMISSION_CREDIT_FROM_BROKER,
  props<{ payload: { broker: string } }>()
);

export const CreateCommissionCreditFromBrokerSuccess = createAction(
  CREATE_COMMISSION_CREDIT_FROM_BROKER_FAIL,
  props<{ response: CommissionCredit }>()
);

export const CreateCommissionCreditFromBrokerFail = createAction(
  CREATE_COMMISSION_CREDIT_FROM_BROKER_SUCCESS,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read PDF

export const READ_PDF = '[Invoices Module] Commission Credit as pdf';
export const READ_PDF_SUCCESS = '[Invoices Module] Commission Credit as pdf Success';
export const READ_PDF_FAIL = '[Invoices Module] Commission Credit as pdf Fail';

export const ReadPDF = createAction(
  READ_PDF,
  props<{ iri?: string }>()
);

export const ReadPDFSuccess = createAction(
  READ_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadPDFFail = createAction(
  READ_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_COMMISSION_CREDIT = '[Invoices Module] Read Commission Credit';
export const READ_COMMISSION_CREDIT_SUCCESS = '[Invoices Module] Read Commission Credit Success';
export const READ_COMMISSION_CREDIT_FAIL = '[Invoices Module] Read Commission Credit Fail';

export const ReadCommissionCredit = createAction(
  READ_COMMISSION_CREDIT,
  props<{ iri: string }>()
);

export const ReadCommissionCreditSuccess = createAction(
  READ_COMMISSION_CREDIT_SUCCESS,
  props<{ response: CommissionCredit | any }>()
);

export const ReadCommissionCreditFail = createAction(
  READ_COMMISSION_CREDIT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_COMMISSION_CREDITS = '[Invoices Module] Read Commission Credits';
export const READ_COMMISSION_CREDITS_SUCCESS = '[Invoices Module] Read Commission Credits Success';
export const READ_COMMISSION_CREDITS_FAIL = '[Invoices Module] Read Commission Credits Fail';

export const ReadCommissionCredits = createAction(
  READ_COMMISSION_CREDITS,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadCommissionCreditsSuccess = createAction(
  READ_COMMISSION_CREDITS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCommissionCreditsFail = createAction(
  READ_COMMISSION_CREDITS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_COMMISSION_CREDITS_CHUNK = '[Invoices Module] Read Commission Credits Chunk';
export const READ_COMMISSION_CREDITS_CHUNK_SUCCESS = '[Invoices Module] Read Commission Credits Chunk Success';
export const READ_COMMISSION_CREDITS_CHUNK_FAIL = '[Invoices Module] Read Commission Credits Chunk Fail';

export const ReadCommissionCreditsChunk = createAction(
  READ_COMMISSION_CREDITS_CHUNK,
  props<{ uri: string }>()
);

export const ReadCommissionCreditsChunkSuccess = createAction(
  READ_COMMISSION_CREDITS_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadCommissionCreditsChunkFail = createAction(
  READ_COMMISSION_CREDITS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// SendMail

export const SEND_MAIL = '[Invoices Module] Send Mail Correction Commission Credit ';
export const SEND_MAIL_SUCCESS = '[Invoices Module] Send Mail Commission Credit Success';
export const SEND_MAIL_FAIL = '[Invoices Module] Send Mail Commission Credit Fail';

export const SendMail = createAction(
  SEND_MAIL,
  props<{ iri: string, payload: InvoiceMail | any }>()
);

export const SendMailSuccess = createAction(
  SEND_MAIL_SUCCESS,
  props<{ response: CommissionCredit }>()
);

export const SendMailFail = createAction(
  SEND_MAIL_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_COMMISSION_CREDIT = '[Invoices Module] Update Commission Credit';
export const UPDATE_COMMISSION_CREDIT_SUCCESS = '[Invoices Module] Update Commission Credit Success';
export const UPDATE_COMMISSION_CREDIT_FAIL = '[Invoices Module] Update Commission Credit Fail';

export const UpdateCommissionCredit = createAction(
  UPDATE_COMMISSION_CREDIT,
  props<{ iri: string, payload: CommissionCredit | any }>()
);

export const UpdateCommissionCreditSuccess = createAction(
  UPDATE_COMMISSION_CREDIT_SUCCESS,
  props<{ response: CommissionCredit }>()
);

export const UpdateCommissionCreditFail = createAction(
  UPDATE_COMMISSION_CREDIT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Patch

export const BOOK_COMMISSION_CREDIT = '[Invoices Module] Book Commission Credit';
export const BOOK_COMMISSION_CREDIT_SUCCESS = '[Invoices Module] Book Commission Credit Success';
export const BOOK_COMMISSION_CREDIT_FAIL = '[Invoices Module] Book Commission Credit Fail';

export const BookCommissionCredit = createAction(
  BOOK_COMMISSION_CREDIT,
  props<{ iri: string }>()
);

export const BookCommissionCreditSuccess = createAction(
  BOOK_COMMISSION_CREDIT_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const BookCommissionCreditFail = createAction(
  BOOK_COMMISSION_CREDIT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const BOOK_PAY_OUT = '[Invoices Module] Book Pay Out';
export const BOOK_PAY_OUT_SUCCESS = '[Invoices Module] Book Pay Out Success';
export const BOOK_PAY_OUT_FAIL = '[Invoices Module] Book Pay Out Fail';

export const BookPayOut = createAction(
  BOOK_PAY_OUT,
  props<{ iri: string, payload: { payoutAt: string } }>()
);

export const BookPayOutSuccess = createAction(
  BOOK_PAY_OUT_SUCCESS,
  props<{ response: { '@context'?: string, '@id'?: string, '@type'?: string }, invoiceIri: string }>()
);

export const BookPayOutFail = createAction(
  BOOK_PAY_OUT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_COMMISSION_CREDIT = '[Invoices Module] Delete Commission Credit';
export const DELETE_COMMISSION_CREDIT_SUCCESS = '[Invoices Module] Delete Commission Credit Success';
export const DELETE_COMMISSION_CREDIT_FAIL = '[Invoices Module] Delete Commission Credit Fail';

export const DeleteCommissionCredit = createAction(
  DELETE_COMMISSION_CREDIT,
  props<{ iri: string }>()
);

export const DeleteCommissionCreditSuccess = createAction(
  DELETE_COMMISSION_CREDIT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCommissionCreditFail = createAction(
  DELETE_COMMISSION_CREDIT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_COMMISSION_CREDIT = '[Invoices Module] Reset Current Commission Credit';

export const ResetCurrentCommissionCredit = createAction(
  RESET_CURRENT_COMMISSION_CREDIT,
);

export const RESET_IS_COMMISSION_CREDIT_LOADED = '[Invoices Module] Reset Is Commission Credit Loaded';

export const ResetCommissionCreditsLoaded = createAction(
  RESET_IS_COMMISSION_CREDIT_LOADED,
);
