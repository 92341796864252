import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {CommissionCreditsActions} from '../store';
import {CommissionCreditsSelectors} from '../store/selectors';

@Injectable()
export class CurrentCommissionCreditLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const iri = route.params.iri;

    return this.currentCommissionCreditLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentCommissionCreditLoaded(iri: string): Observable<boolean | any> {
    return this.store.pipe(
      select(CommissionCreditsSelectors.selectCurrentCommissionCredit),
      tap(invoice => {
        if (!invoice) {
          this.store.dispatch(CommissionCreditsActions.ReadCommissionCredit({iri}));
        }
      }),
      filter(invoice => !!invoice),
      take(2)
    );
  }
}
