import {createSelector} from '@ngrx/store';

import {SPECIAL_AGREEMENTS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectSpecialAgreementsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[SPECIAL_AGREEMENTS_FEATURE_KEY]
);

export const selectSpecialAgreementsEntities = createSelector(
  selectSpecialAgreementsState,
  (state) => state.entities
);

export const selectSpecialAgreements = createSelector(
  selectSpecialAgreementsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectSpecialAgreementsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectSpecialAgreementsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectSpecialAgreementsState,
  (state) => state.loaded
);
