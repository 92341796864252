import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { LeadOriginTypesActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.LeadOriginType };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(LeadOriginTypesActions.LoadLeadOriginTypes, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(
    LeadOriginTypesActions.LoadLeadOriginTypesSuccess,
    (state, { response }) => {
      const originTypes = response['hydra:member'];
      const entities = originTypes.reduce(
        (entities: { [id: string]: fromModuleModels.LeadOriginType }, ot) => {
          return {
            ...entities,
            [ot['@id']]: ot
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
