import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {ParameterBag} from '../../../shared/models/ParameterBag.interface';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_LEAD_COMMENT = '[Leads Module] Create Lead Comment';
export const CREATE_LEAD_COMMENT_SUCCESS = '[Leads Module] Create Lead Comment Success';
export const CREATE_LEAD_COMMENT_FAIL = '[Leads Module] Create Lead Comment Fail';

export const CreateLeadComment = createAction(
  CREATE_LEAD_COMMENT,
  props<{ payload: fromModuleModels.LeadComment }>()
);

export const CreateLeadCommentSuccess = createAction(
  CREATE_LEAD_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateLeadCommentFail = createAction(
  CREATE_LEAD_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const LOAD_LEAD_COMMENT = '[Leads Module] Load Lead Comment';
export const LOAD_LEAD_COMMENT_SUCCESS = '[Leads Module] Load Lead Comment Success';
export const LOAD_LEAD_COMMENT_FAIL = '[Leads Module] Load Lead Comment Fail';

export const LoadLeadComment = createAction(
  LOAD_LEAD_COMMENT,
  props<{ iri: string }>()
);

export const LoadLeadCommentSuccess = createAction(
  LOAD_LEAD_COMMENT_SUCCESS,
  props<{ response: any }>()
);

export const LoadLeadCommentFail = createAction(
  LOAD_LEAD_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LEAD_COMMENTS = '[Leads Module] Read Lead Comments';
export const READ_LEAD_COMMENTS_SUCCESS = '[Leads Module] Read Lead Comments Success';
export const READ_LEAD_COMMENTS_FAIL = '[Leads Module] Read Lead Comments Fail';

export const ReadLeadComments = createAction(
  READ_LEAD_COMMENTS,
  props<{ page: number, params: ParameterBag }>()
);

export const ReadLeadCommentsSuccess = createAction(
  READ_LEAD_COMMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadLeadCommentsFail = createAction(
  READ_LEAD_COMMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_LEAD_COMMENT = '[Leads Module] Update Lead Comment';
export const UPDATE_LEAD_COMMENT_SUCCESS = '[Leads Module] Update Lead Comment Success';
export const UPDATE_LEAD_COMMENT_FAIL = '[Leads Module] Update Lead Comment Fail';

export const UpdateLeadComment = createAction(
  UPDATE_LEAD_COMMENT,
  props<{ iri: string, payload: any }>()
);

export const UpdateLeadCommentSuccess = createAction(
  UPDATE_LEAD_COMMENT_SUCCESS,
  props<{ response: fromModuleModels.LeadComment }>()
);

export const UpdateLeadCommentFail = createAction(
  UPDATE_LEAD_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_LEAD_COMMENT = '[Leads Module] Delete Lead Comment';
export const DELETE_LEAD_COMMENT_SUCCESS = '[Leads Module] Delete Lead Comment Success';
export const DELETE_LEAD_COMMENT_FAIL = '[Leads Module] Delete Lead Comment Fail';

export const DeleteLeadComment = createAction(
  DELETE_LEAD_COMMENT,
  props<{ iri: string }>()
);
export const DeleteLeadCommentSuccess = createAction(
  DELETE_LEAD_COMMENT_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteLeadCommentFail = createAction(
  DELETE_LEAD_COMMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
