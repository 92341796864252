import {createSelector} from '@ngrx/store';

import {SHIPMENT_ADDITIONAL_INSURANCES_FEATURE_KEY, selectShippingModuleState} from '../reducers';

export const selectShipmentAdditionalInsurancesState = createSelector(
  selectShippingModuleState,
  (moduleState) => moduleState[SHIPMENT_ADDITIONAL_INSURANCES_FEATURE_KEY]
);

export const selectShipmentAdditionalInsurancesEntities = createSelector(
  selectShipmentAdditionalInsurancesState,
  (state) => state.entities
);

export const selectShipmentAdditionalInsurances = createSelector(
  selectShipmentAdditionalInsurancesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectShipmentAdditionalInsurancesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectShipmentAdditionalInsurancesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectShipmentAdditionalInsurancesState,
  (state) => state.loaded
);
