import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ApplicationState } from '../../../application-state/store';
import {
  DataRecoveryResultsActions,
  OrdersActions
} from '../../../orders/store';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { FileInputComponent } from '../../../shared/components/file-input/file-input.component';
import { ModalDialogOptions } from '../../../application-state/models';
import { TicketsActions } from '../../store';
import {
  DataRecoveryResultsSelectors,
  OrdersSelectors
} from '../../../orders/store/selectors';
import { DataRecoveryResult, Order } from '../../../orders/models';
import { ActivatedRoute } from '@angular/router';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { NotifierService } from 'angular-notifier';
import { Ticket } from '../../models';
import { TicketsSelectors } from '../../store/selectors';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { OrderDataMediaLogComponent } from '../../../orders/components/order-data-media-log/order-data-media-log.component';
import { TransitionsActions } from '../../../application-state/store/actions';
import { AuthService } from '../../../auth/services/auth.service';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder
} from '../../../orders/helpers';

@Component({
  selector: 'app-ticket-view',
  styleUrls: ['ticket-view.component.scss'],
  templateUrl: 'ticket-view.component.html'
})
export class TicketViewComponent implements OnInit, OnDestroy {
  ticket$: BehaviorSubject<Ticket> = new BehaviorSubject(null);
  ticket: Ticket;
  ticketId: string = null;
  onDestroy$: Subject<any> = new Subject<any>();
  order$: BehaviorSubject<Order> = new BehaviorSubject(null);
  order: Order;
  isLoading$: Observable<boolean>;
  @ViewChild(FileInputComponent) fic!: FileInputComponent;
  dataRecoveryResult$: Observable<DataRecoveryResult>;
  getCustomerName = getCustomerNameFromOrder;
  // }
  getCompanyName = getCompanyNameFromOrder;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private title: AbstractTitleService,
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private createAndUpdateActions$: Actions,
    private notifierService: NotifierService
  ) {}

  get canBeCanceled(): boolean {
    return !!this.order?.potentialActions?.find(
      action =>
        action.workflow === 'order_state' &&
        action.transition.indexOf('to_no_order') > -1
    );
  }

  // getCustomerName(element: Order): string {
  //   if (element?.decisionMakerCustomerContact) {
  //     const contact = element?.decisionMakerCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.technicalCustomerContact) {
  //     const contact = element?.technicalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   } else if (element?.organizationalCustomerContact) {
  //     const contact = element?.organizationalCustomerContact;
  //     return contact.firstName + ' ' + contact.lastName;
  //   }
  //   if (element?.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return element.customer.nameLine1;
  //   } else {
  //     // Geschäftskunde
  //     return '-';
  //   }

  get isTechnicianExternal(): boolean {
    return this.authService.isTechnicianExternal();
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(TicketsSelectors.isLoading)
    ]);
    this.createAndUpdateActions$
      .pipe(takeUntil(this.onDestroy$), ofType(TicketsActions.ReadTicketFail))
      .subscribe(error => {
        console.log(error);
        this.notifierService.show({
          type: 'error',
          message: 'Ausgewähltes Ticket existiert leider nicht.'
        });
      });
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.ticketId = params.get('uuid');
        if (this.ticketId) {
          this.title.setTitle('Ticket bearbeiten');
          this.loadTicket();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  // getCompanyName(element: Order): string {
  //   if (!element) {
  //     return '';
  //   }
  //
  //   if (element.customer && element.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return null;
  //   } else {
  //     // Geschäftskunde
  //     return element.customer.nameLine1;
  //   }
  // }

  isVip(element: Order): boolean {
    return element?.customer?.vip;
  }

  loadTicket(): void {
    this.store$.dispatch(TicketsActions.ReadTicket({ id: this.ticketId }));
    this.store$
      .select(TicketsSelectors.sById, { id: this.ticketId })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticket => {
        if (!ticket) {
          return;
        }
        this.ticket$.next(ticket);
        this.ticket = ticket;
        this.title.setTitle('Ticket #' + ticket.ticketNumber);
        // read Order
        const iri = extractIri(ticket.order);

        this.store$.dispatch(OrdersActions.ReadOrder({ iri }));
        this.store$
          .pipe(select(OrdersSelectors.sDetailedByIri, { iri }))
          // .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {
            this.order$.next(order);
            this.order = order;
            const dataRecoveryResultIri = order?.dataRecoveryResults?.length
              ? order.dataRecoveryResults.slice(-1)[0]
              : null;
            // needed
            if (
              dataRecoveryResultIri &&
              this.dataRecoveryResult$ === undefined
            ) {
              this.dataRecoveryResult$ = this.store$.select(
                DataRecoveryResultsSelectors.selectDataRecoveryResultByIri,
                { iri: dataRecoveryResultIri }
              );
              this.store$.dispatch(
                DataRecoveryResultsActions.ReadDataRecoveryResult({
                  iri: dataRecoveryResultIri
                })
              );
            }
          });
      });
    // this.store$.select(CustomerContactsSelectors.selectCustomerContactEntities)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe((entities) => {
    //     this.customerContactEntities = entities;
    //   });
  }

  showPartnerLabel(order: Order | null): boolean {
    if (!order) {
      return false;
    }
    return !!order.broker;
  }

  handleShowTrackingHistoryDialog(): void {
    this.dialog.open(OrderDataMediaLogComponent, {
      panelClass: 'width-95',
      data: { order$: this.order$ }
    });
  }

  handleCancelOrder(): void {
    if (!this.order || this.canBeCanceled) {
      return;
    }
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Auftrag abgebrochen werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, abbrechen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        const targetTransition = this.order.potentialActions?.find(
          action =>
            action.workflow === 'order_state' &&
            action.transition.indexOf('to_no_order') > -1
        );
        // console.log(targetTransition, this.order.potentialActions);
        if (!targetTransition) {
          return;
        }
        const uri = `${this.order['@id']}/transitions?workflow=${targetTransition.workflow}&transition=${targetTransition.transition}`;
        const payload = {
          workflow: targetTransition.workflow,
          transition: targetTransition.transition
        };
        this.store$.dispatch(
          TransitionsActions.MakeTransition({ uri, payload })
        );
      });
  }

  openEmailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'order', entity$: this.order$ }
    });
  }
}
