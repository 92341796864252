import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerOrdersActions} from '../actions/';
import * as fromCustomersModuleModels from '../../services';

@Injectable()
export class CustomerOrdersEffects {

  constructor(private actions$: Actions, private cs: fromCustomersModuleModels.CustomersService) {
  }


  ReadCustomerOrders$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerOrdersActions.ReadCustomerOrders),
    map(action => action),
    switchMap(({page, params}) => {
      return this.cs.readCustomerOrders(page, params).pipe(
        map((response: any) => CustomerOrdersActions.ReadCustomerOrdersSuccess({response})),
        catchError(response => of(CustomerOrdersActions.ReadCustomerOrdersFail({response})))
      );
    })
  ));
}
