import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ShipmentAdditionalInsurancesActions} from '../actions';
import {ShipmentAdditionalInsurancesService} from '../../services/shipment-additional-insurances.service';

@Injectable()
export class ShipmentAdditionalInsurancesEffects {

    constructor(private actions$: Actions, private service: ShipmentAdditionalInsurancesService) {
    }

  getShipmentAdditionalInsurance$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurance),
    map(action => action),
    switchMap(({iri}) => {
      return this.service
        .getShipmentAdditionalInsurance(iri)
        .pipe(
          map(response => ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsuranceSuccess({response})),
          catchError(response => of(ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsuranceFail({response})))
        );
    })
  ));

    getShipmentAdditionalInsurances$ = createEffect(() => this.actions$.pipe(
        ofType(ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurances),
        map(action => action),
        switchMap(() => {
            return this.service
                .getShipmentAdditionalInsurances()
                .pipe(
                    map(response => ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurancesSuccess({response})),
                    catchError(response => of(ShipmentAdditionalInsurancesActions.ReadShipmentAdditionalInsurancesFail({response})))
                );
        })
    ));
}
