import {createSelector} from '@ngrx/store';

import {CUSTOMER_COMMENTS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';
import {State as CustomerCommentsState} from '../reducers/customer-comments.reducers';
import {getRouterState, RouterStateUrl} from '../../../application-state/store';

export const selectCustomerCommentsState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_COMMENTS_FEATURE_KEY]
);

export const selectRouteParameters = createSelector(
  getRouterState,
  (router: RouterStateUrl | any) => {

    const {state} = router;
    return state.params;
  }
);

export const selectCustomerCommentEntities = createSelector(
  selectCustomerCommentsState,
  (state) => state.entities
);

export const selectCustomerCommentByIndex = createSelector(
  selectCustomerCommentEntities,
  (comments, index: string) => comments[index]
);

export const selectCustomerComments = createSelector(
  selectCustomerCommentEntities,
  selectRouteParameters,
  (comments, params) => Object.keys(comments).map(iri => comments[iri])
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectCustomerCommentsTotalItems = createSelector(
  selectCustomerCommentsState,
  (state) => state.totalItems
);

export const selectCustomerCommentsPagination = createSelector(
  selectCustomerCommentsState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectCustomerCommentsPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectCustomerCommentsPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectCustomerCommentsPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectCustomerCommentsPagination,
  pagination => pagination.next
);

// MISC
export const isLoading = createSelector(
  selectCustomerCommentsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerCommentsState,
  (state: CustomerCommentsState) => state.loaded
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectCustomerCommentsErrors = createSelector(
  selectCustomerCommentsState,
  state => state.errors
);
