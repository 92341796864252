import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as fromModuleModels from '../../models';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'customer-account-invitation-list',
  styleUrls: ['customer-account-invitation-list.component.scss'],
  template: `

    <div class="card m-b--16 m-l--48 mat-elevation-z1">

      <div class="card__heading">
        <ng-content></ng-content>
      </div>
      <div class="card__content">

        <table mat-table *ngIf="customerAccountInvitations?.length; else placeholder"
               [dataSource]="customerAccountInvitations" class="mat-elevation-z1">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.firstName }} {{ element.lastName }}</span>
            </td>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>E-Mail</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.email }}</span>
            </td>
          </ng-container>

          <!-- Invited At Column -->
          <ng-container matColumnDef="invitedAt">
            <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
            <td mat-cell
                *matCellDef="let element">{{ element.invitedAt ? (element.invitedAt | date: 'dd.MM.Y') : '-' }}</td>
          </ng-container>

          <!-- Expires At Column -->
          <ng-container matColumnDef="expiresAt">
            <th mat-header-cell *matHeaderCellDef>Einladung gültig bis</th>
            <td mat-cell
                *matCellDef="let element">{{ element.expiresAt ? (element.expiresAt | date: 'dd.MM.Y - HH:mm') + ' Uhr' : '-' }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button (click)="handleDeleteCustomerAccountInvitation(element)" mat-icon-button>
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>

    <ng-template #placeholder>
      <p class="placeholder">Für diesen Kunden gibt es aktuell keine offenen Einladungen.</p>
    </ng-template>
  `
})
export class CustomerAccountInvitationListComponent {

  @Input() customerAccountInvitations: Array<fromModuleModels.CustomerAccount>;

  @Output() requestDeleteCustomerAccountInvitation: EventEmitter<string> = new EventEmitter();
  @Output() requestUpdateCustomerAccountForm: EventEmitter<fromModuleModels.CustomerAccount> = new EventEmitter<fromModuleModels.CustomerAccount>();

  displayedColumns: Array<string> = ['name', 'email', 'invitedAt', 'expiresAt', 'actions'];

  constructor() {
  }

  handleDeleteCustomerAccountInvitation(account: fromModuleModels.CustomerAccount): void {

    const iri = account['@id'];
    this.requestDeleteCustomerAccountInvitation.emit(iri);
  }
}
