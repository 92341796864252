import {createSelector} from '@ngrx/store';

import {CUSTOMER_ORDERS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerOrdersState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_ORDERS_FEATURE_KEY]
);

export const selectCustomerOrderEntities = createSelector(
  selectCustomerOrdersState,
  (state) => state.entities
);

export const selectCustomerOrders = createSelector(
  selectCustomerOrderEntities,
  (orders) => Object.keys(orders).map(iri => orders[iri])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoaded = createSelector(
  selectCustomerOrdersState,
  state => state.loaded
);

export const isLoading = createSelector(
  selectCustomerOrdersState,
  state => state.loading
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomerOrdersState,
  (state) => state.errors
);
