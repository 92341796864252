import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AnalysisResult } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';
import {getUriWithPageAndParams} from "../../shared/utilities/urlParams.utility";
import {ParameterBag} from "../../shared/models/ParameterBag.interface";
import {AbstractApiResponse} from "../../shared/models";

@Injectable({
  providedIn: 'root'
})
export class AnalysisResultsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createAnalysisResult(payload: AnalysisResult): Observable<AnalysisResult> {
    return this.rolesService.userHasRoleFilter<AnalysisResult>(
      'ROLE_MWS_ANALYSIS_RESULT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/analysis_results`, payload)
    );
  }

  readAnalysisResult(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<AnalysisResult>(
      'ROLE_MWS_ANALYSIS_RESULT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readAnalysisResults(
    page = -1,
    params?: ParameterBag
  ): Observable<AnalysisResult[]> {
    return this.rolesService.userHasRoleFilter<AnalysisResult[]>(
      'ROLE_MWS_ANALYSIS_RESULT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(getUriWithPageAndParams('/analysis_results', page, params))
    );
  }

  readAnalysisResultsChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_ANALYSIS_RESULT_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updateAnalysisResult(
    iri: string,
    payload: AnalysisResult
  ): Observable<AnalysisResult> {
    return this.rolesService.userHasRoleFilter<AnalysisResult>(
      'ROLE_MWS_ANALYSIS_RESULT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteAnalysisResult(iri: string): Observable<AnalysisResult> {
    return this.rolesService.userHasRoleFilter<AnalysisResult>(
      'ROLE_MWS_ANALYSIS_RESULT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
