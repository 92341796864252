import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { PasswordsActions } from '../actions/';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class PasswordsEffects {
  UpdatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordsActions.UpdatePassword),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.updatePassword(iri, payload).pipe(
          map(response => PasswordsActions.UpdatePasswordSuccess({ response })),
          catchError(response =>
            of(PasswordsActions.UpdatePasswordFail({ response }))
          )
        );
      })
    )
  );
  PasswordActionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordsActions.UpdatePasswordSuccess),
        map(() => {
          this.notifierService.show({
            type: 'success',
            message: 'Das Passwort wurde aktualisiert'
          });
        })
      ),
    { dispatch: false }
  );
  PasswordActionError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordsActions.UpdatePasswordFail),
        map(({ response }) => {
          const errors = response?.error['hydra:description'];
          const message =
            'Beim Ändern des Passwords sind Fehler aufgetreten:' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: AuthService,
    private notifierService: NotifierService
  ) {}
}
