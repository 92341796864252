import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';

import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  throwError
} from 'rxjs';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';
import {
  ErrorsObject,
  getErrorOfResponse
} from '../../utilities/error-utility.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-file-input',
  styleUrls: ['file-input.component.scss'],
  template: `
    <form [formGroup]="dlf" enctype="multipart/form-data">
      <div class="grid">
        <div class="column-14" [class.hidden]="!loading">
          <div class="row" *ngIf="uploadStatus$ | async as status">
            <div class="col-12 text-center">
              <span *ngIf="status.type === 1"> Lade hoch ... </span>
              <span *ngIf="status.type === 3"> Verarbeite Datei ... </span>
              <mat-progress-bar
                [mode]="status.type === 1 ? 'determinate' : 'indeterminate'"
                [value]="(status.loaded / status.total) * 100"
              ></mat-progress-bar>
            </div>
          </div>
        </div>

        <div class="column-11" [class.hidden]="loading || uploadedFile">
          <mat-form-field>
            <mat-label>{{ label }}</mat-label>

            <input
              style="cursor: pointer"
              matInput
              readonly
              type="text"
              #fileNameInput
              (click)="fileInput.click()"
            />

            <mat-icon
              matSuffix
              style="margin-right: 12px; cursor: pointer"
              *ngIf="fileNameInput.value"
              (click)="handleClearFile()"
              >clear
            </mat-icon>

            <mat-icon
              matSuffix
              style="margin-right: 12px"
              (click)="fileInput.click()"
              >attach_file
            </mat-icon>

            <mat-hint align="start" *ngIf="errors && errors[this.propertyName]"
              >{{ errors[this.propertyName].message }}
            </mat-hint>
          </mat-form-field>

          <input
            type="file"
            #fileInput
            style="display: none"
            [multiple]="multiple"
          />
        </div>
        <div class="column-13" [class.hidden]="loading || !uploadedFile">
          <mat-icon class="text-color-green">check</mat-icon>
          {{ uploadedFile }}
        </div>

        <div
          class="column-1 text-right"
          [class.hidden]="loading || !uploadedFile"
        >
          <button mat-flat-button mat-icon-button (click)="handleClearFile()">
            <mat-icon class="">close</mat-icon>
          </button>
        </div>

        <div
          class="column-3 m-ta--1 button-wrapper"
          [hidden]="maxFiles <= uploadedFiles.length"
        >
          <button
            *ngIf="!loading && !uploadedFile"
            mat-button
            [color]="_errors ? 'warn' : 'green'"
            [disabled]="!!!this.dlf.get('file').value || _errors"
            (click)="handleUpload()"
          >
            <mat-icon>upload</mat-icon>
          </button>

          <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
        </div>

        <div class="preview-wrapper column-14" *ngIf="!!uploadedFiles.length">
          <ul>
            <li *ngFor="let file of uploadedFiles" class="grid">
              <span class="icon column-1">
                <mat-icon>attach_file</mat-icon>
              </span>
              <span class="column-12"
                >{{ file.fileName }}
                <small>({{ file.size | bytes: 2 }})</small></span
              >
              <span class="column-1" *ngIf="allowDeletion">
                <mat-icon color="warn">delete_forever</mat-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </form>
    <!--    <pre>{{ _errors | json }}</pre>-->
  `
})
export class FileInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('attr.class')
  marginBottom = 'm-b--24';

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileNameInput') fileNameInput: ElementRef;

  @Input() multiple = false;
  @Input() hideButton = false;
  @Input() actionNameCreate: string;
  @Input() allowDeletion = true;
  @Input() entity$: BehaviorSubject<object>;
  @Input() errors: ErrorsObject;
  @Input() label: any;
  @Input() maxFiles = 10;
  @Input() propertyName: string;
  @Input() service: any;

  @Output() requestPatchParentForm: EventEmitter<
    { [k: string]: any } | any
  > = new EventEmitter();
  @Output() fileInputChanged: EventEmitter<void> = new EventEmitter();
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter();

  @Input() dispatchAction?: any;
  @Input() statusSelector?: any;
  @Input() uploadCompletedAction?: any;
  @Input() uploadFailAction?: any;
  @Input() additionalPayload?: {};
  @Input() preventAutoUpload?: boolean = false;
  uploadedFile: string = null;

  uploadStatus$: Observable<{ type: number; loaded: number; total?: number }>;

  _errors: any;
  dlf: FormGroup;
  file: File;
  loading = false;
  onDestroy$: Subject<any> = new Subject<any>();
  uploadedFiles: Array<any> = [];

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    fromEvent(this.fileInput.nativeElement, 'change')
      .pipe(
        takeUntil(this.onDestroy$),
        filter(
          (changeEvent: any) =>
            changeEvent.target.files && changeEvent.target.files.length
        )
      )
      .subscribe((changeEvent: any) => {
        this._errors = null;
        const file = changeEvent.target.files[0];
        this.dlf.patchValue({ file });
        this.fileNameInput.nativeElement.value = file.name;
        this.fileInputChanged.emit();
        if (!this.preventAutoUpload) {
          this.handleUpload();
        }
      });

    if (!!!this.dlf.get('file').value && !!this.uploadedFiles.length) {
      this.handleClearFile();
    }
  }

  initForm(): void {
    this.dlf = this.fb.group({
      file: this.fb.control(null)
    });

    if (this.entity$ && this.entity$.getValue()) {
      this.dlf.addControl('uuid', this.fb.control(this.entity$['@id']));
    }
  }

  handleUpload(additionalPayload = {}): void {
    if (this.additionalPayload) {
      Object.assign(additionalPayload, this.additionalPayload);
    }

    this.loading = true;
    if (this.dispatchAction) {
      this.store$.dispatch(
        this.dispatchAction({
          payload: { ...this.dlf.value, ...additionalPayload }
        })
      );
      if (this.uploadCompletedAction) {
        this.actions$
          .pipe(
            ofType(this.uploadCompletedAction),
            takeUntil(this.onDestroy$),
            take(1)
          )
          .subscribe(() => {
            this.uploadedFile = this.dlf.value.file.name;

            this.fileNameInput.nativeElement.value = null;
            this.dlf.get('file').setValue(null);
            this.loading = false;
          });
      }
      if (this.uploadFailAction) {
        this.actions$
          .pipe(
            ofType(this.uploadFailAction),
            takeUntil(this.onDestroy$),
            take(1)
          )
          .subscribe(response => {
            console.log('ERROR', response);
            this._errors = getErrorOfResponse(response);
            this.loading = false;
          });
      }

      // @ts-ignore status selectors must match the status object
      if (this.statusSelector) {
        this.uploadStatus$ = this.store$.select(this.statusSelector);
      }
    } else {
      const blob$ = this.service[this.actionNameCreate]({
        ...this.dlf.value,
        ...this.additionalPayload
      });
      console.log(this.additionalPayload);
      blob$
        .pipe(
          tap(response => console.log(response)),
          filter(response => !!response),
          catchError(error => throwError(error)),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          ({ body }: any) => {
            if (!body) {
              return;
            }
            this.uploadedFiles = [
              ...this.uploadedFiles,
              { ...body, fileName: this.fileNameInput.nativeElement.value }
            ];

            this.fileNameInput.nativeElement.value = null;
            this.dlf.get('file').setValue(null);
            this.requestPatchParentForm.emit({
              [this.propertyName]: body['@id']
            });

            // console.log(response);
            this.uploadComplete.emit(body);

            this.loading = false;
          },
          response => {
            this.loading = false;
            this._errors = response.error['hydra:description'];
          }
        );
    }
  }

  handleClearFile(dispatch = true): void {
    this.file = null;
    this._errors = null;
    this.dlf.removeControl('uuid');
    this.dlf.get('file').setValue(null);
    this.fileNameInput.nativeElement.value = null;
    this.uploadedFiles = [];
    if (dispatch) {
      this.requestPatchParentForm.emit({ [this.propertyName]: null });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
