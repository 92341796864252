import { createReducer, on } from '@ngrx/store';
import * as fromModuleModels from '../../models';
import { DataRecoveryCostsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.DataRecoveryCosts };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DataRecoveryCostsActions.ReadDataRecoveryCosts,
    DataRecoveryCostsActions.UpdateSellingPrices,
    DataRecoveryCostsActions.UpdateCheckedBySupervisor,
    DataRecoveryCostsActions.UpdatePurchasePrices,
    DataRecoveryCostsActions.UpdateSellingPricesFromTicket,
    DataRecoveryCostsActions.UpdatePurchasePricesFromTicket,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    DataRecoveryCostsActions.ReadDataRecoveryCostsSuccess,
    DataRecoveryCostsActions.UpdateSellingPricesSuccess,
    DataRecoveryCostsActions.UpdateCheckedBySupervisorSuccess,
    DataRecoveryCostsActions.UpdatePurchasePricesSuccess,
    DataRecoveryCostsActions.UpdateSellingPricesFromTicketSuccess,
    DataRecoveryCostsActions.UpdatePurchasePricesFromTicketSuccess,
    (state, { response }) => {
      const entities = { ...state.entities };
      const findElement = entities[response['@id']];
      if (!findElement) {
        entities[response['@id']] = response;
      } else {
        entities[response['@id']] = {
          ...entities[response['@id']],
          ...response
        };
      }
      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    DataRecoveryCostsActions.ReadDataRecoveryCostsFail,
    DataRecoveryCostsActions.UpdateSellingPricesFail,
    DataRecoveryCostsActions.UpdateCheckedBySupervisorFail,
    DataRecoveryCostsActions.UpdatePurchasePricesFail,
    DataRecoveryCostsActions.UpdateSellingPricesFromTicketFail,
    DataRecoveryCostsActions.UpdatePurchasePricesFromTicketFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
