import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_TAX = '[Master Data Module] Create Tax';
export const CREATE_TAX_SUCCESS = '[Master Data Module] Create Tax Success';
export const CREATE_TAX_FAIL = '[Master Data Module] Create Tax Fail';
export const CreateTax = createAction(
  CREATE_TAX,
  props<{ payload: fromModuleModels.Tax }>()
);
export const CreateTaxSuccess = createAction(
  CREATE_TAX_SUCCESS,
  props<{ response: fromModuleModels.Tax }>()
);
export const CreateTaxFail = createAction(
  CREATE_TAX_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_TAX = '[Master Data Module] Read Tax';
export const READ_TAX_SUCCESS = '[Master Data Module] Read Tax Success';
export const READ_TAX_FAIL = '[Master Data Module] Read Tax Fail';
export const ReadTax = createAction(
  READ_TAX,
  props<{ iri: string }>()
);
export const ReadTaxSuccess = createAction(
  READ_TAX_SUCCESS,
  props<{ response: fromModuleModels.Tax }>()
);
export const ReadTaxFail = createAction(
  READ_TAX_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_TAXES = '[Master Data Module] Read Taxes';
export const READ_TAXES_SUCCESS = '[Master Data Module] Read Taxes Success';
export const READ_TAXES_FAIL = '[Master Data Module] Read Taxes Fail';
export const ReadTaxes = createAction(
  READ_TAXES
);
export const ReadTaxesSuccess = createAction(
  READ_TAXES_SUCCESS,
  props<{ response: any }>()
);
export const ReadTaxesFail = createAction(
  READ_TAXES_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_TAX = '[Master Data Module] Update Tax';
export const UPDATE_TAX_SUCCESS = '[Master Data Module] Update Tax Success';
export const UPDATE_TAX_FAIL = '[Master Data Module] Update Tax Fail';
export const UpdateTax = createAction(
  UPDATE_TAX,
  props<{ iri: string, payload: fromModuleModels.Tax }>()
);
export const UpdateTaxSuccess = createAction(
  UPDATE_TAX_SUCCESS,
  props<{ response: fromModuleModels.Tax }>()
);
export const UpdateTaxFail = createAction(
  UPDATE_TAX_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_TAX = '[Master Data Module] Delete Tax';
export const DELETE_TAX_SUCCESS = '[Master Data Module] Delete Tax Success';
export const DELETE_TAX_FAIL = '[Master Data Module] Delete Tax Fail';

export const DeleteTax = createAction(
  DELETE_TAX,
  props<{ iri: string }>()
);

export const DeleteTaxSuccess = createAction(
  DELETE_TAX_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteTaxFail = createAction(
  DELETE_TAX_FAIL,
  props<{ response: HttpErrorResponse }>()
);
