import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {takeUntil} from 'rxjs/operators';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'storage-system-form',
  styleUrls: ['storage-system-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Speichersystem bearbeiten' : 'Neues Speichersystem anlegen' }}</span>
      </div>

      <div class="card__content p-a--24" [formGroup]="ssf">

        <div class="grid">
          <mat-form-field class="column-11">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" required>
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>

          <div class="column-3">
            <mat-slide-toggle formControlName="isActive" [checked]="true">Aktiv</mat-slide-toggle>
          </div>
          <mat-form-field class="column-14">
            <mat-label>Sortierung</mat-label>
            <input type="number" matInput formControlName="order">
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>


          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || ssf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="ssf.invalid || ssf.untouched" (click)="handleSubmit()" mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  `
})
export class StorageSystemFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<any>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromModuleModels.StorageSystem,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.StorageSystem,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  ssf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.ssf, preset);
      this.ssf.markAsUntouched();
    });
  }

  initForm() {
    this.ssf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      isActive: this.fb.control(false, [Validators.required]),
      order: this.fb.control(null)
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromModuleModels.StorageSystem = this.ssf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'StorageSystem'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'StorageSystem'});
  }
}
