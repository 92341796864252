import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { OperatingSystemsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { OperatingSystemsService } from '../../services/operating-systems.service';

@Injectable()
export class OperatingSystemsEffects {
  createOperatingSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatingSystemsActions.CreateOperatingSystem),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createOperatingSystem(payload).pipe(
          map(response =>
            OperatingSystemsActions.CreateOperatingSystemSuccess({ response })
          ),
          catchError(response =>
            of(OperatingSystemsActions.CreateOperatingSystemFail({ response }))
          )
        );
      })
    )
  );
  getOperatingSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatingSystemsActions.ReadOperatingSystem),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getOperatingSystem(iri).pipe(
          map(response =>
            OperatingSystemsActions.ReadOperatingSystemSuccess({ response })
          ),
          catchError(response =>
            of(OperatingSystemsActions.ReadOperatingSystemFail({ response }))
          )
        );
      })
    )
  );
  getOperatingSystems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatingSystemsActions.ReadOperatingSystems),
      map(action => action),
      switchMap(() => {
        return this.service.getOperatingSystems().pipe(
          map(response =>
            OperatingSystemsActions.ReadOperatingSystemsSuccess({ response })
          ),
          catchError(response =>
            of(OperatingSystemsActions.ReadOperatingSystemsFail({ response }))
          )
        );
      })
    )
  );
  updateOperatingSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatingSystemsActions.UpdateOperatingSystem),
      map(action => action),
      switchMap(payload => {
        return this.service.updateOperatingSystem(payload).pipe(
          map(response =>
            OperatingSystemsActions.UpdateOperatingSystemSuccess({ response })
          ),
          catchError(response =>
            of(OperatingSystemsActions.UpdateOperatingSystemFail({ response }))
          )
        );
      })
    )
  );
  deleteOperatingSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperatingSystemsActions.DeleteOperatingSystem),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteOperatingSystem(iri).pipe(
          map(() =>
            OperatingSystemsActions.DeleteOperatingSystemSuccess({ iri })
          ),
          catchError(response =>
            of(OperatingSystemsActions.DeleteOperatingSystemFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OperatingSystemsActions.DeleteOperatingSystemFail,
          OperatingSystemsActions.UpdateOperatingSystemFail,
          OperatingSystemsActions.ReadOperatingSystemsFail,
          OperatingSystemsActions.ReadOperatingSystemFail,
          OperatingSystemsActions.CreateOperatingSystemFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  gradeActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OperatingSystemsActions.CreateOperatingSystemSuccess,
        OperatingSystemsActions.UpdateOperatingSystemSuccess
      ),
      map(({ type }) => {
        const text =
          type === OperatingSystemsActions.CREATE_OPERATING_SYSTEM_SUCCESS
            ? 'Ein neues Betriebssystem wurde angelegt.'
            : 'Das Betriebssystem wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: OperatingSystemsService,
    private notifierService: NotifierService
  ) {}
}
