import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrderSpecialAgreementsSelectors, OrdersSelectors, ProductOrderItemsSelectors} from '../../store/selectors';
import {Order} from '../../models';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {
  ProductsSelectors,
  ProductUnitsSelectors,
  SpecialAgreementsSelectors
} from '../../../master-data/store/selectors';
import {ProductsActions, SpecialAgreementsActions} from '../../../master-data/store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {ProductOrderItemsActions} from '../../store';
import {Product} from '../../../master-data/models';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {Actions, ofType} from '@ngrx/effects';

@Component({
  selector: 'app-product-order-items',
  styleUrls: ['product-order-items.component.scss'],
  template: `
    <table class="bmo-table bmo-table-bordered bmo-table-rounded bmo-table-dense fw-bold pos-relative"
           [class.bmo-table-hover]="(productOrderItems$|async).length>0">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

      <tbody>
      <tr *ngFor="let product of productOrderItems$|async">
        <!--<pre>{{product|json}}</pre>-->
        <td>{{ product?.title }}</td>
        <td>{{ product?.product?.storageSystemManufacturer?.name }}</td>
        <td style="width: 10%;">{{product?.product?.storageSystemSize }} GB</td>
        <td style="width: 10%;">1x Stück</td>
        <td style="width: 40%;">
          {{ product?.netTotal?.value | number:'1.2-2' }} {{ product?.netTotal?.currency|currencyShortener }}
          <small class="text-muted">
            ({{ product?.grossTotal?.value | number:'1.2-2' }} {{ product?.grossTotal?.currency|currencyShortener }}
            brutto, {{ product?.taxRate.name }})</small>
        </td>
        <td style="width: 40px;" class="text-color-red text-center">
          <mat-icon (click)="handleRequestDeleteProductOrderItem(product)" class="cursor-pointer">delete_forever
          </mat-icon>
        </td>
      </tr>
      <tr *ngIf="(productOrderItems$|async).length<=0" class="text-center">
        <td colspan="6"> Keine Ersatzdatenträger hinzugefügt</td>
      </tr>
      </tbody>
    </table>

    <!--<pre>{{ productOrderItems | json }}</pre>-->
  `
})
export class ProductOrderItemsComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;
  productOrderItems$: Observable<Array<fromModuleModels.ProductOrderItem>>;
  order: Order;
  isLoading$: Observable<boolean>;

  unitsEntities: { [iri: string]: fromMasterDataModuleModels.ProductUnit };
  @Output() requestShowProductOrderItemForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestDeleteProductOrderItem: EventEmitter<string> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ProductOrderItemsSelectors.isLoading)

    ]);

    this.actions$.pipe(
      ofType(
        ProductOrderItemsActions.DeleteProductOrderItemSuccess,
        ProductOrderItemsActions.CreateProductOrderItemSuccess
      ),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.order) {
        this.loadProductOrderItems(this.order);
      }
    });
    this.loadUnits();
    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe(order => {
      this.order = order;
      this.loadProductOrderItems(order);
    });
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestDeleteProductOrderItem(product: fromModuleModels.ProductOrderItem): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieses Produkt wirklich entfernt werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(ProductOrderItemsActions.DeleteProductOrderItem({iri: product['@id']}));
      });
  }


  loadProductOrderItems(order): void {
    this.productOrderItems$ = this.store$.select(ProductOrderItemsSelectors.selectProductOrderItemsForOrder, {iri: order['@id']});
    this.store$.dispatch(ProductOrderItemsActions.ReadProductOrderItems({orderUuid: getUuidFromIri(order['@id'])}));
  }

  private loadUnits(): void {
    this.store$.select(ProductUnitsSelectors.selectProductUnitsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.unitsEntities = entities;
      });
    loadIfNotLoaded(this.store$, SpecialAgreementsSelectors.isLoaded, SpecialAgreementsActions.ReadSpecialAgreements());
  }

}
