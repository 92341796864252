export const getDescriptionOfShipmentState = (state: string): string => {
  switch (state.toLowerCase()) {
    case 'created':
    case 'vorgemerkt':
      return 'Paket wurde angelegt (Label noch nicht erstellt)';
    case 'confirmed':
    case 'bestätigt':
      return 'Paket hat nun ein Label und kann bzw. ist im Versand';
    case 'voided':
    case 'storniert':
      return 'Versand Paket wurde abgebrochen (Zustand Paket unklar)';
    case 'delivered':
    case 'zugestellt':
      return 'Paket ist eingegangen / zugestellt (muss manuell gesetzt werden)';
    default:
      return null;
  }
};
