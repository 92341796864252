import { AdministratorsEffects } from './administrators.effects';
import { AdministratorGroupsEffects } from './administrator-groups.effects';
import { FeedbackRequestsEffects } from './feedback-requests.effects';

export const effects: Array<any> = [
  AdministratorsEffects,
  AdministratorGroupsEffects,
  FeedbackRequestsEffects
];

export * from './administrators.effects';
export * from './administrator-groups.effects';
export * from './feedback-requests.effects';
