import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notifierService: NotifierService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const auth = this.authService;
    return next.handle(req).pipe(
      map(event => {
        if (
          (event instanceof HttpResponse ||
            event instanceof HttpHeaderResponse) &&
          event?.status === 401
        ) {
          this.authService.logout();
          this.notifierService.show({
            type: 'error',
            message: 'Fehler bei Authentifizierung. Bitte neu einloggen.'
          });
        } else {
          return event;
        }
      })
    );
  }
}
