import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { filter, takeUntil } from 'rxjs/operators';
import { PotentialAction } from '../../models';
import { TransitionsActions } from '../../../application-state/store/actions';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../auth/services/auth.service';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-action-box-to-dm-in-engineering-department',
  styleUrls: ['./action-box-to-dm-in-engineering-department.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Speichersystem in Technik?</div>
              <div class="sub-header col-auto">
                Wurde das Speichersys. in die Technik gebracht?
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition()"
              [disabled]="!action"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
            <!--<button class="decision-btn" mat-button color="red" (click)="handleMakeTransition2()">
              <mat-icon class="me-2">cancel</mat-icon>
              <span>Nein</span>
            </button>-->
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Warte auf SS in Technik:</div>
          <div class="sub-header col-auto">
            Datenträger muss zunächst zur Technik gebracht werden
          </div>
        </div>
      </div>
      <!-- <div class="additional-content">
         <div class="row">
           <div class="col"></div>
         </div>
       </div>-->
    </div>
  `
})
export class ActionBoxToDmInEngineeringDepartmentComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  action: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.action = order.potentialActions?.find(
          e =>
            e.transition.indexOf('to_dm_in_engineering_department') > -1 &&
            !e.error
        );
      });
  }

  handleMakeTransition(): void {
    if (!this.action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${this.action.workflow}&transition=${this.action.transition}`;
    const payload = {
      workflow: this.action.workflow,
      transition: this.action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  // TODO: gibt es ein Nein?
}
