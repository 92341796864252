import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  styleUrls: ['./form-error.component.scss'],
  template: `
    <ng-container *ngIf="localFromControl && localFromControl.hasError('backendError')">
      {{localFromControl.getError('backendError')}}
    </ng-container>
    <ng-container
      *ngIf="localFromControl && localFromControl.touched && localFromControl.hasError('required')">
      Dieses Feld muss ausgefüllt werden.
    </ng-container>
    <ng-container *ngIf="localFromControl && localFromControl.hasError('minlength')">
      Dieses Feld muss mindestens {{localFromControl.getError('minlength').requiredLength}} Zeichen lang sein.
    </ng-container>
    <ng-container *ngIf="localFromControl && localFromControl.hasError('min')">
      Der Wert muss mindestens {{localFromControl.getError('min').min}} groß sein.
    </ng-container>
    <ng-container *ngIf="localFromControl && localFromControl.hasError('maxlength')">
      Dieses Feld darf maximal {{localFromControl.getError('maxlength').requiredLength}} Zeichen lang sein.
    </ng-container>
    <ng-container *ngIf="localFromControl && localFromControl.hasError('email')">
      Dieses Feld muss eine valide E-Mail enthalten.
    </ng-container>
  `
})
export class FormErrorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  get localFromControl(): AbstractControl {
    if (this.formGroup && this.fieldName) {
      return this.formGroup.get(this.fieldName);
    } else {
      return null;
    }
  }

}
