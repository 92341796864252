import { createReducer, on } from '@ngrx/store';

import {
  ErrorsObject,
  getErrorsObjectForTransitionResponse
} from '../../../shared/utilities/error-utility.utility';
import { TransitionsActions, UserRoleActions } from '../actions';

export interface State {
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(TransitionsActions.MakeTransition, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(TransitionsActions.MakeTransitionSuccess, state => {
    return {
      ...state,
      loading: false
    };
  }),
  on(TransitionsActions.MakeTransitionFail, (state, { response }) => {
    const { error } = response;
    let errors = {};

    if (error.hasOwnProperty('violations')) {
      errors = getErrorsObjectForTransitionResponse(error);
    } else if (
      error.hasOwnProperty('@type') &&
      error['@type'] === 'hydra:Error'
    ) {
      alert(error['hydra:description']);
    } else {
      alert(error.message);
    }

    return {
      ...state,
      errors,
      loading: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
