import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { CustomerType } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerTypesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getCustomerTypes(): Observable<Array<CustomerType | any>> {
    return this.rolesService.userHasRoleFilter<Array<CustomerType | any>>(
      'ROLE_MWS_CUSTOMER_TYPE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/customer_types`)
    );
  }
}
