<app-dialog-header>
  <h2 style="min-width: 800px;">
    E-Mail senden
  </h2>
</app-dialog-header>


<div mat-dialog-content class="pos-relative" [formGroup]="form">
  <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
  <div class="row">
    <div class="col-4">
      <mat-list role="list" class="variable-list">
        <mat-list-item role="listitem" class="title">Verfügbare Variablen</mat-list-item>
        <mat-list-item role="listitem" *ngIf="(emailTemplateContexts$ | async)?.variableNames.length<=0">keine Variablen
          verfügbar
        </mat-list-item>
        <ng-container
          *ngFor="let variable of (emailTemplateContexts$ | async)?.variableNames">
          <mat-list-item
            role="listitem"
            *ngIf="variable === 'orderNumber'"
            (click)="copyTextToClipboard(variable)"
          >
            <mat-icon
              mat-list-icon>edit_note
            </mat-icon>
            <div class="brackets">
              <span>&#123;&#123;</span>
              <div mat-line>{{ variable }}</div>
              <span>&#125;&#125;</span>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
    <div class="col-8">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Betreff der E-Mail*</mat-label>
          <input matInput formControlName="subject" autocomplete="off"/>
          <mat-error>
            <app-form-error
              [fieldName]="'subject'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 body-editor">
        <app-text-editor formControlName="body"></app-text-editor>
        <mat-error>
          <app-form-error
            [fieldName]="'body'"
            [formGroup]="form"
          ></app-form-error>
        </mat-error>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-between">
  <button mat-button color="green" (click)="handleSendEmail()" [disabled]="form.invalid">
    <mat-icon class="m-r--8">email</mat-icon>
    E-Mail versenden
  </button>
</div>
