import { createReducer, on } from '@ngrx/store';
import { keyBy } from 'lodash-es';

import { FollowUpActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FollowUp, FollowUpDetail } from '../../models';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: FollowUp };
  detailEntities: { [iri: string]: FollowUpDetail };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  detailEntities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    FollowUpActions.CreateFollowUp,
    FollowUpActions.LoadFollowUps,
    FollowUpActions.LoadFollowUp,
    FollowUpActions.UpdateFollowUp,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    FollowUpActions.CreateFollowUpSuccess,
    FollowUpActions.UpdateFollowUpSuccess,
    (state, { response }) => {
      const detailEntities = {
        ...state.detailEntities,
        [response['@id']]: response
      };

      return {
        ...state,
        detailEntities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(FollowUpActions.LoadFollowUpsSuccess, (state: State, { response }) => {
    return {
      ...state,
      entities: keyBy(response['hydra:member'], '@id'),
      loading: false,
      loaded: true
    };
  }),
  on(FollowUpActions.LoadFollowUpSuccess, (state: State, { response }) => {
    return {
      ...state,
      detailEntities: {
        ...state.detailEntities,
        [response['@id']]: response
      },
      loading: false
    };
  }),
  on(FollowUpActions.DeleteFollowUpSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;
    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(
    FollowUpActions.CreateFollowUpFail,
    FollowUpActions.LoadFollowUpsFail,
    FollowUpActions.LoadFollowUpFail,
    FollowUpActions.UpdateFollowUpFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
