import { BindingOrdersLoadedGuard } from './binding-orders-loaded.guard';
import { DataMediumStatusLogLoadedGuard } from './data-medium-status-log-loaded.guard';
import { OrderCommentTagsLoadedGuard } from './order-comment-tags-loaded.guard';
import { OrderCommentsLoadedGuard } from './order-comments-loaded.guard';
import { OrderDataMediumsLoadedGuard } from './order-data-mediums-loaded.guard';
import { OrderLoadedGuard } from './order-loaded.guard';
import { OrderStateRangesLoadedGuard } from './order-state-ranges-loaded.guard';
import { OrderStatesLoadedGuard } from './order-states-loaded.guard';
import { OrdersLoadedGuard } from './orders-loaded.guard';
import { ProductOrderItemsLoadedGuard } from './product-order-items-loaded.guard';
import { ProofsOfDestructionLoadedGuard } from './proofs-of-destruction-loaded.guard';
import { SalesLoadedGuard } from './sales-loaded.guard';
import { SalesSummaryLoadedGuard } from './sales-summary-loaded.guard';
import { ServiceOrderItemsLoadedGuard } from './service-order-items-loaded.guard';

export const AllGuards = [
  BindingOrdersLoadedGuard,
  DataMediumStatusLogLoadedGuard,
  OrderCommentTagsLoadedGuard,
  OrderCommentsLoadedGuard,
  OrderDataMediumsLoadedGuard,
  OrderLoadedGuard,
  OrderStateRangesLoadedGuard,
  OrderStatesLoadedGuard,
  OrdersLoadedGuard,
  ProductOrderItemsLoadedGuard,
  ProofsOfDestructionLoadedGuard,
  SalesLoadedGuard,
  SalesSummaryLoadedGuard,
  ServiceOrderItemsLoadedGuard,
];

export * from './binding-orders-loaded.guard';
export * from './data-medium-status-log-loaded.guard';
export * from './order-comment-tags-loaded.guard';
export * from './order-comments-loaded.guard';
export * from './order-data-mediums-loaded.guard';
export * from './order-loaded.guard';
export * from './order-state-ranges-loaded.guard';
export * from './order-states-loaded.guard';
export * from './orders-loaded.guard';
export * from './product-order-items-loaded.guard';
export * from './proofs-of-destruction-loaded.guard';
export * from './sales-loaded.guard';
export * from './sales-summary-loaded.guard';
export * from './service-order-items-loaded.guard';
