import { createReducer, on } from '@ngrx/store';

import * as fromTicketsModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { TicketAttachmentsActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromTicketsModuleModels.TicketAttachment };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    TicketAttachmentsActions.ReadTicketAttachment,
    TicketAttachmentsActions.ReadTicketAttachments,
    TicketAttachmentsActions.DeleteTicketAttachment,
    TicketAttachmentsActions.CreateTicketAttachment,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    TicketAttachmentsActions.ReadTicketAttachmentSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    TicketAttachmentsActions.DownloadTicketAttachmentSuccess,
    (state, { response }) => {
      return {
        ...state,
        loading: false
      };
    }
  ),
  on(
    TicketAttachmentsActions.CreateTicketAttachmentSuccess,
    (state, { response }) => {
      // TODO set update to show upload progress
      if (!response || !response.body) {
        return {
          ...state,
          loading: true
        };
      }
      const entities = {
        [response.body['@id']]: response.body,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    TicketAttachmentsActions.ReadTicketAttachmentsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      return {
        ...state,
        entities: mergeEntities(items, null, state.entities),
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    TicketAttachmentsActions.DeleteTicketAttachmentSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removed, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    TicketAttachmentsActions.CreateTicketAttachmentFail,
    TicketAttachmentsActions.ReadTicketAttachmentFail,
    TicketAttachmentsActions.ReadTicketAttachmentsFail,
    TicketAttachmentsActions.DeleteTicketAttachmentFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
