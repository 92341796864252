import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Order} from '../../models';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {Actions, ofType} from '@ngrx/effects';
import {OrdersActions} from '../../store';
import * as fromOrdersModuleModels from '../../models';
import * as OrdersModuleActions from '../../store';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-device-details-edit-dialog',
  styleUrls: ['./device-details-edit-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Speichersystem bearbeiten</h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <app-order-storage-system-form
        [order]="data.order"
        (requestUpdateOrder)="handleUpdateOrder($event)"
      ></app-order-storage-system-form>
    </div>
  `
})
export class DeviceDetailsEditDialogComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder,
              private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<DeviceDetailsEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { order: Order }) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
    ]);

    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderFail),
      takeUntil(this.onDestroy$),
    ).subscribe((fail) => {
      if (fail?.response?.error) {
        this.notifierService.notify('error', fail?.response?.error['hydra:description']);
      }
    });
    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderSuccess),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.dialogRef.close();
    });


  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleUpdateOrder(payload: { iri: string, payload: fromOrdersModuleModels.Order }): void {
    this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateOrder(payload));
  }


}
