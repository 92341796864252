import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {ProductOrderItemsActions, ServiceOrderItemsActions} from '../store';
import {ServiceOrderItemsSelectors} from '../store/selectors';

@Injectable()
export class ServiceOrderItemsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const orderUuid = route.params.uuid;

    this.store.dispatch(ServiceOrderItemsActions.ReadServiceOrderItems({orderUuid}));

    return of(true);

    /*return this.loadServiceOrderItemsDone(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );*/
  }

  /*loadServiceOrderItemsDone(orderUuid: string): Observable<boolean> {
    return this.store.pipe(
      select(ServiceOrderItemsSelectors.selectIsLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(ServiceOrderItemsActions.ReadServiceOrderItems({ orderUuid }));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }*/
}
