import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

// ================================================================= Read

export const LOAD_FEEDBACK_REQUEST = '[Notifications] Load Feedback Request';
export const LOAD_FEEDBACK_REQUEST_SUCCESS = '[Notifications] Load Feedback Request Success';
export const LOAD_FEEDBACK_REQUEST_FAIL = '[Notifications] Load Feedback Request Fail';

export const LoadFeedbackRequest = createAction(
  LOAD_FEEDBACK_REQUEST,
  props<{ iri: string }>()
);

export const LoadFeedbackRequestSuccess = createAction(
  LOAD_FEEDBACK_REQUEST_SUCCESS,
  props<{ response: fromModuleModels.FeedbackRequest }>()
);

export const LoadFeedbackRequestFail = createAction(
  LOAD_FEEDBACK_REQUEST_FAIL,
  props<{ response: any }>()
);

export const LOAD_FEEDBACK_REQUESTS = '[Notifications] Load Feedback Requests';
export const LOAD_FEEDBACK_REQUESTS_SUCCESS = '[Notifications] Load Feedback Requests Success';
export const LOAD_FEEDBACK_REQUESTS_FAIL = '[Notifications] Load Feedback Requests Fail';

export const LoadFeedbackRequests = createAction(
  LOAD_FEEDBACK_REQUESTS
);

export const LoadFeedbackRequestsSuccess = createAction(
  LOAD_FEEDBACK_REQUESTS_SUCCESS,
  props<{ response: Array<fromModuleModels.FeedbackRequest> }>()
);

export const LoadFeedbackRequestsFail = createAction(
  LOAD_FEEDBACK_REQUESTS_FAIL,
  props<{ response: any }>()
);

export const LOAD_SEND_FEEDBACK_REQUESTS = '[Notifications] Load SendFeedback Requests';
export const LOAD_SEND_FEEDBACK_REQUESTS_SUCCESS = '[Notifications] Load SendFeedback Requests Success';
export const LOAD_SEND_FEEDBACK_REQUESTS_FAIL = '[Notifications] Load SendFeedback Requests Fail';

export const LoadSendFeedbackRequests = createAction(
  LOAD_SEND_FEEDBACK_REQUESTS
);

export const LoadSendFeedbackRequestsSuccess = createAction(
  LOAD_SEND_FEEDBACK_REQUESTS_SUCCESS,
  props<{ response: Array<fromModuleModels.FeedbackRequest> }>()
);

export const LoadSendFeedbackRequestsFail = createAction(
  LOAD_SEND_FEEDBACK_REQUESTS_FAIL,
  props<{ response: any }>()
);

// ================================================================= Update

export const UPDATE_FEEDBACK_REQUEST = '[Notifications] Update Feedback Request';
export const UPDATE_FEEDBACK_REQUEST_SUCCESS = '[Notifications] Update Feedback Request Success';
export const UPDATE_FEEDBACK_REQUEST_FAIL = '[Notifications] Update Feedback Request Fail';

export const UpdateFeedbackRequest = createAction(
  UPDATE_FEEDBACK_REQUEST,
  props<{ iri: string, feedback: string }>()
);

export const UpdateFeedbackRequestSuccess = createAction(
  UPDATE_FEEDBACK_REQUEST_SUCCESS,
  props<{ response: fromModuleModels.FeedbackRequest }>()
);

export const UpdateFeedbackRequestFail = createAction(
  UPDATE_FEEDBACK_REQUEST_FAIL,
  props<{ response: any }>()
);
