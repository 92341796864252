import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Observable} from 'rxjs';

import * as fromModuleModels from '../../models';
import * as fromCustomersModuleModels from '../../../customers/models';

@Component({
  selector: 'app-feedback-requests-list',
  styleUrls: ['feedback-requests-list.component.scss'],
  template: `

    <ng-container *ngIf="(notifications$ | async) as notifications">

      <ng-container *ngIf="(customerCommentsEntities$ | async) as cce">

        <ul class="list">
          <li *ngFor="let notification of notifications" mat-ripple
              (click)="handleRequestOpenReplyForm( notification)" style="position: relative;" class="p-l--32">
            <span class="badge--vip small" *ngIf="notification.vip">VIP</span>
            <span class="badge--express" *ngIf="notification.express"><span>Express</span></span>
            <!---->
            {{ getCommentTypeAcronym(notification.commentIri) }}
            :{{ (notification.comment.content || '')| stripTags | optionalSlice:50 }}
          </li>
        </ul>
      </ng-container>
    </ng-container>
  `
})
export class FeedbackRequestsListComponent {

  @Input()
  customerCommentsEntities$: Observable<{ iri: fromCustomersModuleModels.CustomerComment }>;

  @Input()
  notifications$: Observable<Array<fromModuleModels.FeedbackRequest>>;

  @Output()
  requestOpenReplyForm: EventEmitter<{ feedbackRequest: any }> = new EventEmitter<{ feedbackRequest: any }>();

  data: Observable<any>;

  constructor() {
  }

  getCommentTypeAcronym(iri: string): string {
    if (iri.indexOf('lead') > -1) {
      return 'L';
    } else if (iri.indexOf('ticket') > -1) {
      return 'T';
    } else if (iri.indexOf('order') > -1) {
      return 'A';
    } else if (iri.indexOf('customer_comments') > -1) {
      return 'K';
    } else {
      return '?';
    }
  }

  handleRequestOpenReplyForm(fb: fromModuleModels.FeedbackRequest): void {

    this.requestOpenReplyForm.emit({feedbackRequest: fb});
  }
}
