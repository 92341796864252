import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_DISCOUNT = '[Master Data] Create Discount';
export const CREATE_DISCOUNT_SUCCESS = '[Master Data] Create Discount Success';
export const CREATE_DISCOUNT_FAIL = '[Master Data] Create Discount Fail';
export const CreateDiscount = createAction(
  CREATE_DISCOUNT,
  props<{ payload: fromModuleModels.Discount }>()
);
export const CreateDiscountSuccess = createAction(
  CREATE_DISCOUNT_SUCCESS,
  props<{ response: fromModuleModels.Discount }>()
);
export const CreateDiscountFail = createAction(
  CREATE_DISCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_DISCOUNT = '[Master Data] Read Discount';
export const READ_DISCOUNT_SUCCESS = '[Master Data] Read Discount Success';
export const READ_DISCOUNT_FAIL = '[Master Data] Read Discount Fail';
export const ReadDiscount = createAction(
  READ_DISCOUNT,
  props<{ iri: string }>()
);
export const ReadDiscountSuccess = createAction(
  READ_DISCOUNT_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadDiscountFail = createAction(
  READ_DISCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DISCOUNTS = '[Master Data] Read Discounts';
export const READ_DISCOUNTS_SUCCESS = '[Master Data] Read Discounts Success';
export const READ_DISCOUNTS_FAIL = '[Master Data] Read Discounts Fail';
export const ReadDiscounts = createAction(
  READ_DISCOUNTS
);
export const ReadDiscountsSuccess = createAction(
  READ_DISCOUNTS_SUCCESS,
  props<{ response: any }>()
);
export const ReadDiscountsFail = createAction(
  READ_DISCOUNTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_DISCOUNT = '[Master Data] Update Discount';
export const UPDATE_DISCOUNT_SUCCESS = '[Master Data] Update Discount Success';
export const UPDATE_DISCOUNT_FAIL = '[Master Data] Update Discount Fail';
export const UpdateDiscount = createAction(
  UPDATE_DISCOUNT,
  props<{ iri: string, payload: fromModuleModels.Discount }>()
);
export const UpdateDiscountSuccess = createAction(
  UPDATE_DISCOUNT_SUCCESS,
  props<{ response: fromModuleModels.Discount }>()
);
export const UpdateDiscountFail = createAction(
  UPDATE_DISCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_DISCOUNT = '[Master Data] Delete Discount';
export const DELETE_DISCOUNT_SUCCESS = '[Master Data] Delete Discount Success';
export const DELETE_DISCOUNT_FAIL = '[Master Data] Delete Discount Fail';
export const DeleteDiscount = createAction(
  DELETE_DISCOUNT,
  props<{ iri: string }>()
);
export const DeleteDiscountSuccess = createAction(
  DELETE_DISCOUNT_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteDiscountFail = createAction(
  DELETE_DISCOUNT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
