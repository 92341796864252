import { TicketsViewComponent } from './tickets-view/tickets-view.component';
import { TicketViewComponent } from './ticket-view/ticket-view.component';

export const AllContainers = [
  TicketViewComponent,
  TicketsViewComponent
];

export * from './tickets-view/tickets-view.component';
export * from './ticket-view/ticket-view.component';
