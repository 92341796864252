import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrdersActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromModuleModels.Order;
  entities: { [iri: string]: fromModuleModels.Order };
  detailedEntities: { [iri: string]: fromModuleModels.Order };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  totalItems: number;
}

const initialState = {
  entities: {},
  detailedEntities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    OrdersActions.ReadOrder,
    OrdersActions.ReadOrders,
    OrdersActions.ReadOrderChunk,
    OrdersActions.UpdateOrder,
    OrdersActions.UpdateOrderAnalysisResult,
    OrdersActions.UpdateDataRecoveryLocation,
    OrdersActions.ReadBindingOrderPDF,
    OrdersActions.ReadProofOfDestructionPDF,
    // OrdersActions.UpdateDisposalType,
    OrdersActions.UpdateAnalysisLocation,
    OrdersActions.DeleteOrder,
    OrdersActions.MailOrder,
    OrdersActions.SendBindingOrder,
    OrdersActions.SendProofOfDestructionPDF,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    OrdersActions.ReadOrderSuccess,
    OrdersActions.MailOrderSuccess,
    OrdersActions.SendProofOfDestructionPDFSuccess,
    OrdersActions.SendBindingOrderSuccess,
    OrdersActions.UpdateOrderSuccess,
    OrdersActions.UpdateOrderAnalysisResultSuccess,
    OrdersActions.UpdateDataRecoveryLocationSuccess,
    OrdersActions.UpdateAnalysisLocationSuccess,
    // OrdersActions.UpdateDisposalType,
    (state, { response }) => {
      return {
        ...state,
        entities: mergeEntities([response], null, state.entities),
        detailedEntities: mergeEntities(
          [response],
          null,
          state.detailedEntities
        ),
        errors: {},
        loading: false
      };
    }
  ),
  on(
    OrdersActions.ReadOrdersSuccess,
    OrdersActions.ReadOrderChunkSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      return {
        ...state,
        entities: mergeEntities(items, null, state.entities),
        errors: {},
        loading: false,
        loaded: true
      };
    }
  ),
  on(OrdersActions.UpdateOrderSuccess, (state, { response }) => {
    return {
      ...state,
      entities: mergeEntities([response], null, state.entities),
      detailedEntities: mergeEntities([response], null, state.detailedEntities),
      current: response,
      errors: {},
      loading: false
    };
  }),
  on(OrdersActions.DeleteOrderSuccess, (state, { iri }) => {
    const { [iri]: removedItem1, ...entities }: any = state.entities;
    const {
      [iri]: removedItem3,
      ...detailedEntities
    }: any = state.detailedEntities;

    return {
      ...state,
      entities,
      detailedEntities,
      errors: {},
      loading: false
    };
  }),
  on(
    OrdersActions.ReadBindingOrderPDFSuccess,
    OrdersActions.ReadProofOfDestructionPDFSuccess,
    state => {
      return {
        ...state,
        loading: false
      };
    }
  ),
  on(
    OrdersActions.MailOrderFail,
    OrdersActions.SendProofOfDestructionPDFFail,
    OrdersActions.UpdateOrderFail,
    OrdersActions.DeleteOrderFail,
    OrdersActions.UpdateOrderAnalysisResultFail,
    OrdersActions.UpdateDataRecoveryLocationFail,
    OrdersActions.UpdateDisposalTypeFail,
    OrdersActions.UpdateAnalysisLocationFail,
    OrdersActions.SendBindingOrderFail,
    OrdersActions.ReadBindingOrderPDFFail,
    OrdersActions.ReadProofOfDestructionPDFFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(OrdersActions.ResetCurrentOrder, state => {
    return {
      ...state,
      current: null
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
