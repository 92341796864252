import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_EMAIL_TEMPLATE = '[Master Data Module] Create Email Template';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = '[Master Data Module] Create Email Template Success';
export const CREATE_EMAIL_TEMPLATE_FAIL = '[Master Data Module] Create Email Template Fail';

export const CreateEmailTemplate = createAction(
  CREATE_EMAIL_TEMPLATE,
  props<{ payload: fromModuleModels.EmailTemplate }>()
);

export const CreateEmailTemplateSuccess = createAction(
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  props<{ response: any }>()
);

export const CreateEmailTemplateFail = createAction(
  CREATE_EMAIL_TEMPLATE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_EMAIL_TEMPLATE = '[Master Data Module] Read Email Template';
export const READ_EMAIL_TEMPLATE_SUCCESS = '[Master Data Module] Read Email Template Success';
export const READ_EMAIL_TEMPLATE_FAIL = '[Master Data Module] Read Email Template Fail';

export const ReadEmailTemplate = createAction(
  READ_EMAIL_TEMPLATE,
  props<{ iri: string }>()
);

export const ReadEmailTemplateSuccess = createAction(
  READ_EMAIL_TEMPLATE_SUCCESS,
  props<{ response: any }>()
);

export const ReadEmailTemplateFail = createAction(
  READ_EMAIL_TEMPLATE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_EMAIL_TEMPLATES = '[Master Data Module] Read Email Templates';
export const READ_EMAIL_TEMPLATES_SUCCESS = '[Master Data Module] Read Email Templates Success';
export const READ_EMAIL_TEMPLATES_FAIL = '[Master Data Module] Read Email Templates Fail';

export const ReadEmailTemplates = createAction(
  READ_EMAIL_TEMPLATES,
  (payload: { page?: number, params?: { [p: string]: any } } = {}) => ({...payload})
);

export const ReadEmailTemplatesSuccess = createAction(
  READ_EMAIL_TEMPLATES_SUCCESS,
  props<{ response: any }>()
);

export const ReadEmailTemplatesFail = createAction(
  READ_EMAIL_TEMPLATES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read Chunk


export const READ_EMAIL_TEMPLATES_CHUNK = '[Master Data] Read Email Templates Chunk';
export const READ_EMAIL_TEMPLATES_CHUNK_SUCCESS = '[Master Data] Read Email Templates Chunk Success';
export const READ_EMAIL_TEMPLATES_CHUNK_FAIL = '[Master Data] Read Email Templates Chunk Fail';

export const ReadEmailTemplatesChunk = createAction(
  READ_EMAIL_TEMPLATES_CHUNK,
  props<{ uri: string }>()
);

export const ReadEmailTemplatesChunkSuccess = createAction(
  READ_EMAIL_TEMPLATES_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadEmailTemplatesChunkFail = createAction(
  READ_EMAIL_TEMPLATES_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_EMAIL_TEMPLATE = '[Master Data Module] Update Email Template';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = '[Master Data Module] Update Email Template Success';
export const UPDATE_EMAIL_TEMPLATE_FAIL = '[Master Data Module] Update Email Template Fail';

export const UpdateEmailTemplate = createAction(
  UPDATE_EMAIL_TEMPLATE,
  props<{ iri: string, payload: fromModuleModels.EmailTemplate }>()
);

export const UpdateEmailTemplateSuccess = createAction(
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  props<{ response: fromModuleModels.EmailTemplate }>()
);

export const UpdateEmailTemplateFail = createAction(
  UPDATE_EMAIL_TEMPLATE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_EMAIL_TEMPLATE = '[Master Data Module] Delete Email Template';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = '[Master Data Module] Delete Email Template Success';
export const DELETE_EMAIL_TEMPLATE_FAIL = '[Master Data Module] Delete Email Template Fail';

export const DeleteEmailTemplate = createAction(
  DELETE_EMAIL_TEMPLATE,
  props<{ iri: string }>()
);

export const DeleteEmailTemplateSuccess = createAction(
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteEmailTemplateFail = createAction(
  DELETE_EMAIL_TEMPLATE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
