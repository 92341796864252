import {createSelector} from '@ngrx/store';

import {OFFER_ITEMS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectOfferItemsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[OFFER_ITEMS_FEATURE_KEY]
);

export const selectOfferItemsEntities = createSelector(
  selectOfferItemsState,
  (state) => state.entities
);

export const selectOfferItems = createSelector(
  selectOfferItemsEntities,
  (customers) => Object.keys(customers).map(iri => customers[iri])
);

export const selectOfferItemByIndex = createSelector(
  selectOfferItemsEntities,
  (customers, props) => customers[props.iri]
);

export const selectCurrentOfferItem = createSelector(
  selectOfferItemsState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectOfferItemsState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectOfferItemsEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectOfferItemsState,
  state => state.totalItems
);
export const selectOfferItemPagination = createSelector(
  selectOfferItemsState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectOfferItemPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectOfferItemPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectOfferItemPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectOfferItemPagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectOfferItemPagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectOfferItemsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectOfferItemsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectOfferItemsErrors = createSelector(
  selectOfferItemsState,
  (state) => state.errors
);
