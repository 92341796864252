import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import {GenericOrderItem} from '../../models/generic-order-item.interface';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_GENERIC_ORDER_ITEM = '[Orders Module] Create Generic Order Item';
export const CREATE_GENERIC_ORDER_ITEM_SUCCESS = '[Orders Module] Create Generic Order Item Success';
export const CREATE_GENERIC_ORDER_ITEM_FAIL = '[Orders Module] Create Generic Order Item Fail';

export const CreateGenericOrderItem = createAction(
  CREATE_GENERIC_ORDER_ITEM,
  props<{ payload: GenericOrderItem }>()
);

export const CreateGenericOrderItemSuccess = createAction(
  CREATE_GENERIC_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const CreateGenericOrderItemFail = createAction(
  CREATE_GENERIC_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_GENERIC_ORDER_ITEM = '[Orders Module] Read Generic Order Item';
export const READ_GENERIC_ORDER_ITEM_SUCCESS = '[Orders Module] Read Generic Order Item Success';
export const READ_GENERIC_ORDER_ITEM_FAIL = '[Orders Module] Read Generic Order Item Fail';

export const ReadGenericOrderItem = createAction(
  READ_GENERIC_ORDER_ITEM,
  props<{ iri: string }>()
);

export const ReadGenericOrderItemSuccess = createAction(
  READ_GENERIC_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadGenericOrderItemFail = createAction(
  READ_GENERIC_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_GENERIC_ORDER_ITEMS = '[Orders Module] Read Generic Order Items';
export const READ_GENERIC_ORDER_ITEMS_SUCCESS = '[Orders Module] Read Generic Order Items Success';
export const READ_GENERIC_ORDER_ITEMS_FAIL = '[Orders Module] Read Generic Order Items Fail';

export const ReadGenericOrderItems = createAction(
  READ_GENERIC_ORDER_ITEMS,
  props<{ orderUuid: string }>()
);

export const ReadGenericOrderItemsSuccess = createAction(
  READ_GENERIC_ORDER_ITEMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadGenericOrderItemsFail = createAction(
  READ_GENERIC_ORDER_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_GENERIC_ORDER_ITEM = '[Orders Module] Update Generic Order Item';
export const UPDATE_GENERIC_ORDER_ITEM_SUCCESS = '[Orders Module] Update Generic Order Item Success';
export const UPDATE_GENERIC_ORDER_ITEM_FAIL = '[Orders Module] Update Generic Order Item Fail';

export const UpdateGenericOrderItem = createAction(
  UPDATE_GENERIC_ORDER_ITEM,
  props<{ iri: string, payload: GenericOrderItem }>()
);

export const UpdateGenericOrderItemSuccess = createAction(
  UPDATE_GENERIC_ORDER_ITEM_SUCCESS,
  props<{ response: GenericOrderItem }>()
);

export const UpdateGenericOrderItemFail = createAction(
  UPDATE_GENERIC_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_GENERIC_ORDER_ITEM = '[Orders Module] Delete Generic Order Item';
export const DELETE_GENERIC_ORDER_ITEM_SUCCESS = '[Orders Module] Delete Generic Order Item Success';
export const DELETE_GENERIC_ORDER_ITEM_FAIL = '[Orders Module] Delete Generic Order Item Fail';

export const DeleteGenericOrderItem = createAction(
  DELETE_GENERIC_ORDER_ITEM,
  props<{ iri: string }>()
);

export const DeleteGenericOrderItemSuccess = createAction(
  DELETE_GENERIC_ORDER_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteGenericOrderItemFail = createAction(
  DELETE_GENERIC_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
