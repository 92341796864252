import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {CancellationInvoicesActions, CorrectionInvoicesActions} from '../actions/';
import {CorrectionInvoicesService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {RouterActions} from '../../../application-state/store/actions';
import {NotifierService} from "angular-notifier";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class CorrectionInvoicesEffects {

  constructor(private actions$: Actions, private service: CorrectionInvoicesService, private notifierService: NotifierService) {
  }

  CreateCorrectionInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.CreateCorrectionInvoice),
    switchMap(({payload}) => {
      return this.service.createCorrectionInvoice(payload).pipe(
        map((response) => CorrectionInvoicesActions.CreateCorrectionInvoiceSuccess({response})),
        catchError(response => of(CorrectionInvoicesActions.CreateCorrectionInvoiceFail({response})))
      );
    })
  ));

  ReadCorrectionInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.ReadCorrectionInvoice),
    switchMap(({iri}) => {
      return this.service.readCorrectionInvoice(iri).pipe(
        map((response => CorrectionInvoicesActions.ReadCorrectionInvoiceSuccess({response}))),
        catchError(response => of(CorrectionInvoicesActions.ReadCorrectionInvoiceFail({response})))
      );
    })
  ));

  ReadCorrectionInvoices$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.ReadCorrectionInvoices),
    switchMap(({page, params}) => {
      return this.service.readCorrectionInvoices(page, params).pipe(
        map((response) => CorrectionInvoicesActions.ReadCorrectionInvoicesSuccess({response})),
        catchError(response => of(CorrectionInvoicesActions.ReadCorrectionInvoicesFail({response})))
      );
    })
  ));

  ReadPDF$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.ReadPDF),
    switchMap(({iri}) => {
      return this.service.readCorrectionInvoiceAsPdf(iri).pipe(
        map((response: any) => CorrectionInvoicesActions.ReadPDFSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CorrectionInvoicesActions.ReadPDFFail({response})))
      );
    })
  ));
  SuccessDownloadAction = createEffect(() => this.actions$.pipe(
    ofType(
      CorrectionInvoicesActions.ReadPDFSuccess,
    ),
    map(({response: {contentUrl, fileName}}) => {
      const a = document.createElement('a');
      a.href = contentUrl;
      a.download = fileName; // File name Here
      a.click(); // Downloaded file
      a.remove();
    })
  ), {dispatch: false});


  ReadCorrectionInvoiceChunk$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.ReadCorrectionInvoiceChunk),
    switchMap(({uri}) => {
      return this.service.readCorrectionInvoiceChunk(uri).pipe(
        map((response) => CorrectionInvoicesActions.ReadCorrectionInvoiceChunkSuccess({response})),
        catchError(response => of(CorrectionInvoicesActions.ReadCorrectionInvoiceChunkFail({response})))
      );
    })
  ));

  UpdateCorrectionInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.UpdateCorrectionInvoice),
    switchMap(({iri, payload}) => {
      return this.service.updateCorrectionInvoice(iri, payload).pipe(
        map((response: fromModuleModels.CorrectionInvoice) => CorrectionInvoicesActions.UpdateCorrectionInvoiceSuccess({response})),
        catchError((response) => of(CorrectionInvoicesActions.UpdateCorrectionInvoiceFail({response})))
      );
    })
  ));
  SendMail$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.SendMail),
    switchMap(({iri, payload}) => {
      return this.service.sendMail(iri, payload).pipe(
        map((response) => CorrectionInvoicesActions.SendMailSuccess({response})),
        catchError(response => of(CorrectionInvoicesActions.SendMailFail({response})))
      );
    })
  ));

  BookCorrectionInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.BookCorrectionInvoice),
    switchMap(({iri}) => {
      return this.service.bookCorrectionInvoice(iri).pipe(
        map(() => CorrectionInvoicesActions.BookCorrectionInvoiceSuccess({invoiceIri: iri})),
        catchError((response) => of(CorrectionInvoicesActions.BookCorrectionInvoiceFail({response})))
      );
    })
  ));

  BookPayOut$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.BookCorrectionInvoicePayOut),
    switchMap(({iri, payload}) => {
      return this.service.bookCorrectionInvoicePayOut(iri, payload).pipe(
        map(response => CorrectionInvoicesActions.BookCorrectionInvoicePayOutSuccess({response, invoiceIri: iri})),
        catchError((response) => of(CorrectionInvoicesActions.BookCorrectionInvoicePayOutFail({response})))
      );
    })
  ));

  DeleteCorrectionInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.DeleteCorrectionInvoice),
    switchMap(({iri}) => {
      return this.service.deleteCorrectionInvoice(iri).pipe(
        map(() => CorrectionInvoicesActions.DeleteCorrectionInvoiceSuccess({iri})),
        catchError((response) => of(CorrectionInvoicesActions.DeleteCorrectionInvoiceFail({response})))
      );
    })
  ));

  InvoiceActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CorrectionInvoicesActions.SendMailSuccess,
      CorrectionInvoicesActions.CreateCorrectionInvoiceSuccess,
      CorrectionInvoicesActions.UpdateCorrectionInvoiceSuccess,
      CorrectionInvoicesActions.BookCorrectionInvoiceSuccess,
      CorrectionInvoicesActions.DeleteCorrectionInvoiceSuccess,
    ),
    map(({type}) => {

      const textOptions = {
        [CorrectionInvoicesActions.CREATE_CORRECTION_INVOICE_SUCCESS]: 'Die Korrekturrechnung wurde erstellt.',
        [CorrectionInvoicesActions.UPDATE_CORRECTION_INVOICE_SUCCESS]: 'Die Korrekturrechnung wurde aktualisiert.',
        [CorrectionInvoicesActions.BOOK_CORRECTION_INVOICE_SUCCESS]: 'Die Korrekturrechnung wurde gebucht.',
        [CorrectionInvoicesActions.DELETE_CORRECTION_INVOICE_SUCCESS]: 'Die Korrekturrechnung wurde gelöscht.',
        [CorrectionInvoicesActions.SEND_MAIL_SUCCESS]: 'Die E-Mail wurde gesendet.',
      };
      this.notifierService.show({type: 'success', message: textOptions[type]});

    })
  ), {dispatch: false});
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      CorrectionInvoicesActions.SendMailFail,
      CorrectionInvoicesActions.CreateCorrectionInvoiceFail,
      CorrectionInvoicesActions.UpdateCorrectionInvoiceFail,
      CorrectionInvoicesActions.BookCorrectionInvoiceFail,
      CorrectionInvoicesActions.DeleteCorrectionInvoiceFail,
    ),
    map(({type, response}) => {
      const errors = response?.error['hydra:description'];

      const textOptions = {
        [CorrectionInvoicesActions.CREATE_CORRECTION_INVOICE_FAIL]: 'Beim Erstellen der Korrekturrechnung sind Fehler aufgetreten:',
        [CorrectionInvoicesActions.UPDATE_CORRECTION_INVOICE_FAIL]: 'Beim Aktualisieren der Korrekturrechnung sind Fehler aufgetreten:',
        [CorrectionInvoicesActions.BOOK_CORRECTION_INVOICE_FAIL]: 'Beim Aktualisieren der Korrekturrechnung sind Fehler aufgetreten:',
        [CorrectionInvoicesActions.DELETE_CORRECTION_INVOICE_FAIL]: 'Beim Aktualisieren der Korrekturrechnung sind Fehler aufgetreten:',
        [CorrectionInvoicesActions.SEND_MAIL_FAIL]: 'Beim senden der E-Mail sind Fehler aufgetreten:',
      };
      this.notifierService.show({type: 'error', message: textOptions[type] + errors});

    })
  ), {dispatch: false});

  DeleteCorrectionInvoiceSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CorrectionInvoicesActions.DeleteCorrectionInvoiceSuccess),
    map(() => RouterActions.Go({path: ['invoices']}))
  ));
}
