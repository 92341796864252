import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AnalysisResultCategoriesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { AnalysisResultCategoriesService } from '../../services/analysis-result-categories.service';

@Injectable()
export class AnalysisResultCategoriesEffects {
  createAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultCategoriesActions.CreateAnalysisResultCategory),
      switchMap(({ payload }) => {
        return this.service.createAnalysisResultCategory(payload).pipe(
          map((response: any) =>
            AnalysisResultCategoriesActions.CreateAnalysisResultCategorySuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AnalysisResultCategoriesActions.CreateAnalysisResultCategoryFail({
                response
              })
            )
          )
        );
      })
    )
  );
  readAnalysisResultCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultCategoriesActions.ReadAnalysisResultCategory),
      switchMap(({ iri }) => {
        return this.service.readAnalysisResultCategory(iri).pipe(
          map((response: any) =>
            AnalysisResultCategoriesActions.ReadAnalysisResultCategorySuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AnalysisResultCategoriesActions.ReadAnalysisResultCategoryFail({
                response
              })
            )
          )
        );
      })
    )
  );
  readAnalysisResultCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultCategoriesActions.ReadAnalysisResultCategories),
      switchMap(() => {
        return this.service.readAnalysisResultCategories().pipe(
          map((response: any) =>
            AnalysisResultCategoriesActions.ReadAnalysisResultCategoriesSuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AnalysisResultCategoriesActions.ReadAnalysisResultCategoriesFail({
                response
              })
            )
          )
        );
      })
    )
  );
  updateAnalysisResultCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultCategoriesActions.UpdateAnalysisResultCategory),
      switchMap(({ iri, payload }) => {
        return this.service.updateAnalysisResultCategory(iri, payload).pipe(
          map((response: any) =>
            AnalysisResultCategoriesActions.UpdateAnalysisResultCategorySuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AnalysisResultCategoriesActions.UpdateAnalysisResultCategoryFail({
                response
              })
            )
          )
        );
      })
    )
  );
  deleteAnalysisResultCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisResultCategoriesActions.DeleteAnalysisResultCategory),
      switchMap(({ iri }) => {
        return this.service.deleteAnalysisResultCategory(iri).pipe(
          map(() =>
            AnalysisResultCategoriesActions.DeleteAnalysisResultCategorySuccess(
              { iri }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AnalysisResultCategoriesActions.DeleteAnalysisResultCategoryFail({
                response
              })
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AnalysisResultCategoriesActions.DeleteAnalysisResultCategoryFail,
          AnalysisResultCategoriesActions.UpdateAnalysisResultCategoryFail,
          AnalysisResultCategoriesActions.ReadAnalysisResultCategoriesFail,
          AnalysisResultCategoriesActions.ReadAnalysisResultCategoryFail,
          AnalysisResultCategoriesActions.CreateAnalysisResultCategoryFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: AnalysisResultCategoriesService,
    private notifierService: NotifierService
  ) {}
}
