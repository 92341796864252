import {createSelector} from '@ngrx/store';

import {CUSTOMER_REPLACEMENT_STOCK_ITEMS_FEATURE_KEY, selectWarehouseModuleState} from '../reducers';

export const selectCustomerReplacementStockItemsState = createSelector(
  selectWarehouseModuleState,
  (moduleState) => moduleState[CUSTOMER_REPLACEMENT_STOCK_ITEMS_FEATURE_KEY]
);

export const selectCustomerReplacementStockItemsEntities = createSelector(
  selectCustomerReplacementStockItemsState,
  (state) => state.entities
);

export const selectCustomerReplacementStockItems = createSelector(
  selectCustomerReplacementStockItemsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectCustomerReplacementStockItemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectCustomerReplacementStockItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerReplacementStockItemsState,
  (state) => state.loaded
);
