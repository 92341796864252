import { Component, Inject, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AbstractApiService } from '../../../shared/services';
import { TransitionLogEntry } from '../../models/transition-log-entry.interface';
import { getErrorOfResponse } from '../../../shared/utilities/error-utility.utility';
import { Order } from '../../models';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';

@Component({
  selector: 'app-order-transition-log-dialog',
  styleUrls: ['./order-transition-log-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Auftragszustände</h2>
    </app-dialog-header>
    <div mat-dialog-content style="min-height: 600px;" class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div class="alert alert-danger" *ngIf="backendError">
        Der Abruf der Log ist mit folgendem Fehler schiefgegangen:
        {{ backendError }}
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Zustände</th>
            <th>Person</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transitionState of transitionLog">
            <td>{{ transitionState.createdAt | momentDateWithTime }}</td>
            <td>{{ transitionState.status | keys | joinWith: ', ' }}</td>
            <td>
              {{ transitionState.createdBy?.firstName }}
              {{ transitionState.createdBy?.middleName }}
              {{ transitionState.createdBy?.lastName }}
            </td>
          </tr>
        </tbody>
      </table>

      <!--      <pre>{{shipment|json}}</pre>-->
    </div>
  `
})
export class OrderTransitionLogDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  transitionLog: Array<TransitionLogEntry> = [];
  backendError: string | null = null;
  order: Order;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order$: Observable<Order>;
    },
    private dialogRef: MatDialogRef<OrderTransitionLogDialogComponent>,
    private apiService: AbstractApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.data.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(order => {
        this.order = order;
        this.loadLog(extractIri(order));
      });
  }

  loadLog(orderIri: string): void {
    this.isLoading$.next(true);
    // console.log(this.data);
    this.apiService
      .getObject(orderIri + '/order_status_logs?pagination=false', true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          // console.log(response);
          this.transitionLog = (response['hydra:member'] as Array<
            TransitionLogEntry
          >).sort(sortByCreatedAtDate);
          this.isLoading$.next(false);
          // console.log(response);
          this.backendError = null;
        },
        fail => {
          console.log(fail);
          this.backendError = getErrorOfResponse(fail).message;
          this.isLoading$.next(false);
        }
      );
  }
}
