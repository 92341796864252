import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { Symptom } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class SymptomsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createSymptom(payload: Symptom): Observable<Symptom> {
    return this.rolesService.userHasRoleFilter<Symptom>(
      'ROLE_MWS_SYMPTOM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/symptoms`, payload)
    );
  }

  readSymptom(iri: string): Observable<Symptom> {
    return this.rolesService.userHasRoleFilter<Symptom>(
      'ROLE_MWS_SYMPTOM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readSymptoms(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SYMPTOM_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/symptoms`)
    );
  }

  updateSymptom(iri: string, payload: Symptom): Observable<Symptom> {
    return this.rolesService.userHasRoleFilter<Symptom>(
      'ROLE_MWS_SYMPTOM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteSymptom(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SYMPTOM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
