import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { AddCustomerHardwareDialogComponent } from '../../../warehouse/components';
import { MatDialog } from '@angular/material/dialog';
import { extractIri } from '../../utilities/objects.utility';
import { OrdersActions } from '../../../orders/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions
} from '../../../warehouse/store';
import { AuthService } from '../../../auth/services/auth.service';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';

@Component({
  selector: 'app-action-box-go-to-warehouse',
  styleUrls: ['./action-box-go-to-warehouse.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Speichersystem eingegangen?</div>
              <div class="sub-header col-auto text-color-red" *ngIf="isDelayed">
                Verspätet!
              </div>
              <div class="sub-header col-auto">
                Ist das Speichersystem eingegangen? Bitte Speichersystem
                einlagern!
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              mat-button
              color="green"
              (click)="handleClickGoToWarehouse()"
            >
              <mat-icon class="m-r--8">keyboard_arrow_right</mat-icon>
              <span>Datenträger einlagern</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Einlagerung Datenträger</div>
          <div class="sub-header col-auto">
            Warte auf Einlagerung des Datenträgers
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxGoToWarehouseComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;
  @Input() inputType: ActionBoxInputTypes;
  isDelayed = false;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private actions$: Actions,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.isDelayed = !!order.potentialActions?.find(
          e => e.transition.indexOf('delayed_to') > -1 && !e.error
        );
        this.order = order;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleClickGoToWarehouse(): void {
    this.dialog.open(AddCustomerHardwareDialogComponent, {
      data: {
        hardwareTyp: 'CustomerStockItem',
        shipment: {
          order: extractIri(this.order),
          storageSystemType: extractIri(this.order.storageSystem),
          enclosure: {
            storageSystemManufacturer: extractIri(
              this.order.storageSystemManufacturer
            ),
            storageSystemModel: this.order.storageManufacturerModel || '',
            storageSystemSize: this.order.sizeInGB,
            storageSystemSerialNumber: this.order
              .storageManufacturerSerialNumber
          },
          dataMediums: [
            {
              storageSystemManufacturer: extractIri(
                this.order.storageSystemManufacturer
              ),
              storageSystemModel: this.order.storageManufacturerModel || '',
              storageSystemSize: this.order.sizeInGB,
              storageSystemSerialNumber: this.order
                .storageManufacturerSerialNumber
            }
          ]
        }
      }
    });
    this.actions$
      .pipe(
        ofType(
          CustomerStockItemsActions.CreateCustomerStockItemSuccess,
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess
        ),
        take(1),
        takeUntil(this.onDestroy$)
      )

      .subscribe(({ response }) => {
        this.store$.dispatch(
          OrdersActions.ReadOrder({ iri: extractIri(this.order) })
        );
      });

    // this.store$.dispatch(RouterActions.Go({path: ['warehouse']}));
  }
}
