import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { AdministratorsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Administrator };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    AdministratorsActions.CreateAdministrator,
    AdministratorsActions.ReadAdministrators,
    AdministratorsActions.UpdateAdministrator,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    AdministratorsActions.CreateAdministratorSuccess,
    AdministratorsActions.UpdateAdministratorSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    AdministratorsActions.ReadAdministratorsSuccess,
    (state: State, { response }) => {
      const administrators = response['hydra:member'];
      const entities = administrators.reduce(
        (entities: { [id: string]: fromModuleModels.Administrator }, admin) => {
          return {
            ...entities,
            [admin['@id']]: admin
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(AdministratorsActions.DeleteAdministratorSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(
    AdministratorsActions.CreateAdministratorFail,
    AdministratorsActions.ReadAdministratorsFail,
    AdministratorsActions.UpdateAdministratorFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
