import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DepartmentsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { DepartmentsService } from '../../services/departments.service';

@Injectable()
export class DepartmentsEffects {
  createDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentsActions.CreateDepartment),
      map(action => action),
      switchMap(({ payload }) => {
        return this.ds.createDepartment(payload).pipe(
          map(response =>
            DepartmentsActions.CreateDepartmentSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentsActions.CreateDepartmentFail({ response }))
          )
        );
      })
    )
  );
  getDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentsActions.ReadDepartment),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.getDepartment(iri).pipe(
          map(response =>
            DepartmentsActions.ReadDepartmentSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentsActions.ReadDepartmentFail({ response }))
          )
        );
      })
    )
  );
  getDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentsActions.ReadDepartments),
      map(action => action),
      switchMap(() => {
        return this.ds.getDepartments().pipe(
          map(response =>
            DepartmentsActions.ReadDepartmentsSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentsActions.ReadDepartmentsFail({ response }))
          )
        );
      })
    )
  );
  updateDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentsActions.UpdateDepartment),
      map(action => action),
      switchMap(payload => {
        return this.ds.updateDepartment(payload).pipe(
          map(response =>
            DepartmentsActions.UpdateDepartmentSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentsActions.UpdateDepartmentFail({ response }))
          )
        );
      })
    )
  );
  deleteDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentsActions.DeleteDepartment),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.deleteDepartment(iri).pipe(
          map(() => DepartmentsActions.DeleteDepartmentSuccess({ iri })),
          catchError(response =>
            of(DepartmentsActions.DeleteDepartmentFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentsActions.DeleteDepartmentFail,
          DepartmentsActions.UpdateDepartmentFail,
          DepartmentsActions.ReadDepartmentsFail,
          DepartmentsActions.ReadDepartmentFail,
          DepartmentsActions.CreateDepartmentFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentsActions.CreateDepartmentSuccess,
          DepartmentsActions.UpdateDepartmentSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case DepartmentsActions.CREATE_DEPARTMENT_SUCCESS:
              message = 'Eine neue Abteilung wurde angelegt.';
              break;
            case DepartmentsActions.UPDATE_DEPARTMENT_SUCCESS:
              message = 'Die Abteilung wurde aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private ds: DepartmentsService,
    private notifierService: NotifierService
  ) {}
}
