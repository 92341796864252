import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {Currency, Product, ProductUnit, Service, Tax} from '../../../master-data/models';
import {OrderItemType} from '../../../orders/components';
import {InvoiceItem, InvoiceItemLike, InvoiceLike, OfferItem} from '../../models';
import {
  CurrenciesSelectors,
  ProductUnitsSelectors,
  ServicesSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {
  CurrenciesActions,
  ProductUnitsActions,
  ServicesActions,
  TaxesActions
} from '../../../master-data/store';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {
  CorrectionInvoiceItemsActions,
  CorrectionInvoicesActions,
  InvoiceItemsActions,
  OfferItemsActions, PartialInvoiceItemsActions
} from '../../store';


@Component({
  selector: 'app-invoice-item-form',
  styleUrls: ['invoice-item-form.component.scss'],
  template: `
    <ng-container [formGroup]="form">

      <div class="grid">
        <div class="column-14" *ngIf="getAllowsForUpsell">
          <div class="mat-form-field checkbox m-b--32">
            <mat-checkbox formControlName="upsell">Upsell-Position</mat-checkbox>
          </div>
        </div>
        <div class="column-14" *ngIf="!!form.get('service')">
          <ng-select
            placeholder="Service auswählen"
            (change)="handleItemSelect($event)"
            [items]="services$ | async"
            [loading]="servicesIsLoading$ | async"
            bindValue="@id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            formControlName="service"
          ></ng-select>
          <mat-error>
            <app-form-error [fieldName]="'service'" [formGroup]="form"></app-form-error>
          </mat-error>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Anzahl</mat-label>
            <input type="number" matInput formControlName="quantity">
            <mat-error>
              <app-form-error [fieldName]="'quantity'" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14">

          <ng-select
            placeholder="Einheit"
            [items]="productUnits$|async"
            [loading]="productUnitsIsLoading$|async"
            bindValue="@id"
            bindLabel="name"
            required
            [clearable]="false"
            [searchable]="false"
            formControlName="unit"
          ></ng-select>

          <mat-error>
            <app-form-error [fieldName]="'unit'" [formGroup]="form"></app-form-error>
          </mat-error>
        </div>

        <ng-container formGroupName="netPricePerUnit">

          <div class="column-4">
            <mat-form-field>
              <mat-label>Preis netto je Einheit</mat-label>
              <input type="text" currencyMask matInput formControlName="value" required
                     [options]="{ prefix: '', suffix: '', allowNegative: true }">
              <mat-error>
                <app-form-error [fieldName]="'netPricePerUnit.value'" [formGroup]="form"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="mat-form-field column-4">
            <ng-select
              placeholder="Währung"
              [items]="currencies$|async"
              [loading]="currenciesIsLoading$|async"
              [markFirst]="true"
              bindValue="code"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="currency"
            ></ng-select>
            <mat-error>
              <app-form-error [fieldName]="'netPricePerUnit.currency'" [formGroup]="form"></app-form-error>
            </mat-error>
          </div>
        </ng-container>

        <div class="column-4">
          <mat-form-field>
            <mat-label>Rabatt</mat-label>
            <input type="text" currencyMask matInput formControlName="discount"
                   [options]="{ prefix: '', suffix: '%', allowNegative: false, nullable:true }">
            <mat-error>
              <app-form-error [fieldName]="'discount'" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

        </div>

        <div class="mat-form-field column-2">
          <ng-select
            placeholder="Steuersatz"
            [items]="taxRates$|async"
            [loading]="taxRatesIsLoading$|async"
            bindValue="@id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            formControlName="taxRate"
          ></ng-select>
          <mat-error>
            <app-form-error [fieldName]="'taxRate'" [formGroup]="form"></app-form-error>
          </mat-error>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Name der Position</mat-label>
            <input type="text" matInput formControlName="title">
            <mat-error>
              <app-form-error [fieldName]="'title'" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <textarea matInput rows="8" formControlName="description"
            ></textarea>
            <mat-error>
              <app-form-error [fieldName]="'description'" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-14 m-ta--2">

          <button [disabled]="form.invalid || form.pristine" mat-flat-button color="green" (click)="handleSubmit()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>{{ item ? 'Aktualisieren' : 'Hinzufügen' }}</span>
          </button>
        </div>
      </div>
    </ng-container>
    <!--<pre>{{ iif.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
  `
})
export class InvoiceItemFormComponent extends BaseOnDestroyComponent implements OnInit, OnDestroy {

  @Input() itemType: OrderItemType;
  @Input() items: Array<any>;
  @Input() changeableTypeDisabled: boolean;

  currencies$: Observable<Array<Currency>>;
  currenciesIsLoading$: Observable<boolean>;
  @Input() errors: ErrorsObject;
  @Input() invoice: InvoiceLike;
  @Input() item: InvoiceItemLike;
  productUnits$: Observable<Array<ProductUnit>>;
  productUnitsIsLoading$: Observable<boolean>;
  services$: Observable<Array<Service>>;
  servicesIsLoading$: Observable<boolean>;
  taxRates$: Observable<Array<Tax>>;
  taxRatesIsLoading$: Observable<boolean>;

  @Output() changeItemType: EventEmitter<OrderItemType> = new EventEmitter();

  form: FormGroup;
  products: Product[];

  // note: API requires strings
  constructor(private fb: FormBuilder, private fs: FormsService, private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.loadCurrencies();
    this.loadProductUnits();
    this.loadServices();
    this.loadTaxRates();
    if (this.item) {

      if(this.item['@type'] === 'PartialInvoiceItem') {
        this.item.description = '';
      }
      this.fs.patchForm(this.form, this.item);
      if (this.item.taxRate['@id']) {
        this.form.patchValue({taxRate: this.item.taxRate['@id']});
      }
      this.form.markAsUntouched();
    }

  }

  initForm(): void {
    this.form = this.fb.group({
      upsell: this.fb.control(false),
      discount: this.fb.control(null),
      quantity: this.fb.control(1, Validators.required),
      unit: this.fb.control(null, Validators.required),
      title: this.fb.control(null, Validators.required),
      description: this.fb.control(null),
      service: this.fb.control(null),
      netPricePerUnit: this.fb.group({
        value: this.fb.control(null, Validators.required),
        currency: this.fb.control(null, Validators.required),
      }),
      taxRate: this.fb.control(null, Validators.required),
    });

    if (this.invoice['@type'] === 'CorrectionInvoice') {
      this.form.addControl('correctionInvoice', this.fb.control(this.invoice['@id'], Validators.required));
    } else if (this.invoice['@type'] === 'PartialInvoice') {
      this.form.addControl('partialInvoice', this.fb.control(this.invoice['@id'], Validators.required));
    } else if (this.invoice['@type'] === 'CommissionCredit') {
      this.form.addControl('commissionCredit', this.fb.control(this.invoice['@id'], Validators.required));
    } else if (this.invoice['@type'] === 'Offer') {
      this.form.addControl('offer', this.fb.control(this.invoice['@id'], Validators.required));
    } else {
      this.form.addControl('invoice', this.fb.control(this.invoice['@id'], Validators.required));
    }
  }

  get getAllowsForUpsell(): boolean {
    return (this.invoice['@type'] === 'Invoice') || (this.invoice['@type'] === 'PartialInvoice');
  }

  handleSubmit(): void {
    console.log(this.form.getRawValue());
    this.form.get('netPricePerUnit.value').setValue(this.form.get('netPricePerUnit.value').value.toString());
    this.form.get('quantity').setValue(this.form.get('quantity').value.toString());
    if (this.form.get('discount').value !== null) {
      this.form.get('discount').setValue(this.form.get('discount').value.toString());
    }
    const payload = {
      ...this.form.value,
      discount: this.form.get('discount').value ? this.form.get('discount').value.toString() : null,
      quantity: this.form.get('quantity').value.toString()
    };
    if (this.form.contains('invoice')) {
      const invoiceIri = this.form.get('invoice').value;
      if (this.item) {// UPDATE
        const iri = extractIri(this.item);
        this.store$.dispatch(InvoiceItemsActions.UpdateInvoiceItem({iri, payload, invoiceIri}));
        console.log(iri, payload, invoiceIri);
      } else {
        payload.position = this.invoice.items?.length > 0 ? (Math.max(...(this.invoice.items as InvoiceItem[]).map(e => e.position))) + 1 : 1;
        this.store$.dispatch(InvoiceItemsActions.CreateInvoiceItem({payload, invoiceIri}));
        console.log(payload, invoiceIri);
      }
    }
    if (this.form.contains('partialInvoice')) {
      const invoiceIri = this.form.get('partialInvoice').value;
      if (this.item) {// UPDATE
        const iri = extractIri(this.item);
        this.store$.dispatch(PartialInvoiceItemsActions.UpdatePartialInvoiceItem({iri, payload, invoiceIri}));
        console.log(iri, payload, invoiceIri);
      } else {
        payload.position = this.invoice.items?.length > 0 ? (Math.max(...(this.invoice.items as InvoiceItem[]).map(e => e.position))) + 1 : 1;
        this.store$.dispatch(PartialInvoiceItemsActions.CreatePartialInvoiceItem({payload, invoiceIri}));
        console.log(payload, invoiceIri);
      }
    }
    if (this.form.contains('offer')) {
      const invoiceIri = this.form.get('offer').value;

      if (this.item) {// UPDATE
        const iri = extractIri(this.item);
        this.store$.dispatch(OfferItemsActions.UpdateOfferItem({iri, payload, invoiceIri}));
        console.log(iri, payload, invoiceIri);
      } else {
        payload.position = this.invoice?.items?.length > 0 ? (Math.max(...(this.invoice.items as OfferItem[]).map((e: OfferItem) => e.position))) + 1 : 1;
        this.store$.dispatch(OfferItemsActions.CreateOfferItem({payload, invoiceIri}));
        console.log(payload, invoiceIri);
      }
    }
    if (this.form.contains('correctionInvoice')) {
      const invoiceIri = this.form.get('correctionInvoice').value;
      if (this.item) {// UPDATE
        const iri = extractIri(this.item);
        this.store$.dispatch(CorrectionInvoiceItemsActions.UpdateCorrectionInvoiceItem({iri, payload, invoiceIri}));
        console.log(iri, payload, invoiceIri);
      } else {
        payload.position = this.invoice?.items?.length > 0 ? (Math.max(...(this.invoice.items as OfferItem[]).map((e: OfferItem) => e.position))) + 1 : 1;
        this.store$.dispatch(CorrectionInvoiceItemsActions.CreateCorrectionInvoiceItem({payload, invoiceIri}));
        console.log(payload, invoiceIri);
      }
    }

    // if (!!this.form.get('discount').value) {
    //   this.form.get('discount').setValue(this.form.get('discount').value.toString());
    // }
    //
    // this.form.get('netPricePerUnit.value').setValue(this.form.get('netPricePerUnit.value').value.toString());
    //
    // !!this.presets$.getValue() ?
    //   this.requestUpdateInvoiceItem.emit({iri: this.presets$.getValue()['@id'], payload: this.form.value}) :
    //   this.requestCreateInvoiceItem.emit(this.form.value);

  }

  handleItemSelect(item: Service): void {

    // Set values manually since property names differ
    const {price: {currency, value}, description, name} = item;
    this.form.get('title').setValue(name);
      this.form.get('description').setValue(description);
    this.form.get('netPricePerUnit.currency').setValue(currency);
    this.form.get('netPricePerUnit.value').setValue(value);
  }


  private loadCurrencies(): void {
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.currenciesIsLoading$ = this.store$.select(CurrenciesSelectors.isLoading);
    loadIfNotLoaded(this.store$, CurrenciesSelectors.isLoaded, CurrenciesActions.ReadCurrencies());
    this.currencies$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(currencies => !!currencies && currencies.length > 0))
      .subscribe(currencies => {
        console.log(this.form.get('netPricePerUnit.currency').value, currencies);
        if (this.form.get('netPricePerUnit.currency').value === null) {
          this.form.patchValue({netPricePerUnit: {currency: currencies[0]?.code || null}});
        }
      });

  }

  private loadTaxRates(): void {
    this.taxRates$ = this.store$.select(TaxesSelectors.selectTaxes);
    this.taxRatesIsLoading$ = this.store$.select(TaxesSelectors.isLoading);
    loadIfNotLoaded(this.store$, TaxesSelectors.isLoaded, TaxesActions.ReadTaxes());
    this.taxRates$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(taxRates => !!taxRates && taxRates.length > 0))
      .subscribe(taxRates => {
        if (this.item?.taxRate?.value) {
          console.log(taxRates);
          const taxRate = taxRates.find(e => e.value === this.item?.taxRate?.value);
          this.form.patchValue({taxRate: taxRate['@id']});
        } else if (this.form.get('taxRate').value === null) {
          this.form.patchValue({taxRate: extractIri(taxRates.find(e => e.isDefault === true))});
        }
      });


  }

  private loadProductUnits(): void {
    this.productUnits$ = this.store$.select(ProductUnitsSelectors.selectProductUnits);
    this.productUnitsIsLoading$ = this.store$.select(ProductUnitsSelectors.isLoading);
    loadIfNotLoaded(this.store$, ProductUnitsSelectors.isLoaded, ProductUnitsActions.ReadProductUnits());

  }

  private loadServices(): void {
    this.services$ = this.store$.select(ServicesSelectors.selectServices);
    this.servicesIsLoading$ = this.store$.select(ServicesSelectors.isLoading);
    loadIfNotLoaded(this.store$, ServicesSelectors.isLoaded, ServicesActions.ReadServices());

  }

}
