import {createSelector} from '@ngrx/store';

import {BINDING_ORDERS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectBindingOrdersState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[BINDING_ORDERS_FEATURE_KEY]
);

export const selectBindingOrdersEntities = createSelector(
  selectBindingOrdersState,
  (state) => state.entities
);

export const selectBindingOrders = createSelector(
  selectBindingOrdersEntities,
  (entities) => Object.values(entities)
);
export const selectBindingOrderByIri = createSelector(
  selectBindingOrders,
  (entities, {iri}) => entities.find(e => e['@id'] ? e['@id'].indexOf(iri) > -1 : false)
);
export const selectErrors = createSelector(
  selectBindingOrdersState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectBindingOrdersState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectBindingOrdersState,
  (state) => state.loaded
);

export const sUploadStatus = createSelector(
  selectBindingOrdersState,
  (state) => state.uploadStatus?.type < 4 ? state.uploadStatus : null
);
