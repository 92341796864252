import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  CustomerDataMedium,
  DataMedium,
  EnclosureDataMedium,
  ReplacementDataMedium
} from '../../../warehouse/models';
import { StorageSystemManufacturer } from '../../../master-data/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { StorageSystemManufacturersSelectors } from '../../../master-data/store/selectors';
import { takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { DataMediumsService } from '../../../warehouse/services';
import { DataMediumStatesEntities } from '../../consts/DataMediaStates';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { getErrorOfResponse } from '../../utilities/error-utility.utility';
import { WarehouseRights } from '../../security/warehouseRights';
import { extractIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-data-media-line',
  styleUrls: ['./data-media-line.component.scss'],
  template: `
    <div
      class="d-flex"
      [class.archived]="dataMedia.archived"
      [class.invalid-data-media]="!isValidDataMedia"
      [matTooltip]="
        !isValidDataMedia
          ? 'Dieser Datenträger ist nicht für den Versand vorgesehen.'
          : ''
      "
    >
      <div class="flex-grow-1">
        <div class="row pt-2">
          <div class="col-xl-3 col-lg-6">
            <div class="d-flex">
              <ng-container *ngIf="selectionTyp !== 'none'">
                <div (click)="handleToggle()" class="cursor-pointer">
                  <mat-icon
                    >{{ selected ? 'check_box' : 'check_box_outline_blank' }}
                  </mat-icon>
                </div>
              </ng-container>
              <div class="d-flex flex-grow-1">
                <div
                  class="px-2"
                  *ngIf="dataMedia['@type'] === 'EnclosureDataMedium'"
                >
                  <mat-icon class="inline-icon">computer</mat-icon>
                  Gehäuse
                </div>
                <div
                  class="px-2"
                  *ngIf="dataMedia['@type'] === 'ReplacementDataMedium'"
                >
                  <mat-icon class="inline-icon">usb</mat-icon>
                  Ersatzdatenträger
                </div>
                <div
                  class="px-2"
                  *ngIf="dataMedia['@type'] === 'CustomerDataMedium'"
                >
                  <mat-icon class="inline-icon">save</mat-icon>
                  Festplatte
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div
              class="d-flex "
              *ngIf="dataMedia['@type'] !== 'ReplacementDataMedium'"
            >
              <div class="text-bold px-2" style="width: 90px;">Hersteller:</div>
              <div class="flex-grow-1 px-2">
                {{
                  dataMedia.storageSystemManufacturer?.name
                    ? dataMedia.storageSystemManufacturer.name
                    : dataMedia.storageSystemManufacturer
                    ? storageSystemManufacturersEntities[
                        dataMedia.storageSystemManufacturer
                      ]?.name
                    : '-'
                }}
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div
              class="d-flex "
              *ngIf="dataMedia['@type'] !== 'ReplacementDataMedium'"
            >
              <div class="text-bold px-2" style="width: 90px;">Modell:</div>
              <div class="flex-grow-1 px-2">
                {{
                  dataMedia.storageSystemModel?.trim() !== ''
                    ? dataMedia.storageSystemModel
                    : 'unbekannt'
                }}
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="d-flex ">
              <div class="text-bold px-2" style="width: 122px;">
                Seriennummer:
              </div>
              <div
                class="flex-grow-1 px-2"
                (click)="copyToClipBoard(dataMedia.storageSystemSerialNumber)"
                [matTooltip]="
                  clipboardContent === dataMedia.storageSystemSerialNumber
                    ? 'Kopiert'
                    : 'In Zwischenablage kopieren'
                "
                [matTooltipHideDelay]="2000"
              >
                {{
                  dataMedia?.storageSystemSerialNumber?.trim() !== ''
                    ? (dataMedia.storageSystemSerialNumber | optionalSlice: 20)
                    : 'unbekannt'
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-xl-3 col-lg-6">
            <div class="d-flex ">
              <div
                class="text-bold px-2"
                style="width: 100px;"
                [class.hidden]="
                  !dataMedia.state || !dataMediaStateEntities[dataMedia.state]
                "
              >
                Zustand:
              </div>
              <div class="flex-grow-1 px-2">
                {{
                  dataMediaStateEntities[dataMedia.state]?.label ||
                    dataMedia.state
                }}
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="d-flex ">
              <div class="text-bold px-2" style="width: 90px;">Einl.Nr.:</div>
              <div class="flex-grow-1 px-2">{{ dataMedia.storageNumber }}</div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="d-flex ">
              <div class="text-bold px-2" style="width: 90px;">Archiviert:</div>
              <div class="flex-grow-1 px-2">
                <app-loading-overlay
                  *ngIf="isLoading$ | async"
                ></app-loading-overlay>
                <mat-slide-toggle
                  *ngIf="canEdit$ && !readonlyArchive"
                  (click)="toggleArchive()"
                  [checked]="archived"
                ></mat-slide-toggle>
                {{ dataMedia.archived ? 'Ja' : 'Nein' }}
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div
              class="d-flex "
              *ngIf="dataMedia['@type'] !== 'ReplacementDataMedium'"
            >
              <div class="text-bold px-2" style="width: 122px;">Größe:</div>
              <div class="flex-grow-1 px-2">
                {{
                  dataMedia.storageSystemSize > 0
                    ? (dataMedia.storageSystemSize | number: '1.0')
                    : 'unbekannt'
                }}
                GB
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2 pe-2">
        <button mat-icon-button color="green" (click)="openLabel()">
          <mat-icon>qr_code_2</mat-icon>
        </button>
      </div>
    </div>

    <!--    <pre>{{dataMedia|json}}</pre>-->
  `
})
export class DataMediaLineComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() dataMedia: DataMedium;
  @Input() @Optional() selectionTyp = 'none';
  @Input() @Optional() validDataMediaStates: Array<string> = [];
  @Input() @Optional() selected = false;
  @Input() @Optional() readonly = false;
  @Input() @Optional() readonlyArchive = false;
  @Output() toggleSelect: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateDataMedium: EventEmitter<DataMedium> = new EventEmitter<
    DataMedium
  >();
  canEdit = false;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  archived = false;
  clipboardContent = '';
  storageSystemManufacturersEntities: {
    [p: string]: StorageSystemManufacturer;
  };
  dataMediaStateEntities = DataMediumStatesEntities;

  constructor(
    private store$: Store<ApplicationState>,
    private dataMediumService: DataMediumsService,
    private notifier: NotifierService,
    private warehouseRight: WarehouseRights
  ) {
    super();
  }

  get isValidDataMedia(): boolean {
    if (this.validDataMediaStates && this.validDataMediaStates.length > 0) {
      return this.validDataMediaStates.indexOf(this.dataMedia.state) > -1;
    } else {
      return true;
    }
  }

  get canEdit$(): Observable<boolean> {
    return this.warehouseRight.canEdit$;
  }

  ngOnInit(): void {
    this.canEdit$.pipe(takeUntil(this.onDestroy$)).subscribe(canEdit => {
      this.canEdit = canEdit;
    });
    this.archived = this.dataMedia.archived;
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemManufacturersEntities = entities;
      });
  }

  copyToClipBoard(content: any): void {
    this.clipboardContent = content;
    navigator.clipboard.writeText(content);
  }

  toggleArchive(): void {
    if (!this.canEdit || this.readonlyArchive) {
      return;
    }
    this.archived = !this.archived;
    this.isLoading$.next(true);
    const payload = {
      ...this.dataMedia,
      archived: this.archived,
      storageSystemManufacturer: extractIri(
        this.dataMedia.storageSystemManufacturer
      ),
      storageSystemType: extractIri(this.dataMedia.storageSystemType)
    };
    this.dataMediumService.updateDataMedium(payload).subscribe(
      result => {
        this.updateDataMedium.emit(
          result as
            | EnclosureDataMedium
            | ReplacementDataMedium
            | CustomerDataMedium
        );
        this.isLoading$.next(false);
      },
      fail => {
        this.notifier.notify(
          'error',
          'Fehler beim archivieren/entarchivieren:' +
            getErrorOfResponse(fail).message
        );
        this.isLoading$.next(false);
      }
    );
  }

  handleToggle(): void {
    if (this.readonly) {
      return;
    }
    this.toggleSelect.emit();
  }

  openLabel(): void {
    this.dataMediumService.openLabelInPopup(this.dataMedia['@id']);
  }
}
