import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { ServiceOrderItem } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createServiceOrderItem(payload: any): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SERVICE_ORDER_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/service_order_items`, payload)
    );
  }

  readServiceOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SERVICE_ORDER_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readServiceOrderItems(orderUuid: string, page = 1): Observable<any> {
    // return this.apiService.getObjects('/service_order_items')
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SERVICE_ORDER_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/service_order_items?page=${page}&order.uuid=${orderUuid}`
            )
    );
  }

  updateServiceOrderItem(
    iri: string,
    payload: ServiceOrderItem
  ): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SERVICE_ORDER_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteServiceOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_SERVICE_ORDER_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
