import {createSelector} from '@ngrx/store';

import {SALES_SUMMARY_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectSalesSummaryState = createSelector(
  selectOrdersModuleState,
  (state) => state[SALES_SUMMARY_FEATURE_KEY]
);

export const selectSalesSummaryEntities = createSelector(
  selectSalesSummaryState,
  (state) => state.entities
);

export const selectSalesSummaries = createSelector(
  selectSalesSummaryEntities,
  (entities) => Object.values(entities)
);

export const selectSalesSummary = createSelector(
  selectSalesSummaries,
  (entities) => entities[0]
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectSalesSummaryState,
  (state) => state.errors
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectSalesSummaryState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectSalesSummaryState,
  (state) => state.loaded
);
