import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { FileSystem } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class FileSystemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createFileSystem(payload: FileSystem): Observable<FileSystem> {
    return this.rolesService.userHasRoleFilter<FileSystem>(
      'ROLE_MWS_FILE_SYSTEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/file_systems`, payload)
    );
  }

  getFileSystem(iri: string): Observable<FileSystem> {
    return this.rolesService.userHasRoleFilter<FileSystem>(
      'ROLE_MWS_FILE_SYSTEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getFileSystems(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_FILE_SYSTEM_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(`/file_systems`)
    );
  }

  updateFileSystem({ iri, payload }): Observable<FileSystem> {
    return this.rolesService.userHasRoleFilter<FileSystem>(
      'ROLE_MWS_FILE_SYSTEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteFileSystem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_FILE_SYSTEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
