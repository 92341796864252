import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragSortEvent, moveItemInArray} from '@angular/cdk/drag-drop';
import {cloneDeep} from 'lodash-es';
import {InvoiceItemLike, InvoiceLike} from '../../models';
import {Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ProductUnitsSelectors} from '../../../master-data/store/selectors';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {ProductUnitsActions} from '../../../master-data/store';
import {
  CommissionCreditItemsActions,
  CorrectionInvoiceItemsActions,
  InvoiceItemsActions,
  OfferItemsActions,
  PartialInvoiceItemsActions
} from '../../store';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {ProductUnit} from '../../../master-data/models';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {extractIri, extractTypeByIri} from '../../../shared/utilities/objects.utility';
import {InvoiceItemDialogComponent} from '../invoice-item-dialog/invoice-item-dialog.component';

@Component({
  selector: 'app-invoice-items-summary',
  styleUrls: ['invoice-items-summary.component.scss'],
  template: `
    <table>
      <thead>
      <tr>
        <th class="pos">Pos.</th>
        <th class="count">Anz.</th>
        <th class="einheit">Einh.</th>
        <th class="">Artikel</th>
        <th class="price px-2">Preis</th>
        <th class="total text-right px-2">Total</th>
        <th class="ust">USt.</th>
        <th class="actions" *ngIf="canEditInvoice">&nbsp;</th>
      </tr>
      </thead>

      <tbody class="px-2" cdkDropListLockAxis="y" cdkDropList
             (cdkDropListDropped)="handleRequestUpdateInvoiceItem($event)">
      <tr *ngIf="!localItems||localItems.length < 1" class="my-3">
        <td colspan="7">Diese Rechnung enthällt (noch) keine Positionen.</td>
      </tr>
      <tr
        *ngFor="let item of localItems; let i = index;"
        [cdkDragDisabled]="!canMove"
        class=""
        cdkDrag cdkDragBoundary=".invoice-items-table">

        <td class="pos-relative">
          <span class="upsell-wrapper" *ngIf="item.upsell">
            <span>US</span>
          </span>
          {{ i + 1 }}</td>

        <td class="">{{ item.quantity | number }}</td>

        <td class="">{{ unitsEntities[item.unit]?.abbreviation }}</td>

        <td class="">
          <strong>{{ item.title }}</strong>
          <span class="desc" *ngIf="item.description && item['@type'] !== 'PartialInvoiceItem'">: {{ item.description }}</span>
          <ng-container *ngIf="item.discount">
            <br>
            <span class="text-color-grey">Originalpreis
              {{ item?.netPricePerUnit?.value | number:'1.2-2' }}
              {{ item.netPricePerUnit.currency | currencyShortener }}
              <span>- Rabatt {{ item.discount | number }}% </span>
              ({{(item.netDiscountAmount.value / item.quantity) | number:'1.2-2'}}
              {{ item.netDiscountAmount.currency | currencyShortener }})
            </span>
          </ng-container>
        </td>

        <td class=" px-2">
          {{ item?.netPricePerUnit?.value - (item.discount ? item.netPricePerUnit.value * (item.discount / 100.0) : 0)  | number:'1.2-2' }}
          {{ item.netPricePerUnit.currency | currencyShortener }}
          <ng-container *ngIf="item.discount">
            <br>
            <span class="text-color-grey">(rabattiert)</span>
          </ng-container>
        </td>


        <td class="text-right px-2">
          {{ item.netTotal.value | number:'1.2-2' }}
          {{ item.netTotal.currency | currencyShortener }}
          <!--<span *ngIf="item.discount && item?.originalGrossTotal?.value"
                class="discount">{{ (item?.originalGrossTotal?.value ? item.originalGrossTotal.value : item.grossTotal.value) | number:'1.2-2' }}</span>-->
        </td>

        <td class="">{{ item?.taxRate?.value ? ((item?.taxRate?.value | number) + '%') : '-' }}</td>

        <td class="" *ngIf="canEditInvoice">
          <button *ngIf="canEdit" mat-icon-button
                  (click)="handleRequestShowInvoiceItemForm(item)">
            <mat-icon>edit</mat-icon>
          </button>

          <button *ngIf="canDelete" mat-icon-button
                  (click)="handleRequestDeleteInvoiceItem(item)">
            <mat-icon color="warn">delete_forever</mat-icon>
          </button>

          <button
            cdkDragHandle *ngIf="canMove "
            mat-icon-button
            class="cursor-drag"
            [disableRipple]="true">
            <mat-icon>drag_handle</mat-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!--<pre>{{ items.length | json }}</pre>-->
    <!--<pre>{{ items | json }}</pre>-->
    <!--<pre>{{ localItems | json }}</pre>-->
  `
})
export class InvoiceItemsSummaryComponent extends BaseOnDestroyComponent implements OnInit {

  invoice: InvoiceLike;
  @Input() invoice$: Observable<InvoiceLike>;
  items: Array<InvoiceItemLike> = [];
  unitsEntities: { [iri: string]: ProductUnit };

  @Output() requestShowInvoiceItemForm: EventEmitter<InvoiceItemLike> = new EventEmitter();
  @Output() requestUpdateInvoice: EventEmitter<{ items: Array<{ position: number }> | any }> = new EventEmitter();

  localItems: Array<InvoiceItemLike> = [];

  get canDelete(): boolean {
    return this.canEdit;
  }

  get canEdit(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'cancellation_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'offers') {
      return true;
    }

    return this.invoice && 'editable' in this.invoice && this.invoice?.editable;
  }

  get canEditInvoice(): boolean {
    if (extractTypeByIri(this.invoice, true) === 'cancellation_invoices') {
      return false;
    }
    if (extractTypeByIri(this.invoice, true) === 'offers') {
      return !('acceptedOffer' in this.invoice && this.invoice.acceptedOffer !== null);
    }
    return this.invoice && 'editable' in this.invoice && this.invoice?.editable;
  }

  get canMove(): boolean {
    if (this.localItems.length <= 1) {
      return false;
    }
    return this.canEditInvoice;
  }

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
  ) {
    super();
  }


  ngOnInit(): void {
    this.loadProductUnits();
    this.invoice$?.pipe(takeUntil(this.onDestroy$), filter(i => !!i))
      .subscribe(invoice => {
        this.invoice = invoice;
        if (invoice.items) {
          this.localItems = cloneDeep(invoice.items).sort((a, b) => a.position - b.position);
        }
      });
  }


  handleRequestShowInvoiceItemForm(item: InvoiceItemLike): void {
    this.dialog.open(InvoiceItemDialogComponent, {data: {item, invoice: this.invoice}});
  }

  handleRequestDeleteInvoiceItem(item: InvoiceItemLike): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchtest du diese Position unwiderruflich löschen?',
          heading: 'Positon löschen',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      ).subscribe(() => {
      switch (extractTypeByIri(item)) {
        case 'commission_credit_items':
          this.store$.dispatch(CommissionCreditItemsActions.DeleteCommissionCreditItem({
            invoiceIri: this.invoice['@id'],
            iri: item['@id']
          }));
          break;
        case 'correction_invoice_items':
          this.store$.dispatch(CorrectionInvoiceItemsActions.DeleteCorrectionInvoiceItem({
            invoiceIri: this.invoice['@id'],
            iri: item['@id']
          }));
          break;
        case 'invoice_items':
          this.store$.dispatch(InvoiceItemsActions.DeleteInvoiceItem({
            invoiceIri: this.invoice['@id'],
            iri: item['@id']
          }));
          break;
        case 'offer_items':
          this.store$.dispatch(OfferItemsActions.DeleteOfferItem({invoiceIri: this.invoice['@id'], iri: item['@id']}));
          break;
        case 'partial_invoice_items':
          this.store$.dispatch(PartialInvoiceItemsActions.DeletePartialInvoiceItem({
            invoiceIri: this.invoice['@id'],
            iri: item['@id']
          }));
          break;
        default:
          console.log('TYPE NOT FOUND:', extractTypeByIri(item));
          break;
      }
    });
  }

  handleRequestUpdateInvoiceItem(event: CdkDragSortEvent): void {
    moveItemInArray(this.localItems, event.previousIndex, event.currentIndex);
    const items = this.localItems.map((e, i) => ({'@id': extractIri(e), position: i + 1}));
    this.requestUpdateInvoice.emit({items});
  }

  private loadProductUnits(): void {
    this.store$.select(ProductUnitsSelectors.selectProductUnitsEntities)
      .pipe(takeUntil(this.onDestroy$), filter(invoice => !!invoice))
      .subscribe(e => {
        this.unitsEntities = e;
      });

    loadIfNotLoaded(this.store$, ProductUnitsSelectors.isLoaded, ProductUnitsActions.ReadProductUnits());

  }
}
