import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Country } from '../../../master-data/models';
import { ErrorsObject } from '../../utilities/error-utility.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Observable } from 'rxjs';
import { CountriesSelectors } from '../../../master-data/store/selectors';

@Component({
  selector: 'app-shipping-address',
  styleUrls: ['shipping-address.component.scss'],
  template: `
    <div class="wrap" [formGroup]="group">
      <div class="grid address-block" [formGroupName]="groupName">
        <ng-content select=".heading"></ng-content>

        <div class="column-14">
          <mat-form-field class="white company-name">
            <mat-label>Firma</mat-label>
            <input
              matInput
              type="text"
              [matTooltip]="companyName"
              (focusout)="setCompanyName()"
              formControlName="companyName"
              [matTooltipPosition]="'above'"
              [matTooltipDisabled]="!companyName"
              matTooltipClass="company-name-tooltip"
            />
            <mat-error>
              <app-form-error
                [fieldName]="groupName + '.companyName'"
                [formGroup]="group"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
          <div *ngIf="companyName" class="full-company-name">
            <span class="light-gray">{{ "leads.shipping_dialog.notice" | translate }}:</span>
            <span class="description">{{ "leads.shipping_dialog.notice_description" | translate }}: <strong>{{ companyName }}</strong></span>
          </div>
        </div>

        <div class="column-14" *ngIf="!!group.get(groupName + '.phone')">
          <mat-form-field class="white">
            <mat-label>Telefonnummer*</mat-label>
            <ngx-11-mat-intl-tel-input
              [preferredCountries]="['de', 'us']"
              [enablePlaceholder]="true"
              [enableSearch]="false"
              name="phone"
              formControlName="phone"
            ></ngx-11-mat-intl-tel-input>

            <mat-error>
              <app-form-error
                [fieldName]="groupName + '.phone'"
                [formGroup]="group"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-8">
          <mat-form-field class="white">
            <mat-label>Vorname</mat-label>
            <input type="text" matInput formControlName="firstName" />
            <mat-error>
              <app-form-error
                [fieldName]="groupName + '.firstName'"
                [formGroup]="group"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-6">
          <mat-form-field class="white">
            <mat-label
              >Nachname{{
                groupName === 'pickupDispatcher' ? '*' : ''
              }}</mat-label
            >
            <input type="text" matInput formControlName="lastName" />
            <mat-error>
              <app-form-error
                [fieldName]="groupName + '.lastName'"
                [formGroup]="group"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <!--<mat-form-field class="white">
          <mat-label>c/o*</mat-label>
          <input type="text" matInput formControlName="careOf">
          <mat-hint align="start" *ngIf="errors[groupName]?.careOf">{{ errors[groupName]?.careOf.message }}</mat-hint>
        </mat-form-field>-->

        <ng-container formGroupName="address">
          <div class="column-9">
            <mat-form-field class="white">
              <mat-label
                >Straße{{
                  groupName !== 'pickupDispatcher' ? '*' : ''
                }}</mat-label
              >
              <input type="text" matInput formControlName="line1" />
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.line1'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="column-5">
            <mat-form-field class="white">
              <mat-label>Hausnummer</mat-label>
              <input type="text" matInput formControlName="line2" />
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.line2'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="column-14">
            <mat-form-field class="white">
              <mat-label>c/o, Adresszusatz</mat-label>
              <input type="text" matInput formControlName="line3" />
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.line3'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="column-5">
            <mat-form-field class="white">
              <mat-label>PLZ</mat-label>
              <input type="text" matInput formControlName="zipPostcode" />
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.zipPostcode'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="column-9">
            <mat-form-field class="white">
              <mat-label>Stadt</mat-label>
              <input type="text" matInput formControlName="city" />
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.city'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="column-14">
            <div class="mat-form-field white">
              <ng-select
                placeholder="Land{{
                  groupName !== 'pickupDispatcher' ? '*' : ''
                }}"
                [items]="countries$ | async"
                bindLabel="name"
                [searchable]="false"
                [clearable]="false"
                formControlName="country"
                bindValue="code"
              ></ng-select>
              <mat-error>
                <app-form-error
                  [fieldName]="groupName + '.address.country'"
                  [formGroup]="group"
                ></app-form-error>
              </mat-error>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class ShippingAddressComponent implements OnInit {
  countries$: Observable<Array<Country>>;
  countriesIsLoading$: Observable<boolean>;
  companyName: string;
  @Input() errors: ErrorsObject;
  @Input() formArrayName: string;
  @Input() group: FormGroup;
  @Input() groupName: string;

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.countries$ = this.store$.select(CountriesSelectors.selectCountries);
    this.countriesIsLoading$ = this.store$.select(CountriesSelectors.isLoading);
    this.setCompanyName();
  }

  setCompanyName() {
    if(
      this.group.value.shipFrom.companyName !== null
      && this.group.value.shipFrom.companyName.length > 30
    ) {
      this.companyName = this.group.value.shipFrom.companyName;
      this.group.get(this.groupName).patchValue({
        companyName: this.group.value.shipFrom.companyName.substr(0, 27) + '...'
      });
    }
  }

  hasError(formControlName: string): boolean {
    return this.errors.hasOwnProperty(`${this.groupName}.${formControlName}`);
  }

  getErrorMessage(formControlName: string): string {
    return this.errors[`${this.groupName}.${formControlName}`].message;
  }

  isCompanyRequired(groupName: string): boolean {
    return ['pickupDispatcher', 'shipFrom'].includes(groupName);
  }
}
