import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerCommentTagsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [id: string]: fromModuleModels.CustomerCommentTag };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    CustomerCommentTagsActions.LoadCustomerCommentTag,
    CustomerCommentTagsActions.LoadCustomerCommentTags,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    CustomerCommentTagsActions.LoadCustomerCommentTagsSuccess,
    (state, { response }) => {
      const commentTags = response['hydra:member'];
      const entities = commentTags.reduce(
        (
          entities: { [id: string]: fromModuleModels.CustomerCommentTag },
          commentTag
        ) => {
          return {
            ...entities,
            [commentTag['@id']]: commentTag
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        errors: {},
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    CustomerCommentTagsActions.LoadCustomerCommentTagFail,
    CustomerCommentTagsActions.LoadCustomerCommentTagsFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
