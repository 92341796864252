import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_REPLACEMENT_DATA_MEDIUM = '[Warehouse Module] Create Replacement Data Medium';
export const CREATE_REPLACEMENT_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Create Replacement Data Medium Success';
export const CREATE_REPLACEMENT_DATA_MEDIUM_FAIL = '[Warehouse Module] Create Replacement Data Medium Fail';

export const CreateReplacementDataMedium = createAction(
  CREATE_REPLACEMENT_DATA_MEDIUM,
  props<{ payload: fromModuleModels.ReplacementDataMedium }>()
);

export const CreateReplacementDataMediumSuccess = createAction(
  CREATE_REPLACEMENT_DATA_MEDIUM_SUCCESS,
  props<{ response: fromModuleModels.ReplacementDataMedium | any }>()
);

export const CreateReplacementDataMediumFail = createAction(
  CREATE_REPLACEMENT_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_REPLACEMENT_DATA_MEDIUM = '[Warehouse Module] Read Replacement Data Medium';
export const READ_REPLACEMENT_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Read Replacement Data Medium Success';
export const READ_REPLACEMENT_DATA_MEDIUM_FAIL = '[Warehouse Module] Read Replacement Data Medium Fail';

export const ReadReplacementDataMedium = createAction(
  READ_REPLACEMENT_DATA_MEDIUM,
  props<{ iri: string }>()
);

export const ReadReplacementDataMediumSuccess = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_SUCCESS,
  props<{ response: any }>()
);

export const ReadReplacementDataMediumFail = createAction(
  READ_REPLACEMENT_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_REPLACEMENT_DATA_MEDIUM = '[Warehouse Module] Update Replacement Data Medium';
export const UPDATE_REPLACEMENT_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Update Replacement Data Medium Success';
export const UPDATE_REPLACEMENT_DATA_MEDIUM_FAIL = '[Warehouse Module] Update Replacement Data Medium Fail';

export const UpdateReplacementDataMedium = createAction(
  UPDATE_REPLACEMENT_DATA_MEDIUM,
  props<{ iri: string, payload: fromModuleModels.ReplacementDataMedium }>()
);

export const UpdateReplacementDataMediumSuccess = createAction(
  UPDATE_REPLACEMENT_DATA_MEDIUM_SUCCESS,
  props<{ response: any }>()
);

export const UpdateReplacementDataMediumFail = createAction(
  UPDATE_REPLACEMENT_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_REPLACEMENT_DATA_MEDIUM = '[Warehouse Module] Delete Replacement Data Medium';
export const DELETE_REPLACEMENT_DATA_MEDIUM_SUCCESS = '[Warehouse Module] Delete Replacement Data Medium Success';
export const DELETE_REPLACEMENT_DATA_MEDIUM_FAIL = '[Warehouse Module] Delete Replacement Data Medium Fail';

export const DeleteReplacementDataMedium = createAction(
  DELETE_REPLACEMENT_DATA_MEDIUM,
  props<{ iri: string }>()
);

export const DeleteReplacementDataMediumSuccess = createAction(
  DELETE_REPLACEMENT_DATA_MEDIUM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteReplacementDataMediumFail = createAction(
  DELETE_REPLACEMENT_DATA_MEDIUM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
