import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

export const LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS = '[Administrators] Load Received Administrator Feedback Requests';
export const LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS_SUCCESS = '[Administrators] Load Received Administrator Groups Success';
export const LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS_FAIL = '[Administrators] Load Received Administrator Groups Fail';

// Read

export const LoadReceivedAdministratorFeedbackRequests = createAction(
  LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS,
  props<{ iri: string, params?: Array<string> }>()
);

export const LoadReceivedAdministratorFeedbackRequestsSuccess = createAction(
  LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS_SUCCESS,
  props<{ response: Array<fromModuleModels.FeedbackRequest> }>()
);


export const LoadReceivedAdministratorFeedbackRequestsFail = createAction(
  LOAD_RECEIVED_ADMINISTRATOR_FEEDBACK_REQUESTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);


export const LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS = '[Administrators] Load Sent Administrator Feedback Requests';
export const LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS_SUCCESS = '[Administrators] Load Sent Administrator Groups Success';
export const LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS_FAIL = '[Administrators] Load Sent Administrator Groups Fail';

// Read

export const LoadSentAdministratorFeedbackRequests = createAction(
  LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS,
  props<{ iri: string, params?: Array<string> }>()
);

export const LoadSentAdministratorFeedbackRequestsSuccess = createAction(
  LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS_SUCCESS,
  props<{ response: Array<fromModuleModels.FeedbackRequest> }>()
);

export const LoadSentAdministratorFeedbackRequestsFail = createAction(
  LOAD_SENT_ADMINISTRATOR_FEEDBACK_REQUESTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);


export const PATCH_FEEDBACK_REQUESTS = '[Administrators] Patch Administrator Feedback Requests';
export const PATCH_FEEDBACK_REQUESTS_SUCCESS = '[Administrators] Patch Administrator Feedback Requests Success';
export const PATCH_FEEDBACK_REQUESTS_FAIL = '[Administrators] Patch Administrator Feedback Requests Fail';

// Patch

export const PatchFeedbackRequests = createAction(
  PATCH_FEEDBACK_REQUESTS,
  props<{
    iri: string,
    module: 'customer' | 'lead' | 'order' | 'ticket',
    variation: 'answer' | 'resolve',
    payload: any
  }>()
);

export const PatchFeedbackRequestsSuccess = createAction(
  PATCH_FEEDBACK_REQUESTS_SUCCESS,
  props<{ response: any }>()
);

export const PatchFeedbackRequestsFail = createAction(
  PATCH_FEEDBACK_REQUESTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
