import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {DataMediumStatusLogsActions} from '../actions';
import {DataMediumStatusLogsService} from '../../services';

@Injectable()
export class DataMediumStatusLogsEffects {

  constructor(private actions$: Actions, private service: DataMediumStatusLogsService) {
  }


  ReadDataMediumStatusLog$ = createEffect(() => this.actions$.pipe(
    ofType(DataMediumStatusLogsActions.ReadDataMediumStatusLog),
    switchMap(({iri}) => {
      return this.service.readDataMediumStatusLog(iri).pipe(
        map((response) => DataMediumStatusLogsActions.ReadDataMediumStatusLogSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataMediumStatusLogsActions.ReadDataMediumStatusLogFail({response})))
      );
    })
  ));


  ReadDataMediumStatusLogs$ = createEffect(() => this.actions$.pipe(
    ofType(DataMediumStatusLogsActions.ReadDataMediumStatusLogs),
    switchMap(({page, params}) => {
      return this.service.readDataMediumStatusLogs(page, params).pipe(
        map((response) => DataMediumStatusLogsActions.ReadDataMediumStatusLogsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataMediumStatusLogsActions.ReadDataMediumStatusLogsFail({response})))
      );
    })
  ));
}
