import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {TicketCommentsSelectors} from '../store/selectors';
import {TicketCommentsActions} from '../store';

@Injectable()
export class TicketCommentsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const uuid = route.params.uuid;

    return this.orderCommentsLoaded(uuid).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  orderCommentsLoaded(ticketUuid: string): Observable<any> {

    return this.store.pipe(
      select(TicketCommentsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(TicketCommentsActions.ReadTicketComments({page: 1, params: {}}));
        }
      }),
      take(1)
    );
  }
}
