import { createSelector } from '@ngrx/store';

import {
  CUSTOMER_CONTACTS_FEATURE_KEY,
  selectCustomersModuleState
} from '../reducers';
import {
  getRouterState,
  RouterStateUrl
} from '../../../application-state/store';

export const selectCustomerContactsState = createSelector(
  selectCustomersModuleState,
  state => state[CUSTOMER_CONTACTS_FEATURE_KEY]
);

export const selectRouteParameters = createSelector(
  getRouterState,
  (router: RouterStateUrl | any) => {
    return router?.state?.params;
  }
);

export const selectCustomerContactEntities = createSelector(
  selectCustomerContactsState,
  state => state.entities
);

export const selectCustomerContactByIndex = createSelector(
  selectCustomerContactEntities,
  (contacts, index: string) => contacts[index]
);

export const selectCustomerContacts = createSelector(
  selectCustomerContactEntities,
  selectRouteParameters,
  contacts => Object.keys(contacts).map(iri => contacts[iri])
);

export const selectCustomerContactsByCustomerIri = createSelector(
  selectCustomerContacts,
  (contacts, { customerIri }) =>
    contacts.filter(contact => contact.customer === customerIri)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoaded = createSelector(
  selectCustomerContactsState,
  state => state.loaded
);

export const isLoading = createSelector(
  selectCustomerContactsState,
  state => state.loading
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomerContactsState,
  state => state.errors
);
