import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_ANALYSIS_RESULT_CATEGORY = '[Master Data Module] Create Analysis Result Category';
export const CREATE_ANALYSIS_RESULT_CATEGORY_SUCCESS = '[Master Data Module] Create Analysis Result Category Success';
export const CREATE_ANALYSIS_RESULT_CATEGORY_FAIL = '[Master Data Module] Create Analysis Result Category Fail';

export const CreateAnalysisResultCategory = createAction(
  CREATE_ANALYSIS_RESULT_CATEGORY,
  props<{ payload: fromModuleModels.AnalysisResultCategory }>()
);

export const CreateAnalysisResultCategorySuccess = createAction(
  CREATE_ANALYSIS_RESULT_CATEGORY_SUCCESS,
  props<{ response: any }>()
);

export const CreateAnalysisResultCategoryFail = createAction(
  CREATE_ANALYSIS_RESULT_CATEGORY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ANALYSIS_RESULT_CATEGORY = '[Master Data Module] Read Analysis Result Category';
export const READ_ANALYSIS_RESULT_CATEGORY_SUCCESS = '[Master Data Module] Read Analysis Result Category Success';
export const READ_ANALYSIS_RESULT_CATEGORY_FAIL = '[Master Data Module] Read Analysis Result Category Fail';

export const ReadAnalysisResultCategory = createAction(
  READ_ANALYSIS_RESULT_CATEGORY,
  props<{ iri: string }>()
);

export const ReadAnalysisResultCategorySuccess = createAction(
  READ_ANALYSIS_RESULT_CATEGORY_SUCCESS,
  props<{ response: any }>()
);

export const ReadAnalysisResultCategoryFail = createAction(
  READ_ANALYSIS_RESULT_CATEGORY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ANALYSIS_RESULT_CATEGORIES = '[Master Data Module] Read Analysis Result Categories';
export const READ_ANALYSIS_RESULT_CATEGORIES_SUCCESS = '[Master Data Module] Read Analysis Result Categories Success';
export const READ_ANALYSIS_RESULT_CATEGORIES_FAIL = '[Master Data Module] Read Analysis Result Categories Fail';

export const ReadAnalysisResultCategories = createAction(
  READ_ANALYSIS_RESULT_CATEGORIES,
);

export const ReadAnalysisResultCategoriesSuccess = createAction(
  READ_ANALYSIS_RESULT_CATEGORIES_SUCCESS,
  props<{ response: any }>()
);

export const ReadAnalysisResultCategoriesFail = createAction(
  READ_ANALYSIS_RESULT_CATEGORIES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_ANALYSIS_RESULT_CATEGORY = '[Master Data Module] Update Analysis Result Category';
export const UPDATE_ANALYSIS_RESULT_CATEGORY_SUCCESS = '[Master Data Module] Update Analysis Result Category Success';
export const UPDATE_ANALYSIS_RESULT_CATEGORY_FAIL = '[Master Data Module] Update Analysis Result Category Fail';

export const UpdateAnalysisResultCategory = createAction(
  UPDATE_ANALYSIS_RESULT_CATEGORY,
  props<{ iri: string, payload: fromModuleModels.AnalysisResultCategory }>()
);

export const UpdateAnalysisResultCategorySuccess = createAction(
  UPDATE_ANALYSIS_RESULT_CATEGORY_SUCCESS,
  props<{ response: fromModuleModels.AnalysisResultCategory }>()
);

export const UpdateAnalysisResultCategoryFail = createAction(
  UPDATE_ANALYSIS_RESULT_CATEGORY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_ANALYSIS_RESULT_CATEGORY = '[Master Data Module] Delete Analysis Result Category';
export const DELETE_ANALYSIS_RESULT_CATEGORY_SUCCESS = '[Master Data Module] Delete Analysis Result Category Success';
export const DELETE_ANALYSIS_RESULT_CATEGORY_FAIL = '[Master Data Module] Delete Analysis Result Category Fail';

export const DeleteAnalysisResultCategory = createAction(
  DELETE_ANALYSIS_RESULT_CATEGORY,
  props<{ iri: string }>()
);

export const DeleteAnalysisResultCategorySuccess = createAction(
  DELETE_ANALYSIS_RESULT_CATEGORY_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteAnalysisResultCategoryFail = createAction(
  DELETE_ANALYSIS_RESULT_CATEGORY_FAIL,
  props<{ response: HttpErrorResponse }>()
);
