import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import * as fromModuleModels from '../../models';
import { Order, OrderComment } from '../../models';
import * as fromAdministratorModuleModels from '../../../administrators/models';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import {AdministratorFeedbackRequestsActions, AdministratorsActions} from '../../../administrators/store';
import {
  OrderCommentsSelectors,
  OrderCommentTagsSelectors
} from '../../store/selectors';
import * as OrdersModuleActions from '../../store';
import { OrderCommentsActions, OrderCommentTagsActions } from '../../store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderCommentFormDialogComponent } from '../order-comment-form-dialog/order-comment-form-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { ReplyCommentModalComponent } from '../../../shared/components/reply-comment-modal/reply-comment-modal.component';
import {
  FeedbackRequestsActions,
  FeedbackRequestsSelectors
} from '../../../notifications/store';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';
import { AuthService } from '../../../auth/services/auth.service';
import {AbstractApiService} from "../../../shared/services";

@Component({
  selector: 'app-order-comments',
  styleUrls: ['order-comments.component.scss'],
  template: `
    <div class="card">
      <div class="card__heading">
        <span>Kommentare</span>
      </div>

      <div class="card__content">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
        <div class="order__comments">
          <div *ngIf="(comments$ | async).length <= 0" class="item comment">
            <span *ngIf="isLoading$ | async">Kommentare werden geladen...</span>
            <span *ngIf="(isLoading$ | async) === false"
              >Keine Kommentare vorhanden.</span
            >
          </div>
          <div
            *ngFor="let comment of comments$ | async"
            class="item comment"
            [id]="comment['@id']"
            [ngClass]="{ active: comment['@id'] === targetComment }"
            [class.yellow]="comment.isFeedbackRequest && !comment.feedbackRequestResolved"
          >
            <div class="grid">
              <div class="column-10">
                <span class="created-by" *ngIf="comment.isFeedbackRequest"
                  >Rückfrage von
                  {{ getAdministratorName(comment.createdBy) }} an
                  {{
                    comment.recipient === currentUser['@id']
                      ? 'Dich'
                      : getAdministratorName(comment.recipient)
                  }}
                </span>
                <span
                  class="created-by"
                  *ngIf="!comment.isFeedbackRequest && comment.createdBy"
                  >{{
                    comment.additionalContent
                      ? 'Kundenkommentar'
                      : getAdministratorName(comment.createdBy)
                  }}</span
                >
                <span
                  class="created-by"
                  *ngIf="!comment.isFeedbackRequest && !comment.createdBy"
                  >System</span
                >
                <span class="created-at">
                  {{
                    comment.additionalContent
                      ? '(' + getAdministratorName(comment.createdBy) + ')'
                      : ''
                  }}
                  {{ comment.createdAt | date: 'medium' }}
                  <span
                    [hidden]="comment.createdAt === comment.updatedAt"
                    [matTooltip]="
                      'Bearbeitet: ' + (comment.updatedAt | date: 'medium')
                    "
                    class="text-small"
                    >(bearbeitet)
                  </span>
                </span>
                <span class="comment__tag" *ngIf="comment.commentTag">{{
                  getCommentTag(comment.commentTag)
                }}</span>
                <span class="action__tag" *ngIf="comment.actionTag">{{
                  comment.actionTag
                }}</span>
              </div>

              <div class="column-4 m-ta--2">
                <div
                  class="feedback-requests-actions"
                  *ngIf="comment.isFeedbackRequest && canEditAndDelete"
                >
                  <button
                    mat-icon-button
                    [matTooltip]="comment.feedbackRequestResolved ? ('shared.tooltip.mark_as_resolved' | translate) : ('shared.tooltip.mark_as_resolved' | translate)"
                    (click)="resolveFeedback(comment)"
                    [class.resolved]="comment.feedbackRequestResolved"
                  >
                    <mat-icon>check</mat-icon>
                  </button>
                  <!--                  <button mat-button color="green" class="small-button">-->
                  <!--                    <mat-icon>check</mat-icon>-->
                  <!--                    {{comment.feedbackRequestAnswered ? 'Mark as unanswered' : 'Mark as answered'}}-->
                  <!--                  </button>-->

                  <button
                    [matMenuTriggerFor]="dropdownMenu"
                    mat-icon-button
                    *ngIf="
                    (comment.isFeedbackRequest &&
                      comment.recipient === currentUser['@id']) ||
                    canEditAndDelete ||
                    comment.createdBy === currentUser['@id']
                  "
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                </div>
                <mat-menu #dropdownMenu xPosition="before">
                  <button
                    (click)="replyToComment(comment)"
                    data-action="reply"
                    *ngIf="
                      comment.isFeedbackRequest &&
                      comment.recipient === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>reply</mat-icon>
                    <span>Antworten</span>
                  </button>

                  <button
                    (click)="handleRequestOrderCommentForm(comment)"
                    data-action="edit"
                    *ngIf="
                      canEditAndDelete ||
                      comment.createdBy === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Kommentar bearbeiten</span>
                  </button>
                  <button
                    (click)="handleRequestDeleteOrderComment(comment)"
                    data-action="delete"
                    *ngIf="
                      canEditAndDelete ||
                      comment.createdBy === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Kommentar löschen</span>
                  </button>
                </mat-menu>
              </div>

              <div
                class="{{
                  comment.additionalContent ? 'column-12' : 'column-14'
                }}"
              >
                <div
                  *ngIf="comment?.content"
                  class="comment__content"
                  [innerHTML]="comment?.content | safeHtml"
                ></div>
                <div
                  *ngIf="!comment || !comment.content"
                  class="comment__content text-color-lightgrey"
                >
                  Kein Kommentar Text vorhanden
                </div>
              </div>

              <div *ngIf="comment.additionalContent" class="column-2 m-ta--2">
                <button
                  mat-icon-button
                  (click)="additionalContent.classList.toggle('is-expanded')"
                >
                  <mat-icon
                    >{{
                      additionalContent.classList.contains('is-expanded')
                        ? 'keyboard_arrow_up'
                        : 'keyboard_arrow_down'
                    }}
                  </mat-icon>
                </button>
              </div>

              <div class="column-14 comment__ac-wrapper" #additionalContent>
                <div
                  class="comment__additional-content"
                  *ngIf="comment.additionalContent"
                  [innerHTML]="comment.additionalContent | safeHtml"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <app-order-comment-form [order$]="order$"></app-order-comment-form>
      </div>
    </div>
  `
})
export class OrderCommentsComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  order: Order;
  currentUser: any;
  administratorsEntities: {
    [iri: string]: fromAdministratorModuleModels.Administrator;
  };
  comments$: Observable<Array<fromModuleModels.OrderComment>> = of([]);
  tagsEntities: { [iri: string]: fromModuleModels.OrderCommentTag };

  isLoading$: Observable<boolean>;

  targetComment = '';
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private action$: Actions,
    private authService: AuthService,
    private apiService: AbstractApiService
  ) {}

  get canEditAndDelete(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(OrderCommentsSelectors.isLoading),
      this.store$.select(OrderCommentTagsSelectors.isLoading)
    ]);
    this.scrollToSelectedComment();
    this.loadAdministrators();
    this.loadOrderCommentTags();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.loadComments(order);
      });
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.action$
      .pipe(
        ofType(FeedbackRequestsActions.UpdateFeedbackRequestSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        if (this.order) {
          this.loadComments(this.order);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getAdministratorName(iri: string): string {
    if (iri?.indexOf('/customer_accounts/') > -1) {
      return 'Kunde';
    }
    return (
      this.administratorsEntities[iri]?.firstName +
      ' ' +
      this.administratorsEntities[iri]?.lastName
    );
  }

  getCommentTag(iri: string): string | null {
    if (this.tagsEntities[iri]) {
      return this.tagsEntities[iri]?.commentTag;
    }
    return null;
  }

  scrollToSelectedComment(): void {
    this.route.queryParams.subscribe(params => {
      this.targetComment = '/api/order_comments/' + params.target_comment;
    });

    this.comments$.subscribe(comments => {
      const vm = this;
      setTimeout(() => {
        if (vm.targetComment) {
          const target = document.getElementById(vm.targetComment);
          if (target) {
            target.scrollIntoView();
          }
        }
      }, 500);
    });
  }

  handleRequestOrderCommentForm(comment: OrderComment): void {
    this.dialog.open(OrderCommentFormDialogComponent, {
      disableClose: false,
      data: {
        order$: this.order$,
        comment$: new BehaviorSubject(comment)
      }
    });
  }

  handleRequestDeleteOrderComment(comment: OrderComment): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Kommentar löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          OrdersModuleActions.OrderCommentsActions.DeleteOrderComment({
            iri: comment['@id']
          })
        );
      });
  }

  replyToComment(comment: OrderComment): void {
    this.store$
      .select(FeedbackRequestsSelectors.selectFeedbackRequestByCommentIri, {
        commentIri: comment['@id']
      })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(element => !!element)
      )
      .subscribe(feedbackRequest => {
        this.dialog.open(ReplyCommentModalComponent, {
          disableClose: true,
          panelClass: 'pos-relative',
          data: { response: feedbackRequest, variation: 'answer', module: 'order' }
        });
      });

    //Update comments after close of dialog
    this.dialog.afterAllClosed.subscribe(
      () => {
        this.actions$
          .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.loadComments(this.order);
          })
      }
    )
  }

  private loadAdministrators(): void {
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.administratorsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }

  private loadOrderCommentTags(): void {
    this.store$
      .select(OrderCommentTagsSelectors.selectOrderCommentTagsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.tagsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      OrderCommentTagsSelectors.isLoaded,
      OrderCommentTagsActions.ReadOrderCommentTags()
    );
  }

  private loadComments(order: Order): void {
    this.comments$ = this.store$
      .select(OrderCommentsSelectors.selectOrderCommentsByOrder, {
        iri: order['@id']
      })
      .pipe(map(e => e.sort(sortByCreatedAtDate)));
    this.store$.dispatch(
      OrderCommentsActions.ReadOrderComments({
        page: -1,
        params: { 'order.uuid': getUuidFromIri(order['@id']) }
      })
    );
  }


  resolveFeedback(comment: OrderComment): void {
    this.store$.dispatch(
      AdministratorFeedbackRequestsActions.PatchFeedbackRequests({
        iri: comment['@id'],
        module: 'order',
        variation: "resolve",
        payload: { feedbackRequestResolved: !comment.feedbackRequestResolved }
      })
    )
    this.actions$
      .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadComments(this.order);
      })
  }
}
