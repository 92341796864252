// Provider Imports

import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';

export interface Violation {
  message: string;
  propertyPath: string;
}

export interface ErrorsObject {
  [property: string]: Violation;
}

export interface ApiErrorResponse {
  '@context'?: string;
  '@type'?: string;
  'hydra:description'?: string;
  'hydra:title'?: 'An error occurred';
  violations: Array<Violation>;
}

export interface APIError {
  message: string;
  messages: string[];

}

export interface FormErrors {
  field: string;
  errors: [string];
}

export const getErrorsObject = (error: ApiErrorResponse): ErrorsObject => {

  const errors = {};

  if (error.hasOwnProperty('violations')) {

    const {violations} = error;

    violations.forEach((violation) => {
      // Flatten property path if property is nested
      /*const propertyPath = violation.propertyPath.indexOf('.') !== -1
        ? flattenPropertyPath(violation.propertyPath)
        : violation.propertyPath*/

      errors[violation.propertyPath] = {
        message: violation.message
      };
    });
  }
  return errors;
};

export const getErrorsObjectForTransitionResponse = (error: ApiErrorResponse): Array<string> => {

  let errors = [];

  if (error.hasOwnProperty('violations')) {

    const {violations} = error;

    violations.forEach((violation) => {
      errors = [
        ...errors,
        violation.message
      ];
    });
  }

  return errors;
};
export const getErrorOfResponse = (error: any): APIError => {
  const messages = [];
  if (error.error && error.error['hydra:description']) {
    messages.push(error.error['hydra:description']);

  }
  return {message: messages.join('\n'), messages};
};
export const getErrorsOfForm = (form: FormGroup, prefix = ''): FormErrors[] => {
  const result = [];
  for (const key of Object.keys(form.controls)) {
    if (form.get(key) instanceof FormGroup) {
      result.push(...getErrorsOfForm(form.get(key) as FormGroup, prefix + '.' + key));
    } else if (form.get(key) instanceof FormControl) {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        const field = prefix ? prefix + '.' + key : key;
        const obj = {field, errors: []};
        for (const keyError of Object.keys(controlErrors)) {
          obj.errors.push(keyError);
        }
        result.push(obj);
      }
    }
  }
  return result;
};
export const flattenPropertyPath = (property: string): string => {

  // alert(property);
  return property.split('.').pop();
};

export const selectErrors = (allErrors, identifier: string | Array<string>): ErrorsObject => {

  if (Array.isArray(identifier)) {

    const notInArray = identifier.filter(i => !allErrors.hasOwnProperty(i));

    if (notInArray.length) {
      throw Error(`Identifier(s) '${notInArray.toString()}' do(es) not exist in errors object.`);
    }

    return identifier.reduce((errors, _identifier) => {
        return {
          ...errors,
          ...allErrors[_identifier]
        };
      },
      {}
    );
  } else {
    if (!allErrors.hasOwnProperty(identifier)) {
      throw Error(`Identifier '${identifier}' does not exist in errors object.`);
    }

    return allErrors[identifier];
  }
};

export const ErrorsUtility = {
  getErrorsObject,
  selectErrors
};
