import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs/tab-group';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {combineLatestObject} from 'rxjs-etc';
import {filter, takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {PayableInvoicesActions} from '../../store';
import {ApplicationState} from '../../../application-state/store';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {Router} from '@angular/router';
import {RouterActions} from '../../../application-state/store/actions';

@Component({
  styleUrls: ['invoices-view.component.scss'],
  template: `
    <div class="row">
      <div class="col-auto me-auto">
        <h1>
          Faktura
          <a
            mat-flat-button
            class="ms-3"
            [routerLink]="['payable', 'new']"
            *ngIf="activeLink?.value === 'payable_invoices'"
          ><span class="material-icons me-2">add</span>neue Rechnung
          </a>
          <!--<a mat-flat-button class="ms-3" [routerLink]="['cancellation','new']"
             *ngIf="activeLink?.value==='cancellation_invoices'">neue Storno Rechnung
          </a>-->
          <a
            mat-flat-button
            class="ms-3"
            [routerLink]="['offers', 'new']"
            *ngIf="activeLink?.value === 'offers'"
          ><span class="material-icons me-2">add</span>neues Angebot erstellen
          </a>
          <a
            mat-flat-button
            class="ms-3"
            [routerLink]="['/invoices','corrections', 'new']"
            *ngIf="activeLink?.value === 'correction_invoices'"
          ><span class="material-icons me-2">add</span>Gutschrift erstellen
          </a>
          <!--          <a-->
          <!--            mat-flat-button-->
          <!--            class="ms-3"-->
          <!--            [routerLink]="['commission-credits', 'new']"-->
          <!--            *ngIf="activeLink?.value === 'commission_credits'"-->
          <!--          >-->
          <!--            <span class="material-icons me-2">add</span>Provisionsgutschrift-->
          <!--            erstellen-->
          <!--          </a>-->
        </h1>
      </div>
      <div class="col-auto">
        <app-search-input
          [showToggle]="true"
          (searchTermChange)="handleSearchInput($event)"
          (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
        ></app-search-input>
      </div>
    </div>
    <div class="row">
      <nav mat-tab-nav-bar class="col">
        <a
          mat-tab-link
          *ngFor="let link of links"
          [active]="isActive(link)"
          [routerLinkActive]="link.url"
          #rla="routerLinkActive"
          [routerLink]="link.url"
        >
          {{ link.label }}
        </a>
      </nav>
    </div>
    <div class="row">
      <router-outlet></router-outlet>
    </div>
    <!--
        <mat-tab-group animationDuration="0">

          <mat-tab label="Rechnungen">
            <app-payable-invoices></app-payable-invoices>
          </mat-tab>

          <mat-tab label="Storno-Rechnungen">
            <cancellation-invoices
            ></cancellation-invoices>
          </mat-tab>

          <mat-tab label="Angebote">
            <offers
            ></offers>
          </mat-tab>

          <mat-tab label="Gutschriften">
            <correction-invoices
            ></correction-invoices>
          </mat-tab>

          <mat-tab label="Provisionsgutschriften nach Partner">
            <brokers
            ></brokers>
          </mat-tab>

          <mat-tab label="Erstellte Provisionsgutschriften">
            <commission-credits
            ></commission-credits>
          </mat-tab>

        </mat-tab-group>-->
  `,
  providers: [SearchAddonDataService]
})
export class InvoicesViewComponent implements OnInit, OnDestroy {
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  filterString = '';
  onDestroy$: Subject<any> = new Subject<any>();

  links: Array<{
    url: string;
    label: string;
    value: string;
  }> = [
    {url: '/invoices', value: 'payable_invoices', label: 'Rechnungen'},
    {
      url: '/invoices/cancellation',
      value: 'cancellation_invoices',
      label: 'Storno-Rechnungen'
    },
    {url: '/invoices/offers', value: 'offers', label: 'Angebote'},
    {
      url: '/invoices/corrections',
      value: 'correction_invoices',
      label: 'Gutschriften'
    },
    {
      url: '/invoices/brokers',
      value: 'brokers',
      label: 'Partnergutschriften'
    },
    {
      url: '/invoices/commission-credits',
      value: 'commission_credits',
      label: 'Provisionsgutschriften'
    }
  ];

  get activeLink(): {
    url: string;
    label: string;
    value: string;
  } {
    return this.links.find(this.isActive.bind(this));
  }

  constructor(
    private store$: Store<ApplicationState>,
    private title: AbstractTitleService,
    private searchAddonDataService: SearchAddonDataService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('Faktura');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  isActive(link: any): boolean {
    let url = this.router.url.toLowerCase();
    if (this.router.url.indexOf('?') > -1) {
      url = this.router.url.toLowerCase().split('?')[0];
    }
    return url === link.url.toLowerCase();
  }

  goToNewInvoice(): void {
    this.store$.dispatch(
      RouterActions.Go({path: ['invoices', 'payable', 'new']})
    );
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
    this.updateFilterData();
  }

  handleSearchInput(term: string): void {
    this.filterString = term;
    this.updateFilterData();
  }

  private updateFilterData(): void {
    this.searchAddonDataService.setData({
      filterString: this.filterString,
      searchBoxVisible: this.searchBoxVisible$.getValue()
    });
  }
}
