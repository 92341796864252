import {AfterViewInit, Component, Input, Output, EventEmitter, ViewChild, OnDestroy, OnInit} from '@angular/core';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import * as moment from 'moment/moment';

import * as fromAdministratorsModuleModels from '../../../administrators/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import {DynamicFormComponent} from '../../../shared/containers/dynamic-form/dynamic-form.component';
import {IDynamicFormField} from '../../../shared/models';
import {removeEmptyFormElements} from '../../../shared/utilities/forms.utility';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-ticket-filter-form',
  styleUrls: ['ticket-filter-form.component.scss'],
  template: `

    <div class="ticket-filter__outer mb-3" *ngIf="searchBoxVisible$|async">
      <div class="ticket-filter__wrapper">

        <div class="tickets-filter pt-2" [formGroup]="form">
          <div class="container-fluid">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col-2">
                    <app-ticket-technician-select fieldName="technician"
                                                  [formGroup]="form"></app-ticket-technician-select>
                  </div>
                  <div class="col-2">
                    <app-ticket-priority-mode-select fieldName="dataRecoveryPriorityMode"
                                                     [formGroup]="form"></app-ticket-priority-mode-select>
                  </div>
                  <div class="col-3">
                    <app-ticket-state-range-select fieldName="stateRange"
                                                   [formGroup]="form"></app-ticket-state-range-select>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row">
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>Ticketdatum vom</mat-label>
                      <input type="date" matInput formControlName="createdAtMin">
                      <mat-error>
                        <app-form-error fieldName="createdAtMin" [formGroup]="form"></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">

                    <mat-form-field>
                      <mat-label>Ticketdatum bis</mat-label>
                      <input type="date" matInput formControlName="createdAtMax">
                      <mat-error>
                        <app-form-error fieldName="createdAtMax" [formGroup]="form"></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field>
                      <mat-label>Released vom</mat-label>
                      <input type="date" matInput formControlName="releasedAtMin">
                      <mat-error>
                        <app-form-error fieldName="releasedAtMin" [formGroup]="form"></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-6">

                    <mat-form-field>
                      <mat-label>Released bis</mat-label>
                      <input type="date" matInput formControlName="releasedAtMax">
                      <mat-error>
                        <app-form-error fieldName="releasedAtMax" [formGroup]="form"></app-form-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<pre>{{ dataRecoveryPriorityModes$ | async | json }}</pre>-->
    </div>
  `
})
export class TicketFilterFormComponent implements OnInit, OnDestroy {


  @Input() searchBoxVisible$: BehaviorSubject<boolean>;

  @Output() requestHandleAction: EventEmitter<{ [k: string]: string | number | boolean }> = new EventEmitter();
  @Output() updateFilter: EventEmitter<{ [key: string]: string }> = new EventEmitter<{ [key: string]: string }>();

  form: FormGroup;
  storedFilters?: any;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, public localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      stateRange: this.fb.control(null),
      dataRecoveryPriorityMode: this.fb.control(null),
      technician: this.fb.control(null),
      createdAtMin: this.fb.control(null),
      createdAtMax: this.fb.control(null),
      releasedAtMin: this.fb.control(null),
      releasedAtMax: this.fb.control(null),
    });
    this.form.valueChanges.subscribe(value => {
      this.updateFilter.emit(removeEmptyFormElements(value));
      this.localStorageService.overwriteObjectByKey('filters', 'tickets', value);
    });
    this.searchBoxVisible$.subscribe((visible) => {
      if (!visible) {
        this.form.reset();
      }
    });
    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'tickets');
    if(this.storedFilters) {
      this.requestHandleAction.emit(this.storedFilters);
      this.form.patchValue(this.storedFilters);
    }
  }


  handleRequestHandleAction(formValues: { [k: string]: string | number | boolean }): void {

    const values = {...formValues};

    if (!!values?.createdAtMin) {
      values.createdAtMin = moment(formValues.createdAtMin as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    if (!!values?.createdAtMax) {
      values.createdAtMax = moment(formValues.createdAtMax as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    if (!!values.releasedAtMin) {
      values.releasedAtMin = moment(formValues.releasedAtMin as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    if (!!values.releasedAtMax) {
      values.releasedAtMax = moment(formValues.releasedAtMax as string, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    // Initial emit from dynamic form component is empty
    if (Object.keys(values).length) {
      this.requestHandleAction.emit(values);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
