import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import {Store} from '@ngrx/store';
import {sortBy} from 'lodash-es';

import {ApplicationState} from '../../../application-state/store';
import {OffersActions, PayableInvoicesActions} from '../../store';
import {
  OffersSelectors
} from '../../store/selectors';
import {CancellationInvoice, Offer} from '../../models';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {extractIri} from '../../../shared/utilities/objects.utility';
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "../../../shared/services";
import {OffersService} from "../../services";
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";

@Component({
  selector: 'app-offers',
  styleUrls: ['offers-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="col pt-3">
      <div class="row">
        <div class="col">
          <app-offers-filter
            *ngIf="searchBoxVisible"
            [items]="offers$ | async"
            (updateFilter)="handleFilterFormAction($event)"
          ></app-offers-filter>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-paginator-group pos-relative">
            <app-loading-overlay *ngIf="loadingTable$|async"></app-loading-overlay>
            <app-offers-list
              *ngIf="offers$ | async"
              [items]="offers$ | async"
            ></app-offers-list>
            <app-paginator-unstyled
              [totalItems]="offersCount"
              [pageSize]="pageSize"
              [pageIndex]="currentPage - 1"
              [pageSizeOptions]="pageSizeOptions"
              (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
              [showFirstLastButtons]="true"
            ></app-paginator-unstyled>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OffersViewComponent implements OnInit, OnDestroy {
  offers$: BehaviorSubject<Array<Offer>> = new BehaviorSubject<Array<Offer>>([]);
  currentPage = 1;
  pageSize = 30;
  pageSizeOptions = [5, 10, 15, 20, 25];
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [p: string]: any }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc',
  });
  activeFilter: { [p: string]: string } = {};
  searchBoxVisible = false;
  onDestroy$: Subject<any> = new Subject<any>();
  filterString = '';
  offersCount: number;
  subscriptionList: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private store$: Store<ApplicationState>,
    private localStorageService: LocalStorageService,
    private searchAddonDataService: SearchAddonDataService,
    private offersService: OffersService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.loadOffers();
    const filters = this.localStorageService.getObjectByKey('filters', 'offers');
    if(filters) {
      this.handleFilterFormAction(filters);
    }
    // this.isLoading$ = this.store$.select(OffersSelectors.isLoading);
    // this.store$.dispatch(OffersActions.ReadOffers({page: -1}));
    // this.offers$ = this.store$.select(OffersSelectors.selectOffers);

    this.searchAddonDataService
      .getObservable()
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(data => {
        this.handleSearchInput(data.filterString);
        this.cd.detectChanges();
      });

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.updateParams();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.updateParams();
    this.loadOffers();
    this.updateUrl();
  }
  handleFilterFormAction(filters: any): void {
    const params = {
      department: null,
      orderNumber: null,
      'grossTotal.value[gte]': null,
      'grossTotal.value[lte]': null,
      'createdAt[strictly_after]': null,
      'createdAt[strictly_before]': null,
    };

    if (filters?.department) {
      params['issuer.uuid'] = getUuidFromIri(extractIri(filters.department));
    } else {
      params['issuer.uuid'] = null;
    }
    if (filters?.orderNumber) {
      params.orderNumber =  String(filters.orderNumber);
    }

    if (filters?.exactGrossPrice) {
      params['grossTotal.value[lte]'] = filters.exactGrossPrice;
      params['grossTotal.value[gte]'] = filters.exactGrossPrice;
    }

    if (filters?.createdAtMin) {
      params['createdAt[strictly_after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[strictly_before]'] = filters.createdAtMax;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });
    this.updateParams();
    this.loadOffers();
  }
  loadOffers(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    this.loadingTable$.next(true);
    this.subscriptionList = this.offersService.readOffers(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.loadingTable$.next(false);
        this.offersCount = response['hydra:totalItems'];
        this.offers$.next(this.sortInvoices(response['hydra:member']));
      }, (fail) => {
        this.loadingTable$.next(false);
        console.error(fail);
      });
  }
  sortInvoices(
    toBeSortedInvoices: Offer[]
  ): Offer[] {
    // return sortBy(toBeSortedInvoices, (e: CancellationInvoice) => e.cancellationInvoiceNumber);
    return sortBy(
      toBeSortedInvoices,
      e =>
        moment(e.bookedAt)
          .toDate()
          .getTime() * -1
    );
  }

  handleSearchInput(term: string): void {
    this.params$.next({
      ...this.params$.getValue(),
      fulltext_search: term ? term : ''
    });

    this.store$.dispatch(
      OffersActions.ReadOffers({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
    this.loadOffers();
  }

  // handleUpdateFilter(filters: { [p: string]: string }): void {
  //   this.searchAddonDataService.updateData({ activeFilter: filters });
  // }

  private updateParams(): void {
    if (this.searchBoxVisible === false) {
      this.params$.next({
        ...this.params$.getValue(),
        'order[createdAt]': 'desc',
        fulltext_search: this.params$.getValue().hasOwnProperty('fulltext_search') ? this.params$.getValue().fulltext_search : ''
      });
    }
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });
  }
}
