import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { Customer } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomer(payload: Customer): Observable<Customer> {
    return this.rolesService.userHasRoleFilter<Customer>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/customers`, payload)
    );
  }

  readCustomer(iri: string): Observable<Customer> {
    return this.rolesService.userHasRoleFilter<Customer>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readCustomers(
    page = 1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/customers', page, params)
            )
    );
  }

  readCustomerChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  readPartners(
    page = 1,
    params: ParameterBag = {}
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      () => this.readCustomers(page, { ...params, isPartner: true })
    );
  }

  readPartnersChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      () => this.readCustomerChunk(uri)
    );
  }

  readCustomerInvoices(customerIri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_INVOICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`${customerIri}/invoices`, true)
    );
  }

  readCustomerOffers(customerIri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_OFFER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`${customerIri}/offers`, true)
    );
  }

  readCustomerOrders(
    page = 1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse> {
    return this.apiService.getObject(
      getUriWithPageAndParams('/orders', page, params)
    );
  }

  updateCustomer(
    iri: string,
    payload: Customer
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomer(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
