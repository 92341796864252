import { CancellationInvoicesEffects } from './cancellation-invoices.effects';
import { CommissionCreditItemsEffects } from './commission-credit-items.effects';
import { CommissionCreditsEffects } from './commission-credits.effects';
import { CorrectionInvoiceItemsEffects } from './correction-invoice-items.effects';
import { CorrectionInvoicesEffects } from './correction-invoices.effects';
import { DocumentDeliveriesEffects } from './document-deliveries.effects';
import { DocumentDeliveryProvidersEffects } from './document-delivery-providers.effects';
import { InvoiceItemsEffects } from './invoice-items.effects';
import { InvoicePaymentsEffects } from './invoice-payments.effects';
import { OfferItemsEffects } from './offer-items.effects';
import { OffersEffects } from './offers.effects';
import { PartialInvoiceItemsEffects } from './partial-invoice-items.effects';
import { PayableInvoicesEffects } from './payable-invoices.effects';
import { PaymentProcessesEffects } from './payment-processes.effects';

export const effects: Array<any> = [
  CancellationInvoicesEffects,
  CommissionCreditItemsEffects,
  CommissionCreditsEffects,
  CorrectionInvoiceItemsEffects,
  CorrectionInvoicesEffects,
  DocumentDeliveriesEffects,
  DocumentDeliveryProvidersEffects,
  InvoiceItemsEffects,
  InvoicePaymentsEffects,
  OfferItemsEffects,
  OffersEffects,
  PartialInvoiceItemsEffects,
  PayableInvoicesEffects,
  PaymentProcessesEffects,
];
