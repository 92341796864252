import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { upperFirst } from 'lodash-es';
import { Tax } from '../../../master-data/models';
import { FormsService } from '../../../shared/services';
import { ObjectsUtility } from '../../../shared/utilities/objects.utility';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { DataRecoveryCosts, Order } from '../../models';
import { TaxesSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { TaxesActions } from '../../../master-data/store';
import { Actions, ofType } from '@ngrx/effects';
import { DataRecoveryCostsActions, OrdersActions } from '../../store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import { DataRecoveryCostsSelectors } from '../../store/selectors';
import { GrossPriceOutputComponent } from '../../../shared/components/gross-price-output/gross-price-output.component';

@Component({
  selector: 'app-initial-costs-form',
  styleUrls: ['initial-costs-form.component.scss'],
  template: `
    <div class="grid" [formGroup]="form">
      <ng-container *ngFor="let field of formFields">
        <div class="column-5">
          <strong>Preis {{ getControlCaption(field) }}</strong>
          <br *ngIf="showPartnerProvisionWarning(field)" />
          <span
            *ngIf="showPartnerProvisionWarning(field)"
            class="text-color-red text-smaller"
            >Partner-Provision beachten!</span
          >
          <br *ngIf="!!dataRecoveryCosts" />
          <span
            *ngIf="!!dataRecoveryCosts"
            class="text-color-grey text-smaller"
            >{{ getSellingPriceInfo(field) }}</span
          >
          <br
            *ngIf="
              !!dataRecoveryCosts &&
              field === 'initialCosts' &&
              dataRecoveryCosts.sellingPriceOther.value > 0
            "
          />
          <span
            *ngIf="
              !!dataRecoveryCosts &&
              field === 'initialCosts' &&
              dataRecoveryCosts.sellingPriceOther.value > 0
            "
            class="text-color-grey text-smaller"
          >
            (VK Sonstiges: {{ dataRecoveryCosts.sellingPriceOther.value }}
            {{
              dataRecoveryCosts.sellingPriceOther.currency | currencyShortener
            }}
            )
          </span>
        </div>

        <div class="column-3" [formGroupName]="getKey('price', field, 'Net')">
          <mat-form-field>
            <mat-placeholder>Netto</mat-placeholder>
            <input
              type="text"
              matInput
              formControlName="value"
              required
              currencyMask
              [options]="{ prefix: '', suffix: ' €' }"
              (keyup)="handleUpdateGrossValue(field, true)"
            />
            <mat-error>
              <app-form-error
                [fieldName]="getKey('price', field, 'Net') + '.value'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-3" [formGroupName]="getKey('price', field, 'Gross')">
          <mat-form-field>
            <mat-placeholder>Brutto</mat-placeholder>
            <input
              type="text"
              matInput
              formControlName="value"
              required
              currencyMask
              [options]="{ prefix: '', suffix: ' €' }"
              (keyup)="handleUpdateNetValue(field)"
            />
            <mat-error>
              <app-form-error
                [fieldName]="getKey('price', field, 'Gross') + '.value'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mat-form-field column-3">
          <ng-select
            placeholder="USt."
            [items]="taxes$ | async"
            [loading]="taxesIsLoading$ | async"
            bindLabel="name"
            bindValue="@id"
            [searchable]="false"
            [clearable]="false"
            (change)="handleUpdateGrossValue(field)"
            [formControlName]="getKey('tax', field)"
          ></ng-select>
          <mat-error>
            <app-form-error
              [fieldName]="getKey('tax', field) + '.value'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </div>
      </ng-container>
    </div>

    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
    <!--<pre>{{ icf.value | json }}</pre>-->
  `
})
export class InitialCostsFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(GrossPriceOutputComponent) grossPricesOutputs!: QueryList<
    GrossPriceOutputComponent
  >;

  @Input() order$: Observable<Order>;
  order: Order;
  @Input() errors: ErrorsObject;
  taxes$: Observable<Array<Tax>>;
  taxesIsLoading$: Observable<boolean>;
  taxesEntities: { [iri: string]: Tax };

  formFieldMap = {
    initialCosts: 'Initialkosten',
    analysis: 'Analyse',
    dataRecovery: 'Datenrettung',
    returnShipment: 'Rückversand',
    disposal: 'Entsorgung',
    dismantling: 'Rückbau'
  };

  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  @Output() updateFormData: EventEmitter<any> = new EventEmitter();
  dataRecoveryCosts: DataRecoveryCosts;
  private defaultTax: Tax;

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    public actions$: Actions
  ) {}

  get formFields(): Array<string> {
    return ObjectsUtility.getObjectKeys(this.formFieldMap);
  }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(values => {
        const valuesCleaned = removeEmptyFormElements(values);
        const filteredFormData = {};
        for (const key of Object.keys(valuesCleaned)) {
          if (key.indexOf('Gross') > -1) {
          } else if (
            key.indexOf('Net') > -1 &&
            typeof values[key].value === 'number'
          ) {
            filteredFormData[key] = {
              value: values[key].value.toFixed(4),
              currency: values[key].currency
            };
          } else {
            filteredFormData[key] = values[key];
          }
        }
        this.updateFormData.emit(filteredFormData);
      });
    this.order$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(order => {
        this.order = order;
        if (order.dataRecoveryCosts && order.dataRecoveryCosts.length > 0) {
          const lastIri =
            order.dataRecoveryCosts[order.dataRecoveryCosts.length - 1];
          this.store$.dispatch(
            DataRecoveryCostsActions.ReadDataRecoveryCosts({ iri: lastIri })
          );
          this.store$
            .select(DataRecoveryCostsSelectors.sByIri, { iri: lastIri })
            .pipe(
              takeUntil(this.onDestroy$),
              distinctUntilChanged(),
              filter(e => !!e)
            )
            .subscribe((cost: DataRecoveryCosts) => {
              this.dataRecoveryCosts = cost;
              const initialCostsNet = this.form.get(
                'priceInitialCostsNet.value'
              );
              if (initialCostsNet.value === null) {
                initialCostsNet.patchValue(cost.sellingPriceInitial.value);
              }
              const dataRecoveryPriceNet = this.form.get(
                'priceDataRecoveryNet.value'
              );
              if (dataRecoveryPriceNet.value === null) {
                dataRecoveryPriceNet.setValue(
                  cost.sellingPriceDataRecovery.value
                );
              }
              this.updatePrices();
            });
        }
      });
    this.loadTaxes();
    this.actions$
      .pipe(ofType(OrdersActions.UpdateOrderFail))
      .subscribe(fail => {
        if (fail?.response?.error?.violations) {
          this.formService.mergeErrorsIntoForm(
            fail.response.error.violations,
            this.form
          );
        }
      });
  }

  ngAfterViewInit(): void {
    this.order$.pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      if (!!order) {
        const formData = { ...order };

        if (formData.taxInitialCosts && formData.taxInitialCosts['@id']) {
          formData.taxInitialCosts = formData.taxInitialCosts['@id'];
        }
        if (formData.taxDataRecovery && formData.taxDataRecovery['@id']) {
          formData.taxDataRecovery = formData.taxDataRecovery['@id'];
        }
        if (formData.taxAnalysis && formData.taxAnalysis['@id']) {
          formData.taxAnalysis = formData.taxAnalysis['@id'];
        }
        if (formData.taxReturnShipment && formData.taxReturnShipment['@id']) {
          formData.taxReturnShipment = formData.taxReturnShipment['@id'];
        }
        if (formData.taxDisposal && formData.taxDisposal['@id']) {
          formData.taxDisposal = formData.taxDisposal['@id'];
        }
        if (formData.taxDismantling && formData.taxDismantling['@id']) {
          formData.taxDismantling = formData.taxDismantling['@id'];
        }
        /* for (const key of Object.keys(formData)) {
           const valueGroup = formData[key];
           if (valueGroup && valueGroup.value && valueGroup['@type'] && valueGroup['@type'] === 'Money') {
             valueGroup.value = parseFloat(valueGroup.value);
           }
         }*/

        this.fs.patchForm(this.form, formData);

        this.updatePrices();
      }
      this.form.markAsUntouched();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.form = this.fb.group({});
    for (const formKey of this.formFields) {
      const netKey = this.getKey('price', formKey, 'Net');
      this.form.addControl(
        netKey,
        this.fb.group({
          value: this.fb.control(null, [Validators.required]),
          currency: this.fb.control('EUR', [Validators.required])
        })
      );
      const taxKey = this.getKey('tax', formKey);
      this.form.addControl(taxKey, this.fb.control(null));

      const grossKey = this.getKey('price', formKey, 'Gross');
      this.form.addControl(
        grossKey,
        this.fb.group({
          value: this.fb.control(null, [Validators.required]),
          currency: this.fb.control('EUR', [Validators.required])
        })
      );
    }
  }

  handleUpdateGrossValue(fieldName: string, force: boolean = false): void {
    const taxField = this.getKey('tax', fieldName);
    const netField = this.getKey('price', fieldName, 'Net');
    const grossField = this.getKey('price', fieldName, 'Gross');
    const netValue = parseFloat(this.form.get(netField + '.value').value);
    if (
      !this.form.get(taxField).value ||
      !this.taxesEntities[this.form.get(taxField).value]
    ) {
      return;
    }
    const taxValue = parseFloat(
      this.taxesEntities[this.form.get(taxField).value].value
    );
    const grossValue =
      Math.round(netValue * (taxValue / 100 + 1) * 10000) / 10000;
    if (force || this.form.get(grossField + '.value').value === null) {
      this.form.get(grossField + '.value').patchValue(grossValue);
    }
  }

  handleUpdateNetValue(fieldName: string): void {
    const taxField = this.getKey('tax', fieldName);
    const netField = this.getKey('price', fieldName, 'Net');
    const grossField = this.getKey('price', fieldName, 'Gross');
    const grossValue = parseFloat(this.form.get(grossField + '.value').value);
    if (
      !this.form.get(taxField).value ||
      !this.taxesEntities[this.form.get(taxField).value]
    ) {
      return;
    }
    const taxValue = parseFloat(
      this.taxesEntities[this.form.get(taxField).value].value
    );
    const netValue =
      Math.round((grossValue / (taxValue / 100 + 1)) * 10000) / 10000;
    console.log(netValue);
    this.form.get(netField + '.value').patchValue(netValue);
  }

  getControlCaption(field: string): string {
    return this.formFieldMap[field];
  }

  getKey(
    type: 'price' | 'tax',
    field: string,
    NetOrGross: 'Net' | 'Gross' | null = null
  ): string {
    return type + upperFirst(field) + (NetOrGross ? NetOrGross : '');
  }

  setDefaultTaxesIfNecessary(): void {
    if (!this.defaultTax) {
      return;
    }
    const keys = [
      'taxInitialCosts',
      'taxDataRecovery',
      'taxAnalysis',
      'taxReturnShipment',
      'taxDisposal',
      'taxDismantling'
    ];
    for (const key of keys) {
      if (
        this.form.controls[key].value === '' ||
        this.form.controls[key].value === null
      ) {
        this.form.controls[key].setValue(this.defaultTax['@id']);
      }
    }
  }

  getSellingPriceInfo(field: string): string {
    switch (field) {
      case 'initialCosts':
        return (
          'VK: ' +
          this.dataRecoveryCosts.sellingPriceInitial.value +
          ' ' +
          this.dataRecoveryCosts.sellingPriceInitial.currency
        );
      case 'dataRecovery':
        return (
          'VK: ' +
          this.dataRecoveryCosts.sellingPriceDataRecovery.value +
          ' ' +
          this.dataRecoveryCosts.sellingPriceDataRecovery.currency
        );
      default:
        return null;
    }
  }

  showPartnerProvisionWarning(field: string): boolean {
    if (!this.order.broker) {
      return false;
    }
    switch (field) {
      case 'initialCosts':
      case 'dataRecovery':
      case 'analysis':
        return true;
      default:
        return false;
    }
  }

  private loadTaxes(): void {
    this.taxes$ = this.store$.select(TaxesSelectors.selectTaxes);

    this.store$
      .select(TaxesSelectors.selectTaxesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(taxes => {
        this.taxesEntities = taxes;
      });
    this.taxes$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(taxes => !!taxes && taxes.length > 0)
      )
      .subscribe(taxes => {
        this.defaultTax = taxes.find(tax => tax.isDefault);
        this.setDefaultTaxesIfNecessary();
        this.updatePrices();
      });
    this.taxesIsLoading$ = this.store$.select(TaxesSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      TaxesSelectors.isLoaded,
      TaxesActions.ReadTaxes()
    );
  }

  private updatePrices(): void {
    this.formFields.forEach(key => this.handleUpdateGrossValue(key));
  }
}
