import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { RouterActions } from '../../../application-state/store/actions';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';
import { extractTypeByIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-action-box-goto-autocreated-partial-invoice',
  styleUrls: ['./action-box-goto-autocreated-partial-invoice.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Abschlagsrechnung buchen</div>
              <div class="sub-header col-auto">
                Bitte Abschlagsrechnung buchen.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3 btn-wider"
              mat-button
              color="green"
              (click)="gotToInvoice()"
            >
              <span>Abschlagsrechnung öffnen</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Abschlagsrechnung:</div>
          <div class="sub-header col-auto">
            Warte auf Buchung der Abschlagsrechnung
          </div>
        </div>

        <!--<pre>{{order|json}}</pre>-->
      </div>
    </div>
  `
})
export class ActionBoxGotoAutocreatedPartialInvoiceComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isAccounting() ||
          this.authService.isSales() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
      });
  }

  gotToInvoice(): void {
    const iri =
      this.order.paymentProcess?.autoCreatedInvoice ||
      this.order.paymentProcess?.autoCreatedPartialInvoice;
    const type = extractTypeByIri(iri);
    const uuid = getUuidFromIri(iri);
    this.store$.dispatch(
      RouterActions.Go({ path: ['invoices', 'payable', type, uuid] })
    );
  }
}
