import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Lead, LeadComment, LeadCommentTag } from '../../models';
import {select, Store} from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { selectLeadCommentTagsEntities } from '../../store/selectors/lead-comment-tags.selectors';
import { LeadCommentsActions, LeadCommentTagsActions } from '../../store';
import {getUuidFromIri, StringsUtility} from '../../../shared/utilities/strings.utility';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import {AdministratorFeedbackRequestsActions, AdministratorsActions} from '../../../administrators/store';
import { combineLatestArray } from 'rxjs-etc';
import {
  LeadCommentsSelectors,
  LeadCommentTagsSelectors
} from '../../store/selectors';
import { Administrator } from '../../../administrators/models';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LeadCommentFormDialogComponent } from '../lead-comment-form-dialog/lead-comment-form-dialog.component';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { ReplyCommentModalComponent } from '../../../shared/components/reply-comment-modal/reply-comment-modal.component';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';
import { AuthService } from '../../../auth/services/auth.service';
import {AbstractApiService} from "../../../shared/services";
import {CustomerComment} from "../../../customers/models";
import {Actions, ofType} from "@ngrx/effects";
import {CustomerCommentTagsSelectors} from "../../../customers/store/selectors";
import {CustomerCommentsActions} from "../../../customers/store";
import {extractUUID} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-lead-comments',
  styleUrls: ['lead-comments.component.scss'],
  template: `
    <div class="lead__comments m-b--32 m-t--32 pos-relative">
      <h2 *ngIf="(leadComments$ | async) && isEdit" class="heading">
        Kommentare:
        <span class="text-color-lightgrey">
          {{
            (leadComments$ | async) ? (leadComments$ | async)?.length : 'keine'
          }}</span
        >
      </h2>
      <p class="heading" *ngIf="!isEdit">
        Kommentare können erst nach Anlegen einer Anfrage erstellt werden
      </p>
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div
        *ngFor="let comment of leadComments$ | async | orderBy: '-createdAt'"
        class="item comment"
        [class.not-resolved]="comment.isFeedbackRequest && !comment.feedbackRequestResolved"
      >
        <div class="grid">
          <div class="column-10">
            <span class="created-by" *ngIf="comment.isFeedbackRequest"
              >Rückfrage von {{ getAdministratorName(comment.createdBy) }} an
              {{
                comment.recipient === currentUser['@id']
                  ? 'Dich'
                  : getAdministratorName(comment.recipient)
              }}
            </span>
            <span class="created-by" *ngIf="!comment.isFeedbackRequest">{{
              comment.additionalContent
                ? 'Kundenkommentar'
                : getAdministratorName(comment.createdBy)
            }}</span>
            <span class="created-at">
              {{
                comment.additionalContent
                  ? '(' + getAdministratorName(comment.createdBy) + ')'
                  : ''
              }}
              {{ comment.createdAt | date: 'medium' }}
              <span
                [hidden]="comment.createdAt === comment.updatedAt"
                [matTooltip]="
                  'Bearbeitet: ' + (comment.updatedAt | date: 'medium')
                "
                class="text-small"
                >(bearbeitet)
              </span>
            </span>
            <span class="comment__tag" *ngIf="getTagName(comment.commentTag)">{{
              getTagName(comment.commentTag)
            }}</span>
          </div>

          <div class="column-4 m-ta--2">
            <div
              class="feedback-requests-actions"
              *ngIf="comment.isFeedbackRequest"
            >
              <button
                mat-icon-button
                *ngIf="canEditAndDelete"
                [matTooltip]="comment.feedbackRequestResolved ? ('shared.tooltip.mark_as_resolved' | translate) : ('shared.tooltip.mark_as_resolved' | translate)"
                (click)="resolveFeedback(comment)"
                [class.resolved]="comment.feedbackRequestResolved"
              >
                <mat-icon>check</mat-icon>
              </button>

              <button [matMenuTriggerFor]="dropdownMenu" mat-icon-button
                      *ngIf="
                  comment.isFeedbackRequest &&
                  comment.recipient === currentUser['@id']
                "
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
            </div>

            <mat-menu #dropdownMenu xPosition="before">
              <button
                (click)="replyToComment(comment)"
                data-action="reply"
                mat-menu-item
              >
                <mat-icon>reply</mat-icon>
                <span>Antworten</span>
              </button>
              <button
                (click)="handleRequestLeadCommentForm(comment)"
                data-action="edit"
                *ngIf="canEditAndDelete"
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                <span>Kommentar bearbeiten</span>
              </button>
              <button
                (click)="handleRequestDeleteLeadComment(comment)"
                data-action="delete"
                *ngIf="canEditAndDelete"
                mat-menu-item
              >
                <mat-icon>delete</mat-icon>
                <span>Kommentar löschen</span>
              </button>
            </mat-menu>
          </div>

          <div
            class="{{ comment.additionalContent ? 'column-12' : 'column-14' }}"
          >
            <div
              class="comment__content"
              [innerHTML]="comment?.content | safeHtml"
            ></div>
          </div>

          <div *ngIf="comment.additionalContent" class="column-2 m-ta--2">
            <button
              mat-icon-button
              (click)="additionalContent.classList.toggle('is-expanded')"
            >
              <mat-icon>{{ getIcon(additionalContent) }}</mat-icon>
            </button>
          </div>

          <div class="column-14 comment__ac-wrapper" #additionalContent>
            <div
              *ngIf="comment.additionalContent"
              class="comment__additional-content"
              [innerHTML]="comment.additionalContent | safeHtml"
            ></div>
          </div>
        </div>
      </div>

      <!--<div *ngIf="!!pagination$.getValue()?.next">
        <button
          class="btn&#45;&#45;load-more"
          mat-button color="blue" (click)="handleRequestReadLeadComments()">
          <mat-icon>expand_more</mat-icon>
          <span>Ältere Kommentare laden</span>
        </button>
      </div>-->
    </div>
  `
})
export class LeadCommentsComponent implements OnInit {
  currentUser: any;

  administratorsEntities: { [iri: string]: Administrator } = {};
  @Input() lead$: BehaviorSubject<Lead>;
  leadCommentTagsEntities: { [iri: string]: LeadCommentTag } = {};
  leadComments$: Observable<Array<LeadComment>>;
  isEdit = false;
  isLoading$: Observable<boolean>;
  @Output() requestLeadCommentForm: EventEmitter<
    LeadComment
  > = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private dialog: MatDialog,
    private authService: AuthService,
    private apiService: AbstractApiService
  ) {}

  get canEditAndDelete(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  getAdministratorName(iri: string): string {
    const admin = this.administratorsEntities[iri];
    if (!admin) {
      return 'NOTFOUND';
    }
    return `${admin.firstName} ${admin.lastName} `;
  }

  getTagName(iri: string): string {
    return this.leadCommentTagsEntities[iri]?.commentTag;
  }

  getIcon(additionalContent: HTMLElement): string {
    return additionalContent.classList.contains('is-expanded')
      ? 'keyboard_arrow_up'
      : 'keyboard_arrow_down';
  }

  ngOnInit(): void {
    this.isLoading$ = combineLatestArray(
      [
        this.store$.select(AdministratorsSelectors.isLoading),
        this.store$.select(LeadCommentsSelectors.isLoading)
      ],
      results => {
        return results.indexOf(true) > -1;
      }
    );
    this.bindAndLoadAdministrators();
    this.bindAndLoadLeadCommentTags();
    this.lead$.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.updateLead(value);
    });
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  bindAndLoadAdministrators(): void {
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(administratorsEntities => {
        this.administratorsEntities = administratorsEntities;
      });
  }

  bindAndLoadLeadCommentTags(): void {
    loadIfNotLoaded(
      this.store$,
      LeadCommentTagsSelectors.isLoaded,
      LeadCommentTagsActions.LoadLeadCommentTags()
    );
    this.store$
      .select(selectLeadCommentTagsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(leadCommentTagsEntities => {
        this.leadCommentTagsEntities = leadCommentTagsEntities;
      });
  }

  updateLead(lead): void {
    if (!lead) {
      return;
    }
    this.isEdit = true;
    const leadIri = lead['@id'];
    const leadUuid = StringsUtility.getUuidFromIri(leadIri);

    this.store$.dispatch(
      LeadCommentsActions.ReadLeadComments({
        page: -1,
        params: { 'lead.uuid': leadUuid, 'order[createdAt]': 'desc' }
      })
    );
    this.leadComments$ = this.store$
      .select(LeadCommentsSelectors.selectLeadComments, { iri: lead['@id'] })
      .pipe(map(e => e.sort(sortByCreatedAtDate)));
  }

  handleRequestDeleteLeadComment(comment: LeadComment): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Kommentar löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          LeadCommentsActions.DeleteLeadComment({ iri: comment['@id'] })
        );
      });
  }

  handleRequestLeadCommentForm(comment: LeadComment): void {
    this.dialog.open(LeadCommentFormDialogComponent, {
      width: '600px',
      data: { leadComment$: of(comment), lead$: this.lead$ }
    });
  }

  replyToComment(comment: LeadComment): void {

    this.apiService.getObject(comment['@id'], true).subscribe((response) => {

      this.dialog.open(ReplyCommentModalComponent, {
        disableClose: true,
        panelClass: 'pos-relative',
        data: {
          response: response,
          module: 'lead',
          variation: 'answer'
        }
      });
    });



    //Update comments after close of dialog
    this.dialog.afterAllClosed.subscribe(
      () => {
        this.actions$
          .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.store$.dispatch(LeadCommentsActions.ReadLeadComments({
              page: -1,
              params: { 'lead.uuid': getUuidFromIri(this.lead$.getValue()['@id']) }
            }));
          })
      }
    )

    // this.store$
    //   .select(FeedbackRequestsSelectors.selectFeedbackRequestByCommentIri, {
    //     commentIri: comment['@id']
    //   })
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     filter(element => !!element)
    //   )
    //   .subscribe(feedbackRequest => {
    //   });
  }
  resolveFeedback(comment: LeadComment): void {
    this.store$.dispatch(
      AdministratorFeedbackRequestsActions.PatchFeedbackRequests({
        iri: comment['@id'],
        module: 'order',
        variation: "resolve",
        payload: { feedbackRequestResolved: !comment.feedbackRequestResolved }
      })
    )
    this.actions$
      .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.store$.dispatch(LeadCommentsActions.ReadLeadComments({
          page: -1,
          params: { 'lead.uuid': getUuidFromIri(this.lead$.getValue()['@id']) }
        }));
        // this.leadComments$ = this.store$
        //   .select(LeadCommentsSelectors.selectLeadComments, { iri: this.lead$.getValue()['@id'] })
        //   .pipe(map(e => e.sort(sortByCreatedAtDate)));
      })
  }
}
