import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { ApplicationState } from '../../application-state/store';
import { AuthService } from '../services/auth.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private store: Store<ApplicationState>,
    private as: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkIsAuthenticated();
  }

  checkIsAuthenticated(): Observable<boolean> {
    return this.as.isLoggedIn$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.router.navigate(['login']);
        }
      })
    );
  }
}
