import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { CancellationInvoice, InvoiceMail, Offer } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CancellationInvoicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCancellationInvoice(payload: Offer): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/cancellation_invoices`, payload)
    );
  }

  readCancellationInvoice(iri: string): Observable<CancellationInvoice> {
    return this.rolesService.userHasRoleFilter<CancellationInvoice>(
      'ROLE_MWS_CANCELLATION_INVOICE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readCancellationInvoices(
    page = 1,
    params?: ParameterBag
  ): Observable<Array<any>> {
    return this.rolesService.userHasRoleFilter<Array<CancellationInvoice>>(
      'ROLE_MWS_CANCELLATION_INVOICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/cancellation_invoices', page, params)
            )
    );
  }

  sendMail(
    iri: string,
    payload: InvoiceMail
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  readCancellationInvoiceAsPdf(
    credit: string
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              credit + '/preview?disposition=attachment',
              true
            )
    );
  }

  readCancellationInvoiceChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updateCancellationInvoice(
    iri: string,
    payload: Offer
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCancellationInvoice(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CANCELLATION_INVOICE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
