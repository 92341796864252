<div class="calendar__outer">

  <div class="calendar__wrapper">
    <div *ngIf="(openFollowUps$|async).length>0" class="overlay">
      <div *ngIf="(openFollowUps$|async).length>0" class="open-follow-ups__wrapper">
        <h2>Offene Wiedervorlage:</h2>
        <small class="text-color-grey m-t--8">Sei so gut, und kümmere dich um die alten Wiedervorlagen, bevor du
          heute
          durchstartest!</small>
        <app-follow-up-card (selectFollowUp)="selectFollowUp($event)" *ngFor="let followUp of openFollowUps$|async"
                            [followUp]="followUp"
                            showDate
        ></app-follow-up-card>

      </div>
    </div>
    <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
    <div class="header d-flex justify-content-between align-content-center">
      <div class="heading">
        {{ (selectedWeek$|async).format('MMMM') }} {{ (selectedWeek$|async).year() }}
      </div>
      <ng-container *ngIf="revertFollowUp$|async as followUp">
        <div [class.show]="followUp && (remainingPercentage$|async)<100"
             class="wvl-alert d-inline-block px-4 py-1 rounded border-1 my-1 mx-5 d-flex align-items-center">
          <mat-icon class="me-1">delete</mat-icon>
          <strong class="me-5">
            Wiedervorlage "{{followUp.title}}" wurde gelöscht.</strong>
          <button (click)="undelete(followUp)" class="me-2" mat-flat-button>

            <mat-spinner [diameter]="20" [mode]="'determinate'" [strokeWidth]="5" [value]="remainingPercentage$|async"
                         class="me-3" style="display: inline-block; background: lightgrey; border-radius: 20px;">
            </mat-spinner>
            Löschen rückgängig machen.
          </button>
        </div>
      </ng-container>
      <div class="text-right buttons">
        <button (click)="changeWeek(-1)" [disabled]="(selectedWeek$|async).isSameOrBefore() "
                mat-button><
        </button>
        <span> KW {{(selectedWeek$|async).format('WW') }} </span>
        <button (click)="changeWeek(1)" mat-button>></button>
      </div>
    </div>
    <div cdkDropListGroup class="calendar grid">


      <div *ngFor="let day of (daysOfWeek$ | async)"
           [ngClass]="{'currentDate': day.today}"
           class="col-wrapper column-2 "
      >

        <div class="col-label">
          <span>{{ day.header }}</span>
        </div>
        <div (cdkDropListDropped)="drop($event)" [attr.data-date]="day.startDate.toDate().toISOString()"
             [cdkDropListData]="day.dataPoints|async"
             cdkDropList
             class="col-content"
        >
          <div *ngFor="let followUp of day.dataPoints|async"
               [attr.data-date]="getIsoDate(followUp.date)"
               [attr.data-iri]="followUp['@id']"
               cdkDrag
               class="card-wrapper">
            <app-follow-up-card (selectFollowUp)="selectFollowUp($event)"
                                [followUp]="followUp">
            </app-follow-up-card>

            <div cdkDragHandle class="drag-handle">
              <mat-icon>drag_indicator</mat-icon>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>
