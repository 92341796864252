import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { InvoiceSelectRecipientModalComponent } from '../invoice-select-recipient-modal/invoice-select-recipient-modal.component';
import { InvoiceLike } from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { Department } from '../../../master-data/models';
import { InvoiceRecipient } from '../../models/invoice-recipient.interface';
import { Address, Customer } from '../../../customers/models';
import {
  CustomerAddressesSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import { Order } from '../../../orders/models';
import {
  extractIri,
  extractTypeByIri
} from '../../../shared/utilities/objects.utility';
import {
  CustomerAddressesActions,
  CustomersActions
} from '../../../customers/store';

@Component({
  selector: 'app-invoice-head-recipient',
  styleUrls: ['invoice-head-recipient.component.scss'],
  template: `
    <div class="grid-no-gutter">
      <div class="block-sender column-14" *ngIf="issuer">
        <p>
          {{ issuer.companyName ? issuer.companyName + ',' : '' }}
          {{ getAddressBlock() }}
        </p>
      </div>

      <div class="block-recipient column-14 grid grid-no-gutter">
        <p *ngIf="!!invoice?.recipient" class="column-14 m-b--0">
          {{ invoice.recipient.nameLine1 }}
          <br *ngIf="invoice.recipient.nameLine2" />
          {{ invoice.recipient.nameLine2 }}
        </p>
        <p *ngIf="!invoice?.recipient && !!customer" class="column-14 m-b--0">
          {{ customer.nameLine1 }} {{ customer.nameLine2 }}
        </p>

        <div class="column-10">
          <p *ngIf="!customer" class="notice">Kunden festlegen</p>
          <p
            *ngIf="
              !invoice ||
              !invoice.recipient ||
              !invoice.recipient.address ||
              !invoice.recipient.address.line1
            "
            class="notice"
          >
            Rechnungsadresse festlegen
          </p>

          <address *ngIf="invoice?.recipient?.address">
            {{ invoice.recipient.address.line1 }}
            {{ invoice.recipient.address.line2 }}<br />
            <ng-container *ngIf="invoice?.recipient?.address?.line3">
              {{ invoice.recipient.address.line3 }}<br />
            </ng-container>

            {{ invoice.recipient.address.zipPostcode }}
            {{ invoice.recipient.address.city }}<br />
            {{ invoice.recipient.address.country }}
          </address>
        </div>

        <div class="column-2">
          <button
            *ngIf="isEditButtonActive(invoice)"
            mat-icon-button
            (click)="handleRequestShowCustomerAddressSelectionModal()"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  `
})
export class InvoiceHeadRecipientComponent implements OnInit, OnDestroy {
  customer: Customer;
  issuer: Department;
  @Input() @Optional() readonly = false;
  @Input() invoice$: Observable<InvoiceLike>;
  invoice: InvoiceLike;
  @Input() customerReadOnly = false;
  @Output()
  updateCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output()
  updateOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output()
  updateRecipient: EventEmitter<InvoiceRecipient> = new EventEmitter<
    InvoiceRecipient
  >();
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.invoice$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(invoice => !!invoice)
      )
      .subscribe(invoice => {
        this.invoice = invoice;
        if (invoice.issuer) {
          this.store$
            .select(DepartmentsSelectors.sByIri, {
              iri: extractIri(invoice.issuer)
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i)
            )
            .subscribe(issuer => {
              this.issuer = issuer;
            });
        }
        if (invoice.customerNumber && !this.customer) {
          // console.log('invoice.customerNumber UPDATE', invoice.customerNumber);
          this.store$
            .select(CustomersSelectors.sByCustomerNumber, {
              customerNumber: invoice.customerNumber
            })
            .pipe(
              takeUntil(this.onDestroy$),
              tap(e => {
                if (!e) {
                  this.store$.dispatch(
                    CustomersActions.ReadCustomers({
                      page: -1,
                      params: { id: invoice.customerNumber }
                    })
                  );
                }
              }),
              filter(i => !!i),
              take(1)
            )
            .subscribe(c => {
              this.customer = c;
              if (invoice.recipient?.addressType === null) {
                this.tryToFindAnInvoiceAddressForCustomer();
              }
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  tryToFindAnInvoiceAddressForCustomer(): void {
    // console.log('read extractIri(this.customer)', extractIri(this.customer));
    this.store$
      .select(
        CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
        { customerIri: extractIri(this.customer) }
      )
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e && e.length > 0),
        take(1)
      )
      .subscribe(addresses => {
        console.log(addresses);
        if (addresses.length === 1) {
          this.updateRecipient.emit({
            ...addresses[0],
            nameLine1: this.customer.nameLine1,
            nameLine2: this.customer.nameLine2,
            taxNumber: this.customer.taxNumber
          });
          return;
        }
        if (this.customer?.defaultBillingAddress) {
          const foundAddress = addresses.find(
            e => e['@id'] === this.customer?.defaultBillingAddress
          );
          if (foundAddress) {
            this.updateRecipient.emit({
              ...foundAddress,
              nameLine1: this.customer.nameLine1,
              nameLine2: this.customer.nameLine2,
              taxNumber: this.customer.taxNumber
            });
          }
        }
      });
    this.store$.dispatch(
      CustomerAddressesActions.ReadCustomerBillingAddresses({
        customerIri: extractIri(this.customer)
      })
    );
  }

  handleRequestShowCustomerAddressSelectionModal(): void {
    const dialog = this.dialog.open(InvoiceSelectRecipientModalComponent, {
      data: {
        invoice$: this.invoice$,
        customerReadOnly: this.customerReadOnly
      }
    });
    // this.requestShowCustomerAddressSelectionModal.emit();
    dialog.afterClosed().subscribe((data: any) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data.order) {
        this.updateOrder.emit(data.order);
      }
      if (data.customerAddress) {
        this.updateRecipient.emit(data.customerAddress);
      }
      if (data.customer) {
        this.updateCustomer.emit(data.customer);
      }
    });
  }

  getAddressBlock(): string {
    const address: Address = this.issuer.address;
    return `${address.line1} ${address.line2 ? address.line2 : ''}, ${
      address.zipPostcode
    } ${address.city}`;
  }

  isEditButtonActive(invoice: InvoiceLike): boolean {
    if (!invoice || extractIri(invoice, true) === null) {
      return true;
    }
    if (this.readonly) {
      return false;
    }
    if (
      extractTypeByIri(this.invoice, true) === 'invoices' ||
      extractTypeByIri(this.invoice, true) === 'partial_invoices'
    ) {
      return (
        this.invoice && 'editable' in this.invoice && this.invoice?.editable
      );
    }

    if (extractTypeByIri(this.invoice, true) === 'offers') {
      // @ts-ignore
      return !!!invoice.acceptedOffer;
    }
    if (extractTypeByIri(this.invoice, true) === 'correction_invoices') {
      return (
        this.invoice && 'editable' in this.invoice && this.invoice?.editable
      );
    }
  }
}
