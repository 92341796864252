<div *ngIf="!data?.length; else table" style="padding: 24px;">
  Keine Datensätze vorhanden
</div>

<ng-template #table>
  <table
    class="bmo-table bmo-table-hover bmo-table-heading-uppercase bmo-table-rounded bmo-table-rounded"
    [class.has-paginator]="hasPagination"
    mat-table
    [dataSource]="data | orderBy: getOrderByProperty()"
  >
    <!-- Columns -->
    <ng-container *ngFor="let column of tableColumns">
      <ng-container [matColumnDef]="column.propertyName">
        <ng-container *ngIf="isStatusProperty(column)">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="circle" [class.green]="!!element.isActive"></div>
          </td>
        </ng-container>

        <ng-container *ngIf="isSpecialProperty(column)">
          <ng-container *ngIf="column.propertyName === 'price'">
            <th mat-header-cell *matHeaderCellDef>
              {{ column.columnLabel }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element[column.propertyName]['value'] | number: '1.2-2' }}
              {{ element[column.propertyName]['currency'] }}
            </td>
          </ng-container>

          <ng-container *ngIf="isAddressProperty(column)">
            <th mat-header-cell *matHeaderCellDef>
              {{ column.columnLabel }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ formatAddress(element) }}
            </td>
          </ng-container>

          <ng-container *ngIf="isBooleanProperty(column)">
            <th mat-header-cell *matHeaderCellDef>
              {{ column.columnLabel }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element['@type'] === 'Department'">
                    <span>{{
                        element[column.propertyName] === true ? 'Ja' : 'Nein'
                      }}</span>
              </ng-container>
              <ng-container *ngIf="element['@type'] !== 'Department'">
                <mat-icon
                  [innerHTML]="
                        element[column.propertyName] === true
                          ? 'check_circle_outline'
                          : 'remove_circle_outline'
                      "
                ></mat-icon>
              </ng-container>
            </td>
          </ng-container>

          <ng-container
            *ngIf="isRelatedEntityProperty(column.propertyName)"
          >
            <th mat-header-cell *matHeaderCellDef>
              {{ column.columnLabel }}
            </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="
                getEntityProperty(
                  column.propertyName,
                  element[column.propertyName],
                  'name'
                )
              ">

            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isSpecialProperty(column)">
          <th mat-header-cell *matHeaderCellDef>
            {{ column.columnLabel }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                    element['@type'] === 'Tax' &&
                      column.propertyName === 'value';
                    else elsy
                  "
            >
              {{ element[column.propertyName] | number: '1.2-2' }}
            </ng-container>

            <ng-template #elsy>
              <div [innerHTML]="element[column.propertyName]"></div>
              <span *ngIf="column.propertyName === 'storageSystemSize'">
                    GB</span
              >
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="handleClick('update', element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="handleClick('delete', element)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getTableProperties()"></tr>
    <tr mat-row *matRowDef="let row; columns: getTableProperties()"></tr>
  </table>
</ng-template>

<paginator
  *ngIf="hasPagination"
  [pageSize]="pageSize"
  [totalItems]="totalItems"
  [pageSizeOptions]="[5, 15, 30, 50]"
  (requestChangePaginationPage)="handleRequestReadItemPage($event)"
  (requestChangePaginationChunkSize)="handleRequestReadItemChunk($event)"
></paginator>
