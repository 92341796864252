import {Component, OnInit} from '@angular/core';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {DataMedium, StockItem} from '../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {StockItemsService} from '../../services';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {StorageSystemManufacturersSelectors} from '../../../master-data/store/selectors';
import {StorageSystemManufacturersActions} from '../../../master-data/store';
import {CustomerReplacementStockItemsActions} from '../../store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-warehouse-archive-view',
  styleUrls: ['./warehouse-archive-view.component.scss'],
  template: `
    <div class="row  py-2" [formGroup]="form">
      <div class="col-2 ">
        <app-storage-location-select formControlName="storageLocation"></app-storage-location-select>
      </div>
      <div class="col-2">
        <app-order-select formControlName="order"></app-order-select>
      </div>
      <div class="col pt-4">
        <a (click)="resetFilter()" class="link-primary cursor-pointer text-color-grey text-decoration-none">Filter
          zurücksetzen</a>
      </div>
    </div>
    <div class="pos-relative" style="min-height: 300px;">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <ng-container *ngIf="(items$|async) as items">
        <div class="row" *ngFor="let item of items">
          <app-stock-item-display-line [stockItem]="item" [selectMode]="'none'"
                                       [filterDataMediaFn]="filterArchived"></app-stock-item-display-line>
        </div>
        <div class="row" *ngIf="(isLoading$|async)===false && (!items || items.length<=0)">
          <p>Es sind keine Datenträger mit den ausgewählten Filtern verfügbar!</p>
        </div>
      </ng-container>
      <ng-template #nothing>
      </ng-template>
    </div>
    <div class="row">
      <div class="col text-right">
        <app-paginator-unstyled showFirstLastButtons="true"
                                [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
                                [pageSize]="currentPageSetting.pageSize"
                                [pageIndex]="currentPageSetting.pageIndex"
                                [totalItems]="(itemCount$|async)"
                                (handleUpdatePageOrSize)="updatePageOrSize($event)"
        ></app-paginator-unstyled>

      </div>
    </div>
  `
})
export class WarehouseArchiveViewComponent extends BaseOnDestroyComponent implements OnInit {
  form: FormGroup;
  storedFilters?: any;
  currentPageSetting: { pageSize: number, pageIndex: number } = {pageIndex: 0, pageSize: 15};
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items$: BehaviorSubject<Array<StockItem>> = new BehaviorSubject<Array<StockItem>>([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: StockItemsService,
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private actions$: Actions,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  filterArchived = (dm: DataMedium): boolean => {
    return dm && dm.archived;
  }

  ngOnInit(): void {
    this.initForm();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(params => {
        this.readQueryParams();
        this.loadData();
        //
      });
    loadIfNotLoaded(this.store$, StorageSystemManufacturersSelectors.isLoaded, StorageSystemManufacturersActions.ReadStorageSystemManufacturers());
    this.actions$
      .pipe(ofType(CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess, CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemSuccess),
        takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadData();
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null),
      storageLocation: this.fb.control(null),
      dataMediaState: this.fb.control(null),
      storageSystemType: this.fb.control(null),
    });
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(values => {
      this.updateQueryParams({...values, page: 0}, true);
      this.localStorageService.overwriteObjectByKey('filters', 'warehouse_archive', values);
    });

    this.storedFilters = this.localStorageService.getObjectByKey('filters', 'warehouse_archive');
    if(this.storedFilters) {
      this.updateQueryParams({...this.storedFilters, page: 0}, true);
    }
  }

  resetFilter(): void {
    this.form.patchValue({
      order: null,
      storageLocation: null,
      dataMediaState: null,
      storageSystemType: null,
    });
  }

  updatePageOrSize($event: any): void {
    this.currentPageSetting = {...this.currentPageSetting, ...$event};
    this.updateQueryParams({pageSize: $event.pageSize, page: $event.pageIndex});
  }

  readQueryParams(): void {

    const params = this.activatedRoute.snapshot.queryParams;
    for (const key of ['order', 'storageLocation', 'storageSystemType', 'dataMediaState']) {
      if (this.form.get(key).value !== params[key]) {
        this.form.get(key).setValue(params[key], {onlySelf: true, emitEvent: false});
      }
    }
    if (params.pageSize) {
      this.currentPageSetting.pageSize = params.pageSize;
    }
    if (params.page) {
      this.currentPageSetting.pageIndex = params.page;
    }


  }

  updateQueryParams(values: { [key: string]: string }, alwaysLoad = false): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = {...currentQuery};
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {

      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }

  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const page = parseInt(queryParams.page ? queryParams.page : 0, 10);
    const params: any = {
      itemsPerPage: queryParams.pageSize ? queryParams.pageSize : 15,
      pagination: true,
      'dataMediums.archived': true,
      'order[createdAt]': 'desc'
    };

    if (queryParams.order) {
      params['stockItem.order.uuid'] = getUuidFromIri(queryParams.order);
    }
    if (queryParams.storageLocation) {
      params['stockItem.storageLocation.storageLocationNumber'] = getUuidFromIri(queryParams.storageLocation);
    }
    if (queryParams.storageSystemType) {
      params['stockItem.storageSystemType'] = getUuidFromIri(queryParams.storageSystemType);
    }
    this.backendService.getStockItems(page, params)
      .subscribe((data) => {
        this.items$.next(data['hydra:member']);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }
        this.isLoading$.next(false);
      }, (error) => {
        console.log(error);
        this.isLoading$.next(false);

      });

  }
}
