import { createReducer, on } from '@ngrx/store';

import { RolesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: Array<string> };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(RolesActions.ReadRoles, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(RolesActions.ReadRolesSuccess, (state, { response }) => {
    // note: current structure API-wise:
    // [
    //  {
    //    "ROLE_MWS_USER_USER_EDITOR": [
    //      "ROLE_MWS_USER_ADMINISTRATOR_CREATE",
    //      "ROLE_MWS_USER_ADMINISTRATOR_EDIT"
    //    ],
    //    [...]
    //  }
    // ]

    const entities = response['hydra:member'][0];

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(RolesActions.ReadRolesFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
