import {createSelector} from '@ngrx/store';

import {CUSTOMER_COMMENT_TAGS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerCommentTagsState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_COMMENT_TAGS_FEATURE_KEY]
);

export const selectCustomerCommentTagEntities = createSelector(
  selectCustomerCommentTagsState,
  (state) => state.entities
);

export const selectCustomerCommentTags = createSelector(
  selectCustomerCommentTagEntities,
  (tags) => Object.keys(tags).map(id => tags[id])
);

export const isLoading = createSelector(
  selectCustomerCommentTagsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectCustomerCommentTagsState,
  (state) => state.loaded
);
