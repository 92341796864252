import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {OrderCommentTagsActions} from '../actions';
import {OrderCommentTagsService} from '../../services/order-comment-tags.service';

@Injectable()
export class OrderCommentTagsEffects {

  constructor(private actions$: Actions, private service: OrderCommentTagsService) {
  }


  readOrderCommentTag$ = createEffect(() => this.actions$.pipe(
    ofType(OrderCommentTagsActions.ReadOrderCommentTag),
    switchMap(({iri}) => {

      return this.service
        .readOrderCommentTag(iri)
        .pipe(
          map((response: any) => OrderCommentTagsActions.ReadOrderCommentTagSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentTagsActions.ReadOrderCommentTagFail({response})))
        );
    })
  ));


  readOrderCommentTagTags$ = createEffect(() => this.actions$.pipe(
    ofType(OrderCommentTagsActions.ReadOrderCommentTags),
    switchMap(() => {

      return this.service
        .readOrderCommentTags()
        .pipe(
          map((response: any) => OrderCommentTagsActions.ReadOrderCommentTagsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentTagsActions.ReadOrderCommentTagsFail({response})))
        );
    })
  ));
}
