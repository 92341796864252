import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StringsUtility } from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-item-list',
  styleUrls: ['item-list.component.scss'],
  templateUrl: 'item-list.component.html'
})
export class ItemListComponent {
  @Input() data: Array<any>;
  @Input() mappingData: { [entityName: string]: { [iri: string]: any } };
  @Input() tableColumns: Array<{
    propertyName: string;
    columnLabel: string;
  }> = [];
  @Input() pageSize: number = 30;
  @Input() totalItems: number;
  @Input() hasPagination = false;

  @Output() requestUpdateItemForm: EventEmitter<any> = new EventEmitter();
  @Output() requestDeleteItem: EventEmitter<{
    iri: string;
    entity: string;
  }> = new EventEmitter();
  @Output() requestReadItemPage: EventEmitter<{
    entity: string;
    direction: string;
  }> = new EventEmitter();
  @Output() requestReadItemChunk: EventEmitter<{
    entity: string;
    itemsPerPage: number;
  }> = new EventEmitter();

  getOrderByProperty(): string {
    const type = this.data[0]['@type'];

    if (type == 'Department') {
      return 'companyName';
    }

    if (type == 'StorageLocation') {
      return 'storageLocationNumber';
    }
    if (type === 'StorageSystem' || type === 'StorageSystemManufacturer') {
      return 'order';
    }

    return 'name';
  }

  getEntityProperty(entity: string, iri: string, propertyName: string): string {
    return this.mappingData?.[entity]?.[iri]?.[propertyName];
  }

  getTableProperties(): Array<string> {
    return [...this.tableColumns.map(item => item.propertyName), 'actions'];
  }

  handleClick(action: string, item: any): void {
    if (action === 'update') {
      this.requestUpdateItemForm.emit(item);
    }

    if (action === 'delete') {
      this.requestDeleteItem.emit({ iri: item['@id'], entity: item['@type'] });
    }
  }

  handleRequestReadItemPage(direction: string): void {
    this.requestReadItemPage.emit({ entity: this.data[0]['@type'], direction });
  }

  handleRequestReadItemChunk(itemsPerPage: number): void {
    console.log(this.data[0]);
    this.requestReadItemChunk.emit({
      entity: this.data[0]['@type'],
      itemsPerPage
    });
  }

  formatAddress(element: any): string {
    return StringsUtility.formatAddress(element.address);
  }

  isAddressProperty(column: any): boolean {
    return column.propertyName === 'address';
  }

  isStatusProperty(column: any): boolean {
    return column.propertyName === 'isActive';
  }

  isBooleanProperty(column: any): boolean {
    return column.propertyName === 'isDefault';
  }

  isRelatedEntityProperty(propertyName: string): boolean {
    // note: this should be storageSystem; API is not consistent
    return (
      ['storageSystemType', 'storageSystemManufacturer', 'category'].indexOf(
        propertyName
      ) !== -1
    );
  }

  isSpecialProperty(column: any): boolean {
    return (
      column.propertyName === 'price' ||
      this.isBooleanProperty(column) ||
      this.isRelatedEntityProperty(column.propertyName) ||
      this.isAddressProperty(column)
    );
  }
}
