import {createSelector} from '@ngrx/store';

import {SHIPPING_PROVIDERS_TO_CUSTOMER_FEATURE_KEY, selectShippingModuleState} from '../reducers';

export const selectShippingProvidersToCustomerState = createSelector(
  selectShippingModuleState,
  (moduleState) => moduleState[SHIPPING_PROVIDERS_TO_CUSTOMER_FEATURE_KEY]
);

export const selectShippingProvidersToCustomerEntities = createSelector(
  selectShippingProvidersToCustomerState,
  (state) => state.entities
);

export const selectShippingProvidersToCustomer = createSelector(
  selectShippingProvidersToCustomerEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectShippingProvidersToCustomerState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectShippingProvidersToCustomerState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectShippingProvidersToCustomerState,
  (state) => state.loaded
);
