import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {LeadContactTypesActions} from '../actions';
import * as fromModuleServices from '../../services';

@Injectable()
export class LeadContactTypesEffects {

  constructor(private actions$: Actions, private los: fromModuleServices.LeadContactTypesService) {
  }


  loadLeadContactTypes$ = createEffect(() => this.actions$.pipe(
    ofType(LeadContactTypesActions.LoadLeadContactTypes),
    map(action => action),
    switchMap(() => {

      return this.los
        .getLeadContactTypes()
        .pipe(
          map((response: any) => LeadContactTypesActions.LoadLeadContactTypesSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadContactTypesActions.LoadLeadContactTypesFail({response})))
        );
    })
  ));


  loadLeadContactType$ = createEffect(() => this.actions$.pipe(
    ofType(LeadContactTypesActions.LoadLeadContactType),
    map(action => action),
    switchMap(({iri}) => {

      return this.los
        .getLeadContactType(iri)
        .pipe(
          map((response: any) => LeadContactTypesActions.LoadLeadContactTypeSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadContactTypesActions.LoadLeadContactTypeFail({response})))
        );
    })
  ));
}
