import { LeadCommentTagsEffects } from './lead-comment-tags.effects';
import { LeadCommentsEffects } from './lead-comments.effects';
import { LeadContactTypesEffects } from './lead-contact-types.effects';
import { LeadOriginTypesEffects } from './lead-origin-types.effects';
import { LeadsEffects } from './leads.effects';

export const effects: Array<any> = [
  LeadCommentTagsEffects,
  LeadCommentsEffects,
  LeadContactTypesEffects,
  LeadOriginTypesEffects,
  LeadsEffects
];

export * from './lead-comment-tags.effects';
export * from './lead-comments.effects';
export * from './lead-contact-types.effects';
export * from './lead-origin-types.effects';
export * from './leads.effects';
