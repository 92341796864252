import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromOrdersModuleModels from '../../models';
import {ApplicationState} from '../../../application-state/store';
import {RouterActions} from '../../../application-state/store/actions';
import {getUuidFromIri, StringsUtility} from '../../../shared/utilities/strings.utility';
import {Order} from '../../models';
import {PageEvent} from '@angular/material/paginator';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {LocalStorageService} from "../../../shared/services";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {extractIri} from "../../../shared/utilities/objects.utility";
import {OrdersService} from "../../services";

@Component({
  selector: 'app-orders-view',
  styleUrls: ['./orders-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!-- Heading -->
    <div class="row">
      <div class="col-auto me-auto">
        <h1>
          Aufträge

          <a
            mat-flat-button
            class="ms-3"
            [routerLink]="['/', 'leads', 'new']"
          ><span class="material-icons me-2">add</span>
            {{"leads.leads_view.search_leads.new_lead_button" | translate}}
          </a>
        </h1>
      </div>
      <div class="col-auto">
        <app-search-input
          [showToggle]="true"
          [filtersPage]="'orders'"
          (searchTermChange)="handleSearchInput($event)"
          (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
        ></app-search-input>
      </div>
      <app-orders-filter-form
        [searchBoxVisible$]="searchBoxVisible$"
        (updateFilter)="handleFilterFormAction($event)"
      ></app-orders-filter-form>

    </div>
    <!-- Content -->
    <div class="row">
      <div class="col">
        <div class="table-paginator-group pos-relative">
          <app-loading-overlay *ngIf="loadingTable$|async"></app-loading-overlay>
          <app-orders-list
            *ngIf="(orders$ | async) as orders"
            [orders]="orders"
            (requestViewOrder)="handleViewOrder($event)"
          ></app-orders-list>

          <app-paginator-unstyled
            [totalItems]="orderCount"
            [pageSize]="pageSize"
            [pageIndex]="currentPage - 1"
            [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
            (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
            [showFirstLastButtons]="true"
          ></app-paginator-unstyled>
        </div>
      </div>
    </div>
  `
})
export class OrdersViewComponent implements OnInit, OnDestroy {
  currentPage = 1;

  pageSize = 30;
  pageSizeOptions = [5, 10, 15, 20, 25];

  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [p: string]: any }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc',
  });
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onDestroy$: Subject<any> = new Subject<any>();
  activeFilter: { [p: string]: string } = {};
  isLoading$: Observable<boolean>;
  orders$: BehaviorSubject<Array<Order>> = new BehaviorSubject<Array<Order>>([]);
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orderCount: number;
  subscriptionList: any;

  constructor(private store$: Store<ApplicationState>,
              private title: AbstractTitleService,
              private localStorageService: LocalStorageService,
              private router: Router,
              private route: ActivatedRoute,
              private orderService: OrdersService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    const filters = this.localStorageService.getObjectByKey('filters', 'orders');
    if(filters) {
      this.searchBoxVisible$.next(!!filters);
      this.handleFilterFormAction(filters);
    }

    this.title.setTitle('Aufträge');
    // this.store$.dispatch(OrdersModuleActions.OrdersActions.ReadOrders({page: -1}));

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.updateParams();
    });
  console.log(this.currentPage);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleFilterFormAction(filters: any): void {
    console.log(filters);
    const params = {
      'broker.uuid': null,
      'salesperson.uuid': null,
      'customer.partnerStatus': null,
      'customer.customerCooperations.id': null,
      stateRange: null,
      state: null,
      'exists[broker]': null,
      analysisPriorityMode: null,
      dataRecoveryLocation: null,
      analysisLocation: null,
      'priceDataRecoveryNet.value[gte]': null,
      'priceDataRecoveryNet.value[lte]': null,
      'createdAt[strictly_after]': null,
      'createdAt[strictly_before]': null,
    };

    if (filters?.salesperson) {
      params['salesperson.uuid'] = getUuidFromIri(extractIri(filters.salesperson));
    }

    if (filters?.state) {
      if(
        filters?.state === 'data_recovery_unsuccessful_dr_de'
        || filters.state === 'data_recovery_unsuccessful_dr_remote'
        || filters.state === 'data_recovery_unsuccessful_dr_ext'
      ) {
        params.state = [filters.state];
        params.state.push('provide_disposal_type')
      } else {
        params.state = filters.state;
      }
    }

    if (filters?.stateRange) {
      params.stateRange = getUuidFromIri(extractIri(filters.stateRange));
    }

    if (filters?.dataRecoveryPriorityMode) {
      params.analysisPriorityMode = getUuidFromIri(extractIri(filters.dataRecoveryPriorityMode));
    }
    if (filters?.analysisLocation) {
      params.analysisLocation = getUuidFromIri(extractIri(filters.analysisLocation));
    }
    if (filters?.dataRecoveryLocation) {
      params.dataRecoveryLocation = getUuidFromIri(extractIri(filters.dataRecoveryLocation));
    }
    if (filters?.partnerStatus) {
      params['customer.partnerStatus'] = getUuidFromIri(extractIri(filters.partnerStatus));
    }
    if (filters?.customerCooperation) {
      params['customer.customerCooperations.id'] = getUuidFromIri(extractIri(filters.customerCooperation));
    }

    if (filters?.broker) {
      params['broker.uuid'] = getUuidFromIri(extractIri(filters.broker));
    }

    if (filters?.priceMin) {
      params['priceDataRecoveryNet.value[gte]'] = filters.priceMin;
    }

    if (filters?.priceMax) {
      params['priceDataRecoveryNet.value[lte]'] = filters.priceMax;
    }

    if (filters?.createdAtMin) {
      params['createdAt[strictly_after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[strictly_before]'] = filters.createdAtMax;
    }

    if (filters?.brokerExists === true || filters?.brokerExists === false) {
      params['exists[broker]'] = filters.brokerExists;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });

    this.loadOrders();
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.updateUrl();
    this.updateParams();
    this.loadOrders();
  }

  handleSearchInput(term: string): void {

    this.params$.next({
      ...this.params$.getValue(),
      fulltext_search: term ? term : ''
    });
    this.updateParams();
    this.loadOrders();

  }

  handleViewOrder(order: fromOrdersModuleModels.Order): void {

    const iri = order['@id'];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    this.store$.dispatch(RouterActions.Go({path: ['orders', uuid]}));
  }

  handleUpdateFilter(filters: { [p: string]: string }): void {
    this.activeFilter = filters;
  }



  loadOrders(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    this.loadingTable$.next(true);
    this.subscriptionList = this.orderService.readOrders(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.loadingTable$.next(false);
        this.orderCount = response['hydra:totalItems'];
        this.orders$.next(response['hydra:member']);
      }, (fail) => {
        this.loadingTable$.next(false);
        console.error(fail);
      });
  }

  private updateParams(): void {
    if (this.searchBoxVisible$.getValue() === false) {
      this.params$.next({
        ...this.params$.getValue(),
        'order[createdAt]': 'desc',
        fulltext_search: this.params$.getValue().hasOwnProperty('fulltext_search') ? this.params$.getValue().fulltext_search : ''
      });
    }
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
  }
}
