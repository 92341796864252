import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Customer } from '../../models';
import { CustomersSelectors } from '../../store/selectors';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { CustomersService } from '../../services';
import { NotifierService } from 'angular-notifier';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { CustomersActions } from '../../store';

@Component({
  selector: 'app-customer-select',
  styleUrls: ['./customer-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSelectComponent),
      multi: true
    }
  ],
  template: `
    <form [formGroup]="form">
      <ng-select
        [items]="items"
        formControlName="customer"
        bindLabel="name"
        [searchable]="true"
        [searchFn]="searchCustomer"
        [loading]="isLoading$ | async"
        bindValue="@id"
        [clearable]="true"
        [notFoundText]="
          (searchString$ | async).length < 5
            ? ('dashboard.app_follow_up_calendar.partner_search_placeholder' | translate)
            : partnerOnly
            ? ('dashboard.app_follow_up_calendar.partner_not_found_placeholder' | translate)
            : ('dashboard.app_follow_up_calendar.partner_not_found_customers_placeholder' | translate)
        "
        (search)="onSearch($event)"
        (change)="onSelect($event)"
        (clear)="clearSearch()"
        [readonly]="readonly"
        [placeholder]="label"
      >
        <ng-template ng-label-tmp let-item="item">
          <span class="badge--vip inline" *ngIf="item.vip">VIP</span>
          {{ item.nameLine1 }}
          <span *ngIf="item.nameLine2">({{ item.nameLine2 }})</span>
          <span *ngIf="additionalTextFn">{{ additionalTextFn(item) }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          <span class="badge--vip inline" *ngIf="item.vip">VIP</span>
          {{ item.nameLine1 }}
          <span *ngIf="item.nameLine2">({{ item.nameLine2 }})</span>
          <span *ngIf="additionalTextFn">{{ additionalTextFn(item) }}</span>
        </ng-template>
      </ng-select>
    </form>
  `
})
export class CustomerSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label = 'Kunden wählen';
  @Input() preset$: Observable<Customer>;
  @Input() additionalTextFn: (customer: Customer) => string;
  @Input() partnerOnly = false;
  @Input() readonly = false;

  @Output() selectCustomer: EventEmitter<Customer> = new EventEmitter<
    Customer
  >();
  form: FormGroup;
  addCustomerToItems: Customer;
  searchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  items: Array<Customer> = [];
  localCustomers: Array<Customer> = [];
  cachedCustomers: Array<Customer> = [];
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private customerService: CustomersService,
    private store$: Store<ApplicationState>,
    private notifierService: NotifierService
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  ngOnInit(): void {
    this.form = this.fb.group({
      customer: this.fb.control(null)
    });
    this.form
      .get('customer')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });

    if (this.preset$) {
      this.preset$
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e)
        )
        .subscribe(c => {
          console.log(c);
          this.addCustomerToItems = c;
          this.updateItems();
          this.form.get('customer').setValue(c['@id']);
        });
    }
    this.store$
      .select(CustomersSelectors.selectCustomers)
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(customers => {
        this.cachedCustomers = customers;
        this.updateItems();
      });
    this.searchString$
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(500),
        filter(e => e.length > 4),
        tap(() => {
          this.isLoading$.next(true);
        }),
        switchMap(term => {
          const params: any = {
            nameLine1: term,
            'order[createdAt]': 'desc',
            itemsPerPage: 100
          };
          if (this.partnerOnly) {
            params.isPartner = true;
          }
          return this.customerService.readCustomers(1, params);
        })
      )
      .subscribe(
        response => {
          this.isLoading$.next(false);
          this.localCustomers = response['hydra:member'];
          this.updateItems();
        },
        data => {
          console.error(data);
          // const errors = response?.error['hydra:description'];
          // const message = 'Fehler bei der Anfrage: ' + errors;
          const message = 'FEHLEr';
          this.notifierService.show({ type: 'error', message });
        }
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('customer').disable();
    } else {
      this.form.get('customer').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('customer').setValue(extractIri(value));
    if (!value) {
      return;
    }
    this.store$
      .select(CustomersSelectors.selectCustomerByIndex, {
        iri: extractIri(value)
      })
      .pipe(
        takeUntil(this.onDestroy$),
        tap(e => {
          if (!e) {
            this.store$.dispatch(
              CustomersActions.ReadCustomer({ iri: extractIri(value) })
            );
          }
        }),
        filter(e => !!e)
      )
      .subscribe(customer => {
        this.localCustomers = [customer];
        this.updateItems();
      });
  }

  onSearch(event: any): void {
    this.searchString$.next(event.term);
  }

  onSelect(event: Customer): void {
    this.selectCustomer.emit(event);
  }

  searchCustomer(): any {
    return true;
  }

  updateItems(): void {
    const items = [];
    if (this.addCustomerToItems) {
      items.push(this.addCustomerToItems);
    }

    // TODO show already Loaded customer, if nothing is searched show store else show searched
    // if (this.searchString$.getValue().length > 0 || this.selectedCustomer) {
    //   this.items = [...this.localCustomers];
    // } else {
    //   this.items = [...this.cachedCustomers.sort(sortByCreatedAtDate)];
    // }
    this.items = [...items, ...this.localCustomers];
  }

  clearSearch(): void {
    this.localCustomers = [];
    this.updateItems();
    this.selectCustomer.emit(null);
  }
}
