import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_OPERATING_SYSTEM = '[Master Data] Create Operating System';
export const CREATE_OPERATING_SYSTEM_SUCCESS = '[Master Data] Create Operating System Success';
export const CREATE_OPERATING_SYSTEM_FAIL = '[Master Data] Create Operating System Fail';
export const CreateOperatingSystem = createAction(
  CREATE_OPERATING_SYSTEM,
  props<{ payload: fromModuleModels.OperatingSystem }>()
);
export const CreateOperatingSystemSuccess = createAction(
  CREATE_OPERATING_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.OperatingSystem }>()
);
export const CreateOperatingSystemFail = createAction(
  CREATE_OPERATING_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_OPERATING_SYSTEMS = '[Master Data] Read Operating Systems';
export const READ_OPERATING_SYSTEMS_SUCCESS = '[Master Data] Read Operating Systems Success';
export const READ_OPERATING_SYSTEMS_FAIL = '[Master Data] Read Operating Systems Fail';
export const ReadOperatingSystems = createAction(
  READ_OPERATING_SYSTEMS
);
export const ReadOperatingSystemsSuccess = createAction(
  READ_OPERATING_SYSTEMS_SUCCESS,
  props<{ response: any }>()
);
export const ReadOperatingSystemsFail = createAction(
  READ_OPERATING_SYSTEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1n
export const READ_OPERATING_SYSTEM = '[Master Data] Read Operating System';
export const READ_OPERATING_SYSTEM_SUCCESS = '[Master Data] Read Operating System Success';
export const READ_OPERATING_SYSTEM_FAIL = '[Master Data] Read Operating System Fail';
export const ReadOperatingSystem = createAction(
  READ_OPERATING_SYSTEM,
  props<{ iri: string }>()
);
export const ReadOperatingSystemSuccess = createAction(
  READ_OPERATING_SYSTEM_SUCCESS,
  props<{ response }>()
);
export const ReadOperatingSystemFail = createAction(
  READ_OPERATING_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_OPERATING_SYSTEM = '[Master Data] Update Operating System';
export const UPDATE_OPERATING_SYSTEM_SUCCESS = '[Master Data] Update Operating System Success';
export const UPDATE_OPERATING_SYSTEM_FAIL = '[Master Data] Update Operating System Fail';
export const UpdateOperatingSystem = createAction(
  UPDATE_OPERATING_SYSTEM,
  props<{ iri: string, payload: fromModuleModels.OperatingSystem }>()
);
export const UpdateOperatingSystemSuccess = createAction(
  UPDATE_OPERATING_SYSTEM_SUCCESS,
  props<{ response: fromModuleModels.OperatingSystem }>()
);
export const UpdateOperatingSystemFail = createAction(
  UPDATE_OPERATING_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_OPERATING_SYSTEM = '[Master Data] Delete Operating System';
export const DELETE_OPERATING_SYSTEM_SUCCESS = '[Master Data] Delete Operating System Success';
export const DELETE_OPERATING_SYSTEM_FAIL = '[Master Data] Delete Operating System Fail';
export const DeleteOperatingSystem = createAction(
  DELETE_OPERATING_SYSTEM,
  props<{ iri: string }>()
);
export const DeleteOperatingSystemSuccess = createAction(
  DELETE_OPERATING_SYSTEM_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteOperatingSystemFail = createAction(
  DELETE_OPERATING_SYSTEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
