import {createSelector} from '@ngrx/store';

import {LEAD_COMMENT_TAGS_FEATURE_KEY, selectLeadsModuleState} from '../reducers';

export const selectLeadCommentTagsState = createSelector(
  selectLeadsModuleState,
  (leadsModuleState) => leadsModuleState[LEAD_COMMENT_TAGS_FEATURE_KEY]
);

export const selectLeadCommentTagsEntities = createSelector(
  selectLeadCommentTagsState,
  (state) => state.entities
);

export const selectLeadCommentTags = createSelector(
  selectLeadCommentTagsEntities,
  (entities) => Object.values(entities)
);

export const isLoading = createSelector(
  selectLeadCommentTagsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectLeadCommentTagsState,
  (state) => state.loaded
);
