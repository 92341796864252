<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header">
      <span>{{"orders.data_carrier_return_widget.title" | translate}}</span>
      <div class="spacer"></div>
<!--      <mat-button-toggle-group-->
<!--        [(ngModel)]="BOTypeSelectValue"-->
<!--        name="BOTypeSelect"-->
<!--        aria-label="BO Type Select"-->
<!--        class="m-r&#45;&#45;16"-->
<!--      >-->
<!--        <mat-button-toggle value="missingBO">Fehlende VB</mat-button-toggle>-->
<!--        <mat-button-toggle value="missingDownPayment"-->
<!--        >Fehlende Anzahlung-->
<!--        </mat-button-toggle>-->
<!--        <mat-button-toggle value="BoReceived" *ngIf="userType !== 'Vertrieb'"-->
<!--        >VB Eingegangen-->
<!--        </mat-button-toggle>-->
<!--      </mat-button-toggle-group>-->
    </div>
    <app-orders-list-for-widget
      [customServiceFnName]="customServiceFnName"
      [additionalParams]="additionalParams"
      [indicatorStyleFn]="getCircleColor"
    ></app-orders-list-for-widget>
  </div>
</div>
