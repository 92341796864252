import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const LOAD_CUSTOMER_CONTACT_TYPE = '[Customers Module] Load Customer Contact Type ';
export const LOAD_CUSTOMER_CONTACT_TYPE_SUCCESS = '[Customers Module] Load Customer Contact Type Success';
export const LOAD_CUSTOMER_CONTACT_TYPE_FAIL = '[Customers Module] Load Customer Contact Type Fail';

export const LoadCustomerContactType = createAction(
  LOAD_CUSTOMER_CONTACT_TYPE,
  props<{ iri: string }>()
);

export const LoadCustomerContactTypeSuccess = createAction(
  LOAD_CUSTOMER_CONTACT_TYPE_SUCCESS,
  props<{ response: fromModuleModels.CustomerContactType }>()
);

export const LoadCustomerContactTypeFail = createAction(
  LOAD_CUSTOMER_CONTACT_TYPE_FAIL,
  props<{ response: any }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const LOAD_CUSTOMER_CONTACT_TYPES = '[Customers Module] Load Customer Contact Types ';
export const LOAD_CUSTOMER_CONTACT_TYPES_SUCCESS = '[Customers Module] Load Customer Contact Types Success';
export const LOAD_CUSTOMER_CONTACT_TYPES_FAIL = '[Customers Module] Load Customer Contact Types Fail';

export const ReadCustomerContactTypes = createAction(
  LOAD_CUSTOMER_CONTACT_TYPES
);

export const ReadCustomerContactTypesSuccess = createAction(
  LOAD_CUSTOMER_CONTACT_TYPES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerContactTypesFail = createAction(
  LOAD_CUSTOMER_CONTACT_TYPES_FAIL,
  props<{ response: any }>()
);
