import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { StorageSystem } from '../models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';
import {ParameterBag} from "../../shared/models/ParameterBag.interface";

@Injectable()
export class StorageSystemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createStorageSystem(payload: StorageSystem): Observable<StorageSystem> {
    return this.rolesService.userHasRoleFilter<StorageSystem>(
      'ROLE_MWS_STORAGE_SYSTEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/storage_systems`, payload)
    );
  }

  getStorageSystem(iri: string): Observable<StorageSystem> {
    return this.rolesService.userHasRoleFilter<StorageSystem>(
      'ROLE_MWS_STORAGE_SYSTEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getStorageSystems(
    page?: number,
    params?: ParameterBag
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_STORAGE_SYSTEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(`/storage_systems`, page ? page : -1, params ? params : { 'order[order]': 'asc' })
            )
    );
  }

  updateStorageSystem({ iri, payload }): Observable<StorageSystem> {
    return this.rolesService.userHasRoleFilter<StorageSystem>(
      'ROLE_MWS_STORAGE_SYSTEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteStorageSystem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_STORAGE_SYSTEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
