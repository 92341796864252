import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Order} from '../../../orders/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {FormBuilder} from '@angular/forms';
import {Actions} from '@ngrx/effects';
import {filter, takeUntil} from 'rxjs/operators';
import {RouterActions} from '../../../application-state/store/actions';
import {getUuidFromIri} from '../../utilities/strings.utility';
import {extractTypeByIri} from "../../utilities/objects.utility";

@Component({
  selector: 'app-action-box-create-final-invoice-to-wait-for-payment',
  styleUrls: ['./action-box-create-final-invoice-to-wait-for-payment.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label">Aktion erforderlich</div>
      <div class="content ">
        <div class="row">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header"> Abschlussrechnung erstellen</div>
              <div class="sub-header col-auto">Erstellen Sie die Abschlussrechnung für diesen Auftrag.</div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button class="decision-btn me-3" mat-button color="green" (click)="gotToInvoice()">
              <span>Zur Rechnung</span>
            </button>
          </div>
        </div>
        <!--<pre>{{order|json}}</pre>-->
      </div>

    </div>
  `

})
export class ActionBoxCreateFinalInvoiceToWaitForPaymentComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;
  order: Order;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              private fb: FormBuilder,
              private actions$: Actions) {
  }

  ngOnInit(): void {
    this.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      this.order = order;
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  gotToInvoice(): void {
    // TODO change to ID
    const iri = this.order.paymentProcess?.autoCreatedInvoice || this.order.paymentProcess?.autoCreatedPartialInvoice;
    const uuid = getUuidFromIri(iri);
    const type = extractTypeByIri(iri);
    this.store$.dispatch(RouterActions.Go({path: ['invoices', 'payable', type, uuid]}));
  }

}
