import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { FormsService } from '../../services';
import { NotifierService } from 'angular-notifier';
import { Actions, ofType } from '@ngrx/effects';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../utilities/observable.utility';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Lead } from '../../../leads/models';
import {
  EmailTemplateCategoriesSelectors,
  EmailTemplateContextsSelectors,
  EmailTemplatesSelectors
} from '../../../master-data/store/selectors';
import {
  EmailTemplateCategoriesActions,
  EmailTemplateContextsActions,
  EmailTemplatesActions
} from '../../../master-data/store';
import {
  EmailTemplate,
  EmailTemplateCategory,
  EmailTemplateContext
} from '../../../master-data/models';
import { extractIri } from '../../utilities/objects.utility';
import { LeadsActions } from '../../../leads/store';
import {
  ActionCreator,
  NotAllowedCheck,
  TypedAction
} from '@ngrx/store/src/models';
import { LeadsSelectors } from 'src/app/leads/store/selectors';
import { OrdersActions } from '../../../orders/store';
import {
  CancellationInvoicesActions,
  CommissionCreditsActions,
  CorrectionInvoicesActions,
  DocumentDeliveriesActions,
  OffersActions,
  PayableInvoicesActions
} from '../../../invoices/store';
import {
  CancellationInvoicesSelectors,
  CommissionCreditsSelectors,
  CorrectionInvoicesSelectors,
  DocumentDeliveriesSelectors,
  PayableInvoicesSelectors
} from '../../../invoices/store/selectors';
import {
  CancellationInvoice,
  CommissionCredit,
  CorrectionInvoice,
  Offer
} from '../../../invoices/models';
import {
  CustomerContactsSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import {
  CustomerContactsActions,
  CustomersActions
} from '../../../customers/store';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { uniq } from 'lodash-es';
import { EmailTemplateService } from '../../../master-data/services/email-template.service';

@Component({
  selector: 'app-write-email-dialog',
  styleUrls: ['./write-email-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2 style="min-width: 800px;">
        {{ configuration?.title || 'E-Mail senden' }}
      </h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative" [formGroup]="form">
      <app-loading-overlay
        *ngIf="(isLoading$ | async) || (loadingPreview$ | async)"
      ></app-loading-overlay>
      <div class="row justify-content-end">
        <div [class]="form.contains('department') ? 'col-md-4' : 'col-md-6'">
          <div class="mat-form-field">
            <ng-select
              placeholder="Kategorie"
              [items]="categories$ | async"
              [loading]="categoriesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [searchable]="false"
              [clearable]="true"
              (change)="updateCategory($event)"
              formControlName="category"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'category'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>

        <div [class]="form.contains('department') ? 'col-md-4' : 'col-md-6'">
          <div class="mat-form-field">
            <ng-select
              placeholder="Vorlage"
              [items]="templates$ | async"
              [loading]="templatesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [readonly]="!selectedCategory"
              [markFirst]="false"
              [searchable]="false"
              [clearable]="true"
              formControlName="template"
              (change)="onTemplateChange($event)"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'template'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="col-md-4" *ngIf="form.contains('department')">
          <div class="mat-form-field">
            <app-department-select
              formControlName="department"
            ></app-department-select>
            <mat-error>
              <app-form-error
                [fieldName]="'department'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="form.contains('recipientEmail')">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Empfänger der E-Mail*</mat-label>
            <input matInput formControlName="recipientEmail" list="datalist" />
            <datalist id="datalist">
              <option *ngIf="possibleEmailsIsLoading$ | async">läd...</option>
              <option
                *ngFor="let email of possibleEmails$ | async"
                (click)="form.patchValue({ recipientEmail: email })"
                >{{ email }}
              </option>
            </datalist>
            <mat-error>
              <app-form-error
                fieldName="recipientEmail"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Betreff der E-Mail*</mat-label>
            <input matInput formControlName="subject" />
            <mat-error>
              <app-form-error
                [fieldName]="'subject'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 body-editor">
          <app-text-editor formControlName="body"></app-text-editor>
          <mat-error>
            <app-form-error
              [fieldName]="'body'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </div>
      </div>
      <div class="row mb-5" *ngIf="form.contains('comment')">
        <div class="col-12 body-editor">
          <app-text-editor
            formControlName="comment"
            label="Kommentar"
          ></app-text-editor>
          <mat-error>
            <app-form-error
              fieldName="comment"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="justify-content-between">
      <app-mail-attachment-list
        [type]="data.type"
        [entity$]="data.entity$"
      ></app-mail-attachment-list>

      <button mat-button color="green" (click)="data.collectMail ? handleCollectMail() : handleSendEMail()">
        <mat-icon class="m-r--8">email</mat-icon>
         {{ 'E-Mail versenden'}}
      </button>
    </div>
  `
})
export class WriteEMailDialogComponent implements OnInit, OnDestroy {
  loadingPreview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();
  entity:
    | Order
    | Lead
    | CorrectionInvoice
    | Offer
    | CancellationInvoice
    | CommissionCredit;
  form: FormGroup;

  templates$: Observable<Array<EmailTemplate>>;
  templatesIsLoading$: Observable<boolean>;
  possibleEmails$: BehaviorSubject<Array<string>> = new BehaviorSubject<
    Array<string>
  >([]);
  possibleEmailsIsLoading$: Observable<boolean>;

  categories$: Observable<Array<EmailTemplateCategory>>;
  categoriesIsLoading$: Observable<boolean>;
  selectedCategory: EmailTemplateCategory;
  configuration: {
    context: string;
    sendAction: ActionCreator<
      any,
      (props: any & NotAllowedCheck<any>) => any & TypedAction<any>
    >;
    title: string;
  };
  types = {
    lead: {
      sendAction: LeadsActions.MailLead,
      title: 'E-Mail zu Anfrage',
      context: '/api/template_contexts/LEAD'
    },
    order: {
      sendAction: OrdersActions.MailOrder,
      title: 'E-Mail zu Auftrag',
      context: '/api/template_contexts/ORDER_GENERAL'
    },
    dataRecoveryProtocol: {
      sendAction: OrdersActions.SendDataRecoveryProtocol,
      title: 'Datenrettungsprotokoll per E-Mail versenden',
      context: '/api/template_contexts/ORDER_GENERAL'
    },
    proofOfDestruction: {
      sendAction: OrdersActions.SendProofOfDestructionPDF,
      title: 'Vernichtungsnachweis per E-Mail versenden',
      context: '/api/template_contexts/ORDER_GENERAL'
    },
    bindingOrder: {
      sendAction: OrdersActions.SendBindingOrder,
      title: 'Verbindliche Bestellung per E-Mail verschicken',
      context: '/api/template_contexts/BINDING_ORDER'
    },
    'payable-invoice': {
      sendAction: PayableInvoicesActions.MailPayableInvoice,
      title: 'E-Mail zu Rechnung',
      context: '/api/template_contexts/PAYABLE_INVOICE'
    },
    'cancellation-invoice': {
      sendAction: CancellationInvoicesActions.MailCancellationInvoice,
      title: 'E-Mail zu Stornorechnung',
      context: '/api/template_contexts/CANCELLATION_INVOICE'
    },
    'correction-invoice': {
      sendAction: CorrectionInvoicesActions.SendMail,
      title: 'E-Mail zu Korrekturrechnung',
      context: '/api/template_contexts/CORRECTION_INVOICE'
    },
    'commission-credit': {
      sendAction: CommissionCreditsActions.SendMail,
      title: 'E-Mail zu Provisionsgutschrift',
      context: '/api/template_contexts/COMMISSION_CREDIT'
    },
    offer: {
      sendAction: OffersActions.SendMail,
      title: 'E-Mail zu Angebot',
      context: '/api/template_contexts/OFFER'
    },
    email_document_deliveries: {
      sendAction: DocumentDeliveriesActions.CreateEmailDocumentDelivery,
      title: 'Dokument per E-Mail versenden',
      context: null // will be filled later
    },
    order_confirmation: {
      sendAction: DocumentDeliveriesActions.CreateEmailDocumentDelivery,
      title: 'Dokument per E-Mail versenden',
      context: null // will be filled later
    }
  };
  contexts: Array<EmailTemplateContext>;
  currentContext: EmailTemplateContext;

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    public dialog: MatDialog,
    private formService: FormsService,
    private emailService: EmailTemplateService,
    private notifierService: NotifierService,
    public actions$: Actions,
    public dialogRef: MatDialogRef<WriteEMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      entity$: Observable<
        | Order
        | Lead
        | CorrectionInvoice
        | Offer
        | CancellationInvoice
        | CommissionCredit
      >;
      collectMail?: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.actions$
      .pipe(
        ofType(
          LeadsActions.MailLeadFail,
          OrdersActions.MailOrderFail,
          DocumentDeliveriesActions.CreateEmailDocumentDeliveryFail,
          OrdersActions.SendDataRecoveryProtocolFail,
          OrdersActions.SendProofOfDestructionPDFFail,
          OrdersActions.SendBindingOrderFail
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fail => {
        this.formService.mergeErrorResponseIntoForm(fail, this.form);
      });

    if (this.data?.type) {
      this.configuration = this.types[this.data.type];
      if (this.data.type === 'lead') {
        this.form.addControl(
          'department',
          this.fb.control(null, [Validators.required])
        );
      }
      if (this.data.type === 'bindingOrder') {
        this.form.addControl(
          'comment',
          this.fb.control('', [Validators.required])
        );
      }
      if (this.data.type === 'email_document_deliveries') {
        this.form.addControl(
          'recipientEmail',
          this.fb.control(null, [Validators.required])
        );
        this.form.addControl(
          'document',
          this.fb.control(null, [Validators.required])
        );
      }
    }
    this.watchActions();
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(LeadsSelectors.isLoading),
      this.store$.select(EmailTemplatesSelectors.isLoading),
      this.store$.select(EmailTemplateContextsSelectors.isLoading),
      this.store$.select(EmailTemplateCategoriesSelectors.isLoading),
      this.store$.select(DocumentDeliveriesSelectors.isLoading),
      this.store$.select(PayableInvoicesSelectors.isLoading),
      this.store$.select(CorrectionInvoicesSelectors.isLoading),
      this.store$.select(CancellationInvoicesSelectors.isLoading),
      this.store$.select(CommissionCreditsSelectors.isLoading)
    ]);
    this.data.entity$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(entity => !!entity)
      )
      .subscribe(entity => {
        this.entity = entity;
        if (this.data.type === 'email_document_deliveries') {
          this.loadPossibleRecipients(entity);

          this.form.patchValue({ document: extractIri(entity) });
          console.log(entity['@type']);
          switch (entity['@type']) {
            case 'Invoice':
            case 'PartialInvoice':
              this.configuration.context =
                '/api/template_contexts/PAYABLE_INVOICE';
              break;
            case 'CancellationInvoice':
              this.configuration.context =
                '/api/template_contexts/CANCELLATION_INVOICE';
              break;
            case 'CorrectionInvoice':
              this.configuration.context =
                '/api/template_contexts/CORRECTION_INVOICE';
              break;
            case 'Offer':
              this.configuration.context = '/api/template_contexts/OFFER';
              break;
            case 'CommissionCredit':
              this.configuration.context =
                '/api/template_contexts/COMMISSION_CREDIT';
              break;
            case 'Lead':
              this.configuration.context =
                '/api/template_contexts/ORDER_CONFIRMATION';
              break;
          }
          this.updateCurrentContext();
        }
      });
    this.loadData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.form = this.fb.group({
      category: this.fb.control(null),
      template: this.fb.control(null),
      subject: this.fb.control(null, [Validators.required]),
      body: this.fb.control(null, [Validators.required])
      // comment: this.fb.control(null, Validators.required)
    });
  }

  onTemplateChange(event: EmailTemplate): void {
    this.loadingPreview$.next(true);
    this.emailService
      .preview(extractIri(event), extractIri(this.entity))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        result => {
          this.loadingPreview$.next(false);
          this.form.patchValue({
            subject: result.renderedSubject,
            body: result.renderedContent
          });
        },
        fail => {
          this.loadingPreview$.next(false);
          const errors = fail?.error['hydra:description'];
          const message =
            'Beim abrufen der Vorlage sind Fehler aufgetreten:' +
            (errors || fail);
          this.notifierService.show({ type: 'error', message });
        }
      );
  }

  handleSendEMail(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    // TODO this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateOrder(payload));
    if (this.data.type === 'email_document_deliveries') {
      const formValue = this.form.getRawValue();
      formValue.content = formValue.body || '';
      this.store$.dispatch(
        DocumentDeliveriesActions.CreateEmailDocumentDelivery({
          payload: formValue,
          invoiceIri: extractIri(this.entity)
        })
      );
    } else {
      this.store$.dispatch(
        this.configuration.sendAction({
          iri: extractIri(this.entity),
          payload: this.form.getRawValue()
        })
      );
    }
  }

  handleCollectMail(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  loadData(): void {
    loadIfNotLoaded(
      this.store$,
      EmailTemplateContextsSelectors.isLoaded,
      EmailTemplateContextsActions.ReadList()
    );
    this.store$
      .select(EmailTemplateContextsSelectors.sList)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(contexts => {
        this.contexts = contexts;
        this.updateCurrentContext();
      });
    this.categories$ = this.store$.select(
      EmailTemplateCategoriesSelectors.sList
    );
    this.templatesIsLoading$ = this.store$.select(
      EmailTemplatesSelectors.isLoading
    );
    this.categoriesIsLoading$ = this.store$.select(
      EmailTemplateCategoriesSelectors.isLoading
    );

    loadIfNotLoaded(
      this.store$,
      EmailTemplateCategoriesSelectors.isLoaded,
      EmailTemplateCategoriesActions.ReadList()
    );
    loadIfNotLoaded(
      this.store$,
      EmailTemplatesSelectors.isLoaded,
      EmailTemplatesActions.ReadEmailTemplates()
    );
  }

  updateCurrentContext(): void {
    if (!this.configuration.context || !this.contexts) {
      return;
    }
    this.currentContext = this.contexts.find(
      e => e['@id'] === this.configuration.context
    );
  }

  updateCategory($event: EmailTemplateCategory): void {
    this.selectedCategory = $event;
    this.templates$ = this.store$.select(
      EmailTemplatesSelectors.sByCategoryAndContext,
      {
        category: $event,
        context: this.currentContext
      }
    );
  }

  private watchActions(): void {
    this.actions$
      .pipe(
        ofType(
          LeadsActions.MailLeadSuccess,
          OrdersActions.MailOrderSuccess,
          PayableInvoicesActions.MailPayableInvoiceSuccess,
          CancellationInvoicesActions.MailCancellationInvoiceSuccess,
          CorrectionInvoicesActions.SendMailSuccess,
          CommissionCreditsActions.SendMailSuccess,
          OffersActions.SendMailSuccess,
          DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
          OrdersActions.SendProofOfDestructionPDFSuccess,
          OrdersActions.SendDataRecoveryProtocolSuccess,
          OrdersActions.SendBindingOrderSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  private loadPossibleRecipients(
    entity:
      | Order
      | Lead
      | CorrectionInvoice
      | Offer
      | CancellationInvoice
      | CommissionCredit
  ): void {
    if ('customerNumber' in entity) {
      this.possibleEmailsIsLoading$ = isLoadingArray([
        this.store$.select(CustomersSelectors.isLoading),
        this.store$.select(CustomerContactsSelectors.isLoading)
      ]);
      const customerNumber = entity.customerNumber;
      this.store$
        .select(CustomersSelectors.sByCustomerNumber, { customerNumber })
        .pipe(
          takeUntil(this.onDestroy$),
          tap(e => {
            if (!e) {
              this.store$.dispatch(
                CustomersActions.ReadCustomers({
                  page: -1,
                  params: { id: customerNumber }
                })
              );
            }
          }),
          filter(e => !!e)
        )
        .subscribe(customer => {
          this.store$.dispatch(
            CustomerContactsActions.ReadCustomerContacts({
              page: -1,
              params: { 'customer.uuid': getUuidFromIri(extractIri(customer)) }
            })
          );
          this.store$
            .select(
              CustomerContactsSelectors.selectCustomerContactsByCustomerIri,
              { customerIri: extractIri(customer) }
            )
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e)
            )
            .subscribe(customerContacts => {
              if (customer.defaultOrganizationalCustomerContact) {
                const recipientEmail = customerContacts.find(
                  e =>
                    extractIri(e) ===
                    customer.defaultOrganizationalCustomerContact
                )?.email;
                if (recipientEmail) {
                  this.form.patchValue({ recipientEmail });
                }
              }
              // console.log(customerContacts, customer);
              // this.possibleEmails$.next(customerContacts.map(e => e.firstName + ' ' + e.lastName + '<' + e.email + '>'));
              const mailList = uniq(customerContacts.map(e => e.email));
              if (mailList.length === 1) {
                this.form.patchValue({ recipientEmail: mailList[0] });
              }
              this.possibleEmails$.next(mailList);
            });
          console.log(customer);
        });
    }
  }
}
