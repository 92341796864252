import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { GenericOrderItem } from '../models/generic-order-item.interface';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class GenericOrderItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createGenericOrderItem(payload: any): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_GENERIC_ORDER_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/generic_order_items`, payload)
    );
  }

  readGenericOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_GENERIC_ORDER_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readGenericOrderItems(orderUuid, page = -1): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_GENERIC_ORDER_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/generic_order_items', page, {
                'order.uuid': orderUuid
              })
            )
    );
  }

  updateGenericOrderItem(
    iri: string,
    payload: GenericOrderItem
  ): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_GENERIC_ORDER_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteGenericOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_GENERIC_ORDER_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
