import { createReducer, on } from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { DataRecoveryProtocolsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromOrdersModuleModels.DataRecoveryProtocol;
  entities: { [iri: string]: fromOrdersModuleModels.DataRecoveryProtocol };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocol,
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocols,
    DataRecoveryProtocolsActions.UpdateDataRecoveryProtocol,
    DataRecoveryProtocolsActions.DeleteDataRecoveryProtocol,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocolSuccess,
    DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolSuccess,

    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocolsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: {
            [iri: string]: fromOrdersModuleModels.DataRecoveryProtocol;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        // Note: Intentionally "clear" state when returning from single view or navigating tru list view
        {}
        // {...state.entities}
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    DataRecoveryProtocolsActions.DeleteDataRecoveryProtocolSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocolFail,
    DataRecoveryProtocolsActions.ReadDataRecoveryProtocolsFail,
    DataRecoveryProtocolsActions.UpdateDataRecoveryProtocolFail,
    DataRecoveryProtocolsActions.DeleteDataRecoveryProtocolFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
