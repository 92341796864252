import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DestructionType} from '../../../master-data/models';


// todo: should be merged with lab location box for a common dropdown list component

@Component({
  selector: 'disposal-type-selection-box',
  styleUrls: ['disposal-type-selection-box.component.scss'],
  template: `

    <div class="grid grid-middle">

      <div class="column-10" [formGroup]="dtf">

        <ng-select
          placeholder="Entsorgungstyp"
          [items]="disposalTypes"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="true"
          [clearable]="false"
          formControlName="disposalType"
        ></ng-select>
      </div>

      <div class="column-4">
        <button mat-button color="green" (click)="handleSubmit()" [disabled]="dtf.invalid">
          <mat-icon class="m-r--8">{{ leftButtonIconIdentifier ? leftButtonIconIdentifier : 'done' }}</mat-icon>
          <span>{{ leftButtonLabel }}</span>
        </button>
      </div>
    </div>

    <!--<pre>{{ locationPropertyName | json }}</pre>-->
  `
})
export class DisposalTypeSelectionBoxComponent implements OnInit {

  @Input() leftButtonLabel: string;
  @Input() leftButtonIconIdentifier: string;
  @Input() disposalTypes: Array<DestructionType> = [
    {
      '@id': '/api/disposal_types/DISPOSAL_TYPE_PAID',
      '@type': 'DisposalType',
      name: 'Bezahlte Entsorgung mit Nachweis (nur Datenträger)'
    },
    {
      '@id': '/api/disposal_types/DISPOSAL_TYPE_UNPAID',
      '@type': 'DisposalType',
      name: 'Unbezahlte Entsorgung ohne Nachweis (DT inkl. Zubehör)'
    },
    {
      '@id': '/api/disposal_types/DISPOSAL_TYPE_PAID_PARTIAL',
      '@type': 'DisposalType',
      name: 'disposal_type.paid_partial'
    },
    {
      '@id': '/api/disposal_types/DISPOSAL_TYPE_UNPAID_PARTIAL',
      '@type': 'DisposalType',
      name: 'disposal_type.unpaid_partial'
    },
    {
      '@id': '/api/disposal_types/DISPOSAL_TYPE_RETURN_SHIPPING',
      '@type': 'DisposalType',
      name: 'Rückbau'
    }
  ];

  @Output() requestUpdateOrder: EventEmitter<any> = new EventEmitter();

  dtf: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dtf = this.fb.group({
      disposalType: this.fb.control(null, [Validators.required])
    });
  }

  handleSubmit(): void {
    this.requestUpdateOrder.emit(this.dtf.value);
  }
}
