import { createReducer, on } from '@ngrx/store';

import { EmailTemplateContextsActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { EmailTemplateContext } from '../../models';
import { UserRoleActions } from '../../../application-state/store/actions';

type baseType = EmailTemplateContext;
const Actions = EmailTemplateContextsActions;

export interface State {
  entities: { [iri: string]: baseType };
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(Actions.Read, Actions.ReadList, state => ({
    ...state,
    loading: true
  })),
  on(Actions.ReadSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities([response], null, state.entities),
    loading: false,
    loaded: false
  })),
  on(Actions.ReadListSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities(response['hydra:member'], null, state.entities),
    loading: false,
    loaded: true
  })),

  on(Actions.ReadFail, Actions.ReadListFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
