import {Action, ActionReducerMap, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromFeedbackRequests from './feedback-requests.reducers';

export const FEEDBACK_REQUESTS_FEATURE_KEY = 'feedbackRequests';

export interface NotificationsModuleState {
  [FEEDBACK_REQUESTS_FEATURE_KEY]: fromFeedbackRequests.State;
}

/*export const reducers: ActionReducerMap<NotificationsModuleState> = {
  [FEEDBACK_REQUESTS_FEATURE_KEY]: fromFeedbackRequests.reducer
};*/

export function reducers(state: NotificationsModuleState, action: Action) {

  return combineReducers({
    [FEEDBACK_REQUESTS_FEATURE_KEY]: fromFeedbackRequests.reducer
  })(state, action);
}
