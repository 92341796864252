import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {FormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-open-analysis-awaiting-package-widget-ext',
  styleUrls: ['./open-analysis-awaiting-package-widget-ext.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>{{ "orders.open_analysis_awaiting_package_widget_ext.title" | translate }}</span>
          <div class="spacer"></div>
          <mat-button-toggle-group [formControl]="selectedFilter$" class="me-3">
            <mat-button-toggle [value]="'all'" class="white-if-checked">
              {{ "orders.open_analysis_awaiting_package_widget_ext.button.all" | translate }}
            </mat-button-toggle>
            <mat-button-toggle [value]="'analyses'"
                               class="white-if-checked">
              {{ "orders.open_analysis_awaiting_package_widget_ext.button.analyses" | translate }}
            </mat-button-toggle>
            <mat-button-toggle [value]="'dataRecovery'"
                               class="white-if-checked">
              {{ "orders.open_analysis_awaiting_package_widget_ext.button.data_recovery" | translate }}
            </mat-button-toggle>

          </mat-button-toggle-group>
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenAnalysisAwaitingPackageWidgetExtComponent
  extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  selectedFilter$ = new FormControl('');
  analysis: string[] = [
    'analysis_bundled_shipping_dr_ext',
    'analysis_single_shipping_dr_ext',
  ]
  dataRecovery: string[] = [
    'dm_wrong_placed_trigger_shipping_dr_dr_remote',
    'dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_remote',
    'dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext',
    'dm_wrong_placed_nat_dr_de_trigger_shipping_dr_dr_ext',
    'dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext',
    'dm_wrong_placed_shipping_in_progress_to_dr_ext_dr_dr_ext'
  ]
  all: string[] = [
    ...this.analysis,
    ...this.dataRecovery
  ];
  states: string[] = [];
  onDestroy$: Subject<any> = new Subject<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.getStates();
    this.selectedFilter$.setValue('all', {emitEvent: true});
  }
  getStates(): void {
    this.selectedFilter$.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        switch (value) {
          case 'all':
            this.states = this.all;
            break;
          case 'analyses':
            this.states = this.analysis;
            break;
          case 'dataRecovery':
            this.states = this.dataRecovery;
            break;
          default:
            this.states = this.all;
            break;
        }
      });
  }
  getCircleColor(): { [key: string]: string } {
    return {backgroundColor: '#ffc400'};
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
