import {createSelector} from '@ngrx/store';

import {PAYABLE_INVOICES_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectPayableInvoicesState = createSelector(
  selectInvoicesModuleState,
  (state) => state[PAYABLE_INVOICES_FEATURE_KEY]
);

export const selectPayableInvoicesEntities = createSelector(
  selectPayableInvoicesState,
  (state) => state.entities
);

export const selectPayableInvoices = createSelector(
  selectPayableInvoicesEntities,
  (entities) => Object.values(entities)
);

export const selectPayableInvoiceByIndex = createSelector(
  selectPayableInvoicesEntities,
  (entities, {iri}) => entities[iri]
);

export const selectCurrentInvoice = createSelector(
  selectPayableInvoicesState,
  (state) => state.current
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Search

export const selectSearchResultsIris = createSelector(
  selectPayableInvoicesState,
  state => state.searchEntities
);

export const selectSearchResults = createSelector(
  selectPayableInvoicesEntities,
  selectSearchResultsIris,
  (entities, selection) => selection.map(key => entities[key])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTotalItems = createSelector(
  selectPayableInvoicesState,
  state => state.totalItems
);
export const selectPayableInvoicePagination = createSelector(
  selectPayableInvoicesState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectPayableInvoicePagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectPayableInvoicePagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectPayableInvoicePagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectPayableInvoicePagination,
  pagination => pagination?.next
);
export const selectPaginationLastLink = createSelector(
  selectPayableInvoicePagination,
  pagination => pagination.last
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectPayableInvoicesState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectPayableInvoicesState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectPayableInvoicesState,
  (state) => state.errors
);
