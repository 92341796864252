import { Component, Input, OnInit } from '@angular/core';

import * as fromModuleModels from '../../models';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  styleUrls: ['navbar.component.scss'],
  template: `
    <div class="mainmn__outer">
      <div class="mainmn__wrapper wrap">
        <div class="mainmn grid grid-no-gutter ">
          <a
            *ngFor="let item of menuItems; let i = index"
            class="item column-{{ item.colSpan ? item.colSpan : 'auto' }}"
            [routerLink]="item.linkTarget"
            routerLinkActive="active"
            [class.hidden]="isElementHidden$(item) | async"
          >
            <button mat-ripple type="button">
              <mat-icon>{{ item.iconIdentifier }}</mat-icon>
              <span>{{ item.label }}</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  `
})
export class NavbarComponent implements OnInit {
  @Input() menuItems: Array<fromModuleModels.MenuItem>;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  isElementHidden$(item): Observable<boolean> {
    if (!item?.neededRole) {
      return of(false);
    }
    return this.authService.userBaseRoles$.pipe(
      map(
        roles =>
          !item?.neededRole?.some(e =>
            this.authService.hasGroupWithName(roles, e)
          )
      )
    );
  }
}
