import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_TICKET_ATTACHMENT = '[Tickets Module] Create Ticket Attachment';
export const CREATE_TICKET_ATTACHMENT_SUCCESS = '[Tickets Module] Create Ticket Attachment Success';
export const CREATE_TICKET_ATTACHMENT_FAIL = '[Tickets Module] Create Ticket Attachment Fail';

export const CreateTicketAttachment = createAction(
  CREATE_TICKET_ATTACHMENT,
  props<{ payload: any }>()
);

export const CreateTicketAttachmentSuccess = createAction(
  CREATE_TICKET_ATTACHMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateTicketAttachmentFail = createAction(
  CREATE_TICKET_ATTACHMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_TICKET_ATTACHMENT = '[Tickets Module] Read Ticket Attachment';
export const READ_TICKET_ATTACHMENT_SUCCESS = '[Tickets Module] Read Ticket Attachment Success';
export const READ_TICKET_ATTACHMENT_FAIL = '[Tickets Module] Read Ticket Attachment Fail';

export const ReadTicketAttachment = createAction(
  READ_TICKET_ATTACHMENT,
  props<{ iri: string }>()
);

export const ReadTicketAttachmentSuccess = createAction(
  READ_TICKET_ATTACHMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketAttachmentFail = createAction(
  READ_TICKET_ATTACHMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_TICKET_ATTACHMENTS = '[Tickets Module] Read Ticket Attachments';
export const READ_TICKET_ATTACHMENTS_SUCCESS = '[Tickets Module] Read Ticket Attachments Success';
export const READ_TICKET_ATTACHMENTS_FAIL = '[Tickets Module] Read Ticket Attachments Fail';

export const ReadTicketAttachments = createAction(
  READ_TICKET_ATTACHMENTS,
  props<{ page: number, params: { [p: string]: any } }>()
);

export const ReadTicketAttachmentsSuccess = createAction(
  READ_TICKET_ATTACHMENTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketAttachmentsFail = createAction(
  READ_TICKET_ATTACHMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_TICKET_ATTACHMENT = '[Tickets Module] Delete Ticket Attachment';
export const DELETE_TICKET_ATTACHMENT_SUCCESS = '[Tickets Module] Delete Ticket Attachment Success';
export const DELETE_TICKET_ATTACHMENT_FAIL = '[Tickets Module] Delete Ticket Attachment Fail';

export const DeleteTicketAttachment = createAction(
  DELETE_TICKET_ATTACHMENT,
  props<{ iri: string, ticketIri: string }>()
);

export const DeleteTicketAttachmentSuccess = createAction(
  DELETE_TICKET_ATTACHMENT_SUCCESS,
  props<{ iri: string, ticketIri: string }>()
);

export const DeleteTicketAttachmentFail = createAction(
  DELETE_TICKET_ATTACHMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset State

export const RESET_TICKET_ATTACHMENTS_STATE = '[Tickets Module] Reset Ticket Attachments State';

export const ResetTicketAttachmentsState = createAction(
  RESET_TICKET_ATTACHMENTS_STATE,
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Download

export const DOWNLOAD_TICKET_ATTACHMENT = '[Tickets Module] Read Ticket Attachment';
export const DOWNLOAD_TICKET_ATTACHMENT_SUCCESS = '[Tickets Module] Read Ticket Attachment Success';
export const DOWNLOAD_TICKET_ATTACHMENT_FAIL = '[Tickets Module] Read Ticket Attachment Fail';

export const DownloadTicketAttachment = createAction(
  DOWNLOAD_TICKET_ATTACHMENT,
  props<{ iri: string }>()
);

export const DownloadTicketAttachmentSuccess = createAction(
  DOWNLOAD_TICKET_ATTACHMENT_SUCCESS,
  props<{ response: any }>()
);

export const DownloadTicketAttachmentFail = createAction(
  DOWNLOAD_TICKET_ATTACHMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
