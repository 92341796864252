import {NgModule} from '@angular/core';

import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';
import * as fromModuleGuards from './guards';
import * as fromModuleServices from './services';
import {CustomersModule} from '../customers/customers.module';
import {SharedModule} from '../shared/shared.module';
import {ShippingModule} from '../shipping/shipping.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {FormsModule} from '@angular/forms';
import {NgxMatIntlTelInputModule} from 'ngx-11-mat-intl-tel-input';
import { LeadsListCardWrapperComponent } from './components/leads-list/card-wrapper/leads-list-card-wrapper.component';
import {DragToSelectModule} from "ngx-drag-to-select";
import {TranslateModule} from "@ngx-translate/core";
import { LeadsSearchDialogComponent } from './components/leads-search-dialog/leads-search-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        CustomersModule,
        ShippingModule,
        MatButtonToggleModule,
        FormsModule,
        NgxMatIntlTelInputModule,
        DragToSelectModule,
        TranslateModule
    ],
  providers: [
    ...fromModuleGuards.AllGuards,
    ...fromModuleServices.AllServices,
  ],
  declarations: [
    ...fromModuleComponents.AllComponents,
    ...fromModuleContainers.AllContainers,
    LeadsListCardWrapperComponent,
    LeadsSearchDialogComponent,
  ],
  exports: [
    ...fromModuleComponents.AllComponents,
    ...fromModuleContainers.AllContainers
  ]
})
export class LeadsModule {
}
