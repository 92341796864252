import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {CorrectionInvoice, InvoiceMail} from "../../models";
import {InvoicePdfResponse} from "../../models/invoice-pdf-Response";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CORRECTION_INVOICE = '[Invoices Module] Create Correction Invoice';
export const CREATE_CORRECTION_INVOICE_FAIL = '[Invoices Module] Create Correction Invoice Fail';
export const CREATE_CORRECTION_INVOICE_SUCCESS = '[Invoices Module] Create Correction Invoice Success';

export const CreateCorrectionInvoice = createAction(
  CREATE_CORRECTION_INVOICE,
  props<{ payload: any }>()
);

export const CreateCorrectionInvoiceSuccess = createAction(
  CREATE_CORRECTION_INVOICE_SUCCESS,
  props<{ response: any }>()
);

export const CreateCorrectionInvoiceFail = createAction(
  CREATE_CORRECTION_INVOICE_FAIL,
  props<{ response: any }>()
);


///////////////////////////////////////////////////////////////////// Read PDF

export const READ_PDF = '[Invoices Module] Correction Invoice as pdf';
export const READ_PDF_SUCCESS = '[Invoices Module] Correction Invoice as pdf Success';
export const READ_PDF_FAIL = '[Invoices Module] Correction Invoice as pdf Fail';

export const ReadPDF = createAction(
  READ_PDF,
  props<{ iri?: string }>()
);

export const ReadPDFSuccess = createAction(
  READ_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadPDFFail = createAction(
  READ_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CORRECTION_INVOICE = '[Invoices Module] Read Correction Invoice';
export const READ_CORRECTION_INVOICE_SUCCESS = '[Invoices Module] Read Correction Invoice Success';
export const READ_CORRECTION_INVOICE_FAIL = '[Invoices Module] Read Correction Invoice Fail';

export const ReadCorrectionInvoice = createAction(
  READ_CORRECTION_INVOICE,
  props<{ iri: string }>()
);

export const ReadCorrectionInvoiceSuccess = createAction(
  READ_CORRECTION_INVOICE_SUCCESS,
  props<{ response: fromModuleModels.CorrectionInvoice | any }>()
);

export const ReadCorrectionInvoiceFail = createAction(
  READ_CORRECTION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CORRECTION_INVOICES = '[Invoices Module] Read Correction Invoices';
export const READ_CORRECTION_INVOICES_SUCCESS = '[Invoices Module] Read Correction Invoices Success';
export const READ_CORRECTION_INVOICES_FAIL = '[Invoices Module] Read Correction Invoices Fail';

export const ReadCorrectionInvoices = createAction(
  READ_CORRECTION_INVOICES,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadCorrectionInvoicesSuccess = createAction(
  READ_CORRECTION_INVOICES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCorrectionInvoicesFail = createAction(
  READ_CORRECTION_INVOICES_FAIL,
  props<{ response: any }>()
);

export const READ_CORRECTION_INVOICE_CHUNK = '[Invoices Module] Read Correction Invoice Chunk';
export const READ_CORRECTION_INVOICE_CHUNK_SUCCESS = '[Invoices Module] Read Correction Invoice Chunk Success';
export const READ_CORRECTION_INVOICE_CHUNK_FAIL = '[Invoices Module] Read Correction Invoice Chunk Fail';

export const ReadCorrectionInvoiceChunk = createAction(
  READ_CORRECTION_INVOICE_CHUNK,
  props<{ uri: string }>()
);

export const ReadCorrectionInvoiceChunkSuccess = createAction(
  READ_CORRECTION_INVOICE_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadCorrectionInvoiceChunkFail = createAction(
  READ_CORRECTION_INVOICE_CHUNK_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// SendMail

export const SEND_MAIL = '[Invoices Module] Send Mail Correction Invoice ';
export const SEND_MAIL_SUCCESS = '[Invoices Module] Send Mail Correction Invoice Success';
export const SEND_MAIL_FAIL = '[Invoices Module] Send Mail Correction Invoice Fail';

export const SendMail = createAction(
  SEND_MAIL,
  props<{ iri: string, payload: InvoiceMail | any }>()
);

export const SendMailSuccess = createAction(
  SEND_MAIL_SUCCESS,
  props<{ response: CorrectionInvoice }>()
);

export const SendMailFail = createAction(
  SEND_MAIL_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Patch

export const BOOK_CORRECTION_INVOICE = '[Invoices Module] Book Correction Invoice ';
export const BOOK_CORRECTION_INVOICE_SUCCESS = '[Invoices Module] Book Correction Invoice Success';
export const BOOK_CORRECTION_INVOICE_FAIL = '[Invoices Module] Book Correction Invoice Fail';

export const BookCorrectionInvoice = createAction(
  BOOK_CORRECTION_INVOICE,
  props<{ iri: string }>()
);

export const BookCorrectionInvoiceSuccess = createAction(
  BOOK_CORRECTION_INVOICE_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const BookCorrectionInvoiceFail = createAction(
  BOOK_CORRECTION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const BOOK_CORRECTION_INVOICE_PAY_OUT = '[Invoices Module] Book Correction Invoice Pay Out';
export const BOOK_CORRECTION_INVOICE_PAY_OUT_SUCCESS = '[Invoices Module] Book Correction Invoice Pay Out Success';
export const BOOK_CORRECTION_INVOICE_PAY_OUT_FAIL = '[Invoices Module] Book Correction Invoice Pay Out Fail';

export const BookCorrectionInvoicePayOut = createAction(
  BOOK_CORRECTION_INVOICE_PAY_OUT,
  props<{ iri: string, payload: { payoutAt: string } }>()
);

export const BookCorrectionInvoicePayOutSuccess = createAction(
  BOOK_CORRECTION_INVOICE_PAY_OUT_SUCCESS,
  props<{ response: { '@context'?: string, '@id'?: string, '@type'?: string }, invoiceIri: string }>()
);

export const BookCorrectionInvoicePayOutFail = createAction(
  BOOK_CORRECTION_INVOICE_PAY_OUT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CORRECTION_INVOICE = '[Invoices Module] Update Correction Invoice';
export const UPDATE_CORRECTION_INVOICE_SUCCESS = '[Invoices Module] Update Correction Invoice Success';
export const UPDATE_CORRECTION_INVOICE_FAIL = '[Invoices Module] Update Correction Invoice Fail';

export const UpdateCorrectionInvoice = createAction(
  UPDATE_CORRECTION_INVOICE,
  props<{ iri: string, payload: fromModuleModels.CorrectionInvoice | any }>()
);

export const UpdateCorrectionInvoiceSuccess = createAction(
  UPDATE_CORRECTION_INVOICE_SUCCESS,
  props<{ response: fromModuleModels.CorrectionInvoice }>()
);

export const UpdateCorrectionInvoiceFail = createAction(
  UPDATE_CORRECTION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_CORRECTION_INVOICE = '[Invoices Module] Delete Correction Invoice';
export const DELETE_CORRECTION_INVOICE_SUCCESS = '[Invoices Module] Delete Correction Invoice Success';
export const DELETE_CORRECTION_INVOICE_FAIL = '[Invoices Module] Delete Correction Invoice Fail';

export const DeleteCorrectionInvoice = createAction(
  DELETE_CORRECTION_INVOICE,
  props<{ iri: string }>()
);

export const DeleteCorrectionInvoiceSuccess = createAction(
  DELETE_CORRECTION_INVOICE_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCorrectionInvoiceFail = createAction(
  DELETE_CORRECTION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_CORRECTION_INVOICE = '[Invoices Module] Reset Current Correction Invoice';

export const ResetCurrentCorrectionInvoice = createAction(
  RESET_CURRENT_CORRECTION_INVOICE
);

export const RESET_CORRECTION_INVOICES_LOADED = '[Invoices Module] Reset Correction Invoices Loaded';

export const ResetCorrectionInvoicesLoaded = createAction(
  RESET_CORRECTION_INVOICES_LOADED
);
