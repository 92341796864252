import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormsService} from '../../../shared/services';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Locale} from '../../../master-data/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';


@Component({
  selector: 'user-profile-form',
  styleUrls: ['user-profile-form.component.scss'],
  template: `

    <ng-container [formGroup]="upf">
      <div class="grid">
        <div class="column-7" *ngIf="this.upf.contains('firstName')">
          <mat-form-field>
            <mat-label>Vorname</mat-label>
            <input type="text" formControlName="firstName" matInput required>
            <mat-hint align="start" *ngIf="errors?.firstName">{{ errors.firstName.message }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="column-7" *ngIf="this.upf.contains('lastName')">
          <mat-form-field>
            <mat-label>Nachname</mat-label>
            <input type="text" formControlName="lastName" matInput required>
            <mat-hint align="start" *ngIf="errors?.lastName">{{ errors.lastName.message }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="column-14">
          <mat-form-field>
            <mat-label>E-Mail-Adresse</mat-label>
            <input type="email" formControlName="email" matInput required>
            <mat-hint align="start" *ngIf="errors?.email">{{ errors.email.message }}</mat-hint>
          </mat-form-field>
        </div>

        <div class="column-14" *ngIf="this.upf.contains('username')">
          <mat-form-field>
            <mat-label>Nutzername</mat-label>
            <input type="text" formControlName="username" matInput required>
            <mat-hint align="start" *ngIf="errors?.username">{{ errors.username.message }}</mat-hint>
          </mat-form-field>
        </div>

        <!-- Todo Locale -->

        <div class="mat-form-field submit m-ta--2 column-14">
          <button class="m-r--8" (click)="handleCancelUpdate()"
                  mat-flat-button color="outline">
            <mat-icon class="m-r--8">cancel</mat-icon>
            <span>Abbrechen</span>
          </button>

          <button [disabled]="upf.invalid || upf.pristine" (click)="handleRequestUpdatePassword()"
                  mat-flat-button color="green">Speichern
          </button>
        </div>
      </div>
    </ng-container>
  `
})
export class UserProfileFormComponent implements OnInit, OnDestroy {

  @Input() profile$: any;

  @Output()
  cancelEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  requestUpdateUserProfile: EventEmitter<{ iri: string, payload: any }> =
    new EventEmitter<{ iri: string, payload: any }>();

  errors: ErrorsObject;
  locales$: Observable<Array<Locale>>;
  onDestroy$: Subject<any> = new Subject<any>();
  upf: FormGroup;

  constructor(private fs: FormsService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();


    this.profile$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((presets) => {


      if (presets) {
        this.fs.patchForm(this.upf, presets);
        this.upf.markAsUntouched();
      }
    });
  }

  initForm(): void {
    // if (this.profile$.getValue()['@type'] === 'Administrator' || this.profile$.getValue()['@type'] === 'CustomerAccount') {
    this.upf = this.fb.group({
      firstName: this.fb.control(null, Validators.required),
      lastName: this.fb.control(null, Validators.required),
      username: this.fb.control(null, Validators.required),
      email: this.fb.control(null, Validators.required),
      locale: this.fb.control(null),
    });
    // } else {
    //   this.upf = this.fb.group({
    //     email: this.fb.control(null, [Validators.required]),
    //   });
    // }
  }

  handleCancelUpdate(): void {
    this.cancelEdit.emit();
  }

  handleRequestUpdatePassword(): void {
    this.requestUpdateUserProfile.emit({iri: this.profile$.getValue()['@id'], payload: this.upf.value});
  }


  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
