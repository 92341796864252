import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import {
  extractIri,
  ObjectsUtility
} from '../../../shared/utilities/objects.utility';
import {
  AnalysisPriorityMode,
  Currency,
  DataRecoveryPriorityMode,
  Department,
  Discount,
  DisposalType,
  LabLocation,
  ReplacementDataMediumSource
} from '../../../master-data/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  AnalysisPriorityModesSelectors,
  CurrenciesSelectors,
  DataRecoveryPriorityModesSelectors,
  DepartmentsSelectors,
  DiscountsSelectors,
  DisposalTypesSelectors,
  LabLocationsSelectors,
  ReplacementDataMediumSourcesSelectors
} from '../../../master-data/store/selectors';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {
  AnalysisPriorityModesActions,
  CurrenciesActions,
  DataRecoveryPriorityModesActions,
  DepartmentsActions,
  DiscountsActions,
  DisposalTypesActions,
  LabLocationsActions,
  ReplacementDataMediumSourcesActions
} from '../../../master-data/store';
import {
  Address,
  BillingAddress,
  Customer,
  CustomerAccount,
  CustomerContact,
  DeliveryAddress,
  PartnerWebsite
} from '../../../customers/models';
import {
  CustomerAccountsSelectors,
  CustomerAddressesSelectors,
  CustomerContactsSelectors,
  CustomersSelectors,
  PartnerWebsitesSelectors
} from '../../../customers/store/selectors';
import {
  CustomerAccountsActions,
  CustomerContactsActions,
  PartnerWebsitesActions
} from '../../../customers/store';
import { ShippingProvider } from '../../../shipping/models';
import { ShippingProvidersToCustomerSelectors } from '../../../shipping/store/selectors';
import { ShippingProvidersActions } from '../../../shipping/store';
import { Actions, ofType } from '@ngrx/effects';
import { OrdersActions } from '../../store';
import {
  convertNumberToFloatString,
  getUuidFromIri
} from '../../../shared/utilities/strings.utility';
import { removeEmptyFormElements } from '../../../shared/utilities/forms.utility';
import { combineLatestArray } from 'rxjs-etc';
import { LocationType, Order } from '../../models';
import { Administrator } from '../../../administrators/models';

@Component({
  selector: 'app-order-form',
  styleUrls: ['order-form.component.scss'],
  templateUrl: 'order-form.component.html'
})
export class OrderFormComponent implements OnInit, OnDestroy {
  customer: Customer;
  customerBillingAddresses: Array<BillingAddress>;
  customerContacts: Array<CustomerContact>;
  customerDeliveryAddresses: Array<DeliveryAddress>;

  analysisPriorityModes$: Observable<Array<AnalysisPriorityMode>>;
  analysisPriorityModesIsLoading$: Observable<boolean>;

  dataRecoveryPriorityModes$: Observable<Array<DataRecoveryPriorityMode>>;
  dataRecoveryPriorityModesIsLoading$: Observable<boolean>;

  departments$: Observable<Array<Department>>;
  departmentsIsLoading$: Observable<boolean>;

  brokers$: Observable<Array<Customer>>;
  brokersIsLoading$: Observable<boolean>;

  brokerContacts$: Observable<Array<CustomerContact | PartnerWebsite>>;
  brokerContactsIsLoading$: Observable<boolean>;

  discounts$: Observable<Array<Discount>>;
  discountsIsLoading$: Observable<boolean>;

  currencies$: Observable<Array<Currency>>;
  currenciesIsLoading$: Observable<boolean>;

  shippingProvidersToCustomer$: Observable<Array<ShippingProvider>>;
  shippingProvidersToCustomerIsLoading$: Observable<boolean>;

  disposalTypes$: Observable<Array<DisposalType>>;
  disposalTypesIsLoading$: Observable<boolean>;
  @Input() errors: ErrorsObject;

  labLocations$: Observable<Array<LabLocation>>;
  labLocationsIsLoading$: Observable<boolean>;

  @Input() order$: Observable<Order>;
  order: Order;
  @Input() administrators:
    | { [iri: string]: Administrator }
    | Array<{ [iri: string]: Administrator }>;
  replacementDataMediumSources$: Observable<Array<ReplacementDataMediumSource>>;
  replacementDataMediumSourcesIsLoading$: Observable<boolean>;
  standalone = true;

  @Output() requestUpdateOrder: EventEmitter<{
    iri: string;
    payload: Order;
  }> = new EventEmitter();
  @Output() requestUpdateLocation: EventEmitter<{
    locationType: LocationType;
    iri: string;
    payload: LabLocation;
  }> = new EventEmitter();

  technicalCustomerContacts: Array<CustomerContact>;
  organizationalCustomerContacts: Array<CustomerContact>;
  decisionMakerCustomerContacts: Array<CustomerContact>;

  of: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  selectedBroker: string;

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    public actions$: Actions
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleBrokerChange();

    this.administrators = Object.values(this.administrators);

    this.order$.pipe(takeUntil(this.onDestroy$)).subscribe((order: Order) => {
      this.order = order;

      const formData: any = { ...order };
      if (order.analysisPriorityMode && order.analysisPriorityMode['@id']) {
        formData.analysisPriorityMode = order.analysisPriorityMode['@id'];
      }
      if (
        order.dataRecoveryPriorityMode &&
        order.dataRecoveryPriorityMode['@id']
      ) {
        formData.dataRecoveryPriorityMode =
          order.dataRecoveryPriorityMode['@id'];
      }
      if (order.decisionMakerCustomerContact) {
        formData.decisionMakerCustomerContact = extractIri(
          order.decisionMakerCustomerContact
        );
      }
      if (order.organizationalCustomerContact) {
        formData.organizationalCustomerContact = extractIri(
          order.organizationalCustomerContact
        );
      }
      if (order.technicalCustomerContact) {
        formData.technicalCustomerContact = extractIri(
          order.technicalCustomerContact
        );
      }
      if (order.salesperson && order.salesperson["@id"]) {
        formData.salesperson = order?.salesperson["@id"];
      }
      if (order.technician) {
        formData.technician = order?.technician;
      }
      if (order.broker && order.broker['@id']) {
        formData.broker = order.broker['@id'];
      }
      if (order.brokerContact && order.brokerContact['@id']) {
        formData.brokerContact = order.brokerContact['@id'];
      }
      this.technicalCustomerContacts = [];
      this.organizationalCustomerContacts = [];
      this.decisionMakerCustomerContacts = [];
      this.readCustomerData();
      this.fs.patchForm(this.of, formData);
      this.of.markAsUntouched();
    });

    this.loadDepartments();
    this.loadDataRecoveryPriorityModes();
    this.loadDiscounts();
    this.loadCurrencies();
    this.loadAnalysisPriorityModes();
    this.loadShippingProvidersToCustomer();
    this.loadDisposalTypes();
    this.loadLabLocations();
    this.loadReplacementDataMediumSources();
    this.loadBrokerContacts();

    this.actions$
      .pipe(takeUntil(this.onDestroy$), ofType(OrdersActions.UpdateOrderFail))
      .subscribe(fail => {
        if (fail?.response?.error?.violations) {
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.of
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  readCustomerData(): void {
    this.store$
      .select(CustomersSelectors.selectCustomerByIndex, {
        iri: this.order.customer['@id']
      })
      .subscribe(customer => {
        if (!customer) {
          return;
        }
        this.customer = customer;
        this.store$.dispatch(
          CustomerContactsActions.ReadCustomerContacts({
            page: -1,
            params: { 'customer.uuid': getUuidFromIri(customer['@id']) }
          })
        );
        this.store$
          .select(
            CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri,
            { customerIri: customer['@id'] }
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(adresses => {
            this.customerBillingAddresses = adresses;
          });
        this.store$
          .select(
            CustomerAddressesSelectors.selectCustomerDeliveryAddressesByCustomerIri,
            { customerIri: customer['@id'] }
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(adresses => {
            this.customerDeliveryAddresses = adresses;
          });

        this.store$
          .select(
            CustomerContactsSelectors.selectCustomerContactsByCustomerIri,
            { customerIri: customer['@id'] }
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(customerContacts => {
            this.technicalCustomerContacts = customerContacts.filter(contact =>
              contact.customerContactTypes.includes(
                '/api/customer_contact_types/0'
              )
            );
            this.organizationalCustomerContacts = customerContacts.filter(
              contact =>
                contact.customerContactTypes.includes(
                  '/api/customer_contact_types/1'
                )
            );
            this.decisionMakerCustomerContacts = customerContacts.filter(
              contact =>
                contact.customerContactTypes.includes(
                  '/api/customer_contact_types/2'
                )
            );
          });
      });
  }

  cancelEdit(): void {
    this.initForm();
  }

  getFormattedAddress(address: Address): string {
    if (!address) {
      return '';
    }
    return `${address.line1} ${address.line2}${
      address.line3 ? ' ' + address.line3 : ''
    } ${address.zipPostcode}, ${address.city}`;
  }

  getCustomerName(customer: Customer): string {
    if (!customer) {
      return '';
    }
    let name = `${customer.nameLine1}`;
    if (customer.nameLine2) {
      name = `${name} [${customer.nameLine2}]`;
    }
    return name;
  }

  getStateName(obj): string {
    return ObjectsUtility.getObjectKeys(obj)[0];
  }

  initForm(): void {
    this.of = this.fb.group({
      billingAddress: this.fb.control(null),
      deliveryAddress: this.fb.control(null),
      broker: this.fb.control(null),
      salesperson: this.fb.control(null),
      technician: this.fb.control(null),
      brokerContact: this.fb.control(null),
      technicalCustomerContact: this.fb.control(null),
      organizationalCustomerContact: this.fb.control(null),
      decisionMakerCustomerContact: this.fb.control(null),
      additionalAgreements: this.fb.control(null),
      analysisLocation: this.fb.control({ value: null, disabled: true }),
      dataRecoveryLocation: this.fb.control({ value: null, disabled: true }),
      analysisPriorityMode: this.fb.control(null),
      dataRecoveryPriorityMode: this.fb.control(null),
      department: this.fb.control(null),
      discount: this.fb.control(null),
      specialDiscount: this.fb.control(null),
      disposalType: this.fb.control(null),
      downPayment: this.fb.control('0'),
      replacementDataMediumSource: this.fb.control(null),
      shippingProviderToCustomer: this.fb.control(null),
      willingnessToPay: this.fb.group({
        value: this.fb.control('0'),
        currency: this.fb.control('')
      })
    });
  }

  handleSubmit(): void {
    this.handleRequestUpdateOrder();
  }

  handleRequestUpdateOrder(): void {
    const formValue = removeEmptyFormElements({ ...this.of.value });

    // Always convert ints or floats from HTML number input to string; as per API;
    formValue.downPayment = convertNumberToFloatString(formValue.downPayment);

    // if (!!formValue.downPayment && (formValue.downPayment as number) !== 0) {
    //   formValue.downPayment = formValue.downPayment.toString();
    // }

    // Always convert ints or floats from HTML number input to string; as per API;
    if (
      !!formValue.specialDiscount &&
      (formValue.specialDiscount as number) !== 0
    ) {
      formValue.specialDiscount = formValue.specialDiscount.toString();
    }

    if (
      !!formValue.willingnessToPay.value &&
      (formValue.willingnessToPay.value as number) !== 0
    ) {
      formValue.willingnessToPay.value = formValue.willingnessToPay.value.toString();
    }

    this.requestUpdateOrder.emit({
      iri: this.order['@id'],
      payload: formValue
    });
  }

  handleBrokerChange(): void {
    this.of
      .get('broker')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(brokerId => {
        // const currentBrokerContact = this.of.get('brokerContact').value;
        this.of.get('brokerContact').setValue(null);

        this.selectedBroker = brokerId;
        this.brokerContacts$ = combineLatestArray(
          [
            this.store$.select(
              CustomerAccountsSelectors.selectCustomerAccountsByCustomerIri,
              { iri: this.selectedBroker ? this.selectedBroker : null }
            ),
            this.store$.select(PartnerWebsitesSelectors.selectPartnerWebsites)
          ],
          results => {
            const result = [];
            result.push(...results[0], ...results[1]);
            return result;
          }
        );
      });
  }

  findBroker(term: string, item: Customer): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (
        item.nameLine1.toLowerCase().indexOf(t.toLowerCase()) > -1 ||
        (item.nameLine2 &&
          item.nameLine2.toLowerCase().indexOf(t.toLowerCase()) > -1)
      );
    });
  }

  selectBroker(broker: Customer): void {
    this.of.patchValue({ broker: extractIri(broker) });
  }

  findBrokerContact(
    term: string,
    item: CustomerAccount | PartnerWebsite
  ): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (
        ('firstName' in item &&
          item.firstName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        ('lastName' in item &&
          item.lastName &&
          item.lastName.toLowerCase().indexOf(t.toLowerCase()) > -1) ||
        ('website' in item &&
          item.website &&
          item.website.toLowerCase().indexOf(t.toLowerCase()) > -1)
      );
    });
  }

  handleRequestUpdateLocation(
    locationType: LocationType,
    payload: LabLocation
  ): void {
    this.requestUpdateLocation.emit({
      locationType,
      iri: this.order['@id'],
      payload
    });
  }

  private loadDepartments(): void {
    this.departments$ = this.store$.select(
      DepartmentsSelectors.selectDepartments
    );
    this.departmentsIsLoading$ = this.store$.select(
      DepartmentsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DepartmentsSelectors.isLoaded,
      DepartmentsActions.ReadDepartments()
    );
  }

  private loadDataRecoveryPriorityModes(): void {
    this.dataRecoveryPriorityModes$ = this.store$.select(
      DataRecoveryPriorityModesSelectors.sList
    );
    this.dataRecoveryPriorityModesIsLoading$ = this.store$.select(
      DataRecoveryPriorityModesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes()
    );
  }

  private loadDiscounts(): void {
    this.discounts$ = this.store$.select(DiscountsSelectors.selectDiscounts);
    this.discountsIsLoading$ = this.store$.select(DiscountsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      DiscountsSelectors.isLoaded,
      DiscountsActions.ReadDiscounts()
    );
  }

  private loadCurrencies(): void {
    this.currencies$ = this.store$.select(CurrenciesSelectors.selectCurrencies);
    this.currenciesIsLoading$ = this.store$.select(
      CurrenciesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      CurrenciesSelectors.isLoaded,
      CurrenciesActions.ReadCurrencies()
    );
  }

  private loadAnalysisPriorityModes(): void {
    this.analysisPriorityModes$ = this.store$.select(
      AnalysisPriorityModesSelectors.selectAnalysisPriorityModes
    );
    this.analysisPriorityModesIsLoading$ = this.store$.select(
      AnalysisPriorityModesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
  }

  private loadShippingProvidersToCustomer(): void {
    this.shippingProvidersToCustomer$ = this.store$.select(
      ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomer
    );
    this.shippingProvidersToCustomerIsLoading$ = this.store$.select(
      ShippingProvidersToCustomerSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ShippingProvidersToCustomerSelectors.isLoaded,
      ShippingProvidersActions.ReadShippingProvidersToCustomer()
    );
  }

  private loadDisposalTypes(): void {
    this.disposalTypes$ = this.store$.select(
      DisposalTypesSelectors.selectDisposalTypes
    );
    this.disposalTypesIsLoading$ = this.store$.select(
      DisposalTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }

  private loadLabLocations(): void {
    this.labLocations$ = this.store$.select(LabLocationsSelectors.sList);
    this.labLocationsIsLoading$ = this.store$.select(
      LabLocationsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  private loadReplacementDataMediumSources(): void {
    this.replacementDataMediumSources$ = this.store$.select(
      ReplacementDataMediumSourcesSelectors.selectReplacementDataMediumSources
    );
    this.replacementDataMediumSourcesIsLoading$ = this.store$.select(
      ReplacementDataMediumSourcesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      ReplacementDataMediumSourcesSelectors.isLoaded,
      ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources()
    );
  }

  private loadBrokerContacts(): void {
    this.brokerContactsIsLoading$ = isLoadingArray([
      this.store$.select(CustomerAccountsSelectors.isLoading),
      this.store$.select(PartnerWebsitesSelectors.isLoading)
    ]);
    loadIfNotLoaded(
      this.store$,
      CustomerAccountsSelectors.isLoaded,
      CustomerAccountsActions.ReadCustomerAccounts({ page: -1 })
    );
    loadIfNotLoaded(
      this.store$,
      PartnerWebsitesSelectors.isLoaded,
      PartnerWebsitesActions.ReadPartnerWebsites({ page: -1 })
    );
  }
}
