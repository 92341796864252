import {Component, EventEmitter, Input, Output} from '@angular/core';

import {CorrectionInvoice} from '../../models';
import {RouterActions} from '../../../application-state/store/actions';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {extractUUID} from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-correction-invoices-list',
  styleUrls: ['correction-invoices-list.component.scss'],
  template: `
    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >
      <!-- State Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>

          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>
      <!-- Invoice Number -->
      <ng-container matColumnDef="correctionInvoiceNumber">
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">RE.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{element.correctionInvoiceNumber ? element.correctionInvoiceNumber : 'E'}}
        </td>
      </ng-container>

      <!-- Order Number -->
      <ng-container matColumnDef="orderNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Auftrag"
          style="width: 50px;"
        >
          A
        </th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{element?.correctedInvoice?.paymentProcess?.order ? element.correctedInvoice?.paymentProcess.order.orderNumber : ''}}
        </td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="recipient">
        <th mat-header-cell *matHeaderCellDef>Empfänger [Zusatz]</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.recipient.nameLine1 }}
          <ng-container *ngIf="element.recipient.nameLine2">
            [{{ element.recipient.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>

      <!-- Issuer Column -->
      <ng-container matColumnDef="issuer">
        <th mat-header-cell *matHeaderCellDef>Bereich</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.issuer.name }}</td>
      </ng-container>

      <!-- Net Total Column -->
      <ng-container matColumnDef="netTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (netto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.netTotal.value | number: '1.2-2' }}
          {{ element.netTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Gross Total Column -->
      <ng-container matColumnDef="grossTotal">
        <th mat-header-cell *matHeaderCellDef>Gesamtsumme (brutto)</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.grossTotal.value | number: '1.2-2' }}
          {{ element.grossTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Created At Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.createdAt ? (element.createdAt | date: 'dd.MM.Y') : '-' }}
        </td>
      </ng-container>

      <!-- Payout At Column -->
      <ng-container matColumnDef="payoutAt">
        <th mat-header-cell *matHeaderCellDef>Ausgezahlt am</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a class="stretched-link"
             [routerLink]="['/invoices', 'corrections', extractId(element)]"></a>
          {{ element.payoutAt ? (element.payoutAt | date: 'dd.MM.Y') : '-' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        [class.green]="element.state === 'booked'"
      ></tr>
    </table>
  `
})
export class CorrectionInvoicesListComponent {
  @Input() items: Array<CorrectionInvoice>;
  @Input() totalItems: number;

  @Output() requestGoToCorrectionInvoiceView: EventEmitter<string> = new EventEmitter();
  protected readonly extractId = extractUUID;

  displayedColumns: Array<string> = [
    'dot',
    'correctionInvoiceNumber',
    'orderNumber',
    'recipient',
    'issuer',
    'netTotal',
    'grossTotal',
    'createdAt',
    'payoutAt'
  ];

  constructor(private store$: Store<ApplicationState>) {
  }

  getCircleColorClass(element: CorrectionInvoice): string[] {
    if (element.state === 'booked' || element.state === 'paid_out') {
      return ['green'];
    } else {
      return ['grey'];
    }
  }
}
