import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';

@Injectable()
export class RolesService {
  constructor(private apiService: AbstractApiService) {}

  readRoles(): Observable<AbstractApiResponse | any> {
    return this.apiService.getObject(`/roles_hierarchies`);
  }
}
