import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { DataRecoveryProtocol } from '../models';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DataRecoveryProtocolsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readDataRecoveryProtocol(iri: string): Observable<DataRecoveryProtocol> {
    return this.rolesService.userHasRoleFilter<DataRecoveryProtocol>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readDataRecoveryProtocols(): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject('/data_recovery_protocols')
    );
  }

  updateDataRecoveryProtocol(
    iri: string,
    payload: DataRecoveryProtocol
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteDataRecoveryProtocol(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_DATA_RECOVERY_PROTOCOL_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
