import { createReducer, on } from '@ngrx/store';

import { EmailTemplatesActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities,
  removeEntity
} from '../../../shared/utilities/reducer.utility';
import { EmailTemplate } from '../../models';
import { UserRoleActions } from '../../../application-state/store/actions';
import * as fromMasterDataModuleModels from "../../models";
import {ErrorsObject} from "../../../shared/utilities/error-utility.utility";

type baseType = EmailTemplate;
const Actions = EmailTemplatesActions;

export interface State {
  entities: { [iri: string]: fromMasterDataModuleModels.EmailTemplate };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    Actions.CreateEmailTemplate,
    Actions.ReadEmailTemplate,
    Actions.ReadEmailTemplates,
    Actions.ReadEmailTemplatesChunk,
    Actions.UpdateEmailTemplate,
    Actions.DeleteEmailTemplate,
    state => ({
      ...state,
      loading: true
    })
  ),
  on(Actions.ReadEmailTemplateSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities([response], null, state.entities),
    loading: false,
    loaded: false
  })),
  on(Actions.ReadEmailTemplatesSuccess, Actions.ReadEmailTemplatesChunkSuccess, (state, { response }) => {
    const hasPagination = response.hasOwnProperty('hydra:view');
    const items = response['hydra:member'];
    const totalItems = response['hydra:totalItems'];
    let pagination = {};
    const entities = items.reduce(
      (
        entities: {
          [iri: string]: fromMasterDataModuleModels.EmailTemplate;
        },
        item
      ) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      // Note: Intentionally "clear" state when returning from single view or navigating tru list view
      {}
      // {...state.entities}
    );
    if (hasPagination) {
      pagination = {
        first: response['hydra:view']['hydra:first'],
        current: response['hydra:view']['@id'],
        last: response['hydra:view']['hydra:last']
      };
      console.log(pagination);
      if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
        pagination = {
          ...pagination,
          previous: response['hydra:view']['hydra:previous']
        };
      }

      if (response['hydra:view'].hasOwnProperty('hydra:next')) {
        pagination = {
          ...pagination,
          next: response['hydra:view']['hydra:next']
        };
      }
    }

    return {
      ...state,
      entities,
      pagination,
      totalItems,
      loading: false,
      loaded: true
    };
  }),
  on(Actions.CreateEmailTemplateSuccess, Actions.UpdateEmailTemplateSuccess, (state, { response }) => ({
    ...state,
    entities: mergeEntities([response], null, state.entities),
    loading: false
  })),
  on(Actions.DeleteEmailTemplateSuccess, (state, { iri }) => {
    return {
      ...state,
      entities: removeEntity(iri, state.entities),
      loading: false
    };
  }),
  on(
    Actions.CreateEmailTemplateFail,
    Actions.ReadEmailTemplateFail,
    Actions.ReadEmailTemplatesChunkFail,
    Actions.ReadEmailTemplatesFail,
    Actions.UpdateEmailTemplateFail,
    Actions.DeleteEmailTemplateFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
