import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import {InvoicePdfResponse} from '../../models/invoice-pdf-Response';
import {PayableInvoice, PayableInvoiceCopy} from '../../models';
import {InvoiceMail} from '../../models/invoice-mail.interface';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PAYABLE_INVOICE = '[Invoices Module] Create Payable Invoice';
export const CREATE_PAYABLE_INVOICE_FAIL = '[Invoices Module] Create Payable Invoice Fail';
export const CREATE_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Create Payable Invoice Success';

export const CreatePayableInvoice = createAction(
  CREATE_PAYABLE_INVOICE,
  props<{ payload: PayableInvoice }>()
);

export const CreatePayableInvoiceSuccess = createAction(
  CREATE_PAYABLE_INVOICE_SUCCESS,
  props<{ response: PayableInvoice }>()
);

export const CreatePayableInvoiceFail = createAction(
  CREATE_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create By Copieng

export const COPY_PAYABLE_INVOICE = '[Invoices Module] Copy Payable Invoice';
export const COPY_PAYABLE_INVOICE_FAIL = '[Invoices Module] Copy Payable Invoice Fail';
export const COPY_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Copy Payable Invoice Success';

export const CopyPayableInvoice = createAction(
  COPY_PAYABLE_INVOICE,
  props<{ iri: string, payload?: PayableInvoiceCopy }>()
);

export const CopyPayableInvoiceSuccess = createAction(
  COPY_PAYABLE_INVOICE_SUCCESS,
  props<{ response: PayableInvoice }>()
);

export const CopyPayableInvoiceFail = createAction(
  COPY_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CREATE_PAYABLE_INVOICE_FROM_INVOICE = '[Invoices Module] Create Payable Invoice From Invoice';
export const CREATE_PAYABLE_INVOICE_FROM_INVOICE_SUCCESS = '[Invoices Module] Create Payable Invoice From Invoice Success';
export const CREATE_PAYABLE_INVOICE_FROM_INVOICE_FAIL = '[Invoices Module] Create Payable Invoice From Invoice Fail';

export const CreatePayableInvoiceFromInvoice = createAction(
  CREATE_PAYABLE_INVOICE_FROM_INVOICE,
  props<{ payload: { finalInvoice: string, percentageOfFinalInvoice: string } }>()
);

export const CreatePayableInvoiceFromInvoiceSuccess = createAction(
  CREATE_PAYABLE_INVOICE_FROM_INVOICE_SUCCESS,
  props<{ response: PayableInvoice }>()
);

export const CreatePayableInvoiceFromInvoiceFail = createAction(
  CREATE_PAYABLE_INVOICE_FROM_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read PDF

export const READ_PAYABLE_INVOICE_PDF = '[Invoices Module] Payable Invoice as pdf';
export const READ_PAYABLE_INVOICE_PDF_SUCCESS = '[Invoices Module] Payable Invoice as pdf Success';
export const READ_PAYABLE_INVOICE_PDF_FAIL = '[Invoices Module] Payable Invoice as pdf Fail';

export const ReadPayableInvoicePDF = createAction(
  READ_PAYABLE_INVOICE_PDF,
  props<{ iri?: string }>()
);

export const ReadPayableInvoicePDFSuccess = createAction(
  READ_PAYABLE_INVOICE_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadPayableInvoicePDFFail = createAction(
  READ_PAYABLE_INVOICE_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read DownloadFirstReminder

export const DOWNLOAD_FIRST_REMINDER = '[Invoices Module] Download First Reminder as pdf';
export const DOWNLOAD_FIRST_REMINDER_SUCCESS = '[Invoices Module] Download First Reminder as pdf Success';
export const DOWNLOAD_FIRST_REMINDER_FAIL = '[Invoices Module] Download First Reminder as pdf Fail';

export const DownloadFirstReminder = createAction(
  DOWNLOAD_FIRST_REMINDER,
  props<{ iri?: string }>()
);

export const DownloadFirstReminderSuccess = createAction(
  DOWNLOAD_FIRST_REMINDER_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const DownloadFirstReminderFail = createAction(
  DOWNLOAD_FIRST_REMINDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
///////////////////////////////////////////////////////////////////// Read DownloadSecondReminder

export const DOWNLOAD_SECOND_REMINDER = '[Invoices Module] Download Second Reminder as pdf';
export const DOWNLOAD_SECOND_REMINDER_SUCCESS = '[Invoices Module] Download Second Reminder as pdf Success';
export const DOWNLOAD_SECOND_REMINDER_FAIL = '[Invoices Module] Download Second Reminder as pdf Fail';

export const DownloadSecondReminder = createAction(
  DOWNLOAD_SECOND_REMINDER,
  props<{ iri?: string }>()
);

export const DownloadSecondReminderSuccess = createAction(
  DOWNLOAD_SECOND_REMINDER_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const DownloadSecondReminderFail = createAction(
  DOWNLOAD_SECOND_REMINDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PAYABLE_INVOICE = '[Invoices Module] Read Payable Invoice';
export const READ_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Read Payable Invoice Success';
export const READ_PAYABLE_INVOICE_FAIL = '[Invoices Module] Read Payable Invoice Fail';

export const ReadPayableInvoice = createAction(
  READ_PAYABLE_INVOICE,
  props<{ iri: string }>()
);

export const ReadPayableInvoiceSuccess = createAction(
  READ_PAYABLE_INVOICE_SUCCESS,
  props<{ response: PayableInvoice | any }>()
);

export const ReadPayableInvoiceFail = createAction(
  READ_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_PAYABLE_INVOICES = '[Invoices Module] Read Payable Invoices';
export const READ_PAYABLE_INVOICES_SUCCESS = '[Invoices Module] Read Payable Invoices Success';
export const READ_PAYABLE_INVOICES_FAIL = '[Invoices Module] Read Payable Invoices Fail';

export const ReadPayableInvoices = createAction(
  READ_PAYABLE_INVOICES,
  props<{ page: number, params?: { [p: string]: number | string | boolean | string[] } }>()
);

export const ReadPayableInvoicesSuccess = createAction(
  READ_PAYABLE_INVOICES_SUCCESS,
  props<{ response: any }>()
);

export const ReadPayableInvoicesFail = createAction(
  READ_PAYABLE_INVOICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_PAYABLE_INVOICE_CHUNK = '[Invoices Module] Read Payable Invoice Chunk';
export const READ_PAYABLE_INVOICE_CHUNK_SUCCESS = '[Invoices Module] Read Payable Invoice Chunk Success';
export const READ_PAYABLE_INVOICE_CHUNK_FAIL = '[Invoices Module] Read Payable Invoice Chunk Fail';

export const ReadPayableInvoiceChunk = createAction(
  READ_PAYABLE_INVOICE_CHUNK,
  props<{ uri: string }>()
);

export const ReadPayableInvoiceChunkSuccess = createAction(
  READ_PAYABLE_INVOICE_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadPayableInvoiceChunkFail = createAction(
  READ_PAYABLE_INVOICE_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PAYABLE_INVOICE = '[Invoices Module] Update Payable Invoice';
export const UPDATE_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Update Payable Invoice Success';
export const UPDATE_PAYABLE_INVOICE_FAIL = '[Invoices Module] Update Payable Invoice Fail';

export const UpdatePayableInvoice = createAction(
  UPDATE_PAYABLE_INVOICE,
  props<{ iri: string, payload: PayableInvoice | any }>()
);

export const UpdatePayableInvoiceSuccess = createAction(
  UPDATE_PAYABLE_INVOICE_SUCCESS,
  props<{ response: PayableInvoice }>()
);

export const UpdatePayableInvoiceFail = createAction(
  UPDATE_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Mail

export const MAIL_PAYABLE_INVOICE = '[Invoices Module] Mail Payable Invoice';
export const MAIL_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Mail Payable Invoice Success';
export const MAIL_PAYABLE_INVOICE_FAIL = '[Invoices Module] Mail Payable Invoice Fail';

export const MailPayableInvoice = createAction(
  MAIL_PAYABLE_INVOICE,
  props<{ iri: string, payload: InvoiceMail | any }>()
);

export const MailPayableInvoiceSuccess = createAction(
  MAIL_PAYABLE_INVOICE_SUCCESS,
  props<{ response: PayableInvoice }>()
);

export const MailPayableInvoiceFail = createAction(
  MAIL_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Patch

export const BOOK_PAYABLE_INVOICE = '[Invoices Module] Book Payable Invoice';
export const BOOK_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Book Payable Invoice Success';
export const BOOK_PAYABLE_INVOICE_FAIL = '[Invoices Module] Book Payable Invoice Fail';

export const BookPayableInvoice = createAction(
  BOOK_PAYABLE_INVOICE,
  props<{ iri: string }>()
);

export const BookPayableInvoiceSuccess = createAction(
  BOOK_PAYABLE_INVOICE_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const BookPayableInvoiceFail = createAction(
  BOOK_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CANCEL_PAYABLE_INVOICE = '[Invoices Module] Cancel Payable Invoice';
export const CANCEL_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Cancel Payable Invoice Success';
export const CANCEL_PAYABLE_INVOICE_FAIL = '[Invoices Module] Cancel Payable Invoice Fail';

export const CancelPayableInvoice = createAction(
  CANCEL_PAYABLE_INVOICE,
  props<{ iri: string }>()
);

export const CancelPayableInvoiceSuccess = createAction(
  CANCEL_PAYABLE_INVOICE_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const CancelPayableInvoiceFail = createAction(
  CANCEL_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const BOOK_FULL_PAYMENT_RECEIVED = '[Invoices Module] Book Full Payment Received';
export const BOOK_FULL_PAYMENT_RECEIVED_SUCCESS = '[Invoices Module] Book Full Payment Received Success';
export const BOOK_FULL_PAYMENT_RECEIVED_FAIL = '[Invoices Module] Book Full Payment Received Fail';

export const BookFullPaymentReceived = createAction(
  BOOK_FULL_PAYMENT_RECEIVED,
  props<{ iri: string }>()
);

export const BookFullPaymentReceivedSuccess = createAction(
  BOOK_FULL_PAYMENT_RECEIVED_SUCCESS,
  props<{ invoiceIri: string }>()
);

export const BookFullPaymentReceivedFail = createAction(
  BOOK_FULL_PAYMENT_RECEIVED_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_PAYABLE_INVOICE = '[Invoices Module] Delete Payable Invoice';
export const DELETE_PAYABLE_INVOICE_SUCCESS = '[Invoices Module] Delete Payable Invoice Success';
export const DELETE_PAYABLE_INVOICE_FAIL = '[Invoices Module] Delete Payable Invoice Fail';

export const DeletePayableInvoice = createAction(
  DELETE_PAYABLE_INVOICE,
  props<{ iri: string }>()
);

export const DeletePayableInvoiceSuccess = createAction(
  DELETE_PAYABLE_INVOICE_SUCCESS,
  props<{ iri: string }>()
);

export const DeletePayableInvoiceFail = createAction(
  DELETE_PAYABLE_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_PAYABLE_INVOICE = '[Invoices Module] Reset Current Payable Invoice';

export const ResetCurrentPayableInvoice = createAction(
  RESET_CURRENT_PAYABLE_INVOICE
);

export const RESET_PAYABLE_INVOICES_LOADED = '[Invoices Module] Reset Payable Invoices Loaded';

export const ResetIsLoaded = createAction(
  RESET_PAYABLE_INVOICES_LOADED
);
