import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import * as fromMasterDataModuleModels from "../../models";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormsService} from "../../../shared/services";
import {takeUntil} from "rxjs/operators";
import {EmailTemplateCategory} from "../../models";

@Component({
  selector: 'app-email-template-categories-form',
  styleUrls: ['./email-template-categories-form.component.scss'],
  template: `
    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Email-Vorlagen Kategorie bearbeiten' : 'Neue Email-Vorlagen Kategorie anlegen' }}</span>
      </div>

      <div class="card__content p-a--24">

        <div class="grid" [formGroup]="form">
          <mat-form-field class="column-11">
            <mat-label>Name</mat-label>
            <input type="text" formControlName="name" matInput required>
          </mat-form-field>

          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || form.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="form.invalid || form.untouched"
                    (click)="handleSubmit()"
                    mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  `
})
export class EmailTemplateCategoriesFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() presets$: BehaviorSubject<EmailTemplateCategory>;

  @Output() requestCreateItem: EventEmitter<{ payload: EmailTemplateCategory, entity: string }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{ iri: string, payload: EmailTemplateCategory, entity: string }> = new EventEmitter();
  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.initForm();

  }

  ngAfterViewInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(presets => {
      this.initForm();
      this.fs.patchForm(this.form, presets);
      this.fs.resetFormErrors(this.form);
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: EmailTemplateCategory = this.form.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'EmailTemplateCategory'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'EmailTemplateCategory'});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
