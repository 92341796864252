import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ApplicationState } from '../../../application-state/store';
import { Order } from '../../../orders/models';
import {
  BindingOrdersSelectors,
  DataRecoveryCostsSelectors,
  DataRecoveryProtocolsSelectors,
  DataRecoveryResultsSelectors,
  OrderCommentsSelectors,
  OrdersSelectors
} from '../../../orders/store/selectors';
import { TransitionsSelectors } from '../../../application-state/store/selectors';
import { isLoadingArray } from '../../utilities/observable.utility';
import { TicketsSelectors } from '../../../tickets/store/selectors';
import { TransitionsActions } from '../../../application-state/store/actions';
import { PotentialAction } from '../../models';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

const TRANSITIONS = [
  {name: 'to_incoming_dm_delayed', isPassive: true},
  {name: 'dm_ok_logistics_to_dm_in_engineering_department', isPassive: false},
  {name: 'dm_nok_logistics_to_dm_in_engineering_department', isPassive: false},
  {name: 'to_dm_ok_logistics', isPassive: true},
  {name: 'delayed_to_dm_ok_logistics', isPassive: false},
  {name: 'to_dm_ok_engineering_department', isPassive: true},
  {name: 'to_dm_nok_engineering_department', isPassive: true},
  {name: 'dm_nok_engineering_department_to_assign_analysis', isPassive: true},
  {name: 'create_final_invoice_to_wait_for_payment', isPassive: false},
  {name: 'create_final_invoice', isPassive: false},
  {name: 'assign_analysis_to_prepare_analysis_dr_de', isPassive: true},
  {name: 'assign_analysis_to_prepare_analysis_dr_de_remote', isPassive: true},
  {name: 'assign_analysis_to_prepare_analysis_dr_ext', isPassive: true},
  {name: 'prepare_analysis_dr_de_to_analysis_in_progress_dr_de', isPassive: false},
  {name: 'analysis_dm_received_dr_ext_to_analysis_in_progress_dr_ext', isPassive: false},
  {name: 'access_data_provided_analysis_dr_remote_to_analysis_in_progress_dr_de_remote', isPassive: false},
  {name: 'analysis_in_progress_dr_de_to_analysis_completed_dr_de', isPassive: false},
  {name: 'analysis_in_progress_dr_de_remote_to_analysis_completed_dr_de_remote', isPassive: false},
  {name: 'analysis_in_progress_dr_ext_to_analysis_completed_dr_ext', isPassive: false},
  {name: 'analysis_provide_purchase_prices_dr_ext_to_analysis_purchase_prices_provided_dr_ext', isPassive: false},
  {name: 'analysis_provide_purchase_prices_dr_remote_to_analysis_purchase_prices_provided_dr_remote', isPassive: false},
  {name: 'analysis_provide_purchase_prices_dr_de_to_analysis_purchase_prices_provided_dr_de', isPassive: false},
  {name: 'data_recovery_provide_purchase_prices_dr_de_to_data_recovery_purchase_prices_provided_dr_de', isPassive: false},
  {name: 'data_recovery_provide_selling_prices_dr_remote_to_data_recovery_selling_prices_provided_dr_remote', isPassive: false},
  {name: 'data_recovery_provide_selling_prices_dr_ext_to_data_recovery_selling_prices_provided_dr_ext', isPassive: false},
  {name: 'data_recovery_provide_selling_prices_dr_de_to_data_recovery_selling_prices_provided_dr_de', isPassive: false},
  {name: 'provide_access_data_analysis_dr_remote_to_access_data_provided_analysis_dr_remote', isPassive: false},
  {name: 'dm_right_placed_provide_access_data_dr_dr_remote_to_dm_right_placed_access_data_provided_dr_dr_remote', isPassive: false},
  {name: 'analysis_completed_dr_de_to_analysis_ok_dr_de', isPassive: false},
  {name: 'analysis_completed_dr_de_to_analysis_nok_dr_de', isPassive: false},
  {name: 'analysis_single_shipping_dr_ext_to_analysis_dm_received_dr_ext', isPassive: false},
  {name: 'analysis_bundled_shipping_dr_ext_to_analysis_dm_received_dr_ext', isPassive: false},
  {name: 'analysis_purchase_prices_provided_dr_remote_to_analysis_ok_dr_de_remote', isPassive: false},
  {name: 'analysis_purchase_prices_provided_dr_remote_to_analysis_nok_dr_de_remote', isPassive: false},
  {name: 'analysis_purchase_prices_provided_dr_ext_to_analysis_ok_dr_ext', isPassive: false},
  {name: 'analysis_purchase_prices_provided_dr_ext_to_analysis_nok_dr_ext_trigger_shipping', isPassive: false},
  {name: 'analysis_nok_dr_ext_trigger_shipping_to_analysis_nok_dr_ext_shipping_in_progress', isPassive: false},
  {name: "remote_appointment_unnecessary_data_nok_trigger_shipping_to_remote_appointment_unnecessary_data_nok_shipping_in_progress", isPassive: false},
  {name: "disposal_trigger_shipping_to_disposal_shipping_in_progress", isPassive: false},
  {name: "dm_wrong_placed_trigger_shipping_dr_dr_de_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_de", isPassive: false},
  {name: "dm_wrong_placed_trigger_shipping_dr_dr_remote_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_remote", isPassive: false},
  {name: "dm_wrong_placed_nat_dr_de_trigger_shipping_dr_dr_ext_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext", isPassive: false},
  {name: "analysis_nok_dr_de_to_assign_analysis", isPassive: false},
  {name: "analysis_nok_dr_de_delayed_to_assign_analysis", isPassive: false},
  {name: "analysis_nok_dr_de_remote_to_assign_analysis", isPassive: false},
  {name: "analysis_nok_dr_de_remote_delayed_to_assign_analysis", isPassive: false},
  {name: "analysis_nok_dr_ext_check_dm_to_assign_analysis", isPassive: false},
  {name: "analysis_nok_dr_ext_check_dm_delayed_to_assign_analysis", isPassive: false},
  {name: "create_customer_analysis_to_customer_analysis_created", isPassive: false},
  {name: "customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_de", isPassive: false},
  {name: "customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_remote", isPassive: false},
  {name: "customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_ext", isPassive: false},
  {name: "waiting_for_bo_to_bo_sent", isPassive: false},
  {name: "bo_sent_to_bo_received_via_email", isPassive: false},
  {name: "bo_delayed_to_bo_received_via_email", isPassive: false},
  {name: "create_partial_invoice_to_partial_invoice_created", isPassive: false},
  {name: "waiting_for_data_recovery_dr_de_to_data_recovery_in_progress_dr_de", isPassive: false},
  {name: "data_recovery_delayed_dr_de_to_data_recovery_in_progress_dr_de", isPassive: false},
  {name: "waiting_for_data_recovery_dr_remote_to_data_recovery_in_progress_dr_remote", isPassive: false},
  {name: "waiting_for_data_recovery_dr_remote_to_data_recovery_in_progress_dr_remote_with_check_purchase_prices", isPassive: false},
  {name: "data_recovery_delayed_dr_remote_to_data_recovery_in_progress_dr_remote", isPassive: false},
  {name: "data_recovery_delayed_dr_remote_to_data_recovery_in_progress_dr_remote_with_check_purchase_prices", isPassive: false},
  {name: "waiting_for_data_recovery_dr_ext_to_data_recovery_in_progress_dr_ext", isPassive: false},
  {name: "waiting_for_data_recovery_dr_ext_to_data_recovery_in_progress_dr_ext_with_check_purchase_prices", isPassive: false},
  {name: "data_recovery_delayed_dr_ext_to_data_recovery_in_progress_dr_ext", isPassive: false},
  {name: "data_recovery_delayed_dr_ext_to_data_recovery_in_progress_dr_ext_with_check_purchase_prices", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_de_to_remote_appointment_necessary", isPassive: false},
  {name: "data_recovery_check_purchase_prices_to_data_recovery_purchase_prices_checked", isPassive: false},
  {name: "data_recovery_in_progress_dr_de_to_data_recovery_completed_dr_de", isPassive: false},
  {name: 'data_recovery_in_progress_dr_ext_to_data_recovery_completed_dr_ext_with_purchase_prices_checked', isPassive: false},
  {name: "data_recovery_in_progress_dr_remote_to_data_recovery_completed_dr_remote", isPassive: false},
  {name: "data_recovery_in_progress_dr_ext_to_data_recovery_completed_dr_ext", isPassive: false},
  {name: "provide_data_recovery_result_dr_de_to_data_recovery_result_provided_dr_de", isPassive: false},
  {name: "provide_data_recovery_result_dr_remote_to_data_recovery_result_provided_dr_remote", isPassive: false},
  {name: "provide_data_recovery_result_dr_ext_to_data_recovery_result_provided_dr_ext", isPassive: false},
  {name: "data_recovery_result_provided_dr_de_to_data_recovery_successful_dr_de", isPassive: false},
  {name: "data_recovery_result_provided_dr_de_to_data_recovery_unsuccessful_dr_de", isPassive: false},
  {name: "data_recovery_result_provided_dr_remote_to_data_recovery_successful_dr_remote", isPassive: false},
  {name: "data_recovery_result_provided_dr_remote_to_data_recovery_unsuccessful_dr_remote", isPassive: false},
  {name: "data_recovery_result_provided_dr_ext_to_data_recovery_successful_dr_ext", isPassive: false},
  {name: "data_recovery_result_provided_dr_ext_to_data_recovery_unsuccessful_dr_ext", isPassive: false},
  {name: "disposal_provide_data_recovery_protocol_to_disposal_data_recovery_protocol_provided", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_de_to_remote_appointment_unnecessary", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_remote_to_remote_appointment_necessary", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_remote_to_remote_appointment_unnecessary", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_ext_to_remote_appointment_necessary", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_ext_to_remote_appointment_unnecessary", isPassive: false},

  {name: "remote_appointment_necessary_to_remote_appointment_data_ok", isPassive: false},
  {name: "remote_appointment_necessary_to_remote_appointment_data_nok", isPassive: false},

  {name: "remote_appointment_data_nok_dr_de_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_data_nok_dr_de_to_provide_disposal_type", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_de_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_de_to_provide_disposal_type", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_remote_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_remote_to_provide_disposal_type", isPassive: false},
  {name: "remote_appointment_data_nok_dr_remote_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_data_nok_dr_remote_to_provide_disposal_type", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_ext_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_unnecessary_dr_ext_to_provide_disposal_type", isPassive: false},
  {name: "remote_appointment_data_nok_dr_ext_to_assign_analysis", isPassive: false},
  {name: "remote_appointment_data_nok_dr_ext_to_provide_disposal_type", isPassive: false},

  {name: "provide_disposal_type_to_disposal_type_provided", isPassive: false},

  {name: "data_recovery_successful_dr_de_to_disposal_destroy_dm", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_de_to_disposal_destroy_dm", isPassive: false},
  {name: "data_recovery_successful_dr_remote_to_disposal_destroy_dm", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_remote_to_disposal_destroy_dm", isPassive: false},
  {name: "data_recovery_successful_dr_ext_to_disposal_destroy_dm", isPassive: false},
  {name: "data_recovery_unsuccessful_dr_ext_to_disposal_destroy_dm", isPassive: false},

  {name: "disposal_destroy_dm_to_disposal_dm_destroyed", isPassive: false},

  {name: "disposal_provide_proof_of_destruction_to_disposal_proof_of_destruction_provided", isPassive: false},

  {name: "data_recovery_successful_dr_de_disposal_data_recovery_protocol_provided_to_copy_data", isPassive: false},
  {name: "data_recovery_successful_dr_de_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available", isPassive: false},
  {name: "data_recovery_successful_dr_remote_disposal_data_recovery_protocol_provided_to_copy_data", isPassive: false},
  {name: "data_recovery_successful_dr_remote_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available", isPassive: false},
  {name: "data_recovery_successful_dr_ext_disposal_data_recovery_protocol_provided_to_copy_data", isPassive: false},
  {name: "data_recovery_successful_dr_ext_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available", isPassive: false},

  {name: "replacement_dm_not_available_to_copy_data", isPassive: false},

  {name: "copy_data_to_data_copied", isPassive: false},

  {name: "data_copied_to_data_checking_successful", isPassive: false},

  {name: "data_checking_successful_to_replacement_dm_ready_for_return_shipment", isPassive: false},

  {name: "wait_for_payment_to_payment_received", isPassive: false},
  {name: "wait_for_payment_to_first_reminder", isPassive: false},
  {name: "first_reminder_to_second_reminder", isPassive: false},
  {name: "first_reminder_to_payment_received", isPassive: false},
  {name: "second_reminder_to_debt_collection", isPassive: false},
  {name: "second_reminder_to_payment_received", isPassive: false},
  {name: "debt_collection_to_payment_received", isPassive: false},
  {name: "waiting_for_down_payment_to_down_payment_received", isPassive: false},
  {name: "waiting_for_down_payment_to_down_payment_delayed", isPassive: false},
  {name: "down_payment_delayed_to_down_payment_received", isPassive: false},


  {name: "prepare_analysis_dr_ext_to_analysis_single_shipping_dr_ext", isPassive: false},
  {name: "prepare_analysis_dr_ext_to_analysis_bundled_shipping_dr_ext", isPassive: false},
  {name: "dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext_to_dm_wrong_placed_shipping_in_progress_to_dr_ext_dr_dr_ext", isPassive: false},

  {name: "return_shipment_to_order_closed", isPassive: false},

  {name: "payment_received_to_order_closed", isPassive: false},
  {name: "payment_received_to_return_shipment", isPassive: false},

  {name: "check_return_shipment_necessary_to_order_closed", isPassive: false},
  {name: "check_return_shipment_necessary_to_return_shipment", isPassive: false},

  {name: "no_order_to_create_final_invoice", isPassive: false},
  {name: "no_order_to_check_return_shipment_necessary", isPassive: false},

  {name: "_to_dm_right_placed_dr_dr_ext", isPassive: true},

  {name: "dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext_to_dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext", isPassive: false},
]
@Component({
  selector: 'app-action-box',
  styleUrls: ['action-box.component.scss'],
  template: `
    <div
      *ngIf="order"
      class="pos-relative show-on-hover"
      style="min-height: 30px;"
    >
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <!-- Actions that do not have explicit transitions -->
      <h2 *ngIf="showAllBoxes">TOWareHouse</h2>
      <app-action-box-go-to-warehouse
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['to_incoming_dm_delayed'] ||
          showToWarehouse()
        "
      ></app-action-box-go-to-warehouse>
      <!--actions with explicit actionBox SPECIAL,passive Actions -->

      <!--<app-action-box-to-incoming-dm-delayed
        *ngIf="transitionChecks['to_incoming_dm_delayed') && showPassiveActions"
        [order$]="order$"
      ></app-action-box-to-incoming-dm-delayed>-->

      <!--regular Actions -->

      <!-- OK Box: 0001-->
      <h2 *ngIf="showAllBoxes">0001</h2>
      <app-action-box-to-dm-in-engineering-department
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'dm_ok_logistics_to_dm_in_engineering_department'
          ] ||
          transitionChecks[
            'dm_nok_logistics_to_dm_in_engineering_department'
          ]
        "
      ></app-action-box-to-dm-in-engineering-department>

      <!-- OK Box: 0002 -- Soll aktuell nicth verwendet werden, sondern
      über Logstik-->
      <h2 *ngIf="showAllBoxes">0002</h2>
      <app-action-box-to-dm-ok-logistic
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['to_dm_ok_logistics'] ||
          transitionChecks['delayed_to_dm_ok_logistics']
        "
      ></app-action-box-to-dm-ok-logistic>

      <!-- OK Box: 0003 to_dm_ok_engineering_department -->
      <h2 *ngIf="showAllBoxes">0003</h2>
      <app-action-box-to-dm-ok-engineering-department
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks['to_dm_ok_engineering_department'] &&
            transitionChecks['to_dm_nok_engineering_department'])
        "
      ></app-action-box-to-dm-ok-engineering-department>

      <!-- OK Box: 0051 dm_nok_engineering_department_to_assign_analysis -->
      <h2 *ngIf="showAllBoxes">0051</h2>
      <app-action-box-to-assign-analysis
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['dm_nok_engineering_department_to_assign_analysis']
        "
      ></app-action-box-to-assign-analysis>

      <!-- OK Box: 0004 & 0044 -->
      <h2 *ngIf="showAllBoxes">0004 & 0044</h2>
      <app-action-box-to-invoice-create-final-invoice
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          orderStateContains('create_final_invoice') ||
          transitionChecks['create_final_invoice_to_wait_for_payment']
        "
      ></app-action-box-to-invoice-create-final-invoice>

      <!-- Box: 0005 -->
      <h2 *ngIf="showAllBoxes">0005</h2>
      <app-action-box-assign-analysis-location
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          showSelectAnalysisLocation() ||
          transitionChecks[
            'assign_analysis_to_prepare_analysis_dr_de'
         ] ||
          transitionChecks[
            'assign_analysis_to_prepare_analysis_dr_de_remote'
          ] ||
          transitionChecks['assign_analysis_to_prepare_analysis_dr_ext']
        "
      ></app-action-box-assign-analysis-location>

      <!--TODO  Box: 0006 && 0013 TODO: wann soll es ausgegraut werden?-->
      <h2 *ngIf="showAllBoxes">0006 && 0013</h2>
      <app-action-box-to-analysis-in-progress
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'prepare_analysis_dr_de_to_analysis_in_progress_dr_de'
          ] ||
          transitionChecks[
            'analysis_dm_received_dr_ext_to_analysis_in_progress_dr_ext'
          ] ||
          transitionChecks[
            'access_data_provided_analysis_dr_remote_to_analysis_in_progress_dr_de_remote'
          ]
        "
      ></app-action-box-to-analysis-in-progress>

      <!--BOX UNDEFINED Bestätigung des Eingehens eine Datenmediums bei DR-Ext -->
      <h2 *ngIf="showAllBoxes">
        UNDEFINED (Bestätigung des Eingehens eine Datenmediums bei DR-Ext)
      </h2>
      <app-action-box-to-analysis-dm-received-dr-ext
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'analysis_single_shipping_dr_ext_to_analysis_dm_received_dr_ext'
          ] ||
          transitionChecks[
            'analysis_bundled_shipping_dr_ext_to_analysis_dm_received_dr_ext'
          ]
        "
      ></app-action-box-to-analysis-dm-received-dr-ext>

      <!-- OK Box: 0007 -->
      <h2 *ngIf="showAllBoxes">0007</h2>
      <app-action-box-to-analysis-completed
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'analysis_in_progress_dr_de_to_analysis_completed_dr_de'
          ] ||
          transitionChecks[
            'analysis_in_progress_dr_de_remote_to_analysis_completed_dr_de_remote'
          ] ||
          transitionChecks[
            'analysis_in_progress_dr_ext_to_analysis_completed_dr_ext'
          ]
        "
      ></app-action-box-to-analysis-completed>

      <!-- Box: 0014 -->
      <h2 *ngIf="showAllBoxes">0014</h2>
      <app-action-box-to-analysis-purchase-prices
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'analysis_provide_purchase_prices_dr_ext_to_analysis_purchase_prices_provided_dr_ext'
          ] ||
          transitionChecks[
            'analysis_provide_purchase_prices_dr_remote_to_analysis_purchase_prices_provided_dr_remote'
          ] ||
          transitionChecks[
            'analysis_provide_purchase_prices_dr_de_to_analysis_purchase_prices_provided_dr_de'
          ]
        "
      ></app-action-box-to-analysis-purchase-prices>

      <!-- Box: 0014a -->
      <h2 *ngIf="showAllBoxes">0014a (asynchron)</h2>
      <app-action-box-data-recovery-costs-checked-by-supervisor
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="showAllBoxes || isAdmin"
      ></app-action-box-data-recovery-costs-checked-by-supervisor>
      <!-- Box: 0014c -->
      <h2 *ngIf="showAllBoxes">0014c</h2>
      <app-action-box-to-data-recovery-purchase-prices-provides-dr-de
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_recovery_provide_purchase_prices_dr_de_to_data_recovery_purchase_prices_provided_dr_de'
          ]
        "
      ></app-action-box-to-data-recovery-purchase-prices-provides-dr-de>

      <!-- OK Box: 0018 -->
      <h2 *ngIf="showAllBoxes">0018</h2>
      <app-action-box-to-analysis-selling-prices
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_recovery_provide_selling_prices_dr_remote_to_data_recovery_selling_prices_provided_dr_remote'
          ] ||
          transitionChecks[
            'data_recovery_provide_selling_prices_dr_ext_to_data_recovery_selling_prices_provided_dr_ext'
          ] ||
          transitionChecks[
            'data_recovery_provide_selling_prices_dr_de_to_data_recovery_selling_prices_provided_dr_de'
          ]
        "
      ></app-action-box-to-analysis-selling-prices>

      <!-- OK Box: 0009 -->
      <h2 *ngIf="showAllBoxes">0009</h2>
      <app-action-box-to-access-data-provided
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'provide_access_data_analysis_dr_remote_to_access_data_provided_analysis_dr_remote'
          ] ||
          transitionChecks[
            'dm_right_placed_provide_access_data_dr_dr_remote_to_dm_right_placed_access_data_provided_dr_dr_remote'
          ]
        "
      ></app-action-box-to-access-data-provided>

      <!-- OK Box: 0015 -->
      <h2 *ngIf="showAllBoxes">0015</h2>
      <app-action-box-to-analysis-ok
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'analysis_completed_dr_de_to_analysis_ok_dr_de'
          ] &&
            transitionChecks[
              'analysis_completed_dr_de_to_analysis_nok_dr_de'
            ]) ||
          (transitionChecks[
            'analysis_purchase_prices_provided_dr_remote_to_analysis_ok_dr_de_remote'
          ] &&
            transitionChecks[
              'analysis_purchase_prices_provided_dr_remote_to_analysis_nok_dr_de_remote'
            ]) ||
          (transitionChecks[
            'analysis_purchase_prices_provided_dr_ext_to_analysis_ok_dr_ext'
          ] &&
            transitionChecks[
              'analysis_purchase_prices_provided_dr_ext_to_analysis_nok_dr_ext_trigger_shipping'
            ])
        "
      ></app-action-box-to-analysis-ok>

      <!-- TODO Box: 0019 && 0024  NOCH zu Testen AUFTRENNEN!!!! AKTUELL -->
      <h2 *ngIf="showAllBoxes">0019 && 0024</h2>
      <app-action-box-return-shipment-general
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'remote_appointment_unnecessary_data_nok_trigger_shipping_to_remote_appointment_unnecessary_data_nok_shipping_in_progress'
          ] ||
          transitionChecks[
            'disposal_trigger_shipping_to_disposal_shipping_in_progress'
          ] ||
          transitionChecks[
            'dm_wrong_placed_trigger_shipping_dr_dr_de_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_de'
          ] ||
          transitionChecks[
            'dm_wrong_placed_trigger_shipping_dr_dr_remote_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_remote'
          ] ||
          transitionChecks[
            'dm_wrong_placed_nat_dr_de_trigger_shipping_dr_dr_ext_to_dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext'
          ]
        "
      ></app-action-box-return-shipment-general>

      <!-- OK Box: 0052  analysis_nok_dr_de_to_assign_analysis -->
      <h2 *ngIf="showAllBoxes">0052</h2>
      <app-action-box-analysis-nok-to-assign-analysis
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['analysis_nok_dr_de_to_assign_analysis'] ||
          transitionChecks[
            'analysis_nok_dr_de_delayed_to_assign_analysis'
          ] ||
          transitionChecks[
            'analysis_nok_dr_de_remote_to_assign_analysis'
          ] ||
          transitionChecks[
            'analysis_nok_dr_de_remote_delayed_to_assign_analysis'
          ] ||
          transitionChecks[
            'analysis_nok_dr_ext_check_dm_to_assign_analysis'
          ] ||
          transitionChecks[
            'analysis_nok_dr_ext_check_dm_delayed_to_assign_analysis'
          ]
        "
      ></app-action-box-analysis-nok-to-assign-analysis>

      <!-- OK Box: 0010 -->
      <h2 *ngIf="showAllBoxes">0010</h2>
      <app-action-box-to-customer-analysis-created
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'create_customer_analysis_to_customer_analysis_created'
          ]
        "
      ></app-action-box-to-customer-analysis-created>

      <!-- OK Box: 0020 -->
      <h2 *ngIf="showAllBoxes">0020</h2>
      <app-action-box-to-prepare-dr
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          showSelectDataRecoveryLocation() ||
          transitionChecks[
            'customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_de'
          ] ||
          transitionChecks[
            'customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_remote'
          ] ||
          transitionChecks[
            'customer_analysis_created_to_data_recovery_provide_purchase_prices_dr_ext'
          ]
        "
      ></app-action-box-to-prepare-dr>

      <!-- OK Box: 0021 -->
      <h2 *ngIf="showAllBoxes">0021</h2>
      <app-action-box-to-bo-sent
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes || transitionChecks['waiting_for_bo_to_bo_sent']
        "
      ></app-action-box-to-bo-sent>

      <!-- OK Box: 0022 -->
      <h2 *ngIf="showAllBoxes">0022</h2>
      <app-action-box-to-bo-received-via-email
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          showBoUploadableAction() ||
          transitionChecks['bo_sent_to_bo_received_via_email'] ||
          transitionChecks['bo_delayed_to_bo_received_via_email']
        "
      ></app-action-box-to-bo-received-via-email>

      <!-- OK Box: 0053 -->
      <h2 *ngIf="showAllBoxes">0053</h2>
      <app-action-box-goto-autocreated-partial-invoice
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'create_partial_invoice_to_partial_invoice_created'
          ]
        "
      ></app-action-box-goto-autocreated-partial-invoice>

      <!-- OK Box: 0030 -->
      <h2 *ngIf="showAllBoxes">0030</h2>
      <app-action-box-to-data-recovery-in-progress
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'waiting_for_data_recovery_dr_de_to_data_recovery_in_progress_dr_de'
          ] ||
          transitionChecks[
            'data_recovery_delayed_dr_de_to_data_recovery_in_progress_dr_de'
          ] ||
          transitionChecks[
            'waiting_for_data_recovery_dr_remote_to_data_recovery_in_progress_dr_remote'
          ] ||
          transitionChecks[
            'waiting_for_data_recovery_dr_remote_to_data_recovery_in_progress_dr_remote_with_check_purchase_prices'
          ] ||
          transitionChecks[
            'data_recovery_delayed_dr_remote_to_data_recovery_in_progress_dr_remote'
          ] ||
          transitionChecks[
            'data_recovery_delayed_dr_remote_to_data_recovery_in_progress_dr_remote_with_check_purchase_prices'
          ] ||
          transitionChecks[
            'waiting_for_data_recovery_dr_ext_to_data_recovery_in_progress_dr_ext'
          ] ||
          transitionChecks[
            'waiting_for_data_recovery_dr_ext_to_data_recovery_in_progress_dr_ext_with_check_purchase_prices'
          ] ||
          transitionChecks[
            'data_recovery_delayed_dr_ext_to_data_recovery_in_progress_dr_ext'
          ] ||
          transitionChecks[
            'data_recovery_delayed_dr_ext_to_data_recovery_in_progress_dr_ext_with_check_purchase_prices'
          ]
        "
      ></app-action-box-to-data-recovery-in-progress>
      <!-- OK Box: 0031 -->
      <h2 *ngIf="showAllBoxes">0031</h2>
      <app-action-box-to-data-recovery-completed
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_recovery_in_progress_dr_de_to_data_recovery_completed_dr_de'
          ] ||
          transitionChecks[
            'data_recovery_in_progress_dr_remote_to_data_recovery_completed_dr_remote'
          ] ||
          transitionChecks[
            'data_recovery_in_progress_dr_ext_to_data_recovery_completed_dr_ext'
          ] ||
          transitionChecks[
            'data_recovery_in_progress_dr_ext_to_data_recovery_completed_dr_ext_with_purchase_prices_checked'
          ]
        "
      ></app-action-box-to-data-recovery-completed>

      <!-- OK Box: 0032 -->
      <h2 *ngIf="showAllBoxes">0032</h2>
      <app-action-box-to-data-recovery-provided
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'provide_data_recovery_result_dr_de_to_data_recovery_result_provided_dr_de'
          ] ||
          transitionChecks[
            'provide_data_recovery_result_dr_remote_to_data_recovery_result_provided_dr_remote'
          ] ||
          transitionChecks[
            'provide_data_recovery_result_dr_ext_to_data_recovery_result_provided_dr_ext'
          ]
        "
      ></app-action-box-to-data-recovery-provided>

      <!-- OK Box: 0033 -->
      <h2 *ngIf="showAllBoxes">0033</h2>
      <app-action-box-to-data-recovery-successful
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'data_recovery_result_provided_dr_de_to_data_recovery_successful_dr_de'
          ] &&
            transitionChecks[
              'data_recovery_result_provided_dr_de_to_data_recovery_unsuccessful_dr_de'
            ]) ||
          (transitionChecks[
            'data_recovery_result_provided_dr_remote_to_data_recovery_successful_dr_remote'
          ] &&
            transitionChecks[
              'data_recovery_result_provided_dr_remote_to_data_recovery_unsuccessful_dr_remote'
            ]) ||
          (transitionChecks[
            'data_recovery_result_provided_dr_ext_to_data_recovery_successful_dr_ext'
          ] &&
            transitionChecks[
              'data_recovery_result_provided_dr_ext_to_data_recovery_unsuccessful_dr_ext'
            ])
        "
      ></app-action-box-to-data-recovery-successful>

      <!-- OK Box: 0054 -->
      <h2 *ngIf="showAllBoxes">0054</h2>
      <app-action-box-to-remote-appointment-necessary
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'data_recovery_unsuccessful_dr_de_to_remote_appointment_necessary'
          ] &&
            transitionChecks[
              'data_recovery_unsuccessful_dr_de_to_remote_appointment_unnecessary'
            ]) ||
          (transitionChecks[
            'data_recovery_unsuccessful_dr_remote_to_remote_appointment_necessary'
          ] &&
            transitionChecks[
              'data_recovery_unsuccessful_dr_remote_to_remote_appointment_unnecessary'
            ]) ||
          (transitionChecks[
            'data_recovery_unsuccessful_dr_ext_to_remote_appointment_necessary'
          ] &&
            transitionChecks[
              'data_recovery_unsuccessful_dr_ext_to_remote_appointment_unnecessary'
            ])
        "
      ></app-action-box-to-remote-appointment-necessary>

      <!-- OK Box: 0055 -->
      <h2 *ngIf="showAllBoxes">0055</h2>
      <app-action-box-to-remote-appointment-data-ok
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'remote_appointment_necessary_to_remote_appointment_data_ok'
          ] ||
          transitionChecks[
            'remote_appointment_necessary_to_remote_appointment_data_nok'
          ]
        "
      ></app-action-box-to-remote-appointment-data-ok>

      <!-- OK Box: 0056 -->
      <h2 *ngIf="showAllBoxes">0056</h2>
      <app-action-box-to-provide-disposal-type
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'remote_appointment_data_nok_dr_de_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_data_nok_dr_de_to_provide_disposal_type'
            ]) ||
          (transitionChecks[
            'remote_appointment_unnecessary_dr_de_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_unnecessary_dr_de_to_provide_disposal_type'
            ]) ||
          (transitionChecks[
            'remote_appointment_unnecessary_dr_remote_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_unnecessary_dr_remote_to_provide_disposal_type'
           ]) ||
          (transitionChecks[
            'remote_appointment_data_nok_dr_remote_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_data_nok_dr_remote_to_provide_disposal_type'
            ]) ||
          (transitionChecks[
            'remote_appointment_unnecessary_dr_ext_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_unnecessary_dr_ext_to_provide_disposal_type'
            ]) ||
          (transitionChecks[
            'remote_appointment_data_nok_dr_ext_to_assign_analysis'
          ] &&
            transitionChecks[
              'remote_appointment_data_nok_dr_ext_to_provide_disposal_type'
            ])
        "
      ></app-action-box-to-provide-disposal-type>

      <!-- OK Box: 0034 -->
      <h2 *ngIf="showAllBoxes">0034</h2>
      <app-action-box-to-disposal-type-provided
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'provide_disposal_type_to_disposal_type_provided'
          ]
        "
      ></app-action-box-to-disposal-type-provided>

      <!-- OK Box: 0035-->
      <h2 *ngIf="showAllBoxes">0035</h2>
      <app-action-box-to-disposal-destroy-dm
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_recovery_successful_dr_de_to_disposal_destroy_dm'
          ] ||
          transitionChecks[
            'data_recovery_unsuccessful_dr_de_to_disposal_destroy_dm'
          ] ||
          transitionChecks[
            'data_recovery_successful_dr_remote_to_disposal_destroy_dm'
          ] ||
          transitionChecks[
            'data_recovery_unsuccessful_dr_remote_to_disposal_destroy_dm'
          ] ||
          transitionChecks[
            'data_recovery_successful_dr_ext_to_disposal_destroy_dm'
          ] ||
          transitionChecks[
            'data_recovery_unsuccessful_dr_ext_to_disposal_destroy_dm'
          ]
        "
      ></app-action-box-to-disposal-destroy-dm>

      <!-- OK Box: 0057 -->
      <h2 *ngIf="showAllBoxes">0057</h2>
      <app-action-box-to-disposal-dm-destroyed
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'disposal_destroy_dm_to_disposal_dm_destroyed'
          ]
        "
      ></app-action-box-to-disposal-dm-destroyed>

      <!-- OK Box: 0036 -->
      <h2 *ngIf="showAllBoxes">0036</h2>
      <app-action-box-to-disposal-proof-of-destruction-provided
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'disposal_provide_proof_of_destruction_to_disposal_proof_of_destruction_provided'
          ]
        "
      ></app-action-box-to-disposal-proof-of-destruction-provided>

      <!-- OK Box: 0038 -->
      <h2 *ngIf="showAllBoxes">0038</h2>
      <app-action-box-to-disposal-data-recovery-protocol-provided
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'disposal_provide_data_recovery_protocol_to_disposal_data_recovery_protocol_provided'
          ]
        "
      ></app-action-box-to-disposal-data-recovery-protocol-provided>

      <!-- OK Box: 0037  -->
      <h2 *ngIf="showAllBoxes">0037</h2>
      <app-action-box-to-copy-data
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'data_recovery_successful_dr_de_disposal_data_recovery_protocol_provided_to_copy_data'
          ] &&
            transitionChecks[
              'data_recovery_successful_dr_de_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available'
            ]) ||
          (transitionChecks[
            'data_recovery_successful_dr_remote_disposal_data_recovery_protocol_provided_to_copy_data'
          ] &&
            transitionChecks[
              'data_recovery_successful_dr_remote_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available'
            ]) ||
          (transitionChecks[
            'data_recovery_successful_dr_ext_disposal_data_recovery_protocol_provided_to_copy_data'
          ] &&
            transitionChecks[
              'data_recovery_successful_dr_ext_disposal_data_recovery_protocol_provided_to_replacement_dm_not_available'
            ])
        "
      ></app-action-box-to-copy-data>

      <!-- OK Box: 0037(b) -->
      <h2 *ngIf="showAllBoxes">0037(b)</h2>

      <app-action-box-replacement-dm-not-available-to-copy-data
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['replacement_dm_not_available_to_copy_data']
        "
      ></app-action-box-replacement-dm-not-available-to-copy-data>

      <!-- OK Box: 0041 -->
      <h2 *ngIf="showAllBoxes">0041</h2>
      <app-action-box-copy-data-to-data-copied
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes || transitionChecks['copy_data_to_data_copied']
        "
      ></app-action-box-copy-data-to-data-copied>

      <!-- OK Box: 0042 -->
      <h2 *ngIf="showAllBoxes">0042</h2>
      <app-action-box-data-copied-to-data-checkin-successful
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['data_copied_to_data_checking_successful']
        "
      ></app-action-box-data-copied-to-data-checkin-successful>

      <!-- OK Box: 0043  -->
      <h2 *ngIf="showAllBoxes">0043</h2>
      <app-action-box-data-checkin-successful-to-replacement-dm-ready-for-return-shipment
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_checking_successful_to_replacement_dm_ready_for_return_shipment'
          ]
        "
      ></app-action-box-data-checkin-successful-to-replacement-dm-ready-for-return-shipment>

      <!-- OK Box: 0044
            <h2 *ngIf="showAllBoxes">0044</h2>
<app-action-box-create-final-invoice-to-wait-for-payment
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="showAllBoxes ||
transitionChecks['create_final_invoice_to_wait_for_payment')"></app-action-box-create-final-invoice-to-wait-for-payment>-->

      <!-- OK Box: 0058 -->
      <h2 *ngIf="showAllBoxes">0058</h2>
      <app-action-box-wait-for-payment
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['wait_for_payment_to_payment_received'] ||
          transitionChecks['wait_for_payment_to_first_reminder'] ||
          transitionChecks['first_reminder_to_second_reminder'] ||
          transitionChecks['first_reminder_to_payment_received'] ||
          transitionChecks['second_reminder_to_debt_collection'] ||
          transitionChecks['second_reminder_to_payment_received'] ||
          transitionChecks['debt_collection_to_payment_received'] ||
          transitionChecks[
            'waiting_for_down_payment_to_down_payment_received'
          ] ||
          transitionChecks[
            'waiting_for_down_payment_to_down_payment_delayed'
          ] ||
          transitionChecks[
            'down_payment_delayed_to_down_payment_received'
         ]
        "
      ></app-action-box-wait-for-payment>
      <!-- OK Box: 0059 -->
      <h2 *ngIf="showAllBoxes">0059</h2>
      <app-action-box-shipment-to-dr-ext
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'prepare_analysis_dr_ext_to_analysis_single_shipping_dr_ext'
          ] ||
          transitionChecks[
            'prepare_analysis_dr_ext_to_analysis_bundled_shipping_dr_ext'
          ] ||
          transitionChecks[
            'dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext_to_dm_wrong_placed_shipping_in_progress_to_dr_ext_dr_dr_ext'
          ]
        "
      ></app-action-box-shipment-to-dr-ext>

      <!-- OK Box: 00060 -->
      <h2 *ngIf="showAllBoxes">0060</h2>
      <app-action-box-to-return-shipment
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['return_shipment_to_order_closed']
        "
      ></app-action-box-to-return-shipment>

      <!-- OK Box: 00061 -->
      <h2 *ngIf="showAllBoxes">00061</h2>
      <app-action-box-to-order-closed
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks['payment_received_to_order_closed'] &&
            !transitionChecks['payment_received_to_return_shipment'])
        "
      ></app-action-box-to-order-closed>
      <!-- OK Box: 00062 -->
      <h2 *ngIf="showAllBoxes">00062</h2>
      <app-action-box-from-check-return-shipment-necessary
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks[
            'check_return_shipment_necessary_to_order_closed'
          ] &&
            transitionChecks[
              'check_return_shipment_necessary_to_return_shipment'
            ])
        "
      ></app-action-box-from-check-return-shipment-necessary>
      <!-- OK Box: 00063 -->
      <h2 *ngIf="showAllBoxes">00063</h2>
      <app-action-box-final-invoice-needed
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks['no_order_to_create_final_invoice'] &&
            transitionChecks[
              'no_order_to_check_return_shipment_necessary'
            ])
        "
      ></app-action-box-final-invoice-needed>

      <!-- OK Box: 00061b -->
      <h2 *ngIf="showAllBoxes">00061b</h2>
      <app-action-box-to-order-closed-or-return-shipment
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          (transitionChecks['payment_received_to_order_closed'] &&
            transitionChecks['payment_received_to_return_shipment'])
        "
      ></app-action-box-to-order-closed-or-return-shipment>
      <!-- OK Box: 00064 -->
      <h2 *ngIf="showAllBoxes">00064</h2>
      <app-action-box-shipment-received-dr-ext
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks['_to_dm_right_placed_dr_dr_ext']
        "
      ></app-action-box-shipment-received-dr-ext>

      <!-- OK Box: 0066 -->
      <h2 *ngIf="showAllBoxes">0066</h2>
      <app-action-box-to-data-recovery-purchase-price-checked
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'data_recovery_check_purchase_prices_to_data_recovery_purchase_prices_checked'
          ]
        "
      ></app-action-box-to-data-recovery-purchase-price-checked>

      <!-- OK Box: dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext_to_dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext -->
      <h2 *ngIf="showAllBoxes">
        dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext_to_dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext
      </h2>
      <app-action-box-shipment-on-the-way-to-dr-de
        [order$]="order$"
        [inputType]="inputType"
        *ngIf="
          showAllBoxes ||
          transitionChecks[
            'dm_wrong_placed_shipping_in_progress_to_dr_de_dr_dr_ext_to_dm_wrong_placed_at_dr_de_trigger_shipping_dr_dr_ext'
          ]
        "
      ></app-action-box-shipment-on-the-way-to-dr-de>

      <h2 *ngIf="showAllBoxes">open-shipment-info</h2>
      <app-action-box-open-shipment-info
        [order$]="order$"
        [inputType]="inputType"
      ></app-action-box-open-shipment-info>

      <ng-container *ngFor="let action of potentialActions">
        <div class="row" *ngIf="showActions">
          <div class="col-11">
            <pre>{{ action | json }}</pre>
          </div>
          <div class="col-1" *ngIf="showActions">
            <button
              mat-icon-button
              (click)="makeTransition(action)"
              [disabled]="action.error"
            >
              <mat-icon>play_circle_filled</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <div class="hover inline-block-bottom-center" *ngIf="isAdmin">
        <div
          class="text-center pe-2 cursor-pointer "
          (click)="showActions = !showActions"
        >
          Debug {{ !showActions ? 'anzeigen' : 'verstecken' }}.
        </div>
      </div>
    </div>
  `
})
export class ActionBoxComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;
  order: Order;
  showActions = false;
  // for Debug
  showAllBoxes = false;
  potentialActions: any = [];
  onDestroy$: Subject<any> = new Subject<any>();
  isLoading$: Observable<boolean>;
  transitionChecks: { [key: string]: boolean } = {};

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService
  ) {
    this.isLoading$ = isLoadingArray([
      this.store$.select(DataRecoveryCostsSelectors.isLoading),
      this.store$.select(DataRecoveryResultsSelectors.isLoading),
      this.store$.select(DataRecoveryProtocolsSelectors.isLoading),
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(BindingOrdersSelectors.isLoading),
      this.store$.select(TicketsSelectors.isLoading),
      this.store$.select(OrderCommentsSelectors.isLoading),
      this.store$.select(TransitionsSelectors.isLoading)
    ]);
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.potentialActions = order.potentialActions?.filter(
          action => action.workflow === 'order_state'
        );
        // this.findPotentialAction();
        // this.prepareFormInputOutputs();
        this.order = order;
        TRANSITIONS.map(transition => {
          this.transitionChecks[transition.name] = this.potentialActionsContain(transition.name, transition.isPassive)
        })
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  showToWarehouse(): boolean {
    if (!this.order) {
      return false;
    }
    const potentialActionWithError = this.order?.potentialActions?.filter(
      e => e?.error?.detail === '/: Still waiting for inbound shipment'
    );

    return (
      potentialActionWithError?.length > 0 ||
      this.order?.rawState?.indexOf('new') > -1
    );
  }
  potentialActionsContain(transition: string, partial: boolean = false): boolean {
    console.log(this.order?.potentialActions);
    if (!this.order?.potentialActions) {
     return false;
    } else if (partial) {
      return this.order.potentialActions.some(action => (!action.error || action.error.detail === '/: Access Denied') && action.transition.includes(transition));
    } else {
      return this.transitionChecks[transition] = this.order.potentialActions.some(action => (!action.error || action.error.detail === '/: Access Denied') && action.transition === transition);
    }
  }

  showSelectAnalysisLocation(): boolean {
    const potentialActionWithError = this.order?.potentialActions?.filter(
      e => e?.error?.detail === '/: No analysis lab location selected'
    );

    return (
      potentialActionWithError?.length > 0 && !this.order?.analysisLocation
    );
  }

  showSelectDataRecoveryLocation(): boolean {
    const potentialActionWithError = this.order?.potentialActions?.filter(
      e => e?.error?.detail === '/: No data recovery lab location selected'
    );

    return (
      potentialActionWithError?.length > 0 && !this.order?.dataRecoveryLocation
    );
  }

  showBoUploadableAction(): boolean {
    const potentialActionWithError = this.order?.potentialActions?.filter(
      e =>
        e?.error?.detail === '/: There is no received or uploaded binding order'
    );

    return potentialActionWithError?.length > 0;
  }

  makeTransition(action: PotentialAction): void {
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  orderStateContains(stateName: string, partial = false): boolean {
    if (partial) {
      return !!this.order?.rawState?.find(
        state => state.indexOf(stateName) > -1
      );
    } else {
      return this.order?.rawState?.indexOf(stateName) > -1;
    }
  }
}
