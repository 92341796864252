import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { ReplacementStockItem } from '../models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class ReplacementStockItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createReplacementStockItem(
    payload: ReplacementStockItem
  ): Observable<ReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<ReplacementStockItem>(
      'ROLE_MWS_REPLACEMENT_STOCK_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/replacement_stock_items`, payload)
    );
  }

  getReplacementStockItem(iri: string): Observable<ReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<ReplacementStockItem>(
      'ROLE_MWS_REPLACEMENT_STOCK_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getReplacementStockItems(
    page: number,
    params: any
  ): Observable<ReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<ReplacementStockItem>(
      'ROLE_MWS_REPLACEMENT_STOCK_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/replacement_stock_items', page, params)
            )
    );
  }

  updateReplacementStockItem(
    iri: string,
    payload: ReplacementStockItem
  ): Observable<ReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<ReplacementStockItem>(
      'ROLE_MWS_REPLACEMENT_STOCK_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteReplacementStockItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_REPLACEMENT_STOCK_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
