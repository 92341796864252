export const CustomerDataMedium: Array<{ value: string, label: string }> = [
  {value: 'stored_dr_de', label: 'Eingelagert DR'},
  {value: 'stored_dr_ext', label: 'Eingelagert DR EXT'},
  {value: 'technical_department_dr_de', label: 'Liegt in Technik'},
  {value: 'in_shipment_dr_de_to_dr_ext', label: 'Im Versand zu DR EXT'},
  {value: 'in_shipment_dr_de_to_customer', label: 'Im Versand zum Kunden'},
  {value: 'in_shipment_dr_ext_to_dr_de', label: 'Im Versand von DR Ext nach DR'},
  {value: 'destroyed', label: 'Vernichtet'},
  {value: 'delivered_to_customer', label: 'Bei Kunde eingegangen'},
];
export const EnclosureDataMedium: Array<{ value: string, label: string }> = [
  {value: 'stored_dr_de', label: 'Eingelagert DR'},
  {value: 'technical_department_dr_de', label: 'Liegt in Technik'},
  {value: 'in_shipment_dr_de_to_customer', label: 'Im Versand zum Kunden'},
  {value: 'delivered_to_customer', label: 'Bei Kunde eingegangen'},
];
export const ReplacementDataMedium: Array<{ value: string, label: string }> = [
  {value: 'stored', label: 'Eingelagert'},
  {value: 'reserved', label: 'Reserviert'},
  {value: 'waiting_for_return_shipment', label: 'Warte auf Rückversand'},
  {value: 'in_shipment_dr_de_to_customer', label: 'Im Versand zum Kunden'},
  {value: 'delivered_to_customer', label: 'Bei Kunde eingegangen'},
];

export const CustomerDataMediumEntities: { [key: string]: { value: string, label: string } } = CustomerDataMedium.reduce((previousValue, currentValue) => {
  previousValue[currentValue.value] = currentValue;
  return previousValue;
}, {});
export const EnclosureDataMediumEntities: { [key: string]: { value: string, label: string } } = EnclosureDataMedium.reduce((previousValue, currentValue) => {
  previousValue[currentValue.value] = currentValue;
  return previousValue;
}, {});
export const ReplacementDataMediumEntities: { [key: string]: { value: string, label: string } } = ReplacementDataMedium.reduce((previousValue, currentValue) => {
  previousValue[currentValue.value] = currentValue;
  return previousValue;
}, {});

export const DataMediumStates = [...CustomerDataMedium, ...EnclosureDataMedium, ...ReplacementDataMedium];
export const DataMediumStatesEntities: { [key: string]: { value: string, label: string } } = DataMediumStates.reduce((previousValue, currentValue) => {
  previousValue[currentValue.value] = currentValue;
  return previousValue;
}, {});
