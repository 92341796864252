import { createAction, props } from '@ngrx/store';

export const USER_ROLE_START =
  '[Application State Module] Start testing for User Role';

export const UserRoleCheckStart = createAction(
  USER_ROLE_START,
  props<{ role: string | string[] }>()
);
export const USER_ROLE_SUCCESS =
  '[Application State Module] User Role check successful (user has right role)';

export const UserRoleCheckSuccess = createAction(
  USER_ROLE_SUCCESS,
  props<{ role: string | string[] }>()
);
export const USER_ROLE_FAIL =
  '[Application State Module] User Role check failed (user did not have right role)';

export const UserRoleCheckFail = createAction(
  USER_ROLE_FAIL,
  props<{ role: string | string[] }>()
);
