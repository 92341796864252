import { createSelector } from '@ngrx/store';

// Provider Import
import {
  FOLLOW_UPS_FEATURE_KEY,
  selectDashboardModuleState
} from '../reducers';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FollowUp } from '../../models';

export const selectFollowUpsState = createSelector(
  selectDashboardModuleState,
  state => state[FOLLOW_UPS_FEATURE_KEY]
);

export const selectFollowUpsEntities = createSelector(
  selectFollowUpsState,
  state => state.entities
);

export const selectFollowUps = createSelector(
  selectFollowUpsEntities,
  entities => Object.values(entities)
);
export const selectNotDeletedFollowUps = createSelector(
  selectFollowUps,
  entities => entities.filter(e => e.deletedAt === null)
);
export const selectFollowUpsByDate = createSelector(
  selectNotDeletedFollowUps,
  (entities, momentDate: Moment) =>
    entities
      .filter((e: FollowUp) => moment(e.date).isSame(momentDate, 'date'))
      .sort(
        (a, b): number =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
);
export const UNDELETE_TIMEOUT = 30; // sec

export const lastReversibleFollowUp = createSelector(selectFollowUps, list => {
  return list
    .filter(
      e =>
        e.deletedAt !== null &&
        new Date().getTime() - new Date(e.deletedAt).getTime() <
          UNDELETE_TIMEOUT * 1000
    )
    .sort(
      (a, b): number => new Date(a.date).getTime() - new Date(b.date).getTime()
    )[0];
});
export const selectFollowUpsOpen = createSelector(
  selectNotDeletedFollowUps,
  entities =>
    entities
      .filter((e: FollowUp) =>
        moment(e.date).isSameOrBefore(moment().subtract(1, 'day'), 'date')
      )
      .sort(
        (a, b): number =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
);

export const selectFollowUpDetail = createSelector(
  selectFollowUpsState,
  (state: any, { iri }) => state.detailEntities[iri]
);

export const selectFormErrors = createSelector(
  selectFollowUpsState,
  state => state.errors
);

export const isLoading = createSelector(
  selectFollowUpsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectFollowUpsState,
  state => state.loaded
);
