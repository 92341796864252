import {createSelector} from '@ngrx/store';

import {CUSTOMER_ACCOUNT_INVITATIONS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerAccountInvitationsState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_ACCOUNT_INVITATIONS_FEATURE_KEY]
);

export const selectCustomerAccountInvitationEntities = createSelector(
  selectCustomerAccountInvitationsState,
  (state) => state.entities
);

export const selectCustomerAccountInvitationByIndex = createSelector(
  selectCustomerAccountInvitationEntities,
  (contacts, index: string) => contacts[index]
);

export const selectCustomerAccountInvitations = createSelector(
  selectCustomerAccountInvitationEntities,
  (contacts) => Object.keys(contacts).map(iri => contacts[iri])
);

export const selectCustomerAccountInvitationsByCustomerIri = createSelector(
  selectCustomerAccountInvitations,
  (contacts, props) => contacts.filter(contact => contact.customer == props.iri)
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCustomerAccountInvitationsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCustomerAccountInvitationsState,
  state => state.loaded
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Error

export const selectErrors = createSelector(
  selectCustomerAccountInvitationsState,
  (state) => state.errors
);
