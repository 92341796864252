import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {CustomerCooperationsSelectors} from '../../store/selectors';
import {CustomerCooperationsActions} from '../../store';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {CustomerCooperation} from '../../models';

@Component({
  selector: 'app-customer-cooperation-select',
  styleUrls: ['./customer-cooperation-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerCooperationSelectComponent),
    multi: true
  }],
  template: `
    <div [formGroup]="form">

      <ng-select
        formControlName="selectedElement"
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        placeholder="Kooperation">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
    </div>
  `
})
export class CustomerCooperationSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<CustomerCooperation> = new EventEmitter<CustomerCooperation>();

  items$: Observable<Array<CustomerCooperation>>;
  isLoading$: Observable<boolean>;
  selectedItem: CustomerCooperation;
  onDestroy$: Subject<any> = new Subject<any>();

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });

    this.items$ = this.store$.select(CustomerCooperationsSelectors.selectCustomerCooperations);
    this.isLoading$ = this.store$.select(CustomerCooperationsSelectors.isLoading);
    loadIfNotLoaded(this.store$, CustomerCooperationsSelectors.isLoaded, CustomerCooperationsActions.ReadCustomerCooperations());
  }

  setSelectedItem(element: CustomerCooperation): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: CustomerCooperation): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }
}
