import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import * as fromModuleModels from '../../models';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'file-system-form',
  styleUrls: ['file-system-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Dateisystem bearbeiten' : 'Neues Dateisystem anlegen' }}</span>
      </div>

      <div class="card__content">
        <form [formGroup]="fsf" (ngSubmit)="handleSubmit()">

          <div class="grid">
            <div class="column-11">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" required>
                <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="column-3">
              <mat-slide-toggle formControlName="isActive" [checked]="true">Aktiv</mat-slide-toggle>
            </div>
            <div class="m-ta--2 column-14">

              <button *ngIf="presets$.getValue() || fsf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                      color="outline" mat-flat-button>
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button [disabled]="fsf.invalid || fsf.untouched" mat-flat-button color="green">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  `
})
export class FileSystemFormComponent implements OnInit {

  @Input()
  errors: ErrorsObject;

  @Input()
  presets$: BehaviorSubject<any>;

  @Output()
  requestCreateItem: EventEmitter<{ payload: fromModuleModels.FileSystem, entity: string }>
    = new EventEmitter<{ payload: fromModuleModels.FileSystem, entity: string }>();

  @Output()
  requestUpdateItem: EventEmitter<{ iri: string, payload: fromModuleModels.FileSystem, entity: string }>
    = new EventEmitter<{ iri: string, payload: fromModuleModels.FileSystem, entity: string }>();

  fsf: FormGroup;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.fsf, preset);
      this.fsf.markAsUntouched();
    });
  }

  initForm() {
    this.fsf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      isActive: this.fb.control(false, [Validators.required])
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromModuleModels.FileSystem = this.fsf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'FileSystem'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'FileSystem'});
  }
}
