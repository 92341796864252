import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';

@Component({
  selector: 'app-waiting-for-dm-widget',
  styleUrls: ['./waiting-for-dm-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div
          class="widget_header d-flex align-items-center justify-content-between ps-1"
        >
          <span
            ><button mat-icon-button (click)="loadData()">
              <mat-icon>autorenew</mat-icon></button
            >Warte auf Eingang</span
          >
          <mat-form-field class="dense">
            <mat-select [(value)]="selectedOption">
              <mat-option
                *ngFor="let option of options | values"
                [value]="option.value"
              >
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-orders-list-for-widget
          [states]="states"
          [indicatorStyleFn]="getCircleColor"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class WaitingForDmWidgetComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();
  // states: Array<string> = ['new', 'incoming_dm_delayed'];
  selectedOption = 'analysis';
  options = {
    analysis: {
      value: 'analysis',
      label: 'ZUR ANALYSE',
      states: ['new', 'incoming_dm_delayed']
    }
  };

  constructor(private store$: Store<ApplicationState>) {}

  get states(): Array<string> {
    return this.options[this.selectedOption].states;
  }

  loadData(): void {
    this.options[this.selectedOption].states = [
      ...this.options[this.selectedOption].states
    ];
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(order: Order): { backgroundColor: string } {
    if (order.rawState.indexOf('new') > -1) {
      return { backgroundColor: 'green' };
    } else if (order.rawState.indexOf('incoming_dm_delayed') > -1) {
      return { backgroundColor: 'red' };
    } else {
      return { backgroundColor: '#ffc400' };
    }
  }
}
