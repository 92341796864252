import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {EnclosureDataMediumsActions} from '../actions';
import {EnclosureDataMediumsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class EnclosureDataMediumsEffects {

  constructor(private actions$: Actions, private service: EnclosureDataMediumsService, private notifierService: NotifierService) {
  }


  createEnclosureDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(EnclosureDataMediumsActions.CreateEnclosureDataMedium),
    switchMap(({payload}) => {

      return this.service
        .createEnclosureDataMedium(payload)
        .pipe(
          map((response: any) => EnclosureDataMediumsActions.CreateEnclosureDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(EnclosureDataMediumsActions.CreateEnclosureDataMediumFail({response})))
        );
    })
  ));


  readEnclosureDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(EnclosureDataMediumsActions.ReadEnclosureDataMedium),
    switchMap(({iri}) => {

      return this.service
        .getEnclosureDataMedium(iri)
        .pipe(
          map((response: any) => EnclosureDataMediumsActions.ReadEnclosureDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(EnclosureDataMediumsActions.ReadEnclosureDataMediumFail({response})))
        );
    })
  ));


  updateEnclosureDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(EnclosureDataMediumsActions.UpdateEnclosureDataMedium),
    switchMap(({iri, payload}) => {

      return this.service
        .update(iri, payload)
        .pipe(
          map((response: any) => EnclosureDataMediumsActions.UpdateEnclosureDataMediumSuccess({response})),
          catchError((response: HttpErrorResponse) => of(EnclosureDataMediumsActions.UpdateEnclosureDataMediumFail({response})))
        );
    })
  ));


  deleteEnclosureDataMedium$ = createEffect(() => this.actions$.pipe(
    ofType(EnclosureDataMediumsActions.DeleteEnclosureDataMedium),
    switchMap(({iri}) => {

      return this.service
        .deleteEnclosureDataMedium(iri)
        .pipe(
          map(() => EnclosureDataMediumsActions.DeleteEnclosureDataMediumSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(EnclosureDataMediumsActions.DeleteEnclosureDataMediumFail({response})))
        );
    })
  ));
  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      EnclosureDataMediumsActions.CreateEnclosureDataMediumSuccess,
      EnclosureDataMediumsActions.UpdateEnclosureDataMediumSuccess,
      EnclosureDataMediumsActions.DeleteEnclosureDataMediumSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case EnclosureDataMediumsActions.CREATE_ENCLOSURE_DATA_MEDIUM_SUCCESS:
          message = 'Ein neuer Gehäuses wurde angelegt.';
          break;
        case EnclosureDataMediumsActions.UPDATE_ENCLOSURE_DATA_MEDIUM_SUCCESS:
          message = 'Das Gehäuses wurde aktualisiert.';
          break;
        case EnclosureDataMediumsActions.DELETE_ENCLOSURE_DATA_MEDIUM_SUCCESS:
          message = 'Das Gehäuses wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      EnclosureDataMediumsActions.CreateEnclosureDataMediumFail,
      EnclosureDataMediumsActions.UpdateEnclosureDataMediumFail,
      EnclosureDataMediumsActions.ReadEnclosureDataMediumFail,
      EnclosureDataMediumsActions.DeleteEnclosureDataMediumFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case EnclosureDataMediumsActions.CREATE_ENCLOSURE_DATA_MEDIUM_FAIL:
          message = 'Beim Anlegen eines neuen Gehäuses sind Fehler aufgetreten:' + errors;
          break;
        case EnclosureDataMediumsActions.UPDATE_ENCLOSURE_DATA_MEDIUM_FAIL:
          message = 'Beim Aktualisieren des Gehäuses sind Fehler aufgetreten:' + errors;
          break;
        case EnclosureDataMediumsActions.DELETE_ENCLOSURE_DATA_MEDIUM_FAIL:
          message = 'Beim Löschen des Gehäuses ist ein Fehler aufgetreten:' + errors;
          break;
        case EnclosureDataMediumsActions.READ_ENCLOSURE_DATA_MEDIUM_FAIL:
          message = 'Beim lesen der Gehäuses sind Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});


}
