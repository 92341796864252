import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

// Read
export const LOAD_LEAD_CONTACT_TYPES = '[Leads Module] Load Lead Contact Types';
export const LOAD_LEAD_CONTACT_TYPES_SUCCESS = '[Leads Module] Load Lead Contact Types Success';
export const LOAD_LEAD_CONTACT_TYPES_FAIL = '[Leads Module] Load Lead Contact Types Fail';

export const LoadLeadContactTypes = createAction(
  LOAD_LEAD_CONTACT_TYPES
);
export const LoadLeadContactTypesSuccess = createAction(
  LOAD_LEAD_CONTACT_TYPES_SUCCESS,
  props<{ response: any }>()
);
export const LoadLeadContactTypesFail = createAction(
  LOAD_LEAD_CONTACT_TYPES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const LOAD_LEAD_CONTACT_TYPE = '[Leads Module] Load Lead Contact Type';
export const LOAD_LEAD_CONTACT_TYPE_SUCCESS = '[Leads Module] Load Lead Contact Type Success';
export const LOAD_LEAD_CONTACT_TYPE_FAIL = '[Leads Module] Load Lead Contact Type Fail';

export const LoadLeadContactType = createAction(
  LOAD_LEAD_CONTACT_TYPE,
  props<{ iri: string }>()
);
export const LoadLeadContactTypeSuccess = createAction(
  LOAD_LEAD_CONTACT_TYPE_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);
export const LoadLeadContactTypeFail = createAction(
  LOAD_LEAD_CONTACT_TYPE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
