import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'daysSince'
})
export class DaysSincePipe implements PipeTransform {

  transform(value: Date | string | moment.Moment, ...args: unknown[]): string {
    if (value === null || value === undefined) {
      return '';
    }
    return moment(value).fromNow();

  }
}
