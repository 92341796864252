import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import * as fromModuleModels from '../../models';
import { Order, ProductOrderItem, ServiceOrderItem } from '../../models';
import { ObjectsUtility } from '../../../shared/utilities/objects.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Observable, Subject } from 'rxjs';
import { ProductUnit } from '../../../master-data/models';
import {
  GenericOrderItemsSelectors,
  ProductOrderItemsSelectors,
  ServiceOrderItemsSelectors
} from '../../store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {
  GenericOrderItemsActions,
  ProductOrderItemsActions,
  ServiceOrderItemsActions
} from '../../store';
import { ProductUnitsSelectors } from '../../../master-data/store/selectors';
import { ProductUnitsActions } from '../../../master-data/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import { MatDialog } from '@angular/material/dialog';
import { GenericOrderItem } from '../../models/generic-order-item.interface';
import { OrderItemDialogComponent } from '../order-item-dialog/order-item-dialog.component';
import { ModalDialogOptions } from '../../../application-state/models';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-order-special-items',
  styleUrls: ['order-special-items.component.scss'],
  template: `
    <div class="card">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <table class="bmo-table bmo-table-striped bmo-table-hover">
        <thead class="card__heading">
          <tr>
            <th style="display:flex; justify-content: space-between;">
              <span>Leistung auf Rechnung</span>
              <mat-button-toggle-group
                class="dense pull-right"
                [formControl]="selectedNetGrossDisplay$"
              >
                <mat-button-toggle value="net" class="bold-if-checked"
                  >netto
                </mat-button-toggle>
                <mat-button-toggle value="gross" class="bold-if-checked"
                  >brutto
                </mat-button-toggle>
              </mat-button-toggle-group>
            </th>
            <th>Menge</th>
            <th>
              {{ selectedNetGrossDisplay$.value === 'net' ? 'netto' : 'MWSt' }}
            </th>
            <th>
              Gesamt ({{
                selectedNetGrossDisplay$.value === 'net' ? 'netto' : 'brutto'
              }})
            </th>
            <th>Beschreibung</th>
            <th style="width: 40px;"></th>
          </tr>
        </thead>

        <tbody class="card__content">
          <tr
            *ngFor="let item of order?.specialItems"
            [class.text-color-grey]="parseFloat(item?.netTotal?.value) === 0.0"
          >
            <!--<pre>{{ item | json }}</pre>-->
            <td>
              <span class="material-icons inline-icon mr-2">work</span>
              {{ item?.title }}
            </td>
            <td>
              {{ item?.quantity | number: '1.0' }} {{ item.unit.abbreviation }}
            </td>
            <td>
              <!-- netTotal -->
              <div *ngIf="selectedNetGrossDisplay$.value === 'net'">
                {{
                  (item?.netTotalWithoutCommission?.value
                    ? item?.netTotalWithoutCommission?.value
                    : item?.netTotal?.value
                  ) | number: '1.2'
                }}&nbsp;
                {{
                  item?.netTotalWithoutCommission.currency | currencyShortener
                }}
                <span
                  style="text-decoration: line-through"
                  *ngIf="item?.discount"
                  >(O:{{ item.originalNetTotal.value | number: '1.2'
                  }}{{
                    item.originalNetTotal.currency | currencyShortener
                  }}
                  /R:{{ item.discount }}%)</span
                >
              </div>
              <div *ngIf="selectedNetGrossDisplay$.value === 'gross'">
                +
                {{
                  item?.taxesPerUnit?.value * item?.quantity | number: '1.2'
                }}&nbsp;
                {{ item?.grossTotal?.currency | currencyShortener }}
                <span
                  style="text-decoration: line-through"
                  *ngIf="item?.discount"
                  >(O:{{ item.originalGrossTotal.value | number: '1.2'
                  }}{{
                    item.originalGrossTotal.currency | currencyShortener
                  }}
                  /R:{{ item.discount }}%)</span
                >
              </div>
              <small class="commission" *ngIf="item?.commission?.value"
                >+ {{ item?.commission?.value | number: '1.2' }}
                {{ item?.commission?.currency | currencyShortener }}
                Partnerprovi.</small
              >
              <strong
                class="total"
                *ngIf="
                  selectedNetGrossDisplay$.value === 'net' &&
                  item?.commission?.value &&
                  item?.netTotal?.value
                "
                >{{ item?.netTotal?.value | number: '1.2' }}
                {{ item?.netTotal?.currency | currencyShortener }}</strong
              >
            </td>
            <td *ngIf="selectedNetGrossDisplay$.value === 'net'">
              {{ item?.netTotal?.value | number: '1.2' }}
              {{ item?.netTotal.currency | currencyShortener }}
            </td>
            <td *ngIf="selectedNetGrossDisplay$.value === 'gross'">
              {{ item?.grossTotal?.value | number: '1.2' }}
              {{ item?.grossTotal?.currency | currencyShortener }}
            </td>
            <td>{{ item.description }}</td>
            <td></td>
          </tr>

          <tr
            *ngFor="let item of productOrderItems$ | async"
            [class.upsell]="!!item?.upsell"
            class="clickable"
            (click)="openProductOrderItemDialog(item)"
          >
            <ng-container
              [ngTemplateOutlet]="rowContent"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </tr>

          <tr
            *ngFor="let item of serviceOrderItems$ | async"
            [class.upsell]="!!item?.upsell"
            class="clickable"
            (click)="openServiceOrderItemDialog(item)"
          >
            <ng-container
              [ngTemplateOutlet]="rowContent"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </tr>
          <tr
            *ngFor="let item of genericOrderItems$ | async"
            [class.upsell]="!!item?.upsell"
            class="clickable"
            (click)="openGenericOrderItemDialog(item)"
          >
            <ng-container
              [ngTemplateOutlet]="rowContent"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
          </tr>

          <tr
            class="last text-bold border-top-double dense"
            *ngIf="selectedNetGrossDisplay$.value === 'net'"
          >
            <td colspan="3" class="text-right">Gesamt (Netto)</td>
            <td>
              {{ order?.netTotal?.value | number: '1.2' }}
              {{ order?.netTotal?.currency | currencyShortener }}
            </td>
            <td colspan="3"></td>
          </tr>
          <tr
            class="last dense"
            *ngIf="selectedNetGrossDisplay$.value === 'net'"
          >
            <td colspan="3" class="text-right">MWSt.:</td>
            <td>
              +{{
                order?.grossTotal?.value - order?.netTotal?.value
                  | number: '1.2'
              }}
              {{ order?.grossTotal?.currency | currencyShortener }}
            </td>
            <td colspan="3"></td>
          </tr>
          <tr
            class="last text-bold border-top-double dense"
            *ngIf="selectedNetGrossDisplay$.value === 'net'"
          >
            <td colspan="3" class="text-right">Gesamt (Brutto)</td>
            <td>
              {{ order?.grossTotal?.value | number: '1.2' }}
              {{ order?.grossTotal?.currency | currencyShortener }}
            </td>
            <td colspan="3"></td>
          </tr>
        </tbody>
      </table>

      <div class="actions m-ta--2">
        <!--<button class="m-r--8" color="green" mat-flat-button (click)="handleRequestCreateGenericOrderItemForm()">
          <mat-icon class="m-r--8">list</mat-icon>
          <span>Freie Position hinzufügen</span>
        </button>

        <button class="m-r--8" color="green" mat-flat-button (click)="handleRequestCreateServiceOrderItemForm()">
          <mat-icon class="m-r--8">list</mat-icon>
          <span>Service hinzufügen</span>
        </button>
        -->
        <button
          color="green"
          mat-flat-button
          *ngIf="order?.downPayment == 0"
          (click)="handleRequestCreateProductOrderItemForm()"
        >
          <mat-icon class="m-r--8">list</mat-icon>
          <span>Position hinzufügen</span>
        </button>
        <span *ngIf="order?.downPayment  > 0" style="font-style: italic;">
              {{'invoices.invoices_preview.partial_invoice_note' | translate}}
            </span>
      </div>
    </div>

    <ng-template #rowContent let-item>
      <td>
        <span
          *ngIf="item['@type'] == 'ServiceOrderItem'"
          class="material-icons inline-icon mr-2"
          >local_gas_station</span
        >
        <span
          *ngIf="item['@type'] == 'ProductOrderItem'"
          class="material-icons inline-icon mr-2"
          >eject</span
        >
        <span
          *ngIf="item['@type'] == 'GenericOrderItem'"
          class="material-icons inline-icon mr-2"
          >star_rate</span
        >
        {{ item?.title }}
      </td>
      <td>
        {{ item?.quantity || 1 | number: '1.0' }}
        {{ getUnitAbbreviation(item.unit) }}
      </td>
      <td>
        <div *ngIf="selectedNetGrossDisplay$.value === 'gross'">
          +
          {{
            item?.taxesPerUnit?.value * (item?.quantity || 1) | number: '1.2'
          }}&nbsp;
          {{ item?.grossTotal?.currency | currencyShortener }}
        </div>
        <div *ngIf="selectedNetGrossDisplay$.value === 'net'">
          {{ item?.netPricePerUnit?.value | number: '1.2' }}
          {{ item?.netPricePerUnit.currency | currencyShortener }}
        </div>
      </td>
      <td>
        <div *ngIf="selectedNetGrossDisplay$.value === 'gross'">
          {{ item?.grossTotal?.value | number: '1.2' }}
          {{ item?.grossTotal.currency | currencyShortener }}
        </div>
        <div *ngIf="selectedNetGrossDisplay$.value === 'net'">
          {{ item?.netTotal?.value | number: '1.2' }}
          {{ item?.netTotal.currency | currencyShortener }}
        </div>
      </td>
      <td>{{ item?.description }}</td>
      <td class="text-color-red text-center">
        <mat-icon
          (click)="handleRequestDeleteOrderItem(item, $event)"
          class="cursor-pointer"
          >delete_forever
        </mat-icon>
      </td>
    </ng-template>

    <!--<pre>{{ productOrderItems$|async | json }}</pre>-->
    <!--<pre>{{ serviceOrderItems$|async | json }}</pre>-->
    <!--<pre>{{ order?.specialItems | json }}</pre>-->
  `
})
export class OrderSpecialItemsComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  isLoading$: Observable<boolean>;
  order: Order;
  productOrderItems$: Observable<Array<ProductOrderItem>>;
  serviceOrderItems$: Observable<Array<ServiceOrderItem>>;
  genericOrderItems$: Observable<Array<GenericOrderItem>>;
  unitsEntities: { [iri: string]: ProductUnit };
  selectedNetGrossDisplay$ = new FormControl('net');

  @Output() requestProductOrderItemForm: EventEmitter<
    void
  > = new EventEmitter();
  @Output() requestServiceOrderItemForm: EventEmitter<
    void
  > = new EventEmitter();

  formFieldMap = {
    initialCosts: 'Initialkosten',
    analysis: 'Analyse',
    dataRecovery: 'Datenrettung',
    returnShipment: 'Rückversand',
    disposal: 'Entsorgung',
    dismantling: 'Rückbau'
  };
  itemTypes = ['product', 'service'];
  onDestroy$: Subject<any> = new Subject<any>();
  protected readonly parseFloat = parseFloat;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog
  ) {}

  get formFields(): Array<string> {
    return ObjectsUtility.getObjectKeys(this.formFieldMap);
  }
  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ServiceOrderItemsSelectors.isLoading),
      this.store$.select(ProductOrderItemsSelectors.isLoading),
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(GenericOrderItemsSelectors.isLoading)
    ]);
    this.loadUnits();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.loadOrderItems();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  isGreaterThanZero(amount: string): boolean {
    return parseFloat(amount) > 0;
  }

  handleRequestCreateProductOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, type: 'product' }
    });
  }

  openProductOrderItemDialog(item: ProductOrderItem): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, item, type: 'product' }
    });
  }

  handleRequestCreateServiceOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, type: 'service' }
    });
  }

  openServiceOrderItemDialog(item: ServiceOrderItem): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, item, type: 'service' }
    });
  }

  handleRequestCreateGenericOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, type: 'generic' }
    });
  }

  openGenericOrderItemDialog(item: GenericOrderItem): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, item, type: 'generic' }
    });
  }

  getItemsForType(
    type: string
  ): Array<
    fromModuleModels.ProductOrderItem | fromModuleModels.ServiceOrderItem
  > {
    return this[`${type}OrderItems`];
  }

  getNetPriceControlName(field: string): string {
    return 'price' + this.uppercaseFirstLetter(field) + 'Net';
  }

  getGrossPriceControlName(field: string): string {
    return 'price' + this.uppercaseFirstLetter(field) + 'Gross';
  }

  getUnitAbbreviation(unit: string): string {
    return this.unitsEntities[unit]?.abbreviation;
  }

  getUnitName(unit: string): string {
    return this.unitsEntities[unit]?.name;
  }

  uppercaseFirstLetter(s: string): string {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  handleShowServiceOrderItemForm(ref: any): void {
    // this.openDialog(ref, {id: 'dialog--order-item'});
  }

  handleShowProductOrderItemForm(ref: any): void {
    // this.openDialog(ref, {id: 'dialog--order-item'});
  }

  handleRequestDeleteOrderItem(
    item: ServiceOrderItem | ProductOrderItem | GenericOrderItem,
    event: Event
  ): void {
    event.stopPropagation();

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll diese Position wirklich entfernt werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        // console.log(item);
        if (item['@type'] === 'ServiceOrderItem') {
          this.store$.dispatch(
            ServiceOrderItemsActions.DeleteServiceOrderItem({
              iri: item['@id']
            })
          );
        } else if (item['@type'] === 'ProductOrderItem') {
          this.store$.dispatch(
            ProductOrderItemsActions.DeleteProductOrderItem({
              iri: item['@id']
            })
          );
        } else if (item['@type'] === 'GenericOrderItem') {
          this.store$.dispatch(
            GenericOrderItemsActions.DeleteGenericOrderItem({
              iri: item['@id']
            })
          );
        }
      });
  }

  private loadUnits(): void {
    this.store$
      .select(ProductUnitsSelectors.selectProductUnitsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.unitsEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      ProductUnitsSelectors.isLoaded,
      ProductUnitsActions.ReadProductUnits()
    );
  }

  private loadOrderItems(): void {
    if (this.serviceOrderItems$ === undefined) {
      this.serviceOrderItems$ = this.store$.select(
        ServiceOrderItemsSelectors.selectServiceOrderItemsForOrder,
        { iri: this.order['@id'] }
      );
      this.store$.dispatch(
        ServiceOrderItemsActions.ReadServiceOrderItems({
          orderUuid: getUuidFromIri(this.order['@id'])
        })
      );
    }
    if (this.genericOrderItems$ === undefined) {
      this.genericOrderItems$ = this.store$.select(
        GenericOrderItemsSelectors.selectGenericOrderItemsForOrder,
        { iri: this.order['@id'] }
      );
      this.store$.dispatch(
        GenericOrderItemsActions.ReadGenericOrderItems({
          orderUuid: getUuidFromIri(this.order['@id'])
        })
      );
    }

    if (this.productOrderItems$ === undefined) {
      this.productOrderItems$ = this.store$.select(
        ProductOrderItemsSelectors.selectProductOrderItemsForOrder,
        { iri: this.order['@id'] }
      );
      this.store$.dispatch(
        ProductOrderItemsActions.ReadProductOrderItems({
          orderUuid: getUuidFromIri(this.order['@id'])
        })
      );
    }
  }
}
