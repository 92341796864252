<div class="wrapper">
  <div class="row">
    <div class="col-auto me-auto">
      <h1>Auftrag auswählen</h1>
    </div>
    <div class="col-auto">

      <app-search-input
        (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
        (searchTermChange)="handleSearchInput($event)"
        [showToggle]="true"
      ></app-search-input>
      <app-orders-filter-form
        (requestHandleAction)="handleUpdateFilter($event)"
        [searchBoxVisible$]="searchBoxVisible$"
      ></app-orders-filter-form>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <div class="table-paginator-group pos-relative">
          <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

          <app-orders-list
            (requestViewOrder)="handleListClick($event)"
            [orderSelection]="true"
            [orders]="sortOrders(filterOrders(orders$|async)) | slice: lowValue : highValue"
          ></app-orders-list>

          <app-paginator-unstyled
            (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
            [pageSizeOptions]="[5,10,15,20, 25, 30]"
            [pageSize]="15"
            [showFirstLastButtons]="true"
            [totalItems]="filterOrders(orders$|async)?.length"
          ></app-paginator-unstyled>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <div class="grid">
    <div class="column-auto m-ta--2">

      <button (click)="resetAndClose()" class="btn--cancel" color="outline" mat-flat-button type="button">
        <mat-icon class="m-r--8">cancel</mat-icon>
        <span>Abbrechen</span>
      </button>
    </div>
  </div>

</div>
