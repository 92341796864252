import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Order } from '../../models';
import { extractUUID } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-sales-list',
  styleUrls: ['sales-list.component.scss'],
  template: `
    <table
      class="bmo-table bmo-table-bordered bmo-table-heading-uppercase bmo-table-clickable bmo-table-hover rounded-top"
      style="border-bottom: none;"
      mat-table
      [dataSource]="sales"
    >
      <!-- State Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a
          ><span class="badge--express" *ngIf="isExpress(element)">
            <span>Express</span>
          </span>
          <span
            class="circle grey"
            [ngClass]="getCircleColorClass(element)"
          ></span>
        </td>
      </ng-container>

      <!-- Order Number Column -->
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Auftrag.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a
          >{{ element.orderNumber }}
        </td>
      </ng-container>

      <!-- Data Recovery Prio Mode -->
      <ng-container matColumnDef="dataRecoveryPriorityMode">
        <th mat-header-cell *matHeaderCellDef>Typ</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element.dataRecoveryPriorityMode }}
        </td>
      </ng-container>

      <!-- Customer / Name Column -->
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a
          >{{ element.customerName }}
        </td>
      </ng-container>

      <!-- Customer / Company Column -->
      <ng-container matColumnDef="customerCompany">
        <th mat-header-cell *matHeaderCellDef>Firma</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element?.customerCompany ? element.customerCompany : '-' }}
        </td>
      </ng-container>

      <!-- Sales Person Column -->
      <ng-container matColumnDef="salesPerson">
        <th mat-header-cell *matHeaderCellDef>Verk.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element?.salesperson?.firstName }}
          {{ element?.salesperson?.lastName }}
        </td>
      </ng-container>

      <!-- stateRange Column -->
      <ng-container matColumnDef="stateRange">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a
          >{{ element.stateRange }}
        </td>
      </ng-container>

      <!-- priceAnalysis Column -->
      <ng-container matColumnDef="priceAnalysis">
        <th mat-header-cell *matHeaderCellDef>€ Analyse</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element.priceAnalysisNet.value | number: '1.2' }}
          {{ element.priceAnalysisNet.currency | currencyShortener }}
        </td>
      </ng-container>

      <!-- priceDataRecovery Column -->
      <ng-container matColumnDef="priceDataRecovery">
        <th mat-header-cell *matHeaderCellDef>€ Datenr.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element.priceDataRecoveryNet.value | number: '1.2' }}
          {{ element.priceDataRecoveryNet.currency | currencyShortener }}
        </td>
      </ng-container>

      <!-- bindingOrderReceivedAt Column -->
      <ng-container matColumnDef="bindingOrderReceivedAt">
        <th mat-header-cell *matHeaderCellDef>VB Eingang</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{
            element?.bindingOrderReceivedAt
              ? (element.bindingOrderReceivedAt | date: 'dd.MM.Y')
              : '-'
          }}
        </td>
      </ng-container>

      <!-- downPaymentReceivedAt Column -->
      <ng-container matColumnDef="downPaymentReceivedAt">
        <th mat-header-cell *matHeaderCellDef>Anz. Eingang</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{
            element?.downPaymentReceivedAt
              ? (element.downPaymentReceivedAt | date: 'dd.MM.Y')
              : '-'
          }}
        </td>
      </ng-container>

      <!-- replacementDataMediumSource Column -->
      <ng-container matColumnDef="replacementDataMediumSource">
        <th mat-header-cell *matHeaderCellDef>Ersatzd.</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{
            element?.replacementDataMediumSource
              ? element.replacementDataMediumSource
              : '-'
          }}
        </td>
      </ng-container>

      <!-- externalCosts Column -->
      <ng-container matColumnDef="externalCosts">
        <th mat-header-cell *matHeaderCellDef>Ext. Kosten</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element?.externalCosts ? element.externalCosts : '-' }}
        </td>
      </ng-container>

      <!-- createdAt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellt</th>
        <td mat-cell *matCellDef="let element" class="pos-relative">
          <a
            class="stretched-link"
            [routerLink]="['/orders', extractId(element)]"
          ></a>
          {{ element.createdAt | date: 'dd.MM.Y' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="handleRequestGoToOrderView(row)"
      ></tr>
    </table>
  `
})
export class SalesListComponent implements OnInit {
  @Input() sales: Array<Order>;

  @Output() requestGoToOrderView: EventEmitter<string> = new EventEmitter();

  displayedColumns: Array<string> = [
    'dot',
    'orderNumber',
    'dataRecoveryPriorityMode',
    'customerName',
    'customerCompany',
    'salesPerson',
    'stateRange',
    'priceAnalysis',
    'priceDataRecovery',
    'bindingOrderReceivedAt',
    'downPaymentReceivedAt',
    'replacementDataMediumSource',
    'externalCosts',
    'createdAt'
  ];
  protected readonly extractId = extractUUID;

  constructor() {}

  getCircleColorClass(element: Order): string[] {
    if (element.stateRange === 'Warte auf Schlusszahlung') {
      return ['red'];
    } else if (element.stateRange === 'Auftrag abgeschlossen') {
      return ['grey'];
    } else {
      return ['green'];
    }
  }

  isExpress(order: Order): boolean {
    return order?.dataRecoveryPriorityMode?.toLowerCase() === 'express';
  }

  ngOnInit(): void {}

  handleRequestGoToOrderView(order: Order): void {
    this.requestGoToOrderView.emit(order['@id']);
  }
}
