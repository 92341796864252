import {
  Component,
  Input,
  OnDestroy,
  OnInit, Optional,
  Self,
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {Order} from '../../../orders/models';
import {Observable} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {CurrencyMaskInputMode} from 'ngx-currency/src/currency-mask.config';

@Component({
  selector: 'app-input-size-in-gb',
  styleUrls: ['./input-size-in-gb.component.scss'],
  template: `
    <div [formGroup]="form">
      <mat-form-field>
        <mat-label *ngIf="label">Größe</mat-label>
        <input matInput formControlName="input"
               currencyMask
               [options]="{ inputMode: NATURAL , allowNegative:false, precision:0, prefix: '', suffix: '' }">
        <span matSuffix class="me-2 text-color-grey">GB</span>
        <mat-error>
          <app-form-error fieldName="input" [formGroup]="form"></app-form-error>
        </mat-error>
      </mat-form-field>
    </div>
  `
})
export class InputSizeInGbComponent extends BaseOnDestroyComponent implements OnInit, OnDestroy, ControlValueAccessor {


  form: FormGroup;
  @Input() label = true;
  @Input() append: string = null;
  @Input() readonly = false;
  @Input() required = false;
  @Input() errors: { [key: string]: string };
  items$: Observable<Array<Order>>;
  isLoading$: Observable<boolean>;
  NATURAL: CurrencyMaskInputMode.NATURAL;
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private fb: FormBuilder,
    @Self() @Optional() public control: NgControl
  ) {
    super();
    this.form = this.fb.group({
      input: this.fb.control(null),
    });
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.form?.get('input').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
    if (this.control) {
      // this.form.get('input').setValidators(this.control.validator);

      this.control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
        this.form.controls.input.setErrors(this.control.control.errors);
      });
    }
  }

  setSelectedItem(element: string): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form?.get('input').disable();
    } else {
      this.form?.get('input').enable();
    }
  }

  writeValue(value: any): void {
    this.form?.get('input').setValue(value);
  }

}
