import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {Actions, ofType} from '@ngrx/effects';
import {Order, ProductOrderItem, ServiceOrderItem} from '../../models';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {Observable, Subject} from 'rxjs';
import {GenericOrderItemsActions, ProductOrderItemsActions, ServiceOrderItemsActions} from '../../store';
import {takeUntil} from 'rxjs/operators';
import {OrderItemType} from '../order-item-form/order-item-form.component';
import {GenericOrderItem} from '../../models/generic-order-item.interface';
import {ServicesSelectors} from '../../../master-data/store/selectors';

@Component({
  selector: 'app-order-item-dialog',
  styleUrls: ['./order-item-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>{{typeName}} {{data.item ? 'bearbeiten' : 'hinzufügen'}} </h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <app-order-item-form
        [changeableTypeDisabled]="data.changeableTypeDisabled"
        [order]="data.order"
        [itemType]="itemType"
        (changeItemType)="handleChangeItemType($event)"
        [item]="data.item"
      ></app-order-item-form>
    </div>
  `
})


export class OrderItemDialogComponent implements OnInit, OnDestroy {
  itemType: OrderItemType = 'generic';
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<OrderItemDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                order: Order,
                type: OrderItemType,
                item: GenericOrderItem | ServiceOrderItem | ProductOrderItem,
                changeableTypeDisabled: false
              }) {
  }

  ngOnInit(): void {
    this.itemType = this.data.type;

    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
      this.store$.select(ServicesSelectors.isLoading),
    ]);
    this.actions$.pipe(
      ofType(
        ProductOrderItemsActions.CreateProductOrderItemSuccess,
        GenericOrderItemsActions.CreateGenericOrderItemSuccess,
        ServiceOrderItemsActions.CreateServiceOrderItemSuccess,
        ProductOrderItemsActions.UpdateProductOrderItemSuccess,
        GenericOrderItemsActions.UpdateGenericOrderItemSuccess,
        ServiceOrderItemsActions.UpdateServiceOrderItemSuccess,
      ),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.dialogRef.close();
    });
    this.actions$.pipe(
      ofType(
        ProductOrderItemsActions.CreateProductOrderItemFail,
        GenericOrderItemsActions.CreateGenericOrderItemFail,
        ServiceOrderItemsActions.CreateServiceOrderItemFail,
        ProductOrderItemsActions.UpdateProductOrderItemFail,
        GenericOrderItemsActions.UpdateGenericOrderItemFail,
        ServiceOrderItemsActions.UpdateServiceOrderItemFail,
      ),
      takeUntil(this.onDestroy$)
    ).subscribe((fail) => {
      if (fail?.response?.error['hydra:description']) {
        this.notifierService.notify('error', fail?.response?.error['hydra:description']);
      }
    });

  }

  get typeName(): string {
    switch (this.itemType) {
      case 'product':
        return 'Produkt';
      case 'generic':
        return 'Freie Position';
      case 'service':
        return 'Service';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleChangeItemType(payload: OrderItemType): void {
    this.itemType = payload;
  }

}
