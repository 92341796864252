import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {TicketCommentsActions} from '../actions';
import {TicketCommentsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class TicketCommentsEffects {

  constructor(private actions$: Actions, private service: TicketCommentsService, private notifierService: NotifierService) {
  }

  CreateTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.CreateTicketComment),
    switchMap(({payload}) => {
      return this.service.createTicketComment(payload).pipe(
        map((response: any) => TicketCommentsActions.CreateTicketCommentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.CreateTicketCommentFail({response})))
      );
    })
  ));

  ReadTicketComment$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.ReadTicketComment),
    switchMap(({iri}) => {
      return this.service.readTicketComment(iri).pipe(
        map((response: any) => TicketCommentsActions.ReadTicketCommentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.ReadTicketCommentFail({response})))
      );
    })
  ));

  ReadTicketComments$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.ReadTicketComments),
    switchMap(({page, params}) => {
      return this.service.readTicketComments(page, params).pipe(
        map((response) => TicketCommentsActions.ReadTicketCommentsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.ReadTicketCommentsFail({response})))
      );
    })
  ));

  ReadTicketCommentChunk$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.ReadTicketCommentChunk),
    switchMap(({uri}) => {
      return this.service.readTicketCommentChunk(uri).pipe(
        map((response: any) => TicketCommentsActions.ReadTicketCommentChunkSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.ReadTicketCommentChunkFail({response})))
      );
    })
  ));

  UpdateTicketComment$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.UpdateTicketComment),
    switchMap(({iri, payload}) => {
      return this.service.updateTicketComment(iri, payload).pipe(
        map((response: any) => TicketCommentsActions.UpdateTicketCommentSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.UpdateTicketCommentFail({response})))
      );
    })
  ));

  DeleteTicketComment$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentsActions.DeleteTicketComment),
    switchMap(({iri}) => {
      return this.service.deleteTicketComment(iri).pipe(
        map(() => TicketCommentsActions.DeleteTicketCommentSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(TicketCommentsActions.DeleteTicketCommentFail({response})))
      );
    })
  ));
  TicketCommentActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      TicketCommentsActions.CreateTicketCommentSuccess,
      TicketCommentsActions.UpdateTicketCommentSuccess,
    ),
    map(({type}) => {
      let message = 'Erfolgreich';

      switch (type) {
        case TicketCommentsActions.CREATE_TICKET_COMMENT_SUCCESS:
          message = 'Ein neuer Kommentar wurde angelegt.';
          break;
        case TicketCommentsActions.UPDATE_TICKET_COMMENT_SUCCESS:
          message = 'Der Kommentar wurde aktualisiert.';
          break;
      }

      this.notifierService.show({type: 'success', message});

    })
  ), {dispatch: false});

}
