import {createSelector} from '@ngrx/store';

import {DATA_RECOVERY_COSTS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';
import {sortByCreatedAtDate} from '../../../shared/utilities/array.utility';
import {extractIri} from '../../../shared/utilities/objects.utility';

const NAMESPACE = DATA_RECOVERY_COSTS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectOrdersModuleState;

export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const sEntities = createSelector(sState, (s) => s.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities));
export const sByIri = createSelector(sEntities, (entities, {iri}) => entities[iri]);
export const sByOrder = (orderIri) => createSelector(sList, (entities) =>
  entities.filter(e => extractIri(e.order) === extractIri(orderIri)).sort((a, b) => sortByCreatedAtDate(a, b, true)));

////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectDataRecoveryCostsErrors = createSelector(
  sState,
  (state) => state.errors
);

