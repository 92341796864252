import { Component, Input, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { DataRecoveryResultsActions } from '../../../orders/store';
import { DataRecoveryResultsSelectors } from '../../../orders/store/selectors';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { TransitionsActions } from '../../../application-state/store/actions';
import { MomentDateWithTime } from '../../pipes/moment-date-with-time.pipe';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-data-recovery-provided',
  styleUrls: ['./action-box-to-data-recovery-provided.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Datenrettungsergebnis</div>
              <div class="sub-header col-auto">Bitte Ergebnis festlegen</div>
            </div>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Datenrettungsergebnis:</div>
          <div class="sub-header col-auto">Warte auf Datenrettungsergebnis</div>
        </div>
      </div>
      <div
        class="additional-content p-4"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-sm-4">
            <mat-form-field>
              <mat-label>Erfolgsquote</mat-label>
              <input
                type="string"
                matInput
                currencyMask
                [options]="{
                  allowNegative: false,
                  inputMode: 0,
                  max: 100,
                  min: 0,
                  precision: 2,
                  decimal: ',',
                  prefix: '',
                  suffix: '%'
                }"
                formControlName="recoveredDataPercentage"
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'recoveredDataPercentage'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Datum</mat-label>

              <input type="text" matInput [matDatepicker]="picker" formControlName="createdAt">


              <mat-datepicker-toggle matSuffix [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker
              ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-8">
            <mat-form-field>
              <mat-label>Kommentar</mat-label>
              <textarea
                matInput
                formControlName="comment"
                rows="5"
                cdkTextareaAutosize
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'comment'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid || !okAction"
            >
              <mat-icon class="me-2">save</mat-icon>
              <span>Datenrettungsergebnis speichern</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToDataRecoveryProvidedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  form: FormGroup;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        ((this.okAction?.transition?.indexOf('dr_ext') &&
          this.authService.isTechnicianExternal()) ||
          (this.okAction?.transition?.indexOf('dr_remote') &&
            this.authService.isTechnicianExternal()) ||
          (this.okAction?.transition?.indexOf('dr_de') &&
            this.authService.isTechnician()) ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.subscribeToCreatedAtChanges();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        const dataRecoveryResultIri = order?.dataRecoveryResults?.length
          ? order.dataRecoveryResults.slice(-1)[0]
          : null;
        this.store$
          .select(DataRecoveryResultsSelectors.selectDataRecoveryResultByIri, {
            iri: dataRecoveryResultIri
          })
          .pipe(
            takeUntil(this.onDestroy$),
            filter(data => !!data)
          )
          .subscribe(dataRecoveryResult => {
            const values = { ...dataRecoveryResult };
            // console.log(dataRecoveryResult.createdAt, new MomentDateWithTime().transform(dataRecoveryResult.createdAt));
            values.date = `${new MomentDateWithTime().transform(
              dataRecoveryResult.createdAt
            )}`;
            if (dataRecoveryResult.updatedAt !== dataRecoveryResult.createdAt) {
              values.date += ` (Zuletzt bearbeitet: ${new MomentDateWithTime().transform(
                dataRecoveryResult.updatedAt
              )})`;
            }
            this.form.patchValue(values);
          });

        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('to_data_recovery_result_provided') > -1 &&
            !e.error
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      recoveredDataPercentage: this.fb.control(0, [Validators.required]),
      comment: this.fb.control(''),
      createdAt: this.fb.control(new Date().toISOString().substr(0, 10))
    });
  }
  subscribeToCreatedAtChanges(): void {
    this.form.get('createdAt').valueChanges.subscribe(newValue => {
      if (newValue instanceof Date) {
        let year = newValue.getFullYear();
        let month = ("0" + (newValue.getMonth() + 1)).slice(-2); // Months are 0 based
        let day = ("0" + newValue.getDate()).slice(-2);
        newValue = `${year}-${month}-${day}`;
      }
      this.form.get('createdAt').setValue(newValue, {emitEvent: false});
    });
  }
  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const payload = this.form.getRawValue();
    payload.recoveredDataPercentage = payload.recoveredDataPercentage.toString();
    const iri = this.order?.dataRecoveryResults[
      this.order?.dataRecoveryResults.length - 1
    ];
    if (!iri) {
      return;
    }
    this.store$.dispatch(
      DataRecoveryResultsActions.UpdateDataRecoveryResult({ iri, payload })
    );
    this.actions$
      .pipe(
        ofType(DataRecoveryResultsActions.UpdateDataRecoveryResultSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.makeTransition();
      });
  }
}
