import {createSelector} from '@ngrx/store';

import {DOCUMENT_DELIVERIES_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectDocumentDeliveriesState = createSelector(
  selectInvoicesModuleState,
  (state) => state[DOCUMENT_DELIVERIES_FEATURE_KEY]
);

export const selectDocumentDeliveriesEntities = createSelector(
  selectDocumentDeliveriesState,
  (state) => state.entities
);

export const selectDocumentDeliveries = createSelector(
  selectDocumentDeliveriesEntities,
  (deliveries) => Object.keys(deliveries).map(iri => deliveries[iri])
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectDocumentDeliveriesState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectDocumentDeliveriesState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectDocumentDeliveriesState,
  (state) => state.errors
);
