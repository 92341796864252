import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionalSlice'
})
export class OptionalSlicePipe implements PipeTransform {
  transform(input: string, length: number): unknown {
    if (!input) {
      return '';
    } else if (input.length <= length) {
      return input;
    } else {
      return input.slice(0, length) + '...';
    }
  }
}
