import {createSelector} from '@ngrx/store';

import {PARTIAL_INVOICE_ITEMS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectPartialInvoiceItemsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[PARTIAL_INVOICE_ITEMS_FEATURE_KEY]
);

export const selectPartialInvoiceItemsEntities = createSelector(
  selectPartialInvoiceItemsState,
  (state) => state.entities
);

export const selectPartialInvoiceItems = createSelector(
  selectPartialInvoiceItemsEntities,
  (items) => Object.keys(items).map(iri => items[iri])
);

export const selectPartialInvoiceItemByIndex = createSelector(
  selectPartialInvoiceItemsEntities,
  (customers, props) => customers[props.iri]
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectPartialInvoiceItemsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectPartialInvoiceItemsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectPartialInvoiceItemsState,
  (state) => state.errors
);
