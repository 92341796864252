import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import * as fromLeadsModuleModels from "../../../models";
import {Lead, LeadContactType, LeadOriginType} from "../../../models";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../../application-state/store";
import {AnalysisPriorityMode, StorageSystem} from "../../../../master-data/models";
import {LeadContactTypesSelectors} from "../../../store/selectors";
import {takeUntil} from "rxjs/operators";
import {isLoadingArray, loadIfNotLoaded} from "../../../../shared/utilities/observable.utility";
import {LeadContactTypesActions} from "../../../store";
import {AnalysisPriorityModesSelectors, StorageSystemsSelectors} from "../../../../master-data/store/selectors";
import {AnalysisPriorityModesActions, StorageSystemsActions} from "../../../../master-data/store";
import {Observable, Subject} from "rxjs";
import {MenuWithSubmenuItem} from "../leads-list.component";

@Component({
  selector: 'leads-list-card-wrapper',
  templateUrl: './leads-list-card-wrapper.component.html',
  styleUrls: ['./leads-list-card-wrapper.component.scss']
})
export class LeadsListCardWrapperComponent implements OnInit, OnDestroy {

  @Input() lead: fromLeadsModuleModels.Lead;
  @Input() isSelected: boolean = false;
  @Input() draggable: boolean = true;
  @Input() leadOriginTypesEntities: { [iri: string]: LeadOriginType } = {};
  @Output() menuItems: EventEmitter<MenuWithSubmenuItem[]> = new EventEmitter<MenuWithSubmenuItem[] >();
  leadContactTypesEntities: { [iri: string]: LeadContactType } = {};
  storageSystemsEntities: { [iri: string]: StorageSystem } = {};
  leadContactTypesMenuItems: MenuWithSubmenuItem[] = [];
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();
  classList: string[] = []
  private analysisPriorityModesEntities: {
    [iri: string]: AnalysisPriorityMode;
  };
  constructor(
    private store$: Store<ApplicationState>
  ) { }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(LeadContactTypesSelectors.isLoading),
      this.store$.select(StorageSystemsSelectors.isLoading),
    ]);
    this.loadStorageSystems();
    this.loadLeadContactTypes();
    this.loadAnalysisPriorityModes();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCustomerContactName(lead): string {
    let resultString = '';
    if (lead.firstName || lead.lastName) {
      if (lead.firstName && lead.firstName !== '') {
        resultString += lead.firstName;
      }
      if (lead.lastName && lead.lastName !== '') {
        resultString += ' ' + lead.lastName;
      }
    } else {
      if (lead.decisionMakerCustomerContact?.firstName) {
        resultString += lead.decisionMakerCustomerContact.firstName;
      }
      if (lead.decisionMakerCustomerContact?.lastName) {
        resultString += ' ' + lead.decisionMakerCustomerContact.lastName;
      }
    }
    return resultString.length > 0 ? resultString : '-';
  }


  getCompanyName(lead): string {
    if (lead.nameLine1 && lead.nameLine1 !== '') {
      return lead.nameLine1;
    } else if (lead.customer?.nameLine1) {
      return lead.customer?.nameLine1;
    }
    return '';
  }

  private loadLeadContactTypes(): void {
    this.store$
      .select(LeadContactTypesSelectors.selectLeadContactTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        Object.keys(value).map(key => {
          this.leadContactTypesMenuItems.push({
            label: value[key].name,
            value: key,
            icon: "circle",
            backgroundColor: value[key].backgroundColor
          });
          this.menuItems.emit(this.leadContactTypesMenuItems);
        });
        this.leadContactTypesEntities = value;
      });
    loadIfNotLoaded(
      this.store$,
      LeadContactTypesSelectors.isLoaded,
      LeadContactTypesActions.LoadLeadContactTypes()
    );
  }

  loadStorageSystems(): void {
    this.store$
      .select(StorageSystemsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.storageSystemsEntities = value;
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }
  private loadAnalysisPriorityModes(): void {
    loadIfNotLoaded(
      this.store$,
      AnalysisPriorityModesSelectors.isLoaded,
      AnalysisPriorityModesActions.ReadAnalysisPriorityModes()
    );
    this.store$
      .select(
        AnalysisPriorityModesSelectors.selectAnalysisPriorityModesEntities
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(analysisPriorityModesEntities => {
        this.analysisPriorityModesEntities = analysisPriorityModesEntities;
      });
  }

  private getAnalysisPriorityModes(lead: Lead): AnalysisPriorityMode {
    return this.analysisPriorityModesEntities
      ? this.analysisPriorityModesEntities[lead.analysisPriorityMode]
      : null;
  }

  isExpress(lead: Lead): boolean {
    const mode = this.getAnalysisPriorityModes(lead);
    return mode?.name === 'EXPRESS';
  }
}
