import {AfterViewInit, Component, HostBinding, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'form-date-input',
  styleUrls: ['form-date-input.component.scss'],
  template: `
    <div class="dynamic-field form-date">

      <mat-form-field [formGroup]="group">
        <mat-label>{{ config.label }}</mat-label>
        <input matInput #dateInput type="text"
               [max]="config?.optionsConfig?.maxDateToday ? today : null"
               [matDatepicker]="datePicker" [attr.placeholder]="config.placeholder" [formControlName]="config.name">

        <button mat-icon-button matSuffix
                *ngIf="config.optionsConfig.clearable && !!this.group.get(this.config.name).value">
          <mat-icon matDatepickerToggleIcon (click)="clearDate()">clear</mat-icon>
        </button>

        <mat-datepicker-toggle matSuffix [for]="datePicker">
          <mat-datepicker #datePicker disabled="false"></mat-datepicker>
        </mat-datepicker-toggle>
      </mat-form-field>
    </div>
  `,
})
export class FormDateInputComponent implements AfterViewInit {

  @HostBinding('attr.class')
  column = 'column-auto';

  config: { [k: string]: any };

  group: FormGroup;

  get today(): Date {
    return new Date();
  }

  clearDate(): void {
    this.group.get(this.config.name).setValue(null);
  }

  ngAfterViewInit(): void {
    if (this?.config?.colSpan) {
      this.column = `column-${this?.config?.colSpan}`;
    }
  }
}
