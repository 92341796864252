import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  CustomerReplacementStockItem,
  CustomerStockItem,
  DataMedium,
  ReplacementStockItem
} from '../../../warehouse/models';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Order } from '../../../orders/models';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { OrdersActions } from '../../../orders/store';
import { isLoadingArray } from '../../utilities/observable.utility';
import { Observable } from 'rxjs';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder,
  isVipFromOrder
} from '../../../orders/helpers';
import { extractIri } from '../../utilities/objects.utility';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { OrderShipment } from '../../../orders/models/order-shipment.interface';

@Component({
  selector: 'app-shipment-order-display-line',
  styleUrls: ['./shipment-order-display-line.component.scss'],
  template: `
    <div class="row pos-relative">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <!--      <pre>app-shipment-order-display-line:{{inputSelectedDataMedia|json}}</pre>-->
      <div class="col-3">
        <ng-container *ngIf="!!order">
          <app-stock-item-order-display-line
            [order]="order"
          ></app-stock-item-order-display-line>

          <p>Aktueller Zustand: {{ order.stateRange }}</p>
          <p *ngIf="order.netTotal">
            Auftragswert: {{ order.netTotal | price }}
          </p>
          <!--          <pre>{{replacementStockItems|json}}</pre>-->
        </ng-container>
      </div>
      <div class="col-9">
        <!--        <pre>{{ isOrderShipmentType | json }}</pre>-->
        <app-order-stock-item-select-list
          *ngIf="isOrderShipmentType"
          [customerReplacementStockItems]="customerReplacementStockItems"
          [customerStockItems]="customerStockItems"
          [replacementStockItems]="replacementStockItems"
          [validDataMediaStates]="validDataMediaStates"
          [dataMediaFilterFn]="dataMediaFilterFn"
          [inputSelectedDataMedia]="inputSelectedDataMedia"
          [inputFilteredDataMedia]="inputFilteredDataMedia"
          [readonly]="readonly"
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          [disableAutoLoad]="true"
          [readonlyArchive]="readonlyArchive"
        ></app-order-stock-item-select-list>
        <app-order-stock-item-select-list
          *ngIf="!isOrderShipmentType"
          [orderIri]="orderIri"
          [validDataMediaStates]="validDataMediaStates"
          [dataMediaFilterFn]="dataMediaFilterFn"
          [readonly]="readonly"
          [readonlyArchive]="readonlyArchive"
          [inputSelectedDataMedia]="inputSelectedDataMedia"
          [inputFilteredDataMedia]="inputFilteredDataMedia"
        ></app-order-stock-item-select-list>
      </div>
    </div>
  `
})
export class ShipmentOrderDisplayLineComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() @Optional() orderIri: string = null;
  @Input() @Optional() dataMediaFilterFn: (dataMedium: DataMedium) => boolean;

  @Input() @Optional() dataMediaSelectionFilterFn: (
    dataMedium: DataMedium
  ) => boolean;
  @Input() @Optional() order: Order | OrderShipment;
  @Input() @Optional() inputSelectedDataMedia: Array<string> = [];
  @Input() @Optional() validDataMediaStates: Array<string> = [];
  @Input() @Optional() inputFilteredDataMedia: Array<string> = null;

  @Input() @Optional() readonly = false;
  @Input() @Optional() readonlyArchive = false;
  @Output() updateSelectedDataMedia: EventEmitter<
    Array<string>
  > = new EventEmitter<Array<string>>();
  @Output() changeSelectedDataMedia: EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }> = new EventEmitter<{
    selected: Array<string>;
    unselected: Array<string>;
  }>();
  customerReplacementStockItems: Array<CustomerReplacementStockItem> = null;
  customerStockItems: Array<CustomerStockItem> = null;
  replacementStockItems: Array<ReplacementStockItem> = null;
  isLoading$: Observable<boolean>;
  selectedDataMedia: Array<string> = [];
  getCompanyName = getCompanyNameFromOrder;
  getCustomerName = getCustomerNameFromOrder;
  isVip = isVipFromOrder;
  extractIri = extractIri;
  getUuidFromIri = getUuidFromIri;

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  get isOrderShipmentType(): boolean {
    return !!this.order && 'customerStockItems' in this.order;
  }

  ngOnInit(): void {
    if (this.orderIri && !this.order) {
      this.loadOrder(this.orderIri);
    }
    this.setStockItems();

    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading)
    ]);
  }

  setSelectedDataMedia($event: {
    selected: Array<string>;
    unselected: Array<string>;
  }): void {
    for (const add of $event.selected) {
      const index = this.selectedDataMedia.indexOf(add);
      if (index <= -1) {
        this.selectedDataMedia.push(add);
      }
    }
    for (const remove of $event.selected) {
      const index = this.selectedDataMedia.indexOf(remove);
      if (index > -1) {
        this.selectedDataMedia.splice(index, 1);
      }
    }
    this.changeSelectedDataMedia.emit($event);
  }

  setStockItems(): void {
    if (!this.order || typeof this.order === 'string') {
      return;
    }
    this.customerReplacementStockItems =
      'customerStockItems' in this.order
        ? this.order?.customerStockItems?.filter(
            e => e['@type'] === 'CustomerReplacementStockItem'
          )
        : null;
    this.customerStockItems =
      'customerStockItems' in this.order
        ? this.order?.customerStockItems?.filter(
            e => e['@type'] === 'CustomerStockItem'
          )
        : null;
    if (!('productOrderItems' in this.order)) {
      this.replacementStockItems = null;
    }
    const newList = [];
    console.log(this.order?.productOrderItems);
    if (this.order?.productOrderItems) {
      for (const item of this.order?.productOrderItems as any[]) {
        if (typeof item !== 'string' && 'replacementDataMedium' in item) {
          const orderItem = { ...item };
          delete orderItem.replacementDataMedium;
          const dataMedium = { ...item.replacementDataMedium, orderItem };
          delete dataMedium.stockItem;
          newList.push({
            description: '',
            ...item.replacementDataMedium.stockItem,
            dataMediums: [dataMedium]
          });
        }
      }
      console.log(newList);
    }
    this.replacementStockItems = newList;
  }

  loadOrder(orderIri): void {
    this.store$
      .select(OrdersSelectors.sDetailedByIri, { iri: orderIri })
      .pipe(
        takeUntil(this.onDestroy$),
        tap(order => {
          if (!order) {
            this.store$.dispatch(OrdersActions.ReadOrder({ iri: orderIri }));
          }
        }),
        filter(e => !!e)
      )
      .subscribe(order => {
        this.order = order;
        // return 'customerStockItems' in this.order ? this.order?.productOrderItems : null;
        this.setStockItems();
      });
  }
}
