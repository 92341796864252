import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { InvoiceItemsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.InvoiceItem };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    InvoiceItemsActions.CreateInvoiceItem,
    InvoiceItemsActions.ReadInvoiceItem,
    InvoiceItemsActions.UpdateInvoiceItem,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    InvoiceItemsActions.ReadInvoiceItemSuccess,
    InvoiceItemsActions.CreateInvoiceItemSuccess,
    InvoiceItemsActions.UpdateInvoiceItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(InvoiceItemsActions.DeleteInvoiceItemSuccess, (state, { iri }) => {
    // @ts-ignore
    const { [iri]: removedItem, ...entities } = state.entities;

    return {
      ...state,
      entities,
      errors: {},
      loading: false
    };
  }),
  on(
    InvoiceItemsActions.CreateInvoiceItemFail,
    InvoiceItemsActions.ReadInvoiceItemFail,
    InvoiceItemsActions.UpdateInvoiceItemFail,
    InvoiceItemsActions.DeleteInvoiceItemFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
