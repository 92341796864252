import { Component, Inject, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from '../../../master-data/models';
import { ReplacementStockItem, StockItem } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormsService } from '../../../shared/services';
import { Actions } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CustomerReplacementStockItemsSelectors,
  CustomerStockItemsSelectors,
  DataMediumsSelectors
} from '../../store/selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-show-customer-hardware-dialog',
  styleUrls: ['./show-customer-hardware-dialog.component.scss'],
  template: `
    <form [formGroup]="form">
      <div mat-dialog-title style="min-width: 1000px;">
        <div class="row">
          <div class="col-6">
            <h1>
              {{
                form.get('hardwareTyp').value === 'CustomerStockItem'
                  ? 'Kundenhardware'
                  : 'Ersatzdatenträger (Kunde) '
              }}
            </h1>
          </div>
          <div class="col-6 text-right">
            <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        mat-dialog-content
        style="min-height: 500px; overflow: auto;"
        class="pos-relative"
      >
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="row">
          <div class="col-5">
            <div class="mat-form-field">
              <app-order-select
                [required]="true"
                [readonly]="true"
                formControlName="order"
              ></app-order-select>
            </div>
          </div>
          <div class="col-4">
            <div class="mat-form-field">
              <app-storage-system-type-select
                [required]="true"
                [readonly]="true"
                formControlName="storageSystemType"
              ></app-storage-system-type-select>
            </div>
          </div>
          <div class="col-3">
            <div class="mat-form-field">
              <app-storage-location-select
                [required]="true"
                [readonly]="true"
                formControlName="storageLocation"
              ></app-storage-location-select>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="form.get('withEnclosure').value">
          <div class="col-6">
            <h1 [class.text-strike]="!form.get('withEnclosure').value">
              Gehäuse
            </h1>
          </div>
          <div class="col-6 text-right">
            <mat-slide-toggle class="" disabled formControlName="withEnclosure">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row" *ngIf="form.get('withEnclosure').value">
          <div class="col-12">
            <app-data-media-line
              [dataMedia]="form.get('enclosure').value"
              [readonlyArchive]="true"
            ></app-data-media-line>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Datenträger</h1>
          </div>
          <div class="col-7 text-right">
            <!--<button mat-button color="green" (click)="addDataMediaLine()">
              <mat-icon>add</mat-icon>
              Festplatte hinzufügen
            </button>-->
          </div>
        </div>
        <div formArrayName="dataMediums">
          <div
            class="row"
            *ngFor="
              let subForm of dataMediumFormArray.controls;
              let index = index
            "
          >
            <div class="col-12">
              <app-data-media-line
                [dataMedia]="subForm.value"
                [readonlyArchive]="true"
              ></app-data-media-line>
              <!--<app-customer-data-medium-form [prependText]="(index+1)+'.'"
                                             [readonly]="true"
                                             [formGroup]="makeFg(subForm)"></app-customer-data-medium-form>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Bemerkung / Zubehör</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Bemerkung</mat-label>
              <textarea
                matInput
                formControlName="description"
                rows="12"
                mat-autosize
                readonly
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="description"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 ">
            <mat-form-field class="">
              <mat-label>Zubehör</mat-label>
              <textarea
                matInput
                formControlName="accessories"
                rows="12"
                mat-autosize
                readonly
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="accessories"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          class="row"
          [hidden]="!form.get('dataMediaOkLogisticsDepartment').enabled"
        >
          <div class="col-12">
            <mat-button-toggle-group
              formControlName="dataMediaOkLogisticsDepartment"
              aria-label="Datenträger OK Logistik"
              readonly
              disabled
              class="mb-2 block"
            >
              <mat-button-toggle
                value="true"
                class="bold-if-checked green-if-checked lightgrey-else"
              >
                Datenträger OK (Logistik)
              </mat-button-toggle>
              <mat-button-toggle
                value="false"
                class="bold-if-checked red-if-checked lightgrey-else"
                >Datenträger weißt Probleme auf
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="row"
          [hidden]="!form.get('dataMediaOkTechnicalDepartment').enabled"
        >
          <div class="col-12">
            <mat-button-toggle-group
              formControlName="dataMediaOkTechnicalDepartment"
              aria-label="Datenträger OK "
              class="mb-2 block"
              disabled
              readonly
            >
              <mat-button-toggle
                value="true"
                class="bold-if-checked green-if-checked lightgrey-else"
              >
                Datenträger OK (Technik)
              </mat-button-toggle>
              <mat-button-toggle
                value="false"
                class="bold-if-checked red-if-checked lightgrey-else"
                >Datenträger weißt Probleme auf
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!--<pre>{{toSaveToStockItem|json}}</pre>-->
        <!--<pre>{{stockItems$|async|json}}</pre>-->
      </div>
      <div mat-dialog-actions class="row">
        <div class="offset-6 col-6 text-right">
          <button mat-button color="grey" (click)="close()">
            <mat-icon>close</mat-icon>
            Schließen
          </button>
          <!--          <button mat-button color="green" *ngIf="form.get('hardwareTyp').value==='CustomerStockItem'"-->
          <!--                  (click)="submitSaveAndAddReplacement()">-->
          <!--            <mat-icon>save</mat-icon>-->
          <!--            Speichern und Ersatzdatenträger hinzufügen-->
          <!--          </button>-->
        </div>
      </div>
    </form>
  `
})
export class ShowCustomerHardwareDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  product$: Observable<Product>;
  seperatedSerialNumbers = [];
  toSaveToStockItem: ReplacementStockItem = null;

  stockItems$: Observable<Array<ReplacementStockItem>>;

  constructor(
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    private actions$: Actions,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<ShowCustomerHardwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stockItem: StockItem }
  ) {
    super();
  }

  get dataMediumFormArray(): FormArray {
    return this.form.controls.dataMediums as FormArray;
  }

  get mediaFormTemplate(): FormGroup {
    return this.fb.group({
      '@id': this.fb.control(null),
      '@type': this.fb.control(null),
      state: this.fb.control(null),
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control(''),
      storageSystemSize: this.fb.control(0, [
        Validators.required,
        Validators.min(1)
      ]),
      storageSystemSerialNumber: this.fb.control(null, [Validators.required]),
      storageNumber: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(CustomerReplacementStockItemsSelectors.isLoading),
      this.store$.select(CustomerStockItemsSelectors.isLoading),
      this.store$.select(DataMediumsSelectors.isLoading)
    ]);
    this.initForm();
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }

  makeFg(formGroup: any): FormGroup {
    return formGroup as FormGroup;
  }

  fg(name): FormGroup {
    return this.form.get(name) as FormGroup;
  }

  close(reason: string = null): void {
    this.dialogRef.close(reason);
  }

  addDataMediaLine(): void {
    (this.form.controls.dataMediums as FormArray).push(this.mediaFormTemplate);
  }

  private initForm(): void {
    this.form = this.fb.group({
      hardwareTyp: this.fb.control('CustomerStockItem', [Validators.required]),
      order: this.fb.control(null, [Validators.required]),
      storageSystemType: this.fb.control(null, [Validators.required]),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(''),
      accessories: this.fb.control(''),
      dataMediaOkLogisticsDepartment: this.fb.control(null),
      dataMediaOkTechnicalDepartment: this.fb.control(null),
      withEnclosure: this.fb.control(true),
      enclosure: this.mediaFormTemplate,
      dataMediums: this.fb.array([this.mediaFormTemplate])
    });
    this.form
      .get('withEnclosure')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(withEnclosure => {
        if (withEnclosure) {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.enable();
          });
        } else {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.disable();
          });
        }
      });

    if (this.data?.stockItem) {
      console.log(this.data.stockItem);
      if (this.data.stockItem.dataMediums?.length > 1) {
        this.data.stockItem.dataMediums.forEach((a, i) => {
          if (i === 0) {
            return;
          }
          this.addDataMediaLine();
        });
      }
      this.form.patchValue(this.data?.stockItem);
      if (this.data?.stockItem?.enclosure?.storageSystemSerialNumber) {
        this.form.get('withEnclosure').setValue(true);
      } else {
        this.form.get('withEnclosure').setValue(false);
      }

      if (this.data?.stockItem['@type'] === 'CustomerStockItem') {
        this.form.get('hardwareTyp').setValue('CustomerStockItem');
        this.form
          .get('dataMediaOkLogisticsDepartment')
          .setValue(
            this.data?.stockItem?.dataMediaOkLogisticsDepartment === true
              ? 'true'
              : this.data?.stockItem?.dataMediaOkLogisticsDepartment === false
              ? 'false'
              : null
          );
        this.form
          .get('dataMediaOkTechnicalDepartment')
          .setValue(
            this.data?.stockItem?.dataMediaOkTechnicalDepartment
              ? 'true'
              : this.data?.stockItem?.dataMediaOkTechnicalDepartment === false
              ? 'false'
              : null
          );
      } else if (
        this.data?.stockItem['@type'] === 'CustomerReplacementStockItem'
      ) {
        this.form.get('hardwareTyp').setValue('CustomerReplacementStockItem');
        this.form.get('dataMediaOkLogisticsDepartment').disable();
        this.form.get('dataMediaOkTechnicalDepartment').disable();
      } else {
        console.log('wrong Type', this.data?.stockItem['@type']);
      }
    }
  }
}
