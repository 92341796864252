import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {EmailTemplateCategory} from '../../models';

type baseType = EmailTemplateCategory;
const MODULE_NAME = 'MasterDataModule';
const NAMESPACE = 'Email Template Categories';
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE = '[' + MODULE_NAME + '] Create ' + NAMESPACE;
export const Create = createAction(CREATE, props<{ payload: baseType }>());
export const CREATE_SUCCESS = '[' + MODULE_NAME + '] Create ' + NAMESPACE + ' Success';
export const CreateSuccess = createAction(CREATE_SUCCESS, props<{ response: baseType }>());
export const CREATE_FAIL = '[' + MODULE_NAME + '] Create ' + NAMESPACE + ' Fail';
export const CreateFail = createAction(CREATE_FAIL, props<{ response: HttpErrorResponse }>());

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LIST = '[' + MODULE_NAME + '] Read ' + NAMESPACE;
export const ReadList = createAction(READ_LIST);
export const READ_LIST_SUCCESS = '[' + MODULE_NAME + '] Read ' + NAMESPACE + ' Success';
export const ReadListSuccess = createAction(READ_LIST_SUCCESS, props<{ response: any }>());
export const READ_LIST_FAIL = '[' + MODULE_NAME + '] Read ' + NAMESPACE + ' Fail';
export const ReadListFail = createAction(READ_LIST_FAIL, props<{ response: HttpErrorResponse }>());

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_ONE = '[' + MODULE_NAME + '] Read One' + NAMESPACE;
export const Read = createAction(READ_ONE, props<{ iri: string }>());
export const READ_ONE_SUCCESS = '[' + MODULE_NAME + '] Read One ' + NAMESPACE + ' Success';
export const ReadSuccess = createAction(READ_ONE_SUCCESS, props<{ response }>());
export const READ_ONE_FAIL = '[' + MODULE_NAME + '] Read One ' + NAMESPACE + ' Fail';
export const ReadFail = createAction(READ_ONE_FAIL, props<{ response: HttpErrorResponse }>());

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE = '[' + MODULE_NAME + '] Update ' + NAMESPACE + '';
export const Update = createAction(UPDATE, props<{ iri: string, payload: baseType }>());
export const UPDATE_SUCCESS = '[' + MODULE_NAME + '] Update ' + NAMESPACE + ' Success';
export const UpdateSuccess = createAction(UPDATE_SUCCESS, props<{ response: baseType }>());
export const UPDATE_FAIL = '[' + MODULE_NAME + '] Update ' + NAMESPACE + ' Fail';
export const UpdateFail = createAction(UPDATE_FAIL, props<{ response: HttpErrorResponse }>());

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE = '[' + MODULE_NAME + '] Delete ' + NAMESPACE + '';
export const Delete = createAction(DELETE, props<{ iri: string }>());
export const DELETE_SUCCESS = '[' + MODULE_NAME + '] Delete ' + NAMESPACE + ' Success';
export const DeleteSuccess = createAction(DELETE_SUCCESS, props<{ iri: string }>());
export const DELETE_FAIL = '[' + MODULE_NAME + '] Delete ' + NAMESPACE + ' Fail';
export const DeleteFail = createAction(DELETE_FAIL, props<{ response: HttpErrorResponse }>());
