import { NgModule } from '@angular/core';

import * as formModuleGuards from './guards';
import * as fromModuleComponents from './components';
import * as fromModuleContainers from './containers';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './services/auth.service';
import { UserProfilesService } from './services/user-profiles.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    ...fromModuleComponents.AllComponents,
    ...fromModuleContainers.AllContainers
  ],
  exports: [
    ...fromModuleComponents.AllComponents,
    ...fromModuleContainers.AllContainers
  ],
  providers: [AuthService, UserProfilesService, ...formModuleGuards.AllGuards]
})
export class AuthModule {}
