import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageSystemsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { StorageSystemsService } from '../../services/storage-systems.service';

@Injectable()
export class StorageSystemsEffects {
  createStorageSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemsActions.CreateStorageSystem),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createStorageSystem(payload).pipe(
          map(response =>
            StorageSystemsActions.CreateStorageSystemSuccess({ response })
          ),
          catchError(response =>
            of(StorageSystemsActions.CreateStorageSystemFail({ response }))
          )
        );
      })
    )
  );
  getStorageSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemsActions.ReadStorageSystem),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getStorageSystem(iri).pipe(
          map(response =>
            StorageSystemsActions.ReadStorageSystemSuccess({ response })
          ),
          catchError(response =>
            of(StorageSystemsActions.ReadStorageSystemFail({ response }))
          )
        );
      })
    )
  );
  getStorageSystems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemsActions.ReadStorageSystems),
      map(action => action),
      switchMap(({ page, params }) => {
        return this.service.getStorageSystems(page, params).pipe(
          map(response =>
            StorageSystemsActions.ReadStorageSystemsSuccess({ response })
          ),
          catchError(response =>
            of(StorageSystemsActions.ReadStorageSystemsFail({ response }))
          )
        );
      })
    )
  );
  updateStorageSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemsActions.UpdateStorageSystem),
      map(action => action),
      switchMap(payload => {
        return this.service.updateStorageSystem(payload).pipe(
          map(response =>
            StorageSystemsActions.UpdateStorageSystemSuccess({ response })
          ),
          catchError(response =>
            of(StorageSystemsActions.UpdateStorageSystemFail({ response }))
          )
        );
      })
    )
  );
  deleteStorageSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSystemsActions.DeleteStorageSystem),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteStorageSystem(iri).pipe(
          map(() => StorageSystemsActions.DeleteStorageSystemSuccess({ iri })),
          catchError(response =>
            of(StorageSystemsActions.DeleteStorageSystemFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StorageSystemsActions.DeleteStorageSystemFail,
          StorageSystemsActions.UpdateStorageSystemFail,
          StorageSystemsActions.ReadStorageSystemsFail,
          StorageSystemsActions.ReadStorageSystemFail,
          StorageSystemsActions.CreateStorageSystemFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  fileSystemActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageSystemsActions.CreateStorageSystemSuccess,
        StorageSystemsActions.UpdateStorageSystemSuccess
      ),
      map(({ type }) => {
        const text =
          type === StorageSystemsActions.CREATE_STORAGE_SYSTEM_SUCCESS
            ? 'Ein neues Speichersystem wurde angelegt.'
            : 'Das Speichersystem wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: StorageSystemsService,
    private notifierService: NotifierService
  ) {}
}
