import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit, Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {ShippingProvider} from '../../../shipping/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import {extractIri} from '../../utilities/objects.utility';
import {getUuidFromIri} from '../../utilities/strings.utility';
import {ShipmentServicesService} from '../../../shipping/services/shipment-services.service';

@Component({
  selector: 'app-shipping-service-select',
  styleUrls: ['./shipping-service-select.component.scss'],
  template: `
    <div [formGroup]="form">
      <ng-select
        [class.ng-invalid]="form.invalid"
        [class.ng-touched]="form.invalid"
        formControlName="selectedElement"
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        placeholder="Versand-Service">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>

      </ng-select>
      <div class="mat-error" [hidden]="form.valid">
        <app-form-error fieldName="selectedElement" [formGroup]="form"></app-form-error>
      </div>

    </div>
  `

})
export class ShippingServiceSelectComponent extends BaseOnDestroyComponent implements OnInit, ControlValueAccessor, OnChanges {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<ShippingProvider> = new EventEmitter<ShippingProvider>();
  @Input() provider: string;
  items$: BehaviorSubject<Array<ShippingProvider>> = new BehaviorSubject<Array<ShippingProvider>>([]);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedItem: ShippingProvider;

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private shipmentServiceService: ShipmentServicesService,
    @Self() @Optional() public control: NgControl
  ) {
    super();
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    if (this.control) {
      this.control.valueAccessor = this;
    }

  }

  ngOnInit(): void {
    this.form.get('selectedElement').valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });
    this.loadShipmentServices();
    if (this.control) {
      // this.form.get('input').setValidators(this.control.validator);

      this.control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
        this.form.controls.selectedElement.setErrors(this.control.control.errors);
        this.form.updateValueAndValidity();
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.provider) {
      this.loadShipmentServices();
    }
  }

  setSelectedItem(element: ShippingProvider): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: ShippingProvider): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }

  private loadShipmentServices(): void {
    this.isLoading$.next(true);
    this.shipmentServiceService.getShipmentServices(getUuidFromIri(this.provider))
      .pipe(takeUntil(this.onDestroy$), take(1))
      .subscribe((res) => {
          this.isLoading$.next(false);
          const values = res['hydra:member'];
          this.items$.next(values);
          if (values?.length > 0) {
            this.form?.get('selectedElement')?.enable();
          } else {
            this.form?.get('selectedElement')?.disable();
          }

        },
        error => {
          console.log(error);
          this.isLoading$.next(false);
        });
  }
}
