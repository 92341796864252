import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';

@Injectable()
export class DocumentDeliveryProvidersService {
  constructor(private apiService: AbstractApiService) {}

  // Security: no roles available
  readDocumentDeliveryProvider(iri: string): Observable<AbstractApiResponse> {
    return this.apiService.getObject(iri, true);
  }

  // Security: Constant
  readDocumentDeliveryProviders(): Observable<AbstractApiResponse> {
    return of({
      'hydra:member': [
        { '@id': '/api/email_document_deliveries', name: 'per E-Mail' },
        { '@id': '/api/letter_xpress_document_deliveries', name: 'per Brief' },
        {
          '@id': '/api/registered_letter_document_deliveries',
          name: 'per Einschreiben'
        }
      ]
    });
  }
}
