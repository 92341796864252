import {Component, OnDestroy, OnInit, TemplateRef, ViewChildren} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';

import {FeedbackRequestsActions, FeedbackRequestsSelectors} from '../../store';
import {ApplicationState} from '../../../application-state/store';
import {CustomerCommentsSelectors} from '../../../customers/store/selectors';
import {QuillEditorComponent} from 'ngx-quill';
import {FormBuilder, FormGroup,} from '@angular/forms';
import {ReplyCommentModalComponent} from '../../../shared/components/reply-comment-modal/reply-comment-modal.component';
import {CustomerComment} from '../../../customers/models';
import {FeedbackRequest} from '../../models';

@Component({
  selector: 'app-user-notifications',
  styleUrls: ['user-notifications.component.scss'],
  template: `

    <notifications-counter
      [notificationsCount]="notificationsCount$ | async"
      (requestOpenDropdown)="menuTrigger.openMenu()"></notifications-counter>

    <span #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></span>
    <mat-menu #menu="matMenu">

      <div class="dropdown">
        <app-feedback-requests-list
          [notifications$]="notifications$"
          [customerCommentsEntities$]="customerCommentsEntities$"
          (requestOpenReplyForm)="handleOpenReplyFormModal($event)"></app-feedback-requests-list>
      </div>
    </mat-menu>

  `
})
export class UserNotificationsComponent implements OnInit, OnDestroy {

  customerCommentsEntities$: Observable<{ [iri: string]: CustomerComment }>;
  notifications$: Observable<Array<FeedbackRequest>>;
  notificationsCount$: Observable<number>;
  onDestroy$: Subject<any> = new Subject<any>();
  contents: { [id: string]: string } = {
    confirmationText: 'Antworten'
  };

  form: FormGroup;

  constructor(
    private store: Store<ApplicationState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private actions$: Actions) {
  }

  @ViewChildren(QuillEditorComponent)
  editors: { [property: string]: any };

  ngOnInit(): void {

    // Load Related Comments for Preview
    this.actions$.pipe(
      ofType(FeedbackRequestsActions.UpdateFeedbackRequestSuccess),
      takeUntil(this.onDestroy$)
    ).subscribe(({type, response}) => {
      this.store.dispatch(FeedbackRequestsActions.LoadFeedbackRequests());
    });


    this.store.dispatch(FeedbackRequestsActions.LoadFeedbackRequests());

    this.customerCommentsEntities$ = this.store.pipe(select(CustomerCommentsSelectors.selectCustomerCommentEntities));
    this.notifications$ = this.store.pipe(select(FeedbackRequestsSelectors.selectFeedbackRequests));
    this.notificationsCount$ = this.store.pipe(select(FeedbackRequestsSelectors.selectAmountFeedbackRequests));

  }

  handleOpenReplyFormModal({feedbackRequest}): void {
    this.dialog.open(ReplyCommentModalComponent, {
      disableClose: true,
      panelClass: 'pos-relative',
      data: {feedbackRequest}
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
