import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';

import * as fromLeadsModuleModels from '../../models';
import {Lead, LeadOriginType} from '../../models';
import {select, Store} from '@ngrx/store';
import * as LeadsModuleSelectors from '../../store/selectors';
import {
  LeadContactTypesSelectors,
  LeadOriginTypesSelectors,
  LeadsSelectors
} from '../../store/selectors';
import {filter, map, takeUntil} from 'rxjs/operators';
import {cloneDeep} from 'lodash-es';
import {ObjectsUtility} from '../../../shared/utilities/objects.utility';
import {ApplicationState} from '../../../application-state/store';
import {Observable, Subject} from 'rxjs';
import {
  CustomerContactsSelectors,
  CustomersSelectors
} from '../../../customers/store/selectors';
import {Customer, CustomerContact} from '../../../customers/models';
import {StorageSystemsSelectors} from '../../../master-data/store/selectors';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import {
  LeadOriginTypesActions,
  LeadsActions
} from '../../store';
import {sortByCreatedAtDate} from '../../../shared/utilities/array.utility';
import {AbstractMercureService} from '../../../shared/services';
import {MatMenuTrigger} from "@angular/material/menu";
import {Go} from "../../../application-state/store/actions/router.actions";
import {StringsUtility} from "../../../shared/utilities/strings.utility";
import {ModalDialogOptions} from "../../../application-state/models";
import {DialogComponent} from "../../../shared/components/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

export interface MenuWithSubmenuItem {
  label: string;
  value: string;
  icon?: string;
  backgroundColor?: string;
  submenu?: MenuWithSubmenuItem[];
}

@Component({
  selector: 'app-leads-list',
  styleUrls: ['leads-list.component.scss'],
  templateUrl: 'leads-list.component.html'
})
export class LeadsListComponent implements OnInit, OnDestroy {

  stored:any=[];
  selectedLeads: any;
  isCtrlPressed = false;
  isShiftPressed = false;
  isCdkDragActive = false;
  isLoading$: Observable<boolean>;
  contextMenuItems: MenuWithSubmenuItem[] = [];
  onDestroy$: Subject<any> = new Subject<any>();
  selectedSubmenuItems: MenuWithSubmenuItem[] = [];
  customersEntities: { [iri: string]: Customer } = {};
  leads: Array<Array<fromLeadsModuleModels.Lead>> = [];
  leadOriginTypesEntities: { [iri: string]: LeadOriginType } = {};
  customerContactsEntities: { [iri: string]: CustomerContact } = {};

  headingsMapping = {
    1: 'Neue Anfragen',
    2: 'Tag 1',
    3: 'Tag 2',
    4: 'Tag 3',
    5: 'Nachkontakt herstellen',
    6: 'Final Geschlossen'
  };

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private mercureService: AbstractMercureService,
    private zone: NgZone
  ) {
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent): void {
    this.isCtrlPressed = event.ctrlKey || event.metaKey;
    this.isShiftPressed = event.shiftKey;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    this.isCtrlPressed = event.ctrlKey || event.metaKey;
    this.isShiftPressed = event.shiftKey;
  }

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition: { left: string; top: string } = {left: '0px', top: '0px'};

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(LeadContactTypesSelectors.isLoading),
      this.store$.select(LeadOriginTypesSelectors.isLoading),
      this.store$.select(LeadsSelectors.isLoading),
      this.store$.select(StorageSystemsSelectors.isLoading),
    ]);
    this.loadLeads();
    this.loadLeadOriginTypes();
    this.store$
      .pipe(
        takeUntil(this.onDestroy$),
        select(LeadsModuleSelectors.LeadsSelectors.selectNewLeads),
        map(data =>
          ObjectsUtility.groupBy(
            data.sort(sortByCreatedAtDate),
            item => item.column
          )
        )
      )
      .subscribe(leads => {
        this.zone.run(() => {
          this.leads = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            ...cloneDeep(leads)
          };
          this.sortLeads(this.leads);
        });
      });

    this.store$
      .select(CustomersSelectors.selectCustomersEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.customersEntities = value;
      });
    this.store$
      .select(CustomerContactsSelectors.selectCustomerContactEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.customerContactsEntities = value;
      });
    this.mercureService.createEventSource().subscribe(data => {
      this.moveLeadToColumn(this.leads, data);
      this.sortLeads(this.leads);
    });
  }
  public isLeadInArrayIndex(leads: Lead[] , lead: Lead) {
    return leads?.findIndex(l => l['@id'] === lead['@id']);
  }
  public moveLeadToColumn(leads: Record<number, any[]>, newLead: Record<string, any>) {
    for (const columnKey in leads) {
      const column = leads[columnKey];
      const existingIndex = this.isLeadInArrayIndex(column, newLead);
      if (existingIndex !== -1) {
        column.splice(existingIndex, 1);
        break;
      }
    }

    if (!leads[newLead.column]) {
      leads[newLead.column] = [];
    }
    leads[newLead.column].push(newLead);

    return leads;
  };

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  loadLeads(): void {
    this.store$.dispatch(
      LeadsActions.ReadLeads({page: -1, params: {state: 'new'}})
    );
    this.mercureService.addSubscription('/leads/{uuid}');
  }

  onCdkDragEnded() {
    this.isCdkDragActive = false;
  }

  onCdkDragStarted() {
    this.isCdkDragActive = true;
  }

  loadLeadOriginTypes(): void {
    this.store$
      .select(LeadOriginTypesSelectors.selectLeadOriginTypesEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.leadOriginTypesEntities = value;
      });
    loadIfNotLoaded(
      this.store$,
      LeadOriginTypesSelectors.isLoaded,
      LeadOriginTypesActions.LoadLeadOriginTypes()
    );
  }

  public loadLeadMenuItems(event): void {
    this.contextMenuItems = [
      {
        label: 'leads.contextmenu.move',
        value: 'Verschieben',
        icon: 'open_in_new',
        submenu: [
          {label: 'leads.contextmenu.submenu.move.new_inquiries', value: 1},
          {label: 'leads.contextmenu.submenu.move.day_1', value: 2},
          {label: 'leads.contextmenu.submenu.move.day_2', value: 3},
          {label: 'leads.contextmenu.submenu.move.day_3', value: 4},
          {label: 'leads.contextmenu.submenu.move.night_contact', value: 5},
          {label: 'leads.contextmenu.submenu.move.final_closed', value: 6},
        ]
      },
      {
        label: 'leads.contextmenu.status',
        value: 'leads.contextmenu.status',
        icon: 'circle',
        submenu: event
      },
      {
        label: 'leads.contextmenu.delete',
        value: 'Löschen',
        icon: 'delete'
      },
      {
        label: 'leads.contextmenu.archive',
        value: 'to_archived',
        icon: 'archive'
      }
    ];
  }

  handleDeleteLead(leads: Lead[]): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchtest du diese Anfrage unwiderruflich löschen?',
          heading: 'Anfrage löschen',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => !!hasConfirmedModal)
      )
      .subscribe(() => {
        leads.map((e) => {
          this.store$.dispatch(
            LeadsActions.DeleteLead({ iri: e['@id'] })
          );
        })
      });
  }

  handleItemClick(item: MenuWithSubmenuItem): void {
    if(this.selectedLeads) {
      switch (item.label) {
        case'leads.contextmenu.submenu.move.day_1':
        case'leads.contextmenu.submenu.move.day_2':
        case'leads.contextmenu.submenu.move.day_3':
        case'leads.contextmenu.submenu.move.final_closed':
        case'leads.contextmenu.submenu.move.night_contact':
        case'leads.contextmenu.submenu.move.new_inquiries':
          this.selectedLeads.sort((a: Lead,b: Lead) => a.columnRank - b.columnRank);
          const newLeads = this.selectedLeads.map((e, i) => ({
            '@id': e['@id'],
            column: Number(item.value),
            columnRank: i + 1
          }));

          const oldLeads = this.leads[Number(item.value)].map((e, i) => ({
            '@id': e['@id'],
            columnRank: newLeads.length + i + 1
          }));

          const mergedObject: { [key: number]: Lead } = {};
          this.mergeLeadsObject(newLeads, mergedObject);
          this.mergeLeadsObject(oldLeads, mergedObject);
          const payload: {leads: Lead[]} = {leads: Object.values(mergedObject)};

          this.store$.dispatch(LeadsActions.UpdateLeadColumns({payload}));
          break;
        case 'Neu':
        case 'Vormittagskontakt':
        case 'Nachmittagskontakt':
        case 'Rückfrage extern':
        case 'Rückfrage intern':
        case 'Partner-Anfrage':
          this.selectedLeads.map((e, index) => {
            this.store$.dispatch(
              index === this.selectedLeads.length - 1
                ? LeadsActions.UpdateLead({iri: e['@id'], payload: {leadContactType: item.value}})
                : LeadsActions.UpdateLeadSilently({iri: e['@id'], payload: {leadContactType: item.value}})
            );
          })
          break;
        case'leads.contextmenu.delete':
          this.handleDeleteLead(this.selectedLeads);
          break;
        case'leads.contextmenu.archive':
          this.selectedLeads.map((e) => {
            this.store$.dispatch(
              LeadsActions.ArchiveLead(
                {
                  iri: e['@id'],
                  payload: {
                    workflow: 'lead_status',
                    transition: item.value
                  }
                }
              )
            );
          })
          break;
        default:
          break;
      }
      this.selectedLeads = [];
    }
    this.sortLeads(this.leads);
  }

  mergeLeadsObject(leads: Lead[], object: { [key: number]: Lead }): void {
    leads.forEach(item => {
      object[item.columnRank] = { ...object[item.columnRank], ...item };
    });
  }

  handleSubmenuClick(item: MenuWithSubmenuItem): void {
    this.selectedSubmenuItems = item.submenu || [];
  }

  handleRequestUpdateLeadView(event: Event, iri): void {
    if(!this.isCtrlPressed && !this.isShiftPressed) {
      this.store$.dispatch(
        Go({path: ['leads', StringsUtility.getUuidFromIri(iri)]})
      );
    }
  }

  sortLeads(leads: Lead[][]) {
    for(let i = 1; i  <= 6; i++) {
      if(leads[i]) {
        leads[i].sort((a, b) => a.columnRank - b.columnRank)
      }
    }
    return leads;
  }

  handleRequestUpdateLead(event: CdkDragDrop<any>): void {
    const newList = event.container.data;
    const prevList = event.previousContainer.data;
    const oldColumn = parseInt(
      event.previousContainer.element.nativeElement.dataset.column,
      10
    );
    const newColumn = parseInt(
      event.container.element.nativeElement.dataset.column,
      10
    );
    if (newColumn === oldColumn) {
      moveItemInArray(newList, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        prevList,
        newList,
        event.previousIndex,
        event.currentIndex
      );
    }
    const payload = {
      leads: newList.map((e, i) => ({
        '@id': e['@id'],
        column: newColumn,
        columnRank: i + 1
      }))
    };
    this.store$.dispatch(LeadsActions.UpdateLeadColumns({payload}));
  }

  openContextMenu(event: MouseEvent, fromDragContainer: boolean, lead?: Lead): void {
    const x = event.clientX + 'px';
    const y = event.clientY + 'px';
    if(fromDragContainer) {
      if(this.selectedLeads > 0) {
        event.preventDefault();
        this.contextMenuPosition = {left: x, top: y};
        if (this.contextMenu) {
          this.contextMenu.openMenu();
        }
      }
    } else {
      event.preventDefault();
      if(this.selectedLeads.length === 0) {
        this.selectedLeads = [lead];
      }
      this.contextMenuPosition = {left: x, top: y};
      if (this.contextMenu) {
        this.contextMenu.openMenu();
      }
    }
  }
}
