import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import {InvoiceMail, PayableInvoice, PayableInvoiceCopy} from '../models';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class PayableInvoicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createPayableInvoice(
    payload: PayableInvoice
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/invoices`, payload)
    );
  }

  copyPayableInvoice(iri: string, payload?: PayableInvoiceCopy): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_COPY',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(iri + '/copy', payload || {}, true)
    );
  }

  createPayableInvoiceFromInvoice(payload: {
    finalInvoice: string;
    percentageOfFinalInvoice: string;
  }): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PARTIAL_INVOICE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/partial_invoices/from_invoice`,
              payload
            )
    );
  }

  readPayableInvoice(iri: string): Observable<PayableInvoice> {
    return this.rolesService.userHasRoleFilter<PayableInvoice>(
      'ROLE_MWS_INVOICE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readPayableInvoices(
    page = -1,
    params?: ParameterBag
  ): Observable<Array<any>> {
    return this.rolesService.userHasRoleFilter<Array<any>>(
      'ROLE_MWS_ABSTRACT_PAYABLE_INVOICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(
                '/abstract_payable_invoices',
                page,
                params
              )
            )
    );
  }

  readPayableInvoiceAsPdf(invoice: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              invoice + '/preview?disposition=attachment',
              true
            )
    );
  }

  readFirstReminderAsPdf(invoice: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              invoice + '/first_reminder/preview',
              true
            )
    );
  }

  readSecondReminderAsPdf(invoice: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              invoice + '/second_reminder/preview',
              true
            )
    );
  }

  readPayableInvoiceChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updatePayableInvoice(
    iri: string,
    payload: PayableInvoice
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  sendMail(iri: string, payload: InvoiceMail): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  bookPayableInvoice(iri: string): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    const payload = { workflow: 'invoice', transition: 'book' };
    return this.apiService.patchObject(url, payload, true);
  }

  bookFullPaymentReceived(iri: string): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    const payload = {
      workflow: 'invoice',
      transition: 'full_payment_received'
    };
    return this.apiService.patchObject(url, payload, true);
  }

  cancelPayableInvoice(iri: string): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    const payload = { transition: 'cancel', workflow: 'invoice' };
    return this.apiService.patchObject(url, payload, true);
  }

  deletePayableInvoice(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
