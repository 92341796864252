import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { CustomerPartnerStatus } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerPartnerStatusesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getCustomerPartnerStatuses(): Observable<Array<CustomerPartnerStatus>> {
    return this.rolesService.userHasRoleFilter<Array<CustomerPartnerStatus>>(
      'ROLE_MWS_CUSTOMER_PARTNER_STATUS_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/customer_partner_statuses`)
    );
  }
}
