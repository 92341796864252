import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ReplacementDataMediumSourcesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { ReplacementDataMediumSourcesService } from '../../services/replacement-data-medium-sources.service';

@Injectable()
export class ReplacementDataMediumSourcesEffects {
  readReplacementDataMediumSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSource
      ),
      map(action => action),
      switchMap(({ iri }) => {
        return this.aps.readReplacementDataMediumSource(iri).pipe(
          map(response =>
            ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourceSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourceFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  readReplacementDataMediumSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources
      ),
      map(action => action),
      switchMap(() => {
        return this.aps.readReplacementDataMediumSources().pipe(
          map(response =>
            ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourcesSuccess(
              { response }
            )
          ),
          catchError(response =>
            of(
              ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourcesFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourceFail,
          ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSourcesFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aps: ReplacementDataMediumSourcesService,
    private notifierService: NotifierService
  ) {}
}
