import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {DataMediumsSelectors} from '../store/selectors';
import {DataMediumsActions} from '../store';

@Injectable()
export class DataMediumsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {
    return this.loadDataMediumsDone().pipe(
      switchMap(() => of(true)),
      catchError(() => of(true))
    );
  }

  loadDataMediumsDone(): Observable<boolean> {
    return this.store.pipe(
      select(DataMediumsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          const params = {'order[createdAt]': 'desc'};
          this.store.dispatch(DataMediumsActions.ReadDataMediums({page: 1, params}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
