import { createReducer, on } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import { FeedbackRequestsActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [key: string]: any };
  sendEntities: { [key: string]: any };
  error?: any;
  loadingSendFeedbackRequests: boolean;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  sendEntities: {},
  loading: false,
  loadingSendFeedbackRequests: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(FeedbackRequestsActions.LoadFeedbackRequests, state => {
    return {
      ...state,
      loading: true,
      loaded: true
    };
  }),
  on(FeedbackRequestsActions.LoadSendFeedbackRequests, state => {
    return {
      ...state,
      loadingSendFeedbackRequests: true,
      loaded: true
    };
  }),
  on(
    FeedbackRequestsActions.LoadFeedbackRequestsSuccess,
    (state, { response }) => {
      const entities = keyBy(response['hydra:member'], '@id');
      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    FeedbackRequestsActions.LoadSendFeedbackRequestsSuccess,
    (state, { response }) => {
      const sendEntities = keyBy(response['hydra:member'], '@id');
      return {
        ...state,
        sendEntities,
        loadingSendFeedbackRequests: false,
        loaded: true
      };
    }
  ),
  on(
    FeedbackRequestsActions.UpdateFeedbackRequestSuccess,
    (state, { response }) => {
      // Update in this case is pretty much a delete action
      // @ts-ignore
      const { [response['@id']]: removed, ...entities } = state.entities;

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);

export const selectEntities = state => state.entities;
