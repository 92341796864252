import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CorrectionInvoice, Offer, PartialInvoice } from '../../models';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment/moment';
import { cloneDeep } from 'lodash-es';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Department } from '../../../master-data/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Customer } from '../../../customers/models';
import { Order } from '../../../orders/models';
import { InvoiceRecipient } from '../../models/invoice-recipient.interface';
import { CustomersSelectors } from '../../../customers/store/selectors';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { formatNumber } from '@angular/common';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceItemDialogComponent } from '../invoice-item-dialog/invoice-item-dialog.component';

@Component({
  selector: 'app-correction-invoice-preview',
  styleUrls: ['./correction-invoice-preview.component.scss'],
  template: `
    <div class="invoice-wrapper">
      <div
        class="state-wrapper"
        [style.background-color]="getStateBackgroundColor()"
      >
        <span *ngIf="!invoice || !invoice['@id']"
          >Nicht gespeicherter Entwurf!</span
        >
        <span *ngIf="invoice && invoice.state === 'draft'"
          >? Korrekturrechnung nicht gebucht</span
        >
        <span *ngIf="invoice && invoice.state === 'booked'"
          >✔ Korrekturrechnung gebucht</span
        >
        <span *ngIf="invoice && invoice.state === 'paid_out'"
          >✔ Korrekturrechnung gebucht und ausgezahlt.</span
        >
        <span *ngIf="invoice && invoice.state === 'canceled'"
          >✔ Korrekturrechnung storniert</span
        >
      </div>
      <div class="invoice-content">
        <div class="row invoice-header">
          <div class="col-8">
            <div class="countdown-wrapper my-3">
              <div
                class="countdown"
                *ngIf="isEditable && invoice.editableUntil"
              >
                Korrekturrechnung kann noch
                {{ getHourDifferential(invoice.editableUntil) }}h bearbeitet
                werden.
              </div>
            </div>
            <div class="invoice-action-box-wrapper my-3">
              <!--  [actions]="['copy', 'cancel', 'correct', 'delete']"
                              (requestCancelInvoice)="handleCancelInvoice($event)"
                              (requestCopyInvoice)="handleCopyInvoice()"
                              (requestCreateCorrectionInvoice)="handleCreateCorrectionInvoice($event)"
                              (requestDeleteInvoice)="handleDeleteInvoice($event)"-->
              <app-invoice-action-box
                [invoice]="invoice"
              ></app-invoice-action-box>
            </div>
            <div class="invoice-head-recipient-wrapper mt-5">
              <app-invoice-head-recipient
                class="my-5"
                [invoice$]="invoice$"
                (updateCustomer)="handleUpdateCustomer($event)"
                (updateOrder)="handleUpdateOrder($event)"
                (updateRecipient)="handleUpdateRecipient($event)"
              ></app-invoice-head-recipient>
            </div>
          </div>
          <div class="col-4">
            <app-invoice-head-issuer
              [invoice$]="invoice$"
              (updateIssuer)="handleUpdateIssuer($event)"
            ></app-invoice-head-issuer>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-8">
            <span class="heading--h2">
              {{ heading }}
            </span>
          </div>

          <div class="col-4 text-right">
            <span class="heading--h2">
              Datum/Date:
              {{
                (invoice.createdAt ? invoice.createdAt : null) | date: 'dd.MM.Y'
              }}
            </span>
          </div>
        </div>
        <div class="row my-2">
          <p class="col text-color-grey">
            Sie erhalten heute diese Korrekturrechnung:
          </p>
        </div>

        <div class="row my-2 mb-4">
          <app-invoice-items-summary
            [invoice$]="invoice$"
            (requestUpdateInvoice)="updateInvoice.emit($event)"
          ></app-invoice-items-summary>
        </div>
        <div class="row">
          <div class="col-7 ">
            <button
              mat-flat-button
              class="mb-2"
              *ngIf="invoice['@id'] && canEdit"
              (click)="addPosition()"
            >
              <mat-icon class="text-bg-green text-white border-radius-25"
                >add
              </mat-icon>
              <span class="pt-1 ms-2">
                Position hinzufügen
              </span>
            </button>
            <div *ngIf="!invoice['@id']" class="text-color-red my-3">
              Bitte erst den Entwurf speichern um Positionen hinzufügen zu
              können.
            </div>
            <p
              class="text-color-grey"
              *ngIf="
                invoice.vatLiability ===
                'VAT_LIABILITY_REVERSE_CHARGE_EUROPEAN_UNION'
              "
            >
              Steuerfreie innergemeinschaftliche Lieferung/Leistung. Reverse
              Charge.
            </p>
            <p
              class="text-color-grey"
              *ngIf="
                invoice.vatLiability ===
                'VAT_LIABILITY_REVERSE_CHARGE_THIRD_COUNTRY'
              "
            >
              Steuerfreie Ausfuhrlieferung/Leistung (Drittland). Reverse Charge.
            </p>
            <p class="text-color-grey" *ngIf="customer?.taxNumber">
              Steuernummer Kunde: {{ customer?.taxNumber || '-' }}
            </p>
          </div>
          <div class="col-5">
            <app-invoice-total [invoice]="invoice"></app-invoice-total>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-invoice-custom-text
              [invoice$]="invoice$"
              [readonly]="!canEdit"
            ></app-invoice-custom-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-invoice-footer [invoice$]="invoice$"></app-invoice-footer>
          </div>
        </div>
      </div>
      <!--      <pre>{{ invoice | json }}</pre>-->
    </div>
  `
})
export class CorrectionInvoicePreviewComponent extends BaseOnDestroyComponent
  implements OnInit, OnDestroy {
  @Input() invoice$: Observable<CorrectionInvoice>;
  invoice: CorrectionInvoice;
  invoiceWithChanges: CorrectionInvoice;
  onDestroy$: Subject<any> = new Subject<any>();
  @Output() updateInvoice: EventEmitter<CorrectionInvoice> = new EventEmitter<
    CorrectionInvoice
  >();
  @Output() updateOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() updateCustomer: EventEmitter<Customer> = new EventEmitter<
    Customer
  >();
  customer: Customer;
  department: Department;

  constructor(
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
    private store$: Store<ApplicationState>
  ) {
    super();
  }

  get isEditable(): boolean {
    return this.invoice && 'editable' in this.invoice && this.invoice?.editable;
  }

  get hasDeliveryDate(): boolean {
    return this.invoice && 'deliveryType' in this.invoice;
  }

  get heading(): string {
    return this.invoice?.correctionInvoiceNumber
      ? 'Korrekturrechnung / Correction Invoice ' +
          this.invoice?.correctionInvoiceNumber
      : 'Korrekturrechungsentwurf';
  }

  get canEdit(): boolean {
    if ('editable' in this.invoice) {
      return this.invoice.editable;
    }
    return 'state' in this.invoice ? this.invoice.state === 'draft' : false;
  }

  ngOnInit(): void {
    this.invoice$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(invoice => !!invoice)
      )
      .subscribe(invoice => {
        this.invoice = invoice;
        this.invoiceWithChanges = invoice;
        if (invoice.customerNumber) {
          this.store$
            .select(CustomersSelectors.sByCustomerNumber, {
              customerNumber: invoice.customerNumber
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i),
              take(1)
            )
            .subscribe(c => {
              this.customer = c;
            });
        }
        if (invoice.issuer) {
          this.store$
            .select(DepartmentsSelectors.sByIri, {
              iri: extractIri(invoice.issuer)
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i),
              take(1)
            )
            .subscribe(c => {
              this.department = c;
            });
        }
      });
  }

  fieldInType(field: string, type: any): boolean {
    return field in type;
  }

  getHourDifferential(futureDate: string): number {
    const duration = moment.duration(
      moment(futureDate).diff(moment(new Date()))
    );
    return Math.floor(duration.asHours());
  }

  getStateBackgroundColor(): string {
    if (!('state' in this.invoice)) {
      return '';
    }
    switch (this.invoice?.state) {
      case 'booked':
      case 'paid_out':
        return '#45a352';
      case 'draft':
        return '#6e7c89';
      case 'canceled':
        return '#da0000';
      default:
        return '#6e7c89';
    }
  }

  getInvoiceGrossSum(
    invoice?: CorrectionInvoice | PartialInvoice | Offer
  ): string {
    if (invoice && invoice.grossTotal) {
      return `${formatNumber(
        parseFloat(invoice.grossTotal.value),
        this.locale,
        '1.2-2'
      )} ${invoice.grossTotal.currency}`;
    } else {
      return '-';
    }
  }

  handleUpdateIssuer(selectedIssuers: Department): void {
    const invoice: CorrectionInvoice = cloneDeep(this.invoice);
    invoice.issuer = extractIri(selectedIssuers);
    this.updateInvoice.emit(invoice);
  }

  handleUpdateCustomer(customer: Customer): void {
    this.updateCustomer.emit(customer);
  }

  handleUpdateOrder(order: Order): void {
    this.updateOrder.emit(order);
  }

  handleUpdateRecipient(c: InvoiceRecipient): void {
    if (!this.invoiceWithChanges) {
      return;
    }
    const invoice: CorrectionInvoice = cloneDeep(this.invoiceWithChanges || {});
    invoice.recipient.address = { ...invoice.recipient.address, ...c.address };
    invoice.recipient = { ...invoice.recipient, ...c };
    this.updateInvoice.emit(invoice);
    this.invoiceWithChanges = invoice;
  }

  addPosition(): void {
    this.dialog.open(InvoiceItemDialogComponent, {
      data: { invoice: this.invoice }
    });
  }
}
