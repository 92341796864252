import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {STORAGE_SYSTEM_MANUFACTURERS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';
import {ApplicationState} from '../../../application-state/store';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {StorageSystemManufacturer} from '../../models';
import {State} from '../reducers/storage-system-manufacturers.reducers';

type BaseType = StorageSystemManufacturer;
type StateType = State;
const NAMESPACE = STORAGE_SYSTEM_MANUFACTURERS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, BaseType>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, BaseType>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);
export const sOrderedList = createSelector(
  sList,
  (list) => list.sort((a, b) => a.order - b.order)
);


export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);
