import {Product} from '../../master-data/models';

/**
 * Immutable move item
 */
const move = (arr, from, to) => {
  const clone = [...arr];
  Array.prototype.splice.call(clone, to, 0,
    Array.prototype.splice.call(clone, from, 1)[0]
  );
  return clone;
};

/**
 * Immutable move target a distance
 */
const moveTarget = (arr, target, distance) => {
  const from = arr.indexOf(target);
  if (~from) {
    return move(arr, from, from + distance);
  }
};

export const sortByCreatedAtDate = (a: any, b: any, reverse = false): number => {
  return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) * (reverse ? -1 : 1);
};

export const sortProducts = (a: Product, b: Product): number => {
  if (a.actualStock > 0 && b.actualStock <= 0) {
    return -1;
  } else if (b.actualStock > 0 && a.actualStock <= 0) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};
export const ArrayUtility = {
  move,
  sortProducts,
  moveTarget,
  sortByCreatedAtDate
};
