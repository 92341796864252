import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {OrderCommentsActions, OrdersActions} from '../actions';
import {OrderCommentsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class OrderCommentsEffects {

  constructor(private actions$: Actions, private service: OrderCommentsService, private notifierService: NotifierService) {
  }

  CreateOrderComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.CreateOrderComment),
      switchMap(({payload}) => {
        return this.service.createOrderComment(payload).pipe(
          map((response: any) => OrderCommentsActions.CreateOrderCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.CreateOrderCommentFail({response})))
        );
      })
    );
  });

  ReadOrderComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.ReadOrderComment),
      switchMap(({iri}) => {
        return this.service.readOrderComment(iri).pipe(
          map((response: any) => OrderCommentsActions.ReadOrderCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.ReadOrderCommentFail({response})))
        );
      })
    );
  });

  ReadOrderComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.ReadOrderComments),
      switchMap(({page, params}) => {
        return this.service.readOrderComments(page, params).pipe(
          map((response: any) => OrderCommentsActions.ReadOrderCommentsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.ReadOrderCommentsFail({response})))
        );
      })
    );
  });

  ReadOrderCommentChunk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.ReadOrderCommentChunk),
      switchMap(({uri}) => {
        return this.service.readOrderCommentChunk(uri).pipe(
          map((response: any) => OrderCommentsActions.ReadOrderCommentChunkSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.ReadOrderCommentChunkFail({response})))
        );
      })
    );
  });

  UpdateOrderComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.UpdateOrderComment),
      switchMap(({iri, payload}) => {

        return this.service.updateOrderComment(iri, payload).pipe(
          map((response: any) => OrderCommentsActions.UpdateOrderCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.UpdateOrderCommentFail({response})))
        );
      })
    );
  });

  DeleteOrderComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderCommentsActions.DeleteOrderComment),
      switchMap(({iri}) => {

        return this.service.deleteOrderComment(iri).pipe(
          map(() => OrderCommentsActions.DeleteOrderCommentSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(OrderCommentsActions.DeleteOrderCommentFail({response})))
        );
      })
    );
  });


  OrderCommentActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      OrderCommentsActions.CreateOrderCommentSuccess,
      OrderCommentsActions.UpdateOrderCommentSuccess,
    ),
    map(({type}) => {
      let message = 'Erfolgreich';

      switch (type) {
        case OrderCommentsActions.CREATE_ORDER_COMMENT_SUCCESS:
          message = 'Ein neuer Kommentar wurde angelegt.';
          break;
        case OrderCommentsActions.UPDATE_ORDER_COMMENT_SUCCESS:
          message = 'Der Kommentar wurde aktualisiert.';
          break;
      }

      this.notifierService.show({type: 'success', message});

    })
  ), {dispatch: false});
}
