import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {Actions, ofType} from '@ngrx/effects';
import {clone} from 'lodash-es';
import {CustomerContactsActions} from '../../store';
import {CustomerContact} from '../../models';

// import {MarketingPermissionsRequired} from '../../directives/marketing-permissions-required.directive';

@Component({
  selector: 'customer-contact-form',
  styleUrls: ['customer-contact-form.component.scss'],
  template: `

    <div class="card__heading">
      <span>{{ (presets$ && presets$.getValue()) ? 'Kontakt bearbeiten' : 'Neuen Kontakt anlegen' }}</span>
    </div>

    <div class="card__content p-a--24">

      <div [formGroup]="form" [class.standalone]="isExternalCall">

        <div class="grid">
          <mat-form-field class="column-14">
            <mat-label>Kontakt-Typ*</mat-label>
            <mat-select formControlName="customerContactTypes" multiple>
              <mat-option *ngFor="let type of customerContactTypes" [value]="type['@id']">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="column-7 mat-form-field">
            <ng-select
              placeholder="Anrede"
              [items]="salutations"
              bindLabel="name"
              bindValue="@id"
              [searchable]="false"
              formControlName="salutation"
            ></ng-select>
            <mat-error>
              <app-form-error fieldName="salutation" [formGroup]="form"></app-form-error>
            </mat-error>
          </div>

          <div class="column-7 mat-form-field">
            <ng-select
              placeholder="Akademischer Grad"
              [items]="grades"
              bindLabel="name"
              bindValue="@id"
              [searchable]="false"
              [clearable]="true"
              formControlName="grade"
            ></ng-select>
            <mat-error>
              <app-form-error fieldName="grade" [formGroup]="form"></app-form-error>
            </mat-error>

          </div>

          <mat-form-field class="column-7">
            <mat-label>Vorname</mat-label>
            <input type="text" formControlName="firstName" matInput required>
            <mat-error>
              <app-form-error fieldName="firstName" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="column-7">
            <mat-label>Mittelname</mat-label>
            <input type="text" formControlName="middleName" matInput>
            <mat-error>
              <app-form-error fieldName="middleName" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Nachname</mat-label>
            <input type="text" formControlName="lastName" matInput required>
            <mat-error>
              <app-form-error fieldName="lastName" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Festnetz</mat-label>
            <ngx-11-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="false"
              [preferredCountries]="['de', 'us']"
              formControlName="phone"
              name="phone"
            ></ngx-11-mat-intl-tel-input>
            <mat-error>
              <app-form-error fieldName="phone" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Mobilnummer</mat-label>

            <ngx-11-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="false"
              [preferredCountries]="['de', 'us']"
              formControlName="mobile"
              name="mobile"
            ></ngx-11-mat-intl-tel-input>
            <mat-error>
              <app-form-error fieldName="mobile" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>E-Mail-Adresse*</mat-label>
            <input type="email" formControlName="email" matInput>
            <mat-error>
              <app-form-error fieldName="email" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Vermerke zur Erreichbarkeit</mat-label>
            <input type="text" formControlName="availability" matInput>
            <mat-error>
              <app-form-error fieldName="availability" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>

          <div class="column-14">
            <div class="mat-form-field">
              <mat-label>Marketing-Erlaubnis</mat-label>
              <mat-button-toggle-group [multiple]="true"
                                       formControlName="marketingPermissions"
                                       aria-label="Marketing-Erlaubnis"
                                       class="block small">
                <mat-button-toggle value="marketingPermissionEmail"
                                   class="green-if-checked"
                                   (click)="changeMarketingPermissions('marketingPermissionEmail')">
                  <mat-icon class="">alternate_email</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="marketingPermissionPhone"
                                   class="green-if-checked"
                                   (click)="changeMarketingPermissions('marketingPermissionPhone')">
                  <mat-icon class="">phone</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="marketingPermissionPostal"
                                   class="green-if-checked"
                                   (click)="changeMarketingPermissions('marketingPermissionPostal')">
                  <mat-icon class="">local_post_office</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="marketingPermissionNone"
                                   class="red-if-checked"
                                   (click)="changeMarketingPermissions('marketingPermissionNone')">
                  <mat-icon class="">block</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-error>
                <app-form-error fieldName="marketingPermissions" [formGroup]="form"></app-form-error>
              </mat-error>
            </div>
          </div>


          <div class="m-ta--2 column-14">

            <button *ngIf="(presets$ && presets$.getValue()) || isExternalCall" class="m-r--8"
                    (click)="handleCancelEdit()" type="button" color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="form.invalid || form.pristine" mat-flat-button color="green" (click)="handleSubmit()">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ (presets$ && presets$.getValue()) ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--<pre>{{ presets$?.getValue() | json }}</pre>-->
    <!--<pre>{{ form.value | json }}</pre>-->
  `
})
export class CustomerContactFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() customerContactTypes: Array<fromModuleModels.CustomerContactType>;
  @Input() errors: ErrorsObject;
  @Input() grades: Array<fromMasterDataModuleModels.Grade>;
  @Input() isExternalCall: boolean;
  @Input() presets$: BehaviorSubject<fromModuleModels.CustomerContact>;
  @Input() salutations: Array<fromMasterDataModuleModels.Salutation>;

  @Output() cancelEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestCreateCustomerContact: EventEmitter<fromModuleModels.CustomerContact> = new EventEmitter();
  @Output() requestUpdateCustomerContact: EventEmitter<{
    iri: string,
    payload: fromModuleModels.CustomerContact
  }> = new EventEmitter();

  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService, private actions$: Actions) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {

    // Note: this component might be called from multiple places; presets$ might be undefined
    if (!this.isExternalCall) {
      this.presets$.pipe(
        takeUntil(this.onDestroy$),
        filter((presets: any) => !!presets)
      ).subscribe((contact: CustomerContact) => {
        const formData = {...contact, marketingPermissions: []};
        if (formData.marketingPermissionEmail) {
          formData.marketingPermissions.push('marketingPermissionEmail');
        }
        if (formData.marketingPermissionPhone) {
          formData.marketingPermissions.push('marketingPermissionPhone');
        }
        if (formData.marketingPermissionPostal) {
          formData.marketingPermissions.push('marketingPermissionPostal');
        }
        if (formData.marketingPermissions.length === 0) {
          formData.marketingPermissions.push('marketingPermissionNone');

        }
        this.fs.patchForm(this.form, formData);

      });
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      middleName: this.fb.control(''),
      lastName: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      phone: this.fb.control(''),
      mobile: this.fb.control(''),
      fax: this.fb.control(''),
      availability: this.fb.control(''),
      locale: this.fb.control(localStorage.getItem('preferredLanguageLocale') ? localStorage.getItem('preferredLanguageLocale') : 'de_DE'),
      salutation: this.fb.control(null, [Validators.required]),
      grade: this.fb.control(null),
      customerContactTypes: this.fb.control(null, [Validators.required]),
      marketingPermission: this.fb.control(false),
      // marketing permissions as Multiple field
      marketingPermissions: this.fb.control(null, [Validators.required]),
      marketingPermissionEmail: this.fb.control(null),
      marketingPermissionPhone: this.fb.control(null),
      marketingPermissionPostal: this.fb.control(null),
    });


    this.actions$
      .pipe(
        ofType(CustomerContactsActions.CreateCustomerContactFail, CustomerContactsActions.UpdateCustomerContactFail),
        takeUntil(this.onDestroy$)
      ).subscribe(fail => {
      if (fail?.response?.error?.violations) {
        this.fs.mergeViolationsIntoForm(fail.response.error.violations, this.form);
      }
    });
  }

  changeMarketingPermissions(changedValue: string): void {
    const formField = this.form.get('marketingPermissions');
    let valueCopy: Array<string> = clone(formField.value);
    let hasChanged = false;
    const addedValue = valueCopy.indexOf(changedValue) > -1;
    const hasNoneValue = valueCopy.indexOf('marketingPermissionNone') > -1;
    if (changedValue === 'marketingPermissionEmail'
      || changedValue === 'marketingPermissionPhone'
      || changedValue === 'marketingPermissionPostal') {
      if (addedValue && hasNoneValue) {
        hasChanged = true;
        valueCopy.splice(valueCopy.indexOf('marketingPermissionNone'), 1);
      } else if (valueCopy.length === 0) {
        hasChanged = true;
        valueCopy = ['marketingPermissionNone'];
      }
    } else if (changedValue === 'marketingPermissionNone') {
      if (addedValue) {
        hasChanged = true;
        valueCopy = ['marketingPermissionNone'];

      }
    }
    if (hasChanged) {
      formField.setValue(valueCopy);
    }
    this.form.patchValue({
      marketingPermissionEmail: valueCopy.indexOf('marketingPermissionEmail') > -1,
      marketingPermissionPhone: valueCopy.indexOf('marketingPermissionPhone') > -1,
      marketingPermissionPostal: valueCopy.indexOf('marketingPermissionPostal') > -1,
    });
  }

  handleCancelEdit(): void {
    this.initForm();
    this.presets$?.next(null);
    this.cancelEdit.emit();
  }

  handleSubmit(): void {

    const payload: fromModuleModels.CustomerContact = {...this.form.value};

    if (this.presets$ && this.presets$.getValue()) {
      this.requestUpdateCustomerContact.emit({iri: this.presets$.getValue()['@id'], payload});
    } else {
      this.requestCreateCustomerContact.emit(payload);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
