import {createSelector} from '@ngrx/store';

import {DATA_RECOVERY_PRIORITY_MODES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

const NAMESPACE = DATA_RECOVERY_PRIORITY_MODES_FEATURE_KEY;
const MODULE_STATE_SELECT = selectMasterDataModuleState;
export const sState = createSelector(MODULE_STATE_SELECT, (moduleState) => moduleState[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const sEntities = createSelector(sState, (state) => state.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities));

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);


