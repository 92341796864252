import { createReducer, on } from '@ngrx/store';

import * as romOrdersModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { DataMediumStatusLogsActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: romOrdersModuleModels.DataMediumStatusLog };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DataMediumStatusLogsActions.ReadDataMediumStatusLog,
    DataMediumStatusLogsActions.ReadDataMediumStatusLogs,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    DataMediumStatusLogsActions.ReadDataMediumStatusLogSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    DataMediumStatusLogsActions.ReadDataMediumStatusLogsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: {
            [iri: string]: romOrdersModuleModels.DataMediumStatusLog;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    DataMediumStatusLogsActions.ReadDataMediumStatusLogFail,
    DataMediumStatusLogsActions.ReadDataMediumStatusLogsFail,
    state => {
      return {
        ...state,
        loading: false
      };
    }
  ),
  on(DataMediumStatusLogsActions.ResetDataMediumStatusLogsState, () => {
    return {
      ...initialState
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
