import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ProductOrderItem } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class ProductOrderItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createProductOrderItem(payload: any): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PRODUCT_ORDER_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/product_order_items`, payload)
    );
  }

  readProductOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PRODUCT_ORDER_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readProductOrderItems(orderUuid: string, page = -1): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PRODUCT_ORDER_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/product_order_items', page, {
                'order.uuid': orderUuid
              })
            )
    );
  }

  updateProductOrderItem(
    iri: string,
    payload: ProductOrderItem
  ): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PRODUCT_ORDER_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteProductOrderItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_PRODUCT_ORDER_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
