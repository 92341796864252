import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import {
  PartnerWebsiteInvitation,
  PartnerWebsiteInvitationConfirmation
} from '../models';

@Injectable()
export class PartnerWebsiteInvitationsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createPartnerWebsiteInvitation(
    payload: PartnerWebsiteInvitation
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/invitations/partner_websites`,
              payload
            )
    );
  }

  // Security no roles in invitation_partner_website.xml
  createPartnerWebsiteInvitationConfirmation(
    payload: PartnerWebsiteInvitationConfirmation
  ): Observable<AbstractApiResponse> {
    return this.apiService.createObject(
      `/invitations/confirmation/partner_websites`,
      payload
    );
  }

  readPartnerWebsiteInvitation(
    iri: string
  ): Observable<PartnerWebsiteInvitation> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readPartnerWebsiteInvitations(page = 1): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/invitations/partner_websites?page=${page}`
            )
    );
  }

  readPartnerWebsiteInvitationChunk(
    uri: string
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updatePartnerWebsiteInvitation(
    iri: string,
    payload: PartnerWebsiteInvitation
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deletePartnerWebsiteInvitation(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
