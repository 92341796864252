import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_DEPARTMENT_AD = '[Master Data] Create DepartmentAd';
export const CREATE_DEPARTMENT_AD_SUCCESS =
  '[Master Data] Create DepartmentAd Success';
export const CREATE_DEPARTMENT_AD_STATUS =
  '[Master Data] Create DepartmentAd Status';
export const CREATE_DEPARTMENT_AD_FAIL =
  '[Master Data] Create DepartmentAd Fail';
export const CreateDepartmentAd = createAction(
  CREATE_DEPARTMENT_AD,
  props<{ payload: { file: File } }>()
);
export const CreateDepartmentAdSuccess = createAction(
  CREATE_DEPARTMENT_AD_SUCCESS,
  props<{ response: fromModuleModels.DepartmentAd }>()
);
export const CreateDepartmentAdStatus = createAction(
  CREATE_DEPARTMENT_AD_STATUS,
  props<{ response: any }>()
);

export const CreateDepartmentAdFail = createAction(
  CREATE_DEPARTMENT_AD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DEPARTMENT_ADS = '[Master Data] Read DepartmentAds';
export const READ_DEPARTMENT_ADS_SUCCESS =
  '[Master Data] Read DepartmentAds Success';
export const READ_DEPARTMENT_ADS_FAIL = '[Master Data] Read DepartmentAds Fail';
export const ReadDepartmentAds = createAction(READ_DEPARTMENT_ADS);
export const ReadDepartmentAdsSuccess = createAction(
  READ_DEPARTMENT_ADS_SUCCESS,
  props<{ response: any }>()
);
export const ReadDepartmentAdsFail = createAction(
  READ_DEPARTMENT_ADS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_DEPARTMENT_AD = '[Master Data] Read DepartmentAd';
export const READ_DEPARTMENT_AD_SUCCESS =
  '[Master Data] Read DepartmentAd Success';
export const READ_DEPARTMENT_AD_FAIL = '[Master Data] Read DepartmentAd Fail';
export const ReadDepartmentAd = createAction(
  READ_DEPARTMENT_AD,
  props<{ iri: string }>()
);
export const ReadDepartmentAdSuccess = createAction(
  READ_DEPARTMENT_AD_SUCCESS,
  props<{ response }>()
);
export const ReadDepartmentAdFail = createAction(
  READ_DEPARTMENT_AD_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
// export const UPDATE_DEPARTMENT_AD = '[Master Data] Update DepartmentAd';
// export const UPDATE_DEPARTMENT_AD_SUCCESS = '[Master Data] Update DepartmentAd Success';
// export const UPDATE_DEPARTMENT_AD_FAIL = '[Master Data] Update DepartmentAd Fail';
// export const UpdateDepartmentAd = createAction(
//   UPDATE_DEPARTMENT_AD,
//   props<{ iri: string, payload: fromModuleModels.DepartmentAd }>()
// );
// export const UpdateDepartmentAdSuccess = createAction(
//   UPDATE_DEPARTMENT_AD_SUCCESS,
//   props<{ response: fromModuleModels.DepartmentAd }>()
// );
// export const UpdateDepartmentAdFail = createAction(
//   UPDATE_DEPARTMENT_AD_FAIL,
//   props<{ response: HttpErrorResponse }>()
// );

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_DEPARTMENT_AD = '[Master Data] Delete DepartmentAd';
export const DELETE_DEPARTMENT_AD_SUCCESS =
  '[Master Data] Delete DepartmentAd Success';
export const DELETE_DEPARTMENT_AD_FAIL =
  '[Master Data] Delete DepartmentAd Fail';
export const DeleteDepartmentAd = createAction(
  DELETE_DEPARTMENT_AD,
  props<{ iri: string }>()
);
export const DeleteDepartmentAdSuccess = createAction(
  DELETE_DEPARTMENT_AD_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteDepartmentAdFail = createAction(
  DELETE_DEPARTMENT_AD_FAIL,
  props<{ response: HttpErrorResponse }>()
);
