import { LeadCommentTagsService } from './lead-comment-tags.service';
import { LeadCommentsService } from './lead-comments.service';
import { LeadContactTypesService } from './lead-contact-types.service';
import { LeadOriginTypesService } from './lead-origin-types.service';
import { LeadsService } from './leads.service';

export const AllServices = [
  LeadCommentTagsService,
  LeadCommentsService,
  LeadContactTypesService,
  LeadOriginTypesService,
  LeadsService
];

export * from './lead-comment-tags.service';
export * from './lead-comments.service';
export * from './lead-contact-types.service';
export * from './lead-origin-types.service';
export * from './leads.service';
