import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as fromInvoicesModuleModels from '../../../invoices/models';
import * as fromAdministratorModuleModels from '../../../administrators/models';
import {PayableInvoice} from "../../../invoices/models";
import {loadIfNotLoaded} from "../../../shared/utilities/observable.utility";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {AdministratorsSelectors} from "../../../administrators/store/selectors";
import {AdministratorsActions} from "../../../administrators/store";
import {filter, takeUntil} from "rxjs/operators";
import {BaseOnDestroyComponent} from "../../../shared/injectables/BaseOnDestroy.component";

@Component({
  selector: 'invoice-facturer',
  styleUrls: ['invoice-facturer.component.scss'],
  template: `

    <div class="card mat-elevation-z1 mt-3">

      <div class="card__heading" [class.green]="!!invoice?.bookedBy">
        <span>Rechnung buchen</span>
      </div>

      <div class="card__content grid grid-no-gutter">

        <div class="column-7 m-b--16">
          <mat-checkbox
            [checked]="!isInvoiceBookable(invoice)"
            [disabled]="!isInvoiceBookable(invoice)" #checker>{{ getBookedByText(invoice?.bookedBy) }}</mat-checkbox>
        </div>

        <div class="column-7 m-ta--2">
          <button
            *ngIf="isInvoiceBookable(invoice)"
            mat-flat-button color="green"
            [disabled]="!checker.checked"
            (click)="handleRequestBookInvoice(invoice['@id'])">
            <mat-icon class="m-r--8">description</mat-icon>
            <span>Rechnung buchen</span>
          </button>

          <button
            *ngIf="!isInvoiceBookable(invoice)"
            mat-flat-button
            (click)="handleRequestDownloadInvoice(invoice['@id'])"
            style="color: #bbb">Rechnung herunterladen
          </button>
        </div>
      </div>
      <div class="blocker"></div>
    </div>
  `
})
export class InvoiceFacturerComponent extends BaseOnDestroyComponent implements OnInit {

  @Input() invoice: PayableInvoice | null;
  @Input() administratorEntities: { [iri: string]: fromAdministratorModuleModels.Administrator };

  @Output()
  requestDownloadInvoice: EventEmitter<string> = new EventEmitter();

  @Output()
  requestBookInvoice: EventEmitter<string> = new EventEmitter();

  constructor(
    private store$: Store<ApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadAdministrators();
  }

  getBookedByText(administrator?: string): string {
    return administrator ?
      `Sachlich geprüft von ${this?.administratorEntities?.[administrator]?.firstName} ${this?.administratorEntities?.[administrator]?.lastName}` :
      'Sachliche Prüfung';
  }

  isInvoiceBookable(invoice): boolean {
    return (invoice.state !== 'booked' && invoice.state !== 'canceled') && !!!this.invoice?.bookedBy;
  }

  handleRequestDownloadInvoice(invoice: string): void {
    this.requestDownloadInvoice.emit(invoice);
  }

  loadAdministrators(): void {
    this.store$.select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
      .subscribe(a => {
        this.administratorEntities = a;
      });
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());
  }

  handleRequestBookInvoice(invoice: string): void {
    if (!this.invoice) {
      alert('Invoice must be set');
      return;
    }
    this.requestBookInvoice.emit(invoice);
  }
}
