import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../application-state/store';
import { AuthService } from '../services/auth.service';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsInGroupGuard implements CanActivate {
  constructor(
    private store: Store<ApplicationState>,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const dashboard: UrlTree = this.router.createUrlTree(['/dashboard'], {
      queryParams: { pageError: 'ACCESS_DENIED' }
    });

    // console.log('canActivate', route.data);
    if (!route.data) {
      return of(true);
    } else if (route.data?.neededRole) {
      return this.authService.isLoggedIn$.pipe(
        mergeMap(e => this.authService.isLoggedInAndNotExpired$),
        mergeMap(e => (e ? this.authService.userBaseRoles$ : of([]))),
        map(roles => {
          if (roles.length <= 0) {
            this.router.navigate(['/login']);
            return of(false);
          }
          if (typeof route.data?.neededRole === typeof '') {
            return (
              this.authService.hasGroupWithName(
                roles,
                route.data?.neededRole
              ) || dashboard
            );
          } else if (typeof route.data?.neededRole === typeof []) {
            return route.data?.neededRole?.some(e =>
              this.authService.hasGroupWithName(roles, e)
            );
          } else {
            return of(dashboard);
          }
        })
      );
    } else {
      return of(dashboard);
    }
  }
}
