import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_STOCK_ITEMS = '[Warehouse Module] Read Stock Items';
export const READ_STOCK_ITEMS_SUCCESS =
  '[Warehouse Module] Read Stock Items Success';
export const READ_STOCK_ITEMS_FAIL = '[Warehouse Module] Read Stock Items Fail';

export const ReadStockItems = createAction(READ_STOCK_ITEMS);

export const ReadStockItemsSuccess = createAction(
  READ_STOCK_ITEMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadStockItemsFail = createAction(
  READ_STOCK_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
