import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromCustomerAccountInvitations from './customer-account-invitations.reducers';
import * as fromCustomerAccounts from './customer-accounts.reducers';
import * as fromCustomerAddressesReducers from './customer-addresses.reducers';
import * as fromCustomerCommentTags from './customer-comment-tags.reducers';
import * as fromCustomerComments from './customer-comments.reducers';
import * as fromCustomerContactTypes from './customer-contact-types.reducers';
import * as fromCustomerContactsReducers from './customer-contacts.reducers';
import * as fromCustomerInvoicesReducers from './customer-invoices.reducers';
import * as fromCustomerOffersReducers from './customer-offers.reducers';
import * as fromCustomerOrdersReducers from './customer-orders.reducers';
import * as fromCustomerPartnerStatuses from './customer-partner-statuses.reducers';
import * as fromCustomerTypes from './customer-types.reducers';
import * as fromCustomersReducers from './customers.reducers';
import * as fromPartnerWebsiteInvitations from './partner-website-invitations.reducers';
import * as fromPartnerWebsites from './partner-websites.reducers';
import * as fromPartnersReducers from './partners.reducers';

export const CUSTOMERS_FEATURE_KEY = 'customers';
export const CUSTOMERS_MODULE_FEATURE_KEY = 'customersModule';
export const CUSTOMER_ACCOUNTS_FEATURE_KEY = 'customerAccounts';
export const CUSTOMER_ACCOUNT_INVITATIONS_FEATURE_KEY = 'customerAccountInvitations';
export const CUSTOMER_ADDRESSES_FEATURE_KEY = 'customerAddresses';
export const CUSTOMER_COMMENTS_FEATURE_KEY = 'customerComments';
export const CUSTOMER_COMMENT_TAGS_FEATURE_KEY = 'customerCommentTags';
export const CUSTOMER_CONTACTS_FEATURE_KEY = 'customerContacts';
export const CUSTOMER_CONTACT_TYPES_FEATURE_KEY = 'customerContactTypes';
export const CUSTOMER_INVOICES_FEATURE_KEY = 'customerInvoices';
export const CUSTOMER_OFFERS_FEATURE_KEY = 'customerOffers';
export const CUSTOMER_ORDERS_FEATURE_KEY = 'customerOrders';
export const CUSTOMER_PARTNER_STATUSES = 'customerPartnerStatuses';
export const CUSTOMER_TYPES = 'customerTypes';
export const PARTNER_WEBSITES_FEATURE_KEY = 'partnerWebsites';
export const PARTNER_WEBSITE_INVITATIONS_FEATURE_KEY = 'partnerWebsiteInvitations';
export const PARTNERS_FEATURE_KEY = 'partners';

export interface CustomersModuleState {
  [CUSTOMERS_FEATURE_KEY]: fromCustomersReducers.State;
  [CUSTOMER_ACCOUNTS_FEATURE_KEY]: fromCustomerAccounts.State;
  [CUSTOMER_ACCOUNT_INVITATIONS_FEATURE_KEY]: fromCustomerAccountInvitations.State;
  [CUSTOMER_ADDRESSES_FEATURE_KEY]: fromCustomerAddressesReducers.State;
  [CUSTOMER_COMMENTS_FEATURE_KEY]: fromCustomerComments.State;
  [CUSTOMER_COMMENT_TAGS_FEATURE_KEY]: fromCustomerCommentTags.State;
  [CUSTOMER_CONTACTS_FEATURE_KEY]: fromCustomerContactsReducers.State;
  [CUSTOMER_CONTACT_TYPES_FEATURE_KEY]: fromCustomerContactTypes.State;
  [CUSTOMER_INVOICES_FEATURE_KEY]: fromCustomerInvoicesReducers.State;
  [CUSTOMER_OFFERS_FEATURE_KEY]: fromCustomerOffersReducers.State;
  [CUSTOMER_ORDERS_FEATURE_KEY]: fromCustomerOrdersReducers.State;
  [CUSTOMER_PARTNER_STATUSES]: fromCustomerPartnerStatuses.State;
  [CUSTOMER_TYPES]: fromCustomerTypes.State;
  [PARTNER_WEBSITES_FEATURE_KEY]: fromPartnerWebsites.State;
  [PARTNER_WEBSITE_INVITATIONS_FEATURE_KEY]: fromPartnerWebsiteInvitations.State;
  [PARTNERS_FEATURE_KEY]: fromPartnersReducers.State;
}

export const selectCustomersModuleState = createFeatureSelector<CustomersModuleState>(CUSTOMERS_MODULE_FEATURE_KEY);

export function reducers(state: CustomersModuleState, action: Action) {

  return combineReducers({
    [CUSTOMERS_FEATURE_KEY]: fromCustomersReducers.reducer,
    [CUSTOMER_ACCOUNTS_FEATURE_KEY]: fromCustomerAccounts.reducer,
    [CUSTOMER_ACCOUNT_INVITATIONS_FEATURE_KEY]: fromCustomerAccountInvitations.reducer,
    [CUSTOMER_ADDRESSES_FEATURE_KEY]: fromCustomerAddressesReducers.reducer,
    [CUSTOMER_COMMENTS_FEATURE_KEY]: fromCustomerComments.reducer,
    [CUSTOMER_COMMENT_TAGS_FEATURE_KEY]: fromCustomerCommentTags.reducer,
    [CUSTOMER_CONTACTS_FEATURE_KEY]: fromCustomerContactsReducers.reducer,
    [CUSTOMER_CONTACT_TYPES_FEATURE_KEY]: fromCustomerContactTypes.reducer,
    [CUSTOMER_INVOICES_FEATURE_KEY]: fromCustomerInvoicesReducers.reducer,
    [CUSTOMER_OFFERS_FEATURE_KEY]: fromCustomerOffersReducers.reducer,
    [CUSTOMER_ORDERS_FEATURE_KEY]: fromCustomerOrdersReducers.reducer,
    [CUSTOMER_PARTNER_STATUSES]: fromCustomerPartnerStatuses.reducer,
    [CUSTOMER_TYPES]: fromCustomerTypes.reducer,
    [PARTNER_WEBSITES_FEATURE_KEY]: fromPartnerWebsites.reducer,
    [PARTNER_WEBSITE_INVITATIONS_FEATURE_KEY]: fromPartnerWebsiteInvitations.reducer,
    [PARTNERS_FEATURE_KEY]: fromPartnersReducers.reducer
  })(state, action);
}
