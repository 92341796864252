<div class="grid grid-top" [formGroup]="of">
  <ng-container>
    <div class="column-14 grid">
      <div class="column-7">
        <mat-form-field>
          <mat-placeholder>Status</mat-placeholder>
          <input
            type="text"
            matInput
            disabled
            [value]="getStateName(order.state)"
          />
        </mat-form-field>
      </div>

      <div class="ng-select-container--department mat-form-field column-7">
        <ng-select
          placeholder="Bereich"
          [items]="departments$ | async"
          [loading]="departmentsIsLoading$ | async"
          bindValue="@id"
          bindLabel="companyName"
          [markFirst]="false"
          [clearable]="false"
          formControlName="department"
        ></ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'department'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>

      <div class="column-14">
        <mat-form-field>
          <mat-placeholder>Kunde</mat-placeholder>
          <input
            type="text"
            matInput
            disabled
            [value]="getCustomerName(customer)"
          />
        </mat-form-field>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Rechnungsadresse"
          [items]="customerBillingAddresses"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          formControlName="billingAddress"
        >
          <ng-template ng-label-tmp let-item="item">
                <span class="p-r--8">{{
                    getFormattedAddress(item.address)
                  }}</span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <span>{{ getFormattedAddress(item.address) }}</span>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'billingAddress'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-14">
        <ng-select
          placeholder="Lieferadresse"
          [items]="customerDeliveryAddresses"
          bindValue="@id"
          bindLabel="name"
          [markFirst]="false"
          formControlName="deliveryAddress"
        >
          <ng-template ng-label-tmp let-item="item">
                <span class="p-r--8">{{
                    getFormattedAddress(item.address)
                  }}</span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <span>{{ getFormattedAddress(item.address) }}</span>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'deliveryAddress'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-7">
        <app-customer-select
          label="Vermittler wählen"
          (selectCustomer)="selectBroker($event)"
        ></app-customer-select>
        <!--            -->
        <!--            <ng-select-->
        <!--              [items]="brokers$|async"-->
        <!--              bindLabel="name"-->
        <!--              [searchable]="true"-->
        <!--              [loading]="brokersIsLoading$| async"-->
        <!--              bindValue="@id"-->
        <!--              [clearable]="true"-->
        <!--              [searchFn]="findBroker"-->
        <!--              placeholder="Vermittler wählen"-->
        <!--              appendTo="body"-->
        <!--              formControlName="broker">-->

        <!--              <ng-template ng-label-tmp let-item="item">-->
        <!--                <span class="badge&#45;&#45;vip inline" *ngIf="item.vip">VIP</span>-->
        <!--                {{item.nameLine1}}-->
        <!--                <span *ngIf="item.nameLine2">({{item.nameLine2}})</span>-->
        <!--              </ng-template>-->

        <!--              <ng-template ng-option-tmp let-item="item">-->
        <!--                <span class="badge&#45;&#45;vip inline" *ngIf="item.vip">VIP</span>-->
        <!--                {{item.nameLine1}}-->
        <!--                <span *ngIf="item.nameLine2">({{item.nameLine2}})</span>-->
        <!--              </ng-template>-->

        <!--            </ng-select>-->
        <mat-error>
          <app-form-error
            [fieldName]="'broker'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>
      <div class="mat-form-field column-7">
        <ng-select
          [items]="brokerContacts$ | async"
          bindLabel="name"
          [searchable]="true"
          [loading]="brokerContactsIsLoading$ | async"
          bindValue="@id"
          [clearable]="true"
          [searchFn]="findBrokerContact"
          placeholder="Vermittler Kontakt wählen"
          formControlName="brokerContact"
        >
          <ng-template ng-label-tmp let-item="item">
                <span *ngIf="item['@type'] == 'PartnerWebsite'">
                  <strong>W:</strong>
                  {{ item.website }}
                </span>
            <span *ngIf="item['@type'] == 'CustomerAccount'">
                  <strong>P:</strong>
              {{ item.firstName }} {{ item.lastName }}
              <span *ngIf="item.email"> ({{ item.email }})</span>
                </span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
                <span *ngIf="item['@type'] === 'PartnerWebsite'">
                  <strong>W:</strong>
                  {{ item.website }}
                </span>
            <span *ngIf="item['@type'] === 'CustomerAccount'">
                  <strong>P:</strong>
              {{ item.firstName }} {{ item.lastName }}
              <span *ngIf="item.email"> ({{ item.email }})</span>
                </span>
          </ng-template>
        </ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'brokerContact'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>

      <div class="mat-form-field column-7">
        <ng-select
          [placeholder]="'orders.order-form.technician' | translate"
          [items]="administrators"
          bindValue="@id"
          bindLabel="name"
          [multiple]="false"
          [clearable]="true"
          [searchable]="true"
          formControlName="technician">


          <ng-template
            ng-label-tmp
            let-item="item">
            <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm">
            <div class="ng-option">
              <span>{{ item.firstName }} {{ item.lastName }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="mat-form-field column-7">
        <ng-select
          [placeholder]="'orders.order-form.salesperson' | translate"
          [items]="administrators"
          bindValue="@id"
          bindLabel="name"
          [multiple]="false"
          [clearable]="true"
          [searchable]="true"
          formControlName="salesperson">

          <ng-template
            ng-label-tmp
            let-item="item">
            <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm">
            <div class="ng-option">
              <span>{{ item.firstName }} {{ item.lastName }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="column-14">
      <hr class="m-t--12 m-b--32"/>
    </div>
  </ng-container>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Ersatzdatenträger"
      [items]="replacementDataMediumSources$ | async"
      [loading]="replacementDataMediumSourcesIsLoading$ | async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="replacementDataMediumSource"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'replacementDataMediumSource'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Alte Datenträger"
      [items]="disposalTypes$ | async"
      [loading]="disposalTypesIsLoading$ | async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="disposalType"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'disposalType'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Rabatt"
      [items]="discounts$ | async"
      bindValue="@id"
      #discountInput
      bindLabel="name"
      [markFirst]="false"
      [searchable]="false"
      [clearable]="true"
      formControlName="discount"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'discount'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <!--<pre>{{ discountInput.selectedValues[0] | json}}</pre>-->
    <mat-form-field>
      <mat-placeholder>Sonderrabatt in %</mat-placeholder>
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="of.get('specialDiscount').setValue(null); sdi.blur()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <input
        type="text"
        matInput
        formControlName="specialDiscount"
        #sdi
        currencyMask
        [options]="{ prefix: '', suffix: '%' }"
      />
      <mat-error>
        <app-form-error
          [fieldName]="'specialDiscount'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Analyse-Modus"
      [items]="analysisPriorityModes$ | async"
      [loading]="analysisPriorityModesIsLoading$ | async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="analysisPriorityMode"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'analysisPriorityMode'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Modus Datenrettung"
      [items]="dataRecoveryPriorityModes$ | async"
      [loading]="dataRecoveryPriorityModesIsLoading$ | async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="dataRecoveryPriorityMode"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'dataRecoveryPriorityMode'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <div class="mat-form-field column-7">
    <ng-select
      placeholder="Übergabe an Kunden"
      [items]="shippingProvidersToCustomer$ | async"
      bindValue="@id"
      bindLabel="name"
      [markFirst]="false"
      [clearable]="false"
      formControlName="shippingProviderToCustomer"
    ></ng-select>
    <mat-error>
      <app-form-error
        [fieldName]="'shippingProviderToCustomer'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </div>

  <mat-form-field class="column-7">
    <mat-placeholder>% Anzahlung</mat-placeholder>
    <input
      type="string"
      matInput
      formControlName="downPayment"
      currencyMask
      [options]="{ prefix: '', suffix: '%' }"
    />
    <mat-error>
      <app-form-error
        [fieldName]="'downPayment'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="column-14">
    <mat-placeholder>Zusätzliche Vereinbarungen</mat-placeholder>
    <textarea
      matInput
      rows="2"
      formControlName="additionalAgreements"
      matTextareaAutosize
    ></textarea>
    <mat-error>
      <app-form-error
        [fieldName]="'additionalAgreements'"
        [formGroup]="of"
      ></app-form-error>
    </mat-error>
  </mat-form-field>

  <ng-container>
    <div class="column-14 grid" formGroupName="willingnessToPay">
      <div class="column-10">
        <mat-form-field>
          <mat-placeholder>€ Bereitschaft Kd.</mat-placeholder>
          <input
            type="text"
            matInput
            formControlName="value"
            currencyMask
            [options]="{ prefix: '', suffix: '' }"
          />
          <mat-error>
            <app-form-error
              [fieldName]="'willingnessToPay.value'"
              [formGroup]="of"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mat-form-field column-4">
        <ng-select
          placeholder="Währung"
          [items]="currencies$ | async"
          [loading]="currenciesIsLoading$ | async"
          bindValue="code"
          bindLabel="name"
          [markFirst]="false"
          [clearable]="false"
          formControlName="currency"
        ></ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'willingnessToPay.currency'"
            [formGroup]="of"
          ></app-form-error>
        </mat-error>
      </div>
    </div>

    <div class="mat-form-field column-7">
      <ng-select
        placeholder="Analyse in"
        [items]="labLocations$ | async"
        [loading]="labLocationsIsLoading$ | async"
        bindValue="@id"
        disabled
        (change)="handleRequestUpdateLocation('analysisLocation', $event)"
        bindLabel="name"
        [markFirst]="false"
        formControlName="analysisLocation"
      ></ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'analysisLocation'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </div>

    <div class="mat-form-field column-7">
      <ng-select
        placeholder="Datenrettung in"
        [items]="labLocations$ | async"
        [loading]="labLocationsIsLoading$ | async"
        bindValue="@id"
        bindLabel="name"
        disabled
        (change)="
              handleRequestUpdateLocation('dataRecoveryLocation', $event)
            "
        [markFirst]="false"
        formControlName="dataRecoveryLocation"
      ></ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'dataRecoveryLocation'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </div>

    <div
      class="ng-select-container--customer-contanct mat-form-field column-7"
    >
      <ng-select
        placeholder="Technischer Ansprechpartner"
        [items]="technicalCustomerContacts"
        bindValue="@id"
        dropdownPosition="top"
        [markFirst]="false"
        [searchable]="false"
        formControlName="technicalCustomerContact"
      >
        <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8"
              >{{ item.firstName }} {{ item.lastName }}</span
              >
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="ng-option">
                <span class="p-r--8"
                >{{ item.firstName }} {{ item.lastName }}</span
                >
          </div>
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'technicalCustomerContact'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </div>

    <div class="mat-form-field column-7">
      <ng-select
        placeholder="Organisatorischer Ansprechpartner"
        [items]="organizationalCustomerContacts"
        dropdownPosition="top"
        bindValue="@id"
        [markFirst]="false"
        [searchable]="false"
        formControlName="organizationalCustomerContact"
      >
        <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8"
              >{{ item.firstName }} {{ item.lastName }}</span
              >
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="ng-option">
                <span class="p-r--8"
                >{{ item.firstName }} {{ item.lastName }}</span
                >
          </div>
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'organizationalCustomerContact'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </div>

    <div class="mat-form-field column-14">
      <ng-select
        placeholder="Entscheider"
        [items]="decisionMakerCustomerContacts"
        dropdownPosition="top"
        bindValue="@id"
        bindLabel="name"
        [markFirst]="false"
        [searchable]="false"
        formControlName="decisionMakerCustomerContact"
      >
        <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8"
              >{{ item.firstName }} {{ item.lastName }}</span
              >
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="ng-option">
                <span class="p-r--8"
                >{{ item.firstName }} {{ item.lastName }}</span
                >
          </div>
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error
          [fieldName]="'decisionMakerCustomerContact'"
          [formGroup]="of"
        ></app-form-error>
      </mat-error>
    </div>
  </ng-container>
</div>

<div class="grid grid-right">
  <div class="column-2"></div>
  <div class="column-12 m-ta--2">
    <button
      (click)="handleSubmit()"
      mat-button
      color="green"
      [disabled]="of.invalid"
    >
      <mat-icon>edit</mat-icon>
      <span>Auftrag aktualisieren</span>
    </button>
  </div>
</div>

<!--<pre>{{ of.value | json }}</pre>-->
<!--<pre>{{ errors | json }}</pre>-->
<!--<pre>{{ customerContacts | json }}</pre>-->
<!--<pre>{{ of.value | json }}</pre>-->
