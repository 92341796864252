import {createSelector, select} from '@ngrx/store';

import {selectTicketsModuleState, TICKET_COMMENTS_FEATURE_KEY} from '../reducers';
import {selectCustomersState} from '../../../customers/store/selectors/customers.selectors';
import {TicketsSelectors} from './index';
import {sortByCreatedAtDate} from "../../../shared/utilities/array.utility";

const NAMESPACE = TICKET_COMMENTS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectTicketsModuleState;


export const sState = createSelector(MODULE_STATE_SELECT, (s) => s[NAMESPACE]);
export const isLoading = createSelector(sState, (s) => s.loading);
export const isLoaded = createSelector(sState, (s) => s.loaded);
export const sEntities = createSelector(sState, (state) => state.entities);
export const sList = createSelector(sEntities, (entities) => Object.values(entities).sort(sortByCreatedAtDate));
export const sById = (id) => createSelector(sList, (entities) => entities.find(e => e['@id'].indexOf(id) > -1));
export const sByIri = createSelector(sEntities, (tickets, props) => tickets[props.iri]);

export const sByTicketIri = createSelector(sList, (entities, {iri}) => entities.filter(comment => comment.ticket === iri));

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectTicketCommentTotalItems = createSelector(
  sState,
  state => state.totalItems
);
export const selectTicketCommentPagination = createSelector(
  sState,
  (state) => state.pagination
);
export const selectPaginationCurrentLink = createSelector(
  selectTicketCommentPagination,
  pagination => pagination.current
);
export const selectPaginationFirstLink = createSelector(
  selectTicketCommentPagination,
  pagination => pagination.first
);
export const selectPaginationPreviousLink = createSelector(
  selectTicketCommentPagination,
  pagination => pagination.previous
);
export const selectPaginationNextLink = createSelector(
  selectTicketCommentPagination,
  pagination => pagination.next
);
export const selectPaginationLastLink = createSelector(
  selectTicketCommentPagination,
  pagination => pagination.last
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);

