import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {Ticket} from '../../models';
import {takeUntil} from 'rxjs/operators';
import {TicketsActions} from '../../store';

@Component({
  selector: 'app-remote-access-form-dialog',
  styleUrls: ['./remote-access-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Remote-Zugriff bearbeiten</h2>
    </app-dialog-header>

    <app-remote-access-form [ticket$]="data.ticket$"></app-remote-access-form>
  `
})
export class RemoteAccessFormDialogComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                ticket$: BehaviorSubject<Ticket>
              },
              public dialogRef: MatDialogRef<RemoteAccessFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions) {
  }

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(TicketsActions.UpdateTicketSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


}
