<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header">
      <span>Widget list with destruction</span>
      <div class="spacer"></div>
    </div>
    <app-orders-list-for-widget
      [indicatorStyleFn]="getCircleColor"
      [states]="['disposal_destroy_dm']"
    ></app-orders-list-for-widget>
  </div>
</div>
