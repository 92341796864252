import {createSelector} from '@ngrx/store';

import {DISCOUNTS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectDiscountsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[DISCOUNTS_FEATURE_KEY]
);

export const selectDiscountsEntities = createSelector(
  selectDiscountsState,
  (state) => state.entities
);

export const selectDiscounts = createSelector(
  selectDiscountsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectDiscountsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectDiscountsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDiscountsState,
  (state) => state.loaded
);
