import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-gross-price-output',
  styleUrls: ['gross-price-output.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

    <mat-form-field [formGroup]="gpf">
      <mat-label>Brutto</mat-label>
      <mat-placeholder>{{ taxRateMessage }}</mat-placeholder>
      <input type="text"
             [disabled]="!taxRate"
             currencyMask
             formControlName="grossValue"
             [value]="getGrossValue()"
             matInput>
    </mat-form-field>

    <!--<pre>{{ gpf.get('grossValue').value | json }}</pre>-->
    <!--<pre>{{ netValue | json }}</pre>-->
    <!--<pre>{{ taxRate | json }}</pre>-->
    <!--<pre>{{ identifier | json }}</pre>-->
    <!--<pre>{{ getGrossValue() | json }}</pre>-->
  `
})
export class GrossPriceOutputComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() identifier: string;
  @Input() taxRate: number | string | any;

  @Output() priceUpdated: EventEmitter<string> = new EventEmitter();

  gpf: FormGroup;
  onDestroy$: Subject<any> = new Subject();
  net = '0';

  get taxRateMessage(): string {
    return 'Steuersatz auswählen';
  }

  get netValue(): string {
    return this.net;
  }

  set netValue(value: string) {
    this.net = value;
  }

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.gpf = this.fb.group({
      grossValue: this.fb.control('0')
    });

    this.gpf.get('grossValue').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.handleOnValueUpdated(value);
    });
  }

  ngAfterViewInit() {
    this.doCheck();

  }

  public doCheck(): void {
    this.cd.markForCheck();
  }

  getGrossValue(): string {
    return (parseFloat(this.netValue) * ((parseFloat(this.taxRate) / 100) + 1)).toFixed(2).toString().replace('.', ',') + ' €';
  }

  getNetValue(grossValue: string): string {
    return (parseFloat(grossValue) / ((parseFloat(this.taxRate) / 100) + 1)).toFixed(2);
  }

  handleOnValueUpdated(grossValue: string): any {
    this.priceUpdated.emit(this.getNetValue(grossValue));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
