import * as DialogActions from './dialog.actions';
import * as LoadingActions from './loading.actions';
import * as RouterActions from './router.actions';
import * as TransitionsActions from './transitions.actions';
import * as UserRoleActions from './user-role.actions';

export {
  DialogActions,
  LoadingActions,
  RouterActions,
  TransitionsActions,
  UserRoleActions
};
