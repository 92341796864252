import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import * as fromModuleModels from '../../models';
import { Department, DepartmentLogo } from '../../models';
import { Administrator } from '../../../administrators/models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { QuillEditorComponent } from 'ngx-quill';
import {
  DepartmentAdsActions,
  DepartmentLogosActions,
  TermsAndConditionsActions
} from '../../store';
import {
  DepartmentAdsSelectors,
  DepartmentLogosSelectors,
  TermsAndConditionsSelectors
} from '../../store/selectors';
import { Actions, ofType } from '@ngrx/effects';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-department-form',
  styleUrls: ['department-form.component.scss'],
  template: `
    <div class="card mat-elevation-z1">
      <div class="card__heading">
        <span>{{
          presets$.getValue()
            ? 'Abteilung bearbeiten'
            : 'Neue Abteilung anlegen'
        }}</span>
      </div>

      <div class="card__content p-a--24">
        <ng-container [formGroup]="form">
          <div class="grid">
            <div class="mat-form-field checkbox column-14 m-b--24">
              <mat-checkbox formControlName="isDefault"
                >Standard-Abteilung</mat-checkbox
              >
            </div>

            <mat-form-field class="column-8">
              <mat-label>Firmenname</mat-label>
              <input
                type="text"
                matInput
                formControlName="companyName"
                required
              />
              <mat-hint align="start" *ngIf="errors?.companyName"
                >{{ errors.companyName.message }}
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="column-6">
              <mat-label>Abkürzung Firmenname</mat-label>
              <input
                type="text"
                matInput
                formControlName="nameAbbreviation"
                required
              />
              <mat-hint align="start" *ngIf="errors?.nameAbbreviation"
                >{{ errors.nameAbbreviation.message }}
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="column-14">
              <mat-label>Geschäftsführer</mat-label>
              <input type="text" matInput formControlName="generalManager" />
              <mat-hint align="start" *ngIf="errors?.generalManager"
                >{{ errors.generalManager.message }}
              </mat-hint>
            </mat-form-field>

            <div class="column-14">
              <span class="heading--h3">Adresse</span>
            </div>

            <mat-form-field class="column-9">
              <mat-label>Addressen Vortext</mat-label>
              <input
                type="text"
                matInput
                formControlName="emailFromName"
                required
              />
              <mat-hint
                align="start"
                *ngIf="errors.hasOwnProperty('emailFromName')"
                >{{ errors['emailFromName'].message }}
              </mat-hint>
            </mat-form-field>

            <ng-container formGroupName="address">
              <mat-form-field class="column-9">
                <mat-label>Straße</mat-label>
                <input type="text" matInput formControlName="line1" required />
                <mat-hint
                  align="start"
                  *ngIf="errors.hasOwnProperty('address.line1')"
                  >{{ errors['address.line1'].message }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>Hausnummer</mat-label>
                <input type="text" matInput formControlName="line2" required />
                <mat-hint
                  align="start"
                  *ngIf="errors.hasOwnProperty('address.line2')"
                  >{{ errors['address.line2'].message }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="column-5">
                <mat-label>PLZ</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="zipPostcode"
                  required
                />
                <mat-hint
                  align="start"
                  *ngIf="errors.hasOwnProperty('address.zipPostcode')"
                  >{{ errors['address.zipPostcode'].message }}
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="column-9">
                <mat-label>Stadt</mat-label>
                <input type="text" matInput formControlName="city" required />
                <mat-hint
                  align="start"
                  *ngIf="errors.hasOwnProperty('address.city')"
                  >{{ errors['address.city'].message }}
                </mat-hint>
              </mat-form-field>

              <div class="mat-form-field column-14">
                <ng-select
                  required
                  placeholder="Land*"
                  [items]="countryOptions$ | async"
                  bindLabel="name"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="country"
                  bindValue="code"
                ></ng-select>
                <mat-hint align="start" *ngIf="errors?.country">{{
                  errors.country.message
                }}</mat-hint>
              </div>
            </ng-container>

            <div class="column-14">
              <span class="heading--h3">Kontaktdaten</span>
            </div>

            <mat-form-field class="column-14">
              <mat-label>Telefonnummer</mat-label>
              <input type="tel" matInput formControlName="phone" required />
              <mat-hint align="start" *ngIf="errors?.phone">{{
                errors.phone.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-14">
              <mat-label>Faxnummer</mat-label>
              <input type="tel" matInput formControlName="fax" required />
              <mat-hint align="start" *ngIf="errors?.fax">{{
                errors.fax.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-14">
              <mat-label>Website</mat-label>
              <input
                type="url"
                matInput
                formControlName="website"
                placeholder="Format: http(s)://domain.xyz"
                required
              />
              <mat-hint align="start" *ngIf="errors?.website">{{
                errors.website.message
              }}</mat-hint>
            </mat-form-field>

            <div class="column-14">
              <span class="heading--h3">Rechtliches</span>
            </div>

            <mat-form-field class="column-14">
              <mat-label>USt.-ID</mat-label>
              <input
                type="text"
                matInput
                formControlName="taxNumber"
                required
              />
              <mat-hint align="start" *ngIf="errors?.taxNumber">{{
                errors.taxNumber.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-14">
              <mat-label>Zuständiges Gericht</mat-label>
              <input
                type="text"
                matInput
                formControlName="competentCourt"
                required
              />
              <mat-hint align="start" *ngIf="errors?.competentCourt"
                >{{ errors.competentCourt.message }}
              </mat-hint>
            </mat-form-field>

            <div class="column-14">
              <span class="heading--h3">Bankverbindung</span>
            </div>

            <mat-form-field class="column-14">
              <mat-label>IBAN</mat-label>
              <input type="text" matInput formControlName="iban" required />
              <mat-hint align="start" *ngIf="errors?.iban">{{
                errors.iban.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-7">
              <mat-label>BIC</mat-label>
              <input type="text" matInput formControlName="bic" required />
              <mat-hint align="start" *ngIf="errors?.bic">{{
                errors.bic.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-7">
              <mat-label>Bank</mat-label>
              <input type="text" matInput formControlName="bankName" />
              <mat-hint align="start" *ngIf="errors?.bankName">{{
                errors.bankName.message
              }}</mat-hint>
            </mat-form-field>

            <div class="column-14">
              <span class="heading--h3">E-Mailkonto</span>
            </div>

            <mat-form-field class="column-14">
              <mat-label>Absenderkennung</mat-label>
              <input type="text" matInput formControlName="name" required />
              <mat-hint align="start" *ngIf="errors?.name">{{
                errors.name.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-7">
              <mat-label>E-Mail-Adresse</mat-label>
              <input type="email" matInput formControlName="email" required />
              <mat-hint align="start" *ngIf="errors?.email">{{
                errors.email.message
              }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-7">
              <mat-label>NoReply-E-Mail-Adresse</mat-label>
              <input
                type="email"
                matInput
                formControlName="noReplyEmail"
                required
              />
              <mat-hint align="start" *ngIf="errors?.noReplyEmail"
                >{{ errors.noReplyEmail.message }}
              </mat-hint>
            </mat-form-field>

            <!--<div class="column-14">
                        <div class="mat-form-field">
                          <ng-select
                            placeholder="Techniker"
                            [items]="technicians$ | async"
                            bindValue="@id"
                            bindLabel="name"
                            [multiple]="true"
                            [clearable]="false"
                            [searchable]="false"
                            formControlName="technicians">

                            <ng-template ng-label-tmp let-item="item">
                              <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
                              <strong style="font-size: 12px">(@{{ item.username }})</strong>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                              <div class="ng-option">
                              <span style="padding-right: 7px">
                                <mat-icon [inline]="true" color="accent">person</mat-icon>
                              </span>
                                <span style="padding-right: 7px">{{ item.firstName }} {{ item.lastName }}</span>
                                <strong style="font-size: 12px">(@{{ item.username }})</strong>
                              </div>
                            </ng-template>
                          </ng-select>
                          <mat-hint align="start" *ngIf="errors?.technician">{{ errors.technician.message }}</mat-hint>
                        </div>
                      </div>-->

            <div class="column-14">
              <span class="heading--h3"
                >AGB*
                <small class="text-decoration-none text-color-lightgrey"
                  >Nur zum anlegen/ändern auswählen</small
                >
              </span>
            </div>

            <div class="column-14">
              <app-file-input
                [dispatchAction]="dispatchActionTermsAndCondition"
                [statusSelector]="statusSelectorTermsAndCondition"
                [uploadCompletedAction]="uploadCompletedActionTermsAndCondition"
                [uploadFailAction]="uploadFailedActionTermsAndCondition"
                [additionalPayload]="additionalDataTermsAndCondition"
                label="AGB"
                (requestPatchParentForm)="handlePatchDepartmentForm($event)"
              ></app-file-input>
            </div>

            <div class="column-14">
              <span class="heading--h3"
                >Logo*
                <small class="text-decoration-none text-color-lightgrey"
                  >Nur zum anlegen/ändern auswählen</small
                >
              </span>
              <app-image-input
                [dispatchAction]="dispatchActionDepartmentLogo"
                [statusSelector]="statusSelectorDepartmentLogo"
                [uploadCompletedAction]="uploadCompletedActionDepartmentLogo"
                [uploadFailAction]="uploadFailedActionDepartmentLogo"
                previewOrientation="below"
                (requestPatchParentForm)="handlePatchDepartmentForm($event)"
                [additionalPayload]="additionalPayloadDepartmentLogo"
              ></app-image-input>
            </div>

            <div class="column-14">
              <span class="heading--h3"
                >Werbung für Rechnungen usw.
                <small class="text-decoration-none text-color-lightgrey"
                  >Nur zum anlegen/ändern auswählen</small
                >
              </span>
              <app-image-input
                [dispatchAction]="dispatchActionDepartmentAd"
                [statusSelector]="statusSelectorDepartmentAd"
                [uploadCompletedAction]="uploadCompletedActionDepartmentAd"
                [uploadFailAction]="uploadFailedActionDepartmentAd"
                previewOrientation="below"
                (requestPatchParentForm)="handlePatchDepartmentForm($event)"
                [additionalPayload]="additionalPayloadDepartmentAd"
              ></app-image-input>
            </div>
            <div class="column-14">
              <span class="heading--h3">Zusatzinformationen für E-Mails</span>
            </div>
            <mat-form-field class="column-14">
              <mat-label>Unternehmensanspruch (Claim)</mat-label>
              <input type="text" matInput formControlName="companyClaim" />
              <mat-hint align="start" *ngIf="errors?.companyClaim"
                >{{ errors.companyClaim.message }}
              </mat-hint>
            </mat-form-field>
            <div class="column-14 my-4">
              <app-text-editor
                formControlName="information"
                label="Info Text"
                placeholder="Text hier eingeben"
              ></app-text-editor>
            </div>
            <div class="column-14 my-4">
              <app-text-editor
                formControlName="additionalInformation"
                label="Zusätzliche Infos"
                placeholder="Text hier eingeben"
              ></app-text-editor>
            </div>

            <div class="column-14 text-right mt-4">
              <button
                *ngIf="presets$.getValue() || form.dirty"
                class="m-r--16"
                (click)="cancelEdit()"
                type="button"
                color="outline"
                mat-flat-button
              >
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button
                [disabled]="form.pristine || form.invalid"
                mat-flat-button
                color="green"
                (click)="handleSubmit()"
              >
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{
                  presets$.getValue() ? 'Aktualisieren' : 'Anlegen'
                }}</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class DepartmentFormComponent implements OnInit {
  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  @Input() countryOptions$: Observable<Array<fromModuleModels.Country>>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromModuleModels.Department>;
  @Input() technicians$: Observable<Array<Administrator>>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromModuleModels.Department;
    entity: string;
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string;
    payload: fromModuleModels.Department;
    entity: string;
  }> = new EventEmitter();
  selectedDepartmentLogo$: Observable<DepartmentLogo>;
  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();
  dispatchActionTermsAndCondition =
    TermsAndConditionsActions.CreateTermsAndConditionsEntity;
  statusSelectorTermsAndCondition = TermsAndConditionsSelectors.sUploadStatus;
  uploadCompletedActionTermsAndCondition =
    TermsAndConditionsActions.CreateTermsAndConditionsEntitySuccess;
  uploadFailedActionTermsAndCondition =
    TermsAndConditionsActions.CreateTermsAndConditionsEntityFail;
  additionalDataTermsAndCondition = {};
  dispatchActionDepartmentLogo = DepartmentLogosActions.CreateDepartmentLogo;
  statusSelectorDepartmentLogo = DepartmentLogosSelectors.sUploadStatus;
  uploadCompletedActionDepartmentLogo =
    DepartmentLogosActions.CreateDepartmentLogoSuccess;
  uploadFailedActionDepartmentLogo =
    DepartmentLogosActions.CreateDepartmentLogoFail;
  additionalPayloadDepartmentLogo = {};
  dispatchActionDepartmentAd = DepartmentAdsActions.CreateDepartmentAd;
  statusSelectorDepartmentAd = DepartmentAdsSelectors.sUploadStatus;
  uploadCompletedActionDepartmentAd =
    DepartmentAdsActions.CreateDepartmentAdSuccess;
  uploadFailedActionDepartmentAd = DepartmentAdsActions.CreateDepartmentAdFail;
  additionalPayloadDepartmentAd = {};

  constructor(
    private fb: FormBuilder,
    private actions$: Actions,
    private store$: Store<ApplicationState>,
    private fs: FormsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.presets$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e)
      )
      .subscribe(preset => {
        if (preset.termsAndConditions) {
          this.additionalDataTermsAndCondition = {
            uuid: getUuidFromIri(preset.termsAndConditions)
          };
        }
        if (preset.departmentLogo) {
          this.additionalPayloadDepartmentLogo = {
            uuid: getUuidFromIri(preset.departmentLogo)
          };
        }
        if (preset.departmentAd) {
          this.additionalPayloadDepartmentAd = {
            uuid: getUuidFromIri(preset.departmentAd)
          };
        }

        this.fs.patchForm(this.form, preset);
        this.fs.resetFormErrors(this.form);
      });
    this.actions$
      .pipe(
        ofType(DepartmentLogosActions.CreateDepartmentLogoSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const departmentLogo = extractIri(response);
        this.form.patchValue({ departmentLogo });
      });
    this.actions$
      .pipe(
        ofType(DepartmentAdsActions.CreateDepartmentAdSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const departmentAd = extractIri(response);
        this.form.patchValue({ departmentAd });
      });
    this.actions$
      .pipe(
        ofType(TermsAndConditionsActions.CreateTermsAndConditionsEntitySuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const termsAndConditions = extractIri(response);
        this.form.patchValue({ termsAndConditions });
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      companyName: this.fb.control('', Validators.required),
      generalManager: this.fb.control(''),
      name: this.fb.control('', [
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      nameAbbreviation: this.fb.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      phone: this.fb.control('', [Validators.required]),
      isDefault: this.fb.control(false),
      /*phone: this.fb.group({
       countryCode: this.fb.control(null, [Validators.required]),
       nationalNumber: this.fb.control(null, [Validators.required])
       }),*/
      fax: this.fb.control('', [Validators.required]),
      website: this.fb.control('', [Validators.maxLength(256)]),
      email: this.fb.control('', [Validators.email]),
      noReplyEmail: this.fb.control('', [Validators.email]),
      departmentLogo: this.fb.control(null, [Validators.required]),
      departmentAd: this.fb.control(null),
      termsAndConditions: this.fb.control(null, [Validators.required]),
      technicians: this.fb.control([]),
      taxNumber: this.fb.control('', [Validators.required]),
      competentCourt: this.fb.control('', [Validators.required]),
      bankName: this.fb.control(''),
      iban: this.fb.control('', [Validators.required]),
      bic: this.fb.control('', [Validators.required]),
      address: this.fb.group({
        line1: this.fb.control('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(35)
        ]),
        line2: this.fb.control(''),
        line3: this.fb.control(''),
        line4: this.fb.control(''),
        city: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50)
        ]),
        zipPostcode: this.fb.control(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(10)
        ]),
        stateProvinceCounty: this.fb.control(null),
        country: this.fb.control(null, [Validators.required])
      }),
      emailFromName: this.fb.control(''),
      information: this.fb.control(''),
      companyClaim: this.fb.control(''),
      additionalInformation: this.fb.control('')
    });
  }

  handlePatchDepartmentForm(event: {
    [k: string]: number | string | boolean;
  }): void {
    this.form.patchValue(event);
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleUploadCompleted(event): void {
    console.log('handleUploadCompleted', event);
  }

  handleSubmit(): void {
    const payload: Department = cloneDeep(this.form.value);

    if (this.presets$.getValue()) {
      // remove Images/files because errors appear
      if (payload.departmentAd) {
        delete payload.departmentAd;
      }
      if (payload.departmentLogo) {
        delete payload.departmentLogo;
      }
      if (payload.termsAndConditions) {
        delete payload.termsAndConditions;
      }
    }
    if (payload.iban) {
      payload.iban = payload.iban.trim();
    }

    if (payload.bic) {
      payload.bic = payload.bic.trim();
    }

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({ payload, entity: 'Department' })
      : this.requestUpdateItem.emit({
          iri: this.presets$.getValue()['@id'],
          payload,
          entity: 'Department'
        });
  }
}
