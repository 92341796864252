import {Injectable} from '@angular/core';
import {AbstractApiService} from "../../shared/services";
import {EMPTY, Observable} from "rxjs";
import {ShipmentAdditionalInsurance} from "../models/shipment-additional-insurance.interface";
import {UserRoleService} from "../../shared/services/user-role.service";
import {ShipmentDirection} from "../models";

@Injectable({
  providedIn: 'root'
})
export class ShipmentAdditionalInsurancesService {

  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {
  }

  getShipmentAdditionalInsurance(id: string): Observable<ShipmentAdditionalInsurance> {
    return this.rolesService.userHasRoleFilter<ShipmentAdditionalInsurance>(
      'ROLE_MWS_SHIPMENT_ADDITIONAL_INSURANCE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipment_additional_insurances/${id}`)
    );
  }

  getShipmentAdditionalInsurances(): Observable<Array<ShipmentAdditionalInsurance>> {
    console.log('getShipmentAdditionalInsurances');
    return this.rolesService.userHasRoleFilter<Array<ShipmentAdditionalInsurance>>(
      'ROLE_MWS_SHIPMENT_ADDITIONAL_INSURANCE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/shipment_additional_insurances`)
    );
  }
}
