import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_PRODUCT = '[Master Data] Create Product';
export const CREATE_PRODUCT_SUCCESS = '[Master Data] Create Product Success';
export const CREATE_PRODUCT_FAIL = '[Master Data] Create Product Fail';
export const CreateProduct = createAction(
  CREATE_PRODUCT,
  props<{ payload: fromModuleModels.Product }>()
);
export const CreateProductSuccess = createAction(
  CREATE_PRODUCT_SUCCESS,
  props<{ response: fromModuleModels.Product }>()
);
export const CreateProductFail = createAction(
  CREATE_PRODUCT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_PRODUCT = '[Master Data] Read Product';
export const READ_PRODUCT_SUCCESS = '[Master Data] Read Product Success';
export const READ_PRODUCT_FAIL = '[Master Data] Read Product Fail';
export const ReadProduct = createAction(
  READ_PRODUCT,
  props<{ iri: string }>()
);
export const ReadProductSuccess = createAction(
  READ_PRODUCT_SUCCESS,
  props<{ response }>()
);
export const ReadProductFail = createAction(
  READ_PRODUCT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_PRODUCTS = '[Master Data] Read Products';
export const READ_PRODUCTS_SUCCESS = '[Master Data] Read Products Success';
export const READ_PRODUCTS_FAIL = '[Master Data] Read Products Fail';

export const ReadProducts = createAction(
  READ_PRODUCTS
);

export const ReadProductsSuccess = createAction(
  READ_PRODUCTS_SUCCESS,
  props<{ response: any }>()
);

export const ReadProductsFail = createAction(
  READ_PRODUCTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const SEARCH_PRODUCTS = '[Master Data] Search Products';
export const SEARCH_PRODUCTS_SUCCESS = '[Master Data] Search Products Success';
export const SEARCH_PRODUCTS_FAIL = '[Master Data] Search Products Fail';

export const SearchProducts = createAction(
  SEARCH_PRODUCTS,
  props<{ page: number, params: { [p: string]: number | boolean | string } }>()
);

export const SearchProductsSuccess = createAction(
  SEARCH_PRODUCTS_SUCCESS,
  props<{ response: any }>()
);

export const SearchProductsFail = createAction(
  SEARCH_PRODUCTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_PRODUCT = '[Master Data] Update Product';
export const UPDATE_PRODUCT_SUCCESS = '[Master Data] Update Product Success';
export const UPDATE_PRODUCT_FAIL = '[Master Data] Update Product Fail';
export const UpdateProduct = createAction(
  UPDATE_PRODUCT,
  props<{ iri: string, payload: fromModuleModels.Product }>()
);
export const UpdateProductSuccess = createAction(
  UPDATE_PRODUCT_SUCCESS,
  props<{ response: fromModuleModels.Product }>()
);
export const UpdateProductFail = createAction(
  UPDATE_PRODUCT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_PRODUCT = '[Master Data] Delete Product';
export const DELETE_PRODUCT_SUCCESS = '[Master Data] Delete Product Success';
export const DELETE_PRODUCT_FAIL = '[Master Data] Delete Product Fail';
export const DeleteProduct = createAction(
  DELETE_PRODUCT,
  props<{ iri: string }>()
);
export const DeleteProductSuccess = createAction(
  DELETE_PRODUCT_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteProductFail = createAction(
  DELETE_PRODUCT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const ResetSearchResults = createAction(
  'RESET_SEARCH_RESULTS',
);
