import {createSelector} from '@ngrx/store';

import {ORDER_COMMENT_TAGS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectOrderCommentTagsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[ORDER_COMMENT_TAGS_FEATURE_KEY]
);

export const selectOrderCommentTagsEntities = createSelector(
  selectOrderCommentTagsState,
  (state) => state.entities
);

export const selectOrderCommentTags = createSelector(
  selectOrderCommentTagsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectOrderCommentTagsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectOrderCommentTagsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectOrderCommentTagsState,
  (state) => state.loaded
);
