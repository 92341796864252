import {CustomerReplacementStockItemsEffects} from './customer-replacement-stock-items.effects';
import {CustomerStockItemsEffects} from './customer-stock-items.effects';
import {DataMediumsEffects} from './data-mediums.effects';
import {EnclosureDataMediumsEffects} from './enclosure-data-mediums.effects';
import {ReplacementDataMediumsEffects} from './replacement-data-mediums.effects';
import {ReplacementStockItemsEffects} from './replacement-stock-items.effects';
import {StockItemsEffects} from './stock-items.effects';

export const effects = [
  CustomerReplacementStockItemsEffects,
  CustomerStockItemsEffects,
  DataMediumsEffects,
  EnclosureDataMediumsEffects,
  ReplacementDataMediumsEffects,
  ReplacementStockItemsEffects,
  StockItemsEffects
];
