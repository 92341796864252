import {AbstractApiResponse} from "../../../shared/models";
import {HttpErrorResponse} from "@angular/common/http";
import {createAction, props} from "@ngrx/store";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SHIPMENT_ADDITIONAL_INSURANCE = '[Shipping Module] Read Shipment Additional Insurance';
export const READ_SHIPMENT_ADDITIONAL_INSURANCE_SUCCESS = '[Shipping Module] Read Shipment Additional Insurance Success';
export const READ_SHIPMENT_ADDITIONAL_INSURANCE_FAIL = '[Shipping Module] Read Shipment Additional Insurance Fail';
export const ReadShipmentAdditionalInsurance = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCE,
  props<{ iri: string }>()
);
export const ReadShipmentAdditionalInsuranceSuccess = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCE_SUCCESS,
  props<{ response: AbstractApiResponse | any }>()
);
export const ReadShipmentAdditionalInsuranceFail = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SHIPMENT_ADDITIONAL_INSURANCES = '[Shipping Module] Read Shipment Additional Insurances';
export const READ_SHIPMENT_ADDITIONAL_INSURANCES_SUCCESS = '[Shipping Module] Read Shipment Additional Insurances Success';
export const READ_SHIPMENT_ADDITIONAL_INSURANCES_FAIL = '[Shipping Module] Read Shipment Additional Insurances Fail';
export const ReadShipmentAdditionalInsurances = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCES
);
export const ReadShipmentAdditionalInsurancesSuccess = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCES_SUCCESS,
  props<{ response: AbstractApiResponse | any }>()
);
export const ReadShipmentAdditionalInsurancesFail = createAction(
  READ_SHIPMENT_ADDITIONAL_INSURANCES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
