import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromCancellationInvoicesReducers from './cancellation-invoices.reducers';
import * as fromCommissionCreditItemsReducers from './commission-credit-items.reducers';
import * as fromCommissionCreditsReducers from './commission-credits.reducers';
import * as fromCorrectionInvoiceItemsReducers from './correction-invoice-items.reducers';
import * as fromCorrectionInvoicesReducers from './correction-invoices.reducers';
import * as fromDocumentDeliveriesReducers from './document-deliveries.reducers';
import * as fromDocumentDeliveryProvidersReducers from './document-delivery-providers.reducers';
import * as fromInvoiceItemsReducers from './invoice-items.reducers';
import * as fromInvoicePaymentsReducers from './invoice-payments.reducers';
import * as fromOffersReducers from './offers.reducers';
import * as fromOfferItemsReducers from './offer-items.reducers';
import * as fromPartialInvoiceItemsReducers from './partial-invoice-items.reducers';
import * as fromPayableInvoicesReducers from './payable-invoices.reducers';
import * as fromPaymentProcessesReducers from './payment-processes.reducers';

export const INVOICES_MODULE_FEATURE_KEY = 'invoicesModule';

export const CANCELLATION_INVOICES_FEATURE_KEY = 'cancellationInvoices';
export const COMMISSION_CREDITS_FEATURE_KEY = 'commissionCredits';
export const COMMISSION_CREDIT_ITEMS_FEATURE_KEY = 'commissionCreditItems';
export const CORRECTION_INVOICES_FEATURE_KEY = 'correctionInvoices';
export const CORRECTION_INVOICE_ITEMS_FEATURE_KEY = 'correctionInvoiceItems';
export const DOCUMENT_DELIVERIES_FEATURE_KEY = 'documentDeliveries';
export const DOCUMENT_DELIVERY_PROVIDERS_FEATURE_KEY = 'documentDeliveryProviders';
export const INVOICE_ITEMS_FEATURE_KEY = 'invoiceItems';
export const INVOICE_PAYMENTS_FEATURE_KEY = 'invoicePayments';
export const OFFERS_FEATURE_KEY = 'offers';
export const OFFER_ITEMS_FEATURE_KEY = 'offerItems';
export const PARTIAL_INVOICE_ITEMS_FEATURE_KEY = 'partialInvoiceItems';
export const PAYABLE_INVOICES_FEATURE_KEY = 'payableInvoices';
export const PAYMENT_PROCESSES_FEATURE_KEY = 'paymentProcesses';

export interface InvoicesModuleState {
  [CANCELLATION_INVOICES_FEATURE_KEY]: fromCancellationInvoicesReducers.State;
  [COMMISSION_CREDITS_FEATURE_KEY]: fromCommissionCreditsReducers.State;
  [COMMISSION_CREDIT_ITEMS_FEATURE_KEY]: fromCommissionCreditItemsReducers.State;
  [CORRECTION_INVOICES_FEATURE_KEY]: fromCorrectionInvoicesReducers.State;
  [CORRECTION_INVOICE_ITEMS_FEATURE_KEY]: fromCorrectionInvoiceItemsReducers.State;
  [DOCUMENT_DELIVERIES_FEATURE_KEY]: fromDocumentDeliveriesReducers.State;
  [DOCUMENT_DELIVERY_PROVIDERS_FEATURE_KEY]: fromDocumentDeliveryProvidersReducers.State;
  [INVOICE_ITEMS_FEATURE_KEY]: fromInvoiceItemsReducers.State;
  [INVOICE_PAYMENTS_FEATURE_KEY]: fromInvoicePaymentsReducers.State;
  [OFFERS_FEATURE_KEY]: fromOffersReducers.State;
  [OFFER_ITEMS_FEATURE_KEY]: fromOfferItemsReducers.State;
  [PARTIAL_INVOICE_ITEMS_FEATURE_KEY]: fromPartialInvoiceItemsReducers.State;
  [PAYABLE_INVOICES_FEATURE_KEY]: fromPayableInvoicesReducers.State;
  [PAYMENT_PROCESSES_FEATURE_KEY]: fromPaymentProcessesReducers.State;
}

export const selectInvoicesModuleState = createFeatureSelector<InvoicesModuleState>(INVOICES_MODULE_FEATURE_KEY);

export function reducers(state: InvoicesModuleState, action: Action) {
  return combineReducers({
    [CANCELLATION_INVOICES_FEATURE_KEY]: fromCancellationInvoicesReducers.reducer,
    [COMMISSION_CREDITS_FEATURE_KEY]: fromCommissionCreditsReducers.reducer,
    [COMMISSION_CREDIT_ITEMS_FEATURE_KEY]: fromCommissionCreditItemsReducers.reducer,
    [CORRECTION_INVOICES_FEATURE_KEY]: fromCorrectionInvoicesReducers.reducer,
    [CORRECTION_INVOICE_ITEMS_FEATURE_KEY]: fromCorrectionInvoiceItemsReducers.reducer,
    [DOCUMENT_DELIVERIES_FEATURE_KEY]: fromDocumentDeliveriesReducers.reducer,
    [DOCUMENT_DELIVERY_PROVIDERS_FEATURE_KEY]: fromDocumentDeliveryProvidersReducers.reducer,
    [INVOICE_ITEMS_FEATURE_KEY]: fromInvoiceItemsReducers.reducer,
    [INVOICE_PAYMENTS_FEATURE_KEY]: fromInvoicePaymentsReducers.reducer,
    [OFFERS_FEATURE_KEY]: fromOffersReducers.reducer,
    [OFFER_ITEMS_FEATURE_KEY]: fromOfferItemsReducers.reducer,
    [PARTIAL_INVOICE_ITEMS_FEATURE_KEY]: fromPartialInvoiceItemsReducers.reducer,
    [PAYABLE_INVOICES_FEATURE_KEY]: fromPayableInvoicesReducers.reducer,
    [PAYMENT_PROCESSES_FEATURE_KEY]: fromPaymentProcessesReducers.reducer,
  })(state, action);
}
