import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'joinWith'
})
export class JoinWithPipe implements PipeTransform {

  transform(value: Array<string>, joinString: string = ''): string {
    return value.join(joinString);
  }

}
