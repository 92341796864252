import {createSelector} from '@ngrx/store';
import {EMAIL_TEMPLATE_CATEGORIES, selectMasterDataModuleState} from '../reducers';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';

const NAMESPACE = EMAIL_TEMPLATE_CATEGORIES;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);
