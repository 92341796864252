import {Component} from '@angular/core';


@Component({
  selector: 'page-not-found',
  styles: [`
    .bg {
      background-color: #e4e5e6;
      height: 100vh;
      position: relative;
    }

    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 500px;
      padding: 30px;

      background-color: #fff;
      margin: 0 auto;
    }
  `],
  template: `

    <div class="bg">

      <div class="wrapper">

        <span class="heading--h2">404</span>
        <p>Diese Seite existiert leider nicht.</p>
        <p class="m-ta--2">
          <a [routerLink]="['/']">Zur Startseite</a>
        </p>
      </div>
    </div>
  `
})
export class PageNotFoundComponent {
}
