import { CustomerReplacementStockItemLoadedGuard } from './customer-replacement-stock-item-loaded.guard';
import { CustomerStockItemLoadedGuard } from './customer-stock-item-loaded.guard';
import { DataMediumsLoadedGuard } from './data-mediums-loaded.guard';
import { StockItemsLoadedGuard } from './stock-items-loaded.guard';

export const AllGuards = [
  CustomerReplacementStockItemLoadedGuard,
  CustomerStockItemLoadedGuard,
  DataMediumsLoadedGuard,
  StockItemsLoadedGuard
];

export * from './customer-stock-item-loaded.guard';
export * from './customer-stock-item-loaded.guard';
export * from './data-mediums-loaded.guard';
export * from './stock-items-loaded.guard';
