import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'currencyShortener'
})
export class CurrencyShortenerPipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'EUR') {
      return '€';
    } else {
      return value
    }
  }

}
