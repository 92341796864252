import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { EmailTemplate } from '../models';
import { getUuidFromIri } from '../../shared/utilities/strings.utility';
import { UserRoleService } from '../../shared/services/user-role.service';
import {ParameterBag} from "../../shared/models/ParameterBag.interface";
import {getUriWithPageAndParams} from "../../shared/utilities/urlParams.utility";

type baseType = EmailTemplate;

@Injectable()
export class EmailTemplateService {
  namespace = '/email_templates';

  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  create(payload: baseType): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(this.namespace, payload)
    );
  }

  readOne(iri: string): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readList(
    page = -1,
    params?: ParameterBag
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_EMAIL_TEMPLATE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(getUriWithPageAndParams(this.namespace, page, params))
    );
  }

  readListChunk(uri: string): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_EMAIL_TEMPLATE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  update(iri: string, payload: baseType): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  delete(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_EMAIL_TEMPLATE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }

  preview(templateIri: string, entityIri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_EMAIL_TEMPLATE_RENDER',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/api/email_templates/${getUuidFromIri(
                templateIri
              )}/render?subjectEntity=${entityIri}`,
              true
            )
    );
  }
}
