import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { TermsAndConditionsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.TermsAndConditionsEntity };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
  uploadStatus: { type: number; loaded: number; total?: number };
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    TermsAndConditionsActions.CreateTermsAndConditionsEntity,
    TermsAndConditionsActions.ReadTermsAndConditionsEntity,
    TermsAndConditionsActions.ReadTermsAndConditionsEntities,
    TermsAndConditionsActions.UpdateTermsAndConditionsEntity,
    TermsAndConditionsActions.DeleteTermsAndConditionsEntity,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    TermsAndConditionsActions.ReadTermsAndConditionsEntitySuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    TermsAndConditionsActions.CreateTermsAndConditionsEntityStatus,
    (state, { response }) => {
      return {
        ...state,
        uploadStatus: response,
        loading: true
      };
    }
  ),

  on(
    TermsAndConditionsActions.ReadTermsAndConditionsEntitiesSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: {
            [iri: string]: fromModuleModels.TermsAndConditionsEntity;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    TermsAndConditionsActions.CreateTermsAndConditionsEntitySuccess,
    TermsAndConditionsActions.UpdateTermsAndConditionsEntitySuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    TermsAndConditionsActions.DeleteTermsAndConditionsEntitySuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    TermsAndConditionsActions.CreateTermsAndConditionsEntityFail,
    TermsAndConditionsActions.ReadTermsAndConditionsEntityFail,
    TermsAndConditionsActions.ReadTermsAndConditionsEntitiesFail,
    TermsAndConditionsActions.UpdateTermsAndConditionsEntityFail,
    TermsAndConditionsActions.DeleteTermsAndConditionsEntityFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
