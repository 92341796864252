import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Address } from '../../../customers/models';
import { DepartmentsSelectors } from '../../../master-data/store/selectors';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { InvoiceLike } from '../../models';
import { Department } from '../../../master-data/models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-invoice-footer',
  styleUrls: ['invoice-footer.component.scss'],
  template: `
    <div class="grid" *ngIf="issuer; else nothing">
      <div class="column-14 m-b--32 m-t--32">
        <!--        <span class="p-b&#45;&#45;16" *ngIf="adUri">Kennen Sie schon?</span>-->
        <div style="height: 100px; width: 100%;" class="text-center">
          <img
            [src]="adUri"
            class=""
            alt="Banner {{ issuer?.name }}"
            *ngIf="adUri"
            style="max-width: 100%; max-height: 100%;"
          />
        </div>
      </div>

      <div class="column-7">
        <p>
          <strong>{{ issuer?.name }}</strong
          ><br />
          GF: {{ issuer?.generalManager }}, {{ issuer?.companyName }}
          {{ getAddressBlock() }}
        </p>
      </div>

      <div class="column-auto">
        <p>
          <strong>Bankverbindung / bank account ({{ issuer?.bankName }})</strong
          ><br />
          IBAN: {{ issuer?.iban }} BIC: {{ issuer?.bic }}
        </p>
      </div>
    </div>
    <ng-template #nothing>
      Keine Abteilung festgelegt
    </ng-template>
  `
})
export class InvoiceFooterComponent implements OnInit, OnDestroy {
  @Input() invoice$: Observable<InvoiceLike>;
  invoice: InvoiceLike;
  adUri: SafeUrl;
  issuer: Department;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private dls: DepartmentLogosService,
    private store$: Store<ApplicationState>,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.invoice$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(invoice => !!invoice)
      )
      .subscribe(invoice => {
        this.invoice = invoice;
        if (invoice.issuer) {
          this.store$
            .select(DepartmentsSelectors.sByIri, {
              iri: extractIri(invoice.issuer)
            })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(i => !!i)
            )
            .subscribe(issuer => {
              this.issuer = issuer;
              this.loadAd();
            });
        }
      });
  }

  getAddressBlock(): string {
    if (!this.issuer) {
      return '-';
    }
    const address: Address = this.issuer.address;
    return `${address.line1} ${address.line2 ? address.line2 : ''}${
      address.line3 ? ' ' + address.line3 : ''
    }  ${address.zipPostcode}, ${address.city}`;
  }

  loadAd(): void {
    this.adUri = null;
    if (!this.issuer.departmentAd) {
      return;
    }
    this.dls
      .getDepartmentLogoAsImage(this.issuer.departmentAd)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ body: blob }) => {
        this.adUri = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(blob)
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
