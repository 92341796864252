import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDate implements PipeTransform {

  transform(value: Date | string | moment.Moment): string {
    if (value === null || value === undefined) {
      return '';
    }
    return moment(value).calendar({
      sameDay: '[Heute]',
      nextDay: '[Morgen]',
      nextWeek: 'DD.MM.YYYY',
      lastDay: '[Gestern]',
      lastWeek: 'DD.MM.YYYY',
      sameElse: 'DD.MM.YYYY'
    });

  }

}
