import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import * as fromModuleServices from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {LeadCommentsActions} from '../actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class LeadCommentsEffects {

  constructor(private actions$: Actions, private ls: fromModuleServices.LeadCommentsService, private notifierService: NotifierService) {
  }


  createLeadComment$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentsActions.CreateLeadComment),
    map(action => action),
    switchMap(({payload}) => {

      return this.ls
        .createLeadComment(payload)
        .pipe(
          map(response => LeadCommentsActions.CreateLeadCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadCommentsActions.CreateLeadCommentFail({response})))
        );
    })
  ));


  loadLeadComments$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentsActions.ReadLeadComments),
    map(action => action),
    switchMap(({page, params}) => {

      return this.ls.readLeadComments(page, params).pipe(
        map((response: any) => LeadCommentsActions.ReadLeadCommentsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(LeadCommentsActions.ReadLeadCommentsFail({response})))
      );
    })
  ));


  loadLeadComment$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentsActions.LoadLeadComment),
    map(action => action),
    switchMap(({iri}) => {

      return this.ls
        .getLeadComment(iri)
        .pipe(
          map((response: any) => LeadCommentsActions.LoadLeadCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadCommentsActions.LoadLeadCommentFail({response})))
        );
    })
  ));


  updateLeadComment$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentsActions.UpdateLeadComment),
    map(action => action),
    switchMap(({iri, payload}) => {

      return this.ls
        .updateLeadComment(iri, payload)
        .pipe(
          map((response: any) => LeadCommentsActions.UpdateLeadCommentSuccess({response})),
          catchError((response: HttpErrorResponse) => of(LeadCommentsActions.UpdateLeadCommentFail({response})))
        );
    })
  ));


  deleteLeadComment$ = createEffect(() => this.actions$.pipe(
    ofType(LeadCommentsActions.DeleteLeadComment),
    map(action => action),
    switchMap(({iri}) => {

      return this.ls
        .deleteLeadComment(iri)
        .pipe(
          map(() => LeadCommentsActions.DeleteLeadCommentSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(LeadCommentsActions.DeleteLeadCommentFail({response})))
        );
    })
  ));


  successfulActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      LeadCommentsActions.CreateLeadCommentSuccess,
      LeadCommentsActions.UpdateLeadCommentSuccess,
      LeadCommentsActions.DeleteLeadCommentSuccess
    ),
    tap(({type}) => {
      let message = 'Erfolgreich';
      switch (type) {
        case LeadCommentsActions.UPDATE_LEAD_COMMENT_SUCCESS:
          message = 'Der Kommentar wurde aktualisiert.';
          break;
        case LeadCommentsActions.CREATE_LEAD_COMMENT_SUCCESS:
          message = 'Der Kommentar wurde erstellt.';
          break;
        case LeadCommentsActions.DELETE_LEAD_COMMENT_SUCCESS:
          message = 'Der Kommentar wurde gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});
}
