import {createSelector} from '@ngrx/store';

import {GENERIC_ORDER_ITEMS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectGenericOrderItemsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[GENERIC_ORDER_ITEMS_FEATURE_KEY]
);

export const selectGenericOrderItemsEntities = createSelector(
  selectGenericOrderItemsState,
  (state) => state.entities
);

export const selectGenericOrderItems = createSelector(
  selectGenericOrderItemsEntities,
  (entities) => Object.values(entities)
);

export const selectGenericOrderItemsForOrder = createSelector(
  selectGenericOrderItems,
  (entities, props) => entities.filter(service => service.order === props.iri)
);

export const isLoading = createSelector(
  selectGenericOrderItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectGenericOrderItemsState,
  (state) => state.loaded
);
