import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';

import {STORAGE_LOCATIONS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

import {ApplicationState} from '../../../application-state/store';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {StorageLocation} from '../../models';
import {State} from '../reducers/storage-locations.reducers';


type BaseType = StorageLocation;
type StateType = State;
const NAMESPACE = STORAGE_LOCATIONS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, any>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, any>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);


//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectStorageLocationsTotalItems = createSelector(
  sState,
  (state) => state.totalItems
);

export const selectStorageLocationsPagination = createSelector(
  sState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectStorageLocationsPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectStorageLocationsPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectStorageLocationsPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectStorageLocationsPagination,
  pagination => pagination.next
);

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);
