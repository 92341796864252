import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EmailTemplateCategoriesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { EmailTemplateCategoryService } from '../../services/email-template-category.service';

@Injectable()
export class EmailTemplateCategoriesEffects {
  actionCollection = EmailTemplateCategoriesActions;
  resourceNameDE = 'E-Mail Template Kategorie';
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.Create),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.create(payload).pipe(
          map(response => this.actionCollection.CreateSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.CreateFail({ response }))
          )
        );
      })
    )
  );
  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.Read),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readOne(iri).pipe(
          map(response => this.actionCollection.ReadSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadFail({ response }))
          )
        );
      })
    )
  );
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.ReadList),
      map(action => action),
      switchMap(() => {
        return this.service.readList().pipe(
          map(response => this.actionCollection.ReadListSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadListFail({ response }))
          )
        );
      })
    )
  );
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.Update),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.update(iri, payload).pipe(
          map(response => this.actionCollection.UpdateSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.UpdateFail({ response }))
          )
        );
      })
    )
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.Delete),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.delete(iri).pipe(
          map(() => this.actionCollection.DeleteSuccess({ iri })),
          catchError(response =>
            of(this.actionCollection.DeleteFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          this.actionCollection.DeleteFail,
          this.actionCollection.UpdateFail,
          this.actionCollection.ReadListFail,
          this.actionCollection.ReadFail,
          this.actionCollection.CreateFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei ' + type + ': ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          this.actionCollection.CreateSuccess,
          this.actionCollection.UpdateSuccess,
          this.actionCollection.DeleteSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case this.actionCollection.CREATE_SUCCESS:
              message =
                'Die Ressource (' + this.resourceNameDE + ') wurder angelegt.';
              break;
            case this.actionCollection.UPDATE_SUCCESS:
              message =
                'Die Ressource (' +
                this.resourceNameDE +
                ') wurder aktualisiert.';
              break;
            case this.actionCollection.DELETE_SUCCESS:
              message =
                'Die Ressource (' + this.resourceNameDE + ') wurder gelöscht.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: EmailTemplateCategoryService,
    private notifierService: NotifierService
  ) {}
}
