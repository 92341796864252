import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { ApplicationState } from '../../../application-state/store';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { AuthSelectors } from '../../store/selectors';
import { AuthActions } from '../../store';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-login-screen',
  styleUrls: ['login-screen.component.scss'],
  template: `
    <div class="content__wrapper wrap">
      <div class="form-card mat-elevation-z1 grid pos-relative">
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="logo column-12 m-b--16">
          <img
            src="assets/images/logos/logo-bmoffice24.png"
            class="img--resp"
            alt="Logo Bindig Media"
          />
        </div>

        <div class="icon column-2 m-ta--2">
          <mat-icon>account_circle</mat-icon>
        </div>

        <!-- Todo: Could be standalone component -->

        <form class="column-14 grid" [formGroup]="lf">
          <mat-form-field class="column-14">
            <mat-label>Benutzername</mat-label>
            <input type="text" matInput formControlName="username" />
          </mat-form-field>

          <mat-form-field class="column-14">
            <mat-label>Passwort</mat-label>
            <input
              type="password"
              matInput
              formControlName="password"
              #passwordInput
            />
            <mat-icon
              matSuffix
              style="margin-right: 12px; cursor: pointer; opacity: .8"
              (mousedown)="passwordInput.type = 'text'"
              (mouseup)="passwordInput.type = 'password'"
              >visibility
            </mat-icon>
          </mat-form-field>

          <div class="m-t--8 column-9">
            <span
              class="text-link"
              (click)="
                showRequestPasswordResetFormDialog(requestResetPasswordForm)
              "
              >Passwort vergessen?</span
            >
          </div>

          <div class="m-ta--2 m-t--8 column-5">
            <button
              [disabled]="lf.invalid || lf.untouched"
              (click)="handleLogIn()"
              mat-flat-button
              color="green"
            >
              <mat-icon class="m-r--8">login</mat-icon>
              <span>Login</span>
            </button>
          </div>
        </form>
      </div>

      <div class="grid m-t--32" *ngIf="reason">
        <div
          class="herobox mat-elevation-z1 column-14"
          *ngIf="reason === 'LOGIN_FAILED'"
          data-box-style="yellow"
        >
          <strong>Zugangsdaten ungültig</strong>, bitte versuchen Sie es erneut
        </div>
        <div
          class="herobox mat-elevation-z1 column-14"
          *ngIf="reason === 'LOGOUT_SUCCESS'"
          data-box-style="green"
        >
          Sie haben sich <strong>erfolgreich abgemeldet</strong>.
        </div>
        <div
          class="herobox mat-elevation-z1 column-14"
          *ngIf="reason === 'TIMEOUT'"
          data-box-style="blue"
        >
          Ihre Session ist leider abgelaufen.
          <strong>Sie wurden abgemeldet.</strong>
        </div>
      </div>
    </div>

    <ng-template #requestResetPasswordForm>
      <app-dialog-header>
        Passwort zurücksetzen
      </app-dialog-header>

      <div mat-dialog-content>
        <request-reset-password-form
          (requestCreateResetPasswordRequest)="
            handleCreateResetPasswordRequest($event)
          "
        ></request-reset-password-form>
      </div>
    </ng-template>
  `
})
export class LoginScreenComponent extends BaseOnDestroyComponent
  implements OnInit, AfterViewInit {
  errors$: ErrorsObject | any;
  isLoading$: Observable<boolean>;
  lf: FormGroup;
  reason: string = null;
  subscriptions: Subscription[] = [];
  redirectAfterLogin = '/dashboard';

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private title: AbstractTitleService,
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    public updates$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(AuthSelectors.isLoading);
    this.title.setTitle('Login');

    this.lf = this.fb.group({
      username: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required])
    });
    // this.errors$ = this.store$.pipe(select(AuthSelectors.selectErrors));
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params: Params) => {
        this.reason = params.reason || null;
        if (params.redirectAfterLogin) {
          this.redirectAfterLogin = decodeURIComponent(
            params.redirectAfterLogin
          );
          // console.log(this.redirectAfterLogin);
        }
      });

    this.updates$
      .pipe(
        ofType(AuthActions.LoginUserSuccess),
        takeUntil(this.onDestroy$),
        take(1)
      )
      .subscribe(() => {
        this.notifierService.notify('success', 'Login Erfolgreich');
        this.router
          .navigateByUrl(this.redirectAfterLogin)
          .then(() => {
            // console.log('erfolgreich nach ' + this.redirectAfterLogin);
          })
          .catch(e => {
            console.log(e);
          });
      });
    this.updates$
      .pipe(ofType(AuthActions.LoginUserFail), takeUntil(this.onDestroy$))
      .subscribe(data => {
        this.reason = 'LOGIN_FAILED';
        this.notifierService.notify('error', 'Fehler beim Login');
      });
  }

  ngAfterViewInit(): void {
    this.authService.isLoggedInAndNotExpired$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e === true)
      )
      .subscribe(() => {
        this.router.navigateByUrl(this.redirectAfterLogin);
      });
  }

  handleLogIn(): void {
    this.store$.dispatch(AuthActions.LoginUser({ ...this.lf.value }));
  }

  showRequestPasswordResetFormDialog(ref: TemplateRef<any>): void {
    this.dialog.open(ref);
  }

  handleCreateResetPasswordRequest(payload: { username: string }): void {
    this.store$.dispatch(AuthActions.CreatePasswordResetRequest({ payload }));
  }
}
