import { Component, Input, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { Shipment } from '../../../shipping/models';
import { combineLatest, Observable, of } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { tap } from 'rxjs/operators';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../utilities/observable.utility';
import { OrdersActions } from '../../../orders/store';
import { extractIri } from '../../utilities/objects.utility';
import { DataMedium } from '../../../warehouse/models';
import { StorageSystemsSelectors } from '../../../master-data/store/selectors';
import { StorageSystemsActions } from '../../../master-data/store';

@Component({
  selector: 'app-shipment-detail-display-line',
  styleUrls: ['./shipment-detail-display-line.component.scss'],
  template: `
    <div class="row">
      <div class="col">
        <app-shipment-address-block
          [address]="shipment.shipFrom"
        ></app-shipment-address-block>
      </div>
      <div class="col">
        <app-shipment-address-block
          [address]="shipment.shipTo"
        ></app-shipment-address-block>
      </div>
      <div class="col" *ngIf="shipment.pickupDispatcher">
        <app-shipment-address-block
          [address]="shipment.pickupDispatcher"
        ></app-shipment-address-block>
      </div>
      <div class="col">
        <h1>Zusätzliche Informationen:</h1>

        <div class="d-flex" *ngIf="shipment?.pickupEarliest">
          <div class="text-bold pe-2">Abholung:</div>
          <div
            class="rounded-2 bordered flex-grow-1 ps-2"
            style="min-height: 2.5rem;"
          >
            {{ shipment.pickupEarliest | date: 'dd.MM.Y - HH:mm' }} -
            {{ shipment.pickupLatest | date: 'HH:mm' }}
          </div>
        </div>
        <div class="d-flex">
          <div class="text-bold pe-2">Bemerkung:</div>
          <div
            class="rounded-2 bordered flex-grow-1 ps-2"
            style="min-height: 2.5rem;"
          >
            {{ shipment.description || '-' }}
          </div>
        </div>
        <div class="d-flex">
          <div class="text-bold pe-2">Aufträge/Datenträger:</div>
          <div
            class="rounded-2 bordered flex-grow-1 ps-2"
            style="min-height: 2.5rem;"
          >
            {{ shipment.orders.length || '-' }}/{{
              shipment.dataMedia.length || '-'
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row pos-relative">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <h1>Verknüpfte Aufträge</h1>
      <div class="row" *ngFor="let order of orders$ | async">
        <!--        <pre>{{order|json}}</pre>-->
        <app-shipment-order-display-line
          [orderIri]="extractIri(order)"
          [readonly]="true"
          [readonlyArchive]="true"
          [inputSelectedDataMedia]="selectedDataMedia"
          [inputFilteredDataMedia]="selectedDataMedia"
          [dataMediaSelectionFilterFn]="dataMediaFilterFn"
        ></app-shipment-order-display-line>
      </div>
    </div>
  `
})
export class ShipmentDetailDisplayLineComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() shipment: Shipment;
  isLoading$: Observable<boolean>;

  orders$: Observable<Array<Order>>;
  of = of;
  extractIri = extractIri;

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  get selectedDataMedia(): Array<string> {
    return this.shipment?.dataMedia?.map(e => extractIri(e));
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading)
    ]);
    for (const order of this.shipment.orders) {
      this.store$.dispatch(OrdersActions.ReadOrder({ iri: extractIri(order) }));
    }
    loadIfNotLoaded(
      this.store$,
      OrdersSelectors.isLoaded,
      OrdersActions.ReadOrders({ page: -1 })
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
    this.selectOrders();
  }

  dataMediaFilterFn = (dataMedium: DataMedium) => {
    return (
      this.shipment?.dataMedia?.findIndex(
        e => extractIri(e) === extractIri(dataMedium)
      ) > -1
    );
  };

  private selectOrders(): void {
    const selections = [];
    for (const order of this.shipment.orders) {
      selections.push(
        this.store$.select(OrdersSelectors.sByIri, { iri: order })
      );
    }
    this.orders$ = combineLatest(selections).pipe(
      tap(orders => console.log(orders))
    );
  }
}
