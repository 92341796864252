import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as fromModuleModels from '../../models/follow-up-detail.interface';
import { FollowUpReason } from '../../models/follow-up-reason.interface';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FollowUpReasonSelectors } from '../../store/selectors';
import { FollowUpActions, FollowUpReasonAction } from '../../store';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { Order } from '../../../orders/models';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { OrderSelectDialogComponent } from '../../../orders/components/order-select-dialog/order-select-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { Administrator } from '../../../administrators/models';
import { AdministratorsActions } from '../../../administrators/store';
import { CustomersSelectors } from '../../../customers/store/selectors';
import { Customer } from '../../../customers/models';
import { CustomersActions } from '../../../customers/store';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  isLoading,
  selectFollowUpDetail,
  selectFormErrors
} from '../../store/selectors/follow-up.selectors';
import { FollowUpDetail } from '../../models';
import * as moment from 'moment';
import { OrdersActions } from '../../../orders/store';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-follow-up-create-and-update-dialog',
  templateUrl: './follow-up-create-and-update-dialog.component.html',
  styleUrls: ['./follow-up-create-and-update-dialog.component.scss']
})
export class FollowUpCreateAndUpdateDialogComponent
  implements OnInit, OnDestroy {
  COLORS: Array<any> = [
    { text: 'Gelb', color: '#ffffe0', border: '#d5d5bc' },
    { text: 'Grün', color: '#d1ebcd', border: '#b4cbb1' },
    { text: 'Blau', color: '#dff3ff', border: '#b9c9d3' },
    { text: 'Orange', color: '#ffeedf', border: '#dccdc0' },
    { text: 'Rot', color: '#ffdfea', border: '#dcc0ca' },
    { text: 'Grau', color: '#f0f0f0', border: '#cfcfcf' },
    { text: 'Türkis', color: '#EBFBF9', border: '#D6F5F2' },
    { text: 'Lila', color: '#F8ECF8', border: '#D6CCD6' }
  ];

  errors: any;

  presets$: BehaviorSubject<fromModuleModels.FollowUpDetail | null>;

  @Output()
  cancelEdit: EventEmitter<void> = new EventEmitter<void>();
  customer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  followUpForm: FormGroup;

  orders$: Observable<Array<Order>>;
  followUpReasons$: Observable<Array<FollowUpReason>>;
  followUpReasonsIsLoading$: Observable<boolean>;
  employees$: Observable<Array<Administrator>>;
  employeesIsLoading$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public updates$: Actions,
    public dialogRef: MatDialogRef<FollowUpCreateAndUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(isLoading);
    this.initForm();

    this.errors = this.store$.pipe(select(selectFormErrors));
    this.presets$ = new BehaviorSubject<FollowUpDetail | null>(null);
    this.orders$ = this.store$.select(OrdersSelectors.sList);

    this.followUpReasons$ = this.store$.select(
      FollowUpReasonSelectors.selectFollowUpReasons
    );
    this.followUpReasonsIsLoading$ = this.store$.select(
      FollowUpReasonSelectors.isLoading
    );
    this.loadFollowUpReasonsIfNotLoaded();
    this.employees$ = this.store$.select(AdministratorsSelectors.sList).pipe(
      // Sort first for lastName then firstName
      map((employees: Administrator[]) =>
        employees.sort(
          (a, b) =>
            a.lastName.localeCompare(b.lastName) ||
            a.firstName.localeCompare(b.firstName)
        )
      )
    );
    this.employeesIsLoading$ = this.store$.select(
      AdministratorsSelectors.isLoading
    );
    this.loadEmployeesIfNotLoaded();

    if (this.router.routerState.snapshot.url.indexOf('/orders/') > -1) {
      const parts = this.router.routerState.snapshot.url.split('/');
      const orderUUID = parts[parts.length - 1];
      this.followUpForm.patchValue({ order: '/api/orders/' + orderUUID });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.followUpForm = this.fb.group({
      color: this.fb.control(this.COLORS[0].color, [Validators.required]),
      order: this.fb.control(null),
      customer: this.fb.control(null),
      reason: this.fb.control(null, [Validators.required]),
      employee: this.fb.control(null, [Validators.required]),
      date: this.fb.control(
        moment()
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substring(0, 10),
        [Validators.required]
      ),
      time: this.fb.control(
        moment()
          .toISOString(true)
          .substring(11, 16),
        [Validators.required]
      ),
      title: this.fb.control('', [Validators.required]),
      description: this.fb.control('')
    });

    if (this.data && this.data.followUp) {
      this.store$
        .select(selectFollowUpDetail, { iri: this.data.followUp['@id'] })
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e)
        )
        .subscribe((followUp: FollowUpDetail) => {
          if (followUp.customer) {
            this.store$.dispatch(
              CustomersActions.ReadCustomer({
                iri: extractIri(followUp.customer)
              })
            );
            this.store$
              .select(CustomersSelectors.selectCustomerByIndex, {
                iri: followUp.customer
              })
              .pipe(
                takeUntil(this.onDestroy$),
                filter(e => !!e)
              )
              .subscribe(c => {
                this.customer$.next(c);
              });
          }
          if (followUp.order) {
            this.store$.dispatch(
              OrdersActions.ReadOrder({ iri: extractIri(followUp.order) })
            );
          }
          this.patchForm(followUp);
        });
      this.store$.dispatch(
        FollowUpActions.LoadFollowUp({ iri: extractIri(this.data.followUp) })
      );
    }
    this.store$.select(UserProfileSelectors.selectUserProfile).subscribe(e => {
      if (!this.followUpForm.get('employee').value) {
        this.followUpForm.get('employee').setValue(e['@id']);
      }
    });
  }

  patchForm(followUp: FollowUpDetail): void {
    if (!followUp) {
      return;
    }
    Object.keys(followUp).forEach(key => {
      if (key === 'date') {
        const date = moment(followUp.date);
        const dateFormField = this.followUpForm.get('date');
        dateFormField.setValue(date.format('yyyy-MM-DD'));
        const timeFormField = this.followUpForm.get('time');
        timeFormField.setValue(date.format('HH:mm'));
        return;
      }
      if (key === 'order' && followUp[key]) {
        this.store$.dispatch(OrdersActions.ReadOrder({ iri: followUp[key] }));
      }
      const formInput = this.followUpForm.get(key);

      if (formInput && !!followUp[key]) {
        formInput.setValue(followUp[key]);
      }
    });
    this.followUpForm.markAsPristine();
    this.followUpForm.markAllAsTouched();
  }

  getBackgroundColor(): object {
    if (!this.followUpForm.value.color) {
      return {};
    } else {
      return { 'background-color': this.followUpForm.value.color };
    }
  }

  resetAndClose(): void {
    this.cancelEdit.emit();
    this.dialog.closeAll();
  }

  merkAsDone(): void {
    this.closeOnSuccess();
    this.store$.dispatch(
      FollowUpActions.DeleteFollowUp({ iri: this.data.followUp['@id'] })
    );
  }

  deleteFollowUp(): void {
    if (
      confirm(
        'Sind Sie sich sicher dass die diese Wiedervorlage löschen wollen?'
      )
    ) {
      this.closeOnSuccess();
      this.store$.dispatch(
        FollowUpActions.DeleteFollowUp({ iri: this.data.followUp['@id'] })
      );
    }
  }

  openOrderSelectDialog(): void {
    const dialog = this.dialog.open(OrderSelectDialogComponent, {
      panelClass: 'width-95',
      disableClose: false
    });
    dialog.afterClosed().subscribe(orderId => {
      if (orderId) {
        this.followUpForm.patchValue({ order: orderId });
      }
    });
  }

  handleSubmit(): void {
    const formDate = this.followUpForm.get('date').value;
    const formTime = this.followUpForm.get('time').value;
    const p = (formDate + ' ' + formTime).split(/\D/).map(e => parseInt(e, 10));
    const date = new Date(p[0], p[1] - 1, p[2], p[3], p[4], 0, 0);
    const payload = { ...this.followUpForm.value, date };
    if (!payload.customer || payload.customer.trim() === '') {
      payload.customer = null;
    }
    if (!payload.order || payload.order.trim() === '') {
      payload.order = null;
    }
    if (this.data && this.data.followUp) {
      this.store$.dispatch(
        FollowUpActions.UpdateFollowUp({
          iri: this.data.followUp['@id'],
          payload
        })
      );
    } else {
      this.store$.dispatch(FollowUpActions.CreateFollowUp({ payload }));
    }
    this.updates$
      .pipe(
        ofType(
          FollowUpActions.CreateFollowUpSuccess,
          FollowUpActions.UpdateFollowUpSuccess
        )
      )
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }

  findEmployee(term: string, item: Administrator): boolean {
    return (
      item.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
      item.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1
    );
  }

  selectCustomer($event: Customer): void {
    this.followUpForm.patchValue({ customer: extractIri($event) });
  }

  private loadFollowUpReasonsIfNotLoaded(): void {
    this.store$
      .select(FollowUpReasonSelectors.isLoaded)
      .subscribe((loaded: boolean) => {
        if (!loaded) {
          this.store$.dispatch(FollowUpReasonAction.ReadFollowUpReasons());
        }
      });
  }

  private loadEmployeesIfNotLoaded(): void {
    this.store$
      .select(AdministratorsSelectors.isLoaded)
      .subscribe((loaded: boolean) => {
        if (!loaded) {
          this.store$.dispatch(AdministratorsActions.ReadAdministrators());
        }
      });
  }

  private closeOnSuccess(): void {
    this.updates$
      .pipe(ofType(FollowUpActions.DeleteFollowUpSuccess), take(1))
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }
}
