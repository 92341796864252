import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {EmailTemplate, EmailTemplateCategory, EmailTemplateContext, Locale} from '../../models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../shared/services';
import {filter, takeUntil} from 'rxjs/operators';
import {
  EmailTemplateCategoriesSelectors,
  EmailTemplateContextsSelectors, EmailTemplatesSelectors,
  LocalesSelectors
} from '../../store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {
  EmailTemplateCategoriesActions,
  EmailTemplateContextsActions,
  EmailTemplatesActions,
  LocalesActions
} from '../../store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {extractIri} from "../../../shared/utilities/objects.utility";
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";
import {NotifierService} from "angular-notifier";

enum varType {date = 'date', string = 'string', float = 'float', int = 'integer', bool = 'boolean'};
type VariableType = { key: string, type: varType };

@Component({
  selector: 'app-email-templates-form',
  styleUrls: ['./email-templates-form.component.scss'],
  template: `
    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Email-Vorlage bearbeiten' : 'Neue Email-Vorlage anlegen' }}</span>
      </div>

      <div class="card__content p-a--24">

        <div class="grid" [formGroup]="form">
          <mat-form-field class="column-14">
            <mat-label>Name</mat-label>
            <input type="text" formControlName="name" matInput required>
            <mat-error>
              <app-form-error [fieldName]="'name'" [formGroup]="form"></app-form-error>
            </mat-error>

          </mat-form-field>
          <div class="column-14 mat-form-field">
            <ng-select
              [items]="locales$|async"
              bindLabel="name"
              [loading]="localesIsLoading$| async"
              bindValue="locale"
              [clearable]="true"
              [markFirst]="true"
              placeholder="Sprache"
              formControlName="locale">
            </ng-select>
            <mat-error>
              <app-form-error [fieldName]="'locale'" [formGroup]="form"></app-form-error>
            </mat-error>

          </div>
          <div class=" mat-form-field column-14">
            <ng-select
              [items]="emailTemplateCategories$|async"
              bindLabel="name"
              [loading]="emailTemplateCategoriesIsLoading$| async"
              bindValue="@id"
              [clearable]="true"
              [markFirst]="true"
              placeholder="Vorlagen Kategorie"
              formControlName="emailTemplateCategory">
            </ng-select>
            <mat-error>
              <app-form-error [fieldName]="'emailTemplateCategory'" [formGroup]="form"></app-form-error>
            </mat-error>

          </div>
          <div class="column-14 border-bottom mb-3 pb-1 mat-form-field">
            <ng-select
              [items]="emailTemplateContexts$|async"
              bindLabel="name"
              [loading]="emailTemplateContextsIsLoading$| async"
              bindValue="@id"
              [clearable]="true"
              [markFirst]="true"
              placeholder="Emailverwendung"
              formControlName="templateContext">
            </ng-select>
            <mat-error>
              <app-form-error [fieldName]="'templateContext'" [formGroup]="form"></app-form-error>
            </mat-error>

          </div>
          <mat-form-field class="column-14">
            <mat-label>Betreff</mat-label>
            <input type="text" formControlName="subject" matInput required>
            <mat-error>
              <app-form-error [fieldName]="'subject'" [formGroup]="form"></app-form-error>
            </mat-error>

          </mat-form-field>
          <div class="row column-14">
            <div class="col-4">
              <mat-list role="list" class="variable-list">
                <mat-list-item role="listitem" class="title">Verfügbare Variablen</mat-list-item>
                <mat-list-item role="listitem" *ngIf="possibleVariables.length<=0">keine Variablen verfügbar
                </mat-list-item>
                <mat-list-item role="listitem"
                               *ngFor="let variable of possibleVariables" (click)="copyTextToClipboard(variable)">
                  <mat-icon
                    mat-list-icon>{{variable.type === 'date' ? 'date_range' : variable.type === 'float' ? 'percent' : 'edit_note'}}</mat-icon>
                  <div mat-line>{{variable.key}}</div>

                </mat-list-item>
              </mat-list>
            </div>
            <div class="col-8">
              <div class="mat-form-field">
                <app-text-editor formControlName="content" label="Inhalt" required
                                 [showSignRequestLink]="true"></app-text-editor>
                <mat-error>
                  <app-form-error [fieldName]="'content'" [formGroup]="form"></app-form-error>
                </mat-error>

              </div>
            </div>
          </div>
          <div class="m-ta--2 column-14">

            <button *ngIf="presets$.getValue() || form.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                    color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button [disabled]="form.invalid || form.untouched"
                    (click)="handleSubmit()"
                    mat-flat-button color="green">
              <mat-icon class="m-r--8">save</mat-icon>
              <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  `
})
export class EmailTemplatesFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() presets$: BehaviorSubject<EmailTemplate>;

  @Output() requestCreateItem: EventEmitter<{ payload: EmailTemplate, entity: string }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: EmailTemplate,
    entity: string
  }> = new EventEmitter();
  locales$: Observable<Array<Locale>>;
  localesIsLoading$: Observable<boolean>;
  emailTemplateCategories$: Observable<Array<EmailTemplateCategory>>;
  emailTemplateCategoriesIsLoading$: Observable<boolean>;
  emailTemplateContexts$: Observable<Array<EmailTemplateContext>>;
  emailTemplateContextsIsLoading$: Observable<boolean>;

  form: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  possibleContextVariables: { [key: string]: Array<VariableType> };

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private notifier: NotifierService,
    private store$: Store<ApplicationState>,
  ) {

  }

  get possibleVariables(): Array<VariableType> {
    if (!this.form.get('templateContext').value) {
      return [];
    }
    const selectedContext = getUuidFromIri(this.form.get('templateContext').value);
    return this.possibleContextVariables[selectedContext] || [];
  }

  ngOnInit(): void {
    this.setVariables();
    this.initForm();
    this.loadLocales();
    this.loadEmailTemplateCategories();
    this.loadEmailTemplateContexts();

  }

  ngAfterViewInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(presets => {
      this.initForm();
      if (presets && presets['@id'] && presets['@type'] === 'EmailTemplate') {
        this.store$.dispatch(EmailTemplatesActions.ReadEmailTemplate({iri: presets['@id']}));

        this.store$.select(EmailTemplatesSelectors.sByIri, {iri: presets['@id']})
          .pipe(takeUntil(this.onDestroy$), filter((e => !!e && e.content)))
          .subscribe((data) => {
            const formData = {...data};
            if (formData.emailTemplateCategory) {
              formData.emailTemplateCategory = extractIri(formData.emailTemplateCategory);
            }
            if (formData.locale) {
              formData.locale = extractIri(formData.locale);
            }
            this.fs.patchForm(this.form, formData);
            this.fs.resetFormErrors(this.form);
          });
      }
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      subject: this.fb.control('', [Validators.required]),
      content: this.fb.control(null, [Validators.required]),
      locale: this.fb.control(null, [Validators.required]),
      emailTemplateCategory: this.fb.control(null, [Validators.required]),
      templateContext: this.fb.control(null, [Validators.required]),
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: EmailTemplate = this.form.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'EmailTemplate'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'EmailTemplate'});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private loadLocales(): void {
    this.locales$ = this.store$.select(LocalesSelectors.selectLocales);
    this.localesIsLoading$ = this.store$.select(LocalesSelectors.isLoading);
    loadIfNotLoaded(this.store$, LocalesSelectors.isLoaded, LocalesActions.ReadLocales());

  }

  private loadEmailTemplateCategories(): void {
    this.emailTemplateCategories$ = this.store$.select(EmailTemplateCategoriesSelectors.sList);
    this.emailTemplateCategoriesIsLoading$ = this.store$.select(EmailTemplateCategoriesSelectors.isLoading);
    loadIfNotLoaded(this.store$, EmailTemplateCategoriesSelectors.isLoaded, EmailTemplateCategoriesActions.ReadList());

  }

  private loadEmailTemplateContexts(): void {
    this.emailTemplateContexts$ = this.store$.select(EmailTemplateContextsSelectors.sListSortedByName);
    this.emailTemplateContextsIsLoading$ = this.store$.select(EmailTemplateContextsSelectors.isLoading);
    loadIfNotLoaded(this.store$, EmailTemplateContextsSelectors.isLoaded, EmailTemplateContextsActions.ReadList());

  }

  copyTextToClipboard(item: VariableType): void {
    let text = '';
    switch (item.type) {
      case varType.bool:
      case varType.int:
      case varType.string:
        text = `{{${item.key}}}`;
        break;
      case varType.date:
        text = `{{${item.key} | date('d.m.Y')}}`;
        break;
      case varType.float:
        text = `{{${item.key} | format_percent_number(locale='de')}}`;
        break;
    }
    navigator.clipboard.writeText(text).then(() => {
      this.notifier.show({type: 'success', message: text + ' Erfolgreich in die Zwischenablage kopiert.'});
    }, (err) => {
      this.notifier.show({type: 'error', message: ' Konnte Text nicht in die Zwischenablage kopieren.' + err});
    });
  }

  private setVariables(): void {
    const greeting = [{key: 'greeting', type: varType.string}];
    const departmentVars = [
      {key: 'department.companyName', type: varType.string},
      {key: 'department.name', type: varType.string},
      {key: 'department.phone', type: varType.string},
      {key: 'department.fax', type: varType.string},
      {key: 'department.email', type: varType.string},
      {key: 'department.website', type: varType.string},
      {key: 'department.bankName', type: varType.string},
      {key: 'department.iban', type: varType.string},
      {key: 'department.bic', type: varType.string},
      {key: 'department.address.line1', type: varType.string},
      {key: 'department.address.line2', type: varType.string},
      {key: 'department.address.line3', type: varType.string},
      {key: 'department.address.line4', type: varType.string},
      {key: 'department.address.city', type: varType.string},
      {key: 'department.address.zipPostcode', type: varType.string},
      {key: 'department.address.stateProvinceCounty', type: varType.string},
      {key: 'department.address.country', type: varType.string},
    ];
    const orderVars = [
      {key: 'orderNumber', type: varType.string},
      {key: 'employeeName', type: varType.string},
      ...departmentVars
    ];
    const invoiceVars = [
      {key: 'invoiceNumber', type: varType.string},
      {key: 'invoiceDate', type: varType.date},
      {key: 'netTotal', type: varType.string},
      {key: 'grossTotal', type: varType.string},
      {key: 'amountOutstanding', type: varType.string},
      {key: 'amountPaid', type: varType.string},
      {key: 'dueDate', type: varType.date},
      {key: 'orderCreatedAt', type: varType.date},
      {key: 'bindingOrderCreatedAt', type: varType.date},
      {key: 'firstReminderAt', type: varType.date},
      {key: 'secondReminderAt', type: varType.date},

    ]
    this.possibleContextVariables = {
      BINDING_ORDER: [
        ...greeting,
        ...orderVars,
        {key: 'customerDataMedia[n].model', type: varType.string},
        {key: 'customerDataMedia[n].manufacturer', type: varType.string},
        {key: 'customerDataMedia[n].serialNumber', type: varType.string},
        {key: 'customerDataMedia[n].size', type: varType.string},
        {key: 'customerDataMedia[n].type', type: varType.string},
        {key: 'replacementDataMedia[n].model', type: varType.string},
        {key: 'replacementDataMedia[n].manufacturer', type: varType.string},
        {key: 'replacementDataMedia[n].serialNumber', type: varType.string},
        {key: 'replacementDataMedia[n].size', type: varType.string},
        {key: 'replacementDataMedia[n].type', type: varType.string},
        {key: 'replacementDataMedia[n].netPrice', type: varType.string},
        {key: 'replacementDataMedia[n].grossPrice', type: varType.string},
        {key: 'replacementDataMedia[n].quantity', type: varType.string},
        {key: 'dataRecoveryPriorityMode.name', type: varType.string},
        {key: 'dataRecoveryPriorityMode.id', type: varType.string},
        {key: 'dataRecoveryCosts.net', type: varType.string},
        {key: 'dataRecoveryCosts.gross', type: varType.string},
        {key: 'initialCosts.net', type: varType.string},
        {key: 'initialCosts.gross', type: varType.string},
        {key: 'analysisCosts.net', type: varType.string},
        {key: 'analysisCosts.gross', type: varType.string},
        {key: 'returnShipmentCosts.net', type: varType.string},
        {key: 'returnShipmentCosts.gross', type: varType.string},
        {key: 'disposalCosts.net', type: varType.string},
        {key: 'disposalCosts.gross', type: varType.string},
        {key: 'dismantlingCosts.net', type: varType.string},
        {key: 'dismantlingCosts.gross', type: varType.string},
        {key: 'downPaymentPercentage', type: varType.float},
        {key: 'discountPercentage', type: varType.float},
        {key: 'shippingProviderToCustomer.name', type: varType.string},
        {key: 'shippingProviderToCustomer.id', type: varType.string},
        {key: 'disposalType.name', type: varType.string},
        {key: 'disposalType.id', type: varType.string},
        {key: 'replacementDataMediumSource.name', type: varType.string},
        {key: 'replacementDataMediumSource.id', type: varType.string},
        {key: 'analysisResult', type: varType.string},
      ],
      ORDER_GENERAL: [
        ...greeting,
        ...orderVars,
        {key: 'analysisOrderedAt', type: varType.date}
      ],
      LEAD: [
        ...greeting,
        {key: 'orderNumber', type: varType.string},
        {key: 'employeeName', type: varType.string},
        {key: 'leadCreatedAt', type: varType.date},
        {key: 'storageSystemType', type: varType.string},
        {key: 'storageSystemManufacturer', type: varType.string},
        {key: 'storageSystemModel', type: varType.string},
        {key: 'storageSystemSerialNumber', type: varType.string},
        {key: 'storageSystemSize', type: varType.int},
        {key: 'numberOfDataMedia', type: varType.int},
        {key: 'numberOfPartitions', type: varType.int},
        {key: 'dataLossAt', type: varType.date},
        {key: 'dataLossDetectedAt', type: varType.date},
        {key: 'pinPassword', type: varType.string},
        {key: 'host', type: varType.string},
        {key: 'fileSystem', type: varType.string},
        {key: 'operatingSystem', type: varType.string},
        {key: 'damage', type: varType.string},
        {key: 'symptom', type: varType.string},
        {key: 'stepsAlreadyTaken', type: varType.string},
        {key: 'history', type: varType.string},
        {key: 'requiredData', type: varType.string},
        {key: 'databases', type: varType.bool},
        {key: 'encryptedData', type: varType.bool},
        {key: 'virtualEncryption', type: varType.bool},
        {key: 'virtualSystems', type: varType.bool},
        {key: 'specificInformation', type: varType.string},
        {key: 'priority', type: varType.string},
        {key: 'analysisCosts.net', type: varType.string},
        {key: 'analysisCosts.gross', type: varType.string},
        {key: 'partnerName', type: varType.string},
        {key: 'analysisPriorityMode.id', type: varType.string},
        {key: 'analysisPriorityMode.name', type: varType.string},
        {key: 'shippingProviderToDr.id', type: varType.string},
        {key: 'shippingProviderToDr.name', type: varType.string},
      ],
      PAYABLE_INVOICE: [
        ...greeting,
        ...orderVars,
        ...invoiceVars
      ],
      CANCELLATION_INVOICE: [
        ...greeting,
        {key: 'employeeName', type: varType.string},
        ...departmentVars,
        {key: 'cancellationInvoiceNumber', type: varType.string},
        {key: 'canceledAt', type: varType.date},
        ...invoiceVars.map(e => ({...e, key: 'canceledInvoice.' + e.key})),
      ],
      CORRECTION_INVOICE: [
        ...greeting,
        {key: 'employeeName', type: varType.string},
        ...departmentVars,
        {key: 'correctionInvoiceNumber', type: varType.string},
        {key: 'correctionInvoiceDate', type: varType.date},
        {key: 'netTotal', type: varType.date},
        {key: 'grossTotal', type: varType.date},
        {key: 'payoutAt', type: varType.date},
        ...invoiceVars.map(e => ({...e, key: 'correctedInvoice.' + e.key})),

      ],
      COMMISSION_CREDIT: [
        ...greeting,
        {key: 'employeeName', type: varType.string},
        ...departmentVars,
        {key: 'commissionCreditNumber', type: varType.string},
        {key: 'commissionCreditDate', type: varType.date},
        {key: 'netTotal', type: varType.string},
        {key: 'grossTotal', type: varType.string},
        {key: 'payoutAt', type: varType.date},
      ],
      OFFER: [
        ...greeting,
        ...orderVars,
        {key: 'offerNumber', type: varType.string},
        {key: 'offerDate', type: varType.date},
        {key: 'netTotal', type: varType.string},
        {key: 'grossTotal', type: varType.string},
        {key: 'validUntil', type: varType.date},
        {key: 'confirmedAt', type: varType.date},
        {key: 'bindingOrderCreatedAt', type: varType.date},
        {key: 'orderCreatedAt', type: varType.date},
      ],
    };
  }
}
