import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PRODUCT_ORDER_ITEM = '[Orders Module] Create Product Order Item';
export const CREATE_PRODUCT_ORDER_ITEM_SUCCESS = '[Orders Module] Create Product Order Item Success';
export const CREATE_PRODUCT_ORDER_ITEM_FAIL = '[Orders Module] Create Product Order Item Fail';

export const CreateProductOrderItem = createAction(
  CREATE_PRODUCT_ORDER_ITEM,
  props<{ payload: fromModuleModels.ProductOrderItem }>()
);

export const CreateProductOrderItemSuccess = createAction(
  CREATE_PRODUCT_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const CreateProductOrderItemFail = createAction(
  CREATE_PRODUCT_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PRODUCT_ORDER_ITEM = '[Orders Module] Read Product Order Item';
export const READ_PRODUCT_ORDER_ITEM_SUCCESS = '[Orders Module] Read Product Order Item Success';
export const READ_PRODUCT_ORDER_ITEM_FAIL = '[Orders Module] Read Product Order Item Fail';

export const ReadProductOrderItem = createAction(
  READ_PRODUCT_ORDER_ITEM,
  props<{ iri: string }>()
);

export const ReadProductOrderItemSuccess = createAction(
  READ_PRODUCT_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadProductOrderItemFail = createAction(
  READ_PRODUCT_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_PRODUCT_ORDER_ITEMS = '[Orders Module] Read Product Order Items';
export const READ_PRODUCT_ORDER_ITEMS_SUCCESS = '[Orders Module] Read Product Order Items Success';
export const READ_PRODUCT_ORDER_ITEMS_FAIL = '[Orders Module] Read Product Order Items Fail';

export const ReadProductOrderItems = createAction(
  READ_PRODUCT_ORDER_ITEMS,
  props<{ page?: number, orderUuid: string }>()
);

export const ReadProductOrderItemsSuccess = createAction(
  READ_PRODUCT_ORDER_ITEMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadProductOrderItemsFail = createAction(
  READ_PRODUCT_ORDER_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PRODUCT_ORDER_ITEM = '[Orders Module] Update Product Order Item';
export const UPDATE_PRODUCT_ORDER_ITEM_SUCCESS = '[Orders Module] Update Product Order Item Success';
export const UPDATE_PRODUCT_ORDER_ITEM_FAIL = '[Orders Module] Update Product Order Item Fail';

export const UpdateProductOrderItem = createAction(
  UPDATE_PRODUCT_ORDER_ITEM,
  props<{ iri: string, payload: fromModuleModels.ProductOrderItem }>()
);

export const UpdateProductOrderItemSuccess = createAction(
  UPDATE_PRODUCT_ORDER_ITEM_SUCCESS,
  props<{ response: fromModuleModels.ProductOrderItem }>()
);

export const UpdateProductOrderItemFail = createAction(
  UPDATE_PRODUCT_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_PRODUCT_ORDER_ITEM = '[Orders Module] Delete Product Order Item';
export const DELETE_PRODUCT_ORDER_ITEM_SUCCESS = '[Orders Module] Delete Product Order Item Success';
export const DELETE_PRODUCT_ORDER_ITEM_FAIL = '[Orders Module] Delete Product Order Item Fail';

export const DeleteProductOrderItem = createAction(
  DELETE_PRODUCT_ORDER_ITEM,
  props<{ iri: string }>()
);

export const DeleteProductOrderItemSuccess = createAction(
  DELETE_PRODUCT_ORDER_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteProductOrderItemFail = createAction(
  DELETE_PRODUCT_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
