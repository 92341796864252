import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatestObject } from 'rxjs-etc';
import { select, Store } from '@ngrx/store';

import * as CustomersModuleActions from '../../../customers/store';
import { CustomersActions } from '../../../customers/store';
import * as CustomersModuleSelectors from '../../../customers/store/selectors';
import { CustomersSelectors } from '../../../customers/store/selectors';
import * as InvoicesModuleActions from '../../store';
import { CommissionCreditsActions } from '../../store';
import * as InvoicesModuleSelectors from '../../store/selectors';
import { CommissionCreditsSelectors } from '../../store/selectors';
import * as MasterDataModuleActions from '../../../master-data/store/actions';
import { TaxesActions } from '../../../master-data/store/actions';
import * as MasterDataModuleSelectors from '../../../master-data/store/selectors';
import {
  DepartmentsSelectors,
  TaxesSelectors
} from '../../../master-data/store/selectors';
import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromInvoicesModuleModels from '../../models';
import { CommissionCredit } from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import { Department } from '../../../master-data/models';
import { ApplicationState } from '../../../application-state/store';
import { CommissionCreditsService } from '../../services';
import {
  ErrorsObject,
  ErrorsUtility
} from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { ModalDialogOptions } from '../../../application-state/models';
import { OrdersActions } from '../../../orders/store';
import {
  PaymentRecorderComponent,
  SendDocumentToolComponent
} from '../../components';
import { StringsUtility } from '../../../shared/utilities/strings.utility';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { ActivatedRoute } from '@angular/router';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { DepartmentLogosService } from '../../../master-data/services/department-logos.service';

@Component({
  selector: 'app-commission-credit-view',
  styleUrls: ['commission-credit-view.component.scss'],
  template: `
    <view-heading
      [heading]="
        'Faktura: Provisionsrechnung ' + (credit ? 'bearbeiten' : 'erstellen')
      "
      colRight="text-right"
    >
      <span>
        <button
          mat-icon-button
          class="text-color-grey"
          (click)="openMailDialog()"
        >
          <mat-icon>mail</mat-icon>
        </button>
      </span>
    </view-heading>

    <div class="invoice__outer pos-relative" [formGroup]="form">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

      <div class="wrap invoice__wrap p-b--32 m-b--32">
        <div class="invoice grid">
          <div class="column-9">
            <app-commission-credit-preview
              [credit$]="credit$"
              (updateInvoice)="handleUpdateCommissionCredit($event)"
            ></app-commission-credit-preview>
            <!--              <div class="template__outer m-t&#45;&#45;32 m-b&#45;&#45;32" [attr.data-invoice-state]="data.credit?.state"-->
            <!--                   [class.draft]="!data.credit">-->

            <!--                <div class="template__wrap">-->
            <!--                  <div class="template grid grid-no-gutter">-->

            <!--                    <div class="column-10">-->

            <!--                      <div class="countdown">-->
            <!--                        <ng-container *ngIf="data.credit?.editable && !!data.credit?.editableUntil">-->
            <!--                          Rechnung kann noch {{ getHourDifferential(data.credit.editableUntil) }}h bearbeitet werden.-->
            <!--                        </ng-container>-->
            <!--                      </div>-->

            <!--                      <app-invoice-action-box-->
            <!--                        [invoice]="data.credit"-->
            <!--                        [actions]="['copy','delete']"-->
            <!--                        (requestCopyInvoice)="handleCopyCommissionCredit()"-->
            <!--                        (requestDeleteInvoice)="handleDeleteCommissionCredit($event)"-->
            <!--                      ></app-invoice-action-box>-->

            <!--                      <app-invoice-head-recipient [invoice$]="credit$"></app-invoice-head-recipient>-->
            <!--                    </div>-->

            <!--                    <div class="column-4">-->

            <!--                      <app-invoice-head-issuer [invoice$]="credit$"-->
            <!--                                               (updateIssuer)="handleUpdateIssuer($event)"-->
            <!--                      ></app-invoice-head-issuer>-->
            <!--                    </div>-->

            <!--                    <div class="column-14 m-t&#45;&#45;32 m-b&#45;&#45;8 grid grid-no-gutter">-->
            <!--                      <div class="column-7">-->
            <!--                        <span-->
            <!--                          class="heading&#45;&#45;h2">Provisionsrechnung / Credit {{ data.credit?.commissionCreditNumber }}</span>-->
            <!--                      </div>-->

            <!--                      <div class="column-7 grid-right">-->
            <!--                        <span-->
            <!--                          class="heading&#45;&#45;h2">Datum/Date: {{ (data.credit?.createdAt ? data.credit.createdAt : today) | date: 'dd.MM.Y' }}</span>-->
            <!--                      </div>-->
            <!--                    </div>-->

            <!--                    <div class="column-14 m-b&#45;&#45;64">-->
            <!--                      <p class="m-b&#45;&#45;64">Sie erhalten heute diese Provisionsrechung:</p>-->

            <!--                      <app-invoice-items-summary-->
            <!--                        [invoice$]="credit$"-->
            <!--                        (requestShowInvoiceItemForm)="handleShowCommissionCreditItemForm(iifm, $event)"-->
            <!--                        (requestUpdateInvoice)="handleUpdateCommissionCredit(data.credit['@id'], $event)"-->
            <!--                      ></app-invoice-items-summary>-->
            <!--                    </div>-->

            <!--                    <div class="column-8 p-r&#45;&#45;12">-->

            <!--                      <button-->
            <!--                        *ngIf="data.credit"-->
            <!--                        [disabled]="data?.credit?.state === 'booked'"-->
            <!--                        (click)="handleShowCommissionCreditItemForm(iifm)"-->
            <!--                        class="m-b&#45;&#45;32" mat-flat-button color="green">-->
            <!--                        <mat-icon class="m-r&#45;&#45;16" style="vertical-align: middle">add</mat-icon>-->
            <!--                        <span class="heading&#45;&#45;h3 m-b&#45;&#45;0">Position zur Provisionsrechnung hinzufügen</span>-->
            <!--                      </button>-->
            <!--                    </div>-->

            <!--                    <div class="column-6">-->
            <!--                      <invoice-total [invoice]="data.credit"></invoice-total>-->
            <!--                    </div>-->

            <!--                    <div class="column-14 m-t&#45;&#45;32" style="position: relative"-->
            <!--                         [matTooltipDisabled]="!!!data?.credit?.bookedAt"-->
            <!--                         matTooltip="Gebuchte Rechnungen können nicht bearbeitet werden.">-->

            <!--                      <mat-form-field class="white" appearance="outline"-->
            <!--                                      [ngStyle]="{'pointer-events': !!data?.credit?.bookedAt ? 'none' : 'initial'}">-->

            <!--                        <mat-label>Individueller Text</mat-label>-->
            <!--                        <textarea-->
            <!--                          [readonly]="!!data?.credit?.bookedAt"-->
            <!--                          matTextareaAutosize-->
            <!--                          matInput rows="10"-->
            <!--                          formControlName="customText"-->
            <!--                        ></textarea>-->
            <!--                      </mat-form-field>-->

            <!--                      <button-->
            <!--                        class="btn&#45;&#45;update-text"-->
            <!--                        *ngIf="data.credit && this.form.get('customText').dirty && !!!data?.credit?.bookedAt"-->
            <!--                        (click)="handleUpdateCommissionCredit(data.credit['@id'])"-->
            <!--                        mat-button color="green">Änderungen speichern-->
            <!--                      </button>-->
            <!--                    </div>-->

            <!--                    <div class="column-14">-->

            <!--                      <app-invoice-footer [invoice$]="credit$"></app-invoice-footer>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
          </div>

          <div class="column-5 ">
            <div class="">
              <commission-credit-facturer
                [credit]="credit"
                [parentFormGroup]="form"
                [taxRates$]="taxes$"
                [errors]="selectErrors('commissionCreditsErrors')"
                [broker$]="broker$"
                [paginationNextLink$]="paginationNextLink$"
                [searchResults$]="searchResults$"
                (requestCreateCommissionCredit)="handleCreateCommissionCredit()"
                (requestUpdateCommissionCredit)="handleUpdateCommissionCredit()"
                (requestBookCommissionCredit)="
                  handleBookCommissionCredit($event)
                "
                (requestReadBrokers)="handleReadCustomers($event)"
                (requestResetSearchResults)="handleResetSearchResults()"
              ></commission-credit-facturer>

              <payout-tool
                *ngIf="credit"
                [credit]="credit"
                (requestBookPayOut)="handleBookPayOut(credit['@id'], $event)"
              ></payout-tool>

              <app-send-document-tool
                *ngIf="credit"
                [invoice]="credit"
                invoiceTypeName="Gutschrift"
                [errors]="selectErrors('documentDeliveriesErrors')"
              ></app-send-document-tool>

              <div class="blocker"></div>
            </div>

            <div class="blocker"></div>
          </div>
        </div>
      </div>
    </div>

    <!--      <ng-template #dsfm>-->

    <!--        <div class="dialog__header grid grid-no-gutter">-->
    <!--          <div class="column-12">-->
    <!--            <h2>Abteilung wählen</h2>-->
    <!--          </div>-->
    <!--          <div class="column-2 m-ta&#45;&#45;2">-->
    <!--            <button mat-icon-button mat-dialog-close>-->
    <!--              <mat-icon>close</mat-icon>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="issuer-list" mat-dialog-content style="min-width: 450px;" [formGroup]="dsf">-->

    <!--          <ng-select-->
    <!--            [items]="data.departments"-->
    <!--            placeholder="Abteilung wählen"-->
    <!--            bindLabel="name"-->
    <!--            bindValue="@id"-->
    <!--            #departmentSelector-->
    <!--            [clearable]="false"-->
    <!--            [markFirst]="false"-->
    <!--            formControlName="issuer"-->
    <!--            appendTo="body"-->
    <!--          ></ng-select>-->

    <!--          <div class="m-ta&#45;&#45;2">-->

    <!--            <button [disabled]="!departmentSelector.hasValue"-->
    <!--                    mat-flat-button color="green"-->
    <!--                    (click)="handleUpdateIssuer(departmentSelector.selectedValues)">-->

    <!--              <mat-icon>save</mat-icon>-->
    <!--              <span>Speichern</span>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </ng-template>-->

    <!--      <ng-template #iifm>-->

    <!--        <div class="dialog__header grid grid-no-gutter">-->
    <!--          <div class="column-12">-->
    <!--            <h2>{{ getInvoiceItemModalHeading() }}</h2>-->
    <!--          </div>-->
    <!--          <div class="column-2 m-ta&#45;&#45;2">-->
    <!--            <button mat-icon-button mat-dialog-close>-->
    <!--              <mat-icon>close</mat-icon>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div mat-dialog-content>-->

    <!--          <div *ngIf="!!!invoiceItemType$.getValue()" style="min-width: 450px">-->

    <!--            <ng-select-->
    <!--              class="m-b&#45;&#45;16"-->
    <!--              #itemTypeSelector-->
    <!--              [items]="invoiceTypeOptions"-->
    <!--              bindLabel="label"-->
    <!--              bindValue="value"-->
    <!--              appendTo="body"-->
    <!--              placeholder="Art der Position auswählen"-->
    <!--            ></ng-select>-->

    <!--            <div class="m-ta&#45;&#45;2">-->
    <!--              <button mat-flat-button color="green"-->
    <!--                      (click)="invoiceItemType$.next(itemTypeSelector.selectedValues[0]['value'])">-->
    <!--                <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--                <span>Weiter</span>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <app-invoice-item-form-->
    <!--            *ngIf="!!invoiceItemType$.getValue()"-->
    <!--            [currencies$]="currencies$"-->
    <!--            [invoice]="data.credit"-->
    <!--            [itemType$]="invoiceItemType$"-->
    <!--            [presets$]="presets$"-->
    <!--            [products$]="products$"-->
    <!--            [services$]="services$"-->
    <!--            [taxRates$]="taxes$"-->
    <!--            [errors]="selectErrors('commissionCreditItemsErrors')"-->
    <!--            [units$]="productUnits$"-->
    <!--            (requestReadServices)="handleReadServices()"-->
    <!--            (requestReadProducts)="handleReadProducts()"-->
    <!--            (requestCreateInvoiceItem)="handleCreateCommissionCreditItem($event, data.credit['@id'])"-->
    <!--            (requestUpdateInvoiceItem)="handleUpdateCommissionCreditItem($event, data.credit['@id'])"-->
    <!--          ></app-invoice-item-form>-->
    <!--        </div>-->
    <!--      </ng-template>-->
    <!--    </ng-container>-->

    <!--    <ng-template #casm>-->

    <!--      <app-dialog-header>-->
    <!--        <h2>Kunden festlegen</h2>-->
    <!--      </app-dialog-header>-->

    <!--      <div class="issuer-list" mat-dialog-content style="min-width: 550px;">-->

    <!--        <p class="heading&#45;&#45;h3">Rechungsadressat</p>-->

    <!--        <customer-search-->
    <!--          (requestReadCustomers)="handleReadCustomers($event)"-->
    <!--          (requestResetSearchResults)="handleResetSearchResults()"-->
    <!--          (requestSetCustomer)="handleSelectCustomer($event)"-->
    <!--          [editableSearchTerm]="true"-->
    <!--          [formGroup]="cf"-->
    <!--          [clearable]="true"-->
    <!--          appendTo="body"-->
    <!--          [paginationNextLink$]="paginationNextLink$"-->
    <!--          [searchResults$]="searchResults$"-->
    <!--          notFoundText="Keine Ergebnisse"-->
    <!--          placeholder="Firma oder Kunde"-->
    <!--        ></customer-search>-->

    <!--        <p class="heading&#45;&#45;h3">Rechnungsanschrift</p>-->

    <!--        <ng-container [formGroup]="caf">-->
    <!--          <ng-select-->
    <!--            [items]="customerAddresses$ | async"-->
    <!--            notFoundText="Keine Ergebnisse"-->
    <!--            [searchable]="false"-->
    <!--            [clearable]="false"-->
    <!--            [readonly]="!!!customer$.getValue()"-->
    <!--            (change)="handleUpdateInvoiceRecipient(addressSelector.selectedValues)"-->
    <!--            formControlName="address"-->
    <!--            bindValue="@id"-->
    <!--            placeholder="{{ !!customer$.getValue() ? 'Adressen für diesen Kunden:' : 'Zunächst Kunden wählen' }}"-->
    <!--            appendTo="body"-->
    <!--            #addressSelector>-->

    <!--            <ng-template ng-label-tmp let-item="item">-->
    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->

    <!--            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">-->

    <!--              <div class="ng-option">-->
    <!--                <span>{{ formatAddress(item.address) }}</span>-->
    <!--              </div>-->
    <!--            </ng-template>-->
    <!--          </ng-select>-->
    <!--        </ng-container>-->

    <!--        <div class="m-ta&#45;&#45;2 m-t&#45;&#45;16">-->
    <!--          <button mat-flat-button color="green" (click)="dialog.closeAll()">-->

    <!--            <mat-icon class="m-r&#45;&#45;8">save</mat-icon>-->
    <!--            <span>Speichern und schließen</span>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--<customer-address-search
          (requestReadCustomerAddresses)="handleReadCustomerAddresses($event)"
          (requestResetSearchResults)="handleResetCustomerAddressSearchResults()"
          (requestSetCustomer)="handleSelectCustomer($event)"
          [clearable]="disabled"
          [editableSearchTerm]="true"
          [paginationNextLink$]="customerAddressPaginationNextLink$"
          [searchResults$]="customerAddressesSearchResults$"
          notFoundText="Keine Ergebnisse"
          placeholder="Kunden-Adresse"
        ></customer-address-search>-->
    <!--      </ng-template>-->
  `
})
export class CommissionCreditViewComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentRecorderComponent) prc: PaymentRecorderComponent;
  @ViewChild(SendDocumentToolComponent) sitc: SendDocumentToolComponent;
  isLoading$: Observable<boolean>;

  caf: FormGroup;
  form: FormGroup;
  cf: FormGroup;
  credit$: Observable<CommissionCredit>;
  creditUUID: string = null;
  creditIRI: string = null;
  credit: CommissionCredit;
  localCredit: CommissionCredit;
  currencies$: Observable<Array<fromMasterDataModuleModels.Currency>>;
  customer$: BehaviorSubject<
    fromCustomersModuleModels.Customer
  > = new BehaviorSubject(null);
  broker$: Observable<fromCustomersModuleModels.Customer>;
  customerAddress$: BehaviorSubject<
    fromCustomersModuleModels.CustomerAddress | any
  > = new BehaviorSubject(null);
  customerAddressPaginationNextLink$: BehaviorSubject<
    string
  > = new BehaviorSubject(null);
  customerAddresses$: Observable<
    Array<fromCustomersModuleModels.CustomerAddress>
  >;
  customerContacts$: Observable<
    Array<fromCustomersModuleModels.CustomerContact>
  >;
  departmentAdUri: SafeUrl | any = null;
  departmentLogoUri: SafeUrl | any = null;
  departments$: Observable<Array<fromMasterDataModuleModels.Department>>;
  documentDeliveryProviders$: Observable<
    Array<fromInvoicesModuleModels.DocumentDeliveryProvider>
  >;
  dsf: FormGroup;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject(
    {}
  );
  invoiceItemType$: BehaviorSubject<
    'custom' | 'product' | 'service'
  > = new BehaviorSubject(null);
  invoiceTypeOptions = [
    { label: 'Freie Position', value: 'custom' },
    {
      label: 'Produkt',
      value: 'product'
    },
    { label: 'Service', value: 'service' }
  ];
  issuer$: BehaviorSubject<
    fromMasterDataModuleModels.Department
  > = new BehaviorSubject(null);
  onDestroy$: Subject<any> = new Subject<any>();
  paginationNextLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  presets$: BehaviorSubject<any> = new BehaviorSubject(null);
  productUnits$: Observable<Array<fromMasterDataModuleModels.ProductUnit>>;
  productUnitsEntities$: Observable<{
    [iri: string]: fromMasterDataModuleModels.ProductUnit;
  }>;
  products$: Observable<Array<fromMasterDataModuleModels.Product>>;
  searchResults$: Observable<Array<fromCustomersModuleModels.Customer>>;
  services$: Observable<Array<fromMasterDataModuleModels.Service>>;
  taxes$: Observable<Array<fromMasterDataModuleModels.Tax>>;

  constructor(
    public dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fs: FormsService,
    @Inject(LOCALE_ID) private locale: string,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,
    private cis: CommissionCreditsService,
    private dls: DepartmentLogosService
  ) {}

  get today(): Date {
    return new Date();
  }

  getHourDifferential(futureDate: string): number {
    const duration = moment.duration(
      moment(futureDate).diff(moment(new Date()))
    );
    return Math.floor(duration.asHours());
  }

  ngOnInit(): void {
    this.initForms();
    this.activatedRoute.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.creditUUID = params.uuid;
        this.creditIRI = '/api/commission_credits/' + params.uuid;
        this.credit$ = this.store$.select(
          CommissionCreditsSelectors.selectCommissionCreditByIndex,
          { iri: this.creditIRI }
        );
        this.credit$
          .pipe(
            takeUntil(this.onDestroy$),
            filter(i => !!i)
          )
          .subscribe(credit => {
            this.upsertCommissionCreditData(credit);
            if (credit.customerNumber) {
              this.store$.dispatch(
                CustomersActions.ReadCustomers({
                  page: -1,
                  params: { id: credit.customerNumber }
                })
              );
              this.store$
                .select(CustomersSelectors.sByCustomerNumber, {
                  customerNumber: credit.customerNumber
                })
                .pipe(
                  filter(e => !!e),
                  take(1)
                )
                .subscribe(c => {
                  this.customer$.next(c);
                });
            }
          });
        this.store$.dispatch(
          CommissionCreditsActions.ReadCommissionCredit({ iri: this.creditIRI })
        );
      });
    loadIfNotLoaded(
      this.store$,
      TaxesSelectors.isLoaded,
      TaxesActions.ReadTaxes()
    );
    this.selectData();
    this.mergeErrors();
    this.initSubscriptions();
    this.initActionListeners();
  }

  initForms(): void {
    this.form = this.fb.group({
      broker: this.fb.control(null, Validators.required),
      taxRate: this.fb.control(null, Validators.required),
      issuer: this.fb.control(null),
      recipient: this.fb.group({
        nameLine1: this.fb.control(null, [Validators.required]),
        nameLine2: this.fb.control(null),
        address: this.fb.group({
          line1: this.fb.control('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(35)
          ]),
          line2: this.fb.control('', Validators.maxLength(35)),
          line3: this.fb.control(''),
          line4: this.fb.control(''),
          city: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50)
          ]),
          zipPostcode: this.fb.control(null, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(10)
          ]),
          stateProvinceCounty: this.fb.control(null),
          country: this.fb.control(null, [Validators.required])
        }),
        addressType: this.fb.control(null, Validators.required),
        taxNumber: this.fb.control(null, Validators.required)
      }),
      customerNumber: this.fb.control(null, Validators.required),
      customText: this.fb.control('')
    });

    this.cf = this.fb.group({
      customer: this.fb.control(null)
    });

    this.caf = this.fb.group({
      address: this.fb.control(null)
    });

    this.dsf = this.fb.group({
      issuer: this.fb.control(null)
    });
  }

  selectData(): void {
    // Observables
    this.credit$ = this.store$.pipe(
      select(
        InvoicesModuleSelectors.CommissionCreditsSelectors
          .selectCurrentCommissionCredit
      )
    );
    this.currencies$ = this.store$.pipe(
      select(MasterDataModuleSelectors.CurrenciesSelectors.selectCurrencies)
    );
    this.departments$ = this.store$.pipe(
      select(DepartmentsSelectors.selectDepartments)
    );
    this.documentDeliveryProviders$ = this.store$.pipe(
      select(
        InvoicesModuleSelectors.DocumentDeliveryProvidersSelectors
          .selectDocumentDeliveryProviders
      )
    );
    this.productUnits$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ProductUnitsSelectors.selectProductUnits)
    );
    this.productUnitsEntities$ = this.store$.pipe(
      select(
        MasterDataModuleSelectors.ProductUnitsSelectors
          .selectProductUnitsEntities
      )
    );
    this.products$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ProductsSelectors.sList)
    );
    this.searchResults$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectSearchResults)
    );
    this.services$ = this.store$.pipe(
      select(MasterDataModuleSelectors.ServicesSelectors.selectServices)
    );
    this.taxes$ = this.store$.pipe(
      select(MasterDataModuleSelectors.TaxesSelectors.selectTaxes)
    );
    this.broker$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomersSelectors.selectCurrentPartner)
    );

    // Behavior Subjects
    // this.store$.pipe(select(CustomersModuleSelectors.CustomerAddressesSelectors.selectCurrentCustomerAddress)).subscribe(this.customerAddress$);
    // this.store$.pipe(select(CustomersModuleSelectors.CustomerAddressesSelectors.selectPaginationNextLink)).subscribe(this.customerAddressPaginationNextLink$);
    // this.store$.pipe(select(CustomersModuleSelectors.CustomersSelectors.selectCurrentCustomer)).subscribe(this.customer$);
    // this.store$.pipe(select(CustomersModuleSelectors.CustomersSelectors.selectPaginationNextLink)).subscribe(this.paginationNextLink$);
    // this.store$.pipe(select(DepartmentsSelectors.selectDefaultDepartment)).subscribe(this.issuer$);

    // this.store$.pipe(select(OrdersSelectors.selectCurrentOrder)).subscribe(this.order$);
    // this.orders$ = this.store$.pipe(select(OrdersSelectors.selectOrders));
  }

  initActionListeners(): void {
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          InvoicesModuleActions.CommissionCreditsActions
            .BookCommissionCreditSuccess,
          InvoicesModuleActions.CommissionCreditsActions.BookPayOutSuccess,
          InvoicesModuleActions.CommissionCreditItemsActions
            .CreateCommissionCreditItemSuccess,
          InvoicesModuleActions.CommissionCreditItemsActions
            .UpdateCommissionCreditItemSuccess,
          InvoicesModuleActions.CommissionCreditItemsActions
            .DeleteCommissionCreditItemSuccess,
          InvoicesModuleActions.CommissionCreditsActions
            .CreateCommissionCreditSuccess,
          InvoicesModuleActions.CommissionCreditsActions
            .UpdateCommissionCreditSuccess,
          InvoicesModuleActions.InvoicePaymentsActions
            .CreateInvoicePaymentSuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateEmailDocumentDeliverySuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateRegisteredLetterDocumentDeliverySuccess,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CreateLetterXPressDocumentDeliverySuccess
        )
      ) // @ts-ignore
      .subscribe(({ type, response, invoiceIri = null }) => {
        const triggerRefresh = [
          InvoicesModuleActions.CommissionCreditsActions
            .BOOK_COMMISSION_CREDIT_SUCCESS,
          InvoicesModuleActions.CommissionCreditsActions.BOOK_PAY_OUT_SUCCESS,
          InvoicesModuleActions.CommissionCreditItemsActions
            .CREATE_COMMISSION_CREDIT_ITEM_SUCCESS,
          InvoicesModuleActions.CommissionCreditItemsActions
            .UPDATE_COMMISSION_CREDIT_ITEM_SUCCESS,
          InvoicesModuleActions.CommissionCreditItemsActions
            .DELETE_COMMISSION_CREDIT_ITEM_SUCCESS,
          InvoicesModuleActions.InvoicePaymentsActions
            .CREATE_INVOICE_PAYMENT_SUCCESS,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS,
          InvoicesModuleActions.DocumentDeliveriesActions
            .CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS
        ];

        const resetPresets = [
          InvoicesModuleActions.CommissionCreditItemsActions
            .CREATE_COMMISSION_CREDIT_ITEM_SUCCESS,
          InvoicesModuleActions.CommissionCreditItemsActions
            .UPDATE_COMMISSION_CREDIT_ITEM_SUCCESS,
          InvoicesModuleActions.CommissionCreditItemsActions
            .DELETE_COMMISSION_CREDIT_ITEM_SUCCESS
        ];

        if (
          type ===
            InvoicesModuleActions.CommissionCreditsActions
              .UPDATE_COMMISSION_CREDIT_SUCCESS ||
          type ===
            InvoicesModuleActions.CommissionCreditsActions
              .CREATE_COMMISSION_CREDIT_SUCCESS
        ) {
          this.form.markAsPristine();
        }

        if (
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS ||
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS ||
          type ===
            InvoicesModuleActions.DocumentDeliveriesActions
              .CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS
        ) {
          this.sitc.initForm();
          this.sitc.resetTypeSelect();
          this.sitc.documentDeliveryTarget$.next(null);
        }

        if (triggerRefresh.includes(type) && invoiceIri) {
          this.store$.dispatch(
            InvoicesModuleActions.CommissionCreditsActions.ReadCommissionCredit(
              { iri: invoiceIri }
            )
          );
        }

        if (resetPresets.includes(type)) {
          this.presets$.next(null);
        }
      });
  }

  initSubscriptions(): void {
    this.customer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(customer => !!customer)
      )
      .subscribe(customer => {
        this.handleUpdateFormsAfterCustomerChange(customer);

        // Note: if customer is restored we don't know @id
        if (customer.hasOwnProperty('@id')) {
          this.handleReadCustomerAddresses(customer['@id']);
        }

        this.handleReadCustomerContacts(customer['@id']);
      });

    /*this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe((order) => {

      this.handleReadCustomer(order.customer);
    });*/

    /*this.customerAddress$.pipe(
      takeUntil(this.onDestroy$),
      filter(address => !!address)
    ).subscribe((address) => {
      this.handleUpdateInvoiceRecipient([address]);
    });*/

    this.issuer$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(issuer => !!issuer)
      )
      .subscribe(issuer => {
        this.form.get('issuer').setValue(issuer['@id']);
        this.dsf.get('issuer').setValue(issuer['@id']);
      });

    this.credit$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(credit => !!credit)
      )
      .subscribe(credit => {
        this.upsertCommissionCreditData(credit);
        this.handleReadBroker(credit);
      });

    this.dialog.afterAllClosed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.invoiceItemType$.next(null);
      });
  }

  mergeErrors(): void {
    combineLatestObject({
      commissionCreditsErrors: this.store$.pipe(
        select(InvoicesModuleSelectors.CommissionCreditsSelectors.selectErrors)
      ),
      commissionCreditItemsErrors: this.store$.pipe(
        select(
          InvoicesModuleSelectors.CommissionCreditItemsSelectors.selectErrors
        )
      ),
      documentDeliveriesErrors: this.store$.pipe(
        select(InvoicesModuleSelectors.DocumentDeliveriesSelectors.selectErrors)
      )
    }).subscribe(this.errors$);
  }

  selectErrors(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  upsertCommissionCreditData(
    credit: fromInvoicesModuleModels.CommissionCredit
  ): void {
    this.credit = credit;
    if (!credit) {
      return;
    }

    const existingIssuer = this.issuer$.getValue();

    // Prevent re-fetching issuer every time invoice is updated
    if (
      !!existingIssuer &&
      extractIri(existingIssuer) !== extractIri(credit.issuer)
    ) {
      this.issuer$.next(credit.issuer);
    }

    this.customer$.next({
      nameLine1: credit.recipient.nameLine1,
      nameLine2: credit.recipient.nameLine2,
      customerNumber: credit.customerNumber
    });
    this.customerAddress$.next({
      address: credit.recipient.address,
      addressType: credit.recipient.addressType
    });

    this.form.get('recipient.address').patchValue(credit.recipient.address);
    this.form
      .get('recipient.addressType')
      .patchValue(credit.recipient.addressType['@id']);

    this.form.get('customText').setValue(credit.customText);

    this.form.get('broker').setValue(credit.broker);
    this.form.get('taxRate').setValue(credit.taxRate['@id']);

    // Might not exist in server response if not set beforehand in customer
    if (credit?.recipient?.taxNumber) {
      this.form.get('recipient.taxNumber').setValue(credit.recipient.taxNumber);
    }
  }

  handleUpdateFormsAfterCustomerChange(
    customer: fromCustomersModuleModels.Customer
  ): void {
    this.form.get('customerNumber').setValue(customer.customerNumber);
    this.form.get('recipient.nameLine1').setValue(customer.nameLine1);
    this.form.get('recipient.nameLine2').setValue(customer.nameLine2);

    if (customer?.taxNumber) {
      this.form.get('recipient.taxNumber').setValue(customer.taxNumber);
    }

    this.cf.get('customer').setValue(customer['@id']);

    // todo: auto-set default address if given?
    /*if(!!customer?.defaultBillingAddress) {
      // Check for and fetch default customer address if set
      this.caf.get('address').setValue(customer.defaultBillingAddress);
    }*/
  }

  handleReadBroker(credit: fromInvoicesModuleModels.CommissionCredit): void {
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ReadPartner({
        iri: credit.broker
      })
    );
  }

  handleSelectCustomer(customer: fromCustomersModuleModels.Customer): void {
    if (customer) {
      this.handleReadCustomer(customer['@id']);
    } else {
      this.handleResetCustomer();
    }
  }

  handleUpdateIssuer(selectedIssuer: Department | any): void {
    this.form.patchValue({ issuer: selectedIssuer });
    this.credit$ = this.credit$.pipe(
      switchMap(e => {
        console.log(e);
        return of({ ...e, issuer: selectedIssuer });
      })
    );
    this.issuer$.next(selectedIssuer);
  }

  handleUpdateInvoiceRecipient(
    selectedAddresses: Array<fromCustomersModuleModels.CustomerAddress> | any
  ): void {
    const customerAddress = selectedAddresses[0];
    const { address, addressType } = customerAddress;

    this.form.get('recipient.address').patchValue(address);
    this.form.get('recipient.addressType').patchValue(addressType['@id']);

    this.customerAddress$.next(customerAddress);
  }

  formatAddress(address: fromCustomersModuleModels.Address): string {
    return address
      ? StringsUtility.formatAddress(address)
      : 'Keine Standardadresse gesetzt';
  }

  handleCreateEmailDocumentDelivery(
    payload: fromInvoicesModuleModels.DocumentDelivery,
    credit: string
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.DocumentDeliveriesActions.CreateEmailDocumentDelivery(
        { payload, invoiceIri: credit }
      )
    );
  }

  handleCreateRegisteredLetterDocumentDelivery(
    payload: fromInvoicesModuleModels.DocumentDelivery,
    credit: string
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery(
        {
          payload,
          invoiceIri: credit
        }
      )
    );
  }

  handleCreateLetterXPressDocumentDelivery(
    payload: fromInvoicesModuleModels.DocumentDelivery,
    credit: string
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery(
        { payload, invoiceIri: credit }
      )
    );
  }

  handleDownloadCommissionCredit(credit: string): void {
    this.cis
      .readCommissionCreditAsPdf(credit)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(response => !!response),
        catchError(error => throwError(error))
        // tap(v => console.log(v))
      )
      .subscribe(
        ({ body: blob }) => {
          window.open(window.URL.createObjectURL(blob));
        },
        error => {
          console.log(error);
        }
      );
  }

  handleFetchDepartmentAd(iri: string): void {
    this.departmentAdUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          ({ body: blob }) => {
            this.departmentAdUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleFetchDepartmentLogo(iri: string | null): void {
    this.departmentLogoUri = null;

    if (iri) {
      this.dls
        .getDepartmentLogoAsImage(iri)
        .pipe(
          // tap(response => console.log(response)),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          ({ body: blob }) => {
            this.departmentLogoUri = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(blob)
            );
          },
          response => {
            console.log(response);
          }
        );
    }
  }

  handleReadCustomer(iri: string): void {
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ReadCustomer({ iri })
    );
  }

  handleReadCustomerAddresses(customerIri: string): void {
    const params = {
      'customer.uuid': StringsUtility.getUuidFromIri(customerIri)
    };
    this.store$.dispatch(
      CustomersModuleActions.CustomerAddressesActions.ReadCustomerBillingAddresses(
        { customerIri }
      )
    );
    this.customerAddresses$ = this.store$.pipe(
      select(
        CustomersModuleSelectors.CustomerAddressesSelectors
          .selectCustomerBillingAddressesByCustomerIri,
        { customerIri }
      )
    );
  }

  handleReadCustomerContacts(customerIri: string): void {
    // this.store$.dispatch(CustomerContactsActions.ReadCustomerContacts({ page: 1, params }));
    // this.customerContacts$ = this.store$.pipe(select(CustomerContactsSelectors.selectCustomerContactsByCustomerIri, { customerIri }));
  }

  handleBookCommissionCredit(credit: string): void {
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.BookCommissionCredit({
        iri: credit
      })
    );
  }

  handleBookPayOut(
    commissionCredit: string,
    payload: { payoutAt: string }
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.BookPayOut({
        iri: commissionCredit,
        payload
      })
    );
  }

  handleShowDepartmentSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  handleShowCustomerAddressSelectionModal(ref: TemplateRef<any>): void {
    this.openDialog(ref);
  }

  handleResetCustomer(): void {
    this.customer$.next(null);
    this.customerAddress$.next(null);
    // todo customerContact
  }

  handleResetFormValuesAfterCustomerReset(): void {
    this.form.get('customerNumber').setValue(null);
    // this.ccf.get('paymentProcess').setValue(null);

    this.form.get('recipient.nameLine1').setValue('');
    this.form.get('recipient.nameLine2').setValue('');

    this.form.get('recipient.address.line1').setValue('');
    this.form.get('recipient.address.line2').setValue('');
    this.form.get('recipient.address.line3').setValue('');
    this.form.get('recipient.address.line4').setValue('');
    this.form.get('recipient.address.city').setValue(null);
    this.form.get('recipient.address.zipPostcode').setValue(null);
    this.form.get('recipient.address.country').setValue(null);
    this.form.get('recipient.taxNumber').setValue(null);

    this.cf.get('customer').setValue(null);
    this.caf.get('address').setValue(null);

    /*this.ccf.get('orderDate').setValue(null);
    this.ccf.get('deliveryDate').setValue(null);
    this.ccf.get('dueDate').setValue(null);*/
  }

  handleResetSearchResults(): void {
    // also reset current searchResults and loaded state of customers;
    // so customers are fetched anew when navigating from this component to customers-view component
    this.searchResults$.pipe(switchMap(items => of([])));
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ResetIsLoaded()
    );
  }

  handleReadCustomers(payload: {
    page: number;
    params: { [p: string]: number | boolean | string };
  }): void {
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ReadCustomers(payload)
    );
  }

  handleCopyCommissionCredit(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text:
            'Möchten Sie eine Kopie dieser Provisionsrechnung erstellen?<br>Hinweis: ' +
            'Rechnungspositionen werden nicht mit kopiert<br>und müssen erneut angelegt werden.',
          heading: 'Rechnung kopieren?',
          confirmationText: 'Ja, kopieren',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          InvoicesModuleActions.CommissionCreditsActions.CreateCommissionCredit(
            { payload: this.form.value }
          )
        );
      });
  }

  handleCreateCommissionCredit(): void {
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.CreateCommissionCredit({
        payload: this.form.value
      })
    );
  }

  handleUpdateCommissionCredit(
    _payload?: fromInvoicesModuleModels.InvoiceLike
  ): void {
    if (!extractIri(this.credit, null)) {
      return;
    }
    const payload = {
      iri: extractIri(this.credit),
      payload: { ...this.credit, ...(_payload ? _payload : this.form.value) }
    };
    if (payload.payload.issuer) {
      payload.payload.issuer = extractIri(payload.payload.issuer);
    }
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.UpdateCommissionCredit(
        payload
      )
    );
  }

  handleDeleteCommissionCredit(credit: string): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Provisionsrechnung wirklich löschen?',
          heading: 'Rechnung löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          InvoicesModuleActions.CommissionCreditsActions.DeleteCommissionCredit(
            { iri: credit }
          )
        );
      });
  }

  handleCreateCommissionCreditItem(
    payload: fromInvoicesModuleModels.CommissionCreditItem,
    commissionCredit: string
  ): void {
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditItemsActions.CreateCommissionCreditItem(
        {
          payload,
          invoiceIri: commissionCredit
        }
      )
    );
  }

  handleUpdateCommissionCreditItem(
    _payload: {
      iri: string;
      payload: fromInvoicesModuleModels.CommissionCreditItem;
    },
    commissionCredit: string
  ): void {
    const { iri, payload } = _payload;
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditItemsActions.UpdateCommissionCreditItem(
        {
          iri,
          payload,
          invoiceIri: commissionCredit
        }
      )
    );
  }

  clearDate(formControlName: string) {
    this.form.get(formControlName).setValue(null);
  }

  getInvoiceItemModalHeading(): string {
    const headings = {
      custom: 'Freie Position zur Rechnung hinzufügen',
      service: 'Service zur Rechnung hinzufügen',
      product: 'Produkt zur Rechnung hinzufügen'
    };

    if (!!this.invoiceItemType$.getValue()) {
      return headings[this.invoiceItemType$.getValue()];
    }

    return 'Position zur Rechnung hinzufügen';
  }

  handleReadServices(): void {
    this.store$.dispatch(
      MasterDataModuleActions.ServicesActions.ReadServices()
    );
  }

  handleReadProducts(): void {
    this.store$.dispatch(
      MasterDataModuleActions.ProductsActions.ReadProducts()
    );
  }

  handleShowCommissionCreditItemForm(
    ref: TemplateRef<any>,
    presets?: fromInvoicesModuleModels.CommissionCredit | any
  ): void {
    // Prepare Edit
    if (presets) {
      this.presets$.next(presets);
      // note: fallback to custom since additional product/service selector is not necessary
      this.invoiceItemType$.next('custom');
    }
    this.openDialog(ref, { disableClose: true });
  }

  handleDeleteCommissionCreditItem(
    iri: string,
    commissionCredit: string
  ): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Möchten Sie diese Position wirklich löschen?',
          heading: 'Position löschen?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbruch'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(
          InvoicesModuleActions.CommissionCreditItemsActions.DeleteCommissionCreditItem(
            {
              iri,
              invoiceIri: commissionCredit
            }
          )
        );
      });
  }

  openMailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'commission-credit', entity$: this.credit$ }
    });
  }

  openDialog(
    ref: TemplateRef<any>,
    config: ModalDialogOptions | any = {}
  ): void {
    this.dialog.open(ref, config);
  }

  handleUpdateCredit(_payload?: CommissionCredit): void {
    if (!this.credit) {
      this.localCredit = _payload;
      return;
    }
    const values = _payload ? _payload : this.caf.value;
    if (values.issuer) {
      values.issuer = extractIri(values.issuer);
    }

    const payload = { iri: extractIri(this.credit), payload: { ...values } };
    this.store$.dispatch(
      CommissionCreditsActions.UpdateCommissionCredit(payload)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    this.store$.dispatch(OrdersActions.ResetCurrentOrder());
    this.store$.dispatch(
      CustomersModuleActions.CustomersActions.ResetCurrentCustomer()
    );
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.ResetCommissionCreditsLoaded()
    );
    this.store$.dispatch(
      InvoicesModuleActions.CommissionCreditsActions.ResetCurrentCommissionCredit()
    );
  }
}
