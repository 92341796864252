import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {Actions, ofType} from '@ngrx/effects';
import {Order, ProductOrderItem} from '../../models';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {Observable, Subject} from 'rxjs';
import {OrdersActions, ProductOrderItemsActions} from '../../store';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-generic-order-item-dialog',
  styleUrls: ['./generic-order-item-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Freie Position hinzufügen</h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <app-order-item-form
        [order]="data.order"
        itemType="generic"
      ></app-order-item-form>
    </div>

  `
})
export class GenericOrderItemDialogComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<GenericOrderItemDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { order: Order }) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
    ]);
    this.actions$.pipe(
      ofType(ProductOrderItemsActions.CreateProductOrderItemSuccess, ProductOrderItemsActions.DeleteProductOrderItemSuccess),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.dialogRef.close();
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleCreateProductOrderItem(payload: ProductOrderItem): void {

  }

}
