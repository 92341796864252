import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { RouterActions } from '../../../application-state/store/actions';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';
import { PayableInvoice } from '../../../invoices/models';
import { PayableInvoicesActions } from '../../../invoices/store';
import { PayableInvoicesSelectors } from '../../../invoices/store/selectors';
import { extractTypeByIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-action-box-wait-for-payment',
  styleUrls: ['./action-box-wait-for-payment.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Warte auf Zahlungseingang</div>
              <div
                class="sub-header col-auto text-color-red text-bold"
                *ngIf="overdue"
              >
                Zahlung verspätet!
              </div>
              <div class="sub-header col-auto">
                Warte auf Zahlungseingang der
                {{ isDownPayment ? 'Teilrechnung' : 'Abschlussrechnung' }}.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="gotToInvoice()"
            >
              <span>Zur Rechnung</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Warte auf Zahlung</div>
          <div class="sub-header col-auto">
            Warte auf Zahlungseingang der
            {{ isDownPayment ? 'Teilrechnung' : 'Abschlussrechnung' }}.
          </div>
        </div>

        <!--<pre>{{order|json}}</pre>-->
      </div>
    </div>
  `
})
export class ActionBoxWaitForPaymentComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  invoice: PayableInvoice;
  order: Order;
  isDownPayment = false;

  constructor(
    private store$: Store<ApplicationState>,
    private authService: AuthService,
    private fb: FormBuilder,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isSales() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  get overdue(): boolean {
    return this.invoice?.overdue;
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (
          order.potentialActions?.findIndex(
            e => e.transition.indexOf('down_payment') > -1
          ) > -1
        ) {
          this.isDownPayment = true;
        }
        const iri =
          this.order.paymentProcess?.autoCreatedInvoice ||
          this.order.paymentProcess?.autoCreatedPartialInvoice;
        if (!iri) {
          return;
        }
        this.store$
          .select(PayableInvoicesSelectors.selectPayableInvoicesEntities)
          .pipe(
            map(e => e[iri]),
            tap(e => {
              if (!e) {
                this.store$.dispatch(
                  PayableInvoicesActions.ReadPayableInvoice({ iri })
                );
              }
            }),
            filter(e => !!e)
          )
          .subscribe(i => {
            this.invoice = i;
          });
      });
  }

  gotToInvoice(): void {
    const iri =
      this.order.paymentProcess?.autoCreatedInvoice ||
      this.order.paymentProcess?.autoCreatedPartialInvoice;
    const uuid = getUuidFromIri(iri);
    const type = extractTypeByIri(iri);
    this.store$.dispatch(
      RouterActions.Go({ path: ['invoices', 'payable', type, uuid] })
    );
  }
}
