import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'view-heading',
  styleUrls: ['view-heading.component.scss'],
  template: `

    <div class="vwhdng__outer">
      <div class="vwhdng__wrapper wrap {{ wrapClass }}" [style]="wrapStyles">

        <div class="grid {{ gridClass }}" [style]="gridStyles">
          <div class="left {{ colLeftSpan }}" [style]="leftBlockStyles">
            <h1 class="vwhdng" *ngIf="heading">{{ heading }}</h1>
            <small *ngIf="subheading" class="vwsubhdng">{{ subheading }}</small>

            <ng-content select=".left"></ng-content>
          </div>

          <div class="right {{ colRightSpan }} {{ colRightClass }} {{ colRightOffset }}" [style]="rightBlockStyles">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ViewHeadingComponent {

  @Input() gridClass: string;
  @Input() gridStyles: string;
  @Input() wrapClass: string;
  @Input() wrapStyles: string;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() colLeft: string;
  @Input() colRight: string;
  @Input() colRightClass: string;
  @Input() offsetRight: string;
  @Input() leftBlockStyles: string;
  @Input() rightBlockStyles: string;

  get colLeftSpan(): string {
    return this.colLeft ? this.colLeft : 'column-auto';
  }

  get colRightSpan(): string {
    return this.colRight ? this.colRight : 'column-auto';
  }

  get colRightOffset(): string {
    return this.offsetRight ? 'off-' + this.offsetRight : '';
  }
}
