import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {LocalStorageService} from "../../services";


@Component({
  selector: 'app-search-input',
  styleUrls: ['search-input.component.scss'],
  template: `
    <mat-form-field [formGroup]="searchForm">
      <input type="text" matInput placeholder="Freitextsuche" formControlName="term" #searchBar>
      <button *ngIf="!!this.searchForm.get('term').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="handleSearchTermCleared(); searchBar.blur()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="showToggle" mat-icon-button (click)="toggleFilterBoxVisibility()">
      <mat-icon matSuffix [innerText]="searchBoxVisible$.getValue() ? 'adjust' : 'filter_alt'">filter_alt
      </mat-icon>
    </button>

    <ng-content></ng-content>
  `
})
export class SearchInputComponent implements OnInit, OnDestroy {

  @Input() disabled = false;
  @Input() showToggle = false;
  @Input() filtersPage?: string;

  @Output()
  searchTermChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  searchFilterVisibilityToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  onDestroy$: Subject<any> = new Subject();
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchForm: FormGroup;

  constructor(private fb: FormBuilder, private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    const filters = this.localStorageService.getObjectByKey('filters', this.filtersPage);
    this.searchBoxVisible$.next(!!filters);
    this.searchForm = this.fb.group({
      term: this.fb.control(null)
    });

    this.searchForm
      .valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe(({term}) => {
      this.searchTermChange.emit(term);
    });
  }

  handleSearchTermCleared(): void {
    this.searchForm.get('term').setValue('');
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
    this.searchFilterVisibilityToggle.emit(this.searchBoxVisible$.getValue());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
