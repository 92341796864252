import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {OrdersActions, ServiceOrderItemsActions} from '../actions';
import {ServiceOrderItemsService} from '../../services';
import {NotifierService} from 'angular-notifier';

@Injectable()
export class ServiceOrderItemsEffects {

  constructor(private actions$: Actions, private service: ServiceOrderItemsService, private notifierService: NotifierService) {
  }

  CreateServiceOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceOrderItemsActions.CreateServiceOrderItem),
    switchMap(({payload}) => {
      return this.service.createServiceOrderItem(payload).pipe(
        map((response: any) => ServiceOrderItemsActions.CreateServiceOrderItemSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ServiceOrderItemsActions.CreateServiceOrderItemFail({response})))
      );
    })
  ));

  ReadServiceOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceOrderItemsActions.ReadServiceOrderItem),
    switchMap(({iri}) => {
      return this.service.readServiceOrderItem(iri).pipe(
        map((response: any) => ServiceOrderItemsActions.ReadServiceOrderItemSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ServiceOrderItemsActions.ReadServiceOrderItemFail({response})))
      );
    })
  ));

  ReadServiceOrderItems$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceOrderItemsActions.ReadServiceOrderItems),
    switchMap(({orderUuid}) => {
      return this.service.readServiceOrderItems(orderUuid).pipe(
        map((response: any) => ServiceOrderItemsActions.ReadServiceOrderItemsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ServiceOrderItemsActions.ReadServiceOrderItemsFail({response})))
      );
    })
  ));

  UpdateServiceOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceOrderItemsActions.UpdateServiceOrderItem),
    switchMap(({iri, payload}) => {
      return this.service.updateServiceOrderItem(iri, payload).pipe(
        map((response: any) => ServiceOrderItemsActions.UpdateServiceOrderItemSuccess({response})),
        catchError((response: HttpErrorResponse) => of(ServiceOrderItemsActions.UpdateServiceOrderItemFail({response})))
      );
    })
  ));

  DeleteServiceOrderItem$ = createEffect(() => this.actions$.pipe(
    ofType(ServiceOrderItemsActions.DeleteServiceOrderItem),
    switchMap(({iri}) => {
      return this.service.deleteServiceOrderItem(iri).pipe(
        map(() => ServiceOrderItemsActions.DeleteServiceOrderItemSuccess({iri})),
        catchError((response: HttpErrorResponse) => of(ServiceOrderItemsActions.DeleteServiceOrderItemFail({response})))
      );
    })
  ));

  SuccessActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      ServiceOrderItemsActions.CreateServiceOrderItemSuccess,
      ServiceOrderItemsActions.UpdateServiceOrderItemSuccess,
      ServiceOrderItemsActions.DeleteServiceOrderItemSuccess,
    ),
    map(({type}) => {

      let message = 'Erfolgreich';
      switch (type) {
        case ServiceOrderItemsActions.CREATE_SERVICE_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde angelegt.';
          break;
        case ServiceOrderItemsActions.UPDATE_SERVICE_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde aktualisiert.';
          break;
        case ServiceOrderItemsActions.DELETE_SERVICE_ORDER_ITEM_SUCCESS:
          message = 'Die Position wurde erfolgreich gelöscht.';
          break;
      }
      this.notifierService.show({type: 'success', message});
    })
  ), {dispatch: false});

}
