import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {CustomerPartnerStatusesActions} from '../actions/';
import {CustomerPartnerStatusesService} from '../../services';

@Injectable()
export class CustomerPartnerStatusesEffects {

  constructor(private actions$: Actions, private cpss: CustomerPartnerStatusesService) {
  }


  ReadCustomerPartnerStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerPartnerStatusesActions.LoadCustomerPartnerStatuses),
    map(action => action),
    switchMap(() => {
      return this.cpss.getCustomerPartnerStatuses().pipe(
        map((response: any) => CustomerPartnerStatusesActions.LoadCustomerPartnerStatusesSuccess({response})),
        catchError(response => of(CustomerPartnerStatusesActions.LoadCustomerPartnerStatusesFail({response})))
      );
    })
  ));
}
