import {Action, combineReducers, createFeatureSelector, createReducer} from '@ngrx/store';

import * as fromRemoteAccessReasonsReducers from './remote-access-reasons.reducers';
import * as fromTicketAttachmentsReducers from './ticket-attachments.reducers';
import * as fromTicketCommentTagsReducers from './ticket-comment-tags.reducers';
import * as fromTicketCommentsReducers from './ticket-comments.reducers';
import * as fromTicketsReducers from './tickets.reducers';

export const TICKETS_MODULE_FEATURE_KEY = 'ticketsModule';

export const REMOTE_ACCESS_REASONS_FEATURE_KEY = 'remoteAccessReasons';
export const TICKETS_FEATURE_KEY = 'tickets';
export const TICKET_ATTACHMENTS_FEATURE_KEY = 'ticketAttachments';
export const TICKET_COMMENTS_FEATURE_KEY = 'ticketComments';
export const TICKET_COMMENT_TAGS_FEATURE_KEY = 'ticketCommentTags';

export interface TicketsModuleState {
  [REMOTE_ACCESS_REASONS_FEATURE_KEY]: fromRemoteAccessReasonsReducers.State;
  [TICKETS_FEATURE_KEY]: fromTicketsReducers.State;
  [TICKET_ATTACHMENTS_FEATURE_KEY]: fromTicketAttachmentsReducers.State;
  [TICKET_COMMENTS_FEATURE_KEY]: fromTicketCommentsReducers.State;
  [TICKET_COMMENT_TAGS_FEATURE_KEY]: fromTicketCommentTagsReducers.State;
}

export const selectTicketsModuleState = createFeatureSelector<TicketsModuleState>(TICKETS_MODULE_FEATURE_KEY);

export function reducers(state: TicketsModuleState, action: Action) {
  return combineReducers({
    [REMOTE_ACCESS_REASONS_FEATURE_KEY]: fromRemoteAccessReasonsReducers.reducer,
    [TICKETS_FEATURE_KEY]: fromTicketsReducers.reducer,
    [TICKET_ATTACHMENTS_FEATURE_KEY]: fromTicketAttachmentsReducers.reducer,
    [TICKET_COMMENTS_FEATURE_KEY]: fromTicketCommentsReducers.reducer,
    [TICKET_COMMENT_TAGS_FEATURE_KEY]: fromTicketCommentTagsReducers.reducer,
  })(state, action);
}
