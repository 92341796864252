import {createAction} from '@ngrx/store';

export const LOADING_START = '[Application State Module] Loading Start';
export const LOADING_FINISH = '[Application State Module] Loading  Finish';

export const LoadingStart = createAction(
  LOADING_START,
  () => ({loading: true})
);

export const LoadingFinish = createAction(
  LOADING_FINISH,
  () => ({loading: false})
);
