import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {REPLACEMENT_DATA_MEDIUMS_FEATURE_KEY, selectWarehouseModuleState} from '../reducers';
import {ApplicationState} from '../../../application-state/store';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {ReplacementDataMedium, ReplacementStockItem} from '../../models';
import {State} from '../reducers/replacement-stock-items.reducers';

type BaseType = ReplacementDataMedium;
type StateType = State;
const NAMESPACE = REPLACEMENT_DATA_MEDIUMS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectWarehouseModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, any>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, any>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);
