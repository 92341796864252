import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ShipmentServicesActions} from '../actions';
import {ShipmentServicesService} from '../../services/shipment-services.service';

@Injectable()
export class ShipmentServicesEffects {

  constructor(private actions$: Actions, private service: ShipmentServicesService) {
  }


  getShipmentService$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentServicesActions.ReadShipmentService),
    map(action => action),
    switchMap(({iri}) => {
      return this.service
        .getShipmentService(iri)
        .pipe(
          map(response => ShipmentServicesActions.ReadShipmentServiceSuccess({response})),
          catchError(response => of(ShipmentServicesActions.ReadShipmentServiceFail({response})))
        );
    })
  ));


  getShipmentServices$ = createEffect(() => this.actions$.pipe(
    ofType(ShipmentServicesActions.ReadShipmentServices),
    map(action => action),
    switchMap(({serviceType}) => {
      return this.service
        .getShipmentServices(serviceType)
        .pipe(
          map(response => ShipmentServicesActions.ReadShipmentServicesSuccess({response})),
          catchError(response => of(ShipmentServicesActions.ReadShipmentServicesFail({response})))
        );
    })
  ));
}
