import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';

import * as fromPasswordsReducers from './passwords.reducers';
import * as fromUserProfileReducers from './user-profiles.reducers';
import * as fromUsersReducers from './auth.reducers';

export const AUTH_MODULE_FEATURE_KEY = 'auth';

export const USER_FEATURE_KEY = 'user';
export const PROFILE_FEATURE_KEY = 'profile';
export const PASSWORD_FEATURE_KEY = 'password';

export const selectAuthFeature = createFeatureSelector<AuthModuleState>(AUTH_MODULE_FEATURE_KEY);

export interface AuthModuleState {
  [USER_FEATURE_KEY]: fromUsersReducers.State;
  [PASSWORD_FEATURE_KEY]: fromPasswordsReducers.State;
  [PROFILE_FEATURE_KEY]: fromUserProfileReducers.State;
}

export function reducers(state: any, action: Action) {

  return combineReducers({
    [USER_FEATURE_KEY]: fromUsersReducers.reducer,
    [PASSWORD_FEATURE_KEY]: fromPasswordsReducers.reducer,
    [PROFILE_FEATURE_KEY]: fromUserProfileReducers.reducer
  })(state, action);
}
