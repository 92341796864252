import { Component, Input, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import {
  isLoadingArray,
  loadIfNotLoaded
} from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomerReplacementStockItemsSelectors,
  CustomerStockItemsSelectors,
  DataMediumsSelectors
} from '../../store/selectors';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  DataMediumsActions,
  ReplacementStockItemsActions
} from '../../store';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { CustomerReplacementStockItem, CustomerStockItem } from '../../models';
import { Actions, ofType } from '@ngrx/effects';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductOrderItemsSelectors } from '../../../orders/store/selectors';
import {
  ProductsSelectors,
  StorageLocationsSelectors
} from '../../../master-data/store/selectors';
import {
  ProductsActions,
  StorageLocationsActions
} from '../../../master-data/store';
import { OrderItemDialogComponent } from '../../../orders/components/order-item-dialog/order-item-dialog.component';
import { CreateShipmentDialogComponent } from '../../../shared/components/create-shipment-dialog/create-shipment-dialog.component';
import { AddCustomerHardwareDialogComponent } from '../add-customer-hardware-dialog/add-customer-hardware-dialog.component';
import { WarehouseRights } from '../../../shared/security/warehouseRights';

@Component({
  selector: 'app-order-stock-item-widget',
  styleUrls: ['./order-stock-item-widget.component.scss'],
  template: `
    <div class="card">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <div class="card__heading">
        <span>Datenträger</span>
      </div>

      <div class="card__content px-4 pb-4">
        <!--<pre>{{form.getRawValue()|json}}</pre>-->

        <div class="row pt-4">
          <div class="col">
            <h1 style="font-size: 20px;">Datenträger/Gehäuse</h1>
          </div>
          <div class="col text-right">
            <button
              mat-button
              color="green"
              class=""
              (click)="openAddCustomerStockItem()"
              [disabled]="!canAddDM"
            >
              <mat-icon class="me-3">add</mat-icon>
              Datenträger einlagern
            </button>
          </div>
        </div>
        <app-stock-item-display-line
          *ngFor="let stockItem of customerStockItems"
          [hideToOrderBtn]="true"
          selectMode="dataMedium"
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          [stockItem]="stockItem"
        ></app-stock-item-display-line>
        <div *ngIf="customerStockItems.length <= 0">
          <span *ngIf="customerStockItems.length <= 0"
            >Keine Datenträger vorhanden</span
          >

          <!--        <pre>{{customerStockItems|json}}</pre>-->
        </div>
        <div class="row pt-4">
          <div class="col">
            <h1 style="font-size: 20px;">
              Ersatzdatenträger/-gehäuse vom Kunden
            </h1>
          </div>
          <div class="col text-right">
            <button
              mat-button
              color="green"
              class=""
              (click)="openAddCustomerReplacementStockItem()"
            >
              <mat-icon class="me-3">add</mat-icon>
              Ersatzdatenträger (Kunde) einlagern
            </button>
          </div>
        </div>

        <app-stock-item-display-line
          *ngFor="let stockItem of customerReplacementStockItems"
          [hideToOrderBtn]="true"
          selectMode="dataMedium"
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          [stockItem]="stockItem"
        ></app-stock-item-display-line>
        <div *ngIf="customerReplacementStockItems.length <= 0">
          <span>Keine Datenträger vorhanden</span>
        </div>

        <!--        <pre>{{customerReplacementStockItems|json}}</pre>-->
        <div class="row pt-4">
          <div class="col">
            <h1 style="font-size: 20px;">Ersatzdatenträger</h1>
          </div>
          <div class="col text-right">
            <button
              mat-button
              color="green"
              class=""
              (click)="handleRequestCreateProductOrderItemForm()"
            >
              <mat-icon class="me-3">add</mat-icon>
              Ersatzdatenträger hinzufügen
            </button>
          </div>
        </div>

        <app-stock-item-display-line
          *ngFor="let stockItem of replacementStockItems"
          [hideToOrderBtn]="true"
          selectMode="dataMedium"
          (changeSelectedDataMedia)="setSelectedDataMedia($event)"
          [filterDataMedia]="replacementDataMedia"
          [stockItem]="stockItem"
        ></app-stock-item-display-line>
        <div *ngIf="replacementStockItems.length <= 0">
          <span> Keine Ersatzdatenträger zugewiesen</span>
        </div>

        <!--        <pre>{{replacementStockItems|json}}</pre>-->
      </div>

      <div class="card__footer text-right">
        <button
          mat-flat-button
          class="m-r--8"
          (click)="openCreateShipmentDialog()"
          *ngIf="canCreate$ | async"
        >
          <mat-icon class="me-3">local_shipping</mat-icon>
          <span>{{
            this.form.get('dataMedia').value?.length > 0
              ? 'markierte Datenträger versenden'
              : 'Manuellen Versand beauftragen'
          }}</span>
        </button>
      </div>
    </div>
  `
})
export class OrderStockItemWidgetComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  order: Order;

  form: FormGroup;

  isLoading$: Observable<boolean>;
  loadingStockItems: Array<string> = [];
  customerReplacementStockItems: Array<CustomerReplacementStockItem> = [];
  customerStockItems: Array<CustomerStockItem> = [];
  replacementStockItems: Array<CustomerStockItem> = [];
  replacementDataMedia: Array<string> = [];

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public actions$: Actions,
    private warehouseRight: WarehouseRights
  ) {
    super();
  }

  get canCreate$(): Observable<boolean> {
    return this.warehouseRight.canCreate$;
  }

  get canAddDM(): boolean {
    return (
      this.order?.rawState?.indexOf('new') > -1 ||
      this.order?.rawState?.indexOf('incoming_dm_delayed') > -1
    );
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      dataMedia: this.fb.control([], Validators.required)
    });

    this.isLoading$ = isLoadingArray([
      this.store$.select(DataMediumsSelectors.isLoading),
      this.store$.select(CustomerReplacementStockItemsSelectors.isLoading),
      this.store$.select(CustomerStockItemsSelectors.isLoading)
    ]);

    loadIfNotLoaded(
      this.store$,
      ProductsSelectors.isLoaded,
      ProductsActions.ReadProducts()
    );
    loadIfNotLoaded(
      this.store$,
      StorageLocationsSelectors.isLoaded,
      StorageLocationsActions.ReadStorageLocations()
    );

    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => !!e),
        distinctUntilChanged()
      )
      .subscribe(order => {
        this.order = order;
      });
    this.activatedRoute.paramMap
      .pipe(
        filter(e => !!e && !!e.get('uuid')),
        takeUntil(this.onDestroy$)
      )
      .subscribe(params => {
        const iri = '/api/orders/' + params.get('uuid');
        this.loadCustomerDataMediums(iri);
        this.store$
          .select(ProductOrderItemsSelectors.selectProductOrderItemsForOrder, {
            iri
          })
          .pipe(
            takeUntil(this.onDestroy$),
            filter(e => !!e && e.length > 0)
          )
          .subscribe(items => {
            const dataMediaList = items.map(e => e.replacementDataMedium);
            this.replacementDataMedia.push(...dataMediaList.map(extractIri));
            this.loadStockItemsFromDataMediums(dataMediaList);
          });
      });
  }

  handleRequestCreateProductOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: { order: this.order, type: 'product' }
    });
  }

  openCreateShipmentDialog(): void {
    this.dialog.open(CreateShipmentDialogComponent, {
      panelClass: 'width-95',
      data: {
        type: 'OrderShipment',
        shipment: {
          dataMedia: this.form.get('dataMedia').value,
          order: extractIri(this.order)
        }
      }
    });
  }

  loadStockItemsFromDataMediums(entities): void {
    this.actions$
      .pipe(
        ofType(
          CustomerStockItemsActions.ReadCustomerStockItemSuccess,
          CustomerStockItemsActions.UpdateCustomerStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.customerStockItems.push(response);
        } else {
          this.customerStockItems[
            this.customerStockItems.findIndex(f => extractIri(f) === iri)
          ] = response;
        }
      });
    this.actions$
      .pipe(
        ofType(
          CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemSuccess,
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.customerReplacementStockItems.push(response);
        } else {
          this.customerReplacementStockItems[
            this.customerReplacementStockItems.findIndex(
              f => extractIri(f) === iri
            )
          ] = response;
        }
      });
    this.actions$
      .pipe(
        ofType(ReplacementStockItemsActions.ReadReplacementStockItemSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        const iri = extractIri(response);
        if (this.loadingStockItems.indexOf(iri) > -1) {
          this.loadingStockItems.splice(this.loadingStockItems.indexOf(iri), 1);
          this.replacementStockItems.push(response);
        }
      });
    const customerReplacementStockItemIris: { [iri: string]: true } = {};
    const replacementStockItemIris: { [iri: string]: true } = {};
    const customerStockItemsIris: { [iri: string]: true } = {};
    entities.forEach(e => {
      const iri = extractIri(e.stockItem);
      if (iri.indexOf('/customer_stock_items') > -1) {
        customerStockItemsIris[iri] = true;
      } else if (iri.indexOf('/customer_replacement_stock_items') > -1) {
        customerReplacementStockItemIris[iri] = true;
      } else if (iri.indexOf('/replacement_stock_items') > -1) {
        replacementStockItemIris[iri] = true;
      } else {
        console.log('FEHLER:', e);
      }
    });
    for (const iri of Object.keys(customerStockItemsIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.customerStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          CustomerStockItemsActions.ReadCustomerStockItem({ iri })
        );
      }
    }
    for (const iri of Object.keys(customerReplacementStockItemIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.customerReplacementStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem(
            { iri }
          )
        );
      }
    }
    for (const iri of Object.keys(replacementStockItemIris)) {
      if (
        this.loadingStockItems.indexOf(iri) <= -1 &&
        !!!this.replacementStockItems.find(e => extractIri(e) === iri)
      ) {
        this.loadingStockItems.push(iri);
        this.store$.dispatch(
          ReplacementStockItemsActions.ReadReplacementStockItem({ iri })
        );
      }
    }
  }

  //
  setSelectedDataMedia($event: {
    selected: Array<string>;
    unselected: Array<string>;
  }): void {
    const formField = this.form.get('dataMedia');
    const value = [...(formField.value as Array<string>)];
    for (const unselectIri of $event.unselected) {
      if (value.indexOf(unselectIri) > -1) {
        value.splice(value.indexOf(unselectIri), 1);
      }
    }
    for (const selectedIri of $event.selected) {
      if (value.indexOf(selectedIri) <= -1) {
        value.push(selectedIri);
      }
    }
    this.form.get('dataMedia').setValue(value);
  }

  //              this.store$.dispatch(CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem({iri}));

  openAddCustomerStockItem(): void {
    this.openAddCustomerHardwareDialog({ type: 'CustomerStockItem' });
  }

  openAddCustomerReplacementStockItem(): void {
    this.openAddCustomerHardwareDialog({
      type: 'CustomerReplacementStockItem'
    });
  }

  openAddCustomerHardwareDialog(query: { type: string }): void {
    this.dialog.open(AddCustomerHardwareDialogComponent, {
      data: {
        hardwareTyp: query.type,
        shipment: {
          order: extractIri(this.order)
        }
      }
    });
  }

  private loadCustomerDataMediums(orderIri): void {
    const orderUUID = getUuidFromIri(orderIri);
    this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, { orderIri })
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e.length > 0),
        distinctUntilChanged()
      )
      .subscribe(entities => {
        this.loadStockItemsFromDataMediums(entities);
      });

    this.store$.dispatch(
      DataMediumsActions.ReadDataMediums({
        page: -1,
        params: { 'stockItem.order.uuid': orderUUID }
      })
    );
  }
}
