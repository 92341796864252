import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PARTIAL_INVOICE_ITEM = '[Invoices Module] Create Partial Invoice Item';
export const CREATE_PARTIAL_INVOICE_ITEM_FAIL = '[Invoices Module] Create Partial Invoice Item Fail';
export const CREATE_PARTIAL_INVOICE_ITEM_SUCCESS = '[Invoices Module] Create Partial Invoice Item Success';

export const CreatePartialInvoiceItem = createAction(
  CREATE_PARTIAL_INVOICE_ITEM,
  props<{ payload: fromInvoicesModuleModels.PartialInvoiceItem, invoiceIri: string }>()
);

export const CreatePartialInvoiceItemSuccess = createAction(
  CREATE_PARTIAL_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.PartialInvoiceItem, invoiceIri: string }>()
);

export const CreatePartialInvoiceItemFail = createAction(
  CREATE_PARTIAL_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PARTIAL_INVOICE_ITEM = '[Invoices Module] Read Partial Invoice Item';
export const READ_PARTIAL_INVOICE_ITEM_SUCCESS = '[Invoices Module] Read Partial Invoice Item Success';
export const READ_PARTIAL_INVOICE_ITEM_FAIL = '[Invoices Module] Read Partial Invoice Item Fail';

export const ReadPartialInvoiceItem = createAction(
  READ_PARTIAL_INVOICE_ITEM,
  props<{ iri: string }>()
);

export const ReadPartialInvoiceItemSuccess = createAction(
  READ_PARTIAL_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.PartialInvoiceItem | any }>()
);

export const ReadPartialInvoiceItemFail = createAction(
  READ_PARTIAL_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PARTIAL_INVOICE_ITEM = '[Invoices Module] Update Partial Invoice Item';
export const UPDATE_PARTIAL_INVOICE_ITEM_SUCCESS = '[Invoices Module] Update Partial Invoice Item Success';
export const UPDATE_PARTIAL_INVOICE_ITEM_FAIL = '[Invoices Module] Update Partial Invoice Item Fail';

export const UpdatePartialInvoiceItem = createAction(
  UPDATE_PARTIAL_INVOICE_ITEM,
  props<{ iri: string, payload: fromInvoicesModuleModels.PartialInvoiceItem | any, invoiceIri: string }>()
);

export const UpdatePartialInvoiceItemSuccess = createAction(
  UPDATE_PARTIAL_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.PartialInvoiceItem, invoiceIri: string }>()
);

export const UpdatePartialInvoiceItemFail = createAction(
  UPDATE_PARTIAL_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_PARTIAL_INVOICE_ITEM = '[Invoices Module] Delete Partial Invoice Item';
export const DELETE_PARTIAL_INVOICE_ITEM_SUCCESS = '[Invoices Module] Delete Partial Invoice Item Success';
export const DELETE_PARTIAL_INVOICE_ITEM_FAIL = '[Invoices Module] Delete Partial Invoice Item Fail';

export const DeletePartialInvoiceItem = createAction(
  DELETE_PARTIAL_INVOICE_ITEM,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeletePartialInvoiceItemSuccess = createAction(
  DELETE_PARTIAL_INVOICE_ITEM_SUCCESS,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeletePartialInvoiceItemFail = createAction(
  DELETE_PARTIAL_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
