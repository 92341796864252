import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as fromAdministratorsModuleModels from '../../../administrators/models';
import { Administrator } from '../../../administrators/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { AdministratorsSelectors } from '../../../administrators/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { AdministratorsActions } from '../../../administrators/store';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';
import { TicketCommentsActions } from '../../../tickets/store';

@Component({
  selector: 'app-action-box-analysis-nok-to-assign-analysis',
  styleUrls: ['./action-box-analysis-nok-to-assign-analysis.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Analyse tech. nicht i.O., erneute Analyse beauftragen?
              </div>
              <div class="sub-header col-auto">
                Möchten Sie eine erneute Analyse in einem anderen Labor
                beauftragen?
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="makeTransition1()"
              [disabled]="!okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
            <button
              class="decision-btn btn-wider"
              mat-button
              color="red"
              (click)="makeTransition2()"
            >
              <mat-icon class="me-2">cancel</mat-icon>
              <span>Nein, Auftrag abschließen</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Erneute Analyse?</div>
          <div class="sub-header col-auto">
            Analyse nicht i.O., warte auf Entscheidung, ob eine neue Analyse
            beauftragt wird
          </div>
        </div>
      </div>
      <div
        class="additional-content p-4"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-sm-4">
            <ng-select
              placeholder="Rückfrage richten an ..."
              [items]="administrators$ | async"
              bindValue="@id"
              appendTo="body"
              bindLabel="username"
              [searchable]="false"
              formControlName="recipient"
            >
              <ng-template ng-label-tmp let-administrator="item">
                <span style="">{{ getAdministratorName(administrator) }}</span>
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div class="ng-option">
                  <span style="">{{ getAdministratorName(item) }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-sm-8">
            <app-text-editor
              formControlName="content"
              label="Rückfrage"
            ></app-text-editor>
          </div>
        </div>
        <div class="row" style="margin-top: 50px;">
          <div class="col text-right">
            <button
              class="decision-btn"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid || !okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Rückfrage speichern</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxAnalysisNokToAssignAnalysisComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  okAction: PotentialAction = null;
  nokAction: PotentialAction = null;

  form: FormGroup;
  administrators$: Observable<Array<Administrator>>;
  administratorsEntities: { [key: string]: Administrator };

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadAdministrators();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(this.order['@id']);

        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('_to_assign_analysis') > -1 && !e.error
        );
        this.nokAction = order.potentialActions?.find(
          e => e.transition.indexOf('_to_no_order') > -1 && !e.error
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      content: this.fb.control(null, [
        Validators.required,
        Validators.minLength(2)
      ]),
      recipient: this.fb.control(null)
    });
  }

  makeTransition1(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  makeTransition2(): void {
    const action = this.nokAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    this.store$.dispatch(
      TicketCommentsActions.CreateTicketComment({ payload: this.form.value })
    );
  }

  getAdministratorName(
    admin: fromAdministratorsModuleModels.Administrator
  ): string {
    return `${admin.firstName} ${admin.lastName}`;
  }

  private loadAdministrators(): void {
    this.store$
      .select(AdministratorsSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.administratorsEntities = entities;
      });
    this.administrators$ = this.store$.select(AdministratorsSelectors.sList);
    loadIfNotLoaded(
      this.store$,
      AdministratorsSelectors.isLoaded,
      AdministratorsActions.ReadAdministrators()
    );
  }
}
