import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {Actions, ofType} from '@ngrx/effects';
import {Order} from '../../models';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {Observable, Subject} from 'rxjs';
import {OrdersActions} from '../../store';
import {takeUntil} from 'rxjs/operators';
import * as fromOrdersModuleModels from '../../models';
import * as OrdersModuleActions from '../../store';

@Component({
  selector: 'app-order-analysis-result-dialog',
  styleUrls: ['./order-analysis-result-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Analyseergebnis bearbeiten</h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

      <app-analysis-result-form [order$]="data.order$"
                                (requestUpdateOrder)="handleUpdateOrder($event)"
      ></app-analysis-result-form>
    </div>


  `
})
export class OrderAnalysisResultDialogComponent implements OnInit, OnDestroy {

  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<OrderAnalysisResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order> }
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
    ]);
    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderSuccess),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.dialogRef.close();
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleUpdateOrder(payload: { iri: string, payload: { analysisResult: string } }): void {
    this.store$.dispatch(OrdersActions.UpdateOrderAnalysisResult(payload));
  }


}
