import {createSelector} from '@ngrx/store';

import {ORDER_COMMENTS_FEATURE_KEY, OrdersModuleState, selectOrdersModuleState} from '../reducers';
import {sortByCreatedAtDate} from '../../../shared/utilities/array.utility';
import {generateBaseSelectors} from '../../../shared/utilities/selector.utilities';
import {State} from '../reducers/order-comments.reducers';

const NAMESPACE = ORDER_COMMENTS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectOrdersModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

export const selectOrderCommentsByOrder = createSelector(sList,
  (orderComments, {iri}) => orderComments.filter(comment => comment.order === iri)
);
