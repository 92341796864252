import { FormTouchedGuard } from './form-touched.guard';
import { LeadCommentTagsLoadedGuard } from './lead-comment-tags-loaded.guard';
import { LeadContactTypesLoadedGuard } from './lead-contact-types-loaded.guard';
import { LeadLoadedGuard } from './lead-loaded.guard';
import { LeadOriginTypesLoadedGuard } from './lead-origin-types-loaded.guard';
import { LeadsLoadedGuard } from './leads-loaded.guard';

export const AllGuards = [
  FormTouchedGuard,
  LeadCommentTagsLoadedGuard,
  LeadContactTypesLoadedGuard,
  LeadLoadedGuard,
  LeadOriginTypesLoadedGuard,
  LeadsLoadedGuard
];

export * from './form-touched.guard';
export * from './lead-comment-tags-loaded.guard';
export * from './lead-contact-types-loaded.guard';
export * from './lead-loaded.guard';
export * from './lead-origin-types-loaded.guard';
export * from './leads-loaded.guard';
