import { DeviceDetailsFormComponent } from './device-details-form/device-details-form.component';
import { LeadCommentFormComponent } from './lead-comment-form/lead-comment-form.component';
import { LeadCommentsComponent } from './lead-comments/lead-comments.component';
import { LeadContactFormComponent } from './lead-contact-form/lead-contact-form.component';
import { LeadMetaFormComponent } from './lead-meta-form/lead-meta-form.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { TrackingDetailsComponent } from './tracking-details/tracking-details.component';
import { NewLeadsWidgetComponent } from './new-leads-widget/new-leads-widget.component';
import { LeadShipmentDialogComponent } from './lead-shipment-dialog/lead-shipment-dialog.component';
import { LeadCommentFormDialogComponent } from './lead-comment-form-dialog/lead-comment-form-dialog.component';
import { BrokeredLeadsWidgetComponent } from './brokered-leads-widget/brokered-leads-widget.component';

export const AllComponents = [
  DeviceDetailsFormComponent,
  LeadCommentFormComponent,
  LeadCommentsComponent,
  LeadContactFormComponent,
  LeadMetaFormComponent,
  LeadsListComponent,
  TrackingDetailsComponent,
  NewLeadsWidgetComponent,
  LeadShipmentDialogComponent,
  LeadCommentFormDialogComponent,
  BrokeredLeadsWidgetComponent
];

export * from './device-details-form/device-details-form.component';
export * from './lead-comment-form/lead-comment-form.component';
export * from './lead-comments/lead-comments.component';
export * from './lead-contact-form/lead-contact-form.component';
export * from './lead-meta-form/lead-meta-form.component';
export * from './leads-list/leads-list.component';
export * from './tracking-details/tracking-details.component';
export * from './new-leads-widget/new-leads-widget.component';
export * from './lead-shipment-dialog/lead-shipment-dialog.component';
export * from './lead-comment-form-dialog/lead-comment-form-dialog.component';
export * from './brokered-leads-widget/brokered-leads-widget.component';
