import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {CommissionCreditItemsActions} from '../actions/';
import {CommissionCreditItemsService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class CommissionCreditItemsEffects {

  constructor(private actions$: Actions, private service: CommissionCreditItemsService) {
  }

  CreateCommissionCreditItem$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditItemsActions.CreateCommissionCreditItem),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createCommissionCreditItem(payload).pipe(
        map((response) => CommissionCreditItemsActions.CreateCommissionCreditItemSuccess({response, invoiceIri})),
        catchError(response => of(CommissionCreditItemsActions.CreateCommissionCreditItemFail({response})))
      );
    })
  ));

  ReadCommissionCreditItem$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditItemsActions.ReadCommissionCreditItem),
    switchMap(({iri}) => {
      return this.service.readCommissionCreditItem(iri).pipe(
        map((response => CommissionCreditItemsActions.ReadCommissionCreditItemSuccess({response}))),
        catchError(response => of(CommissionCreditItemsActions.ReadCommissionCreditItemFail({response})))
      );
    })
  ));

  ReadCommissionCreditItems$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditItemsActions.ReadCommissionCreditItems),
    switchMap(() => {
      return this.service.readCommissionCreditItems().pipe(
        map((response) => CommissionCreditItemsActions.ReadCommissionCreditItemsSuccess({response})),
        catchError(response => of(CommissionCreditItemsActions.ReadCommissionCreditItemsFail({response})))
      );
    })
  ));

  UpdateCommissionCreditItem$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditItemsActions.UpdateCommissionCreditItem),
    switchMap(({iri, payload, invoiceIri}) => {
      return this.service.updateCommissionCreditItem(iri, payload).pipe(
        map((response: fromModuleModels.CommissionCreditItem) => CommissionCreditItemsActions.UpdateCommissionCreditItemSuccess({
          response,
          invoiceIri
        })),
        catchError((response: HttpErrorResponse) => of(CommissionCreditItemsActions.UpdateCommissionCreditItemFail({response})))
      );
    })
  ));

  DeleteCommissionCreditItem$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditItemsActions.DeleteCommissionCreditItem),
    switchMap(({iri, invoiceIri}) => {
      return this.service.deleteCommissionCreditItem(iri).pipe(
        map(() => CommissionCreditItemsActions.DeleteCommissionCreditItemSuccess({iri, invoiceIri})),
        catchError((response: HttpErrorResponse) => of(CommissionCreditItemsActions.DeleteCommissionCreditItemFail({response})))
      );
    })
  ));

  CommissionCreditItemActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CommissionCreditItemsActions.CreateCommissionCreditItemSuccess,
      CommissionCreditItemsActions.UpdateCommissionCreditItemSuccess,
    ),
    map(({type}) => {

      const text = type === CommissionCreditItemsActions.CREATE_COMMISSION_CREDIT_ITEM_SUCCESS
        ? `Die Position wurde angelegt.`
        : 'Die Position wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
