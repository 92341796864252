const baseUrl = 'https://bmoffice24api.myweb.solutions';

export const environment = {
  production: false,
  hmr: false,
  baseUrl,
  apiUrl: `${baseUrl}/api`,
  cookieDomain: '.myweb.solutions',
  maxUploadFileSize: 5 * 1024 * 1024
};
