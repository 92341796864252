import {Component, OnInit} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import { combineLatestObject } from 'rxjs-etc';
import { select, Store } from '@ngrx/store';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../models';
import { ApplicationState } from '../../../application-state/store';
import { Go } from '../../../application-state/store/actions/router.actions';
import { StorageSystemsSelectors } from '../../../master-data/store/selectors';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import { StorageSystemsActions } from '../../../master-data/store';
import {ActivatedRoute, Router} from "@angular/router";
import {LeadsService} from "../../services";
import {StockItem} from "../../../warehouse/models";

@Component({
  selector: 'leads-archive',
  styleUrls: ['leads-archive.component.scss'],
  template: `
    <view-heading
      heading="Archivierte Anfragen"
      colLeft="column-3"
    ></view-heading>

    <div class="leads-archive__outer">
      <div class="leads-archive__wrapper wrap">
        <div class="leads-archive grid" *ngIf="dataReady | async as data">
          <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
          <div class="inner column-14">
            <div *ngIf="!data.leads.length" class="m-t--10">
              <h3 class="card__heading">
                Aktuell gibt es keine archivierten Anfragen.
              </h3>
            </div>

            <table
              class="bmo-table bmo-table-bordered bmo-table-heading-uppercase bmo-table-dense bmo-table-rounded"
              style="width: 100%; table-layout: fixed"
              mat-table
              [dataSource]="data.leads"
              *ngIf="data.leads.length"
            >
              <!-- Full Name Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.firstName }} {{ element.lastName }}
                </td>
              </ng-container>

              <!-- nameLine1 "company" Column -->
              <ng-container matColumnDef="nameLine1">
                <th mat-header-cell *matHeaderCellDef>Firma</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameLine1 }}
                </td>
              </ng-container>

              <!-- storageSystem Column -->
              <ng-container matColumnDef="storageSystem">
                <th mat-header-cell *matHeaderCellDef>Speichersystem</th>
                <td mat-cell *matCellDef="let element">
                  {{ data.storageSystemsEntities[element.storageSystem]?.name }}
                </td>
              </ng-container>

              <!-- Updated Column -->
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>Datum der Anfrage</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.createdAt | date: 'medium' }}
                </td>
              </ng-container>

              <!-- Archived At Column -->
              <ng-container matColumnDef="archivedAt">
                <th mat-header-cell *matHeaderCellDef>Archiviert am</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.archivedAt | date: 'medium' }}
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-icon-button
                    (click)="handleChangeView(element['@id'])"
                  >
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <app-paginator-unstyled showFirstLastButtons="true"
                                    [pageSizeOptions]="[5, 10, 15, 20, 25, 30]"
                                    [pageSize]="currentPageSetting.pageSize"
                                    [pageIndex]="currentPageSetting.pageIndex"
                                    [totalItems]="(itemCount$|async)"
                                    (handleUpdatePageOrSize)="updatePageOrSize($event)"
            ></app-paginator-unstyled>
          </div>
        </div>
      </div>
    </div>
  `
})
export class LeadsArchiveComponent implements OnInit {
  displayedColumns: Array<string> = [
    'fullName',
    'nameLine1',
    'storageSystem',
    'createdAt',
    'archivedAt',
    'actions'
  ];
  leads$: Observable<Array<fromModuleModels.Lead>>;
  storageSystemsEntities$: Observable<{
    [iri: string]: fromMasterDataModuleModels.StorageSystem;
  }>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentPageSetting: { pageSize: number, pageIndex: number } = {pageIndex: 0, pageSize: 10};
  items$: BehaviorSubject<Array<StockItem>> = new BehaviorSubject<Array<StockItem>>([]);
  itemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  updatePageOrSize($event: any): void {
    this.currentPageSetting = {...this.currentPageSetting, ...$event};
    this.updateQueryParams({pageSize: $event.pageSize, page: $event.pageIndex});
  }

  constructor(private store$: Store<ApplicationState>,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private backendService: LeadsService,
  ) {}

  get dataReady(): Observable<any> {
    return combineLatestObject({
      leads: this.items$,
      storageSystemsEntities: this.storageSystemsEntities$
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.storageSystemsEntities$ = this.store$.pipe(
      select(StorageSystemsSelectors.sEntities)
    );
    loadIfNotLoaded(
      this.store$,
      StorageSystemsSelectors.isLoaded,
      StorageSystemsActions.ReadStorageSystems({})
    );
  }

  handleChangeView(target: string): void {
    const uuid = target.startsWith('/')
      ? StringsUtility.getUuidFromIri(target)
      : target;
    this.store$.dispatch(Go({ path: ['leads', 'archive', uuid] }));
  }


  updateQueryParams(values: { [key: string]: string }, alwaysLoad = false): void {
    const currentQuery = this.activatedRoute.snapshot.queryParams;
    const query: any = {...currentQuery};
    let changed = false;
    for (const key of Object.keys(values)) {
      if (currentQuery[key] + '' !== values[key] + '') {
        query[key] = values[key];
        changed = true;
      }
    }

    if (changed) {

      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: query,
          queryParamsHandling: ''
        })
        .then(() => {
          this.loadData();
        });
    } else if (alwaysLoad) {
      this.loadData();
    }
  }



  loadData(): void {
    this.isLoading$.next(true);
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const page = parseInt(queryParams.page ? queryParams.page : 0, 10);
    const params: any = {
      itemsPerPage: queryParams.pageSize ? queryParams.pageSize : 15,
      state: 'archived'
    };

    this.backendService.readLeads(page, params.itemsPerPage, params.state)
      .subscribe((data) => {
        this.items$.next(data['hydra:member']);
        if (this.itemCount$.value !== data['hydra:totalItems']) {
          this.itemCount$.next(data['hydra:totalItems']);
        }

        this.isLoading$.next(false);

      }, (error) => {
        console.log(error);
        this.isLoading$.next(false);

      });

  }
}
