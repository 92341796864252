import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { Store } from '@ngrx/store';
import { sortBy } from 'lodash-es';

import { CommissionCredit } from '../../models';
import { ApplicationState } from '../../../application-state/store';
import {CommissionCreditsActions, PayableInvoicesActions} from '../../store';
import { CommissionCreditsSelectors } from '../../store/selectors';
import { RouterActions } from '../../../application-state/store/actions';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SearchAddonDataService } from '../../services/search-addon-data.service';
import { PageEvent } from '@angular/material/paginator';
import { extractIri } from '../../../shared/utilities/objects.utility';
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";
import {CommissionCreditsService} from "../../services";
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-commission-credits',
  styleUrls: ['commission-credits-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="col pt-3">
      <div class="row ">
        <div class="col">
          <app-commission-credits-filter
            *ngIf="searchBoxVisible"
            [items]="commissionCredits$ | async"
            (updateFilter)="handleFilterFormAction($event)"
          ></app-commission-credits-filter>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-paginator-group pos-relative">
            <app-loading-overlay *ngIf="loadingTable$|async"></app-loading-overlay>
            <app-commission-credits-list
              [items]="commissionCredits$ | async"
              (requestGoToCommissionCreditView)="
                handleGoToCommissionCreditView($event)
              "
            ></app-commission-credits-list>

            <app-paginator-unstyled
              [totalItems]="commissionCreditsCount"
              [pageSize]="pageSize"
              [pageIndex]="currentPage - 1"
              [pageSizeOptions]="pageSizeOptions"
              (handleUpdatePageOrSize)="handleUpdatePageOrSize($event)"
              [showFirstLastButtons]="true"
            ></app-paginator-unstyled>
          </div>
        </div>
      </div>
    </div>
  `
})
export class CommissionCreditsViewComponent implements OnInit, OnDestroy {
  commissionCredits$: BehaviorSubject<Array<CommissionCredit>> = new BehaviorSubject<Array<CommissionCredit>>([]);
  currentPage = 1;
  pageSize = 30;
  pageSizeOptions = [5, 10, 15, 20, 25];
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [p: string]: any }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc',
  });
  activeFilter: { [p: string]: string } = {};
  searchBoxVisible = false;
  onDestroy$: Subject<any> = new Subject<any>();
  filterString = '';
  commissionCreditsCount: number;
  subscriptionList: any;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private store$: Store<ApplicationState>,
    private localStorageService: LocalStorageService,
    private searchAddonDataService: SearchAddonDataService,
    private commissionCreditsService: CommissionCreditsService
  ) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.loadCommissionCredits();
    const filters = this.localStorageService.getObjectByKey('filters', 'offers');
    if(filters) {
      this.handleFilterFormAction(filters);
    }

    this.searchAddonDataService
      .getObservable()
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(data => {
        this.handleSearchInput(data.filterString);
        this.cd.detectChanges();
      });

    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      this.updateParams();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.currentPage },
      queryParamsHandling: 'merge'
    });
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.updateParams();
    this.loadCommissionCredits();
    this.updateUrl();
  }
  handleFilterFormAction(filters: any): void {
    const params = {
      issuer: null,
      state: null,
      broker: null,
      'createdAt[strictly_after]': null,
      'createdAt[strictly_before]': null,
    };

    if (filters?.issuer) {
      params['issuer.uuid'] = getUuidFromIri(extractIri(filters.issuer));
    } else {
      params['issuer.uuid'] = null;
    }

    if (filters?.broker) {
      params.broker = getUuidFromIri(extractIri(filters.broker));
    }


    if (filters?.state) {
      params['state'] = filters.state;
    }

    if (filters?.createdAtMin) {
      params['createdAt[strictly_after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[strictly_before]'] = filters.createdAtMax;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });
    this.updateParams();
    this.loadCommissionCredits();
  }
  loadCommissionCredits(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    this.loadingTable$.next(true);
    this.subscriptionList = this.commissionCreditsService.readCommissionCredits(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.loadingTable$.next(false);
        this.commissionCreditsCount = response['hydra:totalItems'];
        this.commissionCredits$.next(this.sortInvoices(response['hydra:member']));
      }, (fail) => {
        this.loadingTable$.next(false);
        console.error(fail);
      });
  }

  sortInvoices(toBeSortedInvoices: CommissionCredit[]): CommissionCredit[] {
    // return sortBy(toBeSortedInvoices, e => e.correctionInvoiceNumber);
    return sortBy(toBeSortedInvoices, (e) => moment(e.bookedAt ? e.bookedAt : null).toDate().getTime() * -1);
  }

  // handleUpdateFilter(filters: { [p: string]: string }): void {
  //   this.searchAddonDataService.updateData({ activeFilter: filters });
  // }

  private updateParams(): void {
    if (this.searchBoxVisible === false) {
      this.params$.next({
        ...this.params$.getValue(),
        'order[createdAt]': 'desc',
        fulltext_search: this.params$.getValue().hasOwnProperty('fulltext_search') ? this.params$.getValue().fulltext_search : ''
      });
    }
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });
  }

  handleSearchInput(term: string): void {
    this.params$.next({
      ...this.params$.getValue(),
      fulltext_search: term ? term : ''
    });

    this.store$.dispatch(
      CommissionCreditsActions.ReadCommissionCredits({
        page: this.currentPage,
        params: this.params$.getValue()
      })
    );
    this.loadCommissionCredits();
  }

  handleGoToCommissionCreditView(iri?: string): void {
    if (iri) {
      this.store$.dispatch(
        RouterActions.Go({ path: ['invoices', 'commission-credits', iri] })
      );
    } else {
      this.store$.dispatch(
        RouterActions.Go({ path: ['invoices', 'commission-credits', 'new'] })
      );
    }
  }
}
