import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleModels from '../../models';
import {CommissionCreditsActions, CorrectionInvoicesActions} from '../actions/';
import {CommissionCreditsService} from '../../services';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {RouterActions} from '../../../application-state/store/actions';
import {NotifierService} from "angular-notifier";

@Injectable()
export class CommissionCreditsEffects {

  constructor(private actions$: Actions, private service: CommissionCreditsService, private notifierService: NotifierService) {
  }

  CreateCommissionCredit$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.CreateCommissionCredit),
    switchMap(({payload}) => {
      return this.service.createCommissionCredit(payload).pipe(
        map((response) => CommissionCreditsActions.CreateCommissionCreditSuccess({response})),
        catchError(response => of(CommissionCreditsActions.CreateCommissionCreditFail({response})))
      );
    })
  ));

  CreateCommissionCreditFromBroker$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.CreateCommissionCreditFromBroker),
    switchMap(({payload}) => {
      return this.service.createCommissionCreditFromBroker(payload).pipe(
        map((response) => CommissionCreditsActions.CreateCommissionCreditFromBrokerSuccess({response})),
        catchError(response => of(CommissionCreditsActions.CreateCommissionCreditFromBrokerFail({response})))
      );
    })
  ));

  ReadPDF$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.ReadPDF),
    switchMap(({iri}) => {
      return this.service.readCommissionCreditAsPdf(iri).pipe(
        map((response: any) => CommissionCreditsActions.ReadPDFSuccess({response})),
        catchError((response: HttpErrorResponse) => of(CommissionCreditsActions.ReadPDFFail({response})))
      );
    })
  ));
  SuccessDownloadAction = createEffect(() => this.actions$.pipe(
    ofType(
      CommissionCreditsActions.ReadPDFSuccess,
    ),
    map(({response: {contentUrl, fileName}}) => {
      const a = document.createElement('a');
      a.href = contentUrl;
      a.download = fileName; // File name Here
      a.click(); // Downloaded file
      a.remove();
    })
  ), {dispatch: false});


  ReadCommissionCredit$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.ReadCommissionCredit),
    switchMap(({iri}) => {
      return this.service.readCommissionCredit(iri).pipe(
        map((response => CommissionCreditsActions.ReadCommissionCreditSuccess({response}))),
        catchError(response => of(CommissionCreditsActions.ReadCommissionCreditFail({response})))
      );
    })
  ));

  ReadCommissionCredits$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.ReadCommissionCredits),
    switchMap(({page, params}) => {
      return this.service.readCommissionCredits(page, params).pipe(
        map((response) => CommissionCreditsActions.ReadCommissionCreditsSuccess({response})),
        catchError(response => of(CommissionCreditsActions.ReadCommissionCreditsFail({response})))
      );
    })
  ));

  ReadCommissionCreditChunk$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.ReadCommissionCreditsChunk),
    switchMap(({uri}) => {
      return this.service.readCommissionCreditsChunk(uri).pipe(
        map((response) => CommissionCreditsActions.ReadCommissionCreditsChunkSuccess({response})),
        catchError(response => of(CommissionCreditsActions.ReadCommissionCreditsChunkFail({response})))
      );
    })
  ));

  SendMail$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.SendMail),
    switchMap(({iri, payload}) => {
      return this.service.sendMail(iri, payload).pipe(
        map((response) => CommissionCreditsActions.SendMailSuccess({response})),
        catchError(response => of(CommissionCreditsActions.SendMailFail({response})))
      );
    })
  ));

  UpdateCommissionCredit$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.UpdateCommissionCredit),
    switchMap(({iri, payload}) => {
      return this.service.updateCommissionCredit(iri, payload).pipe(
        map((response: fromModuleModels.CommissionCredit) => CommissionCreditsActions.UpdateCommissionCreditSuccess({response})),
        catchError((response) => of(CommissionCreditsActions.UpdateCommissionCreditFail({response})))
      );
    })
  ));

  BookCommissionCredit$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.BookCommissionCredit),
    switchMap(({iri}) => {
      return this.service.bookCommissionCredit(iri).pipe(
        map(() => CommissionCreditsActions.BookCommissionCreditSuccess({invoiceIri: iri})),
        catchError((response) => of(CommissionCreditsActions.BookCommissionCreditFail({response})))
      );
    })
  ));

  BookPayOut$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.BookPayOut),
    switchMap(({iri, payload}) => {
      return this.service.bookPayOut(iri, payload).pipe(
        map(response => CommissionCreditsActions.BookPayOutSuccess({response, invoiceIri: iri})),
        catchError((response) => of(CommissionCreditsActions.BookPayOutFail({response})))
      );
    })
  ));

  DeleteCommissionCredit$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.DeleteCommissionCredit),
    switchMap(({iri}) => {
      return this.service.deleteCommissionCredit(iri).pipe(
        map(() => CommissionCreditsActions.DeleteCommissionCreditSuccess({iri})),
        catchError((response) => of(CommissionCreditsActions.DeleteCommissionCreditFail({response})))
      );
    })
  ));

  InvoiceActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CommissionCreditsActions.SendMailSuccess,
      CommissionCreditsActions.CreateCommissionCreditSuccess,
      CommissionCreditsActions.UpdateCommissionCreditSuccess,
      CommissionCreditsActions.BookCommissionCreditSuccess,
      CommissionCreditsActions.DeleteCommissionCreditSuccess,
    ),
    map(({type}) => {

      const textOptions = {
        [CommissionCreditsActions.CREATE_COMMISSION_CREDIT_SUCCESS]: 'Die Provisionsrechnung wurde erstellt.',
        [CommissionCreditsActions.UPDATE_COMMISSION_CREDIT_SUCCESS]: 'Die Provisionsrechnung wurde aktualisiert.',
        [CommissionCreditsActions.BOOK_COMMISSION_CREDIT_SUCCESS]: 'Die Provisionsrechnung wurde gebucht.',
        [CommissionCreditsActions.DELETE_COMMISSION_CREDIT_SUCCESS]: 'Die Provisionsrechnung wurde gelöscht.',
        [CommissionCreditsActions.SEND_MAIL_SUCCESS]: 'Die E-Mail wurde gesendet.',
      };
      this.notifierService.show({type: 'success', message: textOptions[type]});

    })
  ), {dispatch: false});
  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      CommissionCreditsActions.SendMailFail,
      CommissionCreditsActions.CreateCommissionCreditFail,
      CommissionCreditsActions.UpdateCommissionCreditFail,
      CommissionCreditsActions.BookCommissionCreditFail,
      CommissionCreditsActions.DeleteCommissionCreditFail,
    ),
    map(({type, response}) => {
      const errors = response?.error['hydra:description'];

      const textOptions = {
        [CommissionCreditsActions.CREATE_COMMISSION_CREDIT_FAIL]: 'Beim Erstellen der Provisionsrechnung sind Fehler aufgetreten:',
        [CommissionCreditsActions.UPDATE_COMMISSION_CREDIT_FAIL]: 'Beim Aktualisieren der Provisionsrechnung sind Fehler aufgetreten:',
        [CommissionCreditsActions.BOOK_COMMISSION_CREDIT_FAIL]: 'Beim Aktualisieren der Provisionsrechnung sind Fehler aufgetreten:',
        [CommissionCreditsActions.DELETE_COMMISSION_CREDIT_FAIL]: 'Beim Aktualisieren der Provisionsrechnung sind Fehler aufgetreten:',
        [CommissionCreditsActions.SEND_MAIL_FAIL]: 'Beim senden der E-Mail sind Fehler aufgetreten:',
      };
      this.notifierService.show({type: 'error', message: textOptions[type] + errors});

    })
  ), {dispatch: false});

  CreateCommissionCreditFromBrokerSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.CreateCommissionCreditFromBrokerSuccess),
    map(({response}) => RouterActions.Go({path: ['invoices', 'commission-credits', response['@id']]}))
  ));

  DeleteCommissionCreditSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionCreditsActions.DeleteCommissionCreditSuccess),
    map(() => RouterActions.Go({path: ['invoices']}))
  ));
}
