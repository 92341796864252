import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {AbstractApiResponse} from '../../../shared/models';
import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_DOCUMENT_DELIVERY_PROVIDER = '[Invoices Module] Read Document Delivery Provider';
export const READ_DOCUMENT_DELIVERY_PROVIDER_SUCCESS = '[Invoices Module] Read Document Delivery Provider Success';
export const READ_DOCUMENT_DELIVERY_PROVIDER_FAIL = '[Invoices Module] Read Document Delivery Provider Fail';

export const ReadDocumentDeliveryProvider = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDER,
  props<{ iri: string }>()
);

export const ReadDocumentDeliveryProviderSuccess = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDER_SUCCESS,
  props<{ response: any }>()
);

export const ReadDocumentDeliveryProviderFail = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_DOCUMENT_DELIVERY_PROVIDERS = '[Invoices Module] Read Document Delivery Providers';
export const READ_DOCUMENT_DELIVERY_PROVIDERS_SUCCESS = '[Invoices Module] Read Document Delivery Providers Success';
export const READ_DOCUMENT_DELIVERY_PROVIDERS_FAIL = '[Invoices Module] Read Document Delivery Providers Fail';

export const ReadDocumentDeliveryProviders = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDERS
);

export const ReadDocumentDeliveryProvidersSuccess = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDERS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadDocumentDeliveryProvidersFail = createAction(
  READ_DOCUMENT_DELIVERY_PROVIDERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
