// Core Components
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AbstractApiResponse } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class AbstractApiService {
  BASE_URL = environment.baseUrl;
  API_URL = environment.apiUrl;

  requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/ld+json',
      'Accept-Language': 'de_DE', // localStorage.getItem('preferredLanguageLocale'),
      Accept: 'application/ld+json'
    }),
    crossDomain: true,
    withCredentials: true,
    reportProgress: true
  };

  constructor(private httpClient: HttpClient) {}

  /**
   * Creates a new object instance
   * @returns {Observable<any>}
   */
  createObject(
    endpoint: string,
    payload: any,
    endpointFullyQualified?: boolean,
    headers?: HttpHeaders
  ): Observable<any> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    let options = this.requestOptions;

    if (headers) {
      options = {
        ...options,
        headers
      };
    }
    return this.httpClient.post(url, payload, options);
  }

  /**
   * Retrieves a single object instance by ID
   *
   * @param {string} endpoint
   * @param endpointFullyQualified
   * @param headers
   * @returns {any}
   */
  getObject(
    endpoint: string,
    endpointFullyQualified?: boolean,
    headers?: HttpHeaders
  ): Observable<any> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    let options = this.requestOptions;

    if (headers) {
      options = {
        ...options,
        headers
      };
    }
    return this.httpClient.get(url, options);
  }

  /**
   * Updates an object instance
   * @returns {Observable<any>}
   */
  updateObject(
    endpoint: string,
    payload?: {
      [propertyName: string]: any;
    },
    endpointFullyQualified?: boolean
  ): Observable<AbstractApiResponse | any> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }
    return this.httpClient.put(url, payload, this.requestOptions);
  }

  /**
   * @param {string} endpoint
   * @param endpointFullyQualified
   * @returns {Observable<any>}
   */
  deleteObject(
    endpoint: string,
    endpointFullyQualified?: boolean
  ): Observable<any> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }
    return this.httpClient.delete(url, this.requestOptions);
  }

  /**
   * @param endpoint
   * @param payload
   * @param endpointFullyQualified
   */
  patchObject(
    endpoint: string,
    payload: any,
    endpointFullyQualified?: boolean
  ) {
    const requestOptions = {
      ...this.requestOptions,
      headers: new HttpHeaders({
        'Content-Type': 'application/merge-patch+json'
        // 'Accept': 'application/json',
      })
    };

    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      if (endpoint.indexOf(this.BASE_URL) > -1) {
        url = endpoint;
      } else {
        url = `${this.BASE_URL}${endpoint}`;
      }
    }
    return this.httpClient.patch(url, payload, requestOptions);
  }

  /**
   * @param endpoint
   * @param endpointFullyQualified
   */
  getImage(
    endpoint: string,
    endpointFullyQualified?: boolean
  ): Observable<AbstractApiResponse> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    // @ts-ignore
    return this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      withCredentials: true,
      reportProgress: true,
      headers: new HttpHeaders({
        Accept: 'image/png'
      })
    });
  }

  /**
   * @param endpoint
   * @param endpointFullyQualified
   */
  getPdf(
    endpoint: string,
    endpointFullyQualified?: boolean
  ): Observable<AbstractApiResponse> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    // @ts-ignore
    return this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
      withCredentials: true,
      reportProgress: true,
      headers: new HttpHeaders({
        Accept: '*'
      })
    });
  }

  /**
   * @param endpoint
   * @param endpointFullyQualified
   */
  getDownloadJson(
    endpoint: string,
    endpointFullyQualified?: boolean
  ): Observable<AbstractApiResponse | any> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    // @ts-ignore
    return this.httpClient.get(url, {
      ...this.requestOptions,
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    });
  }

  /**
   * @param endpoint
   * @param payload
   * @param endpointFullyQualified
   */
  postFile(
    endpoint: string,
    payload: any,
    endpointFullyQualified?: boolean
  ): Observable<AbstractApiResponse> {
    let url = `${this.API_URL}${endpoint}`;

    if (endpointFullyQualified) {
      url = `${this.BASE_URL}${endpoint}`;
    }

    const req = new HttpRequest<FormData>('POST', url, payload, {
      reportProgress: true,
      withCredentials: true
    });

    // @ts-ignore
    return this.httpClient.request(req);
  }
}
