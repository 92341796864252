import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  getUuidFromIri,
  StringsUtility
} from '../../../shared/utilities/strings.utility';
import { RouterActions } from '../../../application-state/store/actions';
import { Ticket } from '../../../tickets/models';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { OrdersService } from '../../services';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import { OrdersActions } from '../../store';
import { ParameterBag } from '../../../shared/models/ParameterBag.interface';
import {
  extractTypeByIri,
  extractUUID
} from '../../../shared/utilities/objects.utility';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-orders-list-for-widget',
  styleUrls: ['./orders-list-for-widget.component.scss'],
  template: `
    <div class="widget_content" style="position: relative;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <!--      <pre>{{ params$ | async | json }}</pre>-->
      <table class="bmo-table bmo-table-hover table-dense bmo-table-clickable">
        <tbody>
          <tr *ngFor="let order of orders$ | async">
            <td class="p-l--32" style="width: 50px; position: relative;">
              <a
                class="stretched-link"
                [routerLink]="
                  (targetToTicket$ | async)
                    ? ['/tickets', extractUUID(order.ticket)]
                    : ['/orders', extractUUID(order)]
                "
              ></a>
              <span
                class="badge--express"
                *ngIf="order.analysisPriorityMode.name === 'EXPRESS'"
                ><span>Express</span></span
              >
              <div class="circle" [ngStyle]="indicatorStyleFn(order)"></div>
            </td>
            <td style="width: 80px;" class="pos-relative">
              <a
                class="stretched-link"
                [routerLink]="
                  (targetToTicket$ | async)
                    ? ['/tickets', extractUUID(order.ticket)]
                    : ['/orders', extractUUID(order)]
                "
              ></a>
              <span [class.orange]="!order.orderNumber">
                {{ order.orderNumber ? order.orderNumber : '-' }}
              </span>
            </td>
            <td style="width: 100px;" class="pos-relative">
              <a
                class="stretched-link"
                [routerLink]="
                  (targetToTicket$ | async)
                    ? ['/tickets', extractUUID(order.ticket)]
                    : ['/orders', extractUUID(order)]
                "
              ></a>
              <span> {{ order.analysisPriorityMode.name }} </span>
            </td>
            <td class="pos-relative">
              <a
                class="stretched-link"
                [routerLink]="
                  (targetToTicket$ | async)
                    ? ['/tickets', extractUUID(order.ticket)]
                    : ['/orders', extractUUID(order)]
                "
              ></a>
              <span class="badge--vip inline small" *ngIf="order.customer.vip"
                >VIP</span
              >
              <span> {{ order.customer.nameLine1 }} </span>
              <span *ngIf="order.customer.nameLine2">
                [{{ order.customer.nameLine2 }}]
              </span>
            </td>
            <td style="width: 100px;" *ngIf="order.ticket" class="pos-relative">
              <a
                class="stretched-link"
                [routerLink]="['/tickets', extractUUID(order.ticket)]"
              ></a>
              <button mat-button>
                Ticket #{{ order.ticket.ticketNumber }}
              </button>
            </td>
            <td style="width: 170px;" class="pos-relative">
              <a
                class="stretched-link"
                [routerLink]="
                  (targetToTicket$ | async)
                    ? ['/tickets', extractUUID(order.ticket)]
                    : ['/orders', extractUUID(order)]
                "
              ></a>
              <span
                [matTooltip]="order.createdAt | date: 'dd.MM.YYYY'"
                matTooltipPosition="above"
                >{{ order.createdAt | momentDuration }}
              </span>
            </td>
            <!--<pre> {{order|json}} </pre>-->
          </tr>
          <tr *ngIf="(orders$ | async)?.length <= 0">
            <td class="text-center cursor-default">
              <span>Keine Elemente vorhanden</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="rounded-bottom px-2">
      <mat-paginator
        [length]="orderCount"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="showFirstLastButtons"
        (page)="onChangedPage($event)"
      >
      </mat-paginator>
    </div>
  `
})
export class OrdersListForWidgetComponent extends MatPaginatorIntl
  implements OnInit, OnDestroy, OnChanges {
  firstPageLabel = ' Erste Seite';
  nextPageLabel = ' Nächste Seite';
  previousPageLabel = ' Vorherige Seite';
  lastPageLabel = ' Letzte Seite';
  itemsPerPageLabel = ' Zeilen pro Seite';

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() salesperson: string;
  @Input() @Optional() customServiceFnName: string = null;
  @Input() states: Array<string>;
  @Input() indicatorStyleFn: (order: Order) => { [key: string]: string };
  @Input() additionalParams: ParameterBag;
  onDestroy$: Subject<any> = new Subject<any>();
  orders$: BehaviorSubject<Array<Order>> = new BehaviorSubject<Array<Order>>(
    []
  );
  currentPage = 1;
  orderCount = 1;
  pageSize = 10;
  pageSizeOptions = [5, 10, 15, 20, 25];
  showFirstLastButtons = true;
  targetToTicket$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{
    [p: string]: any;
  }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc'
  });
  subscription: any;
  protected readonly extractUUID = extractUUID;
  protected readonly extractTypeByIri = extractTypeByIri;

  constructor(
    private store$: Store<ApplicationState>,
    private orderService: OrdersService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.isTechnicianExternal$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isTechnicianExternal => {
        this.targetToTicket$.next(isTechnicianExternal);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  onChangedPage(pageData: PageEvent): void {
    this.currentPage = pageData.pageIndex + 1;
    this.pageSize = pageData.pageSize;

    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });

    this.loadOrders();
  }

  loadOrders(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.isLoading$.next(true);
    this.subscription = this.orderService[
      this.customServiceFnName ? this.customServiceFnName : 'readOrders'
    ](this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.isLoading$.next(false);

          this.orderCount = response['hydra:totalItems'];
          this.orders$.next(response['hydra:member']);
          this.store$.dispatch(OrdersActions.ReadOrdersSuccess({ response }));
          // this.orders$.next(orders$)
        },
        fail => {
          this.isLoading$.next(false);
          console.error(fail);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newParams = { ...this.params$.getValue() };
    if (changes.salesperson && changes.salesperson.currentValue) {
      newParams['salesperson.uuid'] = getUuidFromIri(
        changes.salesperson.currentValue
      );
    }
    if (changes.states) {
      newParams.state = changes.states.currentValue;
    }
    if (changes.additionalParams) {
      newParams = { ...newParams, ...changes.additionalParams.currentValue };
    }
    this.params$.next(newParams);
    this.loadOrders();
  }

  handleClick($event): void {
    const iri = $event['@id'];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    this.store$.dispatch(RouterActions.Go({ path: ['orders', uuid] }));
  }

  openTicket($event: MouseEvent, ticket: Ticket): void {
    $event.stopPropagation();
    const iri = ticket['@id'];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    this.store$.dispatch(RouterActions.Go({ path: ['tickets', uuid] }));
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `Keine Seiten: ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `Seite ${page + 1}: ${startIndex + 1} - ${endIndex} von ${length}`;
  };
}
