import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import {
  CustomerAccountInvitation,
  CustomerAccountInvitationConfirmation
} from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerAccountInvitationsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  // POST /invitations/customer_accounts
  createCustomerAccountInvitation(
    payload: CustomerAccountInvitation
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<CustomerAccountInvitation>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/invitations/customer_accounts`,
              payload
            )
    );
  }

  // No Security in invitation_customer_account.xml
  // POST /invitations/confirmation/customer_accounts
  createCustomerAccountInvitationConfirmation(
    payload: CustomerAccountInvitationConfirmation
  ): Observable<AbstractApiResponse> {
    return this.apiService.createObject(
      `/invitations/confirmation/customer_accounts`,
      payload
    );
  }

  // GET /invitations/customer_accounts/{uuid}
  readCustomerAccountInvitation(
    iri: string
  ): Observable<CustomerAccountInvitation> {
    return this.rolesService.userHasRoleFilter<CustomerAccountInvitation>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_VIEW',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  // GET /invitations/customer_accounts
  readCustomerAccountInvitations(page = 1): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_LIST',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              `/invitations/customer_accounts?page=${page}`
            )
    );
  }

  // PUT /invitations/customer_accounts/{uuid}
  updateCustomerAccountInvitation(
    iri: string,
    payload: CustomerAccountInvitation
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_CREATE',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  // DELETE /invitations/customer_accounts/{uuid}
  deleteCustomerAccountInvitation(
    iri: string
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      [
        'ROLE_MWS_CUSTOMER_CUSTOMER_DELETE',
        'ROLE_MWS_CUSTOMER_ACCOUNT_ACCOUNT_ADMIN'
      ],
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
