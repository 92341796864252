import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';


import * as fromOrdersModuleModels from '../../models';
import {select, Store} from '@ngrx/store';
import * as OrdersModuleSelectors from '../../store/selectors';
import {Observable, of, Subject, throwError} from 'rxjs';
import {Order, ProofOfDestruction} from '../../models';
import {ProofsOfDestructionSelectors} from '../../store/selectors';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationState} from '../../../application-state/store';
import {OrdersActions, ProofsOfDestructionActions} from '../../store';
import * as OrdersModuleActions from '../../store';
import {ProofOfDestructionDialogComponent} from '../proof-of-destruction-dialog/proof-of-destruction-dialog.component';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {OrdersService} from '../../services';
import {NotifierService} from 'angular-notifier';
import {
  TicketProofOfDestructionEditModalComponent
} from "../../../tickets/components/ticket-proof-of-destruction-edit-modal/ticket-proof-of-destruction-edit-modal.component";
import {WriteEMailDialogComponent} from "../../../shared/components/write-email-dialog/write-email-dialog.component";
import {extractIri} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-pod-status',
  styleUrls: ['pod-status.component.scss'],
  template: `

    <div class="card pos-relative" [class.hidden]="!requiresProofOfDestruction()"
         matTooltip="Für diesen Auftrag ist keine bezahlte Entsorgung vorgesehen."
         [matTooltipDisabled]="requiresProofOfDestruction()">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <div class="card__heading" [class.green]="proofOfDestruction !== null">
        <span>Vernichtungsnachweis</span>
      </div>

      <div class="card__content">
        <p *ngIf="proofOfDestruction?.date">Vernichtung am {{proofOfDestruction.date|momentDateWithTime}}</p>

        <p *ngIf="!!!proofOfDestruction">Vernichtungsnachweis noch nicht erstellt</p>
        <p *ngIf="proofOfDestruction?.date" class="mb-3">Der Vernichtungsnachweis wurde
          am {{ proofOfDestruction?.date | date: 'dd.MM.Y' }} erstellt.</p>
        <p *ngFor="let date of order?.proofOfDestructionSentDates">Der Vernichtungsnachweis wurde <span
          class="text-color-green">{{date|momentDateWithTime}}</span> per Mail verschickt.</p>


        <button mat-flat-button [color]="proofOfDestruction ? 'outline' : 'green'"
                (click)="handleRequestProofOfDestructionForm()" class="ma-2">
          <mat-icon class="m-r--8">description</mat-icon>
          <span>Nachweis {{ proofOfDestruction !== null ? 'bearbeiten' : 'erstellen' }}</span>
        </button>
      </div>

      <div class="card__footer">

        <div class="row">
          <div class="col-6">
            <button *ngIf="proofOfDestruction" mat-flat-button
                    (click)="handleRequestDownloadProofOfDestruction()">
              <mat-icon class="m-r--8">description</mat-icon>
              <span>Download</span>
            </button>
          </div>
          <div class="col-6 text-right">
            <button mat-flat-button [color]="isAlreadySend?'orange':'green'" *ngIf="proofOfDestruction"
                    (click)="handleSendProofOfDestructionEmail()">
              <mat-icon class="m-r--8">mail</mat-icon>
              <span>E-Mail versenden</span>
            </button>
          </div>

        </div>
      </div>

      <div class="blocker"></div>
    </div>

    <!--<pre>{{ proofOfDestruction | json }}</pre>-->
  `
})
export class PodStatusComponent implements OnInit, OnDestroy {

  @Input() order$: Observable<Order>;
  order: Order;
  proofOfDestruction$: Observable<ProofOfDestruction>;
  proofOfDestruction: ProofOfDestruction;
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private dialog: MatDialog,
              private store$: Store<ApplicationState>,
              private os: OrdersService,
              private notifierService: NotifierService) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(ProofsOfDestructionSelectors.isLoading);
    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe((order) => {
      this.order = order;
      this.loadProofOfDestruction(order);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleRequestProofOfDestructionForm(): void {
    this.dialog.open(TicketProofOfDestructionEditModalComponent, {data: {order$: this.order$}, width: '85%'});
  }

  get isAlreadySend(): boolean {
    return this.order.proofOfDestructionSentDates.length > 0
  }

  handleDownloadProofOfDestruction(order: string): void {
  }

  handleRequestDownloadProofOfDestruction(): void {
    this.store$.dispatch(OrdersActions.ReadProofOfDestructionPDF({iri: extractIri(this.order)}));
  }

  requiresProofOfDestruction(): boolean {
    return !!this?.order?.proofOfDestruction;
  }

  handleSendProofOfDestructionEmail(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: {type: 'email_document_deliveries', entity$: this.order$},
    });

  }

  private loadProofOfDestruction(order): void {
    this.proofOfDestruction$ = this.store$.select(ProofsOfDestructionSelectors.selectProofOfDestructionForOrder, {iri: order['@id']});
    this.proofOfDestruction$.pipe(
      takeUntil(this.onDestroy$),
      filter(proofOfDestruction => !!proofOfDestruction)
    ).subscribe(proofOfDestruction => {
      this.proofOfDestruction = proofOfDestruction;
    });
    if (order?.proofOfDestruction) {
      this.store$.dispatch(ProofsOfDestructionActions.ReadProofOfDestruction({iri: order.proofOfDestruction}));
    }


  }
}
