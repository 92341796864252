import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DepartmentAdsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { DepartmentAdsService } from '../../services/department-ads.service';

@Injectable()
export class DepartmentAdsEffects {
  createDepartmentAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentAdsActions.CreateDepartmentAd),
      map(action => action),
      switchMap(({ payload }) => {
        return this.ds.createDepartmentAd(payload).pipe(
          map((data: any) => {
            if (!!data.body) {
              return DepartmentAdsActions.CreateDepartmentAdSuccess({
                response: data.body
              });
            } else {
              return DepartmentAdsActions.CreateDepartmentAdStatus({
                response: data
              });
            }
          }),
          catchError(response =>
            of(DepartmentAdsActions.CreateDepartmentAdFail({ response }))
          )
        );
      })
    )
  );
  getDepartmentAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentAdsActions.ReadDepartmentAd),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.getDepartmentAdInformation(iri).pipe(
          map(response =>
            DepartmentAdsActions.ReadDepartmentAdSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentAdsActions.ReadDepartmentAdFail({ response }))
          )
        );
      })
    )
  );
  getDepartmentAds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentAdsActions.ReadDepartmentAds),
      map(action => action),
      switchMap(() => {
        return this.ds.getDepartmentAds().pipe(
          map(response =>
            DepartmentAdsActions.ReadDepartmentAdsSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentAdsActions.ReadDepartmentAdsFail({ response }))
          )
        );
      })
    )
  );
  // updateDepartmentAd$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DepartmentAdsActions.UpdateDepartmentAd),
  //     map(action => action),
  //     switchMap(payload => {
  //       return this.ds.updateDepartmentAd(payload).pipe(
  //         map(response =>
  //           DepartmentAdsActions.UpdateDepartmentAdSuccess({ response })
  //         ),
  //         catchError(response =>
  //           of(DepartmentAdsActions.UpdateDepartmentAdFail({ response }))
  //         )
  //       );
  //     })
  //   )
  // );
  deleteDepartmentAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentAdsActions.DeleteDepartmentAd),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.deleteDepartmentAd(iri).pipe(
          map(() => DepartmentAdsActions.DeleteDepartmentAdSuccess({ iri })),
          catchError(response =>
            of(DepartmentAdsActions.DeleteDepartmentAdFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentAdsActions.DeleteDepartmentAdFail,
          // DepartmentAdsActions.UpdateDepartmentAdFail,
          DepartmentAdsActions.ReadDepartmentAdsFail,
          DepartmentAdsActions.ReadDepartmentAdFail,
          DepartmentAdsActions.CreateDepartmentAdFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentAdsActions.CreateDepartmentAdSuccess
          // DepartmentAdsActions.UpdateDepartmentAdSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case DepartmentAdsActions.CREATE_DEPARTMENT_AD_SUCCESS:
              message = 'Ein neues Ad wurde angelegt.';
              break;
            // case DepartmentAdsActions.UPDATE_DEPARTMENT_AD_SUCCESS:
            //   message = 'Das Ad wurde aktualisiert.';
            //   break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private ds: DepartmentAdsService,
    private notifierService: NotifierService
  ) {}
}
