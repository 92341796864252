import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_TICKET = '[Tickets Module] Read Ticket';
export const READ_TICKET_SUCCESS = '[Tickets Module] Read Ticket Success';
export const READ_TICKET_FAIL = '[Tickets Module] Read Ticket Fail';

export const ReadTicket = createAction(
  READ_TICKET,
  props<{ iri?: string, id?: string }>()
);

export const ReadTicketSuccess = createAction(
  READ_TICKET_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketFail = createAction(
  READ_TICKET_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_TICKETS = '[Tickets Module] Read Tickets';
export const READ_TICKETS_SUCCESS = '[Tickets Module] Read Tickets Success';
export const READ_TICKETS_FAIL = '[Tickets Module] Read Tickets Fail';

export const ReadTickets = createAction(
  READ_TICKETS,
  props<{ page: number, params?: { [p: string]: string | number | boolean } }>()
);

export const ReadTicketsSuccess = createAction(
  READ_TICKETS_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketsFail = createAction(
  READ_TICKETS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_TICKET_CHUNK = '[Tickets Module] Read Ticket Chunk';
export const READ_TICKET_CHUNK_SUCCESS = '[Tickets Module] Read Ticket Chunk Success';
export const READ_TICKET_CHUNK_FAIL = '[Tickets Module] Read Ticket Chunk Fail';

export const ReadTicketChunk = createAction(
  READ_TICKET_CHUNK,
  props<{ uri: string }>()
);

export const ReadTicketChunkSuccess = createAction(
  READ_TICKET_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadTicketChunkFail = createAction(
  READ_TICKET_CHUNK_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_TICKET = '[Tickets Module] Create Ticket';
export const UPDATE_TICKET_SUCCESS = '[Tickets Module] Create Ticket Success';
export const UPDATE_TICKET_FAIL = '[Tickets Module] Create Ticket Fail';

export const UpdateTicket = createAction(
  UPDATE_TICKET,
  props<{ iri: string, payload: fromModuleModels.Ticket }>()
);

export const UpdateTicketSuccess = createAction(
  UPDATE_TICKET_SUCCESS,
  props<{ response: any }>()
);

export const UpdateTicketFail = createAction(
  UPDATE_TICKET_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const PROVIDE_REMOTE_ACCESS = '[Tickets Module] Provide Remote Access';
export const PROVIDE_REMOTE_ACCESS_SUCCESS = '[Tickets Module] Provide Remote Access Success';
export const PROVIDE_REMOTE_ACCESS_FAIL = '[Tickets Module] Provide Remote Access Fail';

export const ProvideRemoteAccess = createAction(
  PROVIDE_REMOTE_ACCESS,
  props<{ iri: string, payload: { remoteAccess: fromModuleModels.RemoteAccess } }>()
);

export const ProvideRemoteAccessSuccess = createAction(
  PROVIDE_REMOTE_ACCESS_SUCCESS,
  props<{ response: fromModuleModels.Ticket }>()
);

export const ProvideRemoteAccessFail = createAction(
  PROVIDE_REMOTE_ACCESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_TICKET = '[Tickets Module] Delete Ticket';
export const DELETE_TICKET_SUCCESS = '[Tickets Module] Delete Ticket Success';
export const DELETE_TICKET_FAIL = '[Tickets Module] Delete Ticket Fail';

export const DeleteTicket = createAction(
  DELETE_TICKET,
  props<{ iri: string }>()
);

export const DeleteTicketSuccess = createAction(
  DELETE_TICKET_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteTicketFail = createAction(
  DELETE_TICKET_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Reset Current

export const RESET_CURRENT_TICKET = '[Tickets Module] Reset Current Ticket';

export const ResetCurrentTicket = createAction(
  RESET_CURRENT_TICKET
);

export const RESET_IS_LOADED = '[Tickets Module] Reset Is Loaded';

export const ResetIsLoaded = createAction(
  RESET_IS_LOADED
);
