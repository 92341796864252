import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {OffersActions} from '../store';
import {OffersSelectors} from '../store/selectors';

@Injectable()
export class OffersLoadedGuard implements CanActivate {

  constructor(private store$: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return this.readOffersDone().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  readOffersDone(): Observable<boolean> {

    return this.store$.pipe(
      select(OffersSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          const params = {
            'order[createdAt]': 'desc'
          };
          this.store$.dispatch(OffersActions.ReadOffers({page: 1, params}));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
