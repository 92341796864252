import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import * as fromModuleModels from '../../tickets/models';
import {ApplicationState} from '../../application-state/store';
import {TicketsActions} from '../store';
import {TicketsSelectors} from '../store/selectors';

@Injectable()
export class TicketLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const uuid = route.params.uuid;
    const iri = `/api/tickets/${uuid}`;

    return this.currentTicketLoaded(iri).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  currentTicketLoaded(iri: string): Observable<boolean | any> {
    return this.store.pipe(
      select(TicketsSelectors.selectCurrentTicket),
      tap((ticket: fromModuleModels.Ticket) => {
        if (!ticket) {
          this.store.dispatch(TicketsActions.ReadTicket({iri}));
        }
      }),
      filter(ticket => !!ticket),
      take(1)
    );
  }
}
