import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_DEPARTMENT = '[Master Data] Create Department';
export const CREATE_DEPARTMENT_SUCCESS = '[Master Data] Create Department Success';
export const CREATE_DEPARTMENT_FAIL = '[Master Data] Create Department Fail';
export const CreateDepartment = createAction(
  CREATE_DEPARTMENT,
  props<{ payload: fromModuleModels.Department }>()
);
export const CreateDepartmentSuccess = createAction(
  CREATE_DEPARTMENT_SUCCESS,
  props<{ response: fromModuleModels.Department }>()
);
export const CreateDepartmentFail = createAction(
  CREATE_DEPARTMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DEPARTMENTS = '[Master Data] Read Departments';
export const READ_DEPARTMENTS_SUCCESS = '[Master Data] Read Departments Success';
export const READ_DEPARTMENTS_FAIL = '[Master Data] Read Departments Fail';
export const ReadDepartments = createAction(
  READ_DEPARTMENTS
);
export const ReadDepartmentsSuccess = createAction(
  READ_DEPARTMENTS_SUCCESS,
  props<{ response: any }>()
);
export const ReadDepartmentsFail = createAction(
  READ_DEPARTMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_DEPARTMENT = '[Master Data] Read Department';
export const READ_DEPARTMENT_SUCCESS = '[Master Data] Read Department Success';
export const READ_DEPARTMENT_FAIL = '[Master Data] Read Department Fail';
export const ReadDepartment = createAction(
  READ_DEPARTMENT,
  props<{ iri: string }>()
);
export const ReadDepartmentSuccess = createAction(
  READ_DEPARTMENT_SUCCESS,
  props<{ response }>()
);
export const ReadDepartmentFail = createAction(
  READ_DEPARTMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_DEPARTMENT = '[Master Data] Update Department';
export const UPDATE_DEPARTMENT_SUCCESS = '[Master Data] Update Department Success';
export const UPDATE_DEPARTMENT_FAIL = '[Master Data] Update Department Fail';
export const UpdateDepartment = createAction(
  UPDATE_DEPARTMENT,
  props<{ iri: string, payload: fromModuleModels.Department }>()
);
export const UpdateDepartmentSuccess = createAction(
  UPDATE_DEPARTMENT_SUCCESS,
  props<{ response: fromModuleModels.Department }>()
);
export const UpdateDepartmentFail = createAction(
  UPDATE_DEPARTMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_DEPARTMENT = '[Master Data] Delete Department';
export const DELETE_DEPARTMENT_SUCCESS = '[Master Data] Delete Department Success';
export const DELETE_DEPARTMENT_FAIL = '[Master Data] Delete Department Fail';
export const DeleteDepartment = createAction(
  DELETE_DEPARTMENT,
  props<{ iri: string }>()
);
export const DeleteDepartmentSuccess = createAction(
  DELETE_DEPARTMENT_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteDepartmentFail = createAction(
  DELETE_DEPARTMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
