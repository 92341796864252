import * as RemoteAccessReasonsSelectors from './remote-access-reason.selectors';
import * as TicketAttachmentsSelectors from './ticket-attachments.selectors';
import * as TicketCommentTagsSelectors from './ticket-comment-tags.selectors';
import * as TicketCommentsSelectors from './ticket-comments.selectors';
import * as TicketsSelectors from './tickets.selectors';

export {
  RemoteAccessReasonsSelectors,
  TicketAttachmentsSelectors,
  TicketCommentTagsSelectors,
  TicketCommentsSelectors,
  TicketsSelectors,
};
