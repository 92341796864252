import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_PAYMENT_PROCESS = '[Invoices Module] Create Payment Process';
export const CREATE_PAYMENT_PROCESS_SUCCESS = '[Invoices Module] Create Payment Process Success';
export const CREATE_PAYMENT_PROCESS_FAIL = '[Invoices Module] Create Payment Process Fail';

export const CreatePaymentProcess = createAction(
  CREATE_PAYMENT_PROCESS,
  props<{ payload: { customer: string } }>()
);

export const CreatePaymentProcessSuccess = createAction(
  CREATE_PAYMENT_PROCESS_SUCCESS,
  props<{ response: any }>()
);

export const CreatePaymentProcessFail = createAction(
  CREATE_PAYMENT_PROCESS_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_PAYMENT_PROCESS = '[Invoices Module] Read Payment Process';
export const READ_PAYMENT_PROCESS_SUCCESS = '[Invoices Module] Read Payment Process Success';
export const READ_PAYMENT_PROCESS_FAIL = '[Invoices Module] Read Payment Process Fail';

export const ReadPaymentProcess = createAction(
  READ_PAYMENT_PROCESS,
  props<{ iri: string }>()
);

export const ReadPaymentProcessSuccess = createAction(
  READ_PAYMENT_PROCESS_SUCCESS,
  props<{ response: any }>()
);

export const ReadPaymentProcessFail = createAction(
  READ_PAYMENT_PROCESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset


export const RESET_CURRENT_PAYMENT_PROCESS = '[Invoices Module] Reset Current Payment Process';
export const RESET_CURRENT_PAYMENT_PROCESS_SUCCESS = '[Invoices Module] Reset Current Payment Process Success';
export const RESET_CURRENT_PAYMENT_PROCESS_FAIL = '[Invoices Module] Reset Current Payment Process Fail';

export const ResetCurrentPaymentProcess = createAction(
  RESET_CURRENT_PAYMENT_PROCESS
);

export const ResetCurrentPaymentProcessSuccess = createAction(
  RESET_CURRENT_PAYMENT_PROCESS_SUCCESS,
  props<{ response: any }>()
);

export const ResetCurrentPaymentProcessFail = createAction(
  RESET_CURRENT_PAYMENT_PROCESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
