import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {ApplicationState} from '../../application-state/store';
import {OperatingSystemsActions} from '../store';
import {OperatingSystemsSelectors} from '../store/selectors';

@Injectable()
export class OperatingSystemsLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(): Observable<boolean> {

    return this.loadOperatingSystemsDone().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  loadOperatingSystemsDone() {

    return this.store.pipe(
      select(OperatingSystemsSelectors.isLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(OperatingSystemsActions.ReadOperatingSystems());
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
