import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Order} from '../../../orders/models';
import {Lead} from '../../../leads/models';
import {
  Offer,
  CommissionCredit,
  CorrectionInvoice,
  CancellationInvoice, PartialInvoice, PayableInvoice
} from '../../../invoices/models';
import {ApplicationState} from '../../../application-state/store';
import {Store} from '@ngrx/store';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {filter, take, takeUntil, tap} from 'rxjs/operators';
import {DepartmentsActions} from '../../../master-data/store';
import {
  PayableInvoiceNewComponent
} from "../../../invoices/containers/payable-invoice-new/payable-invoice-new.component";

@Component({
  selector: 'app-mail-attachment-list',
  styleUrls: ['./mail-attachment-list.component.scss'],
  template: `
    <div class="attachment" *ngIf="attachments.length > 0">
      <div class="title">Dateianhänge</div>
      <div class="item" *ngFor="let item of attachments">
        <mat-icon class="small">picture_as_pdf</mat-icon>
        {{item}}
      </div>
    </div>
  `
})
export class MailAttachmentListComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() entity$: Observable<Order | Lead | Offer | PayableInvoice | PartialInvoice | CancellationInvoice | CommissionCredit | CorrectionInvoice>;
  onDestroy$: Subject<any> = new Subject<any>();

  attachments: Array<string> = [];

  constructor(
    private store$: Store<ApplicationState>,
  ) {
  }

  ngOnInit(): void {
    // this.addAgbAttachment();
    if (this.type === 'email_document_deliveries') {
      this.addDocumentAttachment();
    }
    if (this.type === 'bindingOrder') {
      this.addBindingOrderAttachment();
    }
    if (this.type === 'proofOfDestruction') {
      this.addProofOfDestructionAttachment();
    }
    if (this.type === 'dataRecoveryProtocol') {
      this.addDataRecoveryProtocolAttachment();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  // private addAgbAttachment(): void {
  //   this.store$.select(DepartmentsSelectors.selectDefaultDepartment).pipe(takeUntil(this.onDestroy$), tap(e => {
  //     if (!e) {
  //       this.store$.dispatch(DepartmentsActions.ReadDepartments());
  //     }
  //   }), filter(e => !!e)).subscribe(d => {
  //     this.attachments.push('AGB-' + d.name.replace(/[^a-zA-Z\d_-]+/g, '-') + '.pdf');
  //   });
  // }

  private addBindingOrderAttachment(): void {
    this.entity$.pipe(takeUntil(this.onDestroy$), filter(e => !!e)).subscribe((d: Order) => {
      this.attachments.push('Auftrag-Nr-' + d.orderNumber + '.pdf');
    });
  }

  private addDataRecoveryProtocolAttachment(): void {
    this.entity$.pipe(takeUntil(this.onDestroy$), filter(e => !!e)).subscribe((d: Order) => {
      this.attachments.push('Datenrettungsprotokoll-Nr-' + '{NR}' + '.pdf');
    });
  }

  private addProofOfDestructionAttachment(): void {
    this.entity$.pipe(takeUntil(this.onDestroy$), filter(e => !!e)).subscribe((d: Order) => {
      this.attachments.push('Vernichtungsnachweis-Nr-' + '{Datum}{NR}' + '.pdf');
    });
  }

  private addDocumentAttachment(): void {
    this.entity$.pipe(take(1)).subscribe(entity => {
      let prefix;
      let ident;
      switch (entity['@type']) {
        case 'Invoice':
          prefix = 'Rechnung';
          if ('invoiceNumber' in entity) {
            ident = entity.invoiceNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        case 'PartialInvoice':
          prefix = 'Abschlagsrechnung';
          if ('invoiceNumber' in entity) {
            ident = entity.invoiceNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        case 'CancellationInvoice':
          prefix = 'Stornorechnung';
          if ('cancellationInvoiceNumber' in entity) {
            ident = entity.cancellationInvoiceNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        case 'CorrectionInvoice':
          prefix = 'Korrekturrechnung';
          if ('correctionInvoiceNumber' in entity) {
            ident = entity.correctionInvoiceNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        case 'Offer':
          prefix = 'Angebot';
          if ('offerNumber' in entity) {
            ident = entity.offerNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        case 'CommissionCredit':
          prefix = 'Provisionsgutschrift';
          if ('commissionCreditNumber' in entity) {
            ident = entity.commissionCreditNumber;
          } else {
            ident = 'ENTWURF';
          }
          break;
        default:
          console.log(entity['@type']);
          break;
      }
      if (prefix && ident) {
        this.attachments.push(prefix + '-' + ident + '.pdf');
      }

    });
  }

}
