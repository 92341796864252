import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedbackRequest } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  FeedbackRequestsActions,
  FeedbackRequestsSelectors
} from '../../store';
import { PageEvent } from '@angular/material/paginator';
import { RouterActions } from '../../../application-state/store/actions';
import { StringsUtility } from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'app-feedback-send-widget',
  templateUrl: './feedback-send-widget.component.html',
  styleUrls: ['./feedback-send-widget.component.scss']
})
export class FeedbackSendWidgetComponent implements OnInit {
  lowValue = 0;
  highValue = 5;
  isLoading$: Observable<boolean>;
  feedbacks$: Observable<FeedbackRequest[]>;

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    // this.isLoading$ = this.store$.select(
    //   FeedbackRequestsSelectors.selectSendIsLoading
    // );
    this.feedbacks$ = this.store$.select(
      FeedbackRequestsSelectors.selectSendFeedbackRequests
    );
    this.loadData();
  }

  loadData(): void {
    this.store$.dispatch(FeedbackRequestsActions.LoadSendFeedbackRequests());
  }

  handleUpdatePageOrSize(event: PageEvent): void {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  handleClick($event: FeedbackRequest): void {
    const iri = $event.relatedInstance;
    const type: string = iri.split('/')[2];
    const uuid = iri.startsWith('/') ? StringsUtility.getUuidFromIri(iri) : iri;
    const uuidCustomerComment = $event.commentIri.startsWith('/')
      ? StringsUtility.getUuidFromIri($event.commentIri)
      : $event.commentIri;
    this.store$.dispatch(
      RouterActions.Go({
        path: [type, uuid],
        query: { target_comment: uuidCustomerComment }
      })
    );
  }
}
