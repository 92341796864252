import {createSelector} from '@ngrx/store';

import {selectMasterDataModuleState, TERMS_AND_CONDITIONS_FEATURE_KEY} from '../reducers';

export const selectTermsAndConditionsState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[TERMS_AND_CONDITIONS_FEATURE_KEY]
);

export const selectTermsAndConditionsEntities = createSelector(
  selectTermsAndConditionsState,
  (state) => state.entities
);

export const sUploadStatus = createSelector(
  selectTermsAndConditionsState,
  (state) => state.uploadStatus
);

export const selectTermsAndConditions = createSelector(
  selectTermsAndConditionsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectTermsAndConditionsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectTermsAndConditionsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectTermsAndConditionsState,
  (state) => state.loaded
);
