import { Component, OnInit } from '@angular/core';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-analysis-widget',
  styleUrls: ['./open-analysis-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header ps-1">
          <span>
            <button mat-icon-button (click)="loadData()">
              <mat-icon>autorenew</mat-icon>
            </button>
            Aktuell offene Analysen</span
          >
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenAnalysisWidgetComponent extends BaseOnDestroyComponent
  implements OnInit {
  states: string[];

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    // Analysis in DR DE
    this.states = [
      'dm_in_engineering_department',
      'dm_ok_engineering_department',
      'assign_analysis',
      'dm_nok_engineering_department',
      'prepare_analysis_dr_de',
      'analysis_in_progress_dr_de',
      // 'analysis_completed_dr_de' ist in "Überprüfung der Analysen"
      'prepare_analysis_dr_de_remote',
      'provide_access_data_analysis_dr_remote'
    ];
  }

  loadData(): void {
    this.states = [...this.states];
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }
}
