import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { FormsService } from '../../../shared/services';
import { Actions, ofType } from '@ngrx/effects';
import { CustomerAddressesActions } from '../../store';

@Component({
  selector: 'customer-account-form',
  styleUrls: ['customer-account-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card m-b--25 mat-elevation-z1">
      <div class="card__heading">
        <div class="grid" style="width: 100%; padding-right: 16px">
          <div class="column-10">
            <ng-content></ng-content>
          </div>
          <div class="column-4 m-ta--2" style="padding-right: 0">
            <mat-checkbox
              [checked]="isInvitation$.getValue()"
              (change)="isInvitation$.next(!isInvitation$.getValue())"
              >Einladen
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="card__content p-a--24" [formGroup]="caf">
        <div class="grid">
          <div class="column-7">
            <div class="mat-form-field">
              <ng-select
                placeholder="Anrede*"
                [items]="salutations$ | async"
                bindLabel="name"
                bindValue="@id"
                [searchable]="false"
                formControlName="salutation"
              ></ng-select>
              <mat-hint align="start" *ngIf="errors?.salutation">{{
                errors.salutation.message
              }}</mat-hint>
            </div>
          </div>

          <div class="column-7">
            <div class="mat-form-field">
              <ng-select
                placeholder="Akademischer Grad"
                [items]="grades$ | async"
                bindLabel="name"
                bindValue="@id"
                [searchable]="false"
                formControlName="grade"
              ></ng-select>
              <mat-hint align="start" *ngIf="errors?.grade">{{
                errors.grade.message
              }}</mat-hint>
            </div>
          </div>

          <div class="column-7">
            <mat-form-field>
              <mat-label>Vorname</mat-label>
              <input
                type="text"
                matInput
                formControlName="firstName"
                required
              />
              <mat-hint align="start" *ngIf="errors && errors.firstName">{{
                errors.firstName.message
              }}</mat-hint>
            </mat-form-field>
          </div>

          <div class="column-7">
            <mat-form-field>
              <mat-label>Nachname</mat-label>
              <input type="text" matInput formControlName="lastName" required />
              <mat-hint align="start" *ngIf="errors && errors.lastName">{{
                errors.lastName.message
              }}</mat-hint>
            </mat-form-field>
          </div>

          <div class="column-14">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input type="email" matInput formControlName="email" required />
              <mat-hint align="start" *ngIf="errors && errors.email">{{
                errors.email.message
              }}</mat-hint>
            </mat-form-field>
          </div>

          <div class="column-14" *ngIf="this.caf.contains('plainPassword')">
            <mat-form-field>
              <mat-label>Passwort</mat-label>
              <input
                type="password"
                matInput
                formControlName="plainPassword"
                #plainPasswordInput
              />

              <mat-icon
                matSuffix
                style="margin-right: 12px; cursor: pointer; opacity: .8"
                (mousedown)="plainPasswordInput.type = 'text'"
                (mouseup)="plainPasswordInput.type = 'password'"
                >visibility
              </mat-icon>
              <mat-hint align="start" *ngIf="errors && errors.plainPassword">{{
                errors.plainPassword.message
              }}</mat-hint>
            </mat-form-field>
          </div>

          <div
            class="column-14"
            *ngIf="this.caf.contains('plainPasswordConfirmation')"
          >
            <mat-form-field>
              <mat-label>Passwort-Bestätigung</mat-label>
              <input
                type="password"
                matInput
                formControlName="plainPasswordConfirmation"
                #plainPasswordConfirmationInput
              />

              <mat-icon
                matSuffix
                style="margin-right: 12px; cursor: pointer; opacity: .8"
                (mousedown)="plainPasswordConfirmationInput.type = 'text'"
                (mouseup)="plainPasswordConfirmationInput.type = 'password'"
                >visibility
              </mat-icon>
              <mat-hint
                align="start"
                *ngIf="errors && errors.plainPasswordConfirmation"
                >{{ errors.plainPasswordConfirmation.message }}</mat-hint
              >
            </mat-form-field>
          </div>

          <!--<div class="column-14">
            <mat-checkbox formControlName="isAccountAdmin">Admin?</mat-checkbox>
          </div>-->

          <div class="column-5" *ngIf="this.caf.contains('enabled')">
            <mat-checkbox formControlName="enabled">Aktiviert</mat-checkbox>
          </div>

          <div class="column-5" *ngIf="this.caf.contains('locked')">
            <mat-checkbox formControlName="locked">Gesperrt</mat-checkbox>
          </div>

          <div class="m-ta--2 column-14">
            <button
              *ngIf="caf.dirty || isMatchingPreset(presets$.getValue()?.type)"
              class="m-r--8"
              (click)="cancelEdit()"
              type="button"
              color="outline"
              mat-flat-button
            >
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <button
              (click)="handleSubmit()"
              [disabled]="caf.invalid"
              mat-flat-button
              color="green"
            >
              <mat-icon>save</mat-icon>
              <span>{{ getSubmitButtonLabel() }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ caf.value | json }}</pre>-->
    <!--<pre>{{ presets$.getValue() | json }}</pre>-->
  `
})
export class CustomerAccountFormComponent implements OnInit, OnDestroy {
  @Input() customer: fromModuleModels.Customer;
  @Input() errors: ErrorsObject;
  @Input() grades$: Observable<Array<fromMasterDataModuleModels.Salutation>>;
  @Input() presets$: BehaviorSubject<{
    type: string;
    presets: fromModuleModels.CustomerAccount;
  }>;
  @Input() salutations$: Observable<Array<fromMasterDataModuleModels.Grade>>;

  @Output() requestCreateCustomerAccount: EventEmitter<
    fromModuleModels.CustomerAccount
  > = new EventEmitter();
  @Output() requestCreateCustomerAccountInvitation: EventEmitter<
    fromModuleModels.CustomerAccount
  > = new EventEmitter();
  @Output() requestUpdateCustomerAccount: EventEmitter<{
    iri: string;
    payload: fromModuleModels.CustomerAccount;
  }> = new EventEmitter();

  allowedPresets = ['InvitationCustomerAccount', 'CustomerAccount'];

  caf: FormGroup;
  isInvitation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private actions$: Actions
  ) {}

  isMatchingPreset(type?: string): boolean {
    if (!type) {
      return false;
    }
    return this.allowedPresets.includes(type);
  }

  ngOnInit(): void {
    this.isInvitation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isInvitation => {
        if (undefined === this.caf) {
          this.initForm();
        }

        if (isInvitation) {
          this.removeNonInvitationFields();
        } else {
          this.addNonInvitationFields();
        }
      });
    this.actions$
      .pipe(
        ofType(CustomerAddressesActions.UpdateCustomerAddressFail),
        takeUntil(this.onDestroy$),
        takeUntil(
          this.actions$.pipe(
            ofType(CustomerAddressesActions.UpdateCustomerAddressSuccess)
          )
        ),
        take(1)
      )
      .subscribe(fail => {
        this.fs.mergeErrorResponseIntoForm(fail, this.caf);
      });
    this.presets$.pipe(takeUntil(this.onDestroy$)).subscribe(presets => {
      if (undefined === this.caf || null === presets) {
        this.initForm();
        this.caf.markAsUntouched();
      } else {
        if (this.isMatchingPreset(presets?.type)) {
          if (presets?.type === 'InvitationCustomerAccount') {
            this.isInvitation$.next(true);
          }

          this.fs.patchForm(this.caf, presets.presets);
        }
      }
    });
  }

  getSubmitButtonLabel(): string {
    return !!this.presets$?.getValue() && this.presets$?.getValue()?.presets
      ? 'Aktualisieren'
      : this.isInvitation$.getValue()
      ? 'Einladen'
      : 'Anlegen';
  }

  handleSubmit(): void {
    const payload: fromModuleModels.CustomerAccount = this.caf.value;

    if (
      this.presets$?.getValue() &&
      this.presets$?.getValue().type === 'CustomerAccount'
    ) {
      // manually cast values to empty string if null; API does not allow for nullable values in this particular case
      if (payload.salutation === null) {
        delete payload.salutation;
      }

      if (payload.grade === null) {
        delete payload.grade;
      }
      this.requestUpdateCustomerAccount.emit({
        iri: this.presets$.getValue().presets['@id'],
        payload
      });
    } else {
      if (this.isInvitation$.getValue()) {
        this.requestCreateCustomerAccountInvitation.emit(payload);
      } else {
        // manually cast values to empty string if null; API does not allow for nullable values in this particular case
        if (payload.salutation === null) {
          delete payload.salutation;
        }

        if (payload.grade === null) {
          delete payload.grade;
        }
        this.requestCreateCustomerAccount.emit(payload);
      }
    }
  }

  initForm(): void {
    this.caf = this.fb.group({
      firstName: this.fb.control(null, [Validators.required]),
      middleName: this.fb.control(''),
      lastName: this.fb.control(null, [Validators.required]),
      customer: this.fb.control(this.customer['@id'], [Validators.required]),
      salutation: this.fb.control(null),
      grade: this.fb.control(null),
      isAccountAdmin: this.fb.control(false),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      plainPassword: this.fb.control(''),
      plainPasswordConfirmation: this.fb.control(''),
      enabled: this.fb.control(true),
      locked: this.fb.control(false)
    });
  }

  removeNonInvitationFields(): void {
    this.caf.removeControl('plainPassword');
    this.caf.removeControl('plainPasswordConfirmation');
    this.caf.removeControl('enabled');
    this.caf.removeControl('locked');
  }

  addNonInvitationFields(): void {
    this.caf.addControl(
      'plainPassword',
      this.fb.control(null, [Validators.required])
    );
    this.caf.addControl(
      'plainPasswordConfirmation',
      this.fb.control(null, [Validators.required])
    );
    this.caf.addControl('enabled', this.fb.control(true));
    this.caf.addControl('locked', this.fb.control(false));
  }

  cancelEdit(): void {
    this.initForm();
    this.addNonInvitationFields();
    this.presets$.next(null);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
