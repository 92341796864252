import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as fromModuleModels from '../../models';

@Component({
  selector: 'app-administrators-table',
  styleUrls: [],
  template: `

    <div class="admins-table__outer   m-b--24">

      <div class="admins-table__wrapper wrap">

        <div class="admins-table grid">

          <div class="column-14">

            <table class="bmo-table bmo-table-hover bmo-table-heading-uppercase bmo-table-rounded bmo-table-rounded"
                   mat-table [dataSource]="admins">

              <!-- firstName Column -->
              <ng-container matColumnDef="dot">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div class="circle" [class.green]="!!element.enabled" *ngIf="!element.locked"></div>
                  <mat-icon *ngIf="element.locked" class="text-color-red">lock</mat-icon>

                </td>
              </ng-container>

              <!-- firstName Column -->
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef>Vorname</th>
                <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
              </ng-container>

              <!-- lastName Column -->
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef>Nachname</th>
                <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
              </ng-container>

              <!-- username Column -->
              <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>Nutzername</th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>E-Mail-Adresse</th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
              </ng-container>

              <!-- lastLogin Column -->
              <ng-container matColumnDef="lastLogin">
                <th mat-header-cell *matHeaderCellDef>Letzter Login</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.lastLogin ? (element.lastLogin | date:'medium') : 'nie' }}</td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">

                  <button mat-icon-button (click)="handleClick('update', element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button (click)="handleClick('delete', element)">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.locked]="row.isLocked"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ admins | json }}</pre>-->
  `
})
export class AdministratorsTableComponent {

  @Input() admins: Array<fromModuleModels.Administrator>;

  @Output() requestUpdateAdministratorForm: EventEmitter<any> = new EventEmitter();
  @Output() requestDeleteAdministrator: EventEmitter<any> = new EventEmitter();

  displayedColumns: Array<string> = ['dot', 'firstName', 'lastName', 'username', 'email', 'lastLogin', 'actions'];

  handleClick(action: string, admin: fromModuleModels.Administrator) {

    action === 'delete' ? this.requestDeleteAdministrator.emit(admin['@id']) : this.requestUpdateAdministratorForm.emit(admin);
  }
}
