import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { CustomerCooperation } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerCooperationsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomerCooperation(
    payload: CustomerCooperation
  ): Observable<CustomerCooperation> {
    return this.rolesService.userHasRoleFilter<CustomerCooperation>(
      'ROLE_MWS_CUSTOMER_COOPERATION_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/customer_cooperations`, payload)
    );
  }

  getCustomerCooperation(iri: string): Observable<CustomerCooperation> {
    return this.rolesService.userHasRoleFilter<CustomerCooperation>(
      'ROLE_MWS_CUSTOMER_COOPERATION_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getCustomerCooperations(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CUSTOMER_COOPERATION_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/customer_cooperations`)
    );
  }

  updateCustomerCooperation({ iri, payload }): Observable<CustomerCooperation> {
    return this.rolesService.userHasRoleFilter<CustomerCooperation>(
      'ROLE_MWS_CUSTOMER_COOPERATION_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomerCooperation(iri: string): Observable<CustomerCooperation> {
    return this.rolesService.userHasRoleFilter<CustomerCooperation>(
      'ROLE_MWS_CUSTOMER_COOPERATION_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
