import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';

import {ApplicationState} from '../../../application-state/store';
import {OrdersSelectors, ProofsOfDestructionSelectors} from '../../store/selectors';
import {Order, ProofOfDestruction} from '../../models';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {TransitionsSelectors} from '../../../application-state/store/selectors';

@Component({
  selector: 'app-proof-of-destruction-box',
  styleUrls: ['proof-of-destruction-box.component.scss'],
  template: `

    <div class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

      <app-proof-of-destruction-form
        [inline]="true"
        [order$]="order$"
        [proofOfDestruction$]="proofOfDestruction$"
      ></app-proof-of-destruction-form>
    </div>

    <!--<pre>{{ analysisResultCategories$ | async | json }}</pre>-->
    <!--<pre>{{ analysisResultTemplates$ | async | json }}</pre>-->
  `
})
export class ProofOfDestructionBoxComponent implements OnInit, OnDestroy {

  isLoading$: Observable<boolean>;

  @Input() order$: Observable<Order>;
  proofOfDestruction$: Observable<ProofOfDestruction>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private store$: Store<ApplicationState>, private actions$: Actions) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(TransitionsSelectors.isLoading),
      this.store$.select(ProofsOfDestructionSelectors.isLoading),
      this.store$.select(OrdersSelectors.isLoading),
    ]);

    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe(order => {
      this.proofOfDestruction$ = this.store$.select(ProofsOfDestructionSelectors.selectProofOfDestructionForOrder, {iri: order['@id']});
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
