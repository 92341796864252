import { OrderViewComponent } from './order-view/order-view.component';
import { OrdersViewComponent } from './orders-view/orders-view.component';
import { SalesViewComponent } from './sales-view/sales-view.component';

export const AllContainers = [
  OrderViewComponent,
  OrdersViewComponent,
  SalesViewComponent
];

export * from './order-view/order-view.component';
export * from './orders-view/orders-view.component';
export * from './sales-view/sales-view.component';
