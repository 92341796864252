import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';
import {Product} from '../../models';

@Component({
  selector: 'analysis-result-category-form',
  styleUrls: ['analysis-result-category-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Analysevorlagen-Kategorie bearbeiten' : 'Neue Analysevorlagen-Kategorie anlegen' }}</span>
      </div>

      <div class="card__content p-a--24" [formGroup]="arcf">

        <div class="grid">

          <mat-form-field class="column-14">
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name" required>
            <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
          </mat-form-field>

          <div class="column-14">
            <div class="m-ta--2 ">
              <button *ngIf="presets$.getValue() || arcf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                      color="outline" mat-flat-button>
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button [disabled]="arcf.invalid || arcf.untouched" (click)="handleSubmit()" mat-flat-button
                      color="green">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class AnalysisResultCategoryFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromModuleModels.AnalysisResultCategory>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromModuleModels.AnalysisResultCategory,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.AnalysisResultCategory,
    entity: string
  }> = new EventEmitter();

  arcf: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {
      this.initForm();
      this.fs.patchForm(this.arcf, preset);
      this.arcf.markAsUntouched();
    });
  }

  initForm(): void {
    this.arcf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)])
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: Product = this.arcf.value;

    // note: "s" is being appended in parent component
    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'AnalysisResultCategory'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'AnalysisResultCategory'});
  }
}
