import {createAction, props} from '@ngrx/store';

import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

export const LOAD_CUSTOMER_PARTNER_STATUSES = '[Customers] Load Customer Partner Statuses';
export const LOAD_CUSTOMER_PARTNER_STATUSES_SUCCESS = '[Customers] Load Customer Partner Statuses Success';
export const LOAD_CUSTOMER_PARTNER_STATUSES_FAIL = '[Customers] Load Customer Partner Statuses Fail';

export const LoadCustomerPartnerStatuses = createAction(
  LOAD_CUSTOMER_PARTNER_STATUSES
);

export const LoadCustomerPartnerStatusesSuccess = createAction(
  LOAD_CUSTOMER_PARTNER_STATUSES_SUCCESS,
  props<{ response: HttpResponse<any> }>()
);

export const LoadCustomerPartnerStatusesFail = createAction(
  LOAD_CUSTOMER_PARTNER_STATUSES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
