import {Component, Input} from '@angular/core';

import * as fromOrdersModuleModels from '../../models';

@Component({
  selector: 'app-data-recovery-result',
  styleUrls: ['data-recovery-result.component.scss'],
  template: `
    <div class="card">
      <div
        class="card__heading"
        [class.green]="dataRecoveryResult?.recoveredDataPercentage > 0"
      >
        <span>Ergebnis Datenrettung</span>
      </div>

      <div class="card__content p-a--24">
        <p
          *ngIf="
            !dataRecoveryResult ||
            dataRecoveryResult?.recoveredDataPercentage == null
          "
        >
          Noch keine Ergebnisse
        </p>

        <dl
          class="list--dl grid"
          *ngIf="
            dataRecoveryResult &&
            dataRecoveryResult?.recoveredDataPercentage !== null
          "
        >
          <dt class="column-5">Datenrettung erfolgreich?</dt>
          <dd
            class="column-9"
            [class.text-color-green]="dataRecoveryResult?.isSuccessful === true"
            [class.text-color-red]="dataRecoveryResult?.isSuccessful === false"
          >
            {{
              dataRecoveryResult?.isSuccessful === true
                ? 'Ja'
                : dataRecoveryResult?.isSuccessful === true
                  ? 'Nein'
                  : 'unbekannt'
            }}
          </dd>

          <dt class="column-5">Datenrettung %:</dt>
          <dd class="column-9">
            {{ dataRecoveryResult.recoveredDataPercentage }}
          </dd>

          <dt class="column-5">Kommentar:</dt>
          <dd class="column-9">{{ dataRecoveryResult.comment }}</dd>
        </dl>
      </div>
    </div>

    <!--<pre>{{ dataRecoveryResult | json }}</pre>-->
  `
})
export class DataRecoveryResultComponent {
  @Input() dataRecoveryResult: fromOrdersModuleModels.DataRecoveryResult;
}
