import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Observable} from 'rxjs';

import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromInvoicesModuleModels from '../../../invoices/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {BaseOnDestroyComponent} from "../../../shared/injectables/BaseOnDestroy.component";
import {FileInputComponent} from "../../../shared/components/file-input/file-input.component";
import {AcceptedOffersService} from "../../services";
import {OffersActions} from "../../store";
import {Store} from "@ngrx/store";
import {ApplicationState} from "../../../application-state/store";
import {Actions, ofType} from "@ngrx/effects";
import {take, takeUntil} from "rxjs/operators";
import {extractIri} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-offer-details',
  styleUrls: ['offer-details.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading" [class.green]="!!offer?.confirmedAt">
        <span>Angebotsdetails</span>
      </div>

      <div class="card__content">

        <div class="grid" [formGroup]="form">

          <mat-form-field class="column-7">
            <mat-label>Angebot gültig bis</mat-label>

            <input type="date" matInput formControlName="validUntil">
            <mat-error>
              <app-form-error [fieldName]="'validUntil'" [formGroup]="form"></app-form-error>
            </mat-error>

          </mat-form-field>

          <mat-form-field class="column-7">
            <mat-label>Angebot bestätigt am</mat-label>

            <input type="date" [disabled]="!!!offer" matInput formControlName="confirmedAt">
            <mat-error>
              <app-form-error [fieldName]="'confirmedAt'" [formGroup]="form"></app-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column-7 off-7 m-ta--2">

          <button mat-flat-button
                  color="green"
                  [disabled]="form.invalid"
                  (click)="handleRequestRequestCreateOrUpdateOffer(offer)">
            <mat-icon class="m-r--8">{{ offer ? 'update' : 'description' }}</mat-icon>
            <span>{{ offer ? 'Aktualisieren' : 'Speichern' }}</span>
          </button>
        </div>
      </div>

      <div class="card__footer">

        <div class="grid">
          <div class="column-7">

            <button
              *ngIf="!!offer"
              mat-flat-button
              [disabled]="!!!offer?.acceptedOffer"
              (click)="handleRequestDownloadAcceptedOffer(offer)"
              style="color: #bbb">
              <mat-icon class="m-r--8">description</mat-icon>
              <span>Download</span>
            </button>
          </div>

          <div class="column-7 m-ta--2">

            <button
              *ngIf="!!offer"
              [color]="!!form.get('confirmedAt').value ? 'blue' : ''"
              mat-button
              class="button-wrapper"
              [class.loading]="loading"
              [disabled]="!!!form.get('confirmedAt').value"
              (click)="handleRequestUploadAcceptedOffer()"
              style="color: #bbb">

              <ng-container *ngIf="!loading">
                <mat-icon class="m-r--8">upload</mat-icon>
                <span>Upload</span>
              </ng-container>

              <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
              <div style="display: none">
                <app-file-input
                  actionNameCreate="createAcceptedOffer"
                  [allowDeletion]="false"
                  propertyName="acceptedOffer"
                  #fic
                  maxFiles="1"
                  label="Angebotsbestätigung"
                  [service]="aos"
                  (fileInputChanged)="handleFileInputChanged()"
                  (requestPatchParentForm)="handleUpdateOfferAfterAcceptedOfferUpload(offer['@id'], $event);"
                ></app-file-input>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ formGroup.value | json }}</pre>-->
    <!--<pre>{{ offer | json }}</pre>-->
  `
})
export class OfferDetailsComponent extends BaseOnDestroyComponent implements OnInit, OnChanges {
  @ViewChild(FileInputComponent) fic!: FileInputComponent;

  @Input() errors: ErrorsObject;
  form: FormGroup;
  @Input() loading: boolean;
  @Input() offer: fromInvoicesModuleModels.Offer | null;
  @Input() partners: Array<fromCustomersModuleModels.Customer>;
  @Input() taxRates$: Observable<Array<fromMasterDataModuleModels.Tax>>;

  @Output() requestDownloadAcceptedOffer: EventEmitter<string> = new EventEmitter();
  @Output() requestBookOffer: EventEmitter<string> = new EventEmitter();
  @Output() requestCreateOffer: EventEmitter<string> = new EventEmitter();
  @Output() requestUpdateOffer: EventEmitter<string> = new EventEmitter();
  @Output() requestUploadAcceptedOffer: EventEmitter<void> = new EventEmitter();

  get now(): Date {
    return new Date();
  }

  constructor(
    public aos: AcceptedOffersService,
    private fb: FormBuilder,
    private actions$: Actions,
    private store$: Store<ApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      validUntil: this.fb.control(null, Validators.required),
      confirmedAt: this.fb.control(null),
      acceptedOffer: this.fb.control(null),
    });
    this.patchFormByOffer(this.offer);
  }

  handleUpdateOfferAfterAcceptedOfferUpload(offer: string, _payload?: { acceptedOffer: string }): void {

    this.loading = true;

    const payload = {
      iri: offer,
      payload: {
        ..._payload,
        confirmedAt: this.form.get('confirmedAt').value
      }
    };

    this.store$.dispatch(OffersActions.UpdateOffer(payload));
    this.actions$.pipe(ofType(OffersActions.UpdateOfferSuccess, OffersActions.UpdateOfferFail), takeUntil(this.onDestroy$), take(1))
      .subscribe(() => {
        this.loading = false;
      });
  }

  handleRequestDownloadAcceptedOffer(offer: fromInvoicesModuleModels.Offer): void {

    this.requestDownloadAcceptedOffer.emit(offer.acceptedOffer);
  }

  handleRequestRequestCreateOrUpdateOffer(offer?: fromInvoicesModuleModels.Offer): void {
    if (!offer) {
      this.requestCreateOffer.emit();
    } else {
      const payload = {
        iri: extractIri(offer),
        payload: {
          confirmedAt: this.form.get('confirmedAt').value
        }
      };

      this.store$.dispatch(OffersActions.UpdateOffer(payload));
      this.actions$.pipe(ofType(OffersActions.UpdateOfferSuccess, OffersActions.UpdateOfferFail), takeUntil(this.onDestroy$), take(1))
        .subscribe(() => {
          this.loading = false;
        });

    }
  }

  handleRequestUploadAcceptedOffer(): void {
    this.fic.fileInput.nativeElement.click();
  }

  handleFileInputChanged(): void {
    this.fic.handleUpload();
    this.loading = true;
  }

  patchFormByOffer(offer: any): void {
    const now = new Date().toISOString().substr(0, 10);
    this.form?.patchValue({
      validUntil: offer?.validUntil?.substr(0, 10),
      confirmedAt: offer?.confirmedAt?.substr(0, 10) || now,
      acceptedOffer: offer?.acceptedOffer
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offer) {
      const val = changes.offer.currentValue;
      this.patchFormByOffer(val);
    }
  }
}
