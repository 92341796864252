import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerInvoicesActions} from '../actions/';
import * as fromCustomersModuleModels from '../../services';

@Injectable()
export class CustomerInvoicesEffects {

  constructor(private actions$: Actions, private cs: fromCustomersModuleModels.CustomersService) {
  }


  ReadCustomerInvoices$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerInvoicesActions.ReadCustomerInvoices),
    map(action => action),
    switchMap(({iri}) => {
      return this.cs.readCustomerInvoices(iri).pipe(
        map((response: any) => CustomerInvoicesActions.ReadCustomerInvoicesSuccess({response})),
        catchError(response => of(CustomerInvoicesActions.ReadCustomerInvoicesFail({response})))
      );
    })
  ));
}
