import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { TicketsActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { TicketList } from '../../models/ticket-list.interface';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromModuleModels.Ticket;
  entities: { [iri: string]: TicketList };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  current: null,
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    TicketsActions.ReadTicket,
    TicketsActions.ReadTickets,
    TicketsActions.UpdateTicket,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(TicketsActions.ReadTicketSuccess, (state, { response }) => {
    return {
      ...state,
      entities: mergeEntities([response], null, state.entities),
      loading: false
    };
  }),
  on(
    TicketsActions.UpdateTicketSuccess,
    TicketsActions.ProvideRemoteAccessSuccess,
    (state, { response }) => {
      return {
        ...state,
        entities: mergeEntities([response], null, state.entities),
        errors: {},
        loading: false
      };
    }
  ),
  on(
    TicketsActions.ReadTicketsSuccess,
    TicketsActions.ReadTicketChunkSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const totalItems = response['hydra:totalItems'];

      return {
        ...state,
        entities: mergeEntities(items, null, state.entities),
        totalItems,
        errors: {},
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    TicketsActions.ReadTicketFail,
    TicketsActions.ReadTicketsFail,
    TicketsActions.ReadTicketChunkFail,
    TicketsActions.UpdateTicketFail,
    TicketsActions.DeleteTicketFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(TicketsActions.ResetCurrentTicket, state => {
    return {
      ...state,
      errors: {},
      current: null
    };
  }),
  on(TicketsActions.ResetIsLoaded, state => {
    return {
      ...state,
      errors: {},
      loaded: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
