import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {FormsService} from '../../../shared/services';
import {FileSystem, OperatingSystem, StorageSystem, Symptom} from '../../../master-data/models';
import {
  FileSystemsSelectors,
  OperatingSystemsSelectors,
  ReplacementDataMediumSourcesSelectors, StorageSystemsSelectors, SymptomsSelectors
} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {
  FileSystemsActions,
  OperatingSystemsActions, StorageSystemsActions, SymptomsActions
} from '../../../master-data/store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Actions, ofType} from '@ngrx/effects';
import {Order} from '../../models';
import {OrdersActions} from '../../store';
import * as moment from 'moment';

@Component({
  selector: 'app-order-storage-system-form',
  styleUrls: ['order-storage-system-form.component.scss'],
  templateUrl: 'order-storage-system-form.component.html'
})
export class OrderStorageSystemFormComponent implements OnInit {

  @Input() order: Order;
  fileSystems$: Observable<Array<FileSystem>>;
  fileSystemsIsLoading$: Observable<boolean>;
  operatingSystems$: Observable<Array<OperatingSystem>>;
  operatingSystemsIsLoading$: Observable<boolean>;
  symptoms$: Observable<Array<Symptom>>;
  symptomsIsLoading$: Observable<boolean>;
  storageSystemsS: Observable<Array<StorageSystem>>;
  storageSystemsIsLoading$: Observable<boolean>;

  @Output() requestUpdateOrder: EventEmitter<{ iri: string, payload: Order }> = new EventEmitter();

  ossf: FormGroup;
  onDestroy$: Subject<any> = new Subject();

  constructor(private fb: FormBuilder,
              private fs: FormsService,
              private store$: Store<ApplicationState>,
              public actions$: Actions) {
  }

  ngOnInit(): void {
    this.initForm();
    const formData: any = {...this.order};
    if (formData.dataLossAt) {
      formData.dataLossAt = this.isValidDateFormat(formData.dataLossAt) ? moment(formData.dataLossAt).toISOString(true).substring(0, 10) : formData.dataLossAt;
    }
    if (formData.storageSystem && formData.storageSystem['@id']) {
      formData.storageSystem = formData.storageSystem['@id'];
    }
    if (formData.dataLossDetectedAt) {
      formData.dataLossDetectedAt = this.isValidDateFormat(formData.dataLossDetectedAt) ? moment(formData.dataLossDetectedAt).toISOString(true).substring(0, 10) : formData.dataLossDetectedAt;
    }
    this.fs.patchForm(this.ossf, formData);
    this.ossf.markAsUntouched();

    this.loadStorageSystems();
    this.loadFileSystems();
    this.loadOperatingSystems();
    this.loadSymptoms();

    this.actions$.pipe(ofType(OrdersActions.UpdateOrderFail)).subscribe((fail) => {
      if (fail?.response?.error?.violations) {
        this.fs.mergeViolationsIntoForm(fail.response.error.violations, this.ossf);
      }
    });

  }

  initForm(): void {
    this.ossf = this.fb.group({
      storageSystem: this.fb.control(null, Validators.required),
      operatingSystem: this.fb.control(null),
      fileSystem: this.fb.control(null, Validators.required),
      futureFileSystem: this.fb.control(null),
      dataLossAt: this.fb.control(null),
      dataLossDetectedAt: this.fb.control(null),
      storageManufacturerSerialNumber: this.fb.control(null),
      symptom: this.fb.control(null),
      host: this.fb.control(''),
      pinPassword: this.fb.control(''),
      history: this.fb.control(''),
      stepsAlreadyTaken: this.fb.control(''),
      requiredData: this.fb.control(''),
    });
  }

  handleSubmit(): void {
    this.requestUpdateOrder.emit({iri: this.order['@id'], payload: this.ossf.value});
  }

  private loadFileSystems(): void {
    this.fileSystems$ = this.store$.select(FileSystemsSelectors.selectFileSystems);
    this.fileSystemsIsLoading$ = this.store$.select(FileSystemsSelectors.isLoading);
    loadIfNotLoaded(this.store$, FileSystemsSelectors.isLoaded, FileSystemsActions.ReadFileSystems());
  }


  private loadOperatingSystems(): void {
    this.operatingSystems$ = this.store$.select(OperatingSystemsSelectors.selectOperatingSystems);
    this.operatingSystemsIsLoading$ = this.store$.select(OperatingSystemsSelectors.isLoading);
    loadIfNotLoaded(this.store$, OperatingSystemsSelectors.isLoaded, OperatingSystemsActions.ReadOperatingSystems());

  }

  private loadSymptoms(): void {
    this.symptoms$ = this.store$.select(SymptomsSelectors.selectSymptoms);
    this.symptomsIsLoading$ = this.store$.select(SymptomsSelectors.isLoading);
    loadIfNotLoaded(this.store$, SymptomsSelectors.isLoaded, SymptomsActions.ReadSymptoms());

  }

  private loadStorageSystems(): void {
    this.storageSystemsS = this.store$.select(StorageSystemsSelectors.sOrderedList);
    this.storageSystemsIsLoading$ = this.store$.select(StorageSystemsSelectors.isLoading);
    loadIfNotLoaded(this.store$, StorageSystemsSelectors.isLoaded, StorageSystemsActions.ReadStorageSystems({}));
  }

  private isValidDateFormat(input: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return regex.test(input);
  }
}
