import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Actions, ofType} from '@ngrx/effects';
import {Order} from '../../models';
import * as OrdersModuleActions from '../../store';
import * as fromOrdersModuleModels from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {FormsService} from '../../../shared/services';
import {NotifierService} from 'angular-notifier';
import {OrdersActions} from '../../store';
import {Observable} from 'rxjs';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors} from '../../store/selectors';
import {
  DisposalTypesSelectors, FileSystemsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors, SymptomsSelectors
} from '../../../master-data/store/selectors';
import {Administrator} from "../../../administrators/models";

@Component({
  selector: 'app-order-details-edit-dialog',
  styleUrls: ['./order-details-edit-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Allgemeine Daten bearbeiten</h2>
    </app-dialog-header>

    <div mat-dialog-content class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <app-order-form
        [order$]="data.order$"
        [administrators]="data.administrators"
        (requestUpdateOrder)="handleUpdateOrder($event)"
        (requestUpdateLocation)="handleUpdateLocation($event)"
      ></app-order-form>
    </div>
  `
})
export class OrderDetailsEditDialogComponent implements OnInit {
  isLoading$: Observable<boolean>;

  constructor(private fb: FormBuilder,
              private store$: Store<ApplicationState>,
              public dialog: MatDialog,
              private formService: FormsService,
              private notifierService: NotifierService,
              public actions$: Actions,
              public dialogRef: MatDialogRef<OrderDetailsEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order>, administrators: { [iri: string]: Administrator }} ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(OrdersSelectors.isLoading),
    ]);

    this.actions$.pipe(ofType(OrdersActions.UpdateOrderFail)).subscribe((fail) => {
      if (fail?.response?.error) {
        this.notifierService.notify('error', fail?.response?.error['hydra:description']);
      }
    });
    this.actions$.pipe(ofType(OrdersActions.UpdateOrderSuccess)).subscribe(() => {
      this.dialogRef.close();
    });

  }

  handleUpdateOrder(payload: { iri: string, payload: fromOrdersModuleModels.Order }): void {
    this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateOrder(payload));
  }

  handleUpdateLocation({locationType, iri, payload}: {
    locationType: fromOrdersModuleModels.LocationType,
    iri: string,
    payload: fromMasterDataModuleModels.LabLocation
  }): void {

    if (locationType === 'analysisLocation') {
      this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateAnalysisLocation({
        iri,
        payload: {analysisLocation: payload['@id']}
      }));
    }

    if (locationType === 'dataRecoveryLocation') {
      this.store$.dispatch(OrdersModuleActions.OrdersActions.UpdateDataRecoveryLocation({
        iri,
        payload: {dataRecoveryLocation: payload['@id']}
      }));
    }
  }


}
