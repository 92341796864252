import {createSelector} from '@ngrx/store';

import {ROLES_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectRolesState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[ROLES_FEATURE_KEY]
);

export const selectRolesEntities = createSelector(
  selectRolesState,
  (state) => state.entities
);

export const selectRoles = createSelector(
  selectRolesEntities,
  (entities) => {
    const list = [];
    list.push(...Object.keys(entities));
    if (list.indexOf('ROLE_SUPER_ADMIN') <= -1) {
      list.push('ROLE_SUPER_ADMIN');
    }
    return list;
  }
);

export const _selectRoles = createSelector(
  selectRolesEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectRolesState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectRolesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectRolesState,
  (state) => state.loaded
);
