import {createSelector} from '@ngrx/store';

import {SALES_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectSalesState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[SALES_FEATURE_KEY]
);

export const selectSalesEntities = createSelector(
  selectSalesState,
  (state) => state.entities
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectSalesState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination

export const selectSalesTotalItems = createSelector(
  selectSalesState,
  (state) => state.totalItems
);

export const selectSalesPagination = createSelector(
  selectSalesState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectSalesPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectSalesPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectSalesPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectSalesPagination,
  pagination => pagination.next
);

export const selectPaginationLastLink = createSelector(
  selectSalesPagination,
  pagination => pagination.last
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectSalesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectSalesState,
  (state) => state.loaded
);
