import { createReducer, on } from '@ngrx/store';

import { DocumentDeliveriesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: any };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    DocumentDeliveriesActions.CreateEmailDocumentDelivery,
    DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery,
    DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery,
    DocumentDeliveriesActions.ReadEmailDocumentDelivery,
    DocumentDeliveriesActions.ReadRegisteredLetterDocumentDelivery,
    DocumentDeliveriesActions.ReadLetterXPressDocumentDelivery,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
    DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
    DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
    DocumentDeliveriesActions.ReadEmailDocumentDeliverySuccess,
    DocumentDeliveriesActions.ReadRegisteredLetterDocumentDeliverySuccess,
    DocumentDeliveriesActions.ReadLetterXPressDocumentDeliverySuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    DocumentDeliveriesActions.CreateEmailDocumentDeliveryFail,
    DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliveryFail,
    DocumentDeliveriesActions.CreateLetterXPressDocumentDeliveryFail,
    DocumentDeliveriesActions.ReadEmailDocumentDeliveryFail,
    DocumentDeliveriesActions.ReadRegisteredLetterDocumentDeliveryFail,
    DocumentDeliveriesActions.ReadLetterXPressDocumentDeliveryFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
