import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommissionCredit, Offer} from '../../models';
import {Customer} from '../../../customers/models';


@Component({
  selector: 'app-brokers-list',
  styleUrls: ['brokers-list.component.scss'],
  template: `
    <table
      mat-table
      [dataSource]="items"
      class="bmo-table bmo-table-hover bmo-table-clickable bmo-table-heading-uppercase"
    >
      <!-- State Column -->
      <ng-container matColumnDef="dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="circle" [ngClass]="getCircleColorClass(element)"></div>
        </td>
      </ng-container>

      <!-- Customer Column -->
      <ng-container matColumnDef="customerNumber">
        <th mat-header-cell *matHeaderCellDef>Kunden-Nr.</th>
        <td mat-cell *matCellDef="let element">{{ element.customerNumber }}</td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Vermittler [Zusatz]</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameLine1 }}

          <ng-container *ngIf="element.nameLine2">
            [{{ element.nameLine2 }}]
          </ng-container>
        </td>
      </ng-container>

      <!-- Commission Analysis Column -->
      <ng-container matColumnDef="commissionAnalysis">
        <th mat-header-cell *matHeaderCellDef>Provision Datenanalysen</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.openPartnerCommissionAmountAnalysis.value | number: '1.2-2'
          }}
          {{ element.openPartnerCommissionAmountAnalysis.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Commission DR Column -->
      <ng-container matColumnDef="commissionDataRecovery">
        <th mat-header-cell *matHeaderCellDef>Provision Datenrettung</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.openPartnerCommissionAmountDataRecovery.value
            | number: '1.2-2'
          }}
          {{ element.openPartnerCommissionAmountDataRecovery.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Commission Other Column -->
      <ng-container matColumnDef="commissionOther">
        <th mat-header-cell *matHeaderCellDef>Provision Sonstiges</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.openPartnerCommissionAmountOther.value | number: '1.2-2'
          }}
          {{ element.openPartnerCommissionAmountOther.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Commission Total Column -->
      <ng-container matColumnDef="commissionTotal">
        <th mat-header-cell *matHeaderCellDef>Provision Gesamt</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.openPartnerCommissionAmountTotal.value | number: '1.2-2'
          }}
          {{ element.openPartnerCommissionAmountTotal.currency|currencyShortener }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="m-ta--1">
          <button
            style="width: 30px;
                height: 30px;
                line-height: 30px;"
            color="lightgrey"
            mat-icon-button
            (click)="handleRequestCreateCommissionCredit(element)"
          >
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `
})
export class BrokersListComponent {
  @Input() items: Array<CommissionCredit>;
  @Input() totalItems: number;

  @Output()
  requestCreateCommissionCredit: EventEmitter<string> = new EventEmitter();

  @Output()
  requestReadCommissionCredits: EventEmitter<
    'next' | 'previous'
  > = new EventEmitter<'next' | 'previous'>();

  displayedColumns: Array<string> = [
    'dot',
    'customerNumber',
    'name',
    'commissionAnalysis',
    'commissionDataRecovery',
    'commissionOther',
    'commissionTotal',
    'actions'
  ];

  constructor() {
  }

  handleRequestCreateCommissionCredit(invoice: CommissionCredit): void {
    this.requestCreateCommissionCredit.emit(invoice['@id']);
  }

  getCircleColorClass(element: Customer): string[] {
    if (parseFloat(element.openPartnerCommissionAmountTotal.value) > 0) {
      return ['red'];
    } else {
      return ['grey'];
    }
  }
}
