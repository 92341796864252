import {BindingOrdersEffects} from './binding-orders.effects';
import {DataMediumStatusLogsEffects} from './data-medium-status-logs.effects';
import {DataRecoveryCostsEffects} from './data-recovery-costs.effects';
import {DataRecoveryProtocolsEffects} from './data-recovery-protocols.effects';
import {DataRecoveryResultsEffects} from './data-recovery-results.effects';
import {OrderCommentTagsEffects} from './order-comment-tags.effects';
import {OrderCommentsEffects} from './order-comments.effects';
import {OrderDataMediumsEffects} from './order-data-mediums.effects';
import {OrderSpecialAgreementsEffects} from './order-special-agreements.effects';
import {OrderStateRangesEffects} from './order-state-ranges.effects';
import {OrderStatesEffects} from './order-states.effects';
import {OrdersEffects} from './orders.effects';
import {ProductOrderItemsEffects} from './product-order-items.effects';
import {ProofsOfDestructionEffects} from './proofs-of-destruction.effects';
import {ServiceOrderItemsEffects} from './service-order-items.effects';
import {GenericOrderItemsEffects} from './generic-order-items.effects';

export const effects: Array<any> = [
  BindingOrdersEffects,
  DataMediumStatusLogsEffects,
  DataRecoveryCostsEffects,
  DataRecoveryProtocolsEffects,
  DataRecoveryResultsEffects,
  OrderCommentTagsEffects,
  OrderCommentsEffects,
  OrderDataMediumsEffects,
  OrderSpecialAgreementsEffects,
  OrderStateRangesEffects,
  OrderStatesEffects,
  OrdersEffects,
  ProductOrderItemsEffects,
  ProofsOfDestructionEffects,
  ServiceOrderItemsEffects,
  GenericOrderItemsEffects,
];

export * from './binding-orders.effects';
export * from './data-medium-status-logs.effects';
export * from './data-recovery-costs.effects';
export * from './data-recovery-protocols.effects';
export * from './data-recovery-results.effects';
export * from './order-comment-tags.effects';
export * from './order-comments.effects';
export * from './order-data-mediums.effects';
export * from './order-special-agreements.effects';
export * from './order-state-ranges.effects';
export * from './order-states.effects';
export * from './orders.effects';
export * from './product-order-items.effects';
export * from './proofs-of-destruction.effects';
export * from './service-order-items.effects';
export * from './generic-order-items.effects';
