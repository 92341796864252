import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Order, ProofOfDestruction} from '../../models';
import {Observable, Subject} from 'rxjs';
import * as fromOrdersModuleModels from '../../models';
import * as OrdersModuleActions from '../../store';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {ofType} from '@ngrx/effects';
import {OrdersActions} from '../../store';
import {filter, takeUntil} from 'rxjs/operators';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {OrdersSelectors, ProofsOfDestructionSelectors} from '../../store/selectors';
import {StorageSystemManufacturersSelectors} from '../../../master-data/store/selectors';

@Component({
  selector: 'app-proof-of-destruction-dialog',
  styleUrls: ['./proof-of-destruction-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Vernichtungsnachweis bearbeiten</h2>
    </app-dialog-header>
    <div mat-dialog-content class="pos-relative">

      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>

      <app-proof-of-destruction-form
        [order$]="data.order$"
        [proofOfDestruction$]="data.proofOfDestruction$"
      ></app-proof-of-destruction-form>

    </div>
  `
})
export class ProofOfDestructionDialogComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private store$: Store<ApplicationState>,
    @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order>, proofOfDestruction$: Observable<ProofOfDestruction> }
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProofsOfDestructionSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading),
    ]);

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }


}
