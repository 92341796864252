import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { PartialInvoice } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';
import { ShippingProvider } from '../../shipping/models';

@Injectable()
export class PartialInvoicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createPartialInvoice(
    payload: PartialInvoice
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PARTIAL_INVOICE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/partial_invoices`, payload)
    );
  }

  readPartialInvoice(iri: string): Observable<PartialInvoice> {
    return this.rolesService.userHasRoleFilter<PartialInvoice>(
      'ROLE_MWS_PARTIAL_INVOICE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readPartialInvoices(page = 1, params?: ParameterBag): Observable<Array<any>> {
    return this.rolesService.userHasRoleFilter<Array<any>>(
      'ROLE_MWS_PARTIAL_INVOICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/partial_invoices', page, params)
            )
    );
  }

  readPartialInvoiceChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_PARTIAL_INVOICE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updatePartialInvoice(
    iri: string,
    payload: PartialInvoice
  ): Observable<PartialInvoice> {
    return this.rolesService.userHasRoleFilter<PartialInvoice>(
      'ROLE_MWS_PARTIAL_INVOICE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  // SECURITY: no roles
  patchPartialInvoice(
    iri: string,
    payload: { workflow: string; transition: string }
  ): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    return this.apiService.patchObject(url, payload, true);
  }

  deletePartialInvoice(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<ShippingProvider>(
      'ROLE_MWS_PARTIAL_INVOICE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
