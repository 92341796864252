<app-dialog-header>
  <h2>Versand vorbereiten ({{shippingProviderObject?.name}})</h2>
</app-dialog-header>

<div mat-dialog-content>
  <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
  <app-shipment-form
    [leadContact]="data.leadContact"
    (updateForm)="updateForm($event)"
    (updateShipment)="updateShipment($event)"
    [shippingProvider]="data.shippingProvider"
  ></app-shipment-form>
</div>

<div mat-dialog-actions>
  <div class="column-14 m-ta--2 pos-relative">
    <span [matTooltip]="(!form?.valid)?'Das Formular enthält Fehler. Bitte beheben.' :''"
          matTooltipPosition="above" (mouseover)="markAsTouched()">
      <button class="btn--submit"
              [disabled]="!form?.valid"
              mat-flat-button
              color="green"
              (click)="openMailComponent()">
      <mat-icon class="m-r--8">qr_code</mat-icon>
      <span>Paket vorbereiten</span>
    </button>
      </span>
  </div>
</div>

