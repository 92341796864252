import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { ShipmentDirectionsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.ShipmentDirection };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    ShipmentDirectionsActions.ReadShipmentDirection,
    ShipmentDirectionsActions.ReadShipmentDirections,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    ShipmentDirectionsActions.ReadShipmentDirectionSuccess,
    (state, { response }: { response: fromModuleModels.ShipmentDirection }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: false
      };
    }
  ),
  on(
    ShipmentDirectionsActions.ReadShipmentDirectionsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: { [iri: string]: fromModuleModels.ShipmentDirection },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    ShipmentDirectionsActions.ReadShipmentDirectionFail,
    ShipmentDirectionsActions.ReadShipmentDirectionsFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
