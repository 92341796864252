import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DisposalTypesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { DisposalTypesService } from '../../services/disposal-types.service';

@Injectable()
export class DisposalTypesEffects {
  readDisposalType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisposalTypesActions.ReadDisposalType),
      map(action => action),
      switchMap(({ iri }) => {
        return this.aps.getDisposalType(iri).pipe(
          map(response =>
            DisposalTypesActions.ReadDisposalTypeSuccess({ response })
          ),
          catchError(response =>
            of(DisposalTypesActions.ReadDisposalTypeFail({ response }))
          )
        );
      })
    )
  );
  readDisposalTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisposalTypesActions.ReadDisposalTypes),
      map(action => action),
      switchMap(() => {
        return this.aps.getDisposalTypes().pipe(
          map(response =>
            DisposalTypesActions.ReadDisposalTypesSuccess({ response })
          ),
          catchError(response =>
            of(DisposalTypesActions.ReadDisposalTypesFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DisposalTypesActions.ReadDisposalTypesFail,
          DisposalTypesActions.ReadDisposalTypeFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aps: DisposalTypesService,
    private notifierService: NotifierService
  ) {}
}
