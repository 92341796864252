import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {OrderStatesActions} from '../actions';
import {OrderStatesService} from '../../services';

@Injectable()
export class OrderStatesEffects {

  constructor(private actions$: Actions, private service: OrderStatesService) {
  }

  ReadOrderState$ = createEffect(() => this.actions$.pipe(
    ofType(OrderStatesActions.ReadOrderState),
    switchMap(({iri}) => {
      return this.service.readOrderState(iri).pipe(
        map((response: any) => OrderStatesActions.ReadOrderStateSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OrderStatesActions.ReadOrderStateFail({response})))
      );
    })
  ));

  ReadOrderStates$ = createEffect(() => this.actions$.pipe(
    ofType(OrderStatesActions.ReadOrderStates),
    switchMap(() => {
      return this.service.readOrderStates().pipe(
        map((response: any) => OrderStatesActions.ReadOrderStatesSuccess({response})),
        catchError((response: HttpErrorResponse) => of(OrderStatesActions.ReadOrderStatesFail({response})))
      );
    })
  ));
}
