import {NgModule} from '@angular/core';
import * as fromInvoicesModuleContainers from './containers';
import * as fromInvoicesModuleComponents from './components';
import * as fromInvoicesModuleServices from './services';
import * as fromInvoicesModuleGuards from './guards';
import {SharedModule} from '../shared/shared.module';
import {CustomersModule} from '../customers/customers.module';
import {OpenInvoiceListWidgetComponent} from './components/open-invoice-list-widget/open-invoice-list-widget.component';
import {BrokersFilterComponent} from './components/brokers-filter/brokers-filter.component';
import {PayableInvoiceNewComponent} from './containers/payable-invoice-new/payable-invoice-new.component';
import {InvoicePreviewComponent} from './components/invoice-preview/invoice-preview.component';
import {
  InvoiceSelectRecipientModalComponent
} from './components/invoice-select-recipient-modal/invoice-select-recipient-modal.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {InvoiceCustomTextComponent} from './components/invoice-custom-text/invoice-custom-text.component';
import {
  CancellationInvoicePreviewComponent
} from './components/cancellation-invoice-preview/cancellation-invoice-preview.component';
import {InvoiceItemDialogComponent} from './components/invoice-item-dialog/invoice-item-dialog.component';
import {OfferPreviewComponent} from './components/offer-preview/offer-preview.component';
import {OfferNewComponent} from './containers/offer-new/offer-new.component';
import {CorrectionInvoiceNewComponent} from './containers';
import {
  CorrectionInvoicePreviewComponent
} from './components/correction-invoice-preview/correction-invoice-preview.component';
import {
  CommissionCreditPreviewComponent
} from './components/commission-credit-preview/commission-credit-preview.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [SharedModule, CustomersModule, MatButtonToggleModule, TranslateModule],
  exports: [
    ...fromInvoicesModuleContainers.AllContainers,
    ...fromInvoicesModuleComponents.AllComponents,
    OpenInvoiceListWidgetComponent,
    OfferNewComponent,
    CorrectionInvoiceNewComponent
  ],
  declarations: [
    ...fromInvoicesModuleContainers.AllContainers,
    ...fromInvoicesModuleComponents.AllComponents,
    OpenInvoiceListWidgetComponent,
    BrokersFilterComponent,
    PayableInvoiceNewComponent,
    InvoicePreviewComponent,
    OfferPreviewComponent,
    CorrectionInvoicePreviewComponent,
    CancellationInvoicePreviewComponent,
    InvoiceSelectRecipientModalComponent,
    InvoiceCustomTextComponent,
    InvoiceItemDialogComponent,
    OfferNewComponent,
    CorrectionInvoiceNewComponent,
    CommissionCreditPreviewComponent
  ],
  providers: [
    ...fromInvoicesModuleServices.AllServices,
    ...fromInvoicesModuleGuards.AllGuards
  ]
})
export class InvoicesModule {
}
