import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from './../../models';

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_PASSWORD = '[Auth Module] Update Password';
export const UPDATE_PASSWORD_SUCCESS = '[Auth Module] Update Password Success';
export const UPDATE_PASSWORD_FAIL = '[Auth Module] Update Password Fail';

export const UpdatePassword = createAction(
  UPDATE_PASSWORD,
  props<{ iri: string, payload: fromModuleModels.PasswordUpdate }>()
);

export const UpdatePasswordSuccess = createAction(
  UPDATE_PASSWORD_SUCCESS,
  props<{ response: any }>()
);

export const UpdatePasswordFail = createAction(
  UPDATE_PASSWORD_FAIL,
  props<{ response: HttpErrorResponse }>()
);
