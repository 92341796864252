import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { PotentialAction } from '../../models';
import { TransitionsActions } from '../../../application-state/store/actions';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-order-closed-or-return-shipment',
  styleUrls: ['./action-box-to-order-closed-or-return-shipment.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Müssen Datenträger an Kunden verschicken werden?
              </div>
              <div class="sub-header col-auto">
                Bitte prüfen, ob ein Rückversand zum Kunden nötig ist!
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn btn-wider me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition()"
              [disabled]="!makeShipmentAction"
            >
              <mat-icon class="me-2">local_shipping</mat-icon>
              <span>Versand nötig</span>
            </button>
            <button
              class="decision-btn btn-wider me-3"
              mat-button
              color="green"
              (click)="handleMakeTransition2()"
              [disabled]="!closeAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>kein Versand nötig</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Rückversand Datenträger prüfen</div>
          <div class="sub-header col-auto">
            Warte auf Entscheidung ob Datenträger an Kunden verschicken werden
            müssen
          </div>
        </div>
      </div>
      <div class="additional-content p-4" *ngIf="canMakeTransition">
        <div class="row">
          <app-order-stock-item-select-list
            [readonlyArchive]="true"
            (updateSelectedDataMedia)="setSelectedDataMedia($event)"
          ></app-order-stock-item-select-list>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToOrderClosedOrReturnShipmentComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  dataMedia: Array<string> = [];
  makeShipmentAction: PotentialAction = null;
  closeAction: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'order' &&
        (this.authService.isLogistic() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$.pipe(takeUntil(this.onDestroy$)).subscribe(order => {
      this.order = order;
      this.closeAction = order.potentialActions?.find(
        e =>
          e.transition.indexOf('payment_received_to_order_closed') > -1 &&
          !e.error
      );
      this.makeShipmentAction = order.potentialActions?.find(
        e =>
          e.transition.indexOf('payment_received_to_return_shipment') > -1 &&
          !e.error
      );
    });
  }

  setSelectedDataMedia(list: Array<string>): void {
    this.dataMedia = list;
  }

  handleMakeTransition(): void {
    const action = this.makeShipmentAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleMakeTransition2(): void {
    const action = this.closeAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }
}
