import {Component, Input, OnInit} from '@angular/core';
import {Lead} from '../../models';


@Component({
  selector: 'app-tracking-details',
  styleUrls: ['tracking-details.component.scss'],
  template: `

    <dl class="grid">
      <dt class="column-4">UTM-Source</dt>
      <dd class="column-10">{{ lead?.UTMSource ? lead.UTMSource : '-' }}</dd>

      <dt class="column-4">UTM-Medium</dt>
      <dd class="column-10">{{ lead?.UTMMedium ? lead.UTMMedium : '-' }}</dd>

      <dt class="column-4">UTM-Campaign</dt>
      <dd class="column-10">{{ lead?.UTMCampaign ? lead.UTMCampaign : '-' }}</dd>

      <dt class="column-4">UTM-Content</dt>
      <dd class="column-10">{{ lead?.UTMContent ? lead.UTMContent : '-' }}</dd>

      <dt class="column-4">UTM-Term</dt>
      <dd class="column-10">{{ lead?.UTMTerm ? lead.UTMTerm : '-' }}</dd>

      <dt class="column-4">City</dt>
      <dd class="column-10">{{ lead?.UTMCity ? lead.UTMCity : '-' }}</dd>

      <dt class="column-4">Entry-URL</dt>
      <dd class="column-10">{{ lead?.UTMEntryUrl ? lead.UTMEntryUrl : '-' }}</dd>

      <dt class="column-4">Submitted from URL</dt>
      <dd class="column-10">{{ lead?.UTMSubmittedFromUrl ? lead.UTMSubmittedFromUrl : '-' }}</dd>

      <dt class="column-4">Used Form</dt>
      <dd class="column-10">{{ lead?.UTMUsedForm ? lead.UTMUsedForm : '-' }}</dd>
    </dl>
  `
})
export class TrackingDetailsComponent implements OnInit {

  @Input()
  lead: Lead;

  constructor() {
  }

  ngOnInit(): void {
  }
}
