import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import * as fromInvoicesModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {StringsUtility} from '../../../shared/utilities/strings.utility';

@Component({
  selector: 'partial-invoice-creator',
  styleUrls: ['partial-invoice-creator.component.scss'],
  template: `

    <div class="card mt-3 mat-elevation-z1" [class.hidden]="!partialInvoiceCreated">

      <div class="card__heading">
        <span>Abschlagsrechnung erstellen</span>
      </div>

      <div class="card__content grid grid-no-gutter">

        <ng-container [formGroup]="pif">
          <mat-form-field class="column-14">
            <mat-label>Höhe der Anzahlung</mat-label>
            <input type="number" matInput min="1" step="1" max="99" required formControlName="percentageOfFinalInvoice">
            <span matSuffix> %</span>
            <mat-hint *ngIf="errors?.percentageOfFinalInvoice">{{ errors.percentageOfFinalInvoice.message }}</mat-hint>
          </mat-form-field>
        </ng-container>

        <div class="column-9">
          <div class="label" *ngIf="!!paymentProcess?.partialInvoices.length">
            <strong>Verknüpfte Abschlagsrechnung/en</strong>
          </div>
          <div class="label" *ngFor="let pi of paymentProcess?.partialInvoices; let i = index">{{ i + 1 }}
            . {{ pi?.invoiceNumber ? 'Nr. ' + pi.invoiceNumber : '(Noch nicht gebuchter Rechnungsentwurf)' }}</div>
        </div>
        <div class="column-5">
          <button
            (click)="handleRequestCreateInvoiceFromFinalInvoice(invoice)"
            [disabled]="!invoice || pif.invalid" mat-flat-button color="green">
            <mat-icon class="m-r--8">description</mat-icon>
            <span>Rechnung erstellen</span>
          </button>
        </div>
      </div>

      <div class="blocker"></div>
    </div>
  `
})
export class PartialInvoiceCreatorComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() invoice: fromInvoicesModuleModels.CorrectionInvoice | any;
  @Input() partialInvoiceCreated: boolean;
  @Input() paymentProcess: fromInvoicesModuleModels.PaymentProcess;

  @Output()
  requestCreateInvoiceFromFinalInvoice: EventEmitter<{
    finalInvoice: string,
    percentageOfFinalInvoice: string
  }> = new EventEmitter();

  pif: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.pif = this.fb.group({
      finalInvoice: this.fb.control(null),
      percentageOfFinalInvoice: this.fb.control(null, Validators.required)
    });
  }

  handleRequestCreateInvoiceFromFinalInvoice(invoice: fromInvoicesModuleModels.CorrectionInvoice): void {

    if (!invoice) {
      alert('Invoice must be set');
      return;
    }
    this.pif.get('finalInvoice').setValue(invoice['@id']);
    this.pif.get('percentageOfFinalInvoice').setValue(this.pif.get('percentageOfFinalInvoice').value.toString());
    this.requestCreateInvoiceFromFinalInvoice.emit(this.pif.value);
  }
}
