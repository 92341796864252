import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';


@Component({
  selector: 'salutation-form',
  styleUrls: ['salutation-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Anrede bearbeiten' : 'Neue Anrede anlegen' }}</span>
      </div>

      <div class="card__content p-a--24">
        <ng-container [formGroup]="sf">

          <div class="grid">
            <mat-form-field class="column-14">
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="name" required>
              <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
            </mat-form-field>

            <mat-form-field class="column-14">
              <mat-label>Grußformel</mat-label>
              <textarea type="text" rows="5" cdkTextareaAutosize matInput formControlName="salutation" required
              ></textarea>
              <mat-hint align="start" *ngIf="errors?.salutation">{{ errors.salutation.message }}</mat-hint>
            </mat-form-field>

            <div class="mat-form-field column-14">
              <ng-select
                placeholder="Sprache"
                [items]="locales$ | async"
                bindValue="locale"
                bindLabel="name"
                [markFirst]="false"
                [clearable]="false"
                formControlName="locale"
              ></ng-select>
            </div>

            <div class="m-ta--2 column-14">

              <button *ngIf="presets$.getValue() || sf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                      color="outline" mat-flat-button>
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button [disabled]="sf.invalid || sf.pristine" mat-flat-button color="green" (click)="handleSubmit()">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class SalutationFormComponent implements OnInit {

  @Input() errors: ErrorsObject;
  @Input() locales$: Observable<Array<fromMasterDataModuleModels.Locale>>;
  @Input() presets$: BehaviorSubject<fromMasterDataModuleModels.Salutation>;

  @Output() requestCreateItem: EventEmitter<{
    payload: fromMasterDataModuleModels.Salutation,
    entity: string
  }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromMasterDataModuleModels.Salutation,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  sf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {

      this.initForm();
      this.fs.patchForm(this.sf, preset);
      this.sf.markAsUntouched();
    });
  }

  initForm() {
    this.sf = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      salutation: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      locale: this.fb.control('de_DE', Validators.required)
    });
  }

  cancelEdit() {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    const payload: fromMasterDataModuleModels.Salutation = this.sf.value;

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Salutation'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Salutation'});
  }
}
