export const payableInvoiceStateMapDE = {
  draft: 'Entwurf',
  booked: 'Gebucht',
  canceled: 'Storniert',
  first_reminder: 'Erste Mahnung',
  second_reminder: 'Zweite Mahnung ',
  dept_collection: 'Inkasso',
  paid: 'Bezahlt'
};

export const correctionInvoiceStateMapDE = {
  draft: 'Entwurf',
  booked: 'Gebucht',
  paid_out: 'Ausgezahlt'
};
export const commissionCreditStateMapDE = {
  draft: 'Entwurf',
  booked: 'Gebucht',
  paid_out: 'Ausgezahlt'
};
