import { NgModule } from '@angular/core';

import * as fromModuleComponents from './components';
import {
  CustomerCooperationSelectComponent,
  StorageLocationSelectComponent
} from './components';
import * as fromModuleContainers from './containers';
import * as fromModuleGuards from './guards';
import { SharedModule } from '../shared/shared.module';
import { EmailTemplateCategoriesFormComponent } from './components/email-template-categories-form/email-template-categories-form.component';
import { EmailTemplatesFormComponent } from './components/email-templates-form/email-templates-form.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { AddressTypesService } from './services/address-types.service';
import { AnalysisPriorityModesService } from './services/analysis-priority-modes.service';
import { AnalysisResultCategoriesService } from './services/analysis-result-categories.service';
import { AnalysisResultsService } from './services/analysis-results.service';
import { CountriesService } from './services/countries.service';
import { CurrenciesService } from './services/currencies.service';
import { CustomerCooperationsService } from './services/customer-cooperations.service';
import { DamagesService } from './services/damages.service';
import { DataRecoveryPriorityModesService } from './services/data-recovery-priority-modes.service';
import { DepartmentAdsService } from './services/department-ads.service';
import { DepartmentLogosService } from './services/department-logos.service';
import { DepartmentsService } from './services/departments.service';
import { DestructionTypesService } from './services/destruction-types.service';
import { DiscountsService } from './services/discounts.service';
import { DisposalTypesService } from './services/disposal-types.service';
import { FileSystemsService } from './services/file-systems.service';
import { GradesService } from './services/grades.service';
import { LabLocationsService } from './services/lab-locations.service';
import { LocalesService } from './services/locales.service';
import { OperatingSystemsService } from './services/operating-systems.service';
import { ProductUnitsService } from './services/product-units.service';
import { ProductsService } from './services/products.service';
import { ReplacementDataMediumSourcesService } from './services/replacement-data-medium-sources.service';
import { RolesService } from './services/roles.service';
import { SalutationsService } from './services/salutations.service';
import { ServicesService } from './services/services.service';
import { SpecialAgreementsService } from './services/special-agreements.service';
import { StorageLocationsService } from './services/storage-locations.service';
import { StorageSystemManufacturersService } from './services/storage-system-manufacturers.service';
import { StorageSystemsService } from './services/storage-systems.service';
import { SymptomsService } from './services/symptoms.service';
import { TaxesService } from './services/taxes.service';
import { TermsAndConditionsService } from './services/terms-and-conditions.service';
import { EmailTemplateCategoryService } from './services/email-template-category.service';
import { EmailTemplateService } from './services/email-template.service';
import { EmailTemplateContextsService } from './services/email-template-context.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    fromModuleContainers.MasterDataViewComponent,
    fromModuleComponents.AllComponents,
    EmailTemplateCategoriesFormComponent,
    EmailTemplatesFormComponent,
    ProductSelectComponent
  ],
  exports: [
    CustomerCooperationSelectComponent,
    ProductSelectComponent,
    StorageLocationSelectComponent
  ],
  providers: [
    fromModuleGuards.AllGuards,
    AddressTypesService,
    AnalysisPriorityModesService,
    AnalysisResultCategoriesService,
    AnalysisResultsService,
    CountriesService,
    CurrenciesService,
    CustomerCooperationsService,
    DamagesService,
    DataRecoveryPriorityModesService,
    DepartmentAdsService,
    DepartmentLogosService,
    DepartmentsService,
    DestructionTypesService,
    DiscountsService,
    DisposalTypesService,
    FileSystemsService,
    GradesService,
    LabLocationsService,
    LocalesService,
    OperatingSystemsService,
    ProductUnitsService,
    ProductsService,
    ReplacementDataMediumSourcesService,
    RolesService,
    SalutationsService,
    ServicesService,
    SpecialAgreementsService,
    StorageLocationsService,
    StorageSystemManufacturersService,
    StorageSystemsService,
    SymptomsService,
    TaxesService,
    TermsAndConditionsService,
    EmailTemplateCategoryService,
    EmailTemplateService,
    EmailTemplateContextsService
  ]
})
export class MasterDataModule {}
