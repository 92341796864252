import { Injectable } from '@angular/core';
import * as fromSharedModuleServices from '../services';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../application-state/store';
import { AuthService } from '../../auth/services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WarehouseRights {
  constructor(
    private auth: AuthService,
    private apiService: fromSharedModuleServices.AbstractApiService,
    private cookieService: CookieService,
    private store$: Store<ApplicationState>
  ) {}

  get canList$(): Observable<boolean> {
    return this.auth.userBaseRoles$.pipe(
      map(
        roles =>
          this.auth.hasGroupWithName(roles, 'administrator') ||
          this.auth.hasGroupWithName(roles, 'supervisor') ||
          this.auth.hasGroupWithName(roles, 'sales') ||
          this.auth.hasGroupWithName(roles, 'technician') ||
          this.auth.hasGroupWithName(roles, 'accounting') ||
          this.auth.hasGroupWithName(roles, 'logistics')
      )
    );
  }

  get canRead$(): Observable<boolean> {
    return this.auth.userBaseRoles$.pipe(
      map(
        roles =>
          this.auth.hasGroupWithName(roles, 'administrator') ||
          this.auth.hasGroupWithName(roles, 'supervisor') ||
          this.auth.hasGroupWithName(roles, 'sales') ||
          this.auth.hasGroupWithName(roles, 'technician') ||
          this.auth.hasGroupWithName(roles, 'accounting') ||
          this.auth.hasGroupWithName(roles, 'logistics')
      )
    );
  }

  get canCreate$(): Observable<boolean> {
    return this.auth.userBaseRoles$.pipe(
      map(
        roles =>
          this.auth.hasGroupWithName(roles, 'administrator') ||
          this.auth.hasGroupWithName(roles, 'supervisor') ||
          this.auth.hasGroupWithName(roles, 'technician') ||
          this.auth.hasGroupWithName(roles, 'accounting') ||
          this.auth.hasGroupWithName(roles, 'logistics')
      )
    );
  }

  get canEdit$(): Observable<boolean> {
    return this.auth.userBaseRoles$.pipe(
      map(
        roles =>
          this.auth.hasGroupWithName(roles, 'administrator') ||
          this.auth.hasGroupWithName(roles, 'supervisor') ||
          this.auth.hasGroupWithName(roles, 'technician') ||
          this.auth.hasGroupWithName(roles, 'accounting') ||
          this.auth.hasGroupWithName(roles, 'logistics')
      )
    );
  }

  get canDelete$(): Observable<boolean> {
    return this.auth.userBaseRoles$.pipe(
      map(
        roles =>
          this.auth.hasGroupWithName(roles, 'administrator') ||
          this.auth.hasGroupWithName(roles, 'supervisor')
      )
    );
  }
}
