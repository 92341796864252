import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';
import { AdministratorGroup } from '../models';

@Injectable()
export class AdministratorGroupsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  getAdministratorGroup(iri: string): Observable<AdministratorGroup> {
    return this.rolesService.userHasRoleFilter<AdministratorGroup>(
      'ROLE_MWS_USER_ADMINISTRATOR_GROUP_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getAdministratorGroups(): Observable<Array<AdministratorGroup>> {
    return this.rolesService.userHasRoleFilter<Array<AdministratorGroup>>(
      'ROLE_MWS_USER_ADMINISTRATOR_GROUP_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/administrator_groups`)
    );
  }
}
