import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Order} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {OrdersSelectors} from '../../store/selectors';
import {Customer} from '../../../customers/models';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-broker-select',
  styleUrls: ['./broker-select.component.scss'],
  template: `
    <div>
      <app-customer-select
        [label]="'orders.broker_select.placeholder' | translate"
        (selectCustomer)="setSelectedItem($event)"
        [additionalTextFn]="getOrderCount"
      ></app-customer-select>
    </div>
  `

})
export class BrokerSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() fieldName: string;
  @Output() updateSelectedObject: EventEmitter<Customer> = new EventEmitter<Customer>();

  selectedItem: Customer;
  orders: Order[];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.store$.select(OrdersSelectors.sList).subscribe((orders) => {
      this.orders = orders;
    });

  }

  getOrderCount(customer: Customer): string {
    if (!this.orders) {
      return '';
    }
    const list = this.orders.filter(e => e.salesperson["@id"] === extractIri(customer));
    return list.length > 0 ? ` (${list.length})` : '';
  }

  setSelectedItem(element: Customer): void {
    this.formGroup.patchValue({broker: extractIri(element)});
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

}
