import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {DataRecoveryResultsActions} from '../actions';
import {DataRecoveryResultsService} from '../../services';

@Injectable()
export class DataRecoveryResultsEffects {

  constructor(private actions$: Actions, private service: DataRecoveryResultsService) {
  }


  ReadDataRecoveryResult$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryResultsActions.ReadDataRecoveryResult),
    switchMap(({iri}) => {
      return this.service.readDataRecoveryResult(iri).pipe(
        map((response: any) => DataRecoveryResultsActions.ReadDataRecoveryResultSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryResultsActions.ReadDataRecoveryResultFail({response})))
      );
    })
  ));


  UpdateDataRecoveryResult$ = createEffect(() => this.actions$.pipe(
    ofType(DataRecoveryResultsActions.UpdateDataRecoveryResult),
    switchMap(({iri, payload}) => {
      return this.service.updateDataRecoveryResult(iri, payload).pipe(
        map((response: any) => DataRecoveryResultsActions.UpdateDataRecoveryResultSuccess({response})),
        catchError((response: HttpErrorResponse) => of(DataRecoveryResultsActions.UpdateDataRecoveryResultFail({response})))
      );
    })
  ));
}
