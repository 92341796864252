import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER = '[Customers Module] Create Customer';
export const CREATE_CUSTOMER_FAIL = '[Customers Module] Create Customer Fail';
export const CREATE_CUSTOMER_SUCCESS = '[Customers Module] Create Customer Success';

export const CreateCustomer = createAction(
  CREATE_CUSTOMER,
  props<{ payload: fromModuleModels.Customer }>()
);

export const CreateCustomerSuccess = createAction(
  CREATE_CUSTOMER_SUCCESS,
  props<{ response: fromModuleModels.Customer }>()
);

export const CreateCustomerFail = createAction(
  CREATE_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER = '[Customers Module] Read Customer';
export const READ_CUSTOMER_SUCCESS = '[Customers Module] Read Customer Success';
export const READ_CUSTOMER_FAIL = '[Customers Module] Read Customer Fail';

export const ReadCustomer = createAction(
  READ_CUSTOMER,
  props<{ iri: string }>()
);

export const ReadCustomerSuccess = createAction(
  READ_CUSTOMER_SUCCESS,
  props<{ response: fromModuleModels.Customer | any }>()
);

export const ReadCustomerFail = createAction(
  READ_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_PARTNER = '[Customers Module] Read Partner';
export const READ_PARTNER_SUCCESS = '[Customers Module] Read Partner Success';
export const READ_PARTNER_FAIL = '[Customers Module] Read Partner Fail';

export const ReadPartner = createAction(
  READ_PARTNER,
  props<{ iri: string }>()
);

export const ReadPartnerSuccess = createAction(
  READ_PARTNER_SUCCESS,
  props<{ response: fromModuleModels.Customer | any }>()
);

export const ReadPartnerFail = createAction(
  READ_PARTNER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
// todo: probably rename to ReadCustomersCollection ?
export const READ_CUSTOMERS = '[Customers Module] Read Customers';
export const READ_CUSTOMERS_SUCCESS = '[Customers Module] Read Customers Success';
export const READ_CUSTOMERS_FAIL = '[Customers Module] Read Customers Fail';

export const ReadCustomers = createAction(
  READ_CUSTOMERS,
  props<{ page: number, params?: { [p: string]: number | string | boolean | string[] } }>()
);

export const ReadCustomersSuccess = createAction(
  READ_CUSTOMERS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomersFail = createAction(
  READ_CUSTOMERS_FAIL,
  props<{ response: any }>()
);

export const READ_CUSTOMER_CHUNK = '[Customers Module] Read Customer Chunk';
export const READ_CUSTOMER_CHUNK_SUCCESS = '[Customers Module] Read Customer Chunk Success';
export const READ_CUSTOMER_CHUNK_FAIL = '[Customers Module] Read Customer Chunk Fail';

export const ReadCustomerChunk = createAction(
  READ_CUSTOMER_CHUNK,
  props<{ uri: string }>()
);

export const ReadCustomerChunkSuccess = createAction(
  READ_CUSTOMER_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerChunkFail = createAction(
  READ_CUSTOMER_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER = '[Customers Module] Update Customer';
export const UPDATE_CUSTOMER_SUCCESS = '[Customers Module] Update Customer Success';
export const UPDATE_CUSTOMER_FAIL = '[Customers Module] Update Customer Fail';

export const UpdateCustomer = createAction(
  UPDATE_CUSTOMER,
  props<{ iri: string, payload: fromModuleModels.Customer | any }>()
);

export const UpdateCustomerSuccess = createAction(
  UPDATE_CUSTOMER_SUCCESS,
  props<{ response: fromModuleModels.Customer }>()
);

export const UpdateCustomerFail = createAction(
  UPDATE_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const SET_DEFAULT_CUSTOMER_ADDRESS = '[Customers Module] Set Default Customer Address';
export const SET_DEFAULT_CUSTOMER_ADDRESS_SUCCESS = '[Customers Module] Set Default Customer Address Success';
export const SET_DEFAULT_CUSTOMER_ADDRESS_FAIL = '[Customers Module] Set Default Customer Address Fail';

export const SetDefaultCustomerAddress = createAction(
  SET_DEFAULT_CUSTOMER_ADDRESS,
  props<{ iri: string, payload: fromModuleModels.Customer | any }>()
);

export const SetDefaultCustomerAddressSuccess = createAction(
  SET_DEFAULT_CUSTOMER_ADDRESS_SUCCESS,
  props<{ response: fromModuleModels.Customer }>()
);

export const SetDefaultCustomerAddressFail = createAction(
  SET_DEFAULT_CUSTOMER_ADDRESS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const SET_DEFAULT_CUSTOMER_CONTACT = '[Customers Module] Set Default Customer Contact';
export const SET_DEFAULT_CUSTOMER_CONTACT_SUCCESS = '[Customers Module] Set Default Customer Contact Success';
export const SET_DEFAULT_CUSTOMER_CONTACT_FAIL = '[Customers Module] Set Default Customer Contact Fail';

export const SetDefaultCustomerContact = createAction(
  SET_DEFAULT_CUSTOMER_CONTACT,
  props<{ iri: string, payload: fromModuleModels.Customer | any }>()
);

export const SetDefaultCustomerContactSuccess = createAction(
  SET_DEFAULT_CUSTOMER_CONTACT_SUCCESS,
  props<{ response: fromModuleModels.Customer }>()
);

export const SetDefaultCustomerContactFail = createAction(
  SET_DEFAULT_CUSTOMER_CONTACT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_CUSTOMER = '[Customers Module] Delete Customer';
export const DELETE_CUSTOMER_SUCCESS = '[Customers Module] Delete Customer Success';
export const DELETE_CUSTOMER_FAIL = '[Customers Module] Delete Customer Fail';

export const DeleteCustomer = createAction(
  DELETE_CUSTOMER,
  props<{ iri: string }>()
);

export const DeleteCustomerSuccess = createAction(
  DELETE_CUSTOMER_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerFail = createAction(
  DELETE_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_CUSTOMER = '[Customers Module] Reset Current Customer';

export const ResetCurrentCustomer = createAction(
  RESET_CURRENT_CUSTOMER
);

export const RESET_IS_LOADED = '[Customers Module] Reset Is Loaded';

export const ResetIsLoaded = createAction(
  RESET_IS_LOADED
);
