import { Component, Inject, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from '../../../master-data/models';
import { ReplacementStockItem, StockItem } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormsService } from '../../../shared/services';
import { Actions, ofType } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CustomerReplacementStockItemsSelectors,
  CustomerStockItemsSelectors,
  DataMediumsSelectors
} from '../../store/selectors';
import { take, takeUntil } from 'rxjs/operators';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions
} from '../../store';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { ShowCustomerHardwareDialogComponent } from '../show-customer-hardware-dialog/show-customer-hardware-dialog.component';

@Component({
  selector: 'app-edit-customer-hardware-dialog',
  styleUrls: ['./edit-customer-hardware-dialog.component.scss'],
  template: `
    <form [formGroup]="form">
      <div mat-dialog-title style="min-width: 1000px;">
        <div class="row">
          <div class="col-6">
            <h1>
              {{
                form.get('hardwareTyp').value === 'CustomerStockItem'
                  ? 'Kundenhardware'
                  : 'Ersatzdatenträger (Kunde) '
              }}
              bearbeiten
            </h1>
          </div>
          <div class="col-6 text-right">
            <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        mat-dialog-content
        style="min-height: 500px; overflow: auto;"
        class="pos-relative"
      >
        <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>

        <div class="row">
          <div class="col-5">
            <div class="mat-form-field">
              <app-order-select
                [required]="true"
                [readonly]="true"
                formControlName="order"
              ></app-order-select>
            </div>
          </div>
          <div class="col-4">
            <div class="mat-form-field">
              <app-storage-system-type-select
                [required]="true"
                formControlName="storageSystemType"
              ></app-storage-system-type-select>
            </div>
          </div>
          <div class="col-3">
            <div class="mat-form-field">
              <app-storage-location-select
                [required]="true"
                formControlName="storageLocation"
              ></app-storage-location-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h1 [class.text-strike]="!form.get('withEnclosure').value">
              Gehäuse
            </h1>
          </div>
          <div class="col-6 text-right">
            <mat-slide-toggle class="" formControlName="withEnclosure">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-customer-data-medium-form
              [formGroup]="fg('enclosure')"
            ></app-customer-data-medium-form>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Datenträger</h1>
          </div>
          <div class="col-7 text-right">
            <button mat-button color="green" (click)="addDataMediaLine()">
              <mat-icon>add</mat-icon>
              Festplatte hinzufügen
            </button>
          </div>
        </div>
        <div formArrayName="dataMediums">
          <div
            class="d-flex"
            *ngFor="
              let subForm of dataMediumFormArray.controls;
              let index = index
            "
          >
            <div class="flex-grow-1">
              <app-customer-data-medium-form
                [prependText]="index + 1 + '.'"
                [formGroup]="makeFg(subForm)"
              ></app-customer-data-medium-form>
            </div>
            <div class="flex">
              <button
                mat-icon-button
                class="text-color-grey mt-1"
                (click)="deleteDataMediaLine(index)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <h1>Bemerkung / Zubehör</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Bemerkung</mat-label>
              <textarea
                matInput
                formControlName="description"
                rows="12"
                mat-autosize
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="description"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 ">
            <mat-form-field class="">
              <mat-label>Zubehör</mat-label>
              <textarea
                matInput
                formControlName="accessories"
                rows="12"
                mat-autosize
              ></textarea>
              <mat-error>
                <app-form-error
                  fieldName="accessories"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          class="row"
          [hidden]="!form.get('dataMediaOkLogisticsDepartment').enabled"
        >
          <div class="col-12">
            <mat-button-toggle-group
              formControlName="dataMediaOkLogisticsDepartment"
              aria-label="Datenträger OK Logistik"
              class="mb-2 block"
            >
              <mat-button-toggle
                value="true"
                class="bold-if-checked green-if-checked lightgrey-else"
              >
                Datenträger OK (Logistik)
              </mat-button-toggle>
              <mat-button-toggle
                value="false"
                class="bold-if-checked red-if-checked lightgrey-else"
                >Datenträger weißt Probleme auf
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="row"
          [hidden]="!form.get('dataMediaOkTechnicalDepartment').enabled"
        >
          <div class="col-12">
            <mat-button-toggle-group
              formControlName="dataMediaOkTechnicalDepartment"
              aria-label="Datenträger OK "
              class="mb-2 block"
            >
              <mat-button-toggle
                value="true"
                class="bold-if-checked green-if-checked lightgrey-else"
                (click)="
                  toggleBtnClicked('dataMediaOkTechnicalDepartment', 'true')
                "
              >
                Datenträger OK (Technik)
              </mat-button-toggle>
              <mat-button-toggle
                value="false"
                (click)="
                  toggleBtnClicked('dataMediaOkTechnicalDepartment', 'false')
                "
                class="bold-if-checked red-if-checked lightgrey-else"
              >
                Datenträger weißt Probleme auf
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!--<pre>{{toSaveToStockItem|json}}</pre>-->
        <!--<pre>{{stockItems$|async|json}}</pre>-->
      </div>
      <div mat-dialog-actions class="row">
        <div class="offset-6 col-6 text-right">
          <button mat-button color="green" (click)="submitSave()">
            <mat-icon>save</mat-icon>
            Speichern
          </button>
          <!--          <button mat-button color="green" *ngIf="form.get('hardwareTyp').value==='CustomerStockItem'"-->
          <!--                  (click)="submitSaveAndAddReplacement()">-->
          <!--            <mat-icon>save</mat-icon>-->
          <!--            Speichern und Ersatzdatenträger hinzufügen-->
          <!--          </button>-->
        </div>
      </div>
    </form>
  `
})
export class EditCustomerHardwareDialogComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: Observable<boolean>;
  form: FormGroup;
  product$: Observable<Product>;
  seperatedSerialNumbers = [];
  toSaveToStockItem: ReplacementStockItem = null;
  lastValueOfToggles = {
    dataMediaOkLogisticsDepartment: null,
    dataMediaOkTechnicalDepartment: null
  };

  stockItems$: Observable<Array<ReplacementStockItem>>;

  constructor(
    private store$: Store<ApplicationState>,
    private formService: FormsService,
    private actions$: Actions,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<EditCustomerHardwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { stockItem: StockItem }
  ) {
    super();
  }

  get dataMediumFormArray(): FormArray {
    return this.form.controls.dataMediums as FormArray;
  }

  get mediaFormTemplate(): FormGroup {
    return this.fb.group({
      '@id': this.fb.control(null),
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control(''),
      storageSystemSize: this.fb.control(0, []),
      storageSystemSerialNumber: this.fb.control(null, []),
      storageNumber: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(CustomerReplacementStockItemsSelectors.isLoading),
      this.store$.select(CustomerStockItemsSelectors.isLoading),
      this.store$.select(DataMediumsSelectors.isLoading)
    ]);
    this.initForm();
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }

  makeFg(formGroup: any): FormGroup {
    return formGroup as FormGroup;
  }

  fg(name): FormGroup {
    return this.form.get(name) as FormGroup;
  }

  updateSelectedHardwareTyp(value: string): void {
    if (value === 'CustomerStockItem') {
      // TODO SELECT only possible Orders (OrderState === new)
      this.form.get('dataMediaOkLogisticsDepartment').enable();
    } else {
      // TODO SELECT only possible orders (Orderstate !== abgeschlossen)
      this.form.get('dataMediaOkLogisticsDepartment').disable();
    }
  }

  submitSave(closeAfterSave = true): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notifierService.show({
        type: 'error',
        message: 'Formular enthält Fehler. Bitte Prüfen! '
      });
      // return;
    }
    const rawValues = this.form.getRawValue();
    const payload = {
      ...rawValues,
      dataMediaOkLogisticsDepartment:
        rawValues.dataMediaOkLogisticsDepartment === 'true'
          ? true
          : rawValues.dataMediaOkLogisticsDepartment === 'false'
          ? false
          : null,
      dataMediaOkTechnicalDepartment:
        rawValues.dataMediaOkTechnicalDepartment === 'true'
          ? true
          : rawValues.dataMediaOkTechnicalDepartment === 'false'
          ? false
          : null
    };

    if (!payload.withEnclosure) {
      payload.enclosure = null;
    } else {
      delete payload.enclosure.storageNumber;
    }
    if (payload.dataMediums?.length > 0) {
      payload.dataMediums = payload.dataMediums.filter(
        e => e.storageSystemManufacturer !== null
      );
    }

    if (this.form.get('hardwareTyp').value === 'CustomerStockItem') {
      this.actions$
        .pipe(
          ofType(CustomerStockItemsActions.UpdateCustomerStockItemSuccess),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomerStockItemsActions.UpdateCustomerStockItemFail)
            )
          )
        )

        .subscribe(({ response }) => {
          this.dialog.open(ShowCustomerHardwareDialogComponent, {
            data: { stockItem: response }
          });
          this.dialogRef.close();
        });

      this.actions$
        .pipe(
          ofType(CustomerStockItemsActions.UpdateCustomerStockItemFail),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(CustomerStockItemsActions.UpdateCustomerStockItemSuccess)
            )
          )
        )
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });
      this.store$.dispatch(
        CustomerStockItemsActions.UpdateCustomerStockItem({
          iri: extractIri(this.data.stockItem),
          payload
        })
      );
    } else {
      this.actions$
        .pipe(
          ofType(
            CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess
          ),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemFail
              )
            )
          )
        )

        .subscribe(({ response }) => {
          this.dialog.open(ShowCustomerHardwareDialogComponent, {
            data: { stockItem: response }
          });
          this.dialogRef.close();
        });

      this.actions$
        .pipe(
          ofType(
            CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemFail
          ),
          take(1),
          takeUntil(this.onDestroy$),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess
              )
            )
          )
        )
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });
      this.store$.dispatch(
        CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItem(
          {
            iri: extractIri(this.data.stockItem),
            payload
          }
        )
      );
    }
  }

  close(reason: string = null): void {
    // this.store$.dispatch(RouterActions.Go({path: ['warehouse']}));
    this.dialogRef.close(reason);
  }

  showLabelWindow(serial: any): void {
    window.print();
  }

  addDataMediaLine(): void {
    (this.form.controls.dataMediums as FormArray).push(this.mediaFormTemplate);
  }

  toggleBtnClicked(field: string, valueClicked: any): void {
    if (this.lastValueOfToggles[field] !== valueClicked) {
      this.lastValueOfToggles[field] = valueClicked;
    } else {
      this.form.get(field).setValue(null);
      this.form.get(field).markAsDirty();
      this.lastValueOfToggles[field] = null;
    }
  }

  deleteDataMediaLine(index: number): void {
    (this.form.controls.dataMediums as FormArray).removeAt(index);
  }

  private initForm(): void {
    this.form = this.fb.group({
      hardwareTyp: this.fb.control('CustomerStockItem', [Validators.required]),
      order: this.fb.control(null, [Validators.required]),
      storageSystemType: this.fb.control(null, [Validators.required]),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(''),
      accessories: this.fb.control(''),
      dataMediaOkLogisticsDepartment: this.fb.control(null),
      dataMediaOkTechnicalDepartment: this.fb.control(null),
      withEnclosure: this.fb.control(true),
      enclosure: this.mediaFormTemplate,
      dataMediums: this.fb.array([this.mediaFormTemplate])
    });
    this.form
      .get('withEnclosure')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(withEnclosure => {
        if (withEnclosure) {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.enable();
          });
        } else {
          Object.values(
            (this.form.get('enclosure') as FormGroup).controls
          ).forEach(e => {
            e.disable();
          });
        }
      });

    if (this.data?.stockItem) {
      console.log(this.data.stockItem);
      if (this.data.stockItem.dataMediums?.length > 1) {
        this.data.stockItem.dataMediums.forEach((a, i) => {
          if (i === 0) {
            return;
          }
          this.addDataMediaLine();
        });
      }
      this.form.patchValue(this.data?.stockItem);
      if (this.data?.stockItem?.enclosure?.storageSystemSerialNumber) {
        this.form.get('withEnclosure').setValue(true);
      } else {
        this.form.get('withEnclosure').setValue(false);
      }
      this.form
        .get('dataMediaOkLogisticsDepartment')
        .setValue(
          this.data?.stockItem?.dataMediaOkLogisticsDepartment === true
            ? 'true'
            : this.data?.stockItem?.dataMediaOkLogisticsDepartment === false
            ? 'false'
            : null
        );
      this.form
        .get('dataMediaOkTechnicalDepartment')
        .setValue(
          this.data?.stockItem?.dataMediaOkTechnicalDepartment
            ? 'true'
            : this.data?.stockItem?.dataMediaOkTechnicalDepartment === false
            ? 'false'
            : null
        );
      this.lastValueOfToggles.dataMediaOkLogisticsDepartment = this.form.get(
        'dataMediaOkLogisticsDepartment'
      ).value;
      this.lastValueOfToggles.dataMediaOkTechnicalDepartment = this.form.get(
        'dataMediaOkTechnicalDepartment'
      ).value;

      if (this.data?.stockItem['@type'] === 'CustomerStockItem') {
        this.form.get('hardwareTyp').setValue('CustomerStockItem');
      } else if (
        this.data?.stockItem['@type'] === 'CustomerReplacementStockItem'
      ) {
        this.form.get('hardwareTyp').setValue('CustomerReplacementStockItem');
      } else {
        console.log('wrong Type', this.data?.stockItem['@type']);
      }
    }
  }
}
