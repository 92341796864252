import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';
import {AbstractApiResponse} from "../../../shared/models";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_SHIPMENT = '[Shipping Module] Create Shipment';
export const CREATE_SHIPMENT_SUCCESS = '[Shipping Module] Create Shipment Success';
export const CREATE_SHIPMENT_FAIL = '[Shipping Module] Create Shipment Fail';

export const CreateShipment = createAction(
  CREATE_SHIPMENT,
  props<{ payload: fromModuleModels.Shipment | any }>()
);

export const CreateShipmentSuccess = createAction(
  CREATE_SHIPMENT_SUCCESS,
  props<{ response: any }>()
);

export const CreateShipmentFail = createAction(
  CREATE_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_SHIPMENT = '[Shipping Module] Read Shipment';
export const READ_SHIPMENT_SUCCESS = '[Shipping Module] Read Shipment Success';
export const READ_SHIPMENT_FAIL = '[Shipping Module] Read Shipment Fail';

export const ReadShipment = createAction(
  READ_SHIPMENT,
  props<{ iri: string }>()
);

export const ReadShipmentSuccess = createAction(
  READ_SHIPMENT_SUCCESS,
  props<{ response: any }>()
);

export const ReadShipmentFail = createAction(
  READ_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_SHIPMENTS = '[Shipping Module] Read Shipments';
export const READ_SHIPMENTS_SUCCESS = '[Shipping Module] Read Shipments Success';
export const READ_SHIPMENTS_FAIL = '[Shipping Module] Read Shipments Fail';

export const ReadShipments = createAction(
  READ_SHIPMENTS,
  props<{ page: number, params?: any }>()
);

export const ReadShipmentsSuccess = createAction(
  READ_SHIPMENTS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadShipmentsFail = createAction(
  READ_SHIPMENTS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_SHIPMENT = '[Shipping Module] Update Shipment';
export const UPDATE_SHIPMENT_SUCCESS = '[Shipping Module] Update Shipment Success';
export const UPDATE_SHIPMENT_FAIL = '[Shipping Module] Update Shipment Fail';

export const UpdateShipment = createAction(
  UPDATE_SHIPMENT,
  props<{ iri: string, payload: fromModuleModels.Shipment }>()
);

export const UpdateShipmentSuccess = createAction(
  UPDATE_SHIPMENT_SUCCESS,
  props<{ response: any }>()
);

export const UpdateShipmentFail = createAction(
  UPDATE_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Cancel

export const CANCEL_SHIPMENT = '[Shipping Module] Cancel Shipment';
export const CANCEL_SHIPMENT_SUCCESS = '[Shipping Module] Cancel Shipment Success';
export const CANCEL_SHIPMENT_FAIL = '[Shipping Module] Cancel Shipment Fail';

export const CancelShipment = createAction(
  CANCEL_SHIPMENT,
  props<{ iri: string, payload: { workflow: string, transition: string } }>()
);

export const CancelShipmentSuccess = createAction(
  CANCEL_SHIPMENT_SUCCESS,
  props<{ response: any }>()
);

export const CancelShipmentFail = createAction(
  CANCEL_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Confirm

export const CONFIRM_SHIPMENT = '[Shipping Module] Confirm Shipment';
export const CONFIRM_SHIPMENT_SUCCESS = '[Shipping Module] Confirm Shipment Success';
export const CONFIRM_SHIPMENT_FAIL = '[Shipping Module] Confirm Shipment Fail';

export const ConfirmShipment = createAction(
  CONFIRM_SHIPMENT,
  props<{ iri: string, payload: { workflow: string, transition: string } }>()
);

export const ConfirmShipmentSuccess = createAction(
  CONFIRM_SHIPMENT_SUCCESS,
  props<{ response: any }>()
);

export const ConfirmShipmentFail = createAction(
  CONFIRM_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_SHIPMENT = '[Shipping Module] Delete Shipment';
export const DELETE_SHIPMENT_SUCCESS = '[Shipping Module] Delete Shipment Success';
export const DELETE_SHIPMENT_FAIL = '[Shipping Module] Delete Shipment Fail';

export const DeleteShipment = createAction(
  DELETE_SHIPMENT,
  props<{ iri: string }>()
);

export const DeleteShipmentSuccess = createAction(
  DELETE_SHIPMENT_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteShipmentFail = createAction(
  DELETE_SHIPMENT_FAIL,
  props<{ response: HttpErrorResponse }>()
);
