import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { OrdersActions } from '../../store';
import { filter, takeUntil } from 'rxjs/operators';
import { OrdersService } from '../../services';
import { NotifierService } from 'angular-notifier';
import { Actions, ofType } from '@ngrx/effects';
import { BindingOrderDetailFormComponent } from '../binding-order-detail-form/binding-order-detail-form.component';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';

@Component({
  selector: 'app-binding-order-dialog',
  styleUrls: ['./binding-order-dialog.component.scss'],
  template: `
    <div class="m-1">
      <div class="row m-0">
        <div class="col-4 pt-3 ps-3 pb-3">
          <app-dialog-header [disableCloseBtn]="true">
            <h2>Analyse und verbindliche Bestellung versenden</h2>
          </app-dialog-header>

          <div mat-dialog-content>
            <app-special-agreements-selector
              [order$]="data.order$"
              (updateFormData)="handleUpdateSpecialAgreementFormData($event)"
            ></app-special-agreements-selector>

            <span class="heading--h3">Details</span>
            <app-binding-order-detail-form
              [order$]="data.order$"
              (updateFormData)="handleUpdateDetailFormData($event)"
            >
            </app-binding-order-detail-form>

            <app-initial-costs-form
              [order$]="data.order$"
              (updateFormData)="handleUpdateDetailFormData($event)"
            ></app-initial-costs-form>
            <app-service-order-items
              [order$]="data.order$"
            ></app-service-order-items>
          </div>
          <div></div>
        </div>

        <div class="col-8 background-grey p-3 pos-relative">
          <app-dialog-header></app-dialog-header>

          <div mat-dialog-content style="margin-bottom: 68px;">
            <span class="heading--h3 m-b--32"
              >Hinzugefügte Ersatzdatenträger</span
            >

            <app-product-order-items
              [order$]="data.order$"
            ></app-product-order-items>

            <span class="heading--h3 m-b--32"
              >Neuen Datenträger hinzufügen</span
            >

            <div class="m-b--48 pos-relative">
              <app-order-product-add-list
                [order$]="data.order$"
              ></app-order-product-add-list>
            </div>
            <app-analysis-result
              [order$]="data.order$"
              (updateFormData)="handleUpdateDetailFormData($event)"
            ></app-analysis-result>
          </div>
          <div
            mat-dialog-actions
            class="me-2"
            style="justify-content: end; position: absolute; height: 68px; right: 0; bottom: 0; left:0; background: #f6f6f6;"
            (click)="bindingOrderForm.of.markAllAsTouched()"
          >
            <button
              class="m-r--8"
              mat-flat-button
              color="green"
              (click)="handlePrepareUpdateOrder()"
            >
              <mat-icon class="m-r--8">description</mat-icon>
              <span>Speichern</span>
            </button>

            <button
              mat-flat-button
              color="blue"
              class="m-r--8"
              (click)="handleRequestDownloadBindingOrder()"
              [disabled]="!isValid()"
            >
              <mat-icon class="m-r--8">download</mat-icon>
              <span>Vorschau herunterladen</span>
            </button>

            <span
              [matTooltip]="
                !canBeSend() ? 'Auftrag nicht in richtigem Zustand!' : ''
              "
            >
              <button
                mat-flat-button
                [color]="wasSend() ? 'orange' : 'blue'"
                [disabled]="!canBeSend() || !isValid() || wasSendWhileOpen"
                (click)="handleSendBindingOrder()"
              >
                <mat-icon class="m-r--8">shopping_basket</mat-icon>
                <span>Verbindliche Bestellung versenden</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ng-template #notification let-notificationData="notification">
      <span [innerHTML]="notificationData.message"></span>
    </ng-template>
  `
})
export class BindingOrderDialogComponent implements OnInit, OnDestroy {
  order: Order;
  tempOrder: Order;
  formHasChanges = false;
  wasSendWhileOpen = false;
  onDestroy$: Subject<any> = new Subject<any>();
  @ViewChild('notification') notificationTemplate;
  @ViewChild(BindingOrderDetailFormComponent)
  bindingOrderForm!: BindingOrderDetailFormComponent;

  constructor(
    public orderService: OrdersService,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: { order$: Observable<Order> }
  ) {}

  ngOnInit(): void {
    this.data.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.tempOrder = { '@id': order['@id'] };
        this.formHasChanges = false;
      });
    this.actions$
      .pipe(
        ofType(OrdersActions.SendBindingOrderSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.wasSendWhileOpen = true;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  isValid(): boolean {
    if (!this.order) {
      return false;
    }
    if (!this.order.replacementDataMediumSource) {
      return false;
    }
    if (!this.order.shippingProviderToCustomer) {
      return false;
    }
    if (!this.order.disposalType) {
      return false;
    }
    return true;
  }

  handleSendBindingOrder(): void {
    this.dialog
      .open(WriteEMailDialogComponent, {
        data: {
          type: 'bindingOrder',
          entity$: of(this.order)
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value) {
        }
      });
  }

  handlePrepareUpdateOrder(): void {
    // console.log(this.tempOrder);
    if (this.tempOrder.analysisResult) {
      this.store$.dispatch(
        OrdersActions.UpdateOrderAnalysisResult({
          iri: this.order['@id'],
          payload: { analysisResult: this.tempOrder.analysisResult }
        })
      );
    }
    this.store$.dispatch(
      OrdersActions.UpdateOrder({
        iri: this.order['@id'],
        payload: {
          ...this.tempOrder
        }
      })
    );
  }

  canBeSend(): boolean {
    return !!this.order.potentialActions?.find(
      e => !e.error && e.transition.indexOf('to_bo_sent') > -1
    );
  }

  wasSend(): boolean {
    return (
      this.wasSendWhileOpen ||
      !!this.order.potentialActions?.find(
        e => !e.error && e.transition.indexOf('bo_sent_to') > -1
      )
    );
  }

  handleRequestDownloadBindingOrder(): void {
    this.store$.dispatch(
      OrdersActions.ReadBindingOrderPDF({ iri: this.order['@id'] })
    );
  }

  handleUpdateDetailFormData($event: any): void {
    this.formHasChanges = true;
    this.tempOrder = { ...this.tempOrder, ...$event };
  }

  handleUpdateSpecialAgreementFormData($event: any): void {
    this.formHasChanges = true;
    this.tempOrder = { ...this.tempOrder, ...$event };
  }
}
