import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';


import {ObjectsUtility} from '../../../shared/utilities/objects.utility';
import {getUuidFromIri, StringsUtility} from '../../../shared/utilities/strings.utility';
import {Administrator} from '../../../administrators/models';
import {isLoadingArray, loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {select, Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MatDialog} from '@angular/material/dialog';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {AdministratorsActions} from '../../../administrators/store';
import {
  AnalysisPriorityModesSelectors,
  DataRecoveryPriorityModesSelectors,
  LabLocationsSelectors, OperatingSystemsSelectors, ReplacementDataMediumSourcesSelectors
} from '../../../master-data/store/selectors';
import {
  AnalysisPriorityModesActions,
  DataRecoveryPriorityModesActions,
  LabLocationsActions, OperatingSystemsActions, ReplacementDataMediumSourcesActions
} from '../../../master-data/store';
import {
  AnalysisPriorityMode,
  DataRecoveryPriorityMode,
  LabLocation,
  OperatingSystem, ReplacementDataMediumSource
} from '../../../master-data/models';
import {Observable, Subject} from 'rxjs';
import {Order} from '../../models';
import {filter, takeUntil} from 'rxjs/operators';
import {TicketCommentsActions, TicketsActions} from '../../../tickets/store';
import {Ticket, TicketComment} from '../../../tickets/models';
import {TicketCommentsSelectors, TicketsSelectors} from '../../../tickets/store/selectors';
import {ProductOrderItemsSelectors} from '../../store/selectors';
import {ProductOrderItemsActions} from '../../store';
import {ProductOrderItem} from '../../models';

@Component({
  selector: 'app-order-ticket-details',
  styleUrls: ['order-ticket-details.component.scss'],
  template: `

    <div class="card pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <div class="card__heading">
        <span>Ticket zum Auftrag</span>
      </div>

      <div class="card__content p-a--24" *ngIf="ticket">

        <dl class="list--dl grid" *ngIf="order$|async">

          <dt class="column-5">Ticket:</dt>
          <dd class="column-9"><a class="text-decoration-none"
                                  [routerLink]="['/tickets',getTicketUUID(ticket)]">{{ ticket.ticketNumber }}</a></dd>

          <dt class="column-5">Letzter Kommentar:</dt>
          <dd class="column-9">
            <ng-container *ngIf="getLatestComment(); else noComments">

              <strong>{{ getCommentCreatorName() }}</strong>, {{ getLatestComment()['createdAt'] | date: 'dd.MM.Y' }}
              <div [innerHTML]="getLatestComment()['content'] | safeHtml"></div>
            </ng-container>
          </dd>

          <dt class="column-5">Encryption Key:</dt>
          <dd class="column-9">{{ order?.encryptionKey ? order.encryptionKey : '-'}}</dd>

          <dt class="column-5">ED Betriebssystem:</dt>
          <dd
            class="column-9">{{ order?.replacementDataMediumOperatingSystem ? getOrderPropertyName('operatingSystemsEntities', 'replacementDataMediumOperatingSystem') : '-'}}</dd>

          <dt class="column-5">Ersatzdatenträger:</dt>
          <dd class="column-9 data-media">

            <div *ngFor="let item of productOrderItems; let last = last" class="item m-b--16 p-b--16"
                 [class.no-border]="last">
              <div>
                <span>{{ item?.product?.storageSystemManufacturer?.name }}</span>,
                <span>{{ item?.product?.storageSystemSize }} GB</span>,
                <span>{{ item?.replacementDataMedium?.storageSystemSerialNumber }}</span>
              </div>

              <small style="display: block">
                <span>Lagerplatz {{ item?.replacementDataMedium?.stockItem?.storageLocation?.storageLocationNumber }}</span>
                <br>
                <span>Einlagerungs-Nr. {{ item?.replacementDataMedium?.storageNumber }}</span>
              </small>
            </div>
          </dd>
        </dl>
      </div>

      <div class="card__content p-a--24" *ngIf="!ticket">
        Zu dem Autrag existiert noch kein Ticket
      </div>
    </div>

    <ng-template #noComments>
      <p>Dieses Ticket hat noch keine Kommentare</p>
    </ng-template>

    <!--<pre>{{ ticket | json }}</pre>-->
  `
})
export class OrderTicketDetailsComponent implements OnInit, OnDestroy {

  administratorsEntities: { [iri: string]: Administrator };
  analysisPriorityModesEntities: { [iri: string]: AnalysisPriorityMode };
  dataRecoveryPriorityModesEntities: { [iri: string]: DataRecoveryPriorityMode };
  labLocationsEntities: { [iri: string]: LabLocation };
  operatingSystemsEntities: { [p: string]: OperatingSystem };
  replacementDataMediumSourcesEntities: { [iri: string]: ReplacementDataMediumSource };

  isLoading$: Observable<boolean>;
  @Input() order$: Observable<Order>;
  order: Order;
  productOrderItems: Array<ProductOrderItem>;
  ticket: Ticket;
  ticketComments: Array<TicketComment>;

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(AdministratorsSelectors.isLoading),
      this.store$.select(AnalysisPriorityModesSelectors.isLoading),
      this.store$.select(DataRecoveryPriorityModesSelectors.isLoading),
      this.store$.select(LabLocationsSelectors.isLoading),
      this.store$.select(OperatingSystemsSelectors.isLoading),
      this.store$.select(ReplacementDataMediumSourcesSelectors.isLoading),
      this.store$.select(TicketCommentsSelectors.isLoading),
      this.store$.select(ProductOrderItemsSelectors.isLoading),
      this.store$.select(TicketsSelectors.isLoading),
    ]);

    this.loadAdministrators();
    this.loadAnalysisPriorityModes();
    this.loadDataRecoveryPriorityModes();
    this.loadLabLocations();
    this.loadOperatingSystems();
    this.loadReplacementDataMediumSources();
    this.order$
      .pipe(takeUntil(this.onDestroy$), filter(order => !!order))
      .subscribe((order) => {
        this.order = order;
        this.loadTicket();
        this.loadTicketComments();
        this.loadProductOrderItems();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getTicketUUID(ticket: Ticket): string {
    return getUuidFromIri(ticket['@id']);
  }

  getLatestComment(): TicketComment {
    return !!this?.ticketComments && !!this.ticketComments.length ? [...this.ticketComments].pop() : null;
  }

  keys(obj): string {
    return ObjectsUtility.getObjectKeys(obj).join('\n');
  }

  getCommentCreatorName(): string {
    const createdBy = this.getLatestComment().createdBy;
    return StringsUtility.formatAdministratorName(this.administratorsEntities[createdBy]);
  }

  getOrderPropertyName(entitiesSet: string, orderProperty: string): string {
    return this[entitiesSet][this.order[orderProperty]]?.name ?
      this[entitiesSet][this.order[orderProperty]].name :
      '-';
  }

  private loadAdministrators(): void {
    this.store$.select(AdministratorsSelectors.sEntities).subscribe(entities => {
      this.administratorsEntities = entities;
    });
    loadIfNotLoaded(this.store$, AdministratorsSelectors.isLoaded, AdministratorsActions.ReadAdministrators());
  }

  private loadAnalysisPriorityModes(): void {
    this.store$.select(AnalysisPriorityModesSelectors.selectAnalysisPriorityModesEntities).subscribe(entities => {
      this.analysisPriorityModesEntities = entities;
    });
    loadIfNotLoaded(this.store$, AnalysisPriorityModesSelectors.isLoaded, AnalysisPriorityModesActions.ReadAnalysisPriorityModes());
  }

  private loadDataRecoveryPriorityModes(): void {
    this.store$.select(DataRecoveryPriorityModesSelectors.sEntities).subscribe(entities => {
      this.dataRecoveryPriorityModesEntities = entities;
    });
    loadIfNotLoaded(this.store$, DataRecoveryPriorityModesSelectors.isLoaded,
      DataRecoveryPriorityModesActions.ReadDataRecoveryPriorityModes());
  }

  private loadLabLocations(): void {
    this.store$.select(LabLocationsSelectors.sEntities).subscribe(entities => {
      this.labLocationsEntities = entities;
    });
    loadIfNotLoaded(this.store$, LabLocationsSelectors.isLoaded, LabLocationsActions.ReadLabLocations());
  }

  private loadOperatingSystems(): void {
    this.store$.select(OperatingSystemsSelectors.selectOperatingSystemsEntities).subscribe(entities => {
      this.operatingSystemsEntities = entities;
    });
    loadIfNotLoaded(this.store$, OperatingSystemsSelectors.isLoaded, OperatingSystemsActions.ReadOperatingSystems());

  }

  private loadReplacementDataMediumSources(): void {
    this.store$.select(ReplacementDataMediumSourcesSelectors.selectReplacementDataMediumSourcesEntities).subscribe(entities => {
      this.replacementDataMediumSourcesEntities = entities;
    });
    loadIfNotLoaded(this.store$, ReplacementDataMediumSourcesSelectors.isLoaded,
      ReplacementDataMediumSourcesActions.ReadReplacementDataMediumSources());

  }

  private loadTicket(): void {
    if (!this.order || !this.order.ticket) {
      return;
    }
    const iri = this.order.ticket['@id'];
    this.store$.dispatch(TicketsActions.ReadTicket({iri}));
    this.store$.select(TicketsSelectors.sByIri, {iri})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ticket) => {
        this.ticket = ticket;
      });
  }

  private loadTicketComments(): void {
    if (!this.order.ticket) {
      return;
    }
    const iri = this.order.ticket['@id'];
    this.store$.dispatch(TicketCommentsActions.ReadTicketComments({
      page: -1,
      params: {'ticket.uuid': getUuidFromIri(iri), 'order[createdAt]': 'desc'}
    }));
    this.store$.select(TicketCommentsSelectors.sByTicketIri, {iri})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ticket) => {
        this.ticketComments = ticket;
      });
  }

  private loadProductOrderItems(): void {
    if (!this.order.ticket) {
      return;
    }
    const iri = this.order.ticket['@id'];
    this.store$.dispatch(ProductOrderItemsActions.ReadProductOrderItems({orderUuid: getUuidFromIri(iri)}));

    this.store$.select(ProductOrderItemsSelectors.selectProductOrderItemsForOrder, {iri})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((entities) => {
        this.productOrderItems = entities;
      });

  }
}
