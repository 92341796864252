import { Component, OnInit } from '@angular/core';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-selling-prices-list-widget',
  styleUrls: ['./open-selling-prices-list-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header ps-1">
          <span>
            <button mat-icon-button (click)="loadData()">
              <mat-icon>autorenew</mat-icon></button
            >Aktuell Offene Verkaufspreise</span
          >
        </div>
        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenSellingPricesListWidgetComponent extends BaseOnDestroyComponent
  implements OnInit {
  states: string[];

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.states = [
      'analysis_provide_selling_prices_dr_ext',
      'analysis_provide_selling_prices_dr_remote',
      'analysis_provide_selling_prices_dr_de',
      'data_recovery_provide_selling_prices_dr_ext',
      'data_recovery_provide_selling_prices_dr_remote',
      'data_recovery_provide_selling_prices_dr_de'
    ];
  }

  loadData(): void {
    this.states = [...this.states];
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }
}
