import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_DEPARTMENT_LOGO = '[Master Data] Create DepartmentLogo';
export const CREATE_DEPARTMENT_LOGO_SUCCESS = '[Master Data] Create DepartmentLogo Success';
export const CREATE_DEPARTMENT_LOGO_STATUS = '[Master Data] Create DepartmentLogo Status';
export const CREATE_DEPARTMENT_LOGO_FAIL = '[Master Data] Create DepartmentLogo Fail';
export const CreateDepartmentLogo = createAction(
  CREATE_DEPARTMENT_LOGO,
  props<{ payload: { file: File } }>()
);
export const CreateDepartmentLogoSuccess = createAction(
  CREATE_DEPARTMENT_LOGO_SUCCESS,
  props<{ response: fromModuleModels.DepartmentLogo }>()
);
export const CreateDepartmentLogoStatus = createAction(
  CREATE_DEPARTMENT_LOGO_STATUS,
  props<{ response: any }>()
);

export const CreateDepartmentLogoFail = createAction(
  CREATE_DEPARTMENT_LOGO_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_DEPARTMENT_LOGOS = '[Master Data] Read DepartmentLogos';
export const READ_DEPARTMENT_LOGOS_SUCCESS = '[Master Data] Read DepartmentLogos Success';
export const READ_DEPARTMENT_LOGOS_FAIL = '[Master Data] Read DepartmentLogos Fail';
export const ReadDepartmentLogos = createAction(
  READ_DEPARTMENT_LOGOS
);
export const ReadDepartmentLogosSuccess = createAction(
  READ_DEPARTMENT_LOGOS_SUCCESS,
  props<{ response: any }>()
);
export const ReadDepartmentLogosFail = createAction(
  READ_DEPARTMENT_LOGOS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Read 1n
export const READ_DEPARTMENT_LOGO = '[Master Data] Read DepartmentLogo';
export const READ_DEPARTMENT_LOGO_SUCCESS = '[Master Data] Read DepartmentLogo Success';
export const READ_DEPARTMENT_LOGO_FAIL = '[Master Data] Read DepartmentLogo Fail';
export const ReadDepartmentLogo = createAction(
  READ_DEPARTMENT_LOGO,
  props<{ iri: string }>()
);
export const ReadDepartmentLogoSuccess = createAction(
  READ_DEPARTMENT_LOGO_SUCCESS,
  props<{ response }>()
);
export const ReadDepartmentLogoFail = createAction(
  READ_DEPARTMENT_LOGO_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_DEPARTMENT_LOGO = '[Master Data] Update DepartmentLogo';
export const UPDATE_DEPARTMENT_LOGO_SUCCESS = '[Master Data] Update DepartmentLogo Success';
export const UPDATE_DEPARTMENT_LOGO_FAIL = '[Master Data] Update DepartmentLogo Fail';
export const UpdateDepartmentLogo = createAction(
  UPDATE_DEPARTMENT_LOGO,
  props<{ iri: string, payload: fromModuleModels.DepartmentLogo }>()
);
export const UpdateDepartmentLogoSuccess = createAction(
  UPDATE_DEPARTMENT_LOGO_SUCCESS,
  props<{ response: fromModuleModels.DepartmentLogo }>()
);
export const UpdateDepartmentLogoFail = createAction(
  UPDATE_DEPARTMENT_LOGO_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_DEPARTMENT_LOGO = '[Master Data] Delete DepartmentLogo';
export const DELETE_DEPARTMENT_LOGO_SUCCESS = '[Master Data] Delete DepartmentLogo Success';
export const DELETE_DEPARTMENT_LOGO_FAIL = '[Master Data] Delete DepartmentLogo Fail';
export const DeleteDepartmentLogo = createAction(
  DELETE_DEPARTMENT_LOGO,
  props<{ iri: string }>()
);
export const DeleteDepartmentLogoSuccess = createAction(
  DELETE_DEPARTMENT_LOGO_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteDepartmentLogoFail = createAction(
  DELETE_DEPARTMENT_LOGO_FAIL,
  props<{ response: HttpErrorResponse }>()
);
