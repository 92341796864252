import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_REMOTE_ACCESS_REASON = '[Tickets Module] Read Remote Access Reason';
export const READ_REMOTE_ACCESS_REASON_SUCCESS = '[Tickets Module] Read Remote Access Reason Success';
export const READ_REMOTE_ACCESS_REASON_FAIL = '[Tickets Module] Read Remote Access Reason Fail';

export const ReadRemoteAccessReason = createAction(
  READ_REMOTE_ACCESS_REASON,
  props<{ iri: string }>()
);

export const ReadRemoteAccessReasonSuccess = createAction(
  READ_REMOTE_ACCESS_REASON_SUCCESS,
  props<{ response: any }>()
);

export const ReadRemoteAccessReasonFail = createAction(
  READ_REMOTE_ACCESS_REASON_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_REMOTE_ACCESS_REASONS = '[Tickets Module] Read Remote Access Reasons';
export const READ_REMOTE_ACCESS_REASONS_SUCCESS = '[Tickets Module] Read Remote Access Reasons Success';
export const READ_REMOTE_ACCESS_REASONS_FAIL = '[Tickets Module] Read Remote Access Reasons Fail';

export const ReadRemoteAccessReasons = createAction(
  READ_REMOTE_ACCESS_REASONS,
);

export const ReadRemoteAccessReasonsSuccess = createAction(
  READ_REMOTE_ACCESS_REASONS_SUCCESS,
  props<{ response: any }>()
);

export const ReadRemoteAccessReasonsFail = createAction(
  READ_REMOTE_ACCESS_REASONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
