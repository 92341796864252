import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_SERVICE_ORDER_ITEM = '[Orders Module] Create Service Order Item';
export const CREATE_SERVICE_ORDER_ITEM_SUCCESS = '[Orders Module] Create Service Order Item Success';
export const CREATE_SERVICE_ORDER_ITEM_FAIL = '[Orders Module] Create Service Order Item Fail';

export const CreateServiceOrderItem = createAction(
  CREATE_SERVICE_ORDER_ITEM,
  props<{ payload: fromModuleModels.ServiceOrderItem }>()
);

export const CreateServiceOrderItemSuccess = createAction(
  CREATE_SERVICE_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const CreateServiceOrderItemFail = createAction(
  CREATE_SERVICE_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_SERVICE_ORDER_ITEM = '[Orders Module] Read Service Order Item';
export const READ_SERVICE_ORDER_ITEM_SUCCESS = '[Orders Module] Read Service Order Item Success';
export const READ_SERVICE_ORDER_ITEM_FAIL = '[Orders Module] Read Service Order Item Fail';

export const ReadServiceOrderItem = createAction(
  READ_SERVICE_ORDER_ITEM,
  props<{ iri: string }>()
);

export const ReadServiceOrderItemSuccess = createAction(
  READ_SERVICE_ORDER_ITEM_SUCCESS,
  props<{ response: any }>()
);

export const ReadServiceOrderItemFail = createAction(
  READ_SERVICE_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_SERVICE_ORDER_ITEMS = '[Orders Module] Read Service Order Items';
export const READ_SERVICE_ORDER_ITEMS_SUCCESS = '[Orders Module] Read Service Order Items Success';
export const READ_SERVICE_ORDER_ITEMS_FAIL = '[Orders Module] Read Service Order Items Fail';

export const ReadServiceOrderItems = createAction(
  READ_SERVICE_ORDER_ITEMS,
  props<{ orderUuid: string }>()
);

export const ReadServiceOrderItemsSuccess = createAction(
  READ_SERVICE_ORDER_ITEMS_SUCCESS,
  props<{ response: any }>()
);

export const ReadServiceOrderItemsFail = createAction(
  READ_SERVICE_ORDER_ITEMS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_SERVICE_ORDER_ITEM = '[Orders Module] Update Service Order Item';
export const UPDATE_SERVICE_ORDER_ITEM_SUCCESS = '[Orders Module] Update Service Order Item Success';
export const UPDATE_SERVICE_ORDER_ITEM_FAIL = '[Orders Module] Update Service Order Item Fail';

export const UpdateServiceOrderItem = createAction(
  UPDATE_SERVICE_ORDER_ITEM,
  props<{ iri: string, payload: fromModuleModels.ServiceOrderItem }>()
);

export const UpdateServiceOrderItemSuccess = createAction(
  UPDATE_SERVICE_ORDER_ITEM_SUCCESS,
  props<{ response: fromModuleModels.ServiceOrderItem }>()
);

export const UpdateServiceOrderItemFail = createAction(
  UPDATE_SERVICE_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_SERVICE_ORDER_ITEM = '[Orders Module] Delete Service Order Item';
export const DELETE_SERVICE_ORDER_ITEM_SUCCESS = '[Orders Module] Delete Service Order Item Success';
export const DELETE_SERVICE_ORDER_ITEM_FAIL = '[Orders Module] Delete Service Order Item Fail';

export const DeleteServiceOrderItem = createAction(
  DELETE_SERVICE_ORDER_ITEM,
  props<{ iri: string }>()
);

export const DeleteServiceOrderItemSuccess = createAction(
  DELETE_SERVICE_ORDER_ITEM_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteServiceOrderItemFail = createAction(
  DELETE_SERVICE_ORDER_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
