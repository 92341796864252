import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order, ProofOfDestruction } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { ProofsOfDestructionSelectors } from '../../../orders/store/selectors';
import * as OrdersModuleActions from '../../../orders/store';
import { ProofsOfDestructionActions } from '../../../orders/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DestructionTypesSelectors,
  StorageSystemManufacturersSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import {
  DestructionTypesActions,
  StorageSystemManufacturersActions
} from '../../../master-data/store';
import {
  DestructionType,
  StorageSystemManufacturer
} from '../../../master-data/models';
import * as moment from 'moment/moment';
import { FormsService } from '../../services';
import { getUuidFromIri } from '../../utilities/strings.utility';
import { DataMediumsSelectors } from '../../../warehouse/store/selectors';
import { DataMediumsActions } from '../../../warehouse/store';
import { CustomerDataMedium } from '../../../warehouse/models';
import { Actions, ofType } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import { WriteEMailDialogComponent } from '../../components/write-email-dialog/write-email-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';
import { extractTypeByIri } from '../../utilities/objects.utility';

@Component({
  selector: 'app-action-box-to-disposal-proof-of-destruction-provided',
  styleUrls: [
    './action-box-to-disposal-proof-of-destruction-provided.component.scss'
  ],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Vernichtungsnachweis erstellen</div>
              <div class="sub-header col-auto">
                Ein Vernichtungsnachweis ist gewünscht, bitte erstellen Sie
                diesen für die erforderlichen Datenträger.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right"></div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Datenträger Vernichtung</div>
          <div class="sub-header col-auto">
            Warte auf Erstellung Vernichtungsnachweis
          </div>
        </div>
      </div>
      <div
        class="additional-content p-4"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="mat-form-field col-12">
                <ng-select
                  placeholder="Art der Vernichtung"
                  [items]="destructionTypes$ | async"
                  [loading]="destructionTypesIsLoading$ | async"
                  bindValue="@id"
                  bindLabel="name"
                  [markFirst]="false"
                  [clearable]="false"
                  formControlName="destructionType"
                ></ng-select>
                <mat-error>
                  <app-form-error
                    [fieldName]="'destructionType'"
                    [formGroup]="form"
                  ></app-form-error>
                </mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <mat-label>Datum</mat-label>
                  <input
                    type="date"
                    matInput
                    formControlName="date"
                    readonly
                    required
                  />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'date'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-8">
            <mat-form-field>
              <mat-label>Beschreibung</mat-label>
              <textarea
                matInput
                formControlName="comment"
                cdkTextareaAutosize
                rows="6"
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="8"
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'comment'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <table
              class="bmo-table bmo-table-heading-uppercase bmo-table-bordered bmo-table-rounded bmo-table-hover bmo-table-clickable"
            >
              <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th class="th column-2">Einl-Nr.</th>
                  <th class="th column-2">Seriennummer</th>
                  <th class="th column-2">Typ</th>
                  <th class="th column-2">Hersteller</th>
                  <th class="th column-1">Speichergröße</th>
                  <th class="th column-5">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="customerDataMediaIsLoading$ | async">
                  <td colspan="6">
                    <app-loading-overlay></app-loading-overlay>
                  </td>
                </tr>
                <tr
                  *ngFor="let medium of customerDataMedia$ | async"
                  (click)="toggleDataMedium(medium)"
                >
                  <td>
                    <mat-icon
                      >{{
                        isChecked(medium)
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </mat-icon>
                  </td>
                  <td class="td column-2">{{ medium.storageNumber }}</td>
                  <td class="td column-2">
                    {{ medium.storageSystemSerialNumber }}
                  </td>
                  <td class="td column-2">
                    {{
                      medium?.storageSystemType?.name
                        ? medium.storageSystemType.name
                        : (medium | json)
                    }}
                  </td>
                  <td class="td column-2">
                    {{
                      getStorageSystemManufacturerName(
                        medium.storageSystemManufacturer
                      )
                    }}
                  </td>
                  <td class="td column-1">{{ medium.storageSystemSize }} GB</td>
                  <td class="td column-5">{{ medium.state }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right mt-3">
            <span
              class="mat-error"
              *ngIf="form.get('customerDataMedia').value.length <= 0"
              >Bitte mindestens einen Datenträger auswählen</span
            >
            <button
              mat-flat-button
              color="gray"
              class="text-color-black"
              (click)="handleSubmitAction()"
            >
              <mat-icon class="m-r--8">save</mat-icon>
              <span>Speichern & Aktion schließen</span>
            </button>
            <button
              class="ms-3"
              mat-flat-button
              color="green"
              (click)="handleSubmitAndSendAction()"
            >
              <mat-icon class="m-r--8">mail</mat-icon>
              <span>Speichern & per E-Mail versenden</span>
            </button>
          </div>
        </div>
      </div>
      <!--<pre>{{possibleAction|json}}</pre>-->
    </div>
  `
})
export class ActionBoxToDisposalProofOfDestructionProvidedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;
  isEdit = false;
  orderPODIri: string = null;

  customerDataMedia$: Observable<Array<CustomerDataMedium>>;
  customerDataMediaIsLoading$: Observable<boolean>;
  proofOfDestruction$: Observable<ProofOfDestruction>;
  destructionTypes$: Observable<Array<DestructionType>>;
  destructionTypesIsLoading$: Observable<boolean>;
  storageSystemManufacturersEntities: {
    [iri: string]: StorageSystemManufacturer;
  };

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private notify: NotifierService,
    private dialog: MatDialog
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDestructionTypes();
    this.loadStorageSystemManufacturersEntities();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('_to_disposal_proof_of_destruction_provided') >
              -1 && !e.error
        );
        this.orderPODIri = order.proofOfDestruction;
        this.form.get('destructionType').setValue(order.disposalType);
        this.loadProofOfDestruction(order);
        this.loadCustomerDataMedium(order);
      });
  }

  getStorageSystemManufacturerName(
    manufacturer: string | { name?: string }
  ): string {
    if (typeof manufacturer !== 'string' && manufacturer?.name) {
      return manufacturer.name;
    }
    if (typeof manufacturer === 'string') {
      return this.storageSystemManufacturersEntities[manufacturer]?.name;
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      destructionType: this.fb.control(null, [Validators.required]),
      date: this.fb.control(
        moment()
          .toISOString(true)
          .substring(0, 10),
        [Validators.required]
      ),
      customerDataMedia: this.fb.control([], Validators.required),
      comment: this.fb.control('')
    });
  }

  isChecked(medium: CustomerDataMedium): boolean {
    return this.form.get('customerDataMedia').value.indexOf(medium['@id']) > -1;
  }

  toggleDataMedium(medium: CustomerDataMedium): void {
    const value = [];
    value.push(...this.form.get('customerDataMedia').value);
    const indexOfElement = value.indexOf(medium['@id']);
    if (indexOfElement > -1) {
      value.splice(indexOfElement, 1);
    } else {
      value.push(medium['@id']);
    }
    this.form.get('customerDataMedia').patchValue(value);
  }

  handleMakeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleSubmitAction(): void {
    if (this.form.value.customerDataMedia.length <= 0) {
      this.notify.show({
        type: 'error',
        message: 'Bitte wenigstens einen Datenträger zur Zerstörung auswählen.'
      });
      return;
    }
    if (this.isEdit) {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.UpdateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.form.value
          }
        )
      );
    } else {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.CreateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.form.value
          }
        )
      );
    }
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          ProofsOfDestructionActions.UpdateProofOfDestructionSuccess,
          ProofsOfDestructionActions.CreateProofOfDestructionSuccess
        )
      )
      .subscribe(() => {
        this.form.markAsPristine();
        this.handleMakeTransition();
      });
  }

  handleSubmitAndSendAction(): void {
    if (this.form.value.customerDataMedia.length <= 0) {
      this.notify.show({
        type: 'error',
        message: 'Bitte wenigstens einen Datenträger zur Zerstörung auswählen.'
      });
      return;
    }
    if (this.isEdit) {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.UpdateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.form.value
          }
        )
      );
    } else {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.CreateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.form.value
          }
        )
      );
    }
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          ProofsOfDestructionActions.UpdateProofOfDestructionSuccess,
          ProofsOfDestructionActions.CreateProofOfDestructionSuccess
        )
      )
      .subscribe(() => {
        this.dialog
          .open(WriteEMailDialogComponent, {
            data: {
              type: 'proofOfDestruction',
              entity$: of(this.order)
            }
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe(value => {
            if (value) {
              this.handleMakeTransition();
            }
          });
      });
  }

  private loadCustomerDataMedium(order): void {
    const orderUUID = getUuidFromIri(order['@id']);
    this.customerDataMedia$ = this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, {
        orderIri: order['@id']
      })
      .pipe(
        map(e => e.filter(f => extractTypeByIri(f) === 'customer_data_media'))
      );
    this.customerDataMediaIsLoading$ = this.store$.select(
      DataMediumsSelectors.isLoading
    );
    this.customerDataMedia$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(list => list.length > 0)
      )
      .subscribe(mediaList => {
        if (this.form.get('customerDataMedia').value.length > 0) {
          this.form
            .get('customerDataMedia')
            .setValue(mediaList.map(e => e['@id']));
        }
      });
    this.proofOfDestruction$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(pod => !!pod)
      )
      .subscribe(pod => {
        this.isEdit = true;
        const values = {
          ...pod,
          date: moment(pod.date)
            .toISOString(true)
            .substring(0, 10)
        };
        this.form.patchValue(values);
        this.form.markAsUntouched();
      });
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          ProofsOfDestructionActions.CreateProofOfDestructionFail,
          ProofsOfDestructionActions.UpdateProofOfDestructionFail
        )
      )
      .subscribe(errors => {
        this.fs.mergeErrorsIntoForm(errors, this.form);
      });

    this.store$.dispatch(
      DataMediumsActions.ReadDataMediums({
        page: -1,
        params: { 'stockItem.order.uuid': orderUUID }
      })
    );
  }

  private loadProofOfDestruction(order): void {
    this.proofOfDestruction$ = this.store$.select(
      ProofsOfDestructionSelectors.selectProofOfDestructionForOrder,
      { iri: order['@id'] }
    );
    if (order?.proofOfDestruction) {
      this.store$.dispatch(
        ProofsOfDestructionActions.ReadProofOfDestruction({
          iri: order.proofOfDestruction
        })
      );
    }
  }

  private loadDestructionTypes(): void {
    this.destructionTypes$ = this.store$.select(
      DestructionTypesSelectors.selectDestructionTypes
    );
    this.destructionTypesIsLoading$ = this.store$.select(
      DestructionTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DestructionTypesSelectors.isLoaded,
      DestructionTypesActions.ReadDestructionTypes()
    );
  }

  private loadStorageSystemManufacturersEntities(): void {
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemManufacturersEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }
}
