import {createSelector} from '@ngrx/store';

import {CORRECTION_INVOICE_ITEMS_FEATURE_KEY, selectInvoicesModuleState} from '../reducers';

export const selectCorrectionInvoiceItemsState = createSelector(
  selectInvoicesModuleState,
  (state) => state[CORRECTION_INVOICE_ITEMS_FEATURE_KEY]
);

export const selectCorrectionInvoiceItemsEntities = createSelector(
  selectCorrectionInvoiceItemsState,
  (state) => state.entities
);

export const selectCorrectionInvoiceItems = createSelector(
  selectCorrectionInvoiceItemsEntities,
  (payments) => Object.keys(payments).map(iri => payments[iri])
);

export const selectCorrectionInvoiceItemByIndex = createSelector(
  selectCorrectionInvoiceItemsEntities,
  (customers, props) => customers[props.iri]
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCorrectionInvoiceItemsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCorrectionInvoiceItemsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCorrectionInvoiceItemsState,
  (state) => state.errors
);
