import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrdersActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  current: fromModuleModels.Order;
  entities: { [iri: string]: fromModuleModels.Order };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(OrdersActions.ReadSales, OrdersActions.ReadSalesChunk, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(
    OrdersActions.ReadSalesSuccess,
    OrdersActions.ReadSalesChunkSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      const totalItems = response['hydra:totalItems'];
      let pagination = {};
      const entities = items.reduce(
        (entities: { [iri: string]: fromModuleModels.Order }, item) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        // Note: Intentionally "clear" state when returning from single view or navigating tru list view
        {}
        // {...state.entities}
      );

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        pagination,
        totalItems,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    OrdersActions.ReadSalesFail,
    OrdersActions.ReadSalesChunkFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
