import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { BindingOrdersActions, OrdersActions } from '../actions';
import { OrdersService } from '../../services';
import { RouterActions } from '../../../application-state/store/actions';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class OrdersEffects {
  // CreateOrder$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(OrdersActions.CreateOrder),
  //     switchMap(({ payload }) => {
  //       return this.service.createOrder(payload).pipe(
  //         map((response: any) =>
  //           OrdersActions.CreateOrderSuccess({ response })
  //         ),
  //         catchError((response: HttpErrorResponse) =>
  //           of(OrdersActions.CreateOrderFail({ response }))
  //         )
  //       );
  //     })
  //   )
  // );
  ReadOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadOrder),
      mergeMap(({ iri, id }) => {
        const obs = id
          ? this.service.getOrderById(id)
          : this.service.getOrderByIri(iri);
        return obs.pipe(
          map((response: any) => OrdersActions.ReadOrderSuccess({ response })),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadOrderFail({ response }))
          )
        );
      })
    )
  );
  ReadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadOrders),
      mergeMap(({ page, params }) => {
        return this.service.readOrders(page, params).pipe(
          map((response: any) => OrdersActions.ReadOrdersSuccess({ response })),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadOrdersFail({ response }))
          )
        );
      })
    )
  );
  ReadOrderChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadOrderChunk),
      switchMap(({ uri }) => {
        return this.service.readOrderChunk(uri).pipe(
          map((response: any) =>
            OrdersActions.ReadOrderChunkSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadOrderChunkFail({ response }))
          )
        );
      })
    )
  );
  ReadSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadSales),
      switchMap(({ page, params }) => {
        return this.service.readSales(page, params).pipe(
          map((response: any) => OrdersActions.ReadSalesSuccess({ response })),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadSalesFail({ response }))
          )
        );
      })
    )
  );
  ReadSalesChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadSalesChunk),
      switchMap(({ uri }) => {
        return this.service.readSalesChunk(uri).pipe(
          map((response: any) =>
            OrdersActions.ReadSalesChunkSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadSalesChunkFail({ response }))
          )
        );
      })
    )
  );
  ReadSalesSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadSalesSummary),
      switchMap(({ params }) => {
        return this.service.readSalesSummary(-1, params).pipe(
          map((response: any) =>
            OrdersActions.ReadSalesSummarySuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadSalesSummaryFail({ response }))
          )
        );
      })
    )
  );
  UpdateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrder),
      switchMap(({ iri, payload }) => {
        return this.service.updateOrder(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateOrderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.UpdateOrderFail({ response }))
          )
        );
      })
    )
  );
  UpdateOrderAnalysisResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderAnalysisResult),
      switchMap(({ iri, payload }) => {
        return this.service.updateOrderAnalysisResult(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateOrderAnalysisResultSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.UpdateOrderAnalysisResultFail({ response }))
          )
        );
      })
    )
  );
  UpdateOrderAnalysisResultFromActionBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateOrderAnalysisResultFromActionBox),
      switchMap(({ iri, payload }) => {
        return this.service.updateOrderAnalysisResult(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateOrderAnalysisResultFromActionBoxSuccess({
              response
            })
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              OrdersActions.UpdateOrderAnalysisResultFromActionBoxFail({
                response
              })
            )
          )
        );
      })
    )
  );
  UpdateDisposalType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateDisposalType),
      switchMap(({ iri, payload }) => {
        return this.service.updateOrder(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateDisposalTypeSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.UpdateDisposalTypeFail({ response }))
          )
        );
      })
    )
  );
  UpdateAnalysisLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateAnalysisLocation),
      switchMap(({ iri, payload }) => {
        return this.service.updateAnalysisLocation(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateAnalysisLocationSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.UpdateAnalysisLocationFail({ response }))
          )
        );
      })
    )
  );
  MailOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.MailOrder),
      switchMap(({ iri, payload }) => {
        return this.service.sendMail(iri, payload).pipe(
          map(response => OrdersActions.MailOrderSuccess({ response })),
          catchError(response => of(OrdersActions.MailOrderFail({ response })))
        );
      })
    )
  );
  SendProofOfDestructionPDF = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.SendProofOfDestructionPDF),
      switchMap(({ iri, payload }) => {
        return this.service.sendProofOfDestructionMail(iri, payload).pipe(
          map(response =>
            OrdersActions.SendProofOfDestructionPDFSuccess({ response })
          ),
          catchError(response =>
            of(OrdersActions.SendProofOfDestructionPDFFail({ response }))
          )
        );
      })
    )
  );
  SendDataRecoveryProtocol = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.SendDataRecoveryProtocol),
      switchMap(({ iri, payload }) => {
        return this.service.sendDataRecoveryProtocolMail(iri, payload).pipe(
          map(response =>
            OrdersActions.SendDataRecoveryProtocolSuccess({ response })
          ),
          catchError(response =>
            of(OrdersActions.SendDataRecoveryProtocolFail({ response }))
          )
        );
      })
    )
  );
  UpdateDataRecoveryLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.UpdateDataRecoveryLocation),
      switchMap(({ iri, payload }) => {
        return this.service.updateDataRecoveryLocation(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.UpdateDataRecoveryLocationSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.UpdateDataRecoveryLocationFail({ response }))
          )
        );
      })
    )
  );
  SendBindingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.SendBindingOrder),
      switchMap(({ iri, payload }) => {
        return this.service.sendBindingOrderEmail(iri, payload).pipe(
          map((response: any) =>
            OrdersActions.SendBindingOrderSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.SendBindingOrderFail({ response }))
          )
        );
      })
    )
  );
  ReadBindingOrderPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadBindingOrderPDF),
      switchMap(({ iri }) => {
        return this.service.readBindingOrderAsPdf(iri).pipe(
          map((response: any) =>
            OrdersActions.ReadBindingOrderPDFSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadBindingOrderPDFFail({ response }))
          )
        );
      })
    )
  );
  ReadProofOfDestructionPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadProofOfDestructionPDF),
      switchMap(({ iri }) => {
        return this.service.readProofOfDestructionAsPdf(iri).pipe(
          map((response: any) =>
            OrdersActions.ReadProofOfDestructionPDFSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadProofOfDestructionPDFFail({ response }))
          )
        );
      })
    )
  );
  ReadDataRecoveryProtocolPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.ReadDataRecoveryProtocolPDF),
      switchMap(({ iri }) => {
        return this.service.readDataRecoveryProtocolPdf(iri).pipe(
          map((response: any) =>
            OrdersActions.ReadDataRecoveryProtocolPDFSuccess({ response })
          ),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.ReadDataRecoveryProtocolPDFFail({ response }))
          )
        );
      })
    )
  );
  DeleteOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.DeleteOrder),
      switchMap(({ iri }) => {
        return this.service.deleteOrder(iri).pipe(
          map(() => OrdersActions.DeleteOrderSuccess({ iri })),
          catchError((response: HttpErrorResponse) =>
            of(OrdersActions.DeleteOrderFail({ response }))
          )
        );
      })
    )
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrdersActions.UpdateOrderSuccess,
          OrdersActions.SendBindingOrderSuccess,
          OrdersActions.MailOrderSuccess,
          OrdersActions.SendProofOfDestructionPDFSuccess,
          OrdersActions.SendDataRecoveryProtocolSuccess,
          BindingOrdersActions.UploadBindingOrderSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case OrdersActions.UPDATE_ORDER_SUCCESS:
              message = 'Auftrag wurde aktualisiert.';
              break;
            case OrdersActions.SEND_BINDING_ORDER_SUCCESS:
              message = 'Die verbindliche Bestellung wurde verschickt.';
              break;
            case BindingOrdersActions.UPLOAD_BINDING_ORDER_SUCCESS:
              message =
                'Die Bestätigung der Verbindliche Bestellung wurde gespeichert.';
              break;
            case OrdersActions.MAIL_ORDER_SUCCESS:
              message = 'Die Email wurde versendet.';
              break;
            case OrdersActions.SEND_PROOF_OF_DESTRUCTION_PDF_SUCCESS:
              message =
                'Die Email mit dem Vernichtungsnachweis wurde versendet.';
              break;
            case OrdersActions.SEND_DATA_RECOVERY_PROTOCOL_SUCCESS:
              message =
                'Die Email mit dem Datenrettungsprotokoll wurde versendet.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  SuccessDownloadPdf = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrdersActions.ReadBindingOrderPDFSuccess,
          OrdersActions.ReadProofOfDestructionPDFSuccess,
          OrdersActions.ReadDataRecoveryProtocolPDFSuccess
        ),
        map(({ response: { contentUrl, fileName } }) => {
          const a = document.createElement('a');
          a.href = contentUrl;
          a.download = fileName; // File name Here
          a.click(); // Downloaded file
          a.remove();
        })
      ),
    { dispatch: false }
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrdersActions.UpdateOrderFail,
          OrdersActions.SendBindingOrderFail,
          OrdersActions.DeleteOrderFail,
          OrdersActions.ReadBindingOrderPDFFail,
          OrdersActions.ReadProofOfDestructionPDFFail,
          OrdersActions.SendDataRecoveryProtocolFail,
          OrdersActions.MailOrderFail
        ),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case OrdersActions.UPDATE_ORDER_FAIL:
              message =
                'Beim Aktualisieren des Analyseauftrags sind Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.DELETE_ORDER_FAIL:
              message =
                'Beim Löschen des Analyseauftrags ist ein Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.SEND_BINDING_ORDER_FAIL:
              message =
                'Beim Senden der Verbindlichen Bestellung sind Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.READ_BINDING_ORDER_PDF_FAIL:
              message =
                'Beim erstellen der Verbindlichen Bestelleung sind Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.READ_PROOF_OF_DESTRUCTION_PDF_FAIL:
              message =
                'Beim erstellen des Vernichtungsnachweises sind Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.SEND_DATA_RECOVERY_PROTOCOL_FAIL:
              message =
                'Beim erstellen des Datenrettungsprotokolls sind Fehler aufgetreten:' +
                errors;
              break;
            case OrdersActions.MAIL_ORDER_FAIL:
              message =
                'Beim senden der E-Mail sind Fehler aufgetreten:' + errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  DeleteSuccessAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.DeleteOrderSuccess),
      map(() => RouterActions.Go({ path: ['orders'] }))
    )
  );

  constructor(
    private actions$: Actions,
    private service: OrdersService,
    private notifierService: NotifierService
  ) {}

  /*
  UpdateAnalysisLocationSuccess$ = createEffect(() => this.actions$.pipe(
   ofType(OrdersActions.UpdateAnalysisLocationSuccess),
   switchMap(({response}) => {

     const transitionMap = (labLocation): string => ({
       '/api/lab_locations/LAB_LOCATION_DR_DE': 'assign_analysis_to_prepare_analysis_dr_de',
       '/api/lab_locations/LAB_LOCATION_DR_DE_REMOTE': 'assign_analysis_to_prepare_analysis_dr_de_remote',
       '/api/lab_locations/LAB_LOCATION_DR_EXT_DHE': 'assign_analysis_to_prepare_analysis_dr_ext',
       '/api/lab_locations/LAB_LOCATION_DR_EXT_DRE': 'assign_analysis_to_prepare_analysis_dr_ext',
     })[labLocation];

     const transition = transitionMap(response.analysisLocation);
     const uri = `${response['@id']}/transitions`;

     return of(TransitionsActions.MakeTransition({uri, payload: {workflow: 'order_state', transition}}));
   })
 ));

@Effect()
 UpdateDataRecoveryLocationSuccess$ = this.actions$.pipe(
   ofType(OrdersActions.UpdateDataRecoveryLocationSuccess),
   switchMap(({ response }) => {

     const transitionMap = (labLocation): string => ({
       '/api/lab_locations/LAB_LOCATION_DR_DE': 'customer_analysis_created_to_prepare_dr_dr_de',
       '/api/lab_locations/LAB_LOCATION_DR_DE_REMOTE': 'customer_analysis_created_to_prepare_dr_dr_remote',
       '/api/lab_locations/LAB_LOCATION_DR_EXT_DHE': 'customer_analysis_created_to_prepare_dr_dr_ext',
       '/api/lab_locations/LAB_LOCATION_DR_EXT_DRE': 'customer_analysis_created_to_prepare_dr_dr_ext',
     })[labLocation];

     const transition = transitionMap(response.dataRecoveryLocation);
     const uri = `${response['@id']}/transitions`;

     return of(TransitionsActions.MakeTransition({ uri, payload: { workflow: 'order_state', transition } }));
   })
 );*/
}
