import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { DataRecoveryResult } from '../models';
import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class DataRecoveryResultsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readDataRecoveryResult(iri: string): Observable<DataRecoveryResult> {
    return this.rolesService.userHasRoleFilter<DataRecoveryResult>(
      'ROLE_MWS_DATA_RECOVERY_RESULT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateDataRecoveryResult(
    iri: string,
    payload: DataRecoveryResult
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_DATA_RECOVERY_RESULT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }
}
