import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import * as fromModuleModels from '../../models';
import {Administrator} from '../../../administrators/models';
import {ApplicationState} from '../../../application-state/store';
import {CustomerCommentTagsSelectors} from '../../store/selectors';
import {AuthService} from "../../../auth/services/auth.service";
import {UserProfileSelectors} from "../../../auth/store/selectors";
import {filter, takeUntil} from "rxjs/operators";
import {OrderComment} from "../../../orders/models";
import {FeedbackRequestsSelectors} from "../../../notifications/store";
import {ReplyCommentModalComponent} from "../../../shared/components/reply-comment-modal/reply-comment-modal.component";
import {AdministratorFeedbackRequestsActions} from "../../../administrators/store";
import {Actions, ofType} from "@ngrx/effects";
import {MatDialog} from "@angular/material/dialog";
import {Customer, CustomerComment} from "../../models";
import {Ticket} from "../../../tickets/models";
import {TicketCommentsSelectors} from "../../../tickets/store/selectors";
import {TicketCommentsActions} from "../../../tickets/store";
import {getUuidFromIri} from "../../../shared/utilities/strings.utility";
import * as CustomersModuleSelectors from "../../store/selectors";
import {LeadCommentsActions} from "../../../leads/store";
import {CustomerCommentsActions} from "../../store";
import {extractUUID} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'customer-comments',
  styleUrls: ['customer-comments.component.scss'],
  template: `

    <div class="card__heading">
      <span>Kommentare zum Kunden</span>
    </div>

    <div class="card__content">

      <div class="customer__comments" *ngIf="(comments$ | async) as comments">

        <ng-container *ngIf="(commentTagsEntities$ | async) as tags">

          <div *ngFor="let comment of comments" class="item comment"
           [class.not-resolved]="comment.isFeedbackRequest && !comment.feedbackRequestResolved"
          >

            <div class="grid">

              <div class="column-10">

                <span class="created-by">{{ getAdministratorName(comment.createdBy) }}</span>
                <span class="created-at">{{ comment.createdAt | date:'medium' }}
                  <span [hidden]="comment.createdAt===comment.updatedAt"
                        [matTooltip]="'Bearbeitet: '+(comment.updatedAt | date: 'medium')"
                        class="text-small">(bearbeitet)
                </span>
              </span>
                <span class="comment__tag"
                      *ngIf="tags[comment.commentTag]">{{ tags[comment.commentTag]['commentTag'] }}</span>
              </div>

              <div class="column-4 m-ta--2">
                <div
                  class="feedback-requests-actions"
                  *ngIf="comment.isFeedbackRequest"
                >
                  <button
                    mat-icon-button
                    [matTooltip]="comment.feedbackRequestResolved ? ('shared.tooltip.mark_as_resolved' | translate) : ('shared.tooltip.mark_as_resolved' | translate)"
                    (click)="resolveFeedback(comment)"
                    [class.resolved]="comment.feedbackRequestResolved"
                    *ngIf="canEditAndDelete"
                  >
                    <mat-icon>check</mat-icon>
                  </button>

                  <button [matMenuTriggerFor]="dropdownMenu" mat-icon-button>
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                </div>

                <mat-menu #dropdownMenu xPosition="before">

                  <button (click)="handleRequestCustomerCommentForm(comment)"
                          data-action="edit"
                          mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Kommentar bearbeiten</span>
                  </button>
                  <button (click)="handleRequestDeleteCustomerComment(comment)"
                          data-action="delete"
                          mat-menu-item>

                    <mat-icon>delete</mat-icon>
                    <span>Kommentar löschen</span>
                  </button>
                  <button
                    (click)="replyToComment(comment)"
                    data-action="reply"
                    *ngIf="
                      comment.isFeedbackRequest &&
                      comment.recipient === currentUser['@id']
                    "
                    mat-menu-item
                  >
                    <mat-icon>reply</mat-icon>
                    <span>Antworten</span>
                  </button>
                </mat-menu>
              </div>

              <div class="{{ comment.additionalContent ? 'column-12' : 'column-14' }}">
                <div class="comment__content" [innerHTML]="comment?.content | safeHtml"></div>
              </div>

              <div *ngIf="comment.additionalContent" class="column-2 m-ta--2">
                <button mat-icon-button (click)="additionalContent.classList.toggle('is-expanded')">
                  <mat-icon>{{ additionalContent.classList.contains('is-expanded') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                </button>
              </div>

              <div class="column-14 comment__ac-wrapper" #additionalContent>
                <div class="comment__additional-content" *ngIf="comment.additionalContent"
                     [innerHTML]="comment.additionalContent | safeHtml"></div>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="!!pagination$.getValue()?.next">
          <button
            class="btn--load-more"
            mat-button color="blue" (click)="handleRequestReadCustomerComments()">
            <mat-icon>expand_more</mat-icon>
            <span>Ältere Kommentare laden</span>
          </button>
        </div>
      </div>
    </div>
  `
})
export class CustomerCommentsComponent implements OnInit {

  @Input() administratorsEntities: { [iri: string]: Administrator };
  @Input() comments$: Observable<Array<fromModuleModels.CustomerComment>>;
  @Input() pagination$: BehaviorSubject<{ [p: string]: string }>;
  @Input() customer: Customer;

  @Output() requestDeleteCustomerComment: EventEmitter<fromModuleModels.CustomerComment> = new EventEmitter();
  @Output() requestCustomerCommentForm: EventEmitter<fromModuleModels.CustomerComment> = new EventEmitter();
  @Output() requestReadCustomerComments: EventEmitter<'previous' | 'next'> = new EventEmitter();

  currentUser: any;
  onDestroy$: Subject<any> = new Subject<any>();

  commentTagsEntities$: Observable<{ [iri: string]: fromModuleModels.CustomerCommentTag }>;

  constructor(
    private store: Store<ApplicationState>,
    private actions$: Actions,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
  }

  get canEditAndDelete(): boolean {
    return this.authService.isAdmin() || this.authService.isSupervisor();
  }

  ngOnInit(): void {
    this.commentTagsEntities$ = this.store.pipe(select(CustomerCommentTagsSelectors.selectCustomerCommentTagEntities));

    this.store
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  handleRequestDeleteCustomerComment(comment: fromModuleModels.CustomerComment): void {

    this.requestDeleteCustomerComment.emit(comment);
  }

  handleRequestCustomerCommentForm(comment: fromModuleModels.CustomerComment): void {

    this.requestCustomerCommentForm.emit(comment);
  }

  handleRequestReadCustomerComments(): void {
    this.requestReadCustomerComments.emit('next');
  }

  getAdministratorName(iri: string): string {
    const admin = this.administratorsEntities[iri];
    return `${admin.firstName} ${admin.lastName} `;
  }
  replyToComment(comment: CustomerComment): void {

    this.dialog.open(ReplyCommentModalComponent, {
      disableClose: true,
      panelClass: 'pos-relative',
      data: { response: comment, variation: 'answer', module: 'customer' }
    });

    //Update comments after close of dialog
    this.dialog.afterAllClosed.subscribe(
      () => {
        this.actions$
          .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.store.dispatch(CustomerCommentsActions.ReadCustomerComments({customerUuid: extractUUID(this.customer["@id"])}));
          })
      }
    )
  }

  resolveFeedback(comment: CustomerComment): void {
    this.store.dispatch(
      AdministratorFeedbackRequestsActions.PatchFeedbackRequests({
        iri: comment['@id'],
        module: 'order',
        variation: "resolve",
        payload: { feedbackRequestResolved: !comment.feedbackRequestResolved }
      })
    )
    this.actions$
      .pipe(ofType(AdministratorFeedbackRequestsActions.PatchFeedbackRequestsSuccess))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.store.dispatch(CustomerCommentsActions.ReadCustomerComments({customerUuid: extractUUID(this.customer["@id"])}));
      })
  }
}
