import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrdersActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.SalesSummary };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(OrdersActions.ReadSalesSummary, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(OrdersActions.ReadSalesSummarySuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.Order }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      // Note: Intentionally "clear" state when returning from single view or navigating tru list view or filtering
      {}
      // {...state.entities}
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(OrdersActions.ReadSalesSummaryFail, (state, { response }) => {
    return {
      ...state,
      errors: getAndHandleBackendErrors(response),
      loading: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
