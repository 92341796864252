import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import * as fromModuleModels from '../../models';
import {Offer, InvoiceMail} from '../../models';
import {InvoicePdfResponse} from '../../models/invoice-pdf-Response';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CANCELLATION_INVOICE = '[Invoices Module] Create Cancellation Invoice';
export const CREATE_CANCELLATION_INVOICE_FAIL = '[Invoices Module] Create Cancellation Invoice Fail';
export const CREATE_CANCELLATION_INVOICE_SUCCESS = '[Invoices Module] Create Cancellation Invoice Success';

export const CreateCancellationInvoice = createAction(
  CREATE_CANCELLATION_INVOICE,
  props<{ payload: any }>()
);

export const CreateCancellationInvoiceSuccess = createAction(
  CREATE_CANCELLATION_INVOICE_SUCCESS,
  props<{ response: any }>()
);

export const CreateCancellationInvoiceFail = createAction(
  CREATE_CANCELLATION_INVOICE_FAIL,
  props<{ response: any }>()
);

///////////////////////////////////////////////////////////////////// Read PDF

export const READ_CANCELLATION_INVOICE_PDF = '[Invoices Module] Cancellation Invoice as pdf';
export const READ_CANCELLATION_INVOICE_PDF_SUCCESS = '[Invoices Module] Cancellation Invoice as pdf Success';
export const READ_CANCELLATION_INVOICE_PDF_FAIL = '[Invoices Module] Cancellation Invoice as pdf Fail';

export const ReadCancellationInvoicePDF = createAction(
  READ_CANCELLATION_INVOICE_PDF,
  props<{ iri?: string }>()
);

export const ReadCancellationInvoicePDFSuccess = createAction(
  READ_CANCELLATION_INVOICE_PDF_SUCCESS,
  props<{ response: InvoicePdfResponse }>()
);

export const ReadCancellationInvoicePDFFail = createAction(
  READ_CANCELLATION_INVOICE_PDF_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CANCELLATION_INVOICE = '[Invoices Module] Read Cancellation Invoice';
export const READ_CANCELLATION_INVOICE_SUCCESS = '[Invoices Module] Read Cancellation Invoice Success';
export const READ_CANCELLATION_INVOICE_FAIL = '[Invoices Module] Read Cancellation Invoice Fail';

export const ReadCancellationInvoice = createAction(
  READ_CANCELLATION_INVOICE,
  props<{ iri: string }>()
);

export const ReadCancellationInvoiceSuccess = createAction(
  READ_CANCELLATION_INVOICE_SUCCESS,
  props<{ response: fromModuleModels.Offer | any }>()
);

export const ReadCancellationInvoiceFail = createAction(
  READ_CANCELLATION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CANCELLATION_INVOICES = '[Invoices Module] Read Cancellation Invoices';
export const READ_CANCELLATION_INVOICES_SUCCESS = '[Invoices Module] Read Cancellation Invoices Success';
export const READ_CANCELLATION_INVOICES_FAIL = '[Invoices Module] Read Cancellation Invoices Fail';

export const ReadCancellationInvoices = createAction(
  READ_CANCELLATION_INVOICES,
  props<{ page: number, params?: { [p: string]: number | string | boolean } }>()
);

export const ReadCancellationInvoicesSuccess = createAction(
  READ_CANCELLATION_INVOICES_SUCCESS,
  props<{ response: any }>()
);

export const ReadCancellationInvoicesFail = createAction(
  READ_CANCELLATION_INVOICES_FAIL,
  props<{ response: any }>()
);

export const READ_CANCELLATION_INVOICE_CHUNK = '[Invoices Module] Read Cancellation Invoice Chunk';
export const READ_CANCELLATION_INVOICE_CHUNK_SUCCESS = '[Invoices Module] Read Cancellation Invoice Chunk Success';
export const READ_CANCELLATION_INVOICE_CHUNK_FAIL = '[Invoices Module] Read Cancellation Invoice Chunk Fail';

export const ReadCancellationInvoiceChunk = createAction(
  READ_CANCELLATION_INVOICE_CHUNK,
  props<{ uri: string }>()
);

export const ReadCancellationInvoiceChunkSuccess = createAction(
  READ_CANCELLATION_INVOICE_CHUNK_SUCCESS,
  props<{ response: any }>()
);

export const ReadCancellationInvoiceChunkFail = createAction(
  READ_CANCELLATION_INVOICE_CHUNK_FAIL,
  props<{ response: any }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CANCELLATION_INVOICE = '[Invoices Module] Update Cancellation Invoice';
export const UPDATE_CANCELLATION_INVOICE_SUCCESS = '[Invoices Module] Update Cancellation Invoice Success';
export const UPDATE_CANCELLATION_INVOICE_FAIL = '[Invoices Module] Update Cancellation Invoice Fail';

export const UpdateCancellationInvoice = createAction(
  UPDATE_CANCELLATION_INVOICE,
  props<{ iri: string, payload: fromModuleModels.Offer | any }>()
);

export const UpdateCancellationInvoiceSuccess = createAction(
  UPDATE_CANCELLATION_INVOICE_SUCCESS,
  props<{ response: fromModuleModels.Offer }>()
);

export const UpdateCancellationInvoiceFail = createAction(
  UPDATE_CANCELLATION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Mail

export const MAIL_CANCELLATION_INVOICE = '[Invoices Module] Mail Cancellation Invoice';
export const MAIL_CANCELLATION_INVOICE_SUCCESS = '[Invoices Module] Mail Cancellation Invoice Success';
export const MAIL_CANCELLATION_INVOICE_FAIL = '[Invoices Module] Mail Cancellation Invoice Fail';

export const MailCancellationInvoice = createAction(
  MAIL_CANCELLATION_INVOICE,
  props<{ iri: string, payload: InvoiceMail | any }>()
);

export const MailCancellationInvoiceSuccess = createAction(
  MAIL_CANCELLATION_INVOICE_SUCCESS,
  props<{ response: Offer }>()
);

export const MailCancellationInvoiceFail = createAction(
  MAIL_CANCELLATION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_CANCELLATION_INVOICE = '[Invoices Module] Delete Cancellation Invoice';
export const DELETE_CANCELLATION_INVOICE_SUCCESS = '[Invoices Module] Delete Cancellation Invoice Success';
export const DELETE_CANCELLATION_INVOICE_FAIL = '[Invoices Module] Delete Cancellation Invoice Fail';

export const DeleteCancellationInvoice = createAction(
  DELETE_CANCELLATION_INVOICE,
  props<{ iri: string }>()
);

export const DeleteCancellationInvoiceSuccess = createAction(
  DELETE_CANCELLATION_INVOICE_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCancellationInvoiceFail = createAction(
  DELETE_CANCELLATION_INVOICE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset

export const RESET_CURRENT_CANCELLATION_INVOICE = '[Invoices Module] Reset Current Cancellation Invoice';

export const ResetCurrentCancellationInvoice = createAction(
  RESET_CURRENT_CANCELLATION_INVOICE
);

export const RESET_CANCELLATION_INVOICES_LOADED = '[Invoices Module] Reset Cancellation Invoices Loaded';

export const ResetIsLoaded = createAction(
  RESET_CANCELLATION_INVOICES_LOADED
);
