import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {CustomerAddressesActions} from '../actions/';
import * as fromModuleServices from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';

@Injectable()
export class CustomerAddressesEffects {

  constructor(private actions$: Actions, private cas: fromModuleServices.CustomerAddressesService) {
  }


  CreateCustomerAddress$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.CreateCustomerAddress),
    map(action => action),
    switchMap(({apiRoute, payload, customer}) => {
      return this.cas.createCustomerAddress(apiRoute, payload).pipe(
        map((response: any) => CustomerAddressesActions.CreateCustomerAddressSuccess({response, customer})),
        catchError(response => of(CustomerAddressesActions.CreateCustomerAddressFail({response})))
      );
    })
  ));


  ReadCustomerAddress$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.ReadCustomerAddress),
    map(action => action),
    switchMap(({iri}) => {
      return this.cas.readCustomerAddress(iri).pipe(
        map((response: any) => CustomerAddressesActions.ReadCustomerAddressSuccess({response})),
        catchError(response => of(CustomerAddressesActions.ReadCustomerAddressFail({response})))
      );
    })
  ));


  ReadCustomerAddresses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.ReadCustomerAddresses),
    map(action => action),
    switchMap(({customerIri}) => {
      return this.cas.readCustomerAddresses(customerIri).pipe(
        map((response: any) => CustomerAddressesActions.ReadCustomerAddressesSuccess({response})),
        catchError(response => of(CustomerAddressesActions.ReadCustomerAddressesFail({response})))
      );
    })
  ));


  ReadCustomerBillingAddresses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.ReadCustomerBillingAddresses),
    map(action => action),
    switchMap(({customerIri}) => {
      return this.cas.readCustomerBillingAddresses(customerIri).pipe(
        map((response: any) => CustomerAddressesActions.ReadCustomerBillingAddressesSuccess({response})),
        catchError(response => of(CustomerAddressesActions.ReadCustomerBillingAddressesFail({response})))
      );
    })
  ));


  ReadCustomerDeliveryAddresses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.ReadCustomerDeliveryAddresses),
    map(action => action),
    switchMap(({customerIri}) => {
      return this.cas.readCustomerDeliveryAddresses(customerIri).pipe(
        map((response: any) => CustomerAddressesActions.ReadCustomerDeliveryAddressesSuccess({response})),
        catchError(response => of(CustomerAddressesActions.ReadCustomerDeliveryAddressesFail({response})))
      );
    })
  ));

  ReadCustomerPartnerBranchOfficeAddresses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddresses),
    map(action => action),
    switchMap(({customerIri}) => {
      return this.cas.readCustomerPartnerBranchOfficeAddresses(customerIri).pipe(
        map((response: any) => CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddressesSuccess({response})),
        catchError(response => of(CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddressesFail({response})))
      );
    })
  ));



  UpdateCustomerAddress$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.UpdateCustomerAddress),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.cas.updateCustomerAddress(iri, payload).pipe(
        map(response => CustomerAddressesActions.UpdateCustomerAddressSuccess({response})),
        catchError(response => of(CustomerAddressesActions.UpdateCustomerAddressFail({response})))
      );
    })
  ));


  DeleteCustomerAddress$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAddressesActions.DeleteCustomerAddress),
    switchMap(({iri}) => {
      return this.cas.deleteCustomerAddress(iri).pipe(
        map(() => CustomerAddressesActions.DeleteCustomerAddressSuccess({iri})),
        catchError(response => of(CustomerAddressesActions.DeleteCustomerAddressFail({response})))
      );
    })
  ));


  successfulActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerAddressesActions.CreateCustomerAddressSuccess,
      CustomerAddressesActions.UpdateCustomerAddressSuccess
    ),
    map(({type}) => {

      const text = type === CustomerAddressesActions.CREATE_CUSTOMER_ADDRESS_SUCCESS
        ? `Eine neue Kundenadresse wurde angelegt.`
        : 'Die Kundenadresse wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
