import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import * as fromModuleModels from '../../models';

@Component({
  selector: 'customer-contact-list',
  styleUrls: ['customer-contact-list.component.scss'],
  template: `

    <div class="card m-b--25 mat-elevation-z1" *ngFor="let cT of customerContactTypes; let i = index">

      <div class="card__heading">{{ cT.name }}</div>

      <div class="card__content">

        <table
          *ngIf="getContactsByContactType(cT) && getContactsByContactType(cT).length; else noContacts"
          mat-table [dataSource]="getContactsByContactType(cT)"
          class="mat-elevation-z1">
          <!-- setAsDefault Column -->
          <ng-container matColumnDef="setAsDefault">
            <th mat-header-cell *matHeaderCellDef style="width: 62px;"></th>
            <td mat-cell *matCellDef="let element">
              <button matTooltip="Als Standardkontakt für diese Kontaktart setzen"
                      [disabled]="element['@id'] == getDefaultContactIri(i)"
                      class="toggle-switch"
                      (click)="handleRequestSetDefaultContact(element['@id'], i)">
                <mat-icon>verified</mat-icon>
              </button>

            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.firstName }} {{ element.lastName }} </span>
            </td>
          </ng-container>

          <!-- marketingPermissions Column -->
          <ng-container matColumnDef="marketingPermissions">
            <th mat-header-cell *matHeaderCellDef style="width: 150px;"></th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="Marketing-Erlaubnis" class="marketingPermissions">

                  <mat-icon class="mx-1"
                            [class.green]="element.marketingPermissionEmail">alternate_email</mat-icon>
                  <mat-icon class="mx-1" [class.green]="element.marketingPermissionPhone">phone</mat-icon>
                  <mat-icon class="mx-1"
                            [class.green]="element.marketingPermissionPostal">local_post_office</mat-icon>
                  <mat-icon class="mx-1"
                            [class.red]="!element.marketingPermissionEmail &&!element.marketingPermissionPhone && !element.marketingPermissionPostal ">block</mat-icon>
              </span>

            </td>
          </ng-container>

          <!-- Firma Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-Mail</th>
            <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
          </ng-container>

          <!-- Phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Festnetz</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element?.phone }}<br></span>
            </td>
          </ng-container>

          <!-- Mobile Column -->
          <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef>Mobilrufnummer</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element?.mobile }}<br></span>
            </td>
          </ng-container>

          <!--<ng-container matColumnDef="availability">
            <th mat-header-cell *matHeaderCellDef>Erreichbarkeit</th>
            <td mat-cell *matCellDef="let element">{{ element?.availability }}</td>
          </ng-container>-->

          <!--<ng-container matColumnDef="availability">
            <th mat-header-cell *matHeaderCellDef>Erreichbarkeit</th>
            <td mat-cell *matCellDef="let element"> {{ element.availability }} </td>
          </ng-container>-->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 110px;"></th>
            <td mat-cell *matCellDef="let element" class="text-right">


              <button (click)="handleRequestUpdateCustomerContactForm(element)"
                      data-action="edit"
                      class="text-color-grey"
                      matTooltip="Kontakt bearbeiten"
                      mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
              <button (click)="handleRequestDeleteCustomerContact(element)"
                      data-action="delete"
                      mat-icon-button
                      class="text-color-grey"
                      matTooltip="Kontakt löschen">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
      </div>
    </div>

    <ng-template #noContacts>
      <p class="no-contacts">In dieser Kategorie gibt es noch keine Kontakte</p>
    </ng-template>
  `
})
export class CustomerContactListComponent implements OnInit {

  @Input() contacts: Array<fromModuleModels.CustomerContact>;
  @Input() customer: fromModuleModels.Customer;
  @Input() customerContactTypes: Array<fromModuleModels.CustomerContactType>;

  @Output()
  requestFetchDataOnLoad: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  requestDeleteCustomerContact: EventEmitter<fromModuleModels.CustomerContact> =
    new EventEmitter<fromModuleModels.CustomerContact>();

  @Output()
  requestUpdateCustomerContactForm: EventEmitter<fromModuleModels.CustomerContact> =
    new EventEmitter<fromModuleModels.CustomerContact>();

  @Output()
  requestUpdateDefaultCustomerContact: EventEmitter<{ iri: string, payload: fromModuleModels.Customer }> =
    new EventEmitter<{ iri: string, payload: fromModuleModels.Customer }>();

  displayColumns: Array<string> = ['setAsDefault', 'fullName', 'marketingPermissions', 'email', 'phone', 'mobile'/*,'availability'*/, 'actions'];

  ngOnInit(): void {
    this.requestFetchDataOnLoad.emit(this.customer['@id']);
  }

  getContactsByContactType(contactType: fromModuleModels.CustomerContactType): Array<fromModuleModels.CustomerContact> {

    return this.contacts.filter(contact => contact.customerContactTypes.includes(contactType['@id']));
  }

  getDefaultContactIri(groupIndex: number): string {

    if (groupIndex === 0) {
      return this.customer.defaultTechnicalCustomerContact;
    } else if (groupIndex === 1) {
      return this.customer.defaultOrganizationalCustomerContact;
    } else {
      return this.customer.defaultDecisionMakerCustomerContact;
    }
  }

  getPropertyName(groupIndex: number): string {
    if (groupIndex == 0) {
      return 'defaultTechnicalCustomerContact';
    } else if (groupIndex == 1) {
      return 'defaultOrganizationalCustomerContact';
    } else {
      return 'defaultDecisionMakerCustomerContact';
    }
  }

  handleRequestSetDefaultContact(iri: string, groupIndex): void {

    const payload = {[this.getPropertyName(groupIndex)]: iri};
    console.log(payload);
    this.requestUpdateDefaultCustomerContact.emit({iri: this.customer['@id'], payload});
  }

  handleRequestUpdateCustomerContactForm(contact: fromModuleModels.CustomerContact): void {

    this.requestUpdateCustomerContactForm.emit(contact);
  }

  handleRequestDeleteCustomerContact(contact: fromModuleModels.CustomerContact): void {

    this.requestDeleteCustomerContact.emit(contact);
  }
}
