import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { filter, takeUntil } from 'rxjs/operators';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { extractIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-binding-orders-open-widget',
  styleUrls: ['./binding-orders-open-widget.component.scss'],
  templateUrl: 'binding-orders-open-widget.component.html'
})
export class BindingOrdersOpenWidgetComponent implements OnInit, OnDestroy {
  @Input() userType: string;
  BOTypeSelectValue = 'missingBO';
  onDestroy$: Subject<any> = new Subject<any>();
  bindingOrdersStates = {
    missingBO: ['bo_sent', 'bo_delayed'],
    missingDownPayment: ['waiting_for_down_payment', 'down_payment_delayed'],
    BoReceived: ['bo_received_via_api', 'bo_received_via_email']
  };

  salesperson: string;
  states: Array<string> = ['replacement_dm_not_available'];

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(user => {
        this.salesperson = extractIri(user);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(order: Order): { [key: string]: string } {
    if (
      order.rawState
        .join('')
        .toLowerCase()
        .indexOf('delayed') > -1
    ) {
      return { backgroundColor: 'red' };
    } else {
      return { backgroundColor: '#ffc400' };
    }
  }
}
