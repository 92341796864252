import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';

import {ANALYSIS_RESULTS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

import {ApplicationState} from "../../../application-state/store";
import {generateBaseSelectors} from "../../../shared/utilities/selector.utilities";
import {AnalysisResult} from "../../models";
import {State} from "../reducers/storage-locations.reducers";

type BaseType = AnalysisResult;
type StateType = State;
const NAMESPACE = ANALYSIS_RESULTS_FEATURE_KEY;
const MODULE_STATE_SELECT = selectMasterDataModuleState;

export const {
  sState,
  isLoading,
  isLoaded,
  sEntities,
  sList,
  sById,
  sByIri
}: {
  isLoading: MemoizedSelector<ApplicationState, boolean>;
  sById: (id) => MemoizedSelector<ApplicationState, any>;
  sByIri: MemoizedSelectorWithProps<ApplicationState, { readonly iri?: string }, any>;
  sEntities: MemoizedSelector<ApplicationState, { [iri: string]: BaseType }>;
  sList: MemoizedSelector<ApplicationState, Array<BaseType>>;
  sState: MemoizedSelector<ApplicationState, StateType>;
  isLoaded: MemoizedSelector<ApplicationState, boolean>
} = generateBaseSelectors(MODULE_STATE_SELECT, NAMESPACE);

export const byCategory = (category) => createSelector(
  sList,
  (entities) => category ? entities.filter(e => e.category === category) : []
);
//////////////////////////////////////////////////////////////////////////////////////////////////////////// Pagination
export const selectAnalysisResultsTotalItems = createSelector(
  sState,
  (state) => state.totalItems
);

export const selectAnalysisResultsPagination = createSelector(
  sState,
  (state) => state.pagination
);

export const selectPaginationCurrentLink = createSelector(
  selectAnalysisResultsPagination,
  pagination => pagination.current
);

export const selectPaginationFirstLink = createSelector(
  selectAnalysisResultsPagination,
  pagination => pagination.first
);

export const selectPaginationPreviousLink = createSelector(
  selectAnalysisResultsPagination,
  pagination => pagination.previous
);

export const selectPaginationNextLink = createSelector(
  selectAnalysisResultsPagination,
  pagination => pagination.next
);

export const selectErrors = createSelector(
  sState,
  (state) => state.errors
);

