import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER_STATE_RANGE = '[Orders Module] Read Order State Range';
export const READ_ORDER_STATE_RANGE_SUCCESS = '[Orders Module] Read Order State Range Success';
export const READ_ORDER_STATE_RANGE_FAIL = '[Orders Module] Read Order State Range Fail';

export const ReadOrderStateRange = createAction(
  READ_ORDER_STATE_RANGE,
  props<{ iri: string }>()
);

export const ReadOrderStateRangeSuccess = createAction(
  READ_ORDER_STATE_RANGE_SUCCESS,
  props<{ response: fromOrdersModuleModels.OrderStateRange }>()
);

export const ReadOrderStateRangeFail = createAction(
  READ_ORDER_STATE_RANGE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_STATE_RANGES = '[Orders Module] Read Order State Ranges';
export const READ_ORDER_STATE_RANGES_SUCCESS = '[Orders Module] Read Order State Ranges Success';
export const READ_ORDER_STATE_RANGES_FAIL = '[Orders Module] Read Order State Ranges Fail';

export const ReadOrderStateRanges = createAction(
  READ_ORDER_STATE_RANGES
);

export const ReadOrderStateRangesSuccess = createAction(
  READ_ORDER_STATE_RANGES_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadOrderStateRangesFail = createAction(
  READ_ORDER_STATE_RANGES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
