import {createAction, createSelector} from '@ngrx/store';

import {PROOFS_OF_DESTRUCTION_FEATURE_KEY, selectOrdersModuleState} from '../reducers';
import {selectServiceOrderItems} from './service-order-items.selectors';

export const selectProofsOfDestructionState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[PROOFS_OF_DESTRUCTION_FEATURE_KEY]
);

export const selectProofsOfDestructionEntities = createSelector(
  selectProofsOfDestructionState,
  (state) => state.entities
);

export const selectProofsOfDestruction = createSelector(
  selectProofsOfDestructionEntities,
  (entities) => Object.values(entities)
);
export const sByIri = createSelector(
  selectProofsOfDestructionEntities,
  (entities, {iri}) => entities[iri]
);

export const selectCurrentProofOfDestruction = createSelector(
  selectProofsOfDestructionState,
  state => state.current
);

export const selectProofOfDestructionForOrder = createSelector(
  selectProofsOfDestruction,
  (entities, props) => entities.find(service => service.order === props.iri)
);


////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectErrors = createSelector(
  selectProofsOfDestructionState,
  (state) => state.errors
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectProofsOfDestructionState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectProofsOfDestructionState,
  (state) => state.loaded
);
