import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromWarehouseModuleModels from '../../models';
import * as fromOrdersModuleModels from '../../../orders/models';
import { Order } from '../../../orders/models';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  ReplacementStockItemsActions
} from '../../store';
import { FormsService } from '../../../shared/services';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';

interface StorageItemGroup {
  value: string;
  label: string;
}

export type StockItemsTypes =
  | 'CustomerReplacementStockItem'
  | 'CustomerStockItem'
  | 'ReplacementStockItem';

@Component({
  selector: 'app-stock-item-form',
  styleUrls: ['stock-item-form.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container [formGroup]="sif">
      <div class="row" style="min-width: 600px">
        <div class="col-12">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              [items]="storageGroupOptions$ | async"
              placeholder="Lagergruppe"
              bindLabel="label"
              bindValue="value"
              [clearable]="false"
              [searchable]="false"
              [markFirst]="false"
              (change)="updateStorageItemGroup($event)"
            ></ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              [items]="storageLocations$ | async"
              placeholder="Lagerplatz-Nr."
              bindLabel="storageLocationNumber"
              bindValue="@id"
              formControlName="storageLocation"
              [clearable]="false"
              [markFirst]="false"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'storageLocation'"
                [formGroup]="sif"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="storageType?.value.indexOf('Customer') > -1 && sif.get('order')"
      >
        <div class="col-12">
          <div class="mat-form-field">
            <ng-select
              [items]="sortElements(orders$ | async)"
              placeholder="Kunde / Auftrag"
              bindLabel="orderNumber"
              bindValue="@id"
              [searchFn]="findOrder"
              formControlName="order"
              [clearable]="false"
              [markFirst]="false"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <span class="me-2">#{{ item.orderNumber }}</span>
                <span *ngIf="item.customer.vip" class="badge--vip mx-2">
                  <mat-icon>check</mat-icon>
                  <span>VIP</span>
                </span>
                <span *ngIf="item?.customer?.nameLine1" class="mx-1">{{
                  item?.customer?.nameLine1
                }}</span>
                <span *ngIf="item?.customer?.nameLine2">{{
                  item?.customer?.nameLine2
                }}</span>
              </ng-template>
              <ng-template
                ng-label-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <span class="me-2">#{{ item.orderNumber }}</span>
                <span *ngIf="item.customer.vip" class="badge--vip mx-2">
                  <mat-icon>check</mat-icon>
                  <span>VIP</span>
                </span>
                <span *ngIf="item?.customer?.nameLine1" class="mx-1">{{
                  item?.customer?.nameLine1
                }}</span>
                <span *ngIf="item?.customer?.nameLine2">{{
                  item?.customer?.nameLine2
                }}</span>
              </ng-template>
            </ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'order'"
                [formGroup]="sif"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="storageType?.value.indexOf('Customer') > -1">
        <div class="col-6 fake-bg--sllg" style="padding-top: 18px;">
          <h2>{{ getHasCasing() ? 'Typ' : 'Produkt' }}</h2>
        </div>

        <div
          *ngIf="getHasCasing() && !!!sif.get('enclosure')"
          class="button--add-casing m-ta--2 col-6"
        >
          <button (click)="handleToggleCasing()" mat-button>
            <mat-icon>add</mat-icon>
            <span>Gehäuse hinzufügen</span>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="storageType?.value.indexOf('Customer') > -1">
        <div class="col-12">
          <div
            *ngIf="!getIsReplacementMedium()"
            class=" mat-form-field storage-system fake-bg--sllg"
          >
            <ng-select
              [items]="storageSystems$ | async"
              placeholder="Typ"
              class="white"
              bindLabel="name"
              bindValue="@id"
              formControlName="storageSystemType"
              [clearable]="false"
              [markFirst]="false"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'storageSystemType'"
                [formGroup]="sif"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="storageType?.value.indexOf('Customer') > -1">
        <div class="col-12">
          <div
            *ngIf="getIsReplacementMedium()"
            class="mat-form-field storage-system fake-bg--sllg"
          >
            <ng-select
              [items]="products$ | async"
              placeholder="Produkt auswählen"
              class="white"
              bindLabel="name"
              bindValue="@id"
              formControlName="product"
              [clearable]="false"
              [markFirst]="false"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'product'"
                [formGroup]="sif"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>

      <ng-container formGroupName="enclosure" *ngIf="!!sif.get('enclosure')">
        <div class="row fake-bg--sllg">
          <div class="col-8">
            <h2>Angaben zum Gehäuse</h2>
          </div>

          <div class="m-ta--2 col-4">
            <button mat-icon-button (click)="handleToggleCasing()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="row fake-bg--sllg">
          <div class="col-4">
            <div class="mat-form-field ">
              <ng-select
                class="white"
                [items]="storageSystemManufacturers$ | async"
                placeholder="Hersteller*"
                bindLabel="name"
                bindValue="@id"
                formControlName="storageSystemManufacturer"
                [clearable]="false"
                [searchable]="false"
                [markFirst]="false"
              ></ng-select>
              <mat-error>
                <app-form-error
                  [fieldName]="'storageSystemManufacturer'"
                  [formGroup]="sif"
                ></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-4">
            <mat-form-field class="white">
              <mat-label>Modell</mat-label>
              <input
                matInput
                type="text"
                formControlName="storageSystemModel"
                required
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemModel'"
                  [formGroup]="sif"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="white ">
              <mat-label>Speichergröße</mat-label>
              <input
                matInput
                type="number"
                formControlName="storageSystemSize"
                min="1"
                required
              />
              <span matSuffix>GB</span>
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemSize'"
                  [formGroup]="sif"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row fake-bg--sllg">
          <div class="col-6">
            <mat-form-field class="white ">
              <mat-label>Seriennummer</mat-label>
              <input
                matInput
                type="text"
                formControlName="storageSystemSerialNumber"
                required
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemSerialNumber'"
                  [formGroup]="sif"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="white column-7">
              <mat-label>Einlagerungs-Nr.*</mat-label>
              <input
                matInput
                type="text"
                value="Wird automatisch ausgefüllt"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container
        formArrayName="dataMediums"
        *ngIf="getDataMediumsControlControls().length"
      >
        <ng-container
          *ngFor="
            let dataMedium of getDataMediumsControlControls();
            let i = index
          "
          [formGroupName]="i"
        >
          <div class="row fake-bg--sllg">
            <div class="col-7">
              <h2>
                Datenträger
                <span *ngIf="getDataMediumsControlControls().length > 1">{{
                  i + 1
                }}</span>
              </h2>
            </div>

            <div class="m-ta--2 column-5">
              <ng-container *ngIf="getDataMediumsControlControls().length > 1">
                <button mat-icon-button (click)="handleRemoveDataMedium(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>

          <div class="row fake-bg--sllg">
            <div class="col-4">
              <div class="mat-form-field">
                <ng-select
                  class="white"
                  [items]="storageSystemManufacturers$ | async"
                  placeholder="Hersteller*"
                  bindLabel="name"
                  bindValue="@id"
                  formControlName="storageSystemManufacturer"
                  [clearable]="false"
                  [markFirst]="false"
                ></ng-select>
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemManufacturer'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>

                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]['storageSystemManufacturer']">{{ errors?.dataMediums[i]['storageSystemManufacturer'].message }}</mat-hint>-->
              </div>
            </div>
            <div class="col-4">
              <mat-form-field class="white">
                <mat-label>Modell</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="storageSystemModel"
                  required
                />
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemModel">{{ errors?.dataMediums[i]?.storageSystemModel?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemModel'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-4">
              <mat-form-field class="white ">
                <mat-label>Speichergröße</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="storageSystemSize"
                  min="1"
                  required
                />
                <span matSuffix>GB</span>
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemSize">{{ errors?.dataMediums[i]?.storageSystemSize?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemSize'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row fake-bg--sllg">
            <div class="col-6">
              <mat-form-field class="white">
                <mat-label>Seriennummer</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="storageSystemSerialNumber"
                  required
                />
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemSerialNumber">{{ errors?.dataMediums[i]?.storageSystemSerialNumber?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemSerialNumber'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="white column-7">
                <mat-label>Einlagerungs-Nr.*</mat-label>
                <input
                  matInput
                  type="text"
                  value="Wird automatisch ausgefüllt"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="button--add-medium m-ta--2 column-14 fake-bg--sllg">
        <button (click)="handleAddDataMedium()" mat-button>
          <mat-icon>add</mat-icon>
          <span>Datenträger hinzufügen</span>
        </button>
      </div>

      <mat-form-field class="m-t--24 column-14">
        <mat-label>Bemerkung Lager-Eintrag</mat-label>
        <textarea
          formControlName="description"
          matInput
          matTextareaAutosize
          rows="6"
        ></textarea>
        <mat-error>
          <app-form-error
            [fieldName]="'description'"
            [formGroup]="sif"
          ></app-form-error>
        </mat-error>
      </mat-form-field>

      <!-- TODO: Remove when using ReplacementStockItem --><!--*ngIf="!isReplacementMedium"-->
      <div class="column-14">
        <div class="mat-form-field checkbox">
          <mat-checkbox formControlName="dataMediaOkLogisticsDepartment"
            >Datenträger ist OK</mat-checkbox
          >
        </div>
      </div>

      <div class="column-7 off-7 m-ta--2">
        <!-- Form Loading in child component is deferred -->
        <button
          [disabled]="sif.invalid || sif.pristine"
          (click)="handleRequestCreateStockItem()"
          mat-button
          color="green"
        >
          <mat-icon>save</mat-icon>
          <span>Lagereintrag hinzufügen</span>
        </button>
      </div>
    </ng-container>

    <!--<pre>{{ sif.value | json }}</pre>-->
    <!--<pre>{{ errors | json }}</pre>-->
    <!--<pre>{{ orders$ | async | json }}</pre>-->
    <!--<pre>{{ targetStorageGroup$.getValue() | json }}</pre>-->
  `
})
export class StockItemFormComponent implements OnInit, OnDestroy {
  @Input() orders$: Observable<Array<fromOrdersModuleModels.Order>>;
  @Input() presets$: BehaviorSubject<fromWarehouseModuleModels.StockItem | any>;
  @Input() products$: Observable<Array<fromMasterDataModuleModels.Product>>;
  @Input() storageLocationsEntities: {
    [iri: string]: fromMasterDataModuleModels.StorageLocation;
  };
  @Input() storageSystemManufacturers$: Observable<
    Array<fromMasterDataModuleModels.StorageSystemManufacturer>
  >;
  @Input() storageSystems$: Observable<
    Array<fromMasterDataModuleModels.StorageSystem>
  >;

  @Output() requestCreateStockItem: EventEmitter<{
    type: string;
    payload: fromWarehouseModuleModels.StockItem;
  }> = new EventEmitter();
  storageType: { value: string; label: string };
  sif: FormGroup;
  storageGroupOptions = [
    { value: 'CustomerStockItem', label: 'Kundenhardware' },
    {
      value: 'CustomerReplacementStockItem',
      label: 'Ersatzdatenträger (Kunde)'
    },
    { value: 'ReplacementStockItem', label: 'Ersatzdatenträger (DR)' }
  ];
  storageGroupOptions$: Observable<Array<StorageItemGroup>> = of(
    this.storageGroupOptions
  );
  storageLocations$: Observable<
    Array<fromMasterDataModuleModels.StorageLocation>
  >;
  targetStorageGroup$: BehaviorSubject<StorageItemGroup> = new BehaviorSubject<
    StorageItemGroup
  >(this.storageGroupOptions[0]);
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    public actions$: Actions,
    private formService: FormsService
  ) {}

  ngOnInit(): void {
    this.storageLocations$ = of(
      Object.keys(this.storageLocationsEntities).map(
        (key: string) => this.storageLocationsEntities[key]
      )
    );
    this.initForm('CustomerStockItem');
    this.actions$
      .pipe(
        ofType(
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail,
          CustomerStockItemsActions.CreateCustomerStockItemFail,
          ReplacementStockItemsActions.CreateReplacementStockItemFail
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fail => {
        console.log(fail);
        if (fail?.response?.error?.violations) {
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.sif
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getDataMediumsControlControls(): any {
    return (this.getDataMediumsControl() as FormArray).controls;
  }

  getDataMediumsControl(): any {
    return this.sif.get('dataMediums');
  }

  getHasCasing(): boolean {
    return this.targetStorageGroup$.getValue() != this.storageGroupOptions[2];
  }

  getIsReplacementMedium(): boolean {
    return this.targetStorageGroup$.getValue() == this.storageGroupOptions[2];
  }

  initForm(type: StockItemsTypes | string): void {
    this.sif = this[`build${type}Form`]();
  }

  handleToggleCasing(): void {
    !!this.sif.get('enclosure')
      ? this.sif.removeControl('enclosure')
      : this.sif.setControl('enclosure', this.createDataMediumForm());
  }

  handleAddDataMedium(): void {
    this.getDataMediumsControl().push(this.createDataMediumForm());
  }

  handleRemoveDataMedium(index: string | number): void {
    this.getDataMediumsControl().removeAt(index as number);
  }

  createDataMediumForm(): FormGroup {
    return this.fb.group({
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control('', [Validators.required]),
      storageSystemSize: this.fb.control(null, [
        Validators.required,
        Validators.min(1)
      ]),
      storageSystemSerialNumber: this.fb.control(null, [Validators.required])
    });
  }

  buildCustomerReplaceStockItemForm(): FormGroup {
    return this.fb.group({
      dataMediaOkLogisticsDepartment: this.fb.control(true),
      // dataMediaOkTechnicalDepartment: this.fb.control(true), // later
      dataMediums: this.fb.array([]),
      description: this.fb.control(''),
      order: this.fb.control(null, [Validators.required]),
      storageLocation: this.fb.control(null, [Validators.required]),
      storageSystemType: this.fb.control(null, [Validators.required])
    });
  }

  // Form content is subject to a change
  buildCustomerStockItemForm(): FormGroup {
    return this.buildCustomerReplaceStockItemForm();
  }

  buildReplacementStockItemForm(): FormGroup {
    return this.fb.group({
      dataMediums: this.fb.array([]),
      product: this.fb.control(null),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(null)
    });
  }

  sortElements(array: Array<any>): Array<any> {
    return array.sort(sortByCreatedAtDate);
  }

  findOrder(term: string, item: Order): boolean {
    return (
      item.orderNumber === parseInt(term, 10) ||
      item.customer?.nameLine1?.toLowerCase().indexOf(term.toLowerCase()) >
        -1 ||
      item.customer?.nameLine2?.toLowerCase().indexOf(term.toLowerCase()) > -1
    );
  }

  handleRequestCreateStockItem(): void {
    const value = this.targetStorageGroup$.getValue().value;
    this.requestCreateStockItem.emit({ type: value, payload: this.sif.value });
  }

  updateStorageItemGroup(option: StorageItemGroup): void {
    this.targetStorageGroup$.next(option);
    this.storageType = option;
    // 2 = ReplacementStockItem
    if (option == this.storageGroupOptions[2]) {
      this.sif.addControl(
        'product',
        this.fb.control(null, [Validators.required])
      );
      this.sif.removeControl('storageSystemType');
      this.sif.removeControl('order');
    } else {
      this.sif.removeControl('product');
      this.sif.addControl(
        'storageSystemType',
        this.fb.control(null, [Validators.required])
      );
    }
  }
}
