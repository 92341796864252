import { createReducer, on } from '@ngrx/store';

import * as fromLeadsModuoeModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';

import { LeadsActions } from '../actions';
import {
  getAndHandleBackendErrors,
  mergeEntities
} from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromLeadsModuoeModels.Lead };
  errors: ErrorsObject;
  leadsErrors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    LeadsActions.ReadLeads,
    LeadsActions.ReadLead,
    LeadsActions.UpdateLead,
    LeadsActions.UpdateLeadSilently,
    LeadsActions.UpdateLeadColumns,
    LeadsActions.MailLead,
    LeadsActions.CreateLead,
    LeadsActions.DeleteLead,
    LeadsActions.TransformLead,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    LeadsActions.CreateLeadSuccess,
    LeadsActions.UpdateLeadSuccess,
    LeadsActions.UpdateLeadSilentlySuccess,
    LeadsActions.UnArchiveLeadSuccess,
    LeadsActions.ArchiveLeadSuccess,
    LeadsActions.MailLeadSuccess,

    (state, { response }) => {
      return {
        ...state,
        errors: {},
        entities: mergeEntities([response], null, state.entities),
        loading: false
      };
    }
  ),
  on(LeadsActions.UpdateLeadColumnsSuccess, (state, { response }) => {
    const leads = response.leads;

    return {
      ...state,
      errors: {},
      entities: mergeEntities(leads, null, state.entities),
      loading: false
    };
  }),
  on(LeadsActions.ReadLeadsSuccess, (state, { response }) => {
    const leads = response['hydra:member'];
    return {
      ...state,
      entities: mergeEntities(leads, { delete: true }, state.entities),
      errors: {},
      loading: false,
      loaded: true
    };
  }),
  on(LeadsActions.ReadLeadSuccess, (state, { response }) => {
    return {
      ...state,
      entities: mergeEntities([response], null, state.entities),
      errors: {},
      loading: false
    };
  }),
  on(
    LeadsActions.DeleteLeadSuccess,
    LeadsActions.TransformLeadSuccess,
    LeadsActions.DeleteArchivedLeadSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removed, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    LeadsActions.CreateLeadFail,
    LeadsActions.MailLeadFail,
    LeadsActions.ReadLeadsFail,
    LeadsActions.ReadLeadFail,
    LeadsActions.UpdateLeadFail,
    LeadsActions.UpdateLeadColumnsFail,
    LeadsActions.UpdateLeadSilentlyFail,
    LeadsActions.TransformLeadFail,
    LeadsActions.DeleteLeadFail,
    LeadsActions.DeleteArchivedLeadFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(LeadsActions.ResetCurrentLead, state => {
    return {
      ...state,
      loading: false,
      current: null
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
