import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {DocumentDeliveriesActions} from '../actions/';
import {DocumentDeliveriesService} from '../../services';
import {ModalDialogOptions} from '../../../application-state/models';
import {NotifierService} from "angular-notifier";
import {OrdersActions} from "../../../orders/store";

@Injectable()
export class DocumentDeliveriesEffects {

  constructor(private actions$: Actions, private service: DocumentDeliveriesService, private notifierService: NotifierService) {
  }


  CreateEmailDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.CreateEmailDocumentDelivery),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createEmailDocumentDelivery(payload).pipe(
        map((response) => DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess({response, invoiceIri})),
        catchError(response => of(DocumentDeliveriesActions.CreateEmailDocumentDeliveryFail({response})))
      );
    })
  ));


  CreateRegisteredLetterDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.CreateRegisteredLetterDocumentDelivery),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createRegisteredLetterDocumentDelivery(payload).pipe(
        map((response) => DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess({
          response,
          invoiceIri
        })),
        catchError(response => of(DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliveryFail({response})))
      );
    })
  ));


  CreateLetterXPressDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.CreateLetterXPressDocumentDelivery),
    switchMap(({payload, invoiceIri}) => {
      return this.service.createLetterXPressDocumentDelivery(payload).pipe(
        map((response) => DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess({response, invoiceIri})),
        catchError(response => of(DocumentDeliveriesActions.CreateLetterXPressDocumentDeliveryFail({response})))
      );
    })
  ));


  ReadEmailDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.ReadEmailDocumentDelivery),
    switchMap(({iri}) => {
      return this.service.readEmailDocumentDelivery(iri).pipe(
        map((response => DocumentDeliveriesActions.ReadEmailDocumentDeliverySuccess({response}))),
        catchError(response => of(DocumentDeliveriesActions.ReadEmailDocumentDeliveryFail({response})))
      );
    })
  ));


  ReadRegisteredLetterDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.ReadRegisteredLetterDocumentDelivery),
    switchMap(({iri}) => {
      return this.service.readRegisteredLetterDocumentDelivery(iri).pipe(
        map((response => DocumentDeliveriesActions.ReadRegisteredLetterDocumentDeliverySuccess({response}))),
        catchError(response => of(DocumentDeliveriesActions.ReadRegisteredLetterDocumentDeliveryFail({response})))
      );
    })
  ));


  ReadLetterXPressDocumentDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(DocumentDeliveriesActions.ReadLetterXPressDocumentDelivery),
    switchMap(({iri}) => {
      return this.service.readLetterXPressDocumentDelivery(iri).pipe(
        map((response => DocumentDeliveriesActions.ReadLetterXPressDocumentDeliverySuccess({response}))),
        catchError(response => of(DocumentDeliveriesActions.ReadLetterXPressDocumentDeliveryFail({response})))
      );
    })
  ));

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      DocumentDeliveriesActions.CreateEmailDocumentDeliveryFail,
      DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliveryFail,
      DocumentDeliveriesActions.CreateLetterXPressDocumentDeliveryFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_FAIL:
          message = 'Beim Verschicken der Rechnung per E-Mail ist ein Fehler aufgetreten:' + errors;
          break;
        case DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_FAIL:
          message = 'Beim Verschicken der Rechnung als Einschreiben ist ein Fehler aufgetreten:' + errors;
          break;
        case DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_FAIL:
          message = 'Beim Verschicken der Rechnung als Brief ist ein Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});

  DocumentDeliveryActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      DocumentDeliveriesActions.CreateEmailDocumentDeliverySuccess,
      DocumentDeliveriesActions.CreateRegisteredLetterDocumentDeliverySuccess,
      DocumentDeliveriesActions.CreateLetterXPressDocumentDeliverySuccess,
    ),
    map(({type}) => {

      const textOptions = {
        [DocumentDeliveriesActions.CREATE_EMAIL_DOCUMENT_DELIVERY_SUCCESS]: 'Die Rechnung wurde per E-Mail verschickt.',
        [DocumentDeliveriesActions.CREATE_REGISTERED_LETTER_DOCUMENT_DELIVERY_SUCCESS]: 'Die Rechnung wurde als Einschreiben verschickt.',
        [DocumentDeliveriesActions.CREATE_LETTER_XPRESS_DOCUMENT_DELIVERY_SUCCESS]: 'Die Rechnung wurde als Brief verschickt.',
      };
      this.notifierService.show({type: 'success', message: textOptions[type]});
    })
  ), {dispatch: false});

}
