import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Product, LabLocation} from '../../../master-data/models';
import {Observable} from 'rxjs';
import {Order} from '../../models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {CustomerCooperationsSelectors, LabLocationsSelectors} from '../../../master-data/store/selectors';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {CustomerCooperationsActions, LabLocationsActions} from '../../../master-data/store';
import {OrdersSelectors} from '../../store/selectors';
import {BaseOnDestroyComponent} from "../../../shared/injectables/BaseOnDestroy.component";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {extractIri} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-order-lab-location-select',
  styleUrls: ['./order-lab-location-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrderLabLocationSelectComponent),
    multi: true
  }],

  template: `
    <div [formGroup]="form">

      <ng-select
        formControlName="selectedElement"
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        placeholder="Laborstandort"
        (touchstart)="onTouched($event)"
        (change)="setSelectedItem($event)">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
          <span *ngIf="getOrderCount(item['@id'])>0">({{getOrderCount(item['@id'])}}x)</span>
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderLabLocationSelectComponent extends BaseOnDestroyComponent implements OnInit, ControlValueAccessor {
  @Output() updateSelectedObject: EventEmitter<LabLocation> = new EventEmitter<LabLocation>();
  form: FormGroup;

  items$: Observable<Array<LabLocation>>;
  isLoading$: Observable<boolean>;
  selectedItem: LabLocation;

  orders: Order[];
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });

    this.items$ = this.store$.select(LabLocationsSelectors.sList);
    this.isLoading$ = this.store$.select(LabLocationsSelectors.isLoading);
    loadIfNotLoaded(this.store$, LabLocationsSelectors.isLoaded, LabLocationsActions.ReadLabLocations());
    this.store$.select(OrdersSelectors.sList).subscribe((orders) => {
      this.orders = orders;
    });

  }

  getOrderCount(ident: string): number {

    return this.orders.filter(e => e.analysisLocation === ident || e.dataRecoveryLocation === ident
    ).length || -1;
  }

  setSelectedItem(element: LabLocation): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: LabLocation): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }


}
