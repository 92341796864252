import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';

import {CustomerReplacementStockItemsActions, ReplacementStockItemsActions} from '../actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ReplacementStockItemsService} from '../../services';
import {OrdersActions} from "../../../orders/store";
import {NotifierService} from "angular-notifier";

@Injectable()
export class ReplacementStockItemsEffects {

  constructor(private actions$: Actions, private service: ReplacementStockItemsService, private notifierService: NotifierService) {
  }


  createReplacementStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementStockItemsActions.CreateReplacementStockItem),
    switchMap(({payload}) => {

      return this.service
        .createReplacementStockItem(payload)
        .pipe(
          map((response: any) => ReplacementStockItemsActions.CreateReplacementStockItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementStockItemsActions.CreateReplacementStockItemFail({response})))
        );
    })
  ));


  readReplacementStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementStockItemsActions.ReadReplacementStockItem),
    mergeMap(({iri}) => {

      return this.service
        .getReplacementStockItem(iri)
        .pipe(
          map((response: any) => ReplacementStockItemsActions.ReadReplacementStockItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementStockItemsActions.ReadReplacementStockItemFail({response})))
        );
    })
  ));

  readReplacementStockItems$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementStockItemsActions.ReadReplacementStockItems),
    switchMap(({page, params}) => {

      return this.service
        .getReplacementStockItems(page, params)
        .pipe(
          map((response) => ReplacementStockItemsActions.ReadReplacementStockItemsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementStockItemsActions.ReadReplacementStockItemsFail({response})))
        );
    })
  ));

  updateReplacementStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementStockItemsActions.UpdateReplacementStockItem),
    switchMap(({iri, payload}) => {

      return this.service
        .updateReplacementStockItem(iri, payload)
        .pipe(
          map((response: any) => ReplacementStockItemsActions.UpdateReplacementStockItemSuccess({response})),
          catchError((response: HttpErrorResponse) => of(ReplacementStockItemsActions.UpdateReplacementStockItemFail({response})))
        );
    })
  ));


  deleteReplacementStockItem$ = createEffect(() => this.actions$.pipe(
    ofType(ReplacementStockItemsActions.DeleteReplacementStockItem),
    switchMap(({iri}) => {

      return this.service
        .deleteReplacementStockItem(iri)
        .pipe(
          map(() => ReplacementStockItemsActions.DeleteReplacementStockItemSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(ReplacementStockItemsActions.DeleteReplacementStockItemFail({response})))
        );
    })
  ));

  FailActions = createEffect(() => this.actions$.pipe(
    ofType(
      ReplacementStockItemsActions.CreateReplacementStockItemFail,
      ReplacementStockItemsActions.UpdateReplacementStockItemFail,
      ReplacementStockItemsActions.ReadReplacementStockItemFail,
      ReplacementStockItemsActions.DeleteReplacementStockItemFail,
    ),
    map(({type, response}) => {

      console.log({response});
      const errors = response?.error['hydra:description'];
      let message = 'Fehler';
      switch (type) {
        case ReplacementStockItemsActions.CREATE_REPLACEMENT_STOCK_ITEM_FAIL:
          message = 'Beim Anlegen eines neuen Lagereintrag sind Fehler aufgetreten:' + errors;
          break;
        case ReplacementStockItemsActions.UPDATE_REPLACEMENT_STOCK_ITEM_FAIL:
          message = 'Beim Aktualisieren des Lagereintrags sind Fehler aufgetreten:' + errors;
          break;
        case ReplacementStockItemsActions.DELETE_REPLACEMENT_STOCK_ITEM_FAIL:
          message = 'Beim Löschen des Lagereintrags ist ein Fehler aufgetreten:' + errors;
          break;
        case ReplacementStockItemsActions.READ_REPLACEMENT_STOCK_ITEM_FAIL:
          message = 'Beim Lesen des Lagereintrags sind Fehler aufgetreten:' + errors;
          break;
      }
      this.notifierService.show({type: 'error', message});
    })
  ), {dispatch: false});
  successActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      ReplacementStockItemsActions.CreateReplacementStockItemSuccess,
      ReplacementStockItemsActions.UpdateReplacementStockItemSuccess
    ),
    map(({type}) => {
      let message = 'Erfolgreich';
      switch (type) {
        case ReplacementStockItemsActions.CREATE_REPLACEMENT_STOCK_ITEM_SUCCESS:
          message = 'Ein neuer Lagereintrag wurde angelegt.';
          break;
        case ReplacementStockItemsActions.UPDATE_REPLACEMENT_STOCK_ITEM_SUCCESS:
          message = 'Der Lagereintrag wurde aktualisiert.';
          break;
      }
      this.notifierService.show({type: 'success', message});

    })
  ), {dispatch: false});
}
