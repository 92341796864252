import {createSelector} from '@ngrx/store';

import {selectWarehouseModuleState, STOCK_ITEMS_FEATURE_KEY} from '../reducers';

export const selectStockItemsState = createSelector(
  selectWarehouseModuleState,
  (moduleState) => moduleState[STOCK_ITEMS_FEATURE_KEY]
);

export const selectStockItemsEntities = createSelector(
  selectStockItemsState,
  (state) => state.entities
);

export const selectStockItems = createSelector(
  selectStockItemsEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectStockItemsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectStockItemsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectStockItemsState,
  (state) => state.loaded
);
