<div class="wrapper  p-y--8">
  <div class="widget card">
    <div class="widget_header">
<!--      <div class="loading-indicator" *ngIf="isLoading$ | async">-->
<!--        <mat-spinner [diameter]="20"></mat-spinner>-->
<!--      </div>-->
      <span>{{"dashboard.feedback_requests.title_received" | translate}}</span>
      <mat-button-toggle-group class="me-3" [multiple]="true"  (change)="onToggleChange($event)">
        <mat-button-toggle *ngFor="let option of options" [value]="option.value">
          {{ option.label | translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="widget_content">
      <app-feedback-request-list
        [profile]="profile"
        [selectedValues]="selectedValue"
        [dispatchAction]="AdministratorFeedbackRequestsActions.LoadSentAdministratorFeedbackRequests"
        [feedbacks$]="feedbacks$"
      >
      </app-feedback-request-list>
    </div>
  </div>
</div>
