import { Component, Input, OnInit } from '@angular/core';
import { TransitionsActions } from '../../../application-state/store/actions';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions } from '@ngrx/effects';
import { ModalDialogOptions } from '../../../application-state/models';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { AuthService } from '../../../auth/services/auth.service';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';

@Component({
  selector: 'app-action-box-to-assign-analysis',
  styleUrls: ['./action-box-to-assign-analysis.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">
                Speichersys. nicht i.O., Analyseort festlegen?
              </div>
              <div class="sub-header col-auto">
                Wurde alles mit dem Vertrieb geklärt? Bitte erst dann im Prozess
                weiter!
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
            <button
              class="decision-btn me-3"
              mat-button
              color="green"
              (click)="handleMakeTransitionYes()"
              [disabled]="!okAction"
            >
              <mat-icon class="me-2">done</mat-icon>
              <span>Ja</span>
            </button>
            <button
              class="decision-btn"
              matTooltip="Auftrag abbrechen"
              mat-button
              color="red"
              [disabled]="!cancelAction"
              (click)="handleMakeTransitionCancel()"
            >
              <mat-icon class="me-2">cancel</mat-icon>
              <span>Nein</span>
            </button>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Warte auf Analyseort:</div>
          <div class="sub-header col-auto">
            SS nicht i.O., warte auf Festlegung Analyseort
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToAssignAnalysisComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  onDestroy$: Subject<any> = new Subject<any>();
  okAction: PotentialAction = null;
  cancelAction: PotentialAction = null;

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.okAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_assign_analysis') > -1 && !e.error
        );
        this.cancelAction = order.potentialActions?.find(
          e => e.transition.indexOf('to_no_order') > -1 && !e.error
        );
      });
  }

  handleMakeTransitionYes(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleMakeTransitionCancel(): void {
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Auftrag abgebrochen werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, abbrechen',
          cancelText: 'Nicht abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        const action = this.cancelAction;
        if (!action) {
          return;
        }
        const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
        const payload = {
          workflow: action.workflow,
          transition: action.transition
        };
        this.store$.dispatch(
          TransitionsActions.MakeTransition({ uri, payload })
        );
      });
  }
}
