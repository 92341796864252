import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_INVOICE_ITEM = '[Invoices Module] Create Invoice Item';
export const CREATE_INVOICE_ITEM_FAIL = '[Invoices Module] Create Invoice Item Fail';
export const CREATE_INVOICE_ITEM_SUCCESS = '[Invoices Module] Create Invoice Item Success';

export const CreateInvoiceItem = createAction(
  CREATE_INVOICE_ITEM,
  props<{ payload: fromInvoicesModuleModels.InvoiceItem, invoiceIri: string }>()
);

export const CreateInvoiceItemSuccess = createAction(
  CREATE_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoiceItem, invoiceIri: string }>()
);

export const CreateInvoiceItemFail = createAction(
  CREATE_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_INVOICE_ITEM = '[Invoices Module] Read Invoice Item';
export const READ_INVOICE_ITEM_SUCCESS = '[Invoices Module] Read Invoice Item Success';
export const READ_INVOICE_ITEM_FAIL = '[Invoices Module] Read Invoice Item Fail';

export const ReadInvoiceItem = createAction(
  READ_INVOICE_ITEM,
  props<{ iri: string }>()
);

export const ReadInvoiceItemSuccess = createAction(
  READ_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoiceItem | any }>()
);

export const ReadInvoiceItemFail = createAction(
  READ_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_INVOICE_ITEM = '[Invoices Module] Update Invoice Item';
export const UPDATE_INVOICE_ITEM_SUCCESS = '[Invoices Module] Update Invoice Item Success';
export const UPDATE_INVOICE_ITEM_FAIL = '[Invoices Module] Update Invoice Item Fail';

export const UpdateInvoiceItem = createAction(
  UPDATE_INVOICE_ITEM,
  props<{ iri: string, payload: fromInvoicesModuleModels.InvoiceItem | any, invoiceIri: string }>()
);

export const UpdateInvoiceItemSuccess = createAction(
  UPDATE_INVOICE_ITEM_SUCCESS,
  props<{ response: fromInvoicesModuleModels.InvoiceItem, invoiceIri: string }>()
);

export const UpdateInvoiceItemFail = createAction(
  UPDATE_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_INVOICE_ITEM = '[Invoices Module] Delete Invoice Item';
export const DELETE_INVOICE_ITEM_SUCCESS = '[Invoices Module] Delete Invoice Item Success';
export const DELETE_INVOICE_ITEM_FAIL = '[Invoices Module] Delete Invoice Item Fail';

export const DeleteInvoiceItem = createAction(
  DELETE_INVOICE_ITEM,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteInvoiceItemSuccess = createAction(
  DELETE_INVOICE_ITEM_SUCCESS,
  props<{ iri: string, invoiceIri: string }>()
);

export const DeleteInvoiceItemFail = createAction(
  DELETE_INVOICE_ITEM_FAIL,
  props<{ response: HttpErrorResponse }>()
);
