import { AddressTypesLoadedGuard } from './address-types-loaded.guard';
import { AnalysisPriorityModesLoadedGuard } from './analysis-priority-modes-loaded.guard';
import { AnalysisResultCategoriesLoadedGuard } from './analysis-result-categories-loaded.guard';
import { AnalysisResultsLoadedGuard } from './analysis-results-loaded.guard';
import { CountriesLoadedGuard } from './countries-loaded.guard';
import { CurrenciesLoadedGuard } from './currencies-loaded.guard';
import { CustomerCooperationsLoadedGuard } from './customer-cooperations-loaded.guard';
import { DamagesLoadedGuard } from './damages-loaded.guard';
import { DataRecoveryPriorityModesLoadedGuard } from './data-recovery-priority-modes-loaded.guard';
import { DepartmentLogosLoadedGuard } from './department-logos-loaded.guard';
import { DepartmentsLoadedGuard } from './departments-loaded.guard';
import { DestructionTypesLoadedGuard } from './destruction-types-loaded.guard';
import { DiscountsLoadedGuard } from './discounts-loaded.guard';
import { DisposalTypesLoadedGuard } from './disposal-types-loaded.guard';
import { FileSystemsLoadedGuard } from './file-systems-loaded.guard';
import { GradesLoadedGuard } from './grades-loaded.guard';
import { LabLocationsLoadedGuard } from './lab-locations-loaded.guard';
import { LocalesLoadedGuard } from './locales-loaded.guard';
import { OperatingSystemsLoadedGuard } from './operating-systems-loaded.guard';
import { ProductUnitsLoadedGuard } from './product-units-loaded.guard';
import { ProductsLoadedGuard } from './products-loaded.guard';
import { ReplacementDataMediumSourcesLoadedGuard } from './replacement-data-medium-sources-loaded.guard';
import { RolesLoadedGuard } from './roles-loaded.guard';
import { SalutationsLoadedGuard } from './salutations-loaded.guard';
import { ServicesLoadedGuard } from './services-loaded.guard';
import { SpecialAgreementsLoadedGuard } from './special-agreements-loaded.guard';
import { StorageLocationsLoadedGuard } from './storage-locations-loaded.guard';
import { StorageSystemManufacturersLoadedGuard } from './storage-system-manufacturers-loaded.guard';
import { StorageSystemsLoadedGuard } from './storage-systems-loaded.guard';
import { SymptomsLoadedGuard } from './symptoms-loaded.guard';
import { TaxesLoadedGuard } from './taxes-loaded.guard';
import { TermsAndConditionsLoadedGuard } from './terms-and-conditions-loaded.guard';

export const AllGuards = [
  AddressTypesLoadedGuard,
  AnalysisPriorityModesLoadedGuard,
  AnalysisResultCategoriesLoadedGuard,
  AnalysisResultsLoadedGuard,
  CountriesLoadedGuard,
  CurrenciesLoadedGuard,
  CustomerCooperationsLoadedGuard,
  DamagesLoadedGuard,
  DataRecoveryPriorityModesLoadedGuard,
  DepartmentLogosLoadedGuard,
  DepartmentsLoadedGuard,
  DestructionTypesLoadedGuard,
  DiscountsLoadedGuard,
  DisposalTypesLoadedGuard,
  FileSystemsLoadedGuard,
  GradesLoadedGuard,
  LabLocationsLoadedGuard,
  LocalesLoadedGuard,
  OperatingSystemsLoadedGuard,
  ProductUnitsLoadedGuard,
  ProductsLoadedGuard,
  ReplacementDataMediumSourcesLoadedGuard,
  RolesLoadedGuard,
  SalutationsLoadedGuard,
  ServicesLoadedGuard,
  SpecialAgreementsLoadedGuard,
  StorageLocationsLoadedGuard,
  StorageSystemManufacturersLoadedGuard,
  StorageSystemsLoadedGuard,
  SymptomsLoadedGuard,
  TaxesLoadedGuard,
  TermsAndConditionsLoadedGuard,
];

export * from './address-types-loaded.guard';
export * from './analysis-priority-modes-loaded.guard';
export * from './analysis-result-categories-loaded.guard';
export * from './analysis-results-loaded.guard';
export * from './countries-loaded.guard';
export * from './currencies-loaded.guard';
export * from './customer-cooperations-loaded.guard';
export * from './damages-loaded.guard';
export * from './data-recovery-priority-modes-loaded.guard';
export * from './department-logos-loaded.guard';
export * from './departments-loaded.guard';
export * from './destruction-types-loaded.guard';
export * from './discounts-loaded.guard';
export * from './disposal-types-loaded.guard';
export * from './file-systems-loaded.guard';
export * from './grades-loaded.guard';
export * from './lab-locations-loaded.guard';
export * from './locales-loaded.guard';
export * from './operating-systems-loaded.guard';
export * from './product-units-loaded.guard';
export * from './products-loaded.guard';
export * from './replacement-data-medium-sources-loaded.guard';
export * from './roles-loaded.guard';
export * from './salutations-loaded.guard';
export * from './services-loaded.guard';
export * from './special-agreements-loaded.guard';
export * from './storage-locations-loaded.guard';
export * from './storage-system-manufacturers-loaded.guard';
export * from './storage-systems-loaded.guard';
export * from './symptoms-loaded.guard';
export * from './taxes-loaded.guard';
export * from './terms-and-conditions-loaded.guard';
