import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';


/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Login

export const LOGIN_USER = '[Auth Module] Login User';
export const LOGIN_USER_SUCCESS = '[Auth Module] Login User Success';
export const LOGIN_USER_FAIL = '[Auth Module] Login User Fail';

export const LoginUser = createAction(
  LOGIN_USER,
  props<{ username: string, password: string }>()
);

export const LoginUserSuccess = createAction(
  LOGIN_USER_SUCCESS,
  props<{ response: any }>()
);

export const LoginUserFail = createAction(
  LOGIN_USER_FAIL,
  props<{ response: HttpErrorResponse | any }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Logout

export const LOGOUT_USER = '[Auth Module] Logout User';
export const LOGOUT_USER_SUCCESS = '[Auth Module] Logout User Success';
export const LOGOUT_USER_FAIL = '[Auth Module] Logout User Fail';

export const LogoutUser = createAction(
  LOGOUT_USER
);

export const LogoutUserSuccess = createAction(
  LOGOUT_USER_SUCCESS
);

export const LogoutUserFail = createAction(
  LOGOUT_USER_FAIL
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Password Reset Request

export const CREATE_PASSWORD_RESET_REQUEST = '[Auth Module] Create Password Reset Request';
export const CREATE_PASSWORD_RESET_REQUEST_SUCCESS = '[Auth Module] Create Password Reset Request Success';
export const CREATE_PASSWORD_RESET_REQUEST_FAIL = '[Auth Module] Create Password Reset Request Fail';

export const CreatePasswordResetRequest = createAction(
  CREATE_PASSWORD_RESET_REQUEST,
  props<{ payload: { username: string } }>()
);

export const CreatePasswordResetRequestSuccess = createAction(
  CREATE_PASSWORD_RESET_REQUEST_SUCCESS,
  props<{ response: any }>()
);

export const CreatePasswordResetRequestFail = createAction(
  CREATE_PASSWORD_RESET_REQUEST_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Password Reset

export const CREATE_PASSWORD_RESET = '[Auth Module] Create Password Reset Reset';
export const CREATE_PASSWORD_RESET_SUCCESS = '[Auth Module] Create Password Reset Reset Success';
export const CREATE_PASSWORD_RESET_FAIL = '[Auth Module] Create Password Reset Reset Fail';

export const CreatePasswordReset = createAction(
  CREATE_PASSWORD_RESET,
  props<{
    payload: {
      token: string,
      plainPassword: string,
      plainPasswordConfirmation: string
    }
  }>()
);

export const CreatePasswordResetSuccess = createAction(
  CREATE_PASSWORD_RESET_SUCCESS,
  props<{ response: any }>()
);

export const CreatePasswordResetFail = createAction(
  CREATE_PASSWORD_RESET_FAIL,
  props<{ response: HttpErrorResponse }>()
);
