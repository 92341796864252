import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { DepartmentAdsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.DepartmentAd };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
  uploadStatus: { type: number; loaded: number; total?: number };
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    DepartmentAdsActions.CreateDepartmentAd,
    DepartmentAdsActions.ReadDepartmentAd,
    DepartmentAdsActions.ReadDepartmentAds,
    // DepartmentAdsActions.UpdateDepartmentAd,
    DepartmentAdsActions.DeleteDepartmentAd,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(DepartmentAdsActions.ReadDepartmentAdSuccess, (state, { response }) => {
    const entities = {
      ...state.entities,
      [response['@id']]: response
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: false
    };
  }),
  on(DepartmentAdsActions.ReadDepartmentAdsSuccess, (state, { response }) => {
    const fss = response['hydra:member'];
    const entities = fss.reduce(
      (entities: { [id: string]: fromModuleModels.DepartmentAd }, fs) => {
        return {
          ...entities,
          [fs['@id']]: fs
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(DepartmentAdsActions.CreateDepartmentAdStatus, (state, { response }) => {
    return {
      ...state,
      uploadStatus: response,
      loading: true
    };
  }),

  on(
    DepartmentAdsActions.CreateDepartmentAdSuccess,
    // DepartmentAdsActions.UpdateDepartmentAdSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(DepartmentAdsActions.DeleteDepartmentAdSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(
    DepartmentAdsActions.CreateDepartmentAdFail,
    DepartmentAdsActions.ReadDepartmentAdFail,
    DepartmentAdsActions.ReadDepartmentAdsFail,
    // DepartmentAdsActions.UpdateDepartmentAdFail,
    DepartmentAdsActions.DeleteDepartmentAdFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
