import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { ProductOrderItemsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.ProductOrderItem };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    ProductOrderItemsActions.CreateProductOrderItem,
    ProductOrderItemsActions.ReadProductOrderItem,
    ProductOrderItemsActions.ReadProductOrderItems,
    ProductOrderItemsActions.UpdateProductOrderItem,
    ProductOrderItemsActions.DeleteProductOrderItem,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    ProductOrderItemsActions.CreateProductOrderItemSuccess,
    ProductOrderItemsActions.UpdateProductOrderItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    ProductOrderItemsActions.ReadProductOrderItemsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: { [iri: string]: fromModuleModels.ProductOrderItem },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    ProductOrderItemsActions.DeleteProductOrderItemSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    ProductOrderItemsActions.CreateProductOrderItemFail,
    ProductOrderItemsActions.UpdateProductOrderItemFail,
    ProductOrderItemsActions.DeleteProductOrderItemFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false,
        loaded: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
