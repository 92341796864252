import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { SymptomsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { SymptomsService } from '../../services/symptoms.service';

@Injectable()
export class SymptomsEffects {
  CreateSymptom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SymptomsActions.CreateSymptom),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createSymptom(payload).pipe(
          map(response => SymptomsActions.CreateSymptomSuccess({ response })),
          catchError(response =>
            of(SymptomsActions.CreateSymptomFail({ response }))
          )
        );
      })
    )
  );
  ReadSymptom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SymptomsActions.ReadSymptom),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readSymptom(iri).pipe(
          map(response => SymptomsActions.ReadSymptomSuccess({ response })),
          catchError(response =>
            of(SymptomsActions.ReadSymptomFail({ response }))
          )
        );
      })
    )
  );
  ReadSymptoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SymptomsActions.ReadSymptoms),
      map(action => action),
      switchMap(() => {
        return this.service.readSymptoms().pipe(
          map(response => SymptomsActions.ReadSymptomsSuccess({ response })),
          catchError(response =>
            of(SymptomsActions.ReadSymptomsFail({ response }))
          )
        );
      })
    )
  );
  UpdateSymptom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SymptomsActions.UpdateSymptom),
      map(action => action),
      switchMap(({ iri, payload }) => {
        return this.service.updateSymptom(iri, payload).pipe(
          map(response => SymptomsActions.UpdateSymptomSuccess({ response })),
          catchError(response =>
            of(SymptomsActions.UpdateSymptomFail({ response }))
          )
        );
      })
    )
  );
  DeleteSymptom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SymptomsActions.DeleteSymptom),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteSymptom(iri).pipe(
          map(() => SymptomsActions.DeleteSymptomSuccess({ iri })),
          catchError(response =>
            of(SymptomsActions.DeleteSymptomFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SymptomsActions.DeleteSymptomFail,
          SymptomsActions.UpdateSymptomFail,
          SymptomsActions.ReadSymptomsFail,
          SymptomsActions.ReadSymptomFail,
          SymptomsActions.CreateSymptomFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SymptomActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SymptomsActions.CreateSymptomSuccess,
        SymptomsActions.UpdateSymptomSuccess
      ),
      map(({ type }) => {
        const text =
          type === SymptomsActions.CREATE_SYMPTOM_SUCCESS
            ? 'Eine neues Symptom wurde angelegt.'
            : 'Das Symptom wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: SymptomsService,
    private notifierService: NotifierService
  ) {}
}
