import {createSelector} from '@ngrx/store';

import {ORDER_SPECIAL_AGREEMENTS_FEATURE_KEY, selectOrdersModuleState} from '../reducers';

export const selectOrderSpecialAgreementsState = createSelector(
  selectOrdersModuleState,
  (moduleState) => moduleState[ORDER_SPECIAL_AGREEMENTS_FEATURE_KEY]
);

export const selectOrderSpecialAgreementsEntities = createSelector(
  selectOrderSpecialAgreementsState,
  (state) => state.entities
);

export const selectOrderSpecialAgreements = createSelector(
  selectOrderSpecialAgreementsEntities,
  (entities) => Object.values(entities)
);
export const selectOrderSpecialAgreementsByOrder = createSelector(
  selectOrderSpecialAgreements,
  (entities, {iri}) => entities.filter(e => e.order === iri).sort((a, b) => a.sort - b.sort)
);

export const selectErrors = createSelector(
  selectOrderSpecialAgreementsState,
  (state) => state.errors
);

export const isLoading = createSelector(
  selectOrderSpecialAgreementsState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectOrderSpecialAgreementsState,
  (state) => state.loaded
);
