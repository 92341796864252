import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {OrderSpecialAgreementsActions} from '../actions';
import {OrderSpecialAgreementsService} from '../../services';

@Injectable()
export class OrderSpecialAgreementsEffects {

  constructor(private actions$: Actions, private service: OrderSpecialAgreementsService) {
  }


  createOrderSpecialAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(OrderSpecialAgreementsActions.CreateOrderSpecialAgreement),
    switchMap(({payload}) => {

      return this.service
        .createOrderSpecialAgreement(payload)
        .pipe(
          map((response: any) => OrderSpecialAgreementsActions.CreateOrderSpecialAgreementSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderSpecialAgreementsActions.CreateOrderSpecialAgreementFail({response})))
        );
    })
  ));


  readOrderSpecialAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(OrderSpecialAgreementsActions.ReadOrderSpecialAgreement),
    switchMap(({iri}) => {

      return this.service
        .readOrderSpecialAgreement(iri)
        .pipe(
          map((response: any) => OrderSpecialAgreementsActions.ReadOrderSpecialAgreementSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderSpecialAgreementsActions.ReadOrderSpecialAgreementFail({response})))
        );
    })
  ));


  readOrderSpecialAgreements$ = createEffect(() => this.actions$.pipe(
    ofType(OrderSpecialAgreementsActions.ReadOrderSpecialAgreements),
    switchMap(({params}) => {

      return this.service
        .readOrderSpecialAgreements(params)
        .pipe(
          map((response: any) => OrderSpecialAgreementsActions.ReadOrderSpecialAgreementsSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderSpecialAgreementsActions.ReadOrderSpecialAgreementsFail({response})))
        );
    })
  ));


  updateOrderSpecialAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(OrderSpecialAgreementsActions.UpdateOrderSpecialAgreement),
    switchMap(({iri, payload}) => {

      return this.service
        .updateOrderSpecialAgreement(iri, payload)
        .pipe(
          map((response: any) => OrderSpecialAgreementsActions.UpdateOrderSpecialAgreementSuccess({response})),
          catchError((response: HttpErrorResponse) => of(OrderSpecialAgreementsActions.UpdateOrderSpecialAgreementFail({response})))
        );
    })
  ));


  deleteOrderSpecialAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(OrderSpecialAgreementsActions.DeleteOrderSpecialAgreement),
    switchMap(({iri}) => {

      return this.service
        .deleteOrderSpecialAgreement(iri)
        .pipe(
          map(() => OrderSpecialAgreementsActions.DeleteOrderSpecialAgreementSuccess({iri})),
          catchError((response: HttpErrorResponse) => of(OrderSpecialAgreementsActions.DeleteOrderSpecialAgreementFail({response})))
        );
    })
  ));
}
