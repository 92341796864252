import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output} from '@angular/core';
import {Offer, PayableInvoice} from '../../models';
import {Observable} from 'rxjs';
import * as moment from 'moment/moment';
import {cloneDeep} from 'lodash-es';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Department} from '../../../master-data/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Customer} from '../../../customers/models';
import {Order} from '../../../orders/models';
import {InvoiceRecipient} from '../../models/invoice-recipient.interface';
import {CustomersSelectors} from '../../../customers/store/selectors';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {extractUUID, extractIri, extractTypeByIri} from '../../../shared/utilities/objects.utility';
import {formatNumber} from '@angular/common';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {now} from 'moment/moment';
import {InvoiceItemDialogComponent} from '../invoice-item-dialog/invoice-item-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-offer-preview',
  styleUrls: ['./offer-preview.component.scss'],
  template: `
    <div class="invoice-wrapper">
      <div class="invoice-content">
        <div class="row invoice-header">
          <div class="col-8">
            <div class="invoice-action-box-wrapper my-3">
              <app-invoice-action-box *ngIf="offer" [invoice]="offer"></app-invoice-action-box>
            </div>
            <div class="invoice-head-recipient-wrapper mt-5">
              <app-invoice-head-recipient
                class="my-5"
                [readonly]="!canEditInvoice"
                [invoice$]="offer$"
                [customerReadOnly]="!isNew"
                (updateCustomer)="handleUpdateCustomer($event)"
                (updateOrder)="handleUpdateOrder($event)"
                (updateRecipient)="handleUpdateRecipient($event)"
              ></app-invoice-head-recipient>
            </div>
          </div>
          <div class="col-4">
            <app-invoice-head-issuer
              [readonly]="!canEditInvoice"
              [invoice$]="offer$"
              (updateIssuer)="handleUpdateIssuer($event)"
            ></app-invoice-head-issuer>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-8">
            <span class="heading--h2">Angebot / Offer {{ offer?.offerNumber }}</span>
          </div>

          <div class="col-4 text-right">
            <span class="heading--h2">
              Datum/Date: {{(offer?.createdAt ? offer.createdAt : now) | date: 'dd.MM.Y'}}
            </span>
          </div>
        </div>
        <div class="row my-2">
          <p class="m-b--64">Vielen Dank für Ihr Interesse.<br>
            Wir bieten Ihnen folgende Lieferungen und Leistungen:
          </p>
        </div>
        <div class="row my-2 mb-4">
          <app-invoice-items-summary [invoice$]="offer$"
                                     (requestUpdateInvoice)="updateInvoice.emit($event)"></app-invoice-items-summary>
        </div>
        <div class="row">
          <div class="col-7 ">
            <button mat-flat-button class="mb-2" *ngIf="canEditInvoice" (click)="addPosition()">
              <mat-icon class="text-bg-green text-white border-radius-25">add</mat-icon>
              <span class="pt-1 ms-2">
              Position hinzufügen
                </span>
            </button>

            <p class="gray">Angebot gültig bis {{ offer?.validUntil | date: 'dd.MM.Y' }}.</p>
          </div>
          <div class="col-5">
            <app-invoice-total [invoice]="offer"></app-invoice-total>

          </div>


        </div>
        <div class="row">
          <div class="col-12">

            <app-invoice-custom-text [invoice$]="offer$"
                                     (updateCustomText)="handleUpdateCustomText($event)"></app-invoice-custom-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12">

            <app-invoice-footer [invoice$]="offer$"></app-invoice-footer>
          </div>
        </div>
      </div>
      <pre>{{offer$|async|json}}</pre>
    </div>
  `
})
export class OfferPreviewComponent extends BaseOnDestroyComponent implements OnInit, OnDestroy {
  @Input() offer$: Observable<Offer>;
  offer: Offer;
  offerWithChanges: Offer;
  @Output() updateInvoice: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output() updateOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() updateCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
  customer: Customer;
  department: Department;
  now: number = now();
  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractId = extractUUID;
  protected readonly extractIri = extractIri;

  get canEditInvoice(): boolean {
    return !this.isNew && !this.offer.acceptedOffer;

  }

  get isNew(): boolean {
    return !this.offer || !this.offer['@id'];

  }

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.offer$
      .pipe(takeUntil(this.onDestroy$), filter(invoice => !!invoice))
      .subscribe(offer => {
        this.offer = offer;
        this.offerWithChanges = cloneDeep(offer);
        if (offer.customerNumber) {
          this.store$.select(CustomersSelectors.sByCustomerNumber, {customerNumber: offer.customerNumber})
            .pipe(takeUntil(this.onDestroy$), filter(i => !!i), take(1))
            .subscribe(c => {
              this.customer = c;
            });
        }
        if (offer.issuer) {
          this.store$.select(DepartmentsSelectors.sByIri, {iri: extractIri(offer.issuer)})
            .pipe(takeUntil(this.onDestroy$), filter(i => !!i), take(1))
            .subscribe(c => {
              this.department = c;
            });
        }
      });
  }

  getHourDifferential(futureDate: string): number {

    const duration = moment.duration(moment(futureDate).diff(moment(new Date())));
    return Math.floor(duration.asHours());
  }

  getInvoiceGrossSum(invoice?: Offer): string {
    if (invoice && invoice.grossTotal) {
      return `${formatNumber(parseFloat(invoice.grossTotal.value), this.locale, '1.2-2')} ${invoice.grossTotal.currency}`;
    } else {
      return '-';
    }
  }

  handleUpdateIssuer(selectedIssuers: Department): void {
    const invoice: Offer = cloneDeep(this.offerWithChanges || {});
    invoice.issuer = selectedIssuers;
    this.updateInvoice.emit(invoice);
    this.offerWithChanges = invoice;
  }

  handleUpdateCustomer(customer: Customer): void {
    console.log('handleUpdateCustomer', customer);
    this.updateCustomer.emit(customer);
    const invoice: Offer = cloneDeep(this.offerWithChanges || {});
    invoice.recipient.nameLine1 = customer.nameLine1;
    invoice.recipient.nameLine2 = customer.nameLine2;
    this.updateInvoice.emit(invoice);
    this.offerWithChanges = invoice;
  }

  handleUpdateOrder(order: Order): void {
    console.log('handleUpdateOrder', order);

    this.updateOrder.emit(order);
  }

  handleUpdateCustomText(payload: { customText: string }): void {
    console.log('handleUpdateCustomText', payload);
    const invoice: Offer = cloneDeep(this.offerWithChanges || {});
    invoice.customText = payload.customText;
    this.updateInvoice.emit(invoice);
    this.offerWithChanges = invoice;
  }

  addPosition(): void {
    this.dialog.open(InvoiceItemDialogComponent, {data: {invoice: this.offer}});

  }

  handleUpdateRecipient(c: InvoiceRecipient): void {
    console.log('handleUpdateRecipient', c);
    if (!this.offerWithChanges) {
      return;
    }
    const invoice: Offer = cloneDeep(this.offerWithChanges || {});
    invoice.recipient.address = {...invoice.recipient.address, ...c.address};
    invoice.recipient = {...invoice.recipient, ...c};
    this.updateInvoice.emit(invoice);
    this.offerWithChanges = invoice;
  }

}
