import { Component, Input, OnInit } from '@angular/core';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { MatDialog } from '@angular/material/dialog';
import { Shipment, ShipmentDirection } from '../../../shipping/models';
import { ShowShipmentDialogComponent } from '../show-shipment-dialog/show-shipment-dialog.component';
import { extractIri } from '../../utilities/objects.utility';
import { ShipmentDirectionsSelectors } from '../../../shipping/store/selectors';
import { takeUntil, tap } from 'rxjs/operators';
import { ShipmentDirectionsActions } from '../../../shipping/store';
import { getDescriptionOfShipmentState } from '../../../shipping/helpers/shipmentStateDescriptionMapper';

@Component({
  selector: 'app-shipment-display-line',
  styleUrls: ['./shipment-display-line.component.scss'],
  template: `
    <div class="card pos-relative my-2 p-3" style="min-height: 60px;">
      <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
      <ng-container *ngIf="shipment">
        <div class="row">
          <div class="col-2">
            Sendung vom {{ shipment.createdAt | momentDateWithTime }}
          </div>
          <div class="col-2">
            {{
              shipmentDirectionsEntities[shipment.shipmentDirection]?.direction
            }}
          </div>
          <div class="col-2">
            <ng-container *ngIf="shipment.supportsTracking">
              <a
                [href]="shipment.trackingLink"
                target="_blank"
                class="text-decoration-none"
                >{{ shipment['@type'] | myI18nSelect: shipmentTypes }}</a
              >
            </ng-container>
            <ng-container *ngIf="!shipment.supportsTracking">
              <span [matTooltip]="'Diese Sendung unterstützt kein Tracking.'">{{
                shipment['@type'] | myI18nSelect: shipmentTypes
              }}</span>
            </ng-container>
          </div>
          <div
            class="col-1"
            [matTooltip]="getDescriptionOfShipmentState(shipment.state)"
          >
            {{ shipment.state }}
          </div>
          <div class="col-2">
            Tracker: {{ shipment.trackerId || '-' }} ({{
              shipment.orders.length || '-'
            }}
            /{{ shipment.dataMedia.length || '-' }})
          </div>
          <div class="col-3 text-right">
            <button
              mat-button
              color="blue"
              (click)="openDetails(shipment)"
              class="me-2"
            >
              <mat-icon>qr_code_2</mat-icon>
              Details & Label
            </button>
            <button mat-button color="green" (click)="toggleDetails()">
              {{ (showDetails$ | async) ? 'Verberge ' : 'Zeige ' }}
              Details
            </button>
          </div>
        </div>
        <hr *ngIf="showDetails$ | async" />

        <app-shipment-detail-display-line
          *ngIf="showDetails$ | async"
          [shipment]="shipment"
        ></app-shipment-detail-display-line>
        <!--        <ng-container>-->
        <!--          <hr>-->

        <!--          <div class="p-2">-->
        <!--            <pre>{{shipment|json}}</pre>-->
        <!--          </div>-->
        <!--        </ng-container>-->
      </ng-container>
    </div>
  `
})
export class ShipmentDisplayLineComponent extends BaseOnDestroyComponent
  implements OnInit {
  @Input() shipment: Shipment;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shipmentDirectionsEntities: { [key: string]: ShipmentDirection };
  shipmentTypes = {
    UpsShipment: 'UPS',
    UpsPickupShipment: 'UPS Pickup',
    DhlShipment: 'DHL',
    GoPickupShipment: 'Go Pickup',
    GenericShipment: 'Generischer Versand'
  };
  protected readonly getDescriptionOfShipmentState = getDescriptionOfShipmentState;

  constructor(
    private store$: Store<ApplicationState>,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    // this.loadDetails();
    // this.store$.select(StorageLocationsSelectors.sEntities).pipe(takeUntil(this.onDestroy$)).subscribe(entities => {
    //   this.storageLocationEntities = entities;
    // });
    this.loadShipmentDirections();
  }

  toggleDetails(): void {
    this.showDetails$.next(!this.showDetails$.value);
  }

  openDetails(shipment: Shipment): void {
    this.dialog.open(ShowShipmentDialogComponent, {
      panelClass: 'width-95',
      data: { shipment: extractIri(shipment), direction: this.shipmentDirectionsEntities[shipment.shipmentDirection]?.direction }
    });
  }

  private loadShipmentDirections(): void {
    this.store$
      .select(ShipmentDirectionsSelectors.selectShipmentDirectionsEntities)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(e => {
          if (!e || !Object.keys(e) || Object.keys(e).length <= 0) {
            this.store$.dispatch(
              ShipmentDirectionsActions.ReadShipmentDirections()
            );
          }
        })
      )
      .subscribe(e => {
        this.shipmentDirectionsEntities = e;
      });
  }
}
