import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ShippingProvidersActions} from '../actions';
import {ShippingProvidersService} from '../../services/shipping-providers.service';

@Injectable()
export class ShippingProvidersEffects {

  constructor(private actions$: Actions, private service: ShippingProvidersService) {
  }


  getShippingProviderToDataRecovery$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingProvidersActions.ReadShippingProviderToDataRecovery),
    map(action => action),
    switchMap(({iri}) => {
      return this.service
        .getShippingProviderToDataRecovery(iri)
        .pipe(
          map(response => ShippingProvidersActions.ReadShippingProviderToDataRecoverySuccess({response})),
          catchError(response => of(ShippingProvidersActions.ReadShippingProviderToDataRecoveryFail({response})))
        );
    })
  ));


  getShippingProvidersToDataRecovery$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingProvidersActions.ReadShippingProvidersToDataRecovery),
    map(action => action),
    switchMap(() => {
      return this.service
        .getShippingProvidersToDataRecovery()
        .pipe(
          map(response => ShippingProvidersActions.ReadShippingProvidersToDataRecoverySuccess({response})),
          catchError(response => of(ShippingProvidersActions.ReadShippingProvidersToDataRecoveryFail({response})))
        );
    })
  ));


  getShippingProviderToCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingProvidersActions.ReadShippingProviderToCustomer),
    map(action => action),
    switchMap(({iri}) => {
      return this.service
        .getShippingProviderToCustomer(iri)
        .pipe(
          map(response => ShippingProvidersActions.ReadShippingProviderToCustomerSuccess({response})),
          catchError(response => of(ShippingProvidersActions.ReadShippingProviderToCustomerFail({response})))
        );
    })
  ));


  getShippingProvidersToCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingProvidersActions.ReadShippingProvidersToCustomer),
    map(action => action),
    switchMap(() => {
      return this.service
        .getShippingProvidersToCustomer()
        .pipe(
          map(response => ShippingProvidersActions.ReadShippingProvidersToCustomerSuccess({response})),
          catchError(response => of(ShippingProvidersActions.ReadShippingProvidersToCustomerFail({response})))
        );
    })
  ));
}
