import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ProductsSelectors,
  StorageLocationsSelectors, StorageSystemManufacturersSelectors,
  StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import {Product, StorageLocation} from '../../../master-data/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as fromWarehouseModuleModels from '../../models';
import {StockItem} from '../../models';
import {OrdersSelectors} from '../../../orders/store/selectors';
import * as WarehouseModuleActions from '../../store';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  ReplacementStockItemsActions
} from '../../store';
import * as fromOrdersModule from '../../../orders/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, takeUntil} from 'rxjs/operators';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {CustomerStockItemFormComponent} from '../customer-stock-item-form/customer-stock-item-form.component';

interface StorageItemGroup {
  value: string;
  label: string;
}

export type StockItemsTypes = 'CustomerReplacementStockItem' | 'CustomerStockItem' | 'ReplacementStockItem';

@Component({
  selector: 'app-stock-item-form-dialog',
  styleUrls: ['./stock-item-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Lagereintrag hinzufügen</h2>
    </app-dialog-header>
    <div mat-dialog-content style="min-width: 800px;">
      <div class="row" [formGroup]="form">
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              [items]="storageGroupOptions"
              placeholder="Lagergruppe"
              bindLabel="label"
              bindValue="value"
              [clearable]="false"
              [searchable]="false"
              [markFirst]="false"
              (change)="updateStorageItemGroup($event)"
            ></ng-select>

          </div>
        </div>
        <div class="col-6">
          <div class="mat-form-field">
            <ng-select
              [items]="storageLocations$ | async"
              placeholder="Lagerplatz-Nr."
              bindLabel="storageLocationNumber"
              bindValue="@id"
              formControlName="storageLocation"
              [clearable]="false"
              [markFirst]="false"
            ></ng-select>
            <mat-error>
              <app-form-error [fieldName]="'storageLocation'" [formGroup]="form"></app-form-error>
            </mat-error>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-12" *ngIf="storageItemGroup.value === 'CustomerStockItem'">
          <app-customer-stock-item-form></app-customer-stock-item-form>
        </div>
      </div>
    </div>

    <!--    <app-stock-item-form-->
    <!--      mat-dialog-content-->
    <!--      [products$]="products$"-->
    <!--      [storageLocationsEntities]="storageLocationsEntities$ | async"-->
    <!--      [storageSystems$]="storageSystems$"-->
    <!--      [storageSystemManufacturers$]="storageSystemManufacturers$"-->
    <!--      [presets$]="presets$"-->
    <!--      [orders$]="orders$"-->
    <!--      (requestCreateStockItem)="handleCreateStockItem()"-->
    <!--      #sifc-->
    <!--    ></app-stock-item-form>-->
    <div class="row" mat-dialog-actions>
      <div class="col-6 text-right">
        <!-- Form Loading in child component is deferred -->
        <button [disabled]="form.invalid || form.pristine"
                (click)="handleCreateStockItem()"
                mat-button color="green">
          <mat-icon>save</mat-icon>
          <span>Lagereintrag hinzufügen</span>
        </button>
      </div>
    </div>
  `
})
export class StockItemFormDialogComponent implements OnInit, OnDestroy {

  @ViewChild(CustomerStockItemFormComponent) customerStockItemFormComponent!: CustomerStockItemFormComponent;

  storageGroupOptions = [
    {value: 'CustomerStockItem', label: 'Kundenhardware'},
    {value: 'CustomerReplacementStockItem', label: 'Ersatzdatenträger (Kunde)'},
    {value: 'ReplacementStockItem', label: 'Ersatzdatenträger (DR)'},
  ];
  form: FormGroup;

  storageItemGroup: StorageItemGroup;
  products$: Observable<Array<Product>>;
  presets$: BehaviorSubject<StockItem> = new BehaviorSubject<StockItem>(null);
  storageLocations$: Observable<Array<StorageLocation>>;
  defaultStorageLocationReplacement: StorageLocation;
  storageLocationsEntities$: Observable<{ [iri: string]: fromMasterDataModuleModels.StorageLocation }>;
  storageSystems$: Observable<Array<fromMasterDataModuleModels.StorageSystem>>;
  storageSystemManufacturers$: Observable<Array<fromMasterDataModuleModels.StorageSystemManufacturer>>;
  orders$: Observable<Array<fromOrdersModule.Order>>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private store$: Store<ApplicationState>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: { type: StockItemsTypes, presets$: StockItem }
  ) {
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      storageLocation: this.fb.control(null, [Validators.required])
    });
    this.storageItemGroup = this.storageGroupOptions.find(e => e.value === this.data.type) || this.storageGroupOptions[0];
    this.presets$.next(this.data.presets$);
    this.products$ = this.store$.select(ProductsSelectors.sList);
    this.storageLocationsEntities$ = this.store$.select(StorageLocationsSelectors.sEntities);
    this.storageSystems$ = this.store$.select(StorageSystemsSelectors.sOrderedList);
    this.storageSystemManufacturers$ = this.store$.select(StorageSystemManufacturersSelectors.sOrderedList);
    this.orders$ = this.store$.select(OrdersSelectors.sList);
    this.storageLocations$ = this.store$.select(StorageLocationsSelectors.sList);
    this.storageLocations$
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
      .subscribe(locations => {
        // TODO find default Locations
        this.defaultStorageLocationReplacement = locations.find(e => e.storageLocationNumber.indexOf('Ersatzdatenträger') > -1);
      });


  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleCreateStockItem(): void {
    let payload;
    let action;
    switch (this.storageItemGroup.value) {
      case 'CustomerStockItem':
        payload = {...this.form.value, ...this.customerStockItemFormComponent.form.value};
        action = CustomerStockItemsActions.CreateCustomerStockItem;
        break;
      case 'CustomerReplacementStockItem':
        break;
      case 'ReplacementStockItem':
        break;
    }

    // const actions = {
    //   CustomerReplacementStockItem: CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItem({payload}),
    //   CustomerStockItem: CustomerStockItemsActions.CreateCustomerStockItem({payload}),
    //   ReplacementStockItem: ReplacementStockItemsActions.CreateReplacementStockItem({payload}),
    // };
    this.store$.dispatch(action({payload}));
  }

  updateStorageItemGroup(option: StorageItemGroup): void {

    // this.targetStorageGroup$.next(option);
    this.storageItemGroup = option;
    if (option.value === 'ReplacementStockItem' && this.defaultStorageLocationReplacement) {
      this.form.patchValue({storageLocation: extractIri(this.defaultStorageLocationReplacement)});

    } else {
      this.form.patchValue({storageLocation: null});

    }
    // // 2 = ReplacementStockItem
    // if (option == this.storageGroupOptions[2]) {
    //   this.sif.addControl('product', this.fb.control(null, [Validators.required]));
    //   this.sif.removeControl('storageSystemType');
    //   this.sif.removeControl('order');
    // } else {
    //   this.sif.removeControl('product');
    //   this.sif.addControl('storageSystemType', this.fb.control(null, [Validators.required]));
    // }
  }

}
