import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class OrderStatesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  readOrderState(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_STATE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readOrderStates(): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_STATE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject('/order_states')
    );
  }
}
