import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create

export const CREATE_CUSTOMER_ACCOUNT_INVITATION = '[Customers Module] Create Customer Account Invitation';
export const CREATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS = '[Customers Module] Create Customer Account Invitation Success';
export const CREATE_CUSTOMER_ACCOUNT_INVITATION_FAIL = '[Customers Module] Create Customer Account Invitation Fail';

export const CreateCustomerAccountInvitation = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION,
  props<{ payload: fromModuleModels.CustomerAccountInvitation }>()
);

export const CreateCustomerAccountInvitationSuccess = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS,
  props<{ response: fromModuleModels.CustomerAccount }>()
);

export const CreateCustomerAccountInvitationFail = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION = '[Customers Module] Create Customer Account Invitation Confirmation';
export const CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION_SUCCESS = '[Customers Module] Create Customer Account Invitation Confirmation Success';
export const CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION_FAIL = '[Customers Module] Create Customer Account Invitation Confirmation Fail';

export const CreateCustomerAccountInvitationConfirmation = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION,
  props<{ payload: fromModuleModels.CustomerAccountInvitationConfirmation }>()
);

export const CreateCustomerAccountInvitationConfirmationSuccess = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION_SUCCESS,
  props<{ response: fromModuleModels.CustomerAccount }>()
);

export const CreateCustomerAccountInvitationConfirmationFail = createAction(
  CREATE_CUSTOMER_ACCOUNT_INVITATION_CONFIRMATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_CUSTOMER_ACCOUNT_INVITATION = '[Customers Module] Read Customer Account Invitation';
export const READ_CUSTOMER_ACCOUNT_INVITATION_SUCCESS = '[Customers Module] Read Customer Account Invitation Success';
export const READ_CUSTOMER_ACCOUNT_INVITATION_FAIL = '[Customers Module] Read Customer Account Invitation Fail';

export const ReadCustomerAccountInvitation = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATION,
  props<{ iri: string }>()
);

export const ReadCustomerAccountInvitationSuccess = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATION_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAccountInvitationFail = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_ACCOUNT_INVITATIONS = '[Customers Module] Read Customer Account Invitations';
export const READ_CUSTOMER_ACCOUNT_INVITATIONS_SUCCESS = '[Customers Module] Read Customer Account Invitations Success';
export const READ_CUSTOMER_ACCOUNT_INVITATIONS_FAIL = '[Customers Module] Read Customer Account Invitations Fail';

export const ReadCustomerAccountInvitations = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATIONS,
  props<{ page: number }>()
);

export const ReadCustomerAccountInvitationsSuccess = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATIONS_SUCCESS,
  props<{ response: any }>()
);

export const ReadCustomerAccountInvitationsFail = createAction(
  READ_CUSTOMER_ACCOUNT_INVITATIONS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update

export const UPDATE_CUSTOMER_ACCOUNT_INVITATION = '[Customers Module] Update Customer Account Invitation';
export const UPDATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS = '[Customers Module] Update Customer Account Invitation Success';
export const UPDATE_CUSTOMER_ACCOUNT_INVITATION_FAIL = '[Customers Module] Update Customer Account Invitation Fail';

export const UpdateCustomerAccountInvitation = createAction(
  UPDATE_CUSTOMER_ACCOUNT_INVITATION,
  props<{ iri: string, payload: fromModuleModels.CustomerAccountInvitation }>()
);

export const UpdateCustomerAccountInvitationSuccess = createAction(
  UPDATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS,
  props<{ response: fromModuleModels.CustomerAccountInvitation }>()
);

export const UpdateCustomerAccountInvitationFail = createAction(
  UPDATE_CUSTOMER_ACCOUNT_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete

export const DELETE_CUSTOMER_ACCOUNT_INVITATION = '[Customers Module] Delete Customer Account Invitation';
export const DELETE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS = '[Customers Module] Delete Customer Account Invitation Success';
export const DELETE_CUSTOMER_ACCOUNT_INVITATION_FAIL = '[Customers Module] Delete Customer Account Invitation Fail';

export const DeleteCustomerAccountInvitation = createAction(
  DELETE_CUSTOMER_ACCOUNT_INVITATION,
  props<{ iri: string }>()
);

export const DeleteCustomerAccountInvitationSuccess = createAction(
  DELETE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS,
  props<{ iri: string }>()
);

export const DeleteCustomerAccountInvitationFail = createAction(
  DELETE_CUSTOMER_ACCOUNT_INVITATION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
