import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { InvoiceItem } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class InvoiceItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createInvoiceItem(payload: InvoiceItem): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/invoice_items`, payload)
    );
  }

  readInvoiceItem(iri: string): Observable<InvoiceItem> {
    return this.rolesService.userHasRoleFilter<InvoiceItem>(
      'ROLE_MWS_INVOICE_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  updateInvoiceItem(
    iri: string,
    payload: InvoiceItem
  ): Observable<InvoiceItem> {
    return this.rolesService.userHasRoleFilter<InvoiceItem>(
      'ROLE_MWS_INVOICE_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteInvoiceItem(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_INVOICE_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
