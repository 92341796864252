import {BehaviorSubject, forkJoin, merge, Observable, of, Subject} from 'rxjs';
import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Actions, ofType} from '@ngrx/effects';
import {combineLatestArray, combineLatestObject} from 'rxjs-etc';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import * as CustomersModuleActions from '../../store/actions';
import * as CustomersModuleComponents from '../../components';
import * as CustomersModuleSelectors from '../../store/selectors';
import * as MasterDataModuleSelectors from '../../../master-data/store/selectors';
import * as fromInvoicesModuleModels from '../../../invoices/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromOrdersModuleModels from '../../../orders/models';
import * as fromTicketsModuleModels from '../../../tickets/models';
import {Administrator} from '../../../administrators/models';
import {AdministratorsSelectors} from '../../../administrators/store/selectors';
import {ApplicationState} from '../../../application-state/store';
import {CustomerPartnerStatus} from '../../models';
import {ErrorsObject, ErrorsUtility} from '../../../shared/utilities/error-utility.utility';
import {Go} from '../../../application-state/store/actions/router.actions';
import {ModalDialogOptions} from '../../../application-state/models';
import {extractIri, extractTypeByIri, extractUUID, ObjectsUtility} from '../../../shared/utilities/objects.utility';
import {StringsUtility} from '../../../shared/utilities/strings.utility';
import {TicketsActions} from '../../../tickets/store';
import {TicketsSelectors} from '../../../tickets/store/selectors';
import * as fromModuleModels from '../../models';
import {Customer} from '../../models';
import {CustomerAddressesSelectors, CustomersSelectors} from '../../store/selectors';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractTitleService} from '../../../shared/services/abstract-title.service';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {parseLazyRoute} from '@angular/compiler/src/aot/lazy_routes';
import * as moment from 'moment';
import {CustomerAddressesActions} from '../../store/actions';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {CorrectionInvoicesActions, PayableInvoicesActions, PaymentProcessesActions} from '../../../invoices/store';
import {BillingAddress} from '../../../customers/models';
import {RouterActions} from "../../../application-state/store/actions";
import {DepartmentsActions} from "../../../master-data/store";

@Component({
  selector: 'app-customer-view',
  styleUrls: ['./customer-view.component.scss'],
  template: `
    <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
    <div class="row" *ngIf="customer">
      <div class="col-auto me-auto">
        <h1>
          {{ customer?.nameLine1 }}
          <small class="text-color-lightgrey">{{ customer?.nameLine2 }}</small>
          <small
            class="text-color-lightgrey">({{ customer.customerType === '/api/customer_types/0' ? 'Geschäftskunde' : 'Privat' }}
            )</small>
          <span class="left ">
            <span class="cursor-pointer" (click)="toggleVIP()">
            <span class="badge--vip " [class.disabled]="!customer?.vip">
              <mat-icon>check</mat-icon>
              <span>VIP</span>
            </span>
              </span>

            <span *ngIf="isPartner(customer.partnerStatus)" class="badge--partner m-l--16">Partner</span>
          </span>
        </h1>
      </div>
      <div class="col-auto text-right">
        <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
          <mat-icon matSuffix>more_vertical</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="handleDeleteCustomer(customer)">
            <mat-icon color="warn">delete</mat-icon>
            <span>Kunden löschen</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <!--    <div class="row" *ngIf="customer">-->
    <!--      <nav mat-tab-nav-bar class="col">-->
    <!--        <a-->
    <!--          mat-tab-link-->
    <!--          *ngFor="let link of links"-->
    <!--          [active]="isActive(link)"-->
    <!--          [routerLinkActive]="link.url"-->
    <!--          #rla="routerLinkActive"-->
    <!--          [routerLink]="link.url"-->
    <!--        >-->
    <!--          {{ link.label }}-->
    <!--        </a>-->
    <!--      </nav>-->
    <!--    </div>-->
    <!--    <div class="row">-->
    <!--      <router-outlet></router-outlet>-->
    <!--    </div>-->


    <ng-container *ngIf="{
    contacts: contacts$ | async,
    customer: customer$ | async,
    customerInvoices: customerInvoices$ | async,
    customerOrders: customerOrders$ | async,
    customerOffers: customerOffers$ | async,
    customerTickets: customerTickets$ | async,
    addressTypes: addressTypes$ | async,
    departments: departments$ | async,
    grades: grades$ | async,
    salutations: salutations$ | async,
    customerBillingAddresses: customerBillingAddresses$ | async,
    customerDeliveryAddresses: customerDeliveryAddresses$ | async,
    customerPartnerBranchOfficeAddresses: customerPartnerBranchOfficeAddresses$ | async,
    customerContactTypes: customerContactTypes$ | async,
    customerContactTypesEntities: customerContactTypesEntities$ | async
  } as data">

      <div class="wrap" *ngIf="data.customer">
        <mat-tab-group animationDuration="0">

          <mat-tab label="Allgemeine Daten">

            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-5">

                  <customer-details
                    (requestShowCustomerForm)="handleShowCustomerForm(customerDetailsFormDialog, $event)"
                    [contacts]="data.contacts"
                    [customerContactTypes]="data.customerContactTypes"
                    [addresses]="mergeAddresses([data.customerBillingAddresses, data.customerDeliveryAddresses, data.customerPartnerBranchOfficeAddresses])"
                    [customer]="data.customer"
                  ></customer-details>

                  <partner-status-form
                    *ngIf="(customerCooperations$ | async) as customerCooperations"
                    [errors]="selectErrors('customersErrors')"
                    [customerCooperations]="customerCooperations"
                    [customerPartnerStatuses]="customerPartnerStatuses$ | async"
                    [customer]="data.customer"
                    (requestUpdateCustomer)="handleUpdateCustomer($event)"
                  ></partner-status-form>
                </div>

                <div class="column-9">
                  <div class="m-l--48">
                    <div class="card mat-elevation-z1">

                      <customer-comments
                        [comments$]="customerComments$"
                        [customer]="customer"
                        [pagination$]="customerCommentsPagination$"
                        [administratorsEntities]="administratorsEntities$ | async"
                        (requestDeleteCustomerComment)="handleDeleteCustomerComment($event)"
                        (requestReadCustomerComments)="handleReadCustomerComments($event)"
                        (requestCustomerCommentForm)="openDialog(customerCommentFormDialog, $event)"
                      ></customer-comments>

                      <div style="padding: 24px; border-top: 1px solid #b9c9d3;">
                        <ng-container *ngTemplateOutlet="customerCommentForm"
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Kontakte">

            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-4">
                  <div class="card mat-elevation-z1">

                    <customer-contact-form
                      [customerContactTypes]="data.customerContactTypes"
                      [grades]="data.grades"
                      [salutations]="data.salutations"
                      [presets$]="presets$"
                      [errors]="selectErrors('customersErrors')"
                      (requestUpdateCustomerContact)="handleUpdateCustomerContact($event)"
                      (requestCreateCustomerContact)="handleCreateCustomerContact($event, data.customer)"
                    ></customer-contact-form>
                  </div>
                </div>
                <div class="column-10">

                  <div class="m-l--48">
                    <customer-contact-list
                      (requestUpdateDefaultCustomerContact)="handleUpdateDefaultCustomerContact($event)"
                      (requestDeleteCustomerContact)="handleDeleteCustomerContact($event)"
                      (requestUpdateCustomerContactForm)="handleUpdateCustomerContactForm($event)"
                      [customer]="data.customer"
                      [customerContactTypes]="data.customerContactTypes"
                      [contacts]="data.contacts"
                    ></customer-contact-list>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Adressen">
            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-5">

                  <customer-address-form
                    (requestCreateCustomerAddress)="handleCreateCustomerAddress($event, data.customer)"
                    (requestUpdateCustomerAddress)="handleUpdateCustomerAddress($event)"
                    [addressTypes$]="addressTypes$"
                    [countries$]="countries$"
                    [contacts$]="contacts$"
                    [errors]="selectErrors('customerAddressesErrors')"
                    [isCopying$]="isCopying$"
                    [presets$]="presets$"
                    [isPartner]="isPartner(customer.partnerStatus)"
                  ></customer-address-form>
                </div>
                <div class="column-9">

                  <customer-address-list
                    (requestDeleteCustomerAddress)="handleDeleteCustomerAddress($event)"
                    (requestFetchAddressData)="handleFetchCustomerBillingAddresses(data.customer['@id'])"
                    (requestUpdateCustomerAddressForm)="handleUpdateCustomerAddressForm($event)"
                    (requestUpdateDefaultCustomerAddress)="handleUpdateDefaultCustomerAddress({ customerUuid: data.customer['@id'], addressUuid: $event, type: 'defaultBillingAddress' })"
                    [addresses]="data.customerBillingAddresses"
                    [defaultAddress]="data.customer.defaultBillingAddress"
                    [isCopying$]="isCopying$">

                    <span>{{"customers.customer_view.customer_address_list_heading.billing_address" | translate}}</span>
                  </customer-address-list>

                  <customer-address-list
                    [addresses]="data.customerDeliveryAddresses"
                    [isCopying$]="isCopying$"
                    (requestFetchAddressData)="handleFetchCustomerDeliveryAddresses(data.customer['@id'])"
                    (requestDeleteCustomerAddress)="handleDeleteCustomerAddress($event)"
                    (requestUpdateCustomerAddressForm)="handleUpdateCustomerAddressForm($event)"
                    (requestUpdateDefaultCustomerAddress)="handleUpdateDefaultCustomerAddress({ customerUuid: data.customer['@id'], addressUuid: $event, type: 'defaultDeliveryAddress' })"
                    [defaultAddress]="data.customer.defaultDeliveryAddress">

                    <span>{{"customers.customer_view.customer_address_list_heading.delivery_address" | translate}}</span>
                  </customer-address-list>
                  <customer-address-list
                    *ngIf="isPartner(customer.partnerStatus)"
                    [addresses]="data.customerPartnerBranchOfficeAddresses"
                    [isCopying$]="isCopying$"
                    (requestFetchAddressData)="handleFetchPartnerBranchOfficeDeliveryAddresses(data.customer['@id'])"
                    (requestDeleteCustomerAddress)="handleDeleteCustomerAddress($event)"
                    (requestUpdateCustomerAddressForm)="handleUpdateCustomerAddressForm($event)"
                    (requestUpdateDefaultCustomerAddress)="handleUpdateDefaultCustomerAddress({ customerUuid: data.customer['@id'], addressUuid: $event, type: 'defaultPartnerBranchOfficeAddress' })"
                    [defaultAddress]="data.customer.defaultPartnerBranchOfficeAddress">
                    <span>{{"customers.customer_view.customer_address_list_heading.partner_office_address" | translate}}</span>
                  </customer-address-list>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Tickets">
            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-14">

                  <customer-ticket-list
                    [items]="data.customerTickets"
                    (requestViewTicket)="handleNavigateToTicketView($event)"
                    (requestFetchTickets)="handleFetchCustomerTickets(data.customer['@id'])"
                  ></customer-ticket-list>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Aufträge">
            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-14">
                  <customer-order-list
                    [items]="data.customerOrders"
                    (requestViewOrder)="handleNavigateToOrderView($event)"
                    (requestFetchOrders)="handleFetchCustomerOrders(data.customer['@id'])"
                  ></customer-order-list>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Rechnungen">

            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-14">
                  <customer-invoice-list
                    [items]="data.customerInvoices"
                    (requestViewCustomerInvoice)="handleNavigateToInvoiceView($event)"
                    (requestFetchCustomerInvoices)="handleFetchCustomerInvoices(data.customer['@id'])"
                    (requestNewInvoice)="createNewInvoice()"
                    (requestNewCorrectionInvoice)="createNewCorrectionInvoice()"
                  ></customer-invoice-list>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Angebote">

            <div class="inner wrap">
              <div class="grid grid-no-gutter">

                <div class="column-14">
                  <customer-offer-list
                    [items]="data.customerOffers"
                    (requestViewCustomerOffer)="handleNavigateToOfferView($event)"
                    (requestFetchCustomerOffers)="handleFetchCustomerOffers(data.customer['@id'])"
                  ></customer-offer-list>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Kundenzugänge">

            <div class="inner wrap">

              <div class="grid grid-no-gutter">

                <div class="column-5">

                  <customer-account-form
                    [customer]="data.customer"
                    [errors]="selectErrors(['customerAccountsErrors','customerAccountInvitationsErrors'])"
                    [salutations$]="salutations$"
                    [grades$]="grades$"
                    [presets$]="presets$"
                    (requestCreateCustomerAccount)="handleCreateCustomerAccount($event)"
                    (requestCreateCustomerAccountInvitation)="handleCreateCustomerAccountInvitation($event)"
                    (requestUpdateCustomerAccount)="handleUpdateCustomerAccount($event)">

                    <span>Kunden-Account</span>
                  </customer-account-form>

                  <partner-website-form
                    [customer]="data.customer"
                    [errors]="selectErrors(['partnerWebsitesErrors','partnerWebsiteInvitationsErrors'])"
                    [presets$]="presets$"
                    (requestCreatePartnerWebsite)="handleCreatePartnerWebsite($event)"
                    (requestCreatePartnerWebsiteInvitation)="handleCreatePartnerWebsiteInvitation($event)"
                    (requestUpdatePartnerWebsite)="handleUpdatePartnerWebsite($event)">

                    <span>Zugang für Partner Website</span>
                  </partner-website-form>
                </div>

                <div class="column-9">

                  <customer-account-list
                    [customer]="data.customer"
                    [customerAccounts]="customerAccounts$ | async"
                    (requestFetchCustomerAccounts)="handleFetchCustomerAccounts($event)"
                    (requestUpdateCustomerAccountForm)="handleUpdateCustomerAccountForm($event)"
                    (requestDeleteCustomerAccount)="handleDeleteCustomerAccount($event)"
                  >Kundenzugänge über Login
                  </customer-account-list>

                  <customer-account-invitation-list
                    [customerAccountInvitations]="customerAccountInvitations$ | async"
                    (requestDeleteCustomerAccountInvitation)="handleDeleteCustomerAccountInvitation($event)"
                    (requestUpdateCustomerAccountForm)="handleUpdateCustomerAccountForm($event)"
                  >Offene Einladungen
                  </customer-account-invitation-list>

                  <hr class="m-l--48 m-t--32 m-b--32">

                  <partner-website-list
                    [customer]="data.customer"
                    [partnerWebsites]="partnerWebsites$ | async"
                    (requestFetchPartnerWebsites)="handleFetchPartnerWebsites($event)"
                    (requestUpdatePartnerWebsiteForm)="handleUpdatePartnerWebsiteForm($event)"
                    (requestDeletePartnerWebsite)="handleDeletePartnerWebsite($event)"
                  >Kundenzugänge über Website
                  </partner-website-list>

                  <partner-website-invitation-list
                    [partnerWebsiteInvitations]="partnerWebsiteInvitations$ | async"
                    (requestDeletePartnerWebsiteInvitation)="handleDeletePartnerWebsiteInvitation($event)"
                  >Offene Einladungen
                  </partner-website-invitation-list>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <ng-template #customerCommentFormDialog>

        <app-dialog-header (requestCloseDialog)="presets$.next(null)">
          <h2>Kommentar bearbeiten</h2>
        </app-dialog-header>

        <ng-container
          mat-dialog-content
          *ngTemplateOutlet="customerCommentForm"
        ></ng-container>
      </ng-template>

      <ng-template #customerCommentForm>

        <customer-comment-form
          [administratorsEntities]="administratorsEntities$ | async"
          [customer]="data.customer"
          [errors]="selectErrors('customerCommentsErrors')"
          [customerCommentTags]="customerCommentTags$ | async"
          [presets$]="presets$"
          (requestCreateCustomerComment)="handleCreateCustomerComment($event)"
          (requestUpdateCustomerComment)="handleUpdateCustomerComment($event)"
        ></customer-comment-form>
      </ng-template>

      <ng-template #customerDetailsFormDialog>

        <app-dialog-header>
          <h2>Allgemeine Daten bearbeiten</h2>
        </app-dialog-header>
        <div mat-dialog-content>

          <div class="dialog--customer-form">

            <customer-details-form
              [presets$]="presets$"
              [contacts]="data.contacts"
              [customerTypes]="customerTypes$ | async"
              [errors]="selectErrors('customersErrors')"
              [billingAddresses]="data.customerBillingAddresses"
              [deliveryAddresses]="data.customerDeliveryAddresses"
              [partnerBranchOfficeAddresses]="data.customerPartnerBranchOfficeAddresses"
              (requestUpdateCustomer)="handleUpdateCustomer($event)"
            ></customer-details-form>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template
      #appLoading [appLoading]
    ></ng-template>
  `
})
export class CustomerViewComponent implements OnInit, OnDestroy {

  @ViewChild(CustomersModuleComponents.CustomerAccountFormComponent) cafc!: CustomersModuleComponents.CustomerAccountFormComponent;
  @ViewChild(CustomersModuleComponents.CustomerAddressFormComponent) caf!: CustomersModuleComponents.CustomerAddressFormComponent;
  @ViewChild(CustomersModuleComponents.CustomerContactFormComponent) ccfc!: CustomersModuleComponents.CustomerContactFormComponent;
  @ViewChild(CustomersModuleComponents.PartnerStatusFormComponent) psfc!: CustomersModuleComponents.PartnerStatusFormComponent;
  @ViewChild(CustomersModuleComponents.PartnerWebsiteFormComponent) pwfc!: CustomersModuleComponents.PartnerWebsiteFormComponent;
  isLoading$: Observable<boolean>;
  customerUUID: string;
  customer: Customer;
  // links: Array<{
  //   url: string;
  //   label: string;
  //   value: string;
  // }> = [
  //   {url: './general', value: 'general', label: 'Allgemeine Daten'},
  //   {
  //     url: '/invoices/cancellation',
  //     value: 'cancellation_invoices',
  //     label: 'Storno-Rechnungen'
  //   },
  //   {url: '/invoices/offers', value: 'offers', label: 'Angebote'},
  //   {
  //     url: '/invoices/corrections',
  //     value: 'correction_invoices',
  //     label: 'Gutschriften'
  //   },
  //   {
  //     url: '/invoices/brokers',
  //     value: 'brokers',
  //     label: 'Partnergutschriften'
  //   },
  //   {
  //     url: '/invoices/commission-credits',
  //     value: 'commission_credits',
  //     label: 'Provisionsgutschriften'
  //   }
  // ];
  addressTypes$: Observable<Array<fromMasterDataModuleModels.AddressType>>;
  administratorsEntities$: Observable<{ [iri: string]: Administrator }>;
  contacts$: Observable<Array<fromCustomersModuleModels.CustomerContact>> = of([]);
  countries$: Observable<Array<fromMasterDataModuleModels.Country>>;
  customer$: Observable<fromCustomersModuleModels.Customer>;
  customerAccountInvitations$: Observable<Array<fromCustomersModuleModels.CustomerAccountInvitation>>;
  customerAccounts$: Observable<Array<fromCustomersModuleModels.CustomerAccount>>;
  customerBillingAddresses$: Observable<Array<fromCustomersModuleModels.CustomerAddress>>;
  customerCommentTags$: Observable<Array<fromCustomersModuleModels.CustomerCommentTag>> | any;
  customerComments$: Observable<Array<fromCustomersModuleModels.CustomerComment>> | any;
  customerCommentsPagination$: BehaviorSubject<{ [p: string]: string }> = new BehaviorSubject(null);
  customerContactTypes$: Observable<Array<fromCustomersModuleModels.CustomerContactType>> | any;
  customerContactTypesEntities$: Observable<{ [iri: string]: fromCustomersModuleModels.CustomerContactType }>;
  customerCooperations$: Observable<Array<fromMasterDataModuleModels.Product>>;
  customerDeliveryAddresses$: Observable<Array<fromCustomersModuleModels.CustomerAddress>>;
  customerPartnerBranchOfficeAddresses$: Observable<Array<fromCustomersModuleModels.CustomerAddress>>;
  customerInvoices$: Observable<Array<fromInvoicesModuleModels.CorrectionInvoice | fromInvoicesModuleModels.PartialInvoice>>;
  customerOffers$: Observable<Array<fromInvoicesModuleModels.Offer>>;
  customerOrders$: Observable<Array<fromOrdersModuleModels.Order>>;
  customerPartnerStatuses$: Observable<Array<CustomerPartnerStatus>>;
  customerTickets$: Observable<Array<fromTicketsModuleModels.Ticket>>;
  customerTypes$: Observable<Array<fromCustomersModuleModels.CustomerType>>;
  departments$: Observable<Array<fromMasterDataModuleModels.Department>>;
  errors$: BehaviorSubject<{ [k: string]: ErrorsObject }> = new BehaviorSubject<{ [k: string]: ErrorsObject }>({});
  grades$: Observable<Array<fromMasterDataModuleModels.Grade>>;
  isCopying$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  locales$: Observable<Array<fromMasterDataModuleModels.Locale>>;
  onDestroy$: Subject<any> = new Subject<any>();
  partnerWebsiteInvitations$: Observable<Array<fromCustomersModuleModels.PartnerWebsiteInvitation>>;
  partnerWebsites$: Observable<Array<fromCustomersModuleModels.PartnerWebsite>>;
  presets$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  salutations$: Observable<Array<fromMasterDataModuleModels.Salutation>>;

  constructor(private actions$: Actions, public dialog: MatDialog, private store$: Store<ApplicationState>,
              private router: Router,
              private route: ActivatedRoute,
              private title: AbstractTitleService,
  ) {
  }

  getAggregatedAddresses(addresses: Array<Array<fromModuleModels.CustomerAddress>>): Array<fromModuleModels.CustomerAddress> {
    return [].concat(...addresses);
  }

  isPartner(partnerStatus: string) {
    return partnerStatus !== '/api/customer_partner_statuses/0';
  }

  ngOnInit(): void {
    this.customerUUID = this.route.snapshot.paramMap.get('uuid');
    this.title.setTitle('Kunde');

    // todo: migrate to facade
    this.selectData();
    this.loadCustomer();
    this.mergeErrors();
    this.initActionListeners();
  }

  mergeAddresses(addresses: Array<Array<fromCustomersModuleModels.CustomerAddress>>): Array<fromCustomersModuleModels.CustomerAddress> {
    return [].concat(...addresses);
  }

  mergeErrors(): void {
    combineLatestObject({
      customerCommentsErrors: this.store$.pipe(select(CustomersModuleSelectors.CustomerCommentsSelectors.selectCustomerCommentsErrors)),
      customerAccountInvitationsErrors: this.store$.pipe(select(CustomersModuleSelectors.CustomerAccountInvitationsSelectors.selectErrors)),
      customerAccountsErrors: this.store$.pipe(select(CustomersModuleSelectors.CustomerAccountsSelectors.selectErrors)),
      customerAddressesErrors: this.store$.pipe(select(CustomersModuleSelectors.CustomerAddressesSelectors.selectCustomerAddressesErrors)),
      customersErrors: this.store$.pipe(select(CustomersModuleSelectors.CustomersSelectors.selectErrors)),
      partnerWebsiteInvitationsErrors: this.store$.pipe(select(CustomersModuleSelectors.PartnerWebsiteInvitationsSelectors.selectErrors)),
      partnerWebsitesErrors: this.store$.pipe(select(CustomersModuleSelectors.PartnerWebsitesSelectors.selectErrors)),
    }).subscribe(this.errors$);
  }

  selectData(): void {
    this.addressTypes$ = this.store$.pipe(select(MasterDataModuleSelectors.AddressTypesSelectors.selectAddressTypes));
    this.administratorsEntities$ = this.store$.pipe(select(AdministratorsSelectors.sEntities));
    this.countries$ = this.store$.pipe(select(MasterDataModuleSelectors.CountriesSelectors.selectCountries));
    this.customerCommentTags$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerCommentTagsSelectors.selectCustomerCommentTags));
    this.customerComments$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerCommentsSelectors.selectCustomerComments));
    this.customerContactTypes$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerContactTypesSelectors.selectCustomerContactTypes));
    this.customerContactTypesEntities$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerContactTypesSelectors.selectCustomerContactTypeEntities));
    this.customerCooperations$ = this.store$.pipe(select(MasterDataModuleSelectors.CustomerCooperationsSelectors.selectCustomerCooperations));
    this.customerPartnerStatuses$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerPartnerStatusesSelectors.selectCustomerPartnerStatuses));
    this.customerTypes$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerTypesSelectors.selectCustomerTypes));
    this.departments$ = this.store$.pipe(select(MasterDataModuleSelectors.DepartmentsSelectors.selectDepartments));
    this.grades$ = this.store$.pipe(select(MasterDataModuleSelectors.GradesSelectors.selectGrades));
    this.locales$ = this.store$.pipe(select(MasterDataModuleSelectors.LocalesSelectors.selectLocales));
    this.salutations$ = this.store$.pipe(select(MasterDataModuleSelectors.SalutationsSelectors.selectSalutations));
  }

  loadCustomer(): void {
    this.customer$ = this.store$.select(CustomersSelectors.sByUUID, {uuid: this.customerUUID}).pipe(filter(e => !!e));
    this.isLoading$ = this.store$.select(CustomersSelectors.isLoading);
    this.customer$.pipe(takeUntil(this.onDestroy$)).subscribe(customer => {
      this.customer = customer;
      this.handleFetchCustomerContacts(customer['@id']);
      this.title.setTitle('Kunde: ' + customer.nameLine1);

    });

    this.store$.pipe(select(CustomersModuleSelectors.CustomerCommentsSelectors.selectCustomerCommentsPagination)).subscribe((this.customerCommentsPagination$));
  }

  initActionListeners(): void {

    this.actions$.pipe(
      ofType(
        CustomersModuleActions.CustomersActions.ReadCustomerSuccess,
        CustomersModuleActions.CustomersActions.UpdateCustomerSuccess,
        CustomersModuleActions.CustomerCommentsActions.CreateCustomerCommentSuccess,
        CustomersModuleActions.CustomerCommentsActions.UpdateCustomerCommentSuccess,
        CustomersModuleActions.CustomerContactsActions.CreateCustomerContactSuccess,
        CustomersModuleActions.CustomerContactsActions.UpdateCustomerContactSuccess,
        CustomersModuleActions.CustomerAddressesActions.CreateCustomerAddressSuccess,
        CustomersModuleActions.CustomerAddressesActions.UpdateCustomerAddressSuccess,
        CustomersModuleActions.CustomerAccountsActions.CreateCustomerAccountSuccess,
        CustomersModuleActions.CustomerAccountsActions.UpdateCustomerAccountSuccess,
        CustomersModuleActions.CustomerAccountInvitationsActions.CreateCustomerAccountInvitationSuccess,
        CustomersModuleActions.PartnerWebsitesActions.CreatePartnerWebsiteSuccess,
        CustomersModuleActions.PartnerWebsitesActions.UpdatePartnerWebsiteSuccess,
        CustomersModuleActions.PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitationSuccess,
      ),
      takeUntil(this.onDestroy$)
    ).subscribe((response) => {

      const customerAddressesActions = [
        CustomersModuleActions.CustomerAddressesActions.CREATE_CUSTOMER_ADDRESS_SUCCESS,
        CustomersModuleActions.CustomerAddressesActions.UPDATE_CUSTOMER_ADDRESS_SUCCESS,
      ];

      const customerContactsActions = [
        CustomersModuleActions.CustomerContactsActions.CREATE_CUSTOMER_CONTACT_SUCCESS,
        CustomersModuleActions.CustomerContactsActions.UPDATE_CUSTOMER_CONTACT_SUCCESS,
      ];

      const customerAccountsActions = [
        CustomersModuleActions.CustomerAccountsActions.CREATE_CUSTOMER_ACCOUNT_SUCCESS,
        CustomersModuleActions.CustomerAccountsActions.UPDATE_CUSTOMER_ACCOUNT_SUCCESS,
        CustomersModuleActions.CustomerAccountInvitationsActions.CREATE_CUSTOMER_ACCOUNT_INVITATION_SUCCESS
      ];

      const partnerWebsitesActions = [
        CustomersModuleActions.PartnerWebsitesActions.CREATE_PARTNER_WEBSITE_SUCCESS,
        CustomersModuleActions.PartnerWebsitesActions.UPDATE_PARTNER_WEBSITE_SUCCESS,
        CustomersModuleActions.PartnerWebsiteInvitationsActions.CREATE_PARTNER_WEBSITE_INVITATION_SUCCESS
      ];

      if (response.type === CustomersModuleActions.CustomersActions.UPDATE_CUSTOMER_SUCCESS) {
        this.psfc.pdf.markAsPristine();
      }

      // Customer Addresses
      if (response.type === CustomersModuleActions.CustomerAddressesActions.CREATE_CUSTOMER_ADDRESS_SUCCESS) {
        this.autoSetDefaultCustomerAddress(response);
      }

      if (customerAddressesActions.includes((response.type))) {
        this.caf.handleCancelEdit();
      }

      // Customer Accounts
      if (customerAccountsActions.includes(response.type)) {
        this.cafc.isInvitation$.next(false);
        this.cafc.cancelEdit();
      }

      // Partner Websites
      if (partnerWebsitesActions.includes(response.type)) {
        this.pwfc.isInvitation$.next(false);
        this.pwfc.cancelEdit();
      }

      // Customer Contacts
      if (response.type === CustomersModuleActions.CustomerContactsActions.CREATE_CUSTOMER_CONTACT_SUCCESS) {
        this.autoSetDefaultCustomerContact(response);
      }

      if (customerContactsActions.includes((response.type))) {
        this.ccfc.handleCancelEdit();
      }

      this.presets$.next(null);
    });
  }

  keys(object): Array<string> {
    return ObjectsUtility.getObjectKeys(object);
  }

  selectErrors(identifier: string | Array<string>): ErrorsObject {
    return ErrorsUtility.selectErrors(this.errors$.getValue(), identifier);
  }

  handleFetchCustomerContacts(customerIri: string): void {

    const params = {'customer.uuid': StringsUtility.getUuidFromIri(customerIri)};

    this.store$.dispatch(CustomersModuleActions.CustomerContactsActions.ReadCustomerContacts({page: -1, params}));
    this.contacts$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerContactsSelectors.selectCustomerContactsByCustomerIri, {customerIri}));
  }


  handleFetchCustomerAccounts(iri: string): void {
    this.customerAccountInvitations$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerAccountInvitationsSelectors.selectCustomerAccountInvitationsByCustomerIri, {iri}));
    this.customerAccounts$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerAccountsSelectors.selectCustomerAccountsByCustomerIri, {iri}));
  }

  handleFetchCustomerInvoices(customer: string): void {
    // todo: could be moved to guard?
    this.store$.dispatch(CustomersModuleActions.CustomerInvoicesActions.ReadCustomerInvoices({iri: customer}));
    this.customerInvoices$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerInvoicesSelectors.selectCustomerInvoices));
  }

  createNewInvoice(): void {
    const customerIri = extractIri(this.customer);
    this.store$.dispatch(PaymentProcessesActions.CreatePaymentProcess({payload: {customer: this.customer['@id']}}));
    this.actions$
      .pipe(ofType(PaymentProcessesActions.CreatePaymentProcessSuccess), takeUntil(this.onDestroy$))
      .subscribe(({response}) => {
        const paymentProcess = extractIri(response);
        console.log('this.customer', this.customer);
        combineLatestArray([
          this.store$.select(CustomerAddressesSelectors.selectCustomerAddressesByCustomerIri, {customerIri})
            .pipe(takeUntil(this.onDestroy$),
              filter(e => !!e && e.length > 0),
              map(e => e.find(f => extractIri(f) === extractIri(this.customer.defaultBillingAddress))),
              take(1)
            ),
          this.store$.select(DepartmentsSelectors.selectDefaultDepartment)
            .pipe(
              takeUntil(this.onDestroy$),
              filter(e => !!e),
              take(1)
            )
        ]).subscribe(([address, department]) => {
          const billingAddress = address as BillingAddress;
          console.log('department', department);
          const issuer = extractIri(department);
          console.log('address', address);
          const payload = {
            deliveryDate: moment().toISOString().substr(0, 10),
            paymentProcess,
            dueDate: moment().add(14, 'day').toISOString().substr(0, 10),
            orderDate: moment().toISOString().substr(0, 10),
            issuer,
            recipient: {
              taxNumber: this.customer.taxNumber,
              nameLine1: this.customer.nameLine1,
              nameLine2: this.customer.nameLine2,
              address: {
                line1: billingAddress.address.line1,
                line2: billingAddress.address.line2,
                line3: billingAddress.address.line3,
                line4: billingAddress.address.line4,
                city: billingAddress.address.city,
                zipPostcode: billingAddress.address.zipPostcode,
                stateProvinceCounty: billingAddress.address.stateProvinceCounty,
                country: billingAddress.address.country
              },
              addressType: extractIri(billingAddress.addressType)
            },
            customerNumber: this.customer.customerNumber,
          };
          this.store$.dispatch(PayableInvoicesActions.CreatePayableInvoice({payload}));
          this.actions$.pipe(ofType(PayableInvoicesActions.CreatePayableInvoiceSuccess), take(1))
            .subscribe((invoiceResult) => {
              this.store$.dispatch(
                RouterActions.Go({
                  path:
                    ['/invoices', 'payable', extractTypeByIri(invoiceResult.response), extractUUID(invoiceResult.response)]
                })
              );

            });
        });


      });
    this.store$.dispatch(CustomerAddressesActions.ReadCustomerBillingAddresses({customerIri}));
    this.store$.dispatch(DepartmentsActions.ReadDepartments());


  }

  createNewCorrectionInvoice(): void {
    const customerIri = extractIri(this.customer);
    combineLatestArray([
      this.store$.select(CustomerAddressesSelectors.selectCustomerAddressesByCustomerIri, {customerIri})
        .pipe(takeUntil(this.onDestroy$),
          filter(e => !!e && e.length > 0),
          map(e => e.find(f => extractIri(f) === extractIri(this.customer.defaultBillingAddress))),
          take(1)
        ),
      this.store$.select(DepartmentsSelectors.selectDefaultDepartment)
        .pipe(
          takeUntil(this.onDestroy$),
          filter(e => !!e),
          take(1)
        )
    ]).subscribe(([address, department]) => {
      const billingAddress = address as BillingAddress;
      console.log('department', department);
      const issuer = extractIri(department);
      console.log('address', address);
      const payload = {
        correctedInvoice: null,
        // paymentProcess,
        issuer,
        recipient: {
          taxNumber: this.customer.taxNumber,
          nameLine1: this.customer.nameLine1,
          nameLine2: this.customer.nameLine2,
          address: {
            line1: billingAddress.address.line1,
            line2: billingAddress.address.line2,
            line3: billingAddress.address.line3,
            line4: billingAddress.address.line4,
            city: billingAddress.address.city,
            zipPostcode: billingAddress.address.zipPostcode,
            stateProvinceCounty: billingAddress.address.stateProvinceCounty,
            country: billingAddress.address.country
          },
          addressType: extractIri(billingAddress.addressType)
        },
        customerNumber: this.customer.customerNumber,
      };
      this.store$.dispatch(CorrectionInvoicesActions.CreateCorrectionInvoice({payload}));
      this.actions$.pipe(ofType(CorrectionInvoicesActions.CreateCorrectionInvoiceSuccess), take(1))
        .subscribe((invoiceResult) => {
          this.store$.dispatch(
            RouterActions.Go({
              path:
                ['/invoices', 'corrections', extractUUID(invoiceResult.response)]
            })
          );

        });
    });


    this.store$.dispatch(CustomerAddressesActions.ReadCustomerBillingAddresses({customerIri}));
    this.store$.dispatch(DepartmentsActions.ReadDepartments());


  }

  handleFetchCustomerOffers(customer: string): void {
    // todo: could be moved to guard?
    this.store$.dispatch(CustomersModuleActions.CustomerOffersActions.ReadCustomerOffers({iri: customer}));
    this.customerOffers$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerOffersSelectors.selectCustomerOffers));
  }

  handleFetchCustomerBillingAddresses(customerIri: string): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.ReadCustomerBillingAddresses({customerIri}));
    this.customerBillingAddresses$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerAddressesSelectors.selectCustomerBillingAddressesByCustomerIri, {customerIri}));
  }

  handleFetchCustomerDeliveryAddresses(customerIri: string): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.ReadCustomerDeliveryAddresses({customerIri}));
    this.customerDeliveryAddresses$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomerAddressesSelectors.selectCustomerDeliveryAddressesByCustomerIri, {customerIri}));
  }

  handleFetchPartnerBranchOfficeDeliveryAddresses(customerIri: string): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.ReadCustomerPartnerBranchOfficeAddresses({customerIri}));
    this.customerPartnerBranchOfficeAddresses$ = this.store$.pipe(
      select(CustomersModuleSelectors.CustomerAddressesSelectors.selectCustomersPartnerBranchOfficeByCustomerIri, {customerIri}));
  }

  handleFetchPartnerWebsites(iri: string): void {
    this.partnerWebsiteInvitations$ = this.store$.pipe(select(CustomersModuleSelectors.PartnerWebsiteInvitationsSelectors.selectPartnerWebsiteInvitationsByCustomerIri, {iri}));
    this.partnerWebsites$ = this.store$.pipe(select(CustomersModuleSelectors.PartnerWebsitesSelectors.selectPartnerWebsitesByCustomerIri, {iri}));
  }

  handleFetchCustomerOrders(customerIri: string): void {
    const params = {'customer.uuid': StringsUtility.getUuidFromIri(customerIri)};
    this.store$.dispatch(CustomersModuleActions.CustomerOrdersActions.ReadCustomerOrders({page: 1, params}));
    this.customerOrders$ = this.store$.pipe(select(CustomersModuleSelectors.CustomerOrdersSelectors.selectCustomerOrders));
  }

  handleFetchCustomerTickets(customerIri: string): void {
    const params = {'customer.uuid': StringsUtility.getUuidFromIri(customerIri)};
    this.store$.dispatch(TicketsActions.ReadTickets({page: 1, params}));
    this.customerTickets$ = this.store$.pipe(select(TicketsSelectors.sByCustomerIri, {customerIri}));
  }

  handleNavigateToInvoiceView(invoice: string): void {
    this.store$.dispatch(Go({path: ['/invoices', 'payable', extractTypeByIri(invoice), extractUUID(invoice)]}));
  }

  handleNavigateToOfferView(offer: string): void {
    this.store$.dispatch(Go({path: ['/invoices', 'offers', extractUUID(offer)]}));
  }

  handleNavigateToOrderView(order: string): void {
    this.store$.dispatch(Go({path: ['orders', StringsUtility.getUuidFromIri(order)]}));
  }

  handleNavigateToTicketView(ticket: string): void {
    this.store$.dispatch(Go({path: ['tickets', StringsUtility.getUuidFromIri(ticket)]}));
  }

  handleCreateCustomerAccount(payload: fromCustomersModuleModels.CustomerAccount): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAccountsActions.CreateCustomerAccount({payload}));
  }

  handleCreateCustomerAccountInvitation(payload: fromCustomersModuleModels.CustomerAccountInvitation): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAccountInvitationsActions.CreateCustomerAccountInvitation({payload}));
  }

  handleUpdateCustomerAccountForm(presets: fromCustomersModuleModels.CustomerAccount | fromCustomersModuleModels.CustomerAccountInvitation): void {
    this.presets$.next({type: presets['@type'], presets});
  }

  handleUpdateCustomerAccount(payload: { iri: string, payload: fromCustomersModuleModels.CustomerAccount }): void {
    this.store$.dispatch(CustomersModuleActions.CustomerAccountsActions.UpdateCustomerAccount(payload));
  }

  handleDeleteCustomerAccount(iri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Kunden-Account und löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.CustomerAccountsActions.DeleteCustomerAccount({iri}));
    });
  }

  handleCreatePartnerWebsite(payload: fromCustomersModuleModels.PartnerWebsite): void {
    this.store$.dispatch(CustomersModuleActions.PartnerWebsitesActions.CreatePartnerWebsite({payload}));
  }

  handleCreatePartnerWebsiteInvitation(payload: fromCustomersModuleModels.PartnerWebsite): void {
    this.store$.dispatch(CustomersModuleActions.PartnerWebsiteInvitationsActions.CreatePartnerWebsiteInvitation({payload}));
  }

  handleUpdatePartnerWebsiteForm(website: fromCustomersModuleModels.PartnerWebsite): void {
    this.presets$.next({type: 'PartnerWebsite', presets: website});
  }

  handleUpdatePartnerWebsite(payload: { iri: string, payload: fromCustomersModuleModels.PartnerWebsite }): void {
    this.store$.dispatch(CustomersModuleActions.PartnerWebsitesActions.UpdatePartnerWebsite(payload));
  }

  handleDeletePartnerWebsite(iri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Partner-Website-Zugang und löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.PartnerWebsitesActions.DeletePartnerWebsite({iri}));
    });
  }

  handleUpdateDefaultCustomerContact(payload: { iri: string, payload: fromCustomersModuleModels.Customer }): void {
    this.store$.dispatch(CustomersModuleActions.CustomersActions.UpdateCustomer(payload));
  }

  handleUpdateCustomerAddressForm(address: fromCustomersModuleModels.CustomerAddress): void {
    this.presets$.next(address);
  }

  handleUpdateCustomerContactForm(contact: fromCustomersModuleModels.CustomerContact) {
    this.presets$.next(contact);
  }

  handleDeleteCustomerAccountInvitation(iri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Einladung widerrufen und löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.CustomerAccountInvitationsActions.DeleteCustomerAccountInvitation({iri}));
    });
  }

  handleDeletePartnerWebsiteInvitation(iri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Einladung widerrufen und löschen?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.PartnerWebsiteInvitationsActions.DeletePartnerWebsiteInvitation({iri}));
    });
  }

  autoSetDefaultCustomerAddress(_response: {
    response: fromCustomersModuleModels.CustomerAddress,
    type: string,
    customer: fromCustomersModuleModels.Customer
  }): void {

    let changed = false;
    let payload = {};
    const {response: address, customer = null} = _response;
    console.log(address);
    if (customer) {

      if ((address['@type'] === 'BillingAddress') && !!!customer.defaultBillingAddress) {

        payload = {...payload, defaultBillingAddress: address['@id']};
        changed = true;
      }

      if ((address['@type'] === 'DeliveryAddress') && !!!customer.defaultDeliveryAddress) {

        payload = {...payload, defaultDeliveryAddress: address['@id']};
        changed = true;
      }

      if ((address['@type'] === 'PartnerBranchOfficeAddress') && !!!customer.defaultPartnerBranchOfficeAddress) {

        payload = {...payload, defaultPartnerBranchOfficeAddress: address['@id']};
        changed = true;
      }

      if (changed) {

        this.store$.dispatch(CustomersModuleActions.CustomersActions.SetDefaultCustomerAddress({
          iri: customer['@id'],
          payload
        }));
      }
    }
  }

  autoSetDefaultCustomerContact(response: any): void {

    const {customer, response: contact} = response;

    // customerContactTypes: ['/api/customer_contact_types/2']
    // 0 = CTO
    // 1 = COO
    // 2 = CEO
    let changed = false;
    let payload = {};

    // todo: migrate to switch statement
    if (contact.customerContactTypes.includes('/api/customer_contact_types/0') && !!!customer.defaultTechnicalCustomerContact) {

      payload = {...payload, defaultTechnicalCustomerContact: contact['@id']};
      changed = true;
    }

    if (contact.customerContactTypes.includes('/api/customer_contact_types/1') && !!!customer.defaultOrganizationalCustomerContact) {

      payload = {...payload, defaultOrganizationalCustomerContact: contact['@id']};
      changed = true;
    }

    if (contact.customerContactTypes.includes('/api/customer_contact_types/2') && !!!customer.defaultDecisionMakerCustomerContact) {

      payload = {...payload, defaultDecisionMakerCustomerContact: contact['@id']};
      changed = true;
    }

    if (changed) {
      this.store$.dispatch(CustomersModuleActions.CustomersActions.SetDefaultCustomerContact({
        iri: customer['@id'],
        payload
      }));
    }
  }

  handleCreateCustomerAddress(
    {apiRoute, payload: _payload}: { apiRoute: string, payload: fromCustomersModuleModels.CustomerAddress },
    customer: fromCustomersModuleModels.Customer
  ): void {

    const payload = {..._payload, customer: customer['@id']};
    const actionPayload = {apiRoute, payload, customer};
    this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.CreateCustomerAddress(actionPayload));
  }

  handleCreateCustomerComment(payload: fromCustomersModuleModels.CustomerComment): void {
    this.store$.dispatch(CustomersModuleActions.CustomerCommentsActions.CreateCustomerComment({payload: {...payload}}));
  }

  handleCreateCustomerContact(contact: fromCustomersModuleModels.CustomerContact, customer: fromCustomersModuleModels.Customer): void {

    const payload = {
      ...contact,
      customer: customer['@id']
    };
    this.store$.dispatch(CustomersModuleActions.CustomerContactsActions.CreateCustomerContact({customer, payload}));
  }

  handleReadCustomerComments(direction: string): void {

    if (!direction) {
      alert('direction is null or undefined');
    }
    const uri = this.customerCommentsPagination$.getValue()[direction];
    this.store$.dispatch(CustomersModuleActions.CustomerCommentsActions.ReadCustomerCommentChunk({uri}));
  }

  handleUpdateCustomer(payload: { iri: string, payload: fromCustomersModuleModels.Customer }): void {

    this.store$.dispatch(CustomersModuleActions.CustomersActions.UpdateCustomer(payload));
  }

  handleDeleteCustomer(customer: fromCustomersModuleModels.Customer): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Kunde wirklich gelöscht werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.CustomersActions.DeleteCustomer({iri: customer['@id']}));
    });
  }

  handleUpdateCustomerAddress(payload: { iri: string, payload: fromCustomersModuleModels.CustomerAddress }): void {

    this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.UpdateCustomerAddress(payload));
  }

  handleUpdateCustomerContact(payload: { iri: string, payload: fromCustomersModuleModels.CustomerContact }) {

    this.store$.dispatch(CustomersModuleActions.CustomerContactsActions.UpdateCustomerContact(payload));
  }

  handleDeleteCustomerContact(contact: fromCustomersModuleModels.CustomerContact) {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Ansprechpartner wirklich gelöscht werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => {
      this.store$.dispatch(CustomersModuleActions.CustomerContactsActions.DeleteCustomerContact({iri: contact['@id']}));
    });
  }

  handleUpdateDefaultCustomerAddress(
    {
      customerUuid,
      addressUuid,
      type
    }: {
      customerUuid: string,
      addressUuid: string,
      type: string
    }): void {

    const payload = {
      [type]: addressUuid
    };

    this.store$.dispatch(CustomersModuleActions.CustomersActions.UpdateCustomer({
      iri: customerUuid,
      payload
    }));
  }

  openDialog(elementRef: TemplateRef<any>, payload: any): void {

    this.presets$.next(payload);
    this.dialog.open(elementRef, {disableClose: true, autoFocus: false});
  }

  handleUpdateCustomerComment({
                                iri,
                                payload
                              }: { iri: string, payload: fromCustomersModuleModels.CustomerComment }): void {
    this.store$.dispatch(CustomersModuleActions.CustomerCommentsActions.UpdateCustomerComment({iri, payload}));
  }

  handleDeleteCustomerComment(comment: fromCustomersModuleModels.CustomerComment): void {
    this.store$.dispatch(CustomersModuleActions.CustomerCommentsActions.DeleteCustomerComment({iri: comment['@id']}));
  }

  handleDeleteCustomerAddress(addressIri: string): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll diese Adresse wirklich gelöscht werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      ).subscribe(() => this.store$.dispatch(CustomersModuleActions.CustomerAddressesActions.DeleteCustomerAddress({iri: addressIri}))
    );
  }

  handleShowCustomerForm(ref: TemplateRef<CustomersModuleComponents.CustomerFormComponent>, presets: fromCustomersModuleModels.Customer): void {

    this.presets$.next(presets);
    this.dialog.open(ref);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
    this.store$.dispatch(CustomersModuleActions.CustomersActions.ResetCurrentCustomer());
    this.store$.dispatch(CustomersModuleActions.CustomerOrdersActions.ResetCustomerOrdersState());
    this.store$.dispatch(CustomersModuleActions.CustomerOffersActions.ResetCustomerOffersState());
    this.store$.dispatch(CustomersModuleActions.CustomerInvoicesActions.ResetCustomerInvoicesState());
  }

  isActive(link: any): boolean {
    let url = this.router.url.toLowerCase();
    if (this.router.url.indexOf('?') > -1) {
      url = this.router.url.toLowerCase().split('?')[0];
    }
    return url === link.url.toLowerCase();
  }

  toggleVIP(): void {
    const payload = {iri: extractIri(this.customer), payload: {vip: !this.customer.vip}};
    this.store$.dispatch(CustomersModuleActions.CustomersActions.UpdateCustomer(payload));
  }
}
