import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';

import * as fromAuthModuleModels from '../models';
import { JwtObject, LoginPayload, LoginResponse } from '../models';
import * as fromSharedModuleServices from '../../shared/services';
import { environment } from '../../../environments/environment';
import { AbstractApiResponse } from '../../shared/models';
import { ApplicationState } from '../../application-state/store';
import { createAction, Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  map,
  timeInterval
} from 'rxjs/operators';
import { combineLatestArray } from 'rxjs-etc';
import {LocalStorageService} from "../../shared/services";

export const clearStateAction = createAction('[ROOT] CLEAR STATE');

@Injectable()
export class AuthService {
  static mapping = {
    sales: 'ROLE_SALES',
    technician: 'ROLE_TECHNICIAN',
    salesExternal: 'ROLE_SALES_EXTERNAL',
    administrator: 'ROLE_ADMINISTRATOR',
    superAdmin: 'ROLE_SUPER_ADMIN',
    supervisor: 'ROLE_SUPERVISOR',
    partnerManagement: 'ROLE_PARTNER_MANAGEMENT',
    technicianExternal: 'ROLE_TECHNICIAN_EXTERNAL',
    technicianExternalDHE: 'ROLE_TECHNICIAN_EXTERNAL_DHE',
    technicianExternalDRE: 'ROLE_TECHNICIAN_EXTERNAL_DRE',
    accounting: 'ROLE_ACCOUNTING',
    logistics: 'ROLE_LOGISTICS'
  };
  private baseUserRoles = [];

  private lastJWTPayload$: BehaviorSubject<string> = new BehaviorSubject<
    string
  >('');
  private currentJWTPayload$: BehaviorSubject<JwtObject> = new BehaviorSubject<
    JwtObject
  >(null);
  private readonly ADMINISTRATOR_ROLES = [
    'ROLE_ADMINISTRATOR',
    'ROLE_SUPER_ADMIN',
    'ROLE_ADMIN'
  ];
  private readonly CUSTOMER_ACCOUNTS_ROLES = [
    'ROLE_MWS_CUSTOMER_GUEST',
    'ROLE_MWS_CUSTOMER_STAFF',
    'ROLE_MWS_CUSTOMER_ADMIN'
  ];
  private readonly DEFAULT_LOCALE = 'preferredLanguageLocale';
  private readonly PARTNER_WEBSITE_ROLES = [
    'ROLE_CUSTOMER_PARTNER_WEBSITE_READER',
    'ROLE_CUSTOMER_PARTNER_WEBSITE_EDITOR',
    'ROLE_CUSTOMER_PARTNER_WEBSITE_ADMIN',
    'ROLE_MWS_PARTNER_WEBSITE_GUEST',
    'ROLE_MWS_PARTNER_WEBSITE_STAFF',
    'ROLE_MWS_PARTNER_WEBSITE_ADMIN',
    'ROLE_CUSTOMER_PARTNER_WEBSITE'
  ];

  constructor(
    private apiService: fromSharedModuleServices.AbstractApiService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private store$: Store<ApplicationState>
  ) {
    const p = this.readJWTPayload();
    this.currentJWTPayload$.next(p);
    this.lastJWTPayload$.next(JSON.stringify(p));

    combineLatestArray([
      interval(1000).pipe(
        timeInterval(),
        map(e => e.value + '')
      ),
      this.lastJWTPayload$
    ])
      .pipe(filter(([f, e]) => e !== JSON.stringify(this.readJWTPayload())))
      .subscribe(data => {
        const p1 = this.readJWTPayload();
        this.currentJWTPayload$.next(p1);
        this.lastJWTPayload$.next(JSON.stringify(p1));
      });
    this.userBaseRoles$
      .pipe(filter(list => list?.length >= 0))
      .subscribe(roles => {
        this.baseUserRoles = roles;
      });
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.currentJWTPayload$.pipe(
      map(e => this.cookieService.check('jwt_hp'))
    );
  }

  get userBaseRoles$(): Observable<Array<string>> {
    return this.currentJWTPayload$.pipe(map(e => e?.roles || []));
  }

  get isLoggedInAndNotExpired$(): Observable<boolean> {
    return this.remainingSessionSeconds$.pipe(
      map(e => e > 0),
      distinctUntilChanged()
    );
  }

  get jwtPayload$(): Observable<JwtObject> {
    return this.currentJWTPayload$.asObservable();
  }

  get userType$(): Observable<string> {
    return this.currentJWTPayload$.pipe(
      filter(e => e?.roles?.length > 0),
      map(({ roles }) => {
        if (!roles) {
          return null;
        } else if (roles.some(val => this.ADMINISTRATOR_ROLES.includes(val))) {
          // Administrator
          return 'administrator';
        } else if (
          roles.some(val => this.CUSTOMER_ACCOUNTS_ROLES.includes(val))
        ) {
          // Customer Accounts

          return 'customer_account';
        } else if (
          roles.some(val => this.PARTNER_WEBSITE_ROLES.includes(val))
        ) {
          // Partner Website
          return 'partner_website';
        } else {
          throw new Error('User Type could not be determined from Roles');
        }
      })
    );
  }

  get remainingSessionSeconds$(): Observable<number> {
    return combineLatestArray([
      interval(1000).pipe(
        timeInterval(),
        map(e => e.value)
      ),
      this.currentJWTPayload$.pipe(
        map(e => e?.exp || 0),
        distinctUntilChanged()
      )
    ]).pipe(map(([i, exp]) => exp * 1000 - new Date().getTime()));
  }

  get isAdmin$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => {
        return (
          e?.includes(AuthService.mapping.administrator) ||
          e?.includes('ROLE_SUPER_ADMIN')
        );
      })
    );
  }

  get isSupervisor$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.supervisor))
    );
  }

  get isPartnerManagement$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.partnerManagement))
    );
  }

  get isSales$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.sales))
    );
  }

  get isSalesExternal$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.salesExternal))
    );
  }

  get isTechnician$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.technician))
    );
  }

  get isTechnicianExternal$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(
        e =>
          e &&
          (e.includes(AuthService.mapping.technicianExternal) ||
            e.includes(AuthService.mapping.technicianExternal))
      )
    );
  }

  get isAccounting$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.accounting))
    );
  }

  get isLogistic$(): Observable<boolean> {
    return this.userBaseRoles$.pipe(
      map(e => e?.includes(AuthService.mapping.logistics))
    );
  }

  login(payload: LoginPayload): Observable<LoginResponse | any> {
    return this.apiService.createObject('/login_check', payload);
  }

  hasGroupWithName(baseUserRoles: Array<string>, name: string): boolean {
    const roles = Object.values(AuthService.mapping);
    if (roles.indexOf(name) > -1) {
      return baseUserRoles?.includes(name);
    } else if (AuthService.mapping[name]) {
      return baseUserRoles?.includes(AuthService.mapping[name]);
    } else {
      console.error(new Error('GROUP ' + name + ' does not exists.'));
      return false;
    }
  }

  /** @deprecated use Observable */
  isAdmin(): boolean {
    return (
      this.baseUserRoles?.includes(AuthService.mapping.administrator) ||
      this.baseUserRoles?.includes('ROLE_SUPER_ADMIN')
    );
  }

  /** @deprecated use Observable */

  isSupervisor(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.supervisor);
  }

  /** @deprecated use Observable */

  isPartnerManagement(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.partnerManagement);
  }

  /** @deprecated use Observable */

  isSales(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.sales);
  }

  /** @deprecated use Observable */

  isSalesExternal(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.salesExternal);
  }

  /** @deprecated use Observable */

  isTechnician(): boolean {
    // console.log(this.baseUserRoles?.includes(AuthService.mapping.technician), AuthService.mapping.technician, this.baseUserRoles);
    return this.baseUserRoles?.includes(AuthService.mapping.technician);
  }

  /** @deprecated use Observable */

  isTechnicianExternal(): boolean {
    const userRoles = this.baseUserRoles;
    return (
      userRoles && userRoles.includes(AuthService.mapping.technicianExternal)
    );
  }

  /** @deprecated use Observable */

  isAccounting(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.accounting);
  }

  /** @deprecated use Observable */
  isLogistic(): boolean {
    return this.baseUserRoles?.includes(AuthService.mapping.logistics);
  }

  updatePassword(
    iri: string,
    payload: fromAuthModuleModels.PasswordUpdate
  ): Observable<AbstractApiResponse> {
    return this.apiService.updateObject(
      `${iri}/change_password`,
      payload,
      true
    );
  }

  createPasswordResetRequest(payload: {
    username: string;
  }): Observable<AbstractApiResponse> {
    return this.apiService.createObject(`/resetting/request`, payload);
  }

  createPasswordReset(payload): Observable<fromAuthModuleModels.LoginResponse> {
    return this.apiService.createObject(`/resetting/reset`, payload);
  }

  logout(): Observable<boolean> {
    this.clearLocalStorage();
    this.removeCookies();
    this.store$.dispatch(clearStateAction());
    return of(true);
  }

  removeCookies(): void {
    this.cookieService.delete('jwt_hp', '/', environment.cookieDomain);
  }

  setDefaultLocale(): void {
    localStorage.setItem(this.DEFAULT_LOCALE, 'de_DE');
  }

  clearLocalStorage(): void {
    var toggle = this.localStorageService.get('toggleState');
    var filters = this.localStorageService.get('filters');
    localStorage.clear();
    if(toggle) {
      this.localStorageService.set('toggleState', toggle);
    }
    if(filters) {
      this.localStorageService.set('filters', filters);
    }
  }

  private readJWTPayload(): JwtObject | null {
    const token = this.cookieService.get('jwt_hp');
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

  private getParsedJwt(): JwtObject {
    return this.readJWTPayload();
  }
}
