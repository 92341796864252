import { createReducer, on } from '@ngrx/store';
import { keyBy } from 'lodash-es';
import * as fromModuleModels from '../../models';
import { OrderSpecialAgreement } from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrderSpecialAgreementsActions } from '../actions';
import { mergeEntities } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.OrderSpecialAgreement };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    OrderSpecialAgreementsActions.CreateOrderSpecialAgreement,
    OrderSpecialAgreementsActions.ReadOrderSpecialAgreement,
    OrderSpecialAgreementsActions.ReadOrderSpecialAgreements,
    OrderSpecialAgreementsActions.UpdateOrderSpecialAgreement,
    OrderSpecialAgreementsActions.DeleteOrderSpecialAgreement,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    OrderSpecialAgreementsActions.CreateOrderSpecialAgreementSuccess,
    OrderSpecialAgreementsActions.UpdateOrderSpecialAgreementSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    OrderSpecialAgreementsActions.ReadOrderSpecialAgreementsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const otherEntities = keyBy(
        Object.values(state.entities).filter(
          (e: OrderSpecialAgreement) => e.order !== items[0].order
        ),
        '@id'
      );
      return {
        ...state,
        entities: mergeEntities(items, null, otherEntities),
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    OrderSpecialAgreementsActions.DeleteOrderSpecialAgreementSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    OrderSpecialAgreementsActions.CreateOrderSpecialAgreementFail,
    OrderSpecialAgreementsActions.UpdateOrderSpecialAgreementFail,
    OrderSpecialAgreementsActions.DeleteOrderSpecialAgreementFail,
    state => {
      return {
        ...state,
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
