/**
 * Get object keys as an array
 *
 * @param anything
 */
const getObjectKeys = (anything: object): Array<string> => {
  return Object.keys(anything);
};

/**
 * Get object values as an array
 *
 * @param anything
 */
const getObjectValues = (anything: object): Array<any> => {
  return Object.values(anything);
};

/**
 * Filter out all falsy values (empty strings '', null, undefined)
 * KEEP false and 0 values
 * @param obj
 */
const removeEmptyValuesFromObject = (obj: object): object => {
  return Object.keys(obj)
    .filter(k => !!obj[k] || obj[k] === false || obj[k] === 0 || obj[k] === '0')
    .reduce((a, k) => ({...a, [k]: obj[k]}), {});
};

/**
 *
 * Group
 *
 * @param data
 * @param keyFn
 */
const groupBy = (data: any, keyFn): Array<any> => {
  return data.reduce((agg, item) => {
    const group = keyFn(item);
    agg[group] = [...(agg[group] || []), item];
    return agg;
  }, {});
};

export const extractTypeByIri = (input: any, nullable = false): string => {
  const iri = extractIri(input, nullable);
  if (!iri) {
    return null;
  }
  const parts = iri.split('/');
  return parts[2] || null;

};
export const extractUUID = (input: any, nullable = false): string => {
  const iri = extractIri(input, nullable);
  if (!iri) {
    return null;
  }

  const parts = iri.split('/');
  return parts[3] || null;
};
export const extractIri = (input: any, nullable = false): string => {
  if (!input) {
    return null;
  }
  if (typeof input === typeof 'string') {
    return input;
  } else if ('@id' in input) {
    return input['@id'];
  } else {
    if (nullable) {
      return null;
    } else {
      throw new Error('could not find IRI');
    }
  }
};
export const extractType = (input: any): string => {
  if (!input) {
    return null;
  }
  if ('@type' in input) {
    return input['@type'];
  } else {
    throw new Error('could not find Type');
  }
};
export const ObjectsUtility = {
  getObjectKeys,
  getObjectValues,
  groupBy,
  removeEmptyValuesFromObject,
  extractIri
};
