import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { CustomerReplacementStockItemsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.CustomerReplacementStockItem };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItem,
    CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem,
    CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItems,
    CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItem,
    state => {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
  ),
  on(
    CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess,
    (state, { response }) => {
      const entities = {
        [response['@id']]: response,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemSuccess,
    CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(
    CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail,
    CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemFail,
    CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(
    CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemSuccess,
    (state, { iri }) => {
      const { [iri]: removedItem, ...entities }: any = state.entities;

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
