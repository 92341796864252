import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {filter, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';

import {AuthActions} from '../../../auth/store';

@Component({
  selector: 'logout-button',
  styleUrls: ['logout-button.component.scss'],
  template: `

    <span mat-menu-item (click)="handleRequestLogoutUser()">
    <mat-icon>power_settings_new</mat-icon>
    <span class="dots">...</span>
  </span>
  `
})
export class LogoutButtonComponent implements OnInit, OnDestroy {

  @Output()
  requestLogoutUser: EventEmitter<void> = new EventEmitter<void>();

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private router: Router, private dialog: MatDialog, private store: Store<ApplicationState>) {
  }

  ngOnInit(): void {
  }

  handleRequestLogoutUser(): void {

    this.dialog
      .open(DialogComponent, {
        data: {
          text: 'Wirklich ausloggen?',
          cancelText: 'Abbrechen',
          confirmationText: 'Ja, ausloggen'
        }
      })
      .afterClosed()
      .pipe(
        filter(hasConfirmedModal => hasConfirmedModal),
        takeUntil(this.onDestroy$)
      ).subscribe(() => {

      this.store.dispatch(AuthActions.LogoutUser());
      this.dialog.closeAll();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
