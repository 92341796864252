import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import {AbstractApiResponse} from '../../../shared/models';
import {ParameterBag} from '../../../shared/models/ParameterBag.interface';

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_OFFERS = '[Customers Module] Read Customer Orders';
export const READ_CUSTOMER_OFFERS_SUCCESS = '[Customers Module] Read Customer Orders Success';
export const READ_CUSTOMER_OFFERS_FAIL = '[Customers Module] Read Customer Orders Fail';

export const ReadCustomerOrders = createAction(
  READ_CUSTOMER_OFFERS,
  props<{ page: number, params: ParameterBag }>()
);

export const ReadCustomerOrdersSuccess = createAction(
  READ_CUSTOMER_OFFERS_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerOrdersFail = createAction(
  READ_CUSTOMER_OFFERS_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_CUSTOMER_OFFERS_CHUNK = '[Customers Module] Read Customer Orders Chunk';
export const READ_CUSTOMER_OFFERS_CHUNK_SUCCESS = '[Customers Module] Read Customer Orders Chunk Success';
export const READ_CUSTOMER_OFFERS_CHUNK_FAIL = '[Customers Module] Read Customer Orders Chunk Fail';

export const ReadCustomerOrdersChunk = createAction(
  READ_CUSTOMER_OFFERS_CHUNK,
  props<{ iri: string }>()
);

export const ReadCustomerOrdersChunkSuccess = createAction(
  READ_CUSTOMER_OFFERS_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerOrdersChunkFail = createAction(
  READ_CUSTOMER_OFFERS_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset
export const RESET_CUSTOMER_OFFERS_STATE = '[Customers Module] Reset Customer Orders State';

export const ResetCustomerOrdersState = createAction(
  RESET_CUSTOMER_OFFERS_STATE
);
