import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { InvoiceMail, Offer } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class OffersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createOffer(payload: Offer): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OFFER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/offers`, payload)
    );
  }

  readOffer(iri: string): Observable<Offer> {
    return this.rolesService.userHasRoleFilter<Offer>(
      'ROLE_MWS_OFFER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  sendMail(
    iri: string,
    payload: InvoiceMail
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse | any>(
      'ROLE_MWS_OFFER_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  readOffers(page = 1, params?: ParameterBag): Observable<Array<any>> {
    return this.rolesService.userHasRoleFilter<Array<any>>(
      'ROLE_MWS_OFFER_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/offers', page, params)
            )
    );
  }

  readOfferAsPdf(credit: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OFFER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              credit + '/preview?disposition=attachment',
              true
            )
    );
  }

  readAcceptedOfferAsPdf(credit: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OFFER_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              credit + '?disposition=attachment',
              true
            )
    );
  }

  readOfferChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_SHIPPING_PROVIDER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  updateOffer(iri: string, payload: Offer): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OFFER_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteOffer(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_OFFER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
