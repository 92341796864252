import { AdministratorGroupsService } from './administrator-groups.service';
import { AdministratorsService } from './administrators.service';
import {NewFeedbackRequestsService} from "./feedback-requests.service";

export const AllServices = [
  AdministratorGroupsService,
  AdministratorsService,
  NewFeedbackRequestsService
];

export * from './administrator-groups.service';
export * from './administrators.service';
export * from './feedback-requests.service';
