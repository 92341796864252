import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output} from '@angular/core';
import {CancellationInvoice, CorrectionInvoice, PartialInvoice, PayableInvoice} from '../../models';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment/moment';
import {cloneDeep} from 'lodash-es';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Department} from '../../../master-data/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {Customer} from '../../../customers/models';
import {Order} from '../../../orders/models';
import {InvoiceRecipient} from '../../models/invoice-recipient.interface';
import {CustomersSelectors} from '../../../customers/store/selectors';
import {DepartmentsSelectors} from '../../../master-data/store/selectors';
import {extractUUID, extractIri, extractTypeByIri} from '../../../shared/utilities/objects.utility';
import {formatNumber} from '@angular/common';
import {PayableInvoicesSelectors} from '../../store/selectors';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {InvoiceItemDialogComponent} from "../invoice-item-dialog/invoice-item-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-cancellation-invoice-preview',
  styleUrls: ['./cancellation-invoice-preview.component.scss'],
  template: `
    <div class="invoice-wrapper">
      <div class="invoice-content">
        <div class="row invoice-header">
          <div class="col-8">
            <div class="invoice-action-box-wrapper my-3">
              <app-invoice-action-box [invoice]="invoice"></app-invoice-action-box>
            </div>
            <div class="invoice-head-recipient-wrapper mt-5">
              <app-invoice-head-recipient
                class="my-5"
                [readonly]="true"
                [invoice$]="invoice$"
                (updateCustomer)="handleUpdateCustomer($event)"
                (updateOrder)="handleUpdateOrder($event)"
                (updateRecipient)="handleUpdateRecipient($event)"
              ></app-invoice-head-recipient>
            </div>
          </div>
          <div class="col-4">
            <app-invoice-head-issuer
              [readonly]="true"
              [invoice$]="invoice$"
              (updateIssuer)="handleUpdateIssuer($event)"
            ></app-invoice-head-issuer>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-8">
            <span class="heading--h2">
              Storno-Rechnung / Cancellation Invoice
              <span>{{invoice?.cancellationInvoiceNumber}}</span>
            </span>
          </div>

          <div class="col-4 text-right">
            <span class="heading--h2">
              Datum/Date: {{(invoice?.createdAt ? invoice.createdAt : null) | date: 'dd.MM.Y'}}
            </span>
          </div>
        </div>
        <div class="row my-2">
          <p class="m-b--64" *ngIf="canceledInvoice">Gemäß unserer Absprache stornieren wir unsere <a
            [routerLink]="['/invoices', 'payable',extractTypeByIri(canceledInvoice), extractId(canceledInvoice)]"
            class="text-decoration-none">Rechnung
            Nr. {{canceledInvoice?.invoiceNumber }} </a>vom {{canceledInvoice?.orderDate|date:'dd.MM.YYYY'}} wie folgt:
          </p>
          <!--          <pre>{{canceledInvoice|json}}</pre>-->
        </div>
        <div class="row my-2 mb-4">
          <app-invoice-items-summary [invoice$]="invoice$"></app-invoice-items-summary>
        </div>
        <div class="row">
          <div class="col-7 ">
            <button mat-flat-button class="mb-2" *ngIf="invoice && invoice['@id'] && canEdit" (click)="addPosition()">
              <mat-icon class="text-bg-green text-white border-radius-25">add</mat-icon>
              <span class="pt-1 ms-2">
              Position hinzufügen
                </span>
            </button>
            <p class="text-color-grey" *ngIf="customer?.taxNumber">Steuernummer
              Kunde: {{ customer?.taxNumber || '-' }}</p>
            <p class="text-color-grey">
              Bitte überweisen sie den Rechnungsbetrag in Höhe von
              {{ getInvoiceGrossSum(invoice) }} sofort nach
              Rechnungserhalt.
            </p>
          </div>
          <div class="col-5">
            <app-invoice-total [invoice]="invoice"></app-invoice-total>

          </div>


        </div>
        <div class="row">
          <div class="col-12">

            <app-invoice-custom-text [invoice$]="invoice$"></app-invoice-custom-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12">

            <app-invoice-footer [invoice$]="invoice$"></app-invoice-footer>
          </div>
        </div>
      </div>

    </div>
  `
})
export class CancellationInvoicePreviewComponent extends BaseOnDestroyComponent implements OnInit, OnDestroy {
  @Input() invoice$: Observable<CancellationInvoice>;
  invoice: CancellationInvoice;
  invoiceWithChanges: CancellationInvoice;
  @Output() updateInvoice: EventEmitter<CancellationInvoice>
    = new EventEmitter<CancellationInvoice>();
  @Output() updateOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() updateCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
  customer: Customer;
  department: Department;
  canceledInvoice: PayableInvoice | PartialInvoice = null;

  protected readonly extractTypeByIri = extractTypeByIri;
  protected readonly extractId = extractUUID;
  protected readonly extractIri = extractIri;

  get canEdit(): boolean {
    if ('editable' in this.invoice) {
      return this.invoice.editable;
    }
    return false;
  }

  constructor(
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
    private store$: Store<ApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.invoice$
      .pipe(takeUntil(this.onDestroy$), filter(invoice => !!invoice))
      .subscribe(invoice => {
        this.invoice = invoice;
        this.invoiceWithChanges = invoice;
        if ('canceledInvoice' in invoice) {
          this.store$.select(PayableInvoicesSelectors.selectPayableInvoicesEntities)
            .pipe(filter(e => !!e[extractIri(invoice.canceledInvoice)]), take(1))
            .subscribe(i => {
              this.canceledInvoice = i[extractIri(invoice.canceledInvoice)];
            });
        }

        if (invoice.customerNumber) {
          this.store$.select(CustomersSelectors.sByCustomerNumber, {customerNumber: invoice.customerNumber})
            .pipe(takeUntil(this.onDestroy$), filter(i => !!i), take(1))
            .subscribe(c => {
              this.customer = c;
            });
        }
        if (invoice.issuer) {
          this.store$.select(DepartmentsSelectors.sByIri, {iri: extractIri(invoice.issuer)})
            .pipe(takeUntil(this.onDestroy$), filter(i => !!i), take(1))
            .subscribe(c => {
              this.department = c;
            });
        }
      });
  }


  getInvoiceGrossSum(invoice?: CancellationInvoice): string {
    if (invoice && invoice.grossTotal) {
      return `${formatNumber(parseFloat(invoice.grossTotal.value), this.locale, '1.2-2')} ${invoice.grossTotal.currency}`;
    } else {
      return '-';
    }
  }

  handleUpdateIssuer(selectedIssuers: Department): void {
    if (!this.invoice) {
      return;
    }
    const invoice: CancellationInvoice = cloneDeep(this.invoice);
    invoice.issuer = selectedIssuers;
    this.updateInvoice.emit(invoice);
  }

  handleUpdateCustomer(customer: Customer): void {
    this.updateCustomer.emit(customer);
  }

  handleUpdateOrder(order: Order): void {
    this.updateOrder.emit(order);
  }

  handleUpdateRecipient(c: InvoiceRecipient): void {
    if (!this.invoiceWithChanges) {
      return;
    }
    const invoice: CancellationInvoice = cloneDeep(this.invoiceWithChanges || {});
    invoice.recipient.address = {...invoice.recipient.address, ...c.address};
    invoice.recipient = {...invoice.recipient, ...c};
    this.updateInvoice.emit(invoice);
    this.invoiceWithChanges = invoice;

  }

  addPosition(): void {
    this.dialog.open(InvoiceItemDialogComponent, {data: {invoice: this.invoice}});

  }

}
