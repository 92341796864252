import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { FeedbackRequest } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class FeedbackRequestsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  loadFeedbackRequest(iri: string): Observable<FeedbackRequest> {
    return this.rolesService.userHasRoleFilter<FeedbackRequest>(
      'ROLE_MWS_FEEDBACK_REQUEST_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  loadFeedbackRequests(): Observable<Array<FeedbackRequest>> {
    return this.rolesService.userHasRoleFilter<Array<FeedbackRequest>>(
      'ROLE_MWS_FEEDBACK_REQUEST_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/feedback_requests`)
    );
  }

  loadSendFeedbackRequests(): Observable<Array<FeedbackRequest>> {
    return this.rolesService.userHasRoleFilter<Array<FeedbackRequest>>(
      'ROLE_MWS_FEEDBACK_REQUEST_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(`/feedback_requests?userIsSender=true`)
    );
  }

  updateFeedbackRequest({
    iri,
    feedback
  }: {
    iri: string;
    feedback: string;
  }): Observable<FeedbackRequest> {
    return this.apiService.updateObject(iri, { feedback }, true);
  }
}
