import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export const MAKE_TRANSITION = '[Application State Module] Make Transition';
export const MAKE_TRANSITION_SUCCESS = '[Application State Module] Make Transition Success';
export const MAKE_TRANSITION_FAIL = '[Application State Module] Make Transition Fail';

export const MakeTransition = createAction(
  MAKE_TRANSITION,
  props<{ uri: string, payload: { workflow: string, transition: string } }>()
);

export const MakeTransitionSuccess = createAction(
  MAKE_TRANSITION_SUCCESS,
  props<{ response: any }>()
);

export const MakeTransitionFail = createAction(
  MAKE_TRANSITION_FAIL,
  props<{ response: HttpErrorResponse }>()
);
