import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromModuleModels from '../../models';
import {CurrencyMaskConfig} from 'ngx-currency';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from 'src/app/shared/services/forms.service';
import {currencyMaskConfig} from '../../../shared/shared.module';

@Component({
  selector: 'product-form',
  styleUrls: ['product-form.component.scss'],
  template: `

    <div class="card mat-elevation-z1">

      <div class="card__heading">
        <span>{{ presets$.getValue() ? 'Produkt bearbeiten' : 'Neues Produkt anlegen' }}</span>
      </div>

      <div class="card__content p-a--24">

        <ng-container [formGroup]="pf">

          <div class="grid">

            <div class="column-14">
              <mat-form-field>
                <mat-label>Produktname</mat-label>
                <input type="text" formControlName="name" matInput>
                <mat-hint align="start" *ngIf="errors?.name">{{ errors.name.message }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="column-7">
              <mat-form-field>
                <mat-label>Mindestbestand</mat-label>
                <input type="number" formControlName="targetStock" matInput>
                <mat-hint align="start" *ngIf="errors?.targetStock">{{ errors.targetStock.message }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="column-7">
              <mat-form-field>
                <mat-label>Datenträger-Größe (in GB)</mat-label>
                <input type="number" formControlName="storageSystemSize" matInput>
                <mat-hint align="start"
                          *ngIf="errors?.storageSystemSize">{{ errors.storageSystemSize.message }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="column-14">
              <div class="mat-form-field">
                <ng-select
                  placeholder="Speichersystem"
                  [items]="storageSystems$ | async"
                  bindValue="@id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="storageSystemType"></ng-select>
                <mat-hint align="start"
                          *ngIf="errors?.storageSystemManufacturer">{{ errors.storageSystemType.message }}</mat-hint>
              </div>
            </div>

            <div class="column-14">
              <div class="mat-form-field">
                <ng-select
                  placeholder="Speichersystem-Hersteller"
                  [items]="storageSystemManufacturers$ | async"
                  bindValue="@id"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="storageSystemManufacturer"></ng-select>
                <mat-hint align="start"
                          *ngIf="errors?.storageSystemManufacturer">{{ errors.storageSystemManufacturer.message }}</mat-hint>
              </div>
            </div>

            <ng-container formGroupName="price">

              <div class="column-10">
                <mat-form-field>
                  <mat-label>Preis (netto)</mat-label>
                  <input type="text" currencyMask [options]="currencyMaskConfigForComponent" formControlName="value"
                         matInput>
                  <mat-hint align="start"
                            *ngIf="errors.hasOwnProperty('price.value')">{{ errors['price.value'].message }}</mat-hint>
                </mat-form-field>
              </div>

              <div class="column-4">
                <div class="mat-form-field">

                  <ng-select
                    placeholder="Währung*"
                    [items]="currencies"
                    bindLabel="name"
                    [searchable]="false"
                    [clearable]="false"
                    formControlName="currency"
                    bindValue="code"
                  ></ng-select>
                  <mat-hint align="start"
                            *ngIf="errors.hasOwnProperty('price.currency')">{{ errors['price.currency'].message }}</mat-hint>
                </div>
              </div>
            </ng-container>

            <div class="column-14 mat-form-field">

              <ng-select
                placeholder="Einheit"
                [items]="productUnits"
                bindValue="@id"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
                formControlName="productUnit"
              ></ng-select>
              <mat-hint align="start" *ngIf="errors?.productUnit">{{ errors.productUnit.message }}</mat-hint>
            </div>

            <div class="column-14 mat-form-field">

              <ng-select
                placeholder="Standard-Steuersatz"
                [items]="taxRates"
                bindValue="@id"
                bindLabel="name"
                required
                [clearable]="false"
                [searchable]="false"
                formControlName="defaultTax"
              ></ng-select>
              <mat-hint align="start" *ngIf="errors?.defaultTax">{{ errors.defaultTax.message }}</mat-hint>
            </div>

            <div class="column-14">
              <mat-form-field>
                <mat-label>Beschreibung</mat-label>
                <textarea formControlName="description" matInput matTextareaAutosize></textarea>
                <mat-hint align="start" *ngIf="errors?.description">{{ errors.description.message }}</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="grid">
            <div class="m-ta--2 column-14">

              <button *ngIf="presets$.getValue() || pf.dirty" class="m-r--16" (click)="cancelEdit()" type="button"
                      color="outline" mat-flat-button>
                <mat-icon class="m-r--8">cancel</mat-icon>
                <span>Abbrechen</span>
              </button>

              <button
                [disabled]="pf.invalid || pf.untouched" mat-flat-button color="green"
                (click)="handleSubmit()">
                <mat-icon class="m-r--8">save</mat-icon>
                <span>{{ presets$.getValue() ? 'Aktualisieren' : 'Anlegen' }}</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!--<pre>{{ pf.value | json }}</pre>-->
  `
})
export class ProductFormComponent implements OnInit {

  @Input() currencies: Array<fromModuleModels.Currency>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromModuleModels.Product>;
  @Input() productUnits: Array<fromModuleModels.ProductUnit>;
  @Input() storageSystemManufacturers$: Observable<Array<fromModuleModels.StorageSystemManufacturer>>;
  @Input() storageSystems$: Observable<Array<fromModuleModels.StorageSystem>>;
  @Input() taxRates: Array<fromModuleModels.Tax>;

  @Output() requestCreateItem: EventEmitter<{ payload: fromModuleModels.Product, entity: string }> = new EventEmitter();
  @Output() requestUpdateItem: EventEmitter<{
    iri: string,
    payload: fromModuleModels.Product,
    entity: string
  }> = new EventEmitter();

  onDestroy$: Subject<any> = new Subject<any>();
  pf: FormGroup;

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {
    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(preset => {

      this.initForm();
      this.fs.patchForm(this.pf, preset);
      this.fs.resetFormErrors(this.pf);
    });
  }

  get currencyMaskConfigForComponent(): CurrencyMaskConfig {
    return {...currencyMaskConfig, suffix: ''};
  }

  initForm(): void {
    this.pf = this.fb.group({
      targetStock: this.fb.control(null, [Validators.required, Validators.minLength(1), Validators.min(1)]),
      storageSystemType: this.fb.control(null, [Validators.minLength(14)]),
      storageSystemManufacturer: this.fb.control(null, [Validators.minLength(14)]),
      storageSystemSize: this.fb.control(null, [Validators.required, Validators.min(1)]),
      name: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]),
      description: this.fb.control(null, [Validators.minLength(10)]),
      price: this.fb.group({
        value: this.fb.control(null, [Validators.required]),
        currency: this.fb.control('EUR', [Validators.required])
      }),
      defaultTax: this.fb.control(null, Validators.required),
      productUnit: this.fb.control(null, Validators.required)
    });
  }

  cancelEdit(): void {
    this.initForm();
    this.presets$.next(null);
  }

  handleSubmit(): void {

    let payload: fromModuleModels.Product = this.pf.value;
    const priceValue = payload.price.value.toString();

    // API wants "string" but "int" coming form ngxCurrency directive
    payload = {
      ...payload,
      price: {
        ...payload.price,
        value: priceValue
      }
    };

    !this.presets$.getValue()
      ? this.requestCreateItem.emit({payload, entity: 'Product'})
      : this.requestUpdateItem.emit({iri: this.presets$.getValue()['@id'], payload, entity: 'Product'});
  }
}
