import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';

import {TicketCommentTagsActions} from '../actions';
import {TicketCommentTagsService} from '../../services';

@Injectable()
export class TicketCommentTagsEffects {

  constructor(private actions$: Actions, private service: TicketCommentTagsService) {
  }


  ReadTicketCommentTag$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentTagsActions.ReadTicketCommentTag),
    switchMap(({iri}) => {
      return this.service.readTicketCommentTag(iri).pipe(
        map((response: any) => TicketCommentTagsActions.ReadTicketCommentTagSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentTagsActions.ReadTicketCommentTagFail({response})))
      );
    })
  ));


  ReadTicketCommentTagTags$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCommentTagsActions.ReadTicketCommentTags),
    switchMap(() => {
      return this.service.readTicketCommentTags().pipe(
        map((response: any) => TicketCommentTagsActions.ReadTicketCommentTagsSuccess({response})),
        catchError((response: HttpErrorResponse) => of(TicketCommentTagsActions.ReadTicketCommentTagsFail({response})))
      );
    })
  ));
}
