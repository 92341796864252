import {createSelector} from '@ngrx/store';

import {DEPARTMENT_LOGOS_FEATURE_KEY, selectMasterDataModuleState} from '../reducers';

export const selectDepartmentLogosState = createSelector(
  selectMasterDataModuleState,
  (moduleState) => moduleState[DEPARTMENT_LOGOS_FEATURE_KEY]
);

export const selectDepartmentLogosEntities = createSelector(
  selectDepartmentLogosState,
  (state) => state.entities
);
export const sByIri = createSelector(
  selectDepartmentLogosState,
  (state, {iri}) => state.entities[iri]
);

export const selectDepartmentLogos = createSelector(
  selectDepartmentLogosEntities,
  (entities) => Object.values(entities)
);

export const selectErrors = createSelector(
  selectDepartmentLogosState,
  (state) => state.errors
);
export const sUploadStatus = createSelector(
  selectDepartmentLogosState,
  (state) => state.uploadStatus
);

export const isLoading = createSelector(
  selectDepartmentLogosState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectDepartmentLogosState,
  (state) => state.loaded
);
