import {ObjectsUtility} from './objects.utility';

export const formatParams = (paramsObject: { [key: string]: number | string | boolean | string[] }): string => {
  const paramsWithValues = ObjectsUtility.removeEmptyValuesFromObject(paramsObject);
  const list = [];
  for (const key of Object.keys(paramsWithValues)) {
    if (typeof paramsObject[key] === typeof []) {
      // @ts-ignore
      for (const value of paramsObject[key]) {
        const valueCoded = encodeURIComponent(value);
        list.push(`${key}[]=${valueCoded}`);

      }
    } else {
      // @ts-ignore
      const value = encodeURIComponent(paramsObject[key]);
      list.push(`${key}=${value}`);

    }
  }
  return list.join('&');
};
export const getUriWithPageAndParams
  = (BASEURL: string, page: number = null, params: { [key: string]: number | string | boolean | string[] } = null): string => {
  const url = BASEURL + '?';
  const queryParts = [];
  if (page) {
    if (page === -1) {
      queryParts.push('pagination=false');
    } else {
      queryParts.push('pagination=true');
      queryParts.push(formatParams({page}));
    }
  }
  if (params) {
    queryParts.push(formatParams(params));
  }
  return url + queryParts.join('&');

};
export default {
  formatParams
};
