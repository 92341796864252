import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';
import { OrderSpecialAgreement } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrderSpecialAgreementsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createOrderSpecialAgreement(payload: any): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_SPECIAL_AGREEMENT_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/order_special_agreements`, payload)
    );
  }

  readOrderSpecialAgreement(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_SPECIAL_AGREEMENT_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readOrderSpecialAgreements(params): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_SPECIAL_AGREEMENT_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/order_special_agreements', null, params)
            )
    );
  }

  updateOrderSpecialAgreement(
    iri: string,
    payload: OrderSpecialAgreement
  ): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_SPECIAL_AGREEMENT_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteOrderSpecialAgreement(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_ORDER_SPECIAL_AGREEMENT_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
