import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DiscountsActions } from '../actions';
import { DialogOpen } from '../../../application-state/store/actions/dialog.actions';
import { ModalDialogOptions } from '../../../application-state/models';
import { NotifierService } from 'angular-notifier';
import { DiscountsService } from '../../services/discounts.service';

@Injectable()
export class DiscountsEffects {
  createDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountsActions.CreateDiscount),
      map(action => action),
      switchMap(({ payload }) => {
        return this.service.createDiscount(payload).pipe(
          map(response => DiscountsActions.CreateDiscountSuccess({ response })),
          catchError(response =>
            of(DiscountsActions.CreateDiscountFail({ response }))
          )
        );
      })
    )
  );
  getDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountsActions.ReadDiscount),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.getDiscount(iri).pipe(
          map(response => DiscountsActions.ReadDiscountSuccess({ response })),
          catchError(response =>
            of(DiscountsActions.ReadDiscountFail({ response }))
          )
        );
      })
    )
  );
  getDiscounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountsActions.ReadDiscounts),
      map(action => action),
      switchMap(() => {
        return this.service.getDiscounts().pipe(
          map(response => DiscountsActions.ReadDiscountsSuccess({ response })),
          catchError(response =>
            of(DiscountsActions.ReadDiscountsFail({ response }))
          )
        );
      })
    )
  );
  updateDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountsActions.UpdateDiscount),
      map(action => action),
      switchMap(payload => {
        return this.service.updateDiscount(payload).pipe(
          map(response => DiscountsActions.UpdateDiscountSuccess({ response })),
          catchError(response =>
            of(DiscountsActions.UpdateDiscountFail({ response }))
          )
        );
      })
    )
  );
  deleteDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountsActions.DeleteDiscount),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.deleteDiscount(iri).pipe(
          map(() => DiscountsActions.DeleteDiscountSuccess({ iri })),
          catchError(response =>
            of(DiscountsActions.DeleteDiscountFail({ response }))
          )
        );
      })
    )
  );
  discountActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiscountsActions.CreateDiscountSuccess,
        DiscountsActions.UpdateDiscountSuccess
      ),
      map(({ type }) => {
        const text =
          type === DiscountsActions.CREATE_DISCOUNT_SUCCESS
            ? 'Eine neue Rabattstufe wurde angelegt.'
            : 'Die Rabattstufe wurde aktualisiert.';

        const payload: ModalDialogOptions = {
          config: {
            data: {
              heading: 'Das hat geklappt',
              text,
              cancelText: 'OK'
            }
          }
        };
        return DialogOpen({ payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: DiscountsService,
    private notifierService: NotifierService
  ) {}
}
