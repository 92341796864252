<app-dialog-header>
  <h2>Versand vorbereiten</h2>
</app-dialog-header>
<div mat-dialog-content>
  <app-loading-overlay *ngIf="isLoading$ | async"></app-loading-overlay>
  <form [formGroup]="form">
    <div class="row">
      <div [class]="columnClass">
        <app-shipment-direction-select
          formControlName="shipmentDirection"
          [dataMediaState$]="dataMediaState$"
          [order$]="order$"
          [required]="true"
        ></app-shipment-direction-select>
      </div>

      <div [class]="columnClass">
        <app-shipping-provider-select
          formControlName="shippingProvider"
          [direction]="form.get('shipmentDirection').value"
          [required]="true"
        ></app-shipping-provider-select>
      </div>
      <div [class]="columnClass">
        <app-shipping-service-select
          formControlName="shipmentService"
          [provider]="form.get('shippingProvider').value"
        ></app-shipping-service-select>
      </div>

      <div
        class="col-3"
        *ngIf="shippingProviderId === 'SHIPPING_PROVIDER_SHIPPING_LABEL_DHL'"
      >
        <ng-select
          [items]="shipmentAdditionalInsurances$ | async"
          [loading]="shipmentAdditionalInsurancesIsLoading$ | async"
          [clearable]="true"
          [markFirst]="true"
          [searchable]="false"
          bindValue="@id"
          bindLabel="name"
          [placeholder]="'shared.show_shipment_dialog.additional_insurance_placeholder' | translate"
          formControlName="additionalInsurance"
        ></ng-select>
        <mat-error>
          <app-form-error
            [fieldName]="'additionalInsurance'"
            [formGroup]="form"
          ></app-form-error>
        </mat-error>
      </div>
    </div>
    <ng-container *ngIf="dataMediaError$ | async as error">
      <div class="alert alert-danger">{{ error }}</div>
    </ng-container>
    <div class="row" *ngIf="showPickupForms">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Abholung</mat-label>
          <input type="date" matInput formControlName="pickupDate" />

          <mat-error>
            <app-form-error
              [fieldName]="'pickupDate'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <mat-label>Abholung (Uhrzeit von)</mat-label>
          <input
            type="time"
            matInput
            formControlName="pickupTimeEarliest"
            list="pickupTimeEarliestList"
          />
          <datalist id="pickupTimeEarliestList">
            <option *ngFor="let time of times">{{ time.time }}</option>
          </datalist>
          <mat-error>
            <app-form-error
              [fieldName]="'pickupTimeEarliest'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Abholung (Uhrzeit bis)</mat-label>
          <input
            type="time"
            matInput
            formControlName="pickupTimeLatest"
            list="pickupTimeLatestList"
          />
          <datalist id="pickupTimeLatestList">
            <option *ngFor="let time of times">{{ time.time }}</option>
          </datalist>
          <mat-error>
            <app-form-error
              [fieldName]="'pickupTimeLatest'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4 px-2">
      <div class="col" style="background: #f9f9f9;">
        <app-shipping-address groupName="shipFrom" [group]="form">
          <h2 class="heading column-14">Absender</h2>
        </app-shipping-address>
      </div>
      <div class="col" style="background: #f9f9f9;">
        <app-shipping-address groupName="shipTo" [group]="form">
          <h2 class="heading column-14">Empfänger</h2>
        </app-shipping-address>
      </div>
      <div
        class="col"
        style="background: #f9f9f9;"
        *ngIf="form.get('pickupDispatcher').enabled"
      >
        <app-shipping-address groupName="pickupDispatcher" [group]="form">
          <h2 class="heading column-14">Abhol-Ort</h2>
        </app-shipping-address>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Beschreibung</mat-label>
          <textarea
            matInput
            rows="5"
            formControlName="description"
            cdkTextareaAutosize
          ></textarea>
          <mat-error>
            <app-form-error
              [fieldName]="'description'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="form.get('trackerId').enabled" class="col">
        <app-tracker-input
          formControlName="trackerId"
          [touched]="form.get('trackerId').touched"
        ></app-tracker-input>
      </div>
      <div *ngIf="form.get('trackingNumber').enabled" class="col">
        <mat-form-field>
          <mat-label>Tracking Number</mat-label>
          <input matInput formControlName="trackingNumber" />
          <mat-error>
            <app-form-error
              [fieldName]="'trackingNumber'"
              [formGroup]="form"
            ></app-form-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <h1>Datenträger</h1>
      <app-data-media-line
        *ngFor="let dataMedium of dataMedia$ | async"
        [dataMedia]="dataMedium"
        [readonly]="true"
        [selected]="isDataMediumSelected(dataMedium)"
        (toggleSelect)="toggleSelectDataMedium(dataMedium)"
        selectionTyp="single"
      ></app-data-media-line>
      <div
        class="alert alert-warning"
        *ngIf="!((dataMedia$ | async)?.length > 0)"
      >
        Es sind kein Datenträger ausgewählt.
      </div>
    </div>
    <!--        <pre>{{ form.getRawValue() | json }}</pre>-->
    <!--        <pre>{{ formValue | json }}</pre>-->
  </form>
</div>
<div mat-dialog-actions>
  <div class="column-14 m-ta--2 pos-relative">
        <span
          [matTooltip]="
            !form?.valid ? 'Das Formular enthält Fehler. Bitte beheben.' : ''
          "
          matTooltipPosition="above"
          (mouseover)="markAsTouched()"
        >
          <button
            class="btn--submit"
            [disabled]="!form?.valid"
            mat-flat-button
            color="green"
            (click)="createShipment()"
          >
            <mat-icon class="m-r--8">qr_code</mat-icon>
            <span>Paket vorbereiten</span>
          </button>
        </span>
  </div>
</div>
