import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../models';
import {DialogComponent} from '../../../shared/components/dialog/dialog.component';
import {ModalDialogOptions} from '../../../application-state/models';
import {BindingOrdersSelectors, OrdersSelectors, ServiceOrderItemsSelectors} from '../../store/selectors';
import {Order, ServiceOrderItem} from '../../models';
import {BindingOrdersActions, ProductOrderItemsActions, ServiceOrderItemsActions} from '../../store';
import {getUuidFromIri} from '../../../shared/utilities/strings.utility';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {ProductUnitsSelectors} from '../../../master-data/store/selectors';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {ServiceOrderItemsService} from '../../services';
import {OrderItemDialogComponent} from '../order-item-dialog/order-item-dialog.component';

@Component({
  selector: 'app-service-order-items',
  styleUrls: ['service-order-items.component.scss'],
  template: `

    <div class="grid">
      <div class="section-heading column-12">
        <span class="heading--h3">Service hinzufügen</span>
      </div>

      <div class="m-ta--2 column-2">
        <button class="button--add" (click)="handleRequestShowServiceOrderItemForm()" color="green" mat-button>
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div *ngFor="let service of serviceOrderItems$|async; let odd = odd; let even = even"
           class="items-list__row column-14 grid" [ngClass]="{ odd: odd, even: even }">

        <div class="items-list__overview column-6">
          <div class="heading">{{ service.title }}</div>
          <div class="quantity">{{ service.quantity | number:'1.0' }} {{ getUnitName(service.unit) }}</div>
        </div>

        <div class="items-list__details column-6">
          <div class="heading">Details</div>
          <span class="net">
          {{ formatValue(service.netTotal.value) }}
            {{ service.netTotal.currency|currencyShortener }} Netto
        </span>
          <!--<span class="divider">/</span>-->
          <br>

          <span
            class="gross">{{ formatValue(service.grossTotal.value) }} {{ service.grossTotal.currency|currencyShortener }}
            Brutto</span>
        </div>

        <div class="column-2">
          <button class="button--edit" mat-button (click)="openServiceOrderItemDialog(service)">
            <mat-icon color="gray">edit</mat-icon>
          </button>
          <button class="button--delete" mat-button (click)="handleRequestDeleteServiceOrderItem(service)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  `
})
export class ServiceOrderItemsComponent implements OnInit, OnDestroy {

  /*
  * [serviceOrderItems]="data.serviceOrderItems"
            (requestShowServiceOrderItemForm)="handleShowServiceOrderItemForm(serviceOrderItemFormDialog)"
            [unitsEntities]="productUnitsEntities$ | async"
            * */

  @Input() order$: Observable<Order>;
  order: Order;
  isLoading$: Observable<boolean>;
  serviceOrderItems$: Observable<Array<fromModuleModels.ServiceOrderItem>>;
  unitsEntities: { [iri: string]: fromMasterDataModuleModels.ProductUnit };

  @Output()
  requestShowServiceOrderItemForm: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  requestDeleteServiceOrderItem: EventEmitter<fromModuleModels.ServiceOrderItem> =
    new EventEmitter<fromModuleModels.ServiceOrderItem>();

  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private dialog: MatDialog,
              private store$: Store<ApplicationState>,
  ) {
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ProductUnitsSelectors.isLoading),
      this.store$.select(ServiceOrderItemsSelectors.isLoading)
    ]);

    this.order$.pipe(
      takeUntil(this.onDestroy$),
      filter(order => !!order)
    ).subscribe((order) => {
      this.order = order;
      this.store$.dispatch(ServiceOrderItemsActions.ReadServiceOrderItems({orderUuid: getUuidFromIri(order['@id'])}));
      this.serviceOrderItems$ = this.store$.select(ServiceOrderItemsSelectors.selectServiceOrderItemsForOrder, {iri: order['@id']});
    });
    this.store$.select(ProductUnitsSelectors.selectProductUnitsEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.unitsEntities = entities;
      });

  }

  handleRequestShowServiceOrderItemForm(): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: {
        order: this.order,
        type: 'service',
        changeableTypeDisabled: true
      }
    });
  }

  openServiceOrderItemDialog(item: ServiceOrderItem): void {
    this.dialog.open(OrderItemDialogComponent, {
      data: {
        order: this.order,
        item,
        type: 'service',
        changeableTypeDisabled: true
      }
    });
  }

  handleRequestDeleteServiceOrderItem(service: fromModuleModels.ServiceOrderItem): void {

    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Service wirklich entfernt werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, löschen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        this.store$.dispatch(ServiceOrderItemsActions.DeleteServiceOrderItem({iri: service['@id']}));
      });
  }

  getUnitName(unitIri: string): string {
    return this.unitsEntities[unitIri]?.name;
  }

  formatValue(value: string): string {
    return parseFloat(value).toFixed(2).toString().replace('.', ',');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
