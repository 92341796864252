import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-dm-shipment-to-customer-widget',
  styleUrls: ['./open-dm-shipment-to-customer-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header ps-1">
          <span>
            <button mat-icon-button (click)="loadData()">
              <mat-icon>autorenew</mat-icon></button
            >Datenträger zum Kunden</span
          >
        </div>
        <app-orders-list-for-widget
          [states]="states"
          [indicatorStyleFn]="getCircleColor"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenDmShipmentToCustomerWidgetComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  onDestroy$: Subject<any> = new Subject<any>();
  salesperson: string;
  states: Array<string> = ['return_shipment'];

  constructor(private store$: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(profile => {
        this.salesperson = extractIri(profile);
      });
  }

  loadData(): void {
    this.states = [...this.states];
  }

  getCircleColor(order: Order): { backgroundColor: string } {
    return { backgroundColor: '#ffc400' };
  }
}
