import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromOrdersModuleModels from '../../models';
import {AbstractApiResponse} from '../../../shared/models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1

export const READ_ORDER_STATE = '[Orders Module] Read Order State';
export const READ_ORDER_STATE_SUCCESS = '[Orders Module] Read Order State Success';
export const READ_ORDER_STATE_FAIL = '[Orders Module] Read Order State Fail';

export const ReadOrderState = createAction(
  READ_ORDER_STATE,
  props<{ iri: string }>()
);

export const ReadOrderStateSuccess = createAction(
  READ_ORDER_STATE_SUCCESS,
  props<{ response: fromOrdersModuleModels.OrderState }>()
);

export const ReadOrderStateFail = createAction(
  READ_ORDER_STATE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_ORDER_STATES = '[Orders Module] Read Order States';
export const READ_ORDER_STATES_SUCCESS = '[Orders Module] Read Order States Success';
export const READ_ORDER_STATES_FAIL = '[Orders Module] Read Order States Fail';

export const ReadOrderStates = createAction(
  READ_ORDER_STATES
);

export const ReadOrderStatesSuccess = createAction(
  READ_ORDER_STATES_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadOrderStatesFail = createAction(
  READ_ORDER_STATES_FAIL,
  props<{ response: HttpErrorResponse }>()
);
