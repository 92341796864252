import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PotentialAction } from '../../models';
import { DestructionType } from '../../../master-data/models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { DisposalTypesSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../utilities/observable.utility';
import { DisposalTypesActions } from '../../../master-data/store';
import { TransitionsActions } from '../../../application-state/store/actions';
import { OrdersActions } from '../../../orders/store';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-disposal-type-provided',
  styleUrls: ['./action-box-to-disposal-type-provided.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Vernichtung Datenträger?</div>
              <div class="sub-header col-auto">
                Der Kunde hat eine Vernichtung gewünscht.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right" [formGroup]="form">
            <button
              class="decision-btn ms-3 btn-wide float-right"
              mat-button
              color="green"
              (click)="submitForm()"
              [disabled]="form.invalid && !okAction"
            >
              <mat-icon class="me-2">check</mat-icon>
              <span>Weiter</span>
            </button>

            <ng-select
              placeholder="Vernichtungstyp"
              style="width: 400px; display: inline-block; float:right;"
              [items]="disposalTypes$ | async"
              [loading]="disposalTypesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="true"
              [clearable]="false"
              formControlName="disposalType"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'disposalType'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">SS Vernichtung:</div>
          <div class="sub-header col-auto">
            Warte auf Definierung, ob der Datenträger vernichtet werden soll
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToDisposalTypeProvidedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  form: FormGroup;
  okAction: PotentialAction = null;

  disposalTypes$: Observable<Array<DestructionType>>;
  disposalTypesIsLoading$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      this.authService.isTechnician() ||
      this.authService.isAdmin() ||
      this.authService.isSupervisor() ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDisposalTypes();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        this.form.get('order').setValue(this.order['@id']);
        this.form
          .get('disposalType')
          .setValue(this.order.disposalType['@id'] || this.order.disposalType);

        this.okAction = order.potentialActions?.find(
          e =>
            e.transition.indexOf('_to_disposal_type_provided') > -1 && !e.error
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      order: this.fb.control(null, [Validators.required]),
      disposalType: this.fb.control(null, [Validators.required])
    });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const formData = this.form.value;
    this.store$.dispatch(
      OrdersActions.UpdateDisposalType({
        iri: formData.order,
        payload: { disposalType: formData.disposalType }
      })
    );
    this.actions$
      .pipe(
        ofType(OrdersActions.UpdateDisposalTypeSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ response }) => {
        this.order = response;
        this.okAction = response.potentialActions.find(
          e =>
            e.transition.indexOf('_to_disposal_type_provided') > -1 && !e.error
        );

        this.makeTransition();
      });
  }

  private loadDisposalTypes(): void {
    this.disposalTypes$ = this.store$.select(
      DisposalTypesSelectors.selectDisposalTypes
    );
    this.disposalTypesIsLoading$ = this.store$.select(
      DisposalTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DisposalTypesSelectors.isLoaded,
      DisposalTypesActions.ReadDisposalTypes()
    );
  }
}
