import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Create
export const CREATE_GRADE = '[MasterDataModule] Create Grade';
export const CREATE_GRADE_SUCCESS = '[MasterDataModule] Create Grade Success';
export const CREATE_GRADE_FAIL = '[MasterDataModule] Create Grade Fail';
export const CreateGrade = createAction(
  CREATE_GRADE,
  props<{ payload: fromModuleModels.Grade }>()
);
export const CreateGradeSuccess = createAction(
  CREATE_GRADE_SUCCESS,
  props<{ response: fromModuleModels.Grade }>()
);
export const CreateGradeFail = createAction(
  CREATE_GRADE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_GRADES = '[MasterDataModule] Read Grades';
export const READ_GRADES_SUCCESS = '[MasterDataModule] Read Grades Success';
export const READ_GRADES_FAIL = '[MasterDataModule] Read Grades Fail';
export const ReadGrades = createAction(
  READ_GRADES
);
export const ReadGradesSuccess = createAction(
  READ_GRADES_SUCCESS,
  props<{ response: any }>()
);
export const ReadGradesFail = createAction(
  READ_GRADES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_GRADE = '[MasterDataModule] Read Grade';
export const READ_GRADE_SUCCESS = '[MasterDataModule] Read Grade Success';
export const READ_GRADE_FAIL = '[MasterDataModule] Read Grade Fail';
export const ReadGrade = createAction(
  READ_GRADE,
  props<{ iri: string }>()
);
export const ReadGradeSuccess = createAction(
  READ_GRADE_SUCCESS,
  props<{ response }>()
);
export const ReadGradeFail = createAction(
  READ_GRADE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update
export const UPDATE_GRADE = '[MasterDataModule] Update Grade';
export const UPDATE_GRADE_SUCCESS = '[MasterDataModule] Update Grade Success';
export const UPDATE_GRADE_FAIL = '[MasterDataModule] Update Grade Fail';
export const UpdateGrade = createAction(
  UPDATE_GRADE,
  props<{ iri: string, payload: fromModuleModels.Grade }>()
);
export const UpdateGradeSuccess = createAction(
  UPDATE_GRADE_SUCCESS,
  props<{ response: fromModuleModels.Grade }>()
);
export const UpdateGradeFail = createAction(
  UPDATE_GRADE_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Delete
export const DELETE_GRADE = '[MasterDataModule] Delete Grade';
export const DELETE_GRADE_SUCCESS = '[MasterDataModule] Delete Grade Success';
export const DELETE_GRADE_FAIL = '[MasterDataModule] Delete Grade Fail';
export const DeleteGrade = createAction(
  DELETE_GRADE,
  props<{ iri: string }>()
);
export const DeleteGradeSuccess = createAction(
  DELETE_GRADE_SUCCESS,
  props<{ iri: string }>()
);
export const DeleteGradeFail = createAction(
  DELETE_GRADE_FAIL,
  props<{ response: HttpErrorResponse }>()
);
