import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { CorrectionInvoice, InvoiceMail } from '../models';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { ParameterBag } from '../../shared/models/ParameterBag.interface';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CorrectionInvoicesService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCorrectionInvoice(
    payload: CorrectionInvoice
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/correction_invoices`, payload)
    );
  }

  readCorrectionInvoice(iri: string): Observable<CorrectionInvoice> {
    return this.rolesService.userHasRoleFilter<CorrectionInvoice>(
      'ROLE_MWS_CORRECTION_INVOICE_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readCorrectionInvoices(
    page = 1,
    params?: ParameterBag
  ): Observable<Array<any>> {
    return this.rolesService.userHasRoleFilter<Array<any>>(
      'ROLE_MWS_CORRECTION_INVOICE_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams('/correction_invoices', page, params)
            )
    );
  }

  readCorrectionInvoiceAsPdf(invoice: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_VIEW',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getDownloadJson(
              invoice + '/preview?disposition=attachment',
              true
            )
    );
  }

  sendMail(
    iri: string,
    payload: InvoiceMail
  ): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_EMAIL',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri + '/email', payload, true)
    );
  }

  readCorrectionInvoiceChunk(uri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(uri, true)
    );
  }

  bookCorrectionInvoice(iri: string): Observable<AbstractApiResponse> {
    const url = `${iri}/transitions`;
    const payload = { workflow: 'correction_invoice', transition: 'book' };
    return this.apiService.patchObject(url, payload, true);
  }

  bookCorrectionInvoicePayOut(
    iri,
    payload: { payoutAt: string }
  ): Observable<AbstractApiResponse> {
    const url = `${iri}/payout_at`;
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_PAYOUT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.patchObject(url, payload, true)
    );
  }

  updateCorrectionInvoice(
    iri: string,
    payload: CorrectionInvoice
  ): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCorrectionInvoice(iri: string): Observable<AbstractApiResponse> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_CORRECTION_INVOICE_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
