import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import * as fromModuleModels from '../models';
import {ApplicationState} from '../../application-state/store';
import {LeadsActions} from '../store';
import {LeadsSelectors} from '../store/selectors';

@Injectable()
export class LeadLoadedGuard implements CanActivate {

  constructor(private store: Store<ApplicationState>) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(true);
  }

}
