import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import * as fromMasterDataModuleModels from '../../../master-data/models';
import { AnalysisResult } from '../../../master-data/models';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import {
  AnalysisResultCategoriesSelectors,
  AnalysisResultsSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import {
  AnalysisResultCategoriesActions,
  AnalysisResultsActions
} from '../../../master-data/store';
import { QuillEditorComponent } from 'ngx-quill';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import {AbstractApiService} from "../../../shared/services";
import {extractIri, extractUUID} from "../../../shared/utilities/objects.utility";

@Component({
  selector: 'app-analysis-result-form',
  styleUrls: ['analysis-result-form.component.scss'],
  template: `
    <div class="grid">
      <div class="column-7">
        <ng-select
          [items]="analysisResultCategories$ | async"
          [loading]="analysisResultCategoriesIsLoading$ | async"
          [searchable]="false"
          [markFirst]="false"
          (change)="handleUpdateAnalysisResultCategory($event)"
          bindValue="@id"
          placeholder="Vorlagen-Kategorie"
          bindLabel="name"
        ></ng-select>
      </div>
      <div class="column-7">
        <!--<mat-form-field>

                <mat-placeholder>Vorlage anpassen</mat-placeholder>
                <mat-select (valueChange)="handleS">
                  <mat-option *ngFor="let option of analysisResultOptions$.getValue()"></mat-option>
                </mat-select>
              </mat-form-field>-->

        <ng-select
          [items]="analysisResults$ | async"
          [loading]="analysisResultsIsLoading$ | async"
          [searchable]="false"
          [markFirst]="false"
          bindValue="@id"
          (change)="handleSetTemplateText($event)"
          [(ngModel)]="selectedAnalysisResult"
          placeholder="Vorlage auswählen"
          bindLabel="name"
        ></ng-select>
      </div>

      <div class="column-14" [formGroup]="form">
        <app-text-editor
          formControlName="analysisResult"
          label="Analyse Ergebnis"
          placeholder="Analyse-Ergebnis hier eingeben"
        ></app-text-editor>
      </div>

      <div class="text-right mt-5 column-14" *ngIf="!hideSaveBtn">
        <button
          class="btn--submit"
          [disabled]="form.invalid"
          mat-flat-button
          color="green"
          (click)="handleRequestUpdateOrder(order)"
        >
          <mat-icon class="m-r--8">save</mat-icon>
          <span>Speichern</span>
        </button>
      </div>
    </div>
  `
})
export class AnalysisResultFormComponent implements OnInit, OnDestroy {
  @ViewChildren(QuillEditorComponent) editors!: QueryList<QuillEditorComponent>;

  analysisResultCategories$: Observable<
    Array<fromMasterDataModuleModels.AnalysisResultCategory>
  >;
  analysisResultCategoriesIsLoading$: Observable<boolean>;
  analysisResults$: Observable<Array<AnalysisResult>>;
  analysisResultsIsLoading$: Observable<boolean>;

  @Input() order$: Observable<Order>;
  order: Order;
  @Input() hideSaveBtn = false;

  @Output() requestUpdateOrder: EventEmitter<{
    iri: string;
    payload: { analysisResult: string };
  }> = new EventEmitter();

  form: FormGroup;
  selectedAnalysisResult: string = null;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    private apiService: AbstractApiService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadAnalysisResultCategories();
    this.loadAnalysisResults();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (order.analysisResult) {
          this.form.get('analysisResult').setValue(order.analysisResult);
        }
      });
    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        filter(() => this.hideSaveBtn)
      )
      .subscribe(values => {
        const payload = {
          iri: this.order['@id'],
          payload: values
        };
        this.requestUpdateOrder.emit(payload);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm(): void {
    this.form = this.fb.group({
      analysisResult: this.fb.control(this.order?.analysisResult, [
        Validators.required
      ])
    });
  }

  handleUpdateAnalysisResultCategory(option: AnalysisResult): void {
    // console.log(option);
    this.analysisResults$ = this.store$.select(
      AnalysisResultsSelectors.byCategory(option['@id'])
    );
    this.selectedAnalysisResult = null;
  }

  handleSetTemplateText(
    option: fromMasterDataModuleModels.AnalysisResult
  ): void {
    this.apiService
      .getObject(
        `${this.order['@id']}/template?template_uuid=${extractUUID(option['@id'])}`,
        true
      )
      .subscribe((response: any) => {
          this.form.get('analysisResult').setValue(response.content);
        },
        (error) => {
          this.form.get('analysisResult').setValue(option.content);
        });
  }

  handleRequestUpdateOrder(order): void {
    const payload = {
      iri: order['@id'],
      payload: this.form.value
    };
    this.requestUpdateOrder.emit(payload);
  }

  handleTextColorChange(
    color: string,
    propertyName: string,
    editorIndex: number
  ): void {
    const editor = this.editors.toArray()[editorIndex].quillEditor;

    const defaultColor = '#000000';
    const { index, length } = editor.getSelection();
    const { color: currentColor } = editor.getFormat();

    // formatText ensures the correct editor is targeted
    editor.formatText(index, length, {
      color: currentColor === color ? defaultColor : color
    });

    const editorHTML = editor.container.querySelector('.ql-editor').innerHTML;
    this.form.patchValue({ [propertyName]: editorHTML });
  }

  private loadAnalysisResultCategories(): void {
    this.analysisResultCategories$ = this.store$.select(
      AnalysisResultCategoriesSelectors.selectAnalysisResultCategories
    );
    this.analysisResultCategoriesIsLoading$ = this.store$.select(
      AnalysisResultCategoriesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisResultCategoriesSelectors.isLoaded,
      AnalysisResultCategoriesActions.ReadAnalysisResultCategories()
    );
  }

  private loadAnalysisResults(): void {
    this.analysisResultsIsLoading$ = this.store$.select(
      AnalysisResultsSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      AnalysisResultsSelectors.isLoaded,
      AnalysisResultsActions.ReadAnalysisResults()
    );
  }
}
