import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { extractUUID } from '../../../shared/utilities/objects.utility';
import { OrdersService } from '../../services';
import { LabLocation } from '../../../master-data/models';
import { LabLocationsSelectors } from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { LabLocationsActions } from '../../../master-data/store';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';

@Component({
  selector: 'app-open-dm-shipment-to-external-widget',
  styleUrls: ['./open-dm-shipment-to-external-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8" [formGroup]="form">
      <div class="widget card">
        <div
          class="widget_header d-flex align-items-center justify-content-between ps-1"
        >
          <span
            ><button mat-icon-button (click)="loadData()">
              <mat-icon>autorenew</mat-icon></button
            >Datenträger zum Dienstleister</span
          >
          <div class="wrapper-selection">
            <app-loading-overlay
              *ngIf="isLoadingLabs$ | async"
            ></app-loading-overlay>
            <mat-form-field class="dense">
              <mat-select formControlName="labLocation">
                <mat-option
                  *ngFor="let option of labLocations$ | async"
                  [value]="option['@id']"
                >
                  {{ option.name }} ({{ option.ordersAwaitingShipment }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <app-orders-list-for-widget
          [indicatorStyleFn]="getCircleColor"
          [states]="states"
          [customServiceFnName]="customServiceFnName"
          [additionalParams]="additionalParams$ | async"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class OpenDmShipmentToExternalWidgetComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  labLocations$: Observable<Array<LabLocation>>;
  isLoadingLabs$: Observable<boolean>;
  customServiceFnName = 'readAwaitingShipmentOrders';
  analysisPriorityModeEntities: { [key: string]: any } = {};
  additionalParams$: BehaviorSubject<any> = new BehaviorSubject<any>({
    'awaitingShipmentDirection.id': 'SHIPMENT_DIRECTION_DR_DE_TO_DR_EXT',
    'awaitingShipmentLabLocation.id': 'LAB_LOCATION_DR_EXT_DHE'
  });

  states: Array<string> = [
    //'prepare_analysis_dr_ext'
    // 'analysis_single_shipping_dr_ext',
    // 'analysis_bundled_shipping_dr_ext'
  ];

  form: FormGroup;
  subscription: any;
  protected readonly extractUUID = extractUUID;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private backendService: OrdersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    // this.states = ['prepare_analysis_dr_ext'];
    this.loadData();

    this.labLocations$ = this.store$
      .select(LabLocationsSelectors.sList)
      .pipe(map(c => c.filter(e => e.external)));
    this.isLoadingLabs$ = this.store$.select(LabLocationsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  getCircleColor(order: Order): { [key: string]: string } {
    return { backgroundColor: '#ffc400' };
  }

  loadData(): void {
    this.states = [...this.states];
  }

  private initForm(): void {
    this.form = this.fb.group({
      labLocation: this.fb.control('/api/lab_locations/LAB_LOCATION_DR_EXT_DHE')
    });
    this.form
      .get('labLocation')
      .valueChanges.pipe(
        takeUntil(this.onDestroy$),
        map(e => extractUUID(e))
      )
      .subscribe(dataRecoveryLocation => {
        this.additionalParams$.next({
          ...this.additionalParams$.getValue(),
          'awaitingShipmentLabLocation.id': dataRecoveryLocation
        });
      });
  }
}
