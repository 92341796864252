import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class BindingOrdersService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createBindingOrder(payload: any): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(`/binding_orders`, payload)
    );
  }

  createBindingOrderEntity(payload: {
    file: File;
    uuid?: string;
    order;
    createdAt?: string;
  }): Observable<any> {
    // Manually create FormDate for proper file processing
    const formData: FormData = new FormData();
    formData.append('file', payload.file, payload.file.name);
    // Note: Image resource is replaced if uuid is set
    if (payload.uuid) {
      formData.append('uuid', payload.uuid);
    }
    if (payload.createdAt) {
      formData.append('createdAt', payload.createdAt);
    }
    if (payload.order) {
      formData.append('order', payload.order);
    }

    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_BINDING_ORDER_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.postFile(`/binding_orders`, formData)
    );
  }

  readBindingOrder(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_BINDING_ORDER_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readBindingOrders(): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_BINDING_ORDER_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject('/binding_orders')
    );
  }

  deleteBindingOrder(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_BINDING_ORDER_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
