import {createSelector} from '@ngrx/store';

import {CUSTOMER_ACCOUNTS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerAccountsState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_ACCOUNTS_FEATURE_KEY]
);

export const selectCustomerAccountEntities = createSelector(
  selectCustomerAccountsState,
  (state) => state.entities
);

export const selectCustomerAccountByIndex = createSelector(
  selectCustomerAccountEntities,
  (contacts, index: string) => contacts[index]
);

export const selectCustomerAccounts = createSelector(
  selectCustomerAccountEntities,
  (contacts) => Object.keys(contacts).map(iri => contacts[iri])
);

export const selectCustomerAccountsByCustomerIri = createSelector(
  selectCustomerAccounts,
  (accounts, {iri}) => accounts.filter(account => account.customer == iri)
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoading = createSelector(
  selectCustomerAccountsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectCustomerAccountsState,
  state => state.loaded
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomerAccountsState,
  state => state.errors
);
