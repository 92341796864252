import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {AbstractApiResponse} from '../../../shared/models';
import * as fromModuleModels from '../../models';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY = '[Shipping Module] Read Shipping Provider to Data Recovery';
export const READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY_SUCCESS = '[Shipping Module] Read Shipping Provider to Data Recovery Success';
export const READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY_FAIL = '[Shipping Module] Read Shipping Provider to Data Recovery Fail';
export const ReadShippingProviderToDataRecovery = createAction(
  READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY,
  props<{ iri: string }>()
);
export const ReadShippingProviderToDataRecoverySuccess = createAction(
  READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY_SUCCESS,
  props<{ response: fromModuleModels.ShippingProvider }>()
);
export const ReadShippingProviderToDataRecoveryFail = createAction(
  READ_SHIPPING_PROVIDER_TO_DATA_RECOVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY = '[Shipping Module] Read Shipping Providers to Data Recovery';
export const READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY_SUCCESS = '[Shipping Module] Read Shipping Providers to Data Recovery Success';
export const READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FAIL = '[Shipping Module] Read Shipping Providers to Data Recovery Fail';
export const ReadShippingProvidersToDataRecovery = createAction(
  READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY
);
export const ReadShippingProvidersToDataRecoverySuccess = createAction(
  READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadShippingProvidersToDataRecoveryFail = createAction(
  READ_SHIPPING_PROVIDERS_TO_DATA_RECOVERY_FAIL,
  props<{ response: HttpErrorResponse }>()
);

// Customers ///////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_SHIPPING_PROVIDER_TO_CUSTOMER = '[Shipping Module] Read Shipping Provider to Customer';
export const READ_SHIPPING_PROVIDER_TO_CUSTOMER_SUCCESS = '[Shipping Module] Read Shipping Provider to Customer Success';
export const READ_SHIPPING_PROVIDER_TO_CUSTOMER_FAIL = '[Shipping Module] Read Shipping Provider to Customer Fail';
export const ReadShippingProviderToCustomer = createAction(
  READ_SHIPPING_PROVIDER_TO_CUSTOMER,
  props<{ iri: string }>()
);
export const ReadShippingProviderToCustomerSuccess = createAction(
  READ_SHIPPING_PROVIDER_TO_CUSTOMER_SUCCESS,
  props<{ response: fromModuleModels.ShippingProvider }>()
);
export const ReadShippingProviderToCustomerFail = createAction(
  READ_SHIPPING_PROVIDER_TO_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_SHIPPING_PROVIDERS_TO_CUSTOMER = '[Shipping Module] Read Shipping Providers to Customer';
export const READ_SHIPPING_PROVIDERS_TO_CUSTOMER_SUCCESS = '[Shipping Module] Read Shipping Providers to Customer Success';
export const READ_SHIPPING_PROVIDERS_TO_CUSTOMER_FAIL = '[Shipping Module] Read Shipping Providers to Customer Fail';
export const ReadShippingProvidersToCustomer = createAction(
  READ_SHIPPING_PROVIDERS_TO_CUSTOMER
);
export const ReadShippingProvidersToCustomerSuccess = createAction(
  READ_SHIPPING_PROVIDERS_TO_CUSTOMER_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);
export const ReadShippingProvidersToCustomerFail = createAction(
  READ_SHIPPING_PROVIDERS_TO_CUSTOMER_FAIL,
  props<{ response: HttpErrorResponse }>()
);
