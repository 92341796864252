import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {loadIfNotLoaded} from '../../utilities/observable.utility';
import {extractIri} from '../../utilities/objects.utility';
import {StorageSystemManufacturer} from '../../../master-data/models';
import {StorageSystemManufacturersSelectors} from '../../../master-data/store/selectors';
import {StorageSystemManufacturersActions} from '../../../master-data/store';

@Component({
  selector: 'app-storage-system-manufacturer-select',
  styleUrls: ['./storage-system-manufacturer-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StorageSystemManufacturerSelectComponent),
    multi: true
  }],

  template: `
    <div [formGroup]="form" class="mat-form-field">

      <ng-select
        [class.required]="required"
        formControlName="selectedElement"
        [items]="items$|async"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        appendTo="body"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        [required]="required"
        placeholder="Hersteller">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error fieldName="selectedElement" [formGroup]="form"></app-form-error>
      </mat-error>

    </div>
  `

})
export class StorageSystemManufacturerSelectComponent extends BaseOnDestroyComponent implements OnInit, OnDestroy, ControlValueAccessor {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<StorageSystemManufacturer> = new EventEmitter<StorageSystemManufacturer>();
  @Input() required = false;
  @Input() readonly = false;

  items$: Observable<Array<StorageSystemManufacturer>>;
  isLoading$: Observable<boolean>;
  selectedItem: StorageSystemManufacturer;
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
    super();

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });

    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });

    this.items$ = this.store$.select(StorageSystemManufacturersSelectors.sOrderedList);
    this.isLoading$ = this.store$.select(StorageSystemManufacturersSelectors.isLoading);
    loadIfNotLoaded(this.store$, StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers());
  }

  setSelectedItem(element: StorageSystemManufacturer): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem = (term: string, item: StorageSystemManufacturer): boolean => {
    const vm = this;
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }


}
