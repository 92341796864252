import {
  Component,
  EventEmitter,
  forwardRef,
  OnChanges,
  OnInit,
  Output,
  Input,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BaseOnDestroyComponent} from '../../injectables/BaseOnDestroy.component';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {loadIfNotLoaded} from '../../utilities/observable.utility';
import {extractIri} from '../../utilities/objects.utility';
import {ShippingProvider} from '../../../shipping/models';
import {
  ShippingProvidersToCustomerSelectors,
  ShippingProvidersToDataRecoverySelectors
} from '../../../shipping/store/selectors';
import {ShippingProvidersActions} from '../../../shipping/store';

@Component({
  selector: 'app-shipping-provider-select',
  styleUrls: ['./shipping-provider-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ShippingProviderSelectComponent),
    multi: true
  }],
  template: `
    <div [formGroup]="form">

      <ng-select
        formControlName="selectedElement"
        [items]="items$|async"
        [class.required]="required"
        [required]="required"
        bindLabel="name"
        [searchable]="true"
        [loading]="isLoading$| async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        placeholder="Versand Dienstleister">

        <ng-template ng-label-tmp let-item="item">
          {{item.name}}

        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
      <mat-error>
        <app-form-error fieldName="selectedElement" [formGroup]="form"></app-form-error>
      </mat-error>

    </div>
  `

})
export class ShippingProviderSelectComponent extends BaseOnDestroyComponent implements OnInit, ControlValueAccessor, OnChanges {

  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<ShippingProvider> = new EventEmitter<ShippingProvider>();
  @Input() direction: string;
  @Input() required = false;

  items$: Observable<Array<ShippingProvider>>;
  isLoading$: Observable<boolean>;
  selectedItem: ShippingProvider;

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null),
    });
    this.form.get('selectedElement').valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe(value => {
      this.onChange(value);
    });
    this.updateDirection();
    this.updateRequired();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.direction) {
      this.updateDirection();
    }
    if (changes.required) {
      this.updateRequired();
    }

  }

  setSelectedItem(element: ShippingProvider): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: ShippingProvider): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return (item.name.toLowerCase().indexOf(t.toLowerCase()) > -1);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }

  private updateDirection(): void {
    console.log(this.direction);
    if (this.direction?.indexOf('TO_CUSTOMER') > -1) {
      this.form?.get('selectedElement')?.enable();
      this.items$ = this.store$.select(ShippingProvidersToCustomerSelectors.selectShippingProvidersToCustomer);
      this.isLoading$ = this.store$.select(ShippingProvidersToCustomerSelectors.isLoading);
      loadIfNotLoaded(this.store$, ShippingProvidersToCustomerSelectors.isLoaded,
        ShippingProvidersActions.ReadShippingProvidersToCustomer());
    } else if (this.direction?.indexOf('TO_DR') > -1) {
      // TO DR
      this.form?.get('selectedElement')?.enable();
      this.items$ = this.store$.select(ShippingProvidersToDataRecoverySelectors.selectShippingProvidersToDataRecovery);
      this.isLoading$ = this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading);
      loadIfNotLoaded(this.store$, ShippingProvidersToDataRecoverySelectors.isLoaded,
        ShippingProvidersActions.ReadShippingProvidersToDataRecovery());
    } else {
      this.form?.get('selectedElement')?.disable();
    }
  }

  private updateRequired(): void {
    if (this.required && this.form?.get('selectedElement')) {
      this.form.get('selectedElement').setValidators([Validators.required]);
      this.form.updateValueAndValidity();
    }
  }

}
