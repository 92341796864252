import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import {DataRecoveryCostsActions, OrderCommentsActions} from '../../../orders/store';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataRecoveryCosts, Order, OrderComment} from '../../../orders/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {DataRecoveryCostsSelectors} from '../../../orders/store/selectors';
import {LabLocationsSelectors} from '../../../master-data/store/selectors';
import {LabLocation} from '../../../master-data/models';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {LabLocationsActions} from '../../../master-data/store';
import {TicketCostsFormComponent} from '../ticket-costs-form/ticket-costs-form.component';

@Component({
  selector: 'app-ticket-costs-form-dialog',
  styleUrls: ['./ticket-costs-form-dialog.component.scss'],
  template: `
    <app-dialog-header>
      <h2>Kosten Datenrettung {{data.create ? 'festlegen' : 'bearbeiten'}}</h2>

    </app-dialog-header>
    <div mat-dialog-content>

      <ng-container *ngFor="let costs of dataRecoveryCosts">
        <app-ticket-costs-form
          [labLocationsEntities]="labLocationsEntities$|async"
          [order]="order"
          [dataRecoveryCosts]="costs"
          (requestUpdatePurchasePrices)="handleUpdatePurchasePrices($event)"
          (requestUpdateSellingPrices)="handleUpdateSellingPrices($event)"
        ></app-ticket-costs-form>
      </ng-container>
    </div>
  `
})
export class TicketCostsFormDialogComponent implements OnInit, OnDestroy {
  @ViewChild(TicketCostsFormComponent) costs!: TicketCostsFormComponent;

  onDestroy$: Subject<any> = new Subject<any>();
  order: Order;
  dataRecoveryCosts: Array<DataRecoveryCosts> = [];
  labLocationsEntities$: Observable<{ [p: string]: LabLocation }>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                order$: BehaviorSubject<Order>,
                create: boolean
              },
              public dialogRef: MatDialogRef<TicketCostsFormDialogComponent>,
              private store$: Store<ApplicationState>,
              private actions$: Actions) {
  }

  ngOnInit(): void {
    if (this.data.create) {
      this.dataRecoveryCosts.push({
        purchasePriceInitial: {value: '0.00', currency: 'EUR'},
        sellingPriceInitial: {value: '0.00', currency: 'EUR'},
        purchasePriceDataRecovery: {value: '0.00', currency: 'EUR'},
        sellingPriceDataRecovery: {value: '0.00', currency: 'EUR'},
        purchasePriceOther: {value: '0.00', currency: 'EUR'},
        sellingPriceOther: {value: '0.00', currency: 'EUR'},
        sellingPriceComment: '',
        purchasePriceComment: '',
      });
    }
    this.actions$.pipe(
      ofType(DataRecoveryCostsActions.UpdatePurchasePricesFromTicketSuccess, DataRecoveryCostsActions.UpdateSellingPricesFromTicketSuccess),
      takeUntil(this.onDestroy$)
    )
      .subscribe(() => {
        setTimeout(() => {
          this.dialogRef.close();
        }, 300);
      });
    this.data.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe((order) => {
        this.order = order;
        if (this.data.create) {
          const cost = this.dataRecoveryCosts[0];
          cost.order = order['@id'];
          cost.analysisLocation = order.analysisLocation && order.analysisLocation['@id'] ?
            order.analysisLocation['@id'] : order.analysisLocation;
          return;
        }
        this.store$.select(DataRecoveryCostsSelectors.sByOrder(order['@id']))
          .pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(),
            filter(costs => !!costs)
          )
          .subscribe(costs => {
            this.dataRecoveryCosts = costs;
          });
      });
    this.loadLabLocations();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleUpdatePurchasePrices(payload: { iri: string, payload: DataRecoveryCosts }): void {
    // this.currentOrderIri = order;
    this.store$.dispatch(DataRecoveryCostsActions.UpdatePurchasePricesFromTicket(payload));
  }

  handleUpdateSellingPrices(payload: { iri: string, payload: DataRecoveryCosts }): void {
    // this.currentOrderIri = order;
    this.store$.dispatch(DataRecoveryCostsActions.UpdateSellingPricesFromTicket(payload));
  }


  private loadLabLocations(): void {
    this.labLocationsEntities$ = this.store$.select(LabLocationsSelectors.sEntities);
    loadIfNotLoaded(this.store$, LabLocationsSelectors.isLoaded, LabLocationsActions.ReadLabLocations());
  }
}
