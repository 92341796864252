import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Customer} from '../../../customers/models';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {SearchAddonDataService} from '../../services/search-addon-data.service';
import {removeEmptyFormElements} from '../../../shared/utilities/forms.utility';
import {loadIfNotLoaded} from '../../../shared/utilities/observable.utility';
import {CustomersActions} from '../../../customers/store';
import {CustomersSelectors} from '../../../customers/store/selectors';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-brokers-filter',
  styleUrls: ['./brokers-filter.component.scss'],
  template: `
    <div class="form">
      <div class="row">
        <div class="col-9">
          <div class="row">
            <div class="col-3">
              <app-customer-select
                label="Vermittler"
                (selectCustomer)="updateCustomer($event)"
              ></app-customer-select>

            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class BrokersFilterComponent implements OnInit, OnDestroy {
  @Output() updateFilter: EventEmitter<{
    [key: string]: string;
  }> = new EventEmitter<{ [key: string]: string }>();

  onDestroy$: Subject<any> = new Subject<any>();
  storedFilters?: any;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private searchAddonDataService: SearchAddonDataService,
    public localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    if(this.storedFilters) {
      this.updateFilter.emit(this.storedFilters);
      // this.form.patchValue(this.storedFilters);
    }
  }

  updateCustomer(event: Customer): void {
    this.updateFilter.emit({broker: event ? event['@id'] : null});

    this.localStorageService.overwriteObjectByKey('filters', 'broker_invoices', {broker: event ? event['@id'] : null});

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
