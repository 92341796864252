import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationState } from '../../../application-state/store';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { AdministratorFeedbackRequestsSelectors } from '../../store/selectors';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { filter, takeUntil, switchMap, tap } from 'rxjs/operators';
import { extractIri } from '../../../shared/utilities/objects.utility';
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {MatTabChangeEvent} from "@angular/material/tabs/tab-group";
import {TranslateService} from "@ngx-translate/core";
import {AdministratorFeedbackRequestsActions} from "../../store";
import {selectRecievedFeedbackRequests} from "../../store/selectors/feedback-requests.selectors";
import {FeedbackRequest} from "../../models";

@Component({
  selector: 'app-feedback-requests-sent-widget',
  templateUrl: './feedback-requests-widget.component.html',
  styleUrls: ['./feedback-requests-widget.component.scss']
})

export class FeedbackRequestsWidgetComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  onDestroy$: Subject<void> = new Subject<void>();
  profile: string;
  selectedValue: Array<any> = [];
  selectedTab: string;
  feedbacks$: Observable<FeedbackRequest[]>;
  options = [
    { value: 'resolved', label: 'Beantwortet' },
    { value: 'answered', label: 'Erledigt' }
  ];

  constructor(private store$: Store<ApplicationState>) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(AdministratorFeedbackRequestsSelectors.isLoading);
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(profile => !!profile && !!profile['@id']),
        tap(profile => this.profile = extractIri(profile)),
        switchMap(() => this.store$.select(AdministratorFeedbackRequestsSelectors.selectSendFeedbackRequests))
      ).subscribe();

    this.feedbacks$ = this.store$.select(AdministratorFeedbackRequestsSelectors.selectSendFeedbackRequests);
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.selectedValue = event.value;
  }

  onTabFocusChange(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  readonly AdministratorFeedbackRequestsActions = AdministratorFeedbackRequestsActions;
}
