import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as fromModuleServices from '../../services';
import {CustomerAccountsActions} from '../actions/';
import {DialogOpen} from '../../../application-state/store/actions/dialog.actions';
import {ModalDialogOptions} from '../../../application-state/models';

@Injectable()
export class CustomerAccountsEffects {

  constructor(private actions$: Actions, private cas: fromModuleServices.CustomerAccountsService) {
  }


  CreateCustomerAccount$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.CreateCustomerAccount),
    map(action => action),
    switchMap(({payload}) => {
      return this.cas.createCustomerAccount(payload).pipe(
        map((response: any) => CustomerAccountsActions.CreateCustomerAccountSuccess({response})),
        catchError(response => of(CustomerAccountsActions.CreateCustomerAccountFail({response})))
      );
    })
  ));


  ReadCustomerAccount$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.ReadCustomerAccount),
    map(action => action),
    switchMap(({iri}) => {
      return this.cas.readCustomerAccount(iri).pipe(
        map((response: any) => CustomerAccountsActions.ReadCustomerAccountSuccess({response})),
        catchError(response => of(CustomerAccountsActions.ReadCustomerAccountFail({response})))
      );
    })
  ));


  ReadCustomerAccountProfile$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.ReadCustomerAccountProfile),
    map(action => action),
    switchMap(({iri}) => {
      return this.cas.readCustomerAccountProfile(iri).pipe(
        map(response => CustomerAccountsActions.ReadCustomerAccountProfileSuccess({response})),
        catchError(response => of(CustomerAccountsActions.ReadCustomerAccountProfileFail({response})))
      );
    })
  ));


  ReadCustomerAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.ReadCustomerAccounts),
    map(action => action),
    switchMap(({page}) => {

      return this.cas.readCustomerAccounts(page).pipe(
        map((response: any) => CustomerAccountsActions.ReadCustomerAccountsSuccess({response})),
        catchError(response => of(CustomerAccountsActions.ReadCustomerAccountsFail({response})))
      );
    })
  ));


  UpdateCustomerAccount$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.UpdateCustomerAccount),
    map(action => action),
    switchMap(({iri, payload}) => {

      return this.cas.updateCustomerAccount(iri, payload).pipe(
        map(response => CustomerAccountsActions.UpdateCustomerAccountSuccess({response})),
        catchError(response => of(CustomerAccountsActions.UpdateCustomerAccountFail({response})))
      );
    })
  ));


  UpdateCustomerAccountProfile$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.UpdateCustomerAccountProfile),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.cas.updateCustomerAccountProfile(iri, payload).pipe(
        map(response => CustomerAccountsActions.UpdateCustomerAccountProfileSuccess({response})),
        catchError(response => of(CustomerAccountsActions.UpdateCustomerAccountProfileFail({response})))
      );
    })
  ));


  UpdateCustomerAccountPassword$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.UpdateCustomerAccountPassword),
    map(action => action),
    switchMap(({iri, payload}) => {
      return this.cas.updateCustomerAccountPassword(iri, payload).pipe(
        map(response => CustomerAccountsActions.UpdateCustomerAccountPasswordSuccess({response})),
        catchError(response => of(CustomerAccountsActions.UpdateCustomerAccountPasswordFail({response})))
      );
    })
  ));


  DeleteCustomerAccount$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerAccountsActions.DeleteCustomerAccount),
    switchMap(({iri}) => {

      return this.cas.deleteCustomerAccount(iri).pipe(
        map(() => CustomerAccountsActions.DeleteCustomerAccountSuccess({iri})),
        catchError(response => of(CustomerAccountsActions.DeleteCustomerAccountFail({response})))
      );
    })
  ));


  ActionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerAccountsActions.CreateCustomerAccountSuccess,
      CustomerAccountsActions.UpdateCustomerAccountSuccess
    ),
    map(({type}) => {

      const text = type === CustomerAccountsActions.CREATE_CUSTOMER_ACCOUNT_SUCCESS
        ? `Eine neuer Kundenzugang wurde angelegt.`
        : 'Der Kundenzugang wurde aktualisiert.';

      const payload: ModalDialogOptions = {
        config: {
          data: {
            heading: 'Das hat geklappt',
            text,
            cancelText: 'OK',
          }
        }
      };
      return DialogOpen({payload});
    })
  ));
}
