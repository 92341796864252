import {createSelector} from '@ngrx/store';

import {CUSTOMER_INVOICES_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectCustomerInvoicesState = createSelector(
  selectCustomersModuleState,
  (state) => state[CUSTOMER_INVOICES_FEATURE_KEY]
);

export const selectCustomerInvoiceEntities = createSelector(
  selectCustomerInvoicesState,
  (state) => state.entities
);

export const selectCustomerInvoices = createSelector(
  selectCustomerInvoiceEntities,
  (invoices) => Object.keys(invoices).map(iri => invoices[iri])
);

export const selectCustomerInvoicesByCustomerIri = createSelector(
  selectCustomerInvoices,
  (invoices, {customerIri}) => invoices.filter(contact => contact.customer === customerIri)
);

//////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const isLoaded = createSelector(
  selectCustomerInvoicesState,
  state => state.loaded
);

export const isLoading = createSelector(
  selectCustomerInvoicesState,
  state => state.loading
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////// Errors

export const selectErrors = createSelector(
  selectCustomerInvoicesState,
  (state) => state.errors
);
