import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import {
  DestructionType,
  StorageSystemManufacturer
} from '../../../master-data/models';
import { Order, ProofOfDestruction } from '../../models';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FormsService } from '../../../shared/services';
import { CustomerDataMedium } from '../../../warehouse/models';
import { getUuidFromIri } from '../../../shared/utilities/strings.utility';
import * as OrdersModuleActions from '../../store';
import { ProofsOfDestructionActions } from '../../store';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { DataMediumsSelectors } from '../../../warehouse/store/selectors';
import { DataMediumsActions } from '../../../warehouse/store';
import {
  DestructionTypesSelectors,
  StorageSystemManufacturersSelectors
} from '../../../master-data/store/selectors';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import {
  DestructionTypesActions,
  StorageSystemManufacturersActions
} from '../../../master-data/store';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { PotentialAction } from '../../../shared/models';
import { environment } from '../../../../environments/environment';
import { TransitionsActions } from '../../../application-state/store/actions';
import { extractTypeByIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-proof-of-destruction-form',
  styleUrls: ['pod-form.component.scss'],
  template: `
    <form autocomplete="off" [formGroup]="podf">
      <div class="grid">
        <div class="column-6 grid">
          <div class="mat-form-field column-14">
            <ng-select
              placeholder="Art der Vernichtung"
              [items]="destructionTypes$ | async"
              [loading]="destructionTypesIsLoading$ | async"
              bindValue="@id"
              bindLabel="name"
              [markFirst]="false"
              [clearable]="false"
              formControlName="destructionType"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'destructionType'"
                [formGroup]="podf"
              ></app-form-error>
            </mat-error>
          </div>

          <div class="column-14">
            <mat-form-field>
              <mat-label>Datum</mat-label>
              <input type="date" matInput formControlName="date" required />
              <mat-error>
                <app-form-error
                  [fieldName]="'date'"
                  [formGroup]="podf"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="column-8 grid">
          <div class="column-14">
            <mat-form-field>
              <mat-label>Beschreibung</mat-label>
              <textarea
                matInput
                formControlName="comment"
                cdkTextareaAutosize
                rows="6"
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field>
            <mat-error>
              <app-form-error
                [fieldName]="'comment'"
                [formGroup]="podf"
              ></app-form-error>
            </mat-error>
          </div>
        </div>

        <div class="column-14 grid">
          <div class="table ">
            <div class="thead column-14 grid grid-no-gutter">
              <div class="th column-2">Einl-Nr.</div>
              <div class="th column-2">Seriennummer</div>
              <div class="th column-2">Typ</div>
              <div class="th column-2">Hersteller</div>
              <div class="th column-1">Speichergröße</div>
              <div class="th column-5">Status</div>
            </div>

            <mat-selection-list formControlName="customerDataMedia">
              <mat-list-option *ngIf="customerDataMediaIsLoading$ | async">
                <app-loading-overlay></app-loading-overlay>
              </mat-list-option>
              <mat-list-option
                *ngFor="let medium of customerDataMedia$ | async"
                checkboxPosition="after"
                [disableRipple]="true"
                [value]="medium['@id']"
              >
                <ng-template-outlet
                  [ngTemplateOutlet]="dataMediumRow"
                  [ngTemplateOutletContext]="{ medium: medium }"
                ></ng-template-outlet>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div class="column-14 text-right">
          <button
            class="mx-2"
            mat-button
            mat-dialog-close
            *ngIf="!inline"
            color="outline"
          >
            <mat-icon>close</mat-icon>
            <span>Abbrechen und schließen</span>
          </button>

          <button
            class="me-2"
            [disabled]="podf.invalid || !podf.dirty"
            mat-flat-button
            color="green"
            (click)="handleSubmit()"
          >
            <mat-icon class="m-r--8">{{
              isEdit ? 'update' : 'description'
            }}</mat-icon>
            <span>Nachweis {{ isEdit ? 'speichern' : 'erstellen' }}</span>
          </button>
          <button
            [disabled]="podf.dirty"
            mat-flat-button
            color="green"
            (click)="handleSubmitAction()"
          >
            <mat-icon class="m-r--8">{{ 'done' }}</mat-icon>
            <span>Nachweis finalisieren</span>
          </button>
        </div>
      </div>
      <!--<pre>{{possibleAction|json}}</pre>-->
    </form>

    <ng-template #dataMediumRow let-medium="medium">
      <div class="grid grid-no-gutter tr">
        <div class="td column-2">{{ medium.storageNumber }}</div>
        <div class="td column-2">{{ medium.storageSystemSerialNumber }}</div>
        <div class="td column-2">{{ medium?.type ? medium.type : '-' }}</div>
        <div class="td column-2">
          {{
            getStorageSystemManufacturerName(medium.storageSystemManufacturer)
          }}
        </div>
        <div class="td column-1">{{ medium.storageSystemSize }} GB</div>
        <div class="td column-5">{{ medium.state }}</div>
      </div>
    </ng-template>

    <!--<pre>{{ dataMedia | json }}</pre>-->
    <!--<pre>{{ podf.value | json }}</pre>-->
  `
})
export class PodFormComponent implements OnInit, OnDestroy {
  @Input() inline = false;
  @Input() order$: Observable<Order>;
  @Input() proofOfDestruction$: Observable<ProofOfDestruction>;

  customerDataMedia$: Observable<Array<CustomerDataMedium>>;
  customerDataMediaIsLoading$: Observable<boolean>;
  destructionTypes$: Observable<Array<DestructionType>>;
  destructionTypesIsLoading$: Observable<boolean>;
  storageSystemManufacturersEntities: {
    [iri: string]: StorageSystemManufacturer;
  };

  isEdit = false;
  orderPODIri: string = null;

  onDestroy$: Subject<any> = new Subject();

  podf: FormGroup;
  possibleAction: PotentialAction | undefined;

  constructor(
    private fb: FormBuilder,
    private fs: FormsService,
    private store$: Store<ApplicationState>,
    private actions$: Actions
  ) {}

  getStorageSystemManufacturerName(manufacturer: string): string {
    return this.storageSystemManufacturersEntities[manufacturer]?.name;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadDestructionTypes();
    this.loadStorageSystemManufacturersEntities();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.orderPODIri = order.proofOfDestruction;
        this.loadCustomerDataMedium(order);
        this.setAction(order);
      });

    this.proofOfDestruction$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(pod => !!pod)
      )
      .subscribe(pod => {
        this.isEdit = true;
        const values = {
          ...pod,
          date: moment(pod.date)
            .toISOString(true)
            .substring(0, 10)
        };
        this.podf.patchValue(values);
        this.podf.markAsUntouched();
      });
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          ProofsOfDestructionActions.CreateProofOfDestructionFail,
          ProofsOfDestructionActions.UpdateProofOfDestructionFail
        )
      )
      .subscribe(errors => {
        this.fs.mergeErrorsIntoForm(errors, this.podf);
      });
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          ProofsOfDestructionActions.UpdateProofOfDestructionSuccess,
          ProofsOfDestructionActions.CreateProofOfDestructionSuccess
        )
      )
      .subscribe(errors => {
        this.podf.markAsPristine();
      });
  }

  initForm(): void {
    this.podf = this.fb.group({
      destructionType: this.fb.control(null, [Validators.required]),
      date: this.fb.control(
        moment()
          .toISOString(true)
          .substring(0, 10),
        [Validators.required]
      ),
      customerDataMedia: this.fb.control([], Validators.required),
      comment: this.fb.control('')
    });
  }

  handleSubmitAction(): void {
    if (!this.possibleAction) {
      return;
    }
    const {
      target: { url },
      workflow,
      transition: targetTransition
    } = this.possibleAction;
    const uri = url.replace(environment.baseUrl, '').split('?')[0];
    const payload = { workflow, transition: targetTransition };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  handleSubmit(): void {
    if (this.isEdit) {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.UpdateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.podf.value
          }
        )
      );
    } else {
      this.store$.dispatch(
        OrdersModuleActions.ProofsOfDestructionActions.CreateProofOfDestruction(
          {
            iri: this.orderPODIri,
            payload: this.podf.value
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private loadCustomerDataMedium(order): void {
    const orderUUID = getUuidFromIri(order['@id']);
    this.customerDataMedia$ = this.store$
      .select(DataMediumsSelectors.selectDataMediumsByOrderId, {
        orderIri: order['@id']
      })
      .pipe(
        map(e => e.filter(f => extractTypeByIri(f) === 'customer_data_media'))
      );
    this.customerDataMediaIsLoading$ = this.store$.select(
      DataMediumsSelectors.isLoading
    );
    this.customerDataMedia$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(list => list.length > 0)
      )
      .subscribe(mediaList => {
        if (this.podf.get('customerDataMedia').value.length <= 0) {
          this.podf
            .get('customerDataMedia')
            .setValue(mediaList.map(e => e['@id']));
        }
      });
    this.store$.dispatch(
      DataMediumsActions.ReadDataMediums({
        page: -1,
        params: { 'stockItem.order.uuid': orderUUID }
      })
    );
  }

  private loadDestructionTypes(): void {
    this.destructionTypes$ = this.store$.select(
      DestructionTypesSelectors.selectDestructionTypes
    );
    this.destructionTypesIsLoading$ = this.store$.select(
      DestructionTypesSelectors.isLoading
    );
    loadIfNotLoaded(
      this.store$,
      DestructionTypesSelectors.isLoaded,
      DestructionTypesActions.ReadDestructionTypes()
    );
  }

  private loadStorageSystemManufacturersEntities(): void {
    this.store$
      .select(StorageSystemManufacturersSelectors.sEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.storageSystemManufacturersEntities = entities;
      });
    loadIfNotLoaded(
      this.store$,
      StorageSystemManufacturersSelectors.isLoaded,
      StorageSystemManufacturersActions.ReadStorageSystemManufacturers()
    );
  }

  private setAction(order: Order): void {
    const actions = order.potentialActions?.filter(
      action => action.workflow === 'order_state' && !action.error
    );
    if (actions.length === 1) {
      this.possibleAction = actions[0];
    }
  }
}
