import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {FeedbackRequestsActions} from '../actions';
import {FeedbackRequestsService} from "../../services/feedback-requests.service";

@Injectable()
export class FeedbackRequestsEffects {

  constructor(private actions$: Actions, private frs: FeedbackRequestsService, private router: Router) {
  }


  loadFeedbackRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FeedbackRequestsActions.LoadFeedbackRequest),
    map(action => action),
    switchMap(({iri}) => {

      return this.frs.loadFeedbackRequest(iri)
        .pipe(
          map((response: any) => FeedbackRequestsActions.LoadFeedbackRequestSuccess({response})),
          catchError(response => of(FeedbackRequestsActions.LoadFeedbackRequestFail({response})))
        );
    })
  ));


  loadFeedbackRequests$ = createEffect(() => this.actions$.pipe(
    ofType(FeedbackRequestsActions.LoadFeedbackRequests),
    map(action => action),
    switchMap(() => {

      return this.frs.loadFeedbackRequests()
        .pipe(
          map((response: any) => FeedbackRequestsActions.LoadFeedbackRequestsSuccess({response})),
          catchError(error => of(FeedbackRequestsActions.LoadFeedbackRequestsFail(error)))
        );
    })
  ));
  loadSendFeedbackRequests$ = createEffect(() => this.actions$.pipe(
    ofType(FeedbackRequestsActions.LoadSendFeedbackRequests),
    map(action => action),
    switchMap(() => {

      return this.frs.loadSendFeedbackRequests()
        .pipe(
          map((response: any) => FeedbackRequestsActions.LoadSendFeedbackRequestsSuccess({response})),
          catchError(error => of(FeedbackRequestsActions.LoadSendFeedbackRequestsFail(error)))
        );
    })
  ));


  updateFeedbackRequests$ = createEffect(() => this.actions$.pipe(
    ofType(FeedbackRequestsActions.UpdateFeedbackRequest),
    map(action => action),
    switchMap((payload: { iri: string, feedback: string }) => {

      return this.frs.updateFeedbackRequest(payload)
        .pipe(
          map((response: any) => FeedbackRequestsActions.UpdateFeedbackRequestSuccess({response})),
          catchError(error => of(FeedbackRequestsActions.UpdateFeedbackRequestFail(error)))
        );
    })
  ));
}
