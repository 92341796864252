import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EmailTemplateContextsActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { EmailTemplateContextsService } from '../../services/email-template-context.service';

@Injectable()
export class EmailTemplateContextsEffects {
  actionCollection = EmailTemplateContextsActions;
  resourceNameDE = 'E-Mail Template Context';
  getOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.Read),
      map(action => action),
      switchMap(({ iri }) => {
        return this.service.readOne(iri).pipe(
          map(response => this.actionCollection.ReadSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadFail({ response }))
          )
        );
      })
    )
  );
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.actionCollection.ReadList),
      map(action => action),
      switchMap(() => {
        return this.service.readList().pipe(
          map(response => this.actionCollection.ReadListSuccess({ response })),
          catchError(response =>
            of(this.actionCollection.ReadListFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          this.actionCollection.ReadListFail,
          this.actionCollection.ReadFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei ' + type + ': ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: EmailTemplateContextsService,
    private notifierService: NotifierService
  ) {}
}
