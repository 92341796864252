import { CustomerAccountInvitationsLoadedGuard } from './customer-account-invitations-loaded.guard';
import { CustomerAccountsLoadedGuard } from './customer-accounts-loaded.guard';
import { CustomerCommentTagsLoadedGuard } from './customer-comment-tags-loaded.guard';
import { CustomerCommentsLoadedGuard } from './customer-comments-loaded.guard';
import { CustomerContactTypesLoadedGuard } from './customer-contact-types-loaded.guard';
import { CustomerLoadedGuard } from './customer-loaded.guard';
import { CustomerPartnerStatusesLoadedGuard } from './customer-partner-statuses-loaded.guard';
import { CustomerTypesLoadedGuard } from './customer-types-loaded.guard';
import { CustomersLoadedGuard  } from './customers-loaded.guard';
import { PartnerWebsiteInvitationsLoadedGuard } from './partner-website-invitations-loaded.guard';
import { PartnerWebsitesLoadedGuard } from './partner-websites-loaded.guard';
import { PartnersLoadedGuard } from './partners-loaded.guard';

export const AllGuards = [
  CustomerAccountInvitationsLoadedGuard,
  CustomerAccountsLoadedGuard,
  CustomerCommentTagsLoadedGuard,
  CustomerCommentsLoadedGuard,
  CustomerContactTypesLoadedGuard,
  CustomerLoadedGuard,
  CustomerPartnerStatusesLoadedGuard,
  CustomerTypesLoadedGuard,
  CustomersLoadedGuard,
  PartnerWebsiteInvitationsLoadedGuard,
  PartnerWebsitesLoadedGuard,
  PartnersLoadedGuard,
];

export * from './customer-account-invitations-loaded.guard';
export * from './customer-accounts-loaded.guard';
export * from './customer-comment-tags-loaded.guard';
export * from './customer-comments-loaded.guard';
export * from './customer-contact-types-loaded.guard';
export * from './customer-loaded.guard';
export * from './customer-partner-statuses-loaded.guard';
export * from './customer-types-loaded.guard';
export * from './customers-loaded.guard';
export * from './partner-website-invitations-loaded.guard';
export * from './partner-websites-loaded.guard';
export * from './partners-loaded.guard';
