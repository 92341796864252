import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ApplicationState } from '../../../application-state/store';
import {
  DataRecoveryResultsSelectors,
  OrdersSelectors
} from '../../store/selectors';
import { TransitionsActions } from '../../../application-state/store/actions';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { ModalDialogOptions } from '../../../application-state/models';
import { DataRecoveryResultsActions, OrdersActions } from '../../store';
import { DataRecoveryResult, Order } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import { NotifierService } from 'angular-notifier';
import { CustomerContact } from '../../../customers/models';
import { CustomerContactsSelectors } from '../../../customers/store/selectors';
import { OrderAnalysisResultDialogComponent } from '../../components/order-analysis-result-dialog/order-analysis-result-dialog.component';
import { OrderDataMediaLogComponent } from '../../components/order-data-media-log/order-data-media-log.component';
import { WriteEMailDialogComponent } from '../../../shared/components/write-email-dialog/write-email-dialog.component';
import {
  getCompanyNameFromOrder,
  getCustomerNameFromOrder
} from '../../helpers';
import { WarehouseRights } from '../../../shared/security/warehouseRights';
import { OrderTransitionLogDialogComponent } from '../../components/order-transition-log-dialog/order-transition-log-dialog.component';
import { AuthService } from '../../../auth/services/auth.service';
import {
  CreateServiceOrderItemSuccess,
  UpdateServiceOrderItemSuccess
} from '../../store/actions/service-order-items.actions';
import {
  CreateGenericOrderItemSuccess,
  UpdateGenericOrderItemSuccess
} from '../../store/actions/generic-order-items.actions';
import {
  CreateProductOrderItemSuccess,
  UpdateProductOrderItemSuccess
} from '../../store/actions/product-order-items.actions';
import {AbstractApiService} from "../../../shared/services";
import {Go} from "../../../application-state/store/actions/router.actions";

@Component({
  selector: 'app-order-view',
  templateUrl: 'order-view.component.html',
  styleUrls: ['order-view.component.scss']
})
export class OrderViewComponent implements OnInit, OnDestroy {
  // needed
  dataRecoveryResult$: Observable<DataRecoveryResult>;
  order$: BehaviorSubject<Order> = new BehaviorSubject(null);
  order: Order;
  converted = true;
  orderId: string = null;
  customerContactEntities: { [key: string]: CustomerContact } = {};
  onDestroy$: Subject<any> = new Subject<any>();
  getCustomerName = getCustomerNameFromOrder;
  // }
  getCompanyName = getCompanyNameFromOrder;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private title: AbstractTitleService,
    private store$: Store<ApplicationState>,
    private warehouseRight: WarehouseRights,
    private authService: AuthService,
    private apiService: AbstractApiService,
    private actions$: Actions,
    private notifierService: NotifierService
  ) {}

  get isTechnicianExternal(): boolean {
    return this.authService.isTechnicianExternal();
  }

  get showUTMButton(): boolean {
    return !!this.order && this.order?.createdBy?.includes('partner_websites');
  }

  get canViewWarehouse(): Observable<boolean> {
    return this.warehouseRight.canList$;
  }

  get canViewTransitionLog(): boolean {
    return (
      this.authService.isAdmin() ||
      this.authService.isAccounting() ||
      this.authService.isLogistic() ||
      this.authService.isPartnerManagement() ||
      this.authService.isSupervisor() ||
      this.authService.isTechnician() ||
      this.authService.isSales() ||
      this.authService.isSalesExternal()
    );
  }

  get canBeCanceled(): boolean {
    return !!this.order?.potentialActions?.find(
      action =>
        action.workflow === 'order_state' &&
        action.transition.indexOf('to_no_order') > -1
    );
  }

  ngOnInit(): void {
    this.actions$
      .pipe(
        takeUntil(this.onDestroy$),
        ofType(
          UpdateServiceOrderItemSuccess,
          UpdateGenericOrderItemSuccess,
          UpdateProductOrderItemSuccess,
          CreateServiceOrderItemSuccess,
          CreateGenericOrderItemSuccess,
          CreateProductOrderItemSuccess
        )
      )
      .subscribe(() => {
        this.loadOrder();
      });
    this.actions$
      .pipe(takeUntil(this.onDestroy$), ofType(OrdersActions.ReadOrderFail))
      .subscribe(error => {
        if (error.response.status === 401) {
          this.notifierService.show({
            type: 'error',
            message:
              'Diese Ressource steht nur eingeloggten Nutzern zur verfügung.'
          });
        } else if (error.response.status === 404) {
          this.notifierService.show({
            type: 'error',
            message: 'Ausgewählter Auftrag existiert leider nicht.'
          });
        } else {
          this.notifierService.show({
            type: 'error',
            message:
              'Fehler beim abrufen des Auftrags:' + error?.response?.message
          });
        }
      });
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(params => {
        this.orderId = params.get('uuid');
        if (this.orderId) {
          this.title.setTitle('Auftrag bearbeiten');
          this.loadOrder();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  // getCompanyName(element: Order): string {
  //   if (!element) {
  //     return '';
  //   }
  //
  //   if (element?.customer && element.customer.customerType['@id'] === '/api/customer_types/1') {
  //     // Privatkunde
  //     return null;
  //   } else {
  //     // Geschäftskunde
  //     return element?.customer?.nameLine1;
  //   }
  // }

  isVip(element: Order): boolean {
    return element?.customer?.vip;
  }

  loadOrder(): void {
    this.store$.dispatch(OrdersActions.ReadOrder({ id: this.orderId }));
    this.store$
      .select(OrdersSelectors.sById, { id: this.orderId })
      .subscribe(order => {
        if (!order) {
          return;
        }
        this.order$.next(order);
        this.order = order;
        this.title.setTitle('Auftrag #' + order.orderNumber);

        const dataRecoveryResultIri = order?.dataRecoveryResults?.length
          ? order.dataRecoveryResults.slice(-1)[0]
          : null;
        // needed
        if (dataRecoveryResultIri && this.dataRecoveryResult$ === undefined) {
          this.dataRecoveryResult$ = this.store$.select(
            DataRecoveryResultsSelectors.selectDataRecoveryResultByIri,
            { iri: dataRecoveryResultIri }
          );
          this.store$.dispatch(
            DataRecoveryResultsActions.ReadDataRecoveryResult({
              iri: dataRecoveryResultIri
            })
          );
        }
      });
    this.store$
      .select(CustomerContactsSelectors.selectCustomerContactEntities)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(entities => {
        this.customerContactEntities = entities;
      });
  }

  handleShowTrackingHistoryDialog(): void {
    this.dialog.open(OrderDataMediaLogComponent, {
      panelClass: 'width-95',
      data: { order$: this.order$ }
    });
  }

  transferOrderToLead(): void {
    this.converted = false
    this.apiService
      .patchObject(
        `${this.order['@id']}/lead_transformation`,
        this.order,
        true
      )
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        lead => {
          this.converted = true;
          this.store$.dispatch(Go({ path: ['leads'] }));
        },
        () => {
          this.converted = true;
        }
    )
  }

  handleCancelOrder(): void {
    if (!this.order || !this.canBeCanceled) {
      return;
    }
    const settings: ModalDialogOptions = {
      config: {
        disableClose: false,
        data: {
          text: 'Soll dieser Auftrag abgebrochen werden?',
          heading: 'Bist du sicher?',
          confirmationText: 'Ja, abbrechen',
          cancelText: 'Abbrechen'
        }
      }
    };

    this.dialog
      .open(DialogComponent, settings.config)
      .afterClosed()
      .pipe(
        takeUntil(this.onDestroy$),
        filter(hasConfirmedModal => hasConfirmedModal)
      )
      .subscribe(() => {
        const targetTransition = this.order.potentialActions?.find(
          action =>
            action.workflow === 'order_state' &&
            action.transition.indexOf('to_no_order') > -1
        );
        if (!targetTransition) {
          return;
        }
        const uri = `${this.order['@id']}/transitions?workflow=${targetTransition.workflow}&transition=${targetTransition.transition}`;
        const payload = {
          workflow: targetTransition.workflow,
          transition: targetTransition.transition
        };
        this.store$.dispatch(
          TransitionsActions.MakeTransition({ uri, payload })
        );
      });
  }

  openDialog(ref, config = {}): void {
    this.dialog.open(ref, config);
  }

  showPartnerLabel(order: Order | null): boolean {
    if (!order) {
      return false;
    }
    return !!order.broker;
  }

  showBrokeredToPartnerLabel(order: Order | null): boolean {
    return order?.brokeredToPartner;
  }

  openEmailDialog(): void {
    this.dialog.open(WriteEMailDialogComponent, {
      data: { type: 'order', entity$: this.order$ },
      width: '90%',
      minWidth: '400px'
    });
  }

  showUTMDetails(ref: TemplateRef<any>): void {
    this.dialog.open(ref);
  }

  openTransitionLog(): void {
    this.dialog.open(OrderTransitionLogDialogComponent, {
      data: { order$: this.order$ },
      width: '90%',
      minWidth: '400px'
    });
  }
}
