import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { ShipmentsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.Shipment };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(
    ShipmentsActions.CreateShipment,
    ShipmentsActions.ReadShipment,
    ShipmentsActions.ReadShipments,
    ShipmentsActions.UpdateShipment,
    ShipmentsActions.CancelShipment,
    ShipmentsActions.ConfirmShipment,
    ShipmentsActions.DeleteShipment,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(ShipmentsActions.ConfirmShipmentSuccess, (state, { response }) => {
    // Note: API does not return a full shipment object after its state has changed. This is a workaround
    // to update shipment list view immediately; else action buttons won't be updated.

    const entities = {
      ...state.entities,
      [response['@id']]: {
        ...state.entities[response['@id']],
        // this is horrible ...
        state: 'bestätigt'
      }
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(ShipmentsActions.CancelShipmentSuccess, (state, { response }) => {
    // Note: API does not return a full shipment object after its state has changed. This is a workaround
    // to update shipment list view immediately; else action buttons won't be updated.

    const entities = {
      ...state.entities,
      [response['@id']]: {
        ...state.entities[response['@id']],
        // this is horrible ...
        state: 'storniert'
      }
    };

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(
    ShipmentsActions.CreateShipmentSuccess,
    ShipmentsActions.UpdateShipmentSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loaded: false,
        loading: false
      };
    }
  ),
  on(ShipmentsActions.ReadShipmentsSuccess, (state, { response }) => {
    const items = response['hydra:member'];
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.Shipment }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      { ...state.entities }
    );

    return {
      ...state,
      entities,
      loading: false,
      loaded: true
    };
  }),
  on(ShipmentsActions.DeleteShipmentSuccess, (state, { iri }) => {
    const { [iri]: removedItem, ...entities }: any = state.entities;

    return {
      ...state,
      entities,
      loading: false
    };
  }),
  on(
    ShipmentsActions.CreateShipmentFail,
    ShipmentsActions.UpdateShipmentFail,
    ShipmentsActions.ConfirmShipmentFail,
    ShipmentsActions.CancelShipmentFail,
    ShipmentsActions.DeleteShipmentFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
