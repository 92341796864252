import { AuthEffects } from './auth.effects';
import { PasswordsEffects } from './passwords.effects';
import { UserProfilesEffects } from './user-profiles.effects';

export const effects: Array<any> = [
  AuthEffects,
  PasswordsEffects,
  UserProfilesEffects,
];

export * from './auth.effects';
export * from './passwords.effects';
export * from './user-profiles.effects';
