import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import * as fromModuleModels from '../../models';

export const LOAD_ADMINISTRATOR_GROUPS = '[Administrators] Load Administrator Groups';
export const LOAD_ADMINISTRATOR_GROUPS_SUCCESS = '[Administrators] Load Administrator Groups Success';
export const LOAD_ADMINISTRATOR_GROUPS_FAIL = '[Administrators] Load Administrator Groups Fail';

// Read

export const LoadAdministratorGroups = createAction(
  LOAD_ADMINISTRATOR_GROUPS
);

export const LoadAdministratorGroupsSuccess = createAction(
  LOAD_ADMINISTRATOR_GROUPS_SUCCESS,
  props<{ response: Array<fromModuleModels.AdministratorGroup> }>()
);

export const LoadAdministratorGroupsFail = createAction(
  LOAD_ADMINISTRATOR_GROUPS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
