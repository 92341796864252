import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Order } from '../../../orders/models';
import * as fromWarehouseModuleModels from '../../models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { FormsService } from '../../../shared/services';
import {
  CustomerReplacementStockItemsActions,
  CustomerStockItemsActions,
  ReplacementStockItemsActions
} from '../../store';
import { takeUntil } from 'rxjs/operators';
import { sortByCreatedAtDate } from '../../../shared/utilities/array.utility';
import { OrdersSelectors } from '../../../orders/store/selectors';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';

@Component({
  selector: 'app-customer-stock-item-form',
  styleUrls: ['./customer-stock-item-form.component.scss'],
  template: `
    <ng-container [formGroup]="form">
      <div class="row pt-4 fake-bg--sllg">
        <div class="col-12">
          <div class="mat-form-field">
            <ng-select
              [items]="sortElements(orders$ | async)"
              placeholder="Kunde / Auftrag"
              bindLabel="orderNumber"
              bindValue="@id"
              class="white"
              [searchFn]="findOrder"
              formControlName="order"
              [clearable]="false"
              [markFirst]="false"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <span class="me-2">#{{ item.orderNumber }}</span>
                <span *ngIf="item.customer.vip" class="badge--vip mx-2">
                  <mat-icon>check</mat-icon>
                  <span>VIP</span>
                </span>
                <span *ngIf="item?.customer?.nameLine1" class="mx-1">{{
                  item?.customer?.nameLine1
                }}</span>
                <span *ngIf="item?.customer?.nameLine2">{{
                  item?.customer?.nameLine2
                }}</span>
              </ng-template>
              <ng-template
                ng-label-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <span class="me-2">#{{ item.orderNumber }}</span>
                <span *ngIf="item.customer.vip" class="badge--vip mx-2">
                  <mat-icon>check</mat-icon>
                  <span>VIP</span>
                </span>
                <span *ngIf="item?.customer?.nameLine1" class="mx-1">{{
                  item?.customer?.nameLine1
                }}</span>
                <span *ngIf="item?.customer?.nameLine2">{{
                  item?.customer?.nameLine2
                }}</span>
              </ng-template>
            </ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'order'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>

      <div class="row fake-bg--sllg">
        <div class="col-12">
          <div class=" mat-form-field">
            <ng-select
              [items]="storageSystems$ | async"
              placeholder="Typ"
              class="white"
              bindLabel="name"
              bindValue="@id"
              formControlName="storageSystemType"
              [clearable]="false"
              [markFirst]="false"
            ></ng-select>
            <mat-error>
              <app-form-error
                [fieldName]="'storageSystemType'"
                [formGroup]="form"
              ></app-form-error>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row pt-5">
        <div class=" col-8">
          <h2>Gehäuse</h2>
        </div>
        <div class="col-4 text-right">
          <mat-slide-toggle
            class=""
            [checked]="!!form.get('enclosure')"
            (toggleChange)="handleToggleCasing()"
          >
            Gehäuse enthalten
          </mat-slide-toggle>
        </div>
      </div>
      <ng-container formGroupName="enclosure" *ngIf="!!form.get('enclosure')">
        <div class="row fake-bg--sllg pt-2">
          <div class="col-4">
            <div class="mat-form-field ">
              <ng-select
                class="white"
                [items]="storageSystemManufacturers$ | async"
                placeholder="Hersteller*"
                bindLabel="name"
                bindValue="@id"
                formControlName="storageSystemManufacturer"
                [clearable]="false"
                [searchable]="false"
                [markFirst]="false"
              ></ng-select>
              <mat-error>
                <app-form-error
                  [fieldName]="'storageSystemManufacturer'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </div>
          </div>
          <div class="col-4">
            <mat-form-field class="white">
              <mat-label>Modell</mat-label>
              <input
                matInput
                type="text"
                formControlName="storageSystemModel"
                required
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemModel'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="white ">
              <mat-label>Speichergröße</mat-label>
              <input
                matInput
                type="number"
                formControlName="storageSystemSize"
                min="1"
                required
              />
              <span matSuffix>GB</span>
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemSize'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row fake-bg--sllg">
          <div class="col-6">
            <mat-form-field class="white ">
              <mat-label>Seriennummer</mat-label>
              <input
                matInput
                type="text"
                formControlName="storageSystemSerialNumber"
                required
              />
              <mat-error>
                <app-form-error
                  [fieldName]="'enclosure.storageSystemSerialNumber'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="white column-7">
              <mat-label>Einlagerungs-Nr.*</mat-label>
              <input
                matInput
                type="text"
                value="Wird automatisch ausgefüllt"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <div class="row pt-5">
        <div class=" col-8">
          <h2>
            {{
              getDataMediumsControlControls().length > 0
                ? 'Datenträger' + getDataMediumsControlControls().length
                : 'Keine Datenträger'
            }}
          </h2>
        </div>
        <div class="col-4 text-right">
          <button (click)="handleAddDataMedium()" mat-button>
            <mat-icon>add</mat-icon>
            <span>Datenträger hinzufügen</span>
          </button>
        </div>
      </div>

      <ng-container
        formArrayName="dataMediums"
        *ngIf="getDataMediumsControlControls().length"
      >
        <ng-container
          *ngFor="
            let dataMedium of getDataMediumsControlControls();
            let i = index
          "
          [formGroupName]="i"
        >
          <div class="row fake-bg--sllg">
            <div class="col-1">
              <h2>
                <span *ngIf="getDataMediumsControlControls().length > 1">{{
                  i + 1
                }}</span>
              </h2>
            </div>

            <div class="m-ta--2 column-5">
              <ng-container *ngIf="getDataMediumsControlControls().length > 1">
                <button mat-icon-button (click)="handleRemoveDataMedium(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
            </div>
          </div>

          <div class="row fake-bg--sllg">
            <div class="col-4">
              <div class="mat-form-field">
                <ng-select
                  class="white"
                  [items]="storageSystemManufacturers$ | async"
                  placeholder="Hersteller*"
                  bindLabel="name"
                  bindValue="@id"
                  formControlName="storageSystemManufacturer"
                  [clearable]="false"
                  [markFirst]="false"
                ></ng-select>
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemManufacturer'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>

                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]['storageSystemManufacturer']">{{ errors?.dataMediums[i]['storageSystemManufacturer'].message }}</mat-hint>-->
              </div>
            </div>
            <div class="col-4">
              <mat-form-field class="white">
                <mat-label>Modell</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="storageSystemModel"
                />
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemModel">{{ errors?.dataMediums[i]?.storageSystemModel?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemModel'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-4">
              <mat-form-field class="white ">
                <mat-label>Speichergröße</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="storageSystemSize"
                  min="1"
                  required
                />
                <span matSuffix>GB</span>
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemSize">{{ errors?.dataMediums[i]?.storageSystemSize?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemSize'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row fake-bg--sllg">
            <div class="col-6">
              <mat-form-field class="white">
                <mat-label>Seriennummer</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="storageSystemSerialNumber"
                  required
                />
                <!--<mat-hint align="start" *ngIf="errors?.dataMediums[i]?.storageSystemSerialNumber">{{ errors?.dataMediums[i]?.storageSystemSerialNumber?.message }}</mat-hint>-->
                <mat-error>
                  <app-form-error
                    [fieldName]="'storageSystemSerialNumber'"
                    [formGroup]="getDataMediumsControlControls()[i]"
                  ></app-form-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="white column-7">
                <mat-label>Einlagerungs-Nr.*</mat-label>
                <input
                  matInput
                  type="text"
                  value="Wird automatisch ausgefüllt"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="button--add-medium m-ta--2 column-14 fake-bg--sllg">
        <button (click)="handleAddDataMedium()" mat-button>
          <mat-icon>add</mat-icon>
          <span>Datenträger hinzufügen</span>
        </button>
      </div>

      <mat-form-field class="m-t--24 column-14">
        <mat-label>Bemerkung Lager-Eintrag</mat-label>
        <textarea
          formControlName="description"
          matInput
          matTextareaAutosize
          rows="6"
        ></textarea>
        <mat-error>
          <app-form-error
            [fieldName]="'description'"
            [formGroup]="form"
          ></app-form-error>
        </mat-error>
      </mat-form-field>

      <!-- TODO: Remove when using ReplacementStockItem --><!--*ngIf="!isReplacementMedium"-->
      <div class="column-14">
        <div class="mat-form-field checkbox">
          <mat-checkbox formControlName="dataMediaOkLogisticsDepartment"
            >Datenträger ist OK
          </mat-checkbox>
        </div>
      </div>
    </ng-container>
  `
})
export class CustomerStockItemFormComponent implements OnInit, OnDestroy {
  orders$: Observable<Array<Order>>;
  @Input() presets$: BehaviorSubject<fromWarehouseModuleModels.StockItem | any>;
  @Input() products$: Observable<Array<fromMasterDataModuleModels.Product>>;
  @Input() storageSystemManufacturers$: Observable<
    Array<fromMasterDataModuleModels.StorageSystemManufacturer>
  >;
  @Input() storageSystems$: Observable<
    Array<fromMasterDataModuleModels.StorageSystem>
  >;

  @Output() requestCreateStockItem: EventEmitter<{
    type: string;
    payload: fromWarehouseModuleModels.StockItem;
  }> = new EventEmitter();
  storageType: { value: string; label: string };
  form: FormGroup;
  storageLocations$: Observable<
    Array<fromMasterDataModuleModels.StorageLocation>
  >;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private store$: Store<ApplicationState>,
    public actions$: Actions,
    private formService: FormsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.orders$ = this.store$.select(OrdersSelectors.sList);

    this.actions$
      .pipe(
        ofType(
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail,
          CustomerStockItemsActions.CreateCustomerStockItemFail,
          ReplacementStockItemsActions.CreateReplacementStockItemFail
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(fail => {
        console.log(fail);
        if (fail?.response?.error?.violations) {
          this.formService.mergeViolationsIntoForm(
            fail.response.error.violations,
            this.form
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getDataMediumsControlControls(): any {
    return (this.getDataMediumsControl() as FormArray).controls;
  }

  getDataMediumsControl(): any {
    return this.form.get('dataMediums');
  }

  initForm(): void {
    this.form = this.fb.group({
      dataMediaOkLogisticsDepartment: this.fb.control(true),
      // dataMediaOkTechnicalDepartment: this.fb.control(true), // later
      dataMediums: this.fb.array([]),
      description: this.fb.control(''),
      order: this.fb.control(null, [Validators.required]),
      storageSystemType: this.fb.control(null, [Validators.required])
    });
  }

  handleToggleCasing(): void {
    if (!!this.form.get('enclosure')) {
      this.form.removeControl('enclosure');
    } else {
      this.form.setControl('enclosure', this.createDataMediumForm());
    }
  }

  handleAddDataMedium(): void {
    this.getDataMediumsControl().push(this.createDataMediumForm());
  }

  handleRemoveDataMedium(index: string | number): void {
    this.getDataMediumsControl().removeAt(index as number);
  }

  createDataMediumForm(): FormGroup {
    return this.fb.group({
      storageSystemManufacturer: this.fb.control(null, [Validators.required]),
      storageSystemModel: this.fb.control('', [Validators.required]),
      storageSystemSize: this.fb.control(null, [
        Validators.required,
        Validators.min(1)
      ]),
      storageSystemSerialNumber: this.fb.control(null, [Validators.required])
    });
  }

  buildReplacementStockItemForm(): FormGroup {
    return this.fb.group({
      dataMediums: this.fb.array([]),
      product: this.fb.control(null),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(null)
    });
  }

  sortElements(array: Array<any>): Array<any> {
    if (!array) {
      return [];
    }
    return array.sort(sortByCreatedAtDate);
  }

  findOrder(term: string, item: Order): boolean {
    return (
      item.orderNumber === parseInt(term, 10) ||
      item.customer?.nameLine1?.toLowerCase().indexOf(term.toLowerCase()) >
        -1 ||
      item.customer?.nameLine2?.toLowerCase().indexOf(term.toLowerCase()) > -1
    );
  }
}
