import { CustomerDataMediumsService } from './customer-data-mediums.service';
import { CustomerReplacementStockItemsService } from './customer-replacement-stock-items.service';
import { CustomerStockItemsService } from './customer-stock-items.service';
import { DataMediumsService } from './data-mediums.service';
import { EnclosureDataMediumsService } from './enclosure-data-mediums.service';
import { ReplacementDataMediumsService } from './replacement-data-mediums.service';
import { ReplacementStockItemsService } from './replacement-stock-items.service';
import { StockItemsService } from './stock-items.service';

export const AllServices = [
  CustomerDataMediumsService,
  CustomerReplacementStockItemsService,
  CustomerStockItemsService,
  DataMediumsService,
  EnclosureDataMediumsService,
  ReplacementDataMediumsService,
  ReplacementStockItemsService,
  StockItemsService
];

export * from './customer-data-mediums.service';
export * from './customer-replacement-stock-items.service';
export * from './customer-stock-items.service';
export * from './data-mediums.service';
export * from './enclosure-data-mediums.service';
export * from './replacement-data-mediums.service';
export * from './replacement-stock-items.service';
export * from './stock-items.service';
