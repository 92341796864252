import {createSelector} from '@ngrx/store';

import {USER_FEATURE_KEY, selectAuthFeature} from '../reducers';

export const selectUserState = createSelector(
  selectAuthFeature,
  (state) => state[USER_FEATURE_KEY]
);

export const selectIsAuthenticated = createSelector(
  selectUserState,
  (user) => user.isAuthenticated
);

export const isLoaded = createSelector(
  selectUserState,
  (state) => state.loaded
);

export const isLoading = createSelector(
  selectUserState,
  (state) => state.loading
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Error States

export const selectErrors = createSelector(
  selectUserState,
  (state) => state.errors
);
