import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-dialog-header',
  styleUrls: ['dialog-header.component.scss'],
  template: `

    <div class="dialog__header grid grid-no-gutter">
      <div class="{{disableCloseBtn?'columns-14':'column-12'}}">
        <ng-content></ng-content>
      </div>
      <div class="column-2 m-ta--2" *ngIf="!disableCloseBtn">
        <button mat-icon-button mat-dialog-close (click)="requestCloseDialog.emit()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  `
})
export class DialogHeaderComponent {
  @Input() disableCloseBtn = false;
  @Output() requestCloseDialog: EventEmitter<void> = new EventEmitter();
}
