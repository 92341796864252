import * as CustomerAccountInvitationsSelectors from './customer-account-invitations.selectors';
import * as CustomerAccountsSelectors from './customer-accounts.selectors';
import * as CustomerAddressesSelectors from './customer-addresses.selectors';
import * as CustomerCommentTagsSelectors from './customer-comment-tags.selectors';
import * as CustomerCommentsSelectors from './customer-comments.selectors';
import * as CustomerContactTypesSelectors from './customer-contact-types.selectors';
import * as CustomerContactsSelectors from './customer-contacts.selectors';
import * as CustomerInvoicesSelectors from './customer-invoices.selectors';
import * as CustomerOffersSelectors from './customer-offers.selectors';
import * as CustomerOrdersSelectors from './customer-orders.selectors';
import * as CustomerPartnerStatusesSelectors from './customer-partner-statuses.selectors';
import * as CustomerTypesSelectors from './customer-types.selectors';
import * as CustomersSelectors from './customers.selectors';
import * as PartnerWebsiteInvitationsSelectors from './partner-website-invitations.selectors';
import * as PartnerWebsitesSelectors from './partner-websites.selectors';
import * as PartnersSelectors from './partners.selectors';

export {
  CustomerAccountInvitationsSelectors,
  CustomerAccountsSelectors,
  CustomerAddressesSelectors,
  CustomerCommentTagsSelectors,
  CustomerCommentsSelectors,
  CustomerContactTypesSelectors,
  CustomerContactsSelectors,
  CustomerInvoicesSelectors,
  CustomerOffersSelectors,
  CustomerOrdersSelectors,
  CustomerPartnerStatusesSelectors,
  CustomerTypesSelectors,
  CustomersSelectors,
  PartnerWebsiteInvitationsSelectors,
  PartnerWebsitesSelectors,
  PartnersSelectors,
};
