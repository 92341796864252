import {createSelector} from '@ngrx/store';

import {PARTNER_WEBSITE_INVITATIONS_FEATURE_KEY, selectCustomersModuleState} from '../reducers';

export const selectPartnerWebsiteInvitationsState = createSelector(
  selectCustomersModuleState,
  (state) => state[PARTNER_WEBSITE_INVITATIONS_FEATURE_KEY]
);

export const selectPartnerWebsiteInvitationEntities = createSelector(
  selectPartnerWebsiteInvitationsState,
  (state) => state.entities
);

export const selectPartnerWebsiteInvitationByIndex = createSelector(
  selectPartnerWebsiteInvitationEntities,
  (contacts, index: string) => contacts[index]
);

export const selectPartnerWebsiteInvitations = createSelector(
  selectPartnerWebsiteInvitationEntities,
  (contacts) => Object.keys(contacts).map(iri => contacts[iri])
);

export const selectPartnerWebsiteInvitationsByCustomerIri = createSelector(
  selectPartnerWebsiteInvitations,
  (invitations, props) => invitations.filter(invitation => invitation.customer == props.iri)
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Loading States

export const selectIsLoading = createSelector(
  selectPartnerWebsiteInvitationsState,
  state => state.loading
);

export const isLoaded = createSelector(
  selectPartnerWebsiteInvitationsState,
  state => state.loaded
);

/////////////////////////////////////////////////////////////////////////////////////////////////////// Error

export const selectErrors = createSelector(
  selectPartnerWebsiteInvitationsState,
  (state) => state.errors
);
