import {enableProdMode, isDevMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {hmrBootstrap} from './hmr';
import * as moment from 'moment';
import 'zone.js/plugins/zone-error';

if (environment.production) {
  console.log('enable ProductionMode');
  enableProdMode();
}
console.log('isDev', isDevMode());

moment.locale('de');
moment.updateLocale('de', {
  week: {
    dow: 1,
    doy: 4
  }
});


const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module.hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}
