import { RemoteAccessReasonsLoadedGuard } from './remote-access-reasons-loaded.guard';
import { TicketCommentTagsLoadedGuard } from './ticket-comment-tags-loaded.guard';
import { TicketCommentsLoadedGuard } from './ticket-comments-loaded.guard';
import { TicketLoadedGuard } from './ticket-loaded.guard';
import { TicketsLoadedGuard } from './tickets-loaded.guard';

export const AllGuards = [
  RemoteAccessReasonsLoadedGuard,
  TicketCommentTagsLoadedGuard,
  TicketCommentsLoadedGuard,
  TicketsLoadedGuard,
  TicketLoadedGuard
];

export * from './remote-access-reasons-loaded.guard';
export * from './ticket-comment-tags-loaded.guard';
export * from './ticket-comments-loaded.guard';
export * from './ticket-loaded.guard';
export * from './tickets-loaded.guard';
