import { createReducer, on } from '@ngrx/store';

import * as fromWarehouseModuleModels from '../../../warehouse/models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { OrderDataMediumsActions } from '../actions';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromWarehouseModuleModels.CustomerDataMedium };
  errors: ErrorsObject;
  loading: boolean;
  loaded: boolean;
  paginationIdPrev?: number;
  paginationIdNext?: number;
}

const initialState = {
  entities: {},
  errors: {},
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,
  on(OrderDataMediumsActions.ReadOrderDataMediums, state => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(
    OrderDataMediumsActions.ReadOrderDataMediumsSuccess,
    (state, { response }) => {
      const items = response['hydra:member'];
      const entities = items.reduce(
        (
          entities: {
            [iri: string]: fromWarehouseModuleModels.CustomerDataMedium;
          },
          item
        ) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      return {
        ...state,
        entities,
        loading: false,
        loaded: true
      };
    }
  ),
  on(OrderDataMediumsActions.ReadOrderDataMediumsFail, state => {
    return {
      ...state,
      loading: false
    };
  }),
  on(OrderDataMediumsActions.ResetOrderMediumsState, state => {
    return {
      ...initialState
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
