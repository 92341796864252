import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { CustomerReplacementStockItemsActions } from '../actions';
import { CustomerReplacementStockItemsService } from '../../services';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CustomerReplacementStockItemsEffects {
  createCustomerReplacementStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItem
      ),
      switchMap(({ payload }) => {
        return this.service.createCustomerReplacementStockItem(payload).pipe(
          map(response =>
            CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  readCustomerReplacementStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItems
      ),
      switchMap(({ page, params }) => {
        return this.service.getCustomerReplacementStockItems(page, params).pipe(
          map(response =>
            CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemsSuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemsFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  readCustomerReplacementStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItem
      ),
      mergeMap(({ iri }) => {
        return this.service.getCustomerReplacementStockItem(iri).pipe(
          map((response: any) =>
            CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemSuccess(
              { response }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  updateCustomerReplacementStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItem
      ),
      switchMap(({ iri, payload }) => {
        return this.service
          .updateCustomerReplacementStockItem(iri, payload)
          .pipe(
            map((response: any) =>
              CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess(
                { response }
              )
            ),
            catchError((response: HttpErrorResponse) =>
              of(
                CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemFail(
                  { response }
                )
              )
            )
          );
      })
    )
  );
  deleteCustomerReplacementStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItem
      ),
      switchMap(({ iri }) => {
        return this.service.deleteCustomerReplacementStockItem(iri).pipe(
          map(() =>
            CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemSuccess(
              { iri }
            )
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemFail(
                { response }
              )
            )
          )
        );
      })
    )
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemSuccess,
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case CustomerReplacementStockItemsActions.CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS:
              message =
                'Ein neuer Lagereintrag (Ersatzdatenträger Kunde) wurde angelegt.';
              break;
            case CustomerReplacementStockItemsActions.UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_SUCCESS:
              message =
                'Der Lagereintrag (Ersatzdatenträger Kunde) wurde aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerReplacementStockItemsActions.CreateCustomerReplacementStockItemFail,
          CustomerReplacementStockItemsActions.UpdateCustomerReplacementStockItemFail,
          CustomerReplacementStockItemsActions.DeleteCustomerReplacementStockItemFail,
          CustomerReplacementStockItemsActions.ReadCustomerReplacementStockItemFail
        ),
        map(({ type, response }) => {
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case CustomerReplacementStockItemsActions.CREATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL:
              message =
                'Beim Anlegen eines neuen Lagereintrags (Ersatzdatenträger Kunde) sind Fehler aufgetreten:' +
                errors;
              break;
            case CustomerReplacementStockItemsActions.UPDATE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL:
              message =
                'Beim Aktualisieren des Lagereintrags (Ersatzdatenträger Kunde) sind Fehler aufgetreten:' +
                errors;
              break;
            case CustomerReplacementStockItemsActions.DELETE_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL:
              message =
                'Beim Löschen des Lagereintrag (Ersatzdatenträger Kunde) ist ein Fehler aufgetreten:' +
                errors;
              break;
            case CustomerReplacementStockItemsActions.READ_CUSTOMER_REPLACEMENT_STOCK_ITEM_FAIL:
              message =
                'Beim lesen des Lagereintrags (Ersatzdatenträger Kunde) sind Fehler aufgetreten:' +
                errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: CustomerReplacementStockItemsService,
    private notifierService: NotifierService
  ) {}
}
