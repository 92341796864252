import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../orders/models';
import { PotentialAction } from '../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { TransitionsActions } from '../../../application-state/store/actions';
import { TicketsSelectors } from '../../../tickets/store/selectors';
import { TicketList } from '../../../tickets/models/ticket-list.interface';
import { TicketsActions } from '../../../tickets/store';
import { BaseOnDestroyComponent } from '../../injectables/BaseOnDestroy.component';
import { ActionBoxInputTypes } from '../../models/action-box-input-types.type';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-action-box-to-access-data-provided',
  styleUrls: ['./action-box-to-access-data-provided.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label" *ngIf="canMakeTransition">
        Aktion erforderlich
      </div>
      <div class="action-required-label bg-green" *ngIf="!canMakeTransition">
        Warte auf Zuarbeit
      </div>
      <div class="content ">
        <div class="row" *ngIf="canMakeTransition">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header">Remote Zugangsdaten?</div>
              <div class="sub-header col-auto">
                Bitte geben Sie die Zugangsdaten für die Remote Analyse bereit.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right"></div>
        </div>
        <div class="row " *ngIf="!canMakeTransition">
          <div class=" col-auto header">Warte auf Remote Zugang</div>
          <div class="sub-header col-auto">
            Warte auf Definierung der Remote Zugangsdaten
          </div>
        </div>
      </div>
      <div
        class="additional-content px-4 py-2"
        [formGroup]="form"
        *ngIf="canMakeTransition"
      >
        <div class="row">
          <div class="col-sm-4">
            <div class="row">
              <div class="col">
                <mat-form-field>
                  <mat-label>IP/Host</mat-label>
                  <input formControlName="ip" type="text" matInput />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'ip'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field>
                  <mat-label>User</mat-label>
                  <input matInput formControlName="user" type="text" />
                  <mat-error>
                    <app-form-error
                      [fieldName]="'user'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field>
                  <mat-label>Passwort</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    type="password"
                    #passInput
                  />
                  <mat-icon
                    matSuffix
                    style="margin-right: 12px; cursor: pointer; opacity: .8"
                    (mousedown)="passInput.type = 'text'"
                    (mouseup)="passInput.type = 'password'"
                    >visibility
                  </mat-icon>
                  <mat-error>
                    <app-form-error
                      [fieldName]="'password'"
                      [formGroup]="form"
                    ></app-form-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <mat-form-field>
              <mat-label>Bemerkung</mat-label>
              <textarea
                matInput
                formControlName="comment"
                rows="10"
                cdkTextareaAutosize
              ></textarea>
              <mat-error>
                <app-form-error
                  [fieldName]="'comment'"
                  [formGroup]="form"
                ></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                class="decision-btn"
                mat-button
                color="green"
                (click)="submitForm()"
                [disabled]="form.invalid && !okAction"
              >
                <mat-icon class="me-2">done</mat-icon>
                <span>Zugangsdaten zur Verfügung stellen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ActionBoxToAccessDataProvidedComponent
  extends BaseOnDestroyComponent
  implements OnInit {
  @Input() order$: Observable<Order>;
  @Input() inputType: ActionBoxInputTypes;

  order: Order;
  ticket: TicketList;
  okAction: PotentialAction = null;
  form: FormGroup;

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private actions$: Actions
  ) {
    super();
  }

  get canMakeTransition(): boolean {
    return (
      (this.inputType === 'ticket' &&
        (this.authService.isTechnician() ||
          this.authService.isAdmin() ||
          this.authService.isSupervisor())) ||
      false
    );
  }

  ngOnInit(): void {
    this.initForm();
    this.order$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(order => !!order)
      )
      .subscribe(order => {
        this.order = order;
        if (order.ticket) {
          this.store$
            .select(TicketsSelectors.sByIri, { iri: order.ticket })
            .pipe(
              takeUntil(this.onDestroy$),
              filter(ticket => !!ticket)
            )
            .subscribe(ticket => {
              this.ticket = ticket;
            });
        }
        this.okAction = order.potentialActions?.find(
          e =>
            !e.error &&
            (e.transition.indexOf(
              '_to_access_data_provided_analysis_dr_remote'
            ) > -1 ||
              e.transition.indexOf(
                'to_dm_right_placed_access_data_provided_dr_dr_remote'
              ) > -1)
        );
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      ip: this.fb.control(''),
      user: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null),
      comment: this.fb.control(null)
    });
  }

  makeTransition(): void {
    const action = this.okAction;
    if (!action) {
      return;
    }
    const uri = `${this.order['@id']}/transitions?workflow=${action.workflow}&transition=${action.transition}`;
    const payload = {
      workflow: action.workflow,
      transition: action.transition
    };
    this.store$.dispatch(TransitionsActions.MakeTransition({ uri, payload }));
  }

  submitForm(): void {
    const ticket = this.order.ticket;
    if (!ticket) {
      return;
    }

    const values = this.form.getRawValue();
    this.store$.dispatch(
      TicketsActions.ProvideRemoteAccess({
        iri: typeof ticket === 'string' ? ticket : ticket['@id'],
        payload: { remoteAccess: values }
      })
    );

    this.actions$
      .pipe(
        ofType(TicketsActions.ProvideRemoteAccessSuccess),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.makeTransition();
      });
  }
}
