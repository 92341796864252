import {AfterViewInit, Component, HostBinding, Input, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'form-select',
  styleUrls: ['form-select.component.scss'],
  template: `

    <div class="mat-form-field" [formGroup]="group">

      <ng-select
        [items]="config.options$ | async"
        [placeholder]="config.label"
        [bindLabel]="config?.optionsConfig?.bindLabel ? config.optionsConfig.bindLabel : null"
        [bindValue]="config?.optionsConfig?.bindValue ? config.optionsConfig.bindValue : null"
        [formControlName]="config.name"
        clearable="{{ config.optionsConfig?.clearable ? config.optionsConfig.clearable : false }}"
        searchable="{{ config.optionsConfig?.searchable ? config.optionsConfig.searchable : false }}"
        markFirst="{{ config?.optionsConfig?.markFirst ? config.optionsConfig.markFirst : false }}"></ng-select>
      <!--<mat-hint align="start" *ngIf="errors?.order">{{ errors.order.message }}</mat-hint>-->


      <!--<mat-label>{{ config.label }}</mat-label>
      <mat-select [formControlName]="">
        <mat-option value="">{{ config.placeholder }}</mat-option>
        <mat-option *ngFor="let option of config.options">{{ option }}</mat-option>
      </mat-select>-->
    </div>
  `,
})
export class FormSelectComponent implements AfterViewInit {

  @HostBinding('attr.class')
  column = 'column-auto';

  config: { [property: string]: any } | any;

  group: FormGroup;

  ngAfterViewInit(): void {
    if (this?.config?.colSpan) {
      this.column = `column-${this?.config?.colSpan}`;
    }
  }
}
