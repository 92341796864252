import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiResponse } from '../../shared/models';
import { AbstractApiService } from '../../shared/services';
import { EmailTemplateCategory } from '../models';
import { UserRoleService } from '../../shared/services/user-role.service';

type baseType = EmailTemplateCategory;

@Injectable()
export class EmailTemplateCategoryService {
  namespace = '/email_template_categories';

  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  create(payload: baseType): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(this.namespace, payload)
    );
  }

  readOne(iri: string): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  readList(): Observable<AbstractApiResponse | any> {
    return this.rolesService.userHasRoleFilter<AbstractApiResponse>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_LIST',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(this.namespace)
    );
  }

  update(iri: string, payload: baseType): Observable<baseType> {
    return this.rolesService.userHasRoleFilter<baseType>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  delete(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_EMAIL_TEMPLATE_CATEGORY_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
