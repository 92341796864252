import {Component, Input, OnInit} from '@angular/core';


import * as fromOrdersModuleModels from '../../models';

@Component({
  selector: 'order-tracking-history',
  styleUrls: ['order-tracking-history.component.scss'],
  template: `

    <div class="card" style="width: 600px">
      <div class="card__heading">
        <span>Versandstatus zu diesem Auftrag</span>
      </div>

      <div class="card__content">
        <div class="order-list__outer">
          <div class="order-list__wrapper wrap">
            <div class="tbody grid">

              <p class="p-t--18" *ngIf="!!!logEntries?.length">Keine Eintragungen im Log für diesen Auftrag.</p>

              <ng-container *ngIf="!!logEntries?.length">
                <div class="th column-3">Datum</div>
                <div class="th column-7">Status</div>
                <div class="th column-4">Trackingnummer</div>

                <div class="tr column-14 grid" *ngFor="let entry of logEntries">
                  <div class="td column-3">{{ entry.createdAt | date: 'dd.MM.Y - HH:mm' }}</div>
                  <div class="td column-7">{{ entry.state }}</div>
                  <div
                    class="td column-4">{{ entry?.shipment?.trackingNumber ? entry?.shipment?.trackingNumber : '-' }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrderTrackingHistoryComponent implements OnInit {

  @Input() logEntries: Array<fromOrdersModuleModels.DataMediumStatusLog>;

  constructor() {
  }

  ngOnInit(): void {
  }
}

/*
*   {
 "@id": "/api/data_medium_status_logs/a6004acd-182a-41d0-89d6-b83eb8e53dcb",
 "@type": "DataMediumStatusLog",
 "order": "/api/orders/5a0393dd-a6f1-4f3d-84f8-9992a304a746",
 "dataMedium": {
 "@id": "/api/customer_data_media/a7e7c697-5930-4371-ab42-f3e7997982a4",
 "@type": "CustomerDataMedium",
 "storageNumber": "2022-02-26-0027"
 },
 "shipment": null,
 "state": "stored_dr_de",
 "createdAt": "2022-02-26T11:31:07+01:00",
 "createdBy": "/api/administrators/5d4815bf-e9ca-4f6d-b5c4-8a7a78a256c9"
 },
*
* */
