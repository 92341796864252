import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromModuleModels from '../../models';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const LOAD_LEAD_COMMENT_TAG = '[Leads Module] Load Lead Comment Tag';
export const LOAD_LEAD_COMMENT_TAG_SUCCESS = '[Leads Module] Load Lead Comment Tag Success';
export const LOAD_LEAD_COMMENT_TAG_FAIL = '[Leads Module] Load Lead Comment Tag Fail';

export const LoadLeadCommentTag = createAction(
  LOAD_LEAD_COMMENT_TAG,
  props<{ iri: string }>()
);
export const LoadLeadCommentTagSuccess = createAction(
  LOAD_LEAD_COMMENT_TAG_SUCCESS,
  props<{ response: fromModuleModels.Lead }>()
);
export const LoadLeadCommentTagFail = createAction(
  LOAD_LEAD_COMMENT_TAG_FAIL,
  props<{ response: HttpErrorResponse }>()
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const LOAD_LEAD_COMMENT_TAGS = '[Leads Module] Load Lead Comment Tags';
export const LOAD_LEAD_COMMENT_TAGS_SUCCESS = '[Leads Module] Load Lead Comment Tags Success';
export const LOAD_LEAD_COMMENT_TAGS_FAIL = '[Leads Module] Load Lead Comment Tags Fail';

export const LoadLeadCommentTags = createAction(
  LOAD_LEAD_COMMENT_TAGS
);
export const LoadLeadCommentTagsSuccess = createAction(
  LOAD_LEAD_COMMENT_TAGS_SUCCESS,
  props<{ response: any }>()
);
export const LoadLeadCommentTagsFail = createAction(
  LOAD_LEAD_COMMENT_TAGS_FAIL,
  props<{ response: HttpErrorResponse }>()
);
