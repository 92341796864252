import {HttpErrorResponse} from '@angular/common/http';

import {createAction, props} from '@ngrx/store';

import * as fromInvoicesModuleModels from '../../../invoices/models';
import {AbstractApiResponse} from '../../../shared/models';

/////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n

export const READ_CUSTOMER_INVOICES = '[Customers Module] Read Customer Invoices';
export const READ_CUSTOMER_INVOICES_SUCCESS = '[Customers Module] Read Customer Invoices Success';
export const READ_CUSTOMER_INVOICES_FAIL = '[Customers Module] Read Customer Invoices Fail';

export const ReadCustomerInvoices = createAction(
  READ_CUSTOMER_INVOICES,
  props<{ iri: string }>()
);

export const ReadCustomerInvoicesSuccess = createAction(
  READ_CUSTOMER_INVOICES_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerInvoicesFail = createAction(
  READ_CUSTOMER_INVOICES_FAIL,
  props<{ response: HttpErrorResponse }>()
);

export const READ_CUSTOMER_INVOICES_CHUNK = '[Customers Module] Read Customer Invoices Chunk';
export const READ_CUSTOMER_INVOICES_CHUNK_SUCCESS = '[Customers Module] Read Customer Invoices Chunk Success';
export const READ_CUSTOMER_INVOICES_CHUNK_FAIL = '[Customers Module] Read Customer Invoices Chunk Fail';

export const ReadCustomerInvoicesChunk = createAction(
  READ_CUSTOMER_INVOICES_CHUNK,
  props<{ iri: string }>()
);

export const ReadCustomerInvoicesChunkSuccess = createAction(
  READ_CUSTOMER_INVOICES_CHUNK_SUCCESS,
  props<{ response: AbstractApiResponse }>()
);

export const ReadCustomerInvoicesChunkFail = createAction(
  READ_CUSTOMER_INVOICES_CHUNK_FAIL,
  props<{ response: HttpErrorResponse }>()
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Reset
export const RESET_CUSTOMER_INVOICES_STATE = '[Customers Module] Reset Customer Invoices State';

export const ResetCustomerInvoicesState = createAction(
  RESET_CUSTOMER_INVOICES_STATE
);
