import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DepartmentLogosActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { DepartmentLogosService } from '../../services/department-logos.service';

@Injectable()
export class DepartmentLogosEffects {
  createDepartmentLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentLogosActions.CreateDepartmentLogo),
      map(action => action),
      switchMap(({ payload }) => {
        return this.ds.createDepartmentLogo(payload).pipe(
          map((data: any) => {
            console.log(data);
            if (!!data.body) {
              return DepartmentLogosActions.CreateDepartmentLogoSuccess({
                response: data.body
              });
            } else {
              return DepartmentLogosActions.CreateDepartmentLogoStatus({
                response: data
              });
            }
          }),
          catchError(response =>
            of(DepartmentLogosActions.CreateDepartmentLogoFail({ response }))
          )
        );
      })
    )
  );
  getDepartmentLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentLogosActions.ReadDepartmentLogo),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.getDepartmentLogoInformation(iri).pipe(
          map(response =>
            DepartmentLogosActions.ReadDepartmentLogoSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentLogosActions.ReadDepartmentLogoFail({ response }))
          )
        );
      })
    )
  );
  getDepartmentLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentLogosActions.ReadDepartmentLogos),
      map(action => action),
      switchMap(() => {
        return this.ds.getDepartmentLogos().pipe(
          map(response =>
            DepartmentLogosActions.ReadDepartmentLogosSuccess({ response })
          ),
          catchError(response =>
            of(DepartmentLogosActions.ReadDepartmentLogosFail({ response }))
          )
        );
      })
    )
  );
  // updateDepartmentLogo$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DepartmentLogosActions.UpdateDepartmentLogo),
  //     map(action => action),
  //     switchMap(payload => {
  //       return this.ds.updateDepartmentLogo(payload).pipe(
  //         map(response =>
  //           DepartmentLogosActions.UpdateDepartmentLogoSuccess({ response })
  //         ),
  //         catchError(response =>
  //           of(DepartmentLogosActions.UpdateDepartmentLogoFail({ response }))
  //         )
  //       );
  //     })
  //   )
  // );
  deleteDepartmentLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentLogosActions.DeleteDepartmentLogo),
      map(action => action),
      switchMap(({ iri }) => {
        return this.ds.deleteDepartmentLogo(iri).pipe(
          map(() =>
            DepartmentLogosActions.DeleteDepartmentLogoSuccess({ iri })
          ),
          catchError(response =>
            of(DepartmentLogosActions.DeleteDepartmentLogoFail({ response }))
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentLogosActions.DeleteDepartmentLogoFail,
          DepartmentLogosActions.UpdateDepartmentLogoFail,
          DepartmentLogosActions.ReadDepartmentLogosFail,
          DepartmentLogosActions.ReadDepartmentLogoFail,
          DepartmentLogosActions.CreateDepartmentLogoFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          const message = 'Fehler bei der Anfrage: ' + errors;
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );
  SuccessActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentLogosActions.CreateDepartmentLogoSuccess,
          DepartmentLogosActions.UpdateDepartmentLogoSuccess
        ),
        map(({ type }) => {
          let message = 'Erfolgreich';
          switch (type) {
            case DepartmentLogosActions.CREATE_DEPARTMENT_LOGO_SUCCESS:
              message = 'Ein neues Logo wurde angelegt.';
              break;
            case DepartmentLogosActions.UPDATE_DEPARTMENT_LOGO_SUCCESS:
              message = 'Das Logo wurde aktualisiert.';
              break;
          }
          this.notifierService.show({ type: 'success', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private ds: DepartmentLogosService,
    private notifierService: NotifierService
  ) {}
}
