import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ApplicationState } from '../../../application-state/store';
import {
  ShipmentDirectionsSelectors,
  ShipmentServicesSelectors,
  ShippingProvidersToCustomerSelectors,
  ShippingProvidersToDataRecoverySelectors
} from '../../../shipping/store/selectors';
import { StockItemsSelectors } from '../../store/selectors';
import { isLoadingArray } from '../../../shared/utilities/observable.utility';
import {
  CountriesSelectors,
  ProductsSelectors,
  StorageLocationsSelectors,
  StorageSystemManufacturersSelectors,
  StorageSystemsSelectors
} from '../../../master-data/store/selectors';
import { AbstractTitleService } from '../../../shared/services/abstract-title.service';
import {
  AddCustomerHardwareDialogComponent,
  AddReplacementHardwareDialogComponent
} from '../../components';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseOnDestroyComponent } from '../../../shared/injectables/BaseOnDestroy.component';
import { RouterActions } from '../../../application-state/store/actions';
import { take, takeUntil } from 'rxjs/operators';
import { WarehouseRights } from '../../../shared/security/warehouseRights';

@Component({
  selector: 'app-warehouse-view',
  styleUrls: ['warehouse-view.component.scss'],
  template: `
    <div class="row">
      <div class="col-auto me-auto">
        <h1>
          Lager
          <button
            class="m-l--24"
            mat-flat-button
            *ngIf="canCreate$ | async"
            (click)="showAddCustomerHardwareDialog()"
          >
            <mat-icon>add</mat-icon>
            Kundenhardware einlagern
          </button>
          <button
            class="m-l--24"
            mat-flat-button
            *ngIf="canCreate$ | async"
            (click)="showAddReplacementHardwareDialog()"
          >
            <mat-icon>add</mat-icon>
            Ersatzdatenträger einlagern
          </button>
        </h1>
      </div>
    </div>
    <div class="row">
      <nav mat-tab-nav-bar class="col">
        <a
          mat-tab-link
          *ngFor="let link of links"
          [active]="isActive(link)"
          [routerLinkActive]="link.url"
          #rla="routerLinkActive"
          [routerLink]="link.url"
        >
          {{ link.label }}
        </a>
      </nav>
    </div>
    <router-outlet></router-outlet>
  `
})
export class WarehouseViewComponent extends BaseOnDestroyComponent
  implements OnInit {
  isLoading$: Observable<boolean>;
  links: Array<{
    url: string;
    label: string;
    value: string;
  }> = [
    {
      url: '/warehouse',
      value: 'customer_data_media',
      label: 'Kundenhardware'
    },
    {
      url: '/warehouse/customer_replacement_data_media',
      value: 'customer_replacement_data_media',
      label: 'Ersatzdatenträger (Kunde)'
    },
    {
      url: '/warehouse/replacement_data_media',
      value: 'replacement_data_media',
      label: 'Ersatzdatenträger (DR)'
    },
    {
      url: '/warehouse/archive',
      value: 'archive',
      label: 'Archiv'
    }
  ];

  constructor(
    private dialog: MatDialog,
    private store$: Store<ApplicationState>,
    private activatedRoute$: ActivatedRoute,
    private warehouseRight: WarehouseRights,
    private router: Router,
    private title: AbstractTitleService
  ) {
    super();
  }

  get canCreate$(): Observable<boolean> {
    return this.warehouseRight.canCreate$;
  }

  get activeLink(): {
    url: string;
    label: string;
    value: string;
  } {
    return this.links.find(this.isActive.bind(this));
  }

  isActive(link: any): boolean {
    let url = this.router.url.toLowerCase();
    if (this.router.url.indexOf('?') > -1) {
      url = this.router.url.toLowerCase().split('?')[0];
    }
    return url === link.url.toLowerCase();
  }

  ngOnInit(): void {
    this.activatedRoute$.data
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.callFunctionOnInit && this[data?.callFunctionOnInit]) {
          this[data?.callFunctionOnInit](
            this.activatedRoute$?.snapshot?.queryParams
              ? this.activatedRoute$.snapshot.queryParams
              : null
          );
        }
      });
    this.isLoading$ = isLoadingArray([
      this.store$.select(CountriesSelectors.isLoading),
      this.store$.select(ProductsSelectors.isLoading),
      this.store$.select(ShipmentDirectionsSelectors.isLoading),
      this.store$.select(ShipmentServicesSelectors.isLoading),
      this.store$.select(ShippingProvidersToCustomerSelectors.isLoading),
      this.store$.select(ShippingProvidersToDataRecoverySelectors.isLoading),
      this.store$.select(StockItemsSelectors.isLoading),
      this.store$.select(StorageLocationsSelectors.isLoading),
      this.store$.select(StorageSystemManufacturersSelectors.isLoading),
      this.store$.select(StorageSystemsSelectors.isLoading)
    ]);
    this.title.setTitle('Lager');
  }

  showAddReplacementHardwareDialog(): void {
    this.store$.dispatch(
      RouterActions.Go({
        path: ['warehouse', 'add-replacement-hardware-dialog']
      })
    );
  }

  showAddCustomerHardwareDialog(): void {
    this.store$.dispatch(
      RouterActions.Go({ path: ['warehouse', 'add-customer-hardware-dialog'] })
    );
  }

  openAddReplacementHardwareDialog(): void {
    this.dialog.open(AddReplacementHardwareDialogComponent, {});
  }

  openAddCustomerHardwareDialog(query: { type: string }): void {
    if (!query) {
      this.dialog
        .open(AddCustomerHardwareDialogComponent, {})
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result === 'CLOSE_AND_OPEN_AGAIN_WITH_CUSTOMER_REPLACEMENT') {
            this.store$.dispatch(
              RouterActions.Go({
                path: ['warehouse', 'add-customer-hardware-dialog'],
                query: { type: 'CustomerReplacementStockItem' }
              })
            );
          }
        });
    } else {
      this.dialog.open(AddCustomerHardwareDialogComponent, {
        data: {
          hardwareTyp: query.type,
          actionAfterClose: 'GO_TO_WAREHOUSE'
        }
      });
    }
  }
}
