import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Observable} from 'rxjs';

import * as fromShippingModuleModels from '../../../shipping/models';

@Component({
  selector: 'shipment-direction',
  styleUrls: ['shipment-direction.component.scss'],
  template: `

    <div class="card">

      <div class="card__heading">
        <ng-content select=".heading"></ng-content>
      </div>

      <div class="card__content p-a--24" [formGroup]="sf">

        <div class="grid">
          <mat-form-field class="column-14">

            <mat-label>Beschreibung</mat-label>
            <textarea matInput
                      formControlName="description"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="2"
            ></textarea>
          </mat-form-field>

          <div class="mat-form-field column-7">
            <ng-select
              [items]="shipmentDirections$ | async"
              [clearable]="false"
              [searchable]="false"
              (change)="updateShipmentDestination($event)"
              #shipmentDirection
              bindValue="@id"
              bindLabel="direction"
              formControlName="shipmentDirection"
              placeholder="Versandrichtung"
            ></ng-select>
          </div>

          <div class="column-7">
            <ng-select
              [items]="isShipmentToCustomer ? (shippingProvidersToCustomer$ | async) : (shippingProvidersToDataRecovery$ | async)"
              [clearable]="false"
              [searchable]="false"
              bindValue="@id"
              bindLabel="name"
              placeholder="Versanddienstleister"
              #shippingProvider
              formControlName="shippingProvider"></ng-select>
          </div>

          <div class="column-7" *ngIf="!!this.sf.get('pickupEarliest')">
            <mat-form-field>
              <mat-label>Abholung (frühest)</mat-label>
              <input type="text" matInput [matDatepicker]="picker1" formControlName="pickupEarliest" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="column-7" *ngIf="!!this.sf.get('pickupLatest')">
            <mat-form-field>
              <mat-label>Abholung (spätest)</mat-label>
              <input type="text" matInput [matDatepicker]="picker2" formControlName="pickupLatest" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="column-7" *ngIf="(shipmentServices$ | async) as ss" [hidden]="!ss.length">

            <ng-select [items]="ss"
                       [clearable]="false"
                       [searchable]="false"
                       bindValue="@id"
                       bindLabel="name"
                       placeholder="Versand-Service"
                       formControlName="shipmentService"></ng-select>
          </div>

          <div class="column-14 m-ta--2">

            <button class="btn--cancel m-r--16" (click)="handleResetForm()"
                    [disabled]="sf.untouched"
                    type="button" color="outline" mat-flat-button>
              <mat-icon class="m-r--8">cancel</mat-icon>
              <span>Abbrechen</span>
            </button>

            <span [matTooltipDisabled]="!!selection.length"
                  matTooltip="Bitte mindestens einen Datenträger auswählen">
          <button class="btn--submit"
                  [disabled]="submitButtonDisabled"
                  mat-flat-button
                  color="green"
                  (click)="handleRequestCreateShipment()">
            <mat-icon class="m-r--8">qr_code</mat-icon>
            <span>Paket vorbereiten</span>
          </button>
        </span>
          </div>
        </div>
      </div>
    </div>

    <!--<pre>{{ selection | json }}</pre>-->
    <!--<pre>{{ shipmentDirection | json }}</pre>-->
    <!--<pre>{{ sf.value | json }}</pre>-->
  `
})
export class ShipmentDirectionComponent {

  @Input() selection: Array<any> = [];
  @Input() sf: FormGroup;
  @Input() shipmentDirections$: Observable<Array<fromShippingModuleModels.ShipmentDirection>>;
  @Input() shipmentServices$: Observable<Array<fromShippingModuleModels.ShipmentService>>;
  @Input() shippingProvidersToCustomer$: Observable<Array<fromShippingModuleModels.ShippingProvider>>;
  @Input() shippingProvidersToDataRecovery$: Observable<Array<fromShippingModuleModels.ShippingProvider>>;

  @Output() requestFetchServices: EventEmitter<string | null> = new EventEmitter();
  @Output() requestShowShipmentForm: EventEmitter<string> = new EventEmitter();
  @Output() requestResetSelection: EventEmitter<void> = new EventEmitter();
  @Output() requestUpdateFormModel: EventEmitter<void> = new EventEmitter();

  shipmentDirection = '';

  get isShipmentToCustomer(): boolean {
    return this.shipmentDirection.includes('TO_CUSTOMER');
  }

  get submitButtonDisabled(): boolean {

    const pickupEarliestValid = this.sf.get('pickupEarliest') ? this.sf.get('pickupEarliest').valid : true;
    const pickupLatestValid = this.sf.get('pickupLatest') ? this.sf.get('pickupLatest').valid : true;

    return this.sf.untouched ||
      !this.selection.length ||
      !!!this.sf.get('shipmentDirection')?.value ||
      !this.sf.get('shipmentService').valid ||
      !pickupEarliestValid ||
      !pickupLatestValid ||
      !!!this.sf.get('shippingProvider')?.value;
  }

  updateShipmentDestination(value: fromShippingModuleModels.ShipmentDirection): void {
    this.handleResetShippingProviderControl();
    this.shipmentDirection = value['@id'];
  }

  handleRequestCreateShipment(): void {
    this.requestShowShipmentForm.emit();
  }

  handleResetShipmentDirectionControl(): void {
    this.sf.get('shipmentDirection').setValue(null);
    this.sf.get('shipmentDirection').updateValueAndValidity();
  }

  handleResetShippingProviderControl(): void {
    this.sf.get('shippingProvider').setValue(null);
    this.sf.get('shippingProvider').updateValueAndValidity();
  }

  handleResetForm(): void {
    this.sf.reset();
    this.requestResetSelection.emit();
    this.handleResetShipmentDirectionControl();
  }
}
