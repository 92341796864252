import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Order } from '../../models';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { UserProfileSelectors } from '../../../auth/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { extractIri } from '../../../shared/utilities/objects.utility';

@Component({
  selector: 'app-missing-replacement-dm-widget',
  styleUrls: ['./missing-replacement-dm-widget.component.scss'],
  template: `
    <div class="wrapper  p-y--8">
      <div class="widget card">
        <div class="widget_header">
          <span>Ersatzdatenträger fehlen</span>
        </div>
        <app-orders-list-for-widget
          [states]="states"
          [additionalParams]="additionalParams"
          [salesperson]="salesperson"
          [indicatorStyleFn]="getCircleColor"
        ></app-orders-list-for-widget>
      </div>
    </div>
  `
})
export class MissingReplacementDmWidgetComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject<any>();
  states: Array<string> = [];
  additionalParams = { missingReplacementDataMedium: true };
  salesperson: string;

  constructor(private store$: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.store$
      .select(UserProfileSelectors.selectUserProfile)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e['@id'])
      )
      .subscribe(profile => {
        this.salesperson = extractIri(profile);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  getCircleColor(order: Order): { color: string } {
    return { color: 'grey' };
  }
}
