import {AfterViewInit, Component, HostBinding, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'form-input',
  styleUrls: ['form-input.component.scss'],
  template: `
    <div class="dynamic-field form-button">

      <mat-form-field [formGroup]="group">
        <mat-label>{{ config.label }}</mat-label>
        <input matInput type="text" [attr.placeholder]="config.placeholder" [formControlName]="config.name"/>
      </mat-form-field>
    </div>
  `,
})
export class FormInputComponent implements AfterViewInit {

  @HostBinding('attr.class')
  column = 'column-auto';

  config;

  group: FormGroup;

  ngAfterViewInit(): void {
    if (this?.config?.colSpan) {
      this.column = `column-${this?.config?.colSpan}`;
    }
  }
}
