import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { PartialInvoiceItemsActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.PartialInvoiceItem };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false
};

export const reducer = createReducer(
  initialState,
  on(
    PartialInvoiceItemsActions.CreatePartialInvoiceItem,
    PartialInvoiceItemsActions.ReadPartialInvoiceItem,
    PartialInvoiceItemsActions.UpdatePartialInvoiceItem,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    PartialInvoiceItemsActions.ReadPartialInvoiceItemSuccess,
    PartialInvoiceItemsActions.CreatePartialInvoiceItemSuccess,
    PartialInvoiceItemsActions.UpdatePartialInvoiceItemSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        current: response,
        loading: false
      };
    }
  ),
  on(
    PartialInvoiceItemsActions.DeletePartialInvoiceItemSuccess,
    (state, { iri }) => {
      // @ts-ignore
      const { [iri]: removedItem, ...entities } = state.entities;

      return {
        ...state,
        entities,
        errors: {},
        loading: false
      };
    }
  ),
  on(
    PartialInvoiceItemsActions.CreatePartialInvoiceItemFail,
    PartialInvoiceItemsActions.ReadPartialInvoiceItemFail,
    PartialInvoiceItemsActions.UpdatePartialInvoiceItemFail,
    PartialInvoiceItemsActions.DeletePartialInvoiceItemFail,
    (state, { type, response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
