import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import * as fromCustomersModule from '../../models';
import * as fromCustomersModuleModels from '../../../customers/models';
import * as fromMasterDataModuleModels from '../../../master-data/models';
import * as fromModuleModels from '../../../master-data/models';
import {ErrorsObject} from '../../../shared/utilities/error-utility.utility';
import {FormsService} from '../../../shared/services';

@Component({
  selector: 'customer-details-form',
  styleUrls: ['customer-details-form.component.scss'],
  template: `

    <ng-container [formGroup]="cef">

      <div class="grid">
        <div class="column-14 mat-form-field">
          <ng-select
            bindValue="@id"
            [clearable]="false"
            [items]="customerTypes"
            bindLabel="customerType"
            [placeholder]="'customers.customer_details_form.customer_type_placeholder' | translate"
            formControlName="customerType"></ng-select>
          <mat-hint align="start" *ngIf="errors?.customerType">{{ errors.customerType.message }}</mat-hint>
        </div>

        <mat-form-field class="column-14">
          <mat-label>Firma</mat-label>
          <input type="text" matInput formControlName="nameLine1">
          <mat-hint align="start" *ngIf="errors?.nameLine1">{{ errors.nameLine1.message }}</mat-hint>
        </mat-form-field>

        <mat-form-field class="column-14">
          <mat-label>Zusatz</mat-label>
          <input type="text" matInput formControlName="nameLine2">
          <mat-hint align="start" *ngIf="errors?.nameLine2">{{ errors.nameLine2.message }}</mat-hint>
        </mat-form-field>

        <div class="mat-form-field column-14">
          <ng-select
            [placeholder]="'customers.customer_details_form.billing_address_placeholder' | translate"
            [items]="billingAddresses"
            bindValue="@id"
            bindLabel="name"
            appendTo="body"
            formControlName="defaultBillingAddress"
            [clearable]="false"
            [markFirst]="false">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ getFormattedAddress(item.address) }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span>{{ getFormattedAddress(item.address) }}</span>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.defaultBillingAddress">{{ errors.defaultBillingAddress.message }}</mat-hint>
        </div>

        <div class="mat-form-field column-14">
          <ng-select
            [placeholder]="'customers.customer_details_form.delivery_address_placeholder' | translate"
            [items]="deliveryAddresses"
            bindValue="@id"
            bindLabel="name"
            [markFirst]="false"
            appendTo="body"
            formControlName="defaultDeliveryAddress"
            [clearable]="false">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ getFormattedAddress(item.address) }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span>{{ getFormattedAddress(item.address) }}</span>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.defaultDeliveryAddress">{{ errors.defaultDeliveryAddress.message }}</mat-hint>
        </div>

        <div class="mat-form-field column-14">
          <ng-select
            [placeholder]="'customers.customer_details_form.partner_office_branch_address_placeholder' | translate"
            [items]="partnerBranchOfficeAddresses"
            bindValue="@id"
            bindLabel="name"
            [markFirst]="false"
            appendTo="body"
            formControlName="defaultPartnerBranchOfficeAddress"
            [clearable]="false">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ getFormattedAddress(item.address) }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span>{{ getFormattedAddress(item.address) }}</span>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.defaultPartnerBranchOfficeAddress">{{ errors.defaultPartnerBranchOfficeAddress.message }}</mat-hint>
        </div>

        <div class="mat-form-field column-14">

          <ng-select
            placeholder="Technischer Ansprechpartner"
            [items]="technicalCustomerContacts"
            bindValue="@id"
            appendTo="body"
            [markFirst]="false"
            [searchable]="false"
            formControlName="defaultTechnicalCustomerContact"
            [clearable]="false">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="ng-option">
                <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.technicalCustomerContact">{{ errors.technicalCustomerContact.message }}</mat-hint>
        </div>

        <div class="mat-form-field column-14">
          <ng-select
            placeholder="Organisatorischer Ansprechpartner"
            [items]="organizationalCustomerContacts"
            appendTo="body"
            bindValue="@id"
            [markFirst]="false"
            [searchable]="false"
            formControlName="defaultOrganizationalCustomerContact"
            [clearable]="false">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="ng-option">
                <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.defaultOrganizationalCustomerContact">{{ errors.defaultOrganizationalCustomerContact.message }}</mat-hint>
        </div>

        <div class="mat-form-field column-14">
          <ng-select
            placeholder="Entscheider"
            [items]="decisionMakerCustomerContacts"
            appendTo="body"
            bindValue="@id"
            bindLabel="name"
            [markFirst]="false"
            [searchable]="false"
            (change)="handleRequestUpdateCustomer()"
            formControlName="defaultDecisionMakerCustomerContact">

            <ng-template ng-label-tmp let-item="item">
              <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="ng-option">
                <span class="p-r--8">{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </ng-template>
          </ng-select>
          <mat-hint align="start"
                    *ngIf="errors?.defaultDecisionMakerCustomerContact">{{ errors.defaultDecisionMakerCustomerContact.message }}</mat-hint>
        </div>

        <mat-form-field class="column-14">
          <mat-label>Steuernummer</mat-label>
          <input type="text" matInput formControlName="taxNumber">
          <mat-hint align="start" *ngIf="errors?.taxNumber">{{ errors.taxNumber.message }}</mat-hint>
        </mat-form-field>

        <div class="m-ta--2 column-14">

          <button class="btn--submit" [disabled]="cef.invalid"
                  mat-flat-button color="green"
                  (click)="handleRequestUpdateCustomer()">
            <mat-icon class="m-r--8">save</mat-icon>
            <span>Daten speichern</span>
          </button>
        </div>
      </div>
    </ng-container>
  `
})
export class CustomerDetailsFormComponent implements OnInit {

  @Input() billingAddresses: Array<fromCustomersModuleModels.CustomerAddress>;
  @Input() customerTypes: Array<fromCustomersModuleModels.CustomerType>;
  @Input() contacts: Array<fromCustomersModuleModels.CustomerContact>;
  @Input() deliveryAddresses: Array<fromCustomersModuleModels.CustomerAddress>;
  @Input() partnerBranchOfficeAddresses: Array<fromCustomersModuleModels.CustomerAddress>;
  @Input() errors: ErrorsObject;
  @Input() presets$: BehaviorSubject<fromCustomersModuleModels.Customer>;

  @Output() requestUpdateCustomer: EventEmitter<{
    iri: string,
    payload: fromCustomersModuleModels.Customer
  }> = new EventEmitter();

  technicalCustomerContacts: Array<fromCustomersModuleModels.CustomerContact>;
  organizationalCustomerContacts: Array<fromCustomersModuleModels.CustomerContact>;
  decisionMakerCustomerContacts: Array<fromCustomersModuleModels.CustomerContact>;

  cef: FormGroup;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private fs: FormsService) {
  }

  ngOnInit(): void {

    this.technicalCustomerContacts = this.getTechnicalCustomerContactsFromAllContacts();
    this.organizationalCustomerContacts = this.getOrganizationalCustomerContactsFromAllContacts();
    this.decisionMakerCustomerContacts = this.getDecisionMakerCustomerContactsFromAllContacts();

    this.presets$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(item => {

      this.initForm();
      this.fs.patchForm(this.cef, item);
      this.cef.markAsUntouched();
    });
  }

  initForm(): void {
    this.cef = this.fb.group({
      department: this.fb.control(null),
      nameLine1: this.fb.control(null, [Validators.required]),
      nameLine2: this.fb.control(null),
      defaultCustomerContact: this.fb.control(null),
      defaultDecisionMakerCustomerContact: this.fb.control(null),
      defaultOrganizationalCustomerContact: this.fb.control(null),
      defaultTechnicalCustomerContact: this.fb.control(null),
      defaultBillingAddress: this.fb.control(null),
      defaultDeliveryAddress: this.fb.control(null),
      defaultPartnerBranchOfficeAddress: this.fb.control(null),
      customerType: this.fb.control(null),
      taxNumber: this.fb.control(null),
    });
  }

  getTechnicalCustomerContactsFromAllContacts(): Array<fromCustomersModuleModels.CustomerContact> {
    return this.contacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/0'));
  }

  getOrganizationalCustomerContactsFromAllContacts(): Array<fromCustomersModuleModels.CustomerContact> {
    return this.contacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/1'));
  }

  getDecisionMakerCustomerContactsFromAllContacts(): Array<fromCustomersModuleModels.CustomerContact> {
    return this.contacts.filter(contact => contact.customerContactTypes.includes('/api/customer_contact_types/2'));
  }

  getFormattedAddress(address: fromCustomersModule.Address): string {
    return `${address.line1} ${address.line2}${address.line3 ? (' ' + address.line3) : ''}  ${address.zipPostcode}, ${address.city}`;
  }

  handleRequestUpdateCustomer(): void {
    this.requestUpdateCustomer.emit({iri: this.presets$.getValue()['@id'], payload: this.cef.value});
  }
}
