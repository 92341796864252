import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {PartnersActions} from '../actions/';
import {CustomersService} from '../../services';

@Injectable()
export class PartnersEffects {

  constructor(private actions$: Actions, private service: CustomersService) {
  }

  ReadPartner$ = createEffect(() => this.actions$.pipe(
    ofType(PartnersActions.ReadPartner),
    switchMap(({iri}) => {
      return this.service.readCustomer(iri).pipe(
        map((response => PartnersActions.ReadPartnerSuccess({response}))),
        catchError(response => of(PartnersActions.ReadPartnerFail({response})))
      );
    })
  ));

  ReadPartners$ = createEffect(() => this.actions$.pipe(
    ofType(PartnersActions.ReadPartners),
    map(action => action),
    switchMap(({page, params}) => {
      return this.service.readPartners(page, params).pipe(
        map((response) => PartnersActions.ReadPartnersSuccess({response})),
        catchError(response => of(PartnersActions.ReadPartnersFail({response})))
      );
    })
  ));

  ReadPartnerChunk$ = createEffect(() => this.actions$.pipe(
    ofType(PartnersActions.ReadPartnerChunk),
    map(action => action),
    switchMap(({uri}) => {
      return this.service.readPartnersChunk(uri).pipe(
        map((response) => PartnersActions.ReadPartnerChunkSuccess({response})),
        catchError(response => of(PartnersActions.ReadPartnerChunkFail({response})))
      );
    })
  ));
}
