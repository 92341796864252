import {Component, Inject, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ApplicationState} from '../../../application-state/store';
import {BaseOnDestroyComponent} from '../../../shared/injectables/BaseOnDestroy.component';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {ProductsActions} from '../../../master-data/store';
import {ProductsSelectors} from '../../../master-data/store/selectors';
import {Product} from '../../../master-data/models';
import {ReplacementDataMediumsActions, ReplacementStockItemsActions} from '../../store';
import {
  ReplacementDataMediumsSelectors,
  ReplacementStockItemsSelectors
} from '../../store/selectors';
import {extractIri} from '../../../shared/utilities/objects.utility';
import {ReplacementStockItem} from '../../models';
import {sortByCreatedAtDate} from '../../../shared/utilities/array.utility';
import {Actions, ofType} from '@ngrx/effects';
import {isLoadingArray} from '../../../shared/utilities/observable.utility';
import {RouterActions} from '../../../application-state/store/actions';
import {FormsService} from '../../../shared/services';
import {DataMediumsService} from '../../services';

@Component({
  selector: 'app-add-replacement-hardware-dialog',
  styleUrls: ['./add-replacement-hardware-dialog.component.scss'],
  template: `
    <div mat-dialog-title style="min-width: 800px;">
      <div class="row">
        <div class="col-6">
          <h1>Ersatzdatenträger einlagern </h1>
        </div>
        <div class="col-6 text-right">
          <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

    </div>
    <div mat-dialog-content style="min-height: 350px;" class="pos-relative">
      <app-loading-overlay *ngIf="isLoading$|async"></app-loading-overlay>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-6">
            <div class="mat-form-field">
              <app-product-select formControlName="product"></app-product-select>
              <app-form-error class="text-color-red" fieldName="product" [formGroup]="form"></app-form-error>

            </div>
          </div>
          <div class="col-6">
            <div class="mat-form-field">
              <app-storage-location-select
                formControlName="storageLocation"></app-storage-location-select>
              <app-form-error class="text-color-red" fieldName="storageLocation" [formGroup]="form"></app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field>
              <mat-label>Beschreibung/Notitz (optional)</mat-label>
              <textarea matInput formControlName="description" rows="2" mat-autosize></textarea>
              <mat-error>
                <app-form-error fieldName="description" [formGroup]="form"></app-form-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isFirstStage">
          <div class="col-12">
            <mat-form-field>
              <mat-label>Seriennummern (eine pro Zeile)</mat-label>
              <textarea matInput formControlName="serialNumbers" rows="12" mat-autosize></textarea>
              <mat-error>
                <app-form-error fieldName="serialNumbers" [formGroup]="form"></app-form-error>
              </mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isFirstStage">
          <div class="col-12 text-right">
            <button mat-button color="green" [class.disabled]="form.invalid" (click)="submitConvertSerialNumbers()">
              <mat-icon>save</mat-icon>
              Weiter
            </button>
          </div>
        </div>
        <ng-container *ngIf="isSecondStage">
          <div class="row" *ngFor="let serial of seperatedSerialNumbers; let index = index" [formGroup]="serial">
            <div class="col-4">
              <mat-form-field>
                <mat-label>{{index + 1}}. Seriennummer</mat-label>
                <input matInput formControlName="serialNumber" type="text">
              </mat-form-field>

            </div>

            <div class="col-4 pt-2" *ngIf="!serial.get('storageNumber').value">
              <button mat-button color="green" (click)="submitSaveDataMedia(serial)">
                <mat-icon>save</mat-icon>
                Speichern und Label erstellen
              </button>

            </div>
            <div class="col-4" *ngIf="!serial.get('storageNumber').value">
              <span class="text-color-red">Dieser Datenträger ist noch nicht gespeichert!</span>
            </div>

            <div class="col-4" *ngIf="serial.get('storageNumber').value">
              <mat-form-field>
                <mat-label>Einlagerungsnummer</mat-label>
                <input matInput formControlName="storageNumber" type="text">
              </mat-form-field>

            </div>
            <div class="col-4 pt-2" *ngIf="serial.get('storageNumber').value">
              <button mat-button color="green" (click)="showLabelWindow(serial)">
                <mat-icon>print</mat-icon>
                Label drucken
              </button>

            </div>

          </div>
        </ng-container>
      </form>
      <!--<pre>{{toSaveToStockItem|json}}</pre>-->
      <!--<pre>{{stockItems$|async|json}}</pre>-->
    </div>
    <!--<div mat-dialog-actions></div>-->
  `
})
export class AddReplacementHardwareDialogComponent extends BaseOnDestroyComponent implements OnInit {

  isLoading$: Observable<boolean>;
  form: FormGroup;
  product$: Observable<Product>;
  seperatedSerialNumbers = [];
  toSaveToStockItem: ReplacementStockItem = null;

  stockItems$: Observable<Array<ReplacementStockItem>>;

  get isFirstStage(): boolean {
    return this.form.contains('serialNumbers') && !this.form.get('serialNumbers').disabled;
  }

  get isSecondStage(): boolean {
    return this.form.get('serialNumbers').disabled || this.seperatedSerialNumbers?.length > 0;
  }

  constructor(
    private store$: Store<ApplicationState>,
    private actions$: Actions,
    private fb: FormBuilder,
    private formService: FormsService,
    private dataMediumService: DataMediumsService,
    private dialogRef: MatDialogRef<AddReplacementHardwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading$ = isLoadingArray([
      this.store$.select(ReplacementStockItemsSelectors.isLoading),
      this.store$.select(ReplacementDataMediumsSelectors.isLoading),
    ]);
    this.initForm();
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });


  }

  private initForm(): void {
    this.form = this.fb.group({
      product: this.fb.control(null, [Validators.required]),
      storageLocation: this.fb.control(null, [Validators.required]),
      description: this.fb.control(''),
      serialNumbers: this.fb.control(null, [Validators.required]),
    });
    this.form.get('product').valueChanges
      .pipe(takeUntil(this.onDestroy$), filter(e => !!e))
      .subscribe(iri => {
        this.loadProduct(iri);
      });

    // TODO remnove just for testing
    /*this.form.patchValue({
      product: '/api/products/c0a9cdc4-94d3-4f3a-aff0-0a21e761b273'
    });
    this.form.updateValueAndValidity();*/


  }

  submitConvertSerialNumbers(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }
    this.form.get('product').disable();
    this.form.get('storageLocation').disable();
    this.form.get('serialNumbers').disable();
    this.form.get('description').disable();
    const values = this.form.get('serialNumbers').value.split(/[\r\n]+/gm).filter(e => e.trim() !== '');
    this.seperatedSerialNumbers = [...values].map(e => this.fb.group({
      '@id': this.fb.control(null),
      serialNumber: this.fb.control(e),
      storageNumber: this.fb.control(null),
    }));
  }

  submitSaveDataMedia(formControl: FormControl): void {
    const payload = {
      storageSystemSerialNumber: formControl.get('serialNumber').value
    };

    if (this.toSaveToStockItem === null) {
      this.store$.dispatch(ReplacementStockItemsActions.CreateReplacementStockItem({
        payload: {
          product: this.form.get('product').value,
          dataMediums: [payload],
          description: this.form.get('description').value,
          storageLocation: this.form.get('storageLocation').value,
        }
      }));
      this.actions$.pipe(ofType(ReplacementStockItemsActions.CreateReplacementStockItemSuccess), take(1), takeUntil(this.onDestroy$))

        .subscribe(({response}) => {
          this.toSaveToStockItem = response as ReplacementStockItem;
          const storageNumberFormControl = formControl.get('storageNumber');
          storageNumberFormControl.setValue(response.dataMediums[0].storageNumber);
          storageNumberFormControl.disable();

          const idFormControl = formControl.get('@id');
          idFormControl.setValue(extractIri(response.dataMediums[0]));
          formControl.get('serialNumber').disable();
          this.showLabelWindow(formControl);
        });
      this.actions$.pipe(
        ofType(ReplacementStockItemsActions.CreateReplacementStockItemFail),
        take(1),
        takeUntil(this.onDestroy$))
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });

    } else {
      this.store$.dispatch(ReplacementDataMediumsActions.CreateReplacementDataMedium({
        payload: {
          ...payload,
          stockItem: extractIri(this.toSaveToStockItem)
        }
      }));
      this.actions$.pipe(ofType(ReplacementDataMediumsActions.CreateReplacementDataMediumSuccess), take(1), takeUntil(this.onDestroy$))

        .subscribe(({response}) => {
          const storageNumberFormControl = formControl.get('storageNumber');
          storageNumberFormControl.setValue(response.storageNumber);
          storageNumberFormControl.disable();
          const idFormControl = formControl.get('@id');
          idFormControl.setValue(extractIri(response));
          formControl.get('serialNumber').disable();
          this.showLabelWindow(formControl);

        });
      this.actions$.pipe(
        ofType(ReplacementDataMediumsActions.CreateReplacementDataMediumFail),
        take(1),
        takeUntil(this.onDestroy$))
        .subscribe(fail => {
          this.formService.mergeErrorResponseIntoForm(fail, this.form);
        });

    }
  }

  private loadStockItems(iris: Array<string>): void {
    if (!iris) {
      return;
    }
    for (const iri of iris) {
      // TODO replace with Search for id[]
      this.store$.dispatch(ReplacementStockItemsActions.ReadReplacementStockItem({iri}));
    }
    this.stockItems$ = this.store$.select(ReplacementStockItemsSelectors.sList)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e.length >= 0),
        map(e => e.filter(f => iris.indexOf(extractIri(f)) > -1))
      );
    this.watchStockItems();
  }

  private loadProduct(iri): void {
    this.store$.dispatch(ProductsActions.ReadProduct({iri}));
    this.product$ = this.store$.select(ProductsSelectors.sByIri, {iri});
    this.product$
      .pipe(takeUntil(this.onDestroy$), filter(p => !!p?.stock))
      .subscribe(p => {
        console.log(p);
        this.loadStockItems(p.stock);
      });

  }

  close(): void {
    this.store$.dispatch(RouterActions.Go({path: ['warehouse']}));
    this.dialogRef.close();
  }

  private watchStockItems(): void {
    // combineLatest([
    //   this.stockItems$,
    //   this.product$,
    //   this.form.valueChanges
    // ]).pipe(takeUntil(this.onDestroy$))
    //   .subscribe(([stockitems, product, form]) => {
    //     if (this.toSaveToStockItem !== null) {
    //       return;
    //     }
    //     const productIri = extractIri(product);
    //     const storageLocation = form.storageLocation;
    //     const description = form.description;
    //     const possibleStockItems = stockitems.filter(e =>
    //       (e.description?.trim() === description?.trim()) &&
    //       e.product === productIri &&
    //       e.storageLocation === storageLocation).sort(sortByCreatedAtDate);
    //     this.toSaveToStockItem = possibleStockItems[0] || null;
    //   });

  }

  showLabelWindow(serial: any): void {
    console.log(serial);
    this.dataMediumService.openLabelInPopup(serial.get('@id').value);

  }
}
