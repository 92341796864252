import { Component, HostBinding, OnInit } from '@angular/core';
import { MenuItem } from './navigation/models';
import { ApplicationState } from './application-state/store';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import {
  ActivatedRoute,
  Event,
  NavigationStart,
  Router
} from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { Go } from './application-state/store/actions/router.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  customerRoles,
  fakturaRoles,
  leadRoles,
  orderRoles,
  salesRoles,
  ticketRoles,
  warehouseRoles
} from './accessRoles';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  template: `
    <header *ngIf="isLoggedInAndNotExpired$ | async">
      <app-smart-bar></app-smart-bar>
      <app-navbar [menuItems]="menuItems"></app-navbar>
    </header>

    <main
      [class]="slug"
      [class.authenticated]="isLoggedInAndNotExpired$ | async"
    >
      <div class="bmo-container py-4">
        <div
          class="alert alert-danger"
          *ngIf="
            (activatedRoute?.queryParams | async)?.pageError === 'ACCESS_DENIED'
          "
        >
          <strong>Error:</strong> Die angeforderte Seite existiert nicht oder
          Sie haben keine Rechte diese aufzurufen.
          <button
            class="ms-2"
            mat-flat-button
            (click)="removeQueryParameter('pageError')"
          >
            Hinweis schließen
          </button>
        </div>
        <router-outlet></router-outlet>
      </div>
    </main>

    <footer *ngIf="isLoggedInAndNotExpired$ | async">
      <app-footer></app-footer>
    </footer>
    <notifier-container></notifier-container>
  `
})
export class AppComponent implements OnInit {
  // Helper class for hiding debug output if left in source code
  @HostBinding('class.debug')
  debug = !this.isProduction;
  remainingSeconds: number;
  showMenu = false;
  menuItems: Array<MenuItem>;
  slug: string;
  styles = `
    left: 30px;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transform: translateY(-50%);
    resize: both;
    width: 450px;`;
  title = 'bmoffice24';
  remainingSessionSeconds$: Observable<number>;
  isLoggedInAndNotExpired$: Observable<boolean>;

  constructor(
    private store$: Store<ApplicationState>,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    //setting default translate
    translate.setDefaultLang('de');
    translate.use('de');
    this.menuItems = [
      {
        iconIdentifier: 'speed',
        label: 'Dashboard',
        linkTarget: 'dashboard'
      } /*, {
        iconIdentifier: 'people_outline',
        label: 'Admins',
        linkTarget: 'administrators'
      }*/,
      {
        iconIdentifier: 'people',
        label: 'Kunden',
        linkTarget: 'customers',
        neededRole: customerRoles
      },
      {
        iconIdentifier: 'message',
        label: 'Tickets',
        linkTarget: 'tickets',
        neededRole: ticketRoles
      },
      {
        iconIdentifier: 'insert_emoticon',
        label: 'Aufträge',
        linkTarget: 'orders',
        neededRole: orderRoles
      },
      {
        iconIdentifier: 'content_paste',
        label: 'Faktura',
        linkTarget: 'invoices',
        neededRole: fakturaRoles
      },
      {
        iconIdentifier: 'dashboard',
        label: 'Anfragen',
        linkTarget: 'leads',
        neededRole: leadRoles
      },
      {
        iconIdentifier: 'topic',
        label: 'Lager',
        linkTarget: 'warehouse',
        neededRole: warehouseRoles
      },
      {
        iconIdentifier: 'local_shipping',
        label: 'Versand',
        linkTarget: 'shipments',
        neededRole: warehouseRoles
      },
      {
        iconIdentifier: 'campaign',
        label: 'Vertrieb',
        linkTarget: 'sales',
        neededRole: salesRoles
      } /*, {
        iconIdentifier: 'how_to_reg',
        label: 'Partner',
        linkTarget: 'partners',
        disabled: true
      }*/ /*, {
        iconIdentifier: 'insert_chart_outlined',
        label: 'Berichte',
        linkTarget: 'reports',
        disabled: true
      }*/ /*,
      {
        iconIdentifier: 'api',
        label: 'Stammdaten',
        linkTarget: 'master-data',
      }*/
    ];
  }

  get isProduction(): boolean {
    return environment.production;
  }

  ngOnInit(): void {
    this.remainingSessionSeconds$ = this.auth.remainingSessionSeconds$;
    this.isLoggedInAndNotExpired$ = this.auth.isLoggedInAndNotExpired$;
    this.auth.remainingSessionSeconds$
      .pipe(filter(e => e <= 0))
      .subscribe(() => {
        if (!this.activatedRoute?.snapshot?.firstChild?.data?.neededRole) {
          return;
        }
        this.store$.dispatch(
          Go({
            path: ['login'],
            query: {
              reason: 'TIMEOUT',
              redirectAfterLogin: encodeURIComponent(
                this.router.routerState.snapshot.url
              )
            }
          })
        );
        this.dialog.closeAll();
      });
    this.slug = this.router.url.split('/')[1] || 'dashboard';

    this.router.events.subscribe((value: Event) => {
      if (value instanceof NavigationStart) {
        this.slug = this.router.url.split('/')[1] || 'dashboard';
      }
    });
  }

  removeQueryParameter(parameter: string): void {
    this.router.navigate([], {
      queryParams: {
        [parameter]: null
      },
      queryParamsHandling: 'merge'
    });
  }
}
