import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Order} from '../../../orders/models';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-action-box-to-incoming-dm-delayed',
  styleUrls: ['./action-box-to-incoming-dm-delayed.component.scss'],
  template: `
    <div class="action-box">
      <div class="action-required-label muted">Aktion möglich</div>
      <div class="content ">
        <div class="row">
          <div class=" col-auto my-2 align-items-center">
            <div class="row ">
              <div class=" col-auto header"> Speichersystem verspätet?</div>
              <div class="sub-header col-auto">Der Autrag wurde
                <strong>{{(order$|async).createdAt|daysSince}} </strong> erstellt. Seitdem wird auf den Eingang des
                Speichersystems gewartet.
              </div>
            </div>
          </div>
          <div class="actions col my-2 text-right">
          </div>
        </div>
      </div>
      <!-- <div class="additional-content">
         <div class="row">
           <div class="col"></div>
         </div>
       </div>-->

    </div>
  `
})
export class ActionBoxToIncomingDmDelayedComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  onDestroy$: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.order$.pipe(takeUntil(this.onDestroy$), filter(order => !!order)).subscribe(order => {
      console.log(order);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

}
