import {Component, Input} from '@angular/core';


@Component({
  selector: 'contact-person-details',
  styleUrls: ['contact-person-details.component.scss'],
  template: `

    <div class="item wrap">

      <div class="inner grid grid-middle grid-no-gutter" [class.last]="last">

        <div class="column-4">
          <mat-icon>account_box</mat-icon>
          <strong>{{ label }}</strong>
        </div>

        <div class="column-10">

          <ul *ngIf="contact">
            <li>{{ contact.firstName }} {{ contact.lastName }}</li>
            <li>
              <mat-icon [inline]="true">voicemail</mat-icon>
              <span *ngIf="contact.phone">{{ contact.phone }}</span>
              <span *ngIf="!contact.phone">-</span>
            </li>
            <li>
              <mat-icon [inline]="true">call</mat-icon>
              <span *ngIf="contact.mobile">{{ contact.mobile }}</span>
              <span *ngIf="!contact.mobile">-</span>
            </li>
            <li>
              <mat-icon [inline]="true">mail</mat-icon>
              <span *ngIf="contact.email">{{ contact.email }}</span>
              <span *ngIf="!contact.email">-</span>
            </li>
          </ul>
          <p *ngIf="!contact">Kein Standard-AP für diese Kategorie</p>
        </div>
      </div>
    </div>
  `
})
export class ContactPersonDetailsComponent {

  @Input() contact: any;
  @Input() index: number;
  @Input() label: string;
  @Input() last: boolean;
}
