import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { loadIfNotLoaded } from '../../../shared/utilities/observable.utility';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../application-state/store';
import { Order, OrderState, OrderStateRange } from '../../models';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { extractIri } from '../../../shared/utilities/objects.utility';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LabLocationsSelectors } from '../../../master-data/store/selectors';
import { LabLocationsActions } from '../../../master-data/store';

@Component({
  selector: 'app-order-data-recovery-location-select',
  styleUrls: ['./order-data-recovery-location-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderDataRecoveryLocationSelectComponent),
      multi: true
    }
  ],
  template: `
    <div [formGroup]="form">
      <ng-select
        formControlName="selectedElement"
        [items]="items$ | async"
        bindLabel="name"
        [class.dense]="dense"
        [searchable]="true"
        [loading]="isLoading$ | async"
        bindValue="@id"
        [clearable]="true"
        [searchFn]="findItem"
        (touchstart)="onTouched($event)"
        [placeholder]="'orders.order_data_recovery_location_select.placeholder' | translate"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.name }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  `
})
export class OrderDataRecoveryLocationSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  form: FormGroup;
  @Output() updateSelectedObject: EventEmitter<
    OrderStateRange
  > = new EventEmitter<OrderStateRange>();
  @Input() @Optional() dense = false;
  items$: Observable<Array<OrderStateRange>>;
  isLoading$: Observable<boolean>;
  selectedItem: OrderStateRange;
  onDestroy$: Subject<any> = new Subject<any>();

  orders: Order[];

  constructor(
    private store$: Store<ApplicationState>,
    private fb: FormBuilder
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedElement: this.fb.control(null)
    });
    this.form
      .get('selectedElement')
      .valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(value => {
        this.onChange(value);
      });

    this.items$ = this.store$.select(LabLocationsSelectors.sList);
    this.isLoading$ = this.store$.select(LabLocationsSelectors.isLoading);
    loadIfNotLoaded(
      this.store$,
      LabLocationsSelectors.isLoaded,
      LabLocationsActions.ReadLabLocations()
    );
  }

  setSelectedItem(element: OrderState): void {
    this.selectedItem = element;
    this.updateSelectedObject.emit(element);
  }

  findItem(term: string, item: OrderState): boolean {
    const parts = term.split(' ');
    return parts.every(t => {
      return item.name.toLowerCase().indexOf(t.toLowerCase()) > -1;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('selectedElement').disable();
    } else {
      this.form.get('selectedElement').enable();
    }
  }

  writeValue(value: any): void {
    this.form.get('selectedElement').setValue(extractIri(value));
  }
}
