import { createReducer, on } from '@ngrx/store';

import * as fromModuleModels from '../../models';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { TicketCommentsActions } from '../actions';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  entities: { [iri: string]: fromModuleModels.TicketComment };
  errors: ErrorsObject;
  loaded: boolean;
  loading: boolean;
  pagination?: {
    current?: string;
    first?: string;
    last?: string;
    next?: string;
    previous?: string;
  };
  totalItems: number;
}

const initialState = {
  entities: {},
  errors: {},
  loaded: false,
  loading: false,
  totalItems: 0
};

export const reducer = createReducer(
  initialState,
  on(
    TicketCommentsActions.ReadTicketComment,
    TicketCommentsActions.ReadTicketComments,
    TicketCommentsActions.UpdateTicketComment,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    TicketCommentsActions.ReadTicketCommentSuccess,
    TicketCommentsActions.UpdateTicketCommentSuccess,
    (state, { response }) => {
      const entities = {
        ...state.entities,
        [response['@id']]: response
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(
    TicketCommentsActions.CreateTicketCommentSuccess,
    (state, { response }) => {
      // Note: Newly created comments are added to state BEFORE existing comments since their order is DESC
      const entities = {
        [response['@id']]: response,
        ...state.entities
      };

      return {
        ...state,
        entities,
        loading: false
      };
    }
  ),
  on(TicketCommentsActions.ReadTicketCommentsSuccess, (state, { response }) => {
    const hasPagination = response.hasOwnProperty('hydra:view');
    const items = response['hydra:member'];
    let pagination = {};
    const entities = items.reduce(
      (entities: { [iri: string]: fromModuleModels.TicketComment }, item) => {
        return {
          ...entities,
          [item['@id']]: item
        };
      },
      // Note: Intentionally "clear" state when entering single view
      {}
    );

    // Sample
    // "@id": "/api/customers?page=1",                          ---- always available
    // "hydra:first": "/api/customers?page=1",                  ---- always available
    // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
    // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
    // "hydra:last": "/api/customers?page=2",                   ---- always available

    if (hasPagination) {
      pagination = {
        first: response['hydra:view']['hydra:first'],
        current: response['hydra:view']['@id'],
        last: response['hydra:view']['hydra:last']
      };

      if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
        pagination = {
          ...pagination,
          previous: response['hydra:view']['hydra:previous']
        };
      }

      if (response['hydra:view'].hasOwnProperty('hydra:next')) {
        pagination = {
          ...pagination,
          next: response['hydra:view']['hydra:next']
        };
      }
    }

    return {
      ...state,
      entities,
      pagination,
      loading: false,
      loaded: true
    };
  }),
  on(
    TicketCommentsActions.ReadTicketCommentChunkSuccess,
    (state, { response }) => {
      const hasPagination = response.hasOwnProperty('hydra:view');
      const items = response['hydra:member'];
      let pagination = {};
      const entities = items.reduce(
        (entities: { [iri: string]: fromModuleModels.TicketComment }, item) => {
          return {
            ...entities,
            [item['@id']]: item
          };
        },
        { ...state.entities }
      );

      // Sample
      // "@id": "/api/customers?page=1",                          ---- always available
      // "hydra:first": "/api/customers?page=1",                  ---- always available
      // "hydra:next": "/api/customers?page=2"                    ---- might be unavailable
      // "hydra:previous": "/api/customers?page=2"                ---- might be unavailable
      // "hydra:last": "/api/customers?page=2",                   ---- always available

      if (hasPagination) {
        pagination = {
          first: response['hydra:view']['hydra:first'],
          current: response['hydra:view']['@id'],
          last: response['hydra:view']['hydra:last']
        };

        if (response['hydra:view'].hasOwnProperty('hydra:previous')) {
          pagination = {
            ...pagination,
            previous: response['hydra:view']['hydra:previous']
          };
        }

        if (response['hydra:view'].hasOwnProperty('hydra:next')) {
          pagination = {
            ...pagination,
            next: response['hydra:view']['hydra:next']
          };
        }
      }

      return {
        ...state,
        entities,
        pagination,
        loading: false,
        loaded: true
      };
    }
  ),
  on(TicketCommentsActions.DeleteTicketCommentSuccess, (state, { iri }) => {
    // @ts-ignore
    const { [iri]: removed, ...entities } = state.entities;

    return {
      ...state,
      entities,
      errors: {},
      loading: false
    };
  }),
  on(
    TicketCommentsActions.CreateTicketCommentFail,
    TicketCommentsActions.ReadTicketCommentFail,
    TicketCommentsActions.ReadTicketCommentsFail,
    TicketCommentsActions.UpdateTicketCommentFail,
    TicketCommentsActions.DeleteTicketCommentFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(TicketCommentsActions.ResetTicketCommentsLoaded, state => {
    return {
      ...state,
      loaded: false
    };
  }),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
