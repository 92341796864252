import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { AbstractApiService } from '../../shared/services';
import { AbstractApiResponse } from '../../shared/models';
import { Country } from '../models';

@Injectable()
export class CountriesService {
  // @SECURITY no roles implemented
  constructor(private apiService: AbstractApiService) {}

  getCountry(code: string): Observable<Country> {
    return this.apiService.getObject(`/intl/countries/${code}`);
  }

  getCountries(): Observable<AbstractApiResponse | any> {
    return this.apiService.getObject(`/intl/countries`);
  }

  readCountriesForProject(): Observable<any> {
    return of({
      'hydra:member': [
        {
          '@id': '/api/intl/countries/DE',
          '@type': 'Country',
          code: 'DE',
          name: 'Deutschland'
        },
        {
          '@id': '/api/intl/countries/AT',
          '@type': 'Country',
          code: 'AT',
          name: 'Österreich'
        }
      ]
    });
  }
}
