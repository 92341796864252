import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { AbstractApiService } from '../../shared/services';
import { CustomerReplacementStockItem } from '../models';
import { getUriWithPageAndParams } from '../../shared/utilities/urlParams.utility';
import { UserRoleService } from '../../shared/services/user-role.service';

@Injectable()
export class CustomerReplacementStockItemsService {
  constructor(
    private apiService: AbstractApiService,
    private rolesService: UserRoleService
  ) {}

  createCustomerReplacementStockItem(
    payload: CustomerReplacementStockItem
  ): Observable<CustomerReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<CustomerReplacementStockItem>(
      'ROLE_MWS_CUSTOMER_REPLACEMENT_STOCK_ITEM_CREATE',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.createObject(
              `/customer_replacement_stock_items`,
              payload
            )
    );
  }

  getCustomerReplacementStockItem(
    iri: string
  ): Observable<CustomerReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<CustomerReplacementStockItem>(
      'ROLE_MWS_CUSTOMER_REPLACEMENT_STOCK_ITEM_VIEW',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.getObject(iri, true)
    );
  }

  getCustomerReplacementStockItems(
    page: number,
    params: any
  ): Observable<CustomerReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<CustomerReplacementStockItem>(
      'ROLE_MWS_CUSTOMER_REPLACEMENT_STOCK_ITEM_LIST',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.getObject(
              getUriWithPageAndParams(
                '/customer_replacement_stock_items',
                page,
                params
              )
            )
    );
  }

  updateCustomerReplacementStockItem(
    iri: string,
    payload: CustomerReplacementStockItem
  ): Observable<CustomerReplacementStockItem> {
    return this.rolesService.userHasRoleFilter<CustomerReplacementStockItem>(
      'ROLE_MWS_CUSTOMER_REPLACEMENT_STOCK_ITEM_EDIT',
      hasRole =>
        hasRole === false
          ? EMPTY
          : this.apiService.updateObject(iri, payload, true)
    );
  }

  deleteCustomerReplacementStockItem(iri: string): Observable<any> {
    return this.rolesService.userHasRoleFilter<any>(
      'ROLE_MWS_CUSTOMER_REPLACEMENT_STOCK_ITEM_DELETE',
      hasRole =>
        hasRole === false ? EMPTY : this.apiService.deleteObject(iri, true)
    );
  }
}
