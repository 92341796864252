import {RemoteAccessDetailsComponent} from './remote-access-details/remote-access-details.component';
import {RemoteAccessFormComponent} from './remote-access-form/remote-access-form.component';
import {TicketCommentFormComponent} from './ticket-comment-form/ticket-comment-form.component';
import {TicketCommentsComponent} from './ticket-comments/ticket-comments.component';
import {TicketCostsFormComponent} from './ticket-costs-form/ticket-costs-form.component';
import {TicketDetailsComponent} from './ticket-details/ticket-details.component';
import {TicketFilterFormComponent} from './ticket-filter-form/ticket-filter-form.component';
import {TicketFormComponent} from './ticket-form/ticket-form.component';
import {TicketListComponent} from './ticket-list/ticket-list.component';

export const AllComponents = [
  RemoteAccessDetailsComponent,
  RemoteAccessFormComponent,
  TicketCommentFormComponent,
  TicketCommentsComponent,
  TicketCostsFormComponent,
  TicketDetailsComponent,
  TicketFilterFormComponent,
  TicketFormComponent,
  TicketListComponent,
];

export * from './remote-access-details/remote-access-details.component';
export * from './remote-access-form/remote-access-form.component';
export * from './ticket-comment-form/ticket-comment-form.component';
export * from './ticket-comments/ticket-comments.component';
export * from './ticket-costs-form/ticket-costs-form.component';
export * from './ticket-details/ticket-details.component';
export * from './ticket-filter-form/ticket-filter-form.component';
export * from './ticket-form/ticket-form.component';
export * from './ticket-list/ticket-list.component';
