import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AnalysisPriorityModesActions } from '../actions';
import { NotifierService } from 'angular-notifier';
import { AnalysisPriorityModesService } from '../../services/analysis-priority-modes.service';

@Injectable()
export class AnalysisPriorityModesEffects {
  readAnalysisPriorityMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisPriorityModesActions.ReadAnalysisPriorityMode),
      map(action => action),
      switchMap(({ iri }) => {
        return this.aps.getAnalysisPriorityMode(iri).pipe(
          map(response =>
            AnalysisPriorityModesActions.ReadAnalysisPriorityModeSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              AnalysisPriorityModesActions.ReadAnalysisPriorityModeFail({
                response
              })
            )
          )
        );
      })
    )
  );
  readAnalysisPriorityModes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisPriorityModesActions.ReadAnalysisPriorityModes),
      map(action => action),
      switchMap(() => {
        return this.aps.getAnalysisPriorityModes().pipe(
          map(response =>
            AnalysisPriorityModesActions.ReadAnalysisPriorityModesSuccess({
              response
            })
          ),
          catchError(response =>
            of(
              AnalysisPriorityModesActions.ReadAnalysisPriorityModesFail({
                response
              })
            )
          )
        );
      })
    )
  );
  FailActions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AnalysisPriorityModesActions.ReadAnalysisPriorityModeFail,
          AnalysisPriorityModesActions.ReadAnalysisPriorityModesFail
        ),
        map(({ type, response }) => {
          console.log({ response });
          const errors = response?.error['hydra:description'];
          let message = 'Fehler';
          switch (type) {
            case AnalysisPriorityModesActions.READ_ANALYSIS_PRIORITY_MODE_FAIL:
              message =
                'Beim Lesen einer Analyse Art sind Fehler aufgetreten:' +
                errors;
              break;
            case AnalysisPriorityModesActions.READ_ANALYSIS_PRIORITY_MODES_FAIL:
              message =
                'Beim Lesen der Analyse Arten sind Fehler aufgetreten:' +
                errors;
              break;
          }
          this.notifierService.show({ type: 'error', message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private aps: AnalysisPriorityModesService,
    private notifierService: NotifierService
  ) {}
}
