import {createSelector} from '@ngrx/store';

import {LEAD_ORIGIN_TYPES_FEATURE_KEY, selectLeadsModuleState} from '../reducers';
import * as fromLeadsSelectors from './leads.selectors';

export const selectLeadOriginTypesState = createSelector(
  selectLeadsModuleState,
  (leadsModuleState) => leadsModuleState[LEAD_ORIGIN_TYPES_FEATURE_KEY]
);

export const selectLeadOriginTypesEntities = createSelector(
  selectLeadOriginTypesState,
  (state) => state.entities
);

export const selectLeadOriginTypes = createSelector(
  selectLeadOriginTypesEntities,
  (entities) => Object.values(entities)
);
export const selectLeadOriginType = createSelector(
  selectLeadOriginTypesEntities,
  (entities, {iri}) => entities[iri]
);

export const isLoading = createSelector(
  selectLeadOriginTypesState,
  (state) => state.loading
);

export const isLoaded = createSelector(
  selectLeadOriginTypesState,
  (state) => state.loaded
);
