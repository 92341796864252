import {Component, Input, OnInit, Optional} from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {

  @Input() @Optional() size = 60;
  @Input() @Optional() overlay = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
