import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '../../../auth/services/auth.service';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-footer',
  styleUrls: ['footer.component.scss'],
  template: `
    <div class="footer__outer">
      <div class="footer__wrapper wrap">
        <div class="footer">
          <div class="grid">
            <div class="column-12">
              <p>
                <a
                  class="text-color-lightgrey"
                  href="mailto:bugs@bmoffice24.de?subject=[BMOffice24.de] Fehler gefunden&body=%0D%0AFehler:%0D%0A%0D%0AVorgang bei dem der Fehler aufgetreten ist:%0D%0A%0D%0AScreenshot:%0D%0A%0D%0AAuftrag/Anfrage/URL:"
                >
                  <mat-icon
                    class="mb-1"
                    style="font-size: 1.5rem; vertical-align: sub;"
                    >bug_report</mat-icon
                  >
                  <span class="">Bug gefunden?</span>
                </a>
                <span class="pipe">|</span>
                <span class="version">Version 0.8.0</span>
                <span
                  [routerLink]="'administrators'"
                  class="text"
                  *ngIf="isAdmin()"
                  ><span class="pipe">|</span>Administrators</span
                >
                <span
                  [routerLink]="'master-data'"
                  *ngIf="isAdmin()"
                  class="text"
                  ><span class="pipe">|</span>Stammdaten</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class FooterComponent implements OnInit {
  constructor(private dialog: MatDialog, private authService: AuthService) {}

  ngOnInit(): void {}

  isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  openBugReportForm(): void {
    this.dialog.open(DialogComponent, {
      data: {
        heading: 'Kontakt',
        text:
          'Bitte eine Mail mit Bild und Beschreibung des Fehlers an Paul Treubrodt.'
      }
    });
  }
}
