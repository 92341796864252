import { createReducer, on } from '@ngrx/store';

import { UserProfilesActions } from '../actions';
import { ErrorsObject } from '../../../shared/utilities/error-utility.utility';
import { getAndHandleBackendErrors } from '../../../shared/utilities/reducer.utility';
import { UserRoleActions } from '../../../application-state/store/actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  username?: string;
  roles?: Array<string>;
  id?: number;
  errors: ErrorsObject;
}

export const state: State = {
  loading: false,
  loaded: false,
  errors: {}
};

export const reducer = createReducer(
  state,
  on(
    UserProfilesActions.ReadUserProfile,
    UserProfilesActions.UpdateUserProfile,
    state => {
      return {
        ...state,
        loading: true
      };
    }
  ),
  on(
    UserProfilesActions.ReadUserProfileSuccess,
    UserProfilesActions.UpdateUserProfileSuccess,
    (state, { response }) => {
      return {
        ...state,
        ...response,
        loaded: true,
        loading: false
      };
    }
  ),
  on(
    UserProfilesActions.ReadUserProfileFail,
    UserProfilesActions.UpdateUserProfileFail,
    (state, { response }) => {
      return {
        ...state,
        errors: getAndHandleBackendErrors(response),
        loading: false
      };
    }
  ),
  on(UserRoleActions.UserRoleCheckFail, state => ({ ...state, loading: false }))
);
