import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

import {EmailTemplateContext} from '../../models';

type baseType = EmailTemplateContext;
const MODULE_NAME = 'MasterDataModule';
const NAMESPACE = 'Email Template Contexts';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read n
export const READ_LIST = '[' + MODULE_NAME + '] Read ' + NAMESPACE;
export const ReadList = createAction(READ_LIST);
export const READ_LIST_SUCCESS = '[' + MODULE_NAME + '] Read ' + NAMESPACE + ' Success';
export const ReadListSuccess = createAction(READ_LIST_SUCCESS, props<{ response: any }>());
export const READ_LIST_FAIL = '[' + MODULE_NAME + '] Read ' + NAMESPACE + ' Fail';
export const ReadListFail = createAction(READ_LIST_FAIL, props<{ response: HttpErrorResponse }>());

//////////////////////////////////////////////////////////////////////////////////////////////////////////////// Read 1
export const READ_ONE = '[' + MODULE_NAME + '] Read One' + NAMESPACE;
export const Read = createAction(READ_ONE, props<{ iri: string }>());
export const READ_ONE_SUCCESS = '[' + MODULE_NAME + '] Read One ' + NAMESPACE + ' Success';
export const ReadSuccess = createAction(READ_ONE_SUCCESS, props<{ response }>());
export const READ_ONE_FAIL = '[' + MODULE_NAME + '] Read One ' + NAMESPACE + ' Fail';
export const ReadFail = createAction(READ_ONE_FAIL, props<{ response: HttpErrorResponse }>());
