import { Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { ApplicationState } from '../../../application-state/store';
import { RouterActions } from '../../../application-state/store/actions';
import {
  getUuidFromIri,
  StringsUtility
} from '../../../shared/utilities/strings.utility';
import { takeUntil } from 'rxjs/operators';
import { OrdersService } from '../../services';
import { Order, SalesSummary } from '../../models';
import { PageEvent } from '@angular/material/paginator';
import { extractIri } from '../../../shared/utilities/objects.utility';
import {LocalStorageService} from "../../../shared/services";

@Component({
  selector: 'app-sales-view',
  styleUrls: ['sales-view.component.scss'],
  template: `
    <div class="row">
      <div class="col">
        <view-heading
          heading="Vertrieb"
          colLeft="column-2"
          colRight="column-12"
          colRightClass="m-ta--2"
        >
          <app-search-input
            [showToggle]="true"
            [disabled]="true"
            [filtersPage]="'sales'"
            (searchTermChange)="handleSearchInput($event)"
            (searchFilterVisibilityToggle)="toggleFilterBoxVisibility()"
          ></app-search-input>
        </view-heading>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-sales-filter-form
          [searchBoxVisible$]="searchBoxVisible$"
          (updateFilter)="handleFilterFormAction($event)"
        ></app-sales-filter-form>
      </div>
    </div>
    <div class="row">
      <div class="col pos-relative py-2">
        <app-loading-overlay
          *ngIf="loadingTable$ | async"
        ></app-loading-overlay>

        <app-sales-list
          *ngIf="sales$ | async as sales"
          [sales]="sales"
          (requestGoToOrderView)="handleRequestGoToOrderView($event)"
        ></app-sales-list>

        <app-paginator-table
          [totalItems]="orderCount"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 15, 30, 50]"
          [showFirstLastButtons]="true"
          (handleUpdatePageOrSize)="onChangedPage($event)"
        ></app-paginator-table>
      </div>
    </div>
    <div class="row">
      <div class="col pos-relative">
        <app-loading-overlay
          *ngIf="loadingSummary$ | async"
        ></app-loading-overlay>

        <app-sales-summary
          [salesSummary]="salesSummary$ | async"
        ></app-sales-summary>
      </div>
    </div>
  `
})
export class SalesViewComponent implements OnInit, OnDestroy {
  currentPage = 1;

  pageSize = 10;
  pageSizeOptions = [5, 10, 15, 20, 25];

  params$: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{
    [p: string]: any;
  }>({
    itemsPerPage: this.pageSize,
    page: this.currentPage,
    'order[createdAt]': 'desc'
  });
  sales$: BehaviorSubject<Array<Order>> = new BehaviorSubject<Array<Order>>([]);
  salesSummary$: BehaviorSubject<SalesSummary> = new BehaviorSubject<
    SalesSummary
  >(null);
  orderCount: number;
  searchBoxVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  onDestroy$: Subject<any> = new Subject<any>();
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingSummary$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  subscriptionList: any;
  subscriptionSummary: any;

  constructor(
    private store$: Store<ApplicationState>,
    private orderService: OrdersService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.loadSales();
    this.loadSalesSummary();
    const filters = this.localStorage.getObjectByKey('filters', 'sales');
    this.searchBoxVisible$.next(!!filters);
    this.searchBoxVisible$.subscribe(value => console.log(value));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  handleFilterFormAction(filters: any): void {
    const params = {
      'salesperson.uuid': null,
      'customer.partnerStatus': null,
      'customer.customerCooperations.id': null,
      stateRange: null,
      'exists[broker]': null,
      dataRecoveryPriorityMode: null,
      dataRecoveryLocation: null,
      analysisLocation: null,
      'priceDataRecoveryNet.value[gte]': null,
      'priceDataRecoveryNet.value[lte]': null,
      'createdAt[after]': null,
      'createdAt[before]': null
    };

    if (filters?.salesperson) {
      params['salesperson.uuid'] = getUuidFromIri(
        extractIri(filters.salesperson)
      );
    }

    if (filters?.stateRange) {
      params.stateRange = getUuidFromIri(extractIri(filters.stateRange));
    }

    if (filters?.dataRecoveryPriorityMode) {
      params.dataRecoveryPriorityMode = getUuidFromIri(
        extractIri(filters.dataRecoveryPriorityMode)
      );
    }
    if (filters?.analysisLocation) {
      params.analysisLocation = getUuidFromIri(
        extractIri(filters.analysisLocation)
      );
    }
    if (filters?.dataRecoveryLocation) {
      params.dataRecoveryLocation = getUuidFromIri(
        extractIri(filters.dataRecoveryLocation)
      );
    }
    if (filters?.partnerStatus) {
      params['customer.partnerStatus'] = getUuidFromIri(
        extractIri(filters.partnerStatus)
      );
    }
    if (filters?.customerCooperation) {
      params['customer.customerCooperations.id'] = getUuidFromIri(
        extractIri(filters.customerCooperation)
      );
    }

    if (filters?.priceMin) {
      params['priceDataRecoveryNet.value[gte]'] = filters.priceMin;
    }

    if (filters?.priceMax) {
      params['priceDataRecoveryNet.value[lte]'] = filters.priceMax;
    }

    if (filters?.createdAtMin) {
      params['createdAt[after]'] = filters.createdAtMin;
    }

    if (filters?.createdAtMax) {
      params['createdAt[before]'] = filters.createdAtMax;
    }

    if (filters?.broker === true || filters?.broker === false) {
      params['exists[broker]'] = filters.broker;
    }

    this.params$.next({
      ...this.params$.getValue(),
      ...params
    });

    this.loadSales();
    this.loadSalesSummary();
  }

  handleSearchInput(term: string): void {
    this.params$.next({
      ...this.params$.getValue(),
      fulltext_search: term ? term : ''
    });
    this.updateParams();
    this.loadSales();
    this.loadSalesSummary();
  }

  toggleFilterBoxVisibility(): void {
    this.searchBoxVisible$.next(!this.searchBoxVisible$.getValue());
    if (this.searchBoxVisible$.getValue() === false) {
      this.updateParams();
      this.loadSales();
      this.loadSalesSummary();
    }
  }

  loadSales(): void {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
    this.loadingTable$.next(true);
    this.subscriptionList = this.orderService
      .readSales(this.currentPage, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.loadingTable$.next(false);
          this.orderCount = response['hydra:totalItems'];
          this.sales$.next(response['hydra:member']);
        },
        fail => {
          this.loadingTable$.next(false);
          console.error(fail);
        }
      );
  }

  loadSalesSummary(): void {
    if (this.subscriptionSummary) {
      this.subscriptionSummary.unsubscribe();
    }
    this.loadingSummary$.next(true);
    this.subscriptionSummary = this.orderService
      .readSalesSummary(-1, this.params$.getValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        response => {
          this.loadingSummary$.next(false);
          this.salesSummary$.next(response['hydra:member'][0]);
        },
        fail => {
          this.loadingSummary$.next(false);
          console.error(fail);
        }
      );
  }

  onChangedPage(pageData: PageEvent): void {
    this.currentPage = pageData.pageIndex + 1;
    this.pageSize = pageData.pageSize;

    this.updateParams();
    this.loadSales();
    // this.loadSalesSummary();
  }

  handleRequestGoToOrderView(order: string): void {
    this.store$.dispatch(
      RouterActions.Go({
        path: ['orders', StringsUtility.getUuidFromIri(order)]
      })
    );
  }

  private updateParams(): void {
    if (this.searchBoxVisible$.getValue() === false) {
      this.params$.next({
        ...this.params$.getValue(),
        'order[createdAt]': 'desc',
        fulltext_search: this.params$
          .getValue()
          .hasOwnProperty('fulltext_search')
          ? this.params$.getValue().fulltext_search
          : ''
      });
    }
    this.params$.next({
      ...this.params$.getValue(),
      itemsPerPage: this.pageSize,
      page: this.currentPage
    });
  }
}
